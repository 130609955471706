// @flow
import React, { memo } from 'react';
import Icon from 'react-icon-base';
import { type TIconBase } from './types.js.flow';

const IconLoaderCircle = (props: TIconBase) => (
  <Icon viewBox="0 0 20 21" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 3.83325V1.33325L6.66671 4.66659L10 7.99992V5.49992C12.7584 5.49992 15 7.74159 15 10.4999C15 11.3416 14.7917 12.1416 14.4167 12.8333L15.6334 14.0499C16.2834 13.0249 16.6667 11.8083 16.6667 10.4999C16.6667 6.81659 13.6834 3.83325 10 3.83325ZM10 15.4999C7.24171 15.4999 5.00004 13.2583 5.00004 10.4999C5.00004 9.65825 5.20837 8.85825 5.58337 8.16659L4.36671 6.94992C3.71671 7.97492 3.33337 9.19159 3.33337 10.4999C3.33337 14.1833 6.31671 17.1666 10 17.1666V19.6666L13.3334 16.3333L10 12.9999V15.4999Z"
      fill="white"
    />
  </Icon>
);

export default memo(IconLoaderCircle);
