// @flow
import React from 'react';
import { useIntl } from 'react-intl';

import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

type Props = {
  title: {
    id: string,
    defaultMessage: string,
  },
  children: React$Node,
};

const GroupItem: React$StatelessFunctionalComponent<Props> = ({ title, children, PaperProps = {} }) => {
  const { formatMessage } = useIntl();
  return (
    <div>
      <Typography variant="subtitle1" fontWeight="500" mb={1}>
        {formatMessage(title)}
      </Typography>
      <Box component={Paper} width="560px" p={3} {...PaperProps}>
        {children}
      </Box>
    </div>
  );
};

export default GroupItem;
