// @flow
import { List, Map, Set, Record, fromJS, type RecordOf, type RecordFactory, OrderedMap } from 'immutable';
import { nanoid } from 'nanoid';
import type {
  PolygonType,
  TextMapStore,
  TextMapPageType,
  PageType,
  TextMap,
  ItemType,
  RawTextMap,
  ReferenceType,
  ExtraPolygonType,
  ExtraPolygonBBType,
  CheckBoxCellType,
  ReconciliationCellType,
  JournalEntryMessageType,
  RowMessage,
  JELayoutType,
} from './types.js.flow';
import type { JournalEntryType } from 'domain/contracts';
import documentJson from '__mock__/document.json';
import { JournalSettingOrder } from './types.js.flow';

export type ListCellSet = $ReadOnly<{|
  value: ?string,
  display?: string,
  options?: any,
|}>;

export type ListPlyCellSet = {
  value: string,
  entityType: string,
  boundingPoly: RecordOf<PolygonType> | RecordOf<ExtraPolygonBBType>,
};

type RawListType = {|
  [key: string]: {|
    DisplayName: string,
    Name: string,
  |},
|};

export type StringCellType = {|
  _cell: string,
  _row: number,
  _col: string,
  return: boolean,
  name: string,
  readonly: boolean,
  type: 'string',
  link?: ?string,
  bold: boolean,
  lenght: ?number,
  maxLength: ?number,
  hint: ?string,
  regex: ?string,
  valid: boolean,
  mandatory?: boolean,
  focused?: boolean,
  cellSet: RecordOf<ListCellSet>,
  style: Map<string, mixed>,
|};

export type DescriptionCellType = {|
  _cell: string,
  _row: number,
  _col: string,
  headerID: string,
  return: boolean,
  bold: boolean,
  hint: ?string,
  text: string,
  link?: ?string,
  readonly: boolean,
  type: 'description',
  name: string,
  regex: ?string,
  valid: boolean,
  mandatory?: boolean,
  focused?: boolean,
  style: Map<string, mixed>,
|};

export type FloatCellType = {|
  _cell: string,
  _row: number,
  _col: string,
  return: boolean,
  name: string,
  readonly: boolean,
  type: 'float',
  bold: boolean,
  hint: ?string,
  regex: ?string,
  valid: boolean,
  mandatory?: boolean,
  focused?: boolean,
  cellSet: RecordOf<ListCellSet>,
  style: Map<string, mixed>,
|};

export type GeneratedStringType = {|
  _cell: string,
  _row: number,
  _col: string,
  return: boolean,
  name: string,
  readonly: boolean,
  type: 'string_auto_generated',
  initValue: ?string,
  bold: boolean,
  maxLength: ?number,
  hint: ?string,
  cellSet: RecordOf<ListCellSet>,
  style: Map<string, mixed>,
|};

type ListItemType = {|
  id: string,
  value: string,
  display: string,
  isEditable?: boolean,
  isActive?: boolean,
|};

const matchRegexp = /^(\D+)(\d+)$/;

const ListItemFactory: RecordFactory<ListItemType> = new Record({
  id: '',
  value: '',
  display: '',
  isEditable: false,
  isActive: true,
});

export type RecordCellSetType = RecordOf<ListCellSet>;

export const RecordCellSet: RecordFactory<ListCellSet> = new Record({
  value: null,
  display: '',
  options: null,
});

function createPolygon(): PolygonType {
  return {
    x: '',
    y: '',
    h: '',
    w: '',
  };
}

const RecordboundingPoly: RecordFactory<PolygonType> = new Record(createPolygon());

export const ReacordPolyCellSet: RecordFactory<ListPlyCellSet> = new Record({
  value: '',
  entityType: '',
  boundingPoly: RecordboundingPoly(),
});

export type OptionsItemType = RecordOf<ListItemType>;

export type ListCellType = {|
  _cell: string,
  _row: number,
  _col: string,
  return: boolean,
  name: string,
  readonly: boolean,
  type: 'index_list' | 'paginated_list',
  bold: boolean,
  hint: ?string,
  regex: ?string,
  valid: boolean,
  mandatory?: boolean,
  focused?: boolean,
  includes: string,
  _options: List<OptionsItemType>,
  isCreatable: boolean,
  cellSet: RecordOf<ListCellSet | ListPlyCellSet>,
  style: Map<string, mixed>,
  include_id: string | null,
|};

export type CellTypes = StringCellType | DescriptionCellType | FloatCellType | ListCellType;

export type EntriesType =
  | RecordOf<StringCellType>
  | RecordOf<DescriptionCellType>
  | RecordOf<FloatCellType>
  | RecordOf<GeneratedStringType>
  | RecordOf<ListCellType>;

export function matchCell(cell: string): [string, number] {
  const res = cell.match(matchRegexp);
  if (res && res.length === 3) {
    return [res[1], parseInt(res[2], 10)];
  }
  throw new Error(`Cell not match pattern ${cell}`);
}

function styleFactory(style): Map<string, mixed> {
  if (style) return fromJS(style);
  return new Map();
}

const StringCellFactory: RecordFactory<StringCellType> = new Record({
  _cell: '',
  _row: '',
  _col: '',
  return: true,
  name: '',
  readonly: false,
  type: 'string',
  bold: false,
  hint: null,
  link: null,
  lenght: null,
  maxLength: undefined,
  regex: null,
  valid: true,
  mandatory: false,
  focused: false,
  cellSet: RecordCellSet(),
  style: new Map(),
});

function StringCellAdapter({ set, style, lenght, ...rest }): RecordOf<StringCellType> {
  return StringCellFactory({
    ...rest,
    cellSet: RecordCellSet(set),
    style: styleFactory(style),
    lenght,
    maxLength: lenght,
  });
}

export const DescriptionCellFactory: RecordFactory<DescriptionCellType> = new Record({
  _cell: '',
  _row: '',
  _col: '',
  headerID: '',
  return: false,
  bold: true,
  hint: null,
  text: '',
  link: null,
  readonly: true,
  type: 'description',
  name: '',
  regex: null,
  valid: true,
  mandatory: false,
  focused: false,
  style: new Map(),
});

function DescriptionCellAdapter({ text, style, ...rest }): RecordOf<DescriptionCellType> {
  return DescriptionCellFactory({
    ...rest,
    text: (text || '').toString().trim(),
    style: styleFactory(style),
  });
}

const FloatCellFactory: RecordFactory<FloatCellType> = new Record({
  _cell: '',
  _row: '',
  _col: '',
  return: true,
  name: '',
  readonly: false,
  type: 'float',
  bold: false,
  hint: null,
  regex: null,
  valid: true,
  mandatory: false,
  focused: false,
  cellSet: RecordCellSet(),
  style: new Map(),
});

function FloatCellAdapter({ set, style, ...rest }): RecordOf<FloatCellType> {
  return FloatCellFactory({
    ...rest,
    cellSet: RecordCellSet(set),
    style: styleFactory(style),
  });
}

// @see https://github.com/facebook/immutable-js/issues/1454#issuecomment-395333146
// FloatCellFactory.prototype.valid = function() {
//   if (typeof this.value === 'undefined' || typeof this.regex === 'undefined') return true;
//   return new RegExp(this.regex, 'i').test(this.value);
// }

export function listToOptions2(list?: RawListType): List<RecordOf<ListItemType>> {
  return new List(list.map((item, key) => ListItemFactory({ ...item, id: key })));

  /* const res = Object.(list).reduce((a, [key, item]) =>
    a.merge(ListItemFactory({ ...item, id: key })), );
  return res; */
}

export function listToOptions(list?: RawListType): List<RecordOf<ListItemType>> {
  return Object.values(list).reduce(
    (a, { display, value, isEditable, isActive }, e) =>
      a.push(ListItemFactory({ id: parseInt(e, 10), display, value, isEditable, isActive })),
    new List(),
  );
}

const ListCellFactory: RecordFactory<ListCellType> = new Record({
  _cell: '',
  _row: '',
  _col: '',
  return: true,
  name: '',
  readonly: false,
  type: 'index_list',
  isStaticList: true,
  bold: false,
  hint: null,
  includes: '',
  _options: new List(),
  regex: null,
  valid: true,
  mandatory: false,
  focused: false,
  isCreatable: false,
  cellSet: RecordCellSet(),
  style: new Map(),
  pageToken: null,
  count: 0,
  include_id: null,
});

// eslint-disable-next-line max-len
function ListCellAdapter({ list, set, style, ...rest }): RecordOf<ListCellType> {
  return ListCellFactory({
    ...rest,
    isStaticList: rest.is_static_list,
    _options: listToOptions(list || []),
    cellSet: RecordCellSet(set),
    style: styleFactory(style),
  });
}

const StringGeneratedFactory = new Record({
  _cell: '',
  _row: '',
  _col: '',
  return: true,
  name: '',
  readonly: false,
  type: 'string_auto_generated',
  initValue: '',
  bold: false,
  hint: null,
  link: null,
  maxLength: null,
  regex: null,
  valid: true,
  mandatory: false,
  focused: false,
  cellSet: RecordCellSet(),
  style: new Map(),
});

// eslint-disable-next-line camelcase
function StringGeneratedAdapter({ set, style, init_value, lenght, ...rest }) {
  return StringGeneratedFactory({
    ...rest,
    initValue: init_value,
    maxLength: lenght,
    cellSet: RecordCellSet(set),
    style: styleFactory(style),
  });
}

const CellSetFactory = new Record({
  value: null,
});

const CheckBoxFactory = new Record({
  _cell: '',
  _row: '',
  _col: '',
  bold: false,
  hint: null,
  name: '',
  readonly: false,
  return: true,
  cellSet: CellSetFactory(),
  style: new Map(),
  type: 'checkbox',
});

function CheckBoxAdapter({ set, style, list, ...rest }) {
  return CheckBoxFactory({
    ...rest,
    cellSet: CellSetFactory(set),
    style: styleFactory(style),
  });
}

const ReconciliationFactory: RecordFactory<ReconciliationCellType> = new Record({
  _cell: '',
  _row: '',
  _col: '',
  type: 'reconciliation',
  bold: false,
  hint: null,
  name: '',
  readonly: false,
  return: true,
  cellSet: CellSetFactory(),
  style: new Map(),
});

// eslint-disable-next-line max-len
export function reconciliationAdapter({ set, style, list, ...rest }): RecordOf<ReconciliationCellType> {
  return ReconciliationFactory({
    ...rest,
    cellSet: CellSetFactory(set),
    style: styleFactory(style),
  });
}

export type RecordsCellType =
  | RecordOf<StringCellType>
  | RecordOf<FloatCellType>
  | RecordOf<DescriptionCellType>
  | RecordOf<ListCellType>
  | RecordOf<GeneratedStringType>
  | RecordOf<CheckBoxCellType>
  | RecordOf<ReconciliationCellType>;

function matchType(cell: $PropertyType<JournalEntryType, 'entries'>): RecordsCellType {
  switch (cell.type) {
    case 'string':
      return StringCellAdapter(cell);

    case 'description':
      return DescriptionCellAdapter(cell);

    case 'float':
      return FloatCellAdapter(cell);

    case 'index_list':
      return ListCellAdapter(cell);

    case 'paginated_list':
      return ListCellAdapter(cell);

    case 'string_auto_generated':
      return StringGeneratedAdapter(cell);

    case 'checkbox':
      return CheckBoxAdapter(cell);

    case 'reconciliation':
      return reconciliationAdapter(cell);

    default:
      throw new Error('Unknown cell type');
  }
}

export type JornalEntryType = OrderedMap<string, RecordsCellType>;

export type IncludesColType = Map<string, List<OptionsItemType>>;

export type IncludesType = Map<string, IncludesColType>;

/**
 * parse cell name from A32 to alphabetic and digit
 * @param cellName - A32
 * @returns {[string,number]} - ['A', 32], [_col, _row]
 */
export const parseCellName = (cellName: string) => matchCell(cellName);

// eslint-disable-next-line max-len
export function getRowByNumber(data: JornalEntryType, rows: Set<number>): JornalEntryType {
  return data.reduce((a, v, k) => (rows.includes(matchCell(k)[1]) ? a.set(k, v) : a), new OrderedMap());
}

interface PaginationList {
  list: List<OptionsItemType>;
  pageToken: ?string;
  cachedList: Map<string, OptionsItemType>;
}

type PaginationListType = RecordOf<PaginationList>;

interface Meta {
  currentPage: number;
  pageCount: number;
  pageSize: number;
}

interface LineItemData {
  line_id: string;
  line_request_status: 'read' | 'unread' | null;
}

export type TJeMatchedLines = Map<string, List<Map<'line' | 'status', string>>>;

export type TJEGridLineColumnsDictionary = OrderedMap<string, string>;

export type Jornal = {|
  jeName: string,
  messages: List<RecordOf<JournalEntryMessageType>>,
  publicationWarnings: Set<string>,
  entry: JornalEntryType,
  includes: IncludesType,
  paginationList: PaginationListType,
  jeType: ?string,
  jeLayout: ?JELayoutType,
  jeInvoiceType: ?string,
  line_items: Set<number>,
  headerLinesNumber: number,
  settings: Set<number>,
  lines: Set<number>,
  rowMessages?: List<RecordOf<RowMessage>>,
  summary: Set<number>,
  initial_hidden_columns: List<string>,
  notHiddenColumns: Set<string>,
  meta: RecordOf<Meta>,
  lineCount: number,
  selectedRow: Set<number>,
  reconcileColumn: ?string,
  pinnedColumn: List<string>,
  amountColumn: ?string,
  balanceColumn: ?string,
  transactionTypeColumn: ?string,
  dateColumn: ?string,
  descriptionColumn: ?string,
  transactionIdColumn: ?string,
  errorFromErp: ?string,
  lineItemsData: Map<string, LineItemData>,
  jeGridLineColumnsDictionary: TJEGridLineColumnsDictionary,
  jeMatchedLines: TJeMatchedLines,
  loading: boolean,
  editing: boolean,
|};

export type JournalStore = RecordOf<Jornal>;

export const MetaFactory: RecordFactory<Meta> = new Record({
  currentPage: 1,
  pageCount: 1,
  pageSize: 50,
});

export const PaginationListFactory: RecordFactory<PaginationListType> = new Record({
  list: new List(),
  pageToken: null,
  isLoading: false,
  cachedList: new Map(),
});

export const JournalFactory: RecordFactory<Jornal> = new Record({
  jeName: 'Journal Entry',
  messages: new List(),
  publicationWarnings: new Set(),
  entry: new OrderedMap(),
  includes: new Map(),
  paginationList: PaginationListFactory(),
  jeType: null,
  jeLayout: null,
  jeInvoiceType: null,
  line_items: new Set(),
  settings: new Set(),
  lines: new Set(),
  rowMessages: new List(),
  summary: new Set(),
  initial_hidden_columns: new List(),
  notHiddenColumns: new Set(),
  meta: MetaFactory(),
  lineCount: 0,
  headerLinesNumber: 0,
  selectedRow: new Set(),
  reconcileColumn: '',
  pinnedColumn: new List(),
  amountColumn: '',
  transactionTypeColumn: '',
  dateColumn: '',
  descriptionColumn: '',
  transactionIdColumn: '',
  balanceColumn: '',
  errorFromErp: null,
  lineItemsData: new Map(),
  jeGridLineColumnsDictionary: new OrderedMap(),
  matchedLines: new Map(),
  loading: false,
  editing: false,
  textractForVendorEnabled: false,
});

export function isValid(cell: CellTypes) {
  if (
    typeof cell.value === 'undefined' ||
    cell.value === null ||
    cell.regex === null ||
    typeof cell.regex === 'undefined'
  )
    return true;
  return new RegExp(cell.regex, 'i').test(cell.value);
}

export function dataAdapter(data?: $PropertyType<JournalEntryType, 'entries'>): JornalEntryType {
  if (!data) throw new Error('No journal entry data');
  return Object.entries(data).reduce((a, [index, item]) => {
    const [_col, _row] = parseCellName(index);
    const inCall: CellTypes = { ...item, _cell: index, _row, _col, valid: isValid(item) };
    const cell = matchType(inCall);
    if (cell) return a.set(index, cell);
    return a;
  }, new OrderedMap());
}

export function jeGridLineColumnsAdapter(data?: $PropertyType<JournalEntryType, 'entries'>): JornalEntryType {
  if (!data) throw new Error('No journal entry data');
  return Object.entries(data).reduce((a, [index, item]) => {
    if (item.headerID) {
      const [_col] = parseCellName(index);

      return a.set(_col, item.headerID);
    }

    return a;
  }, new OrderedMap());
}

export function includeAdapter(include): IncludesColType {
  return Object.entries(include).reduce(
    (res, [fieldName, fieldData]) => res.set(fieldName, listToOptions(fieldData, true)),
    new Map(),
  );
}

export function includesAdapter(includes: $PropertyType<JournalEntryType, 'includes'>): IncludesType {
  return Object.entries(includes).reduce((res, [name, item]) => res.set(name, includeAdapter(item)), new Map());
}

export function listAdapter(...args) {
  return listToOptions(...args);
}

export const PolygonFactory: RecordFactory<PolygonType> = new Record(createPolygon());

export const ExtraPolygonBBFactory: RecordFactory<ExtraPolygonBBType> = new Record({
  x: '',
  y: '',
  h: '',
  w: '',
  page_number: '',
});

function createItem(): ItemType {
  return {
    boundingPoly: PolygonFactory(),
    text: '',
    id: '',
  };
}

export const ItemFactory: RecordFactory<ItemType> = new Record(createItem());

export const extraPolygonFactory = (data: ExtraPolygonType): RecordFactory<ExtraPolygonType> => {
  const record = new Record({
    boundingPoly: ExtraPolygonBBFactory(),
    text: '',
    id: '',
    polygonId: null,
  })(data);
  if (data) {
    return record.set('boundingPoly', ExtraPolygonBBFactory(data.boundingPoly));
  }
  return record;
};

export const ReferenceFactory: RecordFactory<ReferenceType> = new Record({
  id: '',
  x1: null,
  x2: null,
  y1: null,
  y2: null,
  cell: null,
  activity: true,
  text: '',
  type: '',
  polygonId: '',
});

export const PageFactory: RecordFactory<PageType> = new Record({
  data: List(),
  height: '',
  width: '',
});

export const TextMapFactory: RecordFactory<TextMap> = new Record({
  polygons: new Map(),
  extraPolygon: extraPolygonFactory(),
  pages: new Map(),
  reference: new Map(),
});

export const JournalSettingOrderFactory: RecordFactory<JournalSettingOrder> = new Record({
  pinned: [],
  float: [],
});

export const JournalSettingsFactory: RecordFactory<JournalSettings> = new Record({
  pinnedCols: [],
  disabledCols: [],
  orderedCols: JournalSettingOrderFactory({}),
});

function pageAdapter(rawPage: TextMapPageType) {
  const p: {
    data: List<string>,
    polygons: Map<string, RecordOf<ItemType>>,
  } = rawPage.data.reduce(
    (a, v) => {
      const boundingPoly = PolygonFactory(v.boundingPoly);
      const id: string = nanoid(10);
      const item: RecordOf<ItemType> = ItemFactory({ text: v.text, boundingPoly, id });
      const polygons: Map<string, RecordOf<ItemType>> = a.polygons.set(id, item);
      const data = a.data.push(id);
      return { polygons, data };
    },
    { polygons: new Map(), data: new List() },
  );

  const page: RecordOf<PageType> = PageFactory({
    height: rawPage.height,
    width: rawPage.width,
    data: p.data,
  });
  return {
    page,
    polygons: p.polygons,
  };
}

export function textMapAdapter(data: RawTextMap): TextMapStore {
  const reference = new Map();
  const maper: TextMap = Object.keys(data).reduce(
    (a, v) => {
      const container = pageAdapter(data[v]);
      const polygons = a.polygons.merge(container.polygons);
      const pages = a.pages.set(v, container.page);
      return { pages, polygons, reference };
    },
    { polygons: new Map(), pages: new Map(), reference },
  );
  return TextMapFactory(maper);
}

// ======

type AreaCoordinatesType = {|
  aXStart: number,
  aXEnd: number,
  aYStart: number,
  aYEnd: number,
|};

type PolygonCoordinatesType = {|
  pXStart: number,
  pXEnd: number,
  pYStart: number,
  pYEnd: number,
|};

function getPolyGonMatches(
  areaCoordinates: AreaCoordinatesType,
  polygonCoordinates: PolygonCoordinatesType,
): { fullMatch: boolean, notMatch: boolean } {
  const { aXStart, aXEnd, aYStart, aYEnd } = areaCoordinates;
  const { pXStart, pYStart, pXEnd, pYEnd } = polygonCoordinates;

  return {
    fullMatch: pXStart >= aXStart && pXEnd <= aXEnd && pYStart >= aYStart && pYEnd <= aYEnd,
    notMatch: aXEnd < pXStart || aYEnd < pYStart,
  };
}

function getMatchedCorner(
  areaCoordinates: AreaCoordinatesType,
  polygonCoordinates: PolygonCoordinatesType,
): {
  leftTopCorner: boolean,
  rightTopCorner: boolean,
  leftBottomCorner: boolean,
  rightBottomCorner: boolean,
} {
  const { aXStart, aXEnd, aYStart, aYEnd } = areaCoordinates;
  const { pXStart, pYStart, pXEnd, pYEnd } = polygonCoordinates;

  const leftTopCorner = aXStart < pXStart && aYStart < pYStart;
  const rightTopCorner = aYStart < pYStart && aXEnd > pXEnd;
  const leftBottomCorner = aYEnd > pYEnd && aXStart < pXStart;
  const rightBottomCorner = aXEnd > pXEnd && aYEnd > pYEnd;

  return {
    leftTopCorner,
    rightTopCorner,
    leftBottomCorner,
    rightBottomCorner,
  };
}

function polygonPartlyMatch(areaCoordinates: AreaCoordinatesType, polygonCoordinates: PolygonCoordinatesType): boolean {
  const { aXStart, aXEnd, aYStart, aYEnd } = areaCoordinates;
  const { pXStart, pYStart, pXEnd, pYEnd } = polygonCoordinates;

  const { leftTopCorner, rightTopCorner, leftBottomCorner, rightBottomCorner } = getMatchedCorner(
    areaCoordinates,
    polygonCoordinates,
  );

  const matchedWidth = (pXEnd - pXStart) * 0.5;
  const matchedHeight = (pYEnd - pYStart) * 0.5;

  if (leftTopCorner) {
    const xDiff = aXEnd - pXStart;
    const yDiff = aYEnd - pYStart;

    return xDiff > matchedWidth && yDiff > matchedHeight;
  }

  if (rightTopCorner) {
    const xDiff = pXEnd - aXStart;
    const yDiff = aYEnd - pYStart;

    return xDiff > matchedWidth && yDiff > matchedHeight;
  }

  if (leftBottomCorner) {
    const xDiff = aXEnd - pXStart;
    const yDiff = pYEnd - aYStart;

    return xDiff > matchedWidth && yDiff > matchedHeight;
  }

  if (rightBottomCorner) {
    const xDiff = pXEnd - aXStart;
    const yDiff = pYEnd - aYStart;

    return xDiff > matchedWidth && yDiff > matchedHeight;
  }

  return false;
}

type SelectAreaType = {
  x: number,
  y: number,
  width: number,
  height: number,
};

type HTMLPolygonType = {
  left: number,
  top: number,
  width: number,
  height: number,
};

export function isPolygonInArea(area: $ReadOnly<SelectAreaType>, polygon: HTMLPolygonType): boolean {
  const { width: aWidth, height: aHeight, x: aXStart, y: aYStart } = area;
  const { left: pXStart, top: pYStart, width: pWidth, height: pHeight } = polygon;

  const aXEnd: number = aXStart + aWidth;
  const aYEnd: number = aYStart + aHeight;
  const pXEnd: number = pXStart + pWidth;
  const pYEnd: number = pYStart + pHeight;

  const areaCoordinates = { aXStart, aXEnd, aYStart, aYEnd };
  const polygonCoordinates = { pXStart, pYStart, pXEnd, pYEnd };

  const { fullMatch, notMatch } = getPolyGonMatches(areaCoordinates, polygonCoordinates);

  if (notMatch) return false;
  if (fullMatch) return true;
  return polygonPartlyMatch(areaCoordinates, polygonCoordinates);
}

export const jeResponseModidfier = (resp: Object) => {
  // eslint-disable-next-line no-param-reassign
  resp.data = { ...resp.data, document: documentJson };
  return resp;
};

export const jeSorterCb = <T: RecordOf<{ _col: string, _cell: string }>, M: RecordOf<{ _col: string, _cell: string }>>(
  a: T,
  b: M,
) =>
  // A1, A9, A10, B1, ... Z1, AA1, AB1
  a.get('_col').length - b.get('_col').length || a.get('_cell').localeCompare(b.get('_cell'), 'en', { numeric: true });

// v2 - used like marker for migration from letters column to ids in indexedDB
export const generateJETableStoreKey = (keys: Array<string> = [], withSuffix: boolean = true) =>
  keys.concat(withSuffix ? 'v2' : []).join('-');

/**
 * In indexedDB for Journal Entry grid table we store column IDs, but we need column letters for work with data in react
 * this function can serialize and unserialize data from columnLetters to columnIDs and vica versa
 * @param data - this data from indexedDB or will be putted to IndexedDB: Array(['A', 'B', 'C']) - for tablePinnedCols, tableDisabledCols
 *               or Object({'A': 230, 'B': 120, 'C': 180 }) for tableColsWidth
 * @param dictionary - dictionary(OrderedMap) where stored relation columnLetter to columnID, default structure {columnLetter: columnID}, flipped - {columnID: columnLetter}
 * @param isFlippedDictionary - marker:  if true, we flip dictionary and can unserialize column ids to column letters(set to true when get data from indexedDB)
 * @returns {[string,*]|{}|*}
 */
export const swapJEGridLineColumnsData = <
  T: Array<string> | { [string]: string | number },
  M: TJEGridLineColumnsDictionary,
>(
  data: T,
  dictionary: M,
  isFlippedDictionary: boolean = false,
): T => {
  const currentDictionary = isFlippedDictionary ? dictionary.flip() : dictionary;

  if (Array.isArray(data)) {
    return data.map((columnKey) => currentDictionary.get(columnKey));
  }

  return Object.entries(data).reduce((acc, [columnKey, columnValue]) => {
    const currentColumnKey = currentDictionary.get(columnKey);

    acc[currentColumnKey] = columnValue;

    return acc;
  }, {});
};
