// @flow
import { makeStyles } from '@mui/styles';

export default makeStyles(() => ({
  workspace: {
    margin: [24, 20, 0, 20],
    display: 'flex',
    justifyContent: 'space-between',
    overflowX: 'scroll',

    '& > :first-child': {
      marginLeft: 0,
    },
  },
  uploadPopup: {
    width: '90vw',
    maxWidth: 800,
  },
}));
