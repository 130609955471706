/* @flow */
import { Record, Map, List } from 'immutable';
import { nanoid } from 'nanoid';

export const MessageHistoryFactory = new Record({
  companyPicture: '',
  companyName: '',
  firstName: '',
  lastName: '',
  transactions: new Map(),
  bookkeeperMessages: new List(),
  currency: 'USD',
});

const TransactionFactory = new Record({
  status: 'unread',
  bankAccount: '',
  description: '',
  amount: '',
  contact: '',
  date: '',
  id: '',
  messages: new List(),
  currency: undefined,
});

export const MessageFactory = new Record({
  firstName: '',
  lastName: '',
  timestamp: new Date().toISOString(),
  id: nanoid(16),
  message: '',
  email: '',
  owner: 'foreing',
});

export function bookkeeperMessagesAdapter(message: $ReadOnlyArray<*>, currentUser: string): List<*> {
  return message.reduce(
    // eslint-disable-next-line camelcase
    (a, { sender_first_name, sender_last_name, sender_email, ...rest }) =>
      a.push(
        MessageFactory({
          ...rest,
          firstName: sender_first_name,
          lastName: sender_last_name,
          email: sender_email,
          // eslint-disable-next-line camelcase
          owner: sender_email === currentUser ? 'my' : 'foreing',
        }),
      ),
    new List(),
  );
}

interface ICheck {
  (f: string, l: string): boolean;
}

function checkOwner(firstName: string, lastName: string): ICheck {
  return (first: string, last: string) => firstName === first && lastName === last;
}

function messageAdapter(message: $ReadOnlyArray<*>, check: ICheck): List<*> {
  // eslint-disable-next-line camelcase
  return message.reduce(
    (a, { first_name, last_name, ...rest }) =>
      a.push(
        MessageFactory({
          ...rest,
          firstName: first_name,
          lastName: last_name,
          owner: check(first_name, last_name) ? 'my' : 'foreing',
        }),
      ),
    new List(),
  );
}

// eslint-disable-next-line max-len
export function transactionAdapter(transaction: $ReadOnlyArray<*>, check: ICheck): Map<string, *> {
  return transaction.reduce(
    (a, { messages, ...rest }) =>
      a.set(
        rest.id,
        TransactionFactory({
          ...rest,
          messages: messageAdapter(messages, check),
        }),
      ),
    new Map(),
  );
}

export function messageHistoryAdapter({
  company_picture, // eslint-disable-line camelcase
  company_name, // eslint-disable-line camelcase
  first_name, // eslint-disable-line camelcase
  last_name, // eslint-disable-line camelcase
  transactions,
  currency,
}) {
  const check = checkOwner(first_name, last_name);
  return MessageHistoryFactory({
    companyPicture: company_picture,
    companyName: company_name,
    firstName: first_name,
    lastName: last_name,
    transactions: transactionAdapter(transactions, check),
    currency,
  });
}
