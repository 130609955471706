import Alert from '@mui/material/Alert';
import ListItemButton from '@mui/material/ListItemButton';
import { styled } from '@mui/material/styles';

export const EventDetails = styled(Alert)(({ theme }) => ({
  fontWeight: 400,
  marginTop: theme.spacing(1),

  '& .MuiAlert-message': {
    padding: 0,
  },
}));

export const EventItemBase = styled('div')(({ theme }) => ({
  display: 'flex',
  flex: 1,
  padding: theme.spacing(1, 2),
}));

export const EventItemButton = styled(ListItemButton)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  border: `1px solid ${theme.palette.grey[300]}`,
  borderRadius: 8,
}));
