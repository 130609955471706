// @flow
import React, { useMemo, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { usePopupState, bindTrigger, bindMenu } from 'material-ui-popup-state/hooks';

import { appsSelector, userIdSelector, requestAccessToModuleAction } from 'domain/env';
import AppSelectorIcon from '@mui/icons-material/Apps';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import SvgIcon from '@mui/material/SvgIcon';
import TalkToUsDialog from './components/TalkToUsDialog';
import { Menu, MenuItem, IconWrapper } from './StyledComponents';

import useToggle from 'hooks/useToggle';

import { ReactComponent as APIcon } from './icons/APIcon.svg';
import { ReactComponent as APIconSelected } from './icons/APIconSelected.svg';
import { ReactComponent as APIconInactive } from './icons/APIconInactive.svg';
import { ReactComponent as MCIcon } from './icons/MCIcon.svg';
import { ReactComponent as MCIconSelected } from './icons/MCIconSelected.svg';
import { ReactComponent as MCIconInactive } from './icons/MCIconInactive.svg';

import { alpha, useTheme } from '@mui/material';

import elements from 'components/elements';

const Apps = {
  AP: 'AP',
  MC: 'MC',
};

const mapStateToProps = (state) => ({
  apps: appsSelector(state),
  userEmail: userIdSelector(state),
});

const ApplicationDropdown = () => {
  const dispatch = useDispatch();
  const { palette } = useTheme();
  const { formatMessage } = useIntl();
  const [toggleDialog, onToggleDialog] = useToggle(false);
  const popupState = usePopupState({ variant: 'popover', popupId: 'AppsDropdown' });
  const { apps, userEmail } = useSelector(mapStateToProps);
  const [ticketPayload, setTicketPayload] = useState();

  const requestAccesstoModule = useCallback(
    (payload) => () =>
      new Promise((resolve, reject) => {
        dispatch(requestAccessToModuleAction({ payload, resolve, reject }));
      }).finally(() => {
        onToggleDialog();
        popupState.close();
      }),
    [dispatch, onToggleDialog, popupState],
  );

  const onOpenDialog = useCallback(() => {
    onToggleDialog();
  }, [onToggleDialog]);

  const menuItems = useMemo(() => {
    const activeItems = {
      [Apps.AP]: {
        selected: {
          icon: <SvgIcon component={APIconSelected} inheritViewBox fontSize="inherit" />,
        },
        unselected: {
          icon: <SvgIcon component={APIcon} inheritViewBox fontSize="inherit" />,
        },
      },
      [Apps.MC]: {
        selected: {
          icon: <SvgIcon component={MCIconSelected} inheritViewBox fontSize="inherit" />,
        },
        unselected: {
          icon: <SvgIcon component={MCIcon} inheritViewBox fontSize="inherit" />,
          onClick: () => {
            popupState.close();
            const appUrl = apps.getIn(['MC', 'appUrl']);
            if (appUrl) {
              // this simply opens url in new tab/window according to browser preferences
              Object.assign(document.createElement('a'), {
                target: '_blank',
                rel: 'noopener noreferrer',
                href: appUrl,
              }).click();
            }
          },
        },
      },
    };
    const inActiveItems = {
      [Apps.AP]: {
        selected: {
          icon: <SvgIcon component={APIconInactive} inheritViewBox fontSize="inherit" />,
        },
        unselected: {
          icon: <SvgIcon component={APIconInactive} inheritViewBox fontSize="inherit" />,
        },
      },
      [Apps.MC]: {
        selected: {
          icon: <SvgIcon component={MCIconInactive} inheritViewBox fontSize="inherit" />,
        },
        unselected: {
          icon: <SvgIcon component={MCIconInactive} inheritViewBox fontSize="inherit" />,
          onClick: () => {
            const payload = {
              subject: 'Request for upgrade to Monthly Closing',
              description: `User ${userEmail} requested for upgrade to Monthly Closing. Contact with customer is required`,
              custom_fields: {
                cf_app_url: window.location.href,
                cf_product: 'Dokka',
              },
            };
            setTicketPayload(payload);
            onOpenDialog();
          },
        },
      },
    };
    return [
      {
        value: Apps.AP,
      },
      {
        value: Apps.MC,
      },
    ].map((i) => {
      const appCode = i.value;
      const app = apps.get(appCode);
      const items = app?.active ? activeItems : inActiveItems;
      const selectedState = appCode === Apps.AP ? 'selected' : 'unselected';
      const label = formatMessage({
        id: `app.selector.${appCode}.label`,
        defaultMessage: app?.appName,
      });

      return { ...i, ...items[i.value][selectedState], label };
    });
  }, [apps, onOpenDialog, popupState, userEmail, formatMessage]);

  return (
    <>
      <IconButton
        size="small"
        sx={{
          boxShadow: `0 0 0 1px ${popupState.isOpen ? palette.primary.main : palette.grey[400]} inset`,
          backgroundColor: `${popupState.isOpen ? alpha(palette.primary.main, 0.3) : 'transparent'}`,
          '&:hover': {
            backgroundColor: alpha(palette.primary.main, 0.3),
          },
        }}
        {...bindTrigger(popupState)}
        data-element={elements.header.appSelector.container}
      >
        <AppSelectorIcon color="primary" />
      </IconButton>
      <Menu
        {...bindMenu(popupState)}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {menuItems.map((item) => (
          <MenuItem key={item.value} onClick={item.onClick} data-element={elements.header.appSelector.menuItem}>
            <Stack>
              <IconWrapper>{item.icon}</IconWrapper>
              <Typography variant="body1">{item.label}</Typography>
            </Stack>
          </MenuItem>
        ))}
      </Menu>
      <TalkToUsDialog open={toggleDialog} onClose={onToggleDialog} action={requestAccesstoModule(ticketPayload)} />
    </>
  );
};

export default ApplicationDropdown;
