// @flow
import * as React from 'react';
import { createPortal } from 'react-dom';
import { compose, type Dispatch } from 'redux';
import { connect } from 'react-redux';
import { type RecordOf, List, Map } from 'immutable';
import { nanoid } from 'nanoid';
import { setRefernceAction, setExtraPolygonAction, extraPolygonSelector, referenceSelector } from 'domain/journal';
import { companyFeatureSetSelector } from 'domain/companies/companiesSelector';
import { ReferenceFactory } from 'domain/journal/helper';
import clipboardCopy from 'lib/clipboardCopy';
import type { ItemType } from 'domain/journal/types.js.flow';
import { type TDataForTranslateTooltip, defaultDataForTranslateTooltip } from './TranslatePopup/helpers';
import { createPolygon, type HTMLPolygonType, type ExtraPolygon, type StyledPolygonType } from './helpers';

import Area from './selectionArea';
import Polygon from './polygon';
import ContextMenu, { type TDataForContextMenu, defaultDataForContextMenu } from './contextMenu';
import TranslatePopup from './TranslatePopup';

import cx from 'classnames';
import { withStyles } from '@mui/styles';
import sheet from './sheet';

type Props = {
  polygons: RecordOf<{
    data: List<RecordOf<ItemType>>,
    width: number,
    height: number,
  }>,
  classes: {
    [key: string]: string,
  },
  width: number,
  height: number,
  scale: number,
  pageNumber: number,
  getStyledPolygons: () => $ReadOnlyArray<StyledPolygonType>,
  getStyledPolygon: (RecordOf<ItemType>) => ExtraPolygon,
  setReference: Dispatch<setRefernceAction>,
  setExtraPolygon: Dispatch<setExtraPolygonAction>,
  extraPolygon: ExtraPolygon,
  gridReference: Map<*, *>,
  setArea: (area: Area) => void,
  features: Map<string, boolean>,
  wrapper: ?HTMLElement,
  tooltipContainer: ?HTMLElement,
  theme: any,
};

type State = {
  selecting: boolean,
  extraPolygon: ?ExtraPolygon,
  itemBeingDragged: ?string,
  isOpenContextMenu: boolean,
  isOpenTranslateTooltip: boolean,
  dataForContextMenu: TDataForContextMenu,
  dataForTranslateTooltip: TDataForTranslateTooltip,
};

const SIZE_PARAMS = ['width', 'height', 'scale', 'rotate'];

class Polygons extends React.Component<Props, State> {
  state = {
    selecting: false,
    extraPolygon: null,
    itemBeingDragged: null,
    isOpenContextMenu: false,
    isOpenTranslateTooltip: false,
    dataForContextMenu: defaultDataForContextMenu,
    dataForTranslateTooltip: defaultDataForTranslateTooltip,
  };

  shouldComponentUpdate(nextProps: Props, nextState: State): boolean {
    const {
      polygons,
      extraPolygon: { polygonId },
      gridReference,
    } = this.props;
    const {
      selecting,
      extraPolygon,
      itemBeingDragged,
      isOpenContextMenu,
      dataForContextMenu,
      isOpenTranslateTooltip,
      dataForTranslateTooltip,
    } = this.state;
    const {
      polygons: nPolygons,
      extraPolygon: { polygonId: nPolygonId },
      gridReference: nGridReference,
    } = nextProps;
    const {
      selecting: nSelecting,
      extraPolygon: nExtraPolygon,
      itemBeingDragged: nItemBeingDragged,
      isOpenContextMenu: nIsOpenContextMenu,
      dataForContextMenu: nDataForContextMenu,
      isOpenTranslateTooltip: nIsOpenTranslateTooltip,
      dataForTranslateTooltip: nDataForTranslateTooltip,
    } = nextState;

    if (itemBeingDragged !== nItemBeingDragged) return true;
    if (polygonId !== nPolygonId) return true;
    if (!gridReference.equals(nGridReference)) return true;
    if (extraPolygon !== nExtraPolygon || selecting !== nSelecting) return true;
    if (isOpenContextMenu !== nIsOpenContextMenu) return true;
    if (isOpenTranslateTooltip !== nIsOpenTranslateTooltip) return true;
    if (dataForTranslateTooltip !== nDataForTranslateTooltip) return true;
    if (dataForContextMenu !== nDataForContextMenu) return true;

    const samePolygons = polygons.equals(nPolygons);
    return !(samePolygons && this.isSameSizeParams(nextProps));
  }

  componentDidUpdate(prevProps: Props, prevState: State): void {
    const { extraPolygon } = this.state;

    if (this.selectionArea && !this.isSameSizeParams(prevProps)) {
      this.selectionArea.init();
    }
    if (prevState.extraPolygon !== extraPolygon) {
      if (extraPolygon && extraPolygon.text) {
        this.handleTranslateTooltip({
          shapeBounding: this.extraPolygonRef.current.children[0].getBoundingClientRect(),
          text: extraPolygon.text,
        });
      } else {
        this.handleCloseTranslateTooltip();
      }
    }
  }

  onSelectionStart = () => {
    this.setState({
      selecting: true,
    });
  };

  onSelectionEnd = (idx: $ReadOnlyArray<number>, dmx: HTMLPolygonType) => {
    const styledPolygons = this.props.getStyledPolygons();
    const polygons = idx.map((i) => styledPolygons[i]);
    const extraPolygon = createPolygon(polygons, dmx);
    this.setState({
      selecting: false,
      extraPolygon,
    });
  };

  onSelectionRemove = () => {
    this.setState({
      extraPolygon: null,
    });
  };

  get polygons() {
    return this.props.polygons.data;
  }

  getDraggedPolygonId = () => {
    const { gridReference } = this.props;
    return gridReference.isEmpty() ? null : gridReference.first().polygonId;
  };

  documentRef = React.createRef();

  extraPolygonRef = React.createRef();

  isSameSizeParams(nextProps: Props): boolean {
    return SIZE_PARAMS.every((attr) => nextProps[attr] === this.props[attr]);
  }

  handleDragStart = (targetParams: ClientRect, text: string, id: string, type: string) => {
    const { setExtraPolygon, setReference, pageNumber } = this.props;
    const { left, top, width, height } = targetParams;

    this.referenceId = nanoid(10);
    this.setState({ itemBeingDragged: id });

    const params = ReferenceFactory({
      id: this.referenceId,
      x1: left + width,
      y1: top + height / 2 + window.scrollY,
      text,
      type,
      polygonId: id,
    });

    setExtraPolygon({
      id: this.referenceId,
      boundingPoly: { w: width, h: height, x: left, y: top, page_number: pageNumber },
      text,
      polygonId: id,
    });

    setReference({ params, id: this.referenceId });
  };

  createSelectionPlace = (el: ?HTMLCanvasElement) => {
    const { getStyledPolygons, setArea, theme } = this.props;

    if (el) {
      this.selectionPlace = el;
      this.selectionArea = new Area(
        el,
        getStyledPolygons,
        this.onSelectionStart,
        this.onSelectionEnd,
        this.onSelectionRemove,
        theme,
      );
      setArea(this.selectionArea);
    }
  };

  handleContextMenu = ({ shapeBounding, text }: $Shape<TDataForContextMenu>) => {
    const containerBounding = this.documentRef.current
      ? this.documentRef.current.getBoundingClientRect()
      : defaultDataForContextMenu.containerBounding;

    this.setState({
      isOpenContextMenu: true,
      dataForContextMenu: {
        text,
        shapeBounding,
        containerBounding,
      },
    });
  };

  handleTranslateTooltip = ({ shapeBounding, text }: $Shape<TDataForTranslateTooltip>) => {
    const { features, scale, wrapper } = this.props;
    if (features.get('is_translate_enable')) {
      const containerBounding = this.documentRef.current
        ? this.documentRef.current.getBoundingClientRect()
        : defaultDataForTranslateTooltip.containerBounding;

      const visibleContainerBounding =
        scale > 1 && Boolean(wrapper) ? wrapper.getBoundingClientRect() : containerBounding;

      this.setState({
        isOpenTranslateTooltip: true,
        dataForTranslateTooltip: {
          text,
          shapeBounding,
          containerBounding,
          visibleContainerBounding,
        },
      });
    }
  };

  handleCloseContextMenu = () => {
    this.setState({ isOpenContextMenu: false, dataForContextMenu: defaultDataForContextMenu });
  };

  handleCloseTranslateTooltip = () => {
    this.setState({ isOpenTranslateTooltip: false, dataForTranslateTooltip: defaultDataForTranslateTooltip });
  };

  handleCopy = () => {
    const { dataForContextMenu } = this.state;
    const { text } = dataForContextMenu || '';

    clipboardCopy(text).then(() => console.info('Text copied!'));
  };

  selectionArea: ?Area;

  selectionPlace: ?HTMLCanvasElement;

  referenceId: ?string;

  renderPolygon = (polygon: RecordOf<ItemType>) => {
    const { getStyledPolygon, classes } = this.props;
    const draggedPolygonId = this.getDraggedPolygonId();
    return (
      <Polygon
        key={polygon.id}
        className={cx(classes.item, { [classes.dragItem]: draggedPolygonId === polygon.id })}
        style={getStyledPolygon(polygon)}
        id={polygon.id}
        text={polygon.text}
        handleDrag={this.handleDragStart}
        handleContextMenu={this.handleContextMenu}
      />
    );
  };

  render() {
    const { classes, width, height, scale, tooltipContainer } = this.props;
    const {
      extraPolygon,
      selecting,
      isOpenContextMenu,
      dataForContextMenu,
      dataForTranslateTooltip,
      isOpenTranslateTooltip,
    } = this.state;

    return (
      <div ref={this.documentRef} className={classes.documentWrapper}>
        {isOpenTranslateTooltip &&
          tooltipContainer &&
          createPortal(
            <TranslatePopup
              onClose={this.handleCloseTranslateTooltip}
              dataForTranslateTooltip={dataForTranslateTooltip}
            />,
            tooltipContainer,
          )}
        <ContextMenu
          isOpen={isOpenContextMenu}
          onClose={this.handleCloseContextMenu}
          onClickCopy={this.handleCopy}
          dataForContextMenu={dataForContextMenu}
        />
        <canvas className={classes.list} ref={this.createSelectionPlace} width={width * scale} height={height} />
        <div className={cx(classes.list, { [classes.selecting]: selecting })} id="Polygons-container">
          {this.polygons.map(this.renderPolygon)}
          {extraPolygon ? (
            <div ref={this.extraPolygonRef}>
              <Polygon
                key={extraPolygon.id}
                className={cx(classes.item, classes.selectionArea, classes.dragItem)}
                style={extraPolygon.style}
                id={extraPolygon.id}
                text={extraPolygon.text}
                handleDrag={this.handleDragStart}
                handleContextMenu={this.handleContextMenu}
                handleMouseDown={this.handleCloseTranslateTooltip}
              />
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

const mapPropsToDispatch = {
  setReference: setRefernceAction,
  setExtraPolygon: setExtraPolygonAction,
};

const mapStateToProps = (state) => ({
  extraPolygon: extraPolygonSelector(state),
  gridReference: referenceSelector(state),
  features: companyFeatureSetSelector(state),
});

export default compose(connect(mapStateToProps, mapPropsToDispatch), withStyles(sheet, { withTheme: true }))(Polygons);

export { Polygons as TestPolygons };
