// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { isAdminSelector, organizationIdSelector, userIdSelector, userNamesSelector } from 'domain/env';
import { createOrganization } from 'domain/organization';
import { type OrganizationMainFields } from 'domain/organization/helpers';
import Form from './form';
import Dialog from '@mui/material/Dialog';

type Props = {
  isAdmin: boolean,
  email: string,
  organizationId?: string,
  names: {
    firstName: string,
    lastName: string,
  },
  createOrganization: (data: OrganizationMainFields) => void,
};

class OrganizationModalContainer extends Component<Props> {
  onSubmit = (data) => {
    this.props.createOrganization(data.toJS());
  };

  get initialValues() {
    return {
      email: this.props.email,
      first_name: this.props.names.firstName,
      last_name: this.props.names.lastName,
    };
  }

  render() {
    const { isAdmin, organizationId } = this.props;
    const isOpen = organizationId === null && isAdmin;
    return isOpen ? (
      <Dialog open={isOpen}>
        <Form initialValues={this.initialValues} onSubmit={this.onSubmit} />
      </Dialog>
    ) : null;
  }
}

const mapStateToProps = (state) => ({
  organizationId: organizationIdSelector(state),
  isAdmin: isAdminSelector(state),
  email: userIdSelector(state),
  names: userNamesSelector(state),
});

export default connect(mapStateToProps, {
  createOrganization,
})(OrganizationModalContainer);
