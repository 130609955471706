// @flow
import React from 'react';

import useScrollTrigger from '@mui/material/useScrollTrigger';
import MuiAppBar, { AppBarProps as TAppBarProps } from '@mui/material/AppBar';
import Container from '@mui/material/Container';
import Box, { BoxProps as TBoxProps } from '@mui/material/Box';
import SystemMessages from 'components/SystemMessages';
import ConnectionHealthAlert from 'components/ConnectionHealthAlert';

import { styled } from '@mui/material/styles';

type TStickySubheader = {
  children?: React$Node,
  AppBarProps?: TAppBarProps,
  BoxProps?: TBoxProps,
};

function ElevationScroll({ children }) {
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

const AppBar = styled(MuiAppBar)(({ theme }) => ({
  zIndex: theme.zIndex.drawer,
  top: theme.mixins.toolbar.minHeight,
  backgroundColor: theme.palette.background.default,
  // when open Menu, Dialog etc - mui add right padding
  boxSizing: 'content-box', // https://github.com/mui/material-ui/issues/10000#issuecomment-359721955
}));

const StickySubheader: React$StatelessFunctionalComponent<TStickySubheader> = ({
  children,
  AppBarProps = {},
  BoxProps = {},
}) => (
  <ElevationScroll>
    <AppBar component="div" className="mui-fixed" color="transparent" elevation={0} position="sticky" {...AppBarProps}>
      <ConnectionHealthAlert />
      <Container maxWidth={false}>
        <SystemMessages />
        {children && (
          <Box my={1} {...BoxProps}>
            {children}
          </Box>
        )}
      </Container>
    </AppBar>
  </ElevationScroll>
);

export default StickySubheader;
