import { styled } from '@mui/material/styles';

import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';

export const ButtonsGroup = styled(ButtonGroup, { shouldForwardProp: (propName) => propName !== 'isActive' })(
  ({ isActive }) => ({
    ...(isActive ? { position: 'absolute', height: 60, top: -30, borderRadius: 0, minWidth: 202 } : {}),
  }),
);

export const ActionButton = styled(Button, { shouldForwardProp: (propName) => propName !== 'isActive' })(
  ({ isActive }) => ({
    textTransform: 'none',
    fontWeight: 400,
    minWidth: '145px !important',
    ...(isActive ? { borderRadius: 0 } : { color: 'inherit' }),
  }),
);
export const CloseButton = styled(Button)(() => ({
  minWidth: '56px !important',
  borderRadius: 0,
}));
