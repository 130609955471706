/* @flow */
export const MATCH = 'Match';
export const CREATE = 'Create';

export const RECONCILED = 'Reconciled';
export const COMMIT = 'Commit';
export const IGNORE = 'Ignore';
export const IGNORED = 'Ignored';
export const CAN_CREATE = 'NoMatchCanCrate';
export const CANT_CREATE = 'NoMatchCantCrate';
export const PROCESSING = 'Processing';
export const NO_BANK_ACCOUNT = 'NoBankAccount';
export const UNDO = 'Undo';

export const OPTIONS = [
  { id: 'commit', title: 'Commit', type: [MATCH] },
  { id: 'undo', title: 'Undo', type: [RECONCILED, IGNORED] },
  { id: 'detail', title: 'Detail', type: [MATCH, RECONCILED] },
  {
    id: 'create',
    title: 'Create',
    type: [CREATE, CAN_CREATE, CANT_CREATE],
    disabled: ({ value }) => value === CANT_CREATE,
  },
  { id: 'search', title: 'Search', type: [CAN_CREATE, CANT_CREATE], disabled: ({ amount }) => amount === null },
  { id: 'ignore', title: 'Ignore', type: [CAN_CREATE, CANT_CREATE] },
];

export const TRANSACTION_STATES =
  /* :: Object.freeze( */
  {
    MATCH: 'Match',
    RECONCILED: 'Reconciled',
    IGNORED: 'Ignored',
    CAN_CREATE: 'NoMatchCanCrate',
    CANT_CREATE: 'NoMatchCantCrate',
    NO_BANK_ACCOUNT: 'NoBankAccount',
  }; /* ::) */
