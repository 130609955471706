import ui from 'lib/ui';
import mediaQuery from 'lib/mediaQuery';

import type { Props } from './modal';

export default (theme) => ({
  modal: {
    textTransform: 'none',
    width: ({ width = 450 }: Props) => width,
    [`@media (max-height: ${mediaQuery.maxHeight})`]: {
      width: 400,
    },
    whiteSpace: 'pre-line',
  },
  green: {
    borderTop: `4px solid ${theme.colors.secondaryDark}`,
  },
  red: {
    borderTop: `4px solid ${theme.colors.primaryDanger}`,
  },
  blue: {
    borderTop: `4px solid ${theme.colors.primaryLight}`,
  },
  title: {
    fontSize: 24,
    padding: [40, 40, 0],
    color: theme.colors.greyBlack,
    fontWeight: 'bold',
    [`@media (max-height: ${mediaQuery.maxHeight})`]: {
      fontSize: 19,
      padding: [40, 24, 0],
    },
  },
  placeholder: {
    padding: [24, 40, 32],
    fontWeight: 200,
    fontSize: 16,
    lineHeight: '20px',
    color: theme.colors.greyBlack,
    [`@media (max-height: ${mediaQuery.maxHeight})`]: {
      fontSize: 14,
      lineHeight: '18px',
      padding: [24, 24, 32],
    },
  },
  btn: {
    ...ui(theme).btn,
    display: 'flex',
    marginRight: 10,
    whiteSpace: 'nowrap',
    textDecoration: 'none',
  },
  btnGray: {
    ...ui(theme).btnGray,
    extend: 'btn',
    marginLeft: 10,
    marginRight: 0,
  },
  confirmRedBtn: ui(theme).btnDanger,
  confirmBlueBtn: {
    ...ui(theme).btn,
    backgroundColor: theme.colors.primaryLight,
    '&:hover': {
      backgroundColor: theme.colors.primaryLight,
      boxShadow: `0 2px 25px ${theme.colors.primaryLight}`,
    },
    '&:active': {
      backgroundColor: theme.colors.primaryLight,
      boxShadow: `0 2px 25px ${theme.colors.primaryLight}`,
    },
  },
  btnBordered: ui(theme).btnBordered,
  btns: {
    padding: [0, 40, 32],
    display: 'flex',
    justifyContent: 'flex-end',
    '& > button:last-child': {
      marginRight: 0,
    },
    '& > button': {
      minWidth: 110,
      width: 'auto',
      marginRight: 24,
      height: 48,
      lineHeight: '14px',
      fontSize: 14,
      fontWeight: 200,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      textTransform: 'none',
      [`@media (max-height: ${mediaQuery.maxHeight})`]: {
        height: 36,
      },
    },
    [`@media (max-height: ${mediaQuery.maxHeight})`]: {
      fontSize: 14,
      padding: [0, 40, 24],
    },
  },

  keyWordBox: {
    padding: [0, 40, 32],
  },

  keyWordInput: {
    border: '1px solid #DEE2E6',
    borderRadius: 2,
    backgroundColor: '#FFF',
    padding: [0, 8],
    fontSize: 14,
    width: '100%',
    height: 36,
    color: theme.colors.greyBlack,
    textAlign: 'left',
    '&::placeholder': {
      color: 'rgba(79, 79, 79, 0.5)',
    },
    '&:focus': {
      border: '1px solid #6C98D8',
      outline: 'none',
    },
  },
});
