// @flow
import React, { useCallback } from 'react';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import HighlightText from 'components/mui/HighlightText';
import { isMobile } from 'lib/systemHelpers/browserHelpers';

export type OptionValue = string | number;

export type OptionData = {|
  label: string,
  value: OptionValue,
|};

export type OptionStyle = {
  height: number,
  width: number | string,
};

type Props = {|
  data: OptionData,
  style: OptionStyle,
  isPreselect: boolean,
  isActive: boolean,
  index: number,
  onClick: (i: number) => void,
  onPreselect: (i: number) => void,
  searchTerm?: string,
|};

const VirtualOption = ({ style, index, data, onClick, onPreselect, isActive, isPreselect, searchTerm }: Props) => {
  const onMouseMove = useCallback(
    (e: MouseEvent) => {
      // Preselection based on mouseMove is not possible on mobile. It breaks onClick handler
      if (isMobile) return;
      onPreselect(index, e.clientX);
    },
    [index, onPreselect],
  );

  const onOptionClick = useCallback(() => {
    onClick(index);
  }, [index, onClick]);

  return (
    <ListItem
      style={style}
      selected={isActive}
      disablePadding
      sx={(theme) => ({ backgroundColor: isPreselect && !isActive ? theme.palette.grey[100] : 'none' })}
    >
      <ListItemButton style={{ height: '100%', width: '100%' }} onClick={onOptionClick} onMouseMove={onMouseMove}>
        <ListItemText primary={<HighlightText value={data.label} searchTerm={searchTerm || ''} />} />
      </ListItemButton>
    </ListItem>
  );
};

export default VirtualOption;
