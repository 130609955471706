/* @flow */
export type HTMLPolygonType = {
  left: number,
  top: number,
  width: number,
  height: number,
};

export type StyledPolygonType = {|
  id: string,
  text: string,
  ...$Exact<HTMLPolygonType>,
|};

export type ExtraPolygon = {|
  style: HTMLPolygonType,
  id: string,
  text: string,
  polygonId: ?string,
|};

export function getCurrentDimension(idx: number[], polygons: HTMLPolygonType[]): HTMLPolygonType {
  const { left, top, x2, y2 } = idx.reduce(
    (a, v) => ({
      left: Math.min(a.left, polygons[v].left),
      top: Math.min(a.top, polygons[v].top),
      x2: Math.max(a.x2, polygons[v].width + polygons[v].left),
      y2: Math.max(a.y2, polygons[v].height + polygons[v].top),
    }),
    {
      left: polygons[idx[0]].left,
      top: polygons[idx[0]].top,
      x2: polygons[idx[0]].width + polygons[idx[0]].left,
      y2: polygons[idx[0]].height + polygons[idx[0]].top,
    },
  );
  return {
    left,
    top,
    width: x2 - left,
    height: y2 - top,
  };
}

export const createEmptyDimension = () => ({ left: -1000, top: -1000, width: 0, height: 0 });

export function sort(a: StyledPolygonType, b: StyledPolygonType) {
  // this is workaround for detecting hebrew polygons and making row reverse
  // order if both compared items are hebrew
  if (a.top !== b.top) return a.top - b.top;
  const isComparingWithHebrew = /[\u0590-\u06FF]/.test(a.text) || /[\u0590-\u06FF]/.test(b.text);
  return isComparingWithHebrew ? b.left - a.left : a.left - b.left;
}

// eslint-disable-next-line max-len
export function createPolygon(polygons: $ReadOnlyArray<StyledPolygonType>, dmx: HTMLPolygonType) {
  const delimiter = ' ';
  const text = polygons
    .slice()
    // .sort(sort)
    .filter((polygon) => polygon !== undefined)
    .map((polygon) => polygon.text)
    .join(delimiter);
  return {
    style: dmx,
    text,
    id: 'MULTI_SELECT_POLYGON',
  };
}

export const rectBounding: DOMRectReadOnly = {
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  width: 0,
  height: 0,
  x: 0,
  y: 0,
};

type TBoundingEntries = {|
  shapeBounding?: DOMRectReadOnly,
  containerBounding?: DOMRectReadOnly,
  visibleContainerBounding?: DOMRectReadOnly,
|};

export const BoundingFactory = ({
  shapeBounding = rectBounding,
  containerBounding = rectBounding,
  visibleContainerBounding = rectBounding,
}: TBoundingEntries = {}) => ({
  shapeBounding,
  containerBounding,
  visibleContainerBounding,
});
