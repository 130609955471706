// @flow
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import Stack from '@mui/material/Stack';
import { NOTE_PALETTE } from 'components/LabeledThemeProvider/notesPalette';
import Box from '@mui/material/Box';
import ButtonBase from '@mui/material/ButtonBase';

import { alpha, styled } from '@mui/material/styles';
import { grey } from '@mui/material/colors';

export const ToggleButton = styled(ButtonBase, {
  label: 'InsightWidgetToggle',
  shouldForwardProp: (propName) => !['bgColor', 'borderColor'].includes(propName),
})(({ bgColor, borderColor }) => ({
  width: 36,
  height: 104,
  borderRadius: '0px 4px 4px 0px',
  border: `1px solid ${borderColor}`,
  backgroundColor: bgColor,
  borderLeft: 'none',
}));

export const NoteField = styled('textarea', { label: 'NoteWidgetTextarea' })(({ theme }) => ({
  height: '100%',
  width: '100%',
  border: `1px solid ${grey[300]}`,
  borderBottom: 'none',
  borderTopLeftRadius: 8,
  borderTopRightRadius: 8,
  resize: 'none',
  outline: 'none',
  padding: theme.spacing(1),
  fontFamily: theme.typography.fontFamily,
  '&::-webkit-scrollbar': {
    appearance: 'none',
    width: 7,
    height: 7,
  },
  '&::-webkit-scrollbar-thumb': {
    borderRadius: 3,
    backgroundColor: 'rgba(0,0,0,.5)',
    boxShadow: '0 0 1px rgba(255,255,255,.5)',
  },
}));

export const NoteBar = styled(Stack, { label: 'NoteWidgetBar' })(({ theme }) => ({
  backgroundColor: alpha(theme.palette.common.white, 0.5),
  flexDirection: 'row',
  justifyContent: 'space-between',
  border: `1px solid ${grey[300]}`,
  borderTop: 'none',
  borderBottomLeftRadius: 8,
  borderBottomRightRadius: 8,
}));

export const NotePaletteBar = styled(RadioGroup, { label: 'NoteWidgetPaletteBar' })(({ theme }) => ({
  padding: theme.spacing(0.5),
  flexDirection: 'row',
  flexWrap: 'nowrap',
  overflow: 'hidden',
  alignItems: 'center',
  gap: 4,
}));

export const NotePaletteRadio = styled(Radio, { label: 'NoteWidgetPaletteRadio' })(({ theme, value }) => ({
  border: '1px solid transparent',
  padding: 4,

  '& .MuiSvgIcon-root path': {
    stroke: NOTE_PALETTE[value].border,
  },

  '&.Mui-checked': {
    borderColor: theme.palette.primary.main,

    '& .MuiSvgIcon-root path': {
      strokeWidth: 0,
    },
  },
}));

export const Puller = styled(Box)(() => ({
  width: 30,
  height: 6,
  backgroundColor: grey[300],
  borderRadius: 3,
  position: 'absolute',
  top: 8,
  left: 'calc(50% - 15px)',
}));
