// @flow
import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { resetPasswordAction, resetPasswordErrorSelector, loadingSelector } from 'domain/env';
import { FormattedMessage, useIntl } from 'react-intl';
import { emailReg } from 'components/Form/validation';
import ROUTES_PATH from 'domain/router/routesPathConfig';
import { generatePath } from 'react-router-dom';

import Spinner from 'components/Spinner';
import AuthLayout from '../authPage/Layout';
import PasswordResetSuccess from './success';
import Typography from '@mui/material/Typography';
import AuthPaperBox from 'pages/authPage/Layout/PaperBox';
import Box from '@mui/material/Box';
import TextFieldBase from 'components/mui/Form/TextField/TextFieldBase';
import Button from '@mui/material/Button';
import RouterLink from 'components/mui/Router/RouterLink';
import BuildVersion from 'pages/authPage/Layout/BuildVersion';

import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

const mapStateToProps = (state) => ({
  invalidResetToken: resetPasswordErrorSelector(state),
  loading: loadingSelector(state),
});

// Rewritten from class component
const RecoveryPasswordPage = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const { loading, invalidResetToken } = useSelector(mapStateToProps);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [currentValue, setCurrentValue] = useState('');

  const onClickSubmit = () => {
    new Promise((resolve, reject) => {
      dispatch(resetPasswordAction({ username: currentValue, resolve, reject }));
    })
      .then(() => setIsSuccess(true))
      .catch(() => setIsError(true));
  };

  const onChange = useCallback((event: SyntheticInputEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setCurrentValue(value);
  }, []);

  const onBlur = useCallback(() => {
    const isValid = emailReg.test(currentValue);
    setIsError(!isValid);
  }, [currentValue]);

  return (
    <AuthLayout>
      {isSuccess === true && <PasswordResetSuccess />}
      {isSuccess === false && (
        <>
          <AuthPaperBox>
            <Box mb={2}>
              <Button
                variant="text"
                size="large"
                href={generatePath(ROUTES_PATH.AUTH_LOGIN.absolute)}
                component={RouterLink}
                startIcon={<KeyboardBackspaceIcon />}
              >
                <FormattedMessage id="button.back" defaultMessage="Back" />
              </Button>
            </Box>
            <Typography variant="h5">
              <FormattedMessage id="restore.title" defaultMessage="Restore Password" />
            </Typography>
            {invalidResetToken === true ? (
              <Typography variant="subtitle2" color="text.secondary" fontWeight="400" mb={3}>
                <FormattedMessage
                  id="restore.subError"
                  defaultMessage="This password reset link was expired. Please, try to reset password again"
                />
              </Typography>
            ) : (
              <Typography variant="subtitle2" color="text.secondary" fontWeight="400" mb={3}>
                <FormattedMessage
                  id="restore.subtitle"
                  defaultMessage="Please, enter your username. We will send you {br} an username to reset your password."
                />
              </Typography>
            )}

            <TextFieldBase
              id="email"
              name="email"
              type="text"
              value={currentValue}
              onChange={onChange}
              onBlur={onBlur}
              label={intl.formatMessage({
                id: 'restore.placeholder',
                defaultMessage: 'Email',
              })}
              helperText={
                isError && (
                  <FormattedMessage id="message.invalid_email_address" defaultMessage="Invalid email address" />
                )
              }
              error={isError}
              fullWidth
              required
              autoFocus
              sx={{ mb: 2 }}
            />

            <Button
              type="submit"
              variant="contained"
              size="large"
              fullWidth
              onClick={onClickSubmit}
              disabled={isError || !currentValue}
            >
              <FormattedMessage id="restore.submit" defaultMessage="Restore" />
            </Button>
          </AuthPaperBox>
          <BuildVersion />
        </>
      )}
      <Spinner loading={loading} />
    </AuthLayout>
  );
};

export default RecoveryPasswordPage;
