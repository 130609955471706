/* @flow */
import * as React from 'react';
import jss from 'jss';
import cx from 'classnames';
import get from 'lodash/get';
import { getDataCell } from '../helpers';
import type { ColumnsWidth, CSSCol, VGridProps } from './Types.js.flow';
import type { MetaType } from '../../types.js.flow';
import { withLimit, createProperyName, getColRules, setPropeyValue } from './helpers';

import { setTableColsWidth, getTableColsWidth, updateColumnsWidth } from '../../grid/withResize/helpers';
import { DEFAULT_FIELD_WIDTH, REMOVE_COLUMN_SIZE, PINNED_WIDTH, MIN_WIDTH } from './constants';
import { swapJEGridLineColumnsData } from 'domain/journal/helper';

// eslint-disable-next-line no-unused-vars
const MOVE_LEFT = 'left';
// eslint-disable-next-line no-unused-vars
const MOVE_RIGHT = 'right';
const DEFAULT_WIDTH = 170;
const MAX_WIDTH = 1500;

type ResizeProps = {|
  storeKey: string,
  getItem: (d: any) => React$Node,
  reconcileColumn: string,
|};

type Headers = {| [key: string]: string |};

type Props = {|
  ...$Exact<VGridProps>,
  ...ResizeProps,
|};

type State = {
  resizingColName: ?string,
};

function round(n: number, c: number): number {
  const d = 10 ** c;
  return Math.floor(n * d) / d;
}

function getHeaders(meta: MetaType, data: any): Headers {
  const [col, row] = meta;
  return col.reduce(
    (a, v) => ({
      ...a,
      [v]: data.getIn([`${v}${row[0]}`, 'cellSet', 'value']),
    }),
    (({}: any): Headers),
  );
}

function getDefaultWidthList(meta: MetaType, data: any): $ReadOnlyArray<?number> {
  const [col, row] = meta;
  return col.map((v) => DEFAULT_FIELD_WIDTH[data.getIn([`${v}${row[0]}`, 'cellSet', 'value'])]);
}

function arrayParams(array: $ReadOnlyArray<?number>): [number, number] {
  return array.reduce(
    (a, v) => {
      const value = v || 0;
      return [a[0] + value, a[1] + (value && 1)];
    },
    [0, 0],
  );
}

function normalizeWidth(wList: $ReadOnlyArray<?number>, width: number): $ReadOnlyArray<number> {
  const [summ, count] = arrayParams(wList);
  const def = Math.max(Math.floor((width - summ) / (wList.length - count)), MIN_WIDTH);
  return wList.map((e) => e || def);
}

function widthListToMap(wList: $ReadOnlyArray<number>, meta: MetaType): ColumnsWidth {
  return meta[0].reduce((a, v, i) => ({ ...a, [v]: wList[i] }), {});
}

function getStaticWidth(meta: MetaType, colWidth: ColumnsWidth, headers): [number, number] {
  const [col] = meta;
  return col.reduce(
    (a, v) => {
      const d = DEFAULT_FIELD_WIDTH[headers[v]];
      const c = colWidth[v];
      return [
        a[0] + ((d && c) || 0), // Ширина статических столбцов
        a[1] + (d ? 0 : c || MIN_WIDTH), // Ширина динамических столбцов
      ];
    },
    [0, 0],
  );
}

const withResize = (WrappedComponent: React$ComponentType<VGridProps>) => {
  class WithResize extends React.Component<Props, State> {
    static defaultProps = {
      getItem: () => null,
      storeKey: 'table',
    };

    constructor(props: Props) {
      super(props);
      this.sheetRule = getColRules(this.meta, props.reconcileColumn);
      this.headers = getHeaders(this.meta, props.data);
      this.state = {
        resizingColName: undefined,
      };
      const sheetJSS = jss.createStyleSheet(this.sheetRule);
      sheetJSS.attach();

      this.classes = { ...props.classes, ...sheetJSS.classes };
      this.loadStoredColsWidth();
    }

    componentDidUpdate(prevProps: Props) {
      if (prevProps.storeKey !== this.props.storeKey) {
        this.loadStoredColsWidth();
      }

      if (prevProps.meta[0].length !== this.props.meta[0].length) {
        this.setStateColumnsWidth(this.columns);
      }
      if (prevProps.data !== this.props.data) {
        this.headers = getHeaders(this.meta, this.props.data);
      }
    }

    componentWillUnmount() {
      this.clearResizeListeners();
    }

    // eslint-disable-next-line max-len
    onStart = (e: SyntheticMouseEvent<HTMLButtonElement>, resizingColName: string) => {
      this.startPosition = this.columns[resizingColName] - e.clientX;
      this.initResizeListeners();
      this.setState(
        {
          resizingColName,
        },
        () => {
          this.draw();
        },
      );
    };

    setStateColumnsWidth = (columnsWidth: ColumnsWidth) => {
      const normalizeColumnsWidth = this.normalizeColumnsWidth(columnsWidth);
      const staticColumnWidth = getStaticWidth(this.meta, normalizeColumnsWidth, this.headers);
      this.meta[0].forEach((c: string) => {
        this.setColWidth(c, normalizeColumnsWidth[c], staticColumnWidth);
      });
    };

    getTableElWidth = (): number => {
      const tableWidth = get(this.container, 'clientWidth', 0);
      return tableWidth - this.staticTablesWidth;
    };

    get staticTablesWidth() {
      const { reconcileColumn } = this.props;
      return (
        REMOVE_COLUMN_SIZE + (reconcileColumn.length && this.props.meta[0].includes(reconcileColumn) && PINNED_WIDTH)
      );
    }

    get meta(): MetaType {
      const {
        reconcileColumn,
        meta: [col, row],
      } = this.props;
      return [col.filter((f) => f !== reconcileColumn), row];
    }

    getRatioColumnsWidthToTableWidth = (columnsWidth: ColumnsWidth): number => {
      const countColumnsWidth: number = Object.values(columnsWidth)
        // $FlowFixMe
        .reduce((res, val) => res + val, 0);
      return this.getTableElWidth() / countColumnsWidth;
    };

    getCurentTableColsWidth(tableColsWidth: ColumnsWidth): ColumnsWidth {
      return this.meta[0].reduce(
        (res, colName) => ({ ...res, [colName]: get(tableColsWidth, colName, DEFAULT_WIDTH) }),
        {},
      );
    }

    setColWidth = (columnName: string, value: number, ststicColumnWidth: [number, number]) => {
      const { container } = this;
      const width = this.createRules(columnName, value, ststicColumnWidth);
      if (container) {
        setPropeyValue(container, createProperyName(columnName), width);
        this.columns[columnName] = value;
      }
    };

    getItem = (r: number, c: string) => {
      const { classes, getItem, data, reconcileColumn } = this.props;
      return (
        <>
          {data.has(`${c}${r}`) ? getItem(getDataCell(data, r, c)) : <div className={classes.empty} />}
          {c === reconcileColumn ? null : (
            <button
              onMouseDown={(e: SyntheticMouseEvent<HTMLButtonElement>) => this.onStart(e, c)}
              className={cx(classes.resize, {
                [classes.resizing]: this.state.resizingColName === c,
              })}
            />
          )}
        </>
      );
    };

    createRules = (columnName: string, value: number, staticColumnWidth: [number, number]) => {
      /* Ширина статических столбцов */
      if (this.headers[columnName] in DEFAULT_FIELD_WIDTH) {
        return `${value}px`;
      }

      // Ширина отведенная под динамические столбцы в абсолютных велечинах
      const dx = this.getTableElWidth() - staticColumnWidth[0];

      // Ширина статической части таблицы
      const staticTablePart = this.staticTablesWidth + staticColumnWidth[0];

      // Сумма ширин динамических столбцов в абсолютных велечинах
      const sumDxCol = staticColumnWidth[1];

      /**
       * Когда ширина видимой динамической обласьти
       * больше или равна сумме ширин динамических столбцов
       * Ширина области отведеннаой под динамическую часть
       * определяется как: 100% - st
       *
       * ширина конкретного динамического столбца:
       * (100% - st) * v
       * st - статическаЯ часть
       * v  - доля конкретной части в динамической области
       */
      if (dx >= sumDxCol) {
        const k = value / dx;
        return `calc(${round(k * 100, 5)}% - ${round(k * staticTablePart, 0)}px)`;
      }

      /**
       * При привышении, суммой статической и динамической,
       * общей доступно ширины, ширина динамической области
       * определяется как 100% - st + vp
       * vp - привышение ширины (брать в абсолютных или
       * относительных ширинах пока не понятно).
       * Привышение ширины (vp) найдем как разницу между
       * шириной доступной области и суммой статической и
       * динамической части sumDxCol - dx
       * Отсюда ширина столбца
       * (100% - st + vp) * v;
       * vp = sumDxCol - dx;
       * v = value / sumDxCol;
       * ((100% - st + sumDxCol - dx) * value) / dx;
       */
      const k = value / sumDxCol;
      return `calc(${round(k * 100, 5)}% - ${round(k * staticTablePart, 0)}px + ${round(k * (sumDxCol - dx), 0)}px)`;
    };

    draw = () => {
      const {
        currentPosition,
        startPosition,
        state: { resizingColName },
      } = this;
      if (resizingColName && currentPosition && startPosition) {
        const value = withLimit(currentPosition + startPosition, MAX_WIDTH, MIN_WIDTH);

        const staticColumnWidth = getStaticWidth(
          this.meta,
          {
            ...this.columns,
            [resizingColName]: value,
          },
          this.headers,
        );
        this.setColWidth(resizingColName, value, staticColumnWidth);
      }
      if (typeof startPosition === 'number') {
        window.requestAnimationFrame(this.draw);
      }
    };

    moveHandler = ({ clientX }: MouseEvent) => {
      this.currentPosition = clientX;
    };

    applyResize = () => {
      const { storeKey, gridLineColumnsDictionary } = this.props;
      this.startPosition = undefined;
      this.currentPosition = undefined;
      this.clearResizeListeners();
      const newTableColsWidth = this.normalizeColumnsWidth(this.columns);
      this.setState(
        {
          resizingColName: undefined,
        },
        () => {
          this.setStateColumnsWidth(newTableColsWidth);
          setTableColsWidth(storeKey, swapJEGridLineColumnsData(newTableColsWidth, gridLineColumnsDictionary));
        },
      );
    };

    initResizeListeners = () => {
      window.addEventListener('mousemove', this.moveHandler);
      window.addEventListener('mouseup', this.applyResize);
    };

    clearResizeListeners = () => {
      window.removeEventListener('mousemove', this.moveHandler);
      window.removeEventListener('mouseup', this.applyResize);
    };

    normalizeColumnsWidth = (columnsWidth: ColumnsWidth) => {
      const currentColumnsWidth = this.getCurentTableColsWidth(columnsWidth);
      const ratio = this.getRatioColumnsWidthToTableWidth(currentColumnsWidth);
      const updatedCurrentColumnsWidth =
        ratio >= 1
          ? updateColumnsWidth(currentColumnsWidth, (width: number): number => Math.floor(width * ratio))
          : currentColumnsWidth;
      return { ...columnsWidth, ...updatedCurrentColumnsWidth };
    };

    async loadStoredColsWidth() {
      const { storeKey, data, gridLineColumnsDictionary } = this.props;
      const storedColsWidth = await getTableColsWidth(storeKey);
      const unserializedStoredColumns = swapJEGridLineColumnsData(
        get(storedColsWidth, 'width', {}),
        gridLineColumnsDictionary,
        true,
      );
      const defaultWidth = widthListToMap(
        normalizeWidth(getDefaultWidthList(this.meta, data), this.getTableElWidth()),
        this.meta,
      );
      this.setStateColumnsWidth({
        ...this.columns,
        ...(Object.keys(unserializedStoredColumns).length > 0 ? unserializedStoredColumns : defaultWidth),
      });
    }

    refProxy = (el: ?HTMLElement) => {
      this.container = el;
    };

    sheetRule: { [key: string]: CSSCol };

    headers: Headers;

    container: ?HTMLElement;

    resizingColName: ?string;

    startPosition: ?number;

    currentPosition: ?number;

    columns: ColumnsWidth = {};

    render() {
      return (
        <WrappedComponent {...this.props} classes={this.classes} refProxy={this.refProxy} getItem={this.getItem} />
      );
    }
  }
  return WithResize;
};

export default withResize;
