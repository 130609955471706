import { styled } from '@mui/material/styles';

import DividerMui from '@mui/material/Divider';

export const DocumentContainer = styled('div', { shouldForwardProp: (propName) => propName !== 'isDrag' })(
  ({ isDrag }) => ({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    minHeight: 0,
    flexGrow: 1,
    position: 'relative',
    ...(isDrag
      ? {
          '& *': {
            pointerEvents: 'none !important',
          },
          '& .droppable': {
            pointerEvents: 'auto !important',
          },
        }
      : {}),
  }),
);

export const DocumentFooterPanel = styled('div')(({ theme }) => ({
  marginTop: 'auto',
  display: 'flex',
  alignItems: 'center',
  backgroundColor: theme.palette.common.white,
  zIndex: theme.zIndex.appBar,
  boxShadow: theme.shadows[5],
  padding: theme.spacing(1.5, 3),
}));

export const FooterDivider = styled(DividerMui)(() => ({
  height: 24,
}));

FooterDivider.defaultProps = {
  orientation: 'vertical',
};
