/* @flow */
import React, { useCallback } from 'react';
import { isDateToggleble } from 'components/Tables/helpers';
import { type RecordOf } from 'immutable';
import { debounce } from 'throttle-debounce';
import { type StringCellType } from 'domain/journal/helper';

import Tooltip from 'components/mui/Tooltip';
import IconButtonMui from '@mui/material/IconButton';

import CompareArrowsOutlinedIcon from '@mui/icons-material/CompareArrowsOutlined';

import { styled } from '@mui/material/styles';

const IconButton = styled(IconButtonMui)(({ theme }) => ({
  position: 'absolute',
  bottom: 4,
  right: 0,
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  padding: 0,
  width: 25,
  height: 18,
  borderRadius: 4,
  zIndex: 10, // input in focus - 9

  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  },
}));

type TDateToggle = {
  onClick: () => void,
  data: RecordOf<StringCellType>,
};

const DateToggle: React$StatelessFunctionalComponent<TDateToggle> = ({ onClick, data }) => {
  const isVisible =
    typeof data.cellSet.value !== 'undefined' && data.cellSet.value !== null && isDateToggleble(data.cellSet.value);

  const debounceClick = debounce(500, false, onClick);

  const handleClick = useCallback(() => debounceClick(), [debounceClick]);

  return (
    isVisible && (
      <Tooltip
        t={{
          id: 'document.journalEntry.tooltip.switchDate',
          defaultMessage: 'Switch to DD <-> MM format',
        }}
      >
        <IconButton onClick={handleClick}>
          <CompareArrowsOutlinedIcon sx={{ fontSize: '14px !important' }} />
        </IconButton>
      </Tooltip>
    )
  );
};

export default DateToggle;
