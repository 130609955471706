// @flow
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { isLinkedDocsLockedSelector, lockLinkedDocsAction } from 'domain/documents';
import * as ACL from 'domain/restriction';
import useToggle from 'hooks/useToggle';

import TooltipForButton from 'components/mui/Tooltip/TooltipForButton';
import IconButton from '@mui/material/IconButton';

import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';

type TLockButton = {
  linkID: string,
};

const LockButton: React$StatelessFunctionalComponent<TLockButton> = ({ linkID }) => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const isLocked = useSelector(isLinkedDocsLockedSelector);
  const isGranted = useSelector(ACL.isGranted);
  const [isBusy, toggleIsBusy] = useToggle();
  const isAccountUser = isGranted(ACL.IS_ACCOUNT);
  const isSupplier = isGranted(ACL.IS_SUPPLIER_USER);
  const isDisabled = !isAccountUser || isBusy;
  const isDisabledTooltip = isAccountUser || isSupplier || !isLocked;

  const onToggleLock = () => {
    if (isBusy) return;

    toggleIsBusy();

    new Promise((resolve, reject) => {
      dispatch(
        lockLinkedDocsAction({
          linkID,
          locked: !isLocked,
          resolve,
          reject,
        }),
      );
    }).finally(() => {
      toggleIsBusy();
    });
  };

  return (
    <TooltipForButton
      t={formatMessage({
        id: 'documents.linkedPanel.tooltip',
        defaultMessage: 'Please, contact your accountant for unlocking',
      })}
      disabled={isDisabledTooltip}
    >
      <IconButton color="primary" onClick={onToggleLock} disabled={isDisabled}>
        {isLocked ? <LockOutlinedIcon /> : <LockOpenOutlinedIcon />}
      </IconButton>
    </TooltipForButton>
  );
};

export default LockButton;
