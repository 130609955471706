// @flow
import { makeStyles } from '@mui/styles';
import { alpha } from '@mui/material';

export default makeStyles((theme) => ({
  logoContainer: {
    position: 'relative',
    cursor: 'pointer',
    '&:hover, &:active, &:focus': {
      '&::after': {
        content: '""',
        position: 'absolute',
        width: '100%',
        height: '100%',
        backgroundColor: alpha(theme.palette.primary.main, 0.5),
        borderRadius: 4,
      },
      '& $icon': {
        display: 'block',
      },
    },
  },
  icon: {
    display: 'none',
    position: 'absolute',
    color: '#FFF',
    zIndex: 2,
  },
}));
