// @flow
import { useCallback, useEffect, useRef, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { List } from 'immutable';
import { setApprovalStampModeAction, getUiAFlStampModeSelector, setApprovalStampAction } from 'domain/ui';
import { UiAFStampFactory } from 'domain/ui/helpers';
import {
  STAMP_SCALE_DEFAULT_COEFFICIENT,
  STAMP_SHIFT_X,
  STAMP_SHIFT_Y,
  STAMP_SVG_HEIGHT,
  STAMP_SVG_WIDTH,
} from 'pages/document/page/components/ApprovalStampManagement/helpers';

import type {
  TApprovalStampManagement,
  TDimensions,
} from 'pages/document/page/components/ApprovalStampManagement/types.js.flow';

export const useStampManagement = ({ currentDimensions, originalDimensions, rotation }: TApprovalStampManagement) => {
  const dispatch = useDispatch();
  const stampMode = useSelector(getUiAFlStampModeSelector);
  const stampRef: {| current: HTMLDivElement | null |} = useRef();
  const coordinatesRef: {| current: { iX: number, iY: number, oX: number, oY: number } | null |} = useRef({
    iX: 0, // initial mouse position on mouse down
    iY: 0, // initial mouse position on mouse down
    oX: 0, // origin position of stamp left top corner
    oY: 0, // origin position of stamp left top corner
  });
  const [isDragging, setIsDragging] = useState(false);
  const [coordinates, setCoordinates] = useState({ x: 0, y: 0 });
  const [stampDimensions, setStampDimensions] = useState({ width: 0, height: 0 });

  const round2Decimal = (number: number, places: number = 1) => {
    const inc = 10 ** places;
    return Math.round((number + Number.EPSILON) * inc) / inc;
  };

  const getStampDimension = (pageDimension: TDimensions) => {
    const { height: pageH } = pageDimension;
    const ratio = STAMP_SVG_WIDTH / STAMP_SVG_HEIGHT; // aspect ratio
    const height = pageH / STAMP_SCALE_DEFAULT_COEFFICIENT;
    const width = height * ratio;

    return { width, height };
  };

  // coefficient for convert coordinates
  const convertFactor = useMemo(() => {
    const { width: cWidth, height: cHeight } = currentDimensions;
    const { width: oWidth, height: oHeight } = originalDimensions;

    return (cWidth + cHeight) / (oWidth + oHeight);
  }, [originalDimensions, currentDimensions]);

  // convert point coordinates from current to original page size
  // toCurrent - convert point coordinates from origin dimension to current
  const convertPoint = useCallback(
    ([x, y], toCurrent: boolean = false) =>
      toCurrent
        ? [round2Decimal(x * convertFactor), round2Decimal(y * convertFactor)]
        : [round2Decimal(x / convertFactor), round2Decimal(y / convertFactor)],
    [convertFactor],
  );

  const defaultCoordinates = useMemo(() => {
    const { height: stampH, width: stampW } = stampDimensions;
    const { height: pageH, width: pageW } = currentDimensions;

    return { x: pageW - stampW - STAMP_SHIFT_X, y: pageH - stampH - STAMP_SHIFT_Y };
  }, [stampDimensions, currentDimensions]);

  const setStampRef = useCallback((node: HTMLDivElement) => {
    stampRef.current = node;
  }, []);

  const adjustCoordinatesLimit = useCallback(
    ({ x, y }) => {
      const { height: stampH, width: stampW } = stampDimensions;
      const { height: pageH, width: pageW } = currentDimensions;
      const adjustedCoordinates = { x, y };
      const minX = 0; // min x - left
      const maxX = pageW - stampW; // max x - right
      const minY = 0; // min y - top
      const maxY = pageH - stampH; // max y - bottom
      if (x < minX) {
        adjustedCoordinates.x = minX;
      } else if (x > maxX) {
        adjustedCoordinates.x = maxX;
      }

      if (y < minY) {
        adjustedCoordinates.y = minY;
      } else if (y > maxY) {
        adjustedCoordinates.y = maxY;
      }

      return adjustedCoordinates;
    },
    [currentDimensions, stampDimensions],
  );

  const generateStampData = useCallback(() => {
    const { width: stampW, height: stampH } = stampDimensions;
    const { height: pageH } = currentDimensions;
    // stamp point of top/left corner for current page dimension
    const { x, y } = coordinates;

    // b-end waiting stamp coordinates from bottom left corner
    // for b-end { x: 0, y: 0 } - its bottom left corner
    // for f-end { x: 0, y: 0 } - its top left corner
    // correct Y axis for b-end and convert coordinates to original page dimension
    const ll = List(convertPoint([x, pageH - y - stampH])); // b-end bottom/left stamp point from bottom/left system coordinates
    const ur = List(convertPoint([x + stampW, pageH - y])); // top/right

    return UiAFStampFactory({ ur, ll, rotation });
  }, [convertPoint, currentDimensions, stampDimensions, coordinates, rotation]);

  const dispatchStampData = useCallback(() => {
    const stampData = generateStampData();

    dispatch(setApprovalStampAction(stampData));
  }, [dispatch, generateStampData]);

  const handleMouseDown = useCallback(
    (e: SyntheticMouseEvent<HTMLDivElement>) => {
      e.preventDefault();
      e.stopPropagation();
      if (!isDragging && stampMode === 'edit') {
        const { clientX, clientY } = e;
        const { x, y } = coordinates;

        // remember initial mouse coordinates for calculate moving distance
        coordinatesRef.current.iX = clientX;
        coordinatesRef.current.iY = clientY;
        // remember original coordinates for stamp position
        coordinatesRef.current.oX = x;
        coordinatesRef.current.oY = y;

        setIsDragging(true);
        dispatch(setApprovalStampModeAction('move'));
      }
    },
    [coordinates, dispatch, isDragging, stampMode],
  );

  const handleMouseUp = useCallback(() => {
    if (isDragging) {
      const { x, y } = coordinates;

      coordinatesRef.current.oX = x;
      coordinatesRef.current.oY = y;
      setIsDragging(false);
      dispatch(setApprovalStampModeAction('edit'));
      dispatchStampData();
    }
  }, [coordinates, dispatch, isDragging, dispatchStampData]);

  const handleMouseMove = useCallback(
    (e: SyntheticMouseEvent<HTMLDivElement>) => {
      if (isDragging) {
        // mXY - mouse move coordinates
        const { clientX: moveX, clientY: moveY } = e;
        // iXY - init mouse position, oXY - origin coordinates for left/top corner
        const { iX, iY, oX, oY } = coordinatesRef.current;
        // delta - distance between start position iXY and move position moveXY
        const deltaX = moveX - iX;
        const deltaY = moveY - iY;
        const x = oX + deltaX;
        const y = oY + deltaY;

        // final coordinates
        const adjustedCoordinates = adjustCoordinatesLimit({ x, y });

        setCoordinates(adjustedCoordinates);
      }
    },
    [isDragging, adjustCoordinatesLimit],
  );

  // recalculate stamp dimensions according current page dimensions
  useEffect(() => {
    const dimensions = getStampDimension(currentDimensions);

    setStampDimensions(dimensions);
  }, [currentDimensions]);

  // when changing page rotation or sizes - reset stamp coordinates to default position
  useEffect(() => {
    if (stampDimensions.width !== 0 && stampDimensions.height !== 0) setCoordinates(defaultCoordinates);
  }, [defaultCoordinates, stampDimensions]);

  // dispatch default coordinates
  useEffect(() => {
    if (stampMode !== 'move') {
      dispatchStampData();
    }
  }, [dispatchStampData, stampMode]);

  return {
    setStampRef,
    handleMouseDown,
    handleMouseUp,
    handleMouseMove,
    coordinates,
    stampDimensions,
    stampMode,
  };
};
