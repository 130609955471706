// @flow
import { syncAction, asyncAction } from 'lib/action';

export const addTransactionLineAction = asyncAction('RECONCILIATION/ADD_TRANSACTION_LINE');
export const removeTransactionLineAction = asyncAction('RECONCILIATION/REMOVE_TRANSACTION_LINE');

export const getSearchJournalEntryAction = asyncAction('RECONCILIATION/GET_SEARCH');
export const updateSearchParamsAction = asyncAction('RECONCILIATION/UPDATE_SEARCH_PARAMS');
export const showRowDetailsAction = syncAction('RECONCILIATION/SHOW_ROW_DETAILS');
export const hideRowDetailsAction = syncAction('RECONCILIATION/HIDE_ROW_DETAILS');
export const reconcileTransactionAction = asyncAction('RECONCILIATION/RECONCILE_TRANSACTION');
export const undoTransactionAction = asyncAction('RECONCILIATION/UNDO_TRANSACTION');

// transaction request
export const showReconciliationRequestDetailsAction = syncAction('RECONCILIATION/SHOW_RECONCILIATION_REQUEST_DETAILS');
export const hideReconciliationRequestDetailsAction = syncAction('RECONCILIATION/HIDE_RECONCILIATION_REQUEST_DETAILS');
export const createTransactionRequestAction = asyncAction('RECONCILIATION/CREATE_TRANSACTION_REQUEST');
export const updateTransactionRequestAction = asyncAction('RECONCILIATION/UPDATE_TRANSACTION_REQUEST');

export const requestPopupChangeStatusAction = syncAction('RECONCILIATION/REQUEST_POPUP_CHANGE_STATUS');
