export default (theme) => ({
  documentWrapper: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    top: 0,
    userSelect: 'none',
  },

  list: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    top: 0,
    zIndex: 2,
    userSelect: 'none',
  },

  selecting: {
    '& $item': {
      pointerEvents: 'none',
    },
  },

  item: {
    position: 'absolute',
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: 2,
    cursor: 'move',
    boxSizing: 'content-box',
    pointerEvents: 'all',
    opacity: 0,
    transition: '0.2s opacity',
    willChange: 'opacity',

    '&:hover': {
      opacity: 1,
    },
  },

  dragItem: {
    opacity: 1,
  },

  selectionArea: {
    opacity: 1,
    borderStyle: 'dashed',
  },
});
