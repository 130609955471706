import { styled } from '@mui/material/styles';

export const PageArea = styled('div', { shouldForwardProp: (propName) => propName !== 'paid' })(({ theme, paid }) => ({
  position: 'relative',
  cursor: '-webkit-grab',
  margin: theme.spacing(0, 'auto'),
  overflow: 'hidden',
  border: `2px solid ${theme.palette.grey[200]}`,
  borderRadius: 8,

  ...(paid && {
    borderColor: theme.palette.secondary.main,
    boxShadow: `0 0 0 2px inset ${theme.palette.secondary.main}`,
  }),
}));
