import { asyncAction, syncAction } from 'lib/action';

export const getCategory = asyncAction('settings/GET_CATEGORY');
export const getSSOParams = asyncAction('settings/GET_SSO_PARAMS');
export const updateConnectionsTable = asyncAction('settings/UPDATE_CONNECTIONS_TABLE');
export const disconnectErp = asyncAction('settings/DISCONNECT_ERP');
export const getCurrentERPCompaniesAction = asyncAction('settings/GET_CURRENT_ERP_COMPANIES');
export const getCurrentERPSettings = asyncAction('settings/GET_CURRENT_ERP_SETTINGS');
export const setCurrentERPSettings = asyncAction('settings/SET_CURRENT_ERP_SETTINGS');
export const activateErpAction = asyncAction('settings/ACTIVATE_ERP');
export const importDataAction = asyncAction('settings/IMPORT_DATA');
export const updateVendorDefaultsAction = asyncAction('settings/UPDATE_VENDOR_DEFAULTS');
export const importVendorPreferencesAction = asyncAction('settings/UPDATE_VENDOR_PREFERENCES');
export const clearCategoryAction = asyncAction('settings/CLEAR_CATEGORY');
export const getFTPConnectionAction = asyncAction('settings/GET_ERP_FTP_CONNECTION');
export const saveFTPConnectionAction = asyncAction('settings/SAVE_ERP_FTP_CONNECTION');
export const syncErpIndexesAction = asyncAction('settings/SYNC_ERP_INDEXES');

export const setErpsAction = syncAction('settings/SET_CONNECTIONS');
