import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { alpha } from '@mui/material';

// styles copy-paste from previous implementation, its just rework to mui approach
export const JELayoutPane = styled(Box, { label: 'JELayoutPane' })(({ theme }) => ({
  display: 'flex',
  position: 'relative',
  // this block height breaks doc/je splitter height
  overflowY: 'auto', // DA-530
  flexDirection: 'column',
  flex: 1,
  backgroundColor: alpha(theme.palette.primary.main, 0.04),
}));
