/* @flow */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { shouldProcessingDocBeBlockedSelector, documentStopProcessingAction } from 'domain/documents';
import MuiSkeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import {
  NestedSkeleton,
  MessageWrapper,
  JEProcessingOverlayWrapper,
} from 'components/Tables/JEProcessingOverlay/StyledComponents';

const Skeleton = MuiSkeleton;

Skeleton.defaultProps = {
  animation: 'wave',
};

const RowItem = () => (
  <Stack flex={1}>
    <NestedSkeleton width="50%" />
    <Skeleton height="80%" />
  </Stack>
);
const Row = () => (
  <Stack direction="row" width="100%" flexWrap="nowrap" flex={1} spacing={2}>
    {Array.from(Array(4).keys()).map((i) => (
      <RowItem key={i} />
    ))}
  </Stack>
);

const ColumnItem = () => (
  <Stack flex={1} position="relative">
    <Skeleton width="100%" height="100%" sx={{ p: 1 }}>
      <Stack height="100%" width="100%">
        <NestedSkeleton />
      </Stack>
    </Skeleton>
  </Stack>
);

const JEProcessingOverlay = () => {
  const dispatch = useDispatch();
  const isVisible = useSelector(shouldProcessingDocBeBlockedSelector);

  const onClick = () => dispatch(documentStopProcessingAction());

  return (
    isVisible && (
      <JEProcessingOverlayWrapper>
        <MessageWrapper elevation={3}>
          <Typography variant="h6" sx={{ mb: 1.5 }}>
            <FormattedMessage
              id="document.show.journalEntry.documentViewDisabled.reason.header"
              defaultMessage="This document is still being processed."
            />
          </Typography>
          <Typography variant="body1" sx={{ mb: 4 }}>
            <FormattedMessage
              id="document.show.journalEntry.documentViewDisabled.reason.description"
              defaultMessage="Click this button ONLY if you want to stop the auto-processing and manually enter the data."
            />
          </Typography>
          <Button color="error" variant="outlined" onClick={onClick} sx={{ my: 'auto' }}>
            <FormattedMessage
              id="document.show.journalEntry.documentViewDisabled.action"
              defaultMessage="Stop Processing"
            />
          </Button>
        </MessageWrapper>
        <Stack flex={1}>
          {Array.from(Array(4).keys()).map((i) => (
            <Row key={i} />
          ))}
        </Stack>
        <Stack direction="row" flex={1} spacing={2}>
          {Array.from(Array(4).keys()).map((i) => (
            <ColumnItem key={i} />
          ))}
        </Stack>
        <Stack flex={0.5} sx={{ position: 'relative' }}>
          <Skeleton width="100%" height="100%">
            <Stack visibility="visible" width="50%" height="100%" sx={{ ml: 'auto', p: 1 }}>
              {Array.from(Array(3).keys()).map((i) => (
                <NestedSkeleton key={i} width="100%" />
              ))}
            </Stack>
          </Skeleton>
        </Stack>
      </JEProcessingOverlayWrapper>
    )
  );
};

export default JEProcessingOverlay;
