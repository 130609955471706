import { styled } from '@mui/material/styles';

export const UploadText = styled('span')(({ theme }) => ({
  color: theme.palette.primary.main,
  textDecoration: 'underline',
}));

export const FileInput = styled('input')(() => ({
  width: '100%',
  height: '100%',
  position: 'absolute',
  left: 0,
  top: 0,
}));
