import ModalMui from '@mui/material/Modal';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';

export const Modal = styled(ModalMui, { label: 'DialogWebTwainImagePreview' })(() => ({
  outline: 'none',
  display: 'flex',
  alignItems: 'center',
  height: '100vh',
}));

export const ModalCloseButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  right: theme.spacing(-6),
  top: theme.spacing(-1),
  color: theme.palette.common.white,
}));

export const PreviewContent = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
  boxSizing: 'content-box',
  margin: '0 auto',
  borderWidth: 6,
  borderStyle: 'solid',
  borderColor: 'transparent',
  '&:focus': {
    outline: 'none',
  },
}));
