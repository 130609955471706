// @flow
import React from 'react';

import AvatarMui, { type AvatarProps } from '@mui/material/Avatar';
import CorporateFareOutlinedIcon from '@mui/icons-material/CorporateFareOutlined';

import { styled } from '@mui/material/styles';

function stringToColor(string: string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

function stringAvatar(name: string) {
  if (typeof name === 'string') {
    const splitName = name.split(' ').filter(Boolean);

    if (splitName.length > 0) {
      const letters = splitName.length === 1 ? splitName[0][0] : splitName[0][0] + splitName[0][1];

      return {
        children: letters.toUpperCase(),
        backgroundColor: stringToColor(name),
      };
    }
  }

  return {
    children: <CorporateFareOutlinedIcon />,
  };
}

type TAvatarLetter = {
  name: string,
  src?: string,
} & AvatarProps;

const Avatar = styled(AvatarMui, { shouldForwardProp: (propName) => propName !== 'backgroundColor' })(
  ({ backgroundColor }) => ({
    backgroundColor,
  }),
);

const AvatarLetter: React$StatelessFunctionalComponent<TAvatarLetter> = ({ name, src }) => (
  <Avatar variant="rounded" imgProps={{ sx: { objectFit: 'contain' } }} {...(src ? { src } : stringAvatar(name))} />
);

export default AvatarLetter;
