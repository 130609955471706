import React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Checkbox from '@mui/material/Checkbox';
import Divider from '@mui/material/Divider';

const CheckboxSetBase = ({
  items,
  checked,
  onChange,
  ListProps = {},
  ListItemProps = {},
  CheckboxProps = {},
  withDivider = false,
}) => (
  <List {...ListProps}>
    {items.map(({ label, value }) => {
      const labelId = `list-item-${value}-${label}`;

      return (
        <ListItem
          {...ListItemProps}
          key={value}
          role="listitem"
          button
          onClick={(e) => {
            onChange(e, value);
          }}
        >
          <ListItemIcon sx={withDivider ? { minWidth: 0 } : {}}>
            <Checkbox
              {...CheckboxProps}
              checked={checked.indexOf(value) !== -1}
              tabIndex={-1}
              disableRipple
              inputProps={{
                'aria-labelledby': labelId,
              }}
            />
          </ListItemIcon>
          {withDivider && <Divider orientation="vertical" sx={{ height: 24, mr: 1 }} />}
          <ListItemText id={labelId} {...(label.primary ? label : { primary: label })} />
        </ListItem>
      );
    })}
    <ListItem />
  </List>
);

export default CheckboxSetBase;
