// @flow
import * as React from 'react';
import { compose } from 'redux';
import { Document, Page } from 'react-pdf';
import { setViewportParams } from 'lib/domHelpers';

import { generateNumRange } from 'lib/helpers';
import Spinner from 'components/mui/CircularProgressWithBackdrop';

import usePreloadDocumentsMobile from './usePreloadDocumentsMobile';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';

const DocumentWrapper = styled('div')(() => ({
  overflow: 'auto',
  position: 'relative',
  '& :nth-child(1)': {
    marginTop: 0,
  },

  '& .react-pdf__Page__canvas, & .react-pdf__Page__textContent': {
    width: '100% !important',
    height: 'auto !important',
  },
}));
const PageWrapper = styled(Card)(({ theme }) => ({ marginTop: theme.spacing(1) }));

type TWithPreload = {
  prevDocId: string,
  nextDocId: string,
  currentDocumentId: string,
  signatureStatus: 'disabled' | 'show' | 'hide',
};

type State = {
  numPages: number,
  width: number,
};

const withPreloadDocument =
  (Component) =>
  ({ nextDocId, prevDocId, signatureStatus, currentDocumentId, ...rest }: TWithPreload) => {
    const { blob } = usePreloadDocumentsMobile({ currentDocumentId, nextDocId, prevDocId, signatureStatus });
    return <Component cachedDocument={blob} {...rest} />;
  };

class MobileDocument extends React.Component<Props, State> {
  state = {
    numPages: null,
  };

  componentDidMount(): * {
    setViewportParams({ 'maximum-scale': 3 });
  }

  componentWillUnmount(): * {
    setViewportParams({ 'maximum-scale': 1 });
  }

  onLoadSuccess = ({ numPages }: any) => {
    this.setState({ numPages });
  };

  render() {
    const { cachedDocument } = this.props;
    const { numPages } = this.state;
    const pageRange = numPages ? generateNumRange(1, numPages) : [];

    return (
      <>
        {!cachedDocument ? null : (
          <Document
            file={cachedDocument}
            onLoadSuccess={this.onLoadSuccess}
            loading={
              <Stack position="relative">
                <Spinner BackdropProps={{ sx: { position: 'absolute' } }} />
              </Stack>
            }
          >
            <DocumentWrapper>
              {pageRange.map((pageNum) => (
                <PageWrapper key={`${pageNum}`} variant="outlined">
                  <Page key={`${pageNum}`} pageNumber={pageNum} scale={1} />
                </PageWrapper>
              ))}
            </DocumentWrapper>
          </Document>
        )}
      </>
    );
  }
}
export default compose(withPreloadDocument)(MobileDocument);
