// @flow
import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { isStatementSelector } from 'domain/journal/selectors';
import { localeSelector } from 'domain/env';
import { currentCompanySelector, documentDocumentIdSelector, documentDoctypeSelector } from 'domain/documents';
import { forceErpSyncAction } from 'domain/journal';
import { isErpConnectedSelector } from 'domain/settings';

import TooltipForButton from 'components/mui/Tooltip/TooltipForButton';
import IconButtonMui from '@mui/material/IconButton';

import BacksyncIcon from '@mui/icons-material/Loop';
import useToggle from 'hooks/useToggle';

import { styled } from '@mui/material';

const IconButton = styled(IconButtonMui, { shouldForwardProp: (propName) => propName !== 'isBusy' })(({ isBusy }) => ({
  '@keyframes rotate': {
    from: {
      transform: 'rotate(360deg)',
    },
    to: {
      transform: 'rotate(0deg)',
    },
  },
  '&[disabled]': {
    animation: isBusy ? 'rotate 0.8s infinite linear' : false,
  },
}));

const mapStateToProps = (state) => ({
  isStatement: isStatementSelector(state),
  locale: localeSelector(state),
  companyId: currentCompanySelector(state),
  isErpConnected: isErpConnectedSelector(state),
  documentID: documentDocumentIdSelector(state),
  doctype: documentDoctypeSelector(state),
});
const IconButtonForceSync = () => {
  const dispatch = useDispatch();
  const { isErpConnected, documentID, doctype } = useSelector(mapStateToProps);
  const [isBusy, toggleBusy] = useToggle();

  const isDisabled = !isErpConnected || isBusy || doctype === 'general';

  const handleClick = useCallback(() => {
    toggleBusy();

    new Promise((resolve, reject) =>
      dispatch(forceErpSyncAction({ documentID, reindexCache: true, resolve, reject })),
    ).finally(() => toggleBusy());
  }, [dispatch, documentID, toggleBusy]);

  return (
    <TooltipForButton t={{ id: 'header.menu.sync', defaultMessage: 'Force ERP Synchronization' }}>
      <IconButton color="primary" onClick={handleClick} disabled={isDisabled} isBusy={isBusy}>
        <BacksyncIcon />
      </IconButton>
    </TooltipForButton>
  );
};

export default IconButtonForceSync;
