/* @flow */
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import LayoutServicePage from 'components/mui/Layouts/LayoutServicePage';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import { styled, useTheme } from '@mui/material/styles';
import bgImage from './assets/bgImage.svg';

const Img = styled('img')(({ theme }) => ({
  marginBottom: theme.spacing(4),
}));

// TODO: with a probability of 99% this functionality is not used, need approve for deleting
const ExpiredPage = () => {
  const { labelOptions } = useTheme();

  return (
    <LayoutServicePage>
      <Stack flex={1} component="main" justifyContent="center" alignItems="center">
        <Img src={bgImage} alt="background image" />
        <Typography variant="h5" sx={{ mb: 1 }}>
          <FormattedMessage id="expiredPage.yourTrialHasExpired" defaultMessage="Your trial has expired!" />
        </Typography>
        <Typography variant="body1" color="text.secondary" maxWidth={335} sx={{ mb: 3, textAlign: 'center' }}>
          <FormattedMessage
            id="expiredPage.description"
            defaultMessage="Please, contact our support team for details or go back to LogIn."
          />
        </Typography>
        <Stack direction="row" justifyContent="center" spacing={3}>
          <Button component={Link} color="primary" to="/login">
            <FormattedMessage id="expiredPage.backToLogIn" defaultMessage="Back to LogIn" />
          </Button>
          <Button component="a" color="primary" variant="outlined" href={`mailto:${labelOptions.supportEmail}`}>
            <FormattedMessage id="expiredPage.contactUs" defaultMessage="Contact Us" />
          </Button>
        </Stack>
      </Stack>
    </LayoutServicePage>
  );
};

export default ExpiredPage;
