import React from 'react';
import { useSelector } from 'react-redux';
import * as ACL from 'domain/restriction';

import Route404 from 'pages/servicePage/page404';

const mapStateToProps = (state) => ({
  restrictions: ACL.myRestriction(state),
});

// eslint-disable-next-line react/prop-types
const RestrictedRoute = ({ children, restriction }) => {
  const { restrictions } = useSelector(mapStateToProps);
  const ACLFilterFN = ACL.arrayFilter(restrictions);
  const isAvailable = ACLFilterFN({ restriction });

  // TODO: NEED RECHECK
  if (!isAvailable) return <Route404 />;

  return children;
};

export default RestrictedRoute;
