/* @flow */
import * as React from 'react';
import get from 'lodash/get';
import makeCancelable, { type PromiseCanceleable } from 'lib/makeCancelable';
import financialImg from './financialFallback.svg';
import generalImg from './generalFallback.svg';

export const FALBACK = {
  financial: financialImg,
  general: generalImg,
};

type Props = {
  src: string,
  alt: string,
  initialImage: string,
  className: string,
  getStyle: (el: HTMLImageElement) => Object,
  onLoad?: (el: HTMLImageElement) => void,
  type?: 'financial' | 'general',
};

type State = {
  isLoaded: boolean,
  style: ?Object,
};

function promisify(img: HTMLImageElement) {
  return new Promise((resolve, reject) => {
    // eslint-disable-next-line no-param-reassign
    img.onload = resolve;
    // eslint-disable-next-line no-param-reassign
    img.onerror = reject;
  });
}

class ImageFallback extends React.Component<Props, State> {
  static defaultProps = {
    getStyle: () => ({}),
    initialImage: generalImg,
  };

  state = {
    isLoaded: false,
    style: null,
  };

  componentDidMount() {
    const image = document.createElement('img');
    this.cancelable = makeCancelable(promisify(image));
    this.cancelable.promise.then(this.handleLoad).catch(() => {});
    image.src = this.props.src;
  }

  componentWillUnmount() {
    this.cancelable.cancel();
  }

  get falback() {
    return get(FALBACK, this.props.type, this.props.initialImage);
  }

  getSrc = () => (this.state.isLoaded ? this.props.src : this.falback);

  handleLoad = (e: SyntheticCompositionEvent<HTMLImageElement>) => {
    const { onLoad, getStyle } = this.props;
    this.setState(
      {
        style: getStyle(e.currentTarget),
        isLoaded: true,
      },
      () => {
        if (typeof onLoad === 'function') {
          onLoad(e.currentTarget);
        }
      },
    );
  };

  cancelable: PromiseCanceleable<SyntheticCompositionEvent<HTMLImageElement>>;

  render() {
    return <img style={this.state.style} src={this.getSrc()} alt={this.props.alt} className={this.props.className} />;
  }
}

export default ImageFallback;
