/* @flow */
import { makeStyles } from '@mui/styles';

export default (theme) => ({
  list: {
    position: 'fixed',
    marginTop: 3,
    zIndex: 99,
    overflowY: 'auto',
  },
  optionTextRuler: {
    padding: theme.spacing(0, 3),
    height: 0,
    position: 'absolute',
    top: 0,
    left: 0,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },

  optionText: {
    flex: 1,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    unicodeBidi: 'plaintext',
  },
});

export const useHintStyle = makeStyles((theme) => ({
  hintBox: {
    position: 'fixed',
    zIndex: 9999,
    maxWidth: 400,
    pointerEvents: 'none',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },

  hintText: {
    width: '100%',
    height: '100%',
    zIndex: 1,
    color: theme.palette.common.white,
    fontSize: 11,
    backgroundColor: theme.palette.grey[800],
    padding: 8,
    wordBreak: 'break-word',
    borderRadius: 4,
  },

  arrow: {
    position: 'absolute',
    left: 'calc( 50% - 10px )',
    bottom: '-4px',
    transform: 'rotate(45deg)',
    border: `10px solid ${theme.palette.grey[800]}`,
  },
}));
