/* eslint-disable camelcase */
// @flow
import React, { useEffect, useCallback } from 'react';
import { Field, reduxForm, formValueSelector } from 'redux-form/lib/immutable';
import { change } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';
import { compose } from 'redux';
import { useIntl } from 'react-intl';

// mui
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

// components
import SelectForm from 'components/mui/Form/Select/SelectForm';

import usePrevious from 'hooks/usePrevious';

import type { DeviceRecordList } from 'domain/devices/types.js.flow';

type Props = {
  guid: string,
  guidFormValue: string,
  handleSubmit: (data: { guid: string }) => void,
  devices: DeviceRecordList,
};

const mapStateToProps = (state) => ({
  guidFormValue: formValueSelector('guidFeatureForm')(state, 'guid'),
});

const Guid: React$StatelessFunctionalComponent<Props> = ({ guid, devices, handleSubmit }) => {
  const dispatch = useDispatch();
  const { guidFormValue } = useSelector(mapStateToProps);
  const { formatMessage } = useIntl();
  const prevGuid = usePrevious(guid);

  const getOptions = useCallback(
    () => devices.map(({ displayGuid }) => ({ value: displayGuid, label: displayGuid })),
    [devices],
  );

  useEffect(() => {
    if (prevGuid !== 'undefined' && prevGuid !== guid) {
      dispatch(change('guidFeatureForm', 'guid', guid));
    }
  }, [dispatch, guid, prevGuid]);

  return (
    <Stack
      component="form"
      onSubmit={handleSubmit}
      mt={2}
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
    >
      <Field
        component={SelectForm}
        options={getOptions()}
        label={formatMessage({
          id: 'forms.organization.features.sign.guid.label',
          defaultMessage: 'Enter GUID code',
        })}
        name="guid"
        type="text"
        props={{ sx: { width: '28rem', mr: 2 } }}
        id="guid"
      />
      <Button disabled={guidFormValue === guid} variant="contained" type="submit">
        {formatMessage({
          id: 'forms.organization.features.sign.guid.btn',
          defaultMessage: 'Save',
        })}
      </Button>
    </Stack>
  );
};

export default compose(reduxForm({ form: 'guidFeatureForm' }))(Guid);
