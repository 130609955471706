/* @flow */
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { configurationCompanySelector } from 'domain/companies';
import { useParams, Outlet, generatePath } from 'react-router-dom';
import ROUTES_PATH from 'domain/router/routesPathConfig';

import Tabs, { requiredTab } from 'components/Tabs';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ScoreOutlinedIcon from '@mui/icons-material/ScoreOutlined';
import FeaturedVideoOutlinedIcon from '@mui/icons-material/FeaturedVideoOutlined';

const ConfigurationsInfo = () => {
  const company = useSelector(configurationCompanySelector);
  const { '*': asterisk, companyId } = useParams();
  const [, section] = asterisk.split('/');

  const tabs = useMemo(
    () => [
      {
        to: generatePath(ROUTES_PATH.COMPANY_SETTINGS_GENERAL_MAIN.absolute, { companyId }),
        id: 'configurations.info.tabs.main',
        defaultMessage: 'Main info',
        required: requiredTab(company, ['name', 'picture', 'city', 'country', 'street']),
        tabValue: 'main',
        Icon: InfoOutlinedIcon,
      },
      {
        to: generatePath(ROUTES_PATH.COMPANY_SETTINGS_GENERAL_FINANCIAL.absolute, { companyId }),
        id: 'configurations.info.tabs.financial',
        defaultMessage: 'Financial info',
        required: requiredTab(company, ['currency', 'vat_number', 'registration_number']),
        tabValue: 'financial',
        Icon: ScoreOutlinedIcon,
      },
      {
        to: generatePath(ROUTES_PATH.COMPANY_SETTINGS_GENERAL_FEATURES.absolute, { companyId }),
        id: 'configurations.info.tabs.features',
        defaultMessage: 'Features',
        tabValue: 'features',
        Icon: FeaturedVideoOutlinedIcon,
      },
    ],
    [company, companyId],
  );

  return (
    <Stack alignItems="flex-start" direction="row">
      <Paper variant="outlined" sx={{ width: 270, border: 2, mr: 5, p: 1, pr: 0, borderColor: 'grey.200' }}>
        <Tabs value={section} tabs={tabs} orientation="vertical" textAlign="flex-end" />
      </Paper>
      <Stack component={Paper} flex={1} sx={{ p: 3 }}>
        {company.id ? <Outlet /> : null}
      </Stack>
    </Stack>
  );
};

export default ConfigurationsInfo;
