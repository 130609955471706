// @flow
import { selector } from 'lib/selectors';
import { complitedTodoFilter as completedFilter } from 'domain/dashboard/helpers';

const dashboard = (state) => state.dashboard;
export const isDisablingFinAllowedSelector = selector(dashboard, (d) => d.processing === 0 && d.total.fin === 0);

const favorites = (state) => state.favorites;
export const favoritesListSelector = selector(favorites, (f) =>
  f.sort((a, b) => a.type === 'saved' || a.label.localeCompare(b.label)).toList(),
);

const todos = (state) => state.todos;
export const completedTodosSelector = selector(todos, (t) => t.toList().filter(completedFilter));
