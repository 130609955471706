// @flow

export default {
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    marginBottom: 5,
    paddingBottom: 14,
    color: '#171A1C',
    fontSize: '16px',
  },
  left: {
    flex: '0 0 100%',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  value: {
    padding: [0, 12],
    position: 'relative',
    '&:before': {
      content: '"$"',
      display: 'block',
      position: 'absolute',
      left: 3,
      top: 0,
    },
  },
  date: {},
  description: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
};
