import React from 'react';
import { Field, reduxForm } from 'redux-form/immutable';
import { compose } from 'redux';
import { useIntl } from 'react-intl';

import TextField from 'components/mui/Form/TextField/TextFieldForm';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import InputAdornment from '@mui/material/InputAdornment';

type TSecurityForm = {
  handleSubmit: () => void,
};

const SecurityForm: React$StatelessFunctionalComponent<TSecurityForm> = ({ handleSubmit }) => {
  const { formatMessage } = useIntl();
  return (
    <Stack component="form" onSubmit={handleSubmit} spacing={3}>
      <Field
        label={formatMessage({
          id: 'form.organization.security.label',
          defaultMessage: 'Password expiration',
        })}
        component={TextField}
        placeholder={formatMessage({
          id: 'form.organization.security.placeholder',
          defaultMessage: 'Password expiration',
        })}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Typography>
                {formatMessage({ id: 'labels.profile.notifications.days', defaultMessage: 'days' })}
              </Typography>
            </InputAdornment>
          ),
          sx: { maxWidth: 'max(50%, 400px)' },
        }}
        name="expiration_days"
        parse={(value) => Number(value.replace(/[^0-9]/g, ''))}
      />

      <Button type="submit" sx={{ minWidth: '14ch', alignSelf: 'flex-end' }}>
        {formatMessage({ id: 'button.save', defaultMessage: 'Save' })}
      </Button>
    </Stack>
  );
};

export default compose(reduxForm({ form: 'securityForm' }))(SecurityForm);
