/* @flow */
import React, { Component } from 'react';

import cx from 'classnames';

type Props = {|
  children: React$Node,
  onOutsideClick: () => void,
  disabled: boolean,
  enableMaxHeight?: boolean,
  className?: string,
|};

export default class OutsideClickHandler extends Component<Props> {
  static defaultProps = {
    disabled: false,
    enableMaxHeight: false,
  };

  constructor(props) {
    super(props);

    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  onOutsideClick = (e) => {
    const { onOutsideClick, disabled } = this.props;

    if (!disabled && typeof onOutsideClick === 'function') {
      onOutsideClick();
    }
  };

  setWrapperRef(node: ?HTMLElement) {
    this.wrapperRef = node;
  }

  checkIsConfirmModal = (target: HTMLElement) => {
    let isDialog = false;
    let element = target;
    while (element.parentNode && element.parentNode !== document && !isDialog) {
      element = element.parentNode;
      const role = element?.getAttribute('role');
      isDialog = role === 'dialog' || role === 'presentation';
    }

    return isDialog;
  };

  handleClickOutside({ target }: { target: ?HTMLElement }) {
    if (this.wrapperRef && !this.wrapperRef.contains(target) && !this.checkIsConfirmModal(target)) {
      this.onOutsideClick();
    }
  }

  wrapperRef: ?HTMLElement;

  render() {
    const { children, enableMaxHeight, className } = this.props;
    return (
      <div className={cx(className)} ref={this.setWrapperRef} style={{ height: enableMaxHeight ? '100%' : 'auto' }}>
        {children}
      </div>
    );
  }
}
