// @flow
import React, { useEffect, useState, memo } from 'react';
import { useIntl } from 'react-intl';
import { compose } from 'redux';
import { type FormProps, Field, reduxForm, formValueSelector } from 'redux-form/immutable';
import { type TTimezonesList } from 'domain/dictionary/types.js.flow';
import { connect } from 'react-redux';

import useNotificationsForm from 'pages/profiles/company/pages/notifications/form/useNotificationsForm';

import SwitchForm from 'components/mui/Form/Switch/SwitchForm';
import SelectForm from 'components/mui/Form/Select/SelectForm';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import GroupItem from 'pages/profiles/company/pages/components/GroupItem';
import RepeatOutlinedIcon from '@mui/icons-material/RepeatOutlined';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';

import { sessionST } from 'lib/sessionStorage';

type TProfileNotificationsFormProps = {|
  timezonesOptions: TTimezonesList,
  currentNotifyMe: string,
  remindMe: boolean,
  isEnableSlackNotify: boolean,
|} & FormProps;

// eslint-disable-next-line max-len
const ProfileNotificationsForm: React$StatelessFunctionalComponent<TProfileNotificationsFormProps> = ({
  handleSubmit,
  timezonesOptions,
  currentNotifyMe,
  remindMe,
  initialValues,
  change,
  isEnableSlackNotify,
}: TProfileNotificationsFormProps) => {
  const { formatMessage } = useIntl();
  const { notifyOptions, hoursOptions, minutesOptions, daysOptions } = useNotificationsForm({ formatMessage });
  const [isVisibleTime, setIsVisibleTime] = useState(false);
  const [isVisibleRemindSettings, setIsVisibleRemindSettings] = useState(false);

  useEffect(() => {
    setIsVisibleTime(currentNotifyMe === 'per_day');
    if (currentNotifyMe === 'never') {
      setIsVisibleRemindSettings(false);
      change('approval_notification.remind_me', false);
    }
  }, [currentNotifyMe, change]);

  useEffect(() => {
    const {
      approval_notification: { remind_me: initialRemindMe },
    } = initialValues.toJS();
    setIsVisibleRemindSettings(remindMe);
    if (!initialRemindMe && remindMe) {
      sessionST().shouldSaveReminder().set(JSON.stringify(remindMe));
    }
  }, [remindMe, initialValues]);

  return (
    <form onSubmit={handleSubmit} id="user_profile_form">
      <Stack spacing={4}>
        <GroupItem
          title={{
            id: 'profile.notifications.approvals.title',
            defaultMessage: 'Approval notifications',
          }}
        >
          <Stack spacing={2}>
            <Field
              name="approval_notification.notify_me"
              component={SelectForm}
              options={notifyOptions}
              id="approval_notification.notify_me"
              FormControlProps={{ fullWidth: true }}
              label={formatMessage({
                id: 'labels.profile.notifications.notifyMe',
                defaultMessage: 'Notify me',
              })}
            />
            <Field
              name="approval_notification.remind_me"
              component={SwitchForm}
              props={{
                FormControlLabelProps: {
                  labelPlacement: 'end',
                  label: (
                    <Typography variant="body1">
                      {formatMessage({
                        id: 'labels.profile.notifications.remindMe',
                        defaultMessage: 'Remind me',
                      })}
                    </Typography>
                  ),
                  disabled: currentNotifyMe === 'never',
                },
              }}
            />

            {isVisibleRemindSettings && (
              <Grid container spacing={1} alignItems="center" mt={2}>
                <Grid item mt={0.5}>
                  <RepeatOutlinedIcon color="disabled" />
                </Grid>
                <Grid item>
                  <Typography variant="body1" color="grey.600">
                    {formatMessage({
                      id: 'labels.profile.notifications.repeatRemind',
                      defaultMessage: 'Repeat every',
                    })}
                  </Typography>
                </Grid>
                <Grid item>
                  <Field
                    component={SelectForm}
                    options={daysOptions}
                    name="approval_notification.frequency_days"
                    id="approval_notification.frequency_days"
                    FormControlProps={{ size: 'small' }}
                  />
                </Grid>
              </Grid>
            )}

            {(isVisibleTime || isVisibleRemindSettings) && (
              <Grid container spacing={1} alignItems="center" mt={2}>
                <Grid item mt={0.5}>
                  <AccessTimeOutlinedIcon color="disabled" />
                </Grid>
                <Grid item>
                  <Typography variant="body1" color="grey.600">
                    {formatMessage({
                      id: 'labels.profile.notifications.time',
                      defaultMessage: 'Remind time',
                    })}
                  </Typography>
                </Grid>
                <Grid item>
                  <Field
                    component={SelectForm}
                    options={hoursOptions}
                    name="approval_notification.hours"
                    id="approval_notification.hours"
                    FormControlProps={{ size: 'small' }}
                  />
                </Grid>
                <Grid item>
                  <Field
                    component={SelectForm}
                    options={minutesOptions}
                    name="approval_notification.minutes"
                    id="approval_notification.minutes"
                    FormControlProps={{ size: 'small' }}
                  />
                </Grid>
                <Grid item xs zeroMinWidth>
                  <Field
                    component={SelectForm}
                    options={timezonesOptions}
                    name="approval_notification.timezone"
                    id="approval_notification.timezone"
                    FormControlProps={{ fullWidth: true, size: 'small' }}
                  />
                </Grid>
              </Grid>
            )}
            <Field
              name="slack_notification.notify"
              component={SwitchForm}
              props={{
                FormControlLabelProps: {
                  labelPlacement: 'end',
                  label: (
                    <Typography variant="body1">
                      {formatMessage({
                        id: 'profile.notifications.slack.label',
                        defaultMessage: 'Slack notifications',
                      })}
                    </Typography>
                  ),
                  disabled: !isEnableSlackNotify,
                },
              }}
            />
          </Stack>
        </GroupItem>

        <GroupItem
          title={{
            id: 'profile.notifications.chat.title',
            defaultMessage: 'Chat notifications',
          }}
        >
          <Field
            name="chat_notification.chat_to_mail"
            component={SwitchForm}
            props={{
              FormControlLabelProps: {
                label: (
                  <Typography variant="body2">
                    {formatMessage({
                      id: 'profile.notifications.chat.label',
                      defaultMessage: 'Notify me by email about new chat messages',
                    })}
                  </Typography>
                ),
              },
            }}
          />
        </GroupItem>
      </Stack>
    </form>
  );
};

const formName = 'profileNotificationsForm';

const selector = formValueSelector(formName);

const mapStateToProps = (state) => ({
  currentNotifyMe: selector(state, 'approval_notification.notify_me'),
  remindMe: selector(state, 'approval_notification.remind_me'),
  isEnableSlackNotify: selector(state, 'slack_notification.connected'),
});

export default compose(
  reduxForm({ form: formName }), // don't change order with connect
  connect(mapStateToProps),
)(memo(ProfileNotificationsForm));
