// @flow
import React from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useApiToken } from 'lib/apiTokenKeeper';
import { documentGetLinkedIDsAction, mergeDocumentsForPrintingAction } from 'domain/documents';
import { promisify } from 'lib/helpers';
import Api from 'domain/api';
import print, { onPrint } from 'lib/print';
import useToggle from 'hooks/useToggle';

import IconButton from '@mui/material/IconButton';
import TooltipForButton from 'components/mui/Tooltip/TooltipForButton';

import PrintOutlinedIcon from '@mui/icons-material/PrintOutlined';

type TPrintButton = {
  linkID: string,
};

const PrintButton: React$StatelessFunctionalComponent<TPrintButton> = ({ linkID }) => {
  const dispatch = useDispatch();
  const [isBusy, toggleIsBusy] = useToggle();
  const { companyId } = useParams();
  const dokkaToken = useApiToken();

  const onClickPrint = async () => {
    toggleIsBusy();
    const documentGetLinkedIDs = (args) => dispatch(documentGetLinkedIDsAction(args));
    const ids = await promisify(documentGetLinkedIDs, { linkid: linkID });

    if (ids.length > 1) {
      const fn = (arg) => dispatch(mergeDocumentsForPrintingAction({ ...arg }));
      const pdfFile = await promisify(fn, { documentID: ids });
      await onPrint(pdfFile, ids[0], false);
    } else {
      const url = Api.getDocumentUrl({ dokkaToken, documentID: ids[0], companyId });

      await print(url, ids[0], false);
    }

    dispatch({ type: documentGetLinkedIDsAction.success });
    toggleIsBusy();
  };

  return (
    <TooltipForButton
      t={{
        id: 'document.show.actions.printLinked',
        defaultMessage: 'Print Linked',
      }}
    >
      <IconButton color="primary" onClick={onClickPrint} disabled={isBusy}>
        <PrintOutlinedIcon />
      </IconButton>
    </TooltipForButton>
  );
};

export default PrintButton;
