import * as I from 'immutable';
import { createSelectorCreator, defaultMemoize } from 'reselect';

export const peek =
  (...path) =>
  (obj) =>
    obj.getIn(path);

export const peekOr = (path, def) => (obj) => obj.getIn(path, def);

function sThen(s) {
  return (state) => s(this(state))(state);
}

const Selector = createSelectorCreator(defaultMemoize, I.is);

export function selector(...args) {
  function sMap(f) {
    return selector(this, f);
  }
  const res = Selector(...args);
  res.then = sThen;
  res.map = sMap;
  return res;
}

export const select = (f) => selector((x) => x, f);
