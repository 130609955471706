// @flow
import * as React from 'react';
import { Map, type RecordOf } from 'immutable';
import { compose, type Dispatch } from 'redux';
import { connect } from 'react-redux';
import { withStyles } from '@mui/styles';
import cx from 'classnames';
import { ChatUserFactory, makeChannelName } from 'domain/chat/helpers';
import { getParamsFromChannelName } from 'sendbird-utils';
import { userSelector } from 'domain/env';
import {
  createChannelAction,
  chatParticipantsByIdSelector,
  setActiveChannelAction,
  activeChannelSelector,
} from 'domain/chat';
import type { ChatUser } from 'domain/chat/types.js.flow';
import type { CurrentUserType } from 'domain/env/types.js.flow';

import Title from './title';
import Messages from './messages';
import UserPic from 'components/UserPic';
import MessageThread from './thread';
import StatusBar from './ChatStatusAlert';
import Search from './Search';
import sheet from './sheet';

type Props = {|
  classes: {
    [key: string]: string,
  },
  currentUser: CurrentUserType,
  // eslint-disable-next-line react/no-unused-prop-types
  create: Dispatch<createChannelAction>, // @to-do check if prop is used
  activeChannel: ?string,
  setActiveChannel: Dispatch<setActiveChannelAction>, // @to-do check if prop is used
  companyId: string,
  usersById: Map<string, RecordOf<ChatUser>>,
|};

type State = {
  searchText: string,
};

class ChatContainer extends React.Component<Props, State> {
  state = {
    searchText: '',
  };

  onChat = (d: RecordOf<ChatUser>) => {
    const { currentUser, companyId, setActiveChannel } = this.props;
    setActiveChannel({
      threadId: makeChannelName([currentUser.userId, d.userId], companyId),
      companyId,
    });
  };

  onChangeSearchText = (searchText: string) => {
    this.setState({ searchText });
  };

  get status() {
    return typeof this.props.activeChannel === 'string';
  }

  get participant(): RecordOf<ChatUser> {
    const { activeChannel, currentUser, usersById } = this.props;
    if (typeof activeChannel === 'string') {
      const { users } = getParamsFromChannelName(activeChannel);
      const participant = users.filter((e) => e !== currentUser.userId)[0];
      if (typeof participant === 'string') {
        return usersById.get(participant, ChatUserFactory());
      }
    }
    return ChatUserFactory();
  }

  render() {
    const { classes, currentUser, usersById, companyId, activeChannel } = this.props;
    return (
      <div className={classes.container}>
        <div
          className={cx(classes.slider, {
            [classes.userListPos]: !this.status,
            [classes.threadPos]: this.status,
          })}
        >
          <div className={classes.screen}>
            <Title user={this.participant.get('username')}>
              <button
                type="button"
                onClick={() => this.props.setActiveChannel({ threadId: null, companyId })}
                className={classes.back}
              />
              <UserPic size={30} src={this.participant.get('picture')} />
            </Title>
            <StatusBar />
            <MessageThread threadId={activeChannel} params={{ companyId }} />
          </div>
          <div className={classes.screen}>
            <Title user={currentUser.chatUser}>
              <UserPic size={30} src={usersById.getIn([currentUser.userId, 'picture'])} />
            </Title>
            <StatusBar />
            <Search searchText={this.state.searchText} onChangeSearchText={this.onChangeSearchText} />
            <Messages
              onChat={this.onChat}
              searchText={this.state.searchText}
              currentUserID={currentUser.userId}
              companyId={companyId}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  activeChannel: activeChannelSelector(state),
  currentUser: userSelector(state),
  usersById: chatParticipantsByIdSelector(state),
});

const mapDispatchToProps = {
  create: createChannelAction,
  setActiveChannel: setActiveChannelAction,
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(sheet))(ChatContainer);
