import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, generatePath } from 'react-router-dom';
import { documentSelector, getApproveDocumentsCount } from 'domain/documents';
import { cameFromToApproveCategorySelector, navigationSelector } from 'domain/env';
import { queryToSearch } from 'domain/router/helpers';
import ROUTES_PATH from 'domain/router/routesPathConfig';
import * as ACL from 'domain/restriction';

const mapPropsToState = (state) => ({
  navigation: navigationSelector(state),
  documentForApproveCount: getApproveDocumentsCount(state),
  isCameFromToApproveCategory: cameFromToApproveCategorySelector(state),
  isGranted: ACL.isGranted(state),
  document: documentSelector(state),
});

const useRedirectWhenApprovalsEnd = () => {
  const {
    navigation,
    documentForApproveCount,
    isCameFromToApproveCategory,
    isGranted,
    document: { status },
  } = useSelector(mapPropsToState);
  const navigate = useNavigate();
  const isGrantedWorkAsFinancial = isGranted(ACL.IS_WORK_ON_WITH_CURRENT_DOCUMENT_AS_FINANCIAL);
  const isLimitedBK = isGranted(ACL.IS_LIMITED_ACCOUNT);
  const isReadOnlyBK = isGranted(ACL.IS_READ_ONLY_ACCOUNTANT);
  const isGrantedByRole = isLimitedBK || isReadOnlyBK;

  const isAllowRedirectAfterApprove = useMemo(
    () =>
      isCameFromToApproveCategory &&
      documentForApproveCount <= 1 &&
      (!isGrantedWorkAsFinancial || (isGrantedWorkAsFinancial && status.includes('accepted')) || isGrantedByRole),
    [documentForApproveCount, isCameFromToApproveCategory, isGrantedWorkAsFinancial, isGrantedByRole, status],
  );

  const isAllowRedirectAfterPublish = useMemo(
    () => isCameFromToApproveCategory && documentForApproveCount < 1 && isGrantedWorkAsFinancial,
    [documentForApproveCount, isCameFromToApproveCategory, isGrantedWorkAsFinancial],
  );

  const pathToApprove = useMemo(() => {
    if (navigation.size !== 0) {
      const params = navigation.getIn(['search', 'params']);
      const query = navigation.getIn(['search', 'query']);
      const queryString = queryToSearch(query);
      const hasParams = params?.companyId && params?.category1;

      if (hasParams) {
        const path =
          params.companyType === 'confidential'
            ? ROUTES_PATH.COMPANY_CONFIDENTIAL_WORKSPACE.absolute
            : ROUTES_PATH.COMPANY_WORKSPACE.absolute;

        return {
          pathname: generatePath(path, params),
          search: `?${queryString}`,
        };
      }
    }

    return null;
  }, [navigation]);

  const redirectToApprove = useCallback(() => {
    if (pathToApprove) {
      navigate(pathToApprove);
    }
  }, [pathToApprove, navigate]);

  return {
    isAllowRedirectAfterApprove: isAllowRedirectAfterApprove && Boolean(pathToApprove),
    isAllowRedirectAfterPublish: isAllowRedirectAfterPublish && Boolean(pathToApprove),
    pathToApprove,
    redirectToApprove,
  };
};

export const withRedirectWhenApprovalsEnd = (Component) => (props) => {
  const { isAllowRedirectAfterPublish, isAllowRedirectAfterApprove, redirectToApprove } = useRedirectWhenApprovalsEnd();

  return (
    <Component
      isAllowRedirectAfterApprove={isAllowRedirectAfterApprove}
      isAllowRedirectAfterPublish={isAllowRedirectAfterPublish}
      redirectToApprove={redirectToApprove}
      {...props}
    />
  );
};

export default useRedirectWhenApprovalsEnd;
