/* @flow */
import { selector } from 'lib/selectors';

function sortByTimestamp(a, b) {
  return new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime();
}

const events = (state) => state.events;
export const eventsListSelector = selector(events, (e) => e.list.sort(sortByTimestamp));
export const nextNumPageSelector = selector(events, (e) => e.nextNumPage);
export const eventHashSelector = selector(events, (e) => e.eventHash);
export const newEventsCounterSelector = selector(events, (e) => e.countOfNewEvents);
export const isOpenedSelector = selector(events, (e) => e.isOpened);
