// @flow
import React from 'react';

import { compose } from 'redux';
import { Field, reduxForm } from 'redux-form/immutable';
import { useIntl } from 'react-intl';
import { useTheme } from '@mui/material';

import File from 'pages/common/input/file';
import UserPic from 'pages/common/input/UserPic';
import PhoneForm from 'components/mui/Form/Phone/PhoneForm';
import TextFieldForm from 'components/mui/Form/TextField/TextFieldForm';
import GroupItem from 'pages/profiles/company/pages/components/GroupItem';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';

import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';

type TProfileMainForm = {
  handleSubmit: () => void,
  onAvatarChange: (file: File, resolve: (picture: string) => void, reject: () => void) => void,
  pictureValue?: string,
};

const ProfileMainForm: React$StatelessFunctionalComponent<TProfileMainForm> = ({
  handleSubmit,
  pictureValue,
  onAvatarChange,
}) => {
  const { formatMessage } = useIntl();
  const { palette } = useTheme();

  return (
    <form onSubmit={handleSubmit} id="user_profile_form">
      <Stack spacing={4}>
        <GroupItem
          title={{
            id: 'profile.info.generalInfo.title',
            defaultMessage: 'General information',
          }}
        >
          <Field
            component={UserPic}
            onUpload={onAvatarChange}
            label={{
              id: 'forms.profile.main.avatar',
              defaultMessage: 'User Avatar',
            }}
            required
            mark="logo"
            accept="image/png, image/jpg, image/jpeg, image/bmp"
            name="picture"
            picture={pictureValue}
            BoxProps={{
              display: 'flex',
              justifyContent: 'center',
            }}
          />
          <Field
            component={TextFieldForm}
            label={formatMessage({
              id: 'forms.common.firstName.label',
              defaultMessage: 'First name',
            })}
            placeholder={formatMessage({
              id: 'forms.common.firstName.placeholder',
              defaultMessage: 'First name',
            })}
            required
            name="first_name"
            margin="dense"
            fullWidth
            withTrim
          />
          <Field
            component={TextFieldForm}
            label={formatMessage({
              id: 'forms.common.lastName.label',
              defaultMessage: 'Last name',
            })}
            placeholder={formatMessage({
              id: 'forms.common.lastName.placeholder',
              defaultMessage: 'Last name',
            })}
            required
            name="last_name"
            margin="dense"
            fullWidth
            withTrim
          />
        </GroupItem>
        <GroupItem
          title={{
            id: 'profile.info.contactInfo.title',
            defaultMessage: 'Contact information',
          }}
        >
          <Grid container flexWrap="nowrap" mb={2}>
            <Grid
              container
              width={40}
              height={40}
              minWidth={40}
              bgcolor={palette.grey[100]}
              borderRadius="6px"
              justifyContent="center"
              alignItems="center"
              marginRight={1.5}
            >
              <EmailOutlinedIcon color="disabled" />
            </Grid>
            <Field
              component={TextFieldForm}
              label={formatMessage({
                id: 'forms.common.email.label',
                defaultMessage: 'Email',
              })}
              placeholder={formatMessage({
                id: 'forms.common.email.placeholder',
                defaultMessage: 'email@example.com',
              })}
              name="email"
              type="email"
              disabled
              fullWidth
              variant="standard"
              InputProps={{
                disableUnderline: true,
              }}
              sx={{ mb: 1 }}
            />
          </Grid>

          <Field
            name="phone"
            component={PhoneForm}
            CodeProps={{
              fullWidth: true,
            }}
            CodeInputProps={{
              required: true,
            }}
            NumberProps={{
              required: true,
              fullWidth: true,
              placeholder: formatMessage({
                id: 'form.organization.create.phone.placeholder',
                defaultMessage: '+3 8093 4648407',
              }),
            }}
          />
        </GroupItem>
      </Stack>
    </form>
  );
};

export default compose(reduxForm({ form: 'profileMainForm' }))(ProfileMainForm);
