/* @flow */
import * as React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';

// components
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Select from 'components/mui/Form/Select/SelectBase';

// api
import Api from 'domain/api';

// selectors
import { tokenSelector } from 'domain/documents/documentSelector';

// types
import type { ThemesParamsType } from 'styles/dokkaTheme/types.js.flow';

// styles
import { withStyles } from '@mui/styles';
import sheet from './sheet';
import { FormattedMessage } from 'react-intl';

type Props = {
  dokkaToken: string,
  onSelectDevice: (d: { deviceId: string }) => void,
  theme: ThemesParamsType,
};

type ListItem = {
  value: string | number,
  label: string,
};

type HashDevice = {|
  deviceId: string,
  deviceType: string,
  displayGuid: string,
  erpType: string,
  handleConnectModal: () => void,
|};

type State = {
  hashDevices: ?Array<HashDevice>,
  selected: ?string,
  isLoading: boolean,
};

class HashSelectDevice extends React.Component<Props, State> {
  state = {
    hashDevices: null,
    selected: null,
    isLoading: false,
  };

  componentDidMount(): * {
    this.loadHashDevices();
  }

  onConfirm = (selected) => {
    const { onSelectDevice } = this.props;
    if (selected) {
      onSelectDevice({ deviceId: selected.value });
    }
  };

  getOptions = (hashDevices: Array<HashDevice>): ListItem =>
    hashDevices ? hashDevices.map((d) => ({ label: d.displayGuid, value: d.deviceId })) : [];

  setSelectedOption = (e) => {
    const { hashDevices } = this.state;
    const option = this.getOptions(hashDevices).find((i) => i.value === e.target.value);
    this.setState({ selected: option });
  };

  loadHashDevices = () => {
    const { dokkaToken } = this.props;
    this.setState({ isLoading: true });
    Api.getHashDevices({
      params: {
        dokkaToken,
      },
    })
      .then(({ data }) => {
        const options = this.getOptions(data);

        if (options.length === 1) {
          this.onConfirm(options[0]);
        } else {
          this.setState({ hashDevices: data });
        }
      })
      .finally(() => {
        this.setState({ isLoading: false });
      });
  };

  render() {
    const { isLoading, selected, hashDevices } = this.state;
    const { handleConnectModal } = this.props;

    return !isLoading ? (
      <>
        <DialogContent sx={(theme) => ({ pt: `${theme.spacing(1)}!important` })}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Select
                FormControlProps={{ fullWidth: true }}
                options={this.getOptions(hashDevices)}
                value={selected?.value || selected}
                onChange={this.setSelectedOption}
                label={<FormattedMessage id="hashConnect.select.placeholder" defaultMessage="Select GUID" />}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConnectModal} variant="text">
            <FormattedMessage id="button.cancel" defaultMessage="Cancel" />
          </Button>
          <Button type="submit" disabled={isLoading} onClick={() => this.onConfirm(selected)}>
            <FormattedMessage id="hashConnect.connect" defaultMessage="Connect" />
          </Button>
        </DialogActions>
      </>
    ) : null;
  }
}

const mapStateToProps = (state) => ({
  dokkaToken: tokenSelector(state),
});

export default compose(withStyles(sheet, { withTheme: true }), connect(mapStateToProps, {}))(HashSelectDevice);
