// @flow
import * as Bowser from 'bowser';

const browser = Bowser.getParser(window.navigator.userAgent);
const { name: browserName } = browser.getBrowser();
export const handleImport = (importPromise) =>
  new Promise((resolve, reject) => {
    importPromise
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
