/* @flow */
import React, { useCallback, useEffect, useState } from 'react';
import { usePopupState, bindTrigger, bindMenu } from 'material-ui-popup-state/hooks';
import type {
  TOptions,
  TOption,
} from 'components/mui/Layouts/components/EventsHistoryPanel/components/FiltersBlock/types.js.flow';

import HighlightText from 'components/mui/HighlightText';
import TextFieldBase from 'components/mui/Form/TextField/TextFieldBase';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItem from '@mui/material/ListItem';
import LoadingButton from '@mui/lab/LoadingButton';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

import elements from 'components/elements';

import { styled } from '@mui/material/styles';

type TEventsHistoryDropdownFilter = {
  options: TOptions,
  isBusy: boolean,
  toggleText: React$Node | string,
  StartIcon?: React$Node,
  className?: string,
  handleOptionClick: (option: TOption) => void,
  dataElement: string,
};

const StickyListItem = styled(ListItem)(({ theme }) => ({
  zIndex: 1,
  top: 0,
  position: 'sticky',
  backgroundColor: theme.palette.common.white,
}));

const EventsHistoryDropdownFilter: React$StatelessFunctionalComponent<TEventsHistoryDropdownFilter> = ({
  options,
  StartIcon,
  toggleText,
  handleOptionClick,
  isBusy,
  dataElement,
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [currentOptions, setCurrentOptions] = useState<TOptions>(options);
  const popupState = usePopupState({ variant: 'popover', popupId: 'EventsHistoryDropdownFilter' });

  const onClickOption = (option: TOption) => {
    handleOptionClick(option);
    popupState.close();
    setSearchTerm('');
    setCurrentOptions(options);
  };

  const handleFilterOption = useCallback(
    (value: string) => {
      const filteredOptions = options.filter((option) => option.label.toLowerCase().includes(value.toLowerCase()));

      setCurrentOptions(filteredOptions);
    },
    [options],
  );

  const handleChangeInput = (e: SyntheticInputEvent<HTMLInputElement>) => {
    const { value } = e.target;

    setSearchTerm(value);
    handleFilterOption(value);
  };

  // mui Menu component can navigate and preselect option by typing
  // but in that variant we have TextField with autofocus, when we in focus TextField we prevent propagate event
  // but leave behaviour for up/down arrows
  const handleKeyDownInput = (e: SyntheticInputEvent<HTMLInputElement>) => {
    if (![40, 38].includes(e.keyCode)) {
      e.stopPropagation();
    }
  };

  useEffect(() => {
    if (isBusy === false) {
      setCurrentOptions(options);
    }
  }, [isBusy, options]);

  return (
    <div>
      <LoadingButton
        variant="text"
        size="small"
        startIcon={StartIcon}
        endIcon={popupState.isOpen ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
        loading={isBusy}
        loadingPosition="start"
        data-element={dataElement}
        {...bindTrigger(popupState)}
      >
        {toggleText}
      </LoadingButton>

      <Menu
        autoFocus={false}
        variant="menu"
        {...bindMenu(popupState)}
        slotProps={{ paper: { sx: { minWidth: '25ch', maxHeight: 470 } } }}
      >
        <StickyListItem onKeyDown={handleKeyDownInput}>
          <TextFieldBase autoFocus size="small" fullWidth onChange={handleChangeInput} />
        </StickyListItem>
        {currentOptions.map((option) => (
          <MenuItem
            key={option.value}
            onClick={() => onClickOption(option)}
            data-element={elements.history.dropdownFilter.item}
          >
            <HighlightText value={option.label} searchTerm={searchTerm} />
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default EventsHistoryDropdownFilter;
