/* @flow */
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import cx from 'classnames';
import Accordion from 'components/Accordion';
import makeCancelable, { type PromiseCanceleable } from 'lib/makeCancelable';
import elements from 'components/elements';

const message = {
  read: {
    id: 'requestTransaction.answered',
    defaultMessage: 'Answered',
  },
  unread: {
    id: 'requestTransaction.newRequest',
    defaultMessage: 'New',
  },
};

interface IData {
  +status: 'read' | 'unread';
}

type TitleProps = {
  classes: {|
    [key: string]: string,
  |},
  data: IData,
  children?: React$Node,
  up?: boolean,
};

function Title({ classes, data, children, up }: TitleProps) {
  return (
    <div
      data-element={elements.je.request.transaction.title}
      className={cx(classes.status, classes[data.status], {
        [classes.up]: up,
      })}
    >
      <FormattedMessage {...message[data.status]} />
      {children}
    </div>
  );
}

type State = {
  isOpen: boolean,
};

interface Props {
  classes: {|
    [key: string]: string,
  |};
  data: IData;
}

function delay(time: number): Promise<void> {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, time);
  });
}

function withAccordion(WrappedComponent: React.ComponentType<*>) {
  class WithAccordion extends React.Component<Props, State> {
    state = {
      isOpen: this.props.data.status === 'unread',
    };

    componentDidUpdate(prevProps: Props) {
      const { data } = this.props;
      if (data.status !== prevProps.data.status && data.status === 'read') {
        this.collapse();
      }
    }

    componentWillUnmount() {
      if (this.cancelable) {
        this.cancelable.cancel();
      }
    }

    collapse = () => {
      this.cancelable = makeCancelable(delay(1500));
      this.cancelable.promise.then(() => {
        this.setState({ isOpen: false });
      });
    };

    cancelable: PromiseCanceleable<void>;

    render() {
      const { classes, data } = this.props;
      return (
        <Accordion
          isOpen={this.state.isOpen}
          title={(up: boolean) => (
            <Title classes={classes} data={data} up={up}>
              <span className={cx(classes.indicator, { [classes.up]: up })} />
            </Title>
          )}
        >
          <WrappedComponent {...this.props} />
        </Accordion>
      );
    }
  }
  return WithAccordion;
}

export default withAccordion;
