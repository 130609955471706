import moveGrid from './icons/drugIcon.svg';

export default {
  button: {
    zIndex: 1,
    position: 'absolute',
    width: 18,
    height: 18,
    borderRadius: 2,
    outline: 'none',
    cursor: 'pointer',
    transition: 'transform 0.3s',
    willChange: 'transform',

    '&:hover': {
      transform: 'scale(1.1)',
    },

    '&[disabled]': {
      cursor: 'not-allowed',
    },
  },
  dragButton: {
    width: 25,
    height: 25,
    background: `url("${moveGrid}") center no-repeat`,
    border: 'none',
    cursor: 'grab',
    zIndex: 3,
  },
};
