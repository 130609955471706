export default {
  list: {
    display: 'flex',
    position: 'relative',
    marginBottom: 15,
    zIndex: 15,
  },
  item: {
    flex: [0, 1, '25%'],
    minWidth: 0,
    width: '25%',
    position: 'relative',
    marginLeft: 40,
    '&:first-child': {
      marginLeft: 0,
    },
  },
  label: {
    color: '#171A1C',
    fontSize: 12,
    lineHeight: '14px',
    display: 'block',
    fontWeight: 600,
    marginBottom: 8,
  },
  fieldWrapper: {},
  field: {
    padding: [0, 8],
    border: '0 none',
    borderBottom: '1px solid #DEE2E6',
    width: '100%',
    color: '#171A1C',
    height: 30,
    fontSize: 12,
  },
  inputError: {
    borderColor: '#BC5353',
  },
};
