// @flow

export default {
  wrapper: {
    transform: 'translate(-25px, -25px)',
  },
  overlay: {
    position: 'fixed',
    pointerEvents: 'none',
    zIndex: 10000,
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
  },

  layerBox: {
    position: 'fixed',
    opacity: '0',
    transition: 'opacity 0.1s linear',
    '.rtl &': {
      left: '100%',
    },
  },
  image: {
    objectFit: 'contain',
    '.rtl &': {
      marginLeft: '-100%',
    },
  },
};
