// @flow
import * as React from 'react';

import Stack from '@mui/material/Stack';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import IconButton from '@mui/material/IconButton';
import ChatBubbleTwoToneIcon from '@mui/icons-material/ChatBubbleTwoTone';
import InsertDriveFileTwoToneIcon from '@mui/icons-material/InsertDriveFileTwoTone';
import { TotalUnreadBadge } from 'components/mui/Layouts/components/Chat/components/StyledComponents';

import { type View } from '../../Approvals';
import { styled } from '@mui/material/styles';

const EndEdgeButton = styled(IconButton)(() => ({
  position: 'absolute',
  right: 0,
  top: '50%',
  transform: 'translateY(-50%)',
}));

type Props = {
  onClickPrev: () => void,
  onClickNext: () => void,
  currentDocNum: number,
  docsCount: number,
  onViewBtnClick: () => void,
  view: View,
  unreadMessagesCount: number,
  isNavigationDisabled: boolean,
  isEnableClientChat: boolean,
};

class MobileApprovalHeader extends React.Component<Props> {
  render() {
    const {
      docsCount,
      currentDocNum,
      onViewBtnClick,
      onClickNext,
      onClickPrev,
      unreadMessagesCount,
      view,
      isNavigationDisabled,
      isEnableClientChat,
    } = this.props;

    return (
      <Stack flex={1} direction="row" justifyContent="center" alignItems="center" position="relative">
        <Stack direction="row" justifyContent="center" alignItems="center" spacing={0.5}>
          <IconButton color="inherit" disabled={isNavigationDisabled} onClick={onClickPrev}>
            <NavigateBeforeIcon />
          </IconButton>
          <Stack direction="row" justifyContent="center" alignItems="center">
            {currentDocNum} of {docsCount}
          </Stack>
          <IconButton color="inherit" disabled={isNavigationDisabled} onClick={onClickNext}>
            <NavigateNextIcon />
          </IconButton>
        </Stack>
        {isEnableClientChat && (
          <EndEdgeButton color="inherit" edge="end" onClick={onViewBtnClick}>
            <TotalUnreadBadge
              color="error"
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              badgeContent={unreadMessagesCount}
            >
              {view === 'chat' ? <InsertDriveFileTwoToneIcon /> : <ChatBubbleTwoToneIcon />}
            </TotalUnreadBadge>
          </EndEdgeButton>
        )}
      </Stack>
    );
  }
}

export default MobileApprovalHeader;
