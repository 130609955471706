// @flow
import React, { memo } from 'react';

import IconButton from '@mui/material/IconButton';
import TooltipForButton from 'components/mui/Tooltip/TooltipForButton';

import SimCardDownloadOutlinedIcon from '@mui/icons-material/SimCardDownloadOutlined';

import elements from 'components/elements';

type TExportButton = {
  onClick: () => void,
  isDisabled: boolean,
};

const ExportButton: React$StatelessFunctionalComponent<TExportButton> = ({ onClick, isDisabled = true }) => (
  <TooltipForButton
    t={{
      id: 'tooltip.export',
      defaultMessage: 'Export',
    }}
  >
    <IconButton color="primary" onClick={onClick} disabled={isDisabled} data-element={elements.history.export}>
      <SimCardDownloadOutlinedIcon size={20} />
    </IconButton>
  </TooltipForButton>
);

export default memo(ExportButton);
