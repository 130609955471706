/* @flow */

import { makeStyles } from '@mui/styles';

export default makeStyles((theme) => ({
  container: {
    width: '100%',
    padding: 10,
    backgroundColor: theme.colors.greyLightest,
    borderBottom: `1px solid ${theme.colors.greyLighter}`,
  },
}));
