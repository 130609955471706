/* @flow */
import React from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import * as ACL from 'domain/restriction';
import { userFeaturesSelector } from 'domain/env';
import type { LocationShape } from 'react-router-dom';
import type { AcceptContext } from 'domain/documents/types.js.flow';
import { type List } from 'immutable';

import DropdownButton from 'pages/document/components/DocumentPublishButton/DropdownButton';
import RouterLink from 'components/mui/Router/RouterLink';
import Button from '@mui/material/Button';

const mapStateToProps = (state) => ({
  isGranted: ACL.isGranted(state),
  userFeatures: userFeaturesSelector(state),
});

type TDocumentPublishButton = {
  isPending: boolean,
  userId: string,
  backLink: string | LocationShape,
  onAcceptDocument: (p: AcceptContext) => void,
  jePublicationWarnings: List<string>,
};
const DocumentPublishButton: React$StatelessFunctionalComponent<TDocumentPublishButton> = ({
  isPending,
  backLink,
  userId,
  onAcceptDocument,
  jePublicationWarnings,
}) => {
  const { isGranted, userFeatures } = useSelector(mapStateToProps);
  const isAcceptable = isGranted(ACL.IS_ACCEPTABLE_DOCUMENT);
  const isAllowAutoConfirm = !userFeatures.get('modal', true);

  return isAcceptable ? (
    <DropdownButton
      onAccept={onAcceptDocument}
      isPending={isPending}
      isAutoConfirm={isAllowAutoConfirm}
      userId={userId}
      jePublicationWarnings={jePublicationWarnings}
    />
  ) : (
    <Button color="secondary" variant="contained" component={RouterLink} to={backLink} sx={{ minWidth: '15ch' }}>
      <FormattedMessage id="button.ok" defaultMessage="OK" />
    </Button>
  );
};

export default DocumentPublishButton;
