// @flow
import * as ACL from 'domain/restriction';
import { localRights } from 'pages/company/DocumentContextMenu/helpers';
import type { FilterParamsType } from 'pages/company/DocumentContextMenu/helpers';

const items = [
  {
    id: 'document.show.actions.editNotes',
    defaultMessage: 'Edit sticky notes',
    onClick: () => null,
    restriction: localRights.policy([localRights.paramsName.notes, localRights.paramsName.allowUpdateNotes]),
  },
  {
    id: 'document.show.actions.addNotes',
    defaultMessage: 'Add sticky notes',
    onClick: () => null,
    restriction: localRights.policy([localRights.paramsName.noNotes, localRights.paramsName.allowCreateNotes]),
  },
  {
    id: 'document.show.actions.tagAs',
    defaultMessage: 'Tag as',
    onClick: () => null,
    restriction: localRights.policy(localRights.paramsName.isAuthorized),
  },
  {
    id: 'document.show.actions.ignoreWarnings',
    defaultMessage: 'Ignore warnings',
    onClick: () => null,
    restriction: localRights.policy([localRights.paramsName.isAuthorized]),
  },
  {
    id: 'document.show.actions.sign',
    defaultMessage: 'Sign document',
    onClick: () => null,
    restriction: localRights.policy(localRights.paramsName.isAuthorized),
  },
  // {
  //   id: 'document.show.actions.requestSender',
  //   defaultMessage: 'Request sender',
  //   onClick: () => null,
  //   restriction: localRights.policy(
  //     localRights.paramsName.isAccount,
  //   ),
  // },
  {
    id: 'document.show.actions.generateG',
    defaultMessage: 'Mark as general document',
    onClick: () => null,
    restriction: localRights.policy([localRights.paramsName.isAccount, localRights.paramsName.financial]),
  },
  {
    id: 'document.show.actions.generateJE',
    defaultMessage: 'Mark as financial document',
    onClick: () => null,
    restriction: localRights.policy([localRights.paramsName.isAccount, localRights.paramsName.general]),
  },
  {
    id: 'document.show.actions.paid',
    defaultMessage: 'Mark as paid',
    onClick: () => null,
    restriction: localRights.policy([localRights.paramsName.isAuthorized]),
  },
  {
    id: 'document.show.actions.unpaid',
    defaultMessage: 'Mark as unpaid',
    onClick: () => null,
    restriction: localRights.policy([localRights.paramsName.isAuthorized]),
  },
  {
    id: 'document.show.actions.moveTo',
    defaultMessage: 'Move to',
    onClick: () => null,
    restriction: localRights.policy([localRights.paramsName.notLinked]),
  },
  {
    id: 'document.show.actions.linkedBundle',
    defaultMessage: 'Linked bundle',
    disabled: false,
    action: () => null,
    restriction: localRights.policy(localRights.paramsName.isAuthorized),
  },
  {
    id: 'document.show.actions.moveToCompany',
    defaultMessage: 'Move to other company',
    onClick: () => null,
    restriction: localRights.policy(localRights.paramsName.isAccount),
  },
  {
    id: 'document.show.actions.reject',
    defaultMessage: 'Reject',
    onClick: () => null,
    restriction: localRights.policy([localRights.paramsName.isAccount, localRights.paramsName.financial]),
  },
  {
    id: 'document.show.actions.duplicate',
    defaultMessage: 'Duplicate document',
    onClick: () => null,
    restriction: localRights.policy(localRights.paramsName.isAuthorized),
  },
  {
    id: 'document.show.actions.sendByEmail',
    defaultMessage: 'Share via email',
    onClick: () => null,
    restriction: localRights.policy(localRights.paramsName.isAuthorized),
  },
  {
    id: 'document.show.actions.download',
    defaultMessage: 'Download',
    onClick: () => null,
    restriction: localRights.policy(localRights.paramsName.isAuthorized),
  },
  {
    id: 'document.show.actions.edit',
    defaultMessage: 'Edit',
    onClick: () => null,
    restriction: localRights.policy([localRights.paramsName.isAuthorized, localRights.paramsName.approvalNotInitiated]),
  },
  {
    id: 'document.show.actions.print',
    defaultMessage: 'Print',
    onClick: () => null,
    restriction: localRights.policy(localRights.paramsName.isAuthorized),
  },
  {
    id: 'document.show.actions.delete',
    defaultMessage: 'Delete document',
    onClick: () => null,
    restriction: localRights.policy(localRights.paramsName.isAccount, [
      localRights.paramsName.readOnly,
      localRights.paramsName.isNotAccepted,
    ]),
  },
];

const approvalItemsIdList = [
  'document.show.actions.download',
  'document.show.actions.print',
  'document.show.actions.editNotes',
  'document.show.actions.addNotes',
  'document.show.actions.generateG',
  'document.show.actions.generateJE',
];

export const linkedBundleSubmenu = [
  {
    id: 'document.show.actions.moveLinkedToCompany',
    title: { id: 'document.show.actions.moveLinkedToCompany', defaultMessage: 'Move bundle to company' },
    action: () => null,
    restriction: localRights.policy(localRights.paramsName.isAccount),
  },
  {
    id: 'document.show.actions.printLinked',
    title: { id: 'document.show.actions.printLinked', defaultMessage: 'Print linked' },
    onClick: () => null,
    restriction: localRights.policy(localRights.paramsName.isAuthorized),
  },
  {
    id: 'document.show.actions.sendByEmailLinked',
    title: { id: 'document.show.actions.sendByEmailLinked', defaultMessage: 'Share bundle via email' },
    onClick: () => null,
    restriction: localRights.policy(localRights.paramsName.isAuthorized),
  },
  {
    id: 'document.show.actions.downloadLinked',
    title: { id: 'document.show.actions.downloadLinked', defaultMessage: 'Download linked' },
    onClick: () => null,
    restriction: localRights.policy(localRights.paramsName.isAuthorized),
  },
];

export const actionsList = (d: FilterParamsType, isApproval: boolean) => {
  const itemsList = !isApproval ? items : items.filter((it) => approvalItemsIdList.includes(it.id));
  const lr = localRights.restriction(d.notes, d.doctype, d.status, d.restriction, d.tags);
  const submenus = {
    'document.show.actions.linkedBundle': {
      submenu: linkedBundleSubmenu.filter(ACL.arrayFilter(lr)),
    },
  };
  return itemsList.map((item) => ({ ...item, ...submenus[item.id] })).filter(ACL.arrayFilter(lr));
};
