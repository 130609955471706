// @flow
import ButtonBase from '@mui/material/ButtonBase';

import { styled } from '@mui/material/styles';

export const JEFakePane = styled('div', { label: 'JEInsightsFakePane' })(() => ({
  position: 'relative',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  overflow: 'hidden',
  flex: '0 0 auto',
}));

export const ToggleButton = styled(ButtonBase, { label: 'InsightWidgetToggle' })(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  width: 48,
  height: 180,
  borderRadius: '8px 0 0 8px',
  border: `1px solid ${theme.palette.grey[300]}`,
  borderRight: 'none',
  transition: 'opacity 250ms, border-color 250ms',

  '&:hover': {
    opacity: 0.8,
    borderColor: theme.palette.grey[400],
  },
}));

export const WidgetContent = styled('div', { label: 'InsightWidgetContent' })(({ theme }) => ({
  height: '100%',
  overflow: 'hidden',
  backgroundColor: theme.palette.common.white,
  borderLeft: `1px solid ${theme.palette.grey[300]}`,
  flex: 1,
}));
