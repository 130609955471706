/* @flow */
import _ from 'lodash';
import { getRotateByDocID } from 'lib/pdfHelpers';
import Api from 'domain/api';
import ROUTES_PATH from 'domain/router/routesPathConfig';
import { generatePath } from 'react-router-dom';
import type { AngleType } from 'domain/documents/documentsModel';
import type { TDocument } from 'domain/documents/types.js.flow';

export function getRotationAngle(d: ?TDocument): AngleType {
  if (!d || d.protected) return 0;
  const LSrotationAngle = getRotateByDocID(d.documentID);
  // $FlowFixMe Update document adapter to completely immutable
  const lsRotation = _.get(LSrotationAngle, 0);
  return typeof lsRotation === 'number' ? lsRotation : _.get(d, 'viewinfo.rotations[0]', 0);
}

// eslint-disable-next-line max-len
export function getPreviewSrc(
  companyId: string,
  documentID: string,
  dokkaToken: string,
  preview?: boolean,
  unsafe?: boolean,
): string {
  return Api.getDocumentThumbnailUrl({
    companyId,
    documentID,
    dokkaToken,
    preview,
    unsafe,
  });
}

export function getDocumentUrl(companyId: string, documentId: string): string {
  return generatePath(ROUTES_PATH.DOCUMENT.absolute, {
    companyId,
    documentId,
  });
}
