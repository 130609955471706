// @flow
import { compose } from 'redux';
import { rules, addError } from 'components/Form/validation';
import type { Map } from 'immutable';

type Values = Map<string, string | number>;

const passwordLessAuthValidator = (values: Map<string, string>) => (errors, fieldName) => {
  const val = values.get(fieldName, '');
  switch (fieldName) {
    case 'email':
      return compose(addError(fieldName, rules.emailExtended(val)), addError(fieldName, rules.required(val)))(errors);

    default:
      return errors;
  }
};

export const passwordLessAuthValidate = (values: Values) => ['email'].reduce(passwordLessAuthValidator(values), {});
