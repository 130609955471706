// @flow
import _ from 'lodash';

type IdType = string | string[];

export const DISABLED_MESSAGES = 'disabled_messages';

export const getDisabledMessages = (): string[] => {
  const messages = window.localStorage.getItem(DISABLED_MESSAGES);
  return messages ? JSON.parse(messages) : [];
};

export const setDisabledMessages = (id: IdType) =>
  window.localStorage.setItem(DISABLED_MESSAGES, JSON.stringify(_.castArray(id)));

export const addDisabledMessages = (id: IdType) => {
  setDisabledMessages(_.uniq([...getDisabledMessages(), ..._.castArray(id)]));
};

export const isDisabledMessage = (id: string) => getDisabledMessages().includes(id);

export const clearUnusedDisabledMessages = (id: IdType) => {
  setDisabledMessages(_.intersection(getDisabledMessages(), _.castArray(id)));
};
