// @flow
import { makeStyles } from '@mui/styles';
import { mqAuthHeight } from 'pages/authPage/Layout/StyledComponents';

export default makeStyles((theme) => ({
  asideCarousel: {
    width: '80%',

    [`@media ${mqAuthHeight}`]: {
      width: '100%',
    },

    direction: ({ isRtl }) => (isRtl ? 'ltr' : 'ltr'),
    '& .carousel': {
      '& .control-dots': {
        left: 0,

        '& .dot': {
          backgroundColor: theme.palette.common.white,
          width: 6,
          height: 6,
          boxShadow: 'none',
          margin: [0, 2],
        },
        '& .dot.selected': {
          width: 14,
          borderRadius: 28,
        },
      },
      '& .slide': {
        height: 510,

        [`@media ${mqAuthHeight}`]: {
          height: 640,
        },

        '& .legend': {
          color: theme.palette.common.white,
          fontSize: 28,
          fontWeight: 800,
          background: 'none',
          width: '100%',
          marginLeft: 0,
          left: 0,
          right: 'auto',
          opacity: 1,
        },
      },
    },
  },

  item: {
    display: 'flex',
  },

  subtitle: {
    display: 'block',
    fontSize: 14,
    color: theme.palette.common.white,
    fontWeight: 400,
    marginTop: 5,
  },
}));
