/* @flow */
import React from 'react';

import { Dispatch } from 'redux';
import { addAppStatusAction, removeAppStatusAction } from 'domain/env';
import { connect } from 'react-redux';

const message = {
  id: 'app.offline',
  defaultMessage: 'No internet access. Actions may not work until you are back online',
};

type Props = {
  addAppStatus: Dispatch<typeof addAppStatusAction>,
  removeAppStatus: Dispatch<typeof removeAppStatusAction>,
};

class ConnectionStatus extends React.Component<Props, {}> {
  componentDidMount() {
    window.addEventListener('online', this.removeMessage);
    window.addEventListener('offline', this.addMessage);
  }

  componentWillUnmount() {
    // offline
    window.removeEventListener('online', this.removeMessage);
    window.removeEventListener('offline', this.addMessage);
  }

  removeMessage = () => {
    this.props.removeAppStatus(message.id);
  };

  addMessage = () => {
    this.props.addAppStatus(message);
  };

  render() {
    return null;
  }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  addAppStatus: addAppStatusAction,
  removeAppStatus: removeAppStatusAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(ConnectionStatus);
