// @flow
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { usePopupState, bindTrigger, bindMenu } from 'material-ui-popup-state/hooks';
import { signOutAction, userProfileSelector } from 'domain/env';
import ROUTES_PATH from 'domain/router/routesPathConfig';
import { useTheme } from '@mui/material';

import RouterLink from 'components/mui/Router/RouterLink';
import Avatar from 'components/mui/Avatar';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';

import useSettingsLink from 'components/mui/Layouts/components/AccountDropdown/useSettingsLink';
import useSaveBackUrl from 'components/mui/Layouts/components/AccountDropdown/useSaveBackUrl';

import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';

import elements from 'components/elements';
import { generatePath } from 'react-router-dom';

type TAccountDropdown = {};

const mapStateToProps = (state) => ({
  user: userProfileSelector(state),
});

// TODO: add links for PROFILE
const AccountDropdown: React$StatelessFunctionalComponent<TAccountDropdown> = () => {
  const dispatch = useDispatch();
  const { palette } = useTheme();
  const { user } = useSelector(mapStateToProps);
  const { formatMessage } = useIntl();
  const popupState = usePopupState({ variant: 'popover', popupId: 'AccountDropdown' });
  const settingsLink = useSettingsLink();
  const saveBackUrl = useSaveBackUrl();

  const onClickLogout = () => {
    dispatch(
      signOutAction({
        reason: process.env.REACT_APP_ACCESS_DENIED_CODE,
      }),
    );

    popupState.close();
  };

  const onClickLink = () => {
    popupState.close();
    saveBackUrl();
  };

  return (
    <>
      <IconButton
        size="small"
        sx={{ boxShadow: `0 0 0 1px ${popupState.isOpen ? palette.primary.main : palette.grey[400]} inset` }}
        {...bindTrigger(popupState)}
        data-element={elements.header.profile.container}
      >
        <Avatar src={user.pic} sx={{ width: 32, height: 32 }} />
      </IconButton>
      <Menu
        {...bindMenu(popupState)}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <ListItem>
          <ListItemText primary={user.username} secondary={user.email} primaryTypographyProps={{ fontWeight: '500' }} />
        </ListItem>
        <Divider sx={{ mb: 1 }} />
        <MenuItem
          component={RouterLink}
          href={generatePath(ROUTES_PATH.PROFILE_SETTINGS_MAIN.absolute)}
          onClick={onClickLink}
          data-element={elements.header.profile.menuItem}
        >
          <ListItemIcon>
            <AccountCircleOutlinedIcon />
          </ListItemIcon>
          <ListItemText>
            {formatMessage({
              id: 'header.menu.profile',
              defaultMessage: 'Profile',
            })}
          </ListItemText>
        </MenuItem>
        {settingsLink && (
          <MenuItem
            component={RouterLink}
            href={settingsLink}
            onClick={onClickLink}
            data-element={elements.header.profile.menuItem}
          >
            <ListItemIcon>
              <SettingsOutlinedIcon />
            </ListItemIcon>
            <ListItemText>
              {formatMessage({
                id: 'header.menu.settings',
                defaultMessage: 'Settings',
              })}
            </ListItemText>
          </MenuItem>
        )}
        <Divider />
        <MenuItem onClick={onClickLogout} data-element={elements.header.profile.menuItem}>
          <ListItemText primaryTypographyProps={{ textTransform: 'uppercase', fontWeight: '500' }}>
            {formatMessage({
              id: 'header.menu.logout',
              defaultMessage: 'Logout',
            })}
          </ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
};

export default AccountDropdown;
