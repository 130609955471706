// @flow
import React, { memo, useState } from 'react';
import { compose } from 'redux';
import { type FormProps, Field, reduxForm } from 'redux-form/immutable';
import { passwordLessAuthValidate } from 'pages/authPage/Form/validation';
import { FormattedMessage, useIntl } from 'react-intl';
import { useTheme } from '@mui/material';

import AuthPaperBox from 'pages/authPage/Layout/PaperBox';
import TextFieldForm from 'components/mui/Form/TextField/TextFieldForm';
import CheckboxBase from 'components/mui/Form/Checkbox/CheckboxBase';
import FormControlLabel from '@mui/material/FormControlLabel';
import LinkMui from '@mui/material/Link';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Message from 'components/Form/Message';

import IconClockGlass from 'components/Icons/clockglass';

type TPasswordLessFormAuth = {
  isExpired: boolean,
} & FormProps;

const PasswordLessFormAuth: React$StatelessFunctionalComponent<TPasswordLessFormAuth> = ({
  handleSubmit,
  invalid,
  submitting,
  error,
  isExpired,
}: TPasswordLessFormAuth) => {
  const intl = useIntl();
  const { labelOptions } = useTheme();

  const [accept, setAccept] = useState(true);

  const toggleAccept = () => {
    setAccept(!accept);
  };

  return (
    <AuthPaperBox>
      <form onSubmit={handleSubmit}>
        {isExpired && (
          <Box mb={3} textAlign="center">
            <IconClockGlass size={64} />
          </Box>
        )}
        <Box textAlign={isExpired ? 'center' : 'left'}>
          <Typography variant="h5">
            {isExpired ? (
              <FormattedMessage id="auth.passwordLess.expired.title" defaultMessage="Something went wrong" />
            ) : (
              <FormattedMessage id="auth.passwordLess.login.title" defaultMessage="Login" />
            )}
          </Typography>
          <Typography variant="subtitle2" color="text.secondary" fontWeight="400" mb={3}>
            {isExpired ? (
              <FormattedMessage
                id="auth.passwordLess.expired.text"
                defaultMessage="Sign in was not successful or access link is expired. Please, request new access link"
              />
            ) : (
              <FormattedMessage
                id="auth.passwordLess.login.subtitle"
                defaultMessage="Enter your email address to log in"
              />
            )}
          </Typography>
        </Box>
        <Message isWide textAlign="center" meta={{ touched: !!error, error }} />
        <Field
          name="email"
          placeholder={intl.formatMessage({ id: 'placeholders.email', defaultMessage: 'Email' })}
          component={TextFieldForm}
          fullWidth
          type="text"
        />
        {labelOptions.termsAndConditionLink && !isExpired && (
          <FormControlLabel
            control={<CheckboxBase />}
            checked={accept}
            onChange={toggleAccept}
            label={
              <Typography variant="body2">
                <FormattedMessage id="auth.passwordLess.accept" defaultMessage="I agree to the" />
                &nbsp;
                <LinkMui
                  href={labelOptions.termsAndConditionLink}
                  underline="hover"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FormattedMessage id="auth.passwordLess.terms" defaultMessage="Terms of Use" />
                </LinkMui>
              </Typography>
            }
          />
        )}
        <Button
          type="submit"
          fullWidth
          variant="contained"
          size="large"
          sx={{ mt: 2 }}
          disabled={invalid || submitting || !accept}
        >
          <FormattedMessage id="auth.passwordLess.login.title" defaultMessage="Login" />
        </Button>
      </form>
    </AuthPaperBox>
  );
};

export default compose(
  reduxForm({
    form: 'passwordLessAuthForm',
    validate: passwordLessAuthValidate,
  }),
)(memo(PasswordLessFormAuth));
