// @flow
import React, { useEffect, useRef, useCallback, useState } from 'react';

import {
  WidgetContainer,
  KeyboardArrowRightIcon,
  List,
  ListItem,
  Container,
  Wrapper,
  PageNumber,
  ToggleButton,
  useStyles,
} from 'pages/document/DocumentPagesNavigationBar/StyledComponents';
import PdfViewerDocument from 'components/PdfViewer';
import { Page } from 'react-pdf';

type FileT = {
  url: string,
  password: string,
};

type Props = {
  children: React$Node,
  file: FileT,
  pageParams: any,
  onSelect: (n: number) => void,
  focusedPage: number,
  isOpen: boolean,
  setOpen: (s: boolean) => void,
  password: string,
};

const DocumentNavBar = ({ file, pageParams, onSelect, focusedPage, isOpen, setOpen, children, password }: Props) => {
  const refScrollBox = useRef(null);
  const refItems = useRef([]);

  const [isRenderStart, setRenderStart] = useState(false);
  const classes = useStyles({ isOpen });

  const getRotationByIndex = useCallback(
    (pageIndex) => {
      const { rotate, embdRotate } = pageParams.get(pageIndex);

      return (rotate + embdRotate) % 360;
    },
    [pageParams],
  );

  const setRefScrollBox = useCallback((el) => {
    refScrollBox.current = el;
  }, []);

  const toggleOpen = useCallback(() => {
    setOpen(!isOpen);
  }, [setOpen, isOpen]);

  const setItemEl = useCallback((el) => {
    refItems.current.push(el);
  }, []);

  const scrollIntoView = useCallback((page) => {
    const el = refItems.current[page];
    if (el && refScrollBox.current) {
      const { top: boxTop, bottom: bottomTop } = refScrollBox.current.getBoundingClientRect();
      const { top, bottom } = el.getBoundingClientRect();
      if (boxTop > top) {
        el.scrollIntoView({ block: 'start' });
      }
      if (bottom > bottomTop) {
        el.scrollIntoView({ block: 'end' });
      }
    }
  }, []);

  useEffect(() => {
    // it must happen once when we open the preview first time
    if (!isRenderStart && isOpen) {
      setRenderStart(true);
      setTimeout(() => {
        scrollIntoView(focusedPage);
      }, 1000);
    }
  }, [isOpen, isRenderStart, focusedPage, scrollIntoView]);

  useEffect(() => {
    scrollIntoView(focusedPage);
  }, [focusedPage, scrollIntoView]);

  return (
    <Wrapper isOpen={isOpen}>
      <ToggleButton onClick={toggleOpen}>
        <KeyboardArrowRightIcon isOpen={isOpen} color="primary" />
      </ToggleButton>

      {isRenderStart && (
        <Container ref={setRefScrollBox} isOpen={isOpen}>
          <PdfViewerDocument
            className={classes.pdfDocWrapper}
            file={file}
            loading={<div />}
            width={100}
            onPassword={(cp) => {
              cp(password);
            }}
          >
            <List component="div" spacing={1.5}>
              {pageParams.map(({ idx }) => (
                <ListItem
                  key={`${idx}`}
                  onClick={() => onSelect(idx)}
                  ref={setItemEl}
                  selected={focusedPage === idx}
                  index={idx}
                >
                  <Page
                    renderTextLayer={false}
                    renderAnnotationLayer={false}
                    width={80}
                    rotate={getRotationByIndex(idx)}
                    scale={1}
                    pageNumber={idx + 1}
                  />
                  <PageNumber active={focusedPage === idx}>{idx + 1}</PageNumber>
                </ListItem>
              ))}
            </List>
          </PdfViewerDocument>
        </Container>
      )}
      <WidgetContainer>{children}</WidgetContainer>
    </Wrapper>
  );
};

export default DocumentNavBar;
