/* @flow */
import * as React from 'react';
import { type Map } from 'immutable';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withStyles } from '@mui/styles';
import cx from 'classnames';
import { currentConnectedERPselector } from 'domain/settings';
import { rtlEnable } from 'domain/env';
import { isJeLayoutRTLSelector } from 'domain/journal';
import { type EntriesType } from 'domain/journal/helper';
import { GridTable as Grid } from '../grid';
import { getMetaRange } from '../helpers';
import { isMandatory, isActive, getDataCell } from './helpers';
import c from '../constatnt';

type Props = {|
  classes: {|
    [key: string]: string,
  |},
  active: ?string,
  data: Map<string, EntriesType>,
  getItem: (d: ?EntriesType) => React$Node,
  rtl: boolean,
  isJeLayoutRTL: boolean,
|};

const sheet = (theme) => ({
  container: {
    position: 'relative',
  },
  row: {
    '&:nth-child(2n)': {
      height: c.ROW_HEIGHT + c.BLOCK_GAP,
      '& td': {
        paddingBottom: c.BLOCK_GAP,
      },
    },
  },
  col: {
    padding: [0, 11],
    '& [data-element="je.fakeCell.value"]': {
      borderBottom: '1px solid #DEE2E6',
      paddingLeft: 0,
    },
    '& [data-element="je.description"], & [data-element="je.readonly"]': {
      padding: 0,
    },
  },
  mandatory: {
    '& [data-element="je.fakeCell.value"]': {
      borderBottomColor: theme.palette.error.main,
    },
  },
  active: {
    zIndex: 16,
    '& [data-element="je.fakeCell.value"], & [data-element="je.autocomplete.field"]': {
      borderBottom: `2px solid ${theme.palette.primary.light}`,
    },
    '& [data-element="je.hintinput.field"], & [data-element="je.literal.field"] ': {
      borderBottom: `2px solid ${theme.palette.primary.light}`,
      padding: 0,
    },
  },
});

class SettingsLayout extends React.Component<Props> {
  get meta() {
    const { data, rtl, isJeLayoutRTL } = this.props;
    return getMetaRange(data, rtl || isJeLayoutRTL);
  }

  getRowStyle = () => {
    const { classes } = this.props;
    return classes.row;
  };

  getCollStyle = (col: string, row: number) => {
    const { classes, active } = this.props;
    const entry = this.getData(row, col);
    return cx(classes.col, {
      [classes.mandatory]: isMandatory(entry),
      [classes.active]: isActive(col, row, active),
    });
  };

  getData = (row: number, col: string) => getDataCell(this.props.data, row, col);

  getItem = (row: number, col: string) => this.props.getItem(this.getData(row, col));

  render() {
    const { classes } = this.props;
    return (
      <Grid
        meta={this.meta}
        className={classes.container}
        getRowStyle={this.getRowStyle}
        getCollStyle={this.getCollStyle}
        getItem={this.getItem}
        prevItem={() => null}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  erp: currentConnectedERPselector(state),
  rtl: rtlEnable(state),
  isJeLayoutRTL: isJeLayoutRTLSelector(state),
});

export default compose(connect(mapStateToProps), withStyles(sheet))(SettingsLayout);
