// @flow
import { Map } from 'immutable';
import type { FavoritesStore, DashboardStore, TodoItemsStore } from './type.js.flow';
import { DashboardFactory, complitedTodoFilter } from './helpers';
import * as A from './actions';

type ActionType<T> = {
  type: string,
  payload: T,
};

export const reducer = {
  dashboard(state: DashboardStore = DashboardFactory(), action: ActionType<DashboardStore>) {
    switch (action.type) {
      case A.workerStartAction.type:
        return DashboardFactory();

      case A.getDashboardStats.success:
        return state.merge(action.payload).set('responded', true);

      default:
        return state;
    }
  },
  favorites(state: FavoritesStore = new Map(), action: ActionType<DashboardStore>) {
    switch (action.type) {
      case A.getFavoritesAction.success:
        return Map(action.payload);

      case A.deleteFavoritesAction.success:
        return state.delete(action.payload.ID);

      case A.clearFavoritesAction.type:
        return new Map();

      default:
        return state;
    }
  },
  todos(state: TodoItemsStore = new Map(), action: ActionType<*>) {
    switch (action.type) {
      case A.getTodoItemsAction.success:
        return action.payload;
      case A.updateTodoItemAction.success:
        // @to-do solve this is saga. delete should cancel update. Try race effect
        return state.has(action.payload.id)
          ? state.update(action.payload.id, (todo) => todo.merge(action.payload))
          : state;
      case A.createTodoItemAction.success:
        return state.set(action.payload.id, action.payload);
      case A.removeTodoItemAction.success:
        return state.delete(action.payload.id);

      case A.removeCompletedTodoAction.success:
        return state.filterNot(complitedTodoFilter);
      default:
        return state;
    }
  },
};
