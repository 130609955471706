export default (theme) => ({
  spinner: {
    position: ({ position }) => position || 'fixed',
    width: '100%',
    height: '100%',
    left: '0',
    top: '0',
    // this allows to shadow doc page column management icon
    // and vgrid sticky header
    zIndex: ({ position }) => (position === 'fixed' ? 100 : 10),
    backgroundColor: 'rgba(255, 255, 255, 0.9)',
    opacity: ({ loading }) => Number(loading || 0),
    transition: '0.3s opacity',
    transitionDelay: '0.15s',
    pointerEvents: ({ loading }) => (loading ? 'all' : 'none'),
  },
  container: {
    position: 'absolute',
    transform: 'translate(-50%, -50%)',
    marginRight: '-50%',
    top: '50%',
    left: '50%',
  },
  bullet: {
    position: 'absolute',
    padding: 7,
    borderRadius: '50%',
    background: theme.colors.primaryLight,
    animation: 'loading 1s ease-in-out 0s infinite',
    '&:nth-child(1)': {
      animationDelay: '0s',
    },
    '&:nth-child(2)': {
      animationDelay: '0.15s',
    },
    '&:nth-child(3)': {
      animationDelay: '0.3s',
    },
  },
});
