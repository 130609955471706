// @flow
// helpers
import { flow, filter, map } from 'lodash/fp';
import { sessionST } from 'lib/sessionStorage';

export const config = {
  key: process.env.REACT_APP_GCP_PRIVATE_KEY,
};

type TIncomingOption = {| code: string, name: string |};

const TranslationOptionFactory = (option: TIncomingOption) => ({
  value: option.code,
  label: option.name,
});

export type TOption = $Call<typeof TranslationOptionFactory>;

export const TranslateValueAdapter = (value: string) => (value === 'he' ? 'iw' : value);

const removeHeOption = (option: TIncomingOption) => option.code !== 'he';

export const TranslationListAdapter = (translations: Array<TIncomingOption> = []) =>
  flow(filter(removeHeOption), map(TranslationOptionFactory))(translations);

export const getTranslationsFromSessionStorage = () => {
  const translations = sessionST().availableTranslationList().get();

  return JSON.parse(translations);
};

export const setTranslationsInSessionStorage = (traslations: TOption[]) =>
  sessionST().availableTranslationList().set(JSON.stringify(traslations));

export const isLocalesEqual = (from?: string, to?: string) => {
  return from === to;
};

const getTranslationOptionID = (userID: string) => `preferredtranslationOption-${userID}`;

export const getOptionFromLS = (userID: string) => {
  return window.localStorage.getItem(getTranslationOptionID(userID));
};
export const setOptionToLS = (userID: string, value: string) => {
  window.localStorage.setItem(getTranslationOptionID(userID), value);
};
