import { useMemo } from 'react';

const useAgGridColumnTypes = () =>
  useMemo(
    () => ({
      boolean: {},
      string: {},
      number: {},
      list: {},
      multi_list: {},
    }),
    [],
  );

export default useAgGridColumnTypes;
