/* @flow */

import _ from 'lodash';
import indexedDb from 'indexedDb';

export type LimitOption = {| min?: number, max?: number |};
export type TColumnsWidth = { [key: string]: number };

export const limit = (value: number, { min, max }: LimitOption) => {
  const minCond = min ? [[(v) => v < min, () => min]] : [];
  const maxCond = min ? [[(v) => v > max, () => max]] : [];
  return _.cond([...minCond, ...maxCond, [_.stubTrue, () => value]])(value);
};

export const updateColumnsWidth = (columnsWidth: TColumnsWidth, updateFn: (v: number) => number) =>
  _.mapValues(columnsWidth, updateFn);

export async function setTableColsWidth(tableName: string, width: TColumnsWidth) {
  return indexedDb.tableColsWidth.put({ tableName, width });
}

export async function getTableColsWidth(tableName: string) {
  return indexedDb.tableColsWidth.get(tableName);
}

export async function cleanTableColsWidth(tableName) {
  return indexedDb.tableColsWidth.delete(tableName);
}
