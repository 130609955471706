// @flow
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { useConfirm } from 'material-ui-confirm';
import type { LocationShape } from 'react-router-dom';

import BackToArrowBase from 'components/mui/Layouts/components/BackToArrowBase';

type TBackToArrow = {
  href: string | LocationShape,
  hasConfirmBeforeLeave: boolean,
};

const BackToArrow: React$StatelessFunctionalComponent<TBackToArrow> = ({ href, hasConfirmBeforeLeave }) => {
  const navigate = useNavigate();
  const { formatMessage } = useIntl();
  const confirm = useConfirm();

  const handleClick = () => {
    confirm({
      title: formatMessage({
        id: 'document.leave.confirm.title',
        defaultMessage: 'Approval flow was not activated',
      }),
      description: formatMessage({
        id: 'document.leave.confirm.placeholder',
        defaultMessage: 'Are you sure you want to leave the document without Approval flow activation?',
      }),
      confirmationText: formatMessage({
        id: 'button.leave',
        defaultMessage: 'Leave',
      }),
      confirmationButtonProps: {
        color: 'error',
      },
      cancellationText: formatMessage({
        id: 'button.cancel',
        defaultMessage: 'Cancel',
      }),
    }).then(() => {
      navigate(href);
    });
  };

  return <BackToArrowBase path={href} onClick={hasConfirmBeforeLeave && handleClick} />;
};

export default BackToArrow;
