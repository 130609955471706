// @flow
// $FlowFixMe outdated flow types for React
import React, { memo } from 'react';
import { OrderedSet } from 'immutable';
import { useSelector } from 'react-redux';

import { getDocumentsApprovers } from 'domain/documents';

import useDotsMenu, { type TUseDotsMenu } from 'pages/company/CompanyBulkPanel/DotsMenu/useDotsMenu';
import useToggle from 'hooks/useToggle';
import DropdownButton from 'components/mui/DropdownButton';

import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';
import DialogMoveDocumentsToCompany from 'pages/common/Dialog/DialogMoveDocumentsToCompany';
import DialogManageBulkApprovalFlow from 'pages/common/Dialog/DialogManageBulkApprovalFlow';
import DialogBulkReplaceApprover from 'pages/common/Dialog/DialogBulkReplaceApprover';
import { Divider } from 'pages/company/CompanyBulkPanel/StyledComponents';

export type TDotsMenu = {
  selectedIds: OrderedSet<string>,
  clearSelection: () => void,
};

const DotsMenu: React$StatelessFunctionalComponent<TDotsMenu> = ({ selectedIds, clearSelection }) => {
  const [toggleMoveDialog, onToggleMoveDialog] = useToggle(false);
  const [toggleBulkApproval, onToggleBulkApproval] = useToggle(false);
  const [toggleReplaceApprover, onToggleReplaceApprover] = useToggle(false);

  const approversForBulkReplace = useSelector(getDocumentsApprovers(selectedIds));

  const itemsOptions = {
    moveToItemOptions: {
      disabled: false,
      onClick: onToggleMoveDialog,
    },
    bulkApprovalItemOptions: {
      disabled: false,
      onClick: onToggleBulkApproval,
    },
    replaceApprover: {
      disabled: !approversForBulkReplace.length,
      onClick: onToggleReplaceApprover,
    },
  };

  const { menuOptions }: TUseDotsMenu = useDotsMenu({ selectedIds, clearSelection, itemsOptions });

  return (
    <>
      {menuOptions.length ? (
        <>
          <Divider />
          <DropdownButton
            options={menuOptions}
            BtnIcon={<MoreHorizOutlinedIcon />}
            IconButtonProps={{ size: 'medium', color: 'default', sx: { '&:hover': { color: 'primary.main' } } }}
          />
          {toggleMoveDialog && (
            <DialogMoveDocumentsToCompany
              docIds={selectedIds}
              onClose={onToggleMoveDialog}
              clearSelection={clearSelection}
              selectedDocumentsCount={selectedIds.size}
            />
          )}
          <DialogManageBulkApprovalFlow
            open={toggleBulkApproval}
            onClose={onToggleBulkApproval}
            selectedIDs={selectedIds}
          />
          <DialogBulkReplaceApprover
            open={toggleReplaceApprover}
            onClose={onToggleReplaceApprover}
            selectedIDs={selectedIds}
            clearSelection={clearSelection}
          />
        </>
      ) : null}
    </>
  );
};

export default memo(DotsMenu);
