// @flow
import React, { memo, useCallback, useMemo, useState, useEffect } from 'react';
import { Set } from 'immutable';
import { useSelector, useDispatch } from 'react-redux';
import { promisify } from 'lib/helpers';
import { useIntl } from 'react-intl';
import { useNavigate, generatePath } from 'react-router-dom';

import {
  moveDocumentsToCompanyAction,
  moveDocumentToCompanyAction,
  currentCompanySelector,
  isMixedDocumentsForBulkMoveToCompanySelector,
} from 'domain/documents';
import { getAllOrganizationCompanies } from 'domain/companies';

import Dialog from 'components/mui/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import Autocomplete from 'components/mui/Form/Autocomplete/AutocompleteBase';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import ROUTES_PATH from 'domain/router/routesPathConfig';
import { navigationSelector } from 'domain/env';

export type TDialogMoveDocumentsToCompany = {
  documentId?: string,
  docIds?: Array<string>,
  clearSelection?: () => void,
  onClose: () => void,
  backLink?: string,
  selectedDocumentsCount?: string,
  linkID?: string,
};

const mapStateToProps = (state) => ({
  currentCompanyId: currentCompanySelector(state),
  navigation: navigationSelector(state),
  isMixedDocumentsForBulkMoveToCompany: (docIds) => isMixedDocumentsForBulkMoveToCompanySelector(docIds)(state),
});

const DialogMoveDocumentsToCompany: React$StatelessFunctionalComponent<TDialogMoveDocumentsToCompany> = ({
  docIds = Set(),
  documentId,
  clearSelection,
  onClose,
  backLink,
  selectedDocumentsCount,
  linkID,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { formatMessage } = useIntl();
  const { currentCompanyId, navigation, isMixedDocumentsForBulkMoveToCompany } = useSelector(mapStateToProps);
  const isMixedBulkMove = docIds.size > 0 ? isMixedDocumentsForBulkMoveToCompany(docIds) : false;

  const [selectedCompany, setSelectedCompany] = useState(null);
  const [isBusy, setIsBusy] = useState(false);
  const [companies, setCompanies] = useState([]);
  const action = documentId && !linkID ? moveDocumentToCompanyAction : moveDocumentsToCompanyAction;

  const onSelectChange = useCallback((event, list) => {
    setSelectedCompany(list);
  }, []);

  const onConfirm = useCallback(async () => {
    const fn = (arg) => dispatch(action({ ...arg }));
    await promisify(fn, {
      data: {
        toCompanyID: selectedCompany.value,
        documentID: documentId || docIds,
        companyName: selectedCompany.label,
        linkID,
        selectedDocumentsCount,
      },
    });

    if (backLink) {
      const nextDocumentId = navigation.get('nextDocId');
      const totalNumberOfDocuments = navigation.get('totalNumberOfDocuments', 1);

      const linkNext =
        nextDocumentId && totalNumberOfDocuments > 1
          ? generatePath(ROUTES_PATH.DOCUMENT.absolute, {
              companyId: currentCompanyId,
              documentId: nextDocumentId,
            })
          : backLink;

      navigate(linkNext, { replace: true });
    }

    if (typeof clearSelection === 'function') {
      clearSelection();
    } else {
      onClose();
    }
  }, [
    dispatch,
    clearSelection,
    action,
    onClose,
    docIds,
    documentId,
    selectedCompany,
    backLink,
    currentCompanyId,
    navigation,
    selectedDocumentsCount,
    linkID,
  ]);

  const isDisabledConfirm = useMemo(
    () => selectedCompany === null || selectedCompany.value === currentCompanyId,
    [selectedCompany, currentCompanyId],
  );

  const title = formatMessage({
    id: linkID ? 'confirm.moveLinkedToCompany.title' : 'confirm.moveToCompany.title',
    defaultMessage: linkID ? 'Move linked bundle to' : 'Move document(s) to',
  });

  const notice = useMemo(() => {
    if (linkID) {
      return formatMessage({
        id: 'confirm.moveLinkedToCompany.notice.linked',
        defaultMessage: 'You are about to move linked documents to another company, please select one',
      });
    }
    if (isMixedBulkMove) {
      return formatMessage({
        id: 'confirm.moveLinkedToCompany.notice.both',
        defaultMessage:
          'At least one document is part of a linked document. This document and all the linked one will be moved together',
      });
    }

    return null;
  }, [isMixedBulkMove, linkID, formatMessage]);

  useEffect(() => {
    setIsBusy(true);

    new Promise((resolve, reject) => {
      dispatch(getAllOrganizationCompanies({ resolve, reject, params: { format: 'short' } }));
    })
      .then((data) => {
        setCompanies(data);
      })
      .finally(() => setIsBusy(false));
  }, [dispatch]);

  return (
    <Dialog
      open
      onClose={onClose}
      title={title}
      withActions={false}
      withContent={false}
      PaperProps={{ sx: { overflow: 'inherit' } }}
    >
      <DialogContent>
        {notice && (
          <Typography variant="body2" mb={1.25}>
            {notice}
          </Typography>
        )}
        <Autocomplete
          disablePortal
          id="selectCompany"
          fullWidth
          options={companies}
          onChange={onSelectChange}
          sx={{ mt: 1 }}
          loading={isBusy}
          renderInput={(params) => (
            <TextField
              {...params}
              label={formatMessage({ id: 'confirm.moveToCompany.company', defaultMessage: 'Company' })}
            />
          )}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={onClose}>
          {formatMessage({
            id: 'button.cancel',
            defaultMessage: 'Cancel',
          })}
        </Button>
        <Button onClick={onConfirm} disabled={isDisabledConfirm}>
          {formatMessage({
            id: 'button.confirm',
            defaultMessage: 'Confirm',
          })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default memo(DialogMoveDocumentsToCompany);
