// @flow
import React, { useCallback } from 'react';

import TooltipForButton from 'components/mui/Tooltip/TooltipForButton';
import { ControlButton } from 'pages/document/components/DocumentPageControls/components/StyledComponents';

import ZoomInOutlinedIcon from '@mui/icons-material/ZoomInOutlined';
import ZoomOutOutlinedIcon from '@mui/icons-material/ZoomOutOutlined';
import ZoomOutMapOutlinedIcon from '@mui/icons-material/ZoomOutMapOutlined';

type Props = {
  onZoom: (direction: 1 | -1 | 0) => void,
  onReset: (e: MouseEvent) => void,
  getIsDisabledZoomButton: (direction: 1 | -1 | 0) => boolean,
  isSmallActionButton?: boolean,
};

const DocumentZoomControls: React$StatelessFunctionalComponent<Props> = ({
  onZoom,
  onReset,
  getIsDisabledZoomButton,
  isSmallActionButton = false,
}) => {
  const onZoomOut = useCallback(
    (e) => {
      e.stopPropagation();
      onZoom(-1);
    },
    [onZoom],
  );
  const onZoomIn = useCallback(
    (e) => {
      e.stopPropagation();
      onZoom(1);
    },
    [onZoom],
  );

  return (
    <>
      <TooltipForButton t={{ id: 'document.show.bottomBar.zoomOut', defaultMessage: 'Zoom Out' }}>
        <ControlButton
          size={isSmallActionButton ? 'small' : 'medium'}
          disabled={getIsDisabledZoomButton(-1)}
          onClick={onZoomOut}
        >
          <ZoomOutOutlinedIcon />
        </ControlButton>
      </TooltipForButton>

      <TooltipForButton t={{ id: 'document.show.bottomBar.resetZoom', defaultMessage: 'Reset Zoom' }}>
        <ControlButton
          size={isSmallActionButton ? 'small' : 'medium'}
          disabled={getIsDisabledZoomButton(0)}
          onClick={onReset}
        >
          <ZoomOutMapOutlinedIcon />
        </ControlButton>
      </TooltipForButton>

      <TooltipForButton t={{ id: 'document.show.bottomBar.zoomIn', defaultMessage: 'Zoom In' }}>
        <ControlButton
          size={isSmallActionButton ? 'small' : 'medium'}
          disabled={getIsDisabledZoomButton(1)}
          onClick={onZoomIn}
        >
          <ZoomInOutlinedIcon />
        </ControlButton>
      </TooltipForButton>
    </>
  );
};
export default DocumentZoomControls;
