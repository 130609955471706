import AutocompleteVirtualized from 'components/mui/Form/Autocomplete/AutocompleteVirtualized';
import TextFieldMui from '@mui/material/TextField';
import { styled } from '@mui/material/styles';

export const MultipleAutocompleteVirtualized = styled(AutocompleteVirtualized, { label: 'AutocompleteVirtualized' })(
  ({ theme }) => ({
    padding: theme.spacing(1, 1, 1, 0.5),

    '& .MuiFormControl-root': {
      backgroundColor: theme.palette.grey[300],
      borderRadius: 8,
      height: '100%',
    },
  }),
);

export const TextField = styled(TextFieldMui, { label: 'AutocompleteTextField' })(({ theme }) => ({
  '& .MuiInputBase-root': {
    '& .MuiInputBase-input': {
      fontSize: theme.typography.pxToRem(13),
      marginTop: theme.spacing(0.5),
      paddingLeft: theme.spacing(1),
    },
  },
}));
