// @flow
import React, { memo, useCallback, useMemo } from 'react';
import { Map } from 'immutable';
import { useDispatch } from 'react-redux';
import { passwordLessAuthAccessLinkAction } from 'domain/env';

import PasswordLessFormAuth from 'pages/authPage/PasswordLessAuth/Form/form';

type Props = {
  isExpired?: boolean,
};

const PasswordLessAuth: React$StatelessFunctionalComponent<Props> = ({ isExpired = false }) => {
  const dispatch = useDispatch();
  const initialValues = useMemo(
    () =>
      Map({
        email: '',
      }),
    [],
  );

  const handleSubmit = useCallback(
    (values) =>
      new Promise((resolve, reject) => {
        dispatch(passwordLessAuthAccessLinkAction({ values, resolve, reject }));
      }).then(),
    [dispatch],
  );

  return <PasswordLessFormAuth initialValues={initialValues} onSubmit={handleSubmit} isExpired={isExpired} />;
};

export default memo(PasswordLessAuth);
