// @flow
import { List } from 'immutable';
import { UserFactory, EnhancedUserFactory } from 'domain/organizationUser/helpers';
import { currentCompanySelector, isDocumentConfidentialSelector } from 'domain/documents/documentSelector';
import { selector } from 'lib/selectors';
import type { UserList, EnhancedUserList, User } from 'domain/organizationUser/types.js.flow';
import type { S as DokkaStore } from '../types.js.flow';
import type { ChatUserRecords } from 'domain/chat/types.js.flow';

type SelectorType<T> = (s: DokkaStore) => T;

export const organizationUsersSelector: SelectorType<UserList> = (state) => state.organizationUser;

export const organizationUsersForConfidentialDocSelector: SelectorType<ChatUserRecords> = selector(
  (state) => state.chat.users,
  isDocumentConfidentialSelector,
  // eslint-disable-next-line max-len
  (users: ChatUserRecords, isDocumentConfidential: boolean) =>
    !isDocumentConfidential
      ? users.filter(({ role }) => role !== 'supplier')
      : users.filter(
          (user) =>
            [
              'confidential-user',
              'confidential-restricted-user',
              'accountant',
              'limited-accountant',
              'restricted-accountant',
            ].includes(user.role), // user role have access to confidentialdocs
        ),
);

export const getOrganizationUserByIdSelector =
  (id: ?string): SelectorType<User> =>
  (state) =>
    state.organizationUser.find((_) => _.id === id);

// finds bookkeeper by id or returns empty bookkeeper
export const organizationUserByIdSelector =
  (id: ?string): SelectorType<User> =>
  (state) =>
    state.organizationUser.find((_) => _.id === id) || UserFactory();

export const organizationEnhancedUsersSelector: SelectorType<EnhancedUserList> = selector(
  organizationUsersSelector,
  currentCompanySelector,
  (users: UserList, companyId: string): EnhancedUserList =>
    List(
      users.map((user) => {
        // pure 'Record -> another Record' transform, find a way to do it better
        const enhancedUser = {
          ...user.toObject(),
          assigned: user.companies_id.has(companyId),
          assignedCount: user.companies_id.size,
        };

        return EnhancedUserFactory(enhancedUser);
      }),
    ),
);

export const isRequiredAccountantsSelector: SelectorType<UserList> = selector(
  organizationUsersSelector,
  currentCompanySelector,
  (users: UserList, companyId: string): Boolean => {
    const accountants = users.filter(
      (user) =>
        ['accountant', 'limited-accountant', 'restricted-accountant'].includes(user.role) &&
        user.companies_id.includes(companyId),
    );

    return !accountants.size;
  },
);
