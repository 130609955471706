import ButtonBase from '@mui/material/ButtonBase';

import { styled, alpha } from '@mui/material/styles';

export const FloatContainer = styled('div', { label: 'FloatContainer' })(() => ({
  position: 'relative',
  maxWidth: '100%',
  minWidth: 0,

  '&:hover > .MuiButtonBase-root': {
    opacity: 1,
  },
}));

export const FloatContent = styled('div', { label: 'FloatContent' })(({ theme }) => ({
  overflowX: 'auto',
  overflowY: 'hidden',
  position: 'relative',
  paddingBottom: 2,

  '&::-webkit-scrollbar': {
    appearance: 'none',
    height: 7,
  },
  '&::-webkit-scrollbar-thumb': {
    borderRadius: 4,
    backgroundColor: alpha(theme.palette.common.black, 0.5),
    boxShadow: `0 0 1px ${alpha(theme.palette.common.white, 0.5)}`,
  },
}));

export const FloatContainerAction = styled(ButtonBase, {
  label: 'FloatContainerAction',
  shouldForwardProp: (propName) => propName !== 'type',
})(({ theme, type }) => ({
  position: 'absolute',
  width: 16,
  top: 0,
  bottom: 9,
  zIndex: 8,
  outline: '0 none',
  cursor: 'pointer',
  opacity: 0,
  transition: '0.3s opacity',
  color: theme.palette.common.white,
  background: alpha(theme.palette.common.black, 0.25),

  ...(type === 'prev' && { left: 0 }),
  ...(type === 'next' && { right: 0 }),

  '&:hover': {
    zIndex: 10,
    background: alpha(theme.palette.common.black, 0.45),
  },
  '&[disabled]': {
    display: 'none',
  },
}));
