// @flow
import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import {
  organization as organizationSelector,
  type OrganizationType,
  updateOrganization,
  uploadLogoOrganization,
} from 'domain/organization';
import { type main } from 'domain/organization/types.js.flow';
import MainForm from 'pages/common/forms/main';
import { prepareToForm, prepareToSubmit } from '../../../../../helpers';

type Props = {
  organization: OrganizationType,
  updateOrganization: (data: main) => void,
  uploadLogo: (file: File) => void,
};

class MainFormContainer extends Component<Props> {
  onSubmit = (data: main) => {
    const preparedData = prepareToSubmit(data);
    this.props.updateOrganization({ ...preparedData.toJS(), update_tab: 'main_tab' });
  };

  onUploadLogo = (file, resolve, reject) => {
    this.props.uploadLogo({
      file,
      resolve,
      reject,
    });
  };

  render() {
    const { organization } = this.props;
    return (
      <MainForm
        initialValues={prepareToForm(organization)}
        onLogoChange={this.onUploadLogo}
        onSubmit={this.onSubmit}
        context="organization"
      />
    );
  }
}

const mapStateToProps = (state) => ({
  organization: organizationSelector(state),
});

export default compose(connect(mapStateToProps, { updateOrganization, uploadLogo: uploadLogoOrganization }))(
  MainFormContainer,
);
