import { Record, type RecordFactory } from 'immutable';
import type { Image } from './type.js.flow';

export const ImageFactory: RecordFactory<Image> = new Record({
  src: '',
  originalSizeSrc: '',
  initialIndex: 0,
});

export const test = 200;
