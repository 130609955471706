// @flow
import * as React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { userEnhancedApprovalsListSelector } from 'domain/approvals/selectors';

import { userGUIDSelector } from 'domain/env/envSelector';

import type { ApprovalRecordList } from 'domain/approvals/types.js.flow';

import { chatParticipantsByIdSelector } from 'domain/chat';
import type { ChatUser } from 'domain/chat/types.js.flow';

import FullFlow from './FullFlow';
import SlimFlow from './SlimFlow';

import { Map, type RecordOf } from 'immutable';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { styled } from '@mui/material/styles';
import Slide from '@mui/material/Slide';

const Wrapper = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(1, 2),
  backgroundColor: theme.palette.grey[50],
  minHeight: 50,
}));

type Props = {
  approvals: ApprovalRecordList,
  currentUser: string,
  usersById: Map<string, RecordOf<ChatUser>>,
};

type State = {|
  isOpen: boolean,
  parentHeight: number,
|};

class Flow extends React.Component<Props, State> {
  state = {
    isOpen: false,
  };

  toggleOpen = () => {
    const isOpen = !this.state.isOpen;
    this.setState({ isOpen });
  };

  render() {
    const { approvals, currentUser, usersById } = this.props;
    const { isOpen } = this.state;

    return approvals.size ? (
      <Wrapper
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{ border: isOpen ? 0 : 1, borderColor: 'grey.400', borderLeft: 0, borderRight: 0 }}
      >
        <Stack justifyContent="center" alignItems="center" flex={1} position="relative">
          <SlimFlow approvals={approvals} approvalsCurrentSignerGuid={currentUser} usersById={usersById} />
          <IconButton
            sx={{ position: 'absolute', right: 0, top: '50%', transform: 'translateY(-50%)' }}
            edge="end"
            onClick={this.toggleOpen}
          >
            <MoreHorizIcon />
          </IconButton>
        </Stack>
        <Slide in={isOpen} direction="down" unmountOnExit>
          <FullFlow approvals={approvals} onClose={this.toggleOpen} usersById={usersById} />
        </Slide>
      </Wrapper>
    ) : null;
  }
}

const mapStateToProps = (state) => ({
  approvals: userEnhancedApprovalsListSelector(state),
  currentUser: userGUIDSelector(state),
  usersById: chatParticipantsByIdSelector(state),
});

export default compose(connect(mapStateToProps))(Flow);
