// @flow
import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { getConnectionHealthSelector } from 'domain/env';

import { AlertBox } from 'components/ConnectionHealthAlert/StyledComponents';

const ConnectionHealthAlert = () => {
  const { formatMessage } = useIntl();
  const connectionHealth = useSelector(getConnectionHealthSelector);

  return (
    !connectionHealth && (
      <AlertBox>
        {formatMessage({
          id: 'alert.connectionHealth',
          defaultMessage: 'Your network connection is unstable. Performance may slow down.',
        })}
      </AlertBox>
    )
  );
};

export default ConnectionHealthAlert;
