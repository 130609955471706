// @flow
import React, { useCallback, useMemo } from 'react';
import { useParams, useNavigate, generatePath } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { useConfirm } from 'material-ui-confirm';
import { companyFeatureSetSelector } from 'domain/companies';
import ROUTES_PATH from 'domain/router/routesPathConfig';
import * as ACL from 'domain/restriction';

import BackToArrowBase from 'components/mui/Layouts/components/BackToArrowBase';
import { erpsSelector, isRequiredErpConnectSelector } from 'domain/settings';
import { isRequiredAccountantsSelector } from 'domain/organizationUser';

const mapStateToProps = (state) => ({
  features: companyFeatureSetSelector(state),
  isGranted: ACL.isGranted(state),
  erps: erpsSelector(state),
  isRequiredAccountants: isRequiredAccountantsSelector(state),
  isRequiredErpConnect: isRequiredErpConnectSelector(state),
});

const BackToArrow = () => {
  const { companyId } = useParams();
  const navigate = useNavigate();
  const { formatMessage } = useIntl();
  const { features, isGranted, isRequiredAccountants, isRequiredErpConnect } = useSelector(mapStateToProps);
  const confirm = useConfirm();
  const { fin } = features;
  const isGrantedByACL = !isGranted(ACL.IS_BK_WITHOUT_ADMIN_PERMISSIONS);

  const hasConfirmBeforeLeave = useMemo(
    () => isGrantedByACL && ((fin && isRequiredErpConnect) || isRequiredAccountants),
    [fin, isRequiredErpConnect, isRequiredAccountants, isGrantedByACL],
  );

  const path = useMemo(() => generatePath(ROUTES_PATH.COMPANY_DEFAULT_CATEGORY.absolute, { companyId }), [companyId]);

  const pathForConfirm = useMemo(
    () => (isRequiredErpConnect ? generatePath(ROUTES_PATH.COMPANIES.absolute) : path),
    [isRequiredErpConnect, path],
  );

  const handleClick = useCallback(() => {
    confirm({
      description: formatMessage({
        id: 'configurations.leave_company.placeholder',
        defaultMessage:
          "You won't be able to reach workspace. Next time you enter company, you will be redirected to settings",
      }),
      confirmationText: formatMessage({
        id: 'configurations.leave_company.yes',
        defaultMessage: 'Yes',
      }),
      confirmationButtonProps: {
        color: 'error',
      },
      cancellationText: formatMessage({
        id: 'configurations.leave_company.cancel',
        defaultMessage: 'No',
      }),
    }).then(() => {
      navigate(pathForConfirm);
    });
  }, [pathForConfirm, navigate, confirm, formatMessage]);

  return <BackToArrowBase path={path} onClick={hasConfirmBeforeLeave && handleClick} />;
};

export default BackToArrow;
