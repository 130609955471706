/* eslint-disable camelcase */
// @flow
import * as React from 'react';
import * as Sentry from '@sentry/browser';
import get from 'lodash/get';
import isFunction from 'lodash/isFunction';
import scriptLoader from 'react-async-script-loader';
import { injectIntl, type IntlShape } from 'react-intl';
import { compose, type Dispatch } from 'redux';
import { scanningRequest } from 'domain/documents/documentsActions';
import { logger } from 'lib/logger';
import moment from 'moment';
import { List, Set } from 'immutable';

import ImageViewer from './components/ImageViewer';
import SourceSelector from './components/SourceSelector';
import SourceActions from './components/SourceActions';
import Stack from '@mui/material/Stack';
import ImagePopup from './components/ImagePopup';
import Divider from '@mui/material/Divider';
import NoDocs from './components/NoDocs/index';
import './sheet.css';
import { type ImageRecordList } from './type.js.flow';
import { ImageFactory } from './helpers';
import tokenKeeper from 'lib/apiTokenKeeper';
import { CONFIGURABLE_API_ENDPOINT } from 'lib/apiHelpers';

import { grey } from '@mui/material/colors';

type WebTwainType = {|
  BackgroundColor: string,
  BackgroundFillColor: number,
  HttpFieldNameOfUploadedImage: string,
  SetViewMode: (sHorizontalImageCount: number, sVerticalImageCount: number) => void,
  LogLevel: 0 | 1 | 2,
  Height: number,
  Width: number,
  SelectedImagesCount: number,
  SelectSourceByIndex: (index: number) => boolean,
  GetSourceNameItems: (index: number) => string,
  GetSelectedImageIndex: (index: number) => number,
  SourceCount: number,
  SelectSource: () => boolean,
  CloseSource: () => boolean,
  OpenSource: () => boolean,
  SetOpenSourceTimeout: (timeout: number) => boolean,
  SetSelectedImageIndex: (order: number, imageIndex: number) => boolean,
  IfDisableSourceAfterAcquire: boolean,
  HowManyImagesInBuffer: number,
  AcquireImage: (
    optionalDeviceConfig?: Object,
    optionalAsyncSuccessFunc?: () => void,
    optionalAsyncFailureFunc?: (errorCode: number, errorString: string) => void,
  ) => boolean,
  HTTPUploadAllThroughPostAsPDF: (
    HTTPServer: ?string, // @fix-it to string
    ActionPage: string,
    fileName: string,
    optionalAsyncSuccessFunc?: () => void,
    optionalAsyncFailureFunc?: (errorCode: number, errorString: string, httppostresponsestring: string) => void,
  ) => boolean,
  HTTPUploadThroughPostAsMultiPagePDF: (
    HTTPServer: ?string,
    ActionPage: string,
    fileName: string,
    optionalAsyncSuccessFunc?: () => void,
    optionalAsyncFailureFunc?: (errorCode: number, errorString: string, httppostresponsestring: string) => void,
  ) => boolean,
  LoadImage: (filepath: string, successCallback: () => void) => void,
  RemoveAllSelectedImages: () => boolean,
  RemoveAllImages: () => boolean,
  RemoveImage: (sImageIndexToBeDeleted: number) => boolean,
  GetImageURL: (index: number, width?: number, height?: number) => string,
  ErrorString: string,
  CurrentImageIndexInBuffer: number,
  IfFeederEnabled: boolean,
  IfShowUI: boolean,
  IfPaperDetectable: boolean,
  IfFeederLoaded: boolean,
|};

declare var Dynamsoft: {
  Lib: Object, // no need for detailed typing here
  WebTwainEnv: {
    Unload: () => void,
    Load: () => void,
    RegisterEvent: (event: string, () => void) => void,
    GetWebTwain: (container: string) => WebTwainType,
    ScanDirectly: boolean,
  },
};

type Props = {
  isScriptLoaded: boolean,
  isScriptLoadSucceed: boolean,
  dokkaToken: string,
  twainRef: ?React.ElementRef<'div'>,
  ready: () => void,
  hide: (value: boolean) => void,
  enableLoader: Dispatch<scanningRequest>,
  disableLoader: Dispatch<scanningRequest.success>,
  isConfidential: boolean,
  intl: IntlShape,
};

type State = {
  sources: Array<{| id: number, value: string |}>,
  selectedSource: number,
  images: ImageRecordList,
  selectedImages: Set<number>,
  openedImageId: ?number,
  isDuplexAvailable: boolean,
  isFeederOption: boolean,
  isDuplexOption: boolean,
};

const dwObjectSymbol = Symbol('dwObject');

class WebTwain extends React.Component<Props, State> {
  static timestamp() {
    return moment(new Date()).format('YYYY-MM-DDTHH:mm:ss');
  }

  state = {
    sources: [
      {
        id: -1,
        value: this.props.intl.formatMessage({
          id: 'scan.src.selectScanner',
          defaultMessage: 'Select scanner',
        }),
      },
    ],
    selectedSource: -1,
    images: new List(),
    selectedImages: new Set(),
    openedImageId: undefined,
    isDuplexAvailable: false,
    isFeederOption: true,
    isDuplexOption: false,
  };

  componentDidMount() {
    this.props.enableLoader();
  }

  componentDidUpdate({ isScriptLoaded: isScriptLoadedBefore }) {
    const { isScriptLoaded: isScriptLoadedNow, isScriptLoadSucceed } = this.props;
    if (!isScriptLoadedBefore && isScriptLoadedNow && isScriptLoadSucceed) {
      // load finished
      this.initDWT();
    }
  }

  componentWillUnmount() {
    if (isFunction(get(Dynamsoft, 'WebTwainEnv.Unload'))) {
      Dynamsoft.DWT.Unload();
    }
  }

  onReady = () => {
    logger('WebTwain ready event fired');
    // $FlowFixMe
    this.initTwain();
  };

  installationRequiredHandler = () => {
    // Idea is to try connect to local Dynamsoft instanse and in case it fails
    // we consider installation dialog has been added to DOM already so we can add listener to close button
    // this is Dynamsoft team workaround for adding event to installation dialog close button
    // $FlowFixMe
    Dynamsoft.DWT.CheckConnectToTheService(
      '127.0.0.1',
      18623, // 18622 for http when testing on localhost
      () => {}, // do nothing for successful connection
      () => {
        // handle unsuccessful connection
        const dialogClose = document.getElementsByClassName('dynamsoft-dialog-close')[0];
        if (dialogClose)
          dialogClose.addEventListener('click', () => {
            this.hide();
            this.props.disableLoader();
          });
        else setTimeout(this.installationRequiredHandler, 0);
      },
    );
  };

  initTwain = () => {
    this[dwObjectSymbol] = Dynamsoft.DWT.GetWebTwain('dwtcontrolContainer');
    const { twainRef, ready, disableLoader } = this.props;

    if (this.dwObject) {
      this.dwObject.HttpFieldNameOfUploadedImage = 'file';
      // eslint-disable-next-line no-mixed-operators
      this.dwObject.SetViewMode((twainRef && Math.ceil((twainRef.offsetWidth - 40) / 186)) || 3, 1);
      this.dwObject.IfAllowLocalCache = 1;
      this.dwObject.ImageCaptureDriverType = 4;
      this.dwObject.LogLevel = 1;
      this.dwObject.BackgroundColor = '#FCFCFC';
      this.dwObject.Height = 1;
      this.dwObject.Width = 1;
      this.dwObject.IfShowUI = false;
      // added after upgrade to 16.1.1 as adviced by support team
      // this.dwObject.Addon.Camera.getStatus = function() { return this._viewer.getStatus(); };
      this.defineDuplexSupport();
      ready();
      disableLoader();
      this.getSources();
    } else if (this.countOfAttempt < 3) {
      this.countOfAttempt++;
      setTimeout(this.initTwain, 500);
    } else {
      disableLoader();
    }
  };

  onSelectSource = ({ target: { value } }: SyntheticInputEvent<HTMLInputElement>) => {
    const intValue = parseInt(value, 0);
    const index = intValue > 0 ? intValue : 0;
    if (!this.dwObject) return; // hot fix - DAW-1515
    this.dwObject.SelectSourceByIndex(index);
    this.setState({ selectedSource: intValue });
    this.defineDuplexSupport();
  };

  onMouseDown = (index: number, event: MouseEvent): boolean => {
    event.preventDefault();
    const { ctrlKey, metaKey } = event;
    // select multiple images
    if (ctrlKey || metaKey) {
      if (this.isImageSelected(index)) {
        // can not deselect last selected image
        if (this.state.selectedImages.size === 1) {
          return false;
        }
        this.deselectSingleImage(index);
      } else {
        this.selectMultipleImages(index);
      }
      // select single image
    } else {
      this.selectSingleImage(index);
    }
    return false;
  };

  onToggleFeederOption = () => {
    const { isFeederOption } = this.state;
    this.setState({ isFeederOption: !isFeederOption });
  };

  onToggleDuplexOption = () => {
    const { isDuplexOption } = this.state;
    this.setState({ isDuplexOption: !isDuplexOption });
  };

  get dwObject(): WebTwainType {
    // $FlowFixMe
    return this[dwObjectSymbol];
  }

  get uploadUrl() {
    const { dokkaToken, isConfidential } = this.props;
    const isClassifiedParam = isConfidential ? '&isConfidential=1' : '';
    const dokkaTokenParam = `&dokkaToken=${dokkaToken}`;
    const tsParam = this.constructor.timestamp();
    const companyId = `&companyId=${tokenKeeper.getCompany()}`;
    const sourceParam = '&source=scanner';
    // eslint-disable-next-line max-len
    return `v2/3/uploadDocument?creationDate=${tsParam}${dokkaTokenParam}${companyId}${isClassifiedParam}${sourceParam}`;
  }

  getSources = () => {
    const count = this.dwObject.SourceCount;
    const sources = [
      {
        id: -1,
        value: this.props.intl.formatMessage({
          id: 'scan.src.selectScanner',
          defaultMessage: 'Select scanner',
        }),
      },
    ];
    for (let i = 0; i < count; i++) {
      if (Dynamsoft.Lib.env.bMac) {
        sources.push({ id: i, value: `ICA_${this.dwObject.GetSourceNameItems(i)}` });
      } else {
        sources.push({ id: i, value: this.dwObject.GetSourceNameItems(i) });
      }
    }
    this.setState({
      sources,
      selectedSource: count > 0 ? 0 : -1,
    });
  };

  initDWT = () => {
    logger('WebTwain script loaded');
    Dynamsoft.DWT.RegisterEvent('OnWebTwainReady', this.onReady);
    // @see https://developer.dynamsoft.com/dwt/api-reference/global-apis/scandirectly
    Dynamsoft.DWT.ScanDirectly = true;
    Dynamsoft.DWT.Load();
    this.installationRequiredHandler();
    window.scrollTo(0, 0);
  };

  selectMultipleImages = (index: number): void => {
    this.setState(
      {
        selectedImages: this.state.selectedImages.add(index),
      },
      () => this.syncImagesInBuffer(),
    );
  };

  selectSingleImage = (index: number): void => {
    this.setState(
      {
        selectedImages: Set([index]),
      },
      () => {
        // this sets first image as selected by design, no way to deselect all.
        this.dwObject.SelectedImagesCount = 0;
        this.syncImagesInBuffer();
      },
    );
  };

  defineDuplexSupport = () => {
    // this.setState({ isDuplexAvailable: !!this.dwObject.Duplex });
    // set always true until we can test working on hardware scanner with duplex
    this.setState({ isDuplexAvailable: true });
  };

  deselectSingleImage = (index: number): void => {
    this.setState(
      {
        selectedImages: this.state.selectedImages.delete(index),
      },
      this.syncImagesInBuffer(),
    );
  };

  isImageSelected = (index: number) => this.state.selectedImages.includes(index);

  selectImageinBuffer = (index: number, noIncrement: boolean = false) => {
    if (!noIncrement) {
      this.dwObject.SelectedImagesCount = this.dwObject.SelectedImagesCount + 1;
    }
    this.dwObject.SelectImages(this.state.selectedImages.toJS());
  };

  // full UI and buffer selection sync
  syncImagesInBuffer = (): void => {
    // reset buffer will return 1 if at least 1 is scanned
    this.dwObject.SelectedImagesCount = 0;
    let index = 0;
    this.state.selectedImages.sort().forEach((value) => {
      // no increment for the first call inside selectImageinBuffer
      // when reseting whole selected images
      this.selectImageinBuffer(value, index === 0);
      index += 1;
    });
  };

  // rollback selection to nonselected state
  syncStateAfterNoImagesLeft = (): void => {
    this.setState({
      images: new List(),
      selectedImages: new Set(),
    });
  };

  // rollback selection to last element selected
  syncStateAfterScan = (images: ImageRecordList): void => {
    // after scan we must decrease SelectedImagesCount to 1 as it might been
    // increased by prior selections
    this.dwObject.SelectedImagesCount = 1;
    this.setState({
      images,
      // after scan selected image index is automaticaly set to last scanned one
      // we are forced to duplicate this behavior
      selectedImages: Set([this.dwObject.HowManyImagesInBuffer - 1]),
    });
  };

  readImagesFromBuffer = (): ImageRecordList => {
    const imagesinBuffer = this.dwObject.HowManyImagesInBuffer;
    return List(
      Array.from(new Array(imagesinBuffer), (val, i) =>
        ImageFactory({
          initialIndex: i,
          src: this.dwObject.GetImageURL(i, this.previewWidth, this.previewHeight),
          originalSizeSrc: this.dwObject.GetImageURL(i),
        }),
      ),
    );
  };

  showImageInPopup = (index: number): void => {
    this.setState({
      openedImageId: index,
    });
  };

  closePopup = (): void => {
    this.setState({
      openedImageId: undefined,
    });
  };

  initiateScan = () => {
    const { isFeederOption, isDuplexOption, selectedSource } = this.state;
    this.props.enableLoader();
    const onAcquireImageSuccess = (): void => {
      this.dwObject.CloseSource();
      const images: ImageRecordList = this.readImagesFromBuffer();
      this.syncStateAfterScan(images);
      this.props.disableLoader();
      logger('Image scan succeeded!');
    };
    const onAcquireImageFailure = (e, errorString) => {
      this.dwObject.CloseSource();
      this.props.disableLoader();
      logger('Image scan failed!', e, errorString);
    };

    logger('Scan initiated');

    this.dwObject.IfDisableSourceAfterAcquire = true;
    this.dwObject.SelectSourceByIndex(selectedSource);
    this.dwObject.IfFeederEnabled = isFeederOption || false;
    this.dwObject.IfDuplexEnabled = isDuplexOption || false;

    this.dwObject.OpenSource();
    this.dwObject.AcquireImage(onAcquireImageSuccess, onAcquireImageFailure);

    Sentry.captureMessage('Document scan', {
      extra: {
        Scanner: this.dwObject.CurrentSourceName,
      },
    });
  };

  multiUpload = () => {
    this.dwObject.HTTPUploadAllThroughPostAsPDF(
      CONFIGURABLE_API_ENDPOINT,
      this.uploadUrl,
      'multipage.pdf',
      () => {
        logger('Documents successfully uploaded');
        if (!this.dwObject.RemoveAllImages()) {
          logger('Pages removal after upload failed', this.dwObject.ErrorString);
        } else {
          // @to-do move images inside sync function
          const images: ImageRecordList = this.readImagesFromBuffer();
          this.syncStateAfterScan(images);
          logger('Pages removed');
        }
      },
      (error, errorText) => {
        // @to-do add failure toaster with translation
        logger('Error uploading images', errorText);
      },
    );
  };

  singleUpload = () => {
    this.dwObject.HTTPUploadThroughPostAsMultiPagePDF(
      CONFIGURABLE_API_ENDPOINT,
      this.uploadUrl,
      'multipage.pdf',
      () => {
        logger('Selected images successfuly uploaded');
        if (!this.dwObject.RemoveAllSelectedImages()) {
          logger('Pages removal after upload failed', this.dwObject.ErrorString);
        } else {
          const images: ImageRecordList = this.readImagesFromBuffer();
          this.syncStateAfterScan(images);
          logger('Pages removed');
        }
      },
      (error, errorText) => {
        logger('Error uploading selected images', errorText);
      },
    );
  };

  removePage = () => {
    if (!this.dwObject.RemoveAllSelectedImages()) {
      logger('Page removal failed', this.dwObject.ErrorString);
    } else {
      const images: ImageRecordList = this.readImagesFromBuffer();
      this.syncStateAfterScan(images);
      logger('Page removed');
    }
  };

  hide = () => {
    const { hide } = this.props;
    hide(false);
  };

  ref = undefined;

  previewWidth: number = 271;

  previewHeight: number = 375;

  countOfAttempt: number = 0;

  scannerContainerWidth: number = 0;

  render() {
    const {
      onMouseDown,
      onSelectSource,
      initiateScan,
      hide,
      removePage,
      singleUpload,
      multiUpload,
      state: { selectedSource, sources, isDuplexAvailable, isFeederOption, isDuplexOption },
    } = this;

    const isHaveNotImage = this.state.images.size === 0;

    return (
      <>
        {typeof this.state.openedImageId === 'number' ? (
          <ImagePopup close={this.closePopup} image={this.state.images.get(this.state.openedImageId)} />
        ) : null}
        <Stack
          bgcolor="common.white"
          border={`1px solid ${grey[200]}`}
          borderRadius={2}
          mb={2}
          ref={(elem) => {
            this.ref = elem;
            this.scannerContainerWidth = this.scannerContainerWidth || (elem && elem.offsetWidth);
          }}
        >
          <div id="dwtcontrolContainer" />
          <Stack
            minHeight={423}
            maxHeight={440}
            alignItems={isHaveNotImage && 'center'}
            justifyContent={isHaveNotImage && 'center'}
            p={3}
            spacing={2}
            direction="row"
            overflow="auto"
            width={this.scannerContainerWidth}
          >
            {isHaveNotImage ? (
              <NoDocs />
            ) : (
              <ImageViewer
                list={this.state.images}
                onMouseDown={onMouseDown}
                selectedList={this.state.selectedImages}
                showImageInPopup={this.showImageInPopup}
              />
            )}
          </Stack>
          <Divider />
          <Stack
            justifyContent="space-between"
            direction="row"
            px={3}
            py={2}
            border={`1px solid ${grey[200]}`}
            backgroundColor={grey[50]}
          >
            <SourceSelector
              onSelectSource={onSelectSource}
              selectedSource={selectedSource}
              sources={sources}
              removePage={removePage}
              singleUpload={singleUpload}
              multiUpload={multiUpload}
              actionsEnabled={this.state.images.size > 0}
              onToggleFeederOption={this.onToggleFeederOption}
              onToggleDuplexOption={this.onToggleDuplexOption}
              isDuplexAvailable={isDuplexAvailable}
              isFeederOption={isFeederOption}
              isDuplexOption={isDuplexOption}
            />
            <SourceActions hide={hide} initiateScan={initiateScan} isHaveNotImage={isHaveNotImage} />
          </Stack>
        </Stack>
      </>
    );
  }
}

export default compose(
  injectIntl,
  scriptLoader([`/dynamsoft.webtwain.config.js?t=${Date.now()}`, `/dynamsoft.webtwain.initiate.js?t=${Date.now()}`]),
)(WebTwain);
