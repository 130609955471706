// @flow
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link, type LocationShape, generatePath } from 'react-router-dom';
import cx from 'classnames';
import ROUTES_PATH from 'domain/router/routesPathConfig';
import type { UnreadRequestDocRecord } from 'domain/documents/types.js.flow';
import pick from 'lodash/pick';
import { listTimeFormatter } from './helpers';

type Props = {
  classes: {
    [key: string]: string,
  },
  unreadRequestDoc: UnreadRequestDocRecord,
  onSelect: () => void,
};

class UnreadRequests extends React.Component<Props> {
  get pathname(): LocationShape {
    const { unreadRequestDoc } = this.props;

    return {
      pathname: generatePath(ROUTES_PATH.DOCUMENT.absolute, pick(unreadRequestDoc.toJS(), ['companyId', 'documentId'])),
    };
  }

  render() {
    const { classes, onSelect, unreadRequestDoc } = this.props;
    const line = unreadRequestDoc.lines.first();

    return (
      <Link className={cx(classes.item, classes.itemDoc)} to={this.pathname} onClick={onSelect}>
        <div className={cx(classes.content, classes.contentDoc)}>
          <div className={cx(classes.info)}>
            <div className={classes.name} />
            <div className={cx(classes.text, classes.textDoc)}>
              <FormattedMessage id="chat.document.reply" defaultMessage="New Reply" />
            </div>
          </div>
          <div className={cx(classes.meta, classes.metaDoc)}>
            <div className={cx(classes.time, classes.timeDocument)}>{listTimeFormatter(line.lastTimestamp)}</div>
          </div>
        </div>
      </Link>
    );
  }
}

export default UnreadRequests;
