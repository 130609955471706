import TabPanel from '@mui/lab/TabPanel';
import Tab from '@mui/material/Tab';
import { styled } from '@mui/material/styles';

export const JETab = styled(Tab, { label: 'JETab' })(({ theme }) => ({
  '&.Mui-selected': {
    backgroundColor: theme.palette.common.white,
  },
}));

export const JETabPane = styled(TabPanel, {
  label: 'JETabPane',
  shouldForwardProp: (prop) => prop !== 'isInsightsTab',
})(({ theme, isInsightsTab }) => ({
  backgroundColor: theme.palette.common.white,
  padding: isInsightsTab ? theme.spacing(2, 0, 0, 0) : theme.spacing(2, 0),
  flex: '1 1 100%',
}));
