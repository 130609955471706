// @flow
import React, { useMemo } from 'react';

// TODO: check types, its temporary
import DialogTitleMui, { type DialogTitleProps } from '@mui/material/DialogTitle';
import IconButtonMui from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';

type TDialogTitle = {
  children: React$Node,
  onClose: () => void,
  draggable?: boolean,
  withCloseBtn?: boolean,
} & DialogTitleProps;

const DialogTitle: React$StatelessFunctionalComponent<TDialogTitle> = ({
  children,
  onClose,
  draggable,
  withCloseBtn,
  ...rest
}) => {
  const titleProps = useMemo(
    () =>
      draggable
        ? {
            style: { cursor: 'move' },
            id: 'draggable-dialog-title',
          }
        : {},
    [draggable],
  );

  return (
    <DialogTitleMui {...titleProps} {...rest}>
      <Box sx={{ pr: 3 }}>{children}</Box>
      {withCloseBtn && (
        <IconButtonMui
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: (theme) => theme.spacing(1),
            top: (theme) => theme.spacing(1),
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButtonMui>
      )}
    </DialogTitleMui>
  );
};

export default DialogTitle;
