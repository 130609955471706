// @flow
import { makeStyles } from '@mui/styles';

import CONST from 'pages/common/constant';

export default makeStyles((theme) => ({
  bar: {
    height: CONST.topBarHeight,
    minHeight: CONST.topBarHeight,
    top: CONST.headerHeight,
    position: 'sticky',
    zIndex: 11,
    background: '#FFF',
    display: 'flex',
    alignItems: 'center',
    borderBottom: '1px solid #DEE2E6',
    padding: [0, 20],
    '& $btnGroup:first-child:after': {
      border: 'none',
    },
    flex: 0,
  },
  actionsContainer: {
    display: 'flex',
    padding: [0, 10],
    height: 30,
    maxHeight: 30,
    alignItems: 'center',
    position: 'relative',
  },
  uploadBtn: {
    textTransform: 'capitalize',
    color: '#FFF',
    minWidth: 115,
    padding: [0, 15],
    textAlign: 'center',
    fontSize: 12,
    display: 'block',
    textDecoration: 'none',
    minHeight: 30,
    verticalAlign: 'middle',
    lineHeight: '30px',
    backgroundColor: theme.colors.primaryLight,
    cursor: 'pointer',
    borderRadius: 4,
    border: 0,
    position: 'relative',
    left: -10,
    whiteSpace: 'nowrap',
    marginRight: 5,
  },
  btn: {
    margin: [0, 2],
    padding: [3, 8],
    boxSizing: 'content-box',
    backgroundPosition: 'center',
  },
  actionItem: {
    borderLeft: `1px solid ${theme.colors.greyLight}`,
  },
}));
