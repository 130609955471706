// @flow
import React, { forwardRef } from 'react';
import ButtonBase, { type ButtonBaseProps } from '@mui/material/ButtonBase';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { styled } from '@mui/material/styles';

const Button = styled(ButtonBase)(({ theme }) => ({
  padding: theme.spacing(0.5),
  border: `1px solid ${theme.palette.grey[300]}`,
  borderRadius: '50%',
  boxShadow: theme.shadows[3],
  backgroundColor: theme.palette.common.white,
  transition: 'box-shadow 300ms',
  minWidth: 32,
  minHeight: 32,
  '&:hover': {
    boxShadow: 'none',
  },
}));

type TArrowButton = {
  open: boolean,
} & ButtonBaseProps;

const ArrowButton: React$StatelessFunctionalComponent<TArrowButton> = forwardRef((props: TArrowButton, ref) => (
  <Button ref={ref} {...props}>
    <KeyboardArrowDownIcon
      fontSize="small"
      color={props.color}
      sx={{ transform: props.open ? 'rotate(180deg)' : '' }}
    />
  </Button>
));

export default ArrowButton;
