// flow
import { useCallback, useRef, useEffect, useState } from 'react';
import { OrderedMap } from 'immutable';
import type { TDocumentPreviewState } from 'pages/company/type.js.flow';

type TProps = {
  gridApiRef: any,
  isGridReady: boolean,
  list: OrderedMap,
  onPreviewCallback?: (TDocumentPreviewState) => void,
  previewState?: TDocumentPreviewState, // from context menu etc
  previewContextKey?: string,
};

const useAgGridDocumentsPreviewNavigation = ({
  gridApiRef,
  isGridReady,
  list = OrderedMap(),
  previewContextKey = '',
  onPreviewCallback,
  previewState,
}: TProps) => {
  const [state, setState] = useState({ documentId: null, contextName: previewContextKey });
  const [startIndex, setStartIndex] = useState(0);
  const previewNavigateDirectionRef = useRef(null);
  const isPreviewOpen = state.documentId && state.contextName === previewContextKey;

  useEffect(() => {
    if (previewState && previewState?.documentId !== state.documentId) {
      setState(previewState);
    }
  }, [previewState, state]);

  useEffect(() => {
    // previewState passed from contextMenu etc., recalculate startIndex when previewState propagated from another component
    if (state.documentId && isGridReady && gridApiRef?.current) {
      const size = gridApiRef.current.paginationGetPageSize() || 0;
      const pageIndex = gridApiRef.current.paginationGetCurrentPage() || 0;

      setStartIndex(size * pageIndex);
    }
  }, [state, isGridReady, gridApiRef]);

  const handleChangePreview = useCallback(
    (id: string | null) => {
      const [documentId, contextName] = id ? [id, previewContextKey] : [null, ''];

      // order important for avoid rerender, first invoked callback
      if (typeof onPreviewCallback === 'function') {
        onPreviewCallback({ documentId, contextName });
      }

      // second invoked set state
      setState({ documentId, contextName });
    },
    [previewContextKey, onPreviewCallback],
  );

  // move to next or previous page of grid from preview component
  const handlePreviewNavigate = useCallback(
    (direction: number) => {
      if (isGridReady) {
        const pageIndex = gridApiRef.current.paginationGetCurrentPage();
        const newPageIndex = pageIndex + direction;
        previewNavigateDirectionRef.current = direction;

        gridApiRef.current.paginationGoToPage(newPageIndex);
      }
    },
    [gridApiRef, isGridReady],
  );

  // set preview when actual documentList is changed and preview is open and preview navigate direction is existed
  useEffect(() => {
    if (isPreviewOpen && typeof previewNavigateDirectionRef.current === 'number') {
      const pageIndex = gridApiRef.current.paginationGetCurrentPage();
      const size = gridApiRef.current.paginationGetPageSize() || 0;

      if (previewNavigateDirectionRef.current > 0) {
        const previewDocument = list.first();

        handleChangePreview(previewDocument.documentID);
        previewNavigateDirectionRef.current = null;
      }

      if (previewNavigateDirectionRef.current < 0) {
        const previewDocument = list.last();

        handleChangePreview(previewDocument.documentID);
        previewNavigateDirectionRef.current = null;
      }

      setStartIndex(size * pageIndex);
    }
  }, [list, isPreviewOpen, gridApiRef, handleChangePreview]);

  return {
    isPreviewOpen,
    handlePreviewNavigate,
    setPreviewState: setState,
    previewState: state,
    handleChangePreview,
    startIndex,
  };
};

export default useAgGridDocumentsPreviewNavigation;
