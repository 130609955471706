// @flow
import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';

import Box from '@mui/material/Box';
import TableChartOutlinedIcon from '@mui/icons-material/TableChartOutlined';

import { ActionButton, ButtonsGroup, CloseButton } from './StyledComponents';

import { useConfirm } from 'material-ui-confirm';

import ClearRoundedIcon from '@mui/icons-material/ClearRounded';

type Props = {
  isSelectTableMode: boolean,
  onEnableSelectTableMode: () => void,
  onDisableSelectTableMode: () => void,
  onSave: () => void,
};
const SelectTableButton: React$StatelessFunctionalComponent<Props> = ({
  isSelectTableMode,
  onEnableSelectTableMode,
  onDisableSelectTableMode,
  onSave,
}) => {
  const { formatMessage } = useIntl();
  const confirm = useConfirm();

  const buttonTitle = {
    id: isSelectTableMode ? 'document.show.saveTable' : 'document.show.selectTable',
    defaultMessage: isSelectTableMode ? 'Save table' : 'Select table',
  };

  const handleSubmit = useCallback(() => {
    confirm({
      description: formatMessage({
        id: 'confirm.document.saveHeader.description',
        defaultMessage: 'Did you select the header of the relevant table?',
      }),
    })
      .then(() => {
        onSave();
      })
      .catch(() => {});
  }, []);

  const handleClick = isSelectTableMode ? handleSubmit : onEnableSelectTableMode;

  return (
    <Box position="relative">
      <ButtonsGroup isActive={isSelectTableMode}>
        <ActionButton
          variant={isSelectTableMode ? 'contained' : 'text'}
          startIcon={<TableChartOutlinedIcon />}
          onClick={handleClick}
          isActive={isSelectTableMode}
        >
          {formatMessage(buttonTitle)}
        </ActionButton>
        {isSelectTableMode && (
          <CloseButton variant="contained" onClick={onDisableSelectTableMode} color="primary">
            <ClearRoundedIcon />
          </CloseButton>
        )}
      </ButtonsGroup>
    </Box>
  );
};

export default SelectTableButton;
