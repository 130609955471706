// @flow
import React, { useCallback } from 'react';

import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import VirtualSelect from './VirtualSelect';

import type { FormControlProps as MuiFormControlProps } from '@mui/material/FormControl/FormControl';

import { useIntl } from 'react-intl';

export type TSelectOption = {|
  label: string,
  value: string | number,
|};

type GroupOptionsType = {|
  options: $ReadOnlyArray<TSelectOption>,
|};

type Props = {
  className: string,
  placeholder: {
    id: string,
    defaultMessage?: string,
    description?: string,
  },
  label: {
    id: string,
    defaultMessage: string,
  },
  value: string,
  input: {
    value: string,
    onChange: (value: string) => void,
    name: string,
  },
  required?: boolean,
  readOnly?: boolean,
  capital?: boolean,
  options: $ReadOnlyArray<TSelectOption | GroupOptionsType>,
  components?: $ReadOnly<{| Group: React$Node |}>,
  selectStyles?: Object,
  disabled?: boolean,
  FormControlProps?: MuiFormControlProps,
};

const MuiVirtualSelect = ({
  value,
  input,
  label,
  className,
  options,
  placeholder,
  selectStyles,
  required,
  FormControlProps = {},
  ...rest
}: Props) => {
  const { formatMessage } = useIntl();

  const getValue = useCallback(() => value || input.value || null, [input.value, value]);

  return (
    <FormControl fullWidth {...FormControlProps}>
      <VirtualSelect
        {...rest}
        className={className}
        options={options}
        value={getValue()}
        onChange={input.onChange}
        placeholder={(placeholder && formatMessage(placeholder)) || ''}
        styles={selectStyles}
        renderInput={({ ref, onBtnClick, onBtnMouseDown, opened, ...params }) => (
          <TextField
            {...params}
            fullWidth
            label={label || ''}
            required={required}
            inputProps={{
              ref,
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={onBtnClick} onMouseDown={onBtnMouseDown} edge="end">
                    <ArrowDropDownIcon
                      fontSize="inherit"
                      sx={{
                        transition: 'transform .3s ease-in-out',
                        transform: `rotate(${!opened ? 0 : '180deg'})`,
                      }}
                    />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            InputLabelProps={{ shrink: true }}
          />
        )}
      />
    </FormControl>
  );
};

export default MuiVirtualSelect;
