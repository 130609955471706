/* @flow */
import * as React from 'react';

export const withState =
  (valueName: string, fnName: string, defVal: any = null) =>
  (WrappedComponent: React.Component<any>) => {
    class WithState extends React.Component<Props, State> {
      state = {
        val: defVal,
      };

      applyState = (val: any) => {
        this.setState({ val });
      };

      render() {
        const props = {
          ...this.props,
          [fnName]: this.applyState,
          [valueName]: this.state.val,
        };
        return <WrappedComponent {...props} />;
      }
    }

    WithState.displayName = WrappedComponent.displayName;
    return WithState;
  };

export default withState;
