// @flow
import { useTheme } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { realLocaleSelector } from 'domain/env/envSelector';
import { passwordLessAuthDomains } from 'domain/env/helpers';
import logoFR from './images/FR.svg';
import logoGB from './images/GB.svg';
import logoIL from './images/IL.svg';
import logoIT from './images/IT.svg';
import logoSP from './images/SP.svg';
import logoUS from './images/US.svg';
import { locationChangeAction } from 'domain/env';

export type TLocaleValue = 'he' | 'en' | 'es' | 'it' | 'fr' | 'uk';

type TMode = 'supplier'; // can be added white labels for example
type TModes = $ReadOnlyArray<TMode>;

type TOption = {
  value: TLocaleValue,
  label: string,
  icon: string,
  excludedFor: TModes,
};

type TOptions = $ReadOnlyArray<TOption>;

const localeOptions: TOptions = [
  {
    value: 'en',
    label: 'US',
    icon: logoUS,
    excludedFor: [],
  },
  {
    value: 'uk',
    label: 'UK',
    icon: logoGB,
    excludedFor: [],
  },
  {
    value: 'he',
    label: 'עב',
    icon: logoIL,
    excludedFor: [],
  },
  {
    value: 'es',
    label: 'ES',
    icon: logoSP,
    excludedFor: ['supplier'],
  },
  {
    value: 'it',
    label: 'IT',
    icon: logoIT,
    excludedFor: ['supplier'],
  },
  {
    value: 'fr',
    label: 'FR',
    icon: logoFR,
    excludedFor: ['supplier'],
  },
];

function sortOptions(options: TOptions, locale: TLocaleValue) {
  const [primaryValue, otherValues] = options.reduce(
    ([primaryLangOption, rest], option) =>
      !primaryLangOption && option.value === locale ? [option, rest] : [primaryLangOption, [...rest, option]],
    [null, []],
  );
  return [primaryValue, ...otherValues];
}

function filterOptions(options: TOptions): TOptions {
  const modes = {
    supplier: passwordLessAuthDomains.includes(window.location.host),
  };

  return options.filter(({ excludedFor }) => {
    if (excludedFor.length === 0) return true;

    return !excludedFor.filter((mode) => modes[mode]).length;
  });
}

const useLocaleSelect = () => {
  const dispatch = useDispatch();
  const currentLocaleValue = useSelector(realLocaleSelector);
  const { labelOptions } = useTheme();
  const options = sortOptions(filterOptions(localeOptions), labelOptions.primaryLocale);
  const currentLocale = options.find((option) => option.value === currentLocaleValue);

  const onChangeLocale = (value: TLocaleValue) => dispatch(locationChangeAction(value));

  return { options, currentLocale, onChangeLocale };
};

export default useLocaleSelect;
