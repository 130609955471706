// @flow
import React from 'react';
import { FormattedMessage } from 'react-intl';

import LoadingButton from '@mui/lab/LoadingButton';

import UploadFileIcon from '@mui/icons-material/UploadFile';

import { styled } from '@mui/material/styles';

type TButtonImport = {
  onImportFile: () => void,
  isBusy: boolean,
};

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const ButtonImport: React$StatelessFunctionalComponent<TButtonImport> = ({ onImportFile, isBusy }) => (
  <LoadingButton component="label" variant="outlined" loading={isBusy} startIcon={<UploadFileIcon />}>
    <FormattedMessage id="button.import.file" defaultMessage="Import from file" />
    <VisuallyHiddenInput type="file" accept=".xlsx" onChange={onImportFile} value="" />
  </LoadingButton>
);

export default ButtonImport;
