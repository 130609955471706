// @flow
import React, { useCallback, useState } from 'react';

import SearchText from 'components/SearchText/SearchText';
import ChatStatusAlert from 'components/mui/Layouts/components/Chat/components/ChatStatusAlert';
import ChannelsThread from 'components/mui/Layouts/components/Chat/components/ChannelsThread';
import Box, { BoxProps } from '@mui/material/Box';
import Divider from '@mui/material/Divider';

type Props = {|
  wrapperProps?: BoxProps,
  documentId?: string,
|};

const ChatDrawerContent: React$StatelessFunctionalComponent<Props> = ({ wrapperProps = {}, documentId }) => {
  const [searchValue, setSearchValue] = useState('');

  const handleSearchChange = useCallback((value: string) => {
    setSearchValue(value);
  }, []);

  return (
    <Box display="flex" flexDirection="column" overflow="hidden" {...wrapperProps}>
      <Box p={2}>
        <ChatStatusAlert />
        <SearchText
          size="small"
          InputProps={{ sx: { borderRadius: 5 } }}
          onChangeSearchText={handleSearchChange}
          searchText={searchValue}
        />
      </Box>
      <Divider />
      <ChannelsThread searchText={searchValue} documentId={documentId} />
    </Box>
  );
};

export default ChatDrawerContent;
