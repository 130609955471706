// @flow
import React from 'react';

import Box from '@mui/material/Box';

import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';

type THighlightText = {
  value: string,
  searchTerm: string,
  component?: string,
};

const HighlightText: React$StatelessFunctionalComponent<THighlightText> = ({ value, searchTerm, component }) => {
  const matches = match(value, searchTerm, { insideWords: true });
  const parts = parse(value, matches);

  return (
    // this wrapper is needed, it dosnt glue/skip spaces between words
    <Box component={component || 'div'}>
      {parts.map((part, index) => (
        <span
          key={`${index.toString()}`}
          style={{
            fontWeight: part.highlight ? 700 : 400,
          }}
        >
          {part.text}
        </span>
      ))}
    </Box>
  );
};

export default HighlightText;
