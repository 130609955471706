// @flow
import { signOutAction } from 'domain/env/envActions';
import { Map } from 'immutable';
import { ReconciliationFactory } from './helper';
import { requestRowAdapter } from './adapters';
import { type ReconciliationStore } from './types.js.flow';
import * as A from './actions';

type Action = {|
  type: typeof A,
  payload: mixed,
|};

export const reducer = {
  reconciliation(state: ReconciliationStore = ReconciliationFactory(), action: Action) {
    switch (action.type) {
      case A.getSearchJournalEntryAction.request:
        return (
          state
            // $FlowFixMe
            .set('detailsRow', action.payload.detailsRow)
            // $FlowFixMe
            .set('rowState', action.payload.rowState)
            .set('loading', true)
        );

      case A.hideRowDetailsAction.type:
        return ReconciliationFactory();

      case A.getSearchJournalEntryAction.success:
        return state.update('searchEntry', (value) => value.merge(action.payload)).set('loading', false);

      case A.getSearchJournalEntryAction.failure:
        return state.set('loading', false);

      case A.addTransactionLineAction.success:
        // $FlowFixMe
        return state.set('selectedEntry', action.payload);

      case A.removeTransactionLineAction.type:
        // $FlowFixMe
        return state.update('selectedEntry', (list) => list.delete(action.payload));

      case A.undoTransactionAction.failure:
      case A.reconcileTransactionAction.failure:
        // $FlowFixMe
        return state.setIn(['searchEntry', 'errorFromErp'], action.payload.errorFromErp);

      case A.showReconciliationRequestDetailsAction.type:
        // $FlowFixMe
        return state.set('requestPopup', true).set('requestRow', requestRowAdapter(action.payload));

      case A.hideReconciliationRequestDetailsAction.type:
        // $FlowFixMe
        return state.set('requestPopup', false).set('requestRow', Map());

      case A.requestPopupChangeStatusAction.type:
        return state.set('requestPopup', action.payload);

      case signOutAction.success:
        return ReconciliationFactory();

      default:
        return state;
    }
  },
};
