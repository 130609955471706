// @flow
import React, { useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';

import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper, { type PopperProps } from '@mui/material/Popper';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import Avatar from 'components/mui/Avatar';

type TList = {
  handleMenuItemClick: () => void,
  users: { [userId: string]: { picture: string, username: string } },
  selected: null | string,
  setSelected: (tag: string) => void,
  userSuggestions: Array<string>,
  tagSuggestions: Array<string>,
  renderItem: () => React$StatelessFunctionalComponent<any>,
  ListProps: { [key: string]: any },
  PopperProps: { [key: string]: any },
} & PopperProps;

type TListItem = {
  handleMenuItemClick: () => void,
  users: { [userId: string]: { picture: string, username: string } },
  tag: string,
  selected: null | string,
  setSelected: (tag: string) => void,
  isDivider: boolean,
  dividerTitle: { id: string, defaultMessage: string },
  width: string,
  renderItem: () => React$StatelessFunctionalComponent<any>,
};

const List: React$StatelessFunctionalComponent<TList> = ({
  open,
  anchorRef,
  handleMenuItemClick,
  placement = 'bottom',
  users,
  selected,
  setSelected,
  userSuggestions,
  tagSuggestions,
  renderItem,
  ListProps = {},
  PopperProps = {},
}) => (
  <Popper
    sx={{
      zIndex: (theme) => theme.zIndex.modal,
    }}
    open={open}
    anchorEl={anchorRef.current}
    role={undefined}
    transition
    placement={placement}
    {...PopperProps}
  >
    {({ TransitionProps, placement }) => (
      <Grow
        {...TransitionProps}
        style={{
          transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
        }}
      >
        <Paper>
          <MenuList
            id="tag-suggestions"
            style={{ maxHeight: 300, overflow: 'auto', minWidth: anchorRef.current && anchorRef.current.clientWidth }}
            {...ListProps}
          >
            {userSuggestions.map((tag, i) => (
              <ListItem
                key={tag}
                handleMenuItemClick={handleMenuItemClick}
                tag={tag}
                users={users}
                selected={selected}
                setSelected={setSelected}
                dividerTitle={{ id: 'documents.tags.suggestions.dropdown.users', defaultMessage: 'Users' }}
                isDivider={i === 0}
                renderItem={renderItem}
              />
            ))}
            {tagSuggestions.map((tag, i) => (
              <ListItem
                key={tag}
                handleMenuItemClick={handleMenuItemClick}
                tag={tag}
                users={users}
                selected={selected}
                setSelected={setSelected}
                dividerTitle={{ id: 'documents.tags.suggestions.dropdown.tags', defaultMessage: 'Tags' }}
                isDivider={i === 0}
                renderItem={renderItem}
              />
            ))}
          </MenuList>
        </Paper>
      </Grow>
    )}
  </Popper>
);

const ListItem: React$StatelessFunctionalComponent<TListItem> = ({
  handleMenuItemClick,
  tag,
  users,
  selected,
  setSelected,
  isDivider,
  dividerTitle,
  width,
  renderItem,
}) => {
  const ref = useRef(null);
  const { formatMessage } = useIntl();

  const isUser = !!users[tag];

  useEffect(() => {
    if (selected === tag) {
      const listRect = document.getElementById('tag-suggestions')?.getBoundingClientRect();
      const itemRect = ref.current?.getBoundingClientRect();

      if (listRect && itemRect) {
        const listTop = listRect.top;
        const listBottom = listTop + listRect.height;
        const itemTop = itemRect.top;
        const itemBottom = itemTop + itemRect.height;

        const MARGIN = 3;
        let scrollShift = 0;
        if (listTop > itemTop) {
          scrollShift = itemTop - listTop - MARGIN;
        } else if (itemBottom > listBottom) {
          scrollShift = itemBottom - listBottom + MARGIN;
        }

        document.getElementById('tag-suggestions').scrollTop =
          document.getElementById('tag-suggestions').scrollTop + scrollShift;
      }
    }
  }, [selected, tag]);

  return (
    renderItem?.({
      isDivider,
      isUser,
      users,
      tag,
      width,
      dividerTitle,
      menuItemProps: {
        selected: selected === tag,
        onMouseEnter: () => setSelected(tag),
        onClick: () => handleMenuItemClick(tag),
        ref,
      },
    }) || (
      <>
        {isDivider && (
          <Divider sx={{ my: '0 !important' }}>
            <Typography variant="subtitile2">{formatMessage(dividerTitle)}</Typography>
          </Divider>
        )}
        <MenuItem
          onClick={() => handleMenuItemClick(tag)}
          ref={ref}
          sx={{ display: 'flex', justifyContent: 'space-between', width }}
          selected={selected === tag}
          onMouseEnter={() => setSelected(tag)}
        >
          {isUser && (
            <ListItemIcon>
              <Avatar src={users[tag].picture} />
            </ListItemIcon>
          )}
          <ListItemText sx={{ marginLeft: 1 }}>
            <Typography variant="body2">{isUser ? `${users[tag].username} (${tag})` : tag}</Typography>
          </ListItemText>
        </MenuItem>
      </>
    )
  );
};

export default List;
