/* @flow */
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { usePopupState, bindTrigger, bindMenu } from 'material-ui-popup-state/hooks';

import { type TMoreOption, MoreMenuItem } from 'pages/document/components/DocumentMoreButton/components/CascadingMenu';
import CascadingMenuContext from 'pages/document/components/DocumentMoreButton/components/CascadingMenuContext';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import Box from '@mui/material/Box';

import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';
import ArrowDropUpOutlinedIcon from '@mui/icons-material/ArrowDropUpOutlined';

type TDocumentMoreButton = {
  options: Array<TMoreOption>,
};

const DocumentMoreButton: React$StatelessFunctionalComponent<TDocumentMoreButton> = ({ options }) => {
  const popupState = usePopupState({ variant: 'popover', popupId: 'MoreButton' });

  const list = options.filter((option) => !option.isHidden);

  return (
    <>
      <Button
        color="primary"
        variant="outlined"
        endIcon={popupState.isOpen ? <ArrowDropUpOutlinedIcon /> : <ArrowDropDownOutlinedIcon />}
        sx={{ minWidth: '15ch' }}
        {...bindTrigger(popupState)}
      >
        <Box flexGrow={1}>
          <FormattedMessage id="document.show.btns.more" defaultMessage="More" />
        </Box>
      </Button>
      <Menu
        autoFocus
        transformOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
        slotProps={{ paper: { sx: { mt: -1 } } }}
        {...bindMenu(popupState)}
      >
        <CascadingMenuContext.Provider value={{ rootPopupState: popupState, parentPopupState: popupState }}>
          {list.map((option) => (
            <MoreMenuItem option={option} key={option.id} />
          ))}
        </CascadingMenuContext.Provider>
      </Menu>
    </>
  );
};

export default DocumentMoreButton;
