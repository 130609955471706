// @flow
const emptyFn = () => null;
export const GROUP = {
  input: 'input',
  select: 'select',
  selectPassive: 'selectPassive',
  datePassive: 'datePassive',
  button: 'button',
  cell: 'cell',
  literal: 'literal',
  boolean: 'boolean',
  reconciliation: 'reconciliation',
  textareaPassive: 'textareaPassive',
};
export const STATE = {
  passive: {
    name: 'passive',
    group: GROUP.button,
    action: emptyFn,
  },
  focus: {
    name: 'focus',
    group: GROUP.button,
    action: emptyFn,
  },
  insert: {
    name: 'insert',
    group: GROUP.input,
    action: emptyFn,
  },
  edit: {
    name: 'edit',
    group: GROUP.input,
    action: emptyFn,
  },
  roPassive: {
    name: 'roPassive',
    group: GROUP.cell,
    action: emptyFn,
  },
  roFocus: {
    name: 'roFocus',
    group: GROUP.cell,
    action: emptyFn,
  },
  /** TEXTAREA */
  textareaPassive: {
    name: 'textareaPassive',
    group: GROUP.button,
    action: emptyFn,
  },
  textareaFocus: {
    name: 'textareaFocus',
    group: GROUP.button,
    action: emptyFn,
  },
  textareaInsert: {
    name: 'textareaInsert',
    group: GROUP.input,
    action: emptyFn,
  },
  textareaEdit: {
    name: 'textareaEdit',
    group: GROUP.input,
    action: emptyFn,
  },
  /** TOGGLABLE DATE */
  datePassive: {
    name: 'datePassive',
    group: GROUP.datePassive,
    action: emptyFn,
  },
  dateFocus: {
    name: 'dateFocus',
    group: GROUP.datePassive,
    action: emptyFn,
  },
  dateEdit: {
    name: 'dateEdit',
    group: GROUP.input, // won't show format toggler
    action: emptyFn,
  },
  dateInsert: {
    name: 'dateInsert',
    group: GROUP.input, // won't show format toggler
    action: emptyFn,
  },
  /** LITERAL */
  literalPassive: {
    name: 'literalPassive',
    group: GROUP.button,
    action: emptyFn,
  },
  literalFocus: {
    name: 'literalFocus',
    group: GROUP.button,
    action: emptyFn,
  },
  literalUpdate: {
    name: 'literalUpdate',
    group: GROUP.literal,
    action: emptyFn,
  },
  /** END OF LITERAL */
  selectPassive: {
    name: 'selectPassive',
    group: GROUP.selectPassive,
    action: emptyFn,
  },
  selectFocus: {
    name: 'selectFocus',
    group: GROUP.selectPassive,
    action: emptyFn,
  },
  selectUpdate: {
    name: 'selectUpdate',
    group: GROUP.select,
    action: emptyFn,
  },
  /** CHECKBOX */
  booleanPassive: {
    name: 'booleanPassive',
    group: GROUP.boolean,
    action: emptyFn,
  },
  booleanFocus: {
    name: 'booleanFocus',
    group: GROUP.boolean,
    action: emptyFn,
  },
  /** END OF CHECKBOX */
  reconciliationPassive: {
    name: 'reconciliationPassive',
    group: GROUP.reconciliation,
    action: emptyFn,
  },
  reconciliationFocus: {
    name: 'reconciliationFocus',
    group: GROUP.reconciliation,
    action: emptyFn,
  },
};

export const EVENTS = {
  onFocus: 'onFocus',
  onClick: 'onClick',
  onActive: 'onActive',
  onBlur: 'onBlur',
  onSelect: 'onSelect',
  charKey: 'charKey',
  arrowKey: 'arrowKey',
  LRArrowKey: 'LRArrowKey',
  UDArrowKey: 'UDArrowKey',
  onEnter: 'onEnter',
  onDoubleClick: 'onDoubleClick',
  onEsc: 'onEsc',
  onBackSpase: 'onBackSpase',
  onShieldClick: 'onShieldClick',
  onDateToggleClick: 'onDateToggleClick',
  onDrop: 'onDrop',
  onPast: 'onPast',
};

export type FSM$Group = $Keys<typeof GROUP>;

type FSM$StateName = $Keys<typeof STATE>;

export type FSM$State = {|
  name: FSM$StateName,
  group: FSM$Group,
  action: Function,
|};

export type FSM$Event = $Keys<typeof EVENTS>;

type FSM$StatesDict = {
  [key: FSM$StateName]: {
    [key: FSM$Event]: FSM$State,
  },
};

export type FSM$Cell = (e?: FSM$Event) => FSM$State;

export const statesDict: FSM$StatesDict = {
  /** ReadOnly */
  roPassive: {
    onClick: {
      ...STATE.roFocus,
      action(cell: string) {
        this.props.onActive(cell);
      },
    },
    onFocus: {
      ...STATE.roFocus,
      action(cell: string) {
        this.props.onActive(cell);
      },
    },
  },
  roFocus: {
    onBlur: STATE.roPassive,
    arrowKey: {
      ...STATE.roPassive,
      action(keyCode: number) {
        this.props.onActive(keyCode);
      },
    },
  },
  /** Input */
  passive: {
    onClick: {
      ...STATE.focus,
      action(cell: string) {
        this.props.onActive(cell);
      },
    },
    onFocus: {
      ...STATE.focus,
      action(cell: string) {
        this.props.onActive(cell);
      },
    },
    onDoubleClick: {
      ...STATE.edit,
      action() {
        this.transition(STATE.edit, this.setFocus);
      },
    },
  },
  focus: {
    charKey: {
      ...STATE.insert,
      action() {
        this.insert(STATE.edit, this.setFocus);
      },
    },
    onEnter: {
      ...STATE.edit,
      action() {
        this.transition(STATE.edit, this.setFocus);
      },
    },
    onDoubleClick: {
      ...STATE.edit,
      action() {
        this.transition(STATE.edit, this.setFocus);
      },
    },
    onBlur: STATE.passive,
    arrowKey: {
      ...STATE.passive,
      action(keyCode: number) {
        this.props.onActive(keyCode);
      },
    },
    onBackSpase: {
      ...STATE.insert,
      action() {
        this.insert(STATE.edit, this.setFocus);
      },
    },
  },
  insert: {
    onEsc: {
      ...STATE.focus,
      action() {
        this.transition(STATE.focus, this.setFocus);
      },
    },
    onBlur: {
      ...STATE.passive,
      action() {
        this.update(STATE.passive);
      },
    },
    onEnter: {
      ...STATE.passive,
      action() {
        this.update(STATE.passive, () => this.props.onActive(13));
      },
    },
    LRArrowKey: {
      ...STATE.passive,
      action() {
        // simulate onEnter to force dropdown choice
        this.update(STATE.passive, () => this.props.onActive(13));
      },
    },
    onSelect: {
      ...STATE.focus,
      action() {
        this.update(STATE.focus, this.setFocus);
      },
    },
  },
  edit: {
    onEsc: {
      ...STATE.focus,
      action() {
        this.update(STATE.focus, this.setFocus);
      },
    },
    onBlur: {
      ...STATE.passive,
      action() {
        this.update(STATE.passive);
      },
    },
    onEnter: {
      ...STATE.passive,
      action() {
        this.update(STATE.passive, () => this.props.onActive(13));
      },
    },
    onSelect: {
      ...STATE.focus,
      action() {
        this.update(STATE.focus, this.setFocus);
      },
    },
  },
  /** Textarea */
  textareaPassive: {
    onClick: {
      ...STATE.textareaFocus,
      action(cell: string) {
        this.props.onActive(cell);
      },
    },
    onFocus: {
      ...STATE.textareaFocus,
      action(cell: string) {
        this.props.onActive(cell);
      },
    },
    onDoubleClick: {
      ...STATE.textareaEdit,
      action() {
        this.transition(STATE.textareaEdit, this.setFocus);
      },
    },
  },
  textareaFocus: {
    charKey: {
      ...STATE.textareaInsert,
      action() {
        this.insert(STATE.textareaEdit, this.setFocus);
      },
    },
    onEnter: {
      ...STATE.textareaEdit,
      action(e: SyntheticKeyboardEvent<HTMLElement>) {
        this.transition(STATE.textareaEdit, () => {
          e.preventDefault();
          this.setFocus();
        });
      },
    },
    onDoubleClick: {
      ...STATE.textareaEdit,
      action() {
        this.transition(STATE.textareaEdit, this.setFocus);
      },
    },
    onBlur: STATE.textareaPassive,
    arrowKey: {
      ...STATE.textareaPassive,
      action(keyCode: number) {
        this.props.onActive(keyCode);
      },
    },
    onBackSpase: {
      ...STATE.textareaInsert,
      action() {
        this.insert(STATE.textareaEdit, this.setFocus);
      },
    },
  },
  textareaInsert: {
    onEsc: {
      ...STATE.textareaFocus,
      action() {
        this.transition(STATE.textareaFocus, this.setFocus);
      },
    },
    onBlur: {
      ...STATE.textareaPassive,
      action() {
        this.update(STATE.textareaPassive);
      },
    },
    onEnter: {
      ...STATE.textareaPassive,
      action() {
        this.update(STATE.textareaPassive, () => this.props.onActive(13));
      },
    },
    LRArrowKey: {
      ...STATE.textareaPassive,
      action() {
        // simulate onEnter to force dropdown choice
        this.update(STATE.textareaPassive, () => this.props.onActive(13));
      },
    },
    onSelect: {
      ...STATE.textareaFocus,
      action() {
        this.update(STATE.textareaFocus, this.setFocus);
      },
    },
  },
  textareaEdit: {
    onEsc: {
      ...STATE.textareaFocus,
      action() {
        this.update(STATE.textareaFocus, this.setFocus);
      },
    },
    onBlur: {
      ...STATE.textareaPassive,
      action() {
        this.update(STATE.textareaPassive);
      },
    },
    onEnter: {
      ...STATE.textareaPassive,
      action() {
        this.update(STATE.textareaPassive, () => this.props.onActive(13));
      },
    },
    onSelect: {
      ...STATE.textareaFocus,
      action() {
        this.update(STATE.textareaFocus, this.setFocus);
      },
    },
  },
  /** DATE (group determined by cell name, not cell type) */
  datePassive: {
    onClick: {
      ...STATE.dateFocus,
      action(cell: string) {
        this.props.onActive(cell);
      },
    },
    onFocus: {
      ...STATE.dateFocus,
      action(cell: string) {
        this.props.onActive(cell);
      },
    },
    onDoubleClick: {
      ...STATE.dateEdit,
      action() {
        this.transition(STATE.dateEdit, this.setFocus);
      },
    },
    onDateToggleClick: {
      ...STATE.dateFocus,
      action(cell: string) {
        this.toggleDate(STATE.dateFocus, () => {
          this.props.onActive(cell);
          this.setFocus();
        });
      },
    },
  },
  dateEdit: {
    onEsc: {
      ...STATE.dateFocus,
      action() {
        this.update(STATE.dateFocus, this.setFocus);
      },
    },
    onBlur: {
      ...STATE.datePassive,
      action() {
        this.update(STATE.datePassive);
      },
    },
    onEnter: {
      ...STATE.datePassive,
      action() {
        this.update(STATE.datePassive, () => this.props.onActive(13));
      },
    },
    onSelect: {
      ...STATE.dateFocus,
      action() {
        this.update(STATE.dateFocus, this.setFocus);
      },
    },
  },
  dateFocus: {
    onBlur: STATE.datePassive,
    charKey: {
      ...STATE.dateInsert,
      action() {
        this.insert(STATE.dateEdit, this.setFocus);
      },
    },
    onEnter: {
      ...STATE.dateEdit,
      action() {
        this.transition(STATE.dateEdit, this.setFocus);
      },
    },
    onDoubleClick: {
      ...STATE.dateEdit,
      action() {
        this.transition(STATE.dateEdit, this.setFocus);
      },
    },
    arrowKey: {
      ...STATE.datePassive,
      action(keyCode: number) {
        this.props.onActive(keyCode);
      },
    },
    onBackSpase: {
      ...STATE.dateInsert,
      action() {
        this.insert(STATE.dateEdit, this.setFocus);
      },
    },
  },
  dateInsert: {
    onEsc: {
      ...STATE.dateFocus,
      action() {
        this.transition(STATE.dateFocus, this.setFocus);
      },
    },
    onBlur: {
      ...STATE.datePassive,
      action() {
        this.update(STATE.datePassive);
      },
    },
    onEnter: {
      ...STATE.datePassive,
      action() {
        this.update(STATE.datePassive, () => this.props.onActive(13));
      },
    },
    LRArrowKey: {
      ...STATE.datePassive,
      action() {
        // simulate onEnter to force dropdown choice
        this.update(STATE.datePassive, () => this.props.onActive(13));
      },
    },
    onSelect: {
      ...STATE.dateFocus,
      action() {
        this.update(STATE.dateFocus, this.setFocus);
      },
    },
  },

  /** BOOLEAN */
  booleanPassive: {
    onClick: {
      ...STATE.booleanFocus,
      action(cell: string) {
        this.transition(STATE.booleanFocus, () => {
          this.props.onActive(cell);
          this.setFocus();
        });
      },
    },
    onFocus: {
      ...STATE.booleanFocus,
      action(cell: string) {
        this.props.onActive(cell);
      },
    },
  },
  booleanFocus: {
    onBlur: STATE.booleanPassive,
    arrowKey: {
      ...STATE.booleanPassive,
      action(keyCode: number) {
        this.props.onActive(keyCode);
      },
    },
  },
  /** LITERAL */
  literalPassive: {
    onClick: {
      ...STATE.literalFocus,
      action(cell: string) {
        this.props.onActive(cell);
      },
    },
    onFocus: {
      ...STATE.literalFocus,
      action(cell: string) {
        this.props.onActive(cell);
      },
    },
  },
  literalFocus: {
    onBlur: STATE.literalPassive,
    charKey: {
      ...STATE.literalUpdate,
      action() {
        this.insert(STATE.literalUpdate, this.setFocus);
      },
    },
    onEnter: {
      ...STATE.literalUpdate,
      action() {
        this.transition(STATE.literalUpdate, this.setFocus);
      },
    },
    onDoubleClick: {
      ...STATE.literalUpdate,
      action() {
        this.transition(STATE.literalUpdate, this.setFocus);
      },
    },
    arrowKey: {
      ...STATE.literalPassive,
      action(keyCode: number) {
        this.props.onActive(keyCode);
      },
    },
    onBackSpase: {
      ...STATE.literalUpdate,
      action() {
        this.insert(STATE.literalUpdate, this.setFocus);
      },
    },
  },
  literalUpdate: {
    onBlur: {
      ...STATE.literalPassive,
      action() {
        this.setFCMState(STATE.literalPassive);
      },
    },
    onEsc: {
      ...STATE.literalFocus,
      action() {
        this.setFCMState(STATE.literalPassive, this.setFocus);
      },
    },
    onEnter: {
      ...STATE.literalPassive,
      action() {
        this.setFCMState(STATE.literalPassive, () => {
          this.props.onActive(13);
        });
      },
    },
  },
  /** SELECT */
  selectPassive: {
    onFocus: {
      ...STATE.selectFocus,
      action(cell: string) {
        this.props.onActive(cell);
      },
    },
    onShieldClick: {
      ...STATE.selectUpdate,
      action(cell: string) {
        this.transition(STATE.selectUpdate, () => {
          this.props.onActive(cell);
          this.setFocus();
        });
      },
    },
    onDoubleClick: {
      ...STATE.selectUpdate,
      action(cell: string) {
        this.transition(STATE.selectUpdate, () => {
          this.props.onActive(cell);
          this.handleShieldClick(true);
          this.setFocus();
        });
      },
    },
    onDrop: {
      ...STATE.selectUpdate,
      action(value) {
        this.insert(STATE.selectUpdate, () => {
          this.setFocus();
          this.activeComponent.onDropCreate(value);
        });
      },
    },
  },
  selectFocus: {
    onBlur: STATE.selectPassive,
    onEnter: {
      ...STATE.selectUpdate,
      action() {
        this.transition(STATE.selectUpdate, () => {
          this.handleShieldClick(true);
          this.setFocus();
        });
      },
    },
    arrowKey: {
      ...STATE.selectPassive,
      action(keyCode: number) {
        this.props.onActive(keyCode);
      },
    },
    onDoubleClick: {
      ...STATE.selectUpdate,
      action() {
        this.transition(STATE.selectUpdate, () => {
          this.handleShieldClick(true);
          this.setFocus();
        });
      },
    },
    charKey: {
      ...STATE.selectUpdate,
      action() {
        this.insert(STATE.selectUpdate, this.setFocus);
      },
    },
    onBackSpase: {
      ...STATE.selectUpdate,
      action() {
        this.insert(STATE.selectUpdate, this.setFocus);
      },
    },
    onPast: {
      ...STATE.selectUpdate,
      action(value) {
        this.insert(STATE.selectUpdate, () => {
          this.setFocus();
          this.activeComponent.onDropCreate(value);
        });
      },
    },
    onShieldClick: {
      ...STATE.selectUpdate,
      action(cell: string) {
        this.transition(STATE.selectUpdate, () => {
          this.props.onActive(cell);
          this.setFocus();
        });
      },
    },
    onDrop: {
      ...STATE.selectUpdate,
      action(value) {
        this.insert(STATE.selectUpdate, () => {
          this.setFocus();
          this.activeComponent.onDropCreate(value);
        });
      },
    },
  },
  selectUpdate: {
    onEnter: {
      ...STATE.selectPassive,
      action() {
        this.update(STATE.selectFocus, () => this.props.onActive(13, this.props.data._cell));
      },
    },
    onSelect: {
      ...STATE.selectPassive,
      action() {
        this.update(STATE.selectFocus, this.setFocus);
      },
    },
    onEsc: {
      ...STATE.selectFocus,
      action() {
        this.transition(STATE.selectFocus, this.setFocus);
      },
    },
    onBlur: {
      ...STATE.selectPassive,
      action() {
        this.update(STATE.selectPassive);
      },
    },
  },
  reconciliationPassive: {
    onFocus: {
      ...STATE.reconciliationFocus,
      action(cell: string) {
        this.props.onActive(cell);
      },
    },
  },
  reconciliationFocus: {
    onBlur: STATE.reconciliationPassive,
    arrowKey: {
      ...STATE.reconciliationPassive,
      action(keyCode: number) {
        this.props.onActive(keyCode);
      },
    },
  },
};

function fsm(dict: FSM$StatesDict, state: FSM$State, event?: FSM$Event): FSM$State {
  if (typeof event !== 'undefined' && event in dict[state.name]) return dict[state.name][event];
  return state;
}

export function cellFsm(dict: FSM$StatesDict, def: FSM$State): FSM$Cell {
  let state = def;
  return (event?: FSM$Event): FSM$State => {
    state = fsm(dict, state, event);
    return state;
  };
}
