import { takeEvery, fork, put, select } from 'redux-saga/effects';
import { CancelToken } from 'axios';
import * as Documents from 'domain/documents/sagas';
import * as ACL from 'domain/restriction';

function* prepareFile(dispatch, { payload }) {
  const source = CancelToken.source();
  const isGranted = yield select(ACL.isGranted);
  const { id, params } = payload;

  yield put(Documents.actions.queueUpdateAction({ cancelToken: source, id, params, status: 'uploading' }));

  const onUploadProgress = (progressEvent) => {
    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
    dispatch(Documents.actions.queueProgress({ value: percentCompleted, id }));
  };

  const ensureUpload = isGranted(ACL.IS_SUPPLIER_USER)
    ? Documents.ensureSupplierUploadDocument
    : Documents.ensureUploadDocument;

  yield fork(ensureUpload, {
    payload,
    onUploadProgress,
    cancelToken: source.token,
  });
}

export default function* upload(dispatch) {
  yield takeEvery(Documents.actions.documentUploadAction.type, prepareFile, dispatch);
  yield takeEvery(Documents.actions.supplierDocumentUploadAction.type, prepareFile, dispatch);
}
