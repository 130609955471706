// @flow
import React from 'react';

import Box from '@mui/material/Box';
import GhostTabs from 'components/mui/Layouts/components/GhostTabs';

const Bar = () => (
  <Box display="flex" alignItems="center" mt={2}>
    <Box ml="auto">
      <GhostTabs />
    </Box>
  </Box>
);

export default Bar;
