/* @flow */
import React, { Component } from 'react';

import { Page, type RenderSuccessData } from 'react-pdf';

import Stack from '@mui/material/Stack';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

import Modal from '@mui/material/Modal';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';

const ModalCloseButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  right: theme.spacing(-6),
  top: theme.spacing(-1),
  color: theme.palette.common.white,
}));

const PreviewBox = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  height: '100vh',
}));

const PreviewContent = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
  boxSizing: 'content-box',
  margin: '0 auto',
  borderWidth: 6,
  borderStyle: 'solid',
  borderColor: 'transparent',
}));

type Props = {
  onClose: () => void,
  getRotate: (pageIndex: number) => number,
  pageNumber: number,
};

type State = {
  scale: ?number,
};

// optimal ratio between window size and preview size (experementall value)
const NORMAL_RATIO = 1.2;

const scalePdf = (width: number, height: number): number => {
  const { innerWidth, innerHeight } = window;
  const widthRatio = innerWidth / width;
  const heightRatio = innerHeight / height;
  const ratio = widthRatio > heightRatio ? heightRatio : widthRatio;
  return ratio / NORMAL_RATIO;
};

class EditPreview extends Component<Props, State> {
  state = {
    scale: null,
  };

  onRenderSuccess = (pdfData: RenderSuccessData) => {
    if (!this.state.scale) {
      const { width, height } = pdfData;
      const scale = scalePdf(width, height);
      this.setState({ scale });
    }
  };

  getScale = () => this.state.scale || 1;

  render() {
    const { onClose, pageNumber, getRotate } = this.props;
    return (
      <Modal open onClick={onClose}>
        <Stack direction="row" alignItems="center" justifyContent="center" height="100vh">
          <PreviewBox role="button" tabIndex="0">
            <PreviewContent>
              <ModalCloseButton onClick={onClose}>
                <CloseOutlinedIcon />
              </ModalCloseButton>
              <Stack sx={{ opacity: this.state.scale ? 1 : 0 }}>
                <Page
                  pageNumber={pageNumber}
                  scale={this.getScale()}
                  onRenderSuccess={this.onRenderSuccess}
                  rotate={getRotate(pageNumber - 1)}
                  renderTextLayer={false}
                  renderAnnotations={false}
                />
              </Stack>
            </PreviewContent>
          </PreviewBox>
        </Stack>
      </Modal>
    );
  }
}

export default EditPreview;
