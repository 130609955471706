// @flow
import * as React from 'react';
import { List } from 'immutable';
import { compose, type Dispatch } from 'redux';
import { connect } from 'react-redux';
import { debounceCancelable } from 'lib/helpers';
import { documentDocumentIdSelector } from 'domain/documents';
import { type OptionsItemType, type RecordCellSetType, RecordCellSet } from 'domain/journal/helper';
import elements from 'components/elements';
import {
  valueNormalize as defaultValueNormalize,
  filterList as defaultFilterList,
  testOption,
  type ValueNormalize,
} from './helpers';
import type { CellStyleType } from '../styleHelper';
import {
  getJEListAction,
  paginationListSelector,
  paginationListPageTokenSelector,
  getJEIndexListItemDataAction,
  getCachedPaginationListSelector,
  updateCurrentPaginationListAction,
} from 'domain/journal';

import withManageListItemModal from './withManageListItemModal';
import AutocompleteOptions from './options';

import Tooltip from 'components/mui/Tooltip';
import WarningIcon from '@mui/icons-material/Warning';

import { withStyles } from '@mui/styles';
import cx from 'classnames';
import sheet from './sheet';

type CustomEvent = {
  target: {
    value: ?(string | number),
  },
};

type CustomInputEvent = {
  target: {
    value: string,
  },
};

type CustomKeyEvent = {
  keyCode: number,
  key?: string,
};

type TProps = {|
  classes: {
    [key: string]: string,
  },
  maxWidth: number,
  isOpen: boolean,
  children: (n: ?string, (c: CustomInputEvent) => Promise<void>) => React$Node,
  disabled?: boolean,
  id?: string,
  placeholder?: string,
  optionList: List<OptionsItemType>,
  filterList: (l: List<OptionsItemType>, t: string) => List<OptionsItemType>,
  valueNormalize: ValueNormalize,
  blurTimeOut: number,
  input: {
    onChange: (e: CustomEvent, cb?: ?() => void) => void,
    onKeyDown: (e: CustomKeyEvent) => void,
    onToggleOpen: (s: boolean) => void,
    value: RecordCellSetType,
    onFocus?: () => void,
    onBlur: () => void,
    onCreate: (c: RecordCellSetType, cb?: ?() => void) => void,
    isCreatable: boolean,
  },
  onComplete: () => void,
  onCreate: () => Promise<?string>,
  onEdit: (cellValue: string) => Promise<?string>,
  name: { _cell: string, name: string },
  getRefs: (el: ?HTMLInputElement, component: any) => void,
  getJEList: Dispatch<typeof getJEListAction>,
  getIndexForm: Dispatch<typeof getJEIndexListItemDataAction>, // for wrapper component
  rtl: boolean,
  style: CellStyleType,
  params?: { id: string, cellName: string },
  pageLimit?: number,
  pageItemsCount?: number,
  nextPageToken: ?string,
  documentID: string, // for wrapper component
  getCachedPaginationList: (id: string) => List<OptionsItemType> | void,
  updateCurrentPaginationList: Dispatch<typeof updateCurrentPaginationListAction>,
  include_id: string | null,
|};

type TState = {|
  term: string | null,
  index: number,
  isDirectionUP: ?boolean,
  isLoading: boolean,
  isPressSelectOnLoadingEnd: boolean, // IF was pressed enter or tab when options were loading, check this case
  currentOptionsList: List<OptionsItemType>, // we have a delay due to the debounce, so the render looks crooked, that's why the state is used
|};

const QUERY_PAGE_SIZE = 50;
export const QUERY_ALL_PAGES = -1;
const MAX_ITEMS_DISPLAY = 10000;

class Autocomplete extends React.Component<TProps, TState> {
  // TODO: IMPORTANT !!!!!!
  // TODO: all component is very fragile/delicate, be careful when you change the order of any flags and the like

  debounceGetOptionListFn = debounceCancelable(
    250,
    (isNewList: boolean, search: string | null = null, cb: () => void = () => {}) => {
      this.loadOptionList(isNewList, search).then((res) => cb?.(res));
    },
  );

  constructor(props) {
    super(props);

    const { optionList } = props;

    this.state = {
      term: '',
      index: -1,
      isDirectionUP: undefined, // eslint-disable-line react/no-unused-state
      isLoading: false,
      currentOptionsList: optionList,
    };
  }

  componentDidMount() {
    this.getOptionList(true);
  }

  componentWillReceiveProps(nextProps) {
    const { optionList } = this.props;

    if (optionList.size !== nextProps.optionList.size) {
      this.setState({ currentOptionsList: nextProps.optionList });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { isLoading, isPressSelectOnLoadingEnd } = this.state;
    const { optionList } = this.props;

    // IF was pressed enter or tab when options were loading, check this case
    // if search result is one item and enter/tab was pressed, select and move to next cell
    if (isLoading === false && prevState.isLoading === true && optionList.size === 1 && isPressSelectOnLoadingEnd) {
      this.doSelect(0, this.cbEnter);
    }
  }

  componentWillUnmount(): * {
    clearTimeout(this.timeout);
    this.debounceGetOptionListFn.cancel();
  }

  // eslint-disable-next-line getter-return
  get cachedList() {
    const { getCachedPaginationList, include_id } = this.props;
    if (include_id) {
      return getCachedPaginationList(include_id);
    }
  }

  // this need for isLoading flags etc, state flags must change immediately
  getOptionList = (isNewList: boolean) => {
    const { cachedList, props, startLoader, debounceGetOptionListFn } = this;
    const { updateCurrentPaginationList } = props;

    if (!cachedList) {
      startLoader(isNewList);
      debounceGetOptionListFn(isNewList);
    } else {
      updateCurrentPaginationList({ list: cachedList });
    }
  };

  onBlur = () => {
    const { input, blurTimeOut } = this.props;
    const { index } = this.state;
    this.focused = false;
    this.timeout = setTimeout(() => {
      if (!this.focused) {
        if (index && index >= 1) this.doSelect(index);
        this.handleClose();
        input.onBlur();
      }
    }, blurTimeOut);
  };

  onFocus = () => {
    this.focused = true;
    if (this.timeout) {
      clearTimeout(this.timeout);
      this.timeout = undefined;
    }
  };

  onCreate = (cb?: () => void) => {
    const { onCreate, input } = this.props;

    onCreate()
      .then((options) => {
        if (options) {
          const set = RecordCellSet({ value: '', options });
          input.onCreate(set, cb);
        }
      })
      .catch(() => {
        if (this.input) this.input.focus();
        this.slected = false;
      });
  };

  onEdit = (optionItem: OptionsItemType, cb?: () => void) => {
    const { onEdit, input } = this.props;

    onEdit(optionItem.get('value'))
      .then((options) => {
        const set = RecordCellSet({
          value: optionItem.get('value'),
          display: optionItem.get('display'),
          options,
        });
        input.onCreate(set, cb);
      })
      .catch(() => {
        if (this.input) this.input.focus();
        this.slected = false;
      });
  };

  onDropCreate = async (dndValue: string) => {
    const cb = async () => {
      const { options } = this;
      const testDndValue = testOption(dndValue);
      const list = options.filter(testDndValue(false));
      const results = options.findIndex(testDndValue(true));

      if (this.isCreateAvailable) {
        if (results < 0) {
          // if there is no exact match, we show list of submatches found,
          // or open create dialog if mo matches
          if (list.size > 0) {
            await this.setValue(dndValue, 0);
            this.handleOpen();
          } else {
            await this.setValue(dndValue);
            this.onCreate(this.cbEnter);
          }
          // this.props.input.onToggleOpen(true);
        } else if (list.size === 1) {
          this.doSelect(results, this.cbEnter);
        } else {
          await this.setValue(dndValue, 0);
          this.handleOpen();
        }
      } else {
        this.setValue(dndValue, 0).then(() => {
          this.getOptionList(true);
          this.handleOpen();
        });
      }
    };

    this.debounceGetOptionListFn(true, dndValue, cb);
  };

  onChange = (value, cb = (x) => x) => {
    const { options, props } = this;
    const {
      input: { onChange },
    } = props;
    const item = options.filter((f) => f.value === value).first();
    const { display } = item || { display: '' };
    onChange({ target: { value, display } }, cb);
  };

  onRenderItems = (from, to) => {
    this.fromItem = from;
    this.toItem = to;
    const { options } = this;
    const { isLoading } = this.state;
    const { nextPageToken } = this.props;
    const { size } = options;
    // todo change after back fix
    const queryPoint = size - QUERY_PAGE_SIZE / 4;

    // todo change after back fix
    if (MAX_ITEMS_DISPLAY >= size && size >= QUERY_PAGE_SIZE / 2 && nextPageToken && to > queryPoint && !isLoading) {
      this.loadOptionList().then();
    }
  };

  get term(): string {
    const { options } = this;
    const { input } = this.props;
    if (input.value.value) {
      const value = options.filter((f) => f.id === input.value.value).first();
      this.slected = true;
      if (typeof value === 'string') return this.props.valueNormalize(value);
    }
    return '';
  }

  get value(): ?string {
    const {
      state: { term },
    } = this;
    return term || this.term;
  }

  get isCreateAvailable() {
    return this.props.input.isCreatable;
  }

  get options() {
    const { term } = this.state;
    const { optionList } = this.props;
    return term && this.cachedList ? this.filterSearchList(optionList, term) : optionList;
  }

  get currentOptions() {
    const { term, currentOptionsList } = this.state;
    return term && this.cachedList ? this.filterSearchList(currentOptionsList, term) : currentOptionsList;
  }

  getSearchQuery = (isNewList: boolean, search: string | null) => {
    const { term } = this.state;
    const {
      name: { name },
      nextPageToken,
      include_id,
    } = this.props;

    const queryForAllPages = {
      search: '',
      pageSize: QUERY_ALL_PAGES,
      pageToken: null,
    };

    return {
      search: term || search,
      pageSize: QUERY_PAGE_SIZE,
      cellName: name,
      pageToken: isNewList ? null : nextPageToken,
      ...(include_id && queryForAllPages),
    };
  };

  setValue = (term: string, index: number = -1): Promise<void> =>
    new Promise((resolve) => {
      this.setState({ term, index }, () => {
        resolve();
      });
    });

  setActive = (index: number) => {
    if (this.state.index !== index) {
      this.setState({ index }, () => {
        if (index >= 0) {
          this.scrollTo(index);
        }
      });
    }
  };

  setDirection = (isUp: boolean) => {
    this.setState({
      isDirectionUP: isUp, // eslint-disable-line react/no-unused-state
    });
  };

  keyDown = (e: SyntheticKeyboardEvent<HTMLButtonElement>) => {
    const { options: optionList } = this;
    const { index, isLoading } = this.state;
    const { input } = this.props;

    switch (e.keyCode) {
      case 38: {
        // up arrow
        const overlapIndex = optionList.size - 1;
        this.setActive(index > 0 ? index - 1 : overlapIndex);
        break;
      }
      case 40: {
        // down arrow
        const overlapIndex = 0;
        this.setActive(optionList.size > index + 1 ? index + 1 : overlapIndex);
        break;
      }
      case 27: {
        // escape
        this.handleClose();
        input.onKeyDown({ keyCode: 27 });
        break;
      }
      case 8: {
        // backspace
        if (this.slected) {
          this.onChange('');
          this.slected = false;
        }
        break;
      }
      case 9:
      case 13: {
        // TAB
        // enter
        e.preventDefault();

        if (isLoading) {
          this.setState({ isPressSelectOnLoadingEnd: true });
        } else {
          this.doSelect(index, this.cbEnter);
        }

        break;
      }
      // no default
    }
  };

  filterSearchList = (data, term: string) => {
    const testValue = testOption(term);
    return data.filter(testValue(false));
  };

  doSelect = (index: number, cb?: () => void) => {
    const { options: optionList } = this;
    const { input } = this.props;
    const { isLoading, term } = this.state;
    const item = optionList.get(index);

    if ((item && !item.get('isActive')) || isLoading) return;

    if (index === -1) {
      if (term) {
        this.onCreate(cb);
      } else {
        const value = input.value.value || null;
        // if nothing was selected and input value exist, update with existing value
        // if nothing was selected but value was deleted - update with null
        // to delete on backend
        this.onChange(value, cb);
      }
    } else if (item) {
      this.onChange(item.value, cb);
    }
    this.slected = true;
    this.handleClose();
    this.setState({ isPressSelectOnLoadingEnd: false });
  };

  cbEnter = () => this.props.input.onKeyDown({ keyCode: 13 });

  refsProxy = (el: ?HTMLInputElement) => {
    this.input = el;
    this.props.getRefs(el, this);
  };

  searchHandler = (
    e: CustomInputEvent,
    isLoading?: boolean,
    idx?: number,
    cb: (e: CustomInputEvent) => void = () => {},
  ) => {
    const { value } = e.currentTarget;
    this.handleOpen();
    // if we have search results to display set index to 0. 0 means we select first from a list
    // if no search results, -1 must remain caz it allows for onCreate to be invoked
    // on enter key event
    const index = idx !== 'undefined' ? idx : this.options.size ? 0 : -1;
    this.setState({ term: value, index, ...(isLoading !== 'undefined' && { isLoading }) }, cb);
  };

  asyncSearchHandler = (e: CustomInputEvent) => {
    this.searchHandler(e, true, 0, this.getOptionList(true));
  };

  onSearchChanged = (e: CustomInputEvent) => {
    const { cachedList, searchHandler, asyncSearchHandler } = this;
    const handler = cachedList ? searchHandler : asyncSearchHandler;
    handler(e);
  };

  scrollTo = (index) => {
    const shiftVLIndex = this.isCreateAvailable ? index + 1 : index;
    if (this.listRef && this.listRef.current) {
      this.listRef.current.scrollToItem(shiftVLIndex, 'smart');
    }
  };

  startLoader = (isNewList: boolean) => {
    const { currentOptionsList } = this.state;
    this.setState({ isLoading: true, currentOptionsList: isNewList ? new List() : currentOptionsList });
  };

  loadOptionList = (isNewList: boolean = false, search: string | null = null) => {
    const { term } = this.state;
    const { getJEList, include_id } = this.props;
    this.startLoader(isNewList);
    if (term !== null || search !== null) {
      return new Promise((resolve, reject) => {
        getJEList({ query: this.getSearchQuery(isNewList, search), isNewList, resolve, reject, include_id });
      }).finally(() => this.setState({ isLoading: false }));
    }
  };

  handleClose = () => {
    const { input } = this.props;

    input.onToggleOpen(false);
  };

  handleOpen = () => {
    const { input } = this.props;

    input.onToggleOpen(true);
  };

  handleToggle = () => {
    const { isOpen } = this.props;
    const fn = isOpen ? this.handleClose : this.handleOpen;
    fn();
  };

  slected: boolean = false;

  input: ?HTMLInputElement;

  focused: boolean;

  timeout: ?TimeoutID;

  listRef: any = React.createRef();

  loadVListDelay: ?TimeoutID;

  fromItem: number = 0;

  toItem: number = 0;

  currentMinWidth: number = 0;

  render() {
    const {
      classes,
      style,
      name,
      pageItemsCount,
      isOpen,
      id,
      placeholder,
      disabled,
      maxWidth,
      rtl,
      onComplete,
      valueNormalize,
      children,
    } = this.props;
    const { isLoading, index, term } = this.state;

    const isVisibleIcon = term && this.options.size === 0 && !isLoading;

    return (
      <div
        className={cx(classes.wrapper, 'is-paginated')}
        tabIndex="0" // eslint-disable-line jsx-a11y/no-noninteractive-tabindex
        onFocus={this.onFocus}
        onBlur={this.onBlur}
        onKeyDown={this.keyDown}
        role="combobox"
        aria-expanded={isOpen}
        aria-controls={id}
        data-element={elements.je.autocomplete.container}
        data-element-id={name.name}
      >
        <div className={cx(classes.fieldWrapper, { [classes.open]: isOpen, 'field-with-icon': isVisibleIcon })}>
          {isVisibleIcon && (
            <div className={classes.fieldIconWrapper}>
              <Tooltip
                t={{
                  id: 'tooltip.je.cell.paginated.empty.attention',
                  defaultMessage: 'No results found matching search criteria',
                }}
              >
                <WarningIcon color="error" />
              </Tooltip>
            </div>
          )}
          <input
            id={id}
            aria-autocomplete="list"
            autoComplete="no"
            className={classes.field}
            placeholder={placeholder}
            name={name._cell}
            value={this.value}
            disabled={disabled}
            ref={this.refsProxy}
            onChange={this.onSearchChanged}
            onDoubleClick={() => this.handleOpen()}
            style={style}
            data-element={elements.je.autocomplete.field}
            data-element-id={`${name._cell}-${name.name}`}
          />
        </div>
        {isOpen && this.input ? (
          <AutocompleteOptions
            inputEl={this.input}
            maxWidth={maxWidth}
            rtl={rtl}
            optionList={this.currentOptions}
            doSelect={(e) => this.doSelect(e, onComplete)}
            valueNormalize={valueNormalize}
            index={index}
            term={term}
            setDirection={this.setDirection}
            onRenderItems={this.onRenderItems}
            isLoading={isLoading}
            pageItemsCount={pageItemsCount}
            itemHeight={30}
            setListRef={this.listRef}
            setActive={this.setActive}
            onClickEdit={(item: OptionsItemType) => this.onEdit(item, this.cbEnter)}
            addNewItem={this.isCreateAvailable ? () => this.onCreate(this.cbEnter) : null}
          />
        ) : null}
        <div // eslint-disable-line
          role="button"
          className={cx(classes.btnShield, classes.btnRotate)}
          onClick={this.handleToggle}
          data-element={elements.je.autocomplete.shield}
        />
        {children(this.value, this.searchHandler)}
      </div>
    );
  }
}

Autocomplete.defaultProps = {
  valueNormalize: defaultValueNormalize,
  filterList: defaultFilterList,
  blurTimeOut: 100,
};

const mapStateToProps = (state) => ({
  optionList: paginationListSelector(state),
  nextPageToken: paginationListPageTokenSelector(state),
  documentID: documentDocumentIdSelector(state),
  getCachedPaginationList: getCachedPaginationListSelector(state),
});

const mapDispatchToProps = {
  getJEList: getJEListAction,
  getIndexForm: getJEIndexListItemDataAction,
  updateCurrentPaginationList: updateCurrentPaginationListAction,
};

export { Autocomplete as AC };

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(sheet),
  withManageListItemModal,
)(Autocomplete);
