// @flow
import { createContext } from 'react';

export type TCompanyContext = {
  companyType: 'standard' | 'confidential',
  toggleUpload: () => void,
};

const CompanyContext: TCompanyContext = createContext({
  companyType: undefined,
  toggleUpload: undefined,
});

export default CompanyContext;
