/* @flow */
import { SortableElement } from 'react-sortable-hoc';

export type Props = {|
  item: any,
  itemIndex: number,
  children: any,
|};

const ColSortableItem = SortableElement(({ children, itemIndex, item }) => children(item, itemIndex));

export default ColSortableItem;
