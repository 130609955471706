// @flow
import React from 'react';
import { useIntl } from 'react-intl';

import { TOptions } from 'components/mui/Form/Autocomplete/AutocompleteBase';
import ListboxVirtualized from 'components/mui/Form/Autocomplete/components/ListboxVirtualized';
import AutocompleteMui, { AutocompleteProps as TAutocompleteProps } from '@mui/material/Autocomplete';
import { TextField } from 'components/mui/Form/Autocomplete/components/styledComponents';

export type TAutocompleteVirtualized = {
  options: TOptions,
  InputProps?: {
    label?: React$Node | string,
    placeholder?: React$Node | string,
  },
} & TAutocompleteProps;

const AutocompleteVirtualized: React$StatelessFunctionalComponent<TAutocompleteVirtualized> = ({
  options,
  InputProps = {},
  inputProps = {},
  InputLabelProps = {},
  ...otherAutocompleteProps
}) => {
  const intl = useIntl();

  return (
    <AutocompleteMui
      disableListWrap
      options={options}
      ListboxComponent={ListboxVirtualized}
      renderOption={(props, option, state) => [props, option, state]}
      renderInput={(params) => (
        <TextField
          {...InputProps}
          {...params}
          inputProps={{ ...params.inputProps, ...inputProps }}
          InputLabelProps={{ ...params.InputLabelProps, ...InputLabelProps }}
        />
      )}
      noOptionsText={intl.formatMessage({ id: 'options.noOptionsText', defaultMessage: 'No options' })}
      isOptionEqualToValue={(listOption, option) => (option ? listOption.value === option.value : false)}
      {...otherAutocompleteProps}
    />
  );
};

export default AutocompleteVirtualized;
