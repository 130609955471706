/* eslint import/no-webpack-loader-syntax: 0 */
/* eslint import/no-unresolved: 0 */
/* eslint import/extensions: 0 */
import Worker from 'worker-loader!./contextWorker';
import { call, cancelled } from 'redux-saga/effects';
import * as Sentry from '@sentry/browser';
import tokenKeeper from 'lib/apiTokenKeeper';
import { CONFIGURABLE_API_ENDPOINT } from 'lib/apiHelpers';

function messageFactory(dispatch, { success, failure, refresh = (x) => x }) {
  return ({ data }) => {
    switch (data.type) {
      case 'success':
        dispatch(success(data.payload));
        break;

      case 'failure':
        dispatch(failure(data.payload));
        break;

      case 'refresh':
        refresh(data.payload);
        break;

      default:
        break;
    }
  };
}

function listner(worker, handler) {
  return new Promise(() => {
    worker.addEventListener('message', handler);
  });
}

// deprecated
export function* createWorker(dispatch, ensureWorkers, ...args) {
  const worker = new Worker();
  const { apiName, config, success, failure, interval } = yield call(ensureWorkers, ...args);
  const authData = yield call(tokenKeeper.getRequestData);

  worker.postMessage({ type: 'init', request: { apiName, config, interval, authData } });
  const messageHandler = messageFactory(dispatch, { success, failure });
  try {
    yield call(listner, worker, messageHandler);
  } finally {
    if (yield cancelled()) {
      worker.terminate();
    }
  }
}

export class MultyWorker {
  constructor(dispatch) {
    this.worker = new Worker();
    this.dispatch = dispatch;
    this.messageHandler = {};
    this.terminated = false;

    this.init = this.init.bind(this);
    this.listen = this.listen.bind(this);
    this.update = this.update.bind(this);
  }

  _post({ type, request }) {
    this.worker.postMessage({ type, request });
  }

  _handler = ({ data }) => this.messageHandler[data.name]({ data });

  getAuthData = async () => {
    const reqData = await tokenKeeper.getRequestData();
    const isSSO = !tokenKeeper.isDokkaLogin();
    return { ...reqData, isSSO };
  };

  refreshAuthData = async (data) => {
    const authData = await this.getAuthData();
    if (authData.token) {
      this._post({ type: 'refresh', request: { ...data, authData } });
    }
  };

  *init(ensureWorkers, ...args) {
    // eslint-disable-next-line max-len
    const { apiName, config, success, failure, prepare, disabled, interval } = yield call(ensureWorkers, ...args);
    if (!disabled) {
      const authData = yield call(this.getAuthData);
      const baseUrl = CONFIGURABLE_API_ENDPOINT;
      this._post({ type: 'init', request: { apiName, config, interval, authData, baseUrl } });
      this.messageHandler[apiName] = messageFactory(this.dispatch, {
        apiName,
        success,
        failure,
        refresh: this.refreshAuthData,
      });
    }
    return {
      apiName,
      prepare,
    };
  }

  *update(id, prepare, matchParams) {
    const config = {
      params: yield call(prepare, matchParams),
    };
    const baseUrl = CONFIGURABLE_API_ENDPOINT;
    const authData = yield call(this.getAuthData);
    const request = { apiName: id, config, authData, baseUrl };
    this._post({ type: 'update', request });
    return Promise.resolve();
  }

  *listen() {
    try {
      document.addEventListener('visibilitychange', this.visibilityHandler);
      yield call(listner, this.worker, this._handler);
    } catch (err) {
      console.warn(err, 'ERR Worker');
    } finally {
      if (yield cancelled()) {
        document.removeEventListener('visibilitychange', this.visibilityHandler);
        this.worker.terminate();
        this.terminated = true;
      }
    }
  }

  has = (id: string) => Boolean(this.messageHandler[id]);

  visibilityHandler = () => {
    this._post({ type: 'changeStatus', request: document.visibilityState });
  };

  terminate = (id: string) => {
    this._post({ type: 'terminate', request: { apiName: id } });
  };

  pause = (id: string) => {
    this._post({ type: 'pause', request: { apiName: id } });
  };

  resume = (id: string) => {
    this._post({ type: 'resume', request: { apiName: id } });
  };

  forceTerminateAll = () => {
    document.removeEventListener('visibilitychange', this.visibilityHandler);
    this.worker.terminate();
    // at this stage worker must be terminated already and if not
    // it identifies that we are fixing memory leak and terminating
    // it after it was not due to fast page leave
    if (!this.terminated) {
      console.error('Worker termination that was not terminated naturally');
      Sentry.captureMessage('Worker termination that was not terminated naturally');
    }
  };
}
