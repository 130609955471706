import { List, Map } from 'immutable';
import type { THotkeyCombination, THotkeyList } from 'domain/documents/types.js.flow';

export const extraKeyList = new List(['Control', 'Meta', 'Alt', 'Shift']);

export const pickExtraKeys = (keys: THotkeyCombination = new List()) => {
  if (keys.size > 0) {
    return extraKeyList.filter((k: string) => keys.includes(k));
  }

  return new List();
};

export const hotkeyMatcher = (
  e: SyntheticKeyboardEvent<HTMLElement>,
  hotkeysList: THotkeyList = List(),
  onMatch?: (keys: THotkeyCombination) => void,
): boolean => {
  if (e && hotkeysList.size > 0) {
    const { code, ctrlKey, metaKey, altKey, shiftKey } = e;
    const eventCode = code;

    return hotkeysList.some((combination) =>
      combination.keys.some((keys) => {
        const isMatchKey = keys.includes(eventCode);
        const extraKeys = pickExtraKeys(keys);
        let isMatch = isMatchKey;

        // this means its combination like Ctrl + Key Alt + Key etc
        if (isMatchKey && extraKeys.size > 0) {
          extraKeys.forEach((extraKey: string) => {
            switch (extraKey) {
              case 'Control':
                isMatch = ctrlKey;
                break;
              case 'Meta':
                isMatch = metaKey;
                break;
              case 'Alt':
                isMatch = altKey;
                break;
              case 'Shift':
                isMatch = shiftKey;
                break;
              default:
                break;
            }
          });
        }

        if (isMatch && typeof onMatch === 'function') {
          onMatch(keys);
        }

        return isMatch;
      }),
    );
  }

  return false;
};

export const keyCodeForUI = new Map({
  Backspace: 'Backspace',
  Tab: 'Tab',
  Enter: 'Enter',
  Shift: 'Shift',
  ShiftLeft: 'Shift',
  ShiftRight: 'Shift',
  Control: 'Ctrl',
  ControlLeft: 'Ctrl',
  ControlRight: 'Ctrl',
  Meta: 'Cmd',
  MetaLeft: 'Cmd',
  MetaRight: 'Cmd',
  Alt: 'Alt',
  AltLeft: 'Alt',
  AltRight: 'Alt',
  Pause: 'Pause',
  CapsLock: 'CapsLock',
  Escape: 'Escape',
  Space: 'Space',
  PageUp: 'PageUp',
  PageDown: 'PageDown',
  Home: 'Home',
  ArrowLeft: 'ArrowLeft',
  ArrowUp: 'ArrowUp',
  ArrowRight: 'ArrowRight',
  ArrowDown: 'ArrowDown',
  PrintScreen: 'PrintScreen',
  Insert: 'Insert',
  Delete: 'Delete',
  Digit0: '0',
  Digit1: '1',
  Digit2: '2',
  Digit3: '3',
  Digit4: '4',
  Digit5: '5',
  Digit6: '6',
  Digit7: '7',
  Digit8: '8',
  Digit9: '9',
  Numpad0: '0',
  Numpad1: '1',
  Numpad2: '2',
  Numpad3: '3',
  Numpad4: '4',
  Numpad5: '5',
  Numpad6: '6',
  Numpad7: '7',
  Numpad8: '8',
  Numpad9: '9',
  KeyA: 'A',
  KeyB: 'B',
  KeyC: 'C',
  KeyD: 'D',
  KeyE: 'E',
  KeyF: 'F',
  KeyG: 'G',
  KeyH: 'H',
  KeyI: 'I',
  KeyJ: 'J',
  KeyK: 'K',
  KeyL: 'L',
  KeyM: 'M',
  KeyN: 'N',
  KeyO: 'O',
  KeyP: 'P',
  KeyQ: 'Q',
  KeyR: 'R',
  KeyS: 'S',
  KeyT: 'T',
  KeyU: 'U',
  KeyV: 'V',
  KeyW: 'W',
  KeyX: 'X',
  KeyY: 'Y',
  KeyZ: 'Z',
  ContextMenu: 'ContextMenu',
  NumpadMultiply: '*',
  NumpadAdd: '+',
  NumpadSubtract: '-',
  NumpadDecimal: '.',
  NumpadDivide: '/',
  F1: 'F1',
  F2: 'F2',
  F3: 'F3',
  F4: 'F4',
  F5: 'F5',
  F6: 'F6',
  F7: 'F7',
  F8: 'F8',
  F9: 'F9',
  F10: 'F10',
  F11: 'F11',
  F12: 'F12',
  NumLock: 'NumLock',
  ScrollLock: 'ScrollLock',
  Semicolon: ';',
  Equal: '=',
  Comma: ',',
  Minus: '-',
  Period: '.',
  Slash: '/',
  Backquote: '`',
  BracketLeft: '[',
  Backslash: '\\',
  BracketRight: ']',
  Quote: "'",
});
