// @flow
import React from 'react';

import SelectBase, { type TSelectBase } from 'components/mui/Form/Select/SelectBase';
import type { FieldProps } from 'redux-form/lib/FieldProps.types.js.flow';

type TSelectForm = {} & TSelectBase & FieldProps;

const SelectForm: React$StatelessFunctionalComponent<TSelectForm> = (props) => {
  const { input, meta, FormControlProps = {}, ...rest } = props;
  const { onChange, value } = input;
  const isError = Boolean(meta.touched && meta.error);

  return (
    <SelectBase
      FormControlProps={{ error: isError, ...FormControlProps }}
      onChange={onChange}
      value={value}
      {...rest}
    />
  );
};

export default SelectForm;
