/* @flow */
import mediaQuery from 'lib/mediaQuery';
import cross from './cross.svg';

type Props = {
  red: boolean,
};

export default (theme) => ({
  container: {
    position: 'fixed',
    left: 0,
    right: 0,
    bottom: 0,
    top: 0,
    zIndex: 9999,
    background: 'rgba(0, 0, 0, 0.4)',
    '@supports (-webkit-backdrop-filter: blur(10px))': {
      backdropFilter: 'blur(10px)',
      background: 'none',
    },
    '&$mountedContainer': {
      display: 'block',
    },
  },

  defaultPos: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  canMove: { cursor: 'grab' },

  moving: { cursor: 'grabbing' },

  moveArea: {
    flip: false,
    position: 'absolute',
    outline: 'none',
    top: 0,
    left: 0,
    zIndex: 2,
    width: '100%',
    height: 70,
  },

  mountedContainer: {},
  overlay: {
    position: 'absolute',
    display: 'block',
    width: '100%',
    left: 0,
    right: 0,
    bottom: 0,
    top: 0,
    cursor: 'default',
    background: 'none',
    textDecoration: 'none',
    border: '0 none',
    padding: 0,
  },
  popup: {
    display: 'inline-block',
    willChange: 'transform',
    alignSelf: 'center',
    position: 'relative',
    zIndex: 2,
    boxShadow: '0 12px 24px rgba(0, 0, 0, 0.1)',
    borderTop: ({ red }: Props) => (red ? '4px solid #BC5353' : `4px solid ${theme.colors.primaryLight}`),
    backgroundColor: '#ffffff',
    border: 'none',
    outline: 'none',
    [`@media (max-height: ${mediaQuery.maxHeight})`]: {
      // maxHeight: 'calc(100vh - 180px)',
    },
  },
  mountedPopup: {
    margin: 0,
  },
  close: {
    position: 'absolute',
    zIndex: 11,
    cursor: 'pointer',
    top: 20,
    right: 20,
    width: 20,
    height: 20,
    background: `url("${cross}") scroll no-repeat center`,
    border: 'none',
    outline: 'none',
  },
  move: {
    position: 'absolute',
    cursor: 'move',
    top: 23,
    left: 23,
    width: 14,
    height: 14,
    background: 'red',
  },
  '@keyframes bounceIn': {
    'from, 20%, 40%, 60%, 80%, to': {
      animationTimingFunction: 'cubic-bezier(0.215, 0.61, 0.355, 1)',
    },

    '0%': {
      opacity: 0,
      transform: 'scale3d(0.3, 0.3, 0.3)',
    },

    '20%': {
      transform: 'scale3d(1.1, 1.1, 1.1)',
    },

    '40%': {
      transform: 'scale3d(0.9, 0.9, 0.9)',
    },

    '60%': {
      opacity: 1,
      transform: 'scale3d(1.03, 1.03, 1.03)',
    },

    '80%': {
      transform: 'scale3d(1, 1, 1)',
    },

    to: {
      opacity: 1,
      transform: 'scale3d(1, 1, 1)',
    },
  },
});
