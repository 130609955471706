// @flow
import React from 'react';
import { Link } from 'react-router-dom';
import { useIntl } from 'react-intl';

import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import SvgIcon from '@mui/material/SvgIcon';

import { grey } from '@mui/material/colors';

type SvgIconComponent = typeof SvgIcon;

type TTranslateObj = {
  id: string,
  defaultMessage: string,
};

type Props = {
  link?: string,
  title: TTranslateObj,
  description1: TTranslateObj,
  description2?: TTranslateObj,
  buttonText: TTranslateObj,
  imageSrc: string,
  Icon?: SvgIconComponent,
  onClick?: () => void,
};

const EmptyContainer: React$StatelessFunctionalComponent<Props> = ({
  link = '',
  imageSrc,
  Icon,
  title,
  description1,
  description2,
  buttonText,
  onClick = () => {},
}) => {
  const { formatMessage } = useIntl();

  const buttonProps = link ? { component: Link, to: link } : { onClick };

  return (
    <Box display="flex" alignItems="center" flexDirection="column" justifyContent="center" minHeight="60%">
      {Icon ? <Icon sx={{ width: 250, height: 250 }} color="disabled" /> : <img src={imageSrc} alt="no-results" />}
      <Typography variant="h5" mt={3} mb={1}>
        {formatMessage(title)}
      </Typography>
      <Box>
        <Typography variant="body1" color={grey[600]} textAlign="center">
          {formatMessage(description1)}
        </Typography>
        {description2 && (
          <Typography variant="body1" color={grey[600]} textAlign="center">
            {formatMessage(description2)}
          </Typography>
        )}
      </Box>
      <Button {...buttonProps} sx={{ mt: 4 }}>
        {formatMessage(buttonText)}
      </Button>
    </Box>
  );
};

export default EmptyContainer;
