export default (theme) => ({
  contentWrapper: {
    flip: false,
    direction: 'ltr',
  },
  itemWrapper: {
    flip: false,
    direction: 'ltr',
    position: 'relative',
    margin: theme.spacing(2.5, 7, 2.5, 0),

    '& .MuiBadge-badge': {
      right: '50%',
      fontSize: 14,
      height: 28,
      minWidth: 28,
      borderRadius: 14,
      backgroundColor: theme.palette.grey[500],
      padding: theme.spacing(0, 1.25),
    },
  },

  pageList: {
    flip: false,
    direction: 'ltr',
    padding: 0,
    display: 'flex',
    flexWrap: 'wrap',
  },
  docDragPage: {
    zIndex: 10004,
    boxShadow: '0px 12px 24px rgba(0, 0, 0, 0.1)',
    '& > div': {
      border: `2px solid ${theme.palette.primary.main}`,
    },
  },
});
