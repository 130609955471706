import React, { useState, useEffect } from 'react';
import { DateTimeField as DateTimeFieldMui } from '@mui/x-date-pickers/DateTimeField';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import moment from 'moment';
import { useIntl } from 'react-intl';

const DateTimeField = ({ input: { value, ...restInput }, meta: { touched, error }, extraProps, ...rest }) => {
  const { formatMessage } = useIntl();
  const isError = Boolean(touched && error);
  const [date, setDate] = useState(null);

  // this check required because redux-form can provide an invalid value like 'YYYY-01-12',
  // but for momentJS it's a valid date and the app crashes
  useEffect(() => {
    if (moment.isMoment(value) && moment(value).isValid()) {
      setDate(value);
    }
  }, [value]);

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <DateTimeFieldMui
        error={isError}
        {...restInput}
        {...rest}
        {...extraProps}
        value={date}
        onBlur={() => restInput.onBlur()}
        helperText={
          isError && formatMessage({ id: `message.${error.split(' ').join('_')}`, defaultMessage: `${error}` })
        }
      />
    </LocalizationProvider>
  );
};

export default DateTimeField;
