// @flow
import { Set } from 'immutable';
import * as ACL from 'domain/restriction';
import { localRights } from 'pages/company/DocumentContextMenu/helpers';
import type { FilterParamsType } from 'pages/company/DocumentContextMenu/helpers';

const items = [
  {
    id: 'documents.linkedPanel.export',
    defaultMessage: 'Export',
    onClick: () => null,
    restriction: localRights.policy(localRights.paramsName.isAuthorized),
  },
  {
    id: 'document.show.actions.downloadLinked',
    defaultMessage: 'Download Linked',
    onClick: () => null,
    restriction: localRights.policy(localRights.paramsName.isAuthorized),
  },
  {
    id: 'document.show.actions.printLinked',
    defaultMessage: 'Print Linked',
    onClick: () => null,
    restriction: localRights.policy(localRights.paramsName.isAuthorized),
  },
];

export const actionsList = (d: FilterParamsType) =>
  items.filter(ACL.arrayFilter(localRights.restriction('', '', [], d.restriction, new Set())));
