// @flow
import React, { type StatelessFunctionalComponent } from 'react';
import { SortableContainer } from 'react-sortable-hoc';
import DocPage from '../DocPage';
import get from 'lodash/get';
import isFunction from 'lodash/isFunction';

export type GetKeyFn = (Object) => string | number;

export type Props = {|
  items: any,
  idKey?: GetKeyFn | string,
  children: any,
  className: string,
  dataElement: string,
|};

const DocPageList: StatelessFunctionalComponent<Props> = ({
  className,
  items,
  children,
  idKey,
  dataElement,
}: Props) => (
  <ul className={className} data-element={dataElement}>
    {items.map((item, index) => {
      const key = isFunction(idKey) ? idKey(item) : get(item, idKey, index);
      return (
        <DocPage key={`item-${key}`} index={index} item={item} itemIndex={index}>
          {children}
        </DocPage>
      );
    })}
  </ul>
);

export default SortableContainer(DocPageList);
