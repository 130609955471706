import React, { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  documentGet,
  documentGetLinkedAction,
  documentLinkedTagSelector,
  documentSelector,
  documentSetAsFinancialAction,
  moveDocumentToCategoryAction,
  nextDocumentAction,
} from 'domain/documents';
import { isCurrentUserSignaturePendingSelector } from 'domain/approvals';
import { navigationSelector, userFeaturesSelector } from 'domain/env';

import { useConfirm } from 'material-ui-confirm';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import toast from 'components/Toast';
import { QuickActionsWidget } from 'pages/document/components/QuickActionsWidgetForGeneralDocument/StyledComponents';

const mapStateToProps = (state) => ({
  document: documentSelector(state),
  documentLinkedTag: documentLinkedTagSelector(state),
  isCurrentUserSignaturePending: isCurrentUserSignaturePendingSelector(state),
  userFeatures: userFeaturesSelector(state),
  navigation: navigationSelector(state),
});

const QuickActionsWidgetForGeneralDocument = () => {
  const dispatch = useDispatch();
  const confirm = useConfirm();
  const {
    document: { tags, linkid, documentID },
    documentLinkedTag,
    isCurrentUserSignaturePending,
    userFeatures,
    navigation,
  } = useSelector(mapStateToProps);
  const { companyId } = useParams();
  const { formatMessage } = useIntl();
  const isConfidential = tags.has('_S_CONFIDENTIAL');
  const workspaceType = isConfidential ? 'confidential' : 'standard';
  const isLocked = linkid.includes('_locked');

  const convertToFinancial = useCallback(() => {
    new Promise((resolve, reject) => {
      dispatch(documentSetAsFinancialAction({ resolve, reject }));
    }).then(() => {
      toast.info(
        formatMessage({
          id: 'document.actions.financial.success',
          defaultMessage: 'Document marked as financial',
        }),
      );
    });
  }, [dispatch, formatMessage]);

  const isNextDisabled = useMemo(() => !navigation.get('listLength') || navigation.get('listLength') < 2, [navigation]);

  const isAllowAutoConfirm = useMemo(() => !userFeatures.get('modal', true), [userFeatures]);

  const moveToArchive = useCallback(() => {
    new Promise((resolve, reject) => {
      dispatch(
        moveDocumentToCategoryAction({
          categoryId: 23, // 23 - archive category id
          linkedID: [linkid],
          documentID: [documentID],
          workspaceType,
          resolve,
          reject,
        }),
      );
    }).then(() => {
      if (documentLinkedTag === linkid) {
        dispatch(documentGetLinkedAction({ tag: documentLinkedTag }));
      }
      if (!isNextDisabled) {
        dispatch(nextDocumentAction(0));
      }
      dispatch(documentGet({ documentId: documentID, companyId }));
    });
  }, [dispatch, workspaceType, documentID, linkid, companyId, documentLinkedTag, isNextDisabled]);

  const onClickMoveToArchive = useCallback(() => {
    if (linkid && !isAllowAutoConfirm) {
      const messageValues = {
        catName: (
          <span style={{ fontWeight: 600 }}>
            {formatMessage({ id: 'category.name.archive', defaultMessage: 'Archive' })}
          </span>
        ),
      };
      confirm({
        title: formatMessage({ id: 'document.show.modals.moveLinked.title' }, { ...messageValues }),
        description: formatMessage(
          {
            id: 'document.show.modals.moveLinked.placeholder.moveOneAndUnlink',
            defaultMessage: 'Linked document will be moved to Archive and unlinked. Are you sure?',
          },
          { ...messageValues },
        ),
        confirmationText: formatMessage({
          id: 'button.yes',
          defaultMessage: 'Yes',
        }),
      }).then(() => {
        moveToArchive();
      });
    } else {
      moveToArchive();
    }
  }, [isAllowAutoConfirm, moveToArchive, confirm, formatMessage, linkid]);

  return (
    <QuickActionsWidget>
      <Typography variant="subtitle1" fontWeight="bold" mb={0.5} lineHeight={1.5}>
        {formatMessage({
          id: 'document.quickActionsWidget.title',
          defaultMessage: 'This document is classified as a General Document.',
        })}
      </Typography>
      <Typography variant="body2" color="text.secondary" mb={2}>
        {formatMessage({
          id: 'document.quickActionsWidget.subtitle',
          defaultMessage: 'You may use one of the following options:',
        })}
      </Typography>
      <Button fullWidth onClick={convertToFinancial} sx={{ mb: 1 }}>
        {formatMessage({
          id: 'button.markAsFinancial',
          defaultMessage: 'Mark as financial document',
        })}
      </Button>
      {!isCurrentUserSignaturePending && !isLocked && (
        <Button fullWidth variant="text" onClick={onClickMoveToArchive}>
          {formatMessage({
            id: 'button.moveToArchive',
            defaultMessage: 'Move to archive',
          })}
        </Button>
      )}
    </QuickActionsWidget>
  );
};

export default QuickActionsWidgetForGeneralDocument;
