// @flow
import React from 'react';
import { useIntl } from 'react-intl';
import { generatePath, useParams, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import * as ACL from 'domain/restriction';
import ROUTES_PATH from 'domain/router/routesPathConfig';

import LayoutProfile from 'components/mui/Layouts/Profile';
import StickySubheader from 'components/mui/Layouts/components/StickySubheader';
import Tabs from 'components/Tabs';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

const mapStateToProps = (state) => ({
  isGranted: ACL.isGranted(state),
});

const AccountSettingsPage = () => {
  const { '*': asterisk } = useParams();
  const [section] = asterisk.split('*');
  const { formatMessage } = useIntl();
  const { isGranted } = useSelector(mapStateToProps);

  const links = [
    {
      isAllow: isGranted(ACL.IS_AUTHORIZED),
      id: 'profile.info.title',
      defaultMessage: 'My Profile',
      to: generatePath(ROUTES_PATH.PROFILE_SETTINGS_MAIN.absolute),
      tabValue: 'main',
      isDisabledSubmitButton: false,
    },
    {
      isAllow: !isGranted(ACL.IS_SUPPLIER_USER),
      id: 'profile.notifications.nav.title',
      defaultMessage: 'Notifications',
      to: generatePath(ROUTES_PATH.PROFILE_SETTINGS_NOTIFICATIONS.absolute),
      tabValue: 'notifications',
      isDisabledSubmitButton: false,
    },
    {
      isAllow: !isGranted(ACL.IS_SUPPLIER_USER),
      id: 'profile.preferences.nav.title',
      defaultMessage: 'Preferences',
      to: generatePath(ROUTES_PATH.PROFILE_SETTINGS_PREFERENCES.absolute),
      tabValue: 'preferences',
      isDisabledSubmitButton: true,
    },
    {
      isAllow: isGranted(ACL.IS_SUPPLIER_USER),
      id: 'profile.aliases.title',
      defaultMessage: 'Other emails',
      to: generatePath(ROUTES_PATH.PROFILE_SETTINGS_ALIASES.absolute),
      tabValue: 'aliases',
      isDisabledSubmitButton: false,
    },
  ];

  const tabs = links.filter(({ isAllow }) => isAllow);
  const activeTab = tabs.filter(({ tabValue }) => tabValue === section)[0];

  return (
    <LayoutProfile>
      <StickySubheader>
        <Box display="flex" alignItems="center">
          <Typography variant="h6">
            {formatMessage({ id: 'profile.info.title', defaultMessage: 'My Profile' })}
          </Typography>
          <Button
            form="user_profile_form"
            type="submit"
            sx={{ minWidth: 120, ml: 'auto' }}
            disabled={activeTab?.isDisabledSubmitButton}
          >
            {formatMessage({ id: 'button.save', defaultMessage: 'Save' })}
          </Button>
        </Box>
      </StickySubheader>
      <Container maxWidth={false}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={section} tabs={tabs} />
        </Box>
        <Box py={2}>
          <Outlet />
        </Box>
      </Container>
    </LayoutProfile>
  );
};

export default AccountSettingsPage;
