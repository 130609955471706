// @flow
import React, { useCallback, useState, useMemo, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { debounce } from 'throttle-debounce';
import { Set } from 'immutable';

import { updateQueryAction, querySelector } from 'domain/router';
import usePrevious from 'hooks/usePrevious';

import SearchBase from 'components/mui/Layouts/components/AppbarSearchBase';
import TagSuggestions from 'components/mui/Layouts/components/TagSuggestions';

const TAG_PREFIX = '#';

const Search = () => {
  const dispatch = useDispatch();
  const query = useSelector(querySelector);
  const prevQuery = usePrevious(query);
  const updatedQueryTextRef = useRef([]);

  const [inputValue, setInputValue] = useState(query?.stag?.length > 0 ? query.stag.join(' ') : '');

  const search = useCallback(
    (value) => {
      const queryTags = query.tags || [];
      const stagsTrimmed = value.trim();
      const stagList = [stagsTrimmed];
      dispatch(
        updateQueryAction({
          text: updatedQueryTextRef.current,
          tags: queryTags,
          stag: stagList[0] ? stagList : undefined,
        }),
      );
      updatedQueryTextRef.current = [];
    },
    [dispatch, query],
  );
  const debounceSearch = useMemo(() => debounce(500, search), [search]);

  const onChange = useCallback(
    (value: string) => {
      if (!value.startsWith(TAG_PREFIX) && (inputValue || value)) {
        debounceSearch(value);
      }
      setInputValue(value);
    },
    [debounceSearch, inputValue],
  );

  const onSelectSuggestion = useCallback(
    (suggestion: string) => {
      const truncatedSuggestion = suggestion.trim();
      if (truncatedSuggestion.length < 1 || truncatedSuggestion.replace(/ /g, '').length < 1) return;
      // const tags = query.tags || [];
      const text = Set(query.text || []);
      const tagList = Set([truncatedSuggestion]);

      updatedQueryTextRef.current = text.union(tagList).toJS();
      // when we reset input value - we trigger inputOnChangeCallback inside component and
      // after invoked debounced search we set actual query text
      setInputValue('');
    },
    [query],
  );

  useEffect(() => {
    if (prevQuery?.stag && !query.stag) {
      setInputValue('');
    }
    // eslint-disable-next-line
  }, [prevQuery?.stag, query.stag]);

  return (
    <TagSuggestions
      InputComponent={SearchBase}
      inputValue={inputValue}
      onSelect={onSelectSuggestion}
      onInputChange={onChange}
      requirePrefix={TAG_PREFIX}
      prefixOutputWithHashtag
      maxWidth="40ch"
    />
  );
};

export default Search;
