// @flow
import React from 'react';

// react-jss
import { FormattedMessage } from 'react-intl';

import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

type Props = {|
  input: {| onChange: (Event) => void |},
  fileName: ?string,
|};

const FileUploadInput = ({ fileName, input: { onChange: inputOnChange } }: Props) => {
  const onChange = (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    if (file) {
      inputOnChange(file);
    }
  };

  return fileName ? (
    <Typography variant="body2" component="label">
      {fileName}
      <input type="file" hidden onChange={onChange} />
    </Typography>
  ) : (
    <Button variant="outlined" component="label">
      <FormattedMessage id="sapConnect.upload" defaultMessage="upload" />
      <input type="file" hidden onChange={onChange} />
    </Button>
  );
};

export default FileUploadInput;
