// @flow
import { Map, Set, List } from 'immutable';
import { signOutAction } from 'domain/env/envActions';
import { companySignInAction } from 'domain/companies/companiesActions';
import * as A from './documentsActions';
import * as Company from '../companies/companiesActions';
import * as Reconciliation from '../reconciliation/actions';
import {
  DocumentsFactory,
  ValidatedDocumentFactory,
  LinkedFactory,
  GridApplyTransactionFactory,
} from 'domain/documents/factories';
import { wsGridPresetsAdapter, wsGridSharePresetAdapter } from './adapters';
import type { TDocumentsMap, TDocumentsStore } from './types.js.flow';

export type AngleType = 0 | 90 | 180 | 270 | 360 | -90 | -180 | -270;

declare type Actions = {
  type: string,
  payload?: mixed,
};

function withOldGridMetaCB(document) {
  return (d) => d && document.set('gridMeta', d.gridMeta);
}

export const reducer = {
  documents(state: TDocumentsStore = DocumentsFactory(), action: Actions) {
    switch (action.type) {
      case companySignInAction.success:
        return state.set('dokkaToken', action.payload.dokkaToken).set('companyId', action.payload.companyId);

      case A.documentClearValidations.type:
        return state.setIn(['document', 'acceptValidationError'], null);

      case A.resetDocumentAction.type:
        return state.set('document', ValidatedDocumentFactory());

      case A.documentSearchAction.success:
        return state
          .update('list', (list) =>
            action.payload.infiniteScroll ? list.merge(action.payload.data.list) : action.payload.data.list,
          )
          .set('pageToken', action.payload.data.pageToken)
          .set('count', action.payload.data.count)
          .set('processing', action.payload.data.processing)
          .set('company_total', action.payload.data.company_total)
          .set('count_without_link_panels', action.payload.data.count_without_link_panels)
          .set('loaded', true);

      case A.setDocumentsTotalAction.type:
        return state.set('company_total', action.payload);

      case A.supplierDocumentSearchAction.success:
        return state
          .update('list', (list) =>
            action.payload.infiniteScroll ? list.merge(action.payload.data.list) : action.payload.data.list,
          )
          .set('processing', action.payload.data.processing)
          .update('supplierPageTokens', (s) => s.set(action.payload.data.stateColumn, action.payload.data.pageToken))
          .update('supplierStateDocCounts', (s) => s.set(action.payload.data.stateColumn, action.payload.data.count))
          .update('supplierStateDocCountsWithoutLinkPanels', (s) =>
            s.set(action.payload.data.stateColumn, action.payload.data.count_without_link_panels),
          );

      case A.supplierDocumentRefreshAction.success:
        return (
          state
            // filtering first removes all docs for particular column
            // and merged new ones to ensure ones that were deleted are removed
            .update('list', (list) =>
              list.filter((item) => item.stateColumn !== action.payload.stateColumn).merge(action.payload.data.list),
            )
            .set('processing', action.payload.data.processing)
            .update('supplierPageTokens', (s) => s.set(action.payload.data.stateColumn, action.payload.data.pageToken))
            .update('supplierStateDocCounts', (s) => s.set(action.payload.data.stateColumn, action.payload.data.count))
            .update('supplierStateDocCountsWithoutLinkPanels', (s) =>
              s.set(action.payload.data.stateColumn, action.payload.data.count_without_link_panels),
            )
        );

      case A.documentWorkerSearch.success:
        return state
          .set('list', action.payload.list)
          .set('pageToken', action.payload.pageToken)
          .set('count', action.payload.count)
          .set('processing', action.payload.processing)
          .set('company_total', action.payload.company_total)
          .set('count_without_link_panels', action.payload.count_without_link_panels);

      case A.documentWorkerCompanyLatestTimestamp.success:
        return state.set('latestWorkerTimeStamp', action.payload);

      case A.documentForceSearch.success:
        return state
          .update('list', (list) =>
            action.payload.infiniteScroll ? list.merge(action.payload.data.list) : action.payload.data.list,
          )
          .set('count', action.payload.data.count)
          .update('count_without_link_panels', (count) =>
            action.payload.infiniteScroll ? count : action.payload.data.count_without_link_panels,
          )
          .set('processing', action.payload.processing);

      case A.documentGet.success:
        // @to-do remove approvals as they are set in approvals reducer
        return state.merge(action.payload);

      case A.documentUpdate.success:
        return state
          .merge(action.payload)
          .updateIn(['list', action.payload.document.documentID], withOldGridMetaCB(action.payload.document))
          .updateIn(['linked', 'list', action.payload.document.documentID], withOldGridMetaCB(action.payload.document));

      case A.markAsPaidAction.success:
        return state
          .merge(action.payload)
          .updateIn(['list', action.payload.document.documentID], withOldGridMetaCB(action.payload.document))
          .updateIn(['linked', 'list', action.payload.document.documentID], withOldGridMetaCB(action.payload.document));

      case A.documentIgnoreWarningsAction.success:
        return state
          .updateIn(['list', action.payload], (doc) => doc && doc.setIn(['indicateWarning'], false))
          .updateIn(['linked', 'list', action.payload], (doc) => doc && doc.setIn(['indicateWarning'], false))
          .updateIn(['document'], (doc) =>
            doc.documentID === action.payload ? doc.setIn(['indicateWarning'], false) : doc,
          );

      case A.documentGetLinkedAction.success:
      case A.documentGetLinkedForPreviewAction.success:
        return state.update('linked', (l) =>
          l
            .set('count', action.payload.count)
            .set('pageToken', action.payload.pageToken)
            .set('list', action.payload.list)
            .set('tag', action.payload.tag)
            .set('isOpen', action.payload.isOpen),
        );

      case A.lockLinkedDocsAction.success:
        return state
          .update('list', (list) =>
            list.mergeWith(
              (oldV, newV) => oldV.set('linkid', newV.get('linkid')),
              action.payload.list.filter((el) => list.has(el.documentID)),
            ),
          )
          .update('linked', (l) => l.set('list', action.payload.list).set('tag', action.payload.tag));

      case A.documentsGridApplyTransactionAction.type:
        return state.set('gridApplyTransaction', GridApplyTransactionFactory(action.payload));

      // If true, then all rows at the level getting refreshed are immediately destroyed and 'loading' rows will appear.
      // If false, then all rows at the level getting refreshed are kept until rows are loaded (no 'loading' rows appear).
      // null - initial/default state
      case A.documentsGridRefreshServerSideAction.type:
        return state.set('gridRefreshServerSide', action.payload);

      case A.documentsBulkUpdateAction.success:
        return state
          .update('list', (l) => action.payload.documents || l)
          .updateIn(['linked', 'list'], (l) => action.payload.linked || l);

      case A.documentAccept.success:
        return state.set('document', action.payload.document);

      case A.documentsJustPublishedAction.type:
        return state.set('justPublished', action.payload);

      case A.documentSignShowDocument.success:
        return state.set('document', action.payload.document);

      case A.documentStopProcessingAction.success:
        return state.set('document', action.payload.document);

      case A.addEditableDoc.type:
        return state.set('editableDoc', state.list.get(action.payload));

      case A.setEditableDoc.type:
        return state.set('editableDoc', action.payload);

      case A.clearEditableDoc.type:
        return state.set('editableDoc', ValidatedDocumentFactory());

      case A.setLinkingTagAction.type:
        return state.set('linkingTag', action.payload);

      case A.documentUpdateTagsInList.success:
        return (
          state
            // eslint-disable-next-line max-len
            .updateIn(
              ['list', action.payload.document.documentID],
              (d) => d && d.merge(action.payload.document.set('gridMeta', d.gridMeta)),
            )
            .updateIn(
              ['linked', 'list', action.payload.document.documentID],
              (d) => d && d.merge(action.payload.document.set('gridMeta', d.gridMeta)),
            )
        );

      case A.documentMultipleUpdateTags.success: {
        return state
          .update(
            'list',
            (d) => d && d.mergeWith((oldV, newV) => newV.set('gridMeta', oldV.get('gridMeta')), action.payload),
          )
          .updateIn(
            ['linked', 'list'],
            (d) =>
              d &&
              d.merge(
                // only merge documents that are already in linked list,
                // as tags might be updated for docs that are not linked
                action.payload &&
                  action.payload.filter((document) => d.keySeq().toArray().includes(document.documentID)),
              ),
          );
      }

      case A.documentUnreadInFilteredList.success:
        return state
          .deleteIn(['list', action.payload.document.documentID])
          .updateIn(
            ['linked', 'list', action.payload.document.documentID],
            (d) => d && d.merge(action.payload.document),
          );

      case A.documentLinkInListAction.success: {
        const conditionalState = state
          // eslint-disable-next-line max-len
          .updateIn(
            ['list', action.payload.adapter.document.documentID],
            (d) => d && d.merge(action.payload.adapter.document),
          )
          .setIn(['linked', 'tag'], action.payload.tag);
        if (state.linked.tag === action.payload.tag) {
          // regular addition of linked document to list with same linked tag
          return (
            conditionalState
              // eslint-disable-next-line max-len
              .setIn(['linked', 'list', action.payload.adapter.document.documentID], action.payload.adapter.document)
          );
        }
        // document added has another linked tag, so we create new list with this doc only
        // and remove docs with other linked tags
        return (
          conditionalState
            // eslint-disable-next-line max-len
            .setIn(
              ['linked', 'list'],
              new Map({ [action.payload.adapter.document.documentID]: action.payload.adapter.document }),
            )
        );
      }

      case A.documentUnlinkInList.success:
        return (
          state
            // eslint-disable-next-line max-len
            .updateIn(['list', action.payload.document.documentID], (d) => d && d.merge(action.payload.document))
            .deleteIn(['linked', 'list', action.payload.document.documentID])
            .updateIn(['linked', 'tag'], (tag) => (state.linked.list.size === 1 ? null : tag))
        );

      case A.documentsClearLinkedAction.type:
        return state.set('linked', LinkedFactory());

      case A.documentsClearLinkedFromCompany.type:
        return (
          state
            .set('linked', LinkedFactory())
            .set('dokkaToken', '')
            .set('companyId', '')
            // additionally clear document list
            .set('list', new Map())
            .set('loaded', false)
        );

      case A.documentUpdateLinkAction.success: {
        const items: TDocumentsMap = action.payload.list;
        // linked docs may have items that are not present in current document list
        // and mustn't be merged into it. Additional filtering applied

        // eslint-disable-next-line max-len
        return state
          .update('list', (list) => list.mergeDeep(items.filter((item) => state.list.has(item.documentID))))
          .updateIn(['linked', 'list'], (list) => (list.size > 0 ? list.mergeDeep(action.payload.list) : list));
      }

      case A.documentRemoveNotesInList.success:
        return state
          .updateIn(['list', action.payload.document.documentID], withOldGridMetaCB(action.payload.document))
          .updateIn(['linked', 'list', action.payload.document.documentID], withOldGridMetaCB(action.payload.document));

      case A.documentRemove.success:
      case A.moveDocumentToCompanyAction.success:
        return state
          .deleteIn(['list', action.payload.id])
          .deleteIn(['linked', 'list', action.payload.id])
          .update('company_total', (u) => u - 1)
          .update('count_without_link_panels', (u) => u - 1);

      case A.documentSplit.success: {
        const stateInTileType = state.deleteIn(['linked', 'list', action.payload.documentID]);
        return action.payload.isGrid ? stateInTileType.deleteIn(['list', action.payload.documentID]) : stateInTileType;
      }

      case A.mergeDocumentsAction.success:
        return action.payload.isGrid
          ? state.update('list', (l) => l.filter((item) => !action.payload.deletedDocumentIds.has(item.documentID)))
          : state;

      case A.documentUpdateNotes.success:
        return (
          state
            // returning the same value from updater func will result in no changes after updateIn
            // otherwise it will create intermediate keys and set value when no linked
            // docs present at all
            .updateIn(['list', action.payload.documentID, 'notes'], (value) =>
              typeof value === 'undefined' ? undefined : action.payload.notes,
            )
            .updateIn(['linked', 'list', action.payload.documentID, 'notes'], (value) =>
              typeof value === 'undefined' ? undefined : action.payload.notes,
            )
            .updateIn(['list', action.payload.documentID, 'notesColor'], (value) =>
              typeof value === 'undefined' ? undefined : action.payload.notes_color,
            )
            .updateIn(['linked', 'list', action.payload.documentID, 'notesColor'], (value) =>
              typeof value === 'undefined' ? undefined : action.payload.notes_color,
            )
        );

      case A.documentSignDocument.success:
        return state
          .updateIn(['list', action.payload.documentID], withOldGridMetaCB(action.payload.document))
          .updateIn(['linked', 'list', action.payload.documentID], withOldGridMetaCB(action.payload.document));

      case A.documentHideLinkedDocs.type:
        return state.set('linked', LinkedFactory());

      case A.documentGetMRUTags.success:
        return state.set('recentTags', Set(action.payload)).set('recentTagsCompanyId', action.companyId);

      case A.documentInvalidateMRUTagsCacheAction.type:
        return state.set('recentTagsCompanyId', null);

      case A.documentSetAsCoverAction.success:
        return state
          .updateIn(['list', action.payload.document.documentID], (d) => d && action.payload.document)
          .updateIn(['linked', 'list', action.payload.document.documentID], (d) => d && action.payload.document);

      case A.documentsGridGetHeadersAction.type:
        return state.set('gridLoader', true);

      case A.documentsGridGetHeadersAction.success:
        return state.set('gridHeadersList', action.payload).set('gridLoader', false);

      case A.documentsGridGetRowsAction.success:
        return state
          .set('list', action.payload.list)
          .set('count', action.payload.count)
          .set('fetchedDocumentsTimeStamp', action.payload.timeStamp)
          .set('count_without_link_panels', action.payload.count_without_link_panels)
          .set('gridLoader', false)
          .set('loaded', true);

      case A.documentsGridGetHeadersAction.failure:
        return state.set('gridLoader', false);

      case A.queueAddAction.type:
        return state.setIn(['uploadQueue', action.payload.id], action.payload);

      case A.queueUpdateAction.type:
        return state.updateIn(['uploadQueue', action.payload.id], (q) => q && q.merge(action.payload));

      case A.documentUploadAction.success:
      case A.supplierDocumentUploadAction.success:
        return state.setIn(['uploadQueue', action.id, 'status'], 'ok');

      case A.documentUploadAction.failure:
      case A.supplierDocumentUploadAction.failure:
        return state
          .setIn(['uploadQueue', action.payload.id, 'status'], 'failure')
          .setIn(['uploadQueue', action.payload.id, 'file'], action.payload.file);

      case A.queueProgress.type:
        return state.setIn(['uploadQueue', action.payload.id, 'progress'], action.payload.value);

      case A.queueRemoveAction.type:
        return state.update('uploadQueue', (u) => u.removeAll(action.payload));

      case A.documentsRemoveDocumentsAction.success:
      case A.moveDocumentsToCompanyAction.success:
        return state
          .update('list', (u) => u.removeAll(action.payload.movedIDs))
          .updateIn(['linked', 'list'], (u) => u.removeAll(action.payload.movedIDs))
          .update('count', (u) => u - action.payload.movedIDs.length)
          .update('company_total', (u) => u - action.payload.movedIDs.length)
          .update('count_without_link_panels', (u) => u - action.payload.nonLinkedDocumentIDs.length);

      case A.documentWorkerTransactionErp.success:
        return state.update('erpDuplicated', (d) => d.add(action.payload.documentID));

      case A.clearTransactionErpDuplicated.type:
        return state.update('erpDuplicated', (d) => d.filter((docId) => docId !== action.payload));

      // case A.duplicateDocumentAction.success:
      //   return state
      //     .setIn(['linkedDocs', action.payload.documentID], action.payload);

      case A.documentGetTagsAction.success:
        return state.setIn(['document', 'tags'], action.payload);

      case A.documentGetViewArrangementAction.success:
      case A.documentUpdateViewArrangementAction.success:
        return state.set('viewArrangement', action.payload);

      case A.getUnreadRequestsAction.success:
        return state.set('unreadRequests', action.payload).set('unreadRequestsLoaded', true);

      case Reconciliation.showReconciliationRequestDetailsAction.type:
        return action.payload.status === 'read'
          ? state
          : state.update('unreadRequests', (unreadRequests) =>
              unreadRequests.reduce((res, doc) => {
                const uDoc = doc.update('lines', (lines) => lines.filter(({ id }) => id !== action.payload.id));
                return uDoc.lines.size ? res.push(uDoc) : res;
              }, new List()),
            );

      case A.documentGetExportFormats.success:
        return state.set('exportFormats', action.payload).set('exportFormatsLoaded', true);

      case A.documentResetExportFormatsLoaded.type:
        return state.set('exportFormatsLoaded', false);

      case A.documentsGridFilterAppliedAction.type:
        return state.set('gridFilters', action.payload.filters);

      case A.documentsGridSortingAppliedAction.type:
        return state.set('gridSorting', action.payload.sorting);

      case A.documentsGridDocumentsByPageIndexAction.type:
        return action.payload.purge
          ? state.set('gridDocumentsByPageIndex', action.payload.data)
          : state.update('gridDocumentsByPageIndex', (prevMap) => prevMap.merge(action.payload.data));

      case A.documentsGridColumnsAppliedAction.type:
        return state.set('gridColumns', action.payload);

      case A.documentsGridDefaultColumnsAppliedAction.type:
        return state.set('gridDefaultColumns', action.payload);

      case A.documentsGridSavePresetAction.success:
      case A.documentsGridDeletePresetAction.success:
      case A.documentsGridGetPresetsAction.success:
        return state.set('gridPresets', wsGridPresetsAdapter(action.payload));

      case A.documentsGridGetSharedPresetAction.success:
        return state.set('sharePreset', wsGridSharePresetAdapter(action.payload));

      case A.documentsGridClearSharedPresetAction.type:
        return state.set('sharePreset', null);

      case A.documentsGridSetCurrentPresetIdAction.type:
        return state.set('currentPresetId', action.payload);

      case A.documentsGridPresetLoadedAction.type:
        return state.set('gridPresetsLoaded', action.payload);

      case A.documentsGridClearConfigurationChangesAction.type:
        return state.set('gridColumns', null).set('gridSorting', null).set('gridFilters', null);

      case A.documentsGridRestoreResentPresetIdAction.success:
        return state.set('currentPresetId', action.payload);

      case A.documentClear.type:
        return state
          .update('list', (docs) => docs.filter((doc, index) => !action.payload.includes(index)))
          .update('count_without_link_panels', (count) => (count ? count - 1 : count));

      case A.documentClearAll.type:
        return state.set('list', new Map());

      case A.getDocumentHotkeysAction.request:
        return state.setIn(['hotkeys', 'isLoading'], true);

      case A.getDocumentHotkeysAction.success:
        return state
          .setIn(['hotkeys', 'isLoading'], false)
          .setIn(['hotkeys', 'companyId'], action.companyId)
          .setIn(['hotkeys', 'data'], action.payload);

      case A.getDocumentHotkeysAction.failure:
        return state.setIn(['hotkeys', 'isLoading'], false).setIn(['hotkeys', 'data'], new List());

      case Company.companySignInAction.request:
      case signOutAction.success:
        return DocumentsFactory({ cachedDocuments: state.cachedDocuments });

      case A.saveCachedDocumentAction.type:
        return state.update('cachedDocuments', (docs) => {
          const index = docs.findIndex(({ hash }) => hash === action.payload.data.hash);
          if (index !== -1) {
            return docs.set(index, action.payload.data);
          }
          if (docs.size === 50) {
            window.URL.revokeObjectURL(docs.get(0).url);
            return docs.delete(0).push(action.payload.data);
          }
          return docs.push(action.payload.data);
        });

      // used for update document data in document list when approve/reject document
      case A.documentUpdateInListByID.type:
        return state
          .updateIn(['list', action.payload.document.documentID], withOldGridMetaCB(action.payload.document))
          .updateIn(['linked', 'list', action.payload.document.documentID], withOldGridMetaCB(action.payload.document));

      default:
        return state;
    }
  },
};
