import ChipMui from '@mui/material/Chip';
import { styled } from '@mui/material/styles';

export const Chip = styled(ChipMui, { shouldForwardProp: (propName) => propName !== 'isHovered' })(
  ({ theme, isHovered }) => ({
    maxWidth: '78ch',
    opacity: isHovered ? 0.2 : 1,
    transition: theme.transitions.create('opacity', {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.short,
    }),
  }),
);
