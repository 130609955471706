// @flow
import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { getCurrentCompanyData } from 'domain/companies/companiesSelector';
import { documentsGridFilterAppliedAction, documentsGridHeadersListSelector } from 'domain/documents';
import { GridFilterType } from 'domain/documents/types.js.flow';
import moment from 'moment';

import { DEFAULT_FORMAT } from 'components/Form/DayPicker/helper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Tooltip from 'components/mui/Tooltip';
import { Chip } from 'pages/company/FavoriteTagsPanel/components/GridFilterTags/StyledComponents';

type TGridFilterTags = {|
  isHovered: boolean,
  gridFilters: GridFilterType,
|};

const mapStateToProps = (state) => ({
  defaultColumns: documentsGridHeadersListSelector(state),
  currentCompanyData: getCurrentCompanyData(state),
});

const dateFormater = (value, dateFormat) => moment(value).format(dateFormat || DEFAULT_FORMAT);

const GridFilterTags: React$StatelessFunctionalComponent<TGridFilterTags> = ({ isHovered, gridFilters }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { defaultColumns, currentCompanyData } = useSelector(mapStateToProps);
  const removeFilters = useCallback(
    (colId) => () => {
      const { [colId]: _, ...filters } = gridFilters;

      dispatch(documentsGridFilterAppliedAction({ filters }));
    },
    [dispatch, gridFilters],
  );

  const filterEntries = useMemo(() => Object.entries(gridFilters), [gridFilters]);

  const titles = useMemo(
    () =>
      defaultColumns ? defaultColumns.reduce((res, header) => ({ ...res, [header.field]: header.headerName }), {}) : {},
    [defaultColumns],
  );

  const getTypeText = useCallback(
    (type: string) =>
      intl.formatMessage({
        id: `company.workspace.grid.filters.operator.${type}`,
        defaultMessage: type,
      }),
    [intl],
  );

  const textFilterHandler = useCallback(
    ({ type, filter }) => (filter ? `${getTypeText(type)}: ${filter}` : getTypeText(type)),
    [getTypeText],
  );

  const numberFilterHandler = useCallback(
    ({ type, filter, filterTo = null }) => {
      const value = filterTo === null ? filter : `${filter} - ${filterTo}`;
      return value !== null && value !== undefined ? `${getTypeText(type)}: ${value}` : getTypeText(type);
    },
    [getTypeText],
  );

  const setFilterHandler = useCallback(
    ({ type, values }) =>
      `${getTypeText(type)}: ${values
        .map((value) => {
          const transKey = (typeof value === 'object' ? value.label : value) || 'blanks';
          return intl.formatMessage({
            id: `company.workspace.grid.filters.values.${transKey}`,
            defaultMessage: transKey,
          });
        })
        .join(', ')}`,
    [getTypeText, intl],
  );

  const dateFilterHandler = useCallback(
    (data) => {
      const { type, dateFrom = null, dateTo = null } = data;

      const value =
        dateFrom && dateTo
          ? `${dateFrom} - ${dateTo}`
          : (dateFrom && dateFormater(dateFrom, currentCompanyData.dateFormat)) ||
            (dateTo && dateFormater(dateTo, currentCompanyData.dateFormat));

      return `${getTypeText(type)}: ${value}`;
    },
    [getTypeText, currentCompanyData],
  );

  const filterTypeHandlers = useMemo(
    () => ({
      set: setFilterHandler,
      text: textFilterHandler,
      number: numberFilterHandler,
      date: dateFilterHandler,
    }),
    [setFilterHandler, textFilterHandler, numberFilterHandler, dateFilterHandler],
  );

  const getFilterItemText = useCallback(
    (filter): string => {
      const handler =
        filterTypeHandlers[filter.filterType] || (() => console.error(`Undefined filter type ${filter.filterType}`));
      return handler(filter);
    },
    [filterTypeHandlers],
  );

  const getFilterText = useCallback(
    (filters): string => {
      const { conditions = null, operator = null, ...currentFilter } = filters;
      return operator
        ? `${getFilterItemText(conditions[0])} ${getTypeText(operator.toLowerCase())} ${getFilterItemText(
            conditions[1],
          )}`
        : getFilterItemText(currentFilter);
    },
    [getFilterItemText, getTypeText],
  );

  return (
    <>
      {filterEntries.map(([key, filter]) => {
        const text = getFilterText(filter);
        return (
          <Tooltip key={key} t={text}>
            <Chip
              isHovered={isHovered}
              variant="outlined"
              color="primary"
              label={
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Typography variant="subtitle2">{titles[key]}:</Typography>
                  <Typography variant="body2" noWrap>
                    {text}
                  </Typography>
                </Stack>
              }
              onDelete={removeFilters(key)}
            />
          </Tooltip>
        );
      })}
    </>
  );
};

export default GridFilterTags;
