// @flow
import React from 'react';
import type { TMessageRecord, ChatUser } from 'domain/chat/types.js.flow';
import { useListTimeFormatter } from 'components/mui/Layouts/components/Chat/components/ChannelsThread/hooks';

import {
  ChannelUnreadBadge,
  ChannelTime,
  ChannelMeta,
} from 'components/mui/Layouts/components/Chat/components/StyledComponents';
import HighlightText from 'components/mui/HighlightText';
import Avatar from 'components/mui/Avatar';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';

type TPrivateChannel = {
  data: ChatUser,
  unread: number,
  thread?: TMessageRecord,
  searchText?: string,
};

const PrivateChannel: React$StatelessFunctionalComponent<TPrivateChannel> = ({ data, thread, unread, searchText }) => {
  const { username, picture } = data;
  const time = useListTimeFormatter(thread?.createdAt);
  const threadMessage = thread ? thread.message : '';
  const threadTime = thread ? time : '';

  return (
    <>
      <ListItemAvatar>
        <Avatar src={picture} alt={picture} />
      </ListItemAvatar>
      <ListItemText sx={{ my: 0 }} primaryTypographyProps={{ fontSize: 14 }} secondary={threadMessage}>
        <HighlightText value={username} searchTerm={searchText} />
      </ListItemText>
      {thread && (
        <ChannelMeta>
          {threadTime && <ChannelTime variant="caption">{threadTime}</ChannelTime>}
          {unread > 0 && <ChannelUnreadBadge color="primary" badgeContent={unread} />}
        </ChannelMeta>
      )}
    </>
  );
};

export default PrivateChannel;
