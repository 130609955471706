// @flow
import React from 'react';

import { useSelector } from 'react-redux';

// white-labeling
import type { TLabelOptions } from 'labels/type.js.flow';

// selectors
import { organizationAdvancedFeaturesSelector } from 'domain/organization';
import { companyAdvancedFeatureSetSelector } from 'domain/companies';

import FeatureItem from './components/FeatureItem';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import AlertTitle from '@mui/material/AlertTitle';
import ArrowRightIcon from 'pages/components/AccordionArrowRightIcon';
import MuiAccordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary from '@mui/material/AccordionSummary';

// intl
import { useIntl } from 'react-intl';

// styles
import { styled } from '@mui/material/styles';

const Accordion = styled(MuiAccordion)(() => ({
  '&.MuiPaper-root.MuiAccordion-root::before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary elevation={0} expandIcon={<ArrowRightIcon />} {...props} />
))(() => ({
  flexDirection: 'row-reverse',
  padding: 0,
}));

type Props = {
  isCompany?: boolean,
  labelOptions: TLabelOptions,
};

const FeaturesSettings: React$StatelessFunctionalComponent<Props> = ({ isCompany = false }) => {
  const { formatMessage } = useIntl();

  const featuresSelector = isCompany ? companyAdvancedFeatureSetSelector : organizationAdvancedFeaturesSelector;
  const featureSet = useSelector(featuresSelector);

  return (
    <Box mt={2}>
      <Accordion
        disableGutters
        sx={{
          mt: '0!important',
          border: 'none',
          boxShadow: 'none',
          mb: 0,
        }}
        TransitionProps={{ unmountOnExit: true }}
      >
        <AccordionSummary>
          <AlertTitle m={0}>
            {formatMessage({
              id: 'forms.erpConnect.advanced.title',
              defaultMessage: 'Advanced settings',
            })}
          </AlertTitle>
        </AccordionSummary>
        <AccordionDetails>
          <Stack>
            {featureSet.map(([key, values]) => (
              <FeatureItem key={key} featureKey={key} {...values} isCompany={isCompany} />
            ))}
          </Stack>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default FeaturesSettings;
