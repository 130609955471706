// @flow
import * as React from 'react';
import { useSelector } from 'react-redux';

import { loadingSelector } from 'domain/env';

import Spinner from 'components/mui/CircularProgressWithBackdrop';
import Stack from '@mui/material/Stack';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';

import { use100vh } from 'react-div-100vh';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  '@global': {
    '#root': {
      minHeight: 'auto',
    },
  },
  wrapper: {
    width: '100%',
    maxWidth: '100%',
    overflow: 'hidden',
  },
}));

type Props = {
  headerContent?: () => React$Node,
  footerContent?: () => React$Node,
  children: React$Node,
};

const mapStateToProps = (state) => ({
  isLoading: loadingSelector(state),
});

const Layout: React$StatelessFunctionalComponent<Props> = ({ headerContent, children, footerContent }) => {
  const classes = useStyles();
  const { isLoading } = useSelector(mapStateToProps);

  // for avoid incorrect height on mobile ios devices for webkit browsers
  const height = use100vh() || '100vh';

  return (
    <Stack className={classes.wrapper} height={height} maxHeight={height}>
      {headerContent && (
        <AppBar position="static">
          <Toolbar sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: 56 }}>
            {(React.isValidElement(headerContent) && headerContent) || headerContent()}
          </Toolbar>
        </AppBar>
      )}
      {isLoading && <Spinner />}
      <Stack flex={1} minHeight={0}>
        {children}
      </Stack>
      {footerContent && (
        <Stack flex={0} sx={{ py: 1, px: 2, borderTop: 1, borderTopColor: 'grey.400' }}>
          {(React.isValidElement(footerContent) && footerContent) || footerContent()}
        </Stack>
      )}
    </Stack>
  );
};

export default Layout;
