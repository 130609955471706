import Alert from '@mui/material/Alert';
import { styled, alpha } from '@mui/material/styles';

export const DeferredTaskAlert = styled(Alert, { label: 'DeferredTaskAlert' })(({ theme }) => ({
  maxWidth: 700,
  minWidth: 400,

  '& .MuiAlert-action': {
    alignItems: 'center',
  },
}));

export const DeferredTaskValue = styled('div', { label: 'DeferredTaskValue' })(({ theme }) => ({
  fontSize: theme.typography.pxToRem(14),
  fontWeight: 500,
  color: theme.palette.primary.main,
}));
