// @flow
import React, { forwardRef } from 'react';
import { FixedSizeList } from 'react-window';
import VirtualizedRow, {
  type TItemData,
} from 'pages/company/Grid/components/CustomHeaderFilter/CustomSetColumnFilter/VirtualizedRow';

type TVirtualizedInfiniteLoadList = {
  ...TItemData,
};

const VirtualizedList = forwardRef(({ options, values, onClickItem }: TVirtualizedInfiniteLoadList, ref) => {
  // https://react-window.vercel.app/#/examples/list/memoized-list-items
  // DA-14672, it looks like when we memoize data we have an empty list when we scroll down and close/open filter again
  // it doesn't work correctly with memoization for some reason
  const itemData = { options, onClickItem, values };

  return (
    <FixedSizeList
      ref={ref}
      itemData={itemData}
      height={224}
      itemSize={32}
      itemCount={itemData.options.length}
      overscanCount={5}
    >
      {VirtualizedRow}
    </FixedSizeList>
  );
});

export default VirtualizedList;
