/* @flow */
import { Record, Set, type RecordFactory, type RecordOf } from 'immutable';
import type {
  EventsStore,
  EventsStoreShape,
  EventsItemShapeType,
  DocumentPreviewType,
  LinkedDocumentType,
  CompanyChatMessageType,
  DocumentChatMessageType,
  RawEventsItemShapeType,
} from './types.js.flow';
import type { GetEventsType } from '../contracts';

export const EventsFactory: RecordFactory<EventsStoreShape> = new Record({
  eventHash: '',
  nextNumPage: undefined,
  countOfNewEvents: 0,
  list: new Set(),
  isOpened: false,
});

const EventsCompanChatFactory: RecordFactory<CompanyChatMessageType> = new Record({
  type: 'company_chat',
  channel_name: '',
  channel_url: '',
  companyID: '',
});

const EventsDocumentChatFactory: RecordFactory<DocumentChatMessageType> = new Record({
  type: 'document_chat',
  channel_name: '',
  channel_url: '',
  companyID: '',
  documentID: '',
});

const EventsDocumentFactory: RecordFactory<DocumentPreviewType> = new Record({
  type: 'document',
  documentID: '',
  exist: true,
});

const EventsLinkedFactory: RecordFactory<LinkedDocumentType> = new Record({
  type: 'linked_document',
  linkID: '',
  confidential: false,
  rootCategory: 30,
});

export const EventsItemFactory: RecordFactory<EventsItemShapeType> = new Record({
  id: '',
  timestamp: 0,
  userID: '',
  username: '',
  documentID: '',
  message: '',
  details: '',
  link: null,
});

// eslint-disable-next-line max-len
export function EventsItemAdapter({ link, ...rest }: RawEventsItemShapeType): RecordOf<EventsItemShapeType> {
  if (link.type === 'document') {
    return EventsItemFactory({ link: EventsDocumentFactory(link.source), ...rest });
  } else if (link.type === 'linked_document') {
    return EventsItemFactory({ link: EventsLinkedFactory(link.source), ...rest });
  } else if (link.type === 'company_chat') {
    return EventsItemFactory({ link: EventsCompanChatFactory(link.source), ...rest });
  } else if (link.type === 'document_chat') {
    return EventsItemFactory({ link: EventsDocumentChatFactory(link.source), ...rest });
  }
  return EventsItemFactory(rest);
}

export function eventsStateAdapter({ data, ...rest }: GetEventsType): EventsStore {
  const list = Set(data.map((e) => EventsItemAdapter(e)));
  return EventsFactory({ ...rest, list });
}
