// @flow
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';

import { FormControlLabelSwitch } from 'components/mui/Form/FormControl/FormControlLabelSwitch';
import Typography from '@mui/material/Typography';
import Switch from 'components/mui/Form/Switch/SwitchBase';
import SelectBase from 'components/mui/Form/Select/SelectBase';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';

import DeleteIcon from '@mui/icons-material/Delete';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';

type Props = {|
  onSelectSource: (value: string) => void,
  selectedSource: number,
  sources: Array<{ id: number, value: string }>,
  removePage: () => void,
  singleUpload: () => void,
  multiUpload: () => void,
  actionsEnabled: boolean,
  isDuplexAvailable: boolean,
  isFeederOption: boolean,
  isDuplexOption: boolean,
  onToggleFeederOption: (v: boolean) => void,
  onToggleDuplexOption: (v: boolean) => void,
|};

const SourceSelector: React$StatelessFunctionalComponent<Props> = ({
  selectedSource,
  onSelectSource,
  actionsEnabled,
  isDuplexAvailable,
  isDuplexOption,
  isFeederOption,
  onToggleFeederOption,
  onToggleDuplexOption,
  removePage,
  singleUpload,
  multiUpload,
  sources,
}) => {
  const { formatMessage } = useIntl();

  const isDisableOptions = useMemo(() => selectedSource === -1, [selectedSource]);

  const options = useMemo(() => sources.map((item) => ({ value: item.id, label: item.value })), [sources]);

  return (
    <Stack direction="row" spacing={2}>
      {actionsEnabled ? (
        <>
          <Button variant="text" startIcon={<InsertDriveFileIcon />} onClick={singleUpload}>
            {formatMessage({ id: 'scan.buttons.uploadSelected', defaultMessage: 'Upload selected' })}
          </Button>
          <Button variant="text" startIcon={<LibraryBooksIcon />} onClick={multiUpload}>
            {formatMessage({ id: 'scan.buttons.uploadAll', defaultMessage: 'Upload all' })}
          </Button>
          <Button variant="text" color="error" startIcon={<DeleteIcon color="error" />} onClick={removePage}>
            {formatMessage({ id: 'scan.buttons.removeSelected', defaultMessage: 'Remove selected' })}
          </Button>
        </>
      ) : (
        <>
          <SelectBase
            id="scanerSource"
            value={selectedSource}
            options={options}
            onChange={onSelectSource}
            sx={{ width: 200, height: 40 }}
          />
          <FormControlLabelSwitch
            label={
              <Typography variant="body1">
                {formatMessage({ id: 'scan.options.feeder', defaultMessage: 'Feeder' })}
              </Typography>
            }
            onChange={onToggleFeederOption}
            checked={isFeederOption}
            control={<Switch />}
            disabled={isDisableOptions}
          />
          <FormControlLabelSwitch
            label={
              <Typography variant="body1">
                {formatMessage({ id: 'scan.options.duplex', defaultMessage: 'Duplex scanning' })}
              </Typography>
            }
            onChange={onToggleDuplexOption}
            checked={isDuplexOption}
            control={<Switch />}
            disabled={isDisableOptions || !isDuplexAvailable}
          />
        </>
      )}
    </Stack>
  );
};

export default SourceSelector;
