// @flow
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { debounce } from 'throttle-debounce';

import SearchBase from 'components/mui/Layouts/components/AppbarSearchBase';
import SearchTypeButton from './components/SearchTypeButton';
import InputAdornment from '@mui/material/InputAdornment';

import { SEARCH_TYPES, searchTextSelector, searchTypeSelector, setSearchFilterAction } from 'domain/companies';

const AppBarSearch = () => {
  const dispatch = useDispatch();
  const searchText = useSelector(searchTextSelector);
  const searchType = useSelector(searchTypeSelector);
  const searchFieldRef = useRef(null);

  const search = useCallback(
    (value) => {
      dispatch(setSearchFilterAction(value));
    },
    [dispatch],
  );

  const debounceSearch = useMemo(() => debounce(500, search), [search]);

  const onChange = useCallback(
    (value) => {
      const action = searchType !== SEARCH_TYPES.byCompany ? debounceSearch : search;
      action(value);
    },
    [searchType, debounceSearch, search],
  );

  useEffect(() => {
    if (searchFieldRef && searchFieldRef.current) {
      const searchInput = searchFieldRef.current.querySelector('input');
      if (searchInput) {
        searchInput.focus();
      }
    }
  }, [searchType]);

  return (
    <SearchBase
      ref={searchFieldRef}
      value={searchText}
      onChange={onChange}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <SearchTypeButton />
          </InputAdornment>
        ),
      }}
    />
  );
};

export default AppBarSearch;
