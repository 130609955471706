/* @flow */
import * as React from 'react';
import Context from './context';
import type { OutsideTooltipData } from './types.js.flow';

type Props = {||};
type State = {||};

export const withOutsideTooltip = (WrappedComponent: React.Component<any>) => {
  class WithOutsideTooltip extends React.Component<Props, State> {
    isActive: boolean = false;

    componentWillUnmount(): * {
      if (this.isActive) {
        this.setOutsideTooltipFn(null);
      }
    }

    setTooltipData = (data: OutsideTooltipData) => {
      if (this.setOutsideTooltipFn) {
        this.isActive = !!data;
        this.setOutsideTooltipFn(data);
      }
    };

    setOutsideTooltipFn: (OutsideTooltipData) => void = null;

    render() {
      return (
        <Context.Consumer>
          {(value) => {
            this.setOutsideTooltipFn = value.setOutsideTooltip;
            return <WrappedComponent {...this.props} {...value} setOutsideTooltip={this.setTooltipData} />;
          }}
        </Context.Consumer>
      );
    }
  }
  WithOutsideTooltip.displayName = WrappedComponent.displayName;
  return WithOutsideTooltip;
};

export default withOutsideTooltip;
