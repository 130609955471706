// @flow
import React, { useCallback, useMemo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';

import { organizationApiKeysByIdSelector } from 'domain/organization';
import Api from 'domain/api';

import { type ApiKeyType } from 'domain/organization/types.js.flow';

import Dialog from 'components/mui/Dialog';
import Form from './Form';

type TDialogManageKeys = {
  onCancel: () => void,
  onSubmit: (data: ApiKeyType) => Promise<void>,
  open: boolean,
  selectedApiKey?: string,
  dateFormat: string,
};

const DialogManageKeys: React$StatelessFunctionalComponent<TDialogManageKeys> = ({
  onCancel,
  onSubmit,
  open,
  selectedApiKey,
  dateFormat,
}) => {
  const [userIP, setUserIP] = useState('');

  const apiKeyData = useSelector(organizationApiKeysByIdSelector(selectedApiKey)).toJS();

  const initialValues = useMemo(
    () => ({
      ...apiKeyData,
      ips: apiKeyData.ips,
      expiration: apiKeyData.expiration ? moment(apiKeyData.expiration) : moment().add(1, 'years'),
    }),
    [apiKeyData],
  );

  const isEdit = Boolean(selectedApiKey);

  const handleSubmit = useCallback(
    (data) => {
      if (typeof onSubmit === 'function') {
        const expiration = data.get('expiration');
        onSubmit({
          ...data.toJS(),
          expiration: moment.isMoment(expiration) ? moment(expiration).format(dateFormat) : expiration,
        });
      }
    },
    [onSubmit, dateFormat],
  );

  const DialogTitle = useMemo(
    () =>
      isEdit ? (
        <FormattedMessage id="form.organization.apiKeys.edit.title" defaultMessage="Edit API Key" />
      ) : (
        <FormattedMessage id="form.organization.apiKeys.create.title" defaultMessage="Create API Key" />
      ),
    [isEdit],
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await Api.getUserIP({});
        setUserIP(data);
      } catch (error) {
        console.log('error fetching user IP');
      }
    };
    fetchData();
  }, []);

  return (
    <Dialog open={open} onClose={onCancel} title={DialogTitle} maxWidth="sm" withActions={false} withContent={false}>
      <Form
        onSubmit={handleSubmit}
        initialValues={initialValues}
        isEdit={isEdit}
        onCancel={onCancel}
        userIP={userIP}
        dateFormat={dateFormat}
      />
    </Dialog>
  );
};

export default DialogManageKeys;
