/* @flow */
import * as React from 'react';
import _ from 'lodash';
import elements from 'components/elements';
import { findIndex } from 'lib/helpers';
import { getEntry } from '../helpers';

import { FloatContainer, FloatContent, FloatContainerAction } from 'components/Tables/layout/grid/StyledComponents';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

type Props = {
  children: React$Node,
  disabledCols?: Array<string>, // TODO prop is not usable, remake component as HOC
  pinnedCols?: Array<string>, // TODO prop is not usable, remake component as HOC
  forcedEnabledCols?: Array<string>, // TODO prop is not usable, remake component as HOC
  isJeLayoutRTL: boolean,
  isRtl: boolean,
};

type State = {
  prev: boolean,
  next: boolean,
};

class FloatGrid extends React.Component<Props, State> {
  static defaultProps = {
    disabledCols: [],
    pinnedCols: [],
    forcedEnabledCols: [],
  };

  state = {
    prev: true,
    next: true,
  };

  componentDidMount() {
    this.initObservers();
  }

  componentDidUpdate(prevProps: Props) {
    if (this.getHash(this.props) !== this.getHash(prevProps)) {
      this.initObservers();
    }
  }

  componentWillUnmount() {
    this.observers.forEach((o) => {
      o.disconnect();
    });
  }

  get rows(): $ReadOnlyArray<?HTMLElement> {
    const { container } = this;
    return container ? [...container.querySelectorAll('tr:first-child td')] : [];
  }

  get shouldInitObserver(): boolean {
    const { container, rows } = this;
    return !!container && rows.length > 0;
  }

  getHash = (props: Props): string => {
    const keys = ['disabledCols', 'pinnedCols', 'forcedEnabledCols'];
    return _.flatten(Object.values(_.pick(props, keys))).join('-');
  };

  getRef = (el: ?HTMLElement) => {
    this.container = el;
  };

  initObservers = () => {
    const { container, rows } = this;
    if (this.shouldInitObserver) {
      const obeservOptions = { root: container, rootMargin: '0px', threshold: 1 };
      this.observers[0] = new IntersectionObserver(this.handleObserv, obeservOptions);
      this.observers[1] = new IntersectionObserver(this.handleObserv, obeservOptions);
      // $FlowFixMe
      this.observers[0].observe(rows[0]);
      // $FlowFixMe
      this.observers[1].observe(rows[rows.length - 1]);
    }
  };

  handleObserv = (entries: $ReadOnlyArray<IntersectionObserverEntry>) => {
    const { isIntersecting, target } = entries[0];
    const { rows } = this;
    if (rows.length) {
      const idx = rows.reduce(findIndex(target), undefined);
      if (idx === 0) {
        this.setState({ prev: !isIntersecting });
      }
      if (idx === rows.length - 1) {
        this.setState({ next: !isIntersecting });
      }
    }
  };

  handleClick = (idx: 0 | 1) => () => {
    const { container, rows } = this;
    if (this.shouldInitObserver) {
      // $FlowFixMe
      const entry = getEntry(container, rows);
      // $FlowFixMe
      container.scrollTo({ left: entry[idx], behavior: 'smooth' });
    }
  };

  container: ?HTMLElement;

  observers: IntersectionObserver[] = [];

  render() {
    const { isRtl, isJeLayoutRTL, children } = this.props;
    const { prev, next } = this.state;

    return (
      <FloatContainer>
        {!isJeLayoutRTL && (
          <FloatContainerAction className="prev" type="prev" disabled={!prev} onClick={this.handleClick(0)}>
            {isRtl ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </FloatContainerAction>
        )}

        <FloatContent className="scroll-box" data-element={elements.je.grid.float} ref={this.getRef}>
          {children}
        </FloatContent>

        {!isJeLayoutRTL && (
          <FloatContainerAction className="next" type="next" disabled={!next} onClick={this.handleClick(1)}>
            {isRtl ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </FloatContainerAction>
        )}
      </FloatContainer>
    );
  }
}

export default FloatGrid;
