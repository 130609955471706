// @flow
import React from 'react';

// components
// import Select from 'react-select';
import Select from 'components/mui/Form/Select/SelectBase';

// intl
import { FormattedMessage } from 'react-intl';

type Option = 'add' | 'add_on_first' | 'remove';

type Props = {|
  onSelect: (option: Option) => void,
  value: string,
|};

const SplitOptions: React$StatelessFunctionalComponent<Props> = ({ onSelect, value }: Props) => {
  const getLabel = (id: string) => (
    <FormattedMessage id={`document.edit.splitOptions.${id}`}>{(t: string) => t}</FormattedMessage>
  );

  const options: Array<Option> = ['add', 'add_on_first', 'remove'].map((i) => ({ value: i, label: getLabel(i) }));

  return (
    <Select
      FormControlProps={{ size: 'small', fullWidth: true, sx: { maxWidth: 450 } }}
      MenuProps={{
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'bottom',
        },
        transformOrigin: {
          vertical: 'bottom',
          horizontal: 'bottom',
        },
      }}
      fullWidth
      value={value}
      options={options}
      onChange={onSelect}
    />
  );
};

export default SplitOptions;
