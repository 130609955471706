/* eslint-disable jsx-a11y/no-static-element-interactions */
// @flow
import * as React from 'react';

// components
import TextFieldBase from 'components/mui/Form/TextField/TextFieldBase';
import {
  WidgetWrapper,
  WidgetContentWrapper,
  WidgetContentContainer,
  WidgetContent,
  WidgetButtonWrapper,
  WidgetWrapperContainer,
} from './StyledComponents';

// hooks
import useDrag from './useDrag';

import { lightBlue, blue } from '@mui/material/colors';

import CircularProgressWithBackdrop from 'components/mui/CircularProgressWithBackdrop';

type Props = {|
  button: (onClick: () => void) => React.Element<*>,
  isActive: boolean,
  title?: string,
  content: string | HTMLElement,
  loading: boolean,
  onMouseDown: () => void,
  setIsActive: () => void,
  footer?: React.Element<*>,
  isReverse?: boolean,
  borderColor: string,
  bgColor: string,
  widgetButtonPosition: 'top' | 'bottom',
  isMaxInitialWidth: boolean,
  style: {
    zIndex: number,
  },
|};

const Widget = ({
  content,
  loading,
  isActive,
  setIsActive,
  title,
  button,
  footer,
  onMouseDown,
  isReverse = false,
  borderColor = lightBlue[700],
  bgColor = blue[100],
  widgetButtonPosition = 'Top',
  isMaxInitialWidth = false,
  style,
}: Props) => {
  const divRef = React.useRef();

  const [draggable, setDraggable] = React.useState(true);
  const { onDragStart, onDragEnd, onDrag, resetPosition, isDragging } = useDrag({ isActive, divRef });

  const onMouseDownHandler = React.useCallback(
    (e: MouseEvent) => {
      onMouseDown(e);
      setDraggable(true);
    },
    [setDraggable, onMouseDown],
  );

  const handleDragStart = React.useCallback((e) => {
    e.dataTransfer.setData('text/plain', 'widget');
  }, []);

  React.useEffect(() => {
    if (!isDragging) {
      const draggingZone = document.getElementById('draggingZone');
      if (divRef.current && draggingZone) {
        const { top: widgetTop, bottom: widgetBottom } = divRef.current.getBoundingClientRect();
        const { top: draggingZoneTop, bottom: draggingZoneBottom } = draggingZone.getBoundingClientRect();
        if (widgetBottom < draggingZoneTop || widgetTop > draggingZoneBottom) {
          setIsActive(false);
        }
      }
    }
  }, [isDragging, setIsActive]);

  React.useEffect(() => {
    if (!isActive) {
      resetPosition();
    }
  }, [isActive, resetPosition]);

  React.useEffect(() => {
    if (divRef.current) {
      divRef.current.addEventListener('dragstart', handleDragStart);
    }
  }, [handleDragStart]);

  const onDragStartHandler = (...args) => {
    if (draggable) {
      onDragStart(...args);
    }
  };

  return (
    <WidgetWrapperContainer isActive={isActive} style={style}>
      <WidgetWrapper
        ref={divRef}
        isActive={isActive}
        widgetButtonPosition={widgetButtonPosition}
        onDragStart={onDragStartHandler}
        onDragEnd={onDragEnd}
        onDrag={onDrag}
        draggable={isActive && draggable}
        onMouseDownCapture={onMouseDownHandler}
      >
        {!!isReverse && (
          <WidgetButtonWrapper borderColor={borderColor} bgColor={bgColor} widgetButtonPosition={widgetButtonPosition}>
            {button()}
          </WidgetButtonWrapper>
        )}

        <WidgetContentWrapper
          id="widgetContentWrapper"
          isActive={isActive}
          isMaxInitialWidth={isMaxInitialWidth}
          style={{
            ...(!isActive && { width: '0px', height: '100%' }),
          }}
        >
          <WidgetContentContainer borderColor={borderColor} bgColor={bgColor}>
            {!!title && (
              <TextFieldBase
                onMouseDown={() => setDraggable(false)}
                inputProps={{ readOnly: true }}
                fullWidth
                variant="standard"
                InputProps={{
                  disableUnderline: true,
                }}
                value={title}
                sx={{ px: 1, borderRadius: 1, mb: 0.5, backgroundColor: 'common.white' }}
              />
            )}
            <WidgetContent onMouseDown={() => setDraggable(false)}>
              {content}
              {loading && isActive && <CircularProgressWithBackdrop BackdropProps={{ sx: { position: 'absolute' } }} />}
            </WidgetContent>
            {footer}
          </WidgetContentContainer>
        </WidgetContentWrapper>

        {!isReverse && (
          <WidgetButtonWrapper borderColor={borderColor} bgColor={bgColor} widgetButtonPosition={widgetButtonPosition}>
            {button()}
          </WidgetButtonWrapper>
        )}
      </WidgetWrapper>
    </WidgetWrapperContainer>
  );
};

export default Widget;
