// @flow
import React from 'react';

import { AuthAsideBase } from 'pages/authPage/Layout/StyledComponents';
import AsideCarousel from 'pages/authPage/Layout/AsideCarousel';
import Box from '@mui/material/Box';

import { styled } from '@mui/material/styles';

import IntegrationLogos1 from 'pages/authPage/Layout/images/IntegrationLogos1.png';
import IntegrationLogos2 from 'pages/authPage/Layout/images/IntegrationLogos2.png';
import gradient from 'pages/authPage/Layout/AuthAside/Default/images/desktop-curve.svg';
import gradientRtl from 'pages/authPage/Layout/AuthAside/Default/images/desktop-curve-rtl.svg';

const AuthAside = styled(AuthAsideBase)(({ theme }) => ({
  // https://stackoverflow.com/questions/49669689/how-to-create-a-curve-between-two-gradient-using-css
  //   https://codepen.io/Ferie/pen/MxwpYB
  //   https://stackoverflow.com/questions/45494235/is-it-possible-to-do-a-curved-line-with-css-gradient
  //   https://stackoverflow.com/questions/50237001/how-can-i-alter-the-curve-of-a-radial-gradient-in-css
  backgroundImage: `url('${theme.direction === 'rtl' ? gradientRtl : gradient}')`,
  backgroundPosition: 'top right',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'contain',
  backgroundColor: theme.palette.primary.dark,
}));

const AuthAsideDefault = () => (
  <AuthAside>
    <AsideCarousel />
    <Box p={2} mt="auto" textAlign="center">
      <img src={IntegrationLogos1} alt="promo1" />
      <img src={IntegrationLogos2} alt="promo2" />
    </Box>
  </AuthAside>
);

export default AuthAsideDefault;
