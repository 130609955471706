import { styled } from '@mui/material';
import MuiButton from '@mui/material/Button';
import MuiDialog from '@mui/material/Dialog';

export const Dialog = styled(MuiDialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: theme.shape.borderRadius * 2,
  },
}));

export const Button = styled(MuiButton)(({ theme }) => ({
  padding: `${theme.spacing(0.75, 2)}!important`,
}));
