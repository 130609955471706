// @flow
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { assignOrganizationUser, revokeOrganizationUser } from 'domain/organizationUser';

import AssigmentButton from 'pages/configurations/components/AssigmentButton';

import type { EnhancedUser } from 'domain/organizationUser/types.js.flow';

type TProps = {
  user: EnhancedUser,
  isDisabledFn: (user: EnhancedUser) => boolean,
};

const ActionCell: React$StatelessFunctionalComponent<TProps> = ({ user, isDisabledFn }) => {
  const dispatch = useDispatch();

  const handleUserAssignment = useCallback(() => {
    const { assigned, id } = user;
    if (assigned) {
      dispatch(revokeOrganizationUser({ data: { id } }));
    } else {
      dispatch(assignOrganizationUser({ data: { id } }));
    }
  }, [dispatch, user]);

  return <AssigmentButton disabled={isDisabledFn(user)} assigned={user.assigned} onClick={handleUserAssignment} />;
};

export default ActionCell;
