/* eslint-disable camelcase */
// @flow
import get from 'lodash/get';
import keyBy from 'lodash/keyBy';

// types
import type { TColumnDefs } from 'pages/company/Grid/types.js.flow';

export const GRID_SERVICE_KEYS = {
  PREVIEW_CHECKBOX_COLUMN_NAME: '__SERVICE_CHECKBOX_PREVIEW_COLUMN',
  PREVIEW_BTN_COLUMN_NAME: '__SERVICE_BTN_PREVIEW_COLUMN',
  CONTEXT_MENU_COLUMN_NAME: '__SERVICE_CONTEXT_MENU_COLUMN',
  COLUMNS_VISIBILITY_MENU: '__SERVICE_COLUMNS_VISIBILITY_MENU',
  COLUMNS_MASTER_VIEW: '__SERVICE_COLUMNS_MASTER_VIEW',
  COLUMNS_LINKED_ICON: '__SERVICE_COLUMNS_LINKED_ICON',
};

export const isServiceColumn = (colId?: string) => Object.values(GRID_SERVICE_KEYS).includes(colId);

const tooltipComponentByType = {
  string: 'customTooltip',
  select: 'customTooltip',
  static_select: 'customTooltip',
  extraSelect: 'customTooltip',
  date: 'customTooltip',
};

const getTooltipProps = (column) => {
  const tooltipComponent = tooltipComponentByType[column.type];

  return tooltipComponent
    ? {
        tooltipComponent,
        tooltipField: column.field,
      }
    : {};
};

export const addColDefaultParams = (columns: TColumnDefs[]) =>
  columns.map((column) => ({
    ...column,
    width: column.type === 'actions' ? 0 : 200, // width used for save default initial state and compare for differences - dont move
    ...getTooltipProps(column),
  }));

export const changeWidthIfMasterViewNotAvailable = (columns: TColumnDefs[], hasMasterDetail: boolean): TColumnDefs[] =>
  columns.map((column) =>
    column.field === GRID_SERVICE_KEYS.PREVIEW_CHECKBOX_COLUMN_NAME && !hasMasterDetail
      ? { ...column, maxWidth: 55 }
      : column,
  );

// invert in columns pinned value
export const invertPinnedValue = (columns: TColumnDefs[] = []): TColumnDefs[] => {
  const invertPinnedValues = {
    left: 'right',
    right: 'left',
  };
  return columns.map(({ pinned, ...column }) => ({
    ...column,
    pinned: invertPinnedValues[pinned] || null,
    lockPosition: invertPinnedValues[pinned] || null,
  }));
};

// invert in ag-grid state pinned value (columnPinning: {leftColIds: [}, rightColIds: [},)
export const invertPinnedState = (state) => ({
  ...state,
  ...(state?.columnPinning
    ? {
        columnPinning: {
          leftColIds: state.columnPinning?.rightColIds || [],
          rightColIds: state.columnPinning?.leftColIds || [],
        },
      }
    : {}),
});

export const isEllipsisActive = (el: HTMLElement) => el.offsetWidth < el.scrollWidth;

export const setExelModeFilter =
  (filterType: 'mac' | 'windows') =>
  (columns: TColumnDefs[] = []) =>
    columns.map((column) => {
      const up = { excelMode: filterType, buttons: ['apply', 'cancel'], closeOnApply: true };
      const filterParams = column.filterParams ? { ...column.filterParams, ...up } : up;
      return { ...column, filterParams };
    });

// native Map
export const errorStatusList = new Map([
  [1, 'warning'],
  [2, 'error'],
]);

export const getRowStatus = (params) => {
  if (!params?.data) return false;

  return errorStatusList.get(get(params, 'data.warning.error_level'));
};

export const ID_FOR_DETECT_CELL_OVERFLOW = 'containerForDetectCellOverflow';
export const SET_FILTER_HOVER_CLASSNAME = 'set-filter-hover';

export const mergeDifferenceBtwColumnsAdapter = (columns, orderedColumnConfig) => {
  const colMap = keyBy(columns, 'field');
  const currentColumnMap = keyBy(orderedColumnConfig, 'colId');

  const removedColumns = orderedColumnConfig.filter((col) => !colMap[col.colId]).map((col) => col.colId);

  const addedColumns = columns.filter((col) => !currentColumnMap[col.field]);

  const res = orderedColumnConfig
    .filter((state) => !removedColumns.includes(state.colId))
    .map((state) => ({ ...colMap[state.colId], ...state }));

  return [...res, ...addedColumns];
};

const suppressSelectAllShortcut = (params) => {
  const KEY_A = 'A';
  const { event } = params;
  const { key } = event;

  const keysToSuppress = [];
  if (event.ctrlKey || event.metaKey) {
    keysToSuppress.push(KEY_A);
  }
  return keysToSuppress.some((suppressedKey) => suppressedKey === key || key.toUpperCase() === suppressedKey);
};

export const suppressSelectAll = (columns) =>
  columns.map((col) => ({ ...col, suppressKeyboardEvent: suppressSelectAllShortcut }));

export const convertColumnDefsToGridState = (columns) =>
  columns.reduce(
    (acc, { colId, width, maxWidth, minWidth, flex, pinned, hide }) => {
      const currentWidth = Math.max(minWidth || 1, Math.min(maxWidth || 1000, width));

      acc.columnOrder.orderedColIds = [...acc.columnOrder.orderedColIds, colId];
      acc.columnSizing.columnSizingModel = [
        ...acc.columnSizing.columnSizingModel,
        { colId, flex, width: currentWidth },
      ];
      if (pinned) {
        if (pinned === 'left') {
          acc.columnPinning.leftColIds = [...acc.columnPinning.leftColIds, colId];
        }
        if (pinned === 'right') {
          acc.columnPinning.rightColIds = [...acc.columnPinning.rightColIds, colId];
        }
      }
      if (hide) {
        acc.columnVisibility.hiddenColIds = [...acc.columnVisibility.hiddenColIds, colId];
      }
      return acc;
    },
    {
      columnOrder: {
        orderedColIds: [],
      },
      columnPinning: {
        leftColIds: [],
        rightColIds: [],
      },
      columnSizing: {
        columnSizingModel: [],
      },
      columnVisibility: {
        hiddenColIds: [],
      },
    },
  );
