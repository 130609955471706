/* @flow */
import c from '../constatnt';

// eslint-disable-next-line no-unused-vars
export default (theme) => ({
  table: {
    width: '100%',
    tableLayout: 'fixed',
    textAlign: 'left',
  },
  row: {
    height: c.ROW_HEIGHT,
    lineHeight: `${c.ROW_HEIGHT}px`,
    background: theme.palette.common.white,
  },
  removeLine: {
    width: 30,
    lineHeight: 0,
    background: theme.palette.common.white,
    textAlign: 'center',
  },
  col: {
    color: '#171A1C',
    border: '1px solid transparent',
    height: c.ROW_HEIGHT,
    position: 'relative',
    outline: 'none',
    '& *': {
      outline: 'none',
    },
  },
  active: {},
  warning: {
    position: 'absolute',
    top: 0,
    left: -32,
  },
  firstRowOfTable: {
    '&>td': {
      position: 'sticky',
      top: 0,
      zIndex: 11,
    },
  },
});
