/* @flow */
import type { Map } from 'immutable';
import { type RecordsCellType } from 'domain/journal/helper';

export function isMandatory(data: ?RecordsCellType): boolean {
  if (data && data.cellSet) {
    const { mandatory, cellSet, readonly } = data;
    return mandatory && !readonly && !(cellSet.value && cellSet.value.length);
  }
  return false;
}

export function isActive(col: string, row: number, active: ?string): boolean {
  return typeof active === 'string' && col + row === active;
}

export function getDataCell(data: Map<string, RecordsCellType>, r: number, c: string) {
  return data.get(`${c}${r}`);
}

const round = (val: number): number => Math.floor(val / 10);

// eslint-disable-next-line max-len
export function getEntry({ scrollLeft }: $ReadOnly<HTMLElement>, rows: $ReadOnlyArray<HTMLElement>): [number, number] {
  let offset = 0;
  for (let i = 0; i < rows.length - 1; i++) {
    const prev = offset;
    const next = offset + rows[i].offsetWidth;

    if (round(prev) === round(scrollLeft)) {
      return [i === 0 ? 0 : rows[i - 1].offsetLeft, next];
    }

    if (round(next) === round(scrollLeft)) {
      const n = i + 2;
      return [prev, rows.length > n ? rows[n].offsetLeft : next];
    }

    if (prev < scrollLeft && scrollLeft < next) {
      return [prev, next];
    }
    offset = next;
  }
  return [0, rows[rows.length - 1].offsetLeft];
}
