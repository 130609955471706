// @flow
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';

// TODO: check types, its temporary
import AutocompleteMui, { type AutocompleteProps } from '@mui/material/Autocomplete';
import TextFieldMui from '@mui/material/TextField';

export type TOption = { label: string, value: string | number };
export type TOptions = $ReadOnlyArray<TOption>;

export type TAutocompleteBase = {
  isHighlight?: boolean,
  // props pass to TextField
  inputProps?: {
    label?: React$Node | string,
    placeholder?: React$Node | string,
  },
  value?: string,
  options: TOptions, // always Array, mui expect Array, don't List of Record or something else
} & AutocompleteProps;

export const AutocompleteHighlightedOption = (props, { label }: TOption, { inputValue }) => {
  const matches = match(label, inputValue);
  const parts = parse(label, matches);

  return (
    <li {...props}>
      <div>
        {parts.map((part, index) => (
          <span
            key={`${index.toString()}`}
            style={{
              fontWeight: part.highlight ? 700 : 400,
            }}
          >
            {part.text}
          </span>
        ))}
      </div>
    </li>
  );
};

const AutocompleteBase: React$StatelessFunctionalComponent<TAutocompleteBase> = ({
  isHighlight,
  value,
  options,
  inputProps = {},
  ...props
}) => {
  const intl = useIntl();

  // MUI autocomplete work with whole option, so need find option by value, because, we store only value from option
  const currentValue = useMemo(() => options.find((o) => value === o.value), [value, options]);

  return (
    <AutocompleteMui
      noOptionsText={intl.formatMessage({ id: 'options.noOptionsText', defaultMessage: 'No options' })}
      renderInput={(params) => <TextFieldMui {...inputProps} {...params} />}
      isOptionEqualToValue={(listOption, option) => (option ? listOption.value === option.value : false)}
      value={currentValue}
      options={options}
      renderOption={isHighlight ? AutocompleteHighlightedOption : null}
      {...props}
    />
  );
};

export default AutocompleteBase;
