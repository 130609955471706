// @flow
import React, { useMemo, useCallback, useEffect, useState } from 'react';

// components
import Widget from '../components/Widget';
import Iframe from 'components/Iframe';
import Typography from '@mui/material/Typography';

// hooks
import useActive from '../useActive';

// helpers
import DOMPurify from 'dompurify';

// react-intl
import { useIntl } from 'react-intl';

type Props = {|
  api: () => Promise<void>,
  onMouseDown: () => void,
  isReverse?: boolean,
  style: {
    zIndex: number,
  },
|};

const emailContentWrapperClass = `email-content-wrapper-${+new Date()}`;

const contentStyles = `
      .${emailContentWrapperClass} {
        height: 100%!important;
        overflow: auto!important;
      }
      .${emailContentWrapperClass}::-webkit-scrollbar {
          appearance: none!important;
          width: 7px!important;
          height: 7px!important;
      }
      .${emailContentWrapperClass}::-webkit-scrollbar-thumb {
        border-radius: 3px!important;
        background-color: rgba(0,0,0,.5)!important;
        box-shadow: 0 0 1px rgba(255,255,255,.5)!important;
      }
`;

const Email = ({ api, onMouseDown, isReverse = false, style }: Props) => {
  const { formatMessage } = useIntl();

  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState(null);
  const [content, setContent] = useState(null);
  const [error, setError] = useState(null);

  const { isActive, createToggleHandler, setIsActive } = useActive();

  const sanatizeContent = useMemo(() => DOMPurify.sanitize(content, { ADD_ATTR: ['target'] }), [content]);

  const button = useCallback(
    (handler: () => void) => (
      <button type="button" onClick={createToggleHandler(handler)}>
        <Typography variant="subtitle2">
          {formatMessage({ id: 'document.widget.btn.getEmail', defaultMessage: 'Email' })}
        </Typography>
      </button>
    ),
    [createToggleHandler, formatMessage],
  );

  useEffect(() => {
    const request = async () => {
      if (isActive && !content) {
        setLoading(true);
        setError(null);
        await api()
          .then(({ html, subject }) => {
            setTitle(subject);
            setContent(html);
          })
          .catch((err) => setError(err.response.data));
        setLoading(false);
      }
    };
    request();
  }, [isActive, content, api]);

  return (
    <Widget
      style={style}
      button={button}
      isActive={isActive}
      setIsActive={setIsActive}
      loading={loading}
      title={title}
      isReverse={isReverse}
      isMaxInitialWidth={!!content}
      widgetButtonPosition="Bottom"
      content={
        <Iframe
          width="100%"
          height="100%"
          marginWidth="0"
          marginHeight="0"
          hspace="0"
          vspace="0"
          frameBorder="0"
          title="email-content"
          sandbox="allow-same-origin allow-downloads allow-popups-to-escape-sandbox allow-popups"
        >
          <style type="text/css">{contentStyles}</style>
          <div
            className={emailContentWrapperClass}
            dangerouslySetInnerHTML={{
              __html: error || sanatizeContent,
            }}
          />
        </Iframe>
      }
      onMouseDown={onMouseDown}
    />
  );
};

export default Email;
