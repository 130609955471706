// @flow
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { MIN_CHARACTERS } from 'components/PasswordValidationPrompt/usePasswordValidation';

import Stack from '@mui/material/Stack';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import { styled } from '@mui/material/styles';

import CheckIcon from '@mui/icons-material/Check';
import CircleIcon from '@mui/icons-material/Circle';
import Typography from '@mui/material/Typography';

type TValidationRules = {|
  minLength: boolean,
  lowerCase: boolean,
  upperCase: boolean,
  number: boolean,
  symbol: boolean,
|};

type TPasswordValidationPrompt = {
  validation: TValidationRules,
};

type TValidationIcon = {
  isValid: boolean,
};

type TValidationText = {
  children: React$Node,
  isValid: boolean,
};

const ValidationItem = styled(ListItem)(() => ({
  width: 'auto',
}));

const ValidationIcon: React$StatelessFunctionalComponent<TValidationIcon> = ({ isValid }) => (
  <ListItemIcon sx={{ minWidth: 'auto', mr: 0.5 }}>
    {isValid ? <CheckIcon color="success" sx={{ fontSize: 14 }} /> : <CircleIcon sx={{ fontSize: 6 }} />}
  </ListItemIcon>
);

const ValidationText: React$StatelessFunctionalComponent<TValidationText> = ({ children, isValid }) => (
  <ListItemText disableTypography>
    <Typography variant="body2" color={isValid ? 'success.main' : 'error.main'} noWrap sx={{ fontSize: 12 }}>
      {children}
    </Typography>
  </ListItemText>
);

const PasswordValidationPrompt: React$StatelessFunctionalComponent<TPasswordValidationPrompt> = ({ validation }) => (
  <Stack direction="row" flexWrap="wrap" gap={0.5}>
    <ValidationItem disablePadding>
      <ValidationIcon isValid={validation.minLength} />
      <ValidationText isValid={validation.minLength}>
        <FormattedMessage
          id="validation.minCharacters"
          defaultMessage={`${MIN_CHARACTERS} characters`}
          values={{ minValue: MIN_CHARACTERS }}
        />
      </ValidationText>
    </ValidationItem>

    <ValidationItem disablePadding>
      <ValidationIcon isValid={validation.lowerCase} />
      <ValidationText isValid={validation.lowerCase}>
        <FormattedMessage id="validation.lowerCase" defaultMessage="Lower case" />
      </ValidationText>
    </ValidationItem>

    <ValidationItem disablePadding>
      <ValidationIcon isValid={validation.upperCase} />
      <ValidationText isValid={validation.upperCase}>
        <FormattedMessage id="validation.upperCase" defaultMessage="Upper case" />
      </ValidationText>
    </ValidationItem>

    <ValidationItem disablePadding>
      <ValidationIcon isValid={validation.number} />
      <ValidationText isValid={validation.number}>
        <FormattedMessage id="validation.number" defaultMessage="Number" />
      </ValidationText>
    </ValidationItem>

    <ValidationItem disablePadding>
      <ValidationIcon isValid={validation.symbol} />
      <ValidationText isValid={validation.symbol}>
        <FormattedMessage id="validation.symbol" defaultMessage="Symbol" />
      </ValidationText>
    </ValidationItem>
  </Stack>
);

export default PasswordValidationPrompt;
