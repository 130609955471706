/* @flow */
import * as React from 'react';
import Select from 'react-select';

// components
import DropdownIndicator from './DropdownIndicator';

// types
import type { ThemesParamsType } from 'styles/dokkaTheme/types.js.flow';

// styles
import sheets from './sheets';

export type ActyonType = 'input-change' | 'input-blur' | 'menu-close' | 'set-value';

export interface ActyonMeta {
  action: ActyonType;
}

export interface FormatMeta {
  context: 'value' | 'menu';
}

export interface IFilterOption<T> {
  label: string;
  value: string;
  data: T;
}

type Props<T> = {
  value: ?T,
  onChange: (d: T) => void,
  options: Iterable<T>,
  name?: string,
  getOptionLabel?: (d: T) => string | React$Node,
  getOptionValue?: (d: T) => string,
  isSearchable?: boolean,
  placeholder?: string,
  components?: $ReadOnly<{| Group?: React$Node, DropdownIndicator?: React$Node |}>,
  isRtl?: boolean,
  styles?: {},
  isOptionSelected?: (o: T, l: $ReadOnlyArray<T>) => boolean,
  className?: string,
  classNamePrefix?: string,
  onInputChange?: (v: string, action: ActyonMeta) => void,
  isClearable?: boolean,
  formatOptionLabel?: (o: T, meta: FormatMeta) => React$Node,
  filterOption?: (o: IFilterOption<T>, term: string) => boolean,
  hasError?: boolean,
  theme: ThemesParamsType,
  menuIsOpen: boolean,
  readOnly: boolean,
};

// TODO: [mui] check on imports and delete
class CountryCodeDropdown<T> extends React.Component<Props<T>> {
  render() {
    const { styles, components, theme, readOnly } = this.props;

    return (
      <Select
        {...this.props}
        isDisabled={readOnly}
        styles={{ ...sheets(theme), ...styles }}
        components={{ DropdownIndicator, ...components }}
      />
    );
  }
}

export default CountryCodeDropdown;

export const listToSelectOptions = (list: Array<string>) => list.map((item) => ({ value: item, label: item }));
