import logout from './icons/logout.svg';
import settings from './icons/settings.svg';
import calendar from './icons/calendar.svg';
import scanner from './icons/scanner.svg';
import exp from './icons/export.svg';
import syncIcon from './icons/syncIcon.svg';
import downloadIcon from './icons/downloadIcon.svg';
import events from './icons/events.svg';
import points from './icons/points.svg';
import ask from './icons/ask.svg';
import feedback from './icons/feedback.svg';
import info from './icons/info.svg';
import support from './icons/support.svg';
import chat from './icons/chat.svg';
import chatUnread from './icons/chatUnread.svg';
import fullscreen from './icons/fullscreen.svg';
import keyboard from './icons/keyboard.svg';

export const iconSet = {
  logout,
  settings,
  calendar,
  scanner,
  exp,
  syncIcon,
  downloadIcon,
  events,
  points,
  ask,
  feedback,
  info,
  support,
  chat,
  chatUnread,
  fullscreen,
  keyboard,
};

const getBgColor =
  (theme) =>
  ({ white, grey, bgColor }) => {
    const g = grey ? '#8C9BA5' : null;
    const w = white ? theme.colors.primaryLight : null;
    return g || w || bgColor || theme.colors.primaryWhite;
  };

export default (theme) => ({
  '@keyframes rotate': {
    from: {
      transform: 'rotate(0deg)',
    },
    to: {
      transform: 'rotate(360deg)',
    },
  },
  button: {
    width: 24,
    height: 24,
    flexShrink: 0,
    display: 'inline-block',
    WebkitMask: ({ icon }) => `url("${iconSet[icon]}") center center no-repeat`,
    // eslint-disable-next-line no-nested-ternary
    backgroundColor: getBgColor(theme),
    border: '0 none',
    transform: ({ direction, icon }) => (direction === 'rtl' && icon !== 'ask' ? 'matrix(-1, 0, 0, 1, 0, 0)' : null),
    outline: 'none',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: ({ grey }) => (grey ? theme.colors.primaryLight : false),
    },
    '&[disabled]': {
      backgroundColor: theme.colors.greyLight,
      cursor: 'not-allowed',
      animation: ({ animateWhenDisabled }) => (animateWhenDisabled ? '$rotate 0.8s infinite linear' : false),
    },
  },
});
