// @flow
import React from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import Dialog from 'components/mui/Dialog';
import CreateCompanyForm from './components/CreateCompanyForm';

import { createCompanyAction } from 'domain/companies';

type Props = {
  open: boolean,
  onClose: () => void,
};

const CreateCompany: React$StatelessFunctionalComponent<Props> = ({ open, onClose }) => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  const onCreateCompany = (data) => {
    const formData = new global.FormData();
    data.forEach((value, key) => formData.append(key, value));
    return new Promise((resolve, reject) => {
      dispatch(
        createCompanyAction({
          formData,
          resolve,
          reject,
        }),
      );
    });
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      title={formatMessage({ id: 'form.company.create.title', defaultMessage: 'Company Registration' })}
      withActions={false}
      withContent={false}
    >
      <CreateCompanyForm formID="create-company-form" onSubmit={onCreateCompany} onClose={onClose} />
    </Dialog>
  );
};

export default CreateCompany;
