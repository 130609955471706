const WEEKDAYS_SHORT = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
const WEEKDAYS_LONG = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export default function localeUtils(formatMessage) {
  const fm = (prefix, value) =>
    formatMessage({
      id: `${prefix}.${value}`,
      defaultMessage: value,
    });
  return {
    formatWeekdayShort(i) {
      const wd = WEEKDAYS_SHORT[i];
      return fm('dayPicker.weekDayShort', wd);
    },

    formatDay(d, wy = true, short = false) {
      const md = MONTHS[d.getMonth()];
      const day = short ? this.formatWeekdayShort(d.getDay()) : fm('dayPicker.weekDayLong', WEEKDAYS_LONG[d.getDay()]);
      return `${day}, ${d.getDate()} ${fm('dayPicker.months', md)}${wy ? ` ${d.getFullYear()}` : ''}`;
    },

    formatMonthTitle(d) {
      const md = MONTHS[d.getMonth()];
      return `${fm('dayPicker.months', md)} ${d.getFullYear()}`;
    },

    formatWeekdayLong(i) {
      const wd = WEEKDAYS_SHORT[i];
      return fm('dayPicker.weekDayShort', wd);
    },
  };
}
