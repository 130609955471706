/* @flow */
import { MatchingColorsMap, TEXTRACT_MATCH_STATUSES } from 'components/TextractCanvas/utils';

export default {
  [TEXTRACT_MATCH_STATUSES.DEFAULT]: {
    // &&& overrides hover state css
    '&&&': {
      background: MatchingColorsMap[TEXTRACT_MATCH_STATUSES.DEFAULT].fill,
    },
  },
  [TEXTRACT_MATCH_STATUSES.MATCH]: {
    '&&&': {
      background: MatchingColorsMap[TEXTRACT_MATCH_STATUSES.MATCH].fill,
    },
  },
  [TEXTRACT_MATCH_STATUSES.PARTIAL_MATCH]: {
    '&&&': {
      background: MatchingColorsMap[TEXTRACT_MATCH_STATUSES.PARTIAL_MATCH].fill,
    },
  },
};
