// @flow
import React from 'react';
import { List, OrderedMap, is } from 'immutable';
import { debounce } from 'throttle-debounce';
import { FormattedMessage } from 'react-intl';
import type { TMessageRecord } from 'domain/chat/types.js.flow';

import {
  MessagePeriodDivider,
  MessagesList,
  MessagesThread,
  NoMessages,
} from 'components/mui/Layouts/components/Chat/components/MessagesThread/StyledComponents';
import ChatMessage from 'components/mui/Layouts/components/Chat/components/MessagesThread/ChatMessage';
import CircularProgressWithBackdrop from 'components/mui/CircularProgressWithBackdrop';
import Typography from '@mui/material/Typography';

import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';

type Props = {|
  list: ?OrderedMap<string, List<TMessageRecord>>,
  ownerId: string,
  onPrev: () => void,
|};

class ChatMessagesList extends React.Component<Props> {
  static flatternList(list: ?OrderedMap<string, List<TMessageRecord>>): List<TMessageRecord> {
    return list ? list.toList().flatten(true) : new List();
  }

  shouldComponentUpdate(nextProps: Props) {
    const { list } = this.props;
    if (!is(list, nextProps.list)) return true;
    return false;
  }

  componentDidUpdate(prevProps: Props) {
    const list = this.flatList;
    const prevList = this.constructor.flatternList(prevProps.list);
    if (!prevList && list) {
      this.scrollEnd();
    }

    if (prevList && list) {
      if (!is(list.last(), prevList.last())) {
        this.scrollEnd();
      } else {
        const first = prevList.first();
        if (first) {
          this.scrollToElement(first.messageId);
        }
      }
    }
  }

  get dseq() {
    return this.props.list ? this.props.list.keySeq() : new List();
  }

  get dList() {
    return this.props.list ? this.props.list.toList() : new List();
  }

  get flatList(): List<TMessageRecord> {
    return this.constructor.flatternList(this.props.list);
  }

  getPrevious = debounce(500, true, this.props.onPrev);

  scrollToElement(messageId: number): void {
    const c = this.container;
    if (this.dList && c && c.children && c.children[0]) {
      this.dList.forEach((list, groupIndex) => {
        const index = list.findIndex((f) => f.messageId === messageId);
        // eslint-disable-next-line max-len
        if (
          index >= 0 &&
          c.children[groupIndex] &&
          c.children[groupIndex].children &&
          c.children[groupIndex].children[1] &&
          c.children[groupIndex].children[1].children[index]
        ) {
          c.children[groupIndex].children[1].children[index].scrollIntoView(true);
        }
      });
    }
  }

  scrollEnd = () => {
    const { container } = this;
    if (container) container.scrollTop = container.scrollHeight;
  };

  handleScroll = (e: SyntheticEvent<*>) => {
    const { scrollTop } = e.currentTarget;
    if (scrollTop < 5) {
      this.getPrevious();
    }
  };

  periodFormatter = (i: number): ?string | React$Node => {
    switch (this.dseq.get(i)) {
      case 'Yesterday':
        return <FormattedMessage id="chat.dates.yesterday" defaultMessage="Yesterday" />;
      default:
        return this.dseq.get(i);
    }
  };

  container: ?HTMLDivElement;

  render() {
    const { list, ownerId } = this.props;

    return (
      <MessagesThread
        ref={(el) => {
          this.container = el;
        }}
        onScroll={this.handleScroll}
      >
        <MessagesList justifyContent={!this.dList.size ? 'center' : 'flex-end'}>
          {this.dList.size ? (
            this.dList.map((v, i) => (
              <section key={this.dseq.get(i)}>
                <MessagePeriodDivider textAlign="center">{this.periodFormatter(i)}</MessagePeriodDivider>

                {v.map((e) => (
                  <ChatMessage key={e.messageId} data={e} isMy={ownerId === e.sender.userId} />
                ))}
              </section>
            ))
          ) : (
            <NoMessages>
              <ChatBubbleOutlineOutlinedIcon />
              <br />
              <Typography variant="body1">
                <FormattedMessage id="chat.noMessage" defaultMessage="No messages here" />
              </Typography>
            </NoMessages>
          )}
        </MessagesList>
        {!list && <CircularProgressWithBackdrop BackdropProps={{ sx: { position: 'absolute' } }} />}
      </MessagesThread>
    );
  }
}

export default ChatMessagesList;
