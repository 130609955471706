// @flow
import toast from 'components/Toast';
import { FormattedMessage } from 'react-intl';
import { type $AxiosError } from 'axios';
import { get } from 'lodash';

export type ValidationError = {|
  message: string,
  errors: {
    [key: string]: string,
  },
|};

// eslint-disable-next-line max-len
export function yieldSuccessNotification(message: string | React$Element<typeof FormattedMessage>): void {
  toast.success(message);
}
// eslint-disable-next-line max-len
export function yieldInfoNotification(message: string | React$Element<typeof FormattedMessage>): void {
  toast.info(message);
}

// eslint-disable-next-line max-len
export function yieldErrorNotification(message: string | React$Element<typeof FormattedMessage>): void {
  toast.error(message);
}

// eslint-disable-next-line max-len
export function yieldWarningNotification(message: string | React$Element<typeof FormattedMessage>): void {
  toast.warn(message);
}

export const toastResponseError = (response) => {
  const error = get(response, 'response.data.error');

  if (typeof error === 'string') {
    return toast.error(`Failed to import data: ${error}`);
  }

  return toast.error('Import failed');
};

export function validationNotification(err: ValidationError): void {
  if (err && err.message) {
    toast.error(err.message);
  }
  if (err && err.errors) {
    Object.keys(err.errors).forEach((errorKey) => toast.error(err.errors[errorKey]));
  }
}

export function yieldValidationNotification(err: $AxiosError<ValidationError>): void {
  // eslint-disable-next-line max-len
  if (
    err &&
    err.response &&
    (err.response.status.toString() === process.env.REACT_APP_VALIDATION_FAILED_CODE ||
      err.response.status.toString() === process.env.REACT_APP_DUPLICATED_VALIDATION_FAILED_CODE)
  ) {
    validationNotification(err.response.data);
  }
}

// TODO: need to check other error paths, only checked for manual error path
//  developed only following the example of previous error handling methods
/**
 * @returns {boolean} - return result if toast was defined and appears
 */
export function handleErrorResponseWithToast<T>(
  errorResponse: $AxiosError<T>,
  options: {
    path?: string,
    type?: string,
    defaultMessage?: string | React$Element<typeof FormattedMessage>,
  } = {},
): boolean {
  const originOptions = {
    path: 'data.message', // default path to get error
    type: 'error',
    defaultMessage: null,
    ...options,
  };

  const { defaultMessage, type, path } = originOptions;

  if (errorResponse && errorResponse.response) {
    const { response } = errorResponse;
    const error = get(response, path) || get(response, 'data.errors'); // alternative path to get error
    // when error is existed
    if (error) {
      if (Array.isArray(error)) {
        Object.keys(error).forEach((errorKey) => toast[type](error[errorKey]));
      } else {
        toast[type](error);
      }
      return true;
    }
    // when error is not found by path and exist default message
    if (!error && defaultMessage) {
      toast[type](defaultMessage);
      return true;
    }
  }
  // when response is undefined but exist default message
  if (defaultMessage) {
    toast[type](defaultMessage);
    return true;
  }

  return false;
}
