import Breadcrumbs from '@mui/material/Breadcrumbs';

import { styled } from '@mui/material/styles';

export const Footer = styled('footer', { label: 'FooterWithTerms' })(({ theme }) => ({
  flexGrow: 0,
  flexShrink: 0,
  textAlign: 'center',
  padding: theme.spacing(2, 0),
  display: 'flex',
  flexDirection: 'column',
}));

export const FooterNavigation = styled(Breadcrumbs, { label: 'FooterNavigation' })(({ theme }) => ({
  '& .MuiBreadcrumbs-ol': {
    fontSize: theme.typography.pxToRem(12),
    justifyContent: 'center',
  },
}));
