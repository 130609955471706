/* @flow */
import { makeStyles } from '@mui/styles';

export default makeStyles(() => ({
  xlsContainer: {
    position: 'relative',
    width: '100%',
    height: '100%',
  },
  container: {
    height: '100%',
    position: 'relative',
    '&:hover .DocumentPage-controls': {
      opacity: 1,
    },
  },
}));
