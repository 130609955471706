// @flow
import * as React from 'react';
import { DropTarget } from 'react-dnd';

type Props = {
  onDragOver: (e: DragEvent<HTMLDivElement>, it: ?string) => void,
  onDragLeave: (e: DragEvent<HTMLDivElement>, it: ?string) => void,
  onDragEnter: (e: DragEvent<HTMLDivElement>, it: ?string) => void,
  // eslint-disable-next-line react/no-unused-prop-types
  onDndDrop: (d: Object) => void,
  className: string,
  children: React$Node,
  connectDropTarget: Function,
  srcItemType: string,
  targetItemTypes: string | $ReadOnlyArray<string>,
  refProxy: (el: ?HTMLElement) => void,
  isOver: boolean,
  style: {
    [key: string]: string,
  },
};

class DraggableTarget extends React.PureComponent<Props> {
  useCallback = (cb: () => void) => (e: DragEvent<HTMLDivElement>) => {
    const { srcItemType, targetItemTypes } = this.props;
    if (cb && targetItemTypes.includes(srcItemType)) {
      cb(e, srcItemType);
    }
  };

  render() {
    const { onDragEnter, onDragLeave, onDragOver, className, children, connectDropTarget, style, refProxy } =
      this.props;

    return connectDropTarget(
      <div
        onDragEnter={this.useCallback(onDragEnter)}
        onDragLeave={this.useCallback(onDragLeave)}
        // need for firefox
        onDragOver={this.useCallback(onDragOver)}
        className={className}
        ref={refProxy}
        style={style}
      >
        {children}
      </div>,
    );
  }
}

const dropSource = {
  drop(props: Props, monitor) {
    if (!monitor.getItem().files && !monitor.didDrop() && props.onDndDrop) {
      props.onDndDrop(monitor.getItem());
    }
  },
};

const collect = (types: string | $ReadOnlyArray<string>) => (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  srcItemType: monitor.getItemType(),
  targetItemTypes: Array.isArray(types) ? types : [types],
});

export const DnDItemTarget = (name: string | $ReadOnlyArray<string>) =>
  DropTarget(name, dropSource, collect(name))(DraggableTarget);
