// @flow
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, generatePath } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { getParamsFromChannelName } from 'sendbird-utils';
import ROUTES_PATH from 'domain/router/routesPathConfig';
import { chatParticipantsByIdSelector, setActiveChannelForceAction, setOpenAction } from 'domain/chat';
import type { TMessageRecord } from 'domain/chat/types.js.flow';
import { useListTimeFormatter } from 'components/mui/Layouts/components/Chat/components/ChannelsThread/hooks';

import {
  DocumentChannelActionArea,
  ChannelMeta,
  ChannelTime,
} from 'components/mui/Layouts/components/Chat/components/StyledComponents';
import HighlightText from 'components/mui/HighlightText';
import RouterLink from 'components/mui/Router/RouterLink';
import ListItemText from '@mui/material/ListItemText';

type TDocumentChannel = {
  thread: TMessageRecord,
  threadId: string,
  searchText?: string,
};

const DocumentChannel: React$StatelessFunctionalComponent<TDocumentChannel> = ({ thread, threadId, searchText }) => {
  const dispatch = useDispatch();
  const { companyId } = useParams();
  const time = useListTimeFormatter(thread.createdAt);
  const usersByID = useSelector(chatParticipantsByIdSelector);
  const user = usersByID.get(thread.sender.userId);
  const username = user ? user.username : thread.sender.nickname;

  const pathname = {
    pathname: generatePath(ROUTES_PATH.DOCUMENT.absolute, getParamsFromChannelName(threadId)),
    state: {
      chat: true,
      sender: thread.sender.toJS(),
    },
  };

  const handleClick = () => {
    dispatch(
      setActiveChannelForceAction({
        companyId,
        threadId,
      }),
    );
    dispatch(setOpenAction(false));
  };

  return (
    <DocumentChannelActionArea component={RouterLink} to={pathname} onClick={handleClick}>
      <ListItemText sx={{ my: 0 }} primaryTypographyProps={{ fontSize: 14, fontWeight: 500 }}>
        <HighlightText value={username} searchTerm={searchText} />
        <br />
        <FormattedMessage id="chat.document.reply" defaultMessage="New Reply" />
      </ListItemText>
      <ChannelMeta>
        <ChannelTime variant="caption">{time}</ChannelTime>
      </ChannelMeta>
    </DocumentChannelActionArea>
  );
};

export default DocumentChannel;
