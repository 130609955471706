/* @flow */
import { enrichException } from './sentry';

const envs = ['local', 'staging', 'production'];
const __ENV__ = Boolean(envs.indexOf(process.env.REACT_APP_ENV) + 1);

declare class ErrorEntry mixins Error {
  sagaStack: string;
}

type ErrorMetaType = {
  data: Object,
};

export const notify = {
  captureEvent(error: Error, meta?: ErrorMetaType, tag?: string) {
    enrichException(error, meta, tag);
  },
};

export function sagaUncaughtErrorHandler(error: ErrorEntry): void {
  // currently we log saga errors for staging only
  if (__ENV__) {
    const opts = {
      data: {
        sagaStack: error.sagaStack,
      },
    };
    // eslint-disable-next-line no-console
    console.log('saga uncaught error', error);
    notify.captureEvent(error, opts, 'saga');
  } else {
    // eslint-disable-next-line no-console
    console.error('saga unhandled error due to local environment');
  }
}

// eslint-disable-next-line max-len
export function onError(msg: string, url: string, line: string, col: string, error: Error) {
  notify.captureEvent(error, {
    data: {
      msg,
      url,
      line,
      col,
    },
  });
  return true;
}
