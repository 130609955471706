/* @flow */
import React from 'react';

import { FakeDropdownContainer, FakeDropdown } from 'pages/company/Grid/components/InputCellRenderer/StyledComponents';

type TInputCellRenderer = {
  value: string,
};

const InputCellRenderer = ({ value }: TInputCellRenderer) => (
  <FakeDropdownContainer>
    <FakeDropdown clickable label={value} />
  </FakeDropdownContainer>
);

export default InputCellRenderer;
