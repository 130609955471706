// @flow
import { alpha, styled } from '@mui/material/styles';

import BadgeMui from '@mui/material/Badge';
import CardActionsMui from '@mui/material/CardActions';
import CardActionAreaMui from '@mui/material/CardActionArea';

export const CardActionArea = styled(CardActionAreaMui)(() => ({
  '& .MuiCardActionArea-focusHighlight': {
    backgroundColor: 'transparent',
    opacity: 0.5,
    backgroundImage: 'linear-gradient(180deg, rgb(255 255 255 / 0%) 12.5%, rgba(0, 28, 152, 0.24) 100%)',
  },

  '&:hover .MuiCardActionArea-focusHighlight': {
    opacity: 1,
  },
}));

export const CardActionAreaOverlay = styled('span')(({ theme }) => ({
  backdropFilter: 'blur(1px)',
  display: 'flex',
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: alpha(theme.palette.primary.main, 0.12),
  backgroundImage: `repeating-linear-gradient(45deg, ${alpha(theme.palette.primary.main, 0.3)} 10px, ${alpha(
    theme.palette.primary.main,
    0.3,
  )} 12px, transparent 12px, transparent 20px), linear-gradient(to right, ${
    theme.palette.error.main
  } 5px, transparent 5px)`,
}));

export const CardActions = styled(CardActionsMui)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  justifyContent: 'space-between',
  paddingTop: theme.spacing(1.5),
  height: 44,
}));

export const CardBadge = styled(BadgeMui)(({ theme }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: 'transparent',
    color: theme.palette.primary.main,
    right: -4,
  },
}));

export const CardPendingApprovalBadge = styled(BadgeMui)(({ theme }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: 'transparent',
    color: theme.palette.warning.main,
    right: -4,
  },
}));
