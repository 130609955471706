import { useState, useEffect, useMemo } from 'react';

import { useSelector } from 'react-redux';
import { approvalGroupListSelector } from 'domain/approvals/selectors';
import { organizationUsersForConfidentialDocSelector } from 'domain/organizationUser/selectors';
import { UserEnhancedApprovalFactory } from 'domain/approvals/helpers';
import { createFilterOptions } from '@mui/material/Autocomplete';

export const messages = {
  accountants: {
    id: 'document.approval.accountants',
    defaultMessage: 'Accountants',
  },
  clients: { id: 'document.approval.clients', defaultMessage: 'Clients' },
  groups: {
    id: 'document.approval.groups',
    defaultMessage: 'Groups',
  },
};

const GroupFactory = (group, users, groupName) => {
  const g = group.toJS();
  return {
    ...g,
    approvers: g.approvers.map((a) => users.find((u) => u.id === a)).filter(Boolean),
    group: groupName,
  };
};

const mapStateToProps = (state) => ({
  approvalGroups: approvalGroupListSelector(state),
  organizationUsers: organizationUsersForConfidentialDocSelector(state),
});

const [values, order] = [[], ['groups', 'clients', 'accountants']];

export default (formValue = values, groupsOrder = order) => {
  const [approvers, setApprovers] = useState([]);
  const { organizationUsers, approvalGroups } = useSelector(mapStateToProps);


  useEffect(() => {
    const callbacks = {
      groups: (i) => i.group_id,
      accountants: (i) => ['accountant', 'limited-accountant'].includes(i.role),
      clients: (i) => !['accountant', 'limited-accountant'].includes(i.role),
    };

    const list = [];

    organizationUsers.forEach((u) => {
      // eslint-disable-next-line no-unused-vars
      const [key] = Object.entries(callbacks).find(([_, v]) => v(u)) || [];
      if (key) {
        const approver = {
          ...UserEnhancedApprovalFactory({
            userGuid: u.userId,
            tag: u.userId,
            id: u.userId,
            picture: u.picture,
            fullName: u.username,
          }).toJS(),
          group: key,
        };
        list.push(approver);
      }
    });
    const groups = [];
    approvalGroups.forEach((g) => {
      // eslint-disable-next-line no-unused-vars
      const [key] = Object.entries(callbacks).find(([_, v]) => v(g)) || [];
      const group = GroupFactory(g, list, key);
      groups.push(group);
    });

    setApprovers(
      [...list.reverse(), ...groups.sort((a, b) => a.group_title.localeCompare(b.group_title))]
        .filter((i) => groupsOrder.includes(i.group))
        .sort((a, b) => groupsOrder.indexOf(a.group) - groupsOrder.indexOf(b.group)),
    );
  }, [organizationUsers, groupsOrder, approvalGroups]);

  const value = useMemo(
    () =>
      approvers
        .filter((a) => formValue.some((f) => f.id === a.id))
        .sort((a, b) => formValue.findIndex((v) => v.id === a.id) - formValue.findIndex((v) => v.id === b.id)),
    [approvers, formValue],
  );

  return { approvers, value };
};

export const filterOptions = createFilterOptions({
  stringify: (option) =>
    option.group_id
      ? option.group_title + option.approvers.map((o) => o.fullName + o.id).join('')
      : option.fullName + option.id,
});
