/* @flow */
import React from 'react';

import { Dispatch } from 'redux';
import { addAppStatusAction } from 'domain/env';
import { connect } from 'react-redux';
import get from 'lodash/get';

const message = {
  id: 'app.diskSpaceLimit',
  defaultMessage: 'Not enough disk space available for your browser. Please, advise your administrator',
};

const SAFE_DISC_SPACE_LIMIT = 10; // MB

type Props = {
  addAppStatus: Dispatch<typeof addAppStatusAction>,
};

class DiskSpaceStatus extends React.Component<Props, {}> {
  componentDidMount() {
    this.estimate();
  }

  estimate = () => {
    const storageManager = get(window, 'navigator.storage');
    if (storageManager && typeof storageManager.estimate === 'function') {
      storageManager.estimate().then(({ quota, usage }) => {
        if (quota - (usage + SAFE_DISC_SPACE_LIMIT * 1024 * 1024) < 0) {
          this.props.addAppStatus(message);
        }
      });
    }
  };

  render() {
    return null;
  }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  addAppStatus: addAppStatusAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(DiskSpaceStatus);
