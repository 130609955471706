// @flow
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { userSelector } from 'domain/env';
import { useIntl } from 'react-intl';
import { useConfirm } from 'material-ui-confirm';

import { companiesByOrganizationSelector } from 'domain/companies/companiesSelector';
import {
  assignOrganizationUser,
  revokeOrganizationUser,
  revokeUserFromAllCompanies,
  deleteOrganizationUser,
  bulkAssignOrganizationCompanies,
  bulkRevokeOrganizationCompanies,
} from 'domain/organizationUser/actions';
import { getOrganizationUserByIdSelector } from 'domain/organizationUser/selectors';

import AssigmentButton from 'pages/configurations/components/AssigmentButton';
import Avatar from 'components/mui/Avatar';
import MuiList from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Button from '@mui/material/Button';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListSubheader from '@mui/material/ListSubheader';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';

import { getUserDisplayName, sortCompanies } from 'pages/configurations/helpers';
import SearchBase from 'components/mui/Layouts/components/AppbarSearchBase';
import NameCell from 'pages/configurations/components/Table/components/NameCell';
import Table from 'pages/configurations/components/Table';
import SelectPanel from 'pages/configurations/components/SelectPanel';

import { INITIAL_BULK_ACTIONS_CONFIG } from 'pages/configurations/components/SelectPanelWrapper/constants';
import { type CompanyType } from 'domain/companies/helpers';

import { grey } from '@mui/material/colors';
import useSearchAndSort from 'pages/configurations/hooks/useSearchAndSort';

type TOrganizationUserDetails = {
  userID: string,
  onGoBack: () => void,
  onClickEdit: () => void,
};

const mapStateToProps = (state) => ({
  currentUser: userSelector(state),
  companies: companiesByOrganizationSelector(state),
});

const OrganizationUserDetails: React$StatelessFunctionalComponent<TOrganizationUserDetails> = ({
  userID,
  onGoBack,
  onClickEdit,
}) => {
  const dispatch = useDispatch();
  const confirm = useConfirm();
  const { formatMessage } = useIntl();
  const [selected, setSelected] = useState([]);
  const [bulkActionsConfig, setBulkActionsConfig] = useState({ ...INITIAL_BULK_ACTIONS_CONFIG });

  const { companies, currentUser } = useSelector(mapStateToProps);
  const user = useSelector(getOrganizationUserByIdSelector(userID));

  const isAssigned = useCallback((companyID: string) => user.companies_id.has(companyID), [user.companies_id]);

  const generateStringForSearch = useCallback((company) => `${company.cname} ${company.email}`, []);

  const { debounceOnChangeSearch, handleChangeSort, searchText, rows, sort, sortBy } = useSearchAndSort({
    generateStringForSearch,
    initialList: companies,
    defaultSortFn: sortCompanies,
  });

  const handleUserAssignment = useCallback(
    (companyId: string) => {
      const assigned = user.companies_id.has(companyId);

      if (assigned) dispatch(revokeOrganizationUser({ data: { id: user.id, companyId } }));
      else dispatch(assignOrganizationUser({ data: { id: user.id, companyId } }));
    },
    [dispatch, user],
  );

  const handleSelectAllClick = useCallback(
    (event) => {
      if (event.target.checked) {
        const newSelected = rows.toJS().map(({ id }) => id);
        setSelected(newSelected);
        return;
      }
      setSelected([]);
    },
    [rows],
  );

  const onClearSelected = useCallback(() => {
    setSelected([]);
  }, []);

  const handleRevokeAll = useCallback(() => {
    dispatch(revokeUserFromAllCompanies(user.id));
  }, [dispatch, user]);

  const handleDelete = useCallback(() => {
    confirm({
      title: formatMessage({
        id: 'confirm.user.organization.delete.title',
        defaultMessage: 'Delete User?',
      }),
      description: formatMessage({
        id: 'confirm.user.organization.delete.description',
        defaultMessage: 'User will be permanently deleted from the system, action cannot be  undone.',
      }),
      confirmationText: formatMessage({
        id: 'button.delete',
        defaultMessage: 'Delete',
      }),
    }).then(() => {
      dispatch(deleteOrganizationUser({ id: user.id }));
      onGoBack();
    });
  }, [dispatch, confirm, formatMessage, onGoBack, user]);

  const isRevokeDisabled = useMemo(
    () => companies.toList().size === 0 || currentUser.userId === user.email || user.admin,
    [companies, currentUser.userId, user],
  );

  const columns = useMemo(
    () => [
      {
        id: 'cname',
        label: formatMessage({
          id: 'configurations.organization.users.company.tableHeader.name',
          defaultMessage: 'Company name',
        }),
        styles: { minWidth: 170, width: '40%' },
        component: (company: CompanyType) => <NameCell name={company.cname} picture={company.logo} />,
      },
      {
        id: 'email',
        label: formatMessage({ id: 'configurations.company.users.tableHeader.email', defaultMessage: 'Email' }),
        styles: { minWidth: 170, width: '40%' },
      },
      {
        id: 'assigned',
        label: formatMessage({ id: 'configurations.company.users.tableHeader.action', defaultMessage: 'Action' }),
        styles: { minWidth: 130 },
        disabled: true,
        component: (company: CompanyType) => (
          <AssigmentButton
            disabled={isRevokeDisabled}
            assigned={isAssigned(company.id)}
            onClick={() => handleUserAssignment(company.id)}
          />
        ),
      },
    ],
    [formatMessage, handleUserAssignment, isAssigned, isRevokeDisabled],
  );

  const onBulkAssign = useCallback(() => {
    new Promise((resolve, reject) => {
      dispatch(
        bulkAssignOrganizationCompanies({
          data: { companyIds: selected, userId: user.id, username: getUserDisplayName(user) },
          resolve,
          reject,
        }),
      );
    }).then(onClearSelected);
  }, [dispatch, selected, user, onClearSelected]);

  const onBulkRevoke = useCallback(() => {
    new Promise((resolve, reject) => {
      dispatch(
        bulkRevokeOrganizationCompanies({
          data: { companyIds: selected, userId: user.id, username: getUserDisplayName(user) },
          resolve,
          reject,
        }),
      );
    }).then(onClearSelected);
  }, [dispatch, selected, user, onClearSelected]);

  useEffect(() => {
    if (rows.size && selected.length) {
      const selectedCompanies = rows.filter((u) => selected.includes(u.id));
      const actionsConfig = { ...INITIAL_BULK_ACTIONS_CONFIG };

      selectedCompanies.forEach((company) => {
        if (isAssigned(company.id)) {
          actionsConfig.bulkRevokeAvailable = true;
          if (actionsConfig.isDisableBulkRevoke) {
            actionsConfig.isDisableBulkRevoke = isRevokeDisabled;
          }
        } else {
          actionsConfig.bulkAssignAvailable = true;
          if (actionsConfig.isDisableBulkAssign) {
            actionsConfig.isDisableBulkAssign = isRevokeDisabled;
          }
        }
      });
      setBulkActionsConfig(actionsConfig);
    }
  }, [selected, rows, isRevokeDisabled, isAssigned]);

  return (
    <>
      <MuiList
        subheader={
          <ListSubheader sx={{ px: 0, mb: 1 }}>
            <Button size="small" variant="outlined" onClick={onGoBack} startIcon={<ArrowBackIcon />}>
              {formatMessage({ id: 'button.back', defaultMessage: 'Back' })}
            </Button>
          </ListSubheader>
        }
        sx={{ position: 'sticky', top: 0, backgroundColor: 'common.white', zIndex: 1, p: 2 }}
      >
        <ListItem divider alignItems="center" sx={{ backgroundColor: grey[50] }}>
          <ListItemAvatar>
            <Avatar size={40} src={user.pic} />
          </ListItemAvatar>
          <Box flexGrow={1} my="6px">
            <Typography variant="body1">{getUserDisplayName(user)}</Typography>
          </Box>
          <Box flexGrow={1} my="6px">
            <Typography variant="body1">{user.email}</Typography>
          </Box>
          <Box flexGrow={1} my="6px">
            <Typography variant="body1">{user.display_role}</Typography>
          </Box>
          <Box flexGrow={1} my="6px">
            <Typography variant="body1">{user.phone}</Typography>
          </Box>
          <Box flexShrink={0}>
            <Button size="small" color="info" variant="outlined" onClick={onClickEdit} startIcon={<EditIcon />}>
              {formatMessage({ id: 'button.edit', defaultMessage: 'Edit' })}
            </Button>
            <Button
              size="small"
              color="error"
              variant="outlined"
              onClick={() => handleDelete(user.id)}
              startIcon={<DeleteIcon />}
              disabled={Boolean(user.companies_id.size)}
              sx={{ ml: 1 }}
            >
              {formatMessage({ id: 'button.delete', defaultMessage: 'Delete' })}
            </Button>
          </Box>
        </ListItem>
      </MuiList>

      <Stack px={2} py={1} spacing={2}>
        <Typography variant="h6">
          {formatMessage({
            id: 'configurations.org_users.list.assigned_companies',
            defaultMessage: 'Assigned companies',
          })}
          :
          <Typography variant="h6" component="span" pl={1}>
            {user.companies_id.size}
          </Typography>
        </Typography>
        <Stack direction="row" justifyContent="space-between">
          <SearchBase value={searchText} onChange={debounceOnChangeSearch} />
          <Button
            color="error"
            variant="outlined"
            disabled={isRevokeDisabled}
            onClick={handleRevokeAll}
            startIcon={<CloseIcon />}
          >
            {formatMessage({
              id: 'configurations.org_users.details.revokeAllAccess',
              defaultMessage: 'Revoke All Access',
            })}
          </Button>
        </Stack>
      </Stack>

      <Table
        sort={sort}
        sortBy={sortBy}
        selected={selected}
        data={rows}
        columns={columns}
        handleSelectAllClick={handleSelectAllClick}
        handleChangeSort={handleChangeSort}
        setSelected={setSelected}
        withCheckbox
      />

      <SelectPanel
        isVisible={!!selected.length}
        count={selected.length}
        bulkActionsConfig={bulkActionsConfig}
        onClearSelected={onClearSelected}
        onBulkAssign={onBulkAssign}
        onBulkRevoke={onBulkRevoke}
        countTitle={formatMessage({
          id: 'configurations.organization.users.selectedPanel.title',
          defaultMessage: 'Companies selected',
        })}
      />
    </>
  );
};

export default OrganizationUserDetails;
