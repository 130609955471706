// @flow
import React, { useCallback, useMemo } from 'react';

import MuiTable from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Checkbox from '@mui/material/Checkbox';
import TableBody from '@mui/material/TableBody';
import TableSortLabel from '@mui/material/TableSortLabel';
import Typography from '@mui/material/Typography';
import SvgIcon from '@mui/material/SvgIcon';

import { ReactComponent as IconDefaultSRC } from './components/icons/sort-default.svg';
import { ReactComponent as IconAscSRC } from './components/icons/sort-asc.svg';
import { ReactComponent as IconDescSRC } from './components/icons/sort-desc.svg';

type TProps = {
  selected?: Array<string>,
  sort: string,
  sortBy: string | null,
  data: any,
  columns: Array<{
    id: string,
    label: string,
    disabled?: boolean,
    component?: (data: any) => React$Node,
    styles?: CSSStyleDeclaration,
  }>,
  handleSelectAllClick?: (event: SyntheticInputEvent<HTMLInputElement>) => void,
  handleChangeSort: () => void,
  withCheckbox?: boolean,
  onClickRow?: (id: string) => void,
  setSelected?: (Array<string>) => void,
};

export const IconSortAsc = () => <SvgIcon component={IconAscSRC} inheritViewBox />;
export const IconSortDesc = () => <SvgIcon component={IconDescSRC} inheritViewBox />;
export const IconSortDefault = () => <SvgIcon component={IconDefaultSRC} inheritViewBox />;

const SORT_ICONS = {
  asc: IconSortAsc,
  desc: IconSortDesc,
  default: IconSortDefault,
};

const Table: React$StatelessFunctionalComponent<TProps> = ({
  selected = [],
  sort,
  sortBy,
  data,
  columns,
  handleSelectAllClick = () => {},
  handleChangeSort,
  withCheckbox = false,
  onClickRow,
  setSelected,
}) => {
  const isSelected = useCallback((id) => selected.indexOf(id) !== -1, [selected]);

  const handleCheckboxClick = useCallback(
    (event, id) => {
      const selectedIndex = selected.indexOf(id);

      let newSelected = [];

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, id);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
      }
      setSelected(newSelected);
    },
    [selected, setSelected],
  );

  const createSortHandler = useCallback(
    (columnID) => () => {
      handleChangeSort(columnID);
    },
    [handleChangeSort],
  );

  return (
    <TableContainer sx={{ maxHeight: '100%' }}>
      <MuiTable stickyHeader>
        <TableHead>
          <TableRow>
            <>
              {withCheckbox && (
                <TableCell padding="checkbox" sx={{ bgcolor: 'common.white' }}>
                  <Checkbox
                    color="primary"
                    indeterminate={selected.length > 0 && selected.length < data.size}
                    checked={data.size > 0 && selected.length >= data.size}
                    onChange={handleSelectAllClick}
                  />
                </TableCell>
              )}
              {columns.map((column) => (
                <TableCell key={column.id} style={{ ...column.styles }} sx={{ bgcolor: 'common.white' }}>
                  {column.disabled ? (
                    column.label
                  ) : (
                    <TableSortLabel
                      IconComponent={sortBy === column.id ? SORT_ICONS[sort] : IconSortDefault}
                      onClick={createSortHandler(column.id)}
                    >
                      {column.label}
                    </TableSortLabel>
                  )}
                </TableCell>
              ))}
            </>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((item) => (
            <TableRow
              hover
              tabIndex={-1}
              key={item.id}
              onClick={() => onClickRow?.(item.id)}
              sx={onClickRow ? { cursor: 'pointer' } : {}}
            >
              <>
                {withCheckbox && (
                  <TableCell padding="checkbox">
                    <Checkbox
                      color="primary"
                      checked={isSelected(item.id)}
                      onClick={(event) => handleCheckboxClick(event, item.id)}
                    />
                  </TableCell>
                )}
                {columns.map((column) => (
                  <TableCell key={column.id} style={{ whiteSpace: 'normal', wordBreak: 'break-word' }}>
                    {column.component?.(item) || <Typography variant="body1">{item[column.id]}</Typography>}
                  </TableCell>
                ))}
              </>
            </TableRow>
          ))}
        </TableBody>
      </MuiTable>
    </TableContainer>
  );
};

export default Table;
