// @flow
import React, { useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { useConfirm } from 'material-ui-confirm';

import {
  createOrganizationApiKeyAction,
  deleteOrganizationApiKeyAction,
  updateOrganizationApiKeyAction,
} from 'domain/organization/organizationActions';
import {
  organizationApiKeysLoadedSelector,
  organizationApiKeysSelector,
  organizationDateFormatSelector,
} from 'domain/organization/organizationSelector';
import { loadingSelector } from 'domain/env/envSelector';

import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import EmptyData from './components/EmptyData';
import Paper from '@mui/material/Paper';

import DialogManageKeys from './components/DialogManageKeys';
import KeysList from './components/KeysList';
import CircularProgressWithBackdrop from 'components/mui/CircularProgressWithBackdrop';

const mapStateToProps = (state) => ({
  apiKeys: organizationApiKeysSelector(state),
  loading: loadingSelector(state),
  apiKeysLoaded: organizationApiKeysLoadedSelector(state),
  dateFormat: organizationDateFormatSelector(state),
});

const Keys = () => {
  const dispatch = useDispatch();
  const confirm = useConfirm();
  const { formatMessage } = useIntl();

  const [isOpen, setIsOpen] = useState(false);
  const [selectedApiKey, setSelectedApiKey] = useState(null);

  const { apiKeys, loading, apiKeysLoaded, dateFormat } = useSelector(mapStateToProps);

  const openDialog = useCallback(() => setIsOpen(true), []);

  const closeDialog = useCallback(() => {
    setIsOpen(false);
    setSelectedApiKey(null);
  }, []);

  const handleEditClick = useCallback(
    (apiKey) => () => {
      setSelectedApiKey(apiKey);
      openDialog();
    },
    [openDialog],
  );

  const handleDeleteApiKey = useCallback(
    (apiKey) => {
      dispatch(deleteOrganizationApiKeyAction({ apiKey }));
    },
    [dispatch],
  );

  const onSubmit = useCallback(
    async (data) =>
      new Promise((resolve, reject) => {
        const payload = {
          resolve,
          reject,
          ...data,
        };
        const action = selectedApiKey ? updateOrganizationApiKeyAction : createOrganizationApiKeyAction;
        dispatch(action(payload));
      }).then(() => {
        closeDialog();
      }),
    [dispatch, closeDialog, selectedApiKey],
  );

  const handleDeleteApiKeyClick = useCallback(
    (apiKey) => () => {
      confirm({
        title: formatMessage({
          id: 'configurations.apiKeys.deleteModal.title',
          defaultMessage: 'Delete Key?',
        }),
        description: formatMessage({
          id: 'configurations.apiKeys.deleteModal.body',
          defaultMessage:
            'Are you sure you want to delete this Key? This action cannot be undone, and the key cannot be restored.',
        }),
        confirmationText: formatMessage({
          id: 'button.delete',
          defaultMessage: 'Delete',
        }),
        confirmationButtonProps: { color: 'error' },
      }).then(() => {
        handleDeleteApiKey(apiKey);
      });
    },
    [confirm, formatMessage, handleDeleteApiKey],
  );

  return (
    <>
      <CircularProgressWithBackdrop isOpen={loading} />
      <Stack mb={2}>
        <Button variant="outlined" type="button" onClick={openDialog} startIcon={<AddIcon />} sx={{ ml: 'auto' }}>
          {formatMessage({ id: 'button.create.key', defaultMessage: 'Create new key' })}
        </Button>
      </Stack>
      <Paper sx={{ flex: '1 1 0', overflowY: 'auto' }}>
        {apiKeys.size ? (
          <KeysList
            apiKeys={apiKeys}
            handleEditClick={handleEditClick}
            handleDeleteApiKeyClick={handleDeleteApiKeyClick}
            dateFormat={dateFormat}
          />
        ) : (
          <>{apiKeysLoaded && <EmptyData openDialog={openDialog} />}</>
        )}
        <DialogManageKeys
          open={isOpen}
          onCancel={closeDialog}
          onSubmit={onSubmit}
          selectedApiKey={selectedApiKey}
          dateFormat={dateFormat}
        />
      </Paper>
    </>
  );
};

export default Keys;
