/* @flow */
import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { Set } from 'immutable';

import { ControlLabel, Checkbox } from 'components/Tables/grid/SelectAllCheckbox/StyledComponents';

import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

type TSelectAllCheckbox = {|
  selectedRows: Set<number>,
  countAllRows: number,
  startRow: number,
  handleChange: (c: Set<number>) => void,
|};

const range = (start: number, end: number) => {
  if (end < start) return [];
  return start === end ? [start] : [start, ...range(start + 1, end)];
};

const SelectAllCheckbox: React$StatelessFunctionalComponent<TSelectAllCheckbox> = ({
  handleChange,
  startRow,
  countAllRows,
  selectedRows,
}) => {
  const checked = Boolean(selectedRows.size);
  const checkedAll = selectedRows.size === countAllRows;

  const onChange = useCallback(
    (e: SyntheticInputEvent<HTMLInputElement>) => {
      const newChecked = e.currentTarget.checked ? range(startRow + 1, startRow + countAllRows) : [];

      handleChange(Set(newChecked));
    },
    [handleChange, startRow, countAllRows],
  );

  return (
    <ControlLabel
      disableTypography
      checked={checked}
      onChange={onChange}
      control={<Checkbox size="small" checkedIcon={checkedAll ? <CheckBoxIcon /> : <IndeterminateCheckBoxIcon />} />}
      label={<FormattedMessage id="document.show.journalEntry.selectAll" defaultMessage="Select All" />}
    />
  );
};

export default SelectAllCheckbox;
