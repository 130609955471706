// @flow
import React, { useState, lazy, Suspense, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getJournalEntryForGridAction, jeNameSelector } from 'domain/journal';
import { useIntl } from 'react-intl';
import { storage } from 'lib/storage';
import type { TViewArrangement } from 'pages/document/types.js.flow';

import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import LinearProgress from '@mui/material/LinearProgress';
import CircularProgress from '@mui/material/CircularProgress';
import { JETab, JETabPane } from 'pages/document/Body/JE/JETabs/StyledCompoents';

const JEGrid = lazy(() => import('pages/document/Body/JE/JEGrid'));
const JEInsights = lazy(() => import('pages/document/Body/JE/JEInsights'));

type TJETabs = {
  viewArrangement: TViewArrangement,
};
type TJETabTypes = 'je' | 'insights';

const mapStateToProps = (state) => ({
  jeName: jeNameSelector(state),
});

const JETabs: React$StatelessFunctionalComponent<TJETabs> = ({ viewArrangement }) => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const { jeName } = useSelector(mapStateToProps);
  const { documentId: documentID } = useParams();
  const insightsIsActive = JSON.parse(storage().insightsIsActive().get());
  const [tab, setTab] = useState<TJETabTypes>(insightsIsActive ? 'insights' : 'je');
  const [isBusy, setIsBusy] = useState(false);

  const getMainJE = useCallback(() => {
    setIsBusy(true);

    new Promise((resolve, reject) => {
      dispatch(getJournalEntryForGridAction({ documentID, resolve, reject, readonly: false }));
    }).finally(() => {
      setIsBusy(false);
    });
  }, [documentID, dispatch]);

  const handleChange = useCallback(
    (event, tabKey) => {
      setTab(tabKey);

      storage()
        .insightsIsActive()
        .set(tabKey === 'insights');

      if (tabKey === 'je') {
        getMainJE();
      }
    },
    [getMainJE],
  );

  return (
    <>
      <TabContext value={tab}>
        <TabList onChange={handleChange} variant="fullWidth">
          <JETab
            value="je"
            {...(isBusy
              ? {
                  icon: <CircularProgress size={20} />,
                  iconPosition: 'start',
                }
              : {
                  label: jeName,
                })}
            disabled={isBusy}
          />
          <JETab
            label={formatMessage({ id: 'document.je.tabs.insights', defaultMessage: 'Insights' })}
            value="insights"
            disabled={isBusy}
          />
        </TabList>
        <JETabPane value="je">
          <Suspense fallback={<LinearProgress />}>
            <JEGrid viewArrangement={viewArrangement} />
          </Suspense>
        </JETabPane>
        <JETabPane value="insights" isInsightsTab>
          <Suspense fallback={<LinearProgress />}>
            <JEInsights />
          </Suspense>
        </JETabPane>
      </TabContext>
    </>
  );
};

export default JETabs;
