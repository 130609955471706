/* @flow */
import * as React from 'react';
import { compose, type Dispatch } from 'redux';
import { connect } from 'react-redux';
import { navigate } from 'domain/router/redux/reduxActions';
import { type RecordOf } from 'immutable';
import { isEqual } from 'lodash';
import {
  eventsListSelector,
  nextNumPageSelector,
  getEventsAction,
  getCountOfNewEventsAction,
  newEventsCounterSelector,
  toggleEventPanelAction,
  isOpenedSelector,
  exportHistoryEventsAction,
  type EventsListType,
  type EventsItemType,
  type DocumentPreviewType,
  type EventsItemShapeType,
} from 'domain/events';
import { documentHideLinkedDocs, documentLinkedOpenStatusSelector, setLinkingTagAction } from 'domain/documents';
import { saveBackSearchUrlAction } from 'domain/env';
import { querySelector } from 'domain/router';
import { setActiveChannelAction, setActiveChannelForceAction, setOpenAction } from 'domain/chat';
import ROUTES_PATH from 'domain/router/routesPathConfig';
import { generatePath } from 'react-router-dom';
import { bodyToggler } from 'lib/domHelpers';
import { promisify, simulateMouseClick } from 'lib/helpers';
import makeCancelable, { type PromiseCanceleable } from 'lib/makeCancelable';
import { getDocumentUrl, getPreviewSrc, getRotationAngle } from '../workSpace/helpers';
import { formatDate } from 'lib/dateTime';
import { hotkeyMatcher } from 'lib/hotkeysHelpers';

import EventsHistoryDrawer, {
  EVENTS_HISTORY_DRAWER_WIDTH,
} from 'components/mui/Layouts/components/EventsHistoryPanel/components/EventsHistoryDrawer';
import EventsHistoryFiltersBlock from 'components/mui/Layouts/components/EventsHistoryPanel/components/FiltersBlock';
import EventsHistoryList from 'components/mui/Layouts/components/EventsHistoryPanel/components/EventsList';
import DocumentPreview from 'pages/company/DocumentPreview';
import { workspaceHotkeys } from '../DialogKeyboardHotkeys/hotkeys';

import type { TDocumentsMap } from 'domain/documents/types.js.flow';

type Props = {
  list: EventsListType,
  dokkaToken: string,
  match: {
    params: {
      companyId: string,
    },
  },
  documentsById: TDocumentsMap,
  nextNumPage: ?string,
  counter: number,
  company: ?{ dateFormat: string },
  isConfidential: boolean,
  isOpened: boolean,
  setNavigation: (id: string, l?: boolean) => void,
  getEvents: (d: { maxResult: number, page: ?string }) => void,
  onShowLinked: (tag: string) => Promise<*>,
  setActiveChannel: (d: { threadId: string, companyId: string }) => void,
  setActiveChannelForce: (d: { threadId: string, companyId: string }) => void,
  setOpenChat: (p: boolean) => void,
  navigatePush: (l: string) => void,
  getCountOfNewEvents: () => void,
  toggleEventPanel: () => void,
  saveBackSearchUrl: Dispatch<saveBackSearchUrlAction>,
  exportHistoryEvents: Dispatch<exportHistoryEventsAction>,
  hideLinkedDocs: Dispatch<documentHideLinkedDocs>,
  setLinkingTag: Dispatch<setLinkingTagAction>,
  query: any,
  isOpenLinkedPanel: boolean,
};

type State = {
  preview: ?string,
  timestampFrom: ?Date,
  timestampTo: ?Date,
  initialSearchTypes: Array<string>,
};

function filter(pattern: boolean) {
  return (f: RecordOf<EventsItemShapeType>) => {
    if (f.link && f.link.type === 'linked_document') {
      // $FlowFixMe
      return f.link.confidential === pattern;
    }
    return true;
  };
}

class EventsHistoryPanel extends React.Component<Props, State> {
  _event: ?RecordOf<EventsItemType<RecordOf<DocumentPreviewType>>>;

  cancelable: PromiseCanceleable<*>;

  constructor(props) {
    super(props);

    this.state = {
      preview: null,
      filters: {
        usersSearch: [],
        typesSearch: [],
        textsSearch: [],
        freeTextSearch: [],
      },
      timestampFrom: null,
      timestampTo: null,
      initialSearchTypes: [],
    };
  }

  componentDidMount() {
    window.addEventListener('keydown', this.handleKeydown);
  }

  componentDidUpdate(prevProps: Props): * {
    const { isOpened, getCountOfNewEvents } = this.props;
    if (isOpened && isOpened !== prevProps.isOpened) {
      getCountOfNewEvents();
    }
  }

  componentWillUnmount() {
    if (this.cancelable) {
      this.cancelable.cancel();
    }
    window.removeEventListener('keydown', this.handleKeydown);
  }

  handleShortcuts = (keys: Array<string>) => {
    const { toggleEventPanel, hideLinkedDocs, setLinkingTag, isOpenLinkedPanel } = this.props;
    if (isEqual(keys.toJS(), ['Control', 'KeyE'])) {
      // we need to close all modals and dropdowns
      const modalElement =
        document.getElementsByClassName('MuiModal-root')[0]?.getElementsByClassName('MuiBackdrop-root')[0] ||
        document.getElementById('root');

      simulateMouseClick(modalElement);

      if (isOpenLinkedPanel) {
        hideLinkedDocs();
        setLinkingTag('');
      }
      this.setState({ initialSearchTypes: ['upload_existing_document'] });
      toggleEventPanel();
    }
  };

  handleKeydown = (e: KeyboardEvent) => {
    const isMatch = hotkeyMatcher(e, workspaceHotkeys, this.handleShortcuts);
    if (isMatch) {
      e.preventDefault();
    }
  };

  get documentItem() {
    const { preview } = this.state;
    const { documentsById } = this.props;
    // $FlowFixMe
    return documentsById.get(preview);
  }

  get dateFormat(): string {
    const { company } = this.props;
    if (company && typeof company.dateFormat === 'string') return company.dateFormat;
    return 'DD/MM/YYYY';
  }

  get list() {
    const { list, isConfidential } = this.props;
    return list.filter(filter(isConfidential));
  }

  get preview(): RecordOf<EventsItemType<RecordOf<DocumentPreviewType>>> {
    if (this._event) {
      return this._event;
    }
    throw new TypeError('Incorrect preview event type');
  }

  set preview(event: RecordOf<EventsItemType<RecordOf<DocumentPreviewType>>>) {
    this._event = event;
    this.handlePreview(event.id);
  }

  get getDocumentUrl() {
    const { match } = this.props;
    const { documentID } = this.preview.link;
    return getDocumentUrl(match.params.companyId, documentID);
  }

  get getPreviewSrc() {
    const { documentID } = this.preview.link;
    const { company, dokkaToken } = this.props;
    return getPreviewSrc(company.id, documentID, dokkaToken, true, true);
  }

  toggleOpen = () => {
    const { isOpened, getCountOfNewEvents, toggleEventPanel } = this.props;

    if (!isOpened) getCountOfNewEvents();

    toggleEventPanel();

    return new Promise((resolve) => {
      this.setState(
        {
          preview: null,
          timestampFrom: null,
          timestampTo: null,
          initialSearchTypes: [],
          filters: {
            usersSearch: [],
            typesSearch: [],
            textsSearch: [],
            freeTextSearch: [],
          },
        },
        () => {
          bodyToggler(isOpened);
          resolve();
        },
      );
    });
  };

  handlePreview = (eventID: ?string) => {
    this.setState({
      preview: eventID,
    });
  };

  handleOpenDocument = () => {
    const { setNavigation } = this.props;
    const { preview } = this.state;

    this.toggleOpen();
    if (preview) {
      setNavigation(preview, false, true);
    }
  };

  handleEventAction = async (d) => {
    const {
      match,
      query,
      navigatePush,
      onShowLinked,
      setActiveChannel,
      setOpenChat,
      saveBackSearchUrl,
      setActiveChannelForce,
    } = this.props;

    const { link } = d;
    switch (link.type) {
      case 'document':
        this.preview = d;
        break;
      case 'linked_document':
        await this.toggleOpen();
        navigatePush(
          generatePath(ROUTES_PATH.COMPANY_WORKSPACE.absolute, {
            companyId: match.params.companyId,
            category1: link.rootCategory,
          }),
        );

        setTimeout(() => {
          onShowLinked(link.linkID);
        }, 500);

        break;
      case 'company_chat':
        await this.toggleOpen();
        setOpenChat(true);
        setActiveChannel({
          threadId: link.channel_name,
          companyId: link.companyID,
        });
        break;
      case 'document_chat':
        await this.toggleOpen();
        saveBackSearchUrl({ params: match.params, query });
        setActiveChannelForce({
          threadId: link.channel_name,
          companyId: link.companyID,
        });
        navigatePush(
          generatePath(ROUTES_PATH.DOCUMENT.absolute, {
            companyId: link.companyID,
            documentId: link.documentID,
          }),
        );
        break;
      default:
        break;
    }
  };

  handleLoadEvents = (nextNumPage: ?string, clear?: boolean) => {
    const { getEvents } = this.props;
    const { timestampFrom, timestampTo, filters, initialSearchTypes } = this.state;

    if (!initialSearchTypes.length) {
      this.cancelable = makeCancelable(
        promisify(getEvents, {
          maxResult: 10,
          page: nextNumPage,
          timestampFrom: formatDate(timestampFrom),
          timestampTo: formatDate(timestampTo),
          ...filters,
          clear,
        }),
      );
    }
  };

  handleGetNextPage = () => {
    const { nextNumPage } = this.props;
    this.handleLoadEvents(nextNumPage, false);
  };

  handleSetDate = (from, to) => {
    this.setState(
      {
        timestampFrom: from,
        timestampTo: to,
      },
      () => {
        this.handleLoadEvents(null, true);
      },
    );
  };

  handleExportEvents = () => {
    const { timestampFrom, timestampTo, filters } = this.state;
    const {
      exportHistoryEvents,
      company: { id },
    } = this.props;

    exportHistoryEvents({
      companyId: id,
      timestampFrom: formatDate(timestampFrom),
      timestampTo: formatDate(timestampTo),
      ...filters,
    });
  };

  onChangeFilters = (filters) => {
    this.setState({ filters, initialSearchTypes: [] }, () => {
      this.handleLoadEvents(null, true);
    });
  };

  render() {
    const { list, isOpened, nextNumPage, counter } = this.props;
    const { preview, initialSearchTypes } = this.state;

    return (
      <EventsHistoryDrawer toggleOpen={this.toggleOpen} isOpened={isOpened}>
        <EventsHistoryFiltersBlock
          onChangeFilters={this.onChangeFilters}
          onChangeDate={this.handleSetDate}
          onClickExport={this.handleExportEvents}
          dateFormat={this.dateFormat}
          isDisabledExport={this.list.size === 0}
          initialSearchTypes={initialSearchTypes}
        />

        <EventsHistoryList
          list={this.list}
          onAction={this.handleEventAction}
          getNextEvents={this.handleGetNextPage}
          isNext={nextNumPage}
          onReload={() => this.handleLoadEvents(null, false)}
          counter={counter}
          dateFormat={this.dateFormat}
        />

        {typeof preview === 'string' && list.size ? (
          <DocumentPreview
            documentID={preview}
            src={this.getPreviewSrc}
            to={this.getDocumentUrl}
            rotationAngle={getRotationAngle(this.documentItem)}
            onClick={this.handleOpenDocument}
            close={() => this.handlePreview(null)}
            disabled={!this.preview.getIn(['link', 'exist'], true)}
            xGap={EVENTS_HISTORY_DRAWER_WIDTH}
          />
        ) : null}
      </EventsHistoryDrawer>
    );
  }
}

const mapStateToProps = (state) => ({
  list: eventsListSelector(state),
  nextNumPage: nextNumPageSelector(state),
  counter: newEventsCounterSelector(state),
  isOpened: isOpenedSelector(state),
  query: querySelector(state),
  isOpenLinkedPanel: documentLinkedOpenStatusSelector(state),
});

const mapDispatchToProps = {
  toggleEventPanel: toggleEventPanelAction,
  getEvents: getEventsAction,
  setActiveChannel: setActiveChannelAction,
  setActiveChannelForce: setActiveChannelForceAction,
  setOpenChat: setOpenAction,
  getCountOfNewEvents: getCountOfNewEventsAction,
  navigatePush: navigate.push,
  saveBackSearchUrl: saveBackSearchUrlAction,
  exportHistoryEvents: exportHistoryEventsAction,
  hideLinkedDocs: documentHideLinkedDocs,
  setLinkingTag: setLinkingTagAction,
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(EventsHistoryPanel);
