// @flow
import React, { memo, useCallback } from 'react';
import type { TIndexListItemRecords } from 'domain/journal/types.js.flow';
import { type FormProps } from 'redux-form/immutable';

import Form from './form';
import { FormattedMessage } from 'react-intl';
import Dialog from 'components/mui/Dialog';
import DialogContent from '@mui/material/DialogContent';
import CircularProgressWithBackdrop from 'components/mui/CircularProgressWithBackdrop';

type Props = {
  cancel: () => void,
  indexListItemData: TIndexListItemRecords,
  isLoading: boolean,
  isEdit: boolean,
  onSubmit: $PropertyType<FormProps, 'onSubmit'>,
  initialValues: $PropertyType<FormProps, 'initialValues'>,
  onSearchHandler: SyntheticInputEvent<HTMLIFrameElement>,
};

const DialogManageJECellListOption: React$StatelessFunctionalComponent<Props> = ({
  indexListItemData,
  cancel,
  onSubmit,
  initialValues,
  isLoading,
  isEdit,
  onSearchHandler,
}: Props) => {
  // this is necessary to switch between fields using the TAB button
  const onKeyDown = useCallback((e) => {
    e.stopPropagation();
  }, []);

  return (
    <Dialog
      onKeyDown={onKeyDown}
      open
      onClose={cancel}
      maxWidth="sm"
      withCloseBtn
      draggable
      withContent={false}
      withActions={false}
      title={
        isEdit ? (
          <FormattedMessage id="modal.document.edit.supplier.title" defaultMessage="Edit" />
        ) : (
          <FormattedMessage id="modal.document.create.supplier.title" defaultMessage="Create" />
        )
      }
    >
      {isLoading ? (
        <DialogContent sx={{ position: 'relative', minHeight: 300 }}>
          <CircularProgressWithBackdrop
            BackdropProps={{
              sx: {
                position: 'absolute',
                backgroundColor: 'transparent',
                color: 'primary.main',
              },
            }}
          />
        </DialogContent>
      ) : (
        <DialogContent sx={{ pb: 0 }}>
          <Form
            fields={indexListItemData}
            onSubmit={onSubmit}
            initialValues={initialValues}
            cancel={cancel}
            onSearchHandler={onSearchHandler}
          />
        </DialogContent>
      )}
    </Dialog>
  );
};

export default memo(DialogManageJECellListOption);
