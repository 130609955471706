// @flow
import React, { Component } from 'react';
import last from 'lodash/last';

// redux
import { compose } from 'redux';
import { connect } from 'react-redux';
// $FlowFixMe untyped module
import { rtlEnable } from 'domain/env';

// components
import { GridTable as Grid, withSelectedRows } from 'components/Tables/grid';
import withRowMessages from 'components/Tables/layout/virtualGrid/withRowMessages';

// types
import type { JornalEntryType, RecordsCellType } from 'domain/journal/helper';

import type { MetaType } from 'components/Tables/types.js.flow';
import type { RowMessage } from 'domain/journal/types.js.flow';

// helpers
import { getMetaRange } from 'components/Tables/helpers';

// jss
import cx from 'classnames';
// $FlowFixMe untyped module
import { withStyles } from '@mui/styles';
import sheet from './searchResultsSheet';

// new component
import { getDataCell } from 'components/Tables/layout/helpers';
import { List, Set } from 'immutable';

type Props = {|
  classes: typeof sheet,
  data: JornalEntryType,
  rtl: boolean,
  selectedRows: Set<number>,
  rowMessages: List<RowMessage>,
  disableCheckboxes: boolean,
  getItem: (d: RecordsCellType | void) => React$Node,
  onChangeSelectedRows: (c: Set<number>) => void,
|};

const SelectableSearchResults = compose(withRowMessages, withSelectedRows)(Grid);

class SearchResults extends Component<Props> {
  // returns table representation as tuple. eg [['A', 'B', 'C'], [5, 6, 7, 8, 9]]
  get meta(): MetaType {
    const { data, rtl } = this.props;
    return getMetaRange(data, rtl);
  }

  // returns first row number
  get startingRow(): number {
    return this.meta[1][0];
  }

  // returns first column letter
  get selectableColumn(): string {
    return this.meta[0][0];
  }

  // returns number of selectable rows. Substracting 1 header line
  get selectableRowsCount(): number {
    return this.meta[1].length - 1;
  }

  getData = (row: number, col: string) => getDataCell(this.props.data, row, col);

  getItem = (row: number, col: string) => this.props.getItem(this.getData(row, col));

  getRowStyle = () => {
    const { classes } = this.props;
    return cx(classes.row, classes.lineItems);
  };

  getCollStyle = () => {
    const {
      classes: { col },
    } = this.props;
    return col;
  };

  render() {
    const { classes, disableCheckboxes } = this.props;
    return (
      <SelectableSearchResults
        className={cx(classes.container, 'search-scroll-box')}
        classes={{}}
        markFirstRowWithClass
        active={null}
        meta={this.meta}
        getItem={this.getItem}
        prevItem={() => null}
        onChangeSelectedRows={this.props.onChangeSelectedRows}
        getRowStyle={this.getRowStyle}
        getCollStyle={this.getCollStyle}
        selectedRows={this.props.selectedRows}
        selectPlaceCol={this.selectableColumn}
        startRow={this.startingRow}
        countAllRows={this.selectableRowsCount}
        readOnly={false}
        disableCheckboxes={disableCheckboxes}
        rowMessages={this.props.rowMessages}
        rowMessagesRelativeCol={last(this.meta[0])}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  rtl: rtlEnable(state),
});

export default compose(connect(mapStateToProps), withStyles(sheet))(SearchResults);
