import { asyncAction, syncAction } from 'lib/action';

export const fetchCompanies = asyncAction('COMPANIES/GET_COMPANIES');
export const fetchCompany = asyncAction('COMPANIES/GET_COMPANY');
export const deleteCompany = asyncAction('COMPANIES/DELETE_COMPANY');
export const updateLogoCompany = asyncAction('COMPANIES/UPDATE_LOGO_COMPANY');
export const removeCompanySigner = asyncAction('COMPANIES/REMOVE_COMPANY_SIGNER');
export const getCompanySignings = asyncAction('COMPANIES/GET_COMPANY_SIGNINGS');
export const getCompanySigningsCopy = asyncAction('COMPANIES/GET_COMPANY_SIGNINGS_COPY');
export const setCompanySigningsGuid = asyncAction('COMPANIES/SET_COMPANY_SIGNINGS_GUID');
export const updateCompanySignings = asyncAction('COMPANIES/UPDATE_COMPANY_SIGNINGS');
export const removeAllCompanySigner = asyncAction('COMPANIES/REMOVE_ALL_COMPANY_SIGNER');
export const addEmptyCompanySigner = syncAction('COMPANIES/ADD_EMPTY_COMPANY_SIGNER');
export const updateCompanyEmailBox = syncAction('COMPANIES/UPDATE_COMPANY_EMAIL_BOX');
export const updateCompany = asyncAction('COMPANIES/UPDATE_COMPANY');
export const updateCompanyInList = asyncAction('COMPANIES/UPDATE_COMPANY_IN_LIST');
export const setCompanyFeatureSet = asyncAction('COMPANIES/SET_COMPANY_FEATURE_SET');
export const getCompanyFeatureSet = asyncAction('COMPANIES/GET_COMPANY_FEATURE_SET');
export const createCompanyAction = asyncAction('COMPANIES/CREATE');
export const markCompanyPinned = asyncAction('COMPANIES/PIN', (data) => data);
export const setSearchFilterAction = asyncAction('COMPANIES/SET_SEARCH_FILTER');
export const setSearchTypeAction = syncAction('COMPANIES/SET_SEARCH_TYPE');
export const updateSignParams = asyncAction('COMPANIES/UPDATE_SIGN_PARAMS');
export const setSortCompanies = syncAction('COMPANIES/SET_SORT');
export const updateGeneralCompanyParams = asyncAction('COMPANIES/UPDATE_GENERAL_COMPANY_PARAMS');
export const companySignInAction = asyncAction('COMPANIES/SIGN_IN');
export const updateCompanyHashAction = syncAction('COMPANIES/UPDATE_COMPANY_HASH');
export const goBackToListAction = syncAction('COMPANIES/GO_BACK_TO_LIST');
export const resetConfigurationAction = syncAction('COMPANIES/RESET_CONFIGURATION');
export const getCompanySlackConnectionAction = asyncAction('COMPANIES/GET_COMPANY_SLACK_CONNECTION');
export const connectSlackAction = asyncAction('COMPANIES/CONNECT_SLACK');
export const disconnectSlackAction = asyncAction('COMPANIES/DISCONNECT_SLACK');
export const getAllOrganizationCompanies = asyncAction('COMPANIES/GET_ALL_ORGANIZATION_COMPNAIES');
