// @flow
import CardMui from '@mui/material/Card';
import CardActionsMui from '@mui/material/CardActions';
import CardActionArea from '@mui/material/CardActionArea';
import Rating from '@mui/material/Rating';
import cornerSVG from './corner.svg';
import cornerRtlSVG from './corner-rtl.svg';

import { styled } from '@mui/material/styles';

export const TILE_Z_INDEXES = {
  cardNote: 1,
  isWarning: 2, // overlap card note
  linkedBadge: 2,
  copyBadge: 2,
  multiPageCorner: 2,
  userTagAvatars: 2,
  previewEye: 3, // overlap all elements
};

export const THUMBNAIL_H = 194;

export const Tile = styled(CardMui, {
  shouldForwardProp: (propName) => propName !== 'selected',
  label: 'Tile',
})(({ theme, selected }) => ({
  position: 'relative',
  backgroundColor: theme.palette.background.paper,
  border: `1px solid ${theme.palette.grey[200]}`,
  ...(selected && { borderColor: theme.palette.primary.main, outline: `2px solid ${theme.palette.primary.main}` }),
}));

export const TileActionArea = styled(CardActionArea, {
  label: 'TileActionArea',
  shouldForwardProp: (propName) => propName !== 'isWarning',
})(({ theme, isWarning }) => ({
  '&:before': {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    width: 5,
    content: '""',
    backgroundColor: theme.palette.error.main,
    visibility: isWarning ? 'visible' : 'hidden',
    zIndex: TILE_Z_INDEXES.isWarning, // for overlap image
  },

  '& .MuiCardActionArea-focusHighlight': {
    backgroundColor: 'transparent',
    opacity: 0.5,
  },

  '&:hover .MuiCardActionArea-focusHighlight': {
    opacity: 1,
  },
}));

export const TileActions = styled(CardActionsMui, { label: 'TileActions' })(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  height: 44,
  borderTop: `1px solid ${theme.palette.grey[200]}`,
}));

export const MultipageCorner = styled('div', { label: 'MultipageCorner' })(({ theme }) => ({
  backgroundImage: `url(${theme.direction === 'rtl' ? cornerRtlSVG : cornerSVG})`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'contain',
  position: 'absolute',
  top: -2,
  right: -2,
  width: 90,
  height: '100%',
}));

export const FavoriteIcon = styled(Rating, { label: 'FavoriteIcon' })(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(1),
  left: theme.spacing(1),
}));
