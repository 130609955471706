/* @flow */
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import cx from 'classnames';
import elements from 'components/elements';

type Props = {
  classes: {
    [key: string]: string,
  },
  linkedId: string,
  onChange: ({ text: string, linkID: string }) => void,
  text: string,
  isPanelLocked: boolean,
};

type State = {
  text: string,
  isEdit: boolean,
};

class LinkedText extends React.Component<Props, State> {
  state = {
    text: this.props.text,
    isEdit: false,
  };

  componentDidUpdate() {
    const { isEdit } = this.state;
    if (isEdit) {
      this.setFocus();
    }
  }

  onEnterEditMode = () => {
    if (!this.props.isPanelLocked) this.setState({ isEdit: true });
  };

  onLeaveEditMode = () => {
    this.setState({
      isEdit: false,
    });
  };

  setFocus = () => {
    if (this.textarea && typeof this.textarea.focus === 'function') {
      this.textarea.focus();
    }
  };

  handleChange = (event: SyntheticInputEvent<HTMLInputElement>) => {
    const {
      currentTarget: { value: text },
    } = event;

    this.setState({ text }, () => {
      this.props.onChange({ text, linkID: this.props.linkedId });
    });
  };

  textarea: ?HTMLElement;

  render() {
    const { classes, isPanelLocked } = this.props;
    const { text, isEdit } = this.state;
    return (
      <div className={classes.header}>
        <div className={classes.aspectRatioWrapper} data-element={elements.content.linkedPanel.linkedText}>
          {isEdit ? (
            <textarea
              name="linkingText"
              cols="30"
              rows="10"
              ref={(el) => {
                this.textarea = el;
              }}
              className={classes.linkedTextArea}
              value={text}
              onChange={this.handleChange}
              onBlur={this.onLeaveEditMode}
            />
          ) : (
            <button
              className={cx(classes.linkedTextButton, {
                [classes.notEmpty]: text.length > 0,
              })}
              onClick={this.onEnterEditMode}
              disabled={isPanelLocked}
            >
              {text.length > 0 ? (
                text
              ) : (
                <FormattedMessage
                  id="documents.linkedPanel.defaultLinkingText"
                  defaultMessage="Add title and info about linked documents"
                />
              )}
            </button>
          )}
        </div>
      </div>
    );
  }
}

export default LinkedText;
