// @flow
import sheet from './searchResultsSheet';

export default (theme) => ({
  ...sheet(theme),
  lineItems: {
    '&:not(:first-child)': {
      '&$hovered, &:hover, &$activeRow': {
        backgroundColor: 'rgba(108, 152, 216, 0.1)',
        '& $removeBtn': {
          opacity: 1,
        },
      },
    },
  },
  row: {
    '&:first-child': {
      '& $col': {
        backgroundColor: theme.colors.greyLighter,
        borderTop: '1px solid #DEE2E6',
        fontWeight: 'bold', // backend styles are removed, so make header bold on our side
      },
    },
    '&:not(:first-child) $col:hover': {
      '&:hover': {
        backgroundColor: '#F8F8F8',
      },
    },
  },
  deletionDisabled: {
    '& $lineItems td:first-child': {
      backgroundColor: '#fff',
    },
  },
});
