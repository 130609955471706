import { styled } from '@mui/material/styles';
import MuiMenu from '@mui/material/Menu';
import MuiListItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';

export const Menu = styled(MuiMenu)(({ theme }) => ({
  '& .MuiPaper-root': { borderRadius: theme.shape.borderRadius * 2 },
  '& .MuiList-root': { padding: theme.spacing(2, 0) },
}));

export const MenuItem = styled(MuiListItem)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  padding: theme.spacing(2, 3),
}));

export const IconWrapper = styled(Box)(() => ({
  display: 'flex',
  fontSize: 65,
  margin: 'auto',
}));
