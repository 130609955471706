import { useContext } from 'react';
import { companiesByIdSelector, CompanyFactory } from 'domain/companies';
import { currentConnectedERPCompanyName } from 'domain/settings';
import { organizationWhatsappPhoneSelector } from 'domain/organization';
import { currentCompanySelector } from 'domain/documents';
import * as ACL from 'domain/restriction';
import { useSelector } from 'react-redux';
import CompanyContext from 'pages/company/CompanyContext';

const mapStateToProps = (state) => ({
  companiesByID: companiesByIdSelector(state),
  erpCompanyName: currentConnectedERPCompanyName(state),
  orgWhatsappPhone: organizationWhatsappPhoneSelector(state),
  isGranted: ACL.isGranted(state),
  companyId: currentCompanySelector(state),
});

const useAppbarCompanyLogo = () => {
  const { companyType } = useContext(CompanyContext);
  const { companiesByID, erpCompanyName, orgWhatsappPhone, isGranted, companyId } = useSelector(mapStateToProps);
  const company = companiesByID.get(companyId, CompanyFactory());
  const title = [company.cname];
  const email = companyType === 'confidential' ? company['email.confidential'] : company.email;
  const phone = company.whatsappPhone || orgWhatsappPhone;

  if (erpCompanyName) {
    title.push(`(${erpCompanyName})`);
  }

  return {
    name: title.join(' '),
    email,
    phone,
    src: company.logo,
    isAllowPhoneLink: !isGranted(ACL.IS_ACCOUNT),
  };
};

export default useAppbarCompanyLogo;
