// @flow
import Paper from '@mui/material/Paper';

import { styled } from '@mui/material/styles';

export const SelectPanelContainer = styled(Paper, { shouldForwardProp: (propName) => propName !== 'isVisible' })(
  ({ theme, isVisible }) => ({
    position: 'fixed',
    bottom: 30,
    left: 0,
    right: 0,
    margin: 'auto',
    width: 'max-content',
    display: isVisible ? 'flex' : 'none',
    zIndex: theme.zIndex.modal,
    flexDirection: 'row',
    borderRadius: 8,
    gap: 8,
  }),
);
