// @flow
import { createContext } from 'react';

export type TArcherContainerContext = {
  arrowMarkerUniquePrefix: string,
  refreshScreen: () => void,
};

const ArcherContainerContext: TArcherContainerContext = createContext({
  arrowMarkerUniquePrefix: '',
  refreshScreen: () => {},
});

export default ArcherContainerContext;
