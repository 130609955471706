// @flow
import React, { memo } from 'react';
import { compose } from 'redux';
import { Field, reduxForm, type FormProps } from 'redux-form/immutable';
import { ftpConnectionValidate } from 'components/ERPSettings/FTPConnection/FTPConnectionForm/validation';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import TextField from 'components/mui/Form/TextField/TextFieldForm';
import { FormattedMessage, useIntl } from 'react-intl';
import Grid from '@mui/material/Grid';

type TFTPConnectionForm = {
  onClose: () => void,
} & FormProps;

const FTPConnectionForm: React$StatelessFunctionalComponent<TFTPConnectionForm> = ({
  handleSubmit,
  submitting,
  invalid,
  onClose,
}) => {
  const { formatMessage } = useIntl();
  // event bubbling, on click submits main erp settings form, so event bubbling is need to be stopped
  const onSubmit = (e: SyntheticEvent<HTMLFormElement>) => {
    e.stopPropagation();

    return handleSubmit(e);
  };

  return (
    <>
      <DialogTitle sx={{ textAlign: 'center' }}>
        {formatMessage({ id: 'modal.erp.ftpConnection.title', defaultMessage: 'FTP connection' })}
      </DialogTitle>
      <DialogContent sx={(theme) => ({ pt: `${theme.spacing(1)}!important` })}>
        <form id="ftp-connect-form" onSubmit={onSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Field
                fullWidth
                name="address"
                component={TextField}
                type="text"
                label={formatMessage({ id: 'labels.modal.ftpConnection.address', defaultMessage: 'FTP Address' })}
                required
                autoComplete="new-address"
              />
            </Grid>
            <Grid item xs={6}>
              <Field
                fullWidth
                name="login"
                component={TextField}
                type="text"
                label={formatMessage({ id: 'labels.modal.ftpConnection.login', defaultMessage: 'Login' })}
                required
                autoComplete="new-login"
              />
            </Grid>
            <Grid item xs={6}>
              <Field
                fullWidth
                name="password"
                component={TextField}
                type="password"
                label={formatMessage({ id: 'labels.modal.ftpConnection.password', defaultMessage: 'Password' })}
                required
                autoComplete="new-password"
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="text">
          <FormattedMessage id="button.cancel" defaultMessage="Cancel" />
        </Button>
        <Button type="submit" disabled={submitting || invalid} form="ftp-connect-form">
          {formatMessage({ id: 'button.connect', defaultMessage: 'Connect' })}
        </Button>
      </DialogActions>
    </>
  );
};

export default compose(reduxForm({ form: 'FTPConnectionForm', validate: ftpConnectionValidate }))(
  memo(FTPConnectionForm),
);
