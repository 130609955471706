// @flow
import { styled } from '@mui/material/styles';

import CardActionAreaMui from '@mui/material/CardActionArea';
import CardActionsMui from '@mui/material/CardActions';

export const CardActionArea = styled(CardActionAreaMui)(({ theme }) => ({
  padding: theme.spacing(1, 0),
}));

export const CardActions = styled(CardActionsMui)(({ theme }) => ({
  textAlign: 'center',
  justifyContent: 'center',
  borderTop: `1px solid ${theme.palette.grey[300]}`,
}));
