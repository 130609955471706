// @flow
import React from 'react';
import useToggle from 'hooks/useToggle';
import { LayoutCompanyContext } from 'components/mui/Layouts/Company/LayoutCompanyContext';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { DrawerHeader, DrawerToggle, DrawerToggleIcon } from 'components/mui/Layouts/components/Drawer';
import { LayoutRoot, LayoutContent } from 'components/mui/Layouts/components/Layout';
import { AppBarExpanded, AppBarItemDivider } from 'components/mui/Layouts/components/AppBar';
import LayoutCompanyDrawer from 'components/mui/Layouts/Company/components/Drawer';
import AppbarLogoCompany from 'components/mui/Layouts/components/AppbarLogoCompany';
import LocaleSelect from 'components/mui/LocaleSelect';
import AccountDropdown from 'components/mui/Layouts/components/AccountDropdown';
import AppbarSearch from 'components/mui/Layouts/Company/components/Search';
import ChatIconButton from 'components/mui/Layouts/components/Chat/ChatIconButton';
import EventsHistoryIconButton from 'components/mui/Layouts/components/EventsHistoryPanel/EventsHistoryIconButton';
import FullscreenIconButton from 'components/mui/Layouts/components/FullscreenIconButton';
import HelpDropdown from '../components/HelpDropdown';
import ApplicationDropdown from 'components/mui/Layouts/components/ApplicationDropdown';

type TLayoutCompany = {
  children: React$Node,
  DrawerContent?: React$Node,
};

const LayoutCompany: React$StatelessFunctionalComponent<TLayoutCompany> = ({ children, DrawerContent }) => {
  const [toggle, onToggle] = useToggle(true);

  return (
    <LayoutRoot>
      <LayoutCompanyContext.Provider value={{ toggle, onToggle }}>
        <LayoutCompanyDrawer open={toggle} onToggle={onToggle}>
          {DrawerContent}
        </LayoutCompanyDrawer>
        <AppBarExpanded open={toggle}>
          <Box
            flexShrink={1}
            sx={{
              minWidth: 200,
              maxWidth: {
                xs: '33%',
                lg: '47%',
              },
            }}
          >
            <AppbarLogoCompany />
          </Box>
          <Box flexShrink={1} flexGrow={1} px={2} display="flex" justifyContent="center" minWidth={200}>
            <AppbarSearch />
          </Box>
          <Stack direction="row" alignItems="center" spacing={1} flexShrink={0} ml="auto">
            <FullscreenIconButton />
            <AppBarItemDivider />
            <EventsHistoryIconButton />
            <ChatIconButton />
            <HelpDropdown />
            <LocaleSelect flagOnly />
            <ApplicationDropdown />
            <AccountDropdown />
          </Stack>
          {/* placed here because Drawer has overflow: hidden */}
          <DrawerToggle color="primary" size="small" onClick={onToggle}>
            <DrawerToggleIcon open={toggle} />
          </DrawerToggle>
        </AppBarExpanded>

        <LayoutContent>
          <DrawerHeader />
          {children}
        </LayoutContent>
      </LayoutCompanyContext.Provider>
    </LayoutRoot>
  );
};

export default LayoutCompany;
