/* @flow */
import React, { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { ChipTextFactory } from 'components/mui/Layouts/components/EventsHistoryPanel/components/FiltersBlock/helpers';
import type { TChipText } from 'components/mui/Layouts/components/EventsHistoryPanel/components/FiltersBlock/types.js.flow';
import TextFieldBase from 'components/mui/Form/TextField/TextFieldBase';
import InputAdornment from '@mui/material/InputAdornment';
import Box from '@mui/material/Box';

import SearchIcon from '@mui/icons-material/Search';
import elements from 'components/elements';

type TEventsHistoryTextFilter = {
  handleAdd: (chip: TChipText) => void,
  handleChangeText: (value: string) => void,
};

const EventsHistoryTextFilter: React$StatelessFunctionalComponent<TEventsHistoryTextFilter> = ({
  handleAdd,
  handleChangeText,
}) => {
  const { formatMessage } = useIntl();
  const [currentValue, setCurrentValue] = useState('');

  const onChange = (e) => {
    const { value } = e.target;

    setCurrentValue(value);
  };

  const onKeyDown = useCallback(
    (e) => {
      const { keyCode } = e;

      if (keyCode === 13 && currentValue.length > 0) {
        // push to chips
        handleAdd(ChipTextFactory({ label: currentValue, value: currentValue }));
        setCurrentValue('');
      }
    },
    [handleAdd, currentValue],
  );

  useEffect(() => {
    handleChangeText(currentValue);
  }, [handleChangeText, currentValue]);

  return (
    <Box mb={2}>
      <TextFieldBase
        value={currentValue}
        onChange={onChange}
        onKeyDown={onKeyDown}
        InputProps={{
          sx: {
            borderRadius: 16,
          },
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
          'data-element': elements.history.searchInput,
        }}
        placeholder={formatMessage({ id: 'header.search.placeholder', defaultMessage: 'Search' })}
        size="small"
        fullWidth
        autoFocus
      />
    </Box>
  );
};

export default EventsHistoryTextFilter;
