import ui, { modalBehaviours } from 'lib/ui';
import mediaQuery from 'lib/mediaQuery';
import arrow from './arrow.svg';

export default (theme) => ({
  wrapper: {
    padding: [30, 40],
    extend: modalBehaviours.modalButtonGroupBottomMargin,
    '& .DayPicker.Range': {
      marginTop: 20,
      width: '100%',
      [`@media (max-height: ${mediaQuery.maxHeight})`]: {
        marginTop: 5,
      },
    },
    '& .DayPicker-Month': {
      color: '#171A1C',
      height: 300,
      '& .DayPicker-Caption': {
        fontSize: 16,
        [`@media (max-height: ${mediaQuery.maxHeight})`]: {
          fontSize: 14,
        },
        '& > div': {
          fontSize: 'inherit',
        },
      },
    },
    '& .DayPicker-Body': {
      display: 'block',
    },
    '& .DayPicker-Weekend': {
      color: '#D3D6D9',
    },
    '& .DayPicker-Day--selected': {
      backgroundColor: '#F1F4F9!important',
      color: `${theme.colors.primaryLight}!important`,
      '&.DayPicker-Day--start.DayPicker-Day--end': {
        '&:after': {
          display: 'none',
        },
      },
    },
    '& .DayPicker-Day': {
      // width: 32,
      height: 32,
      lineHeight: '32px',
      // flex: '0 0 32px',
      '&:first-child': {
        paddingLeft: 0,
      },
      '&:last-child': {
        paddingRight: 0,
      },
      '&--disabled': {
        color: '#D3D6D9!important',
      },
      '&--today': {
        color: '#171A1C!important',
        '&:before': {
          display: 'none!important',
        },
        '&.DayPicker-Day--selected': {
          fontWeight: 'normal',
          '&:before': {
            display: 'block!important',
          },
        },
      },
      '&--start': {
        color: '#fff!important',
        backgroundColor: 'transparent!important',
        '&:after': {
          left: '50%',
          right: 0,
          backgroundColor: '#F1F4F9!important',
          zIndex: -2,
        },
        '&:before': {
          position: 'absolute',
          left: 15,
          top: -2,
          width: 32,
          height: 32,
          backgroundColor: `${theme.colors.primaryLight}!important`,
        },
        '&:first-child::before': {
          right: 'auto',
          left: 2,
        },
        '&:last-child::before': {
          left: 'auto',
          right: 2,
        },
      },
      '&--end': {
        color: '#fff!important',
        backgroundColor: 'transparent!important',
        '&:after': {
          right: '50%',
          left: 0,
          backgroundColor: '#F1F4F9!important',
          zIndex: -2,
        },
        '&:before': {
          position: 'absolute',
          left: 15,
          top: -2,
          width: 32,
          height: 32,
          backgroundColor: `${theme.colors.primaryLight}!important`,
        },
        '&:first-child::before': {
          right: 'auto',
          left: 2,
        },
        '&:last-child::before': {
          left: 'auto',
          right: 2,
        },
      },
    },
    '& .DayPicker-NavBar': {
      position: 'absolute',
      right: 0,
      display: 'flex',
      flexDirection: 'row',
    },
    '& .DayPicker-NavButton': {
      width: 18,
      height: 18,
      position: 'relative',
      margin: 0,
      top: 'auto',
      display: 'inline-block',
      background: {
        image: `url("${arrow}")`,
        repeat: 'no-repeat',
        position: 'center',
        size: [8, 16],
      },
      '&--prev': {
        right: 0,
        left: 'auto',
        marginLeft: 11,
        '.rtl &': {
          transform: 'rotate(180deg)',
        },
      },
      '&--next': {
        right: 0,
        left: 'auto',
        marginLeft: 11,
        transform: 'rotate(180deg)',
        '.rtl &': {
          transform: 'rotate(0deg)',
        },
      },
      '&--interactionDisabled': {
        display: 'inline-block',
        visibility: 'hidden',
      },
    },
  },
  title: {
    fontSize: 24,
    fontWeight: 'bold',
    color: '#171A1C',
    extend: modalBehaviours.modalTitle,
    [`@media (max-height: ${mediaQuery.maxHeight})`]: {
      padding: 0,
      fontSize: 19,
    },
  },
  range: {
    margin: [25, 0, 15],
    display: 'flex',
    alignItems: 'center',
    color: '#4F4F4F',
    fontSize: 16,
    justifyContent: 'space-between',
    [`@media (max-height: ${mediaQuery.maxHeight})`]: {
      fontSize: 15,
      margin: [25, 0, 35],
    },
  },
  singleSelect: {
    extend: 'range',
    justifyContent: 'flex-start',
  },
  date: {
    borderRadius: 2,
    border: '1px solid #DEE2E6',
    marginLeft: 0,
    height: 36,
    padding: [9, 12],
    fontSize: 16,
    flex: [1, 1, '160px'],
    color: '#171A1C',
    maxWidth: 136,
    outline: 'none',
    transition: 'border 0.2s ease',
    '&:focus': {
      border: '1px solid #6C98D8',
    },
    [`@media (max-height: ${mediaQuery.maxHeight})`]: {},
  },
  btnGroup: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  btn: {
    maxHeight: 50,
    minHeight: 35,
    height: '6vh',
    flex: [1, 1, 'auto'],
    padding: 0,
  },
  btnCancel: {
    marginRight: 7,
    maxWidth: 140,
    maxHeight: 50,
    ...ui(theme).btnType2,
    [`@media (max-height: ${mediaQuery.maxHeight})`]: {
      maxHeight: 40,
    },
  },
  btnSearch: {
    ...ui(theme).btnType3,
    maxHeight: 50,
    minWidth: 140,
    flex: 0,
    [`@media (max-height: ${mediaQuery.maxHeight})`]: {
      maxHeight: 40,
    },
    outline: 'none',
  },
  inputField: {
    position: 'relative',
    [`@media (max-height: ${mediaQuery.maxHeight})`]: {
      fontSize: 14,
      '& input': {
        height: 30,
      },
    },
  },
  invalid: {
    borderColor: 'red',
  },
  message: {
    position: 'absolute',
    top: '100%',
    left: 10,
  },
  messagePeriod: {
    display: 'block',
    textAlign: 'center',
  },

  filterTypes: {
    marginTop: 20,
    border: '1px solid #DEE2E6',
    borderRadius: 4,
    overflow: 'hidden',
    '& :first-child': {
      borderLeft: 0,
    },
  },

  filterTypeBtn: {
    borderRadius: 0,
    backgroundColor: '#fff',
    color: '#5A6872',
    fontSize: 14,
    fontWeight: 600,
    border: 0,
    borderLeft: '1px solid #DEE2E6',
    maxHeight: 37,
    boxSizing: 'border-box',
    cursor: 'pointer',
    padding: [9, 15],
    textAlign: 'center',
  },

  filterTypeActiveBtn: {
    backgroundColor: theme.colors.primaryLight,
    color: '#fff',
  },
});
