/* @flow */
import { makeStyles } from '@mui/styles';

export default makeStyles({
  layout: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    height: '100%',
    width: '100%',
    zIndex: 2, // paid label has zIndex: 1, stamp layout should be higher
  },

  draggable: {
    flip: false,
    position: 'absolute',
    top: 0,
    left: 0,
    transition: 'opacity 250ms',
    transformOrigin: '0 0',

    '&:hover': {
      cursor: 'move',
    },

    '&.visible': {
      opacity: 1,

      '&:hover': {
        opacity: 1,
        cursor: 'default',
      },
    },
  },
});
