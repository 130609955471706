import type { Props } from './index';

export default (theme) => ({
  box: {
    display: 'flex',
    alignItems: 'center',
    padding: [5, 10],
  },

  title: {
    marginLeft: 5,
    cursor: 'pointer',
    color: ({ isWhite }: Props) => (isWhite ? theme.colors.primaryWhite : theme.colors.primaryLight),
    fontSize: 12,
    whiteSpace: 'nowrap',
  },
});
