// @flow
import React, { useCallback, useState, forwardRef, memo } from 'react';
import { FormattedMessage } from 'react-intl';
import type Moment from 'moment';

import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { type BaseSingleInputFieldProps } from '@mui/x-date-pickers/models';
import Button from '@mui/material/Button';

import EventOutlinedIcon from '@mui/icons-material/EventOutlined';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import elements from 'components/elements';

type TDateRangeFilter = {
  range: Array<Date | Moment>,
  onChange: (range: Array<Moment>) => void,
};

type TButtonField = {
  setOpen: () => void,
  open: boolean,
} & BaseSingleInputFieldProps;

const ButtonField = forwardRef((props: TButtonField, ref) => {
  const {
    setOpen,
    open,
    id,
    InputProps: { ref: buttonRef } = {},
    inputProps: { 'aria-label': ariaLabel } = {},
  } = props;

  return (
    <Button
      ref={buttonRef}
      id={id}
      onClick={() => setOpen?.((prev) => !prev)}
      variant="text"
      size="small"
      startIcon={<EventOutlinedIcon />}
      endIcon={open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
      aria-label={ariaLabel}
      data-element={elements.history.calendar}
    >
      <FormattedMessage id="eventsHistory.panel.filter.date" defaultMessage="Date" />
    </Button>
  );
});
// https://github.com/mui/mui-x/issues/10469
// add this line to signal to the range picker to expose the props for a single input range picker
ButtonField.fieldType = 'single-input';

const DateRangeFilter: React$StatelessFunctionalComponent<TDateRangeFilter> = ({ range = [null, null], onChange }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleChange = useCallback(
    ([start, end]) => {
      if (typeof onChange === 'function') {
        if (start && end) {
          onChange([start, end]);
        }
      }
    },
    [onChange],
  );

  const handleOpen = useCallback(() => setIsOpen(true), []);

  const handleClose = useCallback(() => setIsOpen(false), []);

  return (
    <DateRangePicker
      slots={{ field: ButtonField }}
      slotProps={{ field: { setOpen: setIsOpen, open: isOpen }, popper: { placement: 'auto' } }}
      disableFuture
      value={range}
      onChange={handleChange}
      open={isOpen}
      onOpen={handleOpen}
      onClose={handleClose}
    />
  );
};

export default memo(DateRangeFilter);
