export default [
  'image/jpeg',
  'image/tiff',
  'image/png',
  'image/bmp',
  'application/pdf',
  'application/vnd.ms-excel',
  'application/vnd.ms-excel.sheet.macroenabled.12',
  'application/msword',
  '.docx',
  '.doc',
  '.xls',
  '.xlsx',
  '.xlsm',
  '.pptx',
  '.txt',
  '.xml',
  'text/xml',
  'application/xml',
  'text/plain',
  'application/vnd.ms-powerpoint',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
];

export const dataTypes = {
  jpg: 'image/jpeg',
  bmp: 'image/bmp',
  jpeg: 'image/jpeg',
  png: 'image/png',
  tiff: 'image/tiff',
  tif: 'image/tiff',
  pdf: 'application/pdf',
  doc: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  xls: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  xlsm: 'application/vnd.ms-excel.sheet.macroenabled.12',
  ppt: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  pptx: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  xml: 'application/xml',
  txt: 'text/plain',
};
