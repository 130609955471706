// @flow
import React, { useCallback, useRef, useState, useEffect } from 'react';
import Draggable from 'react-draggable';

import Stack from '@mui/material/Stack';

import Tooltip from 'components/mui/Tooltip';
import { Corner, DraggableZone, SelectContainer } from './StyledComponents';

import { useResize } from './hooks/useResize';

import { CORNERS } from './constants';

import { useTheme } from '@mui/material';

type Props = {
  setSelectTableHeaderRef: (ref: HTMLElement) => void,
  documentContainerRef: ?HTMLDivElement,
};

const SelectTableHeader: React$StatelessFunctionalComponent<Props> = ({
  setSelectTableHeaderRef,
  documentContainerRef,
}) => {
  const selectRef = useRef(null);
  const { direction } = useTheme();
  const isRtl = direction === 'rtl';

  const { width: containerWidth = 0, height: containerHeight = 0 } =
    documentContainerRef.current?.getBoundingClientRect();

  const initHorizontalPositionCoefficient = isRtl ? -0.15 : 0.15;

  const [wasMoved, setWasMoved] = useState(false);
  const [isDrag, setIsDrag] = useState(false);
  const [styles, setStyles] = useState({
    width: containerWidth * 0.7 || 150,
    height: 50,
  });
  const [isShowTooltip, setIsShowTooltip] = useState(true);
  const [positionX, setPositionX] = useState(containerWidth * initHorizontalPositionCoefficient);
  const [positionY, setPositionY] = useState(containerHeight / 2);

  const handleStart = useCallback(
    (e, data) => {
      if (!wasMoved) {
        setPositionY(selectRef?.current.offsetTop + data.y);
        setWasMoved(true);
      } else {
        setPositionY(data.y);
      }
      setPositionX(data.x);
      setIsDrag(false);
    },
    [wasMoved],
  );

  const handleMouseDown = useCallback(() => {
    setIsShowTooltip(false);
    setIsDrag(true);
  }, []);

  const { handleCornerMouseDown } = useResize({
    selectRef,
    styles,
    setStyles,
    positionX,
    positionY,
    setPositionX,
    setPositionY,
    handleMouseDown,
    isRtl,
  });

  useEffect(() => {
    setSelectTableHeaderRef(selectRef.current);
  }, [selectRef.current]);

  return (
    <Draggable
      bounds="parent"
      handle="div"
      onStop={handleStart}
      position={{ x: positionX, y: positionY }}
      onMouseDown={handleMouseDown}
    >
      <SelectContainer className="box no-cursor" ref={selectRef} style={styles}>
        <Stack direction={isRtl ? 'row-reverse' : 'row'}>
          <Corner
            style={{ cursor: 'nwse-resize', top: -5, left: -5 }}
            onMouseDown={handleCornerMouseDown}
            data-element={CORNERS.TOP_LEFT}
          />
          <DraggableZone className="cursor" isDrag={isDrag} />
          <Corner
            style={{ cursor: 'nesw-resize', top: -5, right: -5 }}
            onMouseDown={handleCornerMouseDown}
            data-element={CORNERS.TOP_RIGHT}
          />
        </Stack>
        <DraggableZone className="cursor" isDrag={isDrag} />
        <Tooltip
          t={{ id: 'tooltip.document.selectHeader', defaultMessage: 'Select the table header' }}
          open={isShowTooltip}
          PopperProps={{
            sx: {
              zIndex: (theme) => theme.zIndex.modal - 1,
            },
          }}
        >
          <Stack direction={isRtl ? 'row-reverse' : 'row'}>
            <Corner
              style={{ cursor: 'nesw-resize', bottom: -5, left: -5 }}
              onMouseDown={handleCornerMouseDown}
              data-element={CORNERS.BOTTOM_LEFT}
            />
            <DraggableZone className="cursor" isDrag={isDrag} />
            <Corner
              style={{ cursor: 'nwse-resize', bottom: -5, right: -5 }}
              onMouseDown={handleCornerMouseDown}
              data-element={CORNERS.BOTTOM_RIGHT}
            />
          </Stack>
        </Tooltip>
      </SelectContainer>
    </Draggable>
  );
};

export default SelectTableHeader;
