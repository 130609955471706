/* @flow */
import React from 'react';
import { FormattedMessage } from 'react-intl';
import Stack from '@mui/material/Stack';
import Link from '@mui/material/Link';
import SvgIcon from '@mui/material/SvgIcon';

// helpers
import { isSafari, isIE, isEdge, isFirefox, isOpera, isChrome, browserName } from 'lib/systemHelpers/browserHelpers';

import { ReactComponent as ChromeIcon } from './icons/chromeIcon.svg';
import { ReactComponent as EdgeIcon } from './icons/edgeIcon.svg';

import useStyles from './sheet';

const isKnownBrowser = [isEdge, isIE, isFirefox, isSafari, isOpera, isChrome].some((item) => item);

const NotSupported = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.logo} />
      <div className={classes.content}>
        <div className={classes.generalMessage}>
          {!isChrome && (
            <FormattedMessage
              id="notSupportedPage.generalMessage"
              values={{ browserName: isKnownBrowser ? browserName : 'Your' }}
              defaultMessage={`${browserName} browser is not supported`}
            />
          )}
          {isChrome && (
            <FormattedMessage
              id="notSupportedPage.invalidChrome.generalMessage"
              values={{ browserName: isKnownBrowser ? browserName : 'Your' }}
              defaultMessage="You are using an old version of Chrome"
            />
          )}
        </div>

        <div className={classes.imageWrapper}>
          <div className={classes.browserIcon} />
          {!isChrome && <div className={classes.crossIcon} />}
        </div>

        <div className={classes.description}>
          <div className={classes.descriptionTitle}>
            {!isChrome && (
              <Stack direction="row" alignItems="center" spacing={0.5}>
                <FormattedMessage
                  id="notSupportedPage.description.title1"
                  defaultMessage="Unfortunately, we only support"
                />
                <Stack direction="row" alignItems="center" spacing={0.5}>
                  <SvgIcon sx={{ fontSize: '25px' }} component={ChromeIcon} />
                  <Link href="https://www.google.com/chrome/" target="_blank" rel="noopener noreferrer">
                    Chrome
                  </Link>
                </Stack>
                <FormattedMessage id="notSupportedPage.description.or" defaultMessage="or" />
                <Stack direction="row" alignItems="center" spacing={0.5}>
                  <SvgIcon sx={{ fontSize: '20px' }} component={EdgeIcon} viewBox="0 0 154 167" />
                  <Link href="https://www.microsoft.com/en-us/edge/download" target="_blank" rel="noopener noreferrer">
                    Microsoft Edge
                  </Link>
                </Stack>
                <FormattedMessage id="notSupportedPage.description.title2" defaultMessage="internet browser." />
              </Stack>
            )}
            {isChrome && (
              <Stack direction="row" alignItems="center" spacing={0.5}>
                <FormattedMessage
                  id="notSupportedPage.description.invalidChrome.title1"
                  defaultMessage="Update your Chrome browser to"
                />
                <SvgIcon sx={{ fontSize: '25px' }} component={ChromeIcon} inheritViewBox />
                <Link href="https://www.google.com/chrome/" target="_blank" rel="noopener noreferrer">
                  {`Chrome ${process.env.REACT_APP_CHROME_VALID_VERSION}.0`}
                </Link>
                <FormattedMessage id="notSupportedPage.description.invalidChrome.title2" defaultMessage="or later" />
              </Stack>
            )}
          </div>

          <div className={classes.descriptionMessage}>
            {!isChrome && (
              <FormattedMessage
                id="notSupportedPage.description.message"
                defaultMessage="We are working on adding support for other browsers in the near future."
              />
            )}
            {isChrome && (
              <FormattedMessage
                id="notSupportedPage.description.invalidChrome.message"
                defaultMessage="We are working on adding support for the older versions in the near future."
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotSupported;
