// @flow
import React from 'react';
import { withStyles } from '@mui/styles';
import sheet from './sheet';

export type Props = {
  iconType: 'file' | 'files' | 'link',
  classes: { [key: string]: string },
  count: ?number,
};

const IconWithCount: React$StatelessFunctionalComponent<Props> = ({ iconType, count, classes }: Props) => (
  <div className={classes.container}>
    <div className={classes[iconType]} />
    {typeof count === 'number' && count > 1 && (
      <div className={classes.countContainer}>
        <span className={classes.count}>{count}</span>
      </div>
    )}
  </div>
);

export default withStyles(sheet)(IconWithCount);
