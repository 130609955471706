import { MANUALL_INTEGRATION_ERPS } from 'domain/companies/helpers';
import { flow, filter, groupBy } from 'lodash/fp';

export const Statuses = {
  connected: 'connected',
  disconnected: 'disconnected',
  inactive: 'inactive',
  authorized: 'authorized',
  connecting: 'connecting',
};

const statuses = {
  connected: 'active',
  disconnected: 'available',
  inactive: 'available',
  authorized: 'available',
  connecting: 'available',
};

const statusesOrder = ['active', 'available'];

export const statusesTitle = {
  active: { id: 'settings.connections.title.active', defaultMessage: 'Active' },
  available: { id: 'settings.connections.title', defaultMessage: 'Accounting Integrations' },
};

export const sortingStatusOrderCb = ([a], [b]) => statusesOrder.indexOf(a) - statusesOrder.indexOf(b);

export const ERPOptionsAdapter = (availableErps) => (options) => {
  const filterCb = ({ section }) => MANUALL_INTEGRATION_ERPS[section] || availableErps[section];
  const groupCb = (option) => statuses[option.settings.status];

  return flow(filter(filterCb), groupBy(groupCb))(options);
};

export const getIsSettingsVisible = (status) => [Statuses.connected, Statuses.inactive].includes(status);
