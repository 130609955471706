// @flow
import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import { setSortCompanies, searchSortSelector } from 'domain/companies';
import { unreadRequestsLoadedSelector } from 'domain/documents/documentSelector';
import { messagesLoadedSelector } from 'domain/chat/chatSelector';
import { isCurrentOrganizationSetSelector, userHasMultipleOrgsSelector } from 'domain/organization';
import { isDokkaSupportSelector } from 'domain/env/envSelector';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Tooltip from 'components/mui/Tooltip';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import GhostTabs from 'components/mui/Layouts/components/GhostTabs';

const mapStateToProps = (state) => ({
  sort: searchSortSelector(state),
  messagesLoaded: messagesLoadedSelector(state),
  unreadRequestsLoaded: unreadRequestsLoadedSelector(state),
  isCurrentOrganizationSet: isCurrentOrganizationSetSelector(state),
  isMultiplyOrganizations: userHasMultipleOrgsSelector(state),
  isGhost: isDokkaSupportSelector(state),
});

const Bar = () => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const { sort, messagesLoaded, unreadRequestsLoaded, isCurrentOrganizationSet, isMultiplyOrganizations, isGhost } =
    useSelector(mapStateToProps);

  const onClick = (key: string) => {
    const filter = sort === key ? null : key;
    return dispatch(setSortCompanies(filter));
  };

  const chips = useMemo(
    () => [
      {
        key: 'favorites',
        label: formatMessage({
          id: 'companies.bar.favorites',
          defaultMessage: 'Favorites',
        }),
        disabled: false,
      },
      {
        key: 'unread',
        label: formatMessage({
          id: 'companies.bar.unread',
          defaultMessage: 'Unread Messages',
        }),
        disabled: !unreadRequestsLoaded || !messagesLoaded,
        hidden: isGhost,
        tooltip:
          (!unreadRequestsLoaded || !messagesLoaded) &&
          formatMessage({
            id: 'companies.bar.disabledSortingByMessage',
            defaultMessage: 'Loading messages. Please wait',
          }),
      },
      {
        key: 'new',
        label: formatMessage({
          id: 'companies.bar.new',
          defaultMessage: 'New Documents',
        }),
        disabled: false,
      },
      {
        key: 'pendingApproval',
        label: formatMessage({
          id: 'companies.bar.pendingApproval',
          defaultMessage: 'Pending Approval',
        }),
        hidden: isGhost,
        disabled: false,
      },
    ],
    [formatMessage, isGhost, unreadRequestsLoaded, messagesLoaded],
  );

  const actualChips = useMemo(() => chips.filter(({ hidden }) => !hidden), [chips]);

  return (
    <Box display="flex" alignItems="center" mt={2}>
      <Typography variant="subtitle2" mr={2}>
        {formatMessage({ id: 'companies.bar.title', defaultMessage: 'Sort by' })}
      </Typography>
      <Stack direction="row" spacing={1}>
        {actualChips.map((chip) => (
          <Tooltip key={chip.key} t={chip.tooltip}>
            <Chip
              label={chip.label}
              onClick={() => onClick(chip.key)}
              disabled={chip.disabled}
              color={chip.key === sort ? 'primary' : 'default'}
            />
          </Tooltip>
        ))}
      </Stack>

      {!isCurrentOrganizationSet && isMultiplyOrganizations && (
        <Box ml="auto">
          <GhostTabs />
        </Box>
      )}
    </Box>
  );
};

export default Bar;
