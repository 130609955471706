import * as React from 'react';
import Api, { doLogout } from 'domain/api';
import { call, put } from 'redux-saga/effects';
import { FormattedMessage } from 'react-intl';
import * as actions from './actions';
import { ensureJounalEntry } from 'domain/journal/sagas';
import { yieldErrorNotification, yieldSuccessNotification } from 'lib/toasts';
import * as adapters from '../journal/adapters';
import { updateJournalEntryAction } from 'domain/journal/actions';

export function* ensureGetExtractedTableData(documentID) {
  try {
    const { data } = yield call(Api.getExtractedTableData, {
      documentID,
    });
    yield put({
      type: actions.getDocumentExtractedTableData.success,
      payload: data,
    });
    return data;
  } catch (err) {
    yield doLogout(actions.getDocumentExtractedTableData, err);
  }
}

export function* ensureGetExtractedTableFieldsMapping(documentID) {
  try {
    const { data } = yield call(Api.getExtractedTableFieldsMapping, { documentID });
    yield put({
      type: actions.getDocumentExtractedTableFieldMapping.success,
      payload: data,
    });
  } catch (err) {
    yield doLogout(actions.getDocumentExtractedTableFieldMapping, err);
  }
}

export function* ensureLearnExtractedTableFields(pl) {
  /*
  [
  {
    "header": "string",
    "name": "string",
    "type": "string",
    "mandatory": false,
    "default": "string"
  }
] */
  try {
    // filter-out unbound fields represented as empty object
    const prepare = Object.fromEntries(Object.entries(pl.data).filter(([_, v]) => Object.keys(v).length !== 0));
    const payload = Object.entries(prepare).map(([key, { name, type, mandatory }]) => ({
      header: name,
      name: key,
      mandatory,
      type,
    }));

    const { data } = yield call(Api.learnExtractedTableFields, {
      documentID: pl.documentID,
      data: payload,
    });

    yield put({
      type: actions.learnDocumentExtractedTableFieldsAction.success,
      payload: data,
    });

    yield call(ensureJounalEntry, pl.documentID, false);
    yieldSuccessNotification(
      <FormattedMessage id="3WayMatching.toast.successBinded" defaultMessage="Document items binding updated" />,
    );
  } catch (err) {
    yield doLogout(actions.learnDocumentExtractedTableFieldsAction, err);
  }
}
export function* ensureExtractLines({ documentID }) {
  try {
    const { data } = yield call(Api.extractLines, {
      documentID,
    });

    const jePayload = adapters.JEAdapter(data);

    yield put({
      type: actions.ExtractLinesAction.success,
    });

    yield put({
      type: updateJournalEntryAction.success,
      payload: jePayload,
    });
  } catch (err) {
    yieldErrorNotification(err?.response?.data);
    yield doLogout(actions.ExtractLinesAction, err);
  }
}
