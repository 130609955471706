import React from 'react';
import { useIntl } from 'react-intl';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import ScannerIcon from '@mui/icons-material/Scanner';

import { grey } from '@mui/material/colors';

const NoDocs = () => {
  const { formatMessage } = useIntl();
  return (
    <Box width={230} textAlign="center">
      <ScannerIcon color="disabled" fontSize="large" />
      <Typography variant="body1" color={grey[400]}>
        {formatMessage({
          id: 'scan.messages.nothingScannedYet',
          defaultMessage: 'To scan documents, select scaner and press SCAN',
        })}
      </Typography>
    </Box>
  );
};

export default NoDocs;
