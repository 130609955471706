// @flow
/* eslint object-shorthand: 0 */
/* eslint func-names: 0 */
// eslint-disable-file max-len
import moment from 'moment';
import get from 'lodash/get';
import { Record, type RecordOf, List, type RecordFactory, Map, OrderedMap, Set } from 'immutable';
import type { SendBirdInstance, GroupChannel, UserMessage, PreviousMessageListQuery } from 'sendbird';
import { makeChannelName as makeChannelNameFactory, RECONNECTING } from 'sendbird-utils';
import CONST from '../constants';
import { notify } from 'lib/errorLogger';
import type {
  ChatUser,
  RawChatUser,
  ChatRecord,
  MessageType,
  SenderType,
  TMessageRecord,
  MessageGroup,
  CounterRecord,
  MessageMetaData,
  PushNotificationPayloadT,
  PushNotificationPayloadMemberT,
  PushNotificationPayloadMessageT,
} from './types.js.flow';

export const messagesShortTimeFormat = 'MMM D';
export const messagesLongTimeFormat = 'MMM D YYYY';

export function sbInstance(sendbird: SendBirdInstance) {
  if (sendbird && 'getConnectionState' in sendbird) {
    const state = sendbird.getConnectionState();
    if (state !== 'OPEN') notify.captureEvent(new Error(`Chat is not open WS: ${state}`));
    if (state === 'CLOSED') sendbird.reconnect();
  } else {
    notify.captureEvent(new Error('No Sendbird instance'));
  }
}

// factories
export const ChatUserFactory: RecordFactory<ChatUser> = new Record({
  chatToken: '',
  username: '',
  userId: '',
  role: '',
  picture: '',
  isSupport: false,
  firstName: '',
  lastName: '',
  limited: false,
  restricted: false,
});

export const CounterFactory: RecordFactory<CounterRecord> = new Record({
  flat: new Map(),
  pear: new Map(),
});

export const ChatFactory: RecordFactory<ChatRecord> = new Record({
  users: new List(),
  threads: new Map(),
  activeChannel: null,
  activeChannelForce: null,
  messageQuery: new Map(),
  connectionStatus: RECONNECTING,
  readyToRenderOnDocument: false,
  isOpen: false,
  isOnPage: false,
  loaded: false,
  isOneSignalInit: false,
  allowedWorker: false,
});

export const PushNotificationPayloadFactory: RecordFactory<PushNotificationPayloadT> = new Record({
  url: '',
  companyId: '',
  name: '',
  unreadMessageCount: 0,
  members: new List(),
  messages: new List(),
});

// eslint-disable-next-line max-len
export const PushNotificationDocPayloadFactory: RecordFactory<PushNotificationPayloadT> = new Record({
  url: '',
  companyId: '',
  documentId: '',
  name: '',
  unreadMessageCount: 0,
  members: new List(),
  messages: new List(),
});

// eslint-disable-next-line max-len
export const PushNotificationPayloadMemberFactory: RecordFactory<PushNotificationPayloadMemberT> = new Record({
  userId: '',
  nickname: '',
  profileUrl: '',
});

// eslint-disable-next-line max-len
export const PushNotificationPayloadMessageFactory: RecordFactory<PushNotificationPayloadMessageT> = new Record({
  messageId: 0,
  message: '',
  createdAt: 0,
  updatedAt: 0,
  sender: PushNotificationPayloadMemberFactory({}),
  data: '',
});

const getUnredMessagesCount = (members: any[], sender: any) => {
  const res = members.reduce(
    (r, member) => (r === null && member.user_id !== sender.user_id ? member.channel_unread_message_count : r),
    null,
  );
  return res || 0;
};

const extractDocIdFromChanelName = (name: string) => {
  const parts = name.split('#');
  return parts.length === 5 ? parts[2] : null;
};

// adapters

// eslint-disable-next-line max-len
export function PushNotificationPayloadMemberAdapter(member): RecordOf<PushNotificationPayloadMemberT> {
  const userId = get(member, 'user_id');
  const nickname = get(member, 'nickname');
  const profileUrl = get(member, 'profile_url');
  return PushNotificationPayloadMemberFactory({ userId, nickname, profileUrl });
}

// eslint-disable-next-line max-len
export function PushNotificationPayloadMessageAdapter(payload, member): RecordOf<PushNotificationPayloadMessageT> {
  const messageId = get(payload, 'message_id');
  const message = get(payload, 'message');
  const createdAt = get(payload, 'created_at');
  const sender = PushNotificationPayloadMemberAdapter(member);
  const data = get(payload, 'data');
  return PushNotificationPayloadMessageFactory({ messageId, message, createdAt, sender, data });
}

export function PushNotificationPayloadAdapter(data): RecordOf<PushNotificationPayloadT> {
  const url = get(data, 'data.channel.channel_url', '');
  const companyId = get(JSON.parse(get(data, 'data.payload.data')), 'companyId');
  const members = new List(get(data, 'data.members', []).map(PushNotificationPayloadMemberAdapter));
  const name = get(data, 'data.channel.name', '');
  const unreadMessageCount = getUnredMessagesCount(get(data, 'data.members'), get(data, 'data.sender'));
  const messages = new Set([
    PushNotificationPayloadMessageAdapter(get(data, 'data.payload'), get(data, 'data.sender')),
  ]);
  const documentId = extractDocIdFromChanelName(name);

  const factory = documentId ? PushNotificationDocPayloadFactory : PushNotificationPayloadFactory;
  return factory({ url, companyId, documentId, members, name, unreadMessageCount, messages });
}

export function ChatAdapter(data: $ReadOnlyArray<RawChatUser>): List<RecordOf<ChatUser>> {
  return List(
    data.map((rawUser) => {
      const { displayName: username, userID: userId, first_name: firstName, last_name: lastName, ...rest } = rawUser;
      const user = { username, userId, firstName, lastName, ...rest };
      return ChatUserFactory(user);
    }),
  );
}

function creatyeMessageFactory(): (m: UserMessage) => RecordOf<MessageType> {
  const sf: RecordFactory<SenderType> = new Record({
    nickname: '',
    userId: '',
    profileUrl: '',
  });
  const df: RecordFactory<MessageMetaData> = new Record({
    companyId: '',
  });
  const defMessage = () => ({
    messageId: 0,
    message: '',
    createdAt: 0,
    updatedAt: 0,
    sender: sf(),
    data: df(),
  });
  const mf: RecordFactory<MessageType> = new Record(defMessage());

  return (message: UserMessage) =>
    mf(
      // $FlowFixMe
      Object.assign(defMessage(), message, {
        sender: sf(message.sender),
        data: df(JSON.parse(message.data || '{}')),
      }),
    );
}

export const MessageFactory = creatyeMessageFactory();

export function MessageAdapter(messages: UserMessage[]): Set<RecordOf<MessageType>> {
  return messages.reduce((a, v) => a.add(MessageFactory(v)), new Set());
}

export const groupByTodayYesterday = (date: number): string =>
  moment(date).calendar(null, {
    sameDay: '[Today]',
    nextDay: '[got message from future?]',
    nextWeek: '[got message from future?]',
    lastDay: '[Yesterday]',
    lastWeek: messagesShortTimeFormat,
    sameElse: function (now) {
      if (this.isBefore(now)) {
        if (moment().isSame(this, 'year')) {
          return messagesShortTimeFormat;
        }
        return messagesLongTimeFormat;
      }
      return '[got message from future?]';
    },
  });

export function GroupMessages(d: List<TMessageRecord>): MessageGroup {
  const sorter = (a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime();
  const presentGroups = d
    .sort(sorter)
    .groupBy((e) => groupByTodayYesterday(e.createdAt))
    .reverse();
  return OrderedMap(presentGroups).reverse();
}

// SendBird helpers
export const DOCUMENT_CHAT_P2P_TYPE = 'document_p2p';

export function disconnect(sendbird: SendBirdInstance) {
  return new Promise((resolve) => {
    if (sendbird && 'disconnect' in sendbird) {
      sendbird.disconnect(() => resolve());
    } else {
      resolve();
    }
  });
}

export function connect(sendbird: SendBirdInstance, userId: ?string, accessToken: ?string) {
  return new Promise((resolve, reject) => {
    if (sendbird && 'connect' in sendbird && userId && accessToken) {
      sendbird.connect(userId, accessToken, (user, error) => {
        if (error) reject(error);
        if (user) resolve(user);
      });
    } else {
      reject(new Error('No SendBird'));
    }
  });
}

// eslint-disable-next-line max-len
type CreateChannelArgs = [string[], boolean, string, ?string, ?string, ?string];

export function createChannel(sendbird: SendBirdInstance, ...args: CreateChannelArgs) {
  return new Promise((resolve, reject) => {
    sendbird.GroupChannel.createChannelWithUserIds(...args, (res, error) => {
      if (error) reject(error);
      if (res) resolve(res);
    });
  });
}

export function loadMessage(messageListQuery: PreviousMessageListQuery, limit?: number = 20) {
  return new Promise((resolve, reject) => {
    messageListQuery.load(limit, true, (res, err) => {
      if (err) reject(err);
      if (res) resolve(res);
    });
  });
}

export function sendMessage(channel: GroupChannel, message: string, data?: string = '') {
  return new Promise((resolve, reject) => {
    channel.sendUserMessage(message, data, '', (res, err) => {
      if (err) reject(err);
      if (res) resolve(res);
    });
  });
}

// eslint-disable-next-line max-len
export function channelList(sendbird: SendBirdInstance, limit: number = 20): Promise<Array<GroupChannel>> {
  const channelListQuery = sendbird.GroupChannel.createMyGroupChannelListQuery();
  channelListQuery.limit = limit;
  return new Promise((resolve, reject) => {
    channelListQuery.next((res, err) => {
      if (err) reject(err);
      if (res) resolve(res);
    });
  });
}

// eslint-disable-next-line max-len
export function channelExists(sendbird: SendBirdInstance, threadID: string): Promise<Array<GroupChannel>> {
  const channelListQuery = sendbird.GroupChannel.createMyGroupChannelListQuery();
  channelListQuery.channelNameContainsFilter = threadID;
  channelListQuery.includeEmpty = true;
  return new Promise((resolve, reject) => {
    channelListQuery.next((res, err) => {
      if (err) reject(err);
      if (res) resolve(res);
    });
  });
}

export function promisefy<T>(fun: Function, ...args: Array<mixed>): Promise<T> {
  return new Promise((resolve, reject) => {
    fun(...args, (res: ?T, err) => {
      if (err) reject(err);
      if (res) resolve(res);
    });
  });
}

// eslint-disable-next-line max-len
export function makeChannelName(participants: $ReadOnlyArray<string>, companyId: string, documentId?: string) {
  return makeChannelNameFactory(CONST.BUILD_ENV, participants, companyId, documentId);
}

const serializedKeys = {
  message_id: 'messageId',
  last_message: 'lastMessage',
  created_at: 'createdAt',
  updated_at: 'updatedAt',
  user: 'sender',
  user_id: 'userId',
  profile_url: 'profileUrl',
  unread_message_count: 'unreadMessageCount',
  document_id: 'documentId',
  company_id: 'companyId',
  channel_url: 'url',
};

function ChatWorkerThreadFactory<T>(a: T) {
  return Object.entries(a).reduce(
    (ac, [k, v]) => ({
      ...ac,
      [serializedKeys[k] || k]: Array.isArray(v)
        ? v.map(ChatWorkerThreadFactory)
        : v instanceof Object
        ? ChatWorkerThreadFactory(v)
        : v,
    }),
    {},
  );
}

export function ChatWorkerThreadsAdapter<T>(channels: T[]) {
  return channels.map(ChatWorkerThreadFactory);
}
