// @flow
import get from 'lodash/get';
import pick from 'lodash/pick';
import omit from 'lodash/omit';
import { parseCellName } from './helper';

import type { JournalEntryType } from 'domain/contracts';
import type { JournalStore } from './helper';

import { INCLUDES_MAP } from './constants';

type Entries = $PropertyType<JournalEntryType, 'entries'>;
type Entry = $PropertyType<JournalStore, 'entry'>;

export const extractCellIncludes = (cellData: any, map: any) => pick(cellData, Object.keys(map));

export const indexEntries = (entries: Entries) =>
  Object.entries(entries).reduce((res, [cellName, cellData]) => {
    const [_col, _row] = parseCellName(cellName);
    return { ...res, [cellName]: { ...cellData, _col, _row } };
  }, {});

export const extractIncludes = (indexedEntries) =>
  Object.values(indexedEntries).reduce((res, cellData) => {
    const { type, _col } = cellData;
    const map = get(INCLUDES_MAP, type);
    return map ? { ...res, [_col]: extractCellIncludes(cellData, map) } : res;
  }, {});

export const clearEntries = (entries: Entries, firstLineNumber: number) =>
  Object.entries(entries).reduce((res, [cellName, cellData]) => {
    const { type } = cellData;
    const map = get(INCLUDES_MAP, type);
    const clearedCellData =
      map && parseInt(cellName.slice(1), 10) >= firstLineNumber ? omit(cellData, Object.keys(map)) : cellData;

    return { ...res, [cellName]: clearedCellData };
  }, {});

export const getRowCells = (indexedEntries, rowNum: number) =>
  Object.entries(indexedEntries).reduce(
    (res, [cellName, cellData]) => (cellData._row === rowNum ? { ...res, [cellName]: cellData } : res),
    {},
  );

export const normalizeEntries = (entries, firstLineNumber: number) => {
  const indexedEntries = indexEntries(entries);
  const includes = extractIncludes(getRowCells(indexedEntries, firstLineNumber));
  const clearedEntries = clearEntries(indexedEntries, firstLineNumber);
  return [clearedEntries, includes];
};

//
export const replaceIncludesKeysByMap = (include, modifyMap) =>
  Object.entries(modifyMap).reduce(
    (res, [inputKey, outputKey]) => res.set(outputKey, res.get(inputKey)).delete(inputKey),
    include,
  );

export const denormalizeEntry = (entry: Entry, includes: any) =>
  entry.map((cellData) => {
    const include = includes.get(cellData.includes);
    return include ? cellData.merge(replaceIncludesKeysByMap(include, get(INCLUDES_MAP, cellData.type, {}))) : cellData;
  });

export const denormalize = (data: JournalStore) => {
  const entry = denormalizeEntry(data.entry, data.includes);
  return data.set('entry', entry);
};
