/* @flow */
import React from 'react';
import { useTheme } from '@mui/material';
import { isValidBrowser, isMobile, isIPad } from 'lib/systemHelpers/browserHelpers';

import LocaleSelect from 'components/mui/LocaleSelect';
import AppBarMui from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';

import { styled } from '@mui/material/styles';

const AppBar = styled(AppBarMui)(({ theme }) => ({
  // borderTop: `8px solid ${theme.palette.secondary.main}`,
  paddingTop: 8,
  position: 'relative',

  '&:after': {
    backgroundImage: `linear-gradient(to right, ${theme.palette.primary.dark} 0%, ${theme.palette.secondary.main} 100%)`,
    content: '""',
    height: 8,
    top: 0,
    left: 0,
    right: 0,
    position: 'absolute',
  },

  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));

function AuthLayoutHeader() {
  const { labelOptions } = useTheme();
  const isLanguageVisible = labelOptions.loginLangSelect && isValidBrowser && !isMobile && !isIPad;

  return isLanguageVisible ? (
    <AppBar color="transparent" elevation={0} position="static">
      <Toolbar>
        <LocaleSelect />
      </Toolbar>
    </AppBar>
  ) : null;
}

export default AuthLayoutHeader;
