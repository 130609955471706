/* @flow */
import React from 'react';
import { FormattedMessage } from 'react-intl';
import cx from 'classnames';
import elements from 'components/elements';

type Props = {
  classes: {|
    [key: string]: string,
  |},
  className?: string,
  active?: string,
  isAnonymous?: boolean,
  isInitialRequest?: boolean,
  refProxy?: (el: ?HTMLElement) => void,
  onChange: (text: string) => void,
  children?: React$Node,
  value: ?string,
};

class CreateMessage extends React.Component<Props> {
  get isDisable() {
    const { isAnonymous, active } = this.props;
    if (isAnonymous) return false;
    if (typeof active === 'string') return false;
    return true;
  }

  makeRef = (el: ?HTMLElement) => {
    if (typeof this.props.refProxy === 'function') {
      this.props.refProxy(el);
    }
  };

  render() {
    const { classes, active, className, isAnonymous, isInitialRequest } = this.props;
    return (
      <div className={cx(classes.create, className, { [classes.emailTemplate]: isInitialRequest })}>
        {isInitialRequest ? (
          <FormattedMessage id="requestTransaction.requestEmail" defaultMessage="Request Email">
            {(t: string) => <h4 className={classes.title}>{t}</h4>}
          </FormattedMessage>
        ) : null}
        <div className={classes.textWrapper}>
          {isAnonymous ? null : (
            <div className={classes.replyto}>
              <FormattedMessage id="requestTransaction.replyTo" defaultMessage="Reply to:" />
              <div className={classes.recipient}>{active}</div>
            </div>
          )}
          <FormattedMessage id="requestTransaction.enterYourMessageHere" defaultMessage="Enter your message here">
            {(t: string) => (
              <textarea
                name="message"
                ref={this.makeRef}
                className={classes.text}
                placeholder={t}
                onChange={(e) => this.props.onChange(e.currentTarget.value)}
                value={this.props.value}
                disabled={this.isDisable}
                data-element={elements.je.request.transaction.answerMessage}
              />
            )}
          </FormattedMessage>
        </div>
        {this.props.children}
      </div>
    );
  }
}

export default CreateMessage;
