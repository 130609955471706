// @flow
import React from 'react';
import { useSelector } from 'react-redux';
import { generatePath, Navigate } from 'react-router-dom';
import { isAdminSelector, loadingSelector, sagaDataLoadingSelector } from 'domain/env';
import { allCompaniesByOrganizationSelector as companiesSelector } from 'domain/companies';
import ROUTES_PATH from 'domain/router/routesPathConfig';
import useToggle from 'hooks/useToggle';
import * as ACL from 'domain/restriction';

import Container from '@mui/material/Container';
import LayoutCompanies from 'components/mui/Layouts/Companies';
import StickySubheader from 'components/mui/Layouts/components/StickySubheader';
import OrganizationModal from 'pages/common/Dialog/DialogCreateOrganization';
import CompaniesGrid from 'pages/companies/components/Grid/CompaniesGrid';
import CompaniesTitle from 'pages/companies/components/Title';
import Bar from 'pages/companies/components/Bar';
import CreateCompany from './components/CreateCompany';
import CircularProgressWithBackdrop from 'components/mui/CircularProgressWithBackdrop';

const mapStateToProps = (state) => ({
  companies: companiesSelector(state),
  isAdmin: isAdminSelector(state),
  isGranted: ACL.isGranted(state),
  loading: loadingSelector(state),
  isSagaDataLoading: sagaDataLoadingSelector(state),
});

const Companies = () => {
  const { companies, isAdmin, isGranted, loading, isSagaDataLoading } = useSelector(mapStateToProps);
  const [isOpenCompanyModal, toggleCompanyModal] = useToggle();

  const isSupplier = isGranted(ACL.IS_SUPPLIER_USER);

  if (companies.size === 1 && !isAdmin) {
    const ROUTE_ID = isGranted(ACL.IS_SUPPLIER_USER)
      ? ROUTES_PATH.COMPANY_SUPPLIER.id
      : ROUTES_PATH.COMPANY_DEFAULT_CATEGORY.id;
    const companyId = companies.first().get('id');
    const to = generatePath(ROUTES_PATH[ROUTE_ID].absolute, { companyId });

    return <Navigate to={to} />;
  }

  return (
    <>
      <LayoutCompanies>
        <StickySubheader>
          <CompaniesTitle onClickAdd={toggleCompanyModal} />
          {!isSupplier && <Bar />}
        </StickySubheader>
        <Container maxWidth={false} sx={{display: 'flex', flex: 1}}>
          <CompaniesGrid />
        </Container>
        <CircularProgressWithBackdrop type="light" isOpen={loading || isSagaDataLoading} />
      </LayoutCompanies>

      <CreateCompany open={isOpenCompanyModal} onClose={toggleCompanyModal} />

      <OrganizationModal />
    </>
  );
};

export default Companies;
