// @flow
import React, { memo, useCallback, useEffect } from 'react';
import { bindMenu, bindTrigger, usePopupState } from 'material-ui-popup-state/hooks';
import type { TViewArrangement } from 'pages/document/types.js.flow';

import { IconViewByID } from 'pages/document/components/DocumentViewDropdown/components/ViewIcons';
import Tooltip from 'components/mui/Tooltip';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';

import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';
import ArrowDropUpOutlinedIcon from '@mui/icons-material/ArrowDropUpOutlined';

type TDocumentViewDropdown = {
  value: TViewArrangement,
  onSelect: (value: TViewArrangement) => void,
};

type Action = {|
  name: string,
  values: [TViewArrangement, TViewArrangement],
  hotkeyCode: number,
|};

const VIEW_OPTIONS: Array<Action> = [
  {
    values: ['ltr', 'rtl'],
    name: 'CTRL + SHIFT + 1',
    hotkeyCode: 49, // 1
  },
  {
    values: ['ttb', 'btt'],
    name: 'CTRL + SHIFT + 2',
    hotkeyCode: 50, // 2
  },
  {
    values: ['jeOnly', 'docOnly'],
    name: 'CTRL + SHIFT + 3',
    hotkeyCode: 51, // 3
  },
];

const DocumentViewDropdown: React$StatelessFunctionalComponent<TDocumentViewDropdown> = ({ value, onSelect }) => {
  const popupState = usePopupState({ variant: 'popover', popupId: 'DocumentViewDropdown' });

  const handleClick = (itemValue: TViewArrangement) => {
    onSelect(itemValue);
    popupState.close();
  };

  const onCatchHotkey = useCallback(
    (event: KeyboardEvent) => {
      if (event.shiftKey && event.ctrlKey) {
        VIEW_OPTIONS.forEach(({ values, hotkeyCode }) => {
          if (hotkeyCode === event.keyCode) {
            const actualValues = values.includes(value) ? values.filter((v) => v !== value) : values;

            onSelect(actualValues[0]);

            return false; // stop iteration
          }

          return true;
        });
      }
    },
    [onSelect, value],
  );

  useEffect(() => {
    document.addEventListener('keydown', onCatchHotkey, false);

    return () => document.removeEventListener('keydown', onCatchHotkey);
  }, [onCatchHotkey]);

  return (
    <>
      <Tooltip t={{ id: 'document.show.bottomBar.toggleDocumentView', defaultMessage: 'Toggle Document View' }}>
        <Button
          variant="text"
          color="primary"
          endIcon={popupState.isOpen ? <ArrowDropUpOutlinedIcon /> : <ArrowDropDownOutlinedIcon />}
          {...bindTrigger(popupState)}
        >
          <IconViewByID id={value} />
        </Button>
      </Tooltip>
      <Menu
        {...bindMenu(popupState)}
        transformOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
      >
        {VIEW_OPTIONS.map(({ values, ...item }) =>
          values.map((itemValue) => (
            <MenuItem key={itemValue} selected={value === itemValue} onClick={() => handleClick(itemValue)}>
              <ListItemIcon>
                <IconViewByID id={itemValue} />
              </ListItemIcon>
              <ListItemText>{item.name}</ListItemText>
            </MenuItem>
          )),
        )}
      </Menu>
    </>
  );
};

export default memo(DocumentViewDropdown);
