import { shadow } from 'lib/ui';
import eye from './eye.svg';
import eyeA from './eye_a.svg';
import file from './file.svg';

export default (theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 30,
  },
  phoneWrapper: {},
  header: {
    marginBottom: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  label: {
    color: '#171A1C',
    fontSize: ({ capital }) => (capital ? 19 : 14),
  },
  required: {
    flip: false,
    position: 'relative',
    fontSize: 12,
    color: '#8C9BA5',
    display: 'inline-block',
    paddingRight: 10,
  },
  star: {
    flip: false,
    position: 'absolute',
    height: '100%',
    display: 'inline-block',
    top: 0,
    fontSize: 12,
    right: 0,
  },
  input: {
    border: '1px solid #DEE2E6',
    padding: ({ type }) => (type === 'password' ? '9px 40px 9px 12px' : '9px 12px'),
    borderRadius: 2,
    outline: 'none',
    fontSize: 14,
    color: '#171A1C',
    '&:focus': {
      border: `1px solid ${theme.colors.primaryLight}`,
    },
    '&[readonly]': {
      color: '#8C9BA5',
      paddingLeft: 0,
      fontWeight: 'bold',
      border: '1px solid transparent',
    },
    '&::placeholder': {
      color: '#CCD2D7',
    },
  },
  readOnlyLabel: {
    color: '#8C9BA5',
  },
  fileWrapper: {
    padding: [10, 20],
    color: theme.colors.primaryLight,
    fontSize: 13,
    display: 'flex',
    width: 110,
    cursor: 'pointer',
    border: `1px solid ${theme.colors.primaryLight}`,
    borderRadius: 4,
    alignItems: 'center',
    justifyContent: 'center',
  },
  file: {
    display: 'none',
  },

  withValue: {
    padding: [10, 20, 10, 20],
    width: 200,
    justifyContent: 'flex-start',
    border: '1px solid transparent',
    backgroundColor: theme.colors.primaryLight,
    WebkitMask: `url(${file}) 0 center no-repeat`,
    color: '#171A1C',
  },
  select: {
    marginRight: 10,
    flex: 1,
    '& > button': {
      width: 'auto',
      minWidth: 30,
      display: 'inline-block',
      padding: [9, 30, 9, 10],
      border: `1px solid ${theme.colors.greyLight}`,
      background: '#fff',
      borderRadius: 2,
    },
    '& li': {
      padding: 0,
    },
  },
  phoneInput: {
    minWidth: 0,
    flex: '1 1 100%',
    boxSizing: 'border-box',
    display: 'inline-block',
    '&[readonly]': {
      backgroundColor: theme.colors.greyLight,
      color: theme.colors.greyBlack,
      fontWeight: 'normal',
      padding: [9, 12],
    },
  },
  phoneSelect: {
    '&__control--is-disabled': {
      minHeight: '36px !important',
      backgroundColor: `${theme.colors.greyLight} !important`,
    },
    '&__single-value--is-disabled': {
      color: `${theme.colors.greyBlack} !important`,
    },
  },
  code: {
    flip: false,
    flex: '1 1 120px',
    paddingRight: 10,
    position: 'relative',
  },
  withBorder: {
    marginBottom: 16,
    paddingBottom: 24,
    borderBottom: '1px solid #DEE2E6',
  },
  withSmallSizeLabel: {
    '& $top label': {
      fontSize: '14px',
    },
  },
  disabled: {},
  switcherWrapper: {
    position: 'relative',
    '& $checkbox': {
      background: theme.colors.greyDarker,
      border: 'none',
    },
    '&$disabled': {
      '& $checkbox': {
        background: theme.colors.greyDark,
        border: 'none',
        cursor: 'not-allowed',
      },
    },
    '&$activeSwitcher': {
      '& $checkbox': {
        background: theme.colors.primaryLight,
        border: `1px solid ${theme.colors.primaryLight}`,
        '& $switcher': {
          transform: 'translateX(14px)',
        },
      },
      '&$activeSwitcher&$disabled': {
        '& $checkbox': {
          background: `rgba( ${theme.colors.rgbPrimaryLight}, 0.5 )`,
          border: `1px solid rgba( ${theme.colors.rgbPrimaryLight}, 0.5 )`,
          cursor: 'not-allowed',
        },
      },
    },
  },
  disabledFileInput: {
    cursor: 'not-allowed',
    pointerEvents: 'none',
  },
  withChildren: {
    position: 'relative',
    '&:after': {
      position: 'absolute',
      top: 0,
      left: -24,
      width: 0,
      height: '100%',
      content: '""',
      display: 'inline-block',
      background: 'none',
      willChange: 'background, width',
      transition: 'width 0.2s ease, background 0.1s ease',
    },
    '&$activeSwitcher': {
      '&:after': {
        width: 2,
        background: theme.colors.primaryLight,
      },
      '&:before': {
        position: 'absolute',
        top: 8,
        left: -13,
        content: '""',
        display: 'inline-block',
        borderTop: '5px solid #8C9BA5',
        borderLeft: '5px solid transparent',
        borderRight: '5px solid transparent',
      },
      '& $checkbox': {
        background: theme.colors.primaryLight,
        border: `1px solid ${theme.colors.primaryLight}`,
        '& $switcher': {
          background: '#fff',
          transform: 'translateX(14px)',
        },
      },
    },
  },
  withLabel: {
    '& $checkbox': {
      marginLeft: 16,
    },
  },

  top: {
    display: 'inline-block',
    '& label': {
      fontSize: 19,
      alignItems: 'center',
      color: '#171A1C',
      display: 'flex',
    },
  },
  activeSwitcher: {},
  checkbox: {
    display: 'inline-block',
    position: 'relative',
    borderRadius: 8,
    border: '1px solid #5A6872',
    width: 30,
    minWidth: 30,
    height: 16,
    cursor: 'pointer',
    transition: 'background 0.4s ease',
    willChange: 'background',
    '& input': {
      display: 'none',
    },
  },
  switcher: {
    width: 12,
    borderRadius: '50%',
    height: 12,
    transition: 'background 0.2s ease 0.1s, transform 0.2s ease',
    display: 'inline-block',
    position: 'absolute',
    top: '50%',
    marginTop: -6,
    left: 1,
    willChange: 'background, transform',
    background: theme.colors.primaryWhite,
  },
  hint: {
    marginTop: 5,
    fontSize: 14,
    color: '#5A6872',
  },
  suggestions: {
    ...shadow,
    position: 'absolute',
    zIndex: 12,
    top: '100%',
    left: 0,
    right: 0,
    background: {
      color: '#fff',
    },
    border: `1px solid ${theme.colors.greyLight}`,
    '& > ul': {
      maxHeight: 300,
      overflowY: 'auto',
      '& li:first-child': {
        marginTop: 0,
      },
      '& li:last-child': {
        marginBottom: 0,
      },
    },
    '& > ul mark': {
      background: 'transparent',
      color: 'inherit',
    },
    '& > ul > li': {
      padding: [6, 10],
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: theme.colors.primaryLight,
        color: '#FFF',
      },
    },
  },
  activeSuggestion: {
    color: 'rgba(51, 51, 51, 0.6)',
  },
  separated: {
    borderBottom: `1px solid ${theme.colors.greyLight}`,
  },
  autocompleteWrapper: {
    position: 'relative',
  },
  autoInputWrapper: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    '& input': {
      width: '100%',
    },
  },
  toggle: {
    position: 'absolute',
    right: 10,
    width: 20,
    height: 20,
    border: `1px solid ${theme.colors.greyLight}`,
    outline: 'none',
    '&:after': {
      left: 0,
      width: 0,
      right: 0,
      border: '4px solid transparent',
      height: 0,
      margin: 'auto',
      position: 'absolute',
      top: 8,
      content: '""',
      display: 'inline-block',
      borderTopColor: '#333333',
    },
  },
  toggleOpen: {
    border: `1px solid ${theme.colors.primaryLight}`,
    '&:after': {
      borderTopColor: 'transparent',
      borderBottomColor: '#333333',
      top: 4,
    },
  },
  inputWrapper: {
    position: 'relative',
    '& input': {
      width: '100%',
    },
  },
  eye: {
    flip: false,
    position: 'absolute',
    border: 'none',
    outline: 'none',
    top: '50%',
    cursor: 'pointer',
    transform: 'translateY(-50%)',
    right: 8,
    background: `url(${eye}) center center no-repeat`,
    width: 24,
    height: 16,
  },

  openEye: {
    background: `url(${eyeA}) center center no-repeat`,
  },

  phoneInputWrapper: {
    flip: false,
    direction: 'ltr !important',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  switcherLabel: {
    marginRight: 10,
  },
});
