// assets
import clip from './clip.svg';

const stack = {
  content: '""',
  width: '100%',
  height: '100%',
  position: 'absolute',
  border: '1px solid #D3D6D9',
  background: 'white',
};

export default (theme) => ({
  wrapper: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '35%',
    height: '35%',
    zIndex: -1,
  },
  selected: {},
  linkid: {},
  preview: {
    height: '100%',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    background: 'white',
    border: (props) => `1px solid ${props.children.props.selected ? '#D3D6D9' : 'none'}`,
    '&$linkid:after': {
      content: '""',
      width: '34%',
      height: '30%',
      position: 'absolute',
      top: 0,
      left: '2%',
      background: `center / contain no-repeat url(${clip}), ${theme.colors.primaryLight}`,
      borderRadius: [0, 0, 2, 2],
    },
    '&$selected:before': {
      ...stack,
      left: 5,
      top: -6,
      zIndex: -2,
    },
    '&$selected:after': {
      ...stack,
      left: 11,
      top: -11,
      zIndex: -3,
    },
  },
  previewImg: {
    maxHeight: '100%',
    width: '100%',
    height: 'auto',
    flex: 1,
  },
});
