// @flow

export type AngleType = 0 | 90 | 180 | 270;

export type LS$RorateType = {|
  [key: number]: AngleType,
|};

export type LS$RotateStore = {|
  [key: string]: LS$RorateType,
|};

const LOCAL_STORAGE_ROTATE_KEY = 'dokka_documents_rotation';

export function getRotateFromStore(): ?LS$RotateStore {
  const str = window.localStorage.getItem(LOCAL_STORAGE_ROTATE_KEY) || '{}';
  return JSON.parse(str);
}

export function getRotateByDocID(documentID: string): ?LS$RorateType {
  const data = getRotateFromStore();
  if (data && documentID in data) return data[documentID];
  return null;
}

export function setRotateByDocID(documentID: string, params: LS$RorateType): void {
  const store = getRotateFromStore();
  let newStore = {
    ...store,
  };
  if (store && documentID in store) {
    newStore = {
      ...newStore,
      [documentID]: { ...store[documentID], ...params },
    };
  } else {
    newStore = { ...newStore, [documentID]: params };
  }
  const value = JSON.stringify(newStore);
  window.localStorage.setItem(LOCAL_STORAGE_ROTATE_KEY, value);
}
