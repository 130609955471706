/* @flow */
import * as React from 'react';
import { compose, type Dispatch } from 'redux';
import { connect } from 'react-redux';
import { navigate } from 'domain/router/redux/reduxActions';
import {
  eventsListSelector,
  nextNumPageSelector,
  getEventsAction,
  getCountOfNewEventsAction,
  newEventsCounterSelector,
  toggleEventPanelAction,
  isOpenedSelector,
  exportHistoryEventsAction,
} from 'domain/events';
import { documentDocumentIdSelector, documentGetLinkedAction } from 'domain/documents';
import ROUTES_PATH from 'domain/router/routesPathConfig';
import { generatePath } from 'react-router-dom';
import { setActiveChannelAction, setOpenAction } from 'domain/chat';
import { promisify } from 'lib/helpers';
import makeCancelable, { type PromiseCanceleable } from 'lib/makeCancelable';
import { bodyToggler } from 'lib/domHelpers';
import { formatDate } from 'lib/dateTime';

import EventsHistoryDrawer from 'components/mui/Layouts/components/EventsHistoryPanel/components/EventsHistoryDrawer';
import EventsHistoryFiltersBlock from 'components/mui/Layouts/components/EventsHistoryPanel/components/FiltersBlock';
import EventsHistoryList from 'components/mui/Layouts/components/EventsHistoryPanel/components/EventsList';

type Props = {
  list: Set<any>,
  nextNumPage: ?string,
  documentID: string,
  counter: number,
  company: ?{ dateFormat: string, id: string },
  isOpened: boolean,
  navigatePush: (l: string) => void,
  getEvents: (d: { maxResult: number, page: ?string, documentID: string }) => void,
  setActiveChannel: (d: { threadId: string, companyId: string }) => void,
  setOpenChat: (p: boolean) => void,
  getCountOfNewEvents: () => void,
  onShowLinked: (l: { tag: string }) => void,
  toggleEventPanel: () => void,
  exportHistoryEvents: Dispatch<exportHistoryEventsAction>,
};

type State = {
  timestampFrom: ?Date,
  timestampTo: ?Date,
};

class EventsHistoryPanel extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      timestampFrom: null,
      timestampTo: null,
      filters: {
        usersSearch: [],
        typesSearch: [],
        textsSearch: [],
        freeTextSearch: [],
      },
    };
  }

  componentDidUpdate(prevProps: Props): * {
    const { isOpened, getCountOfNewEvents } = this.props;
    if (isOpened && isOpened !== prevProps.isOpened) {
      getCountOfNewEvents();
    }
  }

  componentWillUnmount() {
    if (this.cancelable) {
      this.cancelable.cancel();
    }
  }

  get dateFormat(): string {
    const { company } = this.props;
    if (company && typeof company.dateFormat === 'string') return company.dateFormat;
    return 'DD/MM/YYYY';
  }

  handleAction = async (d) => {
    const { link } = d;
    const { setActiveChannel, setOpenChat, onShowLinked, company, navigatePush } = this.props;
    switch (link.type) {
      case 'document_chat':
        await this.toggleOpen();
        setOpenChat(true);
        setActiveChannel({
          threadId: link.channel_name,
          companyId: link.companyID,
        });
        break;
      case 'no-event-handler': // TODO: 'linked_document':
        await this.toggleOpen();
        onShowLinked({ tag: link.linkID });
        if (company) {
          navigatePush(generatePath(ROUTES_PATH.COMPANY_WORKSPACE.absolute, { companyId: company.id }));
        }
        break;
      default:
        break;
    }
  };

  toggleOpen = () => {
    const { isOpened, toggleEventPanel, getCountOfNewEvents } = this.props;

    if (!isOpened) getCountOfNewEvents();

    toggleEventPanel();

    return new Promise((resolve) => {
      this.setState(
        {
          timestampFrom: null,
          timestampTo: null,
          filters: {
            usersSearch: [],
            typesSearch: [],
            textsSearch: [],
            freeTextSearch: [],
          },
        },
        () => {
          bodyToggler(isOpened);
          resolve();
        },
      );
    });
  };

  handleLoadEvents = (nextNumPage: ?string, clear?: boolean) => {
    const { getEvents, documentID } = this.props;
    const { timestampFrom, timestampTo, filters } = this.state;
    this.cancelable = makeCancelable(
      promisify(getEvents, {
        maxResult: 10,
        page: nextNumPage,
        documentID,
        timestampFrom: formatDate(timestampFrom),
        timestampTo: formatDate(timestampTo),
        ...filters,
        clear,
      }),
    );
  };

  handleExportEvents = () => {
    const { timestampFrom, timestampTo, filters } = this.state;
    const {
      exportHistoryEvents,
      documentID,
      company: { id },
    } = this.props;
    exportHistoryEvents({
      companyId: id,
      documentID,
      timestampFrom: formatDate(timestampFrom),
      timestampTo: formatDate(timestampTo),
      ...filters,
    });
  };

  handleGetNextPage = () => {
    const { nextNumPage } = this.props;
    this.handleLoadEvents(nextNumPage, false);
  };

  handleSetDate = (from, to) => {
    this.setState(
      {
        timestampFrom: from,
        timestampTo: to,
      },
      () => {
        this.handleLoadEvents(null, true);
      },
    );
  };

  onChangeFilters = (filters) => {
    this.setState({ filters }, () => {
      this.handleLoadEvents(null, true);
    });
  };

  cancelable: PromiseCanceleable<*>;

  render() {
    const { isOpened, list, nextNumPage, counter } = this.props;

    return (
      <EventsHistoryDrawer toggleOpen={this.toggleOpen} isOpened={isOpened}>
        <EventsHistoryFiltersBlock
          onChangeFilters={this.onChangeFilters}
          onChangeDate={this.handleSetDate}
          onClickExport={this.handleExportEvents}
          isDisabledExport={list.size === 0}
          dateFormat={this.dateFormat}
        />

        <EventsHistoryList
          list={list}
          onAction={this.handleAction}
          getNextEvents={this.handleGetNextPage}
          isNext={nextNumPage}
          onReload={() => this.handleLoadEvents(null, false)}
          counter={counter}
          dateFormat={this.dateFormat}
        />
      </EventsHistoryDrawer>
    );
  }
}

const mapStateToProps = (state) => ({
  list: eventsListSelector(state),
  nextNumPage: nextNumPageSelector(state),
  counter: newEventsCounterSelector(state),
  isOpened: isOpenedSelector(state),
  documentID: documentDocumentIdSelector(state),
});

const mapDispatchToProps = {
  toggleEventPanel: toggleEventPanelAction,
  getEvents: getEventsAction,
  setActiveChannel: setActiveChannelAction,
  setOpenChat: setOpenAction,
  getCountOfNewEvents: getCountOfNewEventsAction,
  navigatePush: navigate.push,
  onShowLinked: documentGetLinkedAction,
  exportHistoryEvents: exportHistoryEventsAction,
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(EventsHistoryPanel);
