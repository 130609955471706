// @flow
import React from 'react';
import Stack from '@mui/material/Stack';

import Notes from './components/Notes';

const Widgets = () => (
  <Stack position="absolute" justifyContent="center" height="100%" spacing={1} zIndex={30}>
    <Notes />
  </Stack>
);

export default Widgets;
