// @flow
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { rtlEnable } from 'domain/env';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withStyles } from '@mui/styles';
import cx from 'classnames';
import elements from 'components/elements';
import sheet from './sheet';
import './styles.css';
import { isChangeProps } from 'lib/propHelpers';

type Props = {|
  children?: React$Node,
  // jss duplicated styles added to make Fullstory happy as it can not see bundled css
  classes: { [key: string]: string },
  upDirection?: boolean,
  className?: string,
  tooltipClass?: string,
  id?: string,
  defaultMessage?: string,
  message?: string,
  disable?: boolean,
  // eslint-disable-next-line react/no-unused-prop-types
  debounce?: boolean,
  // eslint-disable-next-line react/no-unused-prop-types
  width?: number,
  noWrap?: boolean,
  alignToEdge?: 'left' | 'right',
  isRtl: boolean,
|};

type State = {|
  enableUpDirection: boolean,
  posStyle: { [key: string]: string },
|};

// TODO: MUI - check and delete
class Tooltip extends React.Component<Props, State> {
  state = {
    enableUpDirection: false,
    posStyle: {},
  };

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>) {
    const { enableUpDirection } = this.state;
    if (this.boxEl && prevState.enableUpDirection && !enableUpDirection) {
      this.setState({ enableUpDirection: this.boxEl.getBoundingClientRect().bottom > window.innerHeight });
    }
    if (isChangeProps(prevProps, this.props, ['message', 'id', 'alignToEdge'])) {
      this.setPosStyle();
    }
  }

  onMouseOver = (event: MouseEvent): void => {
    const { upDirection } = this.props;
    const { bottom } = this.boxEl.getBoundingClientRect();

    if (upDirection) return;
    const { clientY } = event;
    this.setState({ enableUpDirection: clientY >= window.innerHeight * 0.75 || bottom > window.innerHeight });
  };

  get messageStyle() {
    const { width } = this.props;
    const { posStyle } = this.state;
    const widthStyle = typeof width === 'number' ? { width: `${width}px` } : {};
    return { ...posStyle, ...widthStyle };
  }

  addRefEl = (el) => {
    this.boxEl = el;
    this.setPosStyle();
  };

  get alignToEdgeStyle() {
    const { alignToEdge, isRtl } = this.props;
    const left = isRtl ? 'right' : 'left';
    const right = isRtl ? 'left' : 'right';
    if (alignToEdge && this.boxEl) {
      const { width } = this.boxEl.getBoundingClientRect();
      const alignLeftStyle = alignToEdge === left ? { left: `${width / 2}px`, right: 'unset' } : {};
      const alignRightStyle = alignToEdge === right ? { right: `-${width / 2}px`, left: 'unset' } : {};
      return { ...alignLeftStyle, ...alignRightStyle };
    }
    return {};
  }

  setPosStyle = () => {
    if (this.boxEl) {
      const { left, width } = this.boxEl.getBoundingClientRect();
      const maxLeft = 20;
      const maxRight = window.innerWidth - 20;
      const leftStyle = maxLeft > left ? { left: `${maxLeft - left}px` } : {};
      const rightStyle = left + width > maxRight ? { right: `${maxRight - (left + width)}px` } : {};
      const posStyle = { ...leftStyle, ...rightStyle, ...this.alignToEdgeStyle };
      if (isChangeProps(this.state.posStyle, posStyle, ['left', 'right'])) {
        this.setState({ posStyle });
      }
    }
  };

  boxEl = null;

  render() {
    const {
      className,
      id,
      message,
      disable,
      tooltipClass,
      defaultMessage,
      children,
      upDirection,
      classes,
      noWrap = true,
    } = this.props;

    const { enableUpDirection } = this.state;

    return disable ? (
      children || null
    ) : (
      <div // eslint-disable-line
        className={cx(
          'tooltype__container',
          {
            tooltype__container_debounced: this.props.debounce,
          },
          classes.messageContainer,
          className,
        )}
        onMouseOver={this.onMouseOver}
        data-element={elements.tooltip.container}
      >
        {children}

        <>
          <div
            className={cx('tooltype__arrow', {
              tooltype__arrow_up: Boolean(upDirection) || enableUpDirection,
            })}
          />
          <div
            ref={this.addRefEl}
            className={cx('tooltype__message', tooltipClass, classes.message, {
              tooltype__message_up: Boolean(upDirection) || enableUpDirection,
              'is-nowrap': !noWrap,
            })}
            style={this.messageStyle}
          >
            {id ? <FormattedMessage id={id} defaultMessage={defaultMessage} /> : message}
          </div>
        </>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isRtl: rtlEnable(state),
});

export default compose(connect(mapStateToProps, {}), withStyles(sheet))(Tooltip);
