// @flow
import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { type User } from 'domain/organizationUser/types.js.flow';
import { userRoleMeta } from 'pages/common/constant';
import { organizationUserByIdSelector } from 'domain/organizationUser/selectors';

import Form from './Form';
import Dialog from 'components/mui/Dialog';

type TDialogManageUser = {
  onCancel: () => void,
  onSubmit: (data: User) => Promise<void>,
  open: boolean,
  userID?: string | number,
  subtitle?: React$Element,
  isCompany?: boolean,
};

const DialogManageUser: React$StatelessFunctionalComponent<TDialogManageUser> = ({
  onCancel,
  onSubmit,
  open,
  userID,
  subtitle,
  isCompany = false,
}) => {
  const initialValues = useSelector(organizationUserByIdSelector(userID));
  const isEdit = Boolean(userID);

  const handleSubmit = useCallback(
    (data: User) => {
      if (typeof onSubmit === 'function') {
        onSubmit(data.merge(userRoleMeta[data.role] || {}));
      }
    },
    [onSubmit],
  );

  const DialogTitle = useMemo(
    () =>
      isEdit ? (
        <FormattedMessage id="form.organization.users.edit.title" defaultMessage="Edit User" />
      ) : (
        <FormattedMessage id="form.organization.users.create.title" defaultMessage="Create User" />
      ),
    [isEdit],
  );

  return (
    <Dialog open={open} onClose={onCancel} title={DialogTitle} maxWidth="sm" withActions={false} withContent={false}>
      <Form
        onSubmit={handleSubmit}
        initialValues={initialValues}
        isEdit={isEdit}
        onCancel={onCancel}
        subtitle={subtitle}
        isCompany={isCompany}
      />
    </Dialog>
  );
};

export default DialogManageUser;
