// @flow
import React, { memo } from 'react';
import Icon from 'react-icon-base';
import { type TIconBase } from './types.js.flow';

const IconEmailSuccess = (props: TIconBase) => (
  <Icon viewBox="0 0 128 128" {...props}>
    <circle cx="102" cy="82" r="20" fill="#17C896" />
    <path
      fillRule-rule="evenodd"
      clipRule="evenodd"
      d="M104 60.0897V36.0274C104 34.8684 103.523 33.8238 102.748 33.0891L71.8 60L83.4651 70.1435C87.3756 64.0429 94.2157 60 102 60C102.674 60 103.341 60.0303 104 60.0897ZM81.6048 73.7361L68.8073 62.6023L58 72L47.1927 62.6023L18 88H80.8281C80.2887 86.0927 80 84.08 80 82C80 79.0772 80.57 76.2876 81.6048 73.7361ZM82.3988 92H16.0144C11.5591 92 8 88.4289 8 84.0238V35.9762C8 31.5781 11.5882 28 16.0144 28H99.9856C104.441 28 108 31.5711 108 35.9762V60.8281C117.234 63.4398 124 71.9298 124 82C124 94.1503 114.15 104 102 104C93.4506 104 86.0402 99.1234 82.3988 92ZM13.26 33.0957C12.4842 33.8296 12 34.8708 12 36.0274V83.9726C12 85.1316 12.4766 86.1762 13.2524 86.9109L44.2 60L13.26 33.0957ZM58 66.8L98 32H18L58 66.8ZM102 100C111.941 100 120 91.9411 120 82C120 72.0589 111.941 64 102 64C92.0589 64 84 72.0589 84 82C84 91.9411 92.0589 100 102 100Z"
      fill="#3F6DB2"
    />
    <path
      d="M90.5858 83.5564L100 92.9706L115.414 77.5564L112.485 74.6274L100 87.1127L93.5147 80.6274L90.5858 83.5564Z"
      fill="white"
    />
  </Icon>
);

export default memo(IconEmailSuccess);
