// @flow
import { Set, Map, List } from 'immutable';
import { CONNECTED } from 'sendbird-utils';
import { signOutAction } from 'domain/env/envActions';
import { type ChatStore } from './types.js.flow';
import { ChatFactory } from './helpers';
import * as A from './chatActions';

const mergeMessages = (messages, newMessages) => {
  const existingMessageIds = messages.map((m) => m.messageId);
  const uniqNewMessages = newMessages.filter((m) => !existingMessageIds.includes(m.messageId));
  return messages.concat(uniqNewMessages);
};

function mergeThread(oldValue, newValue, key) {
  return key === 'messages' ? mergeMessages(oldValue, newValue) : newValue;
}

export const reducer = {
  chat(state: ChatStore = ChatFactory(), action: mixed) {
    switch (action.type) {
      case A.fetchChatUsers.success:
      case A.fetchCompanyChatUsers.success:
      case A.fetchDocumentChatUsers.success:
        return state.set('users', action.payload);

      case A.chatResetUsersAction.type:
        return state.set('users', List());

      case A.chatConnectAction.success:
        return state.set('connectionStatus', CONNECTED);

      case A.onInitOneSignalAction.type:
        return state.set('isOneSignalInit', true);

      case A.chatGetThreadsAction.success:
      case A.chatGetWorkerThreadsAction.success:
        return state
          .update('threads', (t) =>
            t.mergeDeep(action.payload).map((i) =>
              // items are duplicated during merge, its critical for messages
              // less critical for members
              i
                .update('members', (m) =>
                  m
                    .groupBy((x) => x.userId)
                    .map((x) => x.first())
                    .toList(),
                )
                .update('messages', (m) =>
                  m
                    .groupBy((x) => x.messageId)
                    .map((x) => x.first())
                    .toList(),
                ),
            ),
          )
          .set('loaded', true);

      case A.createChannelAction.success:
        return state
          .setIn(['messageQuery', action.payload.name], action.payload.messageQuery)
          .setIn(['threads', action.payload.name], action.payload.messages)
          .setIn(['channels', action.payload.name], action.payload.channel);

      case A.sendMessageAction.success:
        return state.updateIn(['threads', action.payload.name], (u) =>
          u ? u.mergeWith(mergeThread, action.payload) : action.payload,
        );

      case A.markChannelAsReadAction.type:
        return state.setIn(['threads', action.payload.name, 'unreadMessageCount'], 0);

      case A.receiveMessageAction.success:
        return state.updateIn(['threads', action.payload.name], (u) =>
          u ? u.mergeWith(mergeThread, action.payload) : action.payload,
        );

      case A.documentAddThreadAction.type:
        return state
          .updateIn(['company', action.payload.companyId], (c) => (c || new Set()).add(action.payload.name))
          .updateIn(['threads', action.payload.name], (l) => (l || new Set()).add(action.payload.message))
          .setIn(['channels', action.payload.name], action.payload.channel);

      case A.setActiveChannelAction.type:
        return state.set('activeChannel', action.payload.name);

      case A.setActiveChannelForceAction.type:
        return state.set('activeChannel', action.payload.name).set('activeChannelForce', action.payload.name);

      case A.removeActiveChannelForceAction.type:
        return state.set('activeChannelForce', null);

      case A.getPreviousMessageAction.success:
        return state
          .updateIn(['threads', action.payload.name], (u) =>
            u ? u.mergeWith(mergeThread, action.payload) : action.payload,
          )
          .setIn(['messageQuery', action.payload.name], action.query);

      case A.getMessagesAction.success:
        return state
          .updateIn(['threads', action.payload.name], (u) =>
            u ? u.mergeWith(mergeThread, action.payload) : action.payload,
          )
          .setIn(['threads', action.payload.name, 'unreadMessageCount'], 0)
          .setIn(['messageQuery', action.payload.name], action.query);

      case A.getMessagesAction.failure:
        return state.setIn(['messageQuery', action.payload], Map({ isLoading: false }));

      case A.setMessageHandlerIdAction.type:
        return state.set('messageHandlerId', action.payload.id);

      case A.setChatConnectionStatusAction.type:
        return state.set('connectionStatus', action.payload);

      case A.terminateChannelsAction.success:
        return state
          .update('threads', (u) => u.removeAll(action.payload))
          .update('activeChannel', (u) => (action.payload.includes(u) ? null : u))
          .update('messageQuery', (u) => u.removeAll(action.payload));

      case A.setAllowedChatWorkerAction.type:
        return state.set('allowedWorker', action.payload);

      case A.chatReadyToRenderAction.type:
        return state.set('readyToRenderOnDocument', action.payload);

      case A.setOnPageAction.type:
        return state.set('isOnPage', action.payload);

      case A.setOpenAction.type:
        return state.set('isOpen', action.payload);

      case signOutAction.success:
        return (
          ChatFactory()
            // after signOut oneSIgnal remains initialized
            .set('isOneSignalInit', true)
        );

      default:
        return state;
    }
  },
};
