// @flow
import React from 'react';
import { SelectPanelContainer } from './StyledComponents';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

type TProps = {
  isVisible: boolean,
  count: number,
  children: React$Node,
};

const SelectPanelWrapper: React$StatelessFunctionalComponent<TProps> = ({ isVisible, count, children, ...rest }) => (
  <SelectPanelContainer isVisible={isVisible} {...rest}>
    {count && (
      <Box
        bgcolor="primary.main"
        color="common.white"
        px={2}
        borderRadius="8px 0 0 8px"
        display="flex"
        alignItems="center"
      >
        <Typography variant="h6">{count}</Typography>
      </Box>
    )}
    {children}
  </SelectPanelContainer>
);
export default SelectPanelWrapper;
