/* @flow */
import * as React from 'react';
import { withStyles } from '@mui/styles';
import cx from 'classnames';
import { is, type List } from 'immutable';
import { getDate, fullName } from './helper';
import type { IMessage } from './types.js.flow';
import replay from './replay.svg';
import elements from 'components/elements';

const sheet = (theme) => ({
  message: {
    padding: [8, 12],
    lineHeight: 1.6667,
    fontSize: 12,
    display: 'inline-block',
    maxWidth: '100%',
  },
  item: {
    margin: [20, 0],
    '&:first-child': {
      marginTop: 0,
    },
  },
  list: {
    padding: [5, 10],
    overflowY: 'auto',
    maxHeight: 140,
    border: '1px solid #D3D6D9',
    borderTopWidth: 0,
    borderRadius: [0, 0, 4, 4],
  },
  messageWrapper: {},
  foreing: {
    '& $message': {
      borderRadius: [8, 8, 8, 0],
      backgroundColor: '#DEE2E6',
      color: '#171A1C',
      maxWidth: 'calc(100% - 40px)',
      width: 'calc(100% - 40px)',
    },
    '& $owner': {
      color: theme.colors.secondaryDark,
    },
    '& $messageWrapper': {
      display: 'flex',
      alignItems: 'center',
    },
  },
  my: {
    textAlign: 'right',
    '& $message': {
      borderRadius: [8, 8, 0, 8],
      color: '#fff',
      backgroundColor: theme.colors.primaryLight,
      whiteSpace: 'pre-wrap',
      textAlign: 'left',
    },
    '& $owner': {
      color: theme.colors.primaryLight,
    },
    '& $detail': {
      justifyContent: 'flex-end',
    },
  },
  owner: {
    fontWeight: 600,
    margin: [0, 10],
  },
  detail: {
    display: 'flex',
    fontSize: 12,
  },
  timestamp: {
    color: '#8C9BA5',
  },
  replayBtn: {
    width: 16,
    height: 16,
    backgroundColor: theme.colors.secondaryDark,
    background: `url(${replay}) scroll no-repeat center`,
    borderRadius: 2,
    marginLeft: 10,
    border: '0 none',
    cursor: 'pointer',
    padding: 0,
    '& > span': {
      position: 'relative',
      top: 14,
      left: -8,
      fontSize: '12px',
      color: theme.colors.secondaryDark,
    },
  },
});

type Props = {
  classes: {
    [key: string]: string,
  },
  listClassName?: string,
  list: List<IMessage>,
  onReplay?: (idx: number) => void,
};

function scrollToEnd(container: ?HTMLElement) {
  if (container) {
    // eslint-disable-next-line no-param-reassign
    container.scrollTop = container.scrollHeight;
  }
}

class MessageList extends React.Component<Props> {
  componentDidMount() {
    scrollToEnd(this.container);
  }

  componentDidUpdate(prevProps) {
    if (!is(prevProps.list, this.props.list)) {
      scrollToEnd(this.container);
    }
  }

  refProxy = (el: ?HTMLElement) => {
    this.container = el;
  };

  container: ?HTMLElement;

  render() {
    const { classes, listClassName, list, onReplay } = this.props;
    return (
      <ul className={cx(classes.list, listClassName)} ref={this.refProxy}>
        {list.map((e, i) => (
          <li key={e.id} className={cx(classes.item, classes[e.owner])}>
            <div className={classes.detail}>
              <div className={classes.owner}>{fullName(e)}</div>
              <div className={classes.timestamp}>{getDate(e.timestamp)}</div>
            </div>
            <div className={classes.messageWrapper}>
              <p className={cx(classes.message)} data-element={elements.je.request.message}>
                {e.message}
              </p>
              {typeof onReplay === 'function' && e.owner === 'foreing' ? (
                <button
                  type="button"
                  className={classes.replayBtn}
                  onClick={() => onReplay(i)}
                  data-element={elements.je.request.btn.reply}
                >
                  <span>Reply</span>
                </button>
              ) : null}
            </div>
          </li>
        ))}
      </ul>
    );
  }
}

export default withStyles(sheet)(MessageList);
