import { styled } from '@mui/material/styles';

export const ResizeHandler = styled('button', { shouldForwardProp: (propName) => propName !== 'active' })(
  ({ theme, active }) => ({
    height: 'calc(100% + 3px)',
    width: 8,
    position: 'absolute',
    top: -1,
    right: -2,
    cursor: 'col-resize',
    zIndex: 10,
    outline: 'none',
    border: 'none',
    background: 'transparent',

    '&:after': {
      content: '""',
      position: 'absolute',
      top: 0,
      right: 0,
      height: '100%',
      width: 1,
      borderRight: `2px solid ${theme.palette.primary.main}`,
      zIndex: 3,
      display: 'none',

      ...(active && {
        display: 'block',
      }),
    },
  }),
);
