// @flow

import React, { useRef } from 'react';
import useStyles from './sheet';

export default ({ isActive, divRef }: any) => {
  const classes = useStyles();
  const [isDragging, setIsDragging] = React.useState(false);

  const translateRef = useRef({ x: 0, y: 0 });
  const initialRef = useRef({ x: 0, y: 0 });
  const offsetRef = useRef({ x: 0, y: 0 });
  const docCoordinatesRef = useRef({ clientX: 0, clientY: 0, offsetX: 0, offsetY: 0 });

  const setTranslate = React.useCallback(
    (x: number, y: number) => {
      translateRef.current = {
        x,
        y,
      };
      divRef.current.style.transform = `translateX(${x}px) translateY(${y}px)`;
    },
    [divRef],
  );

  const onDragStart = (e: MouseEvent) => {
    e.dataTransfer.effectAllowed = 'move';
    setIsDragging(true);
    window.requestAnimationFrame(() => {
      divRef.current.classList.add(classes.hide);
    });

    const x = e.clientX - offsetRef.current.x;
    const y = e.clientY - offsetRef.current.y;

    initialRef.current = { x, y };
  };

  const onDragEnd = () => {
    setIsDragging(false);
    const { x, y } = offsetRef.current;
    setTranslate(x, y);
    window.requestAnimationFrame(() => {
      divRef.current.classList.remove(classes.hide);
    });
    initialRef.current = translateRef.current;
  };
  const onDrag = React.useCallback(() => {
    if (!isActive) return;
    //* * In FF ondrag event  clientX, clientY, offsetX are aqual 0, we need to use document  */
    //* * https://bugzilla.mozilla.org/show_bug.cgi?id=505521   */
    const e = docCoordinatesRef.current;
    const x = e.clientX - initialRef.current.x;
    const y = e.clientY - initialRef.current.y;

    const limitDragArea = e.offsetX < 0 || e.offsetX === e.pageX || e.offsetY < 0 || e.offsetY === e.pageY;

    if (limitDragArea || (!e.clientX && !e.clientY)) return;
    offsetRef.current = { x, y };
  }, [isActive]);

  const resetPosition = React.useCallback(() => {
    const [x, y] = [0, 0];
    setTranslate(x, y);
    initialRef.current = { x, y };
    offsetRef.current = { x, y };
  }, [setTranslate]);

  const onDragOver = (e: MouseEvent) => {
    e.preventDefault();
    const { clientX, clientY, offsetX, offsetY } = e;
    docCoordinatesRef.current = { clientX, clientY, offsetX, offsetY };
  };

  React.useEffect(() => {
    if (isDragging) {
      document.addEventListener('dragover', onDragOver);
    }
    return () => {
      document.removeEventListener('dragover', onDragOver);
    };
  }, [isDragging]);

  return {
    onDragStart,
    onDragEnd,
    onDrag,
    isDragging,
    resetPosition,
  };
};
