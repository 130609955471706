// @flow
import React, { PureComponent, type Node } from 'react';
import { FormattedMessage } from 'react-intl';

import Popup from 'components/Popup';

import cx from 'classnames';
import { withStyles } from '@mui/styles';
import sheet from './sheet';

export type Props = {
  classes: {
    [key: string]: string,
  },
  onConfirm: (e: SyntheticMouseEvent<HTMLButtonElement>) => void,
  cancel: (e: SyntheticMouseEvent<HTMLButtonElement>) => void,
  title?: React$Node | string,
  confirmClass?: string,
  placeholder?: Node | string,
  confirmText?: string,
  cancelText?: string,
  keyWords?: Array<string>,
  disableAction: boolean,
  red: boolean,
  blue: boolean,
  isWarning: boolean,
  isActionRequired: boolean,
  width?: number,
  btnWrapperClass: ?string,
  isAutoConfirm?: boolean,
  disableMove?: boolean,
};

type State = {
  keyWordInputText: string,
};

class ConfirmationModal extends PureComponent<Props, State> {
  static defaultProps = {
    isWarning: false,
    isActionRequired: false,
  };

  state = {
    keyWordInputText: '',
  };

  componentDidMount(): * {
    this.autoConfirm();
  }

  autoConfirm = () => {
    const { isAutoConfirm = false, onConfirm } = this.props;
    if (isAutoConfirm) onConfirm();
  };

  isDisable = () => {
    const { disableAction } = this.props;
    return disableAction || !this.keywordTest();
  };

  keywordTest = () => {
    const { keyWords } = this.props;
    const { keyWordInputText } = this.state;
    return keyWords && keyWords.length
      ? keyWords.map((w) => w.toLowerCase()).includes(keyWordInputText.split(' ').join('').toLowerCase())
      : true;
  };

  render() {
    const {
      classes,
      title,
      placeholder,
      confirmText,
      cancelText,
      confirmClass,
      cancel,
      onConfirm,
      red,
      blue,
      isWarning,
      isActionRequired,
      keyWords,
      btnWrapperClass,
      isAutoConfirm = false,
      disableMove = false,
    } = this.props;

    const { keyWordInputText } = this.state;

    // dont render model if auto confirm enable
    if (isAutoConfirm) return null;

    return (
      <Popup
        cancel={cancel}
        close
        className={cx(classes.green, { [classes.red]: red, [classes.blue]: blue })}
        disableMove={disableMove}
      >
        <div className={classes.modal}>
          <div className={classes.title}>
            {typeof title !== 'undefined' ? (
              title
            ) : (
              <FormattedMessage id="components.confirmModal.title" defaultMessage="Are you sure?" />
            )}
          </div>
          <div className={classes.placeholder}>
            {placeholder}
            {isActionRequired && (
              <div>
                <FormattedMessage
                  id="components.confirmModal.placeholder"
                  defaultMessage="This action can not be undone."
                />
                !!!
              </div>
            )}
          </div>

          {keyWords && !!keyWords.length && (
            <div className={classes.keyWordBox}>
              <input
                className={classes.keyWordInput}
                onChange={(e) => {
                  this.setState({ keyWordInputText: e.currentTarget.value });
                }}
                value={keyWordInputText}
              />
            </div>
          )}
          <div className={cx(classes.btns, btnWrapperClass)}>
            {!isWarning && (
              <button className={classes.btnBordered} onClick={cancel}>
                {typeof cancelText !== 'undefined' ? (
                  cancelText
                ) : (
                  <FormattedMessage id="components.confirmModal.cancelText" defaultMessage="Cancel" />
                )}
              </button>
            )}
            <button
              className={cx(confirmClass || classes.btn, {
                [classes.confirmRedBtn]: red,
                [classes.confirmBlueBtn]: blue,
              })}
              disabled={this.isDisable()}
              onClick={onConfirm}
            >
              {typeof confirmText !== 'undefined' ? (
                confirmText
              ) : (
                <FormattedMessage id="components.confirmModal.confirmText" defaultMessage="Confirm" />
              )}
            </button>
          </div>
        </div>
      </Popup>
    );
  }
}

export default withStyles(sheet)(ConfirmationModal);
