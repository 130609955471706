// @flow
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { usePopupState, bindTrigger, bindMenu } from 'material-ui-popup-state/hooks';
import { isJEEditingSelector, isJELoadingSelector } from 'domain/journal/selectors';
import { currentConnectedERPselector } from 'domain/settings/settingsSelector';

import Button from '@mui/material/Button';
import ButtonGroup, { type ButtonGroupProps as MuiButtonGroupProps } from '@mui/material/ButtonGroup';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Typography from '@mui/material/Typography';

import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';
import ArrowDropUpOutlinedIcon from '@mui/icons-material/ArrowDropUpOutlined';
import UpdateIcon from '@mui/icons-material/Update';

import { ERP_NAMES } from 'domain/companies';
import { PublishTypes } from '../DropdownButton/config';

type TOptions = {
  key: string,
  title: {
    id: string,
    defaultMessage: string,
  },
  onClick: () => void,
};

export type TSplitButton = {
  options: $ReadOnlyArray<TOptions>,
  activeActionKey?: string,
} & MuiButtonGroupProps;

const mapStateToProps = (state) => ({
  activeErp: currentConnectedERPselector(state),
  isJELoading: isJELoadingSelector(state),
  isJEEditing: isJEEditingSelector(state),
});

const ERPS_FOR_SCHEDULE_PUBLISH_BY_DEFAULT = [
  ERP_NAMES.sap,
  ERP_NAMES.netsuite,
  ERP_NAMES.priorityPro,
  ERP_NAMES.priorityProSQL,
  ERP_NAMES.acumatica,
];
const SplitButton: React$StatelessFunctionalComponent<TSplitButton> = ({
  options,
  activeActionKey,
  disabled,
  variant = 'contained',
  color = 'primary',
}) => {
  const { formatMessage } = useIntl();
  const popupState = usePopupState({ variant: 'popover', popupId: 'PublishButton' });
  const { activeErp, isJELoading, isJEEditing } = useSelector(mapStateToProps);

  const defaultOption = useMemo(
    () =>
      options.filter(
        (o) =>
          o.key ===
          (ERPS_FOR_SCHEDULE_PUBLISH_BY_DEFAULT.includes(activeErp)
            ? PublishTypes.SchedulePublish
            : PublishTypes.Publish),
      )[0],
    [options, activeErp],
  );

  const handleMenuItemClick = (cb) => {
    cb();
    popupState.close();
  };

  const currentState = useMemo(() => {
    const { title, onClick } = options.filter(({ key }) => key === activeActionKey)[0] || defaultOption;
    return { title: typeof title === 'string' ? title : formatMessage(title), onClick };
  }, [options, formatMessage, activeActionKey, defaultOption]);

  return (
    <>
      <ButtonGroup variant={variant} color={color} disabled={disabled || isJELoading || isJEEditing}>
        <Button onClick={currentState.onClick} sx={{ minWidth: '18ch' }} startIcon={disabled && <UpdateIcon />}>
          {disabled
            ? formatMessage({ id: 'document.show.btns.publishing', defaultMessage: 'PUBLISHING' })
            : currentState.title}
        </Button>
        <Button {...bindTrigger(popupState)}>
          {popupState.isOpen ? <ArrowDropUpOutlinedIcon /> : <ArrowDropDownOutlinedIcon />}
        </Button>
      </ButtonGroup>

      <Menu
        autoFocus
        transformOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
        slotProps={{ paper: { sx: { mt: -1, minWidth: '18ch' } } }}
        {...bindMenu(popupState)}
      >
        {options.map(({ key, title, onClick }) => (
          <MenuItem
            key={key}
            selected={key === activeActionKey}
            onClick={() => handleMenuItemClick(onClick)}
            disabled={disabled}
          >
            <Typography variant="body2" align="center">
              {typeof title === 'string' ? title : formatMessage(title)}
            </Typography>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default SplitButton;
