// @flow
import React from 'react';
import { useConfirm } from 'material-ui-confirm';
import { useIntl } from 'react-intl';

import Button, { type ButtonProps as MuiButtonProps } from '@mui/material/Button';
import IconButton, { type IconButtonProps as MuiIconButtonProps } from '@mui/material/IconButton';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import List from './components/List';

type TTile = {
  id: string,
  defaultMessage: string,
};

export type TOptions = {
  key: string,
  title: TTile,
  onClick: () => void,
  submenu: $ReadOnlyArray<TOptions>,
  divider: boolean,
  disabled: boolean,
  isDanger: boolean,
  isHidden: boolean,
  htmlFor?: string,
  icon?: React$Node,
  confirmOptions?: {
    title?: string,
    description?: string,
    confirmationText?: string,
    cancellationText?: string,
    isAutoConfirm?: boolean,
    cancellationButtonProps?: {
      color: string,
    },
  },
};

export type TDropdownButton = {
  title?: TTile,
  options: $ReadOnlyArray<TOptions>,
  BtnIcon?: React$Node,
  iconButtonClassName?: string,
  IconButtonProps?: MuiIconButtonProps,
  ButtonProps?: MuiButtonProps,
  placement?: string,
  popperModifiers?: Array<*>,
  color?: string,
};

const DropdownButton: React$StatelessFunctionalComponent<TDropdownButton> = ({
  options,
  title: btnTitle,
  disabled,
  variant = 'contained',
  color = 'primary',
  BtnIcon,
  IconButtonProps = {},
  ButtonProps = {},
  iconButtonClassName,
  placement,
  popperModifiers,
}) => {
  const confirm = useConfirm();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const { formatMessage } = useIntl();

  const menuItemClick = (cb) => {
    cb();
    setOpen(false);
  };

  const handleMenuItemClick = (cb, confirmOptions) => {
    if (confirmOptions && !confirmOptions.isAutoConfirm) {
      confirm({ ...confirmOptions }).then(() => {
        menuItemClick(cb);
      });
    } else {
      menuItemClick(cb);
    }
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  return (
    <>
      {BtnIcon ? (
        <IconButton
          aria-label="more"
          onClick={handleToggle}
          disabled={disabled}
          size="small"
          ref={anchorRef}
          className={iconButtonClassName}
          color={color}
          {...IconButtonProps}
        >
          {BtnIcon}
        </IconButton>
      ) : (
        <Button
          onClick={handleToggle}
          variant={variant}
          color={color}
          disabled={disabled}
          size="small"
          ref={anchorRef}
          endIcon={<ArrowDropDownIcon />}
          {...ButtonProps}
        >
          {formatMessage(btnTitle)}
        </Button>
      )}
      <List
        options={options}
        handleMenuItemClick={handleMenuItemClick}
        formatMessage={formatMessage}
        handleClose={handleClose}
        anchorRef={anchorRef}
        open={open}
        placement={placement}
        popperModifiers={popperModifiers}
      />
    </>
  );
};

export default DropdownButton;
