/* @flow */
export default {
  errorText: {
    color: '#dc233b',
    fontSize: 12,
  },

  left: {
    textAlign: 'left',
  },

  center: {
    textAlign: 'center',
  },

  right: {
    textAlign: 'right',
  },

  isWide: {
    display: 'block',
  },
};
