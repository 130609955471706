/* @flow */
import React from 'react';

import { compose, Dispatch } from 'redux';
import { removeAppStatusAction, appStatusesSelector } from 'domain/env';
import { type StatusMessage } from 'domain/env/types.js.flow';

// styles
import sheet from './sheet';
import { withStyles } from '@mui/styles';

// intl
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

type Props = {
  classes: {
    [key: string]: string,
  },
  removeAppStatus: Dispatch<typeof removeAppStatusAction>,
  appStatuses: StatusMessage,
};

const AppStatusBar: React$StatelessFunctionalComponent<Props> = ({ classes, removeAppStatus, appStatuses }: Props) => {
  const close = (appStatusId: string) => () => {
    removeAppStatus(appStatusId);
  };

  return (
    <div className={classes.box}>
      {appStatuses.map((appStatus) => (
        <div key={appStatus.id} className={classes.status}>
          <button className={classes.close} type="button" onClick={close(appStatus.id)} />
          <FormattedMessage {...appStatus} />
        </div>
      ))}
    </div>
  );
};

const mapStateToProps = (state) => ({
  appStatuses: appStatusesSelector(state),
});

const mapDispatchToProps = {
  removeAppStatus: removeAppStatusAction,
};

export default compose(withStyles(sheet), connect(mapStateToProps, mapDispatchToProps))(AppStatusBar);
