import { type RecordFactory, Record } from 'immutable';
import type {
  TUserOptionRaw,
  TEventOptionRaw,
  TChipTextRaw,
} from 'components/mui/Layouts/components/EventsHistoryPanel/components/FiltersBlock/types.js.flow';

export const OptionUserFactory: RecordFactory<TUserOptionRaw> = new Record({
  label: '',
  value: '',
  picture: null,
  type: 'user',
});

export const OptionEventFactory: RecordFactory<TEventOptionRaw> = new Record({
  label: '',
  value: '',
  type: 'event',
});

export const ChipTextFactory: RecordFactory<TChipTextRaw> = new Record({
  label: '',
  value: '',
  type: 'text',
});

export const ChipDateFactory: RecordFactory<TChipTextRaw> = new Record({
  label: '',
  value: '',
  type: 'date',
});
