/* @flow */
import { List } from 'immutable';
import * as A from './actions';

export const reducer = {
  currencies(state: List = new List(), action: any) {
    switch (action.type) {
      case A.getCurrenciesAction.success:
        return action.payload;

      default:
        return state;
    }
  },
  timezones(state: List = new List(), action: any) {
    switch (action.type) {
      case A.getTimezonesAction.success:
        return action.payload;

      default:
        return state;
    }
  },
};
