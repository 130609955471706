// @flow
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { rtlEnable } from 'domain/env';

import { ThemeProvider, createTheme } from '@mui/material/styles';
import { StylesProvider } from '@mui/styles';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import { prefixer } from 'stylis';
import rtlPlugin from 'stylis-plugin-rtl';
import jss from 'lib/jss';

import { ConfirmProvider } from 'material-ui-confirm';
import useDefaultThemeConfig from 'components/LabeledThemeProvider/useDefaultThemeConfig';
import useMuiConfirmTheme from 'components/LabeledThemeProvider/useMuiConfirmTheme';

// this help us use sx and system props for some components that support them(ml, mr, paddingLeft, paddingRight etc) for rtl
// https://github.com/mui/material-ui/issues/24803#issuecomment-774686550
// https://codesandbox.io/s/demo-material-demo-forked-g0psc?file=/index.js:476-525 - this example used
// https://codesandbox.io/s/flamboyant-shirley-qj7ytw?file=/src/Demo.tsx
export const cacheRtl = createCache({
  key: 'muirtl',
  stylisPlugins: [prefixer, rtlPlugin],
  prepend: true,
});

export const cacheLtr = createCache({
  key: 'muiltr',
  prepend: true,
});

type Props = {|
  children: React$Node,
|};

const jssConfig = jss(true);

const LabeledThemeProvider: React$StatelessFunctionalComponent<Props> = ({ children }: Props) => {
  const isRtl = useSelector(rtlEnable);
  const defaultThemeConfig = useDefaultThemeConfig();
  const theme = useMemo(() => createTheme(defaultThemeConfig), [defaultThemeConfig]);
  const muiConfirmDefaultOptions = useMuiConfirmTheme();

  return (
    <StylesProvider jss={jssConfig}>
      <CacheProvider value={isRtl ? cacheRtl : cacheLtr}>
        <ThemeProvider theme={theme}>
          <ConfirmProvider defaultOptions={muiConfirmDefaultOptions}>{children}</ConfirmProvider>
        </ThemeProvider>
      </CacheProvider>
    </StylesProvider>
  );
};
export default LabeledThemeProvider;
