// @flow
import React from 'react';
import { Link } from 'react-router-dom';
import { useIntl } from 'react-intl';

import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import LayoutServicePage from 'components/mui/Layouts/LayoutServicePage';

import notFoundImg from '../../images/notFound.png';
import { grey } from '@mui/material/colors';

type TTranslateObj = {
  id: string,
  defaultMessage: string,
};

type TNotFoundContainer = {
  link: string,
  title: TTranslateObj,
  description: TTranslateObj,
  buttonText: TTranslateObj,
};

const NotFoundContainer: React$StatelessFunctionalComponent<TNotFoundContainer> = ({
  link,
  title,
  description,
  buttonText,
}) => {
  const { formatMessage } = useIntl();

  return (
    <LayoutServicePage>
      <Stack alignItems="center" justifyContent="center" spacing={1}>
        <img src={notFoundImg} alt="not-found" />
        <Typography variant="h5">{formatMessage(title)}</Typography>
        <Typography variant="body1" color={grey[600]} maxWidth={600} textAlign="center">
          {formatMessage(description)}
        </Typography>
        <Button component={Link} to={link} sx={{ mt: '32px !important' }}>
          {formatMessage(buttonText)}
        </Button>
      </Stack>
    </LayoutServicePage>
  );
};

export default NotFoundContainer;
