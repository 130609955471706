// @flow
import React, { useState } from 'react';

import Autocomplete from '@mui/material/Autocomplete';

import TextFieldForm from 'components/mui/Form/TextField/TextFieldForm';
import EmailTag from 'pages/common/Dialog/components/EmailTag';
import UserOptionLabel from 'pages/common/Dialog/components/UserOptionLabel';

import type { FieldProps } from 'redux-form/lib/FieldProps.types.js.flow';

type Props = {
  emailOptions: Array<string>,
  users: { [userId: string]: { picture: string, username: string } },
  label?: string,
  meta: $PropertyType<FieldProps, 'meta'>,
  input: {
    value: string,
    name: string,
    onChange: (value: string) => void,
    onBlur: (value: string) => void,
  },
};

const EMAILS_LIMIT = 12;

const EmailAutocomplete: React$StatelessFunctionalComponent<Props> = ({
  emailOptions,
  users,
  input: { value, onChange, onBlur, ...inputRest },
  label: inputLabel,
  meta,
  ...rest
}) => {
  const [inputValue, setInputValue] = useState('');

  return (
    <Autocomplete
      {...rest}
      multiple
      autoHighlight
      id={inputLabel}
      value={value || []}
      onChange={(event, newValue) => onChange(newValue)}
      inputValue={inputValue}
      onBlur={() => {
        onBlur(value);
        if (inputValue) {
          onChange([...value, inputValue]);
          setInputValue('');
        }
      }}
      onInputChange={(e, newValue) => {
        if (value.length + 1 <= EMAILS_LIMIT) {
          setInputValue(newValue);
        }
      }}
      filterSelectedOptions
      options={value.length + 1 <= EMAILS_LIMIT ? emailOptions : []}
      renderOption={(props, tag) => <UserOptionLabel tag={tag} users={users} {...props} />}
      getOptionLabel={(option) => (users[option] ? `${users[option].username} ${users[option].userId}` : option)}
      freeSolo
      renderTags={(values, getTagProps) =>
        values.map((tag, index) => <EmailTag key={tag} tag={tag} users={users} {...getTagProps({ index })} />)
      }
      renderInput={(params) => (
        <TextFieldForm {...params} {...rest} input={inputRest} label={inputLabel} meta={meta} value={inputValue} />
      )}
    />
  );
};

export default EmailAutocomplete;
