// @flow

import React from 'react';

import CircularProgress, { type CircularProgressProps } from '@mui/material/CircularProgress';
import BackdropMui, { type BackdropProps as TBackdropProps } from '@mui/material/Backdrop';

import { styled, alpha } from '@mui/material/styles';

const Backdrop = styled(BackdropMui, {
  label: 'CircularProgressWithBackdrop',
  shouldForwardProp: (propName) => propName !== 'type',
})(({ theme, type }) => ({
  color: theme.palette.common.white,
  zIndex: theme.zIndex.drawer + 1,

  ...(type === 'light' && {
    color: theme.palette.primary.main,
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  }),
}));

type TCircularProgressWithBackdrop = {
  isOpen?: boolean,
  BackdropProps?: TBackdropProps,
  SpinnerProps?: CircularProgressProps,
  type?: 'light' | 'dark',
};

const CircularProgressWithBackdrop: React$StatelessFunctionalComponent<TCircularProgressWithBackdrop> = ({
  isOpen = true,
  BackdropProps = {},
  SpinnerProps = {},
  invisible = false,
  type = 'dark',
}) => (
  <Backdrop open={isOpen} invisible={invisible} type={type} {...BackdropProps}>
    <CircularProgress color="inherit" {...SpinnerProps} />
  </Backdrop>
);

export default CircularProgressWithBackdrop;
