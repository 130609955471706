// flow
import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { buildSelector } from 'domain/env';
import { FormattedMessage } from 'react-intl';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const AuthBuildVersion = () => {
  const version = useSelector(buildSelector);

  return (
    <Box textAlign="center" mt={1}>
      <Typography variant="hint" color="text.secondary">
        <FormattedMessage id="auth.version" defaultMessage="version" /> {version}
      </Typography>
    </Box>
  );
};

export default memo(AuthBuildVersion);
