// @flow
import React, { memo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import { isOpenModalDocumentHotkeysSelector, toggleDocumentHotkeysModalAction } from 'domain/ui';
import { documentHotkeysSelector, documentHotkeysIsLoadingSelector } from 'domain/documents/documentSelector';

import Dialog from 'components/mui/Dialog';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import { keyCodeForUI } from 'lib/hotkeysHelpers';

const mapStateToProps = (state) => ({
  isOpenModalDocumentHotkeys: isOpenModalDocumentHotkeysSelector(state),
  hotkeys: documentHotkeysSelector(state),
  isLoading: documentHotkeysIsLoadingSelector(state),
});

const DialogKeyboardHotkeys = () => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  const { isOpenModalDocumentHotkeys, hotkeys, isLoading } = useSelector(mapStateToProps);

  const onClose = useCallback(() => {
    dispatch(toggleDocumentHotkeysModalAction());
  }, [dispatch]);

  return (
    <Dialog
      open={isOpenModalDocumentHotkeys}
      onClose={onClose}
      maxWidth="sm"
      title={formatMessage({ id: 'modal.document.hotkeys.title', defaultMessage: 'Keyboard Shortcuts' })}
      cancelText={formatMessage({ id: 'button.close', defaultMessage: 'Close' })}
      okBtnProps={{ sx: { display: 'none' } }}
    >
      {isLoading && (
        <Box textAlign="center" my={3}>
          <CircularProgress />
        </Box>
      )}
      {!isLoading && hotkeys.size > 0 && (
        <>
          {hotkeys.map((item, index) => (
            <Grid
              container
              key={index.toString()}
              mt={2}
              flexWrap="nowrap"
              alignItems="center"
              justifyContent="space-between"
            >
              <Grid item flex={1}>
                <Typography variand="body2">{item.description}</Typography>
              </Grid>
              <Grid item>
                {item.keys.map((combinations) => (
                  <Box
                    key={combinations.join()}
                    minWidth={40}
                    height={40}
                    fontSize={14}
                    lineHeight="40px"
                    textAlign="center"
                    borderRadius={2}
                    px={1}
                    bgcolor="grey.100"
                  >
                    {combinations.map((k) => keyCodeForUI.get(k)).join('+')}
                  </Box>
                ))}
              </Grid>
            </Grid>
          ))}
        </>
      )}
      {hotkeys.size === 0 && !isLoading && (
        <Box id="empty.hotkeys" defaultText="No hotkeys" py={3}>
          {formatMessage({ id: 'empty.hotkeys', defaultMessage: 'No hotkeys' })}
        </Box>
      )}
    </Dialog>
  );
};

export default memo(DialogKeyboardHotkeys);
