// @flow

// This hook differs from the one on the web in that:
// 1. We pass the id of the next and previous documents, since navigation works differently for mobile
// 2. For mobile, all files are loaded as pdf, there can't be excel, so there are no such checks here
// 3. Supplier user check is missing

import React, { useEffect, useCallback, useMemo } from 'react';
import hash from 'object-hash';
import fetchProgress from 'fetch-progress';

import { saveCachedDocumentAction } from 'domain/documents/documentsActions';
import { cachedDocumentSelector, documentLastModifiedDateSelector } from 'domain/documents/documentSelector';
import { CachedDocumentFactory } from 'domain/documents/factories';
import { useSelector, useDispatch } from 'react-redux';
import Api from 'domain/api';

import tokenKeeper from 'lib/apiTokenKeeper';
import { useGetDocData, getStore, createHeaders } from 'pages/document/DocumentComponent/usePreloadDocuments';

type TProps = {
  currentDocumentId: string,
  nextDocId: string,
  prevDocId: string,
  signatureStatus: string,
};

const usePreloadDocumentsMobile = ({ currentDocumentId, nextDocId, prevDocId, signatureStatus }: TProps) => {
  const dispatch = useDispatch();

  const companyId = tokenKeeper.getCompany();
  const lastModifiedDate = useSelector(documentLastModifiedDateSelector);

  const isUnsignedNext = useGetDocData(nextDocId, true);
  const isUnsignedPrev = useGetDocData(prevDocId, true);

  const getParams = useCallback(
    ({ documentID, isUnsigned }) => {
      const urlParams = {
        companyId,
        documentID,
      };

      return isUnsigned ? { ...urlParams, unsigned: 1 } : urlParams;
    },
    [companyId],
  );

  const prevDocUrlParams = useMemo(
    () => getParams({ documentID: prevDocId, isUnsigned: isUnsignedPrev }),
    [getParams, prevDocId, isUnsignedPrev],
  );
  const nextDocUrlParams = useMemo(
    () => getParams({ documentID: nextDocId, isUnsigned: isUnsignedNext }),
    [getParams, nextDocId, isUnsignedNext],
  );

  const currentDocUrlParams = useMemo(
    () =>
      getParams({
        documentID: currentDocumentId,
        isUnsigned: signatureStatus && ['disabled', 'hide'].includes(signatureStatus),
      }),
    [getParams, currentDocumentId, signatureStatus],
  );

  const currentDocHash = hash(currentDocUrlParams);
  const nextDocHash = hash(nextDocUrlParams);
  const prevDocHash = hash(prevDocUrlParams);

  const isDocumentCached = useCallback(async (url) => {
    const cache = await getStore();
    return cache.match(url);
  }, []);

  const currentDocumentProgress = useSelector(cachedDocumentSelector(currentDocHash)).downloadProgress;

  const [currentCachedDocument, setCurrentCachedDocument] = React.useState({ blob: null, hash: '' });
  const isCurrentDocumentCached = !!currentCachedDocument.blob && currentCachedDocument.hash === currentDocHash;

  const downloadDoc = useCallback(
    async ({ documentUrl, docHash, token, lastModifiedDate }) => {
      const cache = await getStore();

      const response = await cache.match(documentUrl);
      if (response) {
        const isOlderCache =
          lastModifiedDate && Date.parse(lastModifiedDate) > Date.parse(response.headers.get('date'));
        const isInvalidCache = isOlderCache || response.status !== 200;
        if (isInvalidCache) {
          cache.delete(documentUrl);
        } else {
          dispatch(
            saveCachedDocumentAction({
              data: CachedDocumentFactory({ hash: docHash, downloadProgress: 100 }),
            }),
          );
          return response;
        }
      }

      const res = await window.fetch(documentUrl, createHeaders(token)).then(
        fetchProgress({
          onProgress({ total, transferred }) {
            if (transferred <= total) {
              dispatch(
                saveCachedDocumentAction({
                  data: CachedDocumentFactory({
                    hash: docHash,
                    downloadProgress: Math.round((transferred / total) * 100),
                  }),
                }),
              );
            } else {
              dispatch(
                saveCachedDocumentAction({
                  data: CachedDocumentFactory({ hash: docHash, downloadProgress: 100 }),
                }),
              );
            }
          },
        }),
      );
      const clonedRes = res.clone();
      const modifiedRes = new Response(clonedRes.body, clonedRes);
      modifiedRes.headers.set('docHash', docHash);
      // if appear error with put may be cache store full -> should remove elements
      await cache.put(documentUrl, modifiedRes);

      return res;
    },
    [dispatch],
  );

  useEffect(() => {
    const init = async () => {
      const token = await tokenKeeper.getToken();
      if (isCurrentDocumentCached) {
        const [nextDocumentUrl, prevDocumentUrl] = [nextDocUrlParams, prevDocUrlParams].map(Api.getDocumentUrl);

        if (nextDocId && token && !(await isDocumentCached(nextDocumentUrl))) {
          await downloadDoc({ documentUrl: nextDocumentUrl, token, docHash: nextDocHash });
        }
        if (prevDocId && prevDocId !== nextDocId && token && !(await isDocumentCached(prevDocumentUrl))) {
          await downloadDoc({ documentUrl: prevDocumentUrl, token, docHash: prevDocHash });
        }
      } else if (token && currentDocumentId) {
        const documentUrl = Api.getDocumentUrl(currentDocUrlParams);
        downloadDoc({ documentUrl, docHash: currentDocHash, token, lastModifiedDate })
          .then((res) => res.blob())
          .then((file) => setCurrentCachedDocument({ blob: file, hash: currentDocHash }));
      }
    };
    init();
  }, [
    nextDocId,
    prevDocId,
    downloadDoc,
    isCurrentDocumentCached,
    currentDocUrlParams,
    nextDocUrlParams,
    prevDocUrlParams,
    nextDocHash,
    prevDocHash,
    currentDocHash,
    isDocumentCached,
    lastModifiedDate,
    currentDocumentId,
  ]);
  return { blob: currentCachedDocument.blob, downloadProgress: currentDocumentProgress };
};

export default usePreloadDocumentsMobile;
