// @flow
import React from 'react';
import { useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { documentsDownloadDocumentsAction } from 'domain/documents';

import toast from 'components/Toast';
import Tooltip from 'components/mui/Tooltip';
import IconButton from '@mui/material/IconButton';

import ArrowDownwardOutlinedIcon from '@mui/icons-material/ArrowDownwardOutlined';

type TLockButton = {
  linkID: string,
};

const DownloadButton: React$StatelessFunctionalComponent<TLockButton> = ({ linkID }) => {
  const dispatch = useDispatch();

  const onClickDownload = async () => {
    dispatch(documentsDownloadDocumentsAction({ linkid: linkID }));

    toast.success(
      <FormattedMessage id="selectedPanel.download.toast" defaultMessage="The download will start in a few seconds" />,
      { autoClose: 7000 },
    );
  };

  return (
    <Tooltip
      t={{
        id: 'document.show.actions.downloadLinked',
        defaultMessage: 'Download Linked',
      }}
    >
      <IconButton color="primary" onClick={onClickDownload}>
        <ArrowDownwardOutlinedIcon />
      </IconButton>
    </Tooltip>
  );
};

export default DownloadButton;
