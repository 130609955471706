import Select from '@mui/material/Select';
import { styled } from '@mui/material/styles';

export const AgGridFilterDropdown = styled(Select)(
  ({ theme }) => ({
    borderRadius: theme.shape.borderRadius,
    textTransform: 'none',
    justifyContent: 'space-between',
    fontSize: theme.typography.pxToRem(14),
    height: '32px',
    width: '100%',
    '& .MuiSelect-select': {
      display: 'flex',
      alignItems: 'center',
      height: '100%',
    },
    '& .MuiMenuItem-root': {
      fontSize: theme.typography.pxToRem(14),
    },
  }),
);
