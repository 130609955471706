const white = '#fff';

export default (theme) => ({
  container: (provided) => ({
    ...provided,
    position: 'relative',
    [theme.direction === 'rtl' ? 'marginLeft' : 'marginRight']: 5,
    minWidth: 60,
    fontSize: 14,
    border: 'none',
    width: '100%',
  }),

  control: (provided) => ({
    ...provided,
    minHeight: 30,
    height: 30,
    boxShadow: 'none',
    borderColor: theme.colors.greyLight,
    borderRadius: 0,
    cursor: 'pointer',
    '&:hover': {
      borderColor: theme.colors.greyLight,
    },
  }),

  menu: (provided) => ({
    ...provided,
    boxShadow: 'none',
  }),

  menuList: (provided) => ({
    ...provided,
    position: 'absolute',
    background: white,
    borderTop: 'none',
    boxShadow: `0px 12px 24px rgba(${theme.colors.rgbPrimaryLight}, 0.1)`,
    minWidth: '100%',
  }),

  option: (provided, { isSelected, isFocused }) => {
    const selectedBC = isSelected ? theme.colors.primaryLight : null;
    const focusedBC = isFocused ? theme.colors.primaryLightest : null;

    return {
      ...provided,
      ':active': { background: `rgba(${theme.colors.rgbPrimaryLightest}, 0.8)` },
      whiteSpace: 'nowrap',
      unicodeBidi: 'plaintext',
      background: selectedBC || focusedBC || white,
      color: isSelected || isFocused ? white : 'inherit',
      cursor: 'pointer',
    };
  },

  indicatorSeparator: (provided) => ({
    ...provided,
    display: 'none',
  }),
});
