import { styled } from '@mui/material/styles';

export const EventsListWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flex: '1 1 100%',
  overflowY: 'auto',
  minHeight: '0',
  backgroundColor: theme.palette.background.default,
}));
