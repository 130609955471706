/* @flow */
import * as React from 'react';
import { compose, type Dispatch } from 'redux';
import { connect } from 'react-redux';
import { withStyles } from '@mui/styles';
import cx from 'classnames';
import type { TableProps } from '../table';
import sheet from './sheet';
import { Set } from 'immutable';
import { setGoogleSearchTextAction, googleSearchTextSelector, getStatementTxnSearchAction } from 'domain/googleSearch';

type GoogleSearchProps = {|
  classes: {|
    [key: string]: string,
  |},
  googleSearchCols: Array<string>,
  lineItems: Set<number>,
  googleSearchText: string,
  setGoogleSearchText: Dispatch<typeof setGoogleSearchTextAction>,
  getStatementTxnSearch: Dispatch<typeof getStatementTxnSearchAction>,
|};

type Props = { ...TableProps, ...GoogleSearchProps };

type State = {||};

const shouldCellBeColorCoded = (data) => {
  if (data) {
    const value = data.getIn(['cellSet', 'value']);
    return value === 'Match' || value === 'NoMatchCanCrate'; // use status constants
  }
  return false;
};

export const withGoogleSearch = (WrappedComponent: React.Component<TableProps>) => {
  class WithGoogleSearch extends React.Component<Props, State> {
    getItem = (row, col) => {
      const { getItem, classes, googleSearchCols, lineItems, reconcileColumn } = this.props;

      // return googleSearchCols.includes(col) && lineItems.includes(row) ? (
      return googleSearchCols.includes(col) && lineItems.includes(row) ? (
        <div
          className={cx(classes.box, {
            [classes.match]: shouldCellBeColorCoded(this.props.data.get(`${reconcileColumn}${row}`)),
          })}
        >
          <button className={classes.button} onClick={this.showGooglePanel(`${col}${row}`)} />
          <div className={classes.item}>{getItem(row, col)}</div>
        </div>
      ) : (
        getItem(row, col)
      );
    };

    showGooglePanel = (cell) => (e) => {
      e.preventDefault();
      e.stopPropagation();
      const data = this.props.data.get(cell);
      if (data) {
        const value = data.getIn(['cellSet', 'value']);
        this.props.getStatementTxnSearch(value);
      }
    };

    render() {
      return <WrappedComponent {...this.props} getItem={this.getItem} />;
    }
  }

  WithGoogleSearch.displayName = WrappedComponent.displayName;

  const mapStateToProps = (state) => ({
    googleSearchText: googleSearchTextSelector(state),
  });

  const mapDispatchToProps = {
    setGoogleSearchText: setGoogleSearchTextAction,
    getStatementTxnSearch: getStatementTxnSearchAction,
  };

  return compose(connect(mapStateToProps, mapDispatchToProps), withStyles(sheet))(WithGoogleSearch);
};

export default withGoogleSearch;
