// @flow
import React, { memo } from 'react';

// components
import PasswordLessFormAuth from 'pages/authPage/PasswordLessAuth/Form';
import FormVersionBuild from 'pages/authPage/Layout/BuildVersion';

const PasswordLessAuth = () => (
  <>
    <PasswordLessFormAuth />
    <FormVersionBuild />
  </>
);

export default memo(PasswordLessAuth);
