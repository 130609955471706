// @flow
import * as React from 'react';
import { type Map } from 'immutable';
import { FormattedMessage } from 'react-intl';
import { type Dispatch, compose } from 'redux';
import { connect } from 'react-redux';
import { Link, type LocationShape, generatePath } from 'react-router-dom';
import cx from 'classnames';
import { getParamsFromChannelName } from 'sendbird-utils';
import { chatParticipantsByIdSelector, setActiveChannelForceAction } from 'domain/chat';
import type { TMessageRecord } from 'domain/chat/types.js.flow';
import { listTimeFormatter } from './helpers';
import Highlighter from 'react-highlight-words';
import { type ChatUser } from 'domain/chat/types.js.flow';
import ROUTES_PATH from 'domain/router/routesPathConfig';

type Props = {
  classes: {
    [key: string]: string,
  },
  thread: TMessageRecord,
  name: string,
  setActiveChannel: Dispatch<typeof setActiveChannelForceAction>,
  companyId: string,
  searchText?: string,
  chatParticipants: Map<string, ChatUser>,
};

class DocumentItem extends React.Component<Props> {
  get pathname(): LocationShape {
    const {
      name,
      thread: { sender },
    } = this.props;
    return {
      pathname: generatePath(ROUTES_PATH.DOCUMENT.absolute, getParamsFromChannelName(name)),
      state: {
        chat: true,
        sender: sender.toJS(),
      },
    };
  }

  getUserName = () => {
    const { thread, chatParticipants } = this.props;
    const user = chatParticipants.get(thread.sender.userId);
    return user ? user.username : thread.sender.nickname;
  };

  setActiveChannel = () => {
    const { name: threadId, companyId } = this.props;
    this.props.setActiveChannel({
      threadId,
      companyId,
    });
  };

  render() {
    const { classes, thread, searchText } = this.props;
    const userName = this.getUserName();

    return (
      <Link className={cx(classes.item, classes.itemDoc)} to={this.pathname} onClick={this.setActiveChannel}>
        <div className={cx(classes.content, classes.contentDoc)}>
          <div className={cx(classes.info)}>
            <div className={classes.name}>
              {searchText ? (
                <Highlighter
                  highlightClassName={classes.highlight}
                  searchWords={[searchText]}
                  autoEscape
                  textToHighlight={userName}
                />
              ) : (
                userName
              )}
            </div>
            <div className={cx(classes.text, classes.textDoc)}>
              <FormattedMessage id="chat.document.reply" defaultMessage="New Reply" />
            </div>
          </div>
          <div className={cx(classes.meta, classes.metaDoc)}>
            <div className={cx(classes.time, classes.timeDocument)}>{listTimeFormatter(thread.createdAt)}</div>
          </div>
        </div>
      </Link>
    );
  }
}

const mapStateToProps = (state) => ({
  chatParticipants: chatParticipantsByIdSelector(state),
});

export default compose(connect(mapStateToProps))(DocumentItem);
