// @flow
import React, { useMemo } from 'react';

// TODO: check types, its temporary
import { type DialogProps } from '@mui/material/Dialog';
import DialogDraggable from 'components/mui/Dialog/DialogDraggable';
import DialogTitle from 'components/mui/Dialog/DialogTitle';
import DialogActions from 'components/mui/Dialog/DialogActions';
import DialogContentMui from '@mui/material/DialogContent';
import PaperComponent from 'components/mui/Dialog/PaperDraggable';

type TDialog = {
  title: React$Node | string,
  disableBackdropClose?: boolean,
  onClose: () => void,
  onOk?: () => void,
  cancelText?: React$Node,
  okText?: React$Node,
  draggable?: boolean,
  withContent?: boolean,
  withActions?: boolean,
  forFormID?: string,
  dividers?: boolean,
  withCloseBtn?: boolean,
  okBtnProps?: ButtonProps,
} & DialogProps;

type TDialogCloseReason = 'escapeKeyDown' | 'backdropClick';

const Dialog: React$StatelessFunctionalComponent<TDialog> = ({
  children,
  open,
  title,
  okText,
  cancelText,
  onClose,
  onOk,
  withActions = true,
  withContent = true,
  disableBackdropClose,
  draggable = true,
  forFormID,
  dividers = false,
  withCloseBtn = false,
  okBtnProps,
  ...rest
}) => {
  const handleOnClose = (event: SyntheticEvent<*>, reason: TDialogCloseReason) => {
    if (disableBackdropClose && reason === 'backdropClick') return;

    if (typeof onClose === 'function') {
      onClose();
    }
  };

  const dialogProps = useMemo(() => (draggable ? { PaperComponent } : {}), [draggable]);

  return (
    <DialogDraggable
      fullWidth
      open={open}
      maxWidth="xs"
      scroll="paper"
      onClose={handleOnClose}
      {...dialogProps}
      {...rest}
    >
      <DialogTitle onClose={onClose} draggable={draggable} withCloseBtn={withCloseBtn}>
        {title}
      </DialogTitle>

      {withContent ? <DialogContentMui dividers={dividers}>{children}</DialogContentMui> : children}

      {withActions && (
        <DialogActions
          onCancel={onClose}
          onOk={onOk}
          okText={okText}
          cancelText={cancelText}
          forFormID={forFormID}
          okBtnProps={okBtnProps}
        />
      )}
    </DialogDraggable>
  );
};

export default Dialog;
