// @flow
import React, { useState, useRef, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Draggable from 'react-draggable';

import { documentViewSelector } from 'domain/env';

import {
  DocumentPageControlsWrapper,
  ControlsStack,
  ControlButton,
  ControlsDivider,
  ControlsCounter,
} from 'pages/document/components/DocumentPageControls/components/StyledComponents';
import DocumentZoomControls from 'pages/document/components/DocumentPageControls/components/DocumentZoomControls';
import TooltipForButton from 'components/mui/Tooltip/TooltipForButton';
import Box from '@mui/material/Box';

import Rotate90DegreesCcwOutlinedIcon from '@mui/icons-material/Rotate90DegreesCcwOutlined';
import Rotate90DegreesCwOutlinedIcon from '@mui/icons-material/Rotate90DegreesCwOutlined';
import GridOnOutlinedIcon from '@mui/icons-material/GridOnOutlined';

type Props = {|
  isStatement: boolean,
  isFinancial: boolean,
  handleGrid: () => void,
  setGridButtonRef: (ref: HTMLElement | null) => void,
  onRotateLeft: () => void,
  onRotateRight: () => void,
  onReset: () => void,
  onZoom: (direction: 1 | -1 | 0) => void,
  getIsDisabledZoomButton: (direction: 1 | -1 | 0) => boolean,
  isRotateDisabled: boolean,
  stopPropagationCallback: (cb: () => void) => (e: MouseEvent) => void,
  isSmallActionButton: boolean,
  total: number,
  currentIndex: number,
  pageWidth: number,
|};

const DocumentPageControls: React$StatelessFunctionalComponent<Props> = ({
  total,
  currentNumber,
  isStatement,
  isFinancial,
  handleGrid,
  setGridButtonRef,
  onRotateLeft,
  onRotateRight,
  onZoom,
  onReset,
  getIsDisabledZoomButton,
  isRotateDisabled = false,
  stopPropagationCallback,
  isSmallActionButton,
  pageWidth,
}) => {
  const [wasMoved, setWasMoved] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const controlsRef = useRef(null);
  const documentsWidth = useSelector(documentViewSelector).get('documentsWidth');

  const handleStart = useCallback(
    (e, data) => {
      if (!wasMoved) {
        setPosition({ y: controlsRef?.current.offsetTop + data.y, x: data.x });
        setWasMoved(true);
      } else {
        setPosition({ x: data.x, y: data.y });
      }
    },
    [wasMoved],
  );

  const rotateTooltip = (disabled: boolean) => (dir: 'left' | 'right') => {
    switch (true) {
      case disabled:
        return {
          id: 'document.show.rotateDisabledDueToMatchingEnabled',
          defaultMessage: 'Rotation disabled while 2-way matching is on',
        };

      case dir === 'left':
        return {
          id: 'document.show.rotateLeft',
          defaultMessage: 'Rotate left',
        };

      case dir === 'right':
        return {
          id: 'document.show.rotateRight',
          defaultMessage: 'Rotate right',
        };

      default:
        return null;
    }
  };

  // for general document we consider toggler disabled
  const rotateInstance = rotateTooltip(isRotateDisabled && isFinancial);
  const rotateLeftProps = rotateInstance('left');
  const rotateRightProps = rotateInstance('right');

  const resetPosition = useCallback(() => {
    setWasMoved(false);
    setPosition({ x: 0, y: 0 });
  }, []);

  const handleRotateLeft = useCallback(
    (e) => {
      onRotateLeft(e);
      resetPosition();
    },
    [resetPosition, onRotateLeft],
  );

  const handleRotateRight = useCallback(
    (e) => {
      onRotateRight(e);
      resetPosition();
    },
    [resetPosition, onRotateRight],
  );

  const handleZoom = useCallback(
    (direction) => {
      onZoom(direction);
      resetPosition();
    },
    [resetPosition, onZoom],
  );

  const handleReset = useCallback(
    (e) => {
      onReset(e);
      resetPosition();
    },
    [resetPosition, onReset],
  );

  useEffect(() => {
    if (wasMoved) {
      resetPosition();
    }
    // eslint-disable-next-line
  }, [documentsWidth, resetPosition]);

  return (
    // DocumentPage-controls class use for change opacity in other component
    <DocumentPageControlsWrapper className="DocumentPage-controls" pageWidth={pageWidth}>
      <Draggable bounds="parent" onStop={handleStart} position={position}>
        <ControlsStack shrink={isSmallActionButton} wasMoved={wasMoved} ref={controlsRef}>
          {/* BANK STATEMENT */}
          {isStatement && isFinancial && (
            <TooltipForButton
              t={{
                id: 'document.show.gridBeta.tooltip',
                defaultMessage: 'Add grid for better recognition, where, you think, data isn’t recognizable.',
              }}
            >
              <ControlButton ref={setGridButtonRef} onClick={handleGrid} edge="start">
                <GridOnOutlinedIcon />
              </ControlButton>
            </TooltipForButton>
          )}
          <TooltipForButton t={rotateLeftProps}>
            <ControlButton
              onClick={handleRotateLeft}
              disabled={isRotateDisabled}
              edge={isStatement && isFinancial ? false : 'start'}
            >
              <Rotate90DegreesCcwOutlinedIcon />
            </ControlButton>
          </TooltipForButton>
          <DocumentZoomControls
            onZoom={handleZoom}
            onReset={stopPropagationCallback(handleReset)}
            getIsDisabledZoomButton={getIsDisabledZoomButton}
            isSmallActionButton={isSmallActionButton}
          />
          <TooltipForButton t={rotateRightProps}>
            <ControlButton onClick={handleRotateRight} disabled={isRotateDisabled}>
              <Rotate90DegreesCwOutlinedIcon />
            </ControlButton>
          </TooltipForButton>

          <Box display="flex" alignItems="center">
            <ControlsDivider />
            <ControlsCounter shrink={isSmallActionButton}>
              {currentNumber} / {total}
            </ControlsCounter>
          </Box>
        </ControlsStack>
      </Draggable>
    </DocumentPageControlsWrapper>
  );
};

export default DocumentPageControls;
