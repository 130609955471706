/* @flow */
import ROUTES_PATH from 'domain/router/routesPathConfig';
import { generatePath } from 'react-router-dom';
import { customAlphabet } from 'nanoid';
import type { TDocument, TStoreExportFormats } from 'domain/documents/types.js.flow';

type exportFormatOption = {|
  value: string,
  label: string,
|};

export const getExportFormatOptions = (formats: TStoreExportFormats): Array<exportFormatOption> =>
  Array.from(formats.entries()).map(([value, label]) => ({ value, label }));
export type TPreview = {|
  documentID: string,
  documentItem: TDocument,
  isLinked: boolean,
|};

export function getDocumentUrl(companyId: string, documentId: string): string {
  return generatePath(ROUTES_PATH.DOCUMENT.absolute, {
    documentId,
    companyId,
  });
}

const alphabet = '0123456789abcdefghijklmnopqrstuvwxyz';
const customNanoid = customAlphabet(alphabet, 10);

export const generateLinkID = () => customNanoid();
