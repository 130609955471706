// @flow
import React, { memo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import { isOpenModalWorkspaceHotkeysSelector, toggleWorkspaceHotkeysModalAction } from 'domain/ui';

import Dialog from 'components/mui/Dialog';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import { keyCodeForUI } from 'lib/hotkeysHelpers';
import { workspaceHotkeys } from './hotkeys';

const mapStateToProps = (state) => ({
  isOpenModalWorkspaceHotkeys: isOpenModalWorkspaceHotkeysSelector(state),
});

const DialogKeyboardHotkeys = () => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  const { isOpenModalWorkspaceHotkeys } = useSelector(mapStateToProps);

  const onClose = useCallback(() => {
    dispatch(toggleWorkspaceHotkeysModalAction());
  }, [dispatch]);

  return (
    <Dialog
      open={isOpenModalWorkspaceHotkeys}
      onClose={onClose}
      maxWidth="sm"
      title={formatMessage({ id: 'modal.document.hotkeys.title', defaultMessage: 'Keyboard Shortcuts' })}
      cancelText={formatMessage({ id: 'button.close', defaultMessage: 'Close' })}
      okBtnProps={{ sx: { display: 'none' } }}
    >
      {workspaceHotkeys.map((item, index) => (
        <Grid
          container
          key={index.toString()}
          mt={2}
          flexWrap="nowrap"
          alignItems="center"
          justifyContent="space-between"
        >
          <Grid item flex={1}>
            <Typography variand="body2">{formatMessage(item.description)}</Typography>
          </Grid>
          <Grid item>
            {item.keys.map((combinations) => (
              <Box
                key={combinations.join()}
                minWidth={40}
                height={40}
                fontSize={14}
                lineHeight="40px"
                textAlign="center"
                borderRadius={2}
                px={1}
                bgcolor="grey.100"
              >
                {combinations.map((k) => keyCodeForUI.get(k)).join('+')}
              </Box>
            ))}
          </Grid>
        </Grid>
      ))}
    </Dialog>
  );
};

export default memo(DialogKeyboardHotkeys);
