// @flow
import React, { useMemo } from 'react';

import { compose } from 'redux';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Field, formValueSelector, reduxForm } from 'redux-form/immutable';
import type { Map } from 'immutable';

import DialogContent from '@mui/material/DialogContent';
import Stack from '@mui/material/Stack';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';

import TextFieldForm from 'components/mui/Form/TextField/TextFieldForm';
import EmailAutocomplete from '../EmailAutocomplete';

import { addError, rules } from 'components/Form/validation';

type Props = {
  onClose: () => void,
  submitting: boolean,
  invalid: boolean,
  emailOptions: Array<string>,
  users: { [userId: string]: { picture: string, username: string } },
  handleSubmit: (data: Map<string, string>) => void,
};

const mapStateToProps = (state) => ({
  toValue: formValueSelector('sendByEmailForm')(state, 'to'),
  ccValue: formValueSelector('sendByEmailForm')(state, 'cc'),
});

const EMPTY_ARR = [];

const SendByEmailForm: React$StatelessFunctionalComponent<Props> = ({
  handleSubmit,
  onClose,
  submitting,
  invalid,
  emailOptions,
  users,
}) => {
  const { formatMessage } = useIntl();
  const { toValue = EMPTY_ARR, ccValue = EMPTY_ARR } = useSelector(mapStateToProps);

  const options = useMemo(() => {
    const values = new Set([...toValue, ...ccValue]);

    return emailOptions.filter((option) => !values.has(option));
  }, [toValue, ccValue, emailOptions]);

  return (
    <form onSubmit={handleSubmit} noValidate>
      <DialogContent>
        <Stack direction="column" alignItems="center" spacing={1}>
          <Field
            component={EmailAutocomplete}
            label={formatMessage({ id: 'form.dialog.sendByEmail.to', defaultMessage: 'To' })}
            placeholder={formatMessage({ id: 'form.dialog.sendByEmail.to', defaultMessage: 'To' })}
            required
            name="to"
            fullWidth
            autoFocus
            emailOptions={options}
            users={users}
          />
          <Field
            component={EmailAutocomplete}
            label={formatMessage({ id: 'form.dialog.sendByEmail.cc', defaultMessage: 'CC' })}
            placeholder={formatMessage({ id: 'form.dialog.sendByEmail.cc', defaultMessage: 'CC' })}
            name="cc"
            type="text"
            fullWidth
            emailOptions={options}
            users={users}
          />
          <Field
            component={TextFieldForm}
            label={formatMessage({ id: 'form.dialog.sendByEmail.subject', defaultMessage: 'Subject' })}
            placeholder={formatMessage({ id: 'form.dialog.sendByEmail.subject', defaultMessage: 'Subject' })}
            required
            name="subject"
            type="text"
            fullWidth
          />
          <Field
            component={TextFieldForm}
            label={formatMessage({ id: 'form.dialog.sendByEmail.description', defaultMessage: 'Description' })}
            placeholder={formatMessage({
              id: 'form.dialog.sendByEmail.description',
              defaultMessage: 'Description',
            })}
            required
            multiline
            rows="7"
            name="description"
            type="text"
            fullWidth
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="text">
          {formatMessage({ id: 'form.dialog.sendByEmail.button.cancel', defaultMessage: 'Cancel' })}
        </Button>
        <LoadingButton variant="contained" type="submit" loading={submitting} disabled={invalid || submitting}>
          {formatMessage({ id: 'button.send', defaultMessage: 'Send' })}
        </LoadingButton>
      </DialogActions>
    </form>
  );
};

const validator = (values) => (errors, fieldName) => {
  const val = values.get(fieldName, '');
  switch (fieldName) {
    case 'cc':
      return (
        val &&
        compose(
          addError(fieldName, val ? val.reduce((res, item) => res || rules.emailExtended(item), undefined) : undefined),
        )(errors)
      );

    case 'subject':
      return compose(addError(fieldName, rules.required(val)))(errors);

    case 'description':
      return compose(addError(fieldName, rules.required(val)))(errors);

    case 'to':
      return compose(
        addError(fieldName, val ? val.reduce((res, item) => res || rules.emailExtended(item), undefined) : undefined),
        addError(fieldName, rules.required(val[0])),
      )(errors);

    default:
      return errors;
  }
};

const validate = (values) => ['cc', 'subject', 'description', 'to'].reduce(validator(values), {});

const Form = compose(reduxForm({ form: 'sendByEmailForm', touchOnChange: true, validate }))(SendByEmailForm);

export default Form;
