// @flow
/* eslint-disable react/no-array-index-key */
import React, { useCallback, useRef } from 'react';
import isNil from 'lodash/isNil';
import Api from 'domain/api';
import { useDispatch, connect } from 'react-redux';
import { withApiToken } from 'lib/apiTokenKeeper';
import { useIntl } from 'react-intl';

import {
  currentERPSettingsInitialValuesSelector,
  currentERPGroupedSettingsSelector,
  currentERPCompaniesSelector,
} from 'domain/settings/settingsSelector';

import type { ERPSettings as ERPSettinsList } from 'domain/settings/settingsModel';

import { importDataAction } from 'domain/settings';
import { OrderedMap, type Map, isMap } from 'immutable';
import { compose, type Dispatch } from 'redux';
import { reduxForm, Field, type FormProps, getFormValues } from 'redux-form/immutable';
import elements from 'components/elements';
import TextField from 'components/mui/Form/TextField/TextFieldForm';
import SwitchForm from 'components/mui/Form/Switch/SwitchForm';
import MuiVirtualSelect from 'components/Form/VirtualSelect/MuiVirtualSelect';
import MuiVirtualSelectAsync from 'components/Form/VirtualSelectAsync/MuiVirtualSelectAsync';
import type { GetOptionListParams } from 'components/Form/VirtualSelectAsync';
import InputFile from './inputFile';
import FTPConnection from 'components/ERPSettings/FTPConnection';
import TimeSelector from './TimeSelector';
import BulkEdit from './Propagate';
import { defaultOptionIntl } from 'components/Form/VirtualSelect/Options/Options';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import MuiAccordion from '@mui/material/Accordion';
import ArrowRightIcon from 'pages/components/AccordionArrowRightIcon';
import Button from '@mui/material/Button';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';

import type { erpNamesType } from 'domain/companies/types.js.flow';
import { type ERPSettingDynamicList } from 'domain/settings/settingsModel';

type TOptionsRaw = Map<string, string> | Array<{| display: string, value: string |}>;

type TValues = Map<string, any>;

const Row = styled(Stack)(() => ({}));
const Accordion = styled(MuiAccordion)(() => ({
  '&.MuiPaper-root.MuiAccordion-root::before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary elevation={0} expandIcon={<ArrowRightIcon />} {...props} />
))(() => ({
  flexDirection: 'row-reverse',
  padding: 0,
}));

type Props = {|
  erp: erpNamesType,
  erpName: string,
  erpSettings: OrderedMap<string, ERPSettinsList>,
  // eslint-disable-next-line react/no-unused-prop-types
  initialValues: TValues,
  currentValues: TValues,
  handleSubmit: (data: Map<string, string>) => void,
  importData: Dispatch<typeof importDataAction>,
  onSubmit: (v: any) => Promise<any>,
  intl: IntlShape,
  apiToken: string,
  isBusy: boolean,
|} & FormProps;

const ERPSettings = ({
  onSubmit,
  handleSubmit,
  erp,
  erpName,
  submitting,
  apiToken: dokkaToken,
  erpSettings,
  initialValues,
  currentValues,
  isBusy,
}: Props) => {
  const dispatch = useDispatch();
  const valueElementWhenFocus = useRef({});
  const intl = useIntl();

  const buildOptions = useCallback(
    (options: TOptionsRaw, deletable: boolean) => {
      if (!options) return [];

      const currentOptions = [];

      if (deletable) {
        currentOptions.push({
          label: intl.formatMessage(defaultOptionIntl),
          value: null,
        });
      }

      if (Array.isArray(options)) {
        options.forEach(({ display, value }) => {
          currentOptions.push({ value, label: display });
        });
      }

      if (isMap(options)) {
        // @FlowFixMe we set type guard which flow doesnt understand
        // and considers options might be array here
        options
          .sortBy((value) => value)
          .forEach((val, _key) => {
            currentOptions.push({ label: val, value: _key });
          });
      }

      return currentOptions;
    },
    [intl],
  );

  const getOptionsList = useCallback(
    (setting: ERPSettingDynamicList) => async (queryParams: GetOptionListParams) => {
      // eslint-disable-next-line camelcase
      const { options_function, deletable } = setting;
      const params = {
        ...queryParams,
        dokkaToken,
        optionsFunction: options_function,
      };

      const {
        data: { list, pageToken },
      } = await Api.getOptionsList({ params });

      const options = buildOptions(list, deletable);

      return {
        options,
        pageToken,
      };
    },
    [buildOptions, dokkaToken],
  );

  const filterErpSettings = useCallback(
    (erpSettings: ERPSettinsList) => erpSettings.filter((setting) => !isNil(setting.value) && setting.type !== 'file'),
    [],
  );

  const getTouchedErpSettings = useCallback(
    () =>
      erpSettings.reduce((res, groupSettings, name) => {
        const filteredGroupSettings = filterErpSettings(groupSettings);
        return filteredGroupSettings.size ? res.set(name, filteredGroupSettings) : res;
      }, new OrderedMap()),
    [erpSettings, filterErpSettings],
  );

  // eslint-disable-next-line camelcase
  const propagate = useCallback(
    (propagate_fields: Array<string>, propagate_to_companies: Array<string>) =>
      onSubmit(
        initialValues.merge({
          propagate: { propagate_fields, propagate_to_companies },
        }),
      ),
    [initialValues, onSubmit],
  );

  const onBlur = (e: SyntheticInputEvent<HTMLInputElement>, value: any, prevValue: any, field: string) => {
    if (valueElementWhenFocus.current[field] !== value) {
      onSubmit(currentValues.set(field, value));
    }
  };

  const onFocus = (e: SyntheticInputEvent<HTMLInputElement>, field: string) => {
    valueElementWhenFocus.current[field] = currentValues.get(field);
  };

  const onChange = useCallback(
    (e: SyntheticInputEvent<HTMLInputElement>, value: any, prevValue: any, field: string) => {
      onSubmit(currentValues.set(field, value));
    },
    [currentValues, onSubmit],
  );

  // doesn't submit form by Enter
  const handleKeyDown = useCallback((e: SyntheticKeyboardEvent<HTMLElement>) => {
    if (e.keyCode === 13) {
      e.preventDefault();
    }
  }, []);

  const usedErpSettings = getTouchedErpSettings();
  const disabled = isBusy || submitting;

  return erpSettings.size > 0 ? (
    <Stack
      component="form"
      onSubmit={handleSubmit}
      data-element={elements.configuration.company.integrations.forms.container}
    >
      <Stack
        data-element={elements.configuration.company.integrations.forms.item}
        spacing={3}
        sx={{ pl: 2, maxWidth: 'max(500px, 40%)' }}
      >
        {
          erpSettings
            .map((section, key) => (
              // @to-do remove key as index
              <React.Fragment key={key}>
                {erpSettings.keySeq().findIndex((k) => k === key) !== 0 && <Divider />}
                {key.trim() && <Typography variant="subtitle2">{key}</Typography>}
                {section.map((setting) => (
                  <Row key={setting.name}>
                    {(() => {
                      switch (setting.type) {
                        case 'text':
                        case 'number': // support default input type number that also allows float value
                        case 'int': // it allows only integer type and support strict limitation min and max considering keyboard enter
                        case 'float': // it allows formatted float value
                          return (
                            <Field
                              component={TextField}
                              label={setting.label}
                              fullWidth
                              placeholder=""
                              name={setting.name}
                              type={setting.type}
                              // eslint-disable-next-line max-len
                              data-element={elements.configuration.company.integrations.forms.input}
                              data-element-id={setting.name}
                              disabled={disabled}
                              onBlur={onBlur}
                              onFocus={onFocus}
                              min={setting.min}
                              max={setting.max}
                            />
                          );
                        case 'list':
                          return (
                            <Field
                              component={MuiVirtualSelect}
                              label={setting.label}
                              options={buildOptions(setting.options, setting.deletable)}
                              placeholder={{
                                id: 'form.organization.erp.settings.placeholder',
                                defaultMessage: 'Select',
                              }}
                              // eslint-disable-next-line max-len
                              data-element={elements.configuration.company.integrations.forms.select}
                              data-element-id={setting.name}
                              name={setting.name}
                              type="text"
                              disabled={disabled}
                              onChange={onChange}
                              FormControlProps={{
                                // submit only if the user has set a value
                                onKeyDown: handleKeyDown,
                              }}
                            />
                          );
                        case 'dynamic_list':
                          return (
                            <Field
                              component={MuiVirtualSelectAsync}
                              options={buildOptions(setting.options, setting.deletable)}
                              label={setting.label}
                              placeholder={{
                                id: 'form.organization.erp.settings.placeholder',
                                defaultMessage: 'Select',
                              }}
                              loadData={getOptionsList(setting)}
                              defaultLabel={setting.display_value || ''}
                              pageLimit={50}
                              data-element={elements.configuration.company.integrations.forms.select}
                              data-element-id={setting.name}
                              name={setting.name}
                              type="text"
                              disabled={disabled}
                              onChange={onChange}
                              FormControlProps={{
                                // submit only if the user has set a value
                                onKeyDown: handleKeyDown,
                              }}
                            />
                          );
                        case 'boolean':
                          return (
                            <Field
                              component={SwitchForm}
                              FormControlLabelProps={{
                                labelPlacement: 'end',
                                label: setting.label,
                                sx: (theme) => ({ color: `${theme.palette.common.black}!important` }),
                              }}
                              data-element={elements.configuration.company.integrations.forms.toggler.hint}
                              name={setting.name}
                              value={setting.value}
                              disabled={disabled}
                              onChange={onChange}
                            />
                          );
                        case 'file':
                          return (
                            <InputFile
                              uploadFile={(e) => dispatch(importDataAction(e))}
                              label={setting.label}
                              formats={setting.formats}
                              erp={erp}
                              disabled={disabled}
                            />
                          );

                        case 'ftp_connection':
                          return <FTPConnection label={setting.label} value={setting.value} />;
                        case 'indexes_sync_time':
                          return (
                            <TimeSelector
                              value={setting.value}
                              label={setting.label}
                              name={setting.name}
                              onChange={onChange}
                            />
                          );
                        default:
                          return null;
                      }
                    })()}
                  </Row>
                ))}
              </React.Fragment>
            ))
            .valueSeq() // React complains for getting Map instead of Array(sequence)
        }
      </Stack>
      {!!usedErpSettings.size && (
        <Stack sx={{ mt: 2 }}>
          <Divider />
          <Accordion
            disableGutters
            sx={{
              mt: '0!important',
              border: 'none',
              boxShadow: 'none',
              ml: -1,
              mb: 0,
            }}
            component="div"
          >
            <AccordionSummary>
              <Typography variant="subtitle2">
                {intl.formatMessage({
                  id: 'forms.erpConnect.advanced.title',
                  defaultMessage: 'Advanced settings',
                })}
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ p: 2, pl: 3, pt: 0 }}>
              <Row>
                <Stack direction="row" alignItems="center">
                  <Stack flex="0 0 200px">
                    {intl.formatMessage(
                      {
                        id: 'forms.erpConnect.advanced.label',
                        defaultMessage: 'Propagate settings to {ERPName} connected companies',
                      },
                      {
                        ERPName: <Typography variant="subtitle2">{erpName}</Typography>,
                      },
                    )}
                  </Stack>
                  <BulkEdit erpSettings={usedErpSettings} erp={erp} onPropagate={propagate} />
                </Stack>
              </Row>
            </AccordionDetails>
          </Accordion>
        </Stack>
      )}

      <Button
        type="submit"
        data-element={elements.configuration.company.integrations.forms.saveButton}
        sx={{ ml: 'auto' }}
      >
        {intl.formatMessage({ id: 'button.save', defaultMessage: 'Save' })}
      </Button>
    </Stack>
  ) : null;
};

const mapStateToProps = (state) => ({
  erpCompanies: currentERPCompaniesSelector(state),
  erpSettings: currentERPGroupedSettingsSelector(state),
  initialValues: currentERPSettingsInitialValuesSelector(state),
  currentValues: getFormValues('erpSettingsForm')(state),
});

export default compose(
  connect(mapStateToProps),
  withApiToken,
  reduxForm({
    form: 'erpSettingsForm',
    enableReinitialize: true,
  }),
)(ERPSettings);
