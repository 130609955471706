// @flow
import * as React from 'react';
import { FormattedMessage, injectIntl, type IntlShape } from 'react-intl';
import { connect } from 'react-redux';
import { compose, type Dispatch } from 'redux';
import { navigate } from 'domain/router/redux/reduxActions';
import {
  documentRemove,
  documentSetAsFinancialAction,
  documentSetAsGeneralAction,
  documentUpdateTags,
  documentReject,
  documentAccept,
  documentSignShowDocument,
  duplicateDocumentAction,
  setEditableDoc,
  markAsPaidAction,
  moveDocumentToCategoryAction,
  documentGet,
  documentLinkedTagSelector,
  documentGetLinkedAction,
  documentIgnoreWarningsAction,
  getIndicateWarningByDocSelector,
  documentsDownloadDocumentsAction,
  documentGetLinkedIDsAction,
  mergeDocumentsForPrintingAction,
} from 'domain/documents';
import { navigationSelector, userFeaturesSelector } from 'domain/env';
import { type CategoriesList, rootCategoriesListSelector } from 'domain/categories';
import { isAllowMoveToCategories, getMovableCategories } from 'domain/categories/helpers';
import { Set, type RecordOf } from 'immutable';
import { checkAcceptSignType, isDocPaid } from 'domain/documents/helpers';
import * as ACL from 'domain/restriction';
import print, { onPrint, removePrintFramesFromDOM } from 'lib/print';
import { promisify } from 'lib/helpers';
import download from 'lib/download';
import { isFirefox } from 'lib/systemHelpers/browserHelpers';
import { actionsList } from 'pages/document/components/helpers';
import ROUTES_PATH from 'domain/router/routesPathConfig';
import { generatePath } from 'react-router-dom';
import Api from 'domain/api';
import type { DocDropParams } from 'pages/company/type.js.flow';
import { type Company } from 'domain/companies';
import type { TDocumentRecord, TDocument } from 'domain/documents/types.js.flow';
import type { Navigation, TUserFeatures } from 'domain/env/types.js.flow';
import withConfirm from 'hoc/withConfirm';
import { type ConfirmOptions } from 'material-ui-confirm';
import keeper from 'lib/apiTokenKeeper';
import { CONFIGURABLE_API_ENDPOINT } from 'lib/apiHelpers';

import toast from 'components/Toast';
import DialogTagsManage from 'pages/common/Dialog/DialogTagsManage';
import DialogNotes from 'pages/common/Dialog/DialogNotes';
import DialogSignDocument from 'pages/common/Dialog/DialogSignDocument';
import DialogRejectDocument from 'pages/document/components/Dialog/DialogRejectDocument';
import DialogApprovalFormApprove from 'pages/document/components/Dialog/DialogApprovalFormApprove';
import DialogRejectApproval from 'pages/document/components/Dialog/DialogRejectApproval';
import DocumentMoreButton from 'pages/document/components/DocumentMoreButton';
import DialogMoveDocumentsToCompany from 'pages/common/Dialog/DialogMoveDocumentsToCompany';
import DialogSendByEmail from 'pages/common/Dialog/DialogSendByEmail';

type ActionsList = {
  [key: string]: {|
    onClick: () => void,
    disabled?: boolean,
    divided?: boolean,
  |},
};
type Props = {
  intl: IntlShape,
  document: TDocumentRecord,
  documentRemove: Dispatch<typeof documentRemove>,
  documentReject: Dispatch<typeof documentReject>,
  navigateReplace: Dispatch<*>,
  documentSetAsFinancial: Dispatch<typeof documentSetAsFinancialAction>,
  documentSetAsGeneral: Dispatch<typeof documentSetAsGeneralAction>,
  documentTagsUpdate: Dispatch<typeof documentUpdateTags>,
  setEditableDoc: Dispatch<typeof setEditableDoc>,
  signDocument: Dispatch<typeof documentSignShowDocument>,
  duplicateDocument: Dispatch<typeof duplicateDocumentAction>,
  markAsPaid: Dispatch<typeof markAsPaidAction>,
  moveDocumentToCategory: Dispatch<typeof moveDocumentToCategoryAction>,
  getDocumentMetadata: Dispatch<typeof documentGet>,
  dokkaToken: string,
  company: Company,
  backLink: () => void,
  onReset: () => void,
  restriction: number,
  isGranted: (number | number[]) => boolean,
  goToNextDocument: (f: () => () => void) => void,
  disableGenerteJE: boolean,
  // eslint-disable-next-line react/no-unused-prop-types
  openModalName: ?('tagsModal' | 'notesModal' | 'signModal' | 'rejectModal'),
  handleActionModal: () => void,
  rootCategoriesList: CategoriesList,
  documentGetLinked: Dispatch<typeof documentGetLinkedAction>,
  documentLinkedTag: string,
  isCurrentUserSignaturePending: boolean,
  onManageApprovals: () => void,
  navigation: RecordOf<Navigation>,
  onNoteHandler: () => void,
  isEditActionsBlocked?: boolean,
  userFeatures: RecordOf<TUserFeatures>,
  ignoreDocumentWarnings: Dispatch<typeof documentIgnoreWarningsAction>,
  getIndicateWarningByDoc: (doc: TDocument) => boolean,
  downloadLinkedDocs: Dispatch<typeof documentsDownloadDocumentsAction>,
  documentGetLinkedIDs: Dispatch<documentGetLinkedIDsAction>,
  printPreparationCompleted: Dispatch<documentGetLinkedIDsAction.success>,
  isGoNext: boolean,
  confirm: (options?: ConfirmOptions) => Promise<void>,
  mergeDocumentsForPrinting: Dispatch<typeof mergeDocumentsForPrintingAction>,
};

type State = {
  tagsModal: boolean,
  notesModal: boolean,
  signModal: boolean,
  rejectModal: boolean,
  deleting: boolean,
  rejectApprovalFlow: boolean,
  approveApprovalFlow: boolean,
  moveToCompany: boolean,
  sendByEmail: boolean,
};

type RespType = {|
  reason: string,
  message: string,
|};

class DocumentMoreButtonDialogs extends React.Component<Props, State> {
  constructor() {
    super();

    this.state = {
      tagsModal: false,
      notesModal: false,
      signModal: false,
      rejectModal: false,
      deleting: false,
      rejectApprovalFlow: false,
      approveApprovalFlow: false,
      moveToCompany: false,
      sendByEmail: false,
    };
  }

  static getDerivedStateFromProps(props: Props) {
    const { openModalName } = props;
    if (openModalName) return { [openModalName]: true };
    return null;
  }

  componentWillUnmount() {
    const { document } = this.props;
    removePrintFramesFromDOM(document.documentID);

    this.setState({
      tagsModal: false,
      notesModal: false,
      signModal: false,
      rejectModal: false,
      deleting: false,
      rejectApprovalFlow: false,
      approveApprovalFlow: false,
      moveToCompany: false,
      sendByEmail: false,
    });
  }

  onRejectDocument = (data: RespType) => {
    const { documentReject: rejectDoc, backLink, navigateReplace, goToNextDocument, intl } = this.props;

    const action = () =>
      new Promise((resolve, reject) => {
        const reason = [data.reason, data.message].reduce((a, v) => (v ? a.concat(v) : a), []).join(' : ');
        rejectDoc({ reason, resolve, reject });
      });
    action()
      .then(() => {
        this.onModalClose('rejectModal');
        goToNextDocument(() => navigateReplace(backLink));
        toast.info(
          intl.formatMessage({
            id: 'document.actions.reject.success',
            defaultMessage: 'Document marked as rejected',
          }),
        );
      })
      .catch(() => this.onModalClose('rejectModal'));
  };

  onTagsUpdate = ({ tagsToAdd, tagsToRemove }: { [key: string]: Array<*> }) => {
    const { documentTagsUpdate, intl } = this.props;

    const action = () =>
      new Promise((resolve, reject) => documentTagsUpdate({ tagsToAdd, tagsToRemove, resolve, reject }));
    action()
      .then(() => {
        toast.info(
          intl.formatMessage({
            id: 'document.actions.tags.success',
            defaultMessage: 'Document tags were updated',
          }),
        );
        this.onModalClose('tagsModal');
      })
      .catch(() => this.onModalClose('tagsModal'));
  };

  onDeleteDocument = () => {
    const { deleting } = this.state;
    const {
      documentRemove: remove,
      backLink,
      navigateReplace,
      intl: { formatMessage },
      company,
      navigation,
      confirm,
    } = this.props;

    const documentId = navigation.get('nextDocId');
    const totalNumberOfDocuments = navigation.get('totalNumberOfDocuments', 1);
    const linkNext =
      documentId && totalNumberOfDocuments > 1
        ? generatePath(ROUTES_PATH.DOCUMENT.absolute, {
            companyId: company.id,
            documentId,
          })
        : backLink;

    const confirmOptions = {
      title: formatMessage({
        id: 'document.show.modals.delete.title',
        defaultMessage: 'Delete Document?',
      }),
      description: formatMessage({
        id: 'document.show.modals.delete.placeholder',
        defaultMessage: 'Are you sure you want to delete the document?',
      }),
      confirmationText: formatMessage({
        id: 'document.show.modals.delete.btn',
        defaultMessage: 'Delete',
      }),
      confirmationButtonProps: {
        disabled: deleting,
      },
    };

    confirm({ ...confirmOptions })
      .then(() => {
        this.setState({
          deleting: true,
        });
        promisify(remove)
          .then(() => {
            navigateReplace(linkNext);
            toast.info(
              formatMessage({
                id: 'document.actions.remove.success',
                defaultMessage: 'Document was removed',
              }),
            );
          })
          .catch(() => {
            this.setState({
              deleting: true,
            });
          });
      })
      .catch(() => {
        this.setState({
          deleting: false,
        });
      });
  };

  onDocumentSetAsGeneral = () => {
    const { intl, onReset, documentSetAsGeneral } = this.props;

    promisify(documentSetAsGeneral)
      .then(() => {
        toast.info(
          intl.formatMessage({
            id: 'document.actions.general.success',
            defaultMessage: 'Document marked as general',
          }),
        );
        onReset();
      })
      .catch(() => {});
  };

  onDocumentSetAsFinancial = () => {
    const { documentSetAsFinancial, intl } = this.props;

    const action = () => new Promise((resolve, reject) => documentSetAsFinancial({ resolve, reject }));
    action()
      .then(() => {
        toast.info(
          intl.formatMessage({
            id: 'document.actions.financial.success',
            defaultMessage: 'Document marked as financial',
          }),
        );
      })
      .catch();
  };

  onDuplicateDocument = () => {
    const {
      duplicateDocument,
      document,
      intl: { formatMessage },
      confirm,
    } = this.props;

    const confirmOptions = {
      title: formatMessage({
        id: 'document.show.modals.duplicate.title',
        defaultMessage: 'Duplicate document?',
      }),
      description: formatMessage({
        id: 'document.show.modals.duplicate.placeholder',
        defaultMessage: 'New version of a document will be created',
      }),
    };
    const action = () =>
      new Promise((resolve, reject) => duplicateDocument({ id: document.documentID, resolve, reject }));

    if (this.isAllowAutoConfirm()) {
      action();
    } else {
      confirm({ ...confirmOptions }).then(() => {
        action();
      });
    }
  };

  onModalClose = (modal: string, cb?: Function) => {
    this.setState(
      {
        [modal]: false,
      },
      () => {
        if (typeof cb === 'function') cb();
      },
    );
  };

  onModalInActionsShow = (modal: string) => {
    this.setState({
      [modal]: true,
    });
  };

  onModalInActionsClose = (modal: string, callback?: Function) => {
    if (typeof callback === 'function') callback();
    this.setState({ [modal]: false });
  };

  onDownload = async () => {
    const { company, document } = this.props;
    if (CONFIGURABLE_API_ENDPOINT) {
      const token = await keeper.getToken();
      const url = `${CONFIGURABLE_API_ENDPOINT}/v2/3/getDocument?dokkaToken=${token}&companyId=${company.id}&documentID=${document.documentID}&original=true`;
      download(url, document.documentID);
    }
  };

  onLinkedDownload = async () => {
    const {
      downloadLinkedDocs,
      document: { linkid },
    } = this.props;

    downloadLinkedDocs({ linkid });

    toast.success(
      <FormattedMessage id="selectedPanel.download.toast" defaultMessage="The download will start in a few seconds" />,
      { autoClose: 7000 },
    );
  };

  onPrintLinked = async () => {
    const {
      documentGetLinkedIDs,
      dokkaToken,
      document: { linkid },
      company: { id },
      printPreparationCompleted,
      mergeDocumentsForPrinting,
    } = this.props;
    const ids = await promisify(documentGetLinkedIDs, { linkid });

    if (ids.length > 1) {
      const pdfFile = await promisify(mergeDocumentsForPrinting, { documentID: ids });
      await onPrint(pdfFile, ids[0], false);
    } else {
      const url = Api.getDocumentUrl({ dokkaToken, documentID: ids[0], companyId: id });

      await print(url, ids[0], false);
    }
    printPreparationCompleted();
  };

  onEdit = () => {
    // eslint-disable-next-line no-shadow
    const { document, setEditableDoc, navigateReplace, backLink } = this.props;
    setEditableDoc(document);
    navigateReplace(backLink);
  };

  onSign = (signatureID) => {
    const { signDocument, document } = this.props;
    signDocument({ signatureID, documentID: document.documentID });
  };

  get url() {
    const { dokkaToken, document, company } = this.props;
    return `${CONFIGURABLE_API_ENDPOINT}/v2/3/getDocument?dokkaToken=${dokkaToken}&companyId=${company.id}&documentID=${document.documentID}`;
  }

  get actionsList(): Array<*> {
    const {
      restriction,
      document: { doctype, notes, status, tags },
      isCurrentUserSignaturePending,
    } = this.props;
    const { actions } = this;

    return actionsList({ restriction, doctype, notes, status, tags }, isCurrentUserSignaturePending).map(
      ({ id, defaultMessage, submenu, ...rest }) => ({
        id,
        title: { id, defaultMessage },
        ...rest,
        ...actions[id],
        ...(submenu && { submenu: submenu.map((m) => ({ ...m, ...actions[m.id] })) }),
      }),
    );
  }

  get actions(): ActionsList {
    const {
      isGranted,
      company,
      document,
      rootCategoriesList,
      isEditActionsBlocked,
      getIndicateWarningByDoc,
      disableGenerteJE,
    } = this.props;
    const { documentID, linkid, status, viewinfo, canBeMoved } = document;
    const indicateWarning = getIndicateWarningByDoc(document);
    const isPaid = isDocPaid(document);
    const isLocked = linkid.includes('_locked');

    const signIsNotNecessary = !checkAcceptSignType(documentID);
    return {
      'document.show.actions.editNotes': {
        onClick: this.noteHandler,
      },
      'document.show.actions.addNotes': {
        onClick: this.noteHandler,
      },
      'document.show.actions.tagAs': {
        onClick: () => this.onModalInActionsShow('tagsModal'),
      },
      'document.show.actions.sign': {
        onClick: () => this.onModalInActionsShow('signModal'),
        divided: true,
        disabled:
          !company ||
          !company.getIn(['signParams', 'guid']) ||
          status.indexOf('signed') + 1 > 0 ||
          signIsNotNecessary ||
          document.protected,
      },
      'document.show.actions.generateJE': {
        onClick: this.onDocumentSetAsFinancial,
        disabled: disableGenerteJE,
      },
      'document.show.actions.generateG': {
        onClick: this.onDocumentSetAsGeneral,
        disabled: document.tags.contains('_S_ACCEPTED') || isEditActionsBlocked,
      },
      'document.show.actions.paid': {
        onClick: this.markAsPaid(true),
        isHidden: isPaid,
      },
      'document.show.actions.ignoreWarnings': {
        divided: true,
        onClick: this.ignoreWarnings,
        isHidden: !indicateWarning,
      },
      'document.show.actions.unpaid': {
        onClick: this.markAsPaid(false),
        isHidden: !isPaid,
      },
      'document.show.actions.moveTo': {
        onClick: (x) => x,
        isHidden: !isAllowMoveToCategories({
          isGranted,
          currentRootCategoryId: document.rootCategory,
          rootCategories: rootCategoriesList,
        }),
        submenu: this.getMoveToSubmenu(),
        disabled: isLocked,
      },
      'document.show.actions.moveToCompany': {
        onClick: () => this.onModalInActionsShow('moveToCompany'),
        isHidden: linkid,
        disabled: !canBeMoved,
      },
      'document.show.actions.linkedBundle': {
        isHidden: !linkid,
      },
      'document.show.actions.moveLinkedToCompany': {
        onClick: () => this.onModalInActionsShow('moveToCompany'),
        disabled: isLocked,
      },
      'document.show.actions.reject': {
        onClick: () => this.onModalInActionsShow('rejectModal'),
        disabled:
          document.tags.contains('_S_REJECTED') || document.tags.contains('_S_ACCEPTED') || isEditActionsBlocked,
      },
      'document.show.actions.duplicate': {
        onClick: () => this.onDuplicateDocument(),
      },
      'document.show.actions.sendByEmail': {
        onClick: () => this.onModalInActionsShow('sendByEmail'),
        divided: true,
      },
      'document.show.actions.download': {
        onClick: this.onDownload,
      },
      'document.show.actions.sendByEmailLinked': {
        onClick: () => this.onModalInActionsShow('sendByEmail'),
        isHidden: !linkid,
      },
      'document.show.actions.downloadLinked': {
        onClick: this.onLinkedDownload,
        isHidden: !linkid,
      },
      'document.show.actions.edit': {
        onClick: this.onEdit,
        disabled:
          documentID.slice(-4) !== '.pdf' ||
          viewinfo.pages < 2 ||
          status.indexOf('accepted') + 1 > 0 ||
          isEditActionsBlocked,
      },
      'document.show.actions.print': {
        onClick: async () => print(this.url, document.documentID, document.protected),
        disabled: isFirefox,
      },
      'document.show.actions.printLinked': {
        onClick: this.onPrintLinked,
        isHidden: !linkid,
      },
      'document.show.actions.delete': {
        divided: true,
        onClick: this.onDeleteDocument,
        disabled: (~document.status.indexOf('accepted') && !isGranted(ACL.IS_ADMIN)) || linkid.endsWith('locked'),
        isDanger: true,
      },
    };
  }

  getMoveToSubmenu = () => {
    const {
      document: { documentID, rootCategory, isConfidential, linkid },
      rootCategoriesList,
    } = this.props;
    const movableRootCategoryList = getMovableCategories(rootCategory, rootCategoriesList);

    const res = movableRootCategoryList
      .map((cat) => ({
        id: `category.name.${cat.nameLangId}`,
        title: { id: `category.name.${cat.nameLangId}`, defaultMessage: cat.name },
        disabled: false,
        onClick: () => {
          this.moveTo({
            linkid,
            categoryId: cat.id,
            documentID: [documentID],
            workspaceType: isConfidential ? 'confidential' : 'standard',
          });
        },
      }))
      .toJS();
    return res;
  };

  isAllowAutoConfirm = () => {
    const { userFeatures } = this.props;
    return !userFeatures.get('modal', true);
  };

  noteHandler = () => {
    const { onNoteHandler } = this.props;
    const exec = onNoteHandler ? () => onNoteHandler() : () => this.onModalInActionsShow('notesModal');
    exec();
  };

  moveDocument = (payload) => {
    const {
      moveDocumentToCategory,
      getDocumentMetadata,
      document,
      company,
      documentGetLinked,
      documentLinkedTag,
      goToNextDocument,
    } = this.props;

    const promise = new Promise((resolve, reject) => {
      moveDocumentToCategory({
        ...payload,
        resolve,
        reject,
      });
    });

    // reset linked panel
    promise.then(() => {
      if (documentLinkedTag === document.linkid) {
        documentGetLinked({ tag: documentLinkedTag });
      }
      goToNextDocument(() => {}, 0);
      getDocumentMetadata({ documentId: document.documentID, companyId: company.id });
    });
  };

  moveTo = (payload: DocDropParams) => {
    const {
      rootCategoriesList,
      confirm,
      intl: { formatMessage },
    } = this.props;

    if (payload.linkid) {
      const category = rootCategoriesList.find((i) => i.id === payload.categoryId);

      const values = {
        catName: (
          <span style={{ fontWeight: 600 }}>
            <FormattedMessage id={`category.name.${category.nameLangId}`} defaultMessage={category.name} />
          </span>
        ),
      };
      const confirmOptions = {
        title: (
          <FormattedMessage
            id="document.show.modals.moveLinked.title"
            defaultMessage="Move linked document to {catName}"
            values={values}
          />
        ),
        description: (
          <FormattedMessage
            id="document.show.modals.moveLinked.placeholder.moveOneAndUnlink"
            defaultMessage="Linked document will be moved to {catName} and unlinked. Are you sure?"
            values={values}
          />
        ),
        confirmationText: formatMessage({
          id: 'button.yes',
          defaultMessage: 'Yes',
        }),
      };
      if (this.isAllowAutoConfirm()) {
        this.moveDocument(payload);
      } else {
        confirm({ ...confirmOptions }).then(() => {
          this.moveDocument(payload);
        });
      }
    } else {
      this.moveDocument(payload);
    }
  };

  markAsPaid = (isPaid: boolean) => () => {
    const { document, markAsPaid } = this.props;
    markAsPaid({ documentId: document.documentID, isPaid });
  };

  ignoreWarnings = () => {
    const { ignoreDocumentWarnings, document } = this.props;
    promisify(ignoreDocumentWarnings, { documentId: document.documentID }).finally();
  };

  render() {
    const { document, handleActionModal, company, isGoNext, isGranted, backLink, onManageApprovals } = this.props;

    const {
      tagsModal,
      notesModal,
      rejectModal,
      signModal,
      approveApprovalFlow,
      rejectApprovalFlow,
      moveToCompany,
      sendByEmail,
    } = this.state;
    const isSupplier = isGranted(ACL.IS_SUPPLIER_USER);

    return (
      <>
        {!isSupplier && <DocumentMoreButton options={this.actionsList} />}

        {tagsModal && (
          <DialogTagsManage
            open={tagsModal}
            initialTags={document.tags.toJS()}
            onSubmit={this.onTagsUpdate}
            onCancel={() => this.onModalInActionsClose('tagsModal')}
          />
        )}
        {notesModal && (
          <DialogNotes
            open={notesModal}
            value={document.notes}
            noteColorKey={document.notesColor}
            onClose={() => this.onModalInActionsClose('notesModal', handleActionModal)}
            documentID={document.documentID}
          />
        )}
        {rejectModal && (
          <DialogRejectDocument
            onSubmit={this.onRejectDocument}
            onClose={() => this.onModalInActionsClose('rejectModal')}
          />
        )}

        {signModal && (
          <DialogSignDocument
            onSign={this.onSign}
            documents={Set.of(document)}
            signParams={company.get('signParams')}
            onClose={() => this.onModalInActionsClose('signModal')}
          />
        )}

        {rejectApprovalFlow && (
          <DialogRejectApproval
            onClose={() => this.onModalInActionsClose('rejectApprovalFlow', handleActionModal)}
            onManageApprovals={onManageApprovals}
          />
        )}

        {approveApprovalFlow && (
          <DialogApprovalFormApprove
            onManageApprovals={onManageApprovals}
            onClose={() => this.onModalInActionsClose('approveApprovalFlow', handleActionModal)}
            isGoNext={isGoNext}
          />
        )}
        {moveToCompany && (
          <DialogMoveDocumentsToCompany
            documentId={document.documentID}
            onClose={() => this.onModalInActionsClose('moveToCompany')}
            backLink={backLink}
            linkID={document.linkid}
          />
        )}
        {sendByEmail && (
          <DialogSendByEmail
            documentIds={[document.documentID]}
            onClose={() => this.onModalInActionsClose('sendByEmail')}
            linkID={document.linkid}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  restriction: ACL.myRestriction(state),
  rootCategoriesList: rootCategoriesListSelector(state),
  isGranted: ACL.isGranted(state),
  documentLinkedTag: documentLinkedTagSelector(state),
  navigation: navigationSelector(state),
  userFeatures: userFeaturesSelector(state),
  getIndicateWarningByDoc: getIndicateWarningByDocSelector(state),
});

const mapDispatchToProps = {
  documentRemove,
  documentSetAsFinancial: documentSetAsFinancialAction,
  documentSetAsGeneral: documentSetAsGeneralAction,
  documentTagsUpdate: documentUpdateTags,
  documentReject,
  documentAccept,
  setEditableDoc,
  signDocument: documentSignShowDocument,
  duplicateDocument: duplicateDocumentAction,
  markAsPaid: markAsPaidAction,
  navigateReplace: navigate.replace,
  moveDocumentToCategory: moveDocumentToCategoryAction,
  getDocumentMetadata: documentGet,
  documentGetLinked: documentGetLinkedAction,
  ignoreDocumentWarnings: documentIgnoreWarningsAction,
  downloadLinkedDocs: documentsDownloadDocumentsAction,
  documentGetLinkedIDs: documentGetLinkedIDsAction,
  printPreparationCompleted: () => ({ type: documentGetLinkedIDsAction.success }),
  mergeDocumentsForPrinting: mergeDocumentsForPrintingAction,
};

export default compose(
  injectIntl,
  withConfirm,
  connect(mapStateToProps, mapDispatchToProps),
)(DocumentMoreButtonDialogs);
