// @flow
import React, { useState, useCallback } from 'react';
import MuiAutocomplete, { type AutocompleteProps } from '@mui/material/Autocomplete';
import ClickAwayListener from '@mui/material/ClickAwayListener';

const Autocomplete: React$StatelessFunctionalComponent<AutocompleteProps> = (props) => {
  const [open, setOpen] = useState(false);

  const onClose = useCallback((e, r) => {
    // when we switched between tabs or desktops we shouldn't close autocomplete dropdown
    // only when outside menu click detected
    if (r !== 'blur') {
      setOpen(false);
    }
  }, []);

  const onOpen = useCallback(() => setOpen(true), []);

  const onClickAway = useCallback(() => setOpen(false), []);

  return (
    <ClickAwayListener onClickAway={onClickAway}>
      <MuiAutocomplete
        clearOnBlur={false}
        disableClearable
        filterSelectedOptions
        multiple
        open={open}
        onOpen={onOpen}
        onClose={onClose}
        {...props}
      />
    </ClickAwayListener>
  );
};

export default Autocomplete;
