// @flow
import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { useConfirm } from 'material-ui-confirm';

import { deleteOrganizationUser, organizationEnhancedUsersSelector } from 'domain/organizationUser';

import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import DeleteIcon from '@mui/icons-material/Delete';
import SearchBase from 'components/mui/Layouts/components/AppbarSearchBase';
import NameCell from 'pages/configurations/components/Table/components/NameCell';
import Table from 'pages/configurations/components/Table';
import EmailCell from './components/EmailCell';
import useSearchAndSort from 'pages/configurations/hooks/useSearchAndSort';
import { sortUsers } from 'pages/configurations/helpers';

import type { EnhancedUser } from 'domain/organizationUser/types.js.flow';

type TOrganizationUsersList = {
  onClickListItem: (id: string) => void,
};

const mapStateToProps = (state) => ({
  enhancedUsers: organizationEnhancedUsersSelector(state),
});

const OrganizationUsersList = ({ onClickListItem }: TOrganizationUsersList) => {
  const dispatch = useDispatch();
  const confirm = useConfirm();
  const { enhancedUsers } = useSelector(mapStateToProps);
  const { formatMessage } = useIntl();

  const generateStringForSearch = useCallback(
    (user) => `${user.display_name} ${user.email} ${user.role} ${user.phone}`,
    [],
  );

  const { debounceOnChangeSearch, handleChangeSort, searchText, rows, sort, sortBy } = useSearchAndSort({
    generateStringForSearch,
    initialList: enhancedUsers,
    defaultSortFn: sortUsers,
  });

  const onDelete = useCallback(
    (e, id: string) => {
      e.preventDefault();
      e.stopPropagation();

      confirm({
        title: formatMessage({
          id: 'confirm.user.organization.delete.title',
          defaultMessage: 'Delete User?',
        }),
        description: formatMessage({
          id: 'confirm.user.organization.delete.description',
          defaultMessage: 'User will be permanently deleted from the system, action cannot be  undone.',
        }),
        confirmationText: formatMessage({
          id: 'button.delete',
          defaultMessage: 'Delete',
        }),
        confirmationButtonProps: {
          color: 'error',
        },
      }).then(() => {
        dispatch(deleteOrganizationUser({ id }));
      });
    },
    [dispatch, formatMessage, confirm],
  );

  const columns = useMemo(
    () => [
      {
        id: 'display_name',
        label: formatMessage({ id: 'configurations.company.users.tableHeader.fullName', defaultMessage: 'Full name' }),
        styles: { minWidth: 170 },
        component: (user: EnhancedUser) => <NameCell name={user.display_name} picture={user.pic} />,
      },
      {
        id: 'email',
        label: formatMessage({ id: 'configurations.company.users.tableHeader.email', defaultMessage: 'Email' }),
        styles: { minWidth: 100 },
        component: (user: EnhancedUser) => <EmailCell user={user} />,
      },
      {
        id: 'display_role',
        label: formatMessage({ id: 'configurations.company.users.tableHeader.role', defaultMessage: 'Role' }),
        styles: { minWidth: 170 },
      },
      {
        id: 'phone',
        label: formatMessage({ id: 'configurations.company.users.tableHeader.phone', defaultMessage: 'Phone number' }),
        styles: { minWidth: 170 },
      },
      {
        id: 'assignedCount',
        label: formatMessage({
          id: 'configurations.org_users.list.assigned_companies',
          defaultMessage: 'Assigned companies',
        }),
        styles: { minWidth: 170 },
        component: (user: EnhancedUser) => (
          <>
            {user.assignedCount ? (
              <Typography variant="body1">{user.assignedCount}</Typography>
            ) : (
              <Button
                size="small"
                color="error"
                variant="outlined"
                onClick={(e) => onDelete(e, user.id)}
                startIcon={<DeleteIcon />}
              >
                {formatMessage({
                  id: 'button.delete',
                  defaultMessage: 'Delete',
                })}
              </Button>
            )}
          </>
        ),
      },
    ],
    [formatMessage, onDelete],
  );

  return (
    <>
      <Box p={2}>
        <SearchBase value={searchText} onChange={debounceOnChangeSearch} />
      </Box>
      <Table
        data={rows}
        columns={columns}
        sort={sort}
        sortBy={sortBy}
        onClickRow={onClickListItem}
        handleChangeSort={handleChangeSort}
      />
    </>
  );
};

export default OrganizationUsersList;
