// @flow
import React, { useCallback, useMemo } from 'react';
import { useParams, Link } from 'react-router-dom';
import { getDocumentUrl } from 'pages/company/workSpace/helpers';

import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';

type TPreviewCellRenderer = any;

const PreviewCellRenderer: React$StatelessFunctionalComponent<TPreviewCellRenderer> = ({
  data,
  context,
  colDef,
  api,
  node,
}) => {
  const { companyId } = useParams();
  const {
    rowDocumentData: { documentID },
  } = data;

  const {
    components: {
      [colDef.cellRenderer]: { onClickPreview, onClickDocument },
    },
  } = context;

  const to = useMemo(() => getDocumentUrl(companyId, documentID), [companyId, documentID]);

  const handlePreviewClick = useCallback(() => {
    onClickPreview(documentID);

    // it is way hard row resetting allows us to avoid grid's scrolling during navigation preview by arrow keys
    api.redrawRows({ rowNodes: [node] });
  }, [onClickPreview, documentID, api, node]);

  return (
    <Stack flex={1} direction="row" justifyContent="center" alignItems="center">
      <IconButton onClick={handlePreviewClick} sx={{ fontSize: '22px' }}>
        <RemoveRedEyeOutlinedIcon fontSize="inherit" />
      </IconButton>
      <IconButton to={to} component={Link} onClick={onClickDocument} sx={{ fontSize: '22px' }}>
        <InsertDriveFileOutlinedIcon fontSize="inherit" />
      </IconButton>
    </Stack>
  );
};

export default PreviewCellRenderer;
