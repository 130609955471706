// @flow
import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import SwitchBase from 'components/mui/Form/Switch/SwitchBase';
import { FormControlLabelSwitch } from 'components/mui/Form/FormControl/FormControlLabelSwitch';

// eslint-disable-next-line react/prop-types
const Wrapper = ({ children, ...other }) => (
  <Stack direction="row" alignItems="center" justifyContent="center" spacing={1} {...other}>
    {children}
  </Stack>
);

type Props = {
  onApprove: () => void,
  onReject: () => void,
  onChangeSignature: (s: boolean) => void,
  signatureStatus: 'disabled' | 'show' | 'hide',
  isApproveEnable: boolean,
  isRejectEnable: boolean,
};

class MobileApprovalFooter extends React.Component<Props> {
  render() {
    const { onApprove, onReject, signatureStatus, onChangeSignature, isApproveEnable, isRejectEnable } = this.props;

    return (
      <Wrapper justifyContent="space-between">
        <FormControlLabelSwitch
          onChange={onChangeSignature}
          disabled={signatureStatus === 'disabled'}
          checked={['show', 'disabled'].includes(signatureStatus)}
          control={<SwitchBase />}
          label={<FormattedMessage id="mobile.approval.signatures" defaultMessage="Signatures" />}
        />
        <Stack direction="row" spacing={1}>
          <Button color="error" disabled={!isRejectEnable} onClick={onReject}>
            <FormattedMessage id="mobile.approval.reject" defaultMessage="Reject" />
          </Button>
          <Button color="secondary" disabled={!isApproveEnable} onClick={onApprove}>
            <FormattedMessage id="mobile.approval.approve" defaultMessage="Approve" />
          </Button>
        </Stack>
      </Wrapper>
    );
  }
}

export default MobileApprovalFooter;
