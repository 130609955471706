// @flow
import React from 'react';
import { call, select, put, cancelled } from 'redux-saga/effects';
import * as Sentry from '@sentry/browser';
import { Set } from 'immutable';
import Api, { doLogout } from 'domain/api';
import { documentGetTagsAction, documentSelector, documentDocumentIdSelector } from 'domain/documents';
import { get as _get } from 'lodash';
import { FormattedMessage } from 'react-intl';

import * as actions from './actions';
import * as adapters from './adapters';
import * as selectors from './selectors';
import * as ApprovalActions from 'domain/approvals/actions';
import * as Textract from 'domain/textract/sagas';
import { ApprovalsStoreAdapter } from 'domain/approvals';
import { textMapAdapter, listToOptions, swapJEGridLineColumnsData, JournalSettingOrderFactory } from './helper';
import { ToastEventHandler } from 'components/Tables/toast';
import { documentGet } from 'domain/documents/documentsActions';
import { documentAdapterValidated } from 'domain/documents/helpers';
import indexedDb from 'indexedDb';
import type { TIndexListRaw } from 'domain/journal/types.js.flow';

import { QUERY_ALL_PAGES } from 'components/Tables/Autocomplete/PaginationAutocomplete';
import { jeGridLineColumnsDictionarySelector } from './selectors';
import { matchRoute } from 'domain/router/utils';
import {
  getJEntryIndexedDBTableStoreKey,
  migrateJETablesFromLetterToID,
  migrateJETableDisabledColumnsToTableColumnsVisibility,
} from 'domain/journal/sagas.migrations';
import { yieldSuccessNotification } from 'lib/toasts';

export { actions };

function getJEFactory(action) {
  return function* ensureJE(
    documentID,
    needUpdateDocument = true,
    reindexCache = false,
    page = 1,
    pageSize = 30,
    readonly,
    resolve,
    reject,
  ) {
    yield put({
      type: action.request,
    });

    try {
      const { data } = yield call(Api.getJournalEntry, {
        params: {
          documentID,
          reindexCache,
          page,
          pageSize,
          readonly,
        },
      });

      const payload = adapters.JEAdapter(data);

      yield put({
        type: action.success,
        payload,
      });

      // updating document with data coming from je response
      // used to update tags after je was retrieved as tags might change
      // during je is retrieved

      // we do not update the document when binding columns, as this causes problems for xls
      // documents (the binding is done in pdf view, and after rerendering we get the document in xls view,
      // and the binding data is lost until we refresh the page)
      if (needUpdateDocument) {
        try {
          yield put({
            type: documentGet.success,
            payload: documentAdapterValidated(data.document),
          });
        } catch (err) {
          Sentry.captureMessage('Failed tp update document from JE response');
          console.error('Failed tp update document from JE response', err);
        }
      }

      // This was transfered to ComponentDidMount
      // as shown tosts will be removed by ComponentWillUnmount
      // when navigating with NextDocument
      // ToastEventHandler.onResponce(data);

      if (typeof resolve === 'function') resolve();
    } catch (err) {
      yield put({
        type: action.failure,
        err,
      });
      if (typeof reject === 'function') reject();
      yield doLogout(action, err);
    }
  };
}

export const ensureJounalEntry = getJEFactory(actions.getJournalEntryAction);

export function updateJEFactory(inAdapter) {
  return function* ensureUpdateJE({ payload }) {
    try {
      const jeName = yield select(selectors.jeNameSelector);
      const update = inAdapter(payload);

      // eslint-disable-next-line
      const { data } = yield call(Api.updateJournalEntry, {
        data: {
          update: JSON.stringify(update),
          documentID: payload.documentID,
          ...payload.pageParams,
          ...(payload.hotkey ? { hotkey: payload.hotkey } : {}),
        },
      });
      ToastEventHandler.onResponce(adapters.messagesAdapter(data.messages));

      const jePayload = adapters.JEAdapter(data);
      const { document } = data;

      if (jePayload.jeName !== jeName) {
        yield call(Textract.ensureGetExtractedTableFieldsMapping, payload.documentID);
      }

      yield put({
        type: actions.updateJournalEntryAction.success,
        payload: jePayload,
      });

      if (typeof payload.resolve === 'function') {
        payload.resolve();
      }
      yield put({
        type: documentGetTagsAction.success,
        payload: new Set(document.tags),
      });

      yield put({
        type: ApprovalActions.updateApprovalAction.success,
        payload: ApprovalsStoreAdapter(document.approvals),
      });
    } catch (err) {
      if (typeof payload.reject === 'function') payload.reject();
      yield doLogout(actions.updateJournalEntryAction, err);
    } finally {
      /** sagas may cancelled because wee use takeLatest */
      if (yield cancelled()) {
        if (typeof payload.reject === 'function') payload.reject();
      }
    }
  };
}

export function* ensureUpdateJEFromInsights({ payload }) {
  try {
    const { data } = yield call(Api.updateJournalEntryFromInsights, {
      data: {
        ...payload,
      },
    });

    const jePayload = adapters.JEAdapter(data);

    yield put({
      type: actions.updateJEFromInsightsAction.success,
      payload: jePayload,
    });

    yieldSuccessNotification(
      <FormattedMessage
        id="document.insights.toast.copyDataSuccess"
        defaultMessage="Document data updated successfully"
      />,
    );
  } catch (err) {
    yield doLogout(actions.updateJEFromInsightsAction, err);
  }
}

// eslint-disable-next-line max-len
export const ensureInsertGrid = updateJEFactory(({ grid, cell, polygons }) =>
  adapters.gridAdapter(grid, cell, polygons),
);
export const ensureUpdateJE = updateJEFactory(({ item }) => item);
// eslint-disable-next-line max-len
export const ensureUpdateEntry = updateJEFactory(({ item, selectedRows }) => adapters.itemAdapter(item, selectedRows));
// eslint-disable-next-line max-len
export const ensureBulkCommit = updateJEFactory(({ items, col }) => adapters.bulkCommit(items, col));

export function* ensureTextMap(documentID) {
  yield put({
    type: actions.getTextMapAction.request,
  });
  try {
    const { data } = yield call(Api.getTextMap, {
      params: { documentID },
    });
    yield put({
      type: actions.getTextMapAction.success,
      payload: textMapAdapter(data),
    });
  } catch (err) {
    yield put({
      type: actions.getTextMapAction.failure,
      err,
    });
  }
}

export function* ensureJounalEntryPage({ payload }) {
  const getJE = getJEFactory(actions.getJournalEntryPageAction);
  const { offset, page, resolve, reject } = payload;
  const { documentID } = yield select(documentSelector);
  try {
    yield call(getJE, documentID, false, page, offset);
    resolve(payload.pageList);
  } catch (err) {
    reject(err);
  }
}

// retrieves JE but dispatches request action after async call finished
// not to cause empty JE on document page while call is in progress
export function* ensureSyncJE(documentID, reindexCache = true, page = 1, pageSize = 30, resolve, reject) {
  const { getJournalEntryAction } = actions;

  try {
    const { data } = yield call(Api.getJournalEntry, {
      params: {
        documentID,
        reindexCache,
        page,
        pageSize,
      },
    });

    // DA-7033 when we mark document paid/unpaid, we only need to show toast on document page
    const isDocumentPage = matchRoute.isDocument(window.location.pathname);

    if (isDocumentPage) {
      ToastEventHandler.onResponce(adapters.messagesAdapter(data.messages));
    }

    // updating document with data coming from je response
    // used to update tags after je was retrieved as tags might change
    // during je is retrieved
    try {
      yield put({
        type: documentGet.success,
        payload: documentAdapterValidated(data.document),
      });
    } catch (err) {
      Sentry.captureMessage('Failed tp update document from JE response');
      console.error('Failed tp update document from JE response', err);
    }

    yield put({
      type: getJournalEntryAction.request,
    });
    yield put({
      type: getJournalEntryAction.success,
      payload: adapters.JEAdapter(data),
    });

    if (typeof resolve === 'function') resolve();
  } catch (err) {
    yield put({
      type: getJournalEntryAction.failure,
      err,
    });
    if (typeof reject === 'function') reject();
    yield doLogout(actions.getJournalEntryAction, err);
  }
}

export function* ensureReconcileStatistic({ payload: { resolve, reject } }) {
  const currentDocument = yield select(documentSelector);
  const selectedLines = yield select(selectors.selectedRowSelector);
  try {
    const { data } = yield call(Api.getReconcileStatistic, {
      data: {
        selected_lines: selectedLines,
        documentID: currentDocument.documentID,
      },
    });
    resolve(data);
  } catch (err) {
    reject(err);
    yield doLogout(actions.getReconcileStatisticAction, err);
  }
}

export function* ensureGetJournalSettingsPinnedCols() {
  const jeGridLineColumnsDictionary = yield select(jeGridLineColumnsDictionarySelector);
  const storeKey = yield call(getJEntryIndexedDBTableStoreKey);

  if (storeKey) {
    try {
      const data = yield indexedDb.tablePinnedCols.get(storeKey);
      const columns = _get(data, 'pinnedCols', []);
      yield put({
        type: actions.getJournalSettingsPinnedColsAction.success,
        payload: swapJEGridLineColumnsData(columns, jeGridLineColumnsDictionary, true),
      });
    } catch (err) {
      yield doLogout(actions.getJournalSettingsPinnedColsAction, err);
    }
  }
}

export function* ensureUpdateJournalSettingsPinnedCols({ payload }) {
  const jeGridLineColumnsDictionary = yield select(jeGridLineColumnsDictionarySelector);
  const storeKey = yield call(getJEntryIndexedDBTableStoreKey);

  if (storeKey) {
    try {
      yield indexedDb.tablePinnedCols.put({
        tableName: storeKey,
        pinnedCols: swapJEGridLineColumnsData(payload, jeGridLineColumnsDictionary),
      });
      yield put({
        type: actions.updateJournalSettingsPinnedColsAction.success,
        payload,
      });
    } catch (err) {
      yield doLogout(actions.updateJournalSettingsPinnedColsAction, err);
    }
  }
}

export function* ensureGetJournalSettingsOrderedCols() {
  const storeKey = yield call(getJEntryIndexedDBTableStoreKey);
  if (storeKey) {
    try {
      const data = yield indexedDb.tableOrderedCols.get(storeKey);
      const payload = data.orderedCols || {};
      yield put({
        type: actions.getJournalSettingsOrderedColsAction.success,
        payload: JournalSettingOrderFactory(payload),
      });
    } catch (err) {
      yield doLogout(actions.getJournalSettingsOrderedColsAction, err);
    }
  }
}

export function* ensureUpdateJournalSettingsOrderedCols({ payload }) {
  const storeKey = yield call(getJEntryIndexedDBTableStoreKey);
  if (storeKey) {
    try {
      yield indexedDb.tableOrderedCols.put({
        tableName: storeKey,
        orderedCols: payload.toJS(),
      });

      yield put({
        type: actions.updateJournalSettingsOrderedColsAction.success,
        payload,
      });
    } catch (err) {
      yield doLogout(actions.updateJournalSettingsOrderedColsAction, err);
    }
  }
}

// payload - Array of Letters [A, B, D] - array of disabled columns
export function* ensureUpdateJELineColumnsVisibility({ payload }) {
  // lineColumnsDictionary - OrderedMap<columnLetter, columnID>
  const lineColumnsDictionary = yield select(jeGridLineColumnsDictionarySelector);
  const storeKey = yield call(getJEntryIndexedDBTableStoreKey);

  if (storeKey) {
    try {
      // build data for indexedDB
      // Object<columnID, boolean> - if not exist in payload = visible column
      const lineColumnsVisibility = lineColumnsDictionary.reduce(
        (prevObj, columnID, columnLetter) => ({
          ...prevObj,
          [columnID]: !payload.includes(columnLetter),
        }),
        {},
      );

      yield indexedDb.tableJELineColumnsVisibility.put({
        tableName: storeKey,
        lineColumnsVisibility,
      });
      yield put({
        type: actions.updateJELineColumnsVisibilityAction.success,
        payload,
      });
    } catch (err) {
      yield doLogout(actions.updateJELineColumnsVisibilityAction, err);
    }
  }
}

export function* ensureJELineColumnsVisibility() {
  const storeKey = yield call(getJEntryIndexedDBTableStoreKey);
  // OrderedMap<columnLetter, columnID>
  const lineColumnsDictionary = yield select(jeGridLineColumnsDictionarySelector);
  // initialHiddenColumns - immutable List<columnLetter>,
  const initialHiddenColumnLetters = yield select(selectors.initialHiddenColumnsSelector);

  if (storeKey) {
    try {
      const dbLineColumnsVisibility = yield indexedDb.tableJELineColumnsVisibility.get(storeKey);

      if (dbLineColumnsVisibility && dbLineColumnsVisibility.lineColumnsVisibility) {
        // storedColumns - Object<columnID, boolean>
        const storedColumns = dbLineColumnsVisibility.lineColumnsVisibility;
        // filter columns that are hidden in store
        const storedHiddenColumnIDs = Object.entries(storedColumns)
          .filter(([, visible]) => !visible)
          .map(([columnID]) => columnID);
        // in store we save column by id, but for work with JE grid needed letters, so convert from ids to letters
        const storedHiddenColumnLetters = swapJEGridLineColumnsData(storedHiddenColumnIDs, lineColumnsDictionary, true);

        // we check if there is difference between stored columns and columns from b-end
        // for example: in b-end were added or deleted columns, so we need get stored columns and compare with columns from b-end
        // if we find difference between columns state, than we should add/delete column to/from store
        // storedColumns = saved columns in IndexedDB, we always store initialHiddenColumnLetters if they don't exist in indexedDb
        // in redux(journalSettings) we store columns as Array<columnLetter>
        // compare count of columns from store and from b-end, if stored size less than from b-end,
        // that's mean columns were added
        const columnsWereAdded = Object.keys(storedColumns).length < lineColumnsDictionary.size;

        // if stored length more than from b-end, columns were deleted
        const columnsWereDeleted = Object.keys(storedColumns).length > lineColumnsDictionary.size;

        if (columnsWereAdded) {
          // determine which column was added and its hidden
          const newColumnLettersHidden = lineColumnsDictionary.reduce((prevData, columnID, columnLetter) => {
            // if undefined - new column, and if exist in initial hidden columns - hidden
            if (storedColumns[columnID] === undefined && initialHiddenColumnLetters.includes(columnLetter)) {
              return [...prevData, columnLetter];
            }

            return prevData;
          }, []);

          // put stored columns from indexedDB with new columns to redux and indexedDb
          yield call(ensureUpdateJELineColumnsVisibility, {
            payload: [...storedHiddenColumnLetters, ...newColumnLettersHidden],
          });
        } else if (columnsWereDeleted) {
          // flip dictionary from OrderedMap<columnLetter, columnID> to OrderedMap<columnID, columnLetter>
          const lineColumnsDictionaryFlipped = lineColumnsDictionary.flip();

          const newColumnIDsHidden = Object.entries(storedColumns).reduce((prevData, [columnID, visible]) => {
            // if columnID from store exist in dictionary and is hidden - add to new hidden columns
            if (lineColumnsDictionaryFlipped.get(columnID) !== undefined && visible === false) {
              return [...prevData, columnID];
            }

            return prevData;
          }, []);

          // put stored columns from indexedDB with new columns to redux and indexedDb
          yield call(ensureUpdateJELineColumnsVisibility, {
            // convert ids to letters and I pass already flipped dictionary, and therefore I don't use third parameters
            payload: swapJEGridLineColumnsData(newColumnIDsHidden, lineColumnsDictionaryFlipped),
          });
        } else {
          // if no difference between columns state - put stored columns from indexedDB to redux
          yield put({
            type: actions.getJELineColumnsVisibilityAction.success,
            payload: storedHiddenColumnLetters,
          });
        }
      } else {
        // first time on this document or something else - we don't have stored settings for columns visibility
        // save to indexedDB and put them to redux
        yield call(ensureUpdateJELineColumnsVisibility, { payload: initialHiddenColumnLetters.toJS() });
      }
    } catch (err) {
      yield doLogout(actions.getJELineColumnsVisibilityAction, err);
    }
  }
}

export function* ensureGetJEList({ payload }) {
  const documentID = yield select(documentDocumentIdSelector);
  const { isNewList, resolve, reject, query, include_id: includeId, preload = false } = payload;

  try {
    const {
      data: { list, pageToken },
    } = yield call(Api.getJEList, {
      params: {
        ...query,
        documentID,
      },
    });

    const optionsList = listToOptions(list);

    yield put({
      type: actions.getJEListAction.success,
      payload: {
        list: optionsList,
        pageToken,
        isNewList,
        include_id: includeId,
        preload,
      },
    });

    if (typeof resolve === 'function') {
      resolve(optionsList);
    }
  } catch (err) {
    yield doLogout(actions.getJEListAction, err);
    if (typeof reject === 'function') {
      reject();
    }
  } finally {
    if (yield cancelled()) {
      if (typeof reject === 'function') {
        reject();
      }
    }
  }
}

export function* ensureJournalEntryForGrid(
  documentID,
  reindexCache = false,
  page = 1,
  pageSize = 30,
  readonly,
  resolve,
  reject,
) {
  yield put({
    type: actions.getJournalEntryForGridAction.request,
  });

  try {
    const { data } = yield call(Api.getJournalEntry, {
      params: {
        documentID,
        reindexCache,
        page,
        pageSize,
        readonly,
      },
    });

    const payload = adapters.JEAdapter(data);

    yield put({
      type: actions.getJournalEntryForGridAction.success,
      payload,
    });

    if (typeof resolve === 'function') resolve();
  } catch (err) {
    yield put({
      type: actions.getJournalEntryForGridAction.failure,
      err,
    });
    if (typeof reject === 'function') reject();
    yield doLogout(actions.getJournalEntryForGridAction, err);
  }
  // order is important, be careful if you change it
  yield call(migrateJETablesFromLetterToID);
  yield call(migrateJETableDisabledColumnsToTableColumnsVisibility);
  yield call(ensureGetJournalSettingsPinnedCols);
  yield call(ensureJELineColumnsVisibility);
}

export function* ensureGetIndexListItemData({ payload }) {
  const { field, value, documentID, resolve, reject } = payload;

  try {
    const { data }: { data: TIndexListRaw } = yield call(Api.jeGetIndexForm, {
      params: {
        field,
        value,
        document_id: documentID,
      },
    });

    if (typeof resolve === 'function') {
      resolve(adapters.indexListAdapter(data));
    }
  } catch (err) {
    yield doLogout(actions.getJEIndexListItemDataAction, err);
    if (typeof reject === 'function') {
      reject(err);
    }
  } finally {
    if (yield cancelled()) {
      if (typeof reject === 'function') {
        reject();
      }
    }
  }
}

export function* ensureGetCachedPaginationList() {
  const cachedListIds = yield select(selectors.cachedPaginationListsIncludeIdsSelector);
  const entries = yield select(selectors.journalNotCachedPaginationListsSelector);

  const queryCreator = (cellName) => ({
    cellName,
    pageSize: QUERY_ALL_PAGES,
    pageToken: null,
    search: '',
  });

  // eslint-disable-next-line no-unused-vars,no-restricted-syntax
  for (const [i, { include_id: includeId, name }] of entries) {
    if (!cachedListIds.includes(includeId)) {
      const getCachedPaginationList = yield select(selectors.getCachedPaginationListSelector);
      const list = getCachedPaginationList(includeId);

      if (!list) {
        yield call(ensureGetJEList, {
          payload: {
            query: queryCreator(name),
            isNewList: true,
            include_id: includeId,
            preload: true,
          },
        });
        cachedListIds.push(includeId);
      }
    }
  }
}
