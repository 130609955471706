// @flow
import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { organization as organizationSelector, type OrganizationType, updateOrganization } from 'domain/organization';
import { type contact } from 'domain/organization/types.js.flow';
import ContactForm from 'pages/common/forms/contact';

type Props = {
  organization: OrganizationType,
  updateOrganization: (data: contact) => void,
};

class ContactFormContainer extends Component<Props> {
  onSubmit = (data: contact) => {
    this.props.updateOrganization({ ...data.toJS(), update_tab: 'contact_tab' });
  };

  render() {
    const { organization } = this.props;
    return <ContactForm initialValues={organization.contact} onSubmit={this.onSubmit} context="organization" />;
  }
}

const mapStateToProps = (state) => ({
  organization: organizationSelector(state),
});

export default compose(connect(mapStateToProps, { updateOrganization }))(ContactFormContainer);
