import * as React from 'react';
import { select, call, put, takeEvery } from 'redux-saga/effects';
import Api, { doLogout } from 'domain/api';
import type { Saga } from 'redux-saga';

import * as DocumentActions from 'domain/documents/documentsActions';
// utils
import { yieldSuccessNotification, yieldErrorNotification, handleErrorResponseWithToast } from 'lib/toasts';
import { FormattedMessage } from 'react-intl';
import { documentAdapter, documentAdapterValidated } from 'domain/documents/helpers';
import { documentDocumentIdSelector } from 'domain/documents/documentSelector';
// adapters
import { ApprovalsStoreAdapter } from 'domain/approvals/helpers';
import * as ApprovalActions from 'domain/approvals/actions';

export function* approveDocumentApprovals({ payload }: createArgs<string>): Saga<void> {
  // $FlowFixMe
  const { resolve, reject } = payload;
  const documentID = yield select(documentDocumentIdSelector);

  try {
    const { data } = yield call(Api.signApproval, {
      data: {
        documentID,
      },
    });

    yield put({
      type: DocumentActions.documentUpdateInListByID.type,
      payload: documentAdapter(data),
    });

    yield put({
      type: DocumentActions.documentGet.success,
      payload: documentAdapterValidated(data),
    });

    yieldSuccessNotification(
      <FormattedMessage id="document.approval.approve.success" defaultMessage="Document was approved for payment" />,
    );
    if (typeof resolve === 'function') resolve();
  } catch (error) {
    handleErrorResponseWithToast(error, {
      path: 'data.approvals.error',
      defaultMessage: (
        <FormattedMessage id="document.approval.approve.error" defaultMessage="Document wasn't approved for payment" />
      ),
    });
    if (typeof reject === 'function') reject();
  }
}

export function* rejectApproval({
  payload: { reason: reasonID, message, resolve, reject },
}: createArgs<{|
  reasonID: string,
  message: ?string,
  resolve: () => void,
  reject: () => void,
  isGoNext: boolean,
|}>): Saga<*> {
  const documentID = yield select(documentDocumentIdSelector);
  try {
    const { data } = yield call(Api.rejectApproval, {
      data: {
        documentID,
        reasonID,
        message,
      },
    });

    yield put({
      type: DocumentActions.documentUpdateInListByID.type,
      payload: documentAdapter(data),
    });

    yield put({
      type: ApprovalActions.rejectApprovalAction.success,
      payload: ApprovalsStoreAdapter(data.approvals),
    });

    yieldErrorNotification(
      <FormattedMessage id="document.rejectAlert.approvalWasRejected" defaultMessage="Approval was rejected" />,
    );

    if (typeof resolve === 'function') resolve();
  } catch (err) {
    handleErrorResponseWithToast(err, {
      path: 'data.approvals.error',
      defaultMessage: (
        <FormattedMessage id="document.approval.reject.error" defaultMessage="Approval was not rejected" />
      ),
    });
    if (typeof reject === 'function') reject();
    yield doLogout(ApprovalActions.rejectApprovalAction, err);
  }
}

export default function* approval(): Saga<*> {
  yield takeEvery(ApprovalActions.approveApprovalAction.type, approveDocumentApprovals);
  yield takeEvery(ApprovalActions.rejectApprovalAction.type, rejectApproval);
}
