// @flow
import * as React from 'react';
import moment from 'moment';
import type { RecordOf } from 'immutable';
import { withStyles } from '@mui/styles';
import { compose, type Dispatch } from 'redux';
import { connect } from 'react-redux';
import { injectIntl, type IntlShape, FormattedMessage } from 'react-intl';
import toast from 'components/Toast';
import cx from 'classnames';
import { saveFavoritesAction } from 'domain/dashboard/actions';
import { chatAllUsersByIdSelector } from 'domain/chat';
import type { ChatUser } from 'domain/chat/types.js.flow';
import SaveSearchPopup from 'pages/company/FavoriteTagsPanel/components/CreateFavoriteTagsModal';
import { DEFAULT_FORMAT } from 'components/Form/DayPicker/helper';
import Tooltip from 'components/Tooltip';
import elements from 'components/elements';
import { formatTagLabel, extractEmail } from 'lib/helpers';
import * as ACL from 'domain/restriction';

import sheet from './sheet';
import UserPic from 'components/UserPic/UserPic';
import { Map } from 'immutable';
import GridFilters from '../../pages/company/bar__legacy/GridFilters/GridFilters';
import { gridCurrentSettingsStateSelector } from 'domain/documents';
import { documentWorkSpaceTypeSelector, isGridWorkSpaceSelector } from 'domain/companies';
import { GridPresetConfigType } from 'domain/documents/types.js.flow';

type tagType = {|
  value: string,
  type: 'date' | 'text',
|};

type Query = {
  tags?: Array<string>,
  query?: string,
  label?: string,
  text?: Array<string>,
  date?: Array<string>,
};

type Props = {
  classes: {
    [key: string]: string,
  },
  intl: IntlShape,
  onFilter: (d: Query) => void,
  query: Query,
  count: number,
  saveFavorites: Dispatch<saveFavoritesAction>,
  gridCurrentSettings: GridPresetConfigType,
  gridRowsCount: number,
  isGridWorkSpace: boolean,
  workSpaceType: string,
  params: {
    documentType?: 'financial' | 'general',
  },
  // eslint-disable-next-line react/no-unused-prop-types
  confidential: boolean,
  dateFormat: ?string,
  usersById: Map<string, RecordOf<ChatUser>>,
  isGranted: (r: number | number[]) => boolean,
  selectedTagsRef?: ?React.ElementRef<HTMLDivElement>,
};

type ErrorInfo = {|
  status: number,
  id: string,
  message: string,
|};

type State = {|
  hover: boolean,
  popup: boolean,
  errorInfo: ?ErrorInfo,
|};

type FilterParsers = {
  text: (value: string) => string,
  date: (Array<string>) => Array<string>,
  tags: (value: string) => [],
};

const dateParcer = (dateFormat) => (value) => {
  const format = dateFormat || DEFAULT_FORMAT;
  return [value.map((v) => moment(v).format(format)).join(' - ')];
};

class SelectedTags extends React.Component<Props, State> {
  static defaultProps = {
    dateFormat: DEFAULT_FORMAT,
  };

  /* url params that should be rendered as selected tags */
  static filterParsers = (dateFormat: string): FilterParsers => ({
    text: (value) => value,
    date: dateParcer(dateFormat),
    invoice_date: dateParcer(dateFormat),
    payment_date: dateParcer(dateFormat),
    tags: () => [],
  });

  state = {
    hover: false,
    popup: false,
    errorInfo: null,
  };

  onSave = (label) => {
    const {
      params: { documentType },
      query,
      saveFavorites,
      intl,
    } = this.props;
    new Promise((resolve, reject) => {
      saveFavorites({ label, query, documentType, resolve, reject });
    })
      .then(() => {
        toast.info(
          intl.formatMessage({
            id: 'documents.tags.favorite.popup',
            defaultMessage: 'Search query was added to Favourite',
          }),
        );
        this.hideSavePopup();
      })
      .catch((errorInfo: ErrorInfo) => {
        if (errorInfo.status === 406) {
          toast.info(
            intl.formatMessage({
              id: `components.saveSearchPopup.error.${errorInfo.id}`,
              defaultMessage: errorInfo.message,
            }),
          );
          this.hideSavePopup();
        } else {
          this.setState({
            errorInfo,
          });
        }
      });
  };

  onHover = () => {
    this.setState({
      hover: true,
    });
  };

  onUnHover = () => {
    this.setState({
      hover: false,
    });
  };

  get tags(): Array<tagType> {
    const { query, dateFormat } = this.props;
    const filters = SelectedTags.filterParsers(dateFormat);
    const tags = Object.keys(query).reduce((A, key) => {
      if (key in filters) {
        const tagsInFilter = filters[key](query[key]);
        const tag = tagsInFilter && tagsInFilter.length > 0 ? tagsInFilter.map((v) => ({ value: v, type: key })) : [];
        return A.concat(tag);
      }
      return A;
    }, []);

    // label is system user attribute
    if (query.label) {
      // try to get user email from query
      const email = query.query ? extractEmail(query.query.split(' ').join('+')) : null;

      const value = email || query.label;
      tags.push({
        type: 'label',
        value,
      });
    }
    return tags;
  }

  getGridFilters = () => {
    const {
      gridCurrentSettings: { gridFilters },
      isGridWorkSpace,
    } = this.props;
    return isGridWorkSpace && Object.keys(gridFilters).length > 0 ? gridFilters : null;
  };

  resetError = () => {
    this.setState({
      errorInfo: null,
    });
  };

  showSavePopup = () => {
    this.setState({
      popup: true,
    });
  };

  hideSavePopup = () => {
    this.setState({
      popup: false,
      errorInfo: null,
    });
  };

  removeTag = (tag: tagType): void => {
    const { onFilter } = this.props;
    const result = this.tags
      .filter((t) => t.type === tag.type)
      .filter((t) => t.value !== tag.value)
      .map((t) => t.value);
    if (tag.type === 'label') {
      onFilter({
        stag: result,
        [tag.type]: result,
        query: null,
      });
    } else {
      onFilter({
        stag: result,
        [tag.type]: result,
      });
    }
  };

  renderPopup = () => {
    const { usersById } = this.props;
    const firstTag = this.tags[0].value.replace(/^#*/gi, '');
    const user = usersById.get(firstTag);
    const title = user ? user.username : firstTag;
    return (
      <SaveSearchPopup
        title={title}
        onCancel={this.hideSavePopup}
        onSubmit={this.onSave}
        errorInfo={this.state.errorInfo}
        resetError={this.resetError}
      />
    );
  };

  render() {
    const { classes, usersById, isGranted, selectedTagsRef, count } = this.props;
    const { hover } = this.state;
    const gridFilters = this.getGridFilters();
    const isSupplier = isGranted(ACL.IS_SUPPLIER_USER);

    return (
      <>
        {(this.tags.length > 0 || gridFilters) && (
          <div className={classes.underbar} data-element={elements.filterBar.result.container}>
            {!isSupplier && (
              <div className={classes.results} data-element={elements.filterBar.result.count}>
                <FormattedMessage id="documents.tags.results" defaultMessage="Results" />:{count}
              </div>
            )}
            <div
              className={cx(
                {
                  [classes.hovered]: hover,
                },
                classes.tagsWrapper,
              )}
              data-element={elements.filterBar.save.container}
              aria-hidden={!hover}
              ref={selectedTagsRef}
            >
              {this.tags.length > 0 && !isSupplier && (
                <Tooltip id="documents.tags.saveToFavorites" defaultMessage="Save to Favorites">
                  <button // eslint-disable-line
                    data-element={elements.filterBar.save.button}
                    onClick={this.showSavePopup}
                    className={classes.star}
                    onMouseOver={this.onHover}
                    onMouseLeave={this.onUnHover}
                  />
                </Tooltip>
              )}

              <ul className={classes.list} data-element={elements.filterBar.result.tags}>
                {this.tags.map((tag) => {
                  // tags will start with #
                  const user = usersById.get(tag.value.replace(/^#+/, ''));
                  return (
                    <li
                      key={` ${tag.value}${tag.type} `}
                      className={cx(classes.item, {
                        [classes.userItem]: user,
                        [classes.favHoveredItem]: hover,
                      })}
                      data-element={elements.filterBar.result.tag}
                    >
                      {user && <UserPic size={26} src={user ? user.picture : null} className={classes.userPic} />}

                      {['date', 'invoice_date', 'payment_date'].includes(tag.type) && (
                        <span>
                          <FormattedMessage id={`documents.tags.${tag.type}`} defaultMessage="Date Fil" />
                        </span>
                      )}
                      {user ? user.username : formatTagLabel(tag.value)}
                      <button
                        data-element={elements.filterBar.result.delete}
                        onClick={() => this.removeTag(tag)}
                        type="button"
                      />
                    </li>
                  );
                })}
                {gridFilters && this.tags.length > 0 && <li className={classes.separator} />}
                {gridFilters && <GridFilters gridFilters={gridFilters} isHide={hover} />}
              </ul>
            </div>
          </div>
        )}

        {this.state.popup ? this.renderPopup() : null}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  usersById: chatAllUsersByIdSelector(state),
  gridCurrentSettings: gridCurrentSettingsStateSelector(state),
  isGridWorkSpace: isGridWorkSpaceSelector(state),
  workSpaceType: documentWorkSpaceTypeSelector(state),
  isGranted: ACL.isGranted(state),
});

export default compose(
  injectIntl,
  connect(mapStateToProps, { saveFavorites: saveFavoritesAction }),
  withStyles(sheet),
)(SelectedTags);
