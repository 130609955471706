/* @flow */
import * as React from 'react';
import { FormattedMessage } from 'react-intl';

type Props = {
  classes: {
    [key: string]: string,
  },
  value: string,
  style: {
    [key: string]: mixed,
  },
};

export default function Stub({ classes, value, style }: Props) {
  return (
    <div className={classes.container} style={style}>
      <div className={classes.warpper}>
        <FormattedMessage id={`reconciliation.${value}`} defaultMessage={value}>
          {(t: string) => <div className={classes.stub}>{t}</div>}
        </FormattedMessage>
      </div>
    </div>
  );
}
