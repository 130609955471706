import { WS_GRID_PRESET_TYPES } from 'domain/documents/constants';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import { styled } from '@mui/material/styles';

export const GridPresetsMenuToggle = styled(Button, { shouldForwardProp: (propName) => propName !== 'presetType' })(
  ({ theme, presetType }) => ({
    width: '20ch',
    borderRadius: theme.shape.borderRadius,
    textTransform: 'none',
    justifyContent: 'space-between',
    fontWeight: 500,
    color: presetType === WS_GRID_PRESET_TYPES.shared ? theme.palette.warning.main : theme.palette.text.primary,
  }),
);
export const GridPresetsMenuItem = styled(MenuItem, { shouldForwardProp: (propName) => propName !== 'presetType' })(
  ({ theme, presetType }) => ({
    color: presetType === WS_GRID_PRESET_TYPES.shared ? theme.palette.warning.main : theme.palette.text.primary,
  }),
);
