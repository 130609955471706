import React from 'react';
import { Field, formValueSelector, reduxForm } from 'redux-form/immutable';
import { compose } from 'redux';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import Stack from '@mui/material/Stack';
import DomainAutocomplete from './components/DomainAutocomplete';

type TDomainForm = {
  handleSubmit: () => void,
};

const mapStateToProps = (state) => ({
  hintDomain: formValueSelector('domainForm')(state, 'hint_domain'),
});

const DomainForm: React$StatelessFunctionalComponent<TDomainForm> = ({ handleSubmit }) => {
  const { formatMessage } = useIntl();
  const { hintDomain } = useSelector(mapStateToProps);

  return (
    <Stack component="form" onSubmit={handleSubmit} spacing={3}>
      <Field
        component={DomainAutocomplete}
        label={formatMessage({ id: 'forms.organization.security.domain.label', defaultMessage: 'Organization domain' })}
        placeholder={hintDomain}
        name="domain"
        domainOptions={[hintDomain]}
        handleSubmit={handleSubmit}
      />
    </Stack>
  );
};

export default compose(reduxForm({ form: 'domainForm' }))(DomainForm);
