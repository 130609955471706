// @flow
import { useMemo } from 'react';
import { range } from 'lodash';
import { type TSelectOption } from 'pages/common/input/select';

type TUseNotificationsFormProps = {|
  formatMessage: ({ id: string, defaultMessage: string }) => string,
|};

const useNotificationsForm = ({ formatMessage }: TUseNotificationsFormProps) => {
  const notifyOptions = useMemo(
    (): $ReadOnlyArray<TSelectOption> => [
      {
        label: formatMessage({
          id: 'options.notify.never',
          defaultMessage: 'Never',
        }),
        value: 'never',
      },
      {
        label: formatMessage({
          id: 'options.notify.instantly',
          defaultMessage: 'Instantly',
        }),
        value: 'instantly',
      },
      {
        label: formatMessage({
          id: 'options.notify.perHour',
          defaultMessage: 'Once an hour',
        }),
        value: 'per_hour',
      },
      {
        label: formatMessage({
          id: 'options.notify.perDay',
          defaultMessage: 'Once a day',
        }),
        value: 'per_day',
      },
    ],
    [formatMessage],
  );

  const hoursOptions = useMemo((): $ReadOnlyArray<TSelectOption> => {
    const arr = range(0, 24, 1);

    return arr.reduce((acc, v, index) => {
      acc[index] = { value: v, label: v.toString().length < 2 ? `0${v}` : v.toString() };
      return acc;
    }, []);
  }, []);

  const minutesOptions = useMemo(
    (): $ReadOnlyArray<TSelectOption> => [
      {
        label: '00',
        value: 0,
      },
      {
        label: '15',
        value: 15,
      },
      {
        label: '30',
        value: 30,
      },
      {
        label: '45',
        value: 45,
      },
    ],
    [],
  );

  const days = formatMessage({
    id: 'labels.profile.notifications.days',
    defaultMessage: 'days',
  });

  const daysOptions = useMemo(
    (): $ReadOnlyArray<TSelectOption> => [
      {
        label: `1 ${days}`,
        value: 1,
      },
      {
        label: `2 ${days}`,
        value: 2,
      },
      {
        label: `3 ${days}`,
        value: 3,
      },
      {
        label: `7 ${days}`,
        value: 7,
      },
    ],
    [days],
  );

  return {
    notifyOptions,
    hoursOptions,
    minutesOptions,
    daysOptions,
  };
};

export default useNotificationsForm;
