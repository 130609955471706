export const PROPS = {
  availableTranslationList: 'availableTranslationList',
  workspaceScrollPosition: 'workspaceScrollPosition',
  cognitoTokenLastRefreshAt: 'cognitoTokenLastRefreshAt',
  shouldSaveReminder: 'shouldSaveReminder',
  isAllCompaniesLoaded: 'isAllCompaniesLoaded',
};

const st = window.sessionStorage;

function getItem<T: string>(key: string): ?T {
  return st.getItem(key);
}

function setItem<T: string>(key: T, value: string | number): boolean {
  st.setItem(key, value);
  return getItem(key) === value.toString();
}

function removeItem<T: string>(key: T): boolean {
  st.removeItem(key);
  return getItem(key) === null;
}

type StorageType<K: string, V: string> = {
  [key: K]: () => {
    get: () => ?V,
    set: (n: K) => boolean,
    remove: () => boolean,
  },
};

// eslint-disable-next-line max-len
type PropsType<T: string> = {
  +[key: T]: string,
};

export function sessionST<T: string, V: string>(p: PropsType<T> = PROPS): StorageType<T, V> {
  const fields: Array<T> = Object.keys(p);
  const methods = (name) => ({
    get: () => getItem(name),
    set: (v) => setItem(name, v),
    remove: () => removeItem(name),
  });
  return fields.reduce((a, v) => Object.assign(a, { [v]: () => methods(PROPS[v]) }), {
    [fields[0]]: () => methods(PROPS[fields[0]]),
  });
}
