// @flow
import React, { memo, useCallback } from 'react';

import { useStampManagement } from 'pages/document/page/components/ApprovalStampManagement/useStampManagement';
import type { TApprovalStampManagement } from 'pages/document/page/components/ApprovalStampManagement/types.js.flow';

import IconApprovalStampBlank from 'components/Icons/approval-stamp-blank';

import cx from 'classnames';
import useStyles from './sheet';

const ApprovalStampManagement: React$StatelessFunctionalComponent<TApprovalStampManagement> = (
  props: TApprovalStampManagement,
) => {
  const classes = useStyles();

  const { setStampRef, handleMouseUp, handleMouseDown, handleMouseMove, coordinates, stampDimensions, stampMode } =
    useStampManagement(props);

  const { height: stampH, width: stampW } = stampDimensions;

  const onClick = useCallback((e: MouseEvent) => {
    e.stopPropagation();
  }, []);

  return (
    <div className={classes.layout} onMouseMove={handleMouseMove} onMouseLeave={handleMouseUp}>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
      <div
        role="button"
        tabIndex={0}
        onClick={onClick}
        ref={setStampRef}
        className={cx(classes.draggable, { [stampMode]: stampMode })}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        style={{
          transform: `translate(${coordinates.x}px, ${coordinates.y}px)`,
        }}
      >
        <IconApprovalStampBlank height={stampH} width={stampW} />
      </div>
    </div>
  );
};

export default memo(ApprovalStampManagement, (props, prevProps) =>
  // after start moving stamp we dispatch stamp mode to store and after this component rerender, but current dimensions of page are not changed
  // so, we stop excess rerender
  // I think, when react compare props {height: 10, width: 10} are not equal {height: 10, width: 10} for example
  // {} === {} -> false

  props.currentDimensions.equals(prevProps.currentDimensions),
);
