// @flow
import React from 'react';

import Checkbox, { CheckboxProps } from '@mui/material/Checkbox';

type TCheckboxBaseMui = {} & CheckboxProps;

const CheckboxBase: React$StatelessFunctionalComponent<TCheckboxBaseMui> = (props) => <Checkbox {...props} />;

export default CheckboxBase;
