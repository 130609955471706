// @flow
import React, { useCallback, useMemo } from 'react';
import { List } from 'immutable';
import { useSelector, useDispatch } from 'react-redux';
import {
  chatThreadSelector,
  sendMessageAction,
  getPreviousMessageAction,
  messageQuerySelector,
  isChatConnectedSelector,
} from 'domain/chat';
import { userIdSelector } from 'domain/env';
import { GroupMessages } from 'domain/chat/helpers';
import ChatTextField from 'components/mui/Layouts/components/Chat/components/MessagesThread/ChatTextField';
import ChatMessagesList from 'components/mui/Layouts/components/Chat/components/MessagesThread/ChatMessagesList';
import Box from '@mui/material/Box';

type TMessagesThread = {
  threadId: ?string,
  params: {
    companyId: string,
    documentId?: string,
  },
};

const mapStateToProps = (state) => ({
  threads: chatThreadSelector(state),
  userID: userIdSelector(state),
  messageQuery: messageQuerySelector(state),
  isConnected: isChatConnectedSelector(state),
});

const MessagesThread: React$StatelessFunctionalComponent<TMessagesThread> = ({ threadId, params }) => {
  const dispatch = useDispatch();
  const { threads, userID, messageQuery, isConnected } = useSelector(mapStateToProps);
  const mq = threadId ? messageQuery.get(threadId) : null;

  const thread = useMemo(() => {
    if (threadId && mq) {
      const currentThread = threads.get(threadId);

      if (currentThread) {
        return GroupMessages(currentThread.messages.toList());
      }

      if (mq.get('isLoading') === false) {
        return new List();
      }
    }

    return null;
  }, [threadId, mq, threads]);

  const handleSubmitMessage = useCallback(
    (message: string) => {
      dispatch(sendMessageAction({ message, threadId, params }));
    },
    [dispatch, threadId, params],
  );

  const handlePrevious = useCallback(() => {
    if (messageQuery && messageQuery.hasMore && !messageQuery.isLoading) {
      dispatch(getPreviousMessageAction(threadId));
    }
  }, [dispatch, messageQuery, threadId]);

  return (
    <Box display="flex" flexDirection="column" flex="1 1 100%" position="relative" minHeight="0">
      <ChatMessagesList list={thread} ownerId={userID} onPrev={handlePrevious} />
      <ChatTextField threadID={threadId} handleSubmitMessage={handleSubmitMessage} iDisabled={!isConnected} />
    </Box>
  );
};

export default MessagesThread;
