// @flow
import React, { useMemo, useCallback, useEffect } from 'react';
import { useIntl } from 'react-intl';
import useActive from '../useActive';
import {
  NOTE_PALETTE,
  NOTE_PALETTE_DEFAULT_KEY,
  type TNotePaletteKeys,
} from 'components/LabeledThemeProvider/notesPalette';

import useStickyNotes from 'pages/common/Dialog/DialogNotes/useStickyNotes';

import Widget from '../components/Widget';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';
import { NoteField, NotePaletteBar, NotePaletteRadio } from 'pages/document/components/Widgets/Note/StyledComponents';

import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import CircleIcon from '@mui/icons-material/Circle';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

type Props = {|
  content: string,
  noteColorKey: TNotePaletteKeys,
  onMouseDown: () => void,
  manualIsActive?: boolean,
  setManualIsActive?: (((boolean) => boolean) | boolean) => void,
  isReverse?: boolean,
  style: {
    zIndex: number,
  },
|};

const Note = ({
  content,
  noteColorKey,
  onMouseDown,
  manualIsActive,
  setManualIsActive,
  isReverse = false,
  style,
}: Props) => {
  const { formatMessage } = useIntl();

  const { isActive, createToggleHandler, setIsActive } = useActive(manualIsActive, setManualIsActive);

  const onClose = useCallback(() => setIsActive(false), [setIsActive]);

  const {
    value,
    setValue,
    colorKey,
    setColorKey,
    defaultValidNotePaletteKey,
    loading,
    handleChange,
    handleChangeRadio,
    onNotesUpdate,
    onNotesRemove,
    disableUpdate,
  } = useStickyNotes({ onClose, content, noteColorKey });

  const button = useCallback(
    (handler: () => void) => (
      <button
        type="button"
        onClick={createToggleHandler((...args) => {
          // approach from Artem G :)
          setColorKey(defaultValidNotePaletteKey);
          // eslint-disable-next-line no-unused-expressions
          handler?.(...args);
        })}
      >
        <Typography variant="subtitle2">
          {formatMessage({ id: 'document.show.stickyNote', defaultMessage: 'Note' })}
        </Typography>
      </button>
    ),
    [createToggleHandler, formatMessage, defaultValidNotePaletteKey, setColorKey],
  );

  const editableContent = useMemo(
    () => (
      <NoteField
        value={value}
        onChange={handleChange}
        placeholder={formatMessage({
          id: 'notes.placeholder',
          defaultMessage: 'Write note text',
        })}
      />
    ),
    [value, formatMessage, handleChange],
  );

  const footer = useMemo(
    () => (
      <Stack direction="row" justifyContent="space-between" mt={1}>
        <Stack spacing={1} direction="row">
          <NotePaletteBar
            onChange={handleChangeRadio}
            value={colorKey}
            defaultChecked={NOTE_PALETTE_DEFAULT_KEY}
            name="color"
            sx={{ position: 'static' }}
          >
            {Object.entries(NOTE_PALETTE).map(([key, { color }]) => (
              <NotePaletteRadio
                value={key}
                key={key}
                icon={<CircleIcon sx={{ color }} />}
                checkedIcon={<CheckCircleIcon />}
                size="small"
              />
            ))}
          </NotePaletteBar>
          <Divider flexItem orientation="vertical" sx={{ height: 24, alignSelf: 'center' }} />
          <IconButton aria-label="delete" color="error" onClick={onNotesRemove} disabled={!content || loading}>
            <DeleteOutlineOutlinedIcon />
          </IconButton>
        </Stack>
        <Button variant="text" onClick={onNotesUpdate} disabled={disableUpdate}>
          {formatMessage({
            id: 'button.save',
            defaultMessage: 'Save',
          })}
        </Button>
      </Stack>
    ),
    [onNotesRemove, onNotesUpdate, disableUpdate, content, loading, formatMessage, colorKey, handleChangeRadio],
  );

  useEffect(() => {
    if (!isActive) {
      setValue(content);
    } else {
      setValue(content);
    }
  }, [content, isActive, setValue]);

  return (
    <Widget
      style={style}
      isReverse={isReverse}
      setIsActive={setIsActive}
      button={button}
      isActive={isActive}
      loading={loading}
      content={editableContent}
      footer={footer}
      onMouseDown={onMouseDown}
      borderColor={NOTE_PALETTE[colorKey].border}
      bgColor={NOTE_PALETTE[colorKey].color}
    />
  );
};

export default Note;
