/* @flow */
import { Record, type RecordOf } from 'immutable';
import * as A from './actions';
import { type TNotifications } from 'domain/contracts';
import { NotificationsFactory } from './adapters';

type TNotificationsState = {|
  isLoading: boolean,
  data: RecordOf<TNotifications>,
|};

const initialState: RecordOf<TNotificationsState> = new Record({
  isLoading: false,
  data: NotificationsFactory(),
});

export const reducer = {
  notifications(state = initialState(), action: any) {
    switch (action.type) {
      case A.getNotificationsAction.request:
        return state.setIn(['isLoading'], true);

      case A.getNotificationsAction.success:
        return state.setIn(['data'], action.payload).setIn(['isLoading'], false);
      case A.getNotificationsAction.failure:
        return state.setIn(['isLoading'], false);

      // UPDATE
      case A.updateNotificationsAction.success:
        return state.setIn(['data'], action.payload);

      default:
        return state;
    }
  },
};
