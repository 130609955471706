// @flow
import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { chatAllUsersByIdSelector } from 'domain/chat/chatSelector';
import type { RecordOf } from 'immutable';
import type { ChatUser } from 'domain/chat/types';
import type { RawApproval } from 'domain/approvals/types.js.flow';

import Approver from 'pages/components/Approver';
import Avatar from '@mui/material/Avatar';
import Rest from '../RestCount';
import Stack from '@mui/material/Stack';
import { Map } from 'immutable';
import isEmpty from 'lodash/isEmpty';
import Box from '@mui/material/Box';
import { SYMBOL_FOR_EMPTY_CELL } from 'components/AgGrid/hooks/columnTypes/useColumnTypes';

const [SIZE, SPACING] = ['small', 0.25];

const ApprovalFactoryCreator =
  (usersById: Map<string, RecordOf<ChatUser>>) =>
  ({ username = '', status = '', userid }: RawApproval) => {
    const user = usersById.get(username);
    const userName = user ? user.get('username') : username;
    const src = user ? user.get('picture') : null;

    return { userName, src, status, userid };
  };

const Wrapper = React.forwardRef((props, ref) => <Stack direction="row" flexWrap="nowrap" {...props} ref={ref} />);

interface IProps {
  value?: RawApproval[];
  valueFormatted: RawApproval[] | string;
  usersById: Map<string, RecordOf<ChatUser>>;
}

interface IState {
  hiddenItems: string[];
}

class ApprovalsCellRenderer extends React.Component<IProps, IState> {
  elements: { [key: string]: any } = {};

  observers: { [key: string]: IntersectionObserver } = {};

  boxEl: Element = null;

  constructor(props: IProps) {
    super(props);

    this.state = {
      hiddenItems: [],
    };
  }

  componentDidMount() {
    setTimeout(this.initObservers, 0);
  }

  componentWillUnmount() {
    Object.values(this.observers).forEach((o) => {
      o.disconnect();
    });
  }

  getVisibleKeys = () => (this.sortedApprovalList || []).filter((node) => !this.isHiddenKey(node.userid));

  getApprovalsStat = () => {
    const approvals = this.sortedApprovalList || [];
    const visibleNodes = this.getVisibleKeys();
    const count = approvals.length;
    const countVisible = visibleNodes.length;
    const restCount = count - countVisible;
    const lastKey = count ? approvals[count - 1].userid : null;
    const lastVisibleKey = countVisible && countVisible !== count ? visibleNodes[countVisible - 1].userid : null;

    return { count, countVisible, lastVisibleKey, restCount, lastKey };
  };

  get sortedApprovalList() {
    const { usersById, value } = this.props;

    const ApprovalFactory = ApprovalFactoryCreator(usersById);

    return value ? value.map(ApprovalFactory) : value;
  }

  setItemRef = (key) => (ref) => {
    this.elements[key] = ref;
  };

  setBoxEl = (el: Element) => {
    this.boxEl = el;
  };

  initObservers = () => {
    const observerOptions = { root: this.boxEl, rootMargin: '0px', threshold: 1 };
    Object.entries(this.elements).forEach(([key, el]) => {
      const observer = new IntersectionObserver(this.handleObserver(key), observerOptions);
      observer.observe(el);
      this.observers[key] = observer;
    });
  };

  isHiddenKey = (key) => {
    const { hiddenItems } = this.state;

    return hiddenItems.includes(key);
  };

  showItem = (key: string) => {
    const { hiddenItems } = this.state;

    if (this.isHiddenKey(key)) {
      this.setState({ hiddenItems: hiddenItems.filter((item) => item !== key) });
    }
  };

  hideItem = (key: string) => {
    const { hiddenItems } = this.state;

    if (!this.isHiddenKey(key)) {
      this.setState({ hiddenItems: [...hiddenItems, key] });
    }
  };

  handleObserver =
    (key: string) =>
    ([intersectionObject]) => {
      // when scrolling inside ag-grid we are getting false observer handler invocation
      // with root element (grid cell) having all dimentions from getBoundingCLientRekt equal to 0
      // though cell element is present in DOM and has proper sizing and positioning
      if (intersectionObject.rootBounds.width === 0 && intersectionObject.rootBounds.height === 0) return;
      const action = intersectionObject.intersectionRatio === 1 ? this.showItem : this.hideItem;
      action(key);
    };

  render() {
    const { getApprovalsStat, sortedApprovalList } = this;
    const { valueFormatted } = this.props;
    const { lastVisibleKey, countVisible, restCount, lastKey } = getApprovalsStat();

    return sortedApprovalList ? (
      <Wrapper spacing={SPACING} maxWidth="100%" ref={this.setBoxEl} sx={{ px: 0.5 }}>
        {!countVisible && <Rest size={SIZE} label={restCount} />}
        {sortedApprovalList.map(({ userName, src, userid, status }) => (
          <Wrapper key={userid} ref={this.setItemRef(userid)} spacing={SPACING}>
            <Approver
              size={SIZE}
              spacing={SPACING}
              sx={{ opacity: this.isHiddenKey(userid) ? 0 : 1 }}
              avatar={<Avatar src={src} alt={userName} />}
              status={status}
              label={userName}
              hasArrow={lastKey !== userid}
            />
            {lastVisibleKey === userid && <Rest size={SIZE} label={restCount} />}
          </Wrapper>
        ))}
      </Wrapper>
    ) : (
      <Box sx={{ px: 2 }}>{isEmpty(valueFormatted) ? SYMBOL_FOR_EMPTY_CELL : valueFormatted}</Box>
    );
  }
}

const mapStateToProps = (state) => ({
  usersById: chatAllUsersByIdSelector(state),
});

export default compose(connect(mapStateToProps))(ApprovalsCellRenderer);
