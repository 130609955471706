// @flow
import React, { useCallback, useEffect, useRef } from 'react';
import { FixedSizeList } from 'react-window';
import Option, { type OptionData } from './Option';

export type OptionsProps = {|
  options: OptionData[],
  preselectIndex: number,
  activeIndex: number,
  onPreselect: (i: number) => void,
  onSelect: (i: number) => void,
  maxVisibleOptionsCount?: number,
  optionSize?: number,
  optionWidth?: number,
  searchTerm?: string,
  onVirtualListRender: (from: number, to: number) => void,
|};

export const defaultOptionIntl = { id: 'options.default.resetToDefault', defaultMessage: 'Reset to default' };

const VirtualOptions = ({
  options,
  preselectIndex,
  activeIndex,
  onPreselect,
  onSelect,
  searchTerm = '',
  optionSize = 36,
  optionWidth = 200,
  maxVisibleOptionsCount = 10,
  onVirtualListRender = (x) => x,
}: OptionsProps) => {
  const mousePositionY = useRef(null);
  const virtualListRef = useRef(null);

  const itemCount = options.length;
  const height = Math.min(itemCount * optionSize, maxVisibleOptionsCount * optionSize);

  useEffect(() => {
    setTimeout(() => {
      if (virtualListRef.current) {
        virtualListRef.current.scrollToItem(activeIndex || 0, 'smart');
      }
    }, 0);
  }, [activeIndex]);

  useEffect(() => {
    setTimeout(() => {
      if (virtualListRef.current) {
        virtualListRef.current.scrollToItem(preselectIndex || 0, 'smart');
      }
    }, 0);
  }, [preselectIndex]);

  const onListRender = (params) => {
    const { visibleStartIndex, visibleStopIndex } = params;
    onVirtualListRender(visibleStartIndex, visibleStopIndex);
  };

  const preselectHandler = useCallback(
    (index: number, mouseY: number) => {
      // if mouse doesn't change vertical position it mean
      // that current call is side effect another action and not be apply in this place
      if (mousePositionY.current !== mouseY && preselectIndex !== index) {
        mousePositionY.current = mouseY;
        onPreselect(index);
      }
    },
    [onPreselect, preselectIndex],
  );

  const itemRender = ({ data, index, style }: { data: any, index: number, style: any }) => {
    const itemData = data[index];
    return (
      <Option
        style={style}
        height={optionSize}
        data={itemData}
        index={index}
        isActive={activeIndex === index}
        isPreselect={preselectIndex === index}
        onClick={onSelect}
        onPreselect={preselectHandler}
        searchTerm={searchTerm}
      />
    );
  };

  return (
    <FixedSizeList
      className="list"
      height={height}
      itemCount={itemCount}
      itemSize={optionSize}
      width={optionWidth}
      itemData={options}
      onItemsRendered={onListRender}
      ref={virtualListRef}
    >
      {itemRender}
    </FixedSizeList>
  );
};

export default VirtualOptions;
