// assets
import remove from './images/remove.svg';

export default (theme) => ({
  gridFiltersContainer: {
    position: 'relative',
    fontSize: 12,
  },

  item: {
    border: `1px solid ${theme.colors.greyLight}`,
    borderRadius: 4,
    height: 30,
    background: '#DEE2E6',
    color: '#171A1C',
    padding: [0, 10, 0, 10],
    lineHeight: '26px',
    fontSize: 12,
    margin: [1, 2],
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    opacity: 1,
    transition: 'opacity 0.3s ease',
    maxWidth: '68vw', //imperative value
    '&>span': {
      fontSize: 12,
      lineHeight: '14px',
      paddingRight: 5,
    },
    '&>button': {
      width: 9,
      minWidth: 9,
      height: 9,
      backgroundSize: '100% 100%',
      background: `url("${remove}") 0 0 no-repeat`,
      border: 0,
      cursor: 'pointer',
      marginLeft: 7,
    },
  },

  itemHide: {
    opacity: 0.2,
    transition: 'opacity 0.3s ease',
  },

  colName: {
    whiteSpace: 'nowrap',
    fontWeight: 600,
    marginRight: 3,
  },

  filterText: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
});
