/* @flow */

export class Rect {
  constructor(ctx: CanvasRenderingContext2D, color: string, x: number, y: number, width?: number, height?: number) {
    this.ctx = ctx;
    this.color = color;
    this._x = x;
    this._y = y;
    this._width = width || x + 1;
    this._height = height || y + 1;
  }

  ctx: CanvasRenderingContext2D;
  color: string;
  _x: number;
  _y: number;
  _width: number;
  _height: number;

  get x(): number {
    if (this._width < 0) return this._x + this._width;
    return this._x;
  }

  get y(): number {
    if (this._height < 0) return this._y + this._height;
    return this._y;
  }

  get width(): number {
    return Math.abs(this._width);
  }

  get height(): number {
    return Math.abs(this._height);
  }

  draw = () => {
    this.ctx.strokeStyle = this.color;
    this.ctx.strokeRect(this._x, this._y, this._width, this._height);
  };

  update(x: number, y: number) {
    this._width = x - this._x;
    this._height = y - this._y;
  }
}
