// @flow
import * as React from 'react';
import { Set, List } from 'immutable';
import { compose, type Dispatch } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { withStyles } from '@mui/styles';
import { makeChannelName } from 'domain/chat/helpers';
import {
  chatUsersWithoutSuppliers,
  chatThreadSelector,
  chatByCompanySelector,
  isChatConnectedSelector,
  setActiveChannelForceAction,
  setOpenAction,
  chatParticipantsByIdSelector,
} from 'domain/chat';
import { createMemoFn } from 'lib/propHelpers';

import { currentCompanyUnreadRequestsSelector } from 'domain/companies';

import type { ChatRecord, ChatUser } from 'domain/chat/types.js.flow';
import type { UnreadRequeestDocs, UnreadRequestDocRecord } from 'domain/documents/types.js.flow';
import DocumentItem from './documents';
import PrivatItem from './privat';
import UnreadRequests from './unreadRequests';
import sheet from './sheet';

import { showReconciliationRequestDetailsAction } from 'domain/reconciliation/actions';
import { relevantSearch } from 'lib/helpers';
import type { Map } from 'immutable';

type Props = {|
  classes: {
    [key: string]: string,
  },
  currentUserID: string,
  onChat: (u: ChatUser) => void,
  users: List<ChatUser>,
  threads: $PropertyType<ChatRecord, 'threads'>,
  documents: List<*>,
  companyId: string,
  isConnected: boolean,
  setActiveChannel: Dispatch<typeof setActiveChannelForceAction>,
  setOpenChat: Dispatch<typeof setOpenAction>,
  showReconciliationRequestDetails: Dispatch<typeof showReconciliationRequestDetails>,
  unreadRequests: UnreadRequeestDocs,
  searchText?: string,
  chatParticipants: Map<string, ChatUser>,
|};

function messageToSortedList(messages: Set<*>): List<*> {
  return messages
    .toList()
    .sortBy((m) => m.createdAt)
    .last();
}

class Messages extends React.Component<Props> {
  // @deprecated
  // used to open transaction request popup when user clicks on unread request
  // deprecated due to decision to only open document and not to open request
  // popup
  onSelectUnreadRequestDoc = (doc: UnreadRequestDocRecord) => () => {
    const { showReconciliationRequestDetails } = this.props;
    const line = doc.get('lines').first();
    showReconciliationRequestDetails({ ...line.toJS(), status: 'unread' });
  };

  get documents() {
    const { documents, chatParticipants } = this.props;
    return documents
      .filter((f) => f.has('documentId'))
      .filter((doc) => {
        const thread = messageToSortedList(doc.messages);
        const user = chatParticipants.get(thread.sender.userId);
        const userName = user ? user.username : thread.sender.nickname;
        return this.compareWithSearchText(userName);
      });
  }

  get unreadRequests() {
    const { unreadRequests } = this.props;
    return unreadRequests.slice(0, 5);
  }

  getCounter = (userId: string): number => {
    const { threads } = this.props;
    return threads.getIn([this.getChannelName(userId), 'unreadMessageCount'], 0);
  };

  getMessage = (userId: string) => {
    const { threads } = this.props;
    return messageToSortedList(threads.getIn([this.getChannelName(userId), 'messages'], Set()));
  };

  getChannelName(userId: string) {
    const { companyId, currentUserID } = this.props;
    return makeChannelName([currentUserID, userId], companyId);
  }

  getUsers = createMemoFn(
    () => {
      const { users, searchText } = this.props;
      return { users, searchText };
    },
    ({ users, searchText }) => (searchText ? relevantSearch(searchText, users, (user) => user.username) : users),
  );

  compareWithSearchText = (testText: string) => {
    const { searchText } = this.props;
    if (!searchText) return true;
    if (!searchText) return false;
    return testText.toLowerCase().includes(searchText.toLowerCase());
  };

  closeChat = () => {
    const { setOpenChat } = this.props;
    setOpenChat(false);
  };

  setActiveChannel = (params) => {
    const { setActiveChannel } = this.props;
    setActiveChannel(params);
    this.closeChat();
  };

  render() {
    const { classes, isConnected, companyId, searchText, onChat } = this.props;
    const { getMessage, getCounter } = this;
    return (
      <div className={classes.container}>
        {this.documents.size > 0 || this.unreadRequests.size > 0 ? (
          <h2 className={classes.title}>
            <FormattedMessage id="chat.title.documentRequests" defaultMessage="Document Requests" />
          </h2>
        ) : null}
        <ul className={classes.documentRequests}>
          {this.unreadRequests.map((req) => (
            <li key={req} className={classes.doc}>
              <UnreadRequests classes={classes} unreadRequestDoc={req} onSelect={this.closeChat} />
            </li>
          ))}

          {this.documents.map((e) => (
            <li key={e} className={classes.doc}>
              <DocumentItem
                // $FlowFixMe: realy present
                thread={messageToSortedList(e.messages)}
                searchText={searchText}
                classes={classes}
                name={e.name}
                setActiveChannel={this.setActiveChannel}
                companyId={companyId}
              />
            </li>
          ))}
        </ul>
        <h2 className={classes.title}>
          <FormattedMessage id="chat.title.messages" defaultMessage="Messages" />
        </h2>
        <ul className={classes.users}>
          {this.getUsers().map((e) => (
            <li key={e.userId}>
              <button disabled={!isConnected} onClick={() => onChat(e)} className={classes.btn} type="button">
                <PrivatItem
                  data={e}
                  classes={classes}
                  thread={getMessage(e.userId)}
                  counter={getCounter(e.userId)}
                  searchText={searchText}
                />
              </button>
            </li>
          ))}
        </ul>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  users: chatUsersWithoutSuppliers(state),
  threads: chatThreadSelector(state),
  documents: chatByCompanySelector(state),
  isConnected: isChatConnectedSelector(state),
  unreadRequests: currentCompanyUnreadRequestsSelector(state),
  chatParticipants: chatParticipantsByIdSelector(state),
});

const mapDispatchToProps = {
  setActiveChannel: setActiveChannelForceAction,
  setOpenChat: setOpenAction,
  showReconciliationRequestDetails: showReconciliationRequestDetailsAction,
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(sheet))(Messages);
