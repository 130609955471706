// @flow
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { isStatementSelector } from 'domain/journal/selectors';
import { localeSelector } from 'domain/env';
import { useApiToken } from 'lib/apiTokenKeeper';
import { currentCompanySelector, documentDocumentIdSelector } from 'domain/documents';
import download from 'lib/download';

import Tooltip from 'components/mui/Tooltip';
import IconButton from '@mui/material/IconButton';

import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import Api from 'domain/api';

const mapStateToProps = (state) => ({
  isStatement: isStatementSelector(state),
  locale: localeSelector(state),
  companyId: currentCompanySelector(state),
  documentID: documentDocumentIdSelector(state),
});
const IconButtonExport = () => {
  const { isStatement, locale, companyId, documentID } = useSelector(mapStateToProps);
  const dokkaToken = useApiToken();

  const handleClick = useCallback(() => {
    download(Api.getDocumentCsv({ dokkaToken, documentID, companyId, language: locale }), documentID);
  }, [dokkaToken, documentID, companyId, locale]);

  return (
    isStatement && (
      <Tooltip t={{ id: 'header.menu.export', defaultMessage: 'Export to Excel' }}>
        <IconButton color="primary" onClick={handleClick}>
          <CloudDownloadOutlinedIcon />
        </IconButton>
      </Tooltip>
    )
  );
};

export default IconButtonExport;
