// @flow
import { Record } from 'immutable';

export const TEXTRACT_MATCH_STATUSES = {
  MATCH: 'match',
  PARTIAL_MATCH: 'partial_match',
  HEADER: 'header',
  DEFAULT: 'default',
};

export const MatchingColorsMap = {
  [TEXTRACT_MATCH_STATUSES.MATCH]: {
    border: 'rgba(19, 157, 41, 1)',
    fill: 'rgba(19, 157, 41, 0.12)',
  },
  [TEXTRACT_MATCH_STATUSES.PARTIAL_MATCH]: {
    border: 'rgba(211, 47, 47, 1)',
    fill: 'rgba(211, 47, 47, 0.12)',
  },
  [TEXTRACT_MATCH_STATUSES.DEFAULT]: {
    border: 'rgba(211, 47, 47, 1)',
    fill: 'rgba(211, 47, 47, 0.12)',
  },
  [TEXTRACT_MATCH_STATUSES.HEADER]: {
    border: 'rgba(204, 204, 204, 1)',
    fill: 'rgba(204, 204, 204, 0.12)',
  },
};

export const MatchinArrowsAnchors = {
  documentElementIdFactory: (id: string) => `element-${id}`,
  textractVisibleAnchorElementIdFactory: (id: string) => `element-${id}-child`,
  journalEntryElementIdFactory: (line: string) => `matcher-${line}`,
};

export const ArrowTargetFactory = (targetId: string, color: string, rtl: boolean) => {
  const [targetAnchor, sourceAnchor] = rtl ? ['left', 'right'] : ['right', 'left'];

  const ArrowStyleRecord = new Record({
    strokeColor: 'transparent',
    strokeWidth: 1,
  });

  const ArrowTargetRecord = new Record({
    targetId: null,
    targetAnchor: 'right',
    sourceAnchor: 'left',
    style: ArrowStyleRecord(),
  });

  return ArrowTargetRecord({
    targetId,
    targetAnchor,
    sourceAnchor,
    style: ArrowStyleRecord({ strokeColor: color }),
  });
};

export function convertPointsByRotation({ points, width, height, rotation }) {
  return points.map((point) => {
    switch (rotation) {
      case 90:
        return {
          X: (width - point.Y * width) / width,
          Y: point.X,
        };
      case 180:
        return {
          X: (width - point.X * width) / width,
          Y: (height - point.Y * height) / height,
        };
      case 270:
        return {
          X: point.Y,
          Y: (height - point.X * height) / height,
        };
      default:
        return {
          X: point.X,
          Y: point.Y,
        };
    }
  });
}

function getCenterOfShape(points) {
  let x = 0;
  let y = 0;

  const count = points.length;

  points.forEach((p) => {
    x += p.X;
    y += p.Y;
  });

  return { X: x / count, Y: y / count };
}

/**
 * Find angle beetwen two points centerPoint and point
 * @param {integer} c - centered point of polygon
 * @param {object} point
 * @returns {number}
 */
function getAngle(c, point) {
  const dx = point.X - c.X;
  const dy = point.Y - c.Y;

  return Math.atan2(dy, dx);
}

export function sortPointsByClockwise(points) {
  const cp = getCenterOfShape(points);
  const arr = [...points]; // sort mutate data

  return arr.sort((pointA, pointB) => getAngle(cp, pointA) - getAngle(cp, pointB));
}

/**
 * Find centered point of polygon
 * @param points
 * @returns {{x: number, y: number}}
 */

export const getOppositeCoordinates = (points) => {
  const coordinatesX = points.map((p) => p.X);
  const coordinatesY = points.map((p) => p.Y);

  const top = Math.min(...coordinatesY);
  const left = Math.min(...coordinatesX);
  const bottom = Math.max(...coordinatesY);
  const right = Math.max(...coordinatesX);
  const topLeft = {
    X: left,
    Y: top,
  };
  const topRight = {
    X: right,
    Y: top,
  };
  const bottomRight = {
    X: right,
    Y: bottom,
  };

  const bottomLeft = {
    X: left,
    Y: bottom,
  };

  return [topLeft, bottomRight, topRight, bottomLeft];
};

export type TTextractMatchStatuses = $Values<typeof TEXTRACT_MATCH_STATUSES>;

export type TMatchingColorsMap = {|
  [key: TTextractMatchStatuses]: {|
    border: string,
    fill: string,
  |},
|};
