/* @flow */
import React from 'react';
import { FormattedMessage } from 'react-intl';

import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';

import AddIcon from '@mui/icons-material/Add';

type TOptionAddNew = {|
  onClick: () => void,
  style?: {
    [key: string]: string,
  },
|};

const OptionAddNew: React$StatelessFunctionalComponent<TOptionAddNew> = ({ onClick, style = {} }) => (
  <ListItemButton dense onClick={onClick} style={style}>
    <ListItemIcon sx={(theme) => ({ minWidth: 'auto', [theme.direction === 'rtl' ? 'pl' : 'pr']: 1 })}>
      <AddIcon />
    </ListItemIcon>
    <ListItemText sx={{ display: 'flex' }}>
      <FormattedMessage id="components.options.addNew" defaultMessage="Add New" />
      &nbsp;
      <Typography variant="hint" color="grey.600">
        <FormattedMessage id="components.options.pressEnter" defaultMessage="(press Enter)" />
      </Typography>
    </ListItemText>
  </ListItemButton>
);

export default OptionAddNew;
