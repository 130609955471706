// @flow
import { useMemo } from 'react';
import { useIntl } from 'react-intl';

const useMuiConfirmTheme = () => {
  const { formatMessage } = useIntl();

  return useMemo(
    () => ({
      title: formatMessage({
        id: 'confirm.default.title',
        defaultMessage: 'Confirmation',
      }),
      description: formatMessage({
        id: 'confirm.default.description',
        defaultMessage: 'Are you sure?',
      }),
      confirmationText: formatMessage({
        id: 'button.confirm',
        defaultMessage: 'Confirm',
      }),
      cancellationText: formatMessage({
        id: 'button.cancel',
        defaultMessage: 'Cancel',
      }),
      contentProps: {
        dividers: true,
        sx: {
          py: 5,
          textAlign: 'center',
          color: 'text.primary',

          '& .MuiDialogContentText-root': {
            color: 'text.primary',
          },
        },
      },
      confirmationButtonProps: {
        color: 'primary',
        variant: 'contained',
      },
      cancellationButtonProps: {
        color: 'primary',
        variant: 'text',
        name: 'confirmModalCancelBtn',
      },
      dialogProps: {
        name: 'confirmModal',
      },
    }),
    [formatMessage],
  );
};

export default useMuiConfirmTheme;
