// @flow

import { compose } from 'redux';

type Action<T> = {
  type: string,
  payload?: T,
};

type MakeAction<T> = (a: T) => Action<T>;

type OnSubmitType<T> = (data: T, dispatch: Function) => Action<T>;

type ActionCreatorType<T> = {
  type: string,
  onSubmit?: (fun: MakeAction<T>) => OnSubmitType<T>,
  $call: MakeAction<T>,
};

export const POSTFIX: { [key: string]: string } = {
  request: '/REQUEST',
  success: '/SUCCESS',
  failure: '/FAILURE',
};

type AsyncActionType<T> = {
  type: string,
  $call: MakeAction<T>,
  onSubmit?: (fun: MakeAction<T>) => OnSubmitType<T>,
  [key: string]: string,
};

function formOnSubmit<T>(fun: MakeAction<T>): OnSubmitType<T> {
  return (data: T, dispatch: Function): Action<T> => compose(dispatch, fun)(data);
}

function makeActionDefault<T>(payload: T) {
  return {
    payload,
  };
}

function makeActionCreator<T>(type: string, makeAction: (a: T) => {}): ActionCreatorType<T> {
  const actionCreator = (arg: T): Action<T> => ({ ...makeAction(arg), type });
  return Object.assign(actionCreator, { type });
}

function syncAction<T>(base: string, makeAction: (a: T) => {} = makeActionDefault): ActionCreatorType<T> {
  return makeActionCreator(base, makeAction);
}

type DefinePropertiesType = {
  value?: string | Function,
  configurable?: boolean,
  enumerable?: boolean,
  writable?: boolean,
  get?: Function,
  set?: Function,
};

type Postfix<T> = { [key: string]: T };

type Properties = {
  [key: string]: DefinePropertiesType,
};

function createProps(base: string, postfix: Postfix<string>) {
  return (a: Properties, v: string): Properties => {
    if (v in postfix) {
      return {
        ...a,
        [v]: {
          value: base + postfix[v],
          enumerable: true,
        },
      };
    }
    return a;
  };
}

function asyncAction<T>(base: string, makeAction: (a: T) => {} = makeActionDefault): AsyncActionType<T> {
  const actionCreator = makeActionCreator(base, makeAction);
  const creator = createProps(base, POSTFIX);
  const params = Object.keys(POSTFIX).reduce((a: Properties, v: string) => creator(a, v), {
    onSubmit: {
      value: formOnSubmit(actionCreator),
      enumerable: true,
    },
  });
  Object.defineProperties(actionCreator, params);
  return actionCreator;
}

export { syncAction, asyncAction };
