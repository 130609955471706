// @flow
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { currentCompanySelector } from 'domain/documents';
import DocumentPreview from 'pages/company/DocumentPreview';
import type { TDocument, TDocumentRecord } from 'domain/documents/types.js.flow';
import { getDocumentUrl, getPreviewSrc, getRotationAngle } from 'pages/company/workSpace/helpers';
import { useApiToken } from 'lib/apiTokenKeeper';
import { isDocPaid } from 'domain/documents/helpers';
import { debounce } from '@mui/material/utils';
import { List } from 'immutable';
import { getUiWorkspaceIsActiveContextMenuSelector } from 'domain/ui';

export type Props = {
  documentId: string,
  setPreview: (d: TDocumentRecord, c: string) => void,
  list: List<TDocument>,
  allCount: number,
  onContextMenu?: (e: MouseEvent, d: TDocumentRecord) => void,
  onClick?: () => void,
  // onLoadNext - used for infinite loading in (workspace tile view, linked panel)
  onLoadNext?: () => void,
  // startIndex - actually used for calculate correct ordinalNumber(paginated ag-grid)
  // page * perPage = startIndex, page = 5; perPage = 100; startIndex = 5 * 100 === 500,
  startIndex?: number,
  // onNavigate - used for navigate in paginated ag-grid
  onNavigate?: (direction: number) => void,
};

const mapStateToProps = (state) => ({
  companyId: currentCompanySelector(state),
  isActiveContextMenu: getUiWorkspaceIsActiveContextMenuSelector(state),
});

const DocumentPreviewNavigation = ({
  documentId,
  setPreview,
  list,
  allCount,
  onContextMenu,
  onClick,
  onLoadNext,
  startIndex = 0,
  onNavigate,
}: Props) => {
  const apiToken = useApiToken();
  const { companyId, isActiveContextMenu } = useSelector(mapStateToProps);
  const [lastCountWasUploaded, setLastCountWasUploaded] = useState(0);
  const previousIndexInListRef = useRef(0);

  const indexInList = useMemo(() => {
    const indexInDocuments = list.findIndex((item) => item.documentID === documentId);
    // when we make actions via context menu(move to category, mark as general etc), display previous existed document
    const adjustedIndex = indexInDocuments < 0 ? previousIndexInListRef.current : indexInDocuments;
    const index = Math.min(list.size - 1, adjustedIndex);

    previousIndexInListRef.current = index;

    return index;
  }, [documentId, list]);

  const listCount = list.size;
  const indexInAll = startIndex + indexInList;
  const ordinalNumber = indexInAll + 1;
  const isLoading = lastCountWasUploaded === list.size && lastCountWasUploaded !== allCount;
  const isShowLoader = (ordinalNumber === list.size && isLoading) || indexInList === -1;

  const document = useMemo(() => (indexInList >= 0 ? list.get(indexInList) : null), [indexInList, list]);

  const isLinked = useMemo(() => document && document.linkid, [document]);

  const isOpenModal = document && apiToken !== null;

  const src = useMemo(
    () => (document ? getPreviewSrc(companyId, document.documentID, apiToken, true) : null),
    [companyId, document, apiToken],
  );

  const documentLink = useMemo(
    () => (document ? getDocumentUrl(companyId, document.documentID) : ''),
    [companyId, document],
  );

  const onClose = useCallback(() => {
    setPreview(null);
  }, [setPreview]);

  const onPrev = useCallback(() => {
    const previousIndexInAll = indexInAll - 1;

    // we have not reached first document in the entire list
    if (previousIndexInAll >= 0) {
      const previousIndexInList = indexInList - 1;
      const previousDocument = list.get(previousIndexInList);

      // for infinite loading we always have previous document
      // because we are always starting from the beginning(startIndex === 0) and concat next chunk to this list
      if (previousDocument && previousIndexInList >= 0) {
        setPreview(previousDocument);
      }

      // for paginated list we can start from the middle of list (startIndex === 500)
      // we have only current documents for the page in the list
      // we need to load the previous page of documents
      if (previousIndexInList < 0 && typeof onNavigate === 'function') {
        onNavigate(-1);
      }
    }
  }, [indexInAll, indexInList, list, setPreview, onNavigate]);

  const onPrevWithDelay = useMemo(() => debounce(onPrev, 200), [onPrev]);

  const onNext = useCallback(() => {
    if (indexInAll < allCount - 1) {
      const nextIndex = indexInList + 1;
      const nextDocument = list.get(nextIndex);

      if (nextDocument && nextIndex <= list.size - 1) {
        setPreview(nextDocument);
      }

      if (typeof onNavigate === 'function' && nextIndex > list.size - 1) {
        onNavigate(1);
      }
    }
  }, [indexInAll, allCount, indexInList, list, setPreview, onNavigate]);

  const onNextWithDelay = useMemo(() => debounce(onNext, 200), [onNext]);

  const onKeyNav = useCallback(
    (e: KeyboardEvent) => {
      const actions = {
        ArrowLeft: onPrevWithDelay,
        ArrowRight: onNextWithDelay,
        Escape: onClose,
      };
      const action = actions[e.key];

      if (action) {
        action();
      }
    },
    [onPrevWithDelay, onNextWithDelay, onClose],
  );

  const onClickContextMenu = useCallback(
    (e: MouseEvent) => {
      if (typeof onContextMenu === 'function') {
        onContextMenu(e, document);
      }
    },
    [onContextMenu, document],
  );

  const onClickOpen = useCallback(
    (e: MouseEvent) => {
      if (typeof onClick === 'function') {
        onClick(e, document);
      }
    },
    [onClick, document],
  );

  // infinite loading (tile view, linked panel)
  useEffect(() => {
    if (onLoadNext && allCount > listCount && indexInList + 5 > listCount && !isLoading) {
      setLastCountWasUploaded(listCount);
      onLoadNext();
    }
  }, [indexInList, onLoadNext, allCount, listCount, isLoading]);

  useEffect(() => {
    window.addEventListener('keydown', onKeyNav);
    return () => {
      window.removeEventListener('keydown', onKeyNav);
    };
  }, [onKeyNav]);

  return isOpenModal ? (
    <DocumentPreview
      documentID={document.documentID}
      isPaid={isDocPaid(document)}
      src={src}
      to={documentLink}
      rotationAngle={getRotationAngle(document)}
      onClick={onClickOpen}
      close={onClose}
      onPrev={onPrev}
      onNext={onNext}
      isDisablePrev={ordinalNumber === 1}
      isDisableNext={ordinalNumber === allCount}
      onContextMenu={typeof onContextMenu === 'function' && onClickContextMenu}
      isContextMenuOpen={isActiveContextMenu}
      allPagesCount={allCount}
      currentPage={ordinalNumber}
      isLinked={isLinked}
      isLoading={isShowLoader}
    />
  ) : null;
};

export default DocumentPreviewNavigation;
