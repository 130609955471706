// @flow
import { compose } from 'redux';
import { type RecordOf, List, OrderedSet } from 'immutable';

export type OptionItem = {
  +id: string,
  +value: string,
};

export type ValueNormalize = (o: OptionItem) => string;
export type FilterList = (l: OptionItem[], t: string) => OptionItem[];

export function valueNormalize({ id, value }: OptionItem) {
  return `${id} - ${value}`;
}

export function localeSort(a: OptionItem, b: OptionItem) {
  return a.value.localeCompare(b.value);
}

/* function relevatSort(term: string) {
  if (term && term.length) {

    const index = (v: OptionItem) => v.value.toUpperCase().indexOf(term);
    return (a: OptionItem, b: OptionItem) => {
      const delta = index(a) - index(b);
      if (delta === 0) {
        // compare by id/code length if term is code substring
        // otherwise '110 - xxxxxx' will come prior to '1100 - xx'
        // with term  '110' as it is shorter
        if (a.id.indexOf(term) >= 0 && b.id.indexOf(term) >= 0) {
          return a.id.length - b.id.length;
        }
        // comparre by full option length
        return a.value.length - b.value.length;
      }
      return delta;
    };
  } return localeSort;
} */

export function filterList(list: OptionItem[], term: string): OptionItem[] {
  const t = term.toUpperCase();
  /* const sorter = relevatSort(t); */
  return list.filter(({ value, display }) => {
    const currentVal = display || value;
    return currentVal.toUpperCase().includes(t);
  });
  // .sort(sorter);
}

export function pastAtPosition(input: HTMLInputElement, text: string): string {
  const { value, selectionStart, selectionEnd } = input;
  let from = selectionStart;
  let to = selectionEnd;
  if (selectionStart > selectionEnd) {
    from = selectionEnd;
    to = selectionStart;
  }
  return value.slice(0, from) + text + value.slice(to, value.length);
}

function findCompleteMatch({ result, list, term }) {
  const l = list.filter((f) => f.value.toUpperCase().includes(term));
  // .sort(relevatSort(term));
  return {
    result: result.concat(l.toOrderedSet()),
    list,
    term,
  };
}

function findReversIn({ result, list, term }) {
  const l = list.filter(({ value, display }) => {
    const currentVal = display || value;
    return term.includes(currentVal.toUpperCase());
  });
  // .sort(relevatSort(term));
  return {
    result: result.concat(l.toOrderedSet()),
    list,
    term,
  };
}

export function findText<T: List<RecordOf<{ value: string }>>>(list: T, term: string) {
  const t = term.toUpperCase();
  const { result } = compose(findReversIn, findCompleteMatch)({ result: ((new OrderedSet(): any): T), list, term: t });
  return result.toList();
}

//
export const exactTest = (test: string, value: string) => {
  const bodyValue = test === value ? value : value.replace(new RegExp('\\((.*?)\\)'), '').trim();
  return bodyValue === test;
};

export const inexactTest = (test: string, value: string) => value.includes(test);

export const testOption =
  (test: string) =>
  (isExact: boolean = false) =>
  ({ display, value }) => {
    const currentValue = display || value || '';
    const testFn = isExact ? exactTest : inexactTest;
    return testFn(test.toUpperCase(), currentValue.toUpperCase());
  };
