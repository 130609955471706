import React from 'react';

import Box from '@mui/material/Box';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import ArrowRightIcon from 'pages/components/AccordionArrowRightIcon';
import MuiButton from '@mui/material/Button';

import { styled } from '@mui/material/styles';

export const Accordion = styled(MuiAccordion)(({ theme }) => ({
  marginBottom: theme.spacing(1.5),
  marginTop: '0 !important',
  border: `1px ${theme.palette.grey[200]} solid`,
  boxShadow: 'none',
  borderRadius: `${theme.shape.borderRadius * 2}px !important`,
  '&.MuiPaper-root.MuiAccordion-root::before': {
    display: 'none',
  },
}));
export const AppImageContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  padding: theme.spacing(0.5, 1),
  border: `1px ${theme.palette.grey[200]} solid`,
  backgroundColor: theme.palette.grey[100],
  borderRadius: 2,
  minHeight: 34,
  justifyContent: 'center',
  alignItems: 'center',
}));

export const Button = ({ onClick, ...rest }) => (
  <MuiButton
    {...rest}
    onClick={(...args) => {
      args[0].stopPropagation();
      if (typeof onClick === 'function') onClick(...args);
    }}
  />
);

export const AccordionSummary = styled((props) => (
  <MuiAccordionSummary elevation={0} expandIcon={<ArrowRightIcon />} {...props} />
))(({ theme }) => ({
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-content': {
    margin: theme.spacing(1, 0),
  },
}));
