import React from 'react';
import { useConfirm } from 'material-ui-confirm';

const withConfirm = (Component) =>
  function WithConfirm(props) {
    const confirm = useConfirm();
    return <Component {...props} confirm={confirm} />;
  };

export default withConfirm;
