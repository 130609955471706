// @flow
import React from 'react';
import { useSelector } from 'react-redux';

import { loadingSelector } from 'domain/env';

import AppBarMui from '@mui/material/AppBar';
import FooterWithTerms from 'components/mui/Layouts/components/FooterWithTerms';
import Stack from '@mui/material/Stack';
import CircularProgressWithBackdrop from 'components/mui/CircularProgressWithBackdrop';

import { styled } from '@mui/material/styles';

type TLayoutServicePage = {
  children: React$Node,
};

const Container = styled(Stack)(() => ({
  width: '100vw',
  justifyContent: 'space-between',
}));

const AppBar = styled(AppBarMui)(({ theme }) => ({
  borderTop: `8px solid ${theme.palette.secondary.main}`,
  paddingBottom: theme.spacing(2),
}));

const LayoutServicePage: React$StatelessFunctionalComponent<TLayoutServicePage> = ({ children }) => {
  const loading = useSelector(loadingSelector);
  return (
    <Container>
      <AppBar color="transparent" elevation={0} position="static" />
      {children}
      <FooterWithTerms />
      {loading && <CircularProgressWithBackdrop />}
    </Container>
  );
};

export default LayoutServicePage;
