// @flow
import React, { Component } from 'react';

// redux
import { compose } from 'redux';
import { connect } from 'react-redux';
// $FlowFixMe untyped module
import { rtlEnable } from 'domain/env';

// components
import { GridTable as Grid } from 'components/Tables/grid';

// types
import type { JornalEntryType, RecordsCellType } from 'domain/journal/helper';

import type { MetaType } from 'components/Tables/types.js.flow';

// helpers
import { getMetaRange } from 'components/Tables/helpers';

// jss
import cx from 'classnames';
// $FlowFixMe untyped module
import { withStyles } from '@mui/styles';
import sheet from './sheet';

import { getDataCell } from 'components/Tables/layout/helpers';

type Props = {|
  classes: typeof sheet,
  data: JornalEntryType,
  rtl: boolean,
  getItem: (d: RecordsCellType | void) => React$Node,
|};

class SelectedTransactions extends Component<Props> {
  // returns table representation as tuple. eg [['A', 'B', 'C'], [5, 6, 7, 8, 9]]
  get meta(): MetaType {
    const { data, rtl } = this.props;
    return getMetaRange(data, rtl);
  }

  getData = (row: number, col: string) => getDataCell(this.props.data, row, col);

  getItem = (row: number, col: string) => this.props.getItem(this.getData(row, col));

  getRowStyle = () => {
    const { classes } = this.props;
    return cx(classes.row, classes.lineItems);
  };

  getCollStyle = () => {
    const {
      classes: { col },
    } = this.props;
    return col;
  };

  render() {
    const { classes } = this.props;
    return (
      <Grid
        className={cx(classes.container)}
        markFirstRowWithClass
        meta={this.meta}
        getItem={this.getItem}
        getRowStyle={this.getRowStyle}
        getCollStyle={this.getCollStyle}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  rtl: rtlEnable(state),
});

export default compose(connect(mapStateToProps), withStyles(sheet))(SelectedTransactions);
