/* @flow */
import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { jeMatchedLinesSelector } from 'domain/journal/selectors';
import { type TableProps } from '../table';
import { type TJeMatchedLines } from 'domain/journal/helper';
import { List } from 'immutable';
import { TEXTRACT_MATCH_STATUSES } from 'components/TextractCanvas/utils';

import { withStyles } from '@mui/styles';
import sheet from './sheet';
import cx from 'classnames';

type HOCProps = {|
  jeMatchedLines: TJeMatchedLines,
|};

type Props = { ...TableProps, ...HOCProps };

type State = {||};

export const withMatchingColorcoding = (WrappedComponent: React.Component<TableProps>) => {
  class WithMatchingColorcoding extends React.Component<Props, State> {
    getRowStyle = (row: number) => {
      const { getRowStyle, classes, headerLineNumber, lineCount, jeMatchedLines } = this.props;

      const line = row - headerLineNumber;
      const isLastRow = line === lineCount - 1; // -1 counts for header
      const match = jeMatchedLines.get(String(line), new List()).get(0, new Map());
      const matchStatus = match.get('status');

      if (isLastRow) return getRowStyle(row); // last row is not means for colorcoding, last row is technical

      return match.size > 0
        ? // $FlowFixMe
          cx(getRowStyle(row), classes[matchStatus])
        : cx(getRowStyle(row), classes[TEXTRACT_MATCH_STATUSES.DEFAULT]);
    };

    render() {
      // https://stackoverflow.com/questions/48936467/material-ui-the-key-provided-to-the-classes-property-is-not-implemented
      // MUI: The key `pinBtn` provided to the classes prop is not implemented in GridTable.
      // You can only override one of the following: table,row,removeLine,col,active,warning,firstRowOfTable.
      // we need exclude classes from props that are passed to WrappedComponent
      const { classes, ...other } = this.props;

      return <WrappedComponent {...other} getRowStyle={this.getRowStyle} />;
    }
  }

  WithMatchingColorcoding.displayName = WrappedComponent.displayName;

  const mapStateToProps = (state) => ({
    jeMatchedLines: jeMatchedLinesSelector(state),
  });

  return compose(connect(mapStateToProps), withStyles(sheet))(WithMatchingColorcoding);
};

export default withMatchingColorcoding;
