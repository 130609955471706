/* @flow */
import * as React from 'react';
import { Set, is, type Map } from 'immutable';
import cx from 'classnames';
import elements from 'components/elements';
import type { MetaType } from '../../types.js.flow';
import { removeButton } from '../component';
import { isMandatory } from '../helpers';
import { createCelClassName } from './helpers';

type RowType = {|
  r: number,
  lineItems?: Set<number>,
  removeHandler?: (r: number) => void,
  rowEntries: Map<string, any>,
|};

type CellType = {|
  c: string,
|};

type CellPropsType = {|
  classes: {|
    [key: string]: string,
  |},
  getItem: (r: number, c: string) => React$Node,
  isActiveCell: ?boolean,
  isActiveRow: ?boolean,
  selectedRows: Set<string>,
  cellEntry: Map<string, string>,
  reconcileColumn: string,
|};

type CellProps = {|
  ...CellPropsType,
  ...CellType,
  ...RowType,
|};

type RowProps = {|
  ...CellPropsType,
  ...RowType,
  selectedRows: Set<string>,
  meta: MetaType,
  style?: CSSStyleDeclaration,
|};

type VProps = {
  index: number,
  style: CSSStyleDeclaration,
  data: {|
    ...CellPropsType,
    data: any,
    lineItems?: Set<number>,
    removeHandler?: (r: number) => void,
    meta: MetaType,
  |},
};

function Cell({ classes, r, c, getItem, ...rest }: CellProps) {
  return (
    <div
      key={c}
      className={cx(classes.col, classes[createCelClassName(c)], {
        [classes.pinned]: rest.reconcileColumn === c,
        [classes.mandatory]: isMandatory(rest.cellEntry),
        [classes.activeCell]: rest.isActiveCell,
      })}
    >
      {getItem(r, c)}
    </div>
  );
}

/* function cellEq(prevProps: CellProps, nextProps: CellProps) {
  if (
    !is(prevProps.cellEntry, nextProps.cellEntry)
    || prevProps.c !== nextProps.c
    || prevProps.r !== nextProps.r
    || (
      nextProps.active === nextProps.c + nextProps.r
      && prevProps.active !== prevProps.c + prevProps.r
    ) || (
      prevProps.active === prevProps.c + prevProps.r
      && nextProps.active !== nextProps.c + nextProps.r
    )
    || !is(prevProps.selectedRows, nextProps.selectedRows)
  ) return false;
  return true;
}

const MemoCell = React.memo(Cell, cellEq); */

export class Row extends React.Component<RowProps> {
  static defaultProps = {
    lineItems: new Set(),
  };

  shouldComponentUpdate(nextProps: RowProps) {
    const { lineItems, selectedRows, meta, active, rowEntries } = this.props;
    if (!is(selectedRows, nextProps.selectedRows)) return true;
    if (!is(lineItems, nextProps.lineItems)) return true;
    if (meta[0].length !== nextProps.meta[0].length) return true;
    if (active !== nextProps.active) return true;
    if (is(rowEntries, nextProps.rowEntries)) return true;
    return true;
  }

  get isRemoveDisable() {
    const { lineItems, rowEntries, reconcileColumn, r } = this.props;
    const status = rowEntries.getIn([reconcileColumn + r, 'cellSet', 'value']);
    if (!lineItems.has(r)) return true;
    if (status === 'Ignored') return true;
    if (status === 'NoBankAccount') return true;
    if (status === 'Reconciled') return true;
    return false;
  }

  render() {
    const { classes, style, meta, r, active, getItem, selectedRows, reconcileColumn, rowEntries } = this.props;

    const removeButtonRender = removeButton({
      removeHandler: this.props.removeHandler,
      classes,
    });

    return (
      <div
        className={cx(classes.row, { [classes.activeRow]: active ? active[1] === r : false })}
        style={style}
        data-element={elements.je.virtualGrid.row}
      >
        <div
          className={cx(classes.removeLine, {
            [classes.removeLineEmpty]: this.isRemoveDisable,
          })}
        >
          {this.isRemoveDisable ? null : removeButtonRender(this.props.r)}
        </div>
        {meta[0].map((c) => (
          <Cell
            key={c}
            c={c}
            r={r}
            isActiveCell={active ? active[0] === c && active[1] === r : false}
            classes={classes}
            getItem={getItem}
            selectedRows={selectedRows}
            reconcileColumn={reconcileColumn}
            cellEntry={rowEntries.get(c + r)}
          />
        ))}
      </div>
    );
  }
}

function VirtualRow({ index, style, data: { data, ...rest } }: VProps) {
  const row = rest.meta[1][index];
  const rowEntries = data.filter((f) => f._row === row);
  return <Row r={row} style={style} rowEntries={rowEntries} {...rest} />;
}

export default VirtualRow;
