import React, { forwardRef, useCallback, memo } from 'react';

import { TextField, Autocomplete } from 'pages/company/Grid/components/MultipleDropdownCellRenderer/StyledComponents';
import Chip from '@mui/material/Chip';
import { ID_FOR_DETECT_CELL_OVERFLOW } from 'components/AgGrid/helpers';

const MultipleDropdownCellRenderer = forwardRef((props, ref) => {
  const { value, colDef } = props;
  const isEditable = typeof colDef.editable === 'function' ? colDef.editable(props) : colDef.editable;

  const renderTags = useCallback(
    (chips, getTagProps) =>
      chips.map((option, index) => (
        <Chip
          key={option.value}
          label={
            <span
              id={ID_FOR_DETECT_CELL_OVERFLOW}
              style={{ display: 'block', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}
            >
              {option.label}
            </span>
          }
          size="small"
          {...getTagProps({ index })}
        />
      )),
    [],
  );

  return (
    <Autocomplete
      open
      value={value}
      options={[]}
      renderInput={(params) => <TextField {...params} inputProps={{ ...params.inputProps }} variant="standard" />}
      renderTags={renderTags}
      limitTags={1}
      readOnly
      disabled={!isEditable}
      fullWidth
      autoComplete
      disablePortal
      disableClearable
      disableCloseOnSelect
      multiple
    />
  );
});

export default memo(MultipleDropdownCellRenderer);
