// @flow
import React from 'react';
import { useSelector } from 'react-redux';

import { shouldHighlightAmountSelector } from 'domain/companies';
import { useTheme } from '@mui/material';

type TAgGridAmount = {|
  value: string,
  valueFormatted: string,
|};

const AmountCellRenderer: React$StatelessFunctionalComponent<TAgGridAmount> = ({ valueFormatted, value }) => {
  const shouldHighlightAmount = useSelector(shouldHighlightAmountSelector);
  const { palette } = useTheme();

  const isHighlighted = shouldHighlightAmount && value < 0;

  return (
    <div
      style={{
        color: isHighlighted ? palette.error.main : 'inherit',
        width: '100%',
        flip: 'false',
        direction: 'ltr',
      }}
    >
      {valueFormatted}
    </div>
  );
};

export default AmountCellRenderer;
