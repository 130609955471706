/* @flow */
import * as React from 'react';

// components
import CrashPage from 'components/CrashPage';

type Props = {
  logger: (error: Error, meta: { data: Object }) => void,
  children?: React$Node,
};

type State = {
  hasError: boolean,
};

export class ErrorBoundary extends React.Component<Props, State> {
  // eslint-disable-next-line no-unused-vars
  static getDerivedStateFromError(error: Error) {
    return { hasError: true };
  }

  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error: Error, errorInfo: Object) {
    this.setState({ hasError: Boolean(error) });
    this.props.logger(error, { data: errorInfo });
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;

    if (hasError) {
      return <CrashPage />;
    }

    return children;
  }
}
