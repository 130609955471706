// @flow
import React from 'react';
import { NavLink, type LocationShape } from 'react-router-dom';

type TRouterNavLink = {
  href: string | LocationShape,
  activeClassName?: string,
};

// if we want to use router NavLink component in Mui Link component or Tabs - we need forward ref
const RouterNavLink = React.forwardRef((props: TRouterNavLink, ref) => {
  // TODO: until we can describe the types :(
  // eslint-disable-next-line react/prop-types
  const { href, activeClassName = '', className = '', ...other } = props;

  return (
    <NavLink
      ref={ref} // TODO: check if is needed
      to={href}
      // workaround - in react-router v6 the behavior was changed to detect active link
      className={({ isActive }) => (isActive ? `${className} ${activeClassName}` : className)}
      {...other}
    />
  );
});

export default RouterNavLink;
