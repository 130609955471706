// @flow
import React, { useEffect, useRef, forwardRef, useCallback, useMemo, useState } from 'react';
import { Set, OrderedSet, OrderedMap, Map } from 'immutable';
import isEqual from 'lodash/isEqual';
import flow from 'lodash/fp/flow';
import { debounce } from 'throttle-debounce';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import Api from 'domain/api';
import {
  documentsGridFilterAppliedAction,
  documentsGridSortingAppliedAction,
  documentsGridColumnsAppliedAction,
  documentsGridGetRowsAction,
  documentsGridDefaultColumnsAppliedAction,
  documentsGridDocumentsByPageIndexAction,
  gridCurrentFiltersSelector,
  gridCurrentSortSelector,
  gridCurrentColumnsStateSelector,
  gridRawPresetsLoadedSelector,
  gridRefreshServerSideSelector,
  gridApplyTransactionSelector,
  documentsGridApplyTransactionAction,
  documentsGridRefreshServerSideAction,
  documentsListListSelector,
  documentCountSelector,
} from 'domain/documents';
import { GridApplyTransactionFactory } from 'domain/documents/factories';
import { isCompanyConfigurationLoadedSelector } from 'domain/companies/companiesSelector';
import {
  changeWidthIfMasterViewNotAvailable,
  setExelModeFilter,
  addColDefaultParams,
  suppressSelectAll,
  convertColumnDefsToGridState,
} from 'components/AgGrid/helpers';
import {
  addSelectionMasterDetailToFirstColumn,
  addColumnsVisibilityColumnDef,
  addContextMenuColumnDef,
  addPreviewColumnDef,
  addLinkedButtonColumnRef,
} from 'components/AgGrid/helpers/columnDefs';
import * as ACL from 'domain/restriction';
import { addFilters } from 'components/AgGrid/helpers/filterHelpers';
import { documentsGridRowNodesAdapter, gridDocumentsListAdapter } from 'domain/documents/adapters';
import { getNestedCategory } from 'domain/categories/helpers';
import { GRID_PAGE_SIZE } from 'domain/documents/constants';
import { getWorkspaceGridPerPage, setWorkspaceGridPerPage } from 'domain/documents/helpers';

// components
import { AgGridReact } from 'ag-grid-react';
import Box from '@mui/material/Box';
import DocumentPreviewNavigation from 'pages/company/DocumentPreviewNavigation/DocumentPreviewNavigation';
import MasterViewCellRenderer from './components/MasterViewCellRenderer';
import PreviewCellRenderer from './components/PreviewCellRenderer';
import ContextCellRenderer from './components/ContextCellRenderer';
import AgGridDateFilter from './components/DateFilter';
import ApprovalsCellRenderer from './components/ApprovalsCellRenderer';
import TagsCellRenderer from './components/TagsCellRenderer';
import AgGridCustomTooltip from './components/CustomTooltip';
import AlertCellRenderer from './components/AlertCellRenderer';
import AgGridColumnHeader from './components/ColumnHeader';
import AmountSumStatusPanelRenderer from './components/AmountSumStatusPanelRenderer';
import AmountCellRenderer from './components/AmountCellRenderer';
import CustomSetColumnFilter from 'pages/company/Grid/components/CustomHeaderFilter/CustomSetColumnFilter/CustomSetColumnFilter';
import LinkedCellRenderer from './components/LinkedCellRenderer';
import StubWhenEmpty from 'pages/company/stub/StubWhenEmpty';

import usePropagateGridApi from 'pages/company/Grid/usePropagateGridApiRef';
import usePrevious from 'hooks/usePrevious';
import useDidUpdateEffect from 'hooks/useDidUpdateEffect';
import useScrollManager, { getWorkspaceScrollPosition } from 'lib/scrollManager';
import useAgGridDefaultConfig from 'components/AgGrid/hooks/useAgGridDefaultConfig';
import useColumnTypes from 'components/AgGrid/hooks/columnTypes/useColumnTypes';
import useAgGridManualConfig from 'components/AgGrid/hooks/useAgGridManualConfig';
import useAgGridMethods from 'components/AgGrid/hooks/useAgGridMethods';
import useGridContext from 'pages/company/Grid/useGridContext';
import useFetchGridColumnDefs from 'pages/company/Grid/useFetchGridColumnDefs';
import useFetchTotalByCategory from 'pages/company/Grid/useFetchTotalByCategory';
import useRangeSelectionChanged from './useRangeSelectionChanged';
import useAgGridDocumentsPreviewNavigation from 'components/AgGrid/hooks/useAgGridDocumentsPreviewNavigation';
import useWorkspaceGridStyles from './useWorkspaceGridStyles';

import type { TDocumentRecord, TDocument } from 'domain/documents/types.js.flow';
import type { TgridApiRef, GridRef } from './types.js.flow';
import type { TDocumentPreviewState } from '../type.js.flow';

export interface IGridProps {
  selected: OrderedSet<string>;
  onChangeSelected: (newSelected: Set<string>) => void;
  onContextMenu: (e: SyntheticMouseEvent<HTMLElement>, d?: TDocumentRecord, l?: string) => void;
  onDocumentOpen: (documentID: string, isLinked?: ?boolean) => void;
  onPreview: (p: TDocumentPreviewState) => void;
  preview: TDocumentPreviewState;
  onShowLinked: (tag: string) => Promise<*>;
  resetIsLinkedPanelSelected: () => void;
  isLinkedPanelSelected: string;
}

export const PREVIEW_CONTEXT_NAME = 'gridList';

const mapStateToProps = (state) => ({
  documentsList: documentsListListSelector(state),
  documentsCount: documentCountSelector(state),
  isGranted: ACL.isGranted(state),
  gridFilters: gridCurrentFiltersSelector(state),
  gridSorting: gridCurrentSortSelector(state),
  gridColumnsState: gridCurrentColumnsStateSelector(state),
  isGridPresetLoaded: gridRawPresetsLoadedSelector(state),
  isCompanyConfigurationLoaded: isCompanyConfigurationLoadedSelector(state),
  refreshDocumentNodes: gridApplyTransactionSelector(state),
  refreshServerSide: gridRefreshServerSideSelector(state),
});

const Grid = forwardRef(
  (
    {
      onContextMenu,
      onChangeSelected,
      onDocumentOpen,
      selected,
      onPreview,
      onShowLinked,
      resetIsLinkedPanelSelected,
      isLinkedPanelSelected,
      preview,
    }: IGridProps,
    ref: GridRef,
  ) => {
    const dispatch = useDispatch();
    const routeParams = useParams();
    const { search } = useLocation();
    const DEFAULT_PER_PAGE = useMemo(() => getWorkspaceGridPerPage(), []);

    const {
      documentsList,
      documentsCount,
      refreshDocumentNodes,
      refreshServerSide,
      isGranted,
      gridFilters,
      gridSorting,
      gridColumnsState,
      isGridPresetLoaded,
      isCompanyConfigurationLoaded,
    } = useSelector(mapStateToProps);

    const agGridDefaultProps = useAgGridDefaultConfig(useWorkspaceGridStyles);
    const { enableRtl, rowClassRules, getLocaleText } = useAgGridManualConfig();
    const { invertPinnedWhenRTL } = useAgGridMethods();
    const columnTypes = useColumnTypes();
    const gridApiRef: {| current: null | TgridApiRef |} = useRef();
    // !!! Ref.current used to avoid unnecessary prop changes and re-renders in ag-grid instead of getting this data from Redux after changes
    // !!! dispatched data to redux mostly needed for propagate(sharing) to another components
    const onFirstDataRenderedRef = useRef(false);
    const filterModelRef = useRef(null); // workaround for not applied filter model in grid
    const previousFilterModelRef = useRef({}); // used for detect if filter model has changes
    const previousSortModelRef = useRef([]); // used for detect if sort model has changes
    const previousColumnModelRef = useRef([]); // used for detect if column model has changes - [width, pinned, order, hide]
    const previousInitialColumnStateRef = useRef([]); // used for detect if initial column state has changes
    const previousSelectedSize = usePrevious(selected.size); // used for detect cmd/ctrl+A or escape
    const selectedIDsRef = useRef(selected);
    // documentsOrderedMapRef used for pagination, dispatch actual documents list to redux
    // actual documents for current page
    const documentsOrderedMapRef = useRef(new OrderedMap()); // OrderedMap with adapted documents by ID
    const [isGridInit, setGridInit] = useState(false);
    const [isFirstDataFetched, setIsFirstDataFetched] = useState(false);
    const [perPage, setPerPage] = useState(DEFAULT_PER_PAGE);
    const [isDatasourceRequestInProgress, setDatasourceRequestInProgress] = useState(false);

    const onCellSelectionChanged = useRangeSelectionChanged(gridApiRef);
    const category = getNestedCategory(routeParams);

    const hasMasterDetail = isGranted(ACL.IS_BOOKKEEPER_IN_FINANCIAL_COMPANY);

    const { fetchGridTotal, documentsTotal, documentsTotalIsFetched } = useFetchTotalByCategory();
    const { rawColumnDefsIsBusy, rawColumnDefs } = useFetchGridColumnDefs();

    const defaultColumnDefs = useMemo(() => {
      if (rawColumnDefsIsBusy) return [];

      const columns = flow([
        addLinkedButtonColumnRef,
        addPreviewColumnDef,
        addSelectionMasterDetailToFirstColumn,
        addColumnsVisibilityColumnDef,
        addContextMenuColumnDef,
        addFilters,
        setExelModeFilter('windows'),
        addColDefaultParams,
      ])(rawColumnDefs);

      const state = changeWidthIfMasterViewNotAvailable(columns, hasMasterDetail);
      previousColumnModelRef.current = state;

      return state;
    }, [rawColumnDefsIsBusy, rawColumnDefs, hasMasterDetail]);

    const columnDefs = useMemo(
      () => flow(suppressSelectAll, invertPinnedWhenRTL)(defaultColumnDefs),
      [defaultColumnDefs, invertPinnedWhenRTL],
    );

    const initialColumnState = useMemo(
      () =>
        defaultColumnDefs.map(({ field, hide = false, pinned, width = 0, minWidth, maxWidth }) => {
          const currentWidth = Math.max(minWidth || 1, Math.min(maxWidth || 1000, width));

          return {
            aggFunc: null,
            colId: field,
            flex: null,
            hide,
            pinned: pinned || null,
            pivot: false,
            pivotIndex: null,
            rowGroup: false,
            rowGroupIndex: null,
            sort: null,
            sortIndex: null,
            width: currentWidth,
          };
        }),
      [defaultColumnDefs],
    );

    const initialState = useMemo(() => {
      const { pageIndex = 0 } = getWorkspaceScrollPosition();

      return {
        filter: {
          filterModel: gridFilters,
        },
        sort: {
          sortModel: gridSorting,
        },
        pagination: { page: pageIndex, pageSize: perPage },
        partialColumnState: true,
      };
    }, [gridSorting, gridFilters, perPage]);

    useEffect(() => {
      if (isFirstDataFetched && documentsTotal === 0 && !isDatasourceRequestInProgress) {
        gridApiRef.current.showNoRowsOverlay();
      }
    }, [documentsTotal, isFirstDataFetched, isDatasourceRequestInProgress]);

    useEffect(() => {
      if (
        rawColumnDefs.length > 0 &&
        initialColumnState.length > 0 &&
        !isEqual(previousInitialColumnStateRef.current, initialColumnState)
      ) {
        previousInitialColumnStateRef.current = initialColumnState;
        previousColumnModelRef.current = initialColumnState;
        dispatch(documentsGridDefaultColumnsAppliedAction(initialColumnState));
      }
    }, [dispatch, initialColumnState, rawColumnDefs]);

    // !IMPORTANT - dont change order of useEffect below for applying grid state
    useEffect(() => {
      if (isGridInit) {
        if (!isEqual(previousFilterModelRef.current, gridFilters)) {
          gridApiRef.current.setFilterModel(gridFilters);
          filterModelRef.current = gridFilters;
          previousFilterModelRef.current = gridFilters;
        }
      }
    }, [isGridInit, gridFilters]);

    useEffect(() => {
      if (isGridInit) {
        if (!isEqual(previousColumnModelRef.current, gridColumnsState)) {
          // skip sort props - we apply sort via gridSorting state
          const state = invertPinnedWhenRTL(gridColumnsState).map(({ colId, pinned, width, hide, flex }) => ({
            colId,
            pinned,
            width,
            hide,
            flex,
          }));

          gridApiRef.current.applyColumnState({
            state,
            applyOrder: true,
          });
          previousColumnModelRef.current = gridColumnsState;
        }
      }
    }, [isGridInit, gridColumnsState, invertPinnedWhenRTL]);

    useEffect(() => {
      if (isGridInit) {
        if (!isEqual(previousSortModelRef.current, gridSorting)) {
          const previousSort = previousSortModelRef.current[0];
          const currentSort = gridSorting[0];

          const state = [
            {
              sort: null,
              colId: previousSort?.colId,
            },
            {
              sort: currentSort?.sort || null,
              colId: currentSort?.colId,
            },
          ];

          gridApiRef.current.applyColumnState({
            state,
          });

          previousSortModelRef.current = gridSorting;
        }
      }
    }, [isGridInit, gridSorting]);

    useEffect(() => {
      if (gridApiRef.current && documentsOrderedMapRef.current.size > 0) {
        // this is for cmd/ctrl+A feature, listener is placed in BulkPanelComponent,
        // 'api' - send this source name for event, and skip handler for this
        // reset selected rows when we have empty array list
        if ((selected.size === 0 && previousSelectedSize) || isLinkedPanelSelected) {
          gridApiRef.current.deselectAll('api');
          selectedIDsRef.current = OrderedSet();
        } else if (selected.size === documentsOrderedMapRef.current.size && previousSelectedSize !== selected.size) {
          gridApiRef.current.selectAll('api');
          selectedIDsRef.current = OrderedSet(selected);
        }
      }
    }, [selected, previousSelectedSize, isLinkedPanelSelected]);

    // used for refresh data(silent mode - no request sent to backend) of grid after some changes for example moving to another category etc
    // dispatched in saga mostly
    useEffect(() => {
      const { nodes, transactionType } = refreshDocumentNodes;
      if (transactionType && nodes.size > 0) {
        const data = documentsGridRowNodesAdapter(refreshDocumentNodes.nodes);
        const transaction = {
          [refreshDocumentNodes.transactionType]: data,
        };

        const { status } = gridApiRef.current.applyServerSideTransaction(transaction);

        if (transactionType === 'remove' && status === 'Applied') {
          documentsOrderedMapRef.current = documentsOrderedMapRef.current.filter((d) => !nodes.includes(d.documentID));
          fetchGridTotal().then();
        }
        dispatch(documentsGridApplyTransactionAction(GridApplyTransactionFactory()));
      }
    }, [refreshDocumentNodes, dispatch, selected, fetchGridTotal]);

    // used for refresh data(hard mode - request sent to backend) of grid after some changes for example moving to another category etc
    useEffect(() => {
      if (typeof refreshServerSide === 'boolean') {
        gridApiRef.current.deselectAll();
        gridApiRef.current.refreshServerSide({ purge: refreshServerSide });
        dispatch(documentsGridRefreshServerSideAction(null));
        fetchGridTotal().then();
      }
    }, [refreshServerSide, dispatch, fetchGridTotal]);

    // refresh grid data when category changed and skip it on first render
    useDidUpdateEffect(() => {
      gridApiRef.current.deselectAll();
      gridApiRef.current.refreshServerSide({ purge: true });
    }, [category]);

    // refresh grid data when [location search (URL query params)] changed and skip it on first render
    useDidUpdateEffect(() => {
      gridApiRef.current.refreshServerSide({ purge: false });
    }, [search]);

    const getRowID = useCallback(({ data }) => data.documentID, []);

    const isRowMaster = useCallback((dataItem) => dataItem.hasMasterDetail, []);

    const dispatchColumnState = useCallback(() => {
      if (gridApiRef.current) {
        const columns = gridApiRef.current.getColumnState();
        // we save to store always LTR version of grid state
        const stateLTR = invertPinnedWhenRTL(columns);
        // we compare with gridColumnState in ltr variant, so set to previousColumnModelRef.current ltr variant as well
        previousColumnModelRef.current = stateLTR;
        dispatch(documentsGridColumnsAppliedAction(stateLTR));
      }
    }, [dispatch, invertPinnedWhenRTL]);

    const onSelectionChanged = useCallback(
      ({ api, source }) => {
        // event source 'api' is set in useEffect
        if (!isLinkedPanelSelected || source !== 'api') {
          let selectedIDs;
          const { selectAll, toggledNodes } = api.getServerSideSelectionState();
          const documentIDs = documentsOrderedMapRef.current.keySeq().toOrderedSet();

          if (selectAll) {
            if (toggledNodes.length > 0) {
              selectedIDs = OrderedSet(documentIDs).subtract(toggledNodes);
            } else {
              selectedIDs = OrderedSet(documentIDs);
            }
          } else {
            selectedIDs = OrderedSet(toggledNodes);
          }

          if (!selectedIDs.equals(selectedIDsRef.current)) {
            selectedIDsRef.current = selectedIDs;
            resetIsLinkedPanelSelected();
            onChangeSelected(selectedIDs);
          }
        }
      },
      [onChangeSelected, resetIsLinkedPanelSelected, isLinkedPanelSelected],
    );

    const onRowGroupOpened = useCallback((e: { [key: string]: any, expanded: boolean }) => {
      if (e.expanded && gridApiRef.current) {
        gridApiRef.current.forEachNode((node) => {
          if (node.expanded && e.data.documentID !== node.data.documentID) {
            node.setExpanded(false);
          }
        });
      }
    }, []);

    const handleChangeColumnState = useMemo(
      () =>
        debounce(250, ({ type, source }) => {
          // dispatch column state only when done manually
          // dragStopped - column drag, resize column
          // autosizeColumns - via column menu (generalMenuTab)
          if (type === 'dragStopped' || source === 'autosizeColumns') {
            dispatchColumnState();
          }
        }),
      [dispatchColumnState],
    );

    const onColumnPinned = useCallback(
      ({ source }) => {
        // dispatch column state only when done manually via menu
        // skip when we apply state via api
        if (source === 'columnMenu') {
          dispatchColumnState();
        }
      },
      [dispatchColumnState],
    );

    const onColumnMenuVisibleChanged = useCallback(
      ({ visible, key, api }) => {
        // when click on column menu button for management columns -
        // event return sometimes key generalMenuTab instead of columnsMenuTab
        if (visible === false && ['columnsMenuTab', 'generalMenuTab'].includes(key)) {
          const state = api.getColumnState();
          const previousHiddenColumn = previousColumnModelRef.current.filter((col) => col.hide === true);
          const currentHiddenColumn = state.filter((col) => col.hide === true);

          if (!isEqual(previousHiddenColumn, currentHiddenColumn)) {
            dispatchColumnState();
          }
        }
      },
      [dispatchColumnState],
    );

    const onClickOpen = useCallback(
      (_, doc: TDocument) => {
        onDocumentOpen(doc.documentID);
      },
      [onDocumentOpen],
    );

    const frameworkComponents = useMemo(
      () => ({
        masterViewCellRenderer: MasterViewCellRenderer,
        approvalsCellRenderer: ApprovalsCellRenderer,
        tagsCellRenderer: TagsCellRenderer,
        amountCellRenderer: AmountCellRenderer,
        amountSumStatusPanel: AmountSumStatusPanelRenderer,
        previewCellRenderer: PreviewCellRenderer,
        linkedCellRenderer: LinkedCellRenderer,
        alertCellRenderer: AlertCellRenderer,
        contextCellRenderer: ContextCellRenderer,
        customSetColumnFilter: CustomSetColumnFilter,
        agDateInput: AgGridDateFilter,
        agColumnHeader: AgGridColumnHeader,
        customTooltip: AgGridCustomTooltip,
      }),
      [],
    );

    const serverDatasource = useMemo(
      () => ({
        getRows: async (params) => {
          const {
            context: { fetchParams },
            success,
            fail,
            request,
            api,
          } = params;
          const { filterModel: requestFilterModel, sortModel, startRow, endRow } = request;
          const requestPageIndex = startRow / (endRow - startRow);
          // sometimes we can caught case when filter model(width custom/async filters) not applied
          // we got empty object instead of object with applied filter model
          // for safety, check of the applied model has been added
          const isAppliedFilterModel = isEqual(filterModelRef.current, requestFilterModel);

          if (isAppliedFilterModel) {
            filterModelRef.current = null;
          }

          const filterModel =
            filterModelRef.current && !isAppliedFilterModel ? filterModelRef.current : requestFilterModel;

          const requestData = {
            filterModel,
            sortModel,
            startRow,
            endRow,
          };

          api.hideOverlay();
          api.clearCellSelection();

          const isSortEqual = isEqual(previousSortModelRef.current, sortModel);
          const isFilterEqual = isEqual(previousFilterModelRef.current, filterModel);

          if (!isSortEqual) {
            // order important, set to previous, after dispatch to redux
            previousSortModelRef.current = sortModel;
            dispatch(
              documentsGridSortingAppliedAction({
                sorting: sortModel,
              }),
            );
          }

          if (!isFilterEqual) {
            previousFilterModelRef.current = filterModel;
            dispatch(
              documentsGridFilterAppliedAction({
                filters: filterModel,
              }),
            );
          }

          setDatasourceRequestInProgress(true);

          try {
            const {
              data: { items, count, timeStamp },
            } = await Api.getWorkspaceGridRows({
              data: {
                ...fetchParams,
                ...requestData,
              },
            });

            const adaptedList = gridDocumentsListAdapter(items);
            const rowData = documentsGridRowNodesAdapter(adaptedList);
            const response = { rowData, rowCount: count };

            success(response);

            documentsOrderedMapRef.current = adaptedList;

            dispatch({
              type: documentsGridGetRowsAction.success,
              payload: {
                list: adaptedList,
                count,
                timeStamp,
                count_without_link_panels: count,
              },
            });

            dispatch(
              documentsGridDocumentsByPageIndexAction({
                data: Map({ [requestPageIndex]: adaptedList.keySeq().toList() }),
                purge: true,
              }),
            );

            setDatasourceRequestInProgress(false);
            setIsFirstDataFetched(true);
          } catch (error) {
            console.log('====== REQUEST FAILED', error);
            fail();
            // success({ rowData: [], count: 0 });
          }
        },
      }),
      [dispatch], // be careful when adding dependencies to hook - potentially invoked again every time values change
    );

    const onGridReady = useCallback(async (params: {| api: TgridApiRef |}) => {
      gridApiRef.current = params.api;
      setGridInit(true);
    }, []);

    const onFirstDataRendered = useCallback(() => {
      onFirstDataRenderedRef.current = true;
    }, []);

    const onPaginationChanged = useCallback(({ newPage, newPageSize, api }) => {
      if (newPageSize) {
        const size = api.paginationGetPageSize();

        api.deselectAll();
        setPerPage(size);
        setWorkspaceGridPerPage(size);
      }

      if (newPage) {
        api.deselectAll();
      }
    }, []);

    const onStateUpdated = useCallback(({ state, sources, api }) => {
      // console.log('====== ON STATE UPDATED', state, sources, api.getColumnState());
    }, []);

    const { startIndex, handlePreviewNavigate, handleChangePreview, isPreviewOpen } =
      useAgGridDocumentsPreviewNavigation({
        gridApiRef,
        isGridReady: isGridInit,
        list: documentsList,
        previewContextKey: PREVIEW_CONTEXT_NAME,
        previewState: preview,
        onPreviewCallback: onPreview,
      });

    const componentsContext = useMemo(
      () => ({
        previewCellRenderer: {
          onClickPreview: handleChangePreview,
          onClickDocument: onDocumentOpen,
        },
        linkedCellRenderer: {
          onClickLinked: onShowLinked,
        },
        contextCellRenderer: {
          oncClickContextMenu: onContextMenu,
        },
      }),
      [handleChangePreview, onDocumentOpen, onShowLinked, onContextMenu],
    );

    const gridContext = useGridContext({ components: componentsContext, autocompleteApiInstance: Api.getDocumentsGridFilterListByField });

    usePropagateGridApi({ ref, gridApiRef, gridContext, selectedIDs: selected });

    useScrollManager({
      view: 'grid',
      isTargetLoaded: isFirstDataFetched,
      gridApi: gridApiRef.current,
    });

    // !IMPORTANT - be careful when changing next condition, we must wait for this data for grid to work correctly
    if (!isGridPresetLoaded || !isCompanyConfigurationLoaded || rawColumnDefsIsBusy || !documentsTotalIsFetched) {
      return null;
    }

    return (
      <>
        <Box className="ag-theme-material" display="flex" flexDirection="column" height="100%">
          <AgGridReact
            serverSideDatasource={serverDatasource}
            masterDetail={hasMasterDetail}
            isRowMaster={isRowMaster}
            components={frameworkComponents}
            detailRowHeight={400}
            onGridReady={onGridReady}
            onFirstDataRendered={onFirstDataRendered}
            onStateUpdated={onStateUpdated}
            onRowGroupOpened={onRowGroupOpened}
            onColumnPinned={onColumnPinned}
            onSelectionChanged={onSelectionChanged}
            onDragStopped={handleChangeColumnState}
            onCellSelectionChanged={onCellSelectionChanged}
            onColumnResized={handleChangeColumnState}
            onColumnMenuVisibleChanged={onColumnMenuVisibleChanged}
            onPaginationChanged={onPaginationChanged}
            // props from useAgGridManualConfig hook
            enableRtl={enableRtl}
            getLocaleText={getLocaleText}
            rowClassRules={rowClassRules}
            // props from useAgGridDefaultConfig hook
            {...agGridDefaultProps}
            rowModelType="serverSide"
            suppressServerSideFullWidthLoadingRow
            columnTypes={columnTypes}
            getRowId={getRowID}
            rowHeight={58}
            columnDefs={columnDefs}
            context={gridContext}
            cacheBlockSize={perPage}
            maxBlocksInCache={1}
            initialState={initialState}
            noRowsOverlayComponent={StubWhenEmpty}
            serverSideInitialRowCount={documentsTotal} // used for restore page and scroll position
            // blockLoadDebounceMillis={500}
            pagination
            paginationPageSize={perPage}
            paginationPageSizeSelector={GRID_PAGE_SIZE}
          />
        </Box>

        {isPreviewOpen && (
          <DocumentPreviewNavigation
            documentId={preview.documentId}
            setPreview={(document) => handleChangePreview(document?.documentID)}
            list={documentsList}
            allCount={documentsCount}
            onContextMenu={onContextMenu}
            onClick={onClickOpen}
            startIndex={startIndex}
            onNavigate={handlePreviewNavigate}
          />
        )}
      </>
    );
  },
);

// eslint-disable-next-line react/prop-types
export default ({ forwardedRef, ...props }) => <Grid ref={forwardedRef} {...props} />;
