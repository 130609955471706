// @flow
import React, { useEffect } from 'react';

// redux
import { compose, type Dispatch } from 'redux';
import { connect } from 'react-redux';
import { clearFields, getFormValues } from 'redux-form/immutable';

// components
import Field from 'redux-form/lib/immutable/Field';
import FileUploadInput from './FileUploadInput';
import Tooltip from 'components/mui/Tooltip';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

// react-intl
import { FormattedMessage } from 'react-intl';

// styles
import { styled } from '@mui/material/styles';
import excelIcon from './assets/excel.svg';

// types
import type { Fields } from '../../index';

const DownloadTemplate = styled(Stack)(({ theme }) => ({
  margin: theme.spacing(1),
  minWidth: 16,
  width: 16,
  height: 16,
  background: theme.palette.success.main,
  WebkitMask: `url(${excelIcon}) center no-repeat`,
}));

const HREF = 'https://dokka-assets.s3-eu-west-1.amazonaws.com/SAP.xlsx';
type Props = {|
  fields: $ReadOnlyArray<Fields>,
  formValues: any,
  dispatch: Dispatch<*>,
|};

const UploadField = ({ formValues, dispatch, fields }: Props) => {
  const field = fields[0];

  const upload = formValues && formValues.get(field.name);
  const fileName = upload && upload.name;

  const destroyField = () => {
    dispatch(clearFields('erpConnect', false, false, field.name));
  };

  useEffect(
    () => () => {
      destroyField();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <Stack direction="row" justifyContent="space-between">
      <Stack direction="row" alignItems="center">
        <Typography variant="subtitle2">
          <FormattedMessage id={field.label.id} defaultMessage={field.label.defaultMessage} />
        </Typography>
        <Tooltip t={<FormattedMessage id="sapConnect.downloadTenplate" defaultMessage="Download template" />}>
          <DownloadTemplate component="a" download="template" href={HREF} />
        </Tooltip>
      </Stack>
      <Field key={field.name} name={field.name} fileName={fileName} component={FileUploadInput} />
    </Stack>
  );
};

const mapStateToProps = (state) => ({
  formValues: getFormValues('erpConnect')(state),
});

export default compose(connect(mapStateToProps))(UploadField);
