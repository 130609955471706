// @flow
import React from 'react';
import type { TDocumentRecord } from 'domain/documents/types.js.flow';
import Tooltip from 'components/mui/Tooltip';

import RemoveDoneOutlinedIcon from '@mui/icons-material/RemoveDoneOutlined';
import DoneAllOutlinedIcon from '@mui/icons-material/DoneAllOutlined';
import PublishedWithChangesOutlinedIcon from '@mui/icons-material/PublishedWithChangesOutlined';
// import BorderColorIcon from '@mui/icons-material/BorderColor'; // spared variant for approved
// import HowToRegIcon from '@mui/icons-material/HowToReg'; // spared variant for approved

type TApprovalStatus = {
  document: TDocumentRecord,
};

const STATUS_MAPPING = {
  pending: {
    t: {
      id: 'documents.item.approval.pending',
      defaultMessage: 'Pending Approval',
    },
    Icon: PublishedWithChangesOutlinedIcon,
    color: 'warning.main',
  },
  complete: {
    t: {
      id: 'documents.item.approval.complete',
      defaultMessage: 'Approved',
    },
    Icon: DoneAllOutlinedIcon,
    color: 'success.main',
  },
  rejected: {
    t: {
      id: 'documents.item.approval.rejected',
      defaultMessage: 'Approval Rejected',
    },
    Icon: RemoveDoneOutlinedIcon,
    color: 'error.main',
  },
};

const ApprovalStatus: React$StatelessFunctionalComponent<TApprovalStatus> = ({ document }) => {
  const { tags, approvals } = document;

  const pending = tags.includes('_S_APPROVAL_PENDING') && approvals.status === 'active' ? 'pending' : null;
  const complete = tags.includes('_S_APPROVAL_COMPLETE') ? 'complete' : null;
  const rejected = tags.includes('_S_APPROVAL_REJECTED') ? 'rejected' : null;

  const approval = complete || pending || rejected || null;
  const status = STATUS_MAPPING[approval];

  return status ? (
    <Tooltip t={status.t} placement="top">
      <status.Icon sx={{ fontSize: 20, color: status.color }} />
    </Tooltip>
  ) : null;
};

export default ApprovalStatus;
