// @flow
import React, { type Node } from 'react';
import { withStyles } from '@mui/styles';
import sheet from './sheet';
import { FormattedMessage } from 'react-intl';

export type Props = {
  classes: {
    [key: string]: string,
  },
  title: string,
  titleLangId: string,
  children: Node,

  // jss props
  isWhite?: boolean,
};

type State = {||};

class BarDropdownItem extends React.Component<Props, State> {
  render() {
    const { classes, children, title, titleLangId } = this.props;
    return (
      <label className={classes.box}>
        {children}
        <span className={classes.title}>
          <FormattedMessage defaultMessage={title} id={titleLangId} />
        </span>
      </label>
    );
  }
}

BarDropdownItem.displayName = 'BarDropdownItem';

export default withStyles(sheet)(BarDropdownItem);
