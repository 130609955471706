// @flow
import React, { useCallback, useMemo, memo } from 'react';
import { Set, List } from 'immutable';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { makeChannelName } from 'domain/chat/helpers';
import { userIdSelector } from 'domain/env';
import {
  chatUsersWithoutSuppliers,
  chatThreadSelector,
  chatByCompanySelector,
  isChatConnectedSelector,
  chatParticipantsByIdSelector,
  setActiveChannelAction,
} from 'domain/chat';
import { relevantSearch } from 'lib/helpers';
import type { ChatUser } from 'domain/chat/types.js.flow';
import type { RecordOf } from 'immutable';

import Box from '@mui/material/Box';
import ListMui from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListSubheader from '@mui/material/ListSubheader';
import Divider from '@mui/material/Divider';
import DocumentChannel from 'components/mui/Layouts/components/Chat/components/ChannelsThread/components/DocumentChannel';
import PrivateChannel from 'components/mui/Layouts/components/Chat/components/ChannelsThread/components/PrivateChannel';
import { ChannelActionArea } from 'components/mui/Layouts/components/Chat/components/StyledComponents';

type TChannelsThreads = {|
  searchText?: string,
  documentId: string,
|};

function getLastMessage(messages: Set<*>): List<*> {
  return messages
    .toList()
    .sortBy((m) => m.createdAt)
    .last();
}

const mapStateToProps = (state) => ({
  users: chatUsersWithoutSuppliers(state),
  threads: chatThreadSelector(state),
  documents: chatByCompanySelector(state),
  isConnected: isChatConnectedSelector(state),
  chatParticipants: chatParticipantsByIdSelector(state),
  currentUserID: userIdSelector(state),
});

const ChannelsThreads: React$StatelessFunctionalComponent<TChannelsThreads> = ({ searchText, documentId }) => {
  const dispatch = useDispatch();
  const { users, threads, documents, isConnected, chatParticipants, currentUserID } = useSelector(mapStateToProps);
  // don't try retrieving documentId from url
  // for mobile approvals document must be passed as prop
  // as url doesnt change during navigation
  const { companyId } = useParams();

  const handleClickChatChannel = useCallback(
    (d: RecordOf<ChatUser>) => {
      dispatch(
        setActiveChannelAction({
          threadId: makeChannelName([currentUserID, d.userId], companyId, documentId),
          companyId,
        }),
      );
    },
    [dispatch, currentUserID, companyId, documentId],
  );

  const searchComparator = useCallback(
    (value: string) => {
      if (!searchText) return true;

      return value.toLowerCase().includes(searchText.toLowerCase());
    },
    [searchText],
  );

  const channelsFromDocuments = useMemo(
    () =>
      documents
        .filter((f) => f.has('documentId'))
        .filter((doc) => {
          const thread = getLastMessage(doc.messages);
          const user = chatParticipants.get(thread.sender.userId);
          const userName = user ? user.username : thread.sender.nickname;
          return searchComparator(userName);
        }),
    [documents, chatParticipants, searchComparator],
  );

  const channelsFromUsers = useMemo(
    () => (searchText ? relevantSearch(searchText, users, (user) => user.username) : users),
    [searchText, users],
  );

  const getChannelName = (userId: string) => makeChannelName([currentUserID, userId], companyId, documentId);

  const getCounterByUserID = (userId: string): number =>
    threads.getIn([getChannelName(userId), 'unreadMessageCount'], 0);

  const getMessageByUserID = (userId: string) =>
    getLastMessage(threads.getIn([getChannelName(userId), 'messages'], Set()));

  const userChannels = useMemo(
    () =>
      channelsFromUsers
        .map((entry) => ({
          userData: entry,
          thread: getMessageByUserID(entry.userId),
          unread: getCounterByUserID(entry.userId),
        }))
        .sort((a, b) => {
          if (!a.thread) {
            return 1;
          }

          if (!b.thread) {
            return -1;
          }

          if (a.thread.createdAt === b.thread.createdAt) {
            return 0;
          }

          return a.thread.createdAt < b.thread.createdAt ? 1 : -1;
        }),
    [channelsFromUsers, getMessageByUserID, getCounterByUserID],
  );

  return (
    // overflowY: 'auto' - for scrolling channels and sticky <ListSubheader />
    <Box sx={{ overflowY: 'auto' }}>
      {/* dont render if we on document page */}
      {!documentId && channelsFromDocuments.size > 0 && (
        <ListMui>
          <ListSubheader sx={{ textTransform: 'uppercase' }}>
            <Divider textAlign="left">
              <FormattedMessage id="chat.title.documentRequests" defaultMessage="Document Requests" />
            </Divider>
          </ListSubheader>
          {channelsFromDocuments.map((entry) => (
            <ListItem key={entry.name} disablePadding>
              <DocumentChannel thread={getLastMessage(entry.messages)} searchText={searchText} threadId={entry.name} />
            </ListItem>
          ))}
        </ListMui>
      )}

      <ListMui disablePadding>
        <ListSubheader disableGutters sx={{ textTransform: 'uppercase' }}>
          <Divider textAlign="left">
            <FormattedMessage id="chat.title.messages" defaultMessage="Messages" />
          </Divider>
        </ListSubheader>
        {userChannels.map(({ userData, thread, unread }) => (
          <ListItem key={userData.userId} disablePadding>
            <ChannelActionArea disabled={!isConnected} onClick={() => handleClickChatChannel(userData)}>
              <PrivateChannel data={userData} thread={thread} unread={unread} searchText={searchText} />
            </ChannelActionArea>
          </ListItem>
        ))}
      </ListMui>
    </Box>
  );
};

export default memo(ChannelsThreads);
