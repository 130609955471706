import Api from 'domain/api';

const notifyOfAppActivity = () => {
  try {
      // invoke api when tab is activated
      Api.notifyOfAppActivity({
        data: {
          app_code: process.env.REACT_APP_DOKKA_APPLICATION_ID,
        },
      });
    } catch (error) {
      console.error('Failed to notify app switcher backend endpoint of app actiity', error);
    }
};

export const registerAppSwitcherTabActivationEventHandler = () => {
  // invoke api when AP app is initializing
  notifyOfAppActivity();

  // invoke api on tabActivated event
  document.addEventListener('visibilitychange', notifyOfAppActivity);
};
