/* @flow */

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import LayoutServicePage from 'components/mui/Layouts/LayoutServicePage';

import { styled } from '@mui/material/styles';

import bgImage from './assets/bgImage.svg';

const Img = styled('img')(({ theme }) => ({
  marginBottom: theme.spacing(4),
}));

export type Props = {};

class CrashPage extends Component<Props> {
  reloadPage = (): void => {
    window.location.reload(true);
  };

  render() {
    return (
      <>
        <CssBaseline />
        <LayoutServicePage>
          <Stack component="main" flex={1} justifyContent="center" alignItems="center">
            <Img src={bgImage} alt="background image" />
            <Typography variant="h5" sx={{ mb: 0.5 }}>
              <FormattedMessage id="crashPage.mainMessage" defaultMessage="Something went wrong...(" />
            </Typography>
            <Typography variant="body1" color="text.secondary" sx={{ mb: 3 }}>
              <FormattedMessage
                id="crashPage.supportMessage"
                defaultMessage="We weren't able to do that. Please, try to refresh this page"
              />
            </Typography>
            <Button color="primary" onClick={this.reloadPage}>
              <FormattedMessage id="crashPage.refreshButton" defaultMessage="Refresh page" />
            </Button>
          </Stack>
        </LayoutServicePage>
      </>
    );
  }
}

export default CrashPage;
