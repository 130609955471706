// @flow

import { call, select, takeEvery } from 'redux-saga/effects';
import type { Saga } from 'redux-saga';
import * as env from 'domain/env';
import * as companies from 'domain/companies';
import { ensureWorkerGetMessagesCreator } from 'domain/messages/sagas';
import { MultyWorker } from 'lib/worker';
import { resetMessages } from 'domain/organization';

function* startMessasgeWorker(worker) {
  const isAuthorizedSelector = yield select(env.isAuthorizedSelector);
  if (isAuthorizedSelector) {
    yield call(worker.init, ensureWorkerGetMessagesCreator());
    yield call(worker.listen);
  }
}

const startWorker = (worker: any) =>
  function* signInGen(): Saga<*> {
    yield call(startMessasgeWorker, worker);
  };

const stopWorker = (worker: any) => () => {
  worker.terminate('getMessages');
};

export default function* watchMessage(dispatch): Saga<*> {
  const worker = new MultyWorker(dispatch);

  // log in company
  yield takeEvery(companies.companySignInAction.success, stopWorker(worker));
  yield takeEvery(companies.companySignInAction.success, startWorker(worker));

  yield takeEvery(resetMessages.type, stopWorker(worker));
  yield takeEvery(resetMessages.type, startWorker(worker));

  yield takeEvery(env.signInAction.success, startWorker(worker));
  yield takeEvery(env.signOutAction.success, stopWorker(worker));

  yield call(startMessasgeWorker, worker);
}
