/* eslint-disable camelcase */
// @flow
import React from 'react';
import moment from 'moment';
import type { Set } from 'immutable';
import cx from 'classnames';
import Message, { type MetaType } from 'components/Form/Message';
import { ERRORS, type ErrorsType } from './helper';

type Props = {
  classes: {
    [key: string]: string,
  },
  name: 'from' | 'to',
  format: string,
  onComplete: ({ [key: string]: Date, enteredTo?: ?Date, invalid: Set<ErrorsType> }) => void,
  // eslint-disable-next-line react/no-unused-prop-types
  value: ?Date,
  invalid: Set<ErrorsType>,
};

type State = {
  value: string,
};

class InputElement extends React.Component<Props, State> {
  static defaultProps = {
    format: 'MM/DD/YY',
  };

  static getValue(props: Props) {
    const date = moment(props.value);
    return props.value && date.isValid() ? date.format(props.format) : '';
  }

  state = {
    value: this.constructor.getValue(this.props),
  };

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    this.setState({
      value: this.constructor.getValue(nextProps),
    });
  }

  get meta(): $Exact<MetaType> {
    const { name, invalid } = this.props;
    return {
      touched: invalid.has(name),
      error: 'invalidDate',
    };
  }

  handleChange = (e: SyntheticInputEvent<HTMLInputElement>) => {
    this.setState({
      value: e.target.value,
    });
  };

  handleBlur = () => {
    const { format, name, onComplete, invalid } = this.props;
    const date = moment(this.state.value, format);
    if (date.isValid()) {
      const d = date.toDate();
      onComplete({
        [name]: d,
        enteredTo: name === 'to' ? d : null,
        invalid: invalid.delete(name),
      });
    } else if (this.state.value.length) {
      onComplete({
        invalid: invalid.add(name),
      });
    }
  };

  render() {
    const { classes, invalid, name } = this.props;
    return (
      <div className={classes.inputField}>
        <input
          type="text"
          name={this.props.name}
          className={cx(classes.date, {
            [classes.invalid]: invalid.has(name),
            [classes.invalid]: invalid.has(ERRORS.period),
          })}
          value={this.state.value}
          onChange={this.handleChange}
          onBlur={this.handleBlur}
          placeholder={this.props.format}
        />
        <Message meta={this.meta} className={classes.message} />
      </div>
    );
  }
}

export default InputElement;
