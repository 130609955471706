import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import { NOTE_PALETTE } from 'components/LabeledThemeProvider/notesPalette';

import { styled } from '@mui/material/styles';

export const NoteField = styled('textarea', { label: 'NoteWidgetTextarea' })(({ theme }) => ({
  height: '100%',
  width: '100%',
  border: 'none',
  resize: 'none',
  outline: 'none',
  padding: theme.spacing(0.5),
  fontFamily: theme.typography.fontFamily,
  '&::-webkit-scrollbar': {
    appearance: 'none',
    width: 7,
    height: 7,
  },
  '&::-webkit-scrollbar-thumb': {
    borderRadius: 3,
    backgroundColor: 'rgba(0,0,0,.5)',
    boxShadow: '0 0 1px rgba(255,255,255,.5)',
  },
}));

export const NotePaletteBar = styled(RadioGroup, { label: 'NoteWidgetPaletteBar' })(({ theme }) => ({
  padding: theme.spacing(0.5),
  flexDirection: 'row',
  flexWrap: 'nowrap',
  overflow: 'hidden',
  alignItems: 'center',
  gap: 4,
}));

export const NotePaletteRadio = styled(Radio, { label: 'NoteWidgetPaletteRadio' })(({ theme, value }) => ({
  border: '1px solid transparent',
  padding: 4,

  '& .MuiSvgIcon-root path': {
    stroke: NOTE_PALETTE[value].border,
  },

  '&.Mui-checked': {
    borderColor: theme.palette.primary.main,

    '& .MuiSvgIcon-root path': {
      strokeWidth: 0,
    },
  },
}));
