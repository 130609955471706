// @flow
import * as React from 'react';
import { DragSource } from 'react-dnd';
import { compose } from 'redux';
import { withStyles } from '@mui/styles';
import sourceSheet from './sourceSheet';
import cn from 'classnames';

type Props = {
  // eslint-disable-next-line react/no-unused-prop-types
  isDragging: boolean,
  isMiniThumbnail: boolean,
  connectDragSource: Function,
  connectDragPreview: Function,
  // eslint-disable-next-line react/no-unused-prop-types
  readOnly: boolean,
  // eslint-disable-next-line react/no-unused-prop-types
  id?: string, // (not sure) its needed for build data request when dnd document
  params?: { linkid?: string }, // its needed for build data request when dnd document
  className?: string,
  classes: { [key: string]: string },
  children: React$Node,
  onEndDrag?: () => void,
  onBeginDrag?: () => void,
  thumbnail?: string,
  isMultiplyDND?: boolean,
  linkID?: string, // its needed for build data request when move document
};

class DraggableSource extends React.PureComponent<Props> {
  previewRef: ?HTMLElement;

  componentDidMount(): * {
    const { connectDragPreview } = this.props;
    connectDragPreview(this.previewRef);
  }

  // TODO: DONT UNDERSTAND HOW ITS WORK: document, params - what is this condition? (display clip on thumbnail)
  get isLinkid() {
    const { children } = this.props;

    if (!(children && children.props)) return false;
    const { linkID, params } = this.props;
    return linkID && params && !params.linkid && linkID;
  }

  render() {
    const { connectDragSource, className, isDragging, children, classes, thumbnail, isMultiplyDND, isMiniThumbnail } =
      this.props;

    return connectDragSource(
      <div className={className} style={{ opacity: isDragging ? 0.3 : 1 }}>
        {children}
        {isMiniThumbnail ? (
          <div className={classes.wrapper}>
            <div
              className={cn(classes.preview, {
                // $FlowFixMe
                [classes.selected]: isMultiplyDND,
                [classes.linkid]: this.isLinkid,
              })}
              ref={(el) => {
                this.previewRef = el;
              }}
            >
              {
                // $FlowFixMe
                thumbnail ? <img className={classes.previewImg} loading="lazy" src={thumbnail} alt="" /> : null
              }
            </div>
          </div>
        ) : null}
      </div>,
    );
  }
}

const draggableSource = (itemType: string) => ({
  beginDrag(props: Props) {
    const { id, params = {}, onBeginDrag } = props;
    // eslint-disable-next-line no-unused-expressions
    onBeginDrag && onBeginDrag();
    return { id, ...params, itemType };
  },
  isDragging(props: Props, monitor) {
    return monitor.getItem().id === props.id;
  },
  canDrag(props: Props) {
    return !props.readOnly;
  },
  endDrag({ onEndDrag }: Props, monitor) {
    if (onEndDrag) {
      onEndDrag(monitor.getDropResult());
    }
  },
});

function collect(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),
    connectDragPreview: connect.dragPreview(),
  };
}

export const DnDItemSource = (name: string) =>
  compose(DragSource(name, draggableSource(name), collect), withStyles(sourceSheet))(DraggableSource);
