// @flow
import React, { useCallback, memo, useMemo, useRef } from 'react';
import pick from 'lodash/pick';

import Tooltip from 'components/mui/Tooltip';

type TShapeProps = {
  children: React$Node,
  // eslint-disable-next-line react/no-unused-prop-types
  handleDrag: (d: ClientRect, value: string, id: string) => void,
  handleContextMenu: () => void,
  handleMouseDown?: () => void,
  className: string,
  style: {
    width: number,
    height: number,
    left: number,
    top: number,
  },
  // eslint-disable-next-line react/no-unused-prop-types
  id: string,
  text: string,
};

const Polygon = ({
  children,
  className,
  style,
  handleDrag,
  id,
  text,
  handleContextMenu,
  handleMouseDown,
}: TShapeProps) => {
  const shapeRef = useRef(null);
  const isMouseDown = useRef(false);

  const refType = useMemo(() => (id === 'MULTI_SELECT_POLYGON' ? 'MULTI_POLYGON' : 'POLYGON'), [id]);

  // TODO: rewrite via useMemo maybe
  const getShapeBounding = useCallback(
    () => (shapeRef.current ? shapeRef.current.getBoundingClientRect() : null),
    [shapeRef],
  );

  const onMouseMove = useCallback(() => {
    if (isMouseDown.current) {
      const shapeBounding = getShapeBounding();
      isMouseDown.current = false;

      if (shapeBounding) {
        handleDrag(shapeBounding, text, id, refType);
      }
    }
  }, [id, text, handleDrag, refType, getShapeBounding]);

  // TODO: second variant for catch mousemove
  // const initMouseMoveListener = useCallback(() => {
  //   window.addEventListener('mousemove', onMouseMove);
  // }, [onMouseMove]);
  //
  // const removeMouseMoveListener = useCallback(() => {
  //   window.removeEventListener('mousemove', onMouseMove);
  // }, [onMouseMove]);

  const onMouseDown = useCallback(
    (e: MouseEvent) => {
      e.stopPropagation();

      isMouseDown.current = true;
      // initMouseMoveListener();

      if (typeof handleMouseDown === 'function') {
        // https://reactjs.org/blog/2020/08/10/react-v17-rc.html#changes-to-event-delegation
        // was changed behaviour of Event Delegation for react 17 and click on polygon stop propagate
        // but clickAwayListener cant catch event on close
        handleMouseDown();
      }
    },
    [handleMouseDown],
  );

  const onMouseUp = useCallback(() => {
    isMouseDown.current = false;
  }, []);

  const onContextMenu = useCallback(
    (e: MouseEvent) => {
      e.preventDefault();
      const shapeBounding = getShapeBounding();

      isMouseDown.current = false;

      if (typeof handleContextMenu === 'function') {
        handleContextMenu({ shapeBounding, text });
      }
    },
    [handleContextMenu, text, getShapeBounding],
  );

  const polygonStyles = useMemo(() => {
    const { width, height, top, left } = style;

    return {
      width,
      height,
      top,
      left,
      userSelect: 'none',
    };
  }, [style]);

  // useEffect(() => removeMouseMoveListener(), []);

  return (
    <div
      role="toolbar"
      draggable="false"
      className={className}
      ref={shapeRef}
      style={polygonStyles}
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
      onContextMenu={onContextMenu}
      onMouseMove={onMouseMove}
    >
      <Tooltip t={text} placement="top" disableInteractive>
        <div style={pick(polygonStyles, ['height', 'width'])}>{children}</div>
      </Tooltip>
    </div>
  );
};

export default memo(Polygon);
