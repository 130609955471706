/* @flow */
import { Record, List, type RecordFactory } from 'immutable';
import type { CategoriesStore, Categories, Category } from './types.js.flow';

// type rawCategories = Array<{|
//   id: string,
//   parent_id: string,
// |}>

// factories
export const CategoriesStoreFactory: RecordFactory<Categories> = new Record({
  list: new List(),
});

export const CategoryFactory: RecordFactory<Category> = new Record({
  id: null,
  parent_id: null,
  left: 0,
  right: 0,
  name: '',
  nameLangId: '',
  level: 1,
  count: 0,
  icon: null,
  children: List(),
  parentList: List(),
  documentMovable: false,
  order: null,
});

// adapters
export const categoriesStoreAdapter = (categories: Array<Category>): CategoriesStore => {
  const list = List(categories.map((category) => CategoryFactory(category)));
  return CategoriesStoreFactory({
    list,
  });
};
