/* @flow */
import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { is, Map } from 'immutable';
import { chatAllUsersByIdSelector } from 'domain/chat';
import { FormattedMessage } from 'react-intl';
import type { RecordOf } from 'immutable';
import type { EventsListType } from 'domain/events/types.js.flow';
import type { ChatUser } from 'domain/chat/types.js.flow';

import EventsHistoryReloadButton from 'components/mui/Layouts/components/EventsHistoryPanel/components/ReloadButton';
import EventsHistoryListItem from 'components/mui/Layouts/components/EventsHistoryPanel/components/EventsList/ListItem';
import { EventsListWrapper } from 'components/mui/Layouts/components/EventsHistoryPanel/components/EventsList/StyledComponents';
import List from '@mui/material/List';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';

import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import elements from 'components/elements';

type Props = {
  isNext: ?string,
  list: EventsListType,
  counter: number,
  dateFormat: string,
  onAction: (d: any) => void,
  getNextEvents: () => void,
  onReload: () => void,
  usersById: Map<string, RecordOf<ChatUser>>,
};

class EventsHistoryList extends React.Component<Props> {
  componentDidMount() {
    if (this.observer && this.container) {
      this.observer.observe(this.container);
    }
  }

  shouldComponentUpdate(nextProps: Props) {
    if (!is(nextProps.list, this.props.list)) return true;
    if (this.props.counter !== nextProps.counter) return true;
    if (this.props.isNext !== nextProps.isNext) return true;
    return false;
  }

  componentDidUpdate(prevProps: Props) {
    const { isNext } = this.props;
    if (isNext === null && this.observer && this.container) {
      this.observer.unobserve(this.container);
    }
    if (typeof isNext === 'string' && this.isIntersecting) {
      this.props.getNextEvents();
    }
    // aftter date selection isNext is undefined and observer must be
    // inittialized in this case as if it were null
    if ((prevProps.isNext === null || prevProps.isNext === undefined) && typeof isNext === 'string') {
      if (this.observer && this.container) {
        this.observer.observe(this.container);
      }
    }
  }

  componentWillUnmount(): * {
    if (this.observer) {
      this.observer.disconnect();
    }
  }

  get hasNext() {
    return this.props.isNext !== null;
  }

  getNext = (entries: $ReadOnlyArray<IntersectionObserverEntry>) => {
    const { isIntersecting, intersectionRect } = entries[0];
    this.isIntersecting = isIntersecting;
    if (isIntersecting && intersectionRect.width > 0) {
      this.props.getNextEvents();
    }
  };

  refProxy = (el: ?HTMLElement) => {
    this.container = el;
    if (el) {
      this.observer = new IntersectionObserver(this.getNext, {
        root: null,
        rootMargin: '100px',
        threshold: 0.1,
      });
    }
  };

  isIntersecting: boolean;

  observer: ?IntersectionObserver;

  container: ?HTMLElement;

  render() {
    const { isNext, list, usersById, onReload, counter, onAction, dateFormat } = this.props;

    return (
      <EventsListWrapper data-element={elements.history.list}>
        <EventsHistoryReloadButton onReload={onReload} counter={counter} />
        <List disablePadding>
          {list.toList().map((entry) => (
            <EventsHistoryListItem
              key={entry.id}
              entry={entry}
              onClick={onAction}
              dateFormat={dateFormat}
              src={usersById.getIn([entry.username, 'picture'])}
            />
          ))}
        </List>
        {list.size === 0 && isNext === null && (
          <Box m="auto" textAlign="center">
            <AssignmentOutlinedIcon />
            <Typography variant="body1">
              <FormattedMessage id="eventsHistory.panel.empty" defaultMessage="No history found" />
            </Typography>
          </Box>
        )}
        <Box ref={this.refProxy} display="flex" justifyContent="center" minHeight="20" py={2}>
          {this.hasNext && <CircularProgress />}
        </Box>
      </EventsListWrapper>
    );
  }
}

EventsHistoryList.displayName = 'EventsList';

const mapStateToProps = (state) => ({
  usersById: chatAllUsersByIdSelector(state),
});

const mapDispatchToProps = {};

export default compose(connect(mapStateToProps, mapDispatchToProps))(EventsHistoryList);
