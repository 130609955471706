/* @flow */
import { Record, List } from 'immutable';
import type {
  GoogleSearchStoreFactory,
  GoogleSearchItemFactory,
  GoogleSearchItems,
  GoogleSearchItemShape,
} from './types.js.flow';

export const googleSearchItemFactory: GoogleSearchItemFactory = new Record({
  title: '',
  link: '',
  snippet: '',
});

export const googleSearchFactory: GoogleSearchStoreFactory = new Record({
  items: new List(),
  googleSearchText: null,
  googlePanelStatus: false,
  isGoogleSearchLoading: false,
});

export function googleSearchItemsAdapter(items: Array<GoogleSearchItemShape>): GoogleSearchItems {
  return new List(items.map(googleSearchItemFactory));
}
