/* @flow */
import { alpha } from '@mui/material/styles';

export default (theme) => ({
  container: {
    position: 'relative',
    display: 'flex',
    width: '100%',
    marginBottom: 30,
    marginTop: 5,
  },

  fixedContainer: {
    position: 'relative',
    zIndex: 9,
  },
  activeRow: {},
  removeLine: {},
  removeBtn: {
    padding: 4,
    opacity: 0,
    transition: 'opacity 0.3s',
  },
  hovered: {},
  lineItems: {
    '&&$hovered, &:hover': {
      backgroundColor: '#F9F5E8',
      '& $removeBtn': {
        opacity: 1,
        cursor: 'pointer',
      },
    },
    '&$activeRow': {
      backgroundColor: '#E2EAFF',
      '& $removeBtn': {
        opacity: 1,
        cursor: 'pointer',
      },
    },
  },
  row: {
    '&:first-child': {
      '& $col': {
        backgroundColor: theme.colors.greyLighter,
      },
    },
    '&:not(:first-child) $col:hover': {
      '&:hover': {
        backgroundColor: '#F8F8F8',
      },
    },
  },
  col: {
    borderBottom: '1px solid #DEE2E6',
    minWidth: 170,
    width: '50%',
    '&::before': {
      content: '""',
      position: 'absolute',
      top: -1,
      left: -1,
      bottom: 2,
      borderRight: '1px solid #DEE2E6',
      zIndex: 0,
    },
  },
  mandatory: {
    border: `1px solid ${theme.palette.error.main}`,
    '&:before': {
      display: 'none',
    },
  },
  active: {
    border: `1px solid ${theme.palette.primary.light}`,
    // borderRadius: 2,
    '&:before, & + $col:before': {
      display: 'none',
    },
  },
  fixed: {
    flex: [0, 0],
    paddingBottom: 2,
    '& $row:first-child $col:first-child': {
      background: 'transparent',
    },
  },

  scroll: {
    '&::-webkit-scrollbar': {
      appearance: 'none',
      height: 7,
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: 4,
      backgroundColor: alpha(theme.palette.common.black, 0.5),
      boxShadow: `0 0 1px ${alpha(theme.palette.common.white, 0.5)}`,
    },
  },
  tableCol: {
    width: 170,
  },
  tableRemove: {
    width: 30,
  },
  fixedColumn: {
    '&:nth-child(2):before': {
      display: 'none',
    },
  },

  gridBox: {
    width: '100%',
    display: 'flex',
  },

  pinnedBox: {
    flex: [1, 1],
    position: 'relative',
    maxWidth: '70%',
    '&:after': {
      zIndex: 8,
      content: '""',
      position: 'absolute',
      display: 'block',

      right: 0,
      top: 0,
      bottom: 9,
      width: 3,
      backgroundColor: theme.palette.grey[200],
      borderLeft: `1px solid ${theme.palette.grey[300]}`,
    },
  },

  pinnedScrollBox: {
    extend: 'scroll',
    width: 'auto',
    overflow: 'hidden',
    overflowX: 'auto',
    padding: [0, 3],
  },

  fixX: {
    width: '100px',
    overflow: 'hidden',
  },
});
