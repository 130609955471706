// @flow
import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import qs from 'qs';

import Dialog from 'components/mui/Dialog';
import Form from './components/Form';
import Spinner from 'components/mui/CircularProgressWithBackdrop';

import { sendDocumentByEmailAction } from 'domain/documents/documentsActions';
import { userIdSelector, userNamesSelector } from 'domain/env';
import { chatParticipantsSelector } from 'domain/chat';
import Api from 'domain/api';

import { storage } from 'lib/storage';

type Props = {
  documentIds?: Array<string>,
  linkID?: string,
  onClose: () => void,
};

const DialogSendByEmail: React$StatelessFunctionalComponent<Props> = ({ documentIds = [], onClose, linkID = '' }) => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();

  const [emailsFromBE, setEmailsFromBE] = useState([]);
  const [isFetching, setIsFetching] = useState(false);

  const { firstName, lastName } = useSelector(userNamesSelector);
  const email = useSelector(userIdSelector);
  const users = useSelector(chatParticipantsSelector);

  const emailSuggestion = JSON.parse(storage().emailSuggestion().get() || '[]');
  const options = users.toJS().map(({ userId }) => userId);
  const emails = useMemo(
    () => [...new Set([...options, ...emailSuggestion, ...emailsFromBE])],
    [options, emailSuggestion, emailsFromBE],
  );
  const memoUsers = useMemo(() => users.reduce((res, user) => ({ ...res, [user.userId]: user }), {}), [users]);

  const initialValues = useMemo(
    () => ({
      subject: formatMessage(
        {
          id: 'form.dialog.sendByEmail.subject.default',
          defaultMessage: 'A document has been forwarded to you',
        },
      ),
      description: formatMessage(
        {
          id: 'form.dialog.sendByEmail.description.default',
          defaultMessage: `Hi,\nI have something for you to review.\nTo see the document open the attached file.\n\nBest Regards\n${firstName} ${lastName} (${email})`,
        },
        { username: `${firstName} ${lastName} (${email})` },
      ),
    }),
    [firstName, lastName, email, formatMessage],
  );

  const onSubmit = useCallback(
    (data) =>
      new Promise((resolve, reject) => {
        const to = data.get('to') || [];
        const cc = data.get('cc') || [];
        const allUniqueEmails = [...new Set([...to, ...cc])];
        const newEmailsForSuggestion = allUniqueEmails.filter((value) => !emails.includes(value));
        const emailsForLS = newEmailsForSuggestion.length ? [...emailSuggestion, ...newEmailsForSuggestion] : [];

        const getDataForSending = () => {
          const subject = data.get('subject');
          return initialValues.subject === subject
            ? data.set(
                'subject',
                `${formatMessage({
                  id: 'form.dialog.sendByEmail.subject.doNotReply',
                  defaultMessage: '[Do not reply]',
                })} ${subject}`,
              )
            : data;
        };

        dispatch(
          sendDocumentByEmailAction({
            ...getDataForSending().toJS(),
            documentIds,
            linkID,
            emailsForLS,
            resolve,
            reject,
          }),
        );
      }).then(onClose),
    [documentIds, linkID, dispatch, emails, emailSuggestion, onClose, initialValues.subject, formatMessage],
  );

  useEffect(() => {
    const fetchData = async () => {
      const params = {
        document_ids: documentIds,
        linkID,
      };
      const response = await Api.getEmailsForAutocomplete({
        params,
        paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
      });
      setEmailsFromBE(response.data?.emails || []);
    };
    setIsFetching(true);
    fetchData().finally(() => setIsFetching(false));
  }, [documentIds, linkID]);

  return (
    <Dialog
      open
      onClose={onClose}
      title={formatMessage({ id: 'form.dialog.sendByEmail.title', defaultMessage: 'Send a new email' })}
      maxWidth="sm"
      withActions={false}
      withContent={false}
    >
      {isFetching && <Spinner isOpen={isFetching} BackdropProps={{ sx: { position: 'absolute' } }} />}
      <Form
        onSubmit={onSubmit}
        onClose={onClose}
        emailOptions={emails}
        users={memoUsers}
        initialValues={initialValues}
      />
    </Dialog>
  );
};
export default DialogSendByEmail;
