// @flow
import { compose } from 'redux';
import { rules, addError } from 'components/Form/validation';
import type { Map } from 'immutable';

type Values = Map<string, string | number>;

const ftpConnectionValidator = (values: Values) => (errors, fieldName) => {
  const val = values.get(fieldName, '');
  switch (fieldName) {
    case 'address':
      return compose(addError(fieldName, rules.required(val)))(errors);

    case 'login':
      return compose(addError(fieldName, rules.required(val)))(errors);

    case 'password':
      return compose(addError(fieldName, rules.required(val)))(errors);

    default:
      return errors;
  }
};

export const ftpConnectionValidate = (values: Values) =>
  ['address', 'login', 'password'].reduce(ftpConnectionValidator(values), {});
