// @flow
import React, { useState } from 'react';
import { chatUserSelector } from 'domain/env';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import UserPicEditor from 'components/UserPic/UserPicEditor';
import TextFieldBase from 'components/mui/Form/TextField/TextFieldBase';
import Dialog from 'components/mui/Dialog';
import Avatar from 'components/mui/Avatar';
import DropdownButton from 'components/mui/DropdownButton';
import AddPhotoAlternateOutlinedIcon from '@mui/icons-material/AddPhotoAlternateOutlined';
import InsertPhotoOutlinedIcon from '@mui/icons-material/InsertPhotoOutlined';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import Box, { type BoxProps as TBoxProps } from '@mui/material/Box';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';

import { makeStyles } from '@mui/styles';
import { alpha } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  iconButton: {
    width: 152,
    height: 152,
    position: 'relative',
    '& svg': {
      display: 'none',
    },
    '&:hover, &:active, &:focus': {
      '& svg': {
        display: 'inline-block',
      },
      '&::after': {
        content: '""',
        position: 'absolute',
        width: '100%',
        height: '100%',
        backgroundColor: alpha(theme.palette.primary.main, 0.5),
        borderRadius: '50%',
      },
    },
  },
  userPicEditor: {
    width: 500,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    flexDirection: 'column',
  },
  popupTitle: {
    display: 'flex',
    color: theme.colors.greyBlack,
    fontSize: 24,
    margin: [30, 0, 30, 0],
    fontWeight: 'bold',
    width: '90%',
  },
}));

type Props = {
  label: {
    id: string,
    defaultMessage: string,
  },
  onChange: (event: KeyboardEvent, newValue: string, oldValue: string, name: string) => void,
  name: string,
  value: string,
  accept: string,
  input: {
    value: string,
    onChange: (event: KeyboardEvent, newValue: string, oldValue: string, name: string) => void,
    name: string,
  },
  mark: string,
  required?: boolean,
  disabled?: boolean,
  capital?: boolean,
  onUpload: () => void,
  picture?: string,
  userName: string,
  BoxProps: TBoxProps,
};

const FileUserPic: React$StatelessFunctionalComponent<Props> = ({
  picture,
  accept,
  name,
  disabled,
  onUpload,
  BoxProps,
}) => {
  const [selectedPicture, setSelectedPicture] = useState(null);
  const [editorOpen, setEditorOpen] = useState(false);
  const [previewOpen, setPreviewOpen] = useState(false);
  const classes = useStyles();

  const openPreview = () => setPreviewOpen(true);
  const closePreview = () => setPreviewOpen(false);

  const userName = useSelector(chatUserSelector);

  const { formatMessage } = useIntl();

  const setEditorPicFromSelectedFile = (file: File): void => {
    const reader = new window.FileReader();
    reader.onload = () => {
      setEditorOpen(true);
      setSelectedPicture(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const onChange = (event: Event) => {
    event.preventDefault();
    const file = event.target.files[0];
    if (file) {
      setEditorPicFromSelectedFile(file);
    }
  };

  const getPicture = () => selectedPicture || picture || '';

  const saveCroppedPic = (canvas: HTMLCanvasElement) => {
    setEditorOpen(false);
    setSelectedPicture(canvas.toDataURL());
    // creates blob from canvas that can be used with formData in saga
    canvas.toBlob(
      (blob) => {
        onUpload(blob);
      },
      'image/jpeg',
      95,
    );
  };

  const toggleModal = () => {
    setEditorOpen(!editorOpen);
    setSelectedPicture(null);
  };

  const menuOptions = [
    {
      title: {
        id: 'profile.avatar.options.showPhoto',
        defaultMessage: 'Show photo',
      },
      icon: <InsertPhotoOutlinedIcon />,
      key: 'showPhoto',
      value: 'showPhoto',
      disabled: !getPicture(),
      onClick: () => openPreview(),
    },
    {
      title: {
        id: 'profile.avatar.options.uploadPhoto',
        defaultMessage: 'Upload photo',
      },
      icon: <CloudUploadOutlinedIcon />,
      key: 'uploadPhoto',
      value: 'uploadPhoto',
      htmlFor: 'fileInput',
      onClick: () => {},
    },
    // {
    //   title: {
    //     id: 'avatar.options.deletePhoto',
    //     defaultMessage: 'Delete photo',
    //   },
    //   icon: <DeleteOutlineOutlinedIcon />,
    //   key: 'deletePhoto',
    //   value: 'deletePhoto',
    //   disabled: true,
    //   onClick: () => console.log('delete photo'),
    // },
  ];

  return (
    <Box mb={5} {...BoxProps}>
      <TextFieldBase
        id="fileInput"
        type="file"
        multiple={false}
        disabled={disabled}
        name={name}
        inputProps={{ accept }}
        onChange={onChange}
        sx={{ display: 'none' }}
      />
      <DropdownButton
        options={menuOptions}
        iconButtonClassName={classes.iconButton}
        placement="right-start"
        popperModifiers={[
          {
            name: 'offset',
            options: {
              offset: [95, -40],
            },
          },
        ]}
        BtnIcon={
          <div style={{ position: 'relative' }}>
            <Avatar src={getPicture()} size={152} name={userName} />
            <AddPhotoAlternateOutlinedIcon
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                margin: 'auto',
                color: '#FFF',
                zIndex: 2,
              }}
            />
          </div>
        }
      />
      <Dialog
        open={editorOpen}
        onClose={toggleModal}
        withActions={false}
        title={
          <Typography variant="h6">
            {formatMessage({
              id: 'forms.profile.main.general.pictureEditor.title',
              defaultMessage: 'Edit user picture',
            })}
          </Typography>
        }
      >
        <UserPicEditor image={getPicture()} onCropFinished={saveCroppedPic} />
      </Dialog>
      <Dialog
        open={previewOpen}
        onClose={closePreview}
        title={<Typography variant="h6">{userName}</Typography>}
        withActions={false}
        withCloseBtn
      >
        <DialogContent>
          <img src={picture} alt={userName} style={{ maxWidth: '100%', maxHeight: '100%' }} />
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default FileUserPic;
