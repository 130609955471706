/* @flow */
import * as React from 'react';
import { withStyles } from '@mui/styles';
import cx from 'classnames';
import { type List, type RecordOf, is, Record, type RecordFactory } from 'immutable';
import Select, { type ActyonMeta, type FormatMeta, type IFilterOption } from 'components/Form/Select';
import TranslationField from './field';
import sheet from './sheet';
import { filter } from './helpers';
import { emailReg } from 'components/Form/validation';
import elements from 'components/elements';
import colors from 'styles/dokkaTheme/colors';

const field = [
  {
    label: {
      id: 'requestTransaction.recipientEmail',
      defaultMessage: 'Email',
    },
  },
  {
    label: {
      id: 'requestTransaction.name',
      defaultMessage: 'Name',
    },
  },
];

export interface IUser {
  username: string;
  userId: string;
}

type Props = {
  classes: {|
    [key: string]: string,
  |},
  users: List<RecordOf<IUser>>,
  onChange: (user: RecordOf<IUser>) => void,
};

type State = {
  recipient: RecordOf<IUser> | void,
};

function getColor({ isFocused }) {
  if (isFocused) {
    return {
      background: colors.primaryLight,
      color: '#ffffff',
    };
  }
  return {
    background: '#ffffff',
    color: '#171A1C',
  };
}

const styles = {
  control: (provider, state) => ({
    ...provider,
    boxShadow: 'none',
    borderRadius: 0,
    border: '0 none',
    borderBottom: state.selectProps.hasError ? '1px solid #BC5353' : '1px solid #DEE2E6',
    height: 30,
    minHeight: 30,
    '&:hover': {
      borderColor: state.selectProps.hasError ? '#BC5353' : '#DEE2E6',
    },
  }),
  option: (provided, { isFocused }) => ({
    ...provided,
    ':active': {
      backgroundColor: colors.primaryLight,
      color: '#FFF',
    },
    whiteSpace: 'nowrap',
    unicodeBidi: 'plaintext',
    ...getColor({ isFocused }),
    cursor: 'pointer',
  }),
};

export const UserFactory: RecordFactory<IUser> = new Record({
  username: '',
  userId: '',
});

class RecipientParams extends React.Component<Props, State> {
  state = {
    recipient: undefined,
  };

  getOptionValue = ({ userId }: RecordOf<IUser>) => userId;

  handleChange = (recipient: RecordOf<IUser>) => {
    this.setState({ recipient });
  };

  // eslint-disable-next-line max-len
  handleChangeWithCallback = (cb: (recipient: RecordOf<IUser>) => void) => (recipient: RecordOf<IUser>) => {
    this.setState({ recipient }, () => cb(recipient));
  };

  handledropdownChange = (recipient: RecordOf<IUser>) => {
    this.setState({ recipient }, () => this.props.onChange(recipient));
  };

  handleChangeInput = (value: string, actionMeta: ActyonMeta) => {
    if (actionMeta.action === 'input-change') {
      this.creatingOption = value;
    }
    if (actionMeta.action === 'input-blur') {
      const userId = this.creatingOption;
      const { recipient } = this.state;
      if (typeof userId === 'string') {
        const option = recipient ? recipient.set('userId', userId) : UserFactory({ userId });
        this.handleChange(option);
      }
      this.creatingOption = undefined;
    }
    if (actionMeta.action === 'set-value') {
      this.creatingOption = undefined;
    }
  };

  handleChangeName =
    () =>
    (e: SyntheticEvent<HTMLInputElement>): void => {
      const { recipient } = this.state;
      if (recipient) {
        const r = recipient.set('username', e.currentTarget.value);
        this.handleChange(r);
      } else {
        this.handleChange(UserFactory({ username: e.currentTarget.value }));
      }
    };

  handleBlur =
    () =>
    (e: SyntheticEvent<HTMLInputElement>): void => {
      const { recipient } = this.state;
      if (recipient) {
        const r = recipient.set('username', e.currentTarget.value);
        this.handleChangeWithCallback(this.props.onChange)(r);
      } else {
        this.handleChangeWithCallback(this.props.onChange)(UserFactory({ username: e.currentTarget.value }));
      }
    };

  // eslint-disable-next-line max-len
  formatOptionLabel = ({ username, userId }: RecordOf<IUser>, { context }: FormatMeta) => {
    if (context === 'menu') {
      return `${username} (${userId})`;
    }
    return userId;
  };

  // eslint-disable-next-line max-len
  filterOption = ({ data }: IFilterOption<RecordOf<IUser>>, term: string): boolean => filter(data, term);

  isOptionSelected = (o: RecordOf<IUser>) => is(this.state.recipient, o);

  creatingOption: string | void;

  render() {
    const { classes } = this.props;
    const { recipient } = this.state;
    return (
      <ul className={classes.list}>
        <li className={classes.item} data-element={elements.je.request.selectRecipients}>
          <TranslationField classes={classes} {...field[0].label}>
            <Select
              hasError={!this.state.recipient || !emailReg.test(this.state.recipient.userId)}
              id={field[0].label.id}
              isClearable
              styles={styles}
              name="email"
              placeholder=""
              options={this.props.users}
              onChange={this.handleChangeWithCallback(this.props.onChange)}
              getOptionValue={this.getOptionValue}
              value={this.state.recipient}
              isOptionSelected={this.isOptionSelected}
              className={classes.field}
              onInputChange={this.handleChangeInput}
              formatOptionLabel={this.formatOptionLabel}
              filterOption={this.filterOption}
            />
          </TranslationField>
        </li>
        <li className={classes.item} data-element={elements.je.request.name}>
          <TranslationField classes={classes} {...field[1].label}>
            <input
              id={field[1].label.id}
              type="text"
              name="name"
              className={cx(classes.field, {
                [classes.inputError]: !recipient || recipient.username.length === 0,
              })}
              value={(recipient && recipient.username) || ''}
              onChange={this.handleChangeName()}
              onBlur={this.handleBlur()}
            />
          </TranslationField>
        </li>
      </ul>
    );
  }
}

export default withStyles(sheet)(RecipientParams);
