// @flow
import { OrderedMap, Record, List, Map, type RecordFactory } from 'immutable';
import type { ERPS, ERPSettingFTPConnection, object } from './settingsModel';
import {
  keys,
  type ERPSettingBoolean,
  type ERPSettingText,
  type ERPSettingList,
  type ERPSettingDynamicList,
  type RawERPSettings,
  type ERPSettings,
  type ERPSettingFloat,
  type ERPSettingNumber,
  type ERPSettingInt,
  type ERPSettingsTimeSelector,
  settingsTypes,
} from './settingsModel';

export const erpsUpdateDataAdapter = (data: { [key: string]: object }, stateErps: ERPS) =>
  keys.reduce(
    (
      result,
      key, // eslint-disable-line
    ) => (data[key] ? { ...result, [key]: { ...data[key], details: OrderedMap(data[key]) } } : result),
    stateErps,
  );

export const ERPSettingsBooleanFactory: RecordFactory<ERPSettingBoolean> = new Record({
  group: null,
  name: '',
  label: '',
  value: false,
  type: 'boolean',
});

export const ERPSettingsTextFactory: RecordFactory<ERPSettingText> = new Record({
  group: null,
  name: '',
  label: '',
  value: '',
  type: 'text',
});

export const ERPSettingsFloatFactory: RecordFactory<ERPSettingFloat> = new Record({
  group: null,
  name: '',
  label: '',
  value: '',
  type: 'float',
});

export const ERPSettingsNumberFactory: RecordFactory<ERPSettingNumber> = new Record({
  group: null,
  name: '',
  label: '',
  value: '',
  type: 'number',
});

export const ERPSettingsIntFactory: RecordFactory<ERPSettingInt> = new Record({
  group: null,
  name: '',
  label: '',
  value: '',
  max: null,
  min: null,
  type: 'int',
});

export const ERPSettingsListFactory: RecordFactory<ERPSettingList> = new Record({
  group: null,
  name: '',
  label: '',
  value: '',
  options: new Map(),
  type: 'list',
  deletable: false,
});

export const ERPSettingsDynamicListFactory: RecordFactory<ERPSettingDynamicList> = new Record({
  group: null,
  name: '',
  label: '',
  value: '',
  display_value: '',
  options_function: '',
  type: 'dynamic_list',
  deletable: false,
});

export const ERPSettingsFileFactory = new Record({
  group: null,
  label: '',
  name: '',
  type: 'file',
  formats: new List(),
  value: '',
});

export const ERPSettingsFTPConnectionFactory: RecordFactory<ERPSettingFTPConnection> = new Record({
  group: null,
  label: '',
  mandatory: false,
  name: '',
  type: 'ftp_connection',
  value: '',
});

export const ERPSettingsTimeSelectorFactory: RecordFactory<ERPSettingsTimeSelector> = new Record({
  group: null,
  label: '',
  mandatory: false,
  indexes_update_date: null,
  is_sync_running: false,
  name: '',
  type: 'indexes_sync_time',
  value: {
    hours: 0,
    timezone: '',
  },
});

export const currentERPSettingsAdapter = (data: RawERPSettings): ERPSettings => {
  // eslint-disable-next-line no-bitwise
  const allowedSettings = data.filter((item) => ~Object.keys(settingsTypes).indexOf(item.type));
  // $FlowFixMe it's exhaustive!
  return List(
    // eslint-disable-next-line array-callback-return
    allowedSettings.map((settingsItem) => {
      // eslint-disable-next-line default-case
      switch (
        settingsItem.type // eslint-disable-line default-case
      ) {
        case 'boolean':
          return ERPSettingsBooleanFactory(settingsItem);
        case 'text':
          return ERPSettingsTextFactory(settingsItem);
        case 'float':
          return ERPSettingsFloatFactory(settingsItem);
        case 'number':
          return ERPSettingsNumberFactory(settingsItem);
        case 'int':
          return ERPSettingsIntFactory(settingsItem);
        case 'list': {
          const listSettingRecord = ERPSettingsListFactory(settingsItem);
          if (settingsItem.options) {
            return listSettingRecord.set('options', Map(settingsItem.options));
          }
          return listSettingRecord;
        }
        case 'dynamic_list':
          return ERPSettingsDynamicListFactory(settingsItem);
        case 'file':
          return ERPSettingsFileFactory(settingsItem);
        case 'ftp_connection':
          return ERPSettingsFTPConnectionFactory(settingsItem);
        case 'indexes_sync_time':
          return ERPSettingsTimeSelectorFactory(settingsItem);
      }
    }),
  );
};

export const isCompanyConnected = (erps) => erps.valueSeq().some((erp) => erp.status === 'connected');
