// @flow
import React, { useState, useRef, useCallback } from 'react';
import { withStyles } from '@mui/styles';
import sheet from './sheet';

import type { VirtualSelectProps } from '../VirtualSelect';
import VirtualSelect from '../VirtualSelect';

export type Params = {
  [key: string]: any,
};

export type GetOptionListParams = {|
  search?: string,
  pageSize?: number,
  pageToken?: string,
|};

export type VirtualSelectAsyncProps = {|
  ...VirtualSelectProps,
  pageLimit?: number,
  loadData: (params: GetOptionListParams) => Promise<any[], string>,
  defaultLabel: string,
  disabled?: boolean,
|};

const VirtualSelectAsync = ({ loadData, pageLimit = 50, ...vsProps }: VirtualSelectAsyncProps) => {
  const searchTextRef = useRef(null);
  const [options, setOptions] = useState([]);
  const [nextPageToken, setNextPageToken] = useState(null);
  const [isLoading, setLoadingStatus] = useState(false);

  const loadListData = useCallback(
    (isNew: boolean = false) => {
      if (!isLoading && !(options.length !== 0 && !nextPageToken)) {
        setLoadingStatus(true);
        loadData({
          search: searchTextRef.current || '',
          pageSize: pageLimit,
          pageToken: isNew ? null : nextPageToken,
        })
          .then(({ options: newOptions, pageToken }) => {
            setOptions(isNew ? newOptions : [...options, ...newOptions]);
            setNextPageToken(pageToken);
          })
          .finally(() => {
            setLoadingStatus(false);
          });
      }
    },
    [pageLimit, nextPageToken, isLoading, options, loadData],
  );

  const onRender = useCallback(
    (from) => {
      if (from > options.length - pageLimit / 2) {
        loadListData();
      }
    },
    [pageLimit, options, loadListData],
  );

  const onSearch = useCallback(
    (searchText) => {
      searchTextRef.current = searchText;
      loadListData(true);
    },
    [loadListData],
  );

  const onOpen = useCallback(() => {
    loadListData(true);
  }, [loadListData]);

  const onClose = useCallback(() => {
    searchTextRef.current = null;
    setNextPageToken(null);
    setOptions([]);
  }, []);

  return (
    <VirtualSelect
      {...vsProps}
      options={options}
      onVirtualListRender={onRender}
      onListOpen={onOpen}
      onListClose={onClose}
      onSearch={onSearch}
      isLoading={isLoading}
      isFilterOptionByTerm={false}
    />
  );
};

export default withStyles(sheet)(VirtualSelectAsync);
