import React from 'react';
import Draggable from 'react-draggable';
import Paper from '@mui/material/Paper';

import type { PaperProps } from '@mui/material/Paper';

const PaperComponent = (props: PaperProps) => (
  <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
    <Paper {...props} />
  </Draggable>
);

export default PaperComponent;
