export default {
  btn: {
    width: '100%',
    border: '0 none',
    background: '0 none',
    outline: 'none',
  },
  content: {
    overflow: 'hidden',
    height: 0,
    transition: '0.3s height',
    willChange: 'height',
  },
  open: {
    height: 'auto',
  },
  container: {
    outline: 'none',
  },
};
