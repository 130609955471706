// @flow
import React from 'react';
import { FormattedMessage } from 'react-intl';

import toast from 'components/Toast';

function insertPdfIframeForPrint(url, id) {
  const iframe = document.createElement('iframe');
  iframe.style.cssText = 'display: none;';
  iframe.id = `dokka-print-frame-${id}`;
  iframe.src = url;
  iframe.onload = () => {
    iframe.focus();
    iframe.contentWindow.print();
  };
  document.body.appendChild(iframe);
}

export function onPrint(pdfFile: Blob, id: string, protect: boolean) {
  const url = window.URL.createObjectURL(pdfFile);
  if (protect) {
    const win = window.open(url, '_blank');
    if (win) {
      win.onload = () => {
        win.print();
        win.onfocus = () => win.close();
      };
    }
  } else {
    insertPdfIframeForPrint(url, id);
  }
}

export default async function print(url: string, id: string, protect: boolean) {
  try {
    await window.fetch(url).then((response) => {
      if (response.ok) {
        return response.arrayBuffer().then((data) => {
          const pdfFile = new window.Blob([data], {
            type: 'application/pdf',
          });
          onPrint(pdfFile, id, protect);

          return pdfFile;
        });
      }
      throw new Error(`${response.statusText} - ${response.status}`);
    });
  } catch (error) {
    console.error('CATCH PRINT ONE ERROR: ', error.toString());

    toast.error(
      <FormattedMessage
        id="selectedPanel.toast.print.fetch.error"
        defaultMessage="We encountered a printing issue, please try again"
      />,
    );
  }
}

export function removePrintFramesFromDOM() {
  const nodes = Array.from(document.querySelectorAll('iframe[id^=dokka-print-frame-]'));
  if (nodes.length > 0) nodes.map((node) => node.remove());
}
