// @flow
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { chatParticipantsByIdSelector, setActiveChannelForceAction } from 'domain/chat';
import { getParamsFromChannelName } from 'sendbird-utils';
import ROUTES_PATH from 'domain/router/routesPathConfig';
import { generatePath } from 'react-router-dom';
import type { RecordOf } from 'immutable';
import type { MessageType } from 'domain/chat/types';

import Avatar from 'components/mui/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import RouterLink from 'components/mui/Router/RouterLink';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import CloseIcon from '@mui/icons-material/Close';

import { blue } from '@mui/material/colors';
import { css } from '@emotion/css';

export const ChatToastClasses = {
  wrapper: css({
    alignItems: 'center',
    fontFamily: 'inherit !important',
    boxShadow:
      '0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12) !important',
  }),
  progress: css({ backgroundColor: `${blue[900]} !important`, opacity: '100 !important', height: '2px !important' }),
};

type TChatToastClose = {
  closeToast: () => void,
};

export const ChatToastClose: React$StatelessFunctionalComponent<TChatToastClose> = ({ closeToast }) => (
  <IconButton onClick={closeToast} sx={{ mx: 0.5 }}>
    <CloseIcon />
  </IconButton>
);

type TChatToast = {
  channelName: string,
  message: RecordOf<MessageType>,
  group?: boolean,
  companyName: ?string,
};

const ChatToast: React$StatelessFunctionalComponent<TChatToast> = ({ channelName, message, group, companyName }) => {
  const dispatch = useDispatch();
  const usersByID = useSelector(chatParticipantsByIdSelector);
  const { formatMessage } = useIntl();

  const user = usersByID.get(message.sender.userId);
  const userName = user ? user.username : message.sender.nickname;
  const name = typeof companyName !== 'undefined' ? [userName, companyName].join(', ') : userName;
  const channelParams = getParamsFromChannelName(channelName);

  const handleClick = () => {
    dispatch(
      setActiveChannelForceAction({
        threadId: channelName,
        companyId: channelParams.companyId,
      }),
    );
  };

  return (
    <Box display="flex">
      <List disablePadding>
        <ListItem>
          <ListItemAvatar>
            <Avatar src={usersByID.getIn([message.sender.userId, 'picture'])} />
          </ListItemAvatar>

          <ListItemText disableTypography primaryTypographyProps={{ sx: { color: 'text.primary' } }}>
            <Typography variant="body2" color="text.primary" fontWeight={500}>
              {name}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {formatMessage({
                id: 'chat.newMessage',
                defaultMessage: 'New message',
              })}
            </Typography>
            {group && (
              <Link
                component={RouterLink}
                href={{
                  pathname: generatePath(ROUTES_PATH.DOCUMENT.absolute, channelParams),
                }}
                onClick={handleClick}
                underline="hover"
                sx={{ fontSize: 14, textTransform: 'uppercase', fontWeight: 500 }}
              >
                {formatMessage({ id: 'chat.toasts.documentLink', defaultMessage: 'Open document' })}
              </Link>
            )}
          </ListItemText>
        </ListItem>
      </List>
    </Box>
  );
};
export default ChatToast;
