// @flow
import queryString from 'query-string';

export type Query = {
  [key: string]: string | string[] | boolean | [string, string],
};
export function parseQuery(search: string): Query {
  return queryString.parse(search, { arrayFormat: 'bracket' });
}

export function cleanQuery(obj: Query) {
  const check = (str: string | string[]) =>
    typeof str === 'string' || Array.isArray(str) ? Boolean(str.length) : false;
  return Object.keys(obj).reduce((A, V) => (check(obj[V]) ? { ...A, [V]: obj[V] } : A), {});
}

export function queryToSearch(query: Query) {
  return queryString.stringify(query, { arrayFormat: 'bracket' });
}

export function queryToSupplierUpload(query: Query) {
  return queryString.stringify(query);
}

export const addQueryToUrl = (url: string, queryParams: Query) => {
  const queryStr = queryToSearch(queryParams);
  const glue = url.indexOf('?') > -1 ? '&' : '?';
  return [url, glue, queryStr].join('');
};

export const hrefObjectToString = (path: {| pathname: string, search?: string |}) => {
  const { pathname, search } = path;
  const glue = search.indexOf('?') > -1 ? '' : '?';

  return [pathname, glue, search].join('');
};
