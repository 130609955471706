import React from 'react';

import { DeleteOutlineOutlined, EditOutlined } from '@mui/icons-material';
import { IconButton, Stack } from '@mui/material';

import elements from 'components/elements';

const Controls = ({ handleEditClick, handleDeleteClick, value, disableEdit, disableDelete }) => (
  <Stack direction="row">
    <IconButton
      onClick={() => handleEditClick(value)}
      disabled={disableEdit}
      data-element={elements.configuration.company.approvalGroups.groupTile.controls.edit}
    >
      <EditOutlined />
    </IconButton>
    <IconButton
      onClick={() => handleDeleteClick(value)}
      disabled={disableDelete}
      data-element={elements.configuration.company.approvalGroups.groupTile.controls.delete}
    >
      <DeleteOutlineOutlined sx={{ color: 'error.main' }} />
    </IconButton>
  </Stack>
);

export default Controls;
