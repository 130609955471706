// @flow
import { makeStyles } from '@mui/styles';

import CONST from 'pages/common/constant';

export default makeStyles((theme) => ({
  categoryContainer: {
    minWidth: 255,
    width: '100%',
    marginLeft: 30,
  },
  title: {
    fontSize: 16,
    color: theme.colors.primaryWhite,
    borderRadius: 2,
    padding: [9, 16],
  },
  received: {
    backgroundColor: theme.colors.greyDarkest,
  },
  in_process: {
    backgroundColor: theme.colors.primaryLightest,
  },
  processed: {
    backgroundColor: theme.colors.primaryYellow,
  },
  paid: {
    backgroundColor: theme.colors.secondaryDark,
  },
  rejected: {
    backgroundColor: theme.colors.primaryDanger,
  },

  list: {
    marginTop: 8,
    border: `1px solid ${theme.colors.greyDark}`,
    borderRadius: 2,
    backgroundColor: theme.colors.greyLightest,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: ({ tagsComponentHeight }) =>
      `calc(100vh - ${CONST.headerHeight}px - ${CONST.topBarHeight}px - 68px - ${tagsComponentHeight}px)`,
    overflowY: 'scroll',
    padding: [0, 23],
  },

  documentItem: {
    width: 183,
    minHeight: 260,
    marginBottom: 20,
  },

  period: {
    color: theme.colors.greyDarker,
    fontSize: 12,
    margin: [20, 0],
    display: 'flex',
    alignItems: 'center',
    textTransform: 'capitalize',
    width: '100%',
    '&:after': {
      content: '""',
      height: 1,
      backgroundColor: theme.colors.greyLight,
      display: 'block',
      marginLeft: 20,
      flex: [1, 1, 'auto'],
    },
    '&:before': {
      content: '""',
      height: 1,
      backgroundColor: theme.colors.greyLight,
      display: 'block',
      marginRight: 20,
      flex: [1, 1, 'auto'],
    },
  },
}));
