/* @flow */
import { Record, type RecordFactory, type RecordOf } from 'immutable';
import { type TNotifications } from 'domain/contracts';

export const NotificationsFactory: RecordFactory<TNotifications> = new Record({
  approval_notification: {
    hours: 12,
    minutes: 0,
    timezone: '',
    notify_me: 'instantly',
    remind_me: false,
    frequency_days: 1,
  },
  chat_notification: {
    chat_to_mail: false,
  },
  slack_notification: {
    notify: false,
    connected: false,
  },
});

export function notificationAdapter(d: TNotifications): RecordOf<TNotifications> {
  return NotificationsFactory(d);
}
