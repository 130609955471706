/* @flow */
/* eslint-disable jsx-a11y/anchor-has-content */
import React from 'react';
import { useParams } from 'react-router-dom';

import { useTheme } from '@mui/material';
import useStyles from './mobileSheet';
import cx from 'classnames';

const MobileNotSupported = () => {
  const classes = useStyles();
  const {
    labelOptions: { appStoreDownloadLink, playMarketDownloadLink },
  } = useTheme();
  const { action } = useParams();

  return (
    <div className={classes.container}>
      <div className={classes.logo} />
      <div className={cx(classes.message, { [classes.smallText]: true !== 'password_restored' })}>
        {action === 'password_restored' ? 'You all set' : 'Your browser is not supported'}
      </div>

      {(appStoreDownloadLink || playMarketDownloadLink) && (
        <div className={classes.textContainer}>
          {(appStoreDownloadLink !== null || playMarketDownloadLink !== null) && (
            <div className={cx(classes.text, classes.proposal)}>Please, install our mobile app</div>
          )}

          <div className={classes.downloads}>
            {appStoreDownloadLink !== null && <a href={appStoreDownloadLink} className={classes.appStore} />}
            {playMarketDownloadLink !== null && <a href={playMarketDownloadLink} className={classes.googlePlay} />}
          </div>
        </div>
      )}

      <div className={classes.bottomText}>
        <div>Please open the app from your computer to have access to all accounting features.</div>
      </div>
    </div>
  );
};

export default MobileNotSupported;
