// @flow
import React from 'react';

import { useTheme } from '@mui/material/styles';

const AsideLogo = () => {
  const {
    labelOptions: { whiteLogo },
  } = useTheme();

  return (
    <img
      src={whiteLogo.src}
      alt={whiteLogo.alt}
      style={{ display: 'block', margin: '0 auto', maxWidth: '84%', maxHeight: 52, width: 52 }}
    />
  );
};

export default AsideLogo;
