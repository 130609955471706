// @flow
import React, { useState } from 'react';

// components
import UploadField from './uploadFiled';
import type { Fields } from '../index';
import { styled } from '@mui/material/styles';
import MuiAccordion from '@mui/material/Accordion';
import ArrowRightIcon from 'pages/components/AccordionArrowRightIcon';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

// react-intl
import { FormattedMessage } from 'react-intl';

const Accordion = styled(MuiAccordion)(() => ({
  '&.MuiPaper-root.MuiAccordion-root::before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary elevation={0} expandIcon={<ArrowRightIcon />} {...props} />
))(() => ({
  flexDirection: 'row-reverse',
  padding: 0,
}));

type Props = {|
  fields: $ReadOnlyArray<Fields>,
|};

const AddMoreCompaniesSection = ({ fields }: Props) => {
  const [isOpenMoreCompanies, setIsOpenMoreCompanies] = useState(false);

  const toogleMoreCompanies = () => {
    setIsOpenMoreCompanies((open) => !open);
  };

  return (
    <Grid item xs={12}>
      <Accordion
        disableGutters
        fullWidth
        sx={{
          mt: '0!important',
          border: 'none',
          boxShadow: 'none',
          mb: 0,
          backgroundColor: 'grey.300',
        }}
        TransitionProps={{ unmountOnExit: true }}
      >
        <AccordionSummary expanded={isOpenMoreCompanies} onChange={toogleMoreCompanies}>
          <Typography variant="subtitle2">
            <FormattedMessage id="forms.erpConnect.addMoreCompanies.title" defaultMessage="Connect other companies" />
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ p: 2, pl: 3, pt: 0 }}>
          <UploadField fields={fields} />
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
};

export default AddMoreCompaniesSection;
