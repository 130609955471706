// @flow
import React, { useCallback, memo } from 'react';
import { compose } from 'redux';
import { reduxForm, Field, type FormProps } from 'redux-form/immutable';
import { FormattedMessage, useIntl } from 'react-intl';
import type { TIndexListItemRecords } from 'domain/journal/types.js.flow';
import { rtlEnable } from 'domain/env';
import { useSelector } from 'react-redux';

import TextFieldForm from 'components/mui/Form/TextField/TextFieldForm';
import SelectForm from 'components/mui/Form/Select/SelectForm';
import SwitchForm from 'components/mui/Form/Switch/SwitchForm';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';

import Typography from '@mui/material/Typography';

type Props = {
  fields: TIndexListItemRecords,
  cancel: () => void,
  onSearchHandler: SyntheticInputEvent<HTMLIFrameElement>,
} & FormProps;

const DialogManageJECellListOptionForm: React$StatelessFunctionalComponent<Props> = ({
  fields,
  handleSubmit,
  cancel,
  onSearchHandler,
}: Props) => {
  const { formatMessage } = useIntl();
  const isRtl: boolean = useSelector(rtlEnable);

  const onChangeName = useCallback(
    (e: SyntheticInputEvent<HTMLInputElement>) => {
      if (typeof onSearchHandler === 'function') {
        onSearchHandler(e);
      }
    },
    [onSearchHandler],
  );

  const renderField = React.useCallback(
    (field, autoFocus) => {
      const isTermField = field.get('name') === 'payment_terms';
      const props = {
        name: field.get('name'),
        required: field.get('mandatory'),
        placeholder: field.get('label'),
        label: field.get('label'),
        autoFocus,
      };
      switch (field.get('type')) {
        case 'text':
          return (
            <Field
              {...props}
              fullWidth
              component={TextFieldForm}
              type="text"
              label={field.get('label')}
              inputProps={{
                ...(isRtl && isTermField ? { dir: 'ltr' } : {}),
                style:
                  isRtl && isTermField
                    ? {
                        textAlign: 'right',
                      }
                    : {},
              }}
              {...(field.get('name') === 'Name' ? { onChange: onChangeName } : {})}
            />
          );
        case 'list':
          return (
            <Field
              {...props}
              component={SelectForm}
              options={field.get('options').toJS()}
              FormControlProps={{ fullWidth: true }}
              placeholder={formatMessage({
                id: 'placeholders.select.default',
                defaultMessage: 'Select...',
              })}
            />
          );
        case 'boolean':
          return (
            <Field
              {...props}
              component={SwitchForm}
              props={{
                FormControlLabelProps: {
                  labelPlacement: 'end',
                  label: (
                    <Typography variant="body1" sx={{ color: (theme) => `${theme.palette.common.black}!important` }}>
                      {field.get('label')}
                    </Typography>
                  ),
                },
              }}
            />
          );
        default:
          return null;
      }
    },
    [isRtl, onChangeName, formatMessage],
  );

  return (
    <form onSubmit={handleSubmit} noValidate>
      <Stack spacing={2} py={1} pb={2}>
        {fields.map((field, key) => (
          <React.Fragment key={`${key}`}>{renderField(field, key === 0)}</React.Fragment>
        ))}
      </Stack>
      <DialogActions>
        <Button variant="text" onClick={cancel}>
          <FormattedMessage id="components.confirmModal.cancelText" defaultMessage="Cancel" />
        </Button>
        <Button type="submit">
          <FormattedMessage id="components.confirmModal.confirmText" defaultMessage="Confirm" />
        </Button>
      </DialogActions>
    </form>
  );
};

export default compose(
  reduxForm({
    form: 'ManageJECellListOptionForm',
  }),
)(memo(DialogManageJECellListOptionForm));
