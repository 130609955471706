import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

export const AlertBox = styled(Box, { label: 'AlertSlowConnection' })(({ theme }) => ({
  padding: theme.spacing(1, 0),
  backgroundColor: theme.palette.grey[800],
  fontWeight: theme.typography.fontWeightBold,
  color: theme.palette.common.white,
  textAlign: 'center',
  fontSize: theme.typography.pxToRem(14),
}));
