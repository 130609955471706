// @flow
import { asyncAction, syncAction } from 'lib/action';

export const getDocumentExtractedTableData = asyncAction('RECOGNITION/GET_DOCUMENT_EXTRACTED_TABLE_DATA');
export const getDocumentExtractedTableFieldMapping = asyncAction(
  'RECOGNITION/GET_DOCUMENT_EXTRACTED_TABLE_FIELD_MAPPING',
);
export const learnDocumentExtractedTableFieldsAction = asyncAction('RECOGNITION/LEARN_DOCUMENT_EXTRACTED_TABLE_FIELDS');
export const ExtractLinesAction = asyncAction('RECOGNITION/EXTRACT_LINES');
export const cleanDocumentExtractedTableData = syncAction('RECOGNITION/CLEAN_DOCUMENT_EXTRACTED_TABLE_DATA');
export const toggle2WayMatchingForDocumentAction = syncAction('RECOGNITION/ENABLE_FEATURE_FOR_DOCUMENT');
