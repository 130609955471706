// @flow

import React, { memo } from 'react';
import { withStyles } from '@mui/styles';
import cx from 'classnames';
import sheet from './sheet';
import './sheet.css';

type TSpinnerProps = {
  classes: {
    [key: string]: string,
  },
  // eslint-disable-next-line react/no-unused-prop-types
  loading: boolean, // used in styles
  // eslint-disable-next-line react/no-unused-prop-types
  position?: 'fixed' | 'absolute' | 'static', // used in styles
  className?: string,
};

const Spinner: React$StatelessFunctionalComponent<TSpinnerProps> = ({ classes, className }: TSpinnerProps) => (
  <div className={cx(classes.spinner, className)}>
    <div className={classes.container}>
      <div className={classes.bullet} />
      <div className={classes.bullet} />
      <div className={classes.bullet} />
    </div>
  </div>
);

export default withStyles(sheet)(memo(Spinner));
