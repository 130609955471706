// @flow
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import * as React from 'react';
import { FormattedMessage, injectIntl, type IntlShape } from 'react-intl';
import { OrderedSet, type RecordOf } from 'immutable';
import { documentTagsSelector, documentUpdateTags, recentTagsSelector } from 'domain/documents';
import { TagFactory } from 'domain/approvals';
import { chatAllUsersSelector } from 'domain/chat/chatSelector';
import type { ChatUserRecords } from 'domain/chat/types.js.flow';
import { connect } from 'react-redux';
import { compose, type Dispatch } from 'redux';
import withConfirm from 'hoc/withConfirm';
import { filterTags, sortTags, getSystemUser, customArrayFlat } from 'lib/helpers';
import * as ACL from 'domain/restriction';
import identity from 'lodash/identity';
import { rtlEnable } from 'domain/env';
import { ROLES_FOR_CONFIRM } from 'pages/common/Dialog/DialogTagsManage';

import Tooltip from 'components/mui/Tooltip';
import TagSuggestions from 'components/mui/Layouts/components/TagSuggestions';
import InputSearch from '../InputSearch';
import Chip from '@mui/material/Chip';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import ArrowButton from '../ArrowButton';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import ListSubheader from '@mui/material/ListSubheader';
import Box from '@mui/material/Box';

export type Tag = {|
  tag: string,
  status?: string,
  picture?: string,
|};

type Props = {
  intl: IntlShape,
  tags: Array<RecordOf<Tag>>,
  updateTags: Dispatch<typeof documentUpdateTags>,
  recentTags: Array<string>,
  // eslint-disable-next-line react/no-unused-prop-types
  general?: boolean,
  isGranted: (l: number | number[]) => boolean,
  users: ChatUserRecords,
  isRtl: boolean,
  onOpen?: () => void,
  onClose?: () => void,
  onResizeMove?: () => void,
  confirm: Promise<any>,
};

type State = {
  open: boolean,
  value: string,
};

class Tags extends React.Component<Props, State> {
  // $FlowFixMe props are provided later
  input: ?HTMLInputElement;

  tagRefs: { [key: string]: string } = {};

  wrapper: ?HTMLElement;

  list: ?HTMLUListElement;

  constructor() {
    super();

    this.state = {
      open: false,
      value: '',
    };
  }

  onToggle = () => {
    const { onClose = identity, onOpen = identity } = this.props;
    const { open } = this.state;

    const cb = open ? onClose : onOpen;
    cb();

    this.setState(
      {
        open: !open,
      },
      () => {
        if (this.input) {
          this.input.focus();
        }
      },
    );
  };

  onTagsUpdate = ({ tagsToAdd = [], tagsToRemove = [] }: { [key: string]: Array<*> }) =>
    new Promise((resolve, reject) => {
      const { updateTags } = this.props;
      return updateTags({ tagsToAdd, tagsToRemove, resolve, reject });
    })
      .then(() => {
        if (tagsToRemove.length) {
          tagsToRemove.forEach((tag) => {
            delete this.tagRefs[tag];
          });
        }
        this.clear();
      })
      .catch(() => this.clear());

  onAddTag = (event: Event) => {
    const { value } = this.state;
    event.preventDefault();
    this.onTagsUpdate({ tagsToAdd: [value] });
  };

  onSelectSuggestion = (suggestion: string) => {
    const user = this.getUserForConfirm(suggestion);
    const { confirm } = this.props;
    if (user) {
      confirm({
        title: <FormattedMessage id="confirm.userGainingDocumentAccess.title" default="Access to document" />,
        description: (
          <FormattedMessage
            id="confirm.userAllowDocumentAccess.placeholder"
            defaultMessage={`This action will enable ${user.username} to view the document`}
            values={{
              usernames: <strong>{user.username}</strong>,
            }}
          />
        ),
      }).then(() => this.onConfirmUserAccess(suggestion));
    } else {
      this.onTagsUpdate({ tagsToAdd: [suggestion] });
    }
  };

  onInputChange = (value: string) => {
    this.setState({ value });
  };

  onRemove = ({ tag }: RecordOf<Tag>) => {
    const user = this.getUserForConfirm(tag);
    const { confirm } = this.props;
    if (user) {
      confirm({
        title: <FormattedMessage id="confirm.userGainingDocumentAccess.title" default="Access to document" />,
        description: (
          <FormattedMessage
            id="confirm.userDenyDocumentAccess.placeholder"
            defaultMessage={`This action will disable ${user.username} to view the document`}
            values={{
              usernames: <strong>{user.username}</strong>,
            }}
          />
        ),
      }).then(() => this.onConfirmUserDeny(tag));
    } else {
      this.onTagsUpdate({ tagsToRemove: [tag] });
    }
  };

  onConfirmUserAccess = (tag) => {
    this.onTagsUpdate({ tagsToAdd: [tag] });
  };

  onConfirmUserDeny = (tag) => {
    this.onTagsUpdate({ tagsToRemove: [tag] });
  };

  get tags(): Array<RecordOf<Tag>> {
    const { tags } = this.props;
    return filterTags(tags);
  }

  get sortedTags(): Array<RecordOf<Tag>> {
    const { users } = this.props;
    return sortTags(this.tags, users);
  }

  get recentTags(): OrderedSet<string> {
    // @todo convert to Tag type if needed
    const { recentTags } = this.props;
    const tags = this.tags.map((tag) => tag.tag);
    return recentTags.filter((tag) => !tags.includes(tag));
  }

  get sortedRecentTags(): Array<RecordOf<Tag>> {
    const systemUsers = this.getUsers();
    const separatedTags = this.recentTags.reduce(
      ([users, tags], tag) => (systemUsers[tag] ? [[...users, tag], tags] : [users, [...tags, tag]]),
      [[], []],
    );
    return customArrayFlat(separatedTags);
  }

  get sortedPreviewTags(): Array<RecordOf<Tag>> {
    const { users } = this.props;

    return sortTags(this.sortedTags, users);
  }

  get tailSize(): number {
    const tagRefs = Object.entries(this.tagRefs).map(([k, v]) => v);
    this.hideExtraTags(tagRefs);
    return tagRefs.length ? tagRefs.filter((el) => el && el.style.display === 'none').length : 0;
  }

  get enable(): boolean {
    const { value } = this.state;

    return Boolean(value.replace(/ /g, '').length);
  }

  get tagsEditingAllowed(): boolean {
    const { isGranted } = this.props;
    return isGranted(ACL.IS_AUTHORIZED);
  }

  hideExtraTags = (tagRefs) => {
    const { isRtl } = this.props;

    if (this.wrapper && tagRefs && tagRefs.length) {
      const extraTags = tagRefs.filter((el) => {
        if (el) {
          const elementExtremePosition = isRtl
            ? window.innerWidth - el.getBoundingClientRect().x
            : el.getBoundingClientRect().x + el.getBoundingClientRect().width;
          return elementExtremePosition >= this.wrapper.getBoundingClientRect().width - 75;
        }
        return false;
      });
      if (extraTags.length) {
        extraTags.forEach((el) => (el.style.display = 'none'));
      }
    }
  };

  getUsers = () => {
    const { users } = this.props;
    return users.reduce((res, user) => ({ ...res, [user.userId]: user }), {});
  };

  setRef = (ref: ?HTMLInputElement) => {
    this.input = ref;
  };

  setTagRefs = (tag) => (ref: ?HTMLInputElement) => {
    this.tagRefs[tag] = ref;
  };

  setWrapperRef = (ref: ?HTMLElement) => {
    this.wrapper = ref;
    this.forceUpdate();
  };

  clear = () => {
    this.setState({
      value: '',
    });
  };

  getUserForConfirm = (tag: string) => {
    const { users } = this.props;
    const user = getSystemUser(tag, users);

    return user && ROLES_FOR_CONFIRM.includes(user.role) && user.restricted ? user : null;
  };

  renderTags = (tags: Array<RecordOf<Tag>>, options: { isRemove?: boolean, isToggle?: boolean }) => {
    const { users } = this.props;
    const { open } = this.state;
    const { isRemove = undefined, isToggle = undefined } = options;

    return tags.map((tag) => {
      const user = getSystemUser(tag.tag, users);
      const label = user ? user.username : tag.tag;
      const avatar = user ? <Avatar alt={user.username} src={user.picture} /> : undefined;
      const onTagRemove = open && isRemove ? () => this.onRemove(tag) : undefined;
      const onClick = isToggle ? this.onToggle : undefined;

      const baseProps = {
        label,
        avatar,
        onDelete: onTagRemove,
        onClick,
      };

      return <Chip ref={this.setTagRefs(tag.tag)} key={tag.tag} variant="outlined" color="primary" {...baseProps} />;
    });
  };

  renderSuggestionItem = ({ isDivider, isUser, users, tag, width, dividerTitle, menuItemProps }) => (
    <>
      {isDivider && (
        <ListSubheader style={{ width }}>
          <Typography variant="caption">{this.props.intl.formatMessage(dividerTitle)}</Typography>
        </ListSubheader>
      )}
      <MenuItem style={{ width }} {...menuItemProps}>
        {isUser && (
          <ListItemIcon>
            <Avatar src={users[tag].picture} sx={{ width: 24, height: 24 }} />
          </ListItemIcon>
        )}
        <ListItemText>
          <Typography variant="body2">{isUser ? `${users[tag].username} (${tag})` : tag}</Typography>
        </ListItemText>
      </MenuItem>
    </>
  );

  render() {
    const { users, intl } = this.props;
    const { open, value } = this.state;

    const editable = (
      <Stack direction="row" alignItems="center" width="100%" spacing={2}>
        <TagSuggestions
          InputComponent={InputSearch}
          inputValue={value}
          onSelect={this.onSelectSuggestion}
          onInputChange={this.onInputChange}
          inputComponentProps={{
            variant: 'filled',
            size: 'small',
            fullWidth: true,
            autoFocus: true,
            label: intl.formatMessage({
              id: 'document.show.infoBar.tags.placeholder',
              defaultMessage: 'Add new tag',
            }),
          }}
          renderItem={this.renderSuggestionItem}
        />
        <Button disabled={!this.enable} onClick={this.onAddTag}>
          <FormattedMessage id="document.show.infoBar.tags.add" defaultMessage="Add tag" />
        </Button>
      </Stack>
    );

    const readable = (
      <Box display="flex" width="100%" ref={this.setWrapperRef}>
        <Stack
          direction="row"
          alignItems="center"
          flex={1}
          spacing={1}
          sx={{ mr: 1 }}
          height={32}
          flexWrap="nowrap"
          overflow="hidden"
        >
          {this.renderTags(this.sortedPreviewTags, { isToggle: true })}
          {this.sortedPreviewTags.length === 0 && (
            <Typography variant="body2">
              <FormattedMessage
                id="document.show.infoBar.tags.empty"
                defaultMessage="Attach tags to the document here"
              />
            </Typography>
          )}
          {this.tailSize > 0 ? <Chip label={`+${this.tailSize}`} onClick={this.onToggle} /> : null}
        </Stack>
        {this.tagsEditingAllowed && (
          <Tooltip t={{ id: 'document.show.infoBar.tags.panel', defaultMessage: 'Tags Panel' }}>
            <ArrowButton onClick={this.onToggle} color="primary" />
          </Tooltip>
        )}
      </Box>
    );

    const recentTags = this.sortedRecentTags;
    return (
      <Stack
        justifyContent="center"
        spacing={open ? 1 : 0}
        sx={{
          position: 'relative',
          padding: (theme) => theme.spacing(1, 3),
          border: 1,
          borderColor: (theme) => theme.palette.grey[300],
          bgcolor: (theme) => (open ? theme.palette.common.white : 'inherit'),
          boxShadow: (theme) => (open ? theme.shadows[3] : 'none'),
          borderLeft: 'none',
          borderRight: 'none',
        }}
      >
        <Stack alignItems="center" direction={open ? 'column' : 'row'} spacing={1}>
          {open && recentTags.length > 0 ? (
            <Stack direction="row" alignItems="center" justifyContent="start" alignSelf="start" spacing={2}>
              <Typography variant="body2">
                <FormattedMessage id="document.show.infoBar.tags.recentTitle" defaultMessage="Recent tags" />:
              </Typography>
              <Stack gap={1} direction="row" flexWrap="wrap">
                {recentTags.map((tag) => {
                  const user = getSystemUser(tag, users);

                  return (
                    <Chip
                      key={user ? user.userId : tag}
                      label={tag}
                      onClick={() => this.onSelectSuggestion(tag)}
                      avatar={user ? <Avatar alt={tag.username} src={user.picture} /> : undefined}
                      variant="outlined"
                      sx={{ backgroundColor: (theme) => theme.palette.common.white }}
                    />
                  );
                })}
              </Stack>
            </Stack>
          ) : null}
          {open ? editable : readable}
        </Stack>
        {open ? (
          <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
            <Stack direction="row" gap={1} flexWrap="wrap">
              {this.renderTags(this.sortedTags, { isRemove: true })}
            </Stack>
            <ArrowButton open onClick={this.onToggle} color="primary" />
          </Stack>
        ) : null}
      </Stack>
    );
  }
}

const mapStateToProps = (state) => ({
  recentTags: recentTagsSelector(state),
  isGranted: ACL.isGranted(state),
  users: chatAllUsersSelector(state),
  isRtl: rtlEnable(state),
  tags: documentTagsSelector(state).map((tag) => TagFactory({ tag })),
});

const mapDispatchToProps = {
  updateTags: documentUpdateTags,
};

export default compose(connect(mapStateToProps, mapDispatchToProps), injectIntl, withConfirm)(Tags);
