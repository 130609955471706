// @flow
import React from 'react';

import type { FieldProps } from 'redux-form/lib/FieldProps.types.js.flow';

import Autocomplete from '@mui/material/Autocomplete';
import TextFieldForm from 'components/mui/Form/TextField/TextFieldForm';
import EmailTag from 'pages/common/Dialog/components/EmailTag';
import UserOptionLabel from 'pages/common/Dialog/components/UserOptionLabel';

type Props = {
  emailOptions: Array<string>,
  users: { [userId: string]: { picture: string, username: string } },
  label?: string,
  meta: $PropertyType<FieldProps, 'meta'>,
  input: {
    value: string,
    name: string,
    onChange: (value: string) => void,
    onBlur: (value: string) => void,
  },
};

const UserField: React$StatelessFunctionalComponent<Props> = ({
  emailOptions,
  users,
  input: { value, onChange, onBlur, ...inputRest },
  label: inputLabel,
  meta,
  ...rest
}) => (
  <Autocomplete
    {...rest}
    multiple
    autoHighlight
    id={inputLabel}
    value={value || []}
    clearIcon={null}
    onChange={(event, newValue) => onChange(newValue.slice(-1))}
    onBlur={() => {
      onBlur(value);
    }}
    options={emailOptions}
    renderOption={(props, tag) => <UserOptionLabel tag={tag} users={users} {...props} />}
    renderTags={(values, getTagProps) =>
      values.map((tag, index) => <EmailTag key={tag} tag={tag} users={users} {...getTagProps({ index })} />)
    }
    getOptionLabel={(option) => (users[option] ? `${users[option].username} ${users[option].userId}` : option)}
    renderInput={(params) => <TextFieldForm {...params} {...rest} input={inputRest} label={inputLabel} meta={meta} />}
  />
);

export default UserField;
