// @flow
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

export const WidgetWrapperContainer = styled(Box, {
  shouldForwardProp: (propName) => !['isActive'].includes(propName),
})(({ isActive }) => ({
  zIndex: 30,
  pointerEvents: 'none',
  height: isActive ? '25vh' : 96,
}));

export const WidgetWrapper = styled(Box, {
  shouldForwardProp: (propName) => !['isActive', 'widgetButtonPosition'].includes(propName),
})(({ isActive, widgetButtonPosition }) => ({
  transition: 'opacity 150ms',

  display: 'flex',
  alignItems: widgetButtonPosition === 'Top' ? 'flex-start' : 'flex-end',
  top: 0,
  cursor: 'grab',

  '&:active': {
    cursor: 'move',
  },

  '#doc-preview &': {
    left: 0,
  },

  '.rtl #doc-preview &': {
    flexDirection: 'row-reverse',
  },

  '.rtl #doc-preview-rtl &': {
    flexDirection: 'row-reverse',
  },

  '#doc-preview-rtl &': {
    right: 0,
  },
  ...(!isActive && { height: '100%', minHeight: '100%' }),
}));

export const WidgetContentWrapper = styled(Box, {
  shouldForwardProp: (propName) => !['isActive', 'isMaxInitialWidth'].includes(propName),
})(({ isActive, isMaxInitialWidth }) => ({
  height: '25vh',
  minHeight: 150,
  resize: 'both',
  transition: 'width 150ms linear',
  overflow: 'hidden',
  pointerEvents: 'all',
  maxWidth: 'min(50vw, 950px)',
  maxHeight: '50vh',
  '#doc-preview &': {
    borderRadius: [4, 0, 4, 4],
    boxShadow: '2px 2px 3px 0px #00000040',
  },
  '#doc-preview-rtl &': {
    borderRadius: [0, 4, 4, 4],
    boxShadow: '-2px 2px 3px 0px #00000040',
    direction: 'rtl',
  },
  ...(isActive && {
    minWidth: '12.5vw',
    width: isMaxInitialWidth ? '50vw' : '25vw',
  }),
}));

export const WidgetContentContainer = styled(Stack, {
  shouldForwardProp: (propName) => !['bgColor', 'borderColor'].includes(propName),
})(({ theme, bgColor, borderColor }) => ({
  position: 'relative',
  direction: 'ltr',
  width: '100%',
  height: '100%',
  border: `1px solid ${borderColor}`,
  padding: theme.spacing(0.5),
  backgroundColor: bgColor,
}));

export const WidgetContent = styled(Box)(({ theme }) => ({
  borderRadius: 4,
  padding: theme.spacing(1),
  backgroundColor: theme.palette.common.white,
  flexGrow: 1,
  height: '50%',
}));

export const WidgetButtonWrapper = styled(Box, {
  shouldForwardProp: (propName) => !['bgColor', 'borderColor', 'widgetButtonPosition'].includes(propName),
})(({ bgColor, borderColor, widgetButtonPosition }) => ({
  pointerEvents: 'all',
  height: 32,
  width: 96,
  [`margin${widgetButtonPosition}`]: 32,
  border: `1px solid ${borderColor}`,
  backgroundColor: bgColor,

  '#doc-preview &': {
    borderRadius: '0px 0px 4px 4px',
    boxShadow: '-3px 2px 3px -1px #00000040',
    right: -63,
    transform: 'rotate(-90deg)',
    marginLeft: -33,
  },

  '.rtl #doc-preview &': {
    borderRadius: '0px 0px 4px 4px',
    boxShadow: '-3px 2px 3px -1px #00000040',
    right: -63,
    transform: 'rotate(90deg)',
    marginRight: -33,
  },

  '#doc-preview-rtl &': {
    borderRadius: '4px 4px 0px 0px',
    boxShadow: '-3px -2px 3px -1px #00000040',
    left: -63,
    borderBottom: 'none',
    transform: 'rotate(-90deg)',
    marginRight: -33,
  },

  '.rtl #doc-preview-rtl &': {
    borderRadius: '4px 4px 0px 0px',
    boxShadow: '-3px -2px 3px -1px #00000040',
    right: -63,
    borderBottom: 'none',
    transform: 'rotate(90deg)',
    marginLeft: -33,
  },

  outline: 'none',
  '&:hover': {
    cursor: 'pointer',
  },
  '#doc-preview $active &': {
    boxShadow: '-2px 3px 3px -1px #00000040',
  },
  '#doc-preview-rtl $active &': {
    boxShadow: '-2px -3px 3px -1px #00000040',
  },

  button: {
    cursor: 'pointer',
    border: 'none',
    backgroundColor: 'inherit',
    width: '100%',
    height: '100%',
    borderRadius: 'inherit',
  },
}));
