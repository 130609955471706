/* @flow */

export const getNext = <T>(items: T[], item: T, isLoop: boolean = false): T => {
  const currentIndex = items.indexOf(item);
  const nextIndex = (currentIndex + 1) % items.length;
  const resolveIndex = currentIndex > nextIndex && isLoop ? nextIndex : currentIndex;
  return items[resolveIndex];
};

export const getPrev = <T>(items: T[], item: T, isLoop: boolean = false): T =>
  getNext([...items].reverse(), item, isLoop);

/*
 * move( [ 1, 2, 3, 4 ], 2, 0 ) => [ 3, 2, 1, 4 ]
 * */
export const move = <T>(items: T[], from: number, to: number): T[] => {
  if (from === to) return items;
  const moveItem = items[from];
  return items.reduce((res, item, index) => {
    const fromItem = index === from ? [] : null;
    const movePosition = to > from ? [item, moveItem] : [moveItem, item];
    const toItem = index === to ? movePosition : null;
    const next = fromItem || toItem || [item];
    return [...res, ...next];
  }, []);
};

/*
 * it works like a spread operator for object { ...{ 0: 0, 1: 1, 2: 2 }, ...{ 0: 3, 1: 4 } } => { 0: 3, 1: 4, 2: 2 }
 * replace( [0, 1, 2], [3, 4] ) => [3, 4, 2];
 * replace( [0, 1], [3, 4, 5] ) => [3, 4, 5];
 * */
export const replace = <T>(fromItems: T[], toItems: T[]) => {
  const outsidePart = fromItems.slice(toItems.length);
  return [...toItems, ...outsidePart];
};

/*
 * insert( [0, 1, 2, 3, 4], 4, 99 ) => [0, 1, 2, 3, 99, 4] (99 on index 4);
 * insert( [0, 1, 2, 3, 4], 5, 99 ) => [0, 1, 2, 3, 4, 99] (99 on index 5);
 * insert( [0, 1, 2, 3, 4], 7, 99 ) => [0, 1, 2, 3, 4, null, null, 99]
 * (99 on index 7 and a gap was filled with null values or a value that was passed);
 * */
export const insert = <T>(items: T[], index: number, value: T, fill: T | null = null): Array<T | null> => {
  let res;
  if (items.length > index - 1) {
    res = [...items];
    res.splice(index, 0, value);
  } else {
    res = replace([...new Array(index).fill(fill), value], items);
  }
  return res;
};

/*
 * This function creates an ordering map only for touched values for another will be created empty placeholders
 * createOrderMap( ['A', 'D', 'B', 'C'], ['A', 'B'] ) => ['A', '*', 'B', '*']
 * */
export const createOrderMap = (allItems: any[], existsInMapItems: any[]): any[] =>
  allItems.map((item) => (existsInMapItems.includes(item) ? item : '*'));

/*
 * This function creates an ordering map only for touched values for another will be created empty placeholders
 * orderByMap( ['A', 'B', 'C', 'D'], ['A', '*', 'B', '*'] ) => ['A', 'C', 'B', 'D'] => ( [('A' = A), ('C' = *), ('B' = B), ('D' = *)] )
 * */
export const orderByMap = (allItems: any[], orderMap: any[]): any[] => {
  const noOrderedItems = allItems.filter((item) => !orderMap.includes(item));
  const res = orderMap.map((item) => (item === '*' ? noOrderedItems.shift() : item)).filter((i) => i);
  return [...res, ...noOrderedItems];
};
