// @flow
import React, { useState } from 'react';
import { Set } from 'immutable';
import { useSelector } from 'react-redux';
import { messagesSelector } from 'domain/messages/selectors';
import { getDisabledMessages, addDisabledMessages } from 'domain/messages/helpers';
import type { TSystemMessage } from 'domain/messages/types.js.flow';

import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';

function addTarget(s: string) {
  if (/target/.test(s)) return s;
  return `${s.trim()} target="_blank" rel="noopener noreferrer"`;
}

function linkProcess(t: string) {
  return t.replace(/<a\s([^>]+)>/gm, (s, p) => `<a ${addTarget(p)}>`);
}

const AlertText = styled('div')(({ theme }) => ({
  fontWeight: 500,

  '& a': {
    color: theme.palette.primary.main,
    textDecoration: 'underline',

    '&:hover': {
      textDecoration: 'none',
    },
  },
}));

const SystemMessages = () => {
  const [disabledMessages, setDisabledMessages] = useState(getDisabledMessages());
  const rawMessages = useSelector(messagesSelector);
  const filteredMessages = rawMessages.filter((m) => !disabledMessages.includes(m.id));

  const messages = ['error', 'warning', 'info'].reduce(
    (acc: Set<TSystemMessage>, type) => acc.union(filteredMessages.filter((val): boolean => val.type === type)),
    new Set(),
  );

  const onClickClose = (id: string) => {
    addDisabledMessages(id);
    setDisabledMessages(getDisabledMessages());
  };

  return (
    messages.size > 0 && (
      <Stack spacing={1} my={1}>
        {messages.map((m) => (
          <Alert
            key={m.id}
            severity={m.type}
            {...(m.type === 'error' ? { variant: 'filled' } : { onClose: () => onClickClose(m.id) })}
          >
            <AlertText dangerouslySetInnerHTML={{ __html: linkProcess(m.text) }} />
          </Alert>
        ))}
      </Stack>
    )
  );
};

export default SystemMessages;
