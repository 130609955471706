// @flow
import React from 'react';
import { FormattedMessage } from 'react-intl';

import TextFieldBase from 'components/mui/Form/TextField/TextFieldBase';

import type { FieldProps } from 'redux-form/lib/FieldProps.types.js.flow';
import TextFieldTrim from './TextFieldTrim';

type TTextFieldForm = {} & FieldProps;
type THelperTextError = {|
  meta: $PropertyType<FieldProps, 'meta'>,
  isError: boolean,
  withTrim?: boolean,
|};

// TODO: used for sync reduxValidator by key, need to learn/check how to use errors for translation and from the backend
const HelperTextError: React$StatelessFunctionalComponent<THelperTextError> = ({ meta, isError }) => {
  const { error } = meta;
  return isError ? <FormattedMessage id={`message.${error.split(' ').join('_')}`} defaultMessage={error} /> : null;
};

const TextFieldForm: React$StatelessFunctionalComponent<TTextFieldForm> = (props) => {
  const { input, meta, withTrim, ...rest } = props;
  const isError = Boolean(meta.touched && meta.error);
  const TextField = withTrim ? TextFieldTrim : TextFieldBase;
  return (
    <TextField
      error={isError}
      variant="outlined"
      helperText={<HelperTextError isError={isError} meta={meta} />}
      {...input}
      {...rest}
    />
  );
};

export default TextFieldForm;
