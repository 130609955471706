// @flow
import { type RecordFactory, Record, List, Set } from 'immutable';
import type { RawUserType, UserType, EnhancedUserType, User } from 'domain/organizationUser/types.js.flow';

const defaultRecordValues = (role): UserType => ({
  admin: null,
  pic: null,
  id: null,
  email: null,
  first_name: null,
  last_name: null,
  display_name: null,
  display_role: null,
  phone: '',
  role,
  title: '',
  companies_id: new Set(),
  restricted: false,
  limited: false,
  readonly: false,
  vat_id: null,
});

const defaultEnhancedRecordValues: EnhancedUserType = {
  ...defaultRecordValues('accountant'),
  assigned: false,
  assignedCount: 0,
};

export const UserFactory: RecordFactory<UserType> = new Record(defaultRecordValues('accountant'));

// eslint-disable-next-line max-len
export const EnhancedUserFactory: RecordFactory<EnhancedUserType> = new Record(defaultEnhancedRecordValues);

export function singleUserAdapter(rawUser: RawUserType): User {
  const user: UserType | {} = {
    ...rawUser,
    phone: rawUser.phone || '',
    companies_id: Set(rawUser.companies_id),
    pic: rawUser.picture,
  };
  return UserFactory(user);
}

export function UserAdapter(data: Array<RawUserType>): List<User> {
  return List(data.map(singleUserAdapter));
}
