// @flow
import { useLayoutEffect, useCallback, useRef } from 'react';
import { useSelector } from 'react-redux';

// helpers
import { sessionST } from 'lib/sessionStorage';
import * as Router from 'domain/router';
import { matchRoute } from 'domain/router/utils';
import type { TGridApi } from 'pages/company/Grid/types';

type Props = {
  isTargetLoaded: boolean,
  view?: 'grid' | 'tile',
  gridApi?: TGridApi,
};
export const deleteWorkspaceScrollPosition = () => {
  sessionST().workspaceScrollPosition().remove();
};

export const saveWorkspaceScrollPosition = (data = {}) => {
  sessionST().workspaceScrollPosition().set(JSON.stringify(data));
};

export const getWorkspaceScrollPosition = () => {
  try {
    const position = sessionST().workspaceScrollPosition().get();
    return position ? JSON.parse(position) : {};
  } catch (e) {
    console.log('doesn`t get workspaceScrollPosition');
    return {};
  }
};

const ScrollManager = ({ view = 'tile', isTargetLoaded, gridApi }: Props) => {
  const {
    prevLocation: { pathname: prevPathname },
  } = useSelector(Router.routing);
  const timeoutRef = useRef(null);

  const savePosition = useCallback(
    (target) => {
      try {
        const { scrollLeft: left, scrollTop: top } = target;
        const pageIndex = gridApi?.paginationGetCurrentPage();

        sessionST().workspaceScrollPosition().set(JSON.stringify({ left, top, view, pageIndex }));
      } catch (e) {
        console.log('doesn`t save workspaceScrollPosition');
      }
    },
    [view, gridApi],
  );

  const restorePosition = useCallback(
    (target) => {
      const { view: sview, top = 0, left = 0, pageIndex = 0 } = getWorkspaceScrollPosition();

      if (sview === view) {
        if (view === 'grid' && gridApi) {
          // temporary workaround for back to stored page and scroll position
          // if grid have  predefined async filters, grid make double request and reset us to first page
          timeoutRef.current = setTimeout(() => {
            if (pageIndex > 0) {
              gridApi.paginationGoToPage(pageIndex);
            }
            target.scrollTo({ top, left });
          }, [500]);
        } else {
          target.scrollTo({ top, left });
        }
      }
    },
    [view, gridApi],
  );

  useLayoutEffect(() => {
    const isCompanyPrevPage = matchRoute.isCompany(prevPathname);
    const tileViewElement = document.documentElement;
    const gridViewElement = document.querySelector('.ag-body-viewport');
    const target = view === 'grid' ? gridViewElement : tileViewElement;

    if (isTargetLoaded) {
      if (!isCompanyPrevPage) {
        Promise.resolve().then(() => {
          restorePosition(target);
          deleteWorkspaceScrollPosition();
        });
      } else {
        deleteWorkspaceScrollPosition();
      }
    }

    return () => {
      if (isTargetLoaded) {
        savePosition(target);
      }

      clearTimeout(timeoutRef.current);
    };
  }, [isTargetLoaded, prevPathname, restorePosition, view, savePosition]);

  return null;
};

export default ScrollManager;
