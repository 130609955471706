// @flow
import React, { memo } from 'react';

import Box from '@mui/material/Box';

import IconLogotypeAuth from 'components/Icons/logotype-auth';

import { useTheme } from '@mui/material/styles';

const AuthLogo = () => {
  const { labelOptions } = useTheme();
  const { name, logotype } = labelOptions;

  return (
    <Box
      mb={2}
      display="flex"
      alignItems="center"
      justifyContent="center"
      sx={{ marginTop: 2, mx: 'auto', maxWidth: 160, maxHeight: 100 }}
    >
      {name !== 'Dokka' ? (
        <img width="100%" height="100%" src={logotype.src} alt={logotype.alt} />
      ) : (
        <IconLogotypeAuth />
      )}
    </Box>
  );
};

export default memo(AuthLogo);
