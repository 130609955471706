import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import TextFieldBase from 'components/mui/Form/TextField/TextFieldBase';
import { NOTE_PALETTE } from 'components/LabeledThemeProvider/notesPalette';

import { styled, alpha } from '@mui/material/styles';

export const ContentArea = styled('div', { label: 'DialogNote' })(({ theme }) => ({
  position: 'relative',
  marginTop: theme.spacing(1),
}));

export const NoteTextarea = styled(TextFieldBase, { label: 'DialogNote' })(({ theme }) => ({
  '& .MuiInputBase-root': {
    paddingRight: theme.spacing(8),
  },
}));

export const NotePaletteBar = styled(RadioGroup, { label: 'NotePaletteBar' })(({ theme }) => ({
  padding: theme.spacing(0.5, 1),
  position: 'absolute',
  top: 1,
  right: 1,
  bottom: 1,
  borderRadius: '0 4px 4px 0',
  backgroundColor: alpha(theme.palette.common.white, 0.5),
  justifyContent: 'space-around',
  flexWrap: 'nowrap',
}));

export const NotePaletteRadio = styled(Radio, { label: 'NotePaletteRadio' })(({ theme, value }) => ({
  border: '1px solid transparent',

  '& .MuiSvgIcon-root path': {
    stroke: NOTE_PALETTE[value].border,
  },

  '&.Mui-checked': {
    borderColor: theme.palette.primary.main,

    '& .MuiSvgIcon-root path': {
      strokeWidth: 0,
    },
  },
}));
