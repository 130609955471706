// @flow
import React, { forwardRef, memo } from 'react';

import SwitchMui, { SwitchProps } from '@mui/material/Switch';

import { styled } from '@mui/styles';

type TSwitchBaseMui = {} & SwitchProps;

const sizes = {
  small: 12,
  medium: 16,
};

const size = sizes.medium;

const Switcher = styled(SwitchMui)(({ theme }) => ({
  width: Math.round(size * 1.8),
  height: size,
  padding: 0,
  margin: theme.spacing(1),
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: Math.round(size * 0.6),
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: `translateX(${Math.round(size * 0.8)}px)`,
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: size * 0.25,
    '&.Mui-checked': {
      transform: `translateX(${Math.round(size * 0.8)}px)`,
      '& + .MuiSwitch-track': {
        backgroundColor: '#fff',
        opacity: 1,
        borderColor: theme.palette.primary.main,
      },
      '& > .MuiSwitch-thumb': {
        backgroundColor: theme.palette.primary.main,
      },
    },
    '&.Mui-checked.Mui-disabled': {
      '& + .MuiSwitch-track': {
        backgroundColor: '#fff',
        opacity: 0.5,
        borderColor: theme.palette.primary.main,
      },
      '& > .MuiSwitch-thumb': {
        backgroundColor: theme.palette.primary.main,
        opacity: 0.5,
      },
    },
    '&.Mui-disabled': {
      '& + .MuiSwitch-track': {
        opacity: 0.5,
      },
      '& > .MuiSwitch-thumb': {
        opacity: 0.5,
      },
    },
  },
  '& .MuiSwitch-thumb': {
    width: size * 0.5,
    height: size * 0.5,
    borderRadius: size * 0.5,
    backgroundColor: theme.palette.grey[500],
  },
  '& .MuiSwitch-track': {
    borderRadius: Math.round(size * 0.75),
    border: `2px solid ${theme.palette.grey[500]}`,
    opacity: 1,
    backgroundColor: '#fff',
    boxSizing: 'border-box',
  },
}));

const Switch: React$StatelessFunctionalComponent<TSwitchBaseMui> = forwardRef((props, ref) => (
  <Switcher {...props} ref={ref} />
));

export default memo(Switch);
