// @flow
import React from 'react';
import { compose, type Dispatch } from 'redux';
import { connect } from 'react-redux';
import { List, RecordOf } from 'immutable';
import { organizationBackupConnectionSelector } from 'domain/organization/organizationSelector';
import { getBackupSSOParamsAction, disconnectOrgBackupAction } from 'domain/organization/organizationActions';
import type { OrgBackupConnection } from 'domain/organization/types.js.flow';
import Connection, { COMING_SOON_STATUS } from './Connection';
import Stack from '@mui/material/Stack';

type Props = {
  organizationBackupConnection: List<RecordOf<OrgBackupConnection>>,
  getBackupSSOParams: Dispatch<getBackupSSOParamsAction>,
  disconnectOrgBackup: Dispatch<disconnectOrgBackupAction>,
};

type State = {
  selectedType: $PropertyType<OrgBackupConnection, 'type'> | null,
};

const comingSoonBackups = [
  {
    type: 'DROPBOX',
    name: 'Dropbox',
    connection: COMING_SOON_STATUS,
  },
  {
    type: 'I_CLOUD',
    name: 'iCloud',
    connection: COMING_SOON_STATUS,
  },
  {
    type: 'BOX',
    name: 'Box',
    connection: COMING_SOON_STATUS,
  },
];

class Backups extends React.Component<Props, State> {
  state = {
    selectedType: null,
  };

  getConnections = () => {
    const { organizationBackupConnection } = this.props;
    return organizationBackupConnection.concat(comingSoonBackups);
  };

  setSelectedType = (selectedType: $PropertyType<OrgBackupConnection, 'type'> | null) => () => {
    this.setState({ selectedType });
  };

  connect = (cloudStorageType: $PropertyType<OrgBackupConnection, 'type'>) => () => {
    const { getBackupSSOParams } = this.props;
    new Promise((resolve, reject) => {
      getBackupSSOParams({ cloudStorageType, resolve, reject });
    }).then((data) => {
      if (data && data.endpoint) {
        window.location = data.endpoint;
      }
    });
  };

  render() {
    const { disconnectOrgBackup } = this.props;
    const { selectedType } = this.state;

    return (
      <Stack flex="0">
        {this.getConnections().map((connection) => (
          <Connection
            key={connection.type}
            connection={connection}
            isSelected={connection.type === selectedType}
            onClickOpen={this.setSelectedType(connection.type)}
            onClickClose={this.setSelectedType(null)}
            onClickConnect={this.connect(connection.type)}
            onClickDisconnect={disconnectOrgBackup}
          />
        ))}
      </Stack>
    );
  }
}

Backups.displayName = 'Backups';

const mapStateToProps = (state) => ({
  organizationBackupConnection: organizationBackupConnectionSelector(state),
});

const mapDispatchToProps = {
  getBackupSSOParams: getBackupSSOParamsAction,
  disconnectOrgBackup: disconnectOrgBackupAction,
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(Backups);
