import type { Props } from './index';

export default (theme) => ({
  box: {
    position: 'relative',
    width: '100%',
    zIndex: 100,
  },
  button: {
    display: 'flex',
  },
  buttonPos: {
    padding: 4,
    display: 'flex',
    alignItems: 'center',
  },
  buttonOpen: {
    backgroundColor: ({ isWhite }: Props) => (isWhite ? theme.colors.primaryWhite : theme.colors.primaryLight),
    borderRadius: [2, 2, 0, 0],
  },
  items: {
    position: 'absolute',
    right: 0,
    borderRadius: [2, 0, 2, 2],
    backgroundColor: ({ isWhite }: Props) => (isWhite ? theme.colors.primaryWhite : theme.colors.primaryLight),
    padding: [5, 0],
    boxShadow: '0px 12px 24px rgba(0, 0, 0, 0.1)',
    minWidth: 100,
  },
});
