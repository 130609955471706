// @flow
import React, { useMemo, memo } from 'react';

import ArrowDrawArea from 'pages/document/components/ArrowDrawArea';
import DocumentBetaAlert from 'pages/document/components/DocumentBetaAlert';
import { DocumentLayoutPane } from 'pages/document/Body/Document/DocumentLayout/StyledCompoents';

type TDocumentLayout = {
  isVertical: boolean,
  size: number,
  children: React$Node,
  order: number,
  isShowGridBetaLabel: boolean,
};

// 8px - width separator, 34px - left bar width
// (8 + 34) / 2 = 21;
const offset = 21;

const DocumentLayout: React$StatelessFunctionalComponent<TDocumentLayout> = ({
  children,
  isShowGridBetaLabel,
  isVertical,
  size,
  order,
}) => {
  const style = useMemo(
    () => ({
      maxHeight: isVertical ? '100%' : `${size}%`,
      maxWidth: isVertical ? `calc(${size}% - ${offset}px)` : '100%',
      order,
    }),
    [isVertical, size, order],
  );

  return (
    <DocumentLayoutPane style={style}>
      {isShowGridBetaLabel && <DocumentBetaAlert />}
      {children}
      <ArrowDrawArea />
    </DocumentLayoutPane>
  );
};

export default memo(DocumentLayout);
