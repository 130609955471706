/* @flow */
import * as React from 'react';
import cx from 'classnames';
import type { List, RecordOf } from 'immutable';
import { AnonymousMessages as Messages } from 'components/RequestMessages';
import withAccordion from './withAccordion';
import { FormattedNumber } from 'react-intl';
import elements from 'components/elements';

type StatusType = 'read' | 'unread';

interface SendMessage {
  message: string;
  transactionId: string;
}

type Props = {
  classes: {|
    [key: string]: string,
  |},
  data: RecordOf<{
    id: string,
    status: StatusType,
    date: string,
    bankAccount: string,
    contact: string,
    description: string,
    messages: List<*>,
    amount: string,
    currency: string,
  }>,
  send: (d: SendMessage) => void,
};

function Item({ classes, data, send }: Props) {
  return (
    <div className={classes.content}>
      <header className={classes.header}>
        <div className={classes.head}>
          <h2 data-element={elements.je.request.transaction.date} className={cx(classes.date, classes.icon)}>
            {data.date}
          </h2>
          <div
            data-element={elements.je.request.transaction.amount}
            className={cx(classes.amount, { [classes.minus]: Number(data.amount) < 0 })}
          >
            <FormattedNumber
              value={data.amount}
              style="currency" // eslint-disable-line react/style-prop-object
              currency={data.currency}
            />
          </div>
        </div>
      </header>
      <article className={classes.description}>
        <h3
          data-element={elements.je.request.transaction.description}
          className={cx(classes.subTitle, classes.icon, classes.descriptionTitle)}
        >
          {data.description}
        </h3>
      </article>
      <div className={classes.message}>
        <Messages list={data.messages} send={(text: string) => send({ message: text, transactionId: data.id })} />
      </div>
    </div>
  );
}

export default withAccordion(Item);
