import React from 'react';
import { useRouteError } from 'react-router-dom';
import { notify } from 'lib/errorLogger/logger';

import CrashPage from 'components/CrashPage';

const ErrorElement = () => {
  const error = useRouteError();

  notify.captureEvent(error);

  return <CrashPage />;
};

export default ErrorElement;
