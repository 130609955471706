/* @flow */
import * as React from 'react';
import _ from 'lodash';
import type { TableProps } from '../table';

import { PinCell, PinCellText, PinCellAction } from 'components/Tables/grid/withPinnedCols/StyledComponents';
import PushPinIcon from '@mui/icons-material/PushPin';

type PinnedColsProps = {|
  onPinClick: (col: string) => void,
  permanentlyPinned?: Array<string>,
  pinnedCols: Array<string>,
|};

type Props = { ...TableProps, ...PinnedColsProps };

type WithPinnedCols = (w: React.Component<TableProps>) => React.StatelessFunctionalComponent<Props>;

export const withPinnedCols: WithPinnedCols = (WrappedComponent) => {
  const PinnedCols = (props: Props) => {
    const { getItem, onPinClick, permanentlyPinned = [], pinnedCols, meta } = props;
    const [cols, rows] = meta;
    const titleRow = _.get(rows, [0]);

    const withGetItems = (row: number, col: string) => {
      const item = getItem(row, col);
      return titleRow === row && !permanentlyPinned.includes(col) ? (
        <PinCell>
          <PinCellText>{item}</PinCellText>
          {cols.length > 1 && (
            <PinCellAction
              tabIndex={-1}
              pinned={pinnedCols.includes(col)}
              onClick={() => {
                onPinClick(col);
              }}
            >
              <PushPinIcon sx={{ fontSize: 14 }} />
            </PinCellAction>
          )}
        </PinCell>
      ) : (
        item
      );
    };

    return <WrappedComponent {...props} getItem={withGetItems} />;
  };

  return PinnedCols;
};

export default withPinnedCols;
