/* @flow */
import * as A from './actions';
import { EventsFactory } from './adapters';
import type { EventsStore } from './types.js.flow';

type Action = any;

export const reducer = {
  events(state: EventsStore = EventsFactory(), action: Action) {
    switch (action.type) {
      case A.getEventsAction.type:
        return state.update('countOfNewEvents', (c) => (action.payload.page === null ? 0 : c));

      case A.getEventsAction.success:
        return state
          .set('nextNumPage', action.payload.nextNumPage)
          .set('eventHash', action.payload.eventHash)
          .update('list', (u) => {
            const willMerge = action.payload.list.filter((item) => {
              const isExists = u.some((e) => e.id === item.id);
              return !isExists;
            });
            return u.merge(willMerge);
          });

      case A.clearEventsAction.type:
        return EventsFactory({ isOpened: state.isOpened });

      case A.getCountOfNewEventsAction.success:
        return state.set('countOfNewEvents', action.payload);

      case A.toggleEventPanelAction.type:
        return state.isOpened ? EventsFactory() : state.set('isOpened', true);

      default:
        return state;
    }
  },
};
