// @flow
import React, { useCallback, useContext } from 'react';
import { FormattedMessage } from 'react-intl';

import CascadingMenuContext from 'pages/document/components/DocumentMoreButton/components/CascadingMenuContext';
import HoverMenu from 'material-ui-popup-state/HoverMenu';
import {
  bindFocus,
  bindHover,
  bindMenu,
  usePopupState,
  type PopupState as TPopupState,
} from 'material-ui-popup-state/hooks';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { useTheme } from '@mui/material';

import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

export type TMoreOption = {
  disabled?: boolean,
  isHidden?: boolean,
  isDanger?: boolean,
  divided?: boolean,
  id: string,
  title: { id: string, defaultMessage: string },
  onClick: () => void,
  submenu?: Array<TMoreOption>,
};

export const MoreMenuItem: React$StatelessFunctionalComponent<TMoreOption> = ({ option }) => {
  const { rootPopupState, parentPopupState } = useContext(CascadingMenuContext);
  const { direction } = useTheme();
  const isRtl = direction === 'rtl';
  const { divided, disabled, isDanger, title, submenu, onClick, id } = option;

  const popupState = usePopupState({
    id,
    variant: 'popover',
    parentPopupState,
  });

  const handleClick = useCallback(
    (e) => {
      rootPopupState.close(e);
      if (typeof onClick === 'function') onClick();
    },
    [rootPopupState, onClick],
  );

  const boundProps = submenu
    ? {
        ...bindHover(popupState),
        ...bindFocus(popupState),
      }
    : {};

  return (
    <>
      {divided && <Divider component="li" />}
      <MenuItem disabled={disabled} onClick={handleClick} {...boundProps}>
        <Typography variant="body2" flexGrow={1} {...(isDanger ? { color: 'error' } : {})}>
          <FormattedMessage {...title} />
        </Typography>

        {submenu && (
          <>
            {isRtl ? <KeyboardArrowLeftIcon sx={{ mr: -1 }} /> : <KeyboardArrowRightIcon sx={{ mr: -1 }} />}
            <CascadingHoverMenu popupState={popupState} options={submenu} isRtl={isRtl} />
          </>
        )}
      </MenuItem>
    </>
  );
};

type TCascadingHoverMenu = {
  popupState: TPopupState,
  options: Array<TMoreOption>,
  isRtl: boolean,
} & TMoreOption;
export const CascadingHoverMenu: React$StatelessFunctionalComponent<TCascadingHoverMenu> = ({
  popupState,
  options,
  isRtl,
  ...props
}) => {
  const { rootPopupState } = useContext(CascadingMenuContext);
  const context = React.useMemo(
    () => ({
      rootPopupState: rootPopupState || popupState,
      parentPopupState: popupState,
    }),
    [rootPopupState, popupState],
  );

  const list = options.filter((option) => !option.isHidden);

  return (
    <CascadingMenuContext.Provider value={context}>
      <HoverMenu
        anchorOrigin={{ vertical: 'top', horizontal: isRtl ? 'left' : 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: isRtl ? 'right' : 'left' }}
        PaperProps={{ sx: { ...(isRtl ? { mr: -0.5 } : { ml: 0.5 }) } }}
        {...props}
        {...bindMenu(popupState)}
      >
        {list.map((option) => (
          <MoreMenuItem option={option} key={option.id} />
        ))}
      </HoverMenu>
    </CascadingMenuContext.Provider>
  );
};
