/* @flow */
import React from 'react';

import IconButton from '@mui/material/IconButton';

import ClearIcon from '@mui/icons-material/Clear';

type ItemPropsType = $ReadOnlyArray<{|
  id: string,
  className: string,
  span?: number,
|}>;

export function renderColgroup(props: ItemPropsType) {
  return (
    <colgroup>
      {props.map((c) => (
        <col key={c.id} className={c.className} span={c.span} />
      ))}
    </colgroup>
  );
}

type RemovButtonPropsType = {
  classes: {|
    [key: string]: string,
  |},
  removeHandler?: (r: number) => void,
};

export function removeButton({ classes, removeHandler }: RemovButtonPropsType) {
  return (row: number) => (
    <IconButton
      size="small"
      color="error"
      className={classes.removeBtn}
      onClick={() => (typeof removeHandler === 'function' ? removeHandler(row) : null)}
    >
      <ClearIcon sx={{ fontSize: 18 }} />
    </IconButton>
  );
}
