import mediaQuery from 'lib/mediaQuery';

export default (theme) => ({
  container: {
    display: 'flex',
    flex: [0, 0, '50px'],
    alignItems: 'center',
    background: theme.colors.primaryLight,
    padding: [0, 10],
    height: 50,
    width: '100%',
    [`@media (max-height: ${mediaQuery.maxHeight})`]: {
      fontSize: 12,
      height: 35,
      flexBasis: 35,
    },
  },
  title: {
    marginLeft: 10,
    display: 'flex',
    justifyContent: 'flex-start',
    flexBasis: 'calc(100% - 60px)',
    overflow: 'hidden',
    fontSize: 14,
    color: theme.colors.primaryWhite,
    [`@media (max-height: ${mediaQuery.maxHeight})`]: {
      fontSize: 12,
    },
    '& > span:first-child': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
  role: {
    color: theme.colors.primaryWhite,
  },
});
