// @flow
import type { CategoryRecord, CategoriesList, RouterNestedCategories } from './types.js.flow';
import { List } from 'immutable';
import { CategoryFactory } from './adapters';
import { CATEGORY_SYSTEM_TYPE_FIELD, CATEGORY_SYSTEM_TYPES } from './constants';

import * as ACL from 'domain/restriction';

// transforms flat list to nested list
export const createTreeFromNestedSet = (arr: CategoriesList): CategoriesList =>
  arr
    .sort((a, b) => a.order - b.order || a.left - b.left)
    .reduce((tree, n) => {
      let curr = null;
      let nextIndex = null;
      let next = tree;
      const updatePath = ['children'];
      const parentList = [];

      while (next) {
        curr = next;
        next = curr.children.find((c) => c.left < n.left && c.right > n.right);
        if (next) {
          nextIndex = curr.children.findIndex((c) => c.left < n.left && c.right > n.right);
          updatePath.push(nextIndex, 'children');
          if (next.id !== null && next.id !== undefined) {
            parentList.push(next.id);
          }
        }
      }
      // the only affected field we calculate and update for category record
      const updatedRecord = n.set('parentList', List(parentList));
      return tree.updateIn(updatePath, (list) => list.push(updatedRecord));
    }, CategoryFactory({ children: ((new List(): any): CategoriesList) })).children;

export const getRootItems = (list: CategoriesList): CategoriesList =>
  list.filter((item) => !item.parent_id).sort((a, b) => a.order - b.order || a.left - b.left);

// takes company route category params and returns most nested one
// for 3/4/5 route it should return 5,
// for 3/4 it should return 4
// for 3 it should return 3
// eslint-disable-next-line max-len
export const getNestedCategory = ({ category1, category2, category3 }: RouterNestedCategories): string =>
  category3 || category2 || category1;

export const getFirstCategory = (list: CategoriesList) =>
  list
    .filter((item) => !item.parent_id)
    .sort((a, b) => a.order - b.order || a.left - b.left)
    .first({});

export const getSystemType = (category: CategoryRecord): string => category.get(CATEGORY_SYSTEM_TYPE_FIELD);

export const isSystemType = (category: CategoryRecord, type: string): boolean => getSystemType(category) === type;

export const findCatBySystemType = (categories: CategoriesList, type: string) =>
  categories.find((cat) => isSystemType(cat, type));

export const findCatById = (categories: CategoriesList, id: number | string) =>
  categories.find((cat) => String(cat.id) === String(id));

export const isCategoryAll = (category: CategoryRecord) =>
  category ? isSystemType(category, CATEGORY_SYSTEM_TYPES.all) : false;

export const getCategoryInbox = (categories: CategoriesList) =>
  findCatBySystemType(categories, CATEGORY_SYSTEM_TYPES.inbox);

export const isToApproveCategory = (category: CategoryRecord) =>
  category ? isSystemType(category, CATEGORY_SYSTEM_TYPES.to_approve) : false;

export const getCategoryAll = (categories: CategoriesList) =>
  findCatBySystemType(categories, CATEGORY_SYSTEM_TYPES.all);

export const getMovableCategories = (currentCategoryId: number | string, rootCategories: CategoriesList) =>
  rootCategories.filter((cat) => cat.documentMovable && String(cat.id) !== String(currentCategoryId));

export const isMovableCategories = (currentCategoryId: number | string, rootCategories: CategoriesList) =>
  getMovableCategories(currentCategoryId, rootCategories).size;

type MoveToResolveData = {
  currentRootCategoryId: number | string,
  rootCategories: CategoriesList,
  isGranted: (x: string) => boolean, // ACL resolver
};

export const isAllowMoveToCategories = ({ isGranted, rootCategories, currentRootCategoryId }: MoveToResolveData) =>
  isGranted(ACL.IS_ACCOUNT) && isMovableCategories(currentRootCategoryId, rootCategories);
