/* @flow */
import * as React from 'react';
import { withStyles } from '@mui/styles';
import type { List } from 'immutable';
import sheet from './sheet';
import CreateMessage from './create';
import MessageList from './list';
import Accordion from 'components/Accordion';
import Title from './title';
import { getDate, fullName } from './helper';
import type { IMessage } from './types.js.flow';
import elements from 'components/elements';

type Props = {
  classes: {|
    [key: string]: string,
  |},
  list: List<IMessage>,
  send: (text: string) => void,
};

type State = {
  text: string,
};

class Messages extends React.Component<Props, State> {
  state = {
    text: '',
  };

  get title(): React$Node {
    const { list, classes } = this.props;
    const item = list.last();
    return (
      <span data-element={elements.je.request.transaction.messageTitle} className={classes.messageTitle}>
        <b>{fullName(item)}</b>
        {` [${getDate(item.timestamp)}]: ${item.message}`}
      </span>
    );
  }

  handleOnChange = (text) => {
    this.setState({ text });
  };

  handleSendMessage = () => {
    const { text } = this.state;
    if (text.length > 0) {
      this.setState(
        {
          text: '',
        },
        () => {
          this.props.send(text);
        },
      );
    }
  };

  render() {
    const { classes, list, send } = this.props;
    return (
      <div className={classes.container}>
        <Accordion title={(up: boolean) => <Title isUP={up} title={this.title} />}>
          <div className={classes.messages} data-element={elements.je.request.transaction.incomingMessage}>
            <MessageList list={list} />
          </div>
        </Accordion>
        <CreateMessage
          classes={classes}
          send={send}
          isAnonymous
          value={this.state.text}
          onChange={this.handleOnChange}
          className={classes.anonymous}
        >
          <button
            data-element={elements.je.request.transaction.reply}
            className={classes.btn}
            type="button"
            onClick={this.handleSendMessage}
          />
        </CreateMessage>
      </div>
    );
  }
}

export default withStyles(sheet)(Messages);
