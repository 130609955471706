// type @flow
import React from 'react';
import { FormattedMessage } from 'react-intl';

import Button from '@mui/material/Button';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';

type TUserAssigmentButton = {
  disabled: boolean,
  assigned: boolean,
  onClick: () => void,
};

const AssigmentButton: React$StatelessFunctionalComponent<TUserAssigmentButton> = ({ disabled, assigned, onClick }) => (
  <Button
    size="small"
    color={assigned ? 'error' : 'primary'}
    disabled={disabled}
    onClick={onClick}
    startIcon={assigned ? <RemoveIcon /> : <AddIcon />}
    variant="outlined"
  >
    <FormattedMessage id={`button.${assigned ? 'revoke' : 'assign'}`} defaultMessage={assigned ? 'Revoke' : 'Assign'} />
  </Button>
);

export default AssigmentButton;
