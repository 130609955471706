// @flow
import React, { useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { documentGetLinkedAction, linkedSelector } from 'domain/documents';
import useIntersectionObserver from 'hooks/useIntersectionObserver';

import Box from '@mui/material/Box';

import CircularProgress from '@mui/material/CircularProgress';
import useToggle from 'hooks/useToggle';

type TLinkedNote = {};

const LoadMoreObserver: React$StatelessFunctionalComponent<TLinkedNote> = () => {
  const dispatch = useDispatch();
  const { tag, pageToken } = useSelector(linkedSelector);
  const [isBusy, toggleIsBusy] = useToggle();
  const hasNext = typeof pageToken === 'string';
  const elementObserverRef = useRef();
  const entry = useIntersectionObserver(elementObserverRef, {
    root: null,
    rootMargin: '0px',
    threshold: 1,
  });

  const fetchDocuments = useCallback(() => {
    if (typeof pageToken === 'string') {
      toggleIsBusy();

      new Promise((resolve, reject) => {
        dispatch(documentGetLinkedAction({ tag, pageToken, resolve, reject }));
      }).finally(() => toggleIsBusy());
    }
  }, [tag, pageToken, dispatch, toggleIsBusy]);

  useEffect(() => {
    // when we go down and catch observeIntersection, we have a delay
    // and useEffect make one more fetching before response is back, because pageToken is changed and fetchDocuments Link is changed too
    // so for prevent this behaviour - added isBusy flag
    if (entry && entry.isIntersecting && !isBusy) {
      fetchDocuments();
    }
  }, [entry, fetchDocuments, isBusy]);

  return (
    // hasNext - marker that we opened linked panel and ready to observe load more box
    // permanent drawer is render immediately and LoadMoreObserver is rendered too, so we have intersection on first render
    <Box display="flex" justifyContent="center" py={isBusy ? 3 : 0}>
      {/* display only when we are fetching data */}
      {isBusy && <CircularProgress />}
      {/* display only when we have pageToken for next data chunk */}
      {hasNext && <div ref={elementObserverRef} />}
    </Box>
  );
};

export default LoadMoreObserver;
