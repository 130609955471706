import { styled } from '@mui/material/styles';

export const UploadText = styled('span')(({ theme }) => ({
  color: theme.palette.primary.main,
  textDecoration: 'underline',
}));

export const FileInput = styled('input')(() => ({
  width: '100%',
  height: '100%',
  position: 'absolute',
  left: 0,
  top: 0,
}));

export const SubjectInput = styled('input')(() => ({
  width: '100%',
  border: 'none',
  fontSize: 12,
  '&:focus': {
    outline: 'none',
    border: 'none',
  },
}));

export const MessageInput = styled('textarea')(() => ({
  height: 70,
  width: '100%',
  overflowY: 'auto',
  marginBottom: 20,
  resize: 'none',
  border: 'none',
  fontSize: 14,
  '&:focus': {
    outline: 'none',
    border: 'none',
  },
}));
