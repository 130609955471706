// @flow
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';

import Dialog from 'components/mui/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import {
  ContentArea,
  NoteTextarea,
  NotePaletteBar,
  NotePaletteRadio,
} from 'pages/common/Dialog/DialogNotes/StyledComponents';
import { NOTE_PALETTE, NOTE_PALETTE_DEFAULT_KEY } from 'components/LabeledThemeProvider/notesPalette';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CircleIcon from '@mui/icons-material/Circle';
import useStickyNotes from './useStickyNotes';

type TDialogNotes = {
  onClose: () => void,
  value: string,
  noteColorKey: string,
  documentID: string,
  open: boolean,
};

const DialogNotes: React$StatelessFunctionalComponent<TDialogNotes> = ({
  value: content,
  noteColorKey,
  onClose,
  open,
  documentID,
}) => {
  const { formatMessage } = useIntl();

  const { value, colorKey, loading, handleChange, handleChangeRadio, onNotesUpdate, onNotesRemove, disableUpdate } =
    useStickyNotes({ onClose, content, noteColorKey, closeAfterSave: true, documentID });

  const stopPropagation = (e: SyntheticInputEvent<HTMLTextAreaElement>) => {
    e.nativeEvent.stopPropagation();
  };

  const DialogTitle = useMemo(
    () =>
      value
        ? formatMessage({ id: 'notes.editNote', defaultMessage: 'Edit note' })
        : formatMessage({ id: 'notes.addNote', defaultMessage: 'Add note' }),
    [value, formatMessage],
  );

  return (
    <Dialog
      open={open}
      onClose={onClose}
      title={DialogTitle}
      maxWidth="sm"
      withActions={false}
      withContent={false}
      withCloseBtn
      draggable
    >
      <DialogContent>
        <ContentArea>
          <NoteTextarea
            autoFocus
            placeholder={formatMessage({
              id: 'notes.placeholder',
              defaultMessage: 'Write note text',
            })}
            value={value}
            onChange={handleChange}
            onKeyDown={stopPropagation}
            rows="10"
            name="note"
            multiline
            fullWidth
            InputProps={{
              sx: {
                backgroundColor: NOTE_PALETTE[colorKey].color,
              },
            }}
          />
          <NotePaletteBar
            onChange={handleChangeRadio}
            value={colorKey}
            defaultChecked={NOTE_PALETTE_DEFAULT_KEY}
            name="color"
          >
            {Object.entries(NOTE_PALETTE).map(([key, { color }]) => (
              <NotePaletteRadio
                value={key}
                key={key}
                icon={<CircleIcon sx={{ color }} />}
                checkedIcon={<CheckCircleIcon />}
              />
            ))}
          </NotePaletteBar>
        </ContentArea>
      </DialogContent>
      <DialogActions>
        {content && content.length > 0 ? (
          <Button color="error" onClick={onNotesRemove} disabled={loading}>
            {formatMessage({
              id: 'button.delete',
              defaultMessage: 'Delete',
            })}
          </Button>
        ) : (
          <Button variant="text" onClick={onClose}>
            {formatMessage({
              id: 'button.cancel',
              defaultMessage: 'Cancel',
            })}
          </Button>
        )}

        <Button onClick={onNotesUpdate} disabled={disableUpdate}>
          {formatMessage({
            id: 'button.save',
            defaultMessage: 'Save',
          })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogNotes;
