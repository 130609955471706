// @flow
import React from 'react';

import {
  type TNotePaletteKeys,
  NOTE_PALETTE,
  NOTE_PALETTE_DEFAULT_KEY,
} from 'components/LabeledThemeProvider/notesPalette';
import { TILE_Z_INDEXES } from 'pages/company/TileGrid/TileCard/components/StyledComponents';
import ButtonBase from '@mui/material/ButtonBase';

import { styled, lighten } from '@mui/material/styles';

type TCardNote = {
  note: string,
  colorKey: TNotePaletteKeys,
};

const NoteButton = styled(ButtonBase, {
  label: 'TileNoteButton',
  shouldForwardProp: (propName) => propName !== 'colorKey',
})(({ theme, colorKey }) => ({
  position: 'absolute',
  top: 48,
  left: 0,
  bottom: 48,
  backgroundColor: NOTE_PALETTE[colorKey].color,
  padding: theme.spacing(1),
  fontSize: theme.typography.pxToRem(12),
  borderRadius: '0 4px 4px 0',
  minWidth: 32,
  boxShadow: theme.shadows[1],
  zIndex: TILE_Z_INDEXES.cardNote,
  fontFamily: theme.typography.fontFamily,

  '&:hover': {
    backgroundColor: lighten(NOTE_PALETTE[colorKey || NOTE_PALETTE_DEFAULT_KEY].color, 0.2),
    cursor: 'pointer',
    boxShadow: theme.shadows[3],
  },
}));

// : TODO: remove after approve design
// writingMode - doesn't work in button, need some other tag
// const NoteVerticalLabel = styled('span')(() => ({
//   fontWeight: 500,
//   writingMode: 'vertical-lr',
//   textOrientation: 'mixed',
// }));

const NoteText = styled('span')(() => ({
  whiteSpace: 'pre-wrap',
  overflow: 'hidden',
  // https://caniuse.com/css-line-clamp
  display: '-webkit-box',
  WebkitLineClamp: 5,
  WebkitBoxOrient: 'vertical',
  width: 100,
  textAlign: 'left',
  alignSelf: 'baseline',
  wordBreak: 'break-word',
}));

const CardNote: React$StatelessFunctionalComponent<TCardNote> = ({ note, colorKey, onClickNote }) => (
  <NoteButton onClick={onClickNote} colorKey={colorKey}>
    <NoteText>{note}</NoteText>
  </NoteButton>
);

export default CardNote;
