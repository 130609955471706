/* @flow */
import React, { useCallback, useEffect, useState } from 'react';
import { OrderedSet, List, Map } from 'immutable';
import Api from 'domain/api';
import {
  OptionEventFactory,
  OptionUserFactory,
  ChipDateFactory,
} from 'components/mui/Layouts/components/EventsHistoryPanel/components/FiltersBlock/helpers';
import useDidUpdateEffect from 'hooks/useDidUpdateEffect';
import useDebounceValue from 'hooks/useDebounceValue';
import { FormattedMessage } from 'react-intl';
import type Moment from 'moment';

import type {
  TFiltersListRaw,
  TUserOptionRaw,
  TEventOptionRaw,
  TChips,
  TChip,
  TOptions,
} from 'components/mui/Layouts/components/EventsHistoryPanel/components/FiltersBlock/types.js.flow';

import EventsHistoryTextFilter from 'components/mui/Layouts/components/EventsHistoryPanel/components/FiltersBlock/TextFilter';
import EventsHistoryFilterChips from 'components/mui/Layouts/components/EventsHistoryPanel/components/FiltersBlock/FilterChips';
import EventsHistoryDropdownFilter from 'components/mui/Layouts/components/EventsHistoryPanel/components/FiltersBlock/DropdownFilter';
import EventsHistoryDateRangeFilter from 'components/mui/Layouts/components/EventsHistoryPanel/components/FiltersBlock/DateRangeFilter';
import ExportButton from 'components/mui/Layouts/components/EventsHistoryPanel/components/ExportButton';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';

import SupervisedUserCircleOutlinedIcon from '@mui/icons-material/SupervisedUserCircleOutlined';
import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined';

import { styled } from '@mui/material/styles';
import { grey } from '@mui/material/colors';

import elements from 'components/elements';

const Wrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(2),
  borderBottom: `1px solid ${grey[300]}`,
}));

type TEventsHistoryFiltersBlock = {
  onChangeFilters: (filters: { [string]: Array<string> }) => void,
  onChangeDate: (from: Date | null, to: Date | null) => void,
  onClickExport: () => void,
  dateFormat: string,
  isDisabledExport: boolean,
  initialSearchTypes?: Array<string>,
};

const EventsHistoryFiltersBlock: React$StatelessFunctionalComponent<TEventsHistoryFiltersBlock> = ({
  onChangeFilters,
  onChangeDate,
  onClickExport,
  dateFormat,
  isDisabledExport,
  initialSearchTypes,
}) => {
  const [chips, setChips] = useState<TChips>(OrderedSet());
  const [commonOptions, setCommonOptions] = useState<Map<string, TOptions>>(Map());
  const [text, setText] = useState('');
  const [dateRange, setDateRange] = useState([null, null]);
  // by default true, if yor change default value, pls check behaviour in EventsHistoryDropdownFilter for options
  const [isBusy, setIsBusy] = useState(true);

  const debounceText = useDebounceValue(text, 500);

  const handleAdd = useCallback((chip: TChip) => {
    setChips((prevChips) => prevChips.add(chip));
  }, []);

  const handleRemove = useCallback(
    (chip: TChip) => {
      setChips((prevChips) => prevChips.delete(chip));

      if (chip.type === 'date') {
        setDateRange([null, null]);
        onChangeDate(null, null);
      }
    },
    [onChangeDate],
  );

  const handleChangeText = useCallback((value: string) => {
    setText(value);
  }, []);

  const handleChangeDate = useCallback(
    ([from, to]: Array<Moment>) => {
      const label = `${from.format(dateFormat)} - ${to.format(dateFormat)}`;
      const chipDate = ChipDateFactory({ label, value: label });

      setChips((prevChips) => {
        // find existed date chip, if true - update current date chip with new values
        if (prevChips.find((entry) => entry.type === 'date')) {
          return prevChips.map((chip) => (chip.type === 'date' ? chipDate : chip));
        }
        // else add to OrderedSet
        return prevChips.add(chipDate);
      });

      onChangeDate(from.toDate(), to.toDate());
      setDateRange([from, to]);
    },
    [onChangeDate, dateFormat],
  );

  const getOptionsList = useCallback(() => {
    Api.getEventFilters()
      .then(({ data }: { data: TFiltersListRaw }) => {
        if (data) {
          const users = data.users.reduce((list, user: TUserOptionRaw) => list.push(OptionUserFactory(user)), List());
          const events = data.types.reduce(
            (list, event: TEventOptionRaw) => list.push(OptionEventFactory(event)),
            List(),
          );
          if (initialSearchTypes?.length) {
            const initialEventChips = events.filter(({ value }) => initialSearchTypes.includes(value));
            handleAdd(...initialEventChips);
          }

          setCommonOptions(Map({ users, events }));
        }
      })
      .finally(() => setIsBusy(false));
  }, [initialSearchTypes, handleAdd]);

  useEffect(() => {
    getOptionsList();
  }, [getOptionsList]);

  useDidUpdateEffect(() => {
    const filters = {
      usersSearch: chips
        .filter((chip) => chip.type === 'user')
        .toArray()
        .map((chip) => chip.value),
      typesSearch: chips
        .filter((chip) => chip.type === 'event')
        .toArray()
        .map((chip) => chip.value),
      textsSearch: chips
        .filter((chip) => chip.type === 'text')
        .toArray()
        .map((chip) => chip.value),
      freeTextSearch: debounceText ? [debounceText] : [],
    };

    onChangeFilters(filters);
  }, [chips, debounceText, onChangeFilters]);

  return (
    <Wrapper>
      <EventsHistoryTextFilter handleAdd={handleAdd} handleChangeText={handleChangeText} />

      <Stack direction="row" spacing={0.5} alignItems="center" justifyContent="space-between" mb={1}>
        <EventsHistoryDateRangeFilter onChange={handleChangeDate} range={dateRange} />
        <EventsHistoryDropdownFilter
          options={commonOptions.get('users', List())}
          isBusy={isBusy}
          toggleText={<FormattedMessage id="eventsHistory.panel.filter.users" defaultMessage="Users" />}
          StartIcon={<SupervisedUserCircleOutlinedIcon />}
          handleOptionClick={handleAdd}
          dataElement={elements.history.dropdownFilter.usersDropdown}
        />
        <EventsHistoryDropdownFilter
          options={commonOptions.get('events', List())}
          isBusy={isBusy}
          toggleText={<FormattedMessage id="eventsHistory.panel.filter.events" defaultMessage="Events" />}
          StartIcon={<ListAltOutlinedIcon />}
          handleOptionClick={handleAdd}
          dataElement={elements.history.dropdownFilter.eventsDropdown}
        />
        <Divider orientation="vertical" sx={{ height: 24 }} />
        <ExportButton onClick={onClickExport} isDisabled={isDisabledExport} />
      </Stack>

      <EventsHistoryFilterChips chips={chips} onRemove={handleRemove} />
    </Wrapper>
  );
};

export default EventsHistoryFiltersBlock;
