/* @flow */
import * as React from 'react';
import { compose, type Dispatch } from 'redux';
import { connect } from 'react-redux';
import OutsideClickHandler from 'components/OutsideClickHandler';
import elements from 'components/elements';

// styles
import { withStyles } from '@mui/styles';
import cx from 'classnames';
import sheet from './sheet';
import Tooltip from 'components/Tooltip';
import BarIcon from 'components/BarIcon';
import Chat from 'components/Chat__legacy';
import DocChat from 'components/Chat__legacy/document';
import { currentCompanyUnreadRequestsCountSelector, companyMessagesCountSelector } from 'domain/companies';
import {
  documentUnreadMessagesCountSelector,
  isOpenSelector,
  setOpenAction,
  setActiveChannelAction,
} from 'domain/chat';

import { rtlEnable } from 'domain/env';

type Props = {|
  classes: {
    [key: string]: string,
  },
  children: React$Node,
  isChatOpen: boolean,
  rtl: boolean,
  onOpen: () => void,
  unreadDocument: number,
  unreadCompany: number,
  unreadRequestsCount: number,
  companyId: ?string,
  documentId: ?string,
  setChatOpen: Dispatch<typeof setOpenAction>,
  setActiveChannel: Dispatch<typeof setActiveChannelAction>,
  isDisable?: boolean,
|};

type State = {|
  lastUnread: number,
  isNewMessages: boolean,
|};

const getUnreadCount = ({ documentId, unreadCompany, unreadRequestsCount, unreadDocument }: Props) =>
  documentId ? unreadDocument : unreadCompany + unreadRequestsCount;

class TopChat extends React.PureComponent<Props, State> {
  state = {
    countUnread: 0,
    isNewMessages: false,
  };

  componentDidMount() {
    this.clearNewMessage();
  }

  componentDidUpdate() {
    this.clearNewMessage();
  }

  static getDerivedStateFromProps(props: Props, state: State) {
    const countUnread = getUnreadCount(props);
    return { countUnread, isNewMessages: countUnread > state.countUnread };
  }

  toggleChat = () => {
    const { setActiveChannel, setChatOpen, isChatOpen, companyId, isDisable = false } = this.props;
    if (!isDisable) {
      setChatOpen(!isChatOpen);
      if (isChatOpen) {
        setActiveChannel({ threadId: null, companyId });
      }
    }
  };

  closeChat = () => {
    const { isChatOpen } = this.props;
    if (isChatOpen) {
      this.toggleChat();
    }
  };

  clearNewMessage = () => {
    const { isNewMessages } = this.state;
    if (isNewMessages) {
      this.timer = setTimeout(() => {
        this.setState({ isNewMessages: false });
      }, 1500);
    }
  };

  timer: TimeoutID;

  render() {
    const { classes, isChatOpen, companyId, documentId } = this.props;
    const { countUnread, isNewMessages } = this.state;

    return (
      <div className={cx(classes.topChatWrapper)}>
        <div className={classes.chatBtn}>
          {!!countUnread && (
            <button
              onClick={this.toggleChat}
              className={cx(classes.chatUnread, { [classes.newMessages]: isNewMessages })}
            >
              {countUnread}
            </button>
          )}

          <Tooltip id="header.menu.chat" defaultMessage="Chat" disable={isChatOpen} className={classes.tooltip}>
            <BarIcon
              icon="chat"
              action={this.toggleChat}
              data-element={elements.header.chat}
              bgColor={countUnread ? '#F47F2A' : '#FFF'}
            />
          </Tooltip>
        </div>

        {isChatOpen && (
          <div className={cx(classes.chatPlace)}>
            <div className={cx(classes.wrapper)}>
              <div className={classes.widgetPlaceArrow} />
              <div
                className={cx(classes.chatBox, {
                  [classes.openChatBox]: isChatOpen,
                  [classes.closeChatBox]: !isChatOpen,
                })}
              >
                <OutsideClickHandler onOutsideClick={this.closeChat} enableMaxHeight>
                  {documentId ? (
                    <DocChat companyId={companyId} documentId={documentId} />
                  ) : (
                    <Chat companyId={companyId} />
                  )}
                </OutsideClickHandler>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  rtl: rtlEnable(state),
  isChatOpen: isOpenSelector(state),
  unreadDocument: documentUnreadMessagesCountSelector(state),
  unreadCompany: companyMessagesCountSelector(state),
  unreadRequestsCount: currentCompanyUnreadRequestsCountSelector(state),
});

const mapDispatchToProps = {
  setChatOpen: setOpenAction,
  setActiveChannel: setActiveChannelAction,
};

export default compose(withStyles(sheet), connect(mapStateToProps, mapDispatchToProps))(TopChat);
