// @flow
import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { rtlEnable } from 'domain/env';
import type { $npm$ReactIntl$MessageDescriptor } from 'react-intl';
import elements from 'components/elements';

import RouterLink from 'components/mui/Router/RouterLink';
import Tooltip from 'components/mui/Tooltip';
import IconButton from '@mui/material/IconButton';

import KeyboardBackspaceOutlinedIcon from '@mui/icons-material/KeyboardBackspaceOutlined';

type TBackToArrowBase = {
  path: string,
  onClick?: () => void,
  tooltip?: $npm$ReactIntl$MessageDescriptor | string | null | boolean,
};

const BackToArrowBase: React$StatelessFunctionalComponent<TBackToArrowBase> = ({ path, onClick, tooltip }) => {
  const isRtl = useSelector(rtlEnable);
  const scaleXValue = isRtl ? -1 : 1;

  return (
    <Tooltip t={tooltip || { id: 'tooltip.backToWorkspace', defaultMessage: 'Back to Workspace' }}>
      <IconButton
        edge="start"
        color="primary"
        sx={{ mr: 1 }}
        {...(typeof onClick === 'function' ? { onClick } : { component: RouterLink, href: path })}
        data-element={elements.header.back}
      >
        <KeyboardBackspaceOutlinedIcon sx={{ transform: `scaleX(${scaleXValue})` }} />
      </IconButton>
    </Tooltip>
  );
};

export default memo(BackToArrowBase);
