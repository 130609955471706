// @flow
/* eslint-disable max-len */
import { selector } from 'lib/selectors';
import { Map, List, Set } from 'immutable';
import { compose } from 'redux';
import { type ReconciliationStore, type searchJournal } from './types.js.flow';
import type { DokkaStore } from '../types.js.flow';
import { type JornalEntryType, getRowByNumber, jeSorterCb } from '../journal/helper';
import { rowAdapter, actionRestricted, type ValidatableTransaction } from './helper';
import {
  journalSelector as JournalEntrySelector,
  dateColumnSelector,
  amountColumnSelector,
  descriptionColumnSelector,
} from 'domain/journal/selectors';
import { RECONCILED, MATCH } from 'components/Tables/ReconciliationCell/constants';
import { denormalize } from 'domain/journal/normalizer';
import type { ReconcileCellValueType } from '../journal/types.js.flow';

type SelectorType<T> = (s: DokkaStore) => T;

// transforms List of rows to flat Map,
// updating Map keys to be 1,2,3 instead of 4,7,11
const flatternJE = (entry: List<JornalEntryType>): JornalEntryType => {
  const rowsList = entry;
  return rowsList.reduce((A, V, K) => A.merge(rowAdapter(V, K)), Map());
};

const getHeaderLineEntries = (entry: JornalEntryType, line: number): JornalEntryType =>
  getRowByNumber(entry, Set([line]));

const mergeHeaderLineEntries = (j: ReconciliationStore, headerLineEntries: JornalEntryType) =>
  j.selectedEntry.unshift(headerLineEntries); // adds headerline to the start of lines List

// transactions with openBalance should return openBalance. if empty, amount is returned
const getTransactionAmount = (
  cell: JornalEntryType,
  row: number,
  amountColumn: string,
  openBalanceColumn: string,
): number =>
  // $FlowFixMe
  parseFloat(cell.getIn([`${openBalanceColumn}${row}`, 'cellSet', 'value'], 0)) ||
  // $FlowFixMe
  parseFloat(cell.getIn([`${amountColumn}${row}`, 'cellSet', 'value'], 0));

const reconciliation = (state): ReconciliationStore => state.reconciliation;

// loading
export const loadinglSelector: SelectorType<boolean> = selector(reconciliation, (j: ReconciliationStore) => j.loading);

// search data
export const reconcileJournalSelector: SelectorType<searchJournal> = selector(
  reconciliation,
  (j: ReconciliationStore) => denormalize(j.searchEntry),
);
export const reconcileJournalEntriesSelector: SelectorType<searchJournal> = selector(
  reconcileJournalSelector,
  (j: searchJournal) => j.entry.sort(jeSorterCb),
);

export const settingsSelector: SelectorType<Set<number>> = selector(
  reconcileJournalSelector,
  (j: searchJournal) => j.settings,
);
export const reconcileHeaderLineNumberSelector: SelectorType<number> = selector(
  settingsSelector,
  (lines: Set<number>) => (Math.max(...lines) + 1) | 5,
);
export const reconcileHeaderLineEntriesSelector: SelectorType<searchJournal> = selector(
  reconcileJournalEntriesSelector,
  reconcileHeaderLineNumberSelector,
  getHeaderLineEntries,
);
export const linesSelector: SelectorType<Set<number>> = selector(
  reconcileJournalSelector,
  (j: searchJournal) => j.lines,
);
export const lineItemsNumbersSelector: SelectorType<Set<number>> = selector(
  reconcileJournalSelector,
  (j: searchJournal) => j.line_items,
);
export const reconcileSearchParamsSelector: SelectorType<JornalEntryType> = selector(
  reconcileJournalEntriesSelector,
  settingsSelector,
  getRowByNumber,
);
export const reconcileSearchResultsSelector: SelectorType<JornalEntryType> = selector(
  reconcileJournalEntriesSelector,
  linesSelector,
  getRowByNumber,
);
export const reconcileSearchResultsCountSelector: SelectorType<number> = selector(
  reconcileJournalSelector,
  (j: searchJournal) => j.line_items.size,
);
export const reconcileSearchResultsTotalSelector: SelectorType<number> = selector(
  reconcileJournalSelector,
  (j: searchJournal) => j.lineCount,
);

// selected data
export const reconcileSelectedJournalListSelector: SelectorType<List<JornalEntryType>> = selector(
  reconciliation,
  (j: ReconciliationStore) => j.selectedEntry,
);
export const reconcileSelectedJournalListWithHeaderSelector: SelectorType<List<JornalEntryType>> = selector(
  reconciliation,
  reconcileHeaderLineEntriesSelector,
  mergeHeaderLineEntries,
);
export const reconcileSelectedJournalCounterSelector: SelectorType<JornalEntryType> = selector(
  reconcileSelectedJournalListSelector,
  (list: List<JornalEntryType>) => list.size,
);
// injects header line
export const reconcileSelectedJournalSelector: SelectorType<JornalEntryType> = selector(
  reconcileSelectedJournalListWithHeaderSelector,
  flatternJE,
);

// details row
export const reconciledetailsRowSelector: SelectorType<number | boolean> = selector(
  reconciliation,
  (j: ReconciliationStore) => j.detailsRow,
);
export const reconcileRowStateSelector: SelectorType<?ReconcileCellValueType> = selector(
  reconciliation,
  (j: ReconciliationStore) => j.rowState,
);
export const shouldRowStateForceAutoselection: SelectorType<boolean> = selector(
  reconcileRowStateSelector,
  (state: ReconcileCellValueType) => [MATCH, RECONCILED].includes(state),
);

// request row selectors
export const reconcileRequestRowSelector: SelectorType<number | boolean> = selector(
  reconciliation,
  (j: ReconciliationStore) => j.requestRow,
);
export const reconcileRequestRowLineIdSelector: SelectorType<?string> = selector(reconcileRequestRowSelector, (row) =>
  row.get('id'),
);
export const reconcileRequestRowLineNumberSelector: SelectorType<?string> = selector(
  reconcileRequestRowSelector,
  (row) => row.get('row'),
);

export const requestPopupIsOpenSelector: SelectorType<boolean> = selector(reconciliation, (r) => r.requestPopup);

// column selectors
export const reconcileTransactionIdColumnSelector: SelectorType<string> = selector(
  reconcileJournalSelector,
  (journal: searchJournal) => journal.transactionIdColumn,
);
export const reconcileBalanceColumnSelector: SelectorType<string> = selector(
  reconcileJournalSelector,
  (journal: searchJournal) => journal.balanceColumn,
);
export const searchResultsAmountColumnSelector: SelectorType<string> = selector(
  reconcileJournalSelector,
  (journal: searchJournal) => journal.amountColumn,
);
export const searchResultsBalanceColumnSelector: SelectorType<string> = selector(
  reconcileJournalSelector,
  (journal: searchJournal) => journal.balanceColumn,
);
export const searchResultsTypeColumnSelector: SelectorType<string> = selector(
  reconcileJournalSelector,
  (journal: searchJournal) => journal.transactionTypeColumn,
);

// main JE transaction details selectors
export const transactionCellSelectorFactory = (columnSelector: SelectorType<string>, defaultValue: any) =>
  selector(
    JournalEntrySelector,
    columnSelector,
    reconciledetailsRowSelector,
    // $FlowFixMe flow doesnt know which cell type we are working with
    (journal: JornalEntryType, column: string, row: number) =>
      journal.getIn([`${column}${row}`, 'cellSet', 'value'], defaultValue),
  );
export const transactionAmountSelector = compose(parseFloat, transactionCellSelectorFactory(amountColumnSelector, 0));
export const transactionDateSelector = transactionCellSelectorFactory(dateColumnSelector, '');
export const transactionDescriptionSelector = transactionCellSelectorFactory(descriptionColumnSelector, '');

// summary for selected transactions
export const selectedTransactionsAmountSelector = selector(
  reconcileSelectedJournalListSelector,
  searchResultsAmountColumnSelector,
  searchResultsBalanceColumnSelector,
  (journal: List<JornalEntryType>, amountColumn: string, balanceColumn: string) =>
    Math.round(
      journal
        // $FlowFixMe - equal types are reported as nonmatching?
        .map(rowAdapter) // transforms [A7, A9, AX...] to [A0, A1, A2...]
        .reduce((A, V, K) => A + getTransactionAmount(V, K, amountColumn, balanceColumn), 0) *
        100 +
        Number.EPSILON,
    ) / 100,
);

// search results matched to another transactions
export const anotherTransactionMatchedLinesSelector: SelectorType<Set<number>> = selector(
  reconcileJournalSelector,
  (journal: searchJournal) => journal.rowMessages,
);
export const transactionErrorSelector: SelectorType<?string> = selector(
  reconcileJournalSelector,
  (journal: searchJournal) => journal.errorFromErp,
);

// transactions to reconcile with
export const reconcileTransactionIdsSelector: SelectorType<Set<string>> = selector(
  reconcileSelectedJournalListSelector,
  reconcileTransactionIdColumnSelector,
  (journal: List<JornalEntryType>, column: string) =>
    Set(
      journal
        .map(rowAdapter)
        // $FlowFixMe flow doesnt know which cell type we are working with
        .map((V, K) => V.getIn([`${column}${K}`, 'cellSet', 'value'], '')),
    ),
);

// validation
const selectedTransactionsToBeValidatedSelector: SelectorType<$ReadOnlyArray<ValidatableTransaction>> = selector(
  reconcileSelectedJournalListSelector,
  searchResultsAmountColumnSelector,
  searchResultsBalanceColumnSelector,
  searchResultsTypeColumnSelector,
  (journal: List<JornalEntryType>, amountColumn: string, balanceColumn: string, transactionTypeColumn: string) =>
    journal
      // $FlowFixMe - equal types are reported as nonmatching?
      .map(rowAdapter) // transforms [A7, A9, AX...] to [A0, A1, A2...]
      .reduce(
        (A, V, K) =>
          A.concat({
            amount: getTransactionAmount(V, K, amountColumn, balanceColumn),
            // $FlowFixMe flow doesnt know which cell type we are working with
            type: V.getIn([`${transactionTypeColumn}${K}`, 'cellSet', 'value'], ''),
          }),
        [],
      ),
);
export const isReconciliationRestrictedSelector: SelectorType<boolean> = selector(
  selectedTransactionsToBeValidatedSelector,
  transactionAmountSelector,
  (transactions: $ReadOnlyArray<ValidatableTransaction>, total: number) =>
    actionRestricted(transactions, Math.abs(total)),
);
