import CONST from 'pages/common/constant';
import icon from './tip.svg';

export default (theme) => ({
  header: {
    position: 'fixed',
    top: 0,
    zIndex: 100,
    width: '100%',
    backgroundColor: ({ white }) => (white ? '#fff' : theme.colors.primaryDark),
    '@supports (position: sticky)': {
      position: 'sticky',
    },
  },
  leftBar: {
    paddingLeft: 18,
  },
  top: {
    height: CONST.headerHeight,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    boxShadow: ({ white }) => (white ? '0px 2px 5px rgba(0, 0, 0, 0.15)' : null),
  },
  title: {
    color: ({ white }) => (white ? '#171A1C' : '#FFF'),
    fontSize: '26px',
    lineHeight: '35px',
    letterSpacing: '5%',
    textTransform: 'uppercase',
    textDecoration: 'none',
  },
  rightBar: {
    paddingRight: 18,
    display: 'flex',
    flex: [1, 1, 'auto'],
    justifyContent: 'flex-end',
    alignItems: 'center',
  },

  mainBtnSet: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },

  additionalBtnSet: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },

  buttonBox: {
    marginLeft: 30,
    height: 24,
    width: 24,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  menuBox: {
    marginLeft: 26,
    display: 'flex',
    alignItems: 'center',
  },

  center: {
    flex: '1 auto',
  },
  settings: {
    display: 'flex',
    position: 'relative',
  },

  link: {
    textDecoration: 'none',
  },

  settingsIcon: {
    '&:hover + $tip': {
      display: 'inline-block',
    },
  },
  tip: {
    display: 'none',
    position: 'absolute',
    top: '100%',
    transform: 'translateX(-50%)',
    marginTop: 20,
    left: '60%',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  },
  tipBody: {
    padding: [10, 10, 10, 34],
    fontSize: 12,
    width: 150,
    color: '#fff',
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
    backgroundImage: `url(${icon})`,
    backgroundPosition: '10px center',
    backgroundRepeat: 'no-repeat',
    position: 'relative',
    '&:before': {
      borderRight: '10px solid transparent',
      borderLeft: '10px solid transparent',
      borderBottom: '10px solid rgba(0, 0, 0, 0.8)',
      position: 'absolute',
      top: 0,
      marginTop: -10,
      left: '50%',
      content: '""',
      display: 'inline-block',
    },
  },
  userPickBox: {
    cursor: 'pointer',
    outline: 'none',
    border: 'none',
    backgroundColor: 'transparent',
  },

  userPickBorder: {
    border: '2px solid rgba(255, 255, 255, 0.7);',
    borderRadius: 50,
  },

  tooltipPosition: {
    display: 'flex',
    alignItems: 'center',
  },
});
