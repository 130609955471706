export default (statusColor, theme) => ({
  container: (provided) => ({
    ...provided,
    position: 'relative',
    width: '100%',
    borderRadius: 2,
    fontSize: 12,
    border: 'none',
  }),

  control: (provided) => ({
    ...provided,
    lineHeight: 0,
    height: 22,
    minHeight: 22,
    boxShadow: 'none',
    borderRadius: 2,
    cursor: 'pointer',
    backgroundColor: statusColor,
    border: '0 none',
  }),

  valueContainer: (provided) => ({
    ...provided,
    padding: '0 8px',
    color: '#fff',
  }),

  placeholder: () => ({
    color: '#fff',
    flex: '1 1',
  }),

  singleValue: () => ({
    height: 22,
    lineHeight: '22px',
    margin: '0 2px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    color: '#fff',
    position: 'absolute',
    left: 0,
    right: 0,
    textAlign: 'center',
  }),

  menu: (provided) => ({
    ...provided,
    marginTop: 0,
    marginBottom: 0,
    width: 'calc(100% + 16px)',
  }),

  menuList: (provided) => ({
    ...provided,
    position: 'absolute',
    backgroundColor: '#fff',
    borderTop: 'none',
    minWidth: '100%',
    boxShadow: '0px 12px 24px rgba(0, 0, 0, 0.2)',
    padding: 0,
    borderRadius: '0px 0px 2px 2px',
    overflow: 'hidden',
  }),

  option: (provided, { isFocused, isDisabled }) => ({
    zIndex: 15,
    height: 22,
    lineHeight: '22px',
    whiteSpace: 'nowrap',
    unicodeBidi: 'plaintext',
    cursor: 'pointer',
    padding: '0 8px',
    // eslint-disable-next-line no-nested-ternary
    backgroundColor: isDisabled ? null : isFocused ? theme.colors.primaryLightest : '#fff',
    // eslint-disable-next-line no-nested-ternary
    color: isDisabled ? '#D3D6D9' : isFocused ? '#fff' : '#171A1C',
  }),

  indicatorSeparator: (provided) => ({
    ...provided,
    margin: 0,
    background: 'transparent',
    borderRight: '1px dashed #fff',
  }),
});
