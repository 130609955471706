// @flow

export type CSSStylesType = Array<{ name: string, value: string | null }>;

export function scrollBarWidth(): string {
  // eslint-disable-next-line max-len
  const width =
    window.innerWidth ||
    (document.documentElement && document.documentElement.clientWidth) ||
    (document.body && document.body.clientWidth);
  return `${(width || 0) - ((document.body && document.body.offsetWidth) || 0)}px`;
}

export function updateStyle(el: ?HTMLElement, val: CSSStylesType) {
  val.forEach((prop) => {
    if (el) el.style.setProperty(prop.name, prop.value);
  });
}

export function bodyToggler(isOpened: boolean) {
  const { body } = document;
  if (body) {
    if (!isOpened) {
      updateStyle(body, [
        { name: 'overflow', value: 'hidden' },
        { name: 'padding-right', value: scrollBarWidth() },
      ]);
    } else {
      updateStyle(body, [
        { name: 'overflow', value: null },
        { name: 'padding-right', value: null },
      ]);
    }
  }
}

type ContentData = {
  [key: string]: string,
};

export const parseViewportContent = (content: string): ContentData => {
  const entries = content.split(',').map((param) => param.trim().split('='));
  return Object.fromEntries(entries);
};

export const getViewportElement = (): HTMLMetaElement => document.querySelector('meta[name=viewport]');

export const decodeViewportContent = (params: ContentData): string =>
  Object.entries(params)
    .map(([key, value]) => `${key}=${value}`)
    .join(', ');

export const getViewportParams = (): ContentData => parseViewportContent(getViewportElement().getAttribute('content'));

export const setViewportParams = (params: ContentData): void => {
  const content = { ...getViewportParams(), ...params };
  getViewportElement().setAttribute('content', decodeViewportContent(content));
};
