import { UserEnhancedApprovalFactory } from 'domain/approvals/helpers';

export const Fields = {
  name: 'group_title',
  approvers: 'approvers[]',
};

export const ColumnTypes = {
  text: 'text',
  controls: 'controls',
  approvals: 'approvals',
};

export const HeadersAdapter = ({ formatMessage, handleEditGroup, handleDeleteGroup, disableEdit, disableDelete }) => {
  const headers = [
    {
      field: Fields.name,
      headerName: formatMessage({
        id: 'configurations.company.approval_group.table.column.name',
        defaultMessage: 'Group Name',
      }),
      type: ColumnTypes.text,
      minWidth: 200,
      maxWidth: 450,
    },
    {
      field: Fields.approvers,
      headerName: formatMessage({
        id: 'configurations.company.approval_group.table.column.approvers',
        defaultMessage: 'Approvers',
      }),
      cellRenderer: ColumnTypes.approvals,
    },
    {
      field: 'controls',
      cellRenderer: ColumnTypes.controls,
      width: 120,
      maxWidth: 120,
      cellRendererParams: {
        handleEditClick: handleEditGroup,
        handleDeleteClick: handleDeleteGroup,
        disableEdit,
        disableDelete,
      },
    },
  ];

  return headers;
};

const ApproverFactory = ({ group_title, approvers, ...rest }) => ({
  [Fields.name]: group_title,
  [Fields.approvers]: approvers,
  ...rest,
});

const RowFactory = (value) => ({
  ...ApproverFactory(value),
  [ColumnTypes.controls]: ApproverFactory(value),
});

const RowAdapter = (group, users) =>
  RowFactory({
    ...group,
    approvers: group.approvers.reduce((a, v) => {
      const user = users.find((u) => u.userId === v);
      if (user)
        return [
          ...a,
          UserEnhancedApprovalFactory({
            userGuid: user.userId,
            tag: v.email,
            id: user.userId,
            picture: user.picture,
            fullName: user.username,
          }).toJS(),
        ];
      return a;
    }, []),
  });

export const RowsAdapter = (groups, users) => groups.toJS().map((g) => RowAdapter(g, users));

export const createCellStyle = ({ width, maxWidth, minWidth }) => ({
  width,
  maxWidth,
  minWidth,
  whiteSpace: 'normal',
  wordWrap: 'break-word',
});

export const createRow = ({ rowData, columnDefs, renderCell }) =>
  Object.entries(rowData)
    .filter(([key]) => columnDefs.find((c) => c.field === key))
    .sort((a, b) => columnDefs.findIndex((i) => i.field === a[0]) - columnDefs.findIndex((i) => i.field === b[0]))
    .map(([key, value]) => {
      const header = columnDefs.find((c) => c.field === key);
      return renderCell({ header, value, key });
    });
