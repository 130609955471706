// @flow
import React, { useState, useLayoutEffect, useEffect, useRef, TextareaHTMLAttributes } from 'react';

// helpers
import { updateStyle } from 'lib/domHelpers';

// styles
import useStyles from './sheet';
import cx from 'classnames';

const AutoTextArea: React$StatelessFunctionalComponent<TextareaHTMLAttributes<HTMLTextAreaElement>> = ({
  onChange,
  value,
  className,
  refsProxy,
  ...rest
}) => {
  const classes = useStyles();
  const textAreaRef = useRef(null);
  const parentRef = useRef(null);

  const [text, setText] = useState('');
  const [textAreaHeight, setTextAreaHeight] = useState('auto');
  const [textAreaTopPosition, setTextAreaTopPosition] = useState(0);
  const [textAreaLeftPosition, setTextAreaLeftPosition] = useState(0);
  const [parentHeight, setParentHeight] = useState('auto');
  const [parentWidth, setParentWidth] = useState('auto');
  const [maxAreaHeight, setMaxAreaHeight] = useState('auto');

  const scrollBox =
    document.querySelector('[data-element="je.grid.float"]') || document.querySelector('.search-scroll-box');

  const jeWrapper = document.querySelector('[data-element="content.document.tableBlock"]');

  const disableScroll = () => {
    if (scrollBox) {
      updateStyle(scrollBox, [{ name: 'overflow', value: 'hidden' }]);
      scrollBox.setAttribute('data-scroll-box', 'open');
    }
    if (jeWrapper) {
      updateStyle(jeWrapper, [{ name: 'overflow', value: 'hidden' }]);
      jeWrapper.setAttribute('data-scroll-box', 'open');
    }
  };

  const enableScroll = () => {
    if (scrollBox) {
      updateStyle(scrollBox, [{ name: 'overflow', value: null }]);
      scrollBox.removeAttribute('data-scroll-box');
    }
    if (jeWrapper) {
      updateStyle(jeWrapper, [{ name: 'overflow', value: null }]);
      jeWrapper.removeAttribute('data-scroll-box');
    }
  };

  useEffect(() => {
    setParentHeight(`${textAreaRef.current.scrollHeight}px`);
    setTextAreaHeight(`${textAreaRef.current.scrollHeight}px`);
  }, [text]);

  useLayoutEffect(() => {
    disableScroll();
    setParentWidth(`${parentRef.current.offsetWidth}px`);
    setText(value);
    const { top, left } = parentRef.current.getBoundingClientRect();
    const maxHeight = window.innerHeight - top - 60;
    setMaxAreaHeight(`${maxHeight}px`);
    refsProxy(textAreaRef.current);
    setTextAreaTopPosition(top);
    setTextAreaLeftPosition(left);

    return () => {
      enableScroll();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChangeHandler = (event: SyntheticInputEvent<HTMLTextAreaElement>) => {
    setTextAreaHeight('auto');
    setText(event.target.value);
    if (onChange) {
      onChange(event);
    }
  };

  return (
    <div
      ref={parentRef}
      className={classes.container}
      style={{
        height: parentHeight,
      }}
    >
      <textarea
        {...rest}
        value={text}
        ref={textAreaRef}
        rows={1}
        className={cx(className, classes.textArea)}
        style={{
          height: textAreaHeight,
          maxHeight: maxAreaHeight,
          width: parentWidth,
          paddingTop: 6,
          paddingBottom: 3,
          paddingRight: 1,
          top: textAreaTopPosition,
          left: textAreaLeftPosition,
        }}
        onChange={onChangeHandler}
      />
    </div>
  );
};

export default AutoTextArea;
