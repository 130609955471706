// @flow
import { makeStyles } from '@mui/styles';
import selectedItem from './assets/done.svg';

export default makeStyles(() => ({
  container: {
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 99,
    flip: false, // flipping will break binding component positioning
  },

  binding: {},

  floatingContainer: {
    overflow: 'auto',
    width: 300,
    padding: 0,
    backgroundColor: 'white',
    borderRadius: 4,
    filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
    display: 'flex',
    flexDirection: 'column',
  },

  arrow: {
    position: 'absolute',
    background: 'white',
    width: 8,
    height: 8,
    transform: 'rotate(45deg)',
    filter: (props) =>
      props?.flippedPlacement?.split('-')[0] === 'top' ? 'drop-shadow(5px 4px 4px rgba(0, 0, 0, 0.25))' : 'none',
  },

  option: {
    position: 'relative',
    display: 'block',
    width: '100%',
    border: 'none',
    background: 'transparent',
    textAlign: 'left',
    height: 36,
    padding: [0, 10],
    '&:focus, &:active': {
      background: '#F8F8F8',
    },
    '&:disabled': {
      color: 'lightgrey',
    },
  },

  scrollableContent: {
    overflowY: 'auto',
  },

  header: {
    position: 'sticky',
    top: 0,
    zIndex: 1,
    background: '#FFFFFF',
    padding: 10,
    borderBottom: '1px solid #EFEFEF',
    marginBottom: 10,
    '& h2': {
      fontSize: 14,
      margin: [8, 0],
    },
    '& input': {
      width: '100%',
      height: '32px',
      backgroundColor: '#F8F8F8',
      border: '1px solid #DEE2E6',
      borderRadius: 4,
      color: '#2C2E2F',
      margin: [8, 0],
      padding: [0, 12],
    },
  },

  mandatoryLabel: {
    color: '#8C9BA5',
  },

  active: {
    background: '#F8F8F8',
    position: 'relative',
    '&&': { color: '#000000' },
    '&::after': {
      content: '""',
      position: 'absolute',
      right: 5,
      width: 24,
      height: 24,
      WebkitMask: `url("${selectedItem}") center center no-repeat`,
      backgroundColor: 'green',
    },
  },
}));
