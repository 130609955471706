/* @flow */
import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

type TEventsHistoryReloadButton = {|
  counter: number,
  onReload: () => void,
|};

const EventsHistoryReloadButton: React$StatelessFunctionalComponent<TEventsHistoryReloadButton> = ({
  counter,
  onReload,
}) =>
  counter > 0 ? (
    <Box m={1}>
      <Button color="secondary" variant="outlined" fullWidth onClick={onReload}>
        <FormattedMessage id="company.events.newEvents" defaultMessage="New Events" />
      </Button>
    </Box>
  ) : null;

export default EventsHistoryReloadButton;
