/* @flow */
import * as React from 'react';

// redux
import { compose, type Dispatch } from 'redux';
import { connect } from 'react-redux';

// dnd
import { DragSource } from 'react-dnd';

// types
import type { DragSourceConnector, DragSourceMonitor, ConnectDragSource, ConnectDragPreview } from 'react-dnd';

// helpers
import getEmptyImage from 'react-dnd-html5-backend/lib/esm/getEmptyImage';
import { ReferenceFactory } from 'domain/journal/helper';

// styles
import { withStyles } from '@mui/styles';
import cx from 'classnames';
import sheet from './sheet';
import { clearReferenceAction, setRefernceAction } from 'domain/journal';
import { nanoid } from 'nanoid';
import type { ExtraPolygon } from '../../../../../polygons/helpers';

type Props = {|
  moveGridPosition: { top: number, left: number },
  clearReference: Dispatch<clearReferenceAction>,
  setReference: Dispatch<setRefernceAction>,
  getStyledPolygons: () => Array<ExtraPolygon>,
  dragSource: ConnectDragSource,
  dragPreview: ConnectDragPreview,
  classes: {
    [key: string]: string,
  },
|};

class DragButton extends React.Component<Props> {
  componentDidMount() {
    const { dragPreview } = this.props;
    dragPreview(getEmptyImage(), { captureDraggingState: false });
  }

  setSelfRef = (buttonRef) => {
    this.buttonRef = buttonRef;
  };

  buttonRef: ?HTMLElement;

  render() {
    const { moveGridPosition, dragSource, classes } = this.props;
    return dragSource(
      <div className={cx(classes.button, classes.dragButton)} style={moveGridPosition} ref={this.setSelfRef} />,
    );
  }
}

let referenceId;

const gridSource = {
  beginDrag: (props: Props, monitor: DragSourceMonitor, component: DragButton) => {
    const { left, width, top, height } = component.buttonRef.getBoundingClientRect();
    const { structure, setReference, getStyledPolygons } = props;
    referenceId = nanoid(10);
    const params = ReferenceFactory({
      id: referenceId,
      x1: left + width / 1.8,
      y1: top + height / 1.8 + window.scrollY,
      type: 'GRID',
    });
    setReference({ params, id: referenceId });
    return { type: 'GRID', payload: { grid: structure, polygons: getStyledPolygons() } };
  },

  endDrag(props: Props) {
    props.clearReference();
  },
};

function collect(connector: DragSourceConnector) {
  return {
    dragSource: connector.dragSource(),
    dragPreview: connector.dragPreview(),
  };
}

const mapDispatchToProps = {
  setReference: setRefernceAction,
  clearReference: clearReferenceAction,
};

export default compose(
  connect(null, mapDispatchToProps),
  withStyles(sheet),
  DragSource('Polygon', gridSource, collect),
)(DragButton);
