/* @flow */
import React from 'react';
import { FormattedMessage } from 'react-intl';

import AlertMui from '@mui/material/Alert';

function DocumentBetaAlert() {
  return (
    <AlertMui severity="warning" sx={{ fontWeight: 500, mx: 1, mt: 1 }}>
      <FormattedMessage id="document.show.gridBeta.title" defaultMessage='"Grid feature"' />
      <FormattedMessage id="document.show.gridBeta.message" defaultMessage=" is in BETA. Some problems may appear" />
    </AlertMui>
  );
}

export default DocumentBetaAlert;
