import { Component } from 'react';
import ReactDOM from 'react-dom';

type Props = {|
  children: React$Node,
|};

const modalRoot = document.getElementById('modalRoot');

class Modal extends Component<Props> {
  constructor(props) {
    super(props);
    this.modalContainer = document.createElement('div');
  }

  componentDidMount() {
    modalRoot.appendChild(this.modalContainer);
  }

  componentWillUnmount() {
    modalRoot.removeChild(this.modalContainer);
  }

  render() {
    const { children } = this.props;

    return ReactDOM.createPortal(children, this.modalContainer);
  }
}

export default Modal;
