import { useContext, useMemo } from 'react';
import WLContext from 'labels/context';
import { useSelector } from 'react-redux';
import { rtlEnable } from 'domain/env';

const useDefaultThemeConfig = () => {
  const {
    labelOptions: { styles: wlThemeStyles, ...wlThemeConfig },
  } = useContext(WLContext);
  const isRtl = useSelector(rtlEnable);

  return useMemo(
    () => ({
      ...wlThemeStyles,
      components: {
        MuiCssBaseline: {
          styleOverrides: {
            '*': {
              // [MUI] - needs for JE after migrate to mui
              margin: 0,
              padding: 0,
              fontFamily: ['Rubik', 'Helvetica', 'Arial', 'sans-serif'].join(','),
            },
            body: {
              direction: 'ltr',
            },
            '#root': {
              minHeight: '100vh',
              display: 'flex',
            },
            '.UserSelect-none': {
              userSelect: 'none',
            },
            // hide widget launcher iframe via css, because sometimes freshdesk widget dont want hide through
            // window.FreshworksWidget('hide', 'launcher');
            // I guess Freshdesk script is not yet initialized when hide API is invoked
            // 1 - always hide using styles/css
            // 2- try hide use widget API after 1second timeout - see HelpDropdown component
            '#launcher-frame': {
              display: 'none',
            },
          },
        },
        MuiDialogTitle: {
          styleOverrides: {
            root: ({ theme }) => ({
              fontSize: theme.typography.h6,
            }),
          },
        },
        MuiButtonGroup: {
          styleOverrides: {
            root: {
              borderRadius: 100,
            },
          },
        },
        MuiButton: {
          defaultProps: {
            variant: 'contained',
          },
          styleOverrides: {
            root: {
              borderRadius: 100,
            },
          },
        },
        // TODO: check behaviour - having stuck tooltips for disabled IconButtons
        // cant use code below
        // MuiIconButton: {
        //   styleOverrides: {
        //     root: {
        //       '&[disabled]': {
        //         cursor: 'not-allowed',
        //         pointerEvents: 'auto',
        //       },
        //     },
        //   },
        // },
        MuiChip: {
          styleOverrides: {
            root: {
              borderRadius: 8,
            },
          },
        },
        MuiTooltip: {
          styleOverrides: {
            tooltip: ({ theme }) => ({
              backgroundColor: theme.palette.grey[800],
              padding: theme.spacing(0.25, 1),
            }),
            arrow: ({ theme }) => ({
              color: theme.palette.grey[800],
            }),
          },
        },
      },
      direction: isRtl ? 'rtl' : 'ltr',
      typography: {
        fontFamily: ['Rubik', 'Helvetica', 'Arial', 'sans-serif'].join(','),
        hint: {
          fontSize: '0.75rem',
        },
      },
      mixins: {
        toolbar: {
          minHeight: 64,
        },
      },
      labelOptions: wlThemeConfig,
    }),
    [isRtl, wlThemeConfig, wlThemeStyles],
  );
};

export default useDefaultThemeConfig;
