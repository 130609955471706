import React, { useState } from 'react';

import Box from '@mui/material/Box';
import PreviewEye from 'pages/company/TileGrid/TileCard/components/PreviewEye';
import Badge from '@mui/material/Badge';

import type { Image as ImageType } from '../../type.js.flow';

import { styled } from '@mui/material/styles';

import { grey } from '@mui/material/colors';

type Props = {|
  image: ImageType,
  selected: boolean,
  onMouseDown: (event: MouseEvent) => void,
  showImageInPopup: (index: number) => void,
|};

export const ScanImage = styled(Box, { shouldForwardProp: (propName) => propName !== 'selected' })(
  ({ theme, selected }) => ({
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    borderRadius: 8,
    overflow: 'hidden',
    boxSizing: 'border-box',
    width: 271,
    minWidth: 271,
    height: 375,
    border: selected ? `2px solid ${theme.palette.primary.main}` : `1px solid ${theme.palette.grey[500]}`,
    img: {
      width: '100%',
    },
  }),
);

export const PageBadge = styled(Badge)(() => ({
  '& .MuiBadge-badge': {
    backgroundColor: grey[500],
    right: '50%',
  },
}));

const ImageItem: React$StatelessFunctionalComponent<Props> = ({ image, onMouseDown, selected, showImageInPopup }) => {
  const [isHovered, setIsHovered] = useState(false);

  const onMouseOver = () => setIsHovered(true);
  const onMouseLeave = () => setIsHovered(false);
  return (
    <PageBadge
      color="primary"
      badgeContent={image.initialIndex + 1}
    >
      <ScanImage
        selected={selected}
        onMouseDown={(event: MouseEvent) => onMouseDown(image.initialIndex, event)}
        onMouseOver={onMouseOver}
        onMouseLeave={onMouseLeave}
    >
        {isHovered && (
        <PreviewEye
          onClickEye={() => {
            showImageInPopup(image.initialIndex);
          }}
        />
      )}
        <img src={image.originalSizeSrc} alt={image.src} />
      </ScanImage>
    </PageBadge>
  );
};

export default ImageItem;
