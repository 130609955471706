// @flow
import React, { useCallback } from 'react';
import Tooltip from 'components/mui/Tooltip';
import IconButton from '@mui/material/IconButton';

import InputIcon from '@mui/icons-material/Input';

type TLockButton = {
  linkID: string,
  onMoveToCompany: (linkId: string) => void,
};

const MoveToCompanyButton: React$StatelessFunctionalComponent<TLockButton> = ({ linkID, onMoveToCompany }) => {
  const handelClick = useCallback(() => {
    onMoveToCompany(linkID);
  }, [linkID, onMoveToCompany]);

  return (
    <Tooltip
      t={{
        id: 'contextMenu.moveLinkedToCompany',
        defaultMessage: 'Move linked bundle to',
      }}
    >
      <IconButton color="primary" onClick={handelClick} disabled={linkID.includes('_locked')}>
        <InputIcon />
      </IconButton>
    </Tooltip>
  );
};

export default MoveToCompanyButton;
