// @flow
import * as React from 'react';
import { List, OrderedMap, is } from 'immutable';
import { debounce } from 'throttle-debounce';
import { FormattedMessage } from 'react-intl';
import cx from 'classnames';
import Spinner from 'components/Spinner';
import type { TMessageRecord } from 'domain/chat/types.js.flow';
import MessageItem from './item';

type Props = {|
  classes: {
    [key: string]: string,
  },
  list: ?OrderedMap<string, List<TMessageRecord>>,
  ownerId: string,
  onPrev: () => void,
|};

class Messages extends React.Component<Props> {
  static flatternList(list: ?OrderedMap<string, List<TMessageRecord>>): List<TMessageRecord> {
    return list ? list.toList().flatten(true) : new List();
  }

  shouldComponentUpdate(nextProps: Props) {
    const { list } = this.props;
    if (!is(list, nextProps.list)) return true;
    return false;
  }

  componentDidUpdate(prevProps: Props) {
    const list = this.flatList;
    const prevList = this.constructor.flatternList(prevProps.list);
    if (!prevList && list) {
      this.scrollEnd();
    }

    if (prevList && list) {
      if (!is(list.last(), prevList.last())) {
        this.scrollEnd();
      } else {
        const first = prevList.first();
        if (first) {
          this.scrollToElement(first.messageId);
        }
      }
    }
  }

  get dseq() {
    return this.props.list ? this.props.list.keySeq() : new List();
  }

  get dList() {
    return this.props.list ? this.props.list.toList() : new List();
  }

  get flatList(): List<TMessageRecord> {
    return this.constructor.flatternList(this.props.list);
  }

  getPrevious = debounce(500, true, this.props.onPrev);

  scrollToElement(messageId: number): void {
    const c = this.container;
    if (this.dList && c && c.children && c.children[0]) {
      this.dList.forEach((list, groupIndex) => {
        const index = list.findIndex((f) => f.messageId === messageId);
        // eslint-disable-next-line max-len
        if (
          index >= 0 &&
          c.children[groupIndex] &&
          c.children[groupIndex].children &&
          c.children[groupIndex].children[1] &&
          c.children[groupIndex].children[1].children[index]
        ) {
          c.children[groupIndex].children[1].children[index].scrollIntoView(true);
        }
      });
    }
  }

  scrollEnd = () => {
    const { container } = this;
    if (container) container.scrollTop = container.scrollHeight;
  };

  handleScroll = (e: SyntheticEvent<*>) => {
    const { scrollTop } = e.currentTarget;
    if (scrollTop < 5) {
      this.getPrevious();
    }
  };

  periodFormatter = (i: number): ?string | React$Node => {
    switch (this.dseq.get(i)) {
      case 'Yesterday':
        return <FormattedMessage id="chat.dates.yesterday" defaultMessage="Yesterday" />;
      default:
        return this.dseq.get(i);
    }
  };

  container: ?HTMLDivElement;

  render() {
    const { classes, list, ownerId } = this.props;

    return (
      <div
        ref={(el) => {
          this.container = el;
        }}
        className={classes.messagesScroll}
        onScroll={this.handleScroll}
      >
        <div className={cx(classes.messages, { [classes.noMessages]: !this.dList.size })}>
          {this.dList.size ? (
            this.dList.map((v, i) => (
              <section key={this.dseq.get(i)}>
                <div className={classes.period}>
                  <span>{this.periodFormatter(i)}</span>
                </div>
                <ul className={cx(classes.list)}>
                  {v.map((e) => (
                    <li key={e.messageId} className={classes.item}>
                      <MessageItem data={e} classes={classes} ownerId={ownerId} />
                    </li>
                  ))}
                </ul>
              </section>
            ))
          ) : (
            <li className={classes.noMessage}>
              <FormattedMessage id="chat.noMessage" defaultMessage="No messages here" />
            </li>
          )}
          <Spinner className={classes.spinner} loading={!list} />
        </div>
      </div>
    );
  }
}

export default Messages;
