/* @flow */

export function getPreviewStyle(rotation: number, contsiner: ?HTMLElement): Object {
  const width = contsiner ? contsiner.offsetWidth : 183;
  const height = contsiner ? contsiner.offsetHeight : 240;
  const angleRotation = rotation === 90 || rotation === 270;

  return {
    maxWidth: `${angleRotation ? height : width}px`,
    maxHeight: `${angleRotation ? width : height}px`,
    height: `${angleRotation ? width : height}px`,
    opacity: 1,
    transformOrigin: '50% 50%',
    transform: typeof rotation === 'number' ? `rotate(${rotation}deg)` : null,
  };
}
