export default {
  wrapper: {
    position: 'fixed',
    zIndex: 30,
    left: 0,
    right: 0,
    bottom: 0,
    top: 0,
    pointerEvents: 'none',
  },
  canvas: {
    width: '100%',
    height: '99%',
  },
};
