// @flow
import React from 'react';
import { executeFunc } from 'lib/helpers';
import { getJEIndexListItemDataAction } from 'domain/journal';
import type { TIndexListItemRecords } from 'domain/journal/types.js.flow';
import { List } from 'immutable';

import DialogManageJECellListOption from 'components/Tables/Autocomplete/withManageListItemModal/DialogManageJECellListOption';
import type { Dispatch } from 'redux';

type Props = {
  getIndexForm: Dispatch<typeof getJEIndexListItemDataAction>,
  params?: { id: string, cellName: string },
  input: {
    isCreatable: boolean,
  },
  documentID: string,
};

type State = {|
  isOpenManageModal: boolean,
  isLoading: boolean,
  isEdit: boolean,
  indexListItemData: TIndexListItemRecords,
  initialValues: {|
    [key: string]: boolean | string | number | null,
  |},
|};

export default function withManageListItemModal(WrappedComponent: React.ComponentType<*>) {
  return class Confirmation extends React.Component<Props, State> {
    state = {
      isOpenManageModal: false,
      isLoading: true,
      isEdit: false,
      indexListItemData: new List(),
      initialValues: {},
    };

    onCreate = (): Promise<?string> => {
      if (this.props.input.isCreatable) {
        return this.handleClick();
      }

      return new Promise(() => '');
    };

    onEdit = (cellValue: string) => {
      this.setState({ isEdit: true });

      return this.handleClick(cellValue);
    };

    getIndexForm = (field: string, value: string) =>
      new Promise((resolve, reject) => {
        const { documentID } = this.props;
        this.props.getIndexForm({
          field,
          value,
          documentID,
          resolve,
          reject,
        });
      });

    handleClick = (cellValue = '') => {
      const { cellName } = this.props.params;
      this.setState({ isLoading: true, indexListItemData: new List() });

      return new Promise((resolve, reject) => {
        this.toggleOpen();
        this.resolve = resolve;
        this.reject = reject;
        this.getIndexForm(cellName, cellValue)
          .then((indexListItemData: TIndexListItemRecords) => {
            const initialValues = indexListItemData.reduce((acc, item) => {
              acc[item.get('name')] = item.get('value');

              return acc;
            }, {});

            this.setState({ indexListItemData, initialValues });
          })
          .finally(() => this.setState({ isLoading: false }));
      });
    };

    toggleOpen = (status?: boolean, cb?: ?() => void) => {
      this.setState(
        {
          isOpenManageModal: typeof status === 'undefined' ? !this.state.isOpenManageModal : status,
        },
        () => executeFunc(cb),
      );
    };

    cancel = () => {
      this.toggleOpen(false, this.reject);
      this.setState({ isEdit: false, initialValues: {} });
    };

    handleSubmit = (data) => {
      this.toggleOpen(false, () => {
        if (typeof this.resolve === 'function') this.resolve(data);
        this.setState({ initialValues: {} });
      });
    };

    prepareInitialValues = (name: string) => {
      const { initialValues } = this.state;

      return {
        ...initialValues,
        ...(name && !initialValues.Name ? { Name: name } : {}),
      };
    };

    resolve: ?(v: ?string) => void;

    reject: ?() => void;

    render() {
      const props = {
        ...this.props,
        onEdit: this.onEdit,
        onCreate: this.onCreate,
      };

      const { isOpenManageModal, indexListItemData, isLoading, isEdit } = this.state;

      return (
        <WrappedComponent {...props}>
          {(name: string, onSearchHandler: SyntheticInputEvent<HTMLIFrameElement>) =>
            isOpenManageModal && (
              <DialogManageJECellListOption
                cancel={() => this.cancel()}
                indexListItemData={indexListItemData}
                onSubmit={this.handleSubmit}
                initialValues={this.prepareInitialValues(name)}
                isLoading={isLoading}
                isEdit={isEdit}
                onSearchHandler={onSearchHandler}
              />
            )
          }
        </WrappedComponent>
      );
    }
  };
}
