// @flow
import React, { type StatelessFunctionalComponent } from 'react';
import { SortableElement } from 'react-sortable-hoc';

export type Props = {|
  item: any,
  itemIndex: number,
  children: any,
|};

const DocPage: StatelessFunctionalComponent<Props> = ({ item, children, itemIndex }: Props) => (
  <React.Fragment>{children(item, itemIndex)}</React.Fragment>
);

export default SortableElement(DocPage);
