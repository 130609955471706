// @flow
import React, { memo } from 'react';

import { LinkedBadgeStyled } from 'pages/company/DocumentPreview/components/StyledComponents';

import AttachFileOutlinedIcon from '@mui/icons-material/AttachFileOutlined';

const LinkedBadge = () => (
  <LinkedBadgeStyled>
    <AttachFileOutlinedIcon />
  </LinkedBadgeStyled>
);

export default memo(LinkedBadge);
