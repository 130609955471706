import { select, put, call } from 'redux-saga/effects';
import { cognitoCleanAuthDataAction, isAuthorizedSelector } from 'domain/env';
import { cleanStorageDataOnSignout } from 'domain/env/sagas';

// this cleans data for authorized user from storage and redux so that
// in index saga further rot sagas consider user not authorized
// this allows not to run workers that will trigger 401 logout from workers
// in case of expired token and IDp login
export function* cleanAuthData() {
  const isAuthorized = yield select(isAuthorizedSelector);
  if (isAuthorized) {
    yield call(cleanStorageDataOnSignout);
    yield put({ type: cognitoCleanAuthDataAction.type });
  }
}
