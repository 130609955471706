// @flow
import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { Stack } from '@mui/material';

import { type TUserFeatures } from 'domain/env/types.js.flow';
import { type RecordOf } from 'immutable';
import GroupItem from 'pages/profiles/company/pages/components/GroupItem';
import Switch from 'components/mui/Form/Switch/SwitchBase';
import { FormControlLabelSwitch } from 'components/mui/Form/FormControl/FormControlLabelSwitch';
import Typography from '@mui/material/Typography';

type TProfilePreferencesFormProps = {|
  initialValues: RecordOf<TUserFeatures>,
  handleChange: (boolean, string) => Promise<any>,
|};

type TFeature = 'modal' | 'filter_documents_tagged_by_user_only';

const ProfilePreferencesForm: React$StatelessFunctionalComponent<TProfilePreferencesFormProps> = ({
  handleChange,
  initialValues,
}: TProfilePreferencesFormProps) => {
  const { formatMessage } = useIntl();

  const onChange = useCallback(
    (feature: TFeature) => (e: SyntheticInputEvent<HTMLInputElement>) => {
      const value = e.target.checked;

      handleChange(value, feature);
    },
    [handleChange],
  );

  return (
    <Stack spacing={2}>
      <GroupItem
        title={{
          id: 'profile.preferences.confirmation.title',
          defaultMessage: 'Confirmation dialog',
        }}
      >
        <FormControlLabelSwitch
          label={
            <Typography variant='body2'>
              {formatMessage({
                id: 'profile.preferences.switcher.confirmActions',
                defaultMessage: 'Ask to confirm actions',
              })}
            </Typography>
          }
          onChange={onChange('modal')}
          checked={initialValues.get('modal')}
          control={<Switch />}
        />
      </GroupItem>
      <GroupItem
        title={{
          id: 'profile.preferences.miscellaneous.title',
          defaultMessage: 'Miscellaneous',
        }}
      >
        <FormControlLabelSwitch
          label={
            <Typography variant='body2'>
              {formatMessage({
                id: 'profile.preferences.switcher.showTaggedDocumentsOnly',
                defaultMessage: 'Show only documents I\'m tagged against by default',
              })}
            </Typography>
          }
          onChange={onChange('filter_documents_tagged_by_user_only')}
          checked={initialValues.get('filter_documents_tagged_by_user_only')}
          control={<Switch />}
        />
      </GroupItem>
    </Stack>
  );
};

export default ProfilePreferencesForm;
