export const gMaxWidth = window.innerWidth - 40;
const getHeight = (props) => (props.scale * props.width > gMaxWidth ? props.params.height : null);

export default () => ({
  pageContainer: {
    position: 'relative',
    height: (props) => (props.isFinancial ? Math.ceil(props.params.height) : null),
  },
  selection: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  pageWrapper: {
    overflow: 'hidden',
    position: 'relative',
    display: 'block',
    direction: 'ltr!important',
    height: (props) => (props.isFinancial ? Math.ceil(props.params.height) : getHeight(props)),
    width: '100%',

    '&:hover + .DocumentPage-controls': {
      opacity: 1,
    },

    '& .react-pdf__Page__canvas': {
      borderRadius: 8,
    },
  },
  tooltipContainer: {
    position: 'absolute',
    height: '100%',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
    pointerEvents: 'none',
  },
});
