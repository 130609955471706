// @flow
import React, { memo, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { passwordLessAuthLoginAction } from 'domain/env';
import { useParams } from 'react-router-dom';

import AuthLayout from 'pages/authPage/Layout';
import PasswordLessFormAuth from 'pages/authPage/PasswordLessAuth/Form';

const PasswordLessAuthLogin = () => {
  const { passwordLessAuthToken } = useParams();
  const dispatch = useDispatch();
  const [isBusy, setIsBusy] = useState(true);

  useEffect(() => {
    new Promise((resolve, reject) => {
      dispatch(passwordLessAuthLoginAction({ token: passwordLessAuthToken, resolve, reject }));
    })
      .then()
      .catch(() => {
        setIsBusy(false);
      });
  }, [dispatch, passwordLessAuthToken]);

  return isBusy ? (
    'Loading...'
  ) : (
    <AuthLayout>
      <PasswordLessFormAuth isExpired />
    </AuthLayout>
  );
};

export default memo(PasswordLessAuthLogin);
