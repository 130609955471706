import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { disconnectErp, setCurrentERPSettings, activateErpAction } from 'domain/settings/settingsActions';
import { availableErpsForCompanySelector, configurationCompanySelector } from 'domain/companies/companiesSelector';
import { canErpSyncSelector, isLoadingERPSettingSelector } from 'domain/settings/settingsSelector';
import { ERPOptionsAdapter, Statuses, sortingStatusOrderCb, statusesTitle } from './helpers';
import { ERP_NAMES } from 'domain/companies/helpers';
import queryString from 'query-string';
import { documentInvalidateMRUTagsCacheAction } from 'domain/documents';
import { FormattedMessage } from 'react-intl';
import useToggle from 'hooks/useToggle';

import AccordionDetails from '@mui/material/AccordionDetails';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import toast from 'components/Toast';
import Dialog from '@mui/material/Dialog';
import ZohoConnectModal from './components/ZohoConnectModal';
import ERPConnectModal from './components/ERPConnectModal';
import ERPSettings from 'components/ERPSettings';
import SyncButton from './components/SyncButton';
import CircularProgressWithBackdrop from 'components/mui/CircularProgressWithBackdrop';
import { useConfirm } from 'material-ui-confirm';
import useErpConfigs from 'pages/configurations/company/pages/integrations/hooks/erpConfigs/useErpConfigs';

import { useTheme } from '@mui/material/styles';
import { LoadingButton, Button, createStatuses, Accordion, AccordionSummary } from './styled';

const mapStateToProps = (state) => ({
  availableErps: availableErpsForCompanySelector(state),
  configurationCompany: configurationCompanySelector(state),
  isLoadingERPSetting: isLoadingERPSettingSelector(state),
  canErpSync: canErpSyncSelector(state),
});

const Integrations = () => {
  const [openedSection, setOpenedSection] = useState(null);
  const [connectModal, setConnectModal] = useState(null);
  const [isBusy, toggleIsBusy] = useToggle(null);

  const { availableErps, isLoadingERPSetting, configurationCompany, canErpSync } = useSelector(mapStateToProps);
  const dispatch = useDispatch();
  const theme = useTheme();
  const { erpsOptions } = useErpConfigs();

  const confirm = useConfirm();

  const handleSectionClick = (section) => (e) => {
    setOpenedSection(openedSection === section ? null : section);
  };

  const openConnectModal = useCallback((id) => {
    setConnectModal(id);
  }, []);

  const handleConnectModalClose = useCallback(() => {
    setConnectModal(null);
  }, []);

  const onDisconnectClick = useCallback(
    (title) => {
      confirm({
        title: <FormattedMessage id="settings.modals.disconnect.confirm.title" defaultMessage="Disconnect company" />,
        description: (
          <FormattedMessage
            id="settings.modals.disconnect.confirm.message"
            defaultMessage="Are you sure you want to disconnect?"
            values={{
              labelName: theme.labelOptions.name.toUpperCase(),
              erpName: title,
              companyName: configurationCompany.name,
            }}
          />
        ),
        confirmationText: (
          <FormattedMessage id="settings.modals.disconnect.confirm.acceptBtn" defaultMessage="Disconnect" />
        ),
        confirmationButtonProps: {
          color: 'error',
        },
      }).then(() => {
        dispatch(disconnectErp());
      });
    },
    [configurationCompany.name, confirm, dispatch, theme.labelOptions.name],
  );

  const handleSageError = useCallback(() => {
    const queryParams = queryString.parse(window.location.search);
    if (queryParams.error) {
      toast.error(queryParams.error);
    }
  }, []);

  // clear cache data related to ERP settings.
  const invalidateCacheData = useCallback(() => {
    // clear cached company tags
    dispatch(documentInvalidateMRUTagsCacheAction());
  }, [dispatch]);

  const handleSubmit = useCallback(
    (erp: string, data: Map<string, string>) => {
      const { propagate = {}, ...config } = data.toJS();
      toggleIsBusy(true);

      return new Promise((resolve, reject) => {
        dispatch(
          setCurrentERPSettings({
            data: {
              config,
              erp_type: erp.toUpperCase(),
              ...propagate,
            },
            resolve,
            reject,
          }),
        );
      })
        .then(invalidateCacheData)
        .finally(() => toggleIsBusy(false));
    },
    [dispatch, invalidateCacheData, toggleIsBusy],
  );

  const getIsConnected = useCallback(
    (settings) => [Statuses.connected, Statuses.authorized].some((i) => i === settings.status),
    [],
  );

  const renderButton = useCallback(
    ({ settings, button, section, erpConnect, option }) => {
      const isConnected = getIsConnected(settings);

      const initButtons = () => {
        switch (section) {
          case ERP_NAMES.defaultErp:
            switch (settings.status) {
              case Statuses.inactive:
                return (
                  <LoadingButton
                    variant="contained"
                    loading={settings.status === 'connecting'}
                    onClick={() => dispatch(activateErpAction(section))}
                  >
                    <FormattedMessage id="settings.connections.btn.activate" defaultMessage="Activate" />
                  </LoadingButton>
                );
              default:
                return null;
            }

          default:
            switch (true) {
              case ERP_NAMES.qbd === section && !isConnected:
                return (
                  <LoadingButton
                    variant="contained"
                    loading={settings.status === 'connecting'}
                    onClick={() => dispatch(activateErpAction(section))}
                  >
                    <FormattedMessage id="settings.connections.btn.activate" defaultMessage="Activate" />
                  </LoadingButton>
                );
              case isConnected: {
                return (
                  <Button variant="text" color="error" onClick={() => onDisconnectClick(option.title)}>
                    <FormattedMessage id="settings.connections.disconnect" defaultMessage="Disconnect" />
                  </Button>
                );
              }
              case !!button: {
                return button;
              }
              case !!erpConnect: {
                return (
                  <LoadingButton
                    variant="contained"
                    loading={settings.status === 'connecting'}
                    onClick={() => {
                      openConnectModal(option);
                    }}
                  >
                    <FormattedMessage defaultMessage="Connect" id="settings.connections.connect" />
                  </LoadingButton>
                );
              }
              default: {
                return null;
              }
            }
        }
      };
      return initButtons();
    },
    [dispatch, onDisconnectClick, openConnectModal, getIsConnected],
  );

  const options = useMemo(() => ERPOptionsAdapter(availableErps)(erpsOptions), [availableErps, erpsOptions]);

  useEffect(() => {
    handleSageError();
  }, [handleSageError]);

  return (
    <Stack flex={0}>
      {Object.entries(options)
        .sort(sortingStatusOrderCb)
        .map(([name, options]) => (
          <Stack key={name} justifyContent="start">
            <Typography variant="subtitle1" sx={{ mt: 1 }}>
              <FormattedMessage {...statusesTitle[name]} />
            </Typography>
            {options.map((option) => {
              const { title, settings, section, erpConnect, erpName, button, icon } = option;
              const isConnected = getIsConnected(settings);

              return (
                <Accordion key={title} expanded={section === openedSection} onChange={handleSectionClick(section)}>
                  <AccordionSummary>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      flex="1"
                      flexWrap="no-wrap"
                    >
                      <Stack display="flex" direction="row" spacing={1} alignItems="center">
                        <Stack
                          justifyContent="center"
                          alignItems="center"
                          sx={(theme) => ({
                            padding: theme.spacing(0.5, 1),
                            border: 1,
                            borderColor: theme.palette.grey[200],
                            backgroundColor: theme.palette.grey[100],
                            borderRadius: 2,
                            minHeight: 34,
                          })}
                        >
                          <img width={24} height="auto" src={icon} alt="icon" />
                        </Stack>
                        <Typography>{[title]}</Typography>
                        {createStatuses(option)[settings.status]}
                      </Stack>
                      <Stack direction="row" spacing={2} height="100%">
                        {isConnected && canErpSync && <SyncButton />}
                        {isConnected && renderButton({ settings, button, section, erpConnect, option })}
                      </Stack>
                    </Stack>
                  </AccordionSummary>
                  {isConnected ? (
                    <AccordionDetails sx={{ p: 2 }}>
                      <ERPSettings
                        onSubmit={(data) => handleSubmit(section, data)}
                        erp={section}
                        erpName={erpName}
                        isBusy={isLoadingERPSetting || isBusy}
                      />
                    </AccordionDetails>
                  ) : (
                    <AccordionDetails sx={{ p: 2, pl: 5, pt: 0 }}>
                      {renderButton({ settings, button, section, erpConnect, option })}
                    </AccordionDetails>
                  )}
                  {option.settings.get('status') === 'authorized' && <ZohoConnectModal />}
                </Accordion>
              );
            })}
          </Stack>
        ))}
      {connectModal && (
        <Dialog open={!!connectModal} onClose={handleConnectModalClose} fullWidth>
          <ERPConnectModal
            handleConnectModal={handleConnectModalClose}
            erp={connectModal.section}
            {...connectModal.erpConnect}
          />
        </Dialog>
      )}
      {(isLoadingERPSetting || isBusy) && <CircularProgressWithBackdrop />}
    </Stack>
  );
};

export default Integrations;
