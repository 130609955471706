// @flow
import React, { useState, useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';

import { approvalsRejectReasonsSelector, rejectApprovalAction } from 'domain/approvals';
import useRedirectWhenApprovalsEnd from 'hooks/approval/useRedirectWhenApprovalsEnd';

import Dialog from 'components/mui/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Stack from '@mui/material/Stack';
import TextFieldBase from 'components/mui/Form/TextField/TextFieldBase';
import Button from '@mui/material/Button';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

import { type ApprovalReasonRecordMap } from 'domain/approvals/types.js.flow';

const OTHER_REASON = 'other';

type TDialogRejectApproval = {
  onClose: () => void,
  onManageApprovals: () => void,
  isOpen?: boolean,
};

const DialogRejectApproval: React$StatelessFunctionalComponent<TDialogRejectApproval> = ({
  onClose,
  onManageApprovals = (x) => x,
  isOpen = true,
}) => {
  const dispatch = useDispatch();
  const rejectOptions: ApprovalReasonRecordMap = useSelector(approvalsRejectReasonsSelector);
  const { formatMessage } = useIntl();
  const { redirectToApprove, isAllowRedirectAfterApprove: isAllowRedirect } = useRedirectWhenApprovalsEnd();

  const [reason, setReason] = useState(OTHER_REASON);
  const [message, setMessage] = useState('');

  const onChange = useCallback((e: SyntheticInputEvent<HTMLInputElement>) => {
    setMessage(e.currentTarget.value);
  }, []);

  const handleSubmit = useCallback(
    (e: SyntheticEvent<HTMLFormElement>) => {
      e.preventDefault();

      new Promise((resolve, reject) => {
        dispatch(rejectApprovalAction({ reason, message, resolve, reject }));
      })
        .then(() => (isAllowRedirect ? redirectToApprove() : onManageApprovals()))
        .finally(() => {
          onClose();
        });
    },
    [dispatch, reason, message, onClose, onManageApprovals, isAllowRedirect, redirectToApprove],
  );

  const isDisabled = useMemo(() => !reason || (reason === OTHER_REASON && !message), [reason, message]);

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      title={formatMessage({ id: 'document.show.modals.rejectApproval.title', defaultMessage: 'Reject Approval?' })}
      maxWidth="sm"
      withActions={false}
      withContent={false}
    >
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <Stack spacing={2}>
            <RadioGroup aria-labelledby="reject-reason-group" name="reject-reason" defaultValue={OTHER_REASON}>
              {rejectOptions.entrySeq().map(([reasonId, reasonTranslation]) => (
                <FormControlLabel
                  key={reasonId}
                  onChange={() => setReason(reasonId)}
                  value={reasonId}
                  control={<Radio />}
                  label={reasonTranslation}
                />
              ))}
            </RadioGroup>

            <TextFieldBase
              value={message}
              onChange={onChange}
              label={formatMessage({ id: 'document.show.modals.rejectApproval.details', defaultMessage: 'Details' })}
              placeholder={formatMessage({
                id: 'document.show.modals.rejectApproval.placeholder',
                defaultMessage: 'Details…',
              })}
              fullWidth
              autoFocus
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button variant="text" onClick={onClose}>
            {formatMessage({
              id: 'button.cancel',
              defaultMessage: 'Cancel',
            })}
          </Button>
          <Button color="error" type="submit" disabled={isDisabled}>
            {formatMessage({
              id: 'document.show.modals.rejectApproval.btn',
              defaultMessage: 'Reject',
            })}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default DialogRejectApproval;
