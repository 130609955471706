// @flow
import React, { Component } from 'react';
import { injectIntl, type IntlShape } from 'react-intl';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { type RecordOf } from 'immutable';
import {
  updateOrganizationSignings,
  organizationSigningsSelector,
  removeOrganizationSigner,
  addEmptyOrganizationSigner,
  removeAllOrganizationSigner,
  setOrganizationSigningsGuid,
} from 'domain/organization';
import { type SigningsType } from 'domain/organization/types.js.flow';
import { type Signer } from 'pages/common/forms/signer';
import FeaturesForm from 'pages/common/forms/features';
import Prompt from 'components/Prompt';

type Props = {
  // eslint-disable-next-line react/no-unused-prop-types
  handleSubmit: () => void,
  signings: RecordOf<SigningsType>,
  sign: (data: Signer) => void,
  addSign: () => void,
  remove: (tokenName: string) => void,
  removeAll: () => void,
  setGuid: (guid: string) => void,
  intl: IntlShape,
};

class FeaturesFormContainer extends Component<Props> {
  get guidNotPresent(): boolean {
    const { guid, tokens } = this.props.signings;
    // turned on and guid empty
    return tokens.size > 0 && guid.length === 0;
  }

  get signersNotPresent(): boolean {
    const { tokens, guid } = this.props.signings;
    // if turned on, check for filled value
    if (tokens.size > 0 || guid.length > 0) {
      // 2 signers present, check if at least one is not filled
      if (tokens.size === 2) {
        return tokens.some((token) => token.name.length === 0 && token.password.length === 0);
      }
      // single signer present, check fails automaticaly as we need 2
      return true;
    }
    // turned off, check passes automatically
    return false;
  }

  get message(): string {
    const { intl } = this.props;
    return this.guidNotPresent
      ? intl.formatMessage({
          id: 'forms.common.features.guidEmpty',
          defaultMessage: 'Signing will not work properly without provided GUID',
        })
      : intl.formatMessage({
          id: 'forms.common.features.signersEmpty',
          defaultMessage: 'Signing will not work properly until both signers are added',
        });
  }

  render() {
    return (
      <>
        <Prompt when={this.guidNotPresent || this.signersNotPresent} message={this.message} />
        <FeaturesForm
          onSign={this.props.sign}
          onAddSign={this.props.addSign}
          onSetGuid={(data) => this.props.setGuid(data.toJS())}
          onSignRemove={this.props.remove}
          onAllSignsRemove={this.props.removeAll}
          signings={this.props.signings}
          context="organization"
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  signings: organizationSigningsSelector(state),
});

const mapDispatchToProps = {
  sign: updateOrganizationSignings,
  addSign: addEmptyOrganizationSigner,
  remove: removeOrganizationSigner,
  setGuid: setOrganizationSigningsGuid,
  removeAll: removeAllOrganizationSigner,
};

export default compose(injectIntl, connect(mapStateToProps, mapDispatchToProps))(FeaturesFormContainer);
