// @flow
import React, { useCallback } from 'react';
import { useConfirm } from 'material-ui-confirm';

// redux
import { useSelector } from 'react-redux';

// intl
import { useIntl } from 'react-intl';

// mui
import SwitchBase from 'components/mui/Form/Switch/SwitchBase';
import { FormControlLabelSwitch } from 'components/mui/Form/FormControl/FormControlLabelSwitch';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import AlertTitleMui from '@mui/material/AlertTitle';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import AddCircleIcon from '@mui/icons-material/AddCircle';

// icons
import RotateLeftIcon from '@mui/icons-material/RotateLeft';

// components
import { FirstSigner, SecondSigner } from './signer';
import Guid from './guid';
import FeaturesSettings from 'pages/configurations/components/FeaturesSettings';
import Tooltip from 'components/mui/Tooltip';

// selectors
import { devicesSelector } from 'domain/devices/selectors';

// types
import { type SigningsType } from 'domain/organization/types.js.flow';
import { type Signer } from 'pages/common/forms/signer';
import { type RecordOf } from 'immutable';

// theme
import { useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';

const AlertTitle = styled(AlertTitleMui)(() => ({
  margin: 0,
}));

type Props = {
  context: 'organization' | 'company',
  onSign: (data: RecordOf<Signer>) => void,
  onAddSign: () => void,
  featureSet: boolean,
  onSetFeatureSet: (value: boolean) => void,
  onAllSignsRemove: () => void,
  onGetCopy: () => void,
  onSignRemove: ({ tokenName: string, id: string }) => void,
  onSetGuid: ({ guid: string }) => void,
  signings: SigningsType,
};

const Features: React$StatelessFunctionalComponent<Props> = ({
  context,
  signings,
  onAddSign,
  featureSet,
  onSetGuid,
  onGetCopy,
  onSign,
  onSetFeatureSet,
  onSignRemove,
  onAllSignsRemove,
}) => {
  const { labelOptions } = useTheme();
  const { formatMessage } = useIntl();
  const confirm = useConfirm();

  const devices = useSelector(devicesSelector);

  const getCopy = useCallback(() => {
    confirm().then(() => {
      onGetCopy();
    });
  }, [onGetCopy, confirm]);

  const onSwitchSign = useCallback(() => {
    if (signings.tokens.size > 0 || signings.guid.length > 0) {
      onAllSignsRemove();
    } else {
      onAddSign();
    }
  }, [onAddSign, onAllSignsRemove, signings.tokens.size, signings.guid.length]);

  const isSignOn = devices.size && (signings.guid.length > 0 || signings.tokens.size > 0);

  return (
    <>
      {context === 'company' ? (
        <>
          <FormControlLabelSwitch
            label={
              <AlertTitle>
                {formatMessage({
                  id: 'forms.company.features.financialLabel',
                  defaultMessage: 'Enable Financial Features',
                })}
              </AlertTitle>
            }
            onChange={(e) => onSetFeatureSet(e.target.checked)}
            checked={featureSet}
            control={<SwitchBase />}
          />
          <Typography variant="body2">
            {formatMessage({
              id: 'forms.company.features.company.hint1',
              defaultMessage: '1. Enabling Financial views of the documents',
            })}
          </Typography>
          <Typography variant="body2">
            {formatMessage({
              id: 'forms.company.features.company.hint2',
              defaultMessage: '2. Integrations with the accounting SW packages.',
            })}
          </Typography>
          <Divider sx={{ my: 2 }} />
        </>
      ) : null}
      <Box>
        <Box position="relative">
          <FormControlLabelSwitch
            label={
              <AlertTitle>
                {formatMessage({
                  id: 'forms.organization.features.sign.label',
                  defaultMessage: 'Enable Signing Features',
                })}
              </AlertTitle>
            }
            onChange={onSwitchSign}
            checked={isSignOn}
            control={<SwitchBase />}
            disabled={devices.size === 0}
          />
          {!isSignOn && (
            <Typography variant="body2">
              {formatMessage({
                id: 'forms.organization.features.sign.hint',
                defaultMessage: 'Allows you to sign documents using Signing Keys',
              })}
            </Typography>
          )}
          {context === 'company' && isSignOn ? (
            <Grid container alignItems="baseline">
              <Button
                sx={{
                  fontSize: '0.75rem',
                  fontWeight: 400,
                  textTransform: 'none',
                }}
                onClick={getCopy}
                type="button"
                variant="text"
                size="small"
                startIcon={<RotateLeftIcon fontSize="small" />}
              >
                {formatMessage({
                  id: 'forms.company.features.copy',
                  defaultMessage: 'Re-use Organization Settings',
                })}
              </Button>
              <Tooltip
                t={formatMessage({
                  id: 'forms.organization.features.sign.hint',
                  defaultMessage: 'Allows you to sign documents using Signing Keys',
                })}
                placement="right"
              >
                <HelpOutlineOutlinedIcon fontSize="small" sx={{ color: 'grey.600' }} />
              </Tooltip>
            </Grid>
          ) : null}
        </Box>
        {isSignOn ? (
          <>
            <Guid initialValues={{ guid: signings.guid }} onSubmit={onSetGuid} guid={signings.guid} devices={devices} />
            {signings.guid.length < 1 ? (
              <Typography variant="body2" color="warning.main" sx={{ mb: 2, mt: 1 }}>
                {formatMessage({
                  id: 'forms.common.features.guid',
                  defaultMessage: 'Without GUID signing features won’t work properly.',
                })}
              </Typography>
            ) : null}

            <Grid container>
              {signings.tokens.size > 0 ? (
                <FirstSigner
                  signer={signings.tokens.first()}
                  onSubmit={onSign}
                  onRemove={() =>
                    onSignRemove({
                      tokenName: signings.tokens.first().token_name,
                      idx: 0,
                    })
                  }
                />
              ) : null}
              {signings.tokens.size > 1 ? (
                <SecondSigner
                  signer={signings.tokens.last()}
                  onSubmit={onSign}
                  onRemove={() => onSignRemove({ tokenName: signings.tokens.last().token_name, idx: 1 })}
                />
              ) : null}
              {signings.tokens.size < 2 ? (
                <Grid item mt={1}>
                  <Button
                    type="button"
                    onClick={onAddSign}
                    size="small"
                    variant="text"
                    sx={{ textTransform: 'none' }}
                    startIcon={<AddCircleIcon />}
                  >
                    {formatMessage({
                      id: 'forms.organization.features.addSigner',
                      defaultMessage: 'Add one more signer',
                    })}
                  </Button>
                </Grid>
              ) : null}
            </Grid>
          </>
        ) : null}
      </Box>
      <Divider sx={{ my: 2 }} />
      <FeaturesSettings isCompany={context === 'company'} labelOptions={labelOptions} />
    </>
  );
};

export default Features;
