import React, { memo, useCallback, useRef, useEffect } from 'react';

import Tooltip from 'components/mui/Tooltip';
import IconButton from '@mui/material/IconButton';

import FullscreenIcon from '@mui/icons-material/Fullscreen';

const FullscreenIconButton = () => {
  const htmlRef = useRef();

  const openFullscreen = useCallback(() => {
    if (htmlRef.current) {
      const element = htmlRef.current;

      if (element.requestFullscreen) {
        element.requestFullscreen();
      } else if (element.webkitRequestFullscreen) {
        /* Safari */
        element.webkitRequestFullscreen();
      } else if (element.msRequestFullscreen) {
        /* IE11 */
        element.msRequestFullscreen();
      }
    }
  }, []);

  const exitFullscreen = useCallback(() => {
    if (document.exitFullscreen) {
      document.exitFullscreen().then();
    } else if (document.webkitExitFullscreen) {
      /* Safari */
      document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) {
      /* IE11 */
      document.msExitFullscreen();
    }
  }, []);

  const onClick = useCallback(() => {
    const isFullscreen =
      document.fullscreenElement ||
      document.webkitFullscreenElement ||
      document.mozFullScreenElement ||
      document.msFullscreenElement;

    if (isFullscreen) exitFullscreen();
    else openFullscreen();
  }, [exitFullscreen, openFullscreen]);

  useEffect(() => {
    const [el] = document.getElementsByTagName('html');

    htmlRef.current = el;

    return () => {
      htmlRef.current = null;
    };
  }, []);

  return (
    <Tooltip t={{ id: 'header.menu.fullscreen', defaultMessage: 'Full screen' }}>
      <IconButton color="primary" onClick={onClick}>
        <FullscreenIcon />
      </IconButton>
    </Tooltip>
  );
};

export default memo(FullscreenIconButton);
