import { type RecordFactory, List, Record } from 'immutable';
import type {
  TUiAFStampMode,
  TUiApprovalFlow,
  TUiModal,
  TUiState,
  TUiAFStamp,
  TUiWorkspace,
} from 'domain/ui/types.js.flow';

const ModalFactory: RecordFactory<TUiModal> = new Record({
  isOpenDocumentHotkeysModal: false,
  isOpenWorkspaceHotkeysModal: false,
});

export const validStampMode: TUiAFStampMode = ['visible', 'edit', 'move', 'hidden'];

export const UiAFStampFactory: RecordFactory<TUiAFStamp> = new Record({
  ll: List([0, 0]),
  ur: List([0, 0]),
  rotation: 0,
});

const ApprovalFlowFactory: RecordFactory<TUiApprovalFlow> = new Record({
  isOpen: false,
  stampMode: 'hidden',
  stamp: null,
});

const WorkspaceFactory: RecordFactory<TUiWorkspace> = new Record({
  isActiveContextMenu: false,
});

export const UiStateFactory: RecordFactory<TUiState> = new Record({
  modal: ModalFactory(),
  approvalFlow: ApprovalFlowFactory(),
  workspace: WorkspaceFactory(),
});
