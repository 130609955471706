// @flow
import React from 'react';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Avatar from 'components/mui/Avatar';

type TProps = {
  name: string,
  picture?: string,
};

const NameCell: React$StatelessFunctionalComponent<TProps> = ({ name, picture }) => (
  <Stack direction="row" alignItems="center" spacing={1}>
    <Avatar size={40} src={picture} />
    <Typography variant="body1">{name}</Typography>
  </Stack>
);

export default NameCell;
