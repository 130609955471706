import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import * as ACL from 'domain/restriction';
import { rtlEnable } from 'domain/env';

import { invertPinnedValue, invertPinnedState } from 'components/AgGrid/helpers';

import type { TColumnDefs } from 'pages/company/Grid/types.js.flow';

const mapPropsToState = (state) => ({
  isGranted: ACL.isGranted(state),
  isRtl: rtlEnable(state),
});

const useAgGridMethods = () => {
  const { isRtl } = useSelector(mapPropsToState);

  const invertPinnedWhenRTL = useCallback(
    (columns: TColumnDefs[]) => (isRtl ? invertPinnedValue(columns) : columns),
    [isRtl],
  );

  const invertPinnedStateWhenRTL = useCallback((state) => (isRtl ? invertPinnedState(state) : state), [isRtl]);

  return {
    invertPinnedWhenRTL,
    invertPinnedStateWhenRTL,
  };
};

export default useAgGridMethods;
