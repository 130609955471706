/* @flow */
import * as React from 'react';
import { Set, List } from 'immutable';
import get from 'lodash/get';
import indexedDb from 'indexedDb';
import makeCancelable, { type PromiseCanceleable } from 'lib/makeCancelable';
import VisibilityColsDropdownForVirtualGrid from 'components/Tables/layout/grid/VisibilityColsDropdown/VisibilityColsDropdownForVirtualGrid';
import { namedCols } from './helpers';
import { swapJEGridLineColumnsData } from 'domain/journal/helper';

import Box from '@mui/material/Box';

import type { VGridProps, SwitchColItem } from './Types.js.flow';

type ColSwitchProps = {|
  storeKey: string,
  initialHiddenColumns: List<string>,
  onChangeDisabledCols: (c: Array<string>) => void,
  notHiddenColumns: Set<string>,
  forcedEnabledCols: Array<string>,
|};

type Props = {
  ...$Exact<VGridProps>,
  ...ColSwitchProps,
};

type State = {
  disabledCols: Set<string>,
};

function withColSwitch(WrappedComponent: React$ComponentType<VGridProps>) {
  class WithColSwitch extends React.Component<Props, State> {
    constructor(props: Props) {
      super(props);
      this.columns = namedCols(props.meta, props.data);
    }

    state = {
      disabledCols: new Set(),
    };

    componentDidMount() {
      this.loadDisabledCols();
    }

    componentDidUpdate(prevProps: Props) {
      const { data, meta } = this.props;
      if (prevProps.storeKey !== this.props.storeKey) {
        this.columns = namedCols(meta, data);
        this.loadDisabledCols();
      }
    }

    componentWillUnmount() {
      if (this.disabledColsCancelable) {
        this.disabledColsCancelable.cancel();
      }
    }

    get columnList(): $ReadOnlyArray<SwitchColItem> {
      const { forcedEnabledCols } = this.props;
      const { disabledCols } = this.state;
      return this.columns.map(({ name, title }) => ({
        name,
        title,
        disabled: this.getDisabledState(name),
        state: !disabledCols.has(name) || forcedEnabledCols.includes(name),
      }));
    }

    get meta() {
      const { meta } = this.props;
      return [this.columnList.reduce((a, v) => (v.state ? [...a, v.name] : a), []), meta[1]];
    }

    getDisabledState = (name: string): boolean => {
      const {
        props: { notHiddenColumns },
        state: { disabledCols },
      } = this;
      if (disabledCols.has(name)) return false;
      if (notHiddenColumns.has(name)) return true;
      if (this.columns.length - disabledCols.size === 1) return true;
      return false;
    };

    loadDisabledCols = () => {
      const { initialHiddenColumns, storeKey, gridLineColumnsDictionary } = this.props;
      if (this.disabledColsCancelable) {
        this.disabledColsCancelable.cancel();
      }
      this.disabledColsCancelable = makeCancelable(indexedDb.tableDisabledCols.get(storeKey));
      this.disabledColsCancelable.promise
        .then((res) => {
          const unserializedData = swapJEGridLineColumnsData(
            get(res, 'disabledCols', []),
            gridLineColumnsDictionary,
            true,
          );
          const disabledCols = Set(unserializedData.length > 0 ? unserializedData : [...initialHiddenColumns]);

          this.setState({
            disabledCols,
          });
        })
        .catch(() => null);
    };

    handleChange = (name: string, state: boolean) => {
      const { storeKey, gridLineColumnsDictionary } = this.props;
      const { disabledCols } = this.state;
      const disabledColsSet = state ? disabledCols.delete(name) : disabledCols.add(name);

      indexedDb.tableDisabledCols.put({
        disabledCols: swapJEGridLineColumnsData(disabledColsSet.toArray(), gridLineColumnsDictionary),
        tableName: storeKey,
      });
      this.setState({
        disabledCols: disabledColsSet,
      });
    };

    disabledColsCancelable: ?PromiseCanceleable<*>;

    columns: $ReadOnlyArray<{ name: string, title: string }>;

    render() {
      return (
        <Box position="relative" flexGrow={1} height="100%">
          <WrappedComponent
            {...this.props}
            meta={this.meta}
            startRow={this.meta[1][0]}
            selectPlaceCol={this.meta[0][0]}
          />
          <VisibilityColsDropdownForVirtualGrid columns={this.columnList} handleChange={this.handleChange} />
        </Box>
      );
    }
  }

  WithColSwitch.displayName = WrappedComponent.displayName;
  return WithColSwitch;
}

export default withColSwitch;
