// @flow
import * as React from 'react';

import { FormattedMessage } from 'react-intl';

import type { ApprovalRecordList } from 'domain/approvals/types.js.flow';

import { Map } from 'immutable';
import type { RecordOf } from 'immutable';
import type { ChatUser } from 'domain/chat/types.js.flow';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import ArrowButton from 'pages/document/DocumentTopPanel/ArrowButton';
import Approver from 'pages/components/Approver';
import Avatar from '@mui/material/Avatar';

const Wrapper = styled(Stack)(({ theme }) => ({
  position: 'absolute',
  inset: 0,
  zIndex: theme.zIndex.mobileStepper,
  overflow: 'hidden',
  height: '100%',
  backgroundColor: theme.palette.common.white,
}));

export type Props = {
  onClose: () => void,
  approvals: ApprovalRecordList,
  usersById: Map<string, RecordOf<ChatUser>>,
  innerRef: any,
};

class FullFlow extends React.Component<Props> {
  render() {
    const { onClose, approvals, usersById, innerRef } = this.props;

    return (
      <Wrapper ref={innerRef}>
        <Stack
          direction="row"
          justifyContent="start"
          alignItems="center"
          sx={{ border: 1, borderColor: 'grey.400', borderLeft: 0, borderRight: 0, p: 1 }}
          flex={0}
        >
          <Typography varian="h3">
            <FormattedMessage id="mobile.approval.flow.full.title" defaultMessage="Document Approvals" />
          </Typography>
        </Stack>
        <Stack flex={1} overflow="auto" minHeight={0} spacing={2} sx={{ pt: 2 }}>
          {approvals.map((approval, idx) => (
            <Approver
              hasArrow={approvals.size !== idx + 1}
              arrowDirection="column"
              spacing={2}
              key={approval.tag}
              label={approval.get('fullName')}
              status={approval.get('status')}
              avatar={<Avatar alt={approval.get('fullName')} src={usersById.getIn([approval.get('tag'), 'picture'])} />}
            />
          ))}
        </Stack>
        <Stack flex={0} sx={{ px: 1, py: 2 }} justifyContent="center" alignItems="center">
          <ArrowButton open onClick={onClose} />
        </Stack>
      </Wrapper>
    );
  }
}

export default React.forwardRef((props, ref) => <FullFlow {...props} innerRef={ref} />);
