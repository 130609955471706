import ui, { modalBehaviours } from 'lib/ui';
import mediaQuery from 'lib/mediaQuery';
import cross from './icons/cross.svg';
import stroke from './icons/stroke.svg';
import lockIcon from './icons/lockIcon.svg';
import unlockIcon from './icons/unlockIcon.svg';
import favoriteIcon from './icons/favorite.svg';

import * as ACL from 'domain/restriction';

import { getWidth, params, xsParams } from '../sheet_legacy';

export default (theme) => ({
  wrapper: {
    position: 'fixed',
    right: 0,
    top: 0,
    willChange: 'transform',
    width: (props) => `${getWidth(props)}px`,
    animationDuration: '0.35s',
    animationName: '$bounceInRight',
    height: '100%',
    zIndex: ({ previewOpened }) => (previewOpened ? 1000 : 900),
    background: theme.colors.greyLightest,
    boxShadow: '-4px 0px 13px rgba(23, 41, 47, 0.2)',
    display: 'flex',
    flexDirection: 'column',
  },
  titleHeader: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    backgroundColor: '#FFF',
  },
  lockIcon: {
    cursor: 'pointer',
    width: 25,
    height: 25,
    WebkitMask: ({ isPanelLocked, isForcePanelLocked }) =>
      `url("${isPanelLocked || isForcePanelLocked ? lockIcon : unlockIcon}")`,
    maskSize: 'cover',
    background: theme.colors.primaryDark,
    border: '0 none',
    outline: 'none',
    '&:disabled': {
      background: '#8C9BA5',
      cursor: 'not-allowed',
    },
  },

  lockTooltip: {
    display: 'flex',
    alignItems: 'center',
    visibility: ({ isGranted }) => (isGranted(ACL.IS_SUPPLIER_USER) ? 'hidden' : 'initial'),
  },

  close: {
    cursor: 'pointer',
    width: 16,
    height: 16,
    background: `url("${cross}") scroll no-repeat center`,
    border: '0 none',
  },
  title: {
    color: '#171A1C',
    textAlign: 'center',
    background: '#fff',
    padding: [26, 0, 26],
    fontSize: 19,
    fontWeight: 200,
  },
  content: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
  header: {
    height: 200,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#fff',
  },
  mainPreview: {
    maxHeight: 304,
    '& img': {
      maxHeight: '100%',
      maxWidth: 'calc(100% - 50px)',
      '@media all and (max-height: 900px)': {
        maxHeight: 'calc((100vh - 120px)*0.4*0.8)',
      },
    },
  },
  itemsDropzone: {
    flex: '1 100%',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    '&:after': {
      height: 2,
      position: 'absolute',
      content: '""',
      background: '#DEE2E6',
      display: 'inline-block',
      top: 0,
      left: 16,
      width: 'calc(100% - 32px)',
    },
  },
  items: {
    padding: [6, 0],
    margin: [0, 16, 0, 16],
    flex: [1, 1, 0],
    overflowY: 'auto',
    overflowX: 'hidden',
    border: '2px solid transparent ',
    transition: 'background 0.3s ease',
    '&$empty': {
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
  list: {
    padding: [5, 10],
    display: 'grid',
    gap: 10,
    flexWrap: 'wrap',
    gridTemplateColumns: `repeat(auto-fill, minmax(${params.width}px, 1fr))`,
    [`@media (max-height: ${mediaQuery.maxHeight})`]: {
      gridTemplateColumns: `repeat(auto-fill, minmax(${xsParams.width}px, 1fr))`,
    },
    '& > li > div': {
      height: '100%',
    },
  },
  empty: {},
  docInList: {
    zIndex: 1,
    width: '100%',
    height: '100%',
    marginRight: 8,
    // willChange: 'transform', this  makes tooltip block pverlap item bottom and forces mouseover
    // to repeatedly be invoked.
    animationDuration: '0.35s',
    animationName: '$bounceIn',
    '&:nth-child(2)': {
      marginRight: 0,
    },
  },
  dragOver: {
    background: 'rgba(79, 116, 209, 0.08)',
    border: '2px dashed #4F74D1',
  },
  dndPlaceholder: {
    fontSize: 19,
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    color: '#333333',
    textAlign: 'center',
    padding: [0, 40],
    fontWeight: 200,
  },
  '@keyframes bounceIn': {
    'from, 20%, 40%, 60%, 80%, to': {
      animationTimingFunction: 'cubic-bezier(0.215, 0.61, 0.355, 1)',
    },

    '0%': {
      opacity: 0,
      transform: 'scale3d(0.3, 0.3, 0.3)',
    },

    '20%': {
      transform: 'scale3d(1.1, 1.1, 1.1)',
    },

    '40%': {
      transform: 'scale3d(0.9, 0.9, 0.9)',
    },

    '60%': {
      opacity: 1,
      transform: 'scale3d(1.03, 1.03, 1.03)',
    },

    '80%': {
      transform: 'scale3d(1, 1, 1)',
    },

    to: {
      opacity: 1,
      transform: 'scale3d(1, 1, 1)',
    },
  },
  '@keyframes bounceInRight': {
    'from, 20%, 40%, 60%, 80%, to': {
      animationTimingFunction: 'cubic-bezier(0.215, 0.61, 0.355, 1)',
      transformOrigin: 'right',
    },

    '0%': {
      opacity: 0,
      transform: 'scaleX(0.3)',
    },

    '20%': {
      transform: 'scaleX(1.1)',
    },

    '40%': {
      transform: 'scaleX(0.9)',
    },

    '60%': {
      opacity: 1,
      transform: 'scaleX(1.03)',
    },

    '80%': {
      transform: 'scaleX(1)',
    },

    to: {
      opacity: 1,
      transform: 'scaleX(1)',
    },
  },
  footer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: [0, 25],
    height: 56,
    zIndex: 10,
    background: '#fff',
    boxShadow: '0px 2px 13px rgba(50, 45, 45, 0.25)',
    position: 'relative',
  },
  actionsPopup: {
    position: 'absolute',
    bottom: '100%',
    marginBottom: 10,
    zIndex: 1,
    background: '#fff',
    boxShadow: '0px 12px 24px rgba(0, 0, 0, 0.1)',
    border: '1px solid #DFE3E6',
    padding: [4, 0],
    left: 0,
  },
  actionsList: {
    '& li:last-child': {
      '& button:disabled > span': {
        color: '#D3D6D9',
        '&:hover': {
          color: '#D3D6D9',
        },
      },
      '& $divided': {
        borderBottom: 'none',
      },
    },
  },
  actionItem: {
    position: 'relative',
    height: 40,
    width: 160,
    padding: [4, 12],
    fontSize: 14,
    color: '#171A1C',
    cursor: 'pointer',
    transition: 'color 0.1s ease, background 0.1s ease',
    background: 'none',
    border: 'none',
    textAlign: 'left',
    fontWeight: 200,
    '&[disabled]': {
      color: '#D3D6D9',
      '&:hover': {
        cursor: 'not-allowed',
        background: 'none',
        color: '#D3D6D9',
      },
    },
  },
  disabledActionItem: {
    color: '#D3D6D9',
    '&:hover': {
      cursor: 'not-allowed',
      background: 'none',
      color: '#D3D6D9',
    },
  },
  activeActionItem: {
    background: theme.colors.primaryLight,
    color: '#fff',
  },
  submenuActionItem: {
    '&:after': {
      content: '""',
      position: 'absolute',
      height: 8,
      width: 8,
      top: 15,
      right: 12,
      borderTop: '2px solid #4F4F4F',
      borderRight: '2px solid #4F4F4F',
      transform: 'rotate(45deg)',
    },
    '&$activeActionItem:after': {
      borderColor: '#FFF',
    },
    '&$disabledActionItem:after': {
      borderColor: '#D3D6D9',
    },
  },
  dangerActionItem: {
    color: '#BC5353',
    '&$activeActionItem': {
      color: '#FFF',
    },
  },

  divided: {
    borderTop: '1px solid #DEE2E6',
  },
  actionButtons: {
    padding: 30,
    display: 'flex',
    justifyContent: 'flex-end',
    [`@media (max-height: ${mediaQuery.midHeight})`]: {
      padding: [15, 30],
    },
    '& > button:last-child': {
      marginRight: 0,
    },
    [`@media (max-height: ${mediaQuery.maxHeight})`]: {
      padding: 10,
    },
    extend: modalBehaviours.modalButtonGroupBottomMargin,
  },
  doneButton: {
    ...ui(theme).btnType3,
    fontSize: 14,
    width: 140,
    height: 40,
  },
  moreButton: {
    ...ui(theme).btnType2,
    borderColor: theme.colors.primaryLight,
    color: theme.colors.primaryLight,
    fontSize: 13,
    width: 140,
    height: 40,
  },
  linkedHeaderControl: {
    display: 'block',
    textAlign: 'center',
    background: '#fff',
    borderRadius: 4,
    border: '1px solid #DEE2E6',
    color: '#8C9BA5',
    height: '100%',
    fontSize: 16,
    maxWidth: 400,
    margin: [0, 'auto'],
    width: '100%',
    padding: 20,
  },
  linkedTextButton: {
    extend: 'linkedHeaderControl',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'pre-wrap',
    color: '#8C9BA5',
    padding: [9, 12],
    borderRadius: 2,
    outline: 'none',
    border: ({ isPanelLocked, isForcePanelLocked }) => `
      ${isPanelLocked || isForcePanelLocked ? 0 : 1}px solid #DEE2E6`,
    cursor: ({ isPanelLocked, isForcePanelLocked }) => (isPanelLocked || isForcePanelLocked ? 'default' : 'pointer'),
    '&$notEmpty': {
      color: theme.colors.primaryLight,
    },
  },
  notEmpty: {},
  linkedTextArea: {
    extend: 'linkedHeaderControl',
    resize: 'none',
    color: theme.colors.primaryLight,
    outline: 'none',
    '&:focus': {
      border: `1px solid ${theme.colors.primaryLight}`,
    },
    '&::-webkit-input-placeholder, &:-moz-placeholder': {
      color: '#8C9BA5',
    },
  },
  // width of this container is determined by screen height,
  // and is itself determining children height by preserving
  // aspect ratio
  aspectRatioWrapper: {
    position: 'relative',
    width: '100%',
    height: '100%',
    padding: [0, 30, 20],
  },
  favorite: {},
  item: {
    width: params.width,
    height: params.height,
    position: 'relative',
    margin: [5, 'auto'],
    '&$disabled:after': {
      content: '""',
      display: 'block',
      position: 'absolute',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
    },
    '&$favorite:before': {
      content: '""',
      position: 'absolute',
      zIndex: 2,
      top: 4,
      left: 4,
      display: 'inline-block',
      height: 16,
      width: 16,
      backgroundColor: theme.colors.primaryOrange,
      WebkitMask: `url("${favoriteIcon}") 0 0 no-repeat`,
    },
  },
  lockedZone: {
    position: 'absolute',
    top: 0,
    left: 7,
    width: (props) => `${getWidth(props) - 7}px`,
    height: '100%',
    backgroundColor: theme.colors.greyDarkest,
    maskSize: 'cover',
    maskImage: `url("${stroke}")`,
    pointerEvents: 'none',
  },
  disabled: {},
  [`@media (max-height: ${mediaQuery.maxHeight})`]: {
    item: {
      width: `${xsParams.width}px!important`,
      height: `${xsParams.height}px!important`,
      '& > div': {
        width: '100%',
        height: '100%',
      },
    },
  },
  slidePanel: {
    position: 'absolute',
    left: 0,
    top: 0,
    zIndex: 10,
  },
  load: {
    minHeight: 20,
    position: 'relative',
  },
  spinner: {
    position: 'relative',
    backgroundColor: 'transparent',
    height: 20,
  },
});
