// @flow

import { useEffect, useState } from 'react';

const regExps = {
  regExpLowerCase: /(?=.*[a-z])/,
  regExpUpperCase: /^(?=.*[A-Z])/,
  regExpNumber: /^(?=.*[0-9])/,
  regExpSymbol: /^(?=.*[^A-Za-z0-9])/,
};

export const MIN_CHARACTERS = 8;

const usePasswordValidation = () => {
  const [passwordValues, setPasswordValues] = useState({
    password: '',
    passwordConfirmation: '',
  });

  const [validationRules, setValidationRules] = useState({
    minLength: false,
    lowerCase: false,
    upperCase: false,
    number: false,
    symbol: false,
    same: false,
  });

  const handleChange = (event: SyntheticInputEvent<HTMLInputElement>) => {
    const { value, name } = event.currentTarget;

    setPasswordValues({ ...passwordValues, [name]: value });
  };

  const isValid = Object.values(validationRules).every(Boolean);

  useEffect(() => {
    const { password, passwordConfirmation } = passwordValues;

    setValidationRules({
      minLength: password.split('').length > MIN_CHARACTERS - 1,
      lowerCase: regExps.regExpLowerCase.test(password),
      upperCase: regExps.regExpUpperCase.test(password),
      number: regExps.regExpNumber.test(password),
      symbol: regExps.regExpSymbol.test(password),
      same: password === passwordConfirmation,
    });
  }, [passwordValues]);

  return {
    passwordValues,
    validationRules,
    handleChange,
    isValid,
  };
};

export default usePasswordValidation;
