// @flow
import React, { useCallback, useState, useMemo } from 'react';

import find from 'lodash/find';
import { FormattedMessage, useIntl } from 'react-intl';
import type { GridPreset } from 'domain/documents/types.js.flow';
import { ERP_TITLES } from 'domain/companies/helpers';
import { WS_GRID_PRESET_TYPES } from 'domain/documents/constants';

import Box from '@mui/material/Box';
import { FormControlLabelSwitch } from 'components/mui/Form/FormControl/FormControlLabelSwitch';
import Dialog from 'components/mui/Dialog';
import TextFieldBase from 'components/mui/Form/TextField/TextFieldBase';
import Switch from 'components/mui/Form/Switch/SwitchBase';

export type TGrisPresetSaveParams = {|
  name: string,
  isNew: boolean,
  isAll: boolean,
|};

type TDialogCreateGridPreset = {
  preset: GridPreset,
  gridPresets: GridPreset[],
  onClose: () => void,
  onSave: (p: TGrisPresetSaveParams) => void,
  erpName: string,
  isMultiCompany: boolean,
  isNew: boolean,
};

const NAME_ERROR_TYPES = {
  company: 'company',
  all: 'all',
  empty: 'empty',
};

const nameErrors = {
  [NAME_ERROR_TYPES.company]: {
    id: 'document.bar.preset.modal.error.thisCompanyExists',
    defaultMessage: 'Configuration with this name already exists',
  },
  [NAME_ERROR_TYPES.all]: {
    id: 'document.bar.preset.modal.error.anotherCompanyExists',
    defaultMessage: 'Configuration with this name already exists in another Company',
  },
  [NAME_ERROR_TYPES.empty]: {
    id: 'document.bar.preset.modal.error.emptyName',
    defaultMessage: 'Configuration name cannot remain empty',
  },
};

const DialogCreateGridPreset: React$StatelessFunctionalComponent<TDialogCreateGridPreset> = ({
  preset,
  gridPresets,
  onClose,
  onSave,
  erpName,
  isMultiCompany,
  isNew,
}) => {
  const { formatMessage } = useIntl();
  const [name, setName] = useState(!isNew && preset.type === WS_GRID_PRESET_TYPES.custom ? preset.name : null);
  const [isAll, setAll] = useState(preset.isAll);

  const nameError = useMemo((): 'company' | 'all' | null => {
    if (name === '') return NAME_ERROR_TYPES.empty;
    const scopePresets = gridPresets.filter((p) => p.isAll === isAll);
    const checkPresets = isNew ? scopePresets : scopePresets.filter((p) => p.id !== preset.id);
    const sameNameCompany = find(checkPresets, (p) => p.name === name);
    const typeResolver = (c) => (c.isAll ? NAME_ERROR_TYPES.all : NAME_ERROR_TYPES.company);
    return sameNameCompany ? typeResolver(sameNameCompany) : null;
  }, [name, gridPresets, isNew, isAll, preset]);

  const onToggleAll = useCallback((e) => {
    setAll(e.currentTarget.checked);
  }, []);

  const onChangeName = useCallback((e) => {
    setName(e.currentTarget.value);
  }, []);

  const onClickSave = useCallback(() => {
    const id = isNew ? {} : { id: preset.id };
    onSave({ name, isAll, ...id });
  }, [name, isNew, isAll, preset, onSave]);

  const titleType = isNew ? 'add' : 'save';

  return (
    <Dialog
      open
      withCloseBtn
      disableRestoreFocus
      onClose={onClose}
      title={formatMessage({
        id: `document.bar.preset.modal.title.${titleType}`,
        defaultMessage: `${titleType} configuration`,
      })}
      maxWidth="sm"
      okText={formatMessage({
        id: 'button.save',
        defaultMessage: 'Save',
      })}
      onOk={onClickSave}
      okBtnProps={{ disabled: !name || Boolean(nameError) }}
    >
      <Box pt={2}>
        <TextFieldBase
          error={Boolean(nameError)}
          helperText={nameError ? <FormattedMessage {...nameErrors[nameError]} /> : null}
          onChange={onChangeName}
          value={name || ''}
          label={formatMessage({
            id: 'document.bar.preset.modal.placeholder',
            defaultMessage: 'Name',
          })}
          fullWidth
          autoFocus
        />
        <Box my={2}>
          <FormControlLabelSwitch
            checked={isAll}
            onChange={onToggleAll}
            control={<Switch />}
            disabled={!isMultiCompany}
            label={
              <FormattedMessage
                values={{ erpName: ERP_TITLES[erpName] || erpName }}
                id="document.bar.preset.modal.label.all"
                defaultMessage="Save for all {erpName} connected companies"
              />
            }
            sx={{ ml: -0.75 }}
          />
        </Box>
      </Box>
    </Dialog>
  );
};

export default DialogCreateGridPreset;
