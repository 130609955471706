// @flow
import React, { useState, useCallback, useRef, useEffect } from 'react';

// redux
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

// components
import Header from './components/Header';
import Bar from './components/Bar';
import Workspace from './components/Workspace';
import DayPicker from 'components/Form/DayPicker';
import Popup from 'components/Popup';
import Spinner from 'components/Spinner';
import ProgressBar from 'pages/supplierCompany/components/ProgressBar';

// selectors
import { localeSelector, loadingSelector } from 'domain/env/envSelector';
import { companiesByIdSelector } from 'domain/companies/companiesSelector';
import { uploadQueueSelector } from 'domain/documents/documentSelector';
import { querySelector } from 'domain/router/RouterSelector';

// actions
import { setNavigationAction, saveBackSearchUrlAction } from 'domain/env/envActions';
import { updateQueryAction } from 'domain/router/RouterActions';
import { queueRemoveAction } from 'domain/documents/documentsActions';

// styles
import useStyles from './sheet';

type Props = {};

const mapStateToProps = (state) => ({
  locale: localeSelector(state),
  loading: loadingSelector(state),
  queue: uploadQueueSelector(state),
  query: querySelector(state),
});

const SupplierCompanyPage: React$StatelessFunctionalComponent<Props> = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const params = useParams();

  const { locale, loading, queue, query } = useSelector(mapStateToProps);
  const company = useSelector(companiesByIdSelector).get(params.companyId);

  const [isOpenUpload, setIsOpenUpload] = useState(false);
  const [isOpenCalendar, setIsOpenCalendar] = useState(false);
  const [tagsComponentHeight, setTagsComponentHeight] = useState(0);

  const selectedTagsRef = useRef(null);

  // TODO: remove on supplier portal redesign
  useEffect(() => {
    if (selectedTagsRef.current) {
      const { height } = selectedTagsRef.current.getBoundingClientRect();
      // 20 - is the marginBottom value for the selectedTags component
      setTagsComponentHeight(height - 20);
    } else if (tagsComponentHeight) {
      setTagsComponentHeight(0);
    }
  }, [query, tagsComponentHeight]);

  const closeUploadPopup = () => {
    setIsOpenUpload(false);
    if (queue.size) {
      const filteredQueue = queue.filter((i) => i.status !== 'uploading');
      dispatch(queueRemoveAction(filteredQueue.keySeq().toArray()));
    }
  };
  const openUploadPopup = useCallback(() => setIsOpenUpload(true), []);
  const openCalendar = useCallback(() => setIsOpenCalendar(true), []);
  const closeCalendar = useCallback(() => setIsOpenCalendar(false), []);
  const onFilter = useCallback((payload) => dispatch(updateQueryAction(payload)), [dispatch]);

  const setNavigation = useCallback(
    (id: string, fromLinked: boolean = false) => {
      dispatch(
        setNavigationAction({
          search: { query, params },
          fromLinked,
          isSetRequest: true,
        }),
      );
      dispatch(saveBackSearchUrlAction({ query, params }));
    },
    [params, query, dispatch],
  );

  return (
    <main style={{ width: '100%' }}>
      <Header />
      <div className={classes.contentWrapper}>
        <Bar
          openUploadPopup={openUploadPopup}
          onCalendar={openCalendar}
          onFilter={onFilter}
          query={query}
          match={{ params }}
          locale={locale}
          dateFormat={company ? company.get('dateFormat') : null}
          selectedTagsRef={selectedTagsRef}
        />
        <ProgressBar />
        <Workspace
          match={{ params }}
          closeUploadPopup={closeUploadPopup}
          isOpenUpload={isOpenUpload}
          setNavigation={setNavigation}
          tagsComponentHeight={tagsComponentHeight}
        />
        {isOpenCalendar && (
          <Popup cancel={closeCalendar} close>
            <DayPicker
              query={query}
              cancel={closeCalendar}
              onFilter={onFilter}
              locale={locale}
              format={company.get('dateFormat')}
            />
          </Popup>
        )}
        <Spinner loading={loading} />
      </div>
    </main>
  );
};

export default SupplierCompanyPage;
