/* @flow */
import React, { useCallback, useMemo } from 'react';
import moment from 'moment';
import type { RecordOf } from 'immutable';
import type { EventsItemShapeType } from 'domain/events/types.js.flow';

import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import Avatar from 'components/mui/Avatar';
import {
  EventItemBase,
  EventItemButton,
  EventDetails,
} from 'components/mui/Layouts/components/EventsHistoryPanel/components/EventsList/ListItem/StyledComponents';

import elements from 'components/elements';

type TEventsHistoryListItem = {
  entry: RecordOf<EventsItemShapeType>,
  dateFormat: string,
  onClick: (entry: RecordOf<EventsItemShapeType>) => void,
  src: ?string,
};

const formatDate = (timestamp: string, dateFormat: string) =>
  moment.utc(timestamp).local().format(`${dateFormat}, hh:mm A`);

const EventsHistoryListItem: React$StatelessFunctionalComponent<TEventsHistoryListItem> = ({
  src,
  dateFormat,
  entry,
  onClick,
}) => {
  const dateTime = useMemo(() => formatDate(entry.timestamp, dateFormat), [entry, dateFormat]);

  const handleClick = useCallback(() => {
    if (entry.link) {
      onClick(entry);
    }
  }, [entry, onClick]);

  const ListItemNode = entry.link ? EventItemButton : EventItemBase;

  return (
    <ListItem divider data-element={elements.history.item}>
      <ListItemNode alignItems="flex-start" onClick={handleClick}>
        <ListItemAvatar>
          <Avatar src={src} />
        </ListItemAvatar>
        <ListItemText disableTypography>
          <Typography variant="body2" sx={{ fontWeight: 500 }} data-element={elements.history.itemTitle}>
            {entry.message}
          </Typography>
          <Typography variant="body2" color="text.secondary" data-element={elements.history.itemDate}>
            {dateTime}
          </Typography>
          {entry.details && (
            <EventDetails icon={false} severity="warning" data-element={elements.history.itemDetails}>
              {entry.details}
            </EventDetails>
          )}
        </ListItemText>
      </ListItemNode>
    </ListItem>
  );
};

export default EventsHistoryListItem;
