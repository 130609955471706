// @flow
import React, { useMemo } from 'react';
import { useParams, generatePath } from 'react-router-dom';
import useQuery from 'hooks/useQuery';

// redux
import { useSelector } from 'react-redux';

import { CompanyFactory, companiesByIdSelector } from 'domain/companies';
import ROUTES_PATH from 'domain/router/routesPathConfig';

// components
import Header from 'pages/common/header__legacy';
import Left from 'pages/common/left__legacy/index';

const SupplierCompanyHeader = () => {
  const companies = useSelector(companiesByIdSelector);
  const params = useParams();
  const query = useQuery();

  const company = useMemo(
    () => companies.get(params.companyId, CompanyFactory(), [params.companyId]),
    [params.companyId, companies],
  );
  const toBack = useMemo(
    () => (companies.count() === 1 ? generatePath(ROUTES_PATH.AUTH_LOGOUT.absolute) : '/'),
    [companies],
  );
  return (
    <Header searchBar={false} match={{ params }} query={query} left={<Left to={toBack} company={company} home />} />
  );
};
export default SupplierCompanyHeader;
