// @flow
import React from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import useToggle from 'hooks/useToggle';
import type { ProcessedStatusTag } from 'domain/documents/helpers';
import { approvalsRejectReasonDetailsSelector, approvalsRejectReasonTextSelector } from 'domain/approvals/selectors';

import Stack from '@mui/material/Stack';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

type TDocumentRejectAlert = {
  reason: string,
  status: Array<ProcessedStatusTag>,
};

const mapStateToProps = (state) => ({
  approvalsRejectReason: approvalsRejectReasonTextSelector(state),
  approvalsRejectReasonDetails: approvalsRejectReasonDetailsSelector(state),
});

const DocumentRejectAlert: React$StatelessFunctionalComponent<TDocumentRejectAlert> = ({
  reason = '',
  status = [],
}) => {
  const { approvalsRejectReason, approvalsRejectReasonDetails } = useSelector(mapStateToProps);
  const [isVisibleDocumentReason, toggleIsVisibleDocumentReason] = useToggle();
  const [isVisibleApprovalsReason, toggleIsVisibleApprovalsReason] = useToggle();
  const isRejectedDocument = status.includes('rejected');
  const isVisibleComponent = isRejectedDocument || approvalsRejectReason;

  return (
    isVisibleComponent && (
      <Stack spacing={1} p={1}>
        {isRejectedDocument && (
          <Alert
            variant="filled"
            severity="error"
            action={
              <Button variant="text" color="inherit" size="small" onClick={toggleIsVisibleDocumentReason}>
                {isVisibleDocumentReason ? (
                  <FormattedMessage id="document.rejectAlert.hide" defaultMessage="Hide details" />
                ) : (
                  <FormattedMessage id="document.rejectAlert.details" defaultMessage="Show details" />
                )}
              </Button>
            }
          >
            <Typography variant="body2" fontWeight="500">
              <FormattedMessage id="document.rejectAlert.documentWasRejected" defaultMessage="Document was Rejected" />
            </Typography>
            {isVisibleDocumentReason && (
              <Typography variant="body2" mt={1}>
                {reason}
              </Typography>
            )}
          </Alert>
        )}
        {approvalsRejectReason && (
          <Alert
            severity="warning"
            action={
              <Button variant="text" color="inherit" size="small" onClick={toggleIsVisibleApprovalsReason}>
                {isVisibleApprovalsReason ? (
                  <FormattedMessage id="document.rejectAlert.hide" defaultMessage="Hide details" />
                ) : (
                  <FormattedMessage id="document.rejectAlert.details" defaultMessage="Show details" />
                )}
              </Button>
            }
          >
            <Typography variant="body2" fontWeight="500">
              <FormattedMessage id="document.rejectAlert.approvalWasRejected" defaultMessage="Approval was Rejected" />
            </Typography>

            {isVisibleApprovalsReason && (
              <Typography variant="body2" mt={1}>
                {[approvalsRejectReason, approvalsRejectReasonDetails].join(approvalsRejectReasonDetails ? ': ' : '')}
              </Typography>
            )}
          </Alert>
        )}
      </Stack>
    )
  );
};

export default React.memo(DocumentRejectAlert);
