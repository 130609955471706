import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { currentCompanySelector, documentDocumentIdSelector } from 'domain/documents';
import { userIdSelector } from 'domain/env';
import { companyEnableClientChatFeatureSelector, companyMessagesCountSelector } from 'domain/companies';
import {
  setOpenAction,
  setActiveChannelAction,
  chatReadyToRenderOnDocumentSelector,
  ghostIdentities,
  isOpenSelector,
  documentUnreadMessagesCountSelector,
} from 'domain/chat';
import * as ACL from 'domain/restriction';

import TooltipForButton from 'components/mui/Tooltip/TooltipForButton';
import { ChatDrawer, TotalUnreadBadge } from 'components/mui/Layouts/components/Chat/components/StyledComponents';
import ChatMessagesDrawer from 'components/mui/Layouts/components/Chat/components/ChatMessagesDrawer';
import ChatDrawerContent from 'components/mui/Layouts/components/Chat/components/ChatDrawerContent';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import AppBar from '@mui/material/AppBar';

import ChatBubbleTwoToneIcon from '@mui/icons-material/ChatBubbleTwoTone';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

const mapStateToProps = (state) => ({
  userID: userIdSelector(state),
  currentCompanyID: currentCompanySelector(state),
  documentID: documentDocumentIdSelector(state),
  isGranted: ACL.isGranted(state),
  chatReadyToRenderOnDocument: chatReadyToRenderOnDocumentSelector(state),
  isEnableChatClient: companyEnableClientChatFeatureSelector(state),
  isChatReadyToRenderOnDocument: chatReadyToRenderOnDocumentSelector(state),
  isChatOpen: isOpenSelector(state),
  unreadDocument: documentUnreadMessagesCountSelector(state),
  unreadCompany: companyMessagesCountSelector(state),
});

const ChatIconButton = () => {
  const dispatch = useDispatch();
  const {
    currentCompanyID,
    userID,
    unreadCompany,
    unreadDocument,
    isGranted,
    isEnableChatClient,
    isChatReadyToRenderOnDocument,
    isChatOpen,
    variant = 'temporary',
    documentID,
  } = useSelector(mapStateToProps);
  const isSupplier = isGranted(ACL.IS_SUPPLIER_USER);
  const { documentId, companyId } = useParams();
  const { formatMessage } = useIntl();
  const [unreadCount, setUnreadCount] = useState(0);

  const count = documentId ? unreadDocument : unreadCompany;
  const isDisabled = !(!documentId || isChatReadyToRenderOnDocument); // TODO: check fo company and document
  const isAvailable = currentCompanyID && !isSupplier && !ghostIdentities.includes(userID) && isEnableChatClient;

  const handleToggleDrawer = () => {
    if (!isDisabled) {
      dispatch(setOpenAction(!isChatOpen));

      if (isChatOpen) {
        dispatch(setActiveChannelAction({ threadId: null, companyId }));
      }
    }
  };

  useEffect(() => {
    setUnreadCount(count);
  }, [count]);

  return (
    isAvailable ? (
      <div>
        <TooltipForButton t={{ id: 'tooltip.chat', defaultMessage: 'Chat' }}>
          <IconButton color="primary" onClick={handleToggleDrawer} disabled={isDisabled}>
            <TotalUnreadBadge color="warning" badgeContent={unreadCount}>
              <ChatBubbleTwoToneIcon />
            </TotalUnreadBadge>
          </IconButton>
        </TooltipForButton>

        <ChatDrawer anchor="right" variant={variant} open={isChatOpen} onClose={handleToggleDrawer}>
          <AppBar position="static">
            <Toolbar>
              <Typography variant="h6">{formatMessage({ id: 'chat.title', defaultMessage: 'Chat' })}</Typography>
              <IconButton edge="end" onClick={handleToggleDrawer} color="inherit" sx={{ ml: 'auto' }}>
                <CloseOutlinedIcon />
              </IconButton>
            </Toolbar>
          </AppBar>
          {/* isChatOpen condition - dont call render next components if we dont open chat */}
          {/* didnt add this condition higher, because I want to save animation for drawer */}
          {isChatOpen && <ChatDrawerContent documentId={documentID} />}
        </ChatDrawer>

        {isChatOpen && <ChatMessagesDrawer handleCloseChat={handleToggleDrawer} />}
      </div>
    ) : null
  );
};

export default ChatIconButton;
