// @flow
import React from 'react';
import { FormattedMessage } from 'react-intl';

// TODO: check types, its temporary
import DialogActionsMui, { type DialogActionsProps } from '@mui/material/DialogActions';
import ButtonMui, { type ButtonProps } from '@mui/material/Button';

type TDialogActions = {
  cancelText?: React$Node,
  okText?: React$Node,
  onCancel: () => void,
  onOk: () => void,
  okBtnProps?: ButtonProps,
  forFormID?: string,
} & DialogActionsProps;

const Dialog: React$StatelessFunctionalComponent<TDialogActions> = ({
  okText,
  cancelText,
  onCancel,
  onOk,
  okBtnProps,
  forFormID,
  ...rest
}) => (
  <DialogActionsMui {...rest}>
    <ButtonMui variant="text" onClick={onCancel}>
      {cancelText || <FormattedMessage id="button.cancel" defaultMessage="Cancel" />}
    </ButtonMui>
    <ButtonMui onClick={onOk} type="button" form={forFormID} {...okBtnProps}>
      {okText || <FormattedMessage id="button.ok" defaultMessage="Ok" autoFocus />}
    </ButtonMui>
  </DialogActionsMui>
);

export default Dialog;
