/* @flow */
import * as React from 'react';

// components
import DragButton from './components/DrugButton';

// immutable
import { List, Record } from 'immutable';

// types
import type { RecordOf } from 'immutable';

// styles
import { withStyles } from '@mui/styles';
import cx from 'classnames';
import sheet from './sheet';

import type { ExtraPolygon } from '../../../polygons/helpers';

type Props = {|
  width: number,
  height: number,
  handleGrid: () => void,
  mouseYPosition: number,
  getStyledPolygons: () => Array<ExtraPolygon>,
  hiddenGrid: boolean,
  classes: {
    [key: string]: string,
  },
|};

type State = {|
  addRowPosition: { top: number, left: number },
  addColumnPosition: { top: number, left: number },
  removeRowPosition: { top: number, left: number },
  removeColumnPosition: { top: number, left: number },
  moveGridPosition: { top: number, left: number },
  closeGridPosition: { top: number, left: number },
  showButtons: boolean,
|};

type CellType = {|
  width: number,
  height: number,
  xStart: number,
  xEnd: number,
  yStart: number,
  yEnd: number,
|};

type StructureType = List<List<RecordOf<CellType>>>;

const cellFactory = Record({
  width: 0,
  height: 0,
  xStart: 0,
  xEnd: 0,
  yStart: 0,
  yEnd: 0,
});

const buttonSpace: number = 30;
const initialCellHeight: number = 40;
const criticalLength: number = 15;
const portSize = 4;
const pagePadding = 30;

// helpers
const createInitialRow = (width: number, gridYStart: number, rowIndex: number) => {
  const row = Array(4)
    .fill(cellFactory({ width, height: initialCellHeight }))
    .map((cell, index) =>
      cell
        .set('xStart', cell.width * index + pagePadding)
        .set('xEnd', cell.width * (index + 1) + pagePadding)
        .set('yStart', gridYStart + initialCellHeight * rowIndex)
        .set('yEnd', gridYStart + initialCellHeight * (rowIndex + 1)),
    );

  return List(row);
};

class Grid extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.gridYStart = this.calculateYStart(props);
    this.structure = List([
      createInitialRow((props.width - pagePadding * 2) / 4, this.gridYStart, 0),
      createInitialRow((props.width - pagePadding * 2) / 4, this.gridYStart, 1),
    ]);
  }

  state = {
    addRowPosition: { top: 0, left: 0 },
    addColumnPosition: { top: 0, left: 0 },
    removeRowPosition: { top: 0, left: 0 },
    removeColumnPosition: { top: 0, left: 0 },
    moveGridPosition: { top: 0, left: 0 },
    closeGridPosition: { top: 0, left: 0 },
    showButtons: true,
  };

  componentDidMount(): void {
    this.initiateGrid();
    this.addEffects();
  }

  componentDidUpdate(prevProps): void {
    const { width: prevWidth, height: prevHeight } = prevProps;
    const { width, height } = this.props;

    if (width !== prevWidth || height !== prevHeight) {
      this.updateCellParamsAndDraw(prevWidth, width, prevHeight, height);
    }
  }

  componentWillUnmount(): void {
    this.removeEffects();
  }

  onMouseMove = ({ offsetX, offsetY }) => {
    if (!this.isGridZone(offsetY, offsetX)) {
      this.grid.style.cursor = 'auto';
      return;
    }
    this.handleCursors(offsetX, offsetY);
    this.handleResizeColumns(offsetX);
    this.handleResizeRows(offsetY);
    this.handleDragGrid(offsetX, offsetY);
  };

  onMouseDown = (event) => {
    const { offsetX, offsetY } = event;

    if (this.isGridZone(offsetY, offsetX)) {
      event.stopPropagation();
      this.gridPortSize = 500;
      this.setState({ showButtons: false });
    }

    this.changedStructure = this.structure;
    // if click was on horizontal line, dontt check if it was also
    // on versical line as we will then handle resizes in both directions
    // simultanesly. e.g. click or lines intersection
    if (this.inYRange(offsetY)) {
      this.startResizeYPoint = offsetY;
      return;
    }
    if (this.inXRange(offsetX)) {
      this.startResizeXPoint = offsetX;
    }

    if (!this.inXRange(offsetX) && !this.inYRange(offsetY)) {
      this.startDragXPoint = offsetX;
      this.startDragYPoint = offsetY;
    }
  };

  onMouseUp = () => {
    this.setState({ showButtons: true });
    this.startResizeXPoint = null;
    this.startResizeYPoint = null;
    this.startDragXPoint = null;
    this.startDragYPoint = null;
    this.gridPortSize = 1;

    if (this.changedStructure.size > 0) {
      const invalid = this.changedStructure
        .flatten()
        .some((cell) => cell.width < criticalLength - 2 || cell.height < criticalLength - 2);
      if (!invalid) {
        this.structure = this.changedStructure;
      }
      this.changedStructure = List();
      this.correctNewStructure();
      this.moveButtonsAndDraw();
    }
  };

  // eslint-disable-next-line max-len
  get cornerCells(): {
    leftTopCell: CellType,
    leftBottomCell: CellType,
    rightTopCell: CellType,
    rightBottomCell: CellType,
  } {
    const leftTopCell = this.structure.first().first();
    const leftBottomCell = this.structure.last().first();
    const rightTopCell = this.structure.first().last();
    const rightBottomCell = this.structure.last().last();

    return { leftTopCell, leftBottomCell, rightTopCell, rightBottomCell };
  }

  // eslint-disable-next-line max-len
  get scaleRights(): {
    preventAddRows: boolean,
    preventAddColumns: boolean,
    preventRemoveRow: boolean,
    preventRemoveColumn: boolean,
  } {
    const { height, width } = this.props;
    const { rightBottomCell, rightTopCell } = this.cornerCells;

    const preventRemoveRow = this.structure.size <= 1;
    const preventRemoveColumn = this.structure.first().size <= 1;
    const preventScaleRight = Math.round(rightTopCell.xEnd) >= Math.round(width);
    const preventScaleDown = Math.round(rightBottomCell.yEnd + buttonSpace) >= Math.round(height);

    const preventAddRows = preventScaleDown && rightBottomCell.height <= criticalLength;
    const preventAddColumns = preventScaleRight && rightBottomCell.width <= criticalLength;

    return {
      preventRemoveRow,
      preventRemoveColumn,
      preventAddRows,
      preventAddColumns,
    };
  }

  setGridRef = (gridRef) => {
    this.grid = gridRef;
  };

  setGridWrapperRef = (wrapperRef) => {
    this.gridWrapperRef = wrapperRef;
  };

  getColumnsPortRanges() {
    const { rightBottomCell } = this.cornerCells;

    const columnsLinesRanges = this.structure
      .first()
      .map((cell) => List([cell.xStart - portSize, cell.xStart + portSize]));

    return columnsLinesRanges.push(
      List([
        rightBottomCell.xStart + rightBottomCell.width - portSize,
        rightBottomCell.xStart + rightBottomCell.width + portSize,
      ]),
    );
  }

  getRowsPortRanges() {
    const { rightBottomCell } = this.cornerCells;

    const columnsLinesRanges = this.structure.map((row) =>
      List([row.first().yStart - portSize, row.first().yStart + portSize]),
    );

    return columnsLinesRanges.push(List([rightBottomCell.yEnd - portSize, rightBottomCell.yEnd + portSize]));
  }

  calculateYStart = (props: Props): number => {
    const { mouseYPosition, height } = props;
    const freeBottomSpace = 160;
    const freeTopSpace = 30;

    if (height - mouseYPosition < 100) return mouseYPosition - freeBottomSpace;
    if (mouseYPosition < 100) return mouseYPosition + freeTopSpace;
    return mouseYPosition - initialCellHeight;
  };

  resetGridCellWidth = (): void => {
    const { width } = this.props;
    const {
      leftTopCell: { xStart },
    } = this.cornerCells;
    const cellWidth = (width - xStart) / this.structure.first().size;

    this.structure = this.structure.map((row) =>
      row.map((cell, index) =>
        cell
          .set('xStart', xStart + cellWidth * index)
          .set('width', cellWidth)
          .set('xEnd', xStart + cellWidth * index + cellWidth),
      ),
    );
  };

  resetGridCellHeight = (): void => {
    const { height } = this.props;
    const { yStart } = this.structure.first().first();

    const cellHeight = (height - buttonSpace - yStart) / this.structure.size;
    this.structure = this.structure.map((row, rowIndex) =>
      row.map((cell) =>
        cell
          .set('yStart', yStart + cellHeight * rowIndex)
          .set('height', cellHeight)
          .set('yEnd', yStart + cellHeight * (rowIndex + 1)),
      ),
    );
  };

  initiateGrid = (): void => {
    this.ctx = this.grid.getContext('2d');
    this.moveButtonsAndDraw();
  };

  isGridZone = (offsetY, offsetX): boolean => {
    const { leftTopCell, rightBottomCell } = this.cornerCells;

    const yZone =
      offsetY >= leftTopCell.yStart - this.gridPortSize && offsetY <= rightBottomCell.yEnd + this.gridPortSize;
    const xZone =
      offsetX >= leftTopCell.xStart - this.gridPortSize && offsetX <= rightBottomCell.xEnd + this.gridPortSize;

    return yZone && xZone;
  };

  correctNewStructure = (): void => {
    const { width, height } = this.props;

    const { rightBottomCell, leftBottomCell, leftTopCell } = this.cornerCells;
    const rightXDiff = rightBottomCell.xEnd - width;
    const leftXDiff = 0 - leftBottomCell.xStart;
    const bottomYDiff = leftBottomCell.yEnd - height + buttonSpace;
    const topYDiff = 0 - leftTopCell.yStart + buttonSpace;

    if (rightXDiff > 0) {
      this.structure = this.structure.map((row) =>
        row.map((cell) => cell.set('xStart', cell.xStart - rightXDiff).set('xEnd', cell.xEnd - rightXDiff)),
      );
    }

    if (leftXDiff > 0) {
      this.structure = this.structure.map((row) =>
        row.map((cell) => cell.set('xStart', cell.xStart + leftXDiff).set('xEnd', cell.xEnd + leftXDiff)),
      );
    }

    if (bottomYDiff > 0) {
      this.structure = this.structure.map((row) =>
        row.map((cell) => cell.set('yStart', cell.yStart - bottomYDiff).set('yEnd', cell.yEnd - bottomYDiff)),
      );
    }

    if (topYDiff > 0) {
      this.structure = this.structure.map((row) =>
        row.map((cell) => cell.set('yStart', cell.yStart + topYDiff).set('yEnd', cell.yEnd + topYDiff)),
      );
    }
  };

  updateCellParamsAndDraw = (prevWidth: number, width: number, prevHeight: number, height: number): void => {
    const wPercent = width / prevWidth;
    const hPercent = height / prevHeight;

    this.structure = this.structure.map((row) =>
      row.map((cell) =>
        cell
          .set('xStart', cell.xStart * wPercent)
          .set('width', cell.width * wPercent)
          .set('xEnd', cell.xEnd * wPercent)
          .set('yStart', cell.yStart * hPercent)
          .set('height', cell.height * hPercent)
          .set('yEnd', cell.yEnd * hPercent),
      ),
    );

    this.moveButtonsAndDraw();
  };

  inXRange = (offsetX: number): boolean =>
    this.getColumnsPortRanges().some((range) => offsetX >= range.first() && offsetX <= range.last());

  inYRange = (offsetY: number): boolean =>
    this.getRowsPortRanges().some((range) => offsetY >= range.first() && offsetY <= range.last());

  handleCursors = (offsetX: number, offsetY: number): void => {
    if (this.inXRange(offsetX)) this.grid.style.cursor = 'col-resize';
    if (this.inYRange(offsetY)) this.grid.style.cursor = 'row-resize';
    if (!this.inXRange(offsetX) && !this.inYRange(offsetY)) this.grid.style.cursor = 'grab';
  };

  handleResizeColumns = (offsetX: number): void => {
    if (this.startResizeXPoint === null) return;

    const diff = Number(this.startResizeXPoint) - offsetX;
    const gridRow = this.changedStructure.first();

    const criticalCase = gridRow
      .filter((cell) => offsetX >= cell.xStart && offsetX <= cell.xEnd)
      .some((cell) => {
        const firstCondition = diff > 0 && offsetX > cell.xStart && cell.width <= criticalLength;
        const secondCondition = diff < 0 && offsetX < cell.xEnd && cell.width <= criticalLength;

        return firstCondition || secondCondition;
      });

    if (!criticalCase) {
      this.changedStructure = this.structure.map((row) =>
        row.map((cell) => {
          // eslint-disable-next-line max-len
          if (
            Number(this.startResizeXPoint) >= cell.xStart - portSize &&
            Number(this.startResizeXPoint) <= cell.xStart + portSize
          ) {
            return cell.set('xStart', cell.xStart - diff).set('width', cell.width + diff);
          }
          // eslint-disable-next-line max-len
          if (
            Number(this.startResizeXPoint) >= cell.xEnd - portSize &&
            Number(this.startResizeXPoint) <= cell.xEnd + portSize
          ) {
            return cell.set('width', cell.width - diff).set('xEnd', cell.xEnd - diff);
          }
          return cell;
        }),
      );

      this.drawGrid(this.changedStructure);
    } else {
      this.onMouseUp();
    }
  };

  handleResizeRows = (offsetY: number): void => {
    const { height } = this.props;
    if (this.startResizeYPoint === null) return;

    const diff = Number(this.startResizeYPoint) - offsetY;
    if (diff === 0) return;

    const upDirection = diff > 0;
    const downDirection = diff < 0;

    const preventScaleDown = Math.round(this.changedStructure.last().last().yEnd + buttonSpace) >= Math.round(height);
    const preventScaleUp = Math.round(this.changedStructure.first().first().yStart - buttonSpace) <= 0;

    const criticalCellHeight = this.changedStructure
      .filter((row) => offsetY >= row.first().yStart && offsetY <= row.first().yEnd)
      .some((row) => {
        const firstCondition = upDirection && offsetY > row.first().yStart && row.first().height <= criticalLength;
        const secondCondition = downDirection && offsetY < row.first().yEnd && row.first().height <= criticalLength;

        return firstCondition || secondCondition;
      });

    const criticalCase = criticalCellHeight || (preventScaleUp && upDirection) || (preventScaleDown && downDirection);

    if (!criticalCase) {
      this.changedStructure = this.structure.map((row) =>
        row.map((cell) => {
          // eslint-disable-next-line max-len
          if (Number(this.startResizeYPoint) >= cell.yStart - 3 && Number(this.startResizeYPoint) <= cell.yStart + 3) {
            return cell.set('yStart', cell.yStart - diff).set('height', cell.height + diff);
          }
          // eslint-disable-next-line max-len
          if (Number(this.startResizeYPoint) >= cell.yEnd - 3 && Number(this.startResizeYPoint) <= cell.yEnd + 3) {
            return cell.set('height', cell.height - diff).set('yEnd', cell.yEnd - diff);
          }
          // eslint-disable-next-line max-len
          if (Number(this.startResizeYPoint) >= cell.yEnd - 3 && Number(this.startResizeYPoint) <= cell.yEnd + 3) {
            return cell.set('height', cell.height - diff).set('yEnd', cell.yEnd - diff);
          }
          return cell;
        }),
      );

      this.drawGrid(this.changedStructure);
    } else {
      this.onMouseUp();
    }
  };

  handleDragGrid = (offsetX: number, offsetY: number): void => {
    if (this.startDragXPoint === null && this.startDragYPoint === null) return;

    const xDiff = Number(this.startDragXPoint) - offsetX;
    const yDiff = Number(this.startDragYPoint) - offsetY;

    this.changedStructure = this.structure.map((row) =>
      row.map((cell) =>
        cell
          .set('xStart', cell.xStart - xDiff)
          .set('xEnd', cell.xEnd - xDiff)
          .set('yStart', cell.yStart - yDiff)
          .set('yEnd', cell.yEnd - yDiff),
      ),
    );

    this.drawGrid(this.changedStructure);
  };

  addEffects = (): void => {
    this.grid.addEventListener('mousemove', this.onMouseMove);
    this.grid.addEventListener('mousedown', this.onMouseDown);
    this.grid.addEventListener('mouseup', this.onMouseUp);
    this.gridWrapperRef.addEventListener('mouseleave', this.onMouseUp);
  };

  removeEffects = (): void => {
    this.grid.removeEventListener('mousemove', this.onMouseMove);
    this.grid.removeEventListener('mousedown', this.onMouseDown);
    this.grid.removeEventListener('mouseup', this.onMouseUp);
    this.gridWrapperRef.removeEventListener('mouseleave', this.onMouseUp);
  };

  // drawing

  drawGrid = (newStructure: ?StructureType): void => {
    const structure = newStructure || this.structure;

    window.requestAnimationFrame(() => {
      this.ctx.clearRect(0, 0, this.grid.width, this.grid.height);

      structure.forEach((row) =>
        row.forEach(({ xStart, yStart, width, height }) => this.drawCell(xStart, yStart, width, height)),
      );
    });
  };

  drawCell = (xStart: number, yStart: number, width: number, height: number): void => {
    this.ctx.fillStyle = 'rgba(255, 250, 250, 0.51)';
    this.ctx.fillRect(xStart, yStart, width, height);
    this.ctx.beginPath();
    this.ctx.lineWidth = 1;
    this.ctx.strokeStyle = '#BC5353';
    this.ctx.rect(xStart, yStart, width, height);
    this.ctx.stroke();
  };

  addRow = (): void => {
    const { height } = this.props;
    const oldRow = this.structure.last();
    const newRow = oldRow.map((cell) => cell.set('yStart', cell.yEnd).set('yEnd', cell.yEnd + cell.height));

    this.structure = this.structure.push(newRow);

    const { rightBottomCell } = this.cornerCells;
    const outOfPort = rightBottomCell.yEnd + buttonSpace >= height;

    if (outOfPort) this.resetGridCellHeight();
    this.moveButtonsAndDraw();
  };

  addColumn = (): void => {
    const { width } = this.props;
    this.structure = this.structure.map((row) => {
      const oldCell = row.last();
      const newCell = oldCell.set('xStart', oldCell.xEnd).set('xEnd', oldCell.xEnd + oldCell.width);
      return row.push(newCell);
    });

    const { rightBottomCell } = this.cornerCells;
    const outOfPort = rightBottomCell.xEnd >= width;

    if (outOfPort) this.resetGridCellWidth();

    this.moveButtonsAndDraw();
  };

  removeRow = (): void => {
    this.structure = this.structure.pop();
    this.moveButtonsAndDraw();
  };

  removeColumn = (): void => {
    this.structure = this.structure.map((row) => row.pop());
    this.moveButtonsAndDraw();
  };

  moveButtonsAndDraw = (): void => {
    const { leftBottomCell, rightTopCell, leftTopCell, rightBottomCell } = this.cornerCells;
    this.setState({
      addRowPosition: { top: leftBottomCell.yEnd + 5, left: leftBottomCell.xStart + 5 },
      removeRowPosition: { top: leftBottomCell.yEnd - 23, left: leftBottomCell.xStart - 23 },
      addColumnPosition: { top: rightTopCell.yStart + 5, left: rightTopCell.xEnd + 5 },
      removeColumnPosition: { top: rightTopCell.yStart - 23, left: rightTopCell.xEnd - 23 },
      moveGridPosition: { top: leftTopCell.yStart - 30, left: leftTopCell.xStart - 20 },
      closeGridPosition: { top: rightBottomCell.yEnd + 5, left: rightBottomCell.xEnd + 5 },
    });

    this.drawGrid();
  };

  grid: ?HTMLElement;
  gridWrapperRef: ?HTMLElement;
  structure: StructureType;
  changedStructure: StructureType = List();
  ctx: CanvasRenderingContext2D;
  startResizeXPoint: ?number = null;
  startResizeYPoint: ?number = null;
  gridPortSize: number = 1;
  startDragXPoint: ?number = null;
  startDragYPoint: ?number = null;
  gridWidth: number = window.innerWidth;
  gridHeight: number = window.innerHeight * 4;

  render() {
    const { classes, handleGrid, hiddenGrid, getStyledPolygons } = this.props;
    const {
      addRowPosition,
      addColumnPosition,
      removeRowPosition,
      removeColumnPosition,
      showButtons,
      moveGridPosition,
      closeGridPosition,
    } = this.state;

    const { preventRemoveRow, preventRemoveColumn, preventAddColumns, preventAddRows } = this.scaleRights;

    return (
      <div
        className={cx(classes.grid, {
          [classes.overflowHidden]: !showButtons,
          [classes.hiddenGrid]: hiddenGrid,
        })}
        ref={this.setGridWrapperRef}
      >
        {showButtons && (
          <>
            <DragButton
              moveGridPosition={moveGridPosition}
              structure={this.structure}
              getStyledPolygons={getStyledPolygons}
            />
            <button
              className={cx(classes.button, classes.addButton)}
              style={addRowPosition}
              onClick={this.addRow}
              disabled={preventAddRows}
              type="button"
            />
            <button
              className={cx(classes.button, classes.removeButton)}
              style={removeRowPosition}
              onClick={this.removeRow}
              disabled={preventRemoveRow}
              type="button"
            />
            <button
              className={cx(classes.button, classes.addButton)}
              style={addColumnPosition}
              onClick={this.addColumn}
              disabled={preventAddColumns}
              type="button"
            />
            <button
              className={cx(classes.button, classes.removeButton)}
              style={removeColumnPosition}
              onClick={this.removeColumn}
              disabled={preventRemoveColumn}
              type="button"
            />
            <button
              className={cx(classes.button, classes.closeButton)}
              style={closeGridPosition}
              onClick={handleGrid}
              type="button"
            />
          </>
        )}

        <canvas width={this.gridWidth} height={this.gridHeight} ref={this.setGridRef} className={classes.grid} />
      </div>
    );
  }
}

export default withStyles(sheet)(Grid);
