/* @flow */
import type { Middleware, MiddlewareAPI, Dispatch } from 'redux';
import { nanoid } from 'nanoid';
import type { GroupChannel, UserMessage } from 'sendbird';
import {
  sbCreatInstance,
  sbConnect,
  sbDisconnect,
  sbGetInstance,
  CONNECTED,
  FAILED,
  RECONNECTING,
} from 'sendbird-utils';
import type { DokkaStore as Store } from 'domain/types.js.flow';
import { messageReceiveFactory } from 'sendbird-utils/lib/imAdapters';
import { PushNotificationPayloadAdapter } from './helpers';
import {
  chatTokenSelector,
  userIdSelector,
  newChatFlowSelector,
  signOutAction,
  signInAction,
  saveOnboardingInfoAction,
  isDokkaSupportSelector,
} from '../env';

import { messagesLoadedSelector, isOpenSelector, chatThreadSelector, isOneSignalInitSelector } from './chatSelector';

import CONSTANTS from '../constants';
import * as A from './chatActions';

const WS_CLOSE_STATUS = 'CLOSED';

function isWsConnectionClose() {
  return sbGetInstance().getConnectionState() === WS_CLOSE_STATUS;
}

function sbConnectAction(dispatch, type: 'success' | 'failure' = 'success', userId?: string) {
  dispatch({
    type: A.chatConnectAction[type],
    userId,
  });
}

function initPushNotificationObserver(handler) {
  // necessary set one signal observer after one signal init
  window.OneSignal = window.OneSignal || [];
  const { OneSignal } = window;

  OneSignal.push(() => {
    OneSignal.setSubscription(true);
  });

  OneSignal.push(() => {
    OneSignal.on('notificationDisplay', (e) => {
      handler.onNotificationDisplay(e);
    });
  });

  OneSignal.push(() => {
    OneSignal.on('notificationPermissionChange', (permissionChange) => {
      const currentPermission = permissionChange.to;
      if (currentPermission === 'granted') OneSignal.setSubscription(true);
    });
  });

  document.addEventListener('visibilitychange', () => {
    const isHidden = document.visibilityState === 'hidden';
    if (isHidden) {
      handler.onBrowserHide();
    } else {
      handler.onBrowserActive();
    }
  });
}

class HandlerClass {
  constructor({ dispatch, getState }) {
    this.handlerId = nanoid(10);
    this.isBrowserActive = true;
    this._dispatch = dispatch;
    this._getState = getState;
    this.tryConnect();
  }

  _dispatch: Dispatch<*>;

  _getState: () => S;

  handlerId: string;

  isBrowserActive: boolean;

  _messageReceive = messageReceiveFactory(CONSTANTS.BUILD_ENV);

  onChatStatusOpenChange() {
    const isOpen = isOpenSelector(this._getState());
    if (isOpen) {
      this.onOpenChat();
    } else {
      this.onCloseChat();
    }
  }

  // ---- EVENTS START ----
  // One signal loaded and init
  onOneSignalInit() {
    this.resolveConnection();
  }

  // SenBird threads is loaded
  onThreadsLoaded() {
    // middleware is executed before reducer,
    // so messaged load flag check will fail as it
    // is not yet set
    setTimeout(this.resolveConnection.bind(this), 200);
  }

  // Get org features
  onGetUserProfile() {
    this.resolveConnection();
  }

  // Chat is open now
  onOpenChat() {
    this.resolveConnection();
  }

  // Chat is close now
  onCloseChat() {
    this.resolveConnection();
  }

  // Browser is inactive now
  onBrowserHide() {
    this.isBrowserActive = false;
    this.resolveConnection();
  }

  // Browser is active now
  onBrowserActive() {
    this.isBrowserActive = true;
    this.resolveConnection();
  }

  // Receive new push notification
  onNotificationDisplay(event) {
    window.OneSignal = window.OneSignal || [];
    const { OneSignal } = window;
    // eslint-disable-next-line no-console
    console.log('this.receiveMessage', event);
    if (this.isAllowPushConnection()) {
      this.receiveMessage(PushNotificationPayloadAdapter(event));
    } else {
      // if push notes is not allow but keep receiving notes then retry disable subscription
      OneSignal.push(() => {
        OneSignal.setSubscription(false);
      });
    }
  }

  disconnectServer() {
    if (!isWsConnectionClose()) {
      sbDisconnect();
      this._dispatch({
        type: A.setAllowedChatWorkerAction.type,
        payload: true,
      });
    }
  }

  // ---- EVENTS END ----
  resolveConnection() {
    window.OneSignal = window.OneSignal || [];
    const { OneSignal } = window;
    const isAllowSocketConnection = this.isAllowSocketConnection();
    const isAllowPushConnection = this.isAllowPushConnection();

    // eslint-disable-next-line no-console
    console.log('isAllowSocketConnection', isAllowSocketConnection);
    // eslint-disable-next-line no-console
    console.log('isAllowPushConnection', isAllowPushConnection);

    if (isAllowSocketConnection) {
      this.tryConnect();
    } else {
      this.disconnectServer();
    }

    OneSignal.push(() => {
      OneSignal.setSubscription(isAllowPushConnection);
    });
  }

  isAllowSocketConnection = () => {
    const state = this._getState();
    const isOneSignalInit = isOneSignalInitSelector(state);
    const isNewChatFlow = newChatFlowSelector(state);
    const isLoadedChannels = messagesLoadedSelector(state);
    const isOpenChat = isOpenSelector(state);
    return !isNewChatFlow || !isLoadedChannels || (isOpenChat && isNewChatFlow) || !isOneSignalInit;
  };

  isAllowPushConnection = () => {
    const state = this._getState();
    const isSocketConnection = this.isAllowSocketConnection();
    const isOneSignalInit = isOneSignalInitSelector(state);
    return isOneSignalInit ? !isSocketConnection || !this.isBrowserActive : false;
  };

  tryConnect(): Promise<*> {
    const state = this._getState();
    const accessToken = chatTokenSelector(state);
    const userId = userIdSelector(state);
    const isGhost = isDokkaSupportSelector(state);
    if (isWsConnectionClose() && !this.isSocketConnectionStatus && accessToken && userId && !isGhost) {
      return sbConnect(userId, accessToken).then(() => {
        this._dispatch({
          type: A.chatConnectAction.success,
          userId,
        });
        this._dispatch({
          type: A.setAllowedChatWorkerAction.type,
          payload: false,
        });
      });
    }
    return Promise.reject().catch(() => {
      sbConnectAction(this._dispatch, 'failure');
    });
  }

  messageHandler = (channel: GroupChannel, message: UserMessage) => {
    let payload = this._messageReceive(channel, [message]);
    if (payload) {
      if (this._getState().chat.activeChannel === payload.name) {
        channel.markAsRead();
        payload = payload.set('unreadMessageCount', 0);
      }
      this.receiveMessage(payload);
    }
  };

  receiveMessage = (payload) => {
    if (this.isNoExistsMessage(payload)) {
      this._dispatch({
        type: A.receiveMessageAction.success,
        payload,
      });
    }
  };

  isNoExistsMessage = (payload) => {
    const threads = chatThreadSelector(this._getState());
    const currentThread = threads.get(payload.name);
    // if message comes from nonexisting channel, it probably is not message duplicate
    // otherwise when message is first sent in channel, receiver will not receive in
    // as channel doesnt exist
    if (!currentThread) return true;

    const existsMessageIds = currentThread.get('messages').map((m) => m.messageId);
    return payload.get('messages').every((newMessages) => !existsMessageIds.includes(newMessages.messageId));
  };

  _connectionStatus(status) {
    this._dispatch({
      type: A.setChatConnectionStatusAction.type,
      payload: status,
    });
  }

  reconnectStartedHandler = () => {
    this._connectionStatus(RECONNECTING);
  };

  reconnectSucceededHandler = () => {
    this._connectionStatus(CONNECTED);
  };

  reconnectFailedHandler = () => {
    this._connectionStatus(FAILED);
  };

  add() {
    if (!this.ConnectionHandler && !this.ChannelHandler) {
      const sbInstance = sbGetInstance();
      this.ConnectionHandler = new sbInstance.ConnectionHandler();
      this.ChannelHandler = new sbInstance.ChannelHandler();
      this.ConnectionHandler.onReconnectStarted = this.reconnectStartedHandler;
      this.ConnectionHandler.onReconnectSucceeded = this.reconnectSucceededHandler;
      this.ConnectionHandler.onReconnectFailed = this.reconnectFailedHandler;
      this.ChannelHandler.onMessageReceived = this.messageHandler;
      sbInstance.addConnectionHandler(this.handlerId, this.ConnectionHandler);
      sbInstance.addChannelHandler(this.handlerId, this.ChannelHandler);
    }
  }

  remove() {
    if (this.ConnectionHandler && this.ChannelHandler) {
      const sbInstance = sbGetInstance();
      sbInstance.removeConnectionHandler(this.handlerId);
      sbInstance.removeChannelHandler(this.handlerId);
      this.ConnectionHandler = null;
      this.ChannelHandler = null;
    }
  }
}

export default function chatMiddlware(): Middleware<Store, Action> {
  return (store: MiddlewareAPI<Store, Action>) => {
    sbCreatInstance(process.env.REACT_APP_SENDBIRD_APP_ID);
    const handler = new HandlerClass(store);

    // first connection for loading channels
    handler.tryConnect();
    handler.add();

    return (next) => (action) => {
      switch (action.type) {
        case signOutAction.success:
          sbDisconnect();
          store.dispatch({
            type: A.setAllowedChatWorkerAction.type,
            payload: false,
          });
          break;

        case A.setOpenAction.type:
          setTimeout(() => {
            handler.onChatStatusOpenChange();
          }, 0);
          break;

        case A.onInitOneSignalAction.type:
          setTimeout(() => {
            initPushNotificationObserver(handler);
            handler.onOneSignalInit();
          }, 0);
          break;

        case A.chatGetThreadsAction.success:
          handler.onThreadsLoaded();
          break;

        case signInAction.success:
        case saveOnboardingInfoAction.type:
          setTimeout(() => {
            handler.onGetUserProfile();
          }, 0);
          break;

        default:
          break;
      }
      return next(action);
    };
  };
}
