// @flow
import React, { useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';

import IconButton from '@mui/material/IconButton';
import TextFieldMui from '@mui/material/TextField';
import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';

import SendOutlinedIcon from '@mui/icons-material/SendOutlined';

import { styled } from '@mui/material/styles';

const TextField = styled(TextFieldMui)(({ theme }) => ({
  '& .MuiInputBase-root:hover .MuiOutlinedInput-notchedOutline': {
    borderTopColor: theme.palette.grey[400],
  },

  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
    borderRadius: 0,
    borderTop: `2px solid ${theme.palette.grey[300]}`,
  },

  '&.Mui-focused': {
    '& .MuiOutlinedInput-notchedOutline': {
      borderTopColor: theme.palette.primary.main,
    },
  },
}));

type TChatTextField = {
  threadID: ?string,
  handleSubmitMessage: (message: string) => void,
  iDisabled?: boolean,
};

const ChatTextField: React$StatelessFunctionalComponent<TChatTextField> = ({
  handleSubmitMessage,
  threadID,
  iDisabled,
}) => {
  const { formatMessage } = useIntl();
  const inputRef = useRef();

  const onSubmitMessage = () => {
    const message: string = inputRef.current.value;

    if (message.replace(/\s+/g, '').length > 0) {
      handleSubmitMessage(message);
      inputRef.current.value = '';
    }
  };
  const onKeyDown = (e: SyntheticEvent<HTMLFormElement>) => {
    if (e.ctrlKey || e.metaKey) {
      if (e.keyCode === 13) {
        onSubmitMessage();
      }
    }
  };

  useEffect(() => {
    if (inputRef.current && threadID !== null) {
      inputRef.current.focus();
    }
  }, [threadID]);

  return (
    <Box display="flex" alignItems="center">
      <TextField
        inputRef={inputRef}
        autoFocus
        fullWidth
        multiline
        onKeyDown={onKeyDown}
        placeholder={formatMessage({
          id: 'chat.room.typeMessagePlaceholder',
          defaultMessage: 'Type your message...',
        })}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={onSubmitMessage} disabled={iDisabled} sx={{ ml: 1 }}>
                <SendOutlinedIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </Box>
  );
};

export default ChatTextField;
