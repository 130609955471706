import MuiSkeleton from '@mui/material/Skeleton';
import Paper from '@mui/material/Paper';

import { styled, alpha } from '@mui/material/styles';

export const JEProcessingOverlayWrapper = styled('div', { label: 'JEProcessingOverlay' })(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  position: 'absolute',
  inset: 0,
  zIndex: 20,
  background: theme.palette.common.white,
  borderRadius: 4,
  border: `1px solid ${theme.palette.grey[300]}`,
  padding: theme.spacing(1),
}));

export const NestedSkeleton = styled(MuiSkeleton)(() => ({
  maxHeight: 50,
  flex: 1,
  visibility: 'visible',
}));

export const MessageWrapper = styled(Paper)(({ theme }) => ({
  position: 'absolute',
  zIndex: 1,
  top: '20%',
  left: '50%',
  transform: 'translateX(-50%)',
  backgroundColor: alpha(theme.palette.common.white, 0.8),
  padding: theme.spacing(2),
  textAlign: 'center',
}));
