// @flow
import { Auth } from 'aws-amplify';
import moment from 'moment';
import { sessionST } from 'lib/sessionStorage';
import { createMemoFn } from 'lib/propHelpers';
import queryString from 'query-string';
import { SSO_ONLY_DOMAINS, PROVIDERS_CONFIG } from './config';
import { type ProviderConfig } from './types.js.flow';
import { storage } from 'lib/storage';

type TFederatedSignIn = {
  backUrl?: string,
};

export class InactivityTimeoutError extends Error {
  constructor(message: string) {
    super(message);
    this.message = message;
  }
}
// this must preserve proper stack trace
InactivityTimeoutError.prototype.name = 'InactivityTimeoutError';

export const getAuthProvider = (config: ProviderConfig = PROVIDERS_CONFIG) => {
  const { host } = window.location;
  return Object.entries(config).reduce(
    (res, [provider, hosts]) => (!res && hosts.includes(host) ? provider : res),
    null,
  );
};

export const federatedSignIn: TFederatedSignIn = (backUrl = '') => {
  if (backUrl) {
    storage().backUrl().set(backUrl);
  }
  const provider = getAuthProvider();
  if (provider) {
    Auth.federatedSignIn({ provider });
  } else {
    Auth.federatedSignIn();
  }
};

export const isAuthenticated = async () => {
  try {
    await Auth.currentAuthenticatedUser();
    return true;
  } catch {
    return false;
  }
};

/**
 * @method refreshToken
 * refreshes accessToken for current session by force.
 * used for force-refresh in case accessToken is about to expire
 * to avoid sending it to API with a chance of token expiration
 * before its verifications by endpoint its used for.
 */
export const refreshToken: () => Promise<*> = async () => {
  try {
    const cognitoUser = await Auth.currentAuthenticatedUser();
    const currentSession = await Auth.currentSession();
    // eslint-disable-next-line no-shadow
    const refreshToken = currentSession.getRefreshToken();
    return new Promise((res, rej) => {
      cognitoUser.refreshSession(refreshToken, (err, session) => {
        if (err) {
          rej(err);
        }
        res(session);
      });
    });
  } catch (err) {
    return Promise.reject(err);
  }
};

/**
 * @method isValidSession
 * @param {CognitoUserSession} session CognitoUserSession.
 * @param {number} aheadTime number in seconds.
 * @returns {boolean} if the session is still valid
 */
export const isValidSession = (session, aheadTime: number = 5) => {
  const now = Math.floor(+new Date() / 1000);
  const clockDrift = session.getClockDrift() || 0;
  const adjusted = now - clockDrift + aheadTime;
  return adjusted < session.getAccessToken().getExpiration();
};

// checks if token is expired and refreshes with Cognito if needed automatically
export const getCognitoToken = async () => {
  const session = await Auth.currentSession();

  if (!isValidSession(session)) {
    try {
      // eslint-disable-next-line no-shadow
      const session = await refreshToken();
      return session.getAccessToken().getJwtToken();
    } catch (e) {
      console.error('Unable to refresh token:', e);
    }
    throw new Error('Unable to refresh token');
  }

  return session.getAccessToken().getJwtToken();
};

export const persistTokenRefreshTime = (timestamp: number | null) => {
  sessionST().cognitoTokenLastRefreshAt().set(timestamp);
};

export const nullifyTokenRefreshTime = sessionST().cognitoTokenLastRefreshAt().remove;

export const getTokenRefreshTime = (): number | null => {
  const value = sessionST().cognitoTokenLastRefreshAt().get();

  if (value) return parseInt(value, 10);
  return null;
};

export const tokenInctivityTimeoutReached = (timestamp: number | null) => {
  if (timestamp === null) return false;

  const updatedAt = moment(timestamp);
  return updatedAt.isBefore(moment().subtract(process.env.REACT_APP_COGNITO_INACTIVITY_TIMEOUT || 8, 'hours'));
};

export const checkForTokenInactivityTimeout = () => {
  const tokenLastRefreshTime = getTokenRefreshTime();
  const inactivityLimitReached = tokenInctivityTimeoutReached(tokenLastRefreshTime);
  if (inactivityLimitReached) {
    // cleanup previous data not to force subsecuent getToken requests to throw again
    // until logout action redirects to login
    nullifyTokenRefreshTime();
    throw new InactivityTimeoutError('Token inactivity timeout reached');
  }
};

export const isDomainSSOOnly = createMemoFn(
  () => ({ host: window.location.host }),
  ({ host }) => SSO_ONLY_DOMAINS.includes(host),
);

export const isRedirectFromSSO = createMemoFn(
  () => ({ search: window.location.search }),
  ({ search }) => {
    const queryParams = queryString.parse(search);
    return queryParams.code;
  },
);
