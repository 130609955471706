/* @flow */

import Dexie from 'dexie';

const db = new Dexie('dokka');

db.version(1).stores({
  tableColsWidth: 'tableName,width',
});

db.version(1.1).stores({
  tablePinnedCols: 'tableName,pinnedCols',
  tableDisabledCols: 'tableName,disabledCols',
});

db.version(1.2).stores({
  arrangementOptions: 'jeType,view',
});

db.version(1.3).stores({
  companyRecipients: '++id,companyId,userId,recName,recEmail',
});

db.version(1.4).stores({
  gridColumnsProps: 'gridId',
});

db.version(2.0).stores({
  gridCurrentPresetIds: 'userId,companies',
});

db.version(2.1).stores({
  tableOrderedCols: 'tableName,orderedCols',
});

// new table for handling JE line columns visibility (active, disabled)
// structure - { priv_purchase_order: true, priv_part_number: true, priv_part_desc: false }
db.version(2.4).stores({
  tableJELineColumnsVisibility: 'tableName,lineColumnsVisibility',
});

export default db;
