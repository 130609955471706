// @flow
import React from 'react';
import { FormattedMessage } from 'react-intl';
import type { ReconcileCellValueType } from 'domain/journal/types.js.flow';
import { TRANSACTION_STATES } from 'components/Tables/ReconciliationCell/constants';

type Props = {|
  state: ?ReconcileCellValueType,
  positive: boolean,
|};

const messageId = (postfix: string) => `reconciliation.transactionDetails.title.${postfix}`;

const Title: React$StatelessFunctionalComponent<Props> = ({ state, positive }: Props) => {
  switch (state) {
    case TRANSACTION_STATES.CAN_CREATE:
    case TRANSACTION_STATES.CANT_CREATE:
    case TRANSACTION_STATES.MATCH:
      return positive ? (
        <FormattedMessage
          id={messageId(`${TRANSACTION_STATES.CAN_CREATE}.RECEIVED`)}
          defaultMessage="Search transaction"
        />
      ) : (
        <FormattedMessage
          id={messageId(`${TRANSACTION_STATES.CAN_CREATE}.SPENT`)}
          defaultMessage="Search transaction"
        />
      );
    default:
      return <FormattedMessage id={messageId(TRANSACTION_STATES.RECONCILED)} defaultMessage="Transaction details" />;
  }
};

export default Title;
