import { useCallback, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { DEFAULT_FORMAT } from 'components/Form/DayPicker/helper';
import moment from 'moment';
import { gridCurrentSettingsStateSelector } from 'domain/documents';
import { chatAllUsersByIdSelector } from 'domain/chat';
import { isGridWorkSpaceSelector, getCurrentCompanyData } from 'domain/companies';
import { saveFavoritesAction } from 'domain/dashboard/actions'; // TODO: move from dashboard model
import * as ACL from 'domain/restriction';
import { extractEmail } from 'lib/helpers';
import { querySelector, updateQueryAction } from 'domain/router';
import useToggle from 'hooks/useToggle';
import { TErrorMeta } from 'pages/company/FavoriteTagsPanel/components/CreateFavoriteTagsModal';

import toast from 'components/Toast';

const dateParser = (dateFormat) => (value) => {
  const format = dateFormat || DEFAULT_FORMAT;
  return [value.map((v) => moment(v).format(format)).join(' - ')];
};
const filterParsers = (dateFormat: string) => ({
  text: (value) => value,
  date: dateParser(dateFormat),
  invoice_date: dateParser(dateFormat),
  payment_date: dateParser(dateFormat),
  tags: () => [],
});

const mapStateToProps = (state) => ({
  currentCompany: getCurrentCompanyData(state),
  usersByID: chatAllUsersByIdSelector(state),
  gridCurrentSettings: gridCurrentSettingsStateSelector(state),
  isGridWorkspace: isGridWorkSpaceSelector(state),
  searchQuery: querySelector(state),
  isGranted: ACL.isGranted(state),
});

const useFavoriteTags = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const { isGranted, isGridWorkspace, gridCurrentSettings, searchQuery, currentCompany, usersByID } =
    useSelector(mapStateToProps);
  const { documentType } = useParams();
  const [isOpenModal, toggleIsOpenModal] = useToggle();
  const [errorMeta, setErrorMeta] = useState();
  const { gridFilters } = gridCurrentSettings;
  const { dateFormat } = currentCompany;
  const isSupplier = isGranted(ACL.IS_SUPPLIER_USER);

  const gridCurrentFilters = useMemo(
    () => (isGridWorkspace && Object.keys(gridFilters).length > 0 ? gridFilters : null),
    [isGridWorkspace, gridFilters],
  );

  const currentTags = useMemo(() => {
    const filters = filterParsers(dateFormat);
    const tags = Object.keys(searchQuery).reduce((A, key) => {
      if (key in filters) {
        const tagsInFilter = filters[key](searchQuery[key]);
        const tag = tagsInFilter && tagsInFilter.length > 0 ? tagsInFilter.map((v) => ({ value: v, type: key })) : [];
        return A.concat(tag);
      }
      return A;
    }, []);

    // label is system user attribute
    if (searchQuery.label) {
      // try to get user email from query
      const email = searchQuery.query ? extractEmail(searchQuery.query.split(' ').join('+')) : null;

      const value = email || searchQuery.label;
      tags.push({
        type: 'label',
        value,
      });
    }
    return tags;
  }, [searchQuery, dateFormat]);

  const checkUserTag = useCallback((tagValue: string) => usersByID.get(tagValue.replace(/^#+/, '')), [usersByID]);

  const handleDeleteTag = useCallback(
    (tag): void => {
      const result = currentTags
        .filter((t) => t.type === tag.type)
        .filter((t) => t.value !== tag.value)
        .map((t) => t.value);
      if (tag.type === 'label') {
        dispatch(
          updateQueryAction({
            stag: result,
            [tag.type]: result,
            query: null,
          }),
        );
      } else {
        dispatch(
          updateQueryAction({
            stag: result,
            [tag.type]: result,
          }),
        );
      }
    },
    [dispatch, currentTags],
  );

  const handleSaveTags = useCallback(
    (label: string) => {
      new Promise((resolve, reject) => {
        dispatch(saveFavoritesAction({ label, query: searchQuery, documentType, resolve, reject }));
      })
        .then(() => {
          toast.info(
            intl.formatMessage({
              id: 'documents.tags.favorite.popup',
              defaultMessage: 'Search query was added to Favourite',
            }),
          );
          toggleIsOpenModal();
        })
        .catch((error: TErrorMeta) => {
          if (error.status === 406) {
            toast.info(
              intl.formatMessage({
                id: `components.saveSearchPopup.error.${error.id}`,
                defaultMessage: error.message,
              }),
            );
            toggleIsOpenModal();
          } else {
            setErrorMeta(error);
          }
        });
    },
    [dispatch, documentType, searchQuery, intl, toggleIsOpenModal],
  );

  const resetErrorMeta = () => setErrorMeta(null);

  const handleToggleModal = () => {
    toggleIsOpenModal();
    setErrorMeta(null);
  };

  const modalTitle = useMemo(() => {
    if (currentTags.length) {
      const firstTag = currentTags[0].value.replace(/^#*/gi, '');
      const user = checkUserTag(firstTag);

      return user ? user.username : firstTag;
    }

    return '';
  }, [currentTags, checkUserTag]);

  return {
    tags: currentTags,
    gridTags: gridCurrentFilters,
    errorMeta,
    modalTitle,
    isSupplier,
    isOpenModal,
    handleToggleModal,
    handleSaveTags,
    handleDeleteTag,
    checkUserTag,
    resetErrorMeta,
  };
};

export default useFavoriteTags;
