// @flow
import React from 'react';
import { List } from 'immutable';

import { TILE_Z_INDEXES } from 'pages/company/TileGrid/TileCard/components/StyledComponents';
import AvatarGroupMui from '@mui/material/AvatarGroup';
import AvatarMui from '@mui/material/Avatar';

import Tooltip from 'components/mui/Tooltip';

import { styled, alpha, useTheme } from '@mui/material/styles';

const AvatarGroup = styled(AvatarGroupMui)(({ theme }) => ({
  position: 'absolute',
  bottom: theme.spacing(1),
  left: theme.spacing(1),
  zIndex: TILE_Z_INDEXES.userTagAvatars, // overlap MuiCardActionArea-focusHighlight

  '& .MuiAvatar-root': {
    width: 28,
    height: 28,
    fontSize: theme.typography.pxToRem(14),
  },
}));

type TTagUser = {
  userId: string,
  username: string,
  src?: string,
};

type TUserTagAvatars = {
  tags: List<TTagUser>,
};

const UserTagAvatars: React$StatelessFunctionalComponent<TUserTagAvatars> = ({ tags }) => {
  const theme = useTheme();

  return (
    <AvatarGroup
      componentsProps={{
        imgProps: {
          loading: 'lazy',
        },
        additionalAvatar: {
          sx: {
            backgroundColor: alpha(theme.palette.primary.main, 0.2),
            color: theme.palette.primary.main,
          },
        },
      }}
    >
      {tags.map((tag) => (
        <Tooltip t={tag.username} placement="top" key={tag.userId}>
          <AvatarMui src={tag.src} imgProps={{ loading: 'lazy' }} />
        </Tooltip>
      ))}
    </AvatarGroup>
  );
};

export default UserTagAvatars;
