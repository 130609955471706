// @flow
import * as React from 'react';
import { withStyles } from '@mui/styles';
import sheet from './sheet';

type Props = {|
  user: ?string,
  classes: {
    [key: string]: string,
  },
  children: ?React$Node,
|};

class Title extends React.PureComponent<Props> {
  render() {
    const { classes, user } = this.props;
    return (
      <div className={classes.container}>
        {this.props.children}
        <span className={classes.title}>
          <span className={classes.name}>{user}</span>
        </span>
      </div>
    );
  }
}

export default withStyles(sheet)(Title);
