// @flow
import React from 'react';

import CheckboxBase from 'components/mui/Form/Checkbox/CheckboxBase';

import type { FieldProps } from 'redux-form/lib/FieldProps.types.js.flow';

type TCheckboxForm = {} & FieldProps;

const CheckboxForm: React$StatelessFunctionalComponent<TCheckboxForm> = (props) => {
  const { meta, ...rest } = props;
  const isError = Boolean(meta.touched && meta.error);

  return <CheckboxBase error={isError} {...rest} />;
};

export default CheckboxForm;
