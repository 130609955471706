/* @flow */
export default {
  warning: {
    id: 'document.journalEntry.toast.dataValidationWarning',
    defaultMessage: 'Data Validation Warning:',
  },
  error: {
    id: 'document.journalEntry.toast.invalidData',
    defaultMessage: 'Invalid Data:',
  },
  info: {
    id: 'document.journalEntry.toast.info',
    defaultMessage: 'Info:',
  },
};
