// @flow
import React from 'react';
import { Field, reduxForm } from 'redux-form/lib/immutable';

// intl
import { useIntl } from 'react-intl';

import type { RecordOf } from 'immutable';

// mui
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import AlertTitle from '@mui/material/AlertTitle';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';

// components
import TextFieldForm from 'components/mui/Form/TextField/TextFieldForm';

import { styled } from '@mui/styles';

const SoBadGoogle = styled('div')(() => ({ opacity: 0, float: 'left', height: 0, width: 0 }));

export type Signer = {
  name: string,
  token_name: string,
  password: string,
};

type SignerFormProps = {|
  // eslint-disable-next-line react/no-unused-prop-types
  signer: RecordOf<Signer>,
  handleSubmit: (data: Signer) => void,
  onRemove: () => void,
|};

type SignerProps = {|
  signer: RecordOf<Signer>,
  onSubmit: (data: Signer) => void,
  onRemove: () => void,
|};

type ReadOnlySignerType = {|
  signer: RecordOf<Signer>,
  onRemove: () => void,
|};

const SignerForm = (props: SignerFormProps) => {
  const { handleSubmit, onRemove } = props;
  const { formatMessage } = useIntl();
  return (
    <Box mr={3}>
      <form onSubmit={handleSubmit}>
        <SoBadGoogle>
          <input name="name" type="text" />
          <input name="password" type="password" />
        </SoBadGoogle>
        <Grid container flexWrap="nowrap" alignItems="baseline" spacing={2}>
          <Grid container spacing={2} flexDirection="column" ml={0}>
            <Grid item width={200}>
              <Field
                component={TextFieldForm}
                label={formatMessage({
                  id: 'forms.organization.features.sign.name.label',
                  defaultMessage: 'Signer email',
                })}
                placeholder={formatMessage({
                  id: 'forms.organization.features.sign.name.placeholder',
                  defaultMessage: 'Enter email',
                })}
                required
                name="name"
                type="text"
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item width={200}>
              <Field
                component={TextFieldForm}
                label={formatMessage({
                  id: 'forms.organization.features.sign.status.label',
                  defaultMessage: 'Enter Encryption password',
                })}
                placeholder={formatMessage({
                  id: 'forms.organization.features.sign.placeholder',
                  defaultMessage: '00000000',
                })}
                required
                name="password"
                type="password"
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
          <Grid item>
            <IconButton aria-label="delete" color="error" onClick={onRemove}>
              <DeleteOutlineOutlinedIcon />
            </IconButton>
          </Grid>
        </Grid>
        <Button type="submit" variant="contained" sx={{ minWidth: (theme) => theme.typography.pxToRem(140), mt: 1 }}>
          {formatMessage({
            id: 'forms.common.confirm',
            defaultMessage: 'Confirm',
          })}
        </Button>
      </form>
    </Box>
  );
};

const FirstSignerForm = reduxForm({
  form: 'SingerFormFirst',
})(SignerForm);

const SecondSignerForm = reduxForm({
  form: 'SingerFormSecond',
})(SignerForm);

const ReadOnlySigner = (props: ReadOnlySignerType) => {
  const { signer, onRemove } = props;
  const { formatMessage } = useIntl();
  return (
    <Box width={178} mr={12} mt={2}>
      <Box mb={2}>
        <Grid container justifyContent="space-between" mb={1}>
          <Grid item>
            <Typography>
              {formatMessage({
                id: 'forms.organization.features.signer',
                defaultMessage: 'Signer',
              })}
              :
            </Typography>
          </Grid>
          <Grid item>
            {signer.token_name ? (
              <IconButton aria-label="delete" color="error" onClick={onRemove} size="small">
                <DeleteOutlineOutlinedIcon fontSize="small" />
              </IconButton>
            ) : null}
          </Grid>
        </Grid>
        <AlertTitle>{signer.name}</AlertTitle>
      </Box>

      <Typography mb={1} color="grey.600">
        {formatMessage({
          id: 'forms.organization.features.sign.status.savedLabel',
          defaultMessage: 'Encryption password',
        })}
      </Typography>
      <Typography color="grey.600">{signer.password}</Typography>
    </Box>
  );
};

export const FirstSigner = (props: SignerProps) => {
  const { onSubmit, onRemove, signer } = props;
  return signer.token_name ? (
    <ReadOnlySigner signer={signer} onRemove={onRemove} />
  ) : (
    <FirstSignerForm signer={signer} onSubmit={onSubmit} onRemove={onRemove} />
  );
};

export const SecondSigner = (props: SignerProps) => {
  const { onSubmit, onRemove, signer } = props;
  return signer.token_name ? (
    <ReadOnlySigner signer={signer} onRemove={onRemove} />
  ) : (
    <SecondSignerForm signer={signer} onSubmit={onSubmit} onRemove={onRemove} />
  );
};
