import React from 'react';
import { useSelector } from 'react-redux';
import { RouterProvider } from 'react-router-dom';
import { userGUIDSelector } from 'domain/env';
import { matchRoute } from 'domain/router/utils';
import router from 'domain/router/router';

// v7_startTransition: true - https://github.com/remix-run/react-router/releases/tag/react-router%406.13.0

const RootRoute = () => {
    // reconsider approach based on other data set on auth event specifically
    // to indicate user has been loaded
    const userLoaded = useSelector(userGUIDSelector);
    return userLoaded || matchRoute.canBeAccessedUnauthorizedRoute() ? <RouterProvider router={router} future={{ v7_startTransition: true }} /> : null;
};

export default RootRoute;
