import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';

import { styled } from '@mui/material/styles';

export const FakeDropdownContainer = styled(Box, { label: 'FakeDropdownContainer' })(() => ({
  height: '100%',
  display: 'flex',
  alignItems: 'center',
}));

export const FakeDropdown = styled(Chip, { label: 'FakeDropdownChip' })(({ theme }) => ({
  flex: 1,
  justifyContent: 'left',
  backgroundColor: theme.palette.grey[300],

  '& .MuiChip-label': {
    width: '100%',
    paddingLeft: theme.spacing(1),
    paddingRight: 0,

    '.ag-cell-popup-editing &': {
      display: 'none',
    },
  },

  '& .MuiSvgIcon-root': {
    marginLeft: 'auto',
    color: theme.palette.grey[600],
    fontSize: theme.typography.pxToRem(24),
  },
}));

export const FakeDropdownLabel = styled('span', { label: 'FakeDropdownLabel' })(() => ({
  flex: 1,
  display: 'flex',
  alignItems: 'center',
}));

export const FakeDropdownLabelText = styled('span', { label: 'FakeDropdownLabel' })(() => ({
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  // unicodeBidi: 'plaintext',
}));
