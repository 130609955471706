// @flow
import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';

import AccordionDetails from '@mui/material/AccordionDetails';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import {
  Accordion,
  Button,
  AccordionSummary,
  AppImageContainer,
} from 'pages/configurations/company/pages/applications/components/AppItem/StyledComponents';

const connectionColors = {
  pending: 'warning',
  connected: 'success',
};

type Props = {
  isSelected: boolean,
  onClickOpen: () => void,
  onClickClose: () => void,
  name: string,
  id: string,
  img: any,
  onConnect: () => void,
  onDisconnect: () => void,
  status: 'connected' | 'not_connected' | 'pending',
  workspace: string,
};

const AppItem: React$StatelessFunctionalComponent<Props> = ({
  isSelected,
  onClickOpen,
  onClickClose,
  name,
  img,
  onConnect,
  onDisconnect,
  status,
  workspace,
}) => {
  const isConnected = status === 'connected';
  const isDisconnected = status === 'not_connected';

  const onToggle = () => (isSelected ? onClickClose() : onClickOpen());

  const renderButton = useCallback(() => {
    switch (true) {
      case !isDisconnected:
        return (
          <Button variant="outlined" onClick={onDisconnect}>
            <FormattedMessage id="settings.connections.disconnect" defaultMessage="Disconnect" />
          </Button>
        );
      case isDisconnected:
        return (
          <Button onClick={onConnect}>
            <FormattedMessage id="settings.connections.connect" defaultMessage="Connect" />
          </Button>
        );
      default:
        return null;
    }
  }, [onDisconnect, onConnect, isDisconnected]);

  return (
    <Accordion expanded={isSelected} disableGutters onChange={onToggle}>
      <AccordionSummary>
        <Stack direction="row" justifyContent="space-between" alignItems="center" flex="1" flexWrap="no-wrap">
          <Stack display="flex" direction="row" spacing={1} alignItems="center">
            <AppImageContainer>
              <img width={24} height="auto" src={img} alt={name} />
            </AppImageContainer>
            <Typography>{name}</Typography>
            {!isDisconnected && (
              <Chip
                label={
                  <>
                    <FormattedMessage id={`settings.connections.${status}`} defaultMessage={status} />{' '}
                    {isConnected && `(${workspace})`}
                  </>
                }
                color={connectionColors[status]}
                size="small"
              />
            )}
          </Stack>
          {renderButton()}
        </Stack>
      </AccordionSummary>
      <AccordionDetails sx={{ px: 5 }} />
    </Accordion>
  );
};

export default AppItem;
