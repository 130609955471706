// @flow
import { set, update } from 'lodash/fp';
import * as actions from './actions';

declare type Actions = {
  type: string,
  payload?: mixed,
};

export const reducer = {
  textract(state: Object = { enabled: false }, action: Actions) {
    switch (action.type) {
      case actions.getDocumentExtractedTableData.success:
        return set('data', action.payload)(state);
      case actions.getDocumentExtractedTableFieldMapping.success:
        return set('fields', action.payload)(state);
      case actions.toggle2WayMatchingForDocumentAction.type:
        return update('enabled', (value) => !value)(state);
      case actions.cleanDocumentExtractedTableData.type:
        // toggler state should remain when navigating through documents
        return { enabled: state.enabled };
      default:
        return state;
    }
  },
};
