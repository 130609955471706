/* @flow */
import * as React from 'react';
import { withStyles } from '@mui/styles';
import { Document as DocumentPDF, Page } from 'react-pdf';
import { debounce } from 'throttle-debounce';
import Api from 'domain/api';

type Props = {
  anonymousToken: string,
  classes: {|
    [key: string]: string,
  |},
};

type State = {
  pages: $ReadOnlyArray<number>,
  width: number,
};

function createRange(from: number, to: number): $ReadOnlyArray<number> {
  let current = from;
  let res = [];
  do {
    res = res.concat(current);
    current++;
  } while (current <= to);
  return res;
}

const sheet = {
  item: {
    marginTop: 15,
    '&:first-child': {
      marginTop: 0,
    },
  },
};

class Preview extends React.Component<Props, State> {
  state = {
    pages: [],
    width: 0,
  };

  get url() {
    const { anonymousToken } = this.props;
    return Api.anGetDocument({ anonymousToken });
  }

  setWidth = (value: number) => {
    this.width = value;
    this.setState({ width: Math.ceil(value) });
  };

  getRef = (el: ?HTMLElement) => {
    if (el) {
      this.renderPages(el.offsetWidth);
    }
    this.container = el;
  };

  handlSuccess = ({ numPages }) => {
    const range = createRange(1, numPages);
    this.setState({
      pages: range,
    });
  };

  width: number = 400;

  container: ?HTMLElement;

  renderPages = debounce(500, this.setWidth);

  render() {
    const { classes } = this.props;
    return (
      <DocumentPDF file={{ url: this.url }} onLoadSuccess={this.handlSuccess}>
        <ul className={classes.list} ref={this.getRef}>
          {this.state.pages.map((e) => (
            <li key={e} className={classes.item}>
              <Page renderTextLayer={false} renderAnnotations pageNumber={e} width={this.state.width} />
            </li>
          ))}
        </ul>
      </DocumentPDF>
    );
  }
}

export default withStyles(sheet)(Preview);
