// @flow
import c from '../../constatnt';

export default (theme) => ({
  container: {
    position: 'relative',
  },
  col: {
    borderBottom: '1px solid #DEE2E6',
    minWidth: 170,
    width: '50%',
    '&::before': {
      content: '""',
      position: 'absolute',
      top: -1,
      left: -1,
      bottom: 2,
      borderRight: '1px solid #DEE2E6',
      zIndex: 0,

      '.rtl &': {
        left: 'auto',
        right: -1,
      },

      '.ltr &': {
        left: -1,
      },
    },
  },
  activeRow: {},
  hovered: {},
  removeBtn: {
    width: 30,
    height: c.ROW_HEIGHT,
    padding: 0,
    position: 'relative',
    '&:focus': {
      zIndex: 1,
    },
    opacity: 0,
    transition: 'opacity 0.3s',
  },
  lineItems: {
    '&:not(:first-child)': {
      '&&$hovered, &:hover, &$activeRow': {
        backgroundColor: 'rgba(108, 152, 216, 0.1)',
        '& $removeBtn': {
          opacity: 1,
        },
      },
    },
  },
  row: {
    '&:first-child': {
      '& $col': {
        backgroundColor: theme.colors.greyLighter,
        borderTop: '1px solid #DEE2E6',
      },
    },
    '&:not(:first-child) $col:hover': {
      '&:hover': {
        backgroundColor: '#F8F8F8',
      },
    },
  },
});
