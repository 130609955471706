// @flow
import React, { useCallback, memo } from 'react';
import { FormattedMessage } from 'react-intl';
import { messages } from 'components/AgGrid/helpers/translations';

// styles
import { styled } from '@mui/material/styles';
import { SET_FILTER_HOVER_CLASSNAME } from 'components/AgGrid/helpers';

const Wrapper = styled('div')(() => ({ maxWidth: 165, overflow: 'hidden', textOverflow: 'ellipsis' }));
const ContainerForTooltip = styled('div')(() => ({ position: 'absolute', inset: 0 }));

type Props = {|
  value: (string | number)[],
  valueFormatted: ?string | number,
|};

const FilterSetRender = (props: Props) => {
  const { valueFormatted, value } = props;
  const [className, setClassName] = React.useState('');

  const handleEnter = useCallback(() => {
    setClassName(SET_FILTER_HOVER_CLASSNAME);
  }, []);

  const handleLeave = useCallback(() => {
    setClassName('');
  }, []);

  return (
    <>
      <ContainerForTooltip onMouseEnter={handleEnter} onMouseLeave={handleLeave} />
      <Wrapper className={className}>{valueFormatted || value || <FormattedMessage {...messages.blanks} />}</Wrapper>
    </>
  );
};
export default memo(FilterSetRender);
