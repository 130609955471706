/* @flow */
import * as React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';

// types
import type { Dispatch } from 'redux';

// components
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import CircularProgressWithBackdrop from 'components/mui/CircularProgressWithBackdrop';
import Select from 'components/mui/Form/Select/SelectBase';
import FormHelperText from '@mui/material/FormHelperText';
import { ReactComponent as Logo } from './icons/logo.svg';
import SvgIcon from '@mui/material/SvgIcon';

// api
import Api from 'domain/api';

// helpers
import { get } from 'lodash';

// selectors
import { tokenSelector } from 'domain/documents/documentSelector';

// actions
import { setErpsAction } from 'domain/settings/settingsActions';

import { FormattedMessage } from 'react-intl';

// helpers
const companySerializer = (companies) =>
  // eslint-disable-next-line camelcase
  companies.map(({ organization_id, organization_name }) => ({ value: organization_id, label: organization_name }));

type Props = {
  classes: {
    [key: string]: string,
  },
  dokkaToken: string,
  setErps: Dispatch<setErpsAction>,
};

type ListItem = {
  value: string | number,
  label: string,
};

type State = {
  errorMessage: null | false | string,
  companies: ?Array<ListItem>,
  isLoading: boolean,
  selectedCompanyId: ?number,
  show: boolean,
};

class ZohoConnectModal extends React.Component<Props, State> {
  state = {
    errorMessage: null,
    companies: null,
    isLoading: false,
    selectedCompanyId: null,
    show: true,
  };

  componentDidMount() {
    const { dokkaToken } = this.props;
    // eslint-disable-next-line react/no-did-mount-set-state
    this.setState({ isLoading: true, errorMessage: '' });

    Api.getZohoCompanyList({
      params: {
        dokkaToken,
      },
    }).then(({ data: { error, organizations, message } }) => {
      if (error) {
        this.setState({ errorMessage: message, isLoading: false });
      } else {
        this.setState({
          companies: companySerializer(organizations),
          isLoading: false,
        });
      }
    });
  }

  onCompanyChange = ({ target: { value } }) => {
    this.setState({ selectedCompanyId: value, errorMessage: '' });
  };

  onCompanySave = () => {
    const { dokkaToken, setErps } = this.props;
    const { selectedCompanyId } = this.state;

    this.setState({ isLoading: true });

    Api.setZohoCompany({
      data: {
        dokkaToken,
        organization_id: selectedCompanyId,
      },
    })
      .then((response) => {
        setErps(response.data);
        this.handleClose();
      })
      .catch((error) => {
        const errorMessage = get(error, 'response.data.message', 'Server error');
        this.setState({ errorMessage, isLoading: false });
      });
  };

  handleClose = () => {
    this.setState({ show: false });
  };

  render() {
    const { isLoading, companies, errorMessage, selectedCompanyId, show } = this.state;

    const companiesFetched = Array.isArray(companies);

    return show ? (
      <Dialog open={show} onClose={this.handleClose} fullWidth>
        <DialogTitle textAlign="center">
          <SvgIcon sx={{ fontSize: '45px' }} component={Logo} inheritViewBox />
          <Typography variant="h6">
            <FormattedMessage id="sageConnect.chooseCompany" defaultMessage="Select company" />
          </Typography>
          {Boolean(errorMessage) && <FormHelperText error={Boolean(errorMessage)}>{errorMessage}</FormHelperText>}
        </DialogTitle>
        <DialogContent sx={(theme) => ({ pt: `${theme.spacing(1)}!important`, minHeight: 100 })}>
          {isLoading && <CircularProgressWithBackdrop BackdropProps={{ sx: { position: 'absolute' } }} />}
          {companiesFetched && (
            <Select
              FormControlProps={{ fullWidth: true }}
              options={companies}
              value={selectedCompanyId}
              onChange={this.onCompanyChange}
              label="Choose company"
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button disabled={!selectedCompanyId} onClick={this.onCompanySave}>
            <FormattedMessage id="sageConnect.chooseCompany.save" defaultMessage="Save" />
          </Button>
        </DialogActions>
      </Dialog>
    ) : null;
  }
}

const mapStateToProps = (state) => ({ dokkaToken: tokenSelector(state) });

export default compose(connect(mapStateToProps, { setErps: setErpsAction }))(ZohoConnectModal);
