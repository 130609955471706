// @flow
import React, { Component } from 'react';

// redux
import { compose } from 'redux';
import { connect } from 'react-redux';
// $FlowFixMe untyped module
import { rtlEnable } from 'domain/env';

// components
import { GridTable as Grid } from 'components/Tables/grid';

// types
import type { JornalEntryType, RecordsCellType } from 'domain/journal/helper';

import type { MetaType } from 'components/Tables/types.js.flow';

// helpers
import { getMetaRange } from 'components/Tables/helpers';

// jss
import cx from 'classnames';
// $FlowFixMe untyped module
import { withStyles } from '@mui/styles';
import sheet from './selectedResultsSheet';

import { getDataCell } from 'components/Tables/layout/helpers';
import { removeButton } from '../component';

type Props = {|
  classes: typeof sheet,
  data: JornalEntryType,
  rtl: boolean,
  allowLineDeletion: boolean,
  getItem: (d: RecordsCellType | void) => React$Node,
  removeHandler: (row: number) => void,
|};

class SelectedResults extends Component<Props> {
  // returns table representation as tuple. eg [['A', 'B', 'C'], [5, 6, 7, 8, 9]]
  get meta(): MetaType {
    const { data, rtl } = this.props;
    return getMetaRange(data, rtl);
  }

  getData = (row: number, col: string) => getDataCell(this.props.data, row, col);

  getItem = (row: number, col: string) => this.props.getItem(this.getData(row, col));

  getRowStyle = () => {
    const { classes } = this.props;
    return cx(classes.row, classes.lineItems);
  };

  getCollStyle = () => {
    const {
      classes: { col },
    } = this.props;
    return col;
  };

  removeFactory = (r: number) =>
    // header line index = 0 and it can not be deleted
    this.props.allowLineDeletion && r > 0
      ? // $FlowFixMe inexact classes dont match exact
        removeButton({
          classes: this.props.classes,
          removeHandler: this.props.removeHandler,
          // decrease row as we have rendered header line that is injected in selector and is not
          // present in table data List
        })(r - 1)
      : () => null;

  render() {
    const { classes } = this.props;
    return (
      <Grid
        className={cx(
          classes.container,
          // $FlowFixMe
          { [classes.deletionDisabled]: !this.props.allowLineDeletion },
        )}
        markFirstRowWithClass
        meta={this.meta}
        getItem={this.getItem}
        prevItem={this.removeFactory}
        getRowStyle={this.getRowStyle}
        getCollStyle={this.getCollStyle}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  rtl: rtlEnable(state),
});

export default compose(connect(mapStateToProps), withStyles(sheet))(SelectedResults);
