// @flow
import { useCallback, useEffect, useState } from 'react';

// redux
import { useSelector } from 'react-redux';
import { localeSelector, userIdSelector } from 'domain/env/envSelector';

// helpers
import { get } from 'lodash';
import {
  config,
  TranslationListAdapter,
  type TOption,
  getTranslationsFromSessionStorage,
  setTranslationsInSessionStorage,
  isLocalesEqual,
  getOptionFromLS,
  setOptionToLS,
  TranslateValueAdapter,
} from './helpers';

const mapStateToProps = (state) => ({
  locale: localeSelector(state),
  userID: userIdSelector(state),
});

let translateInstance = null;

export default (sourceText: ?string) => {
  const { locale, userID } = useSelector(mapStateToProps);

  const [translate, setTranslate] = useState(translateInstance);

  const [availableTranslationList, setAvailableTranslationList] = useState([]);
  const [sourceTranslateOption, setSourceTranslateOption] = useState(null);
  const [targetTranslateOption, setTargetTranslateOption] = useState(
    TranslateValueAdapter(getOptionFromLS(userID) || locale),
  );
  const [targetTranslate, setTargetTranslate] = useState(null);
  const [isTranslating, setIsTranslating] = useState(false);

  const translateText = useCallback(
    async (source?: string, target?: string) => {
      const [to, from] = [target || targetTranslateOption, source || sourceTranslateOption];

      if (!isLocalesEqual(from, to) && Boolean(translate)) {
        if (sourceText && Boolean(to)) {
          try {
            setIsTranslating(true);

            // eslint-disable-next-line no-unused-vars
            const [_, response] = await translate.translate(sourceText, {
              to,
              from,
            });

            if (response) {
              const [translations] = get(response, 'data.translations', []);
              if (translations) {
                const { translatedText, detectedSourceLanguage } = translations;
                setTargetTranslate(translatedText);
                if (detectedSourceLanguage) setSourceTranslateOption(TranslateValueAdapter(detectedSourceLanguage));
              }
            }
          } finally {
            setIsTranslating(false);
          }
        }
      }
    },
    [targetTranslateOption, sourceTranslateOption, sourceText, translate],
  );

  const onChangeSourceTranslateOption = useCallback(
    (e, option: TOption) => {
      setSourceTranslateOption(option.value);
      translateText(option.value, targetTranslateOption);
    },
    [translateText, targetTranslateOption],
  );
  const onChangeTargetTranslateOption = useCallback(
    (e, option: TOption) => {
      setTargetTranslateOption(option.value);
      setOptionToLS(userID, option.value);
      translateText(sourceTranslateOption, option.value);
    },
    [translateText, userID, sourceTranslateOption],
  );

  useEffect(() => {
    const dynamycImport = async () => {
      if (!translate) {
        const { Translate } = await import('@google-cloud/translate/build/src/v2');
        const tr = new Translate(config);
        translateInstance = tr;
        setTranslate(tr);
      }
    };

    dynamycImport();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const callApi = async () => {
      const translations = getTranslationsFromSessionStorage();
      let res = translations;
      if (!translations) {
        const [response] = await translate.getLanguages();
        res = response;
      }

      setAvailableTranslationList(TranslationListAdapter(res));
      setTranslationsInSessionStorage(res);
    };
    if (translate) {
      callApi();
    }
  }, [translate]);

  useEffect(() => {
    translateText();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sourceText, translate]);

  return {
    availableTranslationList,
    targetTranslateOption,
    sourceTranslateOption,
    onChangeSourceTranslateOption,
    onChangeTargetTranslateOption,
    targetTranslate,
    isTranslating,
  };
};
