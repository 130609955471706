import mediaQuery from 'lib/mediaQuery';

export default (theme) => ({
  container: {
    overflowY: 'auto',
    height: 'calc(100% - 50px)',
    flex: [1, 1, '100%'],
    minHeight: 0,
  },
  title: {
    position: 'relative',
    color: theme.colors.greyDarker,
    fontSize: 12,
    margin: [10, 7, 10, 10],
    [`@media (max-height: ${mediaQuery.maxHeight})`]: {
      fontSize: 10,
    },
    '& > span': {
      backgroundColor: '#FFF',
      padding: [10, 10, 10, 0],
      textTransform: 'uppercase',
    },
    '&:after': {
      content: '""',
      position: 'absolute',
      bottom: 0,
      left: 0,
      right: 0,
      height: '0.5em',
      color: theme.colors.greyDark,
      borderTop: '1px solid',
      zIndex: -1,
    },
  },
  users: {
    marginRight: 12,
    paddingBottom: 15,
    '& > li': {
      marginTop: 10,
    },
  },
  documentRequests: {
    margin: [0, 12, 20, 0],
    '&:empty': {
      margin: 0,
    },
  },
  btn: {
    display: 'block',
    border: '0 none',
    textAlign: 'left',
    background: 'none',
    padding: 0,
    width: '100%',
    outline: '0 none',
    cursor: 'pointer',
    '&[disabled]': {
      cursor: 'wait',
    },
  },
  // item
  doc: {
    position: 'relative',
    marginBottom: 3,
  },
  item: {
    marginLeft: 10,
    height: 56,
    display: 'flex',
    alignItems: 'flex-start',
    position: 'relative',
    [`@media (max-height: ${mediaQuery.maxHeight})`]: {
      height: 45,
    },
    borderBottom: `1px solid ${theme.colors.greyDark}`,
  },
  itemDoc: {
    background: '#F47F2A',
    borderRadius: 4,
    textDecoration: 'none',
  },
  info: {
    fontSize: 14,
    padding: [0, 5, 0, 0],
    marginLeft: 10,
    flex: [1, 1],
    minWidth: 0,
    maxWidth: 'calc(100% - 10px)',
    [`@media (max-height: ${mediaQuery.maxHeight})`]: {
      fontSize: 12,
      padding: [0, 5, 5, 0],
    },
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: 34,
  },
  content: {
    flex: [1, 1],
    color: '#333333',
    minHeight: '100%',
    display: 'flex',
    alignItems: 'flex-start',
    maxWidth: 'calc(100% - 30px)',
    position: 'relative',
  },
  contentDoc: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: '#fff',
    border: '0 none',
    position: 'relative',
    marginLeft: 0,
    maxWidth: '100%',
    '& $info $name, & $info $text': {
      color: '#fff',
      fontStyle: 'normal',
    },
    '& $info $text': {
      textTransform: 'uppercase',
      fontWeight: 'bold',
    },
  },
  name: {
    fontSize: 14,
    color: theme.colors.greyBlack,
    maxWidth: 205,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  text: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: theme.colors.greyDarker,
  },
  textDoc: {
    fontStyle: 'italic',
    fontWeight: 'normal',
  },
  meta: {
    flex: [0, 1, '60px'],
    maxWidth: 60,
    minWidth: 60,
    whiteSpace: 'nowrap',
    textAlign: 'right',
    padding: [0, 0, 10, 0],
    position: 'relative',
    fontSize: 12,
    overflow: 'hidden',
    minHeight: '100%',
    color: '#828282',
  },
  metaDoc: {
    border: '0 none',
    color: 'rgba(255, 255, 255, 0.8)',
  },
  time: {
    maxWidth: '100%',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    color: 'currentColor',
    padding: [0, 10, 1, 5],
  },
  count: {
    color: '#fff',
    // padding: [0, 5],
    display: 'inline-block',
    height: 24,
    lineHeight: '24px',
    background: '#FF6914',
    borderRadius: 8,
    position: 'relative',
    zIndex: 2,
    marginRight: 16,
    minWidth: 12,
    textAlign: 'center',
    '&:before, &:after': {
      content: '""',
      position: 'absolute',
      display: 'inline-block',
      width: 12,
      height: 24,
      background: '#FF6914',
      top: 0,
      zIndex: -1,
    },
    '&:before': {
      left: -6,
      borderRadius: [12, 0, 0, 12],
    },
    '&:after': {
      borderRadius: [0, 12, 12, 0],
      right: -6,
    },
  },

  highlight: {
    color: 'inherit',
    fontWeight: 600,
    backgroundColor: 'transparent',
  },
});
