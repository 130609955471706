// @flow
import Paper from '@mui/material/Paper';
import DividerMui from '@mui/material/Divider';

import { styled } from '@mui/material/styles';

export const SelectPanelContainer = styled(Paper, { shouldForwardProp: (propName) => propName !== 'isVisible' })(
  ({ theme, isVisible }) => ({
    position: 'fixed',
    bottom: 30,
    left: 0,
    right: 0,
    margin: 'auto',
    width: 'max-content',
    display: isVisible ? 'flex' : 'none',
    zIndex: theme.zIndex.modal,
    flexDirection: 'row',
    borderRadius: 8,
    gap: 8,
  }),
);

export const CategoryIcon = styled('div', {
  shouldForwardProp: (propName) => !['src'].includes(propName),
})(({ src, theme }) => ({
  width: 24,
  minWidth: 24,
  height: 24,
  backgroundColor: theme.palette.primary.main,
  WebkitMask: src !== null && src !== undefined ? `url(${src}) 0 0 no-repeat` : '',
  WebkitMaskSize: 'contain',
}));

export const Divider = styled(DividerMui)(() => ({
  height: 24,
}));

Divider.defaultProps = {
  orientation: 'vertical',
};
