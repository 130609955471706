// @flow
import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import type { EnhancedUser as EnhancedUserType } from 'domain/organizationUser/types.js.flow';

import { createOrganizationUser } from 'domain/organizationUser/actions';
import { sagaDataLoadingSelector } from 'domain/env/envSelector';

import CompanyUsersList from 'pages/configurations/company/pages/users/List/UsersList';
import DialogManageUser from 'pages/common/Dialog/DialogManageUser';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import CircularProgressWithBackdrop from 'components/mui/CircularProgressWithBackdrop';

const mapStateToProps = (state) => ({
  isSagaDataLoading: sagaDataLoadingSelector(state),
});

const CompanyUsers = () => {
  const dispatch = useDispatch();

  const { isSagaDataLoading } = useSelector(mapStateToProps);

  const [isOpen, setIsOpen] = useState(false);

  const openDialog = useCallback(() => setIsOpen(true), []);

  const closeDialog = useCallback(() => {
    setIsOpen(false);
  }, []);

  const handleManageUser = useCallback(
    (data: EnhancedUserType): Promise<void> =>
      new Promise((resolve, reject) => {
        dispatch(createOrganizationUser({ data, resolve, reject }));
      }).then(() => {
        setIsOpen(false);
      }),
    [dispatch],
  );

  return (
    <>
      <CircularProgressWithBackdrop isOpen={isSagaDataLoading} />
      <Box display="flex" mb={2}>
        <Typography variant="subtitle1">
          <FormattedMessage id="configurations.org_users.title" defaultMessage="Organization Users" />
        </Typography>
        <Button type="button" onClick={openDialog} variant="outlined" startIcon={<AddIcon />} sx={{ ml: 'auto' }}>
          <FormattedMessage id="button.add.user" defaultMessage="Add User" />
        </Button>
      </Box>

      <CompanyUsersList />

      <DialogManageUser open={isOpen} onSubmit={handleManageUser} onCancel={closeDialog} isCompany />
    </>
  );
};

export default CompanyUsers;
