// @flow
import React from 'react';
import { useIntl } from 'react-intl';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

type TEmptyData = {
  openDialog: () => void,
};

const EmptyData: React$StatelessFunctionalComponent<TEmptyData> = ({ openDialog }) => {
  const { formatMessage } = useIntl();
  return (
    <Box textAlign="center" mt="10%">
      <Typography variant="h5">
        {formatMessage({
          id: 'configurations.apiKeys.empty.title',
          defaultMessage: 'No API keys created',
        })}
      </Typography>
      <Typography variant="body1">
        {formatMessage({
          id: 'configurations.apiKeys.empty.text',
          defaultMessage: 'Generate an API key with allowed IP addresses',
        })}
      </Typography>
      <Button type="button" onClick={openDialog} sx={{ mt: 2 }}>
        {formatMessage({
          id: 'button.create.key',
          defaultMessage: 'Create new Key',
        })}
      </Button>
    </Box>
  );
};

export default EmptyData;
