/* @flow */
import React, { memo, useCallback, useState } from 'react';
import { isReadOnlyCell } from 'pages/configurations/company/pages/supplier_defaults/helpers/columnDefinitions';

import CheckboxBase from 'components/mui/Form/Checkbox/CheckboxBase';

interface IProps {
  api: any;
  rowIndex: number;
  column: any;
  value: { name: string };
}

const BooleanCellRenderer = (props: IProps) => {
  const { value, setValue, disabled } = props;
  const isDisabled = disabled || isReadOnlyCell(props);
  const [currentValue, setCurrentValue] = useState(value);

  const handleOnChange = useCallback(
    (event) => {
      setCurrentValue(event.target.checked);
      setValue(event.target.checked);
    },
    [setValue],
  );

  return <CheckboxBase checked={currentValue} onChange={handleOnChange} disabled={isDisabled} />;
};

export default memo(BooleanCellRenderer);
