// @flow
import React, { useCallback } from 'react';
import { type RecordOf } from 'immutable';
import { useDispatch, useSelector } from 'react-redux';
import { userFeaturesSelector } from 'domain/env/envSelector';
import { updateUserFeaturesAction } from 'domain/env/envActions';
import { type TUserFeatures } from 'domain/env/types.js.flow';

import ProfileNotificationsForm from 'pages/profiles/company/pages/preferences/form';

const ProfilePreferencesTab = () => {
  const dispatch = useDispatch();
  const userFeatures: RecordOf<TUserFeatures> = useSelector(userFeaturesSelector);

  const handleChange = useCallback(
    (value, name) =>
      new Promise((resolve, reject) => {
        const values = {
          [name]: value,
        };

        dispatch(updateUserFeaturesAction({ values, resolve, reject }));
      }),
    [dispatch],
  );

  return <ProfileNotificationsForm handleChange={handleChange} initialValues={userFeatures} />;
};

export default ProfilePreferencesTab;
