// @flow
import * as React from 'react';
import { withStyles } from '@mui/styles';
import cx from 'classnames';
import type { List } from 'immutable';
import { type OptionsItemType, type RecordCellSetType } from 'domain/journal/helper';
import Options, { type OptionProps } from './options';
import sheet from './sheet';
import {
  valueNormalize as defaultValueNormalize,
  filterList as defaultFilterList,
  type ValueNormalize,
} from './helpers';
import { keyCodeArrow } from '../helpers';
import { executeFunc } from 'lib/helpers';
import type { CellStyleType } from '../styleHelper';
import elements from 'components/elements';
import AutoTextArea from '../AutoTextArea';

type LikeEvent = {
  target: {
    value: string | number,
  },
};

type LikeEventKey = {
  keyCode: number,
};

type Props = {
  classes: {
    [key: string]: string,
  },
  children: React.ComponentType<OptionProps>,
  disabled?: boolean,
  id?: string,
  placeholder?: string,
  optionList: List<OptionsItemType>,
  filterList: (l: List<OptionsItemType>, t: string) => List<OptionsItemType>,
  valueNormalize: ValueNormalize,
  blurTimeOut: number,
  input: {
    onChange: (e: LikeEvent, cb?: () => void) => void,
    onKeyDown: (e: LikeEventKey) => void,
    value: RecordCellSetType,
    onFocus?: () => void,
    onBlur?: () => void,
  },
  maxLength: number,
  onComplete?: () => void,
  name: { _cell: string, name: string },
  getRefs: (el: ?HTMLInputElement) => void,
  rtl: boolean,
  style: CellStyleType,
  type: string,
};

type State = {
  show: boolean,
  index: ?number,
  isDirectionUP: ?boolean,
};

const ONE_SYMBOL_WIDTH = 7.5;

class HintInput extends React.Component<Props, State> {
  static defaultProps = {
    valueNormalize: defaultValueNormalize,
    filterList: defaultFilterList,
    blurTimeOut: 100,
  };

  static defIndex(): number {
    // this would normally return first selected element
    // currently we dont select first option to allow partially entered values
    return -1;
  }

  state = {
    show: true,
    index: -1,
    isDirectionUP: undefined,
    style: {},
  };

  componentDidUpdate(prevProps: Props): * {
    if (this.props.input.value && this.props.input.value !== prevProps.input.value) {
      this.setActive(-1);
    }
  }

  onBlur = () => {
    this.timeout = setTimeout(() => {
      this.handleOpen(false, this.props.input.onBlur);
    }, this.props.blurTimeOut);
    this.focused = false;
  };

  onFocus = () => {
    this.focused = true;
    if (this.timeout) {
      clearTimeout(this.timeout);
      this.timeout = undefined;
    }
  };

  get value(): string {
    return this.props.input.value.value || '';
  }

  get optionList(): List<OptionsItemType> {
    const list = this.getOptionList(this.props.input.value.value);
    if (this.state.isDirectionUP) {
      return list.reverse();
    }
    return list;
  }

  get isOpen(): boolean {
    const { show } = this.state;
    const { length } = this.value;
    const maxLengthOptions = 8;
    const options = this.optionList;
    return Boolean(length > 1 && maxLengthOptions >= options.size && show);
  }

  get position() {
    const wh = () => window.innerHeight;
    if (this.input && window) {
      const { bottom } = this.input.getBoundingClientRect();
      const maxHeightBottom = wh() - bottom;
      return maxHeightBottom;
    }
    return wh();
  }

  get index(): number {
    const { index } = this.state;
    if (typeof index === 'number' && this.optionList.size > 0 && this.optionList.get(index)) return index;
    return this.constructor.defIndex();
  }

  setActive = (index: number) => this.setState({ index });

  getOptionList = (term: ?string) => {
    const { optionList, filterList } = this.props;
    return filterList(optionList, term || '');
  };

  getMinWidth = () => {
    const optionList = this.getOptionList();
    const widthByData = optionList.reduce((res, item) => {
      const currentWidth = item.display.length * ONE_SYMBOL_WIDTH;
      return Math.max(res, currentWidth);
    }, 0);
    return this.input ? Math.max(this.input.offsetWidth, widthByData) : widthByData;
  };

  setDirection = (isUp: boolean) => {
    this.setState({
      isDirectionUP: isUp,
      index: this.constructor.defIndex(),
    });
  };

  doSelect = (index: number, cb?: () => void) => {
    if (index !== -1) {
      const item = this.optionList.get(index);
      if (item) {
        this.props.input.onChange({ target: { value: item.id } }, cb);
      }
    } else {
      this.props.input.onKeyDown({ keyCode: 13 });
    }
  };

  keyDown = (e: SyntheticKeyboardEvent<HTMLInputElement>) => {
    const { index } = this.state;
    const currentIndex = index === null ? -1 : index;

    if (e.keyCode === 9) {
      e.preventDefault();
      this.props.input.onKeyDown({ keyCode: 13 });
    }

    if (keyCodeArrow.has(e.keyCode)) {
      this.props.input.onKeyDown({ keyCode: e.keyCode });
    }
    if (e.keyCode === 38) {
      this.setActive(((currentIndex || this.optionList.size) - 1) % this.optionList.size);
    }
    if (e.keyCode === 40) {
      this.setActive((currentIndex + 1) % this.optionList.size);
    }
    if (e.keyCode === 27) {
      this.props.input.onKeyDown({ keyCode: 27 });
    }
    if (e.keyCode === 13) {
      // use dropdown selected value only if we have dropdown visible,
      // e.g 2 or more symbols are entered
      if (this.props.input.value.value && this.props.input.value.value.length > 1) {
        this.doSelect(this.index, () => this.props.input.onKeyDown({ keyCode: 13 }));
      } else {
        this.props.input.onKeyDown({ keyCode: 13 });
      }
    }
    if (e.keyCode === 8) {
      if (this.slected) {
        this.props.input.onChange({ target: { value: '' } });
        this.slected = false;
        this.handleOpen(true);
      }
    }
  };

  refsProxy = (el: ?HTMLInputElement) => {
    this.input = el;
    this.initOptionListPosition();
    this.props.getRefs(el);
  };

  handleOpen = (state?: boolean, cb?: () => void) => {
    this.setState(
      {
        show: typeof state === 'undefined' ? !this.state.show : state,
      },
      () => {
        executeFunc(cb);
      },
    );
  };

  initOptionListPosition = () => {
    if (this.input) {
      const { left } = this.input.getBoundingClientRect();
      const width = this.getMinWidth();
      const overlap = width + left + 5 - window.innerWidth;
      const currentLeft = overlap > 0 ? left - overlap : left;
      const style = { left: currentLeft };

      this.setState({ style });
    }
  };

  slected: boolean;

  input: ?HTMLInputElement;

  wrapper: ?HTMLInputElement;

  focused: boolean;

  timeout: ?TimeoutID;

  isDirectionUP: ?boolean;

  listRef: any = React.createRef();

  render() {
    const { classes, name, maxLength, style, id, placeholder, disabled, input, children, type } = this.props;

    const inputProps = {
      style,
      id,
      'aria-autocomplete': 'list',
      autoComplete: 'no',
      className: classes.field,
      placeholder,
      name: name._cell,
      value: this.value,
      disabled,
      onKeyDown: this.keyDown,
      onChange: input.onChange,
      ...(!['float', 'number'].includes(type) && { maxLength }),
      onDoubleClick: () => this.handleOpen(true),
      'data-element': elements.je.hintinput.field,
      'data-element-id': `${name._cell}-${name.name}`,
    };

    return (
      <div
        className={classes.wrapper}
        tabIndex="0" // eslint-disable-line jsx-a11y/no-noninteractive-tabindex
        onFocus={this.onFocus}
        onBlur={this.onBlur}
        role="combobox"
        aria-expanded={this.isOpen}
        aria-controls={id}
        data-element={elements.je.hintinput.container}
        data-element-id={name.name}
      >
        <div className={cx(classes.fieldWrapper, { [classes.open]: this.isOpen })}>
          {type === 'string_auto_generated' ? (
            <AutoTextArea {...inputProps} refsProxy={this.refsProxy} />
          ) : (
            <input {...inputProps} ref={this.refsProxy} />
          )}
        </div>
        {this.isOpen ? (
          <Options
            inputEl={this.input}
            minWidth={this.getMinWidth()}
            maxWidth={390}
            rtl={this.props.rtl}
            optionList={this.optionList}
            doSelect={(e: number) => this.doSelect(e, this.props.onComplete)}
            valueNormalize={this.props.valueNormalize}
            index={this.state.index}
            term={this.value}
            setDirection={this.setDirection}
            pageItemsCount={10}
            itemHeight={30}
            setListRef={this.listRef}
            addNewItem={null}
            setActive={this.setActive}
            style={this.state.style}
            isDisabledScroll={type === 'string_auto_generated'}
          />
        ) : null}

        {this.optionList.size && this.value.length > 1 ? (
          <div // eslint-disable-line
            role="button"
            className={cx(classes.btnShield, classes.btnRotate)}
            onClick={() => this.handleOpen()}
          />
        ) : null}
      </div>
    );
  }
}

HintInput.displayName = 'HintInput';

export { HintInput as Hint };

export default withStyles(sheet)(HintInput);
