/* @flow */
import React, { type ElementConfig } from 'react';
import { compose } from 'redux';
import { withStyles } from '@mui/styles';
import { FormattedMessage } from 'react-intl';
import Select, { components } from 'react-select';
import { type RecordOf } from 'immutable';
import elements from 'components/elements';
import DropdownIndicator from './ddIndicator';
import { keyCodeArrow } from '../helpers';
import { matchCell } from 'domain/journal/helper';
import styles from './sheet';
import * as C from './constants';
import Stub from './stub';
import type { ReconcileCellValueType, ReconciliationCellType } from 'domain/journal/types.js.flow';
import type { ThemesParamsType } from 'styles/dokkaTheme/types.js.flow';

// type ValueType = 'Reconciled'
//   | 'Match'
//   | 'NoMatchCanCrate'
//   | 'NoMatchCantCrate';

type OptionsType = {
  id: string,
  title: string,
};

type Props = {
  classes: {
    [key: string]: string,
  },
  data: RecordOf<ReconciliationCellType>,
  onChange: (
    cell: string,
    cellSet: $PropertyType<RecordOf<ReconciliationCellType>, 'cellSet'>,
    ignoreSelectedRows: boolean,
  ) => void,
  onShowDetails: ({| row: number, lineState: ReconcileCellValueType |}) => void,
  onKeyDown: (e: SyntheticKeyboardEvent<HTMLInputElement>) => void,
  onFocus: () => void,
  onBlur: () => void,
  getRefs: (el: ?HTMLElement) => void,
  amount: ?number,
  theme: ThemesParamsType,
};

// duplicates react-select basic component and
// adds 'data-element' attr to the dom
const OptionComponent = (props: ElementConfig<typeof components.Option>) => (
  <components.Option
    {...props}
    innerProps={{
      ...props.innerProps,
      'data-element': `reconcile-action-${props.data.id}`,
    }}
  />
);

const sheet = {
  container: {},
  description: {
    color: '#BDBDBD',
    fontSize: 14,
    margin: [0, 'auto'],
    textAlign: 'center',
  },
  warpper: {
    position: 'relative',
    width: 100,
    height: 22,
    margin: [1, 'auto', 1, 10],
  },
  action: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: 'calc(100% - 18px)',
    height: '100%',
    fontSize: 12,
    color: '#fff',
    background: 'transparent',
    border: '0 none',
    cursor: 'pointer',
  },
  '@keyframes rotate': {
    from: {
      transform: 'rotate(0deg)',
    },
    to: {
      transform: 'rotate(360deg)',
    },
  },
  stub: {
    borderRadius: 2,
    width: '100%',
    fontSize: 12,
    backgroundColor: '#8C9BA5',
    color: '#fff',
    padding: '0 8px',
    height: 22,
    lineHeight: '22px',
  },
  loading: {
    textAlign: 'center',
    '&:before': {
      content: '""',
      display: 'inline-block',
      width: 12,
      height: 12,
      border: '2px solid #fff',
      borderLeftColor: 'transparent',
      borderRadius: '50%',
      animation: '$rotate 0.8s infinite',
    },
  },
};

// eslint-disable-next-line max-len
function createOptions(
  options: $ReadOnlyArray<{ id: string, type: $ReadOnlyArray<string>, title: string }>,
  action: {},
) {
  return options.map((e) => ({ ...e, onAction: action[e.id] }));
}

class ReconciliationCell extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
    this.optionList = createOptions(C.OPTIONS, {
      commit: () => this.onUpdate(C.COMMIT),
      create: () => this.onUpdate(C.COMMIT),
      ignore: () => this.onUpdate(C.IGNORE),
      undo: () => this.onUpdate(C.UNDO),
      detail: () => this.onShowDetails(),
      search: () => this.onShowDetails(),
    });
  }

  onUpdate = (value) => {
    const { data } = this.props;
    // value set as action COMMIT is not of type that comes from backend.
    // discuss this
    if (data.cellSet.value !== value) {
      this.props.onChange(data._cell, data.cellSet.set('value', value), true);
    }
  };

  onShowDetails = () => {
    const {
      onShowDetails,
      data: {
        cellSet: { value: lineState },
      },
    } = this.props;
    onShowDetails({ row: this.currentRow, lineState });
  };

  get currentRow(): number {
    const {
      data: { _cell },
    } = this.props;
    // eslint-disable-next-line no-unused-vars
    const [col, row] = matchCell(_cell);
    return row;
  }

  getLabel = ({ title }: OptionsType) => (
    <FormattedMessage id={`reconciliation.${title}`} defaultMessage={title}>
      {(t: string) => t}
    </FormattedMessage>
  );

  getValue = ({ id }: OptionsType): string => id;

  get value() {
    return this.options[0];
  }

  get placeholder(): string | React$Node {
    const { classes } = this.props;
    const value = this.dataValue;
    if (value === C.IGNORE || value === C.COMMIT || value === C.UNDO) {
      return <div className={classes.loading} />;
    }
    return (
      <FormattedMessage id={`reconciliation.${value}`} defaultMessage={value}>
        {(t: string) => t}
      </FormattedMessage>
    );
  }

  get dataValue() {
    const { data } = this.props;
    return data.getIn(['cellSet', 'value']);
  }

  get options() {
    return this.getOptions(this.dataValue);
  }

  get color() {
    if (this.dataValue === C.CAN_CREATE) return '#456595';
    if (this.dataValue === C.CANT_CREATE) return '#8C9BA5';
    if (this.dataValue === C.RECONCILED) return '#8C9BA5';
    if (this.dataValue === C.IGNORED) return '#8C9BA5';
    if (this.dataValue === C.IGNORE) return '#7597D3';
    if (this.dataValue === C.COMMIT) return '#7597D3';
    if (this.dataValue === C.MATCH) return '#139D29';
    return 'rgb(117, 151, 211)';
  }

  get celleStyle() {
    return this.props.data.style.toJS();
  }

  getOptions = (type) => this.optionList.filter((f) => f.type.includes(type));

  isOptionDisabled = ({ disabled }) => {
    const { amount } = this.props;
    if (typeof disabled === 'function') {
      return disabled({ amount, value: this.dataValue });
    }
    return false;
  };

  makeAction = ({ onAction }) => {
    if (typeof onAction === 'function') {
      onAction();
    }
  };

  handleAction = () => {
    this.makeAction(this.value);
  };

  handleChange = ({ onAction }) => {
    this.makeAction({ onAction });
  };

  handleKeyDown = (e: SyntheticKeyboardEvent<HTMLInputElement>) => {
    if (!this.menuStatus && keyCodeArrow.has(e.keyCode)) {
      this.props.onKeyDown(e);
    } else {
      switch (e.keyCode) {
        case 37:
          break;
        case 38:
          break;
        case 39:
          break;
        case 40:
          break;
        case 13:
          break;
        case 27:
          break;
        default:
          break;
      }
    }
  };

  handleMenu = (state: boolean) => () => {
    this.menuStatus = state;
  };

  optionList: $ReadOnlyArray<*>;
  menuStatus: boolean = false;

  render() {
    const {
      classes,
      data: { _cell, type },
      onFocus,
      onBlur,
      getRefs,
      theme,
    } = this.props;
    if (this.dataValue === C.NO_BANK_ACCOUNT) {
      return <Stub style={this.celleStyle} classes={classes} value={C.NO_BANK_ACCOUNT} />;
    }
    return (
      <div
        className={classes.container}
        tabIndex={0} // eslint-disable-line jsx-a11y/no-noninteractive-tabindex
        onFocus={onFocus}
        style={this.celleStyle}
      >
        {this.dataValue ? (
          <div
            className={classes.warpper}
            data-element-value={this.dataValue}
            data-element-id={`${_cell}-${type}`}
            data-element={elements.je.reconcileCell.container}
          >
            <Select
              styles={styles(this.color, theme)}
              options={this.options}
              value={null}
              components={{ DropdownIndicator, Option: OptionComponent }}
              getOptionLabel={this.getLabel}
              getOptionValue={this.getValue}
              isSearchable={false}
              onChange={this.handleChange}
              ref={getRefs}
              onFocus={onFocus}
              onBlur={onBlur}
              onKeyDown={this.handleKeyDown}
              onMenuOpen={this.handleMenu(true)}
              onMenuClose={this.handleMenu(false)}
              isOptionDisabled={this.isOptionDisabled}
              placeholder={this.placeholder}
            />
            {this.dataValue === C.MATCH || this.dataValue === C.CAN_CREATE ? (
              <button type="button" className={classes.action} onClick={this.handleAction} />
            ) : null}
          </div>
        ) : null}
      </div>
    );
  }
}

export default compose(withStyles(sheet, { withTheme: true }))(ReconciliationCell);
