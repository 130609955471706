// @flow
import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getUiWorkspaceIsActiveContextMenuSelector } from 'domain/ui';

import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import { IS_OPEN_CONTEXT_MENU_CLASS } from 'components/AgGrid/hooks/agGridThemeClasses';

type TProps = any;

const ContextCellRenderer = ({ data, colDef, context, eGridCell }: TProps) => {
  const isActiveContextMenu = useSelector(getUiWorkspaceIsActiveContextMenuSelector);
  const { rowDocumentData } = data;
  const {
    components: {
      [colDef.cellRenderer]: { oncClickContextMenu },
    },
  } = context;

  const handleClick = useCallback(
    (e) => {
      oncClickContextMenu(e, rowDocumentData);
      eGridCell.classList.add(IS_OPEN_CONTEXT_MENU_CLASS);
    },
    [rowDocumentData, oncClickContextMenu, eGridCell],
  );

  useEffect(() => {
    if (!isActiveContextMenu) {
      eGridCell.classList.remove(IS_OPEN_CONTEXT_MENU_CLASS);
    }
  }, [isActiveContextMenu, eGridCell]);

  return (
    <Stack justifyContent="center" alignItems="center" flex={1} height="100%">
      <IconButton onClick={handleClick} sx={{ fontSize: '22px' }}>
        <MoreHorizIcon fontSize="inherit" />
      </IconButton>
    </Stack>
  );
};

export default ContextCellRenderer;
