// @flow
import React, { useState, useRef, useCallback, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { customArrayFlat } from 'lib/helpers';

import { documentGetTagsSuggestions } from 'domain/documents';
import { chatAllUsersSelector } from 'domain/chat/chatSelector';

import List from './components/List';

import ClickAwayListener from '@mui/material/ClickAwayListener';
import Box from '@mui/material/Box';

type Props = {
  InputComponent: React.Element<*>,
  requirePrefix?: string,
  prefixOutputWithHashtag: boolean,
  inputValue: string,
  onSelect: (suggestion: string) => void,
  onInputChange: (value: string) => void,
  maxWidth?: string,
  inputComponentProps?: Object,
  renderItem: () => React$StatelessFunctionalComponent<any>,
  ListProps: { [key: string]: any },
  PopperProps: { [key: string]: any },
};

const TagSuggestions: React$StatelessFunctionalComponent<Props> = ({
  InputComponent,
  requirePrefix,
  prefixOutputWithHashtag,
  inputValue,
  onSelect,
  onInputChange,
  maxWidth,
  inputComponentProps,
  renderItem,
  ListProps = { disablePadding: true },
  PopperProps = {},
}) => {
  const dispatch = useDispatch();
  const anchorRef = useRef(null);

  const [open, setOpen] = useState(false);
  const [suggestion, setSuggestion] = useState([]);
  const [userSuggestions, setUserSuggestions] = useState([]);
  const [tagSuggestions, setTagSuggestions] = useState([]);
  const [activeSuggestion, setActiveSuggestion] = useState(null);

  const stringToTag = useCallback(
    (tag: string): string => (prefixOutputWithHashtag ? `#${tag}` : tag),
    [prefixOutputWithHashtag],
  );

  const users = useSelector(chatAllUsersSelector);

  const handleClose = () => setOpen(false);

  const memoUsers = useMemo(() => users.reduce((res, user) => ({ ...res, [user.userId]: user }), {}), [users]);

  const isAllowedSuggestion = useMemo(
    () => (requirePrefix ? inputValue.startsWith(requirePrefix) : true),
    [inputValue, requirePrefix],
  );

  const separateSuggestions = useCallback(
    (suggestions) =>
      suggestions.reduce(
        ([users, tags], suggestion) =>
          memoUsers[suggestion] ? [[...users, suggestion], tags] : [users, [...tags, suggestion]],
        [[], []],
      ),
    [memoUsers],
  );

  const loadSuggestions = useCallback(
    (value: string) => {
      if (isAllowedSuggestion) {
        const promise = new Promise((resolve, reject) =>
          dispatch(documentGetTagsSuggestions({ prefix: value, resolve, reject })),
        );
        promise.then((response) => {
          const res = response.map((tag) => Object.keys(tag).join(''));
          const suggestions = customArrayFlat(separateSuggestions(res));

          const active = suggestions.length ? suggestions[0] : null;
          setSuggestion(suggestions);
          setActiveSuggestion(active);
        });
        setOpen(true);
      } else {
        setOpen(false);
      }
    },
    [dispatch, separateSuggestions, isAllowedSuggestion],
  );

  const clearSuggestions = () => {
    setActiveSuggestion(null);
    setSuggestion([]);
  };

  const onChange = useCallback(
    (value: string) => {
      onInputChange(value);
      if (value) {
        loadSuggestions(value);
      } else {
        clearSuggestions();
      }
    },
    [loadSuggestions, onInputChange],
  );

  const onSelectSuggestion = useCallback(
    (tag: string) => {
      onSelect(tag);
      setOpen(false);
      clearSuggestions();
    },
    [onSelect],
  );

  const onPreselectPrev = useCallback(() => {
    if (suggestion.length) {
      const currentIndex = suggestion.indexOf(activeSuggestion);
      const prevIndex = currentIndex <= 0 ? suggestion.length - 1 : currentIndex - 1;
      setActiveSuggestion(suggestion[prevIndex]);
    }
  }, [suggestion, activeSuggestion]);

  const onPreselectNext = useCallback(() => {
    if (suggestion.length) {
      const currentIndex = suggestion.indexOf(activeSuggestion);
      const nextIndex = currentIndex >= suggestion.length - 1 ? 0 : currentIndex + 1;
      setActiveSuggestion(suggestion[nextIndex]);
    }
  }, [suggestion, activeSuggestion]);

  const onConfirm = useCallback(() => {
    const confirmValue = activeSuggestion ? stringToTag(activeSuggestion) : inputValue;
    onSelectSuggestion(confirmValue);
  }, [activeSuggestion, onSelectSuggestion, stringToTag, inputValue]);

  const onInputKeyDown = useCallback(
    (e: KeyboardEvent) => {
      const { keyCode } = e;
      if (keyCode === 13) {
        onConfirm();
      }
      if (keyCode === 40) {
        onPreselectNext();
      }

      if (keyCode === 38) {
        onPreselectPrev();
      }
    },
    [onConfirm, onPreselectNext, onPreselectPrev],
  );

  useEffect(() => {
    const [usersList, tagsList] = separateSuggestions(suggestion);
    setUserSuggestions(usersList);
    setTagSuggestions(tagsList);
  }, [suggestion, separateSuggestions]);

  const openSuggestions = () => {
    setOpen(true);
  };

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <Box flexGrow={1} maxWidth={maxWidth}>
        <InputComponent
          value={inputValue}
          onChange={onChange}
          ref={anchorRef}
          onKeyDown={onInputKeyDown}
          onClick={openSuggestions}
          onFocus={openSuggestions}
          {...inputComponentProps}
        />
        <List
          userSuggestions={userSuggestions}
          tagSuggestions={tagSuggestions}
          handleMenuItemClick={onConfirm}
          anchorRef={anchorRef}
          open={open}
          users={memoUsers}
          selected={activeSuggestion}
          setSelected={setActiveSuggestion}
          renderItem={renderItem}
          ListProps={ListProps}
          PopperProps={PopperProps}
        />
      </Box>
    </ClickAwayListener>
  );
};

export default TagSuggestions;
