// @flow
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { healthCheckAction } from 'domain/env/envActions';
import { FormattedMessage } from 'react-intl';

import LayoutServicePage from 'components/mui/Layouts/LayoutServicePage';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { styled } from '@mui/material/styles';

import bgImage from './assets/bgImage.svg';

const Img = styled('img')(({ theme }) => ({
  marginBottom: theme.spacing(4),
}));

const Maintenance = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const timer = setInterval(() => {
      dispatch(healthCheckAction());
    }, 10000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <LayoutServicePage>
      <Stack flex={1} component="main" justifyContent="center" alignItems="center">
        <Img src={bgImage} alt="background image" />
        <Typography variant="h5" sx={{ mb: 0.5 }}>
          <FormattedMessage id="pages.maintenance.title" defaultMessage="Service under maintenance" />
        </Typography>
        <Typography variant="body1" color="text.secondary">
          <FormattedMessage
            id="pages.maintenance.subTitle"
            defaultMessage="We’ll be back shortly. Please try again later."
          />
        </Typography>
      </Stack>
    </LayoutServicePage>
  );
};

export default Maintenance;
