// @flow
import ui, { resetButton } from 'lib/ui';
import warning from './warning.svg';
import counterWarning from './counterWarning.svg';

export default (theme) => ({
  container: {
    minHeight: 500, // to add scroll once we reach this height
    left: 0,
    right: 0,
    bottom: 0,
    top: 0,
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    position: 'absolute',
    padding: [0, 50],
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  loader: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    zIndex: 10, // close has 11
    backdropFilter: 'blur(3px)',
  },
  actionInProgress: {
    // textAlign: 'center',
    '&:before': {
      content: '""',
      display: 'inline-block',
      width: 8,
      height: 8,
      border: '2px solid #fff',
      borderLeftColor: 'transparent',
      borderRadius: '50%',
      animation: 'rotate 0.8s infinite',
    },
    '&$actionUndoButton:before': {
      borderColor: theme.colors.primaryLight,
      borderLeftColor: 'transparent',
    },
  },
  tableHeader: {
    flex: '0',
    padding: [14, 0, 10, 0],
    background: '#fff',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: '1px solid #DEE2E6',
    marginBottom: 7,
    marginLeft: 34,
  },
  headerLeft: {
    flex: '1 70%',
    overflow: 'hidden',
  },
  headerRight: {
    flex: '1 30%',
  },
  tableWrapper: {
    overflowY: 'auto',
    overflowX: 'hidden', // or tooltips will force horizontal scroll
    padding: [0, 5, 0, 0],
    marginTop: 10,
    flex: '1 90%',
    background: '#fff',
    minHeight: 150,
  },
  noResults: {
    minHeight: 150, // to show empty results text without scroll
  },
  selectedSearchResultsWrapper: {
    overflowY: 'auto',
    overflowX: 'hidden',
    padding: [0, 5, 0, 0],
    marginTop: 10,
    flex: '1 50%',
    background: '#fff',
    minHeight: 100,
  },
  searchResultsTable: {
    paddingBottom: 40,
  },
  scrollAlwaysVisible: {
    '&::-webkit-scrollbar': {
      appearance: 'none',
      '&:horizontal': {
        height: 7,
      },
      '&:vertical': {
        width: 7,
      },
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: 4,
      backgroundColor: 'rgba(0,0,0,.5)',
      boxShadow: '0 0 1px rgba(255,255,255,.5)',
    },
  },
  title: {
    margin: [0, 0, 12, 0],
  },
  withWarning: {},
  addRowButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginLeft: 30,
    height: 46,
    zIndex: 15,
    position: 'sticky',
    bottom: 0,
    backgroundColor: '#FFF',
    '&$withWarning': {
      justifyContent: 'space-between',
    },
    boxShadow: '0px -5px 4px -2px rgba(0,0,0,0.08)',
  },
  addRowButton: {
    ...resetButton,
    fontSize: '13px',
    fontWeight: 'bold',
    padding: [8, 15],
    marginTop: 0,
    minWidth: 140,
    color: theme.colors.primaryLight,
    border: `2px solid ${theme.colors.primaryLight}`,
    borderRadius: 4,
    '&[disabled]': {
      color: theme.colors.greyDarkest,
      borderColor: theme.colors.greyDarkest,
    },
  },
  resultsCounterNotification: {
    position: 'relative',
    paddingLeft: 30,
    color: '#171A1C',
    fontSize: '12px',
    '&:before': {
      content: '""',
      display: 'block',
      height: 24,
      width: 24,
      position: 'absolute',
      left: 0,
      top: '50%',
      transform: 'translateY(-50%)',
      background: `url(${counterWarning}) center center no-repeat`,
    },
  },
  summaryHeader: {
    color: theme.colors.greyBlack,
    fontSize: '13px',
    fontWeight: 'bold',
    paddingLeft: 30,
    marginBottom: 10,
  },
  summaryDetails: {
    display: 'flex',
    justifyContent: 'flex-end',
    '& table': {
      color: '#171A1C',
      fontSize: '12px',
      '& tr:first-child td': {
        fontSize: 20,
        paddingBottom: 5,
      },
      '& td': {
        padding: [5, 12],
        position: 'relative',
        '&:last-child': {
          fontWeight: 'normal',
          textAlign: 'left',
          '&:before': {
            content: '"$"',
            display: 'block',
            position: 'absolute',
            left: 1,
            top: 'auto',
          },
        },
        '&:first-child': {
          textAlign: 'right',
        },
      },
    },
  },
  footer: {
    position: 'sticky',
    bottom: 0,
    flex: '0',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: [5, 0, 20, 0],
    backgroundColor: '#fff',
  },
  transactionError: {
    flex: '1 1 75%',
    position: 'relative',
    fontSize: '14px',
    color: '#BC5353',
    padding: [0, 50, 0, 15],
    marginLeft: 50,
    '&:before': {
      content: '""',
      display: 'block',
      height: 16,
      width: 16,
      position: 'absolute',
      left: -15,
      top: '50%',
      transform: 'translateY(-50%)',
      background: `url(${warning}) center center no-repeat`,
    },
  },
  actionButton: {
    ...ui(theme).btn,
    flex: '0 1 140px',
  },
  actionUndoButton: {
    ...ui(theme).btnBordered,
    flex: '0 1 190px',
    marginRight: 10,
  },
  emptyResults: {
    fontSize: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    padding: [20, 40, 10, 40],
    lineHeight: '23px',
    color: '#8C9BA5',
    whiteSpace: 'pre-line',
  },
  counter: {
    textAlign: 'right',
    fontSize: '12px',
    margin: [5, 0],
  },
});
