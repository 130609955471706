// @flow
import ui from 'lib/ui';

export default (theme) => ({
  create: {},
  edit: {},
  mainTitle: {
    fontSize: 24,
  },
  container: {
    height: '80vh',
    width: '75vw',
    padding: [0, 50],
    minHeight: 500,
    '& h1': {
      padding: [20, 0],
    },
    '&$create': {
      '& $table': {
        order: 3,
        flex: '0 0 110px',
      },
      '& $message': {
        order: 1,
        flex: '1 auto',
      },
    },
    '&$edit': {
      '& $table': {
        order: 1,
      },
      '& $message': {
        order: 2,
      },
    },
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
  },
  transactionUpdate: {},
  table: {
    order: 2,
    flex: '1 1 60%',
    // overflowY: 'auto',
    marginBottom: 20,
    '&$transactionUpdate': {
      flex: 0,
      overflowY: 'visible',
    },
  },
  message: {
    order: 1,
    flex: [0, 0, '100px'],
    minHeight: '100px',
    '&$transactionUpdate': {
      flex: 1,
      paddingBottom: 25,
      paddingTop: 10,
    },
  },
  footer: {
    order: 3,
    minHeight: 60,
    flex: 0,
    bottom: 0,
    display: 'flex',
    padding: '5px 0 50px 0',
    position: 'sticky',
    alignItems: 'center',
    justifyContent: 'flex-end',
    backgroundColor: '#fff',
  },
  actionButton: {
    ...ui(theme).btn,
    flex: '0 1 100px',
    marginLeft: 20,
    '&[disabled]': {
      backgroundColor: '#5A6872',
    },
  },
  cancelButton: {
    ...ui(theme).btnBordered,
    flex: '0 1 100px',
  },
  messageList: {
    overflowY: 'auto',
  },
  messageInput: {
    position: 'sticky',
    bottom: 0,
  },
  actionInProgress: {
    '&:before': {
      content: '""',
      display: 'inline-block',
      width: 8,
      height: 8,
      border: '2px solid #fff',
      borderLeftColor: 'transparent',
      borderRadius: '50%',
      animation: 'rotate 0.8s infinite',
    },
    '&$actionUndoButton:before': {
      borderColor: theme.colors.primaryLight,
      borderLeftColor: 'transparent',
    },
  },
  actionUndoButton: {},
  messageTitle: {
    fontSize: 13,
    padding: [0, 10],
    marginBottom: 4,
    height: 28,
    lineHeight: '28px',
    color: '#fff',
    textTransform: 'uppercase',
    background: '#8C9BA5',
    borderRadius: 4,
    fontWeight: 600,
  },
  icon: {
    position: 'relative',
    fontWeight: 'bold',
    '&:before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      width: 24,
      height: 24,
      left: 0,
      top: 0,
      margin: 'auto',
      bottom: 0,
    },
  },
  title: {
    color: theme.colors.primaryLight,
    fontSize: 16,
    fontWeight: 'bold',
    marginBottom: 10,
    order: 2,
    margin: [10, 0],
  },
  separator: {
    color: '#8C9BA5',
    display: 'flex',
    whiteSpace: 'nowrap',
    alignItems: 'center',
    paddingTop: 8,
    '&:before, &:after': {
      content: '""',
      height: 2,
      width: '100%',
      display: 'block',
      background: 'currentColor',
    },
    '&:before': {
      marginRight: 10,
    },
    '&:after': {
      marginLeft: 10,
    },
  },
});
