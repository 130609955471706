// @flow
import React, { useCallback, useMemo, useState } from 'react';
import { List } from 'immutable';
import { useIntl } from 'react-intl';
import { getCurrentPublishType, updateCurrentPublishType } from 'domain/documents/helpers';
import type { AcceptContext } from 'domain/documents/types.js.flow';
import { useConfirm } from 'material-ui-confirm';
import { PublishTypes, actionsList } from 'pages/document/components/DocumentPublishButton/DropdownButton/config';

import SplitButton from 'pages/document/components/DocumentPublishButton/SplitButton';

type TDropdownButton = {|
  onAccept: (p: AcceptContext) => void,
  isAutoConfirm?: boolean,
  isPending?: boolean,
  userId: string,
  jePublicationWarnings: List<string>,
|};

const DropdownButton: React$StatelessFunctionalComponent<TDropdownButton> = ({
  userId,
  isPending,
  isAutoConfirm,
  jePublicationWarnings,
  onAccept,
}) => {
  const { formatMessage } = useIntl();
  const confirm = useConfirm();
  const lsPublishType = getCurrentPublishType(userId); // publish type from local storage
  const [currentPublishType, setCurrentPublishType] = useState(lsPublishType);
  const warnings = jePublicationWarnings.size ? jePublicationWarnings.join(', ') : null;

  const getConfirmData = useCallback(
    (publishType: string) => {
      const config = actionsList.find((i) => i.key === publishType);

      return config && warnings
        ? {
            ...config,
            confirm: {
              ...config.confirm,
              confirmationPlaceholder: {
                id: 'skip.translate',
                defaultMessage: `${warnings} ${formatMessage(config.confirm.confirmationPlaceholder)}`,
              },
            },
          }
        : config;
    },
    [warnings, formatMessage],
  );

  const savePublishType = useCallback(
    (publishType: string) => {
      if (publishType !== PublishTypes.ForcePublish) {
        setCurrentPublishType(publishType);
        updateCurrentPublishType(userId, publishType);
      }
    },
    [userId],
  );

  const onConfirm = useCallback(
    (currentAcceptData) => {
      if (currentAcceptData) {
        onAccept({ ...currentAcceptData.acceptContext, userConfirmed: true, publishType: currentAcceptData.key });
        savePublishType(currentAcceptData.key);
      }
    },
    [savePublishType, onAccept],
  );

  const onClickOption = useCallback(
    (publishType: string) => {
      const isForce = publishType === PublishTypes.ForcePublish;
      const isAllowAutoConfirm = isAutoConfirm && !warnings && !isForce;
      const currentAcceptData = getConfirmData(publishType);

      if (isAllowAutoConfirm) {
        onConfirm(currentAcceptData);
      } else {
        confirm({
          title: formatMessage(currentAcceptData.confirm.confirmationTitle),
          description: formatMessage(currentAcceptData.confirm.confirmationPlaceholder),
          confirmationText: formatMessage(currentAcceptData.confirm.confirmationConfirmText),
        })
          .then(() => {
            onConfirm(currentAcceptData);
          })
          .catch();
      }
    },
    [warnings, isAutoConfirm, onConfirm, getConfirmData, formatMessage, confirm],
  );

  const options = useMemo(
    () => actionsList.map((item) => ({ ...item, onClick: () => onClickOption(item.key) })),
    [onClickOption],
  );

  return <SplitButton options={options} activeActionKey={currentPublishType} disabled={isPending} color="secondary" />;
};

export default DropdownButton;
