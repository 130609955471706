/* @flow */
import React, { useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams, generatePath } from 'react-router-dom';
import { hrefObjectToString, queryToSearch } from 'domain/router/helpers';
import ROUTES_PATH from 'domain/router/routesPathConfig';
import { isToApproveCategory } from 'domain/categories/helpers';
import { useIntl } from 'react-intl';
import { querySelector } from 'domain/router';
import * as ACL from 'domain/restriction';
import { LayoutCompanyContext } from 'components/mui/Layouts/Company/LayoutCompanyContext';
import CompanyContext from 'pages/company/CompanyContext';
import type { CategoriesList, CategoryRecord } from 'domain/categories/types.js.flow';
import type { DocDropParams } from 'pages/company/type.js.flow';

import { ListItemIcon } from 'components/mui/Layouts/components/Drawer';
import ListItemText from '@mui/material/ListItemText';
import Categories from 'pages/company/CompanyNavigation/Categories';
import {
  DropTarget,
  ListItem,
  ListItemButton,
  ListItemImage,
} from 'pages/company/CompanyNavigation/Categories/Node/StyledComponents';
import RouterNavLink from 'components/mui/Router/RouterNavLink';

import cx from 'classnames';

export type TNavigationItem = {|
  item: CategoryRecord,
  level: number,
  tree: CategoriesList,
  onDocMove: (params: DocDropParams) => void,
  onCategoryClick: (e: MouseEvent, item: CategoryRecord) => void,
|};

const mapStateToProps = (state) => ({
  isGranted: ACL.isGranted(state),
  query: querySelector(state),
});

const Item: React$StatelessFunctionalComponent<TNavigationItem> = ({
  item, // Record, dont try destraction
  level,
  tree,
  onDocMove,
  onCategoryClick,
}) => {
  const { formatMessage } = useIntl();
  const [isDndOver, setDndOver] = useState();
  const { toggle } = useContext(LayoutCompanyContext);
  const { companyType } = useContext(CompanyContext);
  const { isGranted, query } = useSelector(mapStateToProps);
  const { companyId, category1 } = useParams();

  const isPendingApproval = isToApproveCategory(item);
  const isAccountant = isGranted(ACL.IS_ACCOUNT);
  const routePath =
    companyType === 'confidential'
      ? ROUTES_PATH.COMPANY_CONFIDENTIAL_WORKSPACE.absolute
      : ROUTES_PATH.COMPANY_WORKSPACE.absolute;

  const hasChildren = tree.size > 0;
  const categoryLevels = item.parentList.push(item.id);

  const path = generatePath(routePath, {
    companyId,
    // @to-do refactor to pass only present parents
    category1: categoryLevels.get(0),
    category2: categoryLevels.get(1),
    category3: categoryLevels.get(2),
  });

  const linkTo = hrefObjectToString({
    pathname: path,
    search: `${queryToSearch({
      ...query,
      tags: [],
    })}`,
  });

  const allowToMove = String(category1) !== String(item.id) && item.documentMovable && isAccountant;

  const onDndDrop = (dndParams: DocDropParams) => {
    if (allowToMove && item.id) {
      const { id: categoryId } = item;
      onDocMove({ categoryId, ...dndParams });
      setDndOver(false);
    }
  };

  const onDragEnter = () => {
    if (allowToMove) {
      setDndOver(true);
    }
  };

  const onDragLeave = () => {
    setDndOver(false);
  };

  const onClick = (e: MouseEvent) => {
    onCategoryClick(e, item);
  };

  return (
    <>
      <ListItem
        component={DropTarget}
        onDragOver={onDragEnter}
        onDndDrop={onDndDrop}
        onDragEnter={onDragEnter}
        onDragLeave={onDragLeave}
        sx={{ justifyContent: toggle ? 'flex-start' : 'center' }}
      >
        <ListItemButton
          className={cx({
            isDndOver: isDndOver && allowToMove,
          })}
          component={RouterNavLink}
          href={linkTo}
          onClick={onClick}
          level={level}
          isPendingApproval={isPendingApproval && item.count > 0}
          dense
          activeClassName="Mui-selected"
          sx={{ flexGrow: toggle ? 1 : 0 }}
        >
          {item.icon && (
            <ListItemIcon sx={{ mr: toggle ? 1 : 0 }}>
              <ListItemImage src={item.icon} />
            </ListItemIcon>
          )}
          <ListItemText
            disableTypography
            sx={{
              display: toggle ? 'flex' : 'none',
              whiteSpace: 'normal',
            }}
          >
            {formatMessage({
              id: `category.name.${item.nameLangId}`,
              defaultMessage: item.name,
            })}
            &nbsp;({item.count})
          </ListItemText>
        </ListItemButton>
      </ListItem>
      {hasChildren && level <= 2 && toggle && (
        <Categories tree={tree} onCategoryClick={onCategoryClick} onDocMove={onDocMove} />
      )}
    </>
  );
};

export default Item;
