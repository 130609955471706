// @flow
import React, { memo } from 'react';
import Icon from 'react-icon-base';
import { type TIconBase } from './types.js.flow';

const IconClockGlass = (props: TIconBase) => (
  <Icon viewBox="0 0 64 64" {...props}>
    <path
      d="M51.5554 2.84424H12.4443V12.4442V18.8442L16.711 24.8887H47.6443L49.7777 20.2665C50.3703 17.8961 51.8399 12.7287 52.9777 11.022C54.1154 9.31535 52.5036 4.85905 51.5554 2.84424Z"
      fill="#17C896"
    />
    <path
      d="M14.5778 50.1333L11.7334 52.9778V57.6L13.1556 62.2222H48.3556L52.2667 60.8V52.9778L47.289 50.1333L37.3334 48H23.4667L14.5778 50.1333Z"
      fill="#17C896"
    />
    <path
      d="M55.3511 59.6756H53.5349C53.7944 57.9458 53.9674 56.1296 53.9674 54.3134C53.9674 42.6377 48.1728 35.8918 42.3782 31.9999C48.0863 28.108 53.9674 21.2756 53.9674 9.68637C53.9674 7.87015 53.7944 6.05394 53.5349 4.32421H55.3511C56.3025 4.32421 57.0809 3.54583 57.0809 2.59448C57.0809 1.64312 56.3025 0.864746 55.3511 0.864746H8.64843C7.69708 0.864746 6.9187 1.64312 6.9187 2.59448C6.9187 3.54583 7.69708 4.32421 8.64843 4.32421H10.4646C10.2052 6.05394 10.0322 7.87015 10.0322 9.68637C10.0322 21.2756 15.8268 28.108 21.6214 31.9999C15.9133 35.8918 10.0322 42.6377 10.0322 54.3134C10.0322 56.1296 10.2052 57.9458 10.4646 59.6756H8.64843C7.69708 59.6756 6.9187 60.4539 6.9187 61.4053C6.9187 62.3566 7.69708 63.135 8.64843 63.135H55.3511C56.3025 63.135 57.0809 62.3566 57.0809 61.4053C57.0809 60.4539 56.3025 59.6756 55.3511 59.6756ZM13.9241 4.32421H49.989C50.3349 6.05394 50.5079 7.87015 50.5079 9.68637C50.5079 15.7404 48.6917 20.3242 46.1836 23.6972H17.816C15.3079 20.3242 13.4917 15.7404 13.4917 9.68637C13.4052 7.87015 13.5782 6.05394 13.9241 4.32421ZM25.6863 33.5566C26.2052 33.2107 26.5511 32.6918 26.5511 31.9999C26.5511 31.3945 26.2052 30.7891 25.6863 30.4431C24.1295 29.5783 22.4863 28.5404 21.016 27.1566H30.443H30.9331C30.9331 27.1566 31.2097 27.1566 32.1611 27.1566C33.1124 27.1566 33.422 27.1566 33.422 27.1566H33.9025H43.0701C41.5133 28.5404 39.8701 29.5783 38.3998 30.4431C37.8809 30.7891 37.5349 31.308 37.5349 31.9999C37.5349 32.6053 37.8809 33.2107 38.3998 33.5566C43.0701 36.1512 48.5187 40.8215 50.1619 49.1242C38.2268 44.6269 25.5998 44.6269 14.0971 49.1242C15.6538 40.735 21.016 36.0648 25.6863 33.5566ZM13.4052 54.3134C13.4052 53.881 13.4052 53.4485 13.4917 53.0161C25.2538 47.9134 38.3133 47.9134 50.5079 53.0161C50.5079 53.4485 50.5944 53.881 50.5944 54.3134C50.5944 56.1296 50.4214 57.9458 50.0755 59.6756H13.9241C13.5782 57.9458 13.4052 56.1296 13.4052 54.3134Z"
      fill="#456595"
    />
  </Icon>
);

export default memo(IconClockGlass);
