import { useEffect, useRef } from 'react';

/**
 * If you need to skip the first render of functional
 * component (the equivalent of componentDidMount method in class component)
 */

export default function useDidUpdateEffect(cb: () => void, deps: Array<*>) {
  const didMountRef = useRef(false);

  useEffect(() => {
    if (didMountRef.current) cb();
    else didMountRef.current = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  return true;
}
