import mgf from './mgf.svg';
import mgfW from './mgf-w.svg';
import mgfA from './mgf-a.svg';

export default (theme) => ({
  search: {
    maxWidth: 327,
    minWidth: 280,
    textAlign: 'left',
    flex: [1, 0, '327px'],
    position: 'relative',
    marginRight: 10,
  },
  field: {
    color: ({ white }) => (white ? '#5A6872' : '#fff'),
    border: ({ white }) => (white ? `1px solid ${theme.colors.greyLighter}` : '1px solid #5876A3'),
    height: 30,
    width: '100%',
    padding: [0, 15, 0, 27],
    borderRadius: 2,
    outline: 'none',
    transition: '0.3s all',
    fontSize: 16,
    backgroundColor: ({ white }) => (white ? '#FAFAFA' : 'transparent'),
    backgroundImage: ({ white }) => (white ? `url("${mgfW}")` : `url("${mgf}")`),
    backgroundSize: [12, 12],
    backgroundRepeat: 'no-repeat',
    backgroundPositionY: 'center',
    backgroundPositionX: '10px',
    '.rtl &': {
      backgroundPositionX: '10px',
    },
    '&::placeholder': {
      color: ({ white }) => (white ? '#5A6872' : '#fff'),
      opacity: 0.5,
      fontWeight: 'normal',
    },
    '&:hover': {
      border: '1px solid #8C9BA5',
      color: '#8C9BA5',
    },
    '&:focus': {
      opacity: 1,
      backgroundImage: `url("${mgfA}")`,
      fontWeight: 300,
      border: `1px solid ${theme.colors.primaryLight}`,
      color: '#171A1C',
      '&::placeholder': {
        color: ({ white }) => (white ? '#5A6872' : '#171A1C'),
      },
    },
  },
});
