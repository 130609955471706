// @flow
import moment from 'moment';
import { useSelector } from 'react-redux';
import { currentCompanyDateFormatSelector } from 'domain/companies';

export function useListTimeFormatter(t: number | string): string {
  const dateFormat = useSelector(currentCompanyDateFormatSelector);
  return moment(t).calendar(null, {
    sameDay: `[${moment().from(t, true)}]`,
    lastDay: 'dddd',
    lastWeek: 'dddd',
    sameElse: dateFormat,
  });
}
