// @flow
import React, { memo, type StatelessFunctionalComponent } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import cx from 'classnames';

export type Props = {|
  item: any,
  itemIndex: number,
  children: any,
  render: any,
  id: string | number,
  disabled: boolean,
  helperClass: string,
  className?: string,
|};

const SortableItem: StatelessFunctionalComponent<Props> = ({
  item,
  render,
  itemIndex,
  id,
  className,
  helperClass,
  disabled,
}: Props) => {
  const { attributes, isDragging, listeners, setNodeRef, transform, transition } = useSortable({
    id,
    disabled,
  });

  const cursor = (disabled && 'auto') || (isDragging ? 'grabbing' : 'grab');

  const style = {
    zIndex: isDragging ? 1 : 0,
    transform: CSS.Translate.toString(transform),
    transition,
    userSelect: 'none',
    cursor,
  };

  return (
    <div
      ref={setNodeRef}
      style={style}
      className={className}
      {...attributes}
      {...listeners}
      {...(isDragging && { 'data-order': 'last' })}
    >
      {render(
        {
          ...(item.toJS ? item.toJS() : item),
          className: cx({ [helperClass]: isDragging }),
          style: { cursor },
          idx: itemIndex,
          dragging: isDragging,
        },
        itemIndex,
      )}
    </div>
  );
};

export default memo(SortableItem);
