/* @flow */
import * as React from 'react';

import { withStyles } from '@mui/styles';
import OTC from './context';

import OutsideTooltip from './OutsideTooltip';
import sheet from './sheet';

import type { OutsideTooltipData } from './types.js.flow';

export type Props = {|
  children: React$Node,
  classes: {
    [key: string]: string,
  },
|};

type State = {|
  data: OutsideTooltipData | null,
|};

class OutsideTooltipProvider extends React.Component<Props, State> {
  state = {
    data: {
      text: '',
      pos: null,
      textId: null,
      classNameWrapper: '',
    },
  };

  setData = (uData: OutsideTooltipData | null): void => {
    const data = uData || { text: '', pos: null, textId: null, classNameWrapper: '' };
    this.setState({ data });
  };

  value = { setOutsideTooltip: this.setData };

  render() {
    const { children } = this.props;
    const { data } = this.state;

    return (
      <OTC.Provider value={this.value}>
        {children}
        <OutsideTooltip {...data} />
      </OTC.Provider>
    );
  }
}

export default withStyles(sheet)(OutsideTooltipProvider);
