// @flow
import React from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { querySelector } from 'domain/router';
import { cognitoAuthAppUrl } from 'amplify/awsauth';

import LayoutServicePage from 'components/mui/Layouts/LayoutServicePage';
import Stack from '@mui/material/Stack';
import AlertMui from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import RouterLink from 'components/mui/Router/RouterLink';
import bgImage from 'pages/accessLimited/images/icon.svg';

const AccessLimited = () => {
  const { msg } = useSelector(querySelector);

  return (
    <LayoutServicePage>
      <Stack flex={1} component="main" justifyContent="center" alignItems="center">
        <img src={bgImage} alt="background image" />
        <Typography variant="h5" mt={4}>
          <FormattedMessage
            id="pages.access_limited.invalid_grant"
            defaultMessage="Access to the system is forbidden"
          />
        </Typography>
        <Box mb={1} mt={2}>
          <AlertMui severity="error">
            <Typography variant="body1" color="error">
              {msg}
            </Typography>
          </AlertMui>
        </Box>
        <Typography variant="body1" color="text.secondary" mt={1} mb={2}>
          <FormattedMessage
            id="pages.access_limited.contact_admin"
            defaultMessage="Please contact your organization administrator"
          />
        </Typography>
        <Button href={cognitoAuthAppUrl} component={RouterLink}>
          <FormattedMessage id="pages.access_limited.back_to_login" defaultMessage="Back to login" />
        </Button>
      </Stack>
    </LayoutServicePage>
  );
};

export default AccessLimited;
