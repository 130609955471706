/* @flow */
import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Set, Map } from 'immutable';
import { FormattedMessage } from 'react-intl';
import { checkAcceptSignType } from 'domain/documents/helpers';
import { isSignAvailableSelector } from 'domain/companies';
import type { SignParamsType } from 'domain/companies/helpers';
import type { TDocumentRecord } from 'domain/documents/types.js.flow';

import TooltipForButton from 'components/mui/Tooltip/TooltipForButton';
import Dialog from 'components/mui/Dialog';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import LoadingButton from '@mui/lab/LoadingButton';
import TextFieldBase from 'components/mui/Form/TextField/TextFieldBase';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import UpdateOutlinedIcon from '@mui/icons-material/UpdateOutlined';

type Props = {|
  signParams: SignParamsType,
  onClose: () => void,
  onSign: (id: string, nextIsLast: boolean) => void,
  documents: Set<TDocumentRecord>,
|};

type SignPivotItemType = {|
  sign: number,
  pending: number,
|};

const statusesDefaultMessages = {
  pending: 'Scheduled to Sign',
  signed: 'Signed',
};

const PENDING = 'pending';
const SIGNED = 'signed';

type SignPivotType = $ReadOnlyArray<SignPivotItemType>;

const DialogSignDocument: React$StatelessFunctionalComponent<Props> = ({
  onSign,
  onClose,
  signParams,
  documents,
}: Props) => {
  const [isInAction, setIsInAction] = useState(new Map());
  const [signCounter, setSignCounter] = useState(0);

  const isSignAvailable = useSelector(isSignAvailableSelector);

  const signRange = useMemo(() => [1, 2], []);

  const createDefaultPivot = (range: $ReadOnlyArray<any>): SignPivotType => range.map(() => ({ sign: 0, pending: 0 }));

  const signableDocuments = useMemo((): Set<TDocumentRecord> => {
    if (!Set.isSet(documents)) throw new TypeError(`${JSON.stringify(documents)} is NOT an Immutable Set`);
    return documents.filter((d: TDocumentRecord) => checkAcceptSignType(d.documentID) && !d.protected);
  }, [documents]);

  const signStatus = useMemo(
    (): SignPivotType =>
      signableDocuments.reduce(
        (a, v) =>
          signRange.map((id, i) => ({
            // $FlowFixMe
            sign: a[i].sign + v.tags.has(`_S_SIG_DOKKA${id}_SIGNED`),
            // $FlowFixMe
            pending: a[i].pending + v.tags.has(`_S_SIG_DOKKA${id}_PENDING`),
          })),
        createDefaultPivot(signRange),
      ),
    [signRange, signableDocuments],
  );

  const paramsSignCounter = useMemo((): number => {
    const status = signStatus;
    return signRange.reduce(
      (a: number, v, i) => a + (status[i].sign + status[i].pending === signableDocuments.size),
      0,
    );
  }, [signRange, signStatus, signableDocuments.size]);

  const getSignatureStatus = useCallback(
    ({ sign, pending }: SignPivotItemType, size: number): 'signed' | 'pending' | null => {
      if (sign === size) return SIGNED;
      if (pending === size) return PENDING;
      if (sign + pending === size) return PENDING;
      return null;
    },
    [],
  );

  const signaturesParams = useMemo(() => {
    const signatureStatus = signStatus;
    return signRange.map((e, i) => ({
      token: ((`token${e}`: any): 'token1' | 'token2'),
      name: signParams[`token${e}`].name,
      signatureID: e.toString(),
      status: getSignatureStatus(signatureStatus[i], signableDocuments.size),
    }));
  }, [signRange, signStatus, signParams, signableDocuments.size, getSignatureStatus]);

  const isDisabled = useCallback((token: 'token1' | 'token2') => !isSignAvailable.get(token), [isSignAvailable]);

  const isBeingRequested = useCallback(
    (signatureID: string): boolean => isInAction.get(signatureID, false),
    [isInAction],
  );

  const handleSign = useCallback(
    (id: string) => {
      const numberOfSignatures = signCounter + 1;
      setSignCounter(numberOfSignatures);
      const max = signRange.length;
      const isLast = numberOfSignatures === max || paramsSignCounter === max;
      setIsInAction(isInAction.set(id, true), onSign(id, isLast));
    },
    [isInAction, onSign, signCounter, paramsSignCounter, signRange.length],
  );

  useEffect(() => {
    setSignCounter(paramsSignCounter);
  }, [paramsSignCounter]);

  if (!signParams.guid) return null;

  return (
    <Dialog
      open
      onClose={onClose}
      onOk={onClose}
      title={<FormattedMessage id="document.sign.title" defaultMessage="Sign Document" />}
    >
      <Stack spacing={2}>
        {signaturesParams.map(({ name, status, signatureID, token }) => {
          const disableSign = isDisabled(token, signatureID);
          return (
            <Stack key={name} direction="row" spacing={2} alignItems="center">
              <TextFieldBase readOnly value={name} inputProps={{ readOnly: true }} disabled size="small" fullWidth />
              {status !== null && (
                <Stack direction="row" spacing={1} alignItems="center">
                  {status === PENDING && <UpdateOutlinedIcon color="warning" />}
                  {status === SIGNED && <CheckOutlinedIcon color="success" />}

                  <Typography variant="hint" noWrap color={status === SIGNED ? 'success.main' : 'warning.main'}>
                    <FormattedMessage id={`document.sign.${status}`} defaultMessage={statusesDefaultMessages[status]} />
                  </Typography>
                </Stack>
              )}
              {status === null && (
                <TooltipForButton
                  id="document.sign.disableButtonTooltip"
                  defaultMessage="User is not authorized to sign"
                  disabled={!disableSign}
                >
                  <LoadingButton
                    variant="contained"
                    color="primary"
                    loading={isBeingRequested(signatureID)}
                    disabled={disableSign}
                    onClick={() => handleSign(signatureID)}
                  >
                    <FormattedMessage id="document.sign.signBtn" defaultMessage="Sign" />
                  </LoadingButton>
                </TooltipForButton>
              )}
            </Stack>
          );
        })}
      </Stack>
    </Dialog>
  );
};

export default DialogSignDocument;
