/* @flow */
import * as React from 'react';
import { withStyles } from '@mui/styles';
import sheet from './sheet';

export type RowMessageTypes = 'info' | 'error' | 'warning';

export type RowMessagesProps = {|
  classes: {
    [key: string]: string,
  },
  message: string,
  type: RowMessageTypes,
|};

const RowMessages: React.StatelessFunctionalComponent<RowMessagesProps> = React.memo(
  ({ classes, message }: RowMessagesProps) => (
    <div className={classes.box}>
      <div className={classes.tooltipBox}>
        <div className={classes.tooltip}>{message}</div>
      </div>
    </div>
  ),
);

export default withStyles(sheet)(RowMessages);
