// @flow
import React from 'react';
import { ERP_NAMES } from 'domain/companies';
import { FormattedMessage } from 'react-intl';

import type { State, TPriorityVersionOptions, TOption } from '../ERPConnectModal';
import type { TLabelOptions } from 'labels/type.js.flow';

import Paper from '@mui/material/Paper';
import CheckboxBase from 'components/mui/Form/Checkbox/CheckboxBase';
import FormControlLabel from '@mui/material/FormControlLabel';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Select from 'components/mui/Form/Select/SelectBase';
import TextField from 'components/mui/Form/TextField/TextFieldBase';
import CheckboxSetBase from 'components/mui/Form/CheckboxSet/CheckboxSetBase';
import Grid from '@mui/material/Grid';

const Checkbox = (props) => (
  <FormControlLabel
    sx={{ display: 'flex', width: 'fit-content' }}
    control={<CheckboxBase {...props} />}
    label={props.label}
  />
);
const CheckboxSet = ({ renderFooter, ...rest }) => (
  <Grid item xs={12}>
    <CheckboxSetBase
      {...rest}
      ListItemProps={{ dense: true, disablePadding: true }}
      ListProps={{
        component: Paper,
        dense: true,
        disablePadding: true,
        sx: {
          maxHeight: 200,
          overflow: 'auto',
          borderBottom: 'none',
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
        },
        variant: 'outlined',
      }}
    />
    {renderFooter()}
  </Grid>
);

type Props = State & {
  onCompanyChange: (selectedCompany: string) => void,
  onOtherCompanyChange: (e: SyntheticInputEvent<HTMLInputElement>) => void,
  setSelectedOtherCompanies: (selectedOtherCompanies: Array<string>) => void,
  toggleAllSelection: () => void,
  onCompanySave: () => void,
  isPaidOrg: boolean,
  labelOptions: TLabelOptions,
  erp: string,
  priorityVersion: null | TOption,
  priorityVersionOptions: Array<TPriorityVersionOptions>,
  onChangePriorityVersion: (option: TOption) => void,
  handleConnectModal: () => void,
};

const ERPConnectMultiStep = (props: Props) => {
  const {
    companies,
    selectedCompany,
    onCompanyChange,
    onOtherCompanyChange,
    setSelectedOtherCompanies,
    onCompanySave,
    isSelectedOther,
    isPaidOrg,
    labelOptions,
    selectedOtherCompanies: stateSelectedOtherCompanies,
    erp,
    priorityVersion,
    priorityVersionOptions,
    onChangePriorityVersion,
    handleConnectModal,
    onStationIdChange,
    onTokenChange,
  } = props;

  const options = companies ? companies.sort((i1, i2) => i1.label.localeCompare(i2.label)) : [];

  const otherCompanies = selectedCompany ? options.filter((option) => option.value !== selectedCompany.value) : options;

  const selectedOtherCompanies =
    stateSelectedOtherCompanies.filter((compId: string) => selectedCompany !== compId) || [];

  const onChangeOther = React.useCallback(
    (e, value) => {
      const newSelected = selectedOtherCompanies.includes(value)
        ? selectedOtherCompanies.filter((v) => v !== value)
        : [...selectedOtherCompanies, value];
      setSelectedOtherCompanies(newSelected);
    },
    [selectedOtherCompanies, setSelectedOtherCompanies],
  );
  const onChangeOtherAll = React.useCallback(() => {
    setSelectedOtherCompanies(selectedOtherCompanies.length ? [] : otherCompanies.map((i) => i.value));
  }, [otherCompanies, selectedOtherCompanies.length, setSelectedOtherCompanies]);

  const onChangeCompany = React.useCallback(
    (e) => {
      const company = options.find((i) => i.value === e.target.value);
      onCompanyChange(company);
    },
    [onCompanyChange, options],
  );
  const onChangeVersion = React.useCallback(
    (e) => {
      const version = priorityVersionOptions.find((i) => i.value === e.target.value);
      onChangePriorityVersion(version);
    },
    [onChangePriorityVersion, priorityVersionOptions],
  );

  const handleStationIdChange = React.useCallback(
    (e) => {
      onStationIdChange(e.target.value);
    },
    [onStationIdChange],
  );

  const handleTokenChange = React.useCallback(
    (e) => {
      onTokenChange(e.target.value);
    },
    [onTokenChange],
  );

  const isPriority = [ERP_NAMES.priorityPro].includes(erp);
  const isHash = [ERP_NAMES.hash].includes(erp);

  const isPriorityVersionInValid = isPriority && !priorityVersion;

  const isDisabledSubmit = !selectedCompany || isPriorityVersionInValid;

  const indeterminate = selectedOtherCompanies.length && selectedOtherCompanies.length !== otherCompanies.length;

  return (
    <>
      <DialogContent sx={(theme) => ({ pt: `${theme.spacing(1)}!important` })}>
        <Grid container spacing={3}>
          <FormattedMessage id="sageConnect.chooseCompany" defaultMessage="Select company">
            {(text: string) => (
              <Grid item xs={12}>
                <Select
                  FormControlProps={{ fullWidth: true }}
                  options={options}
                  value={selectedCompany?.value || selectedCompany}
                  onChange={onChangeCompany}
                  label={text}
                  readOnly={isSelectedOther}
                />
              </Grid>
            )}
          </FormattedMessage>
          {isHash && (
            <>
              <Grid item xs={12}>
                <TextField
                  id="stationId"
                  label={<FormattedMessage id="hashConnect.stationId" defaultMessage="Station Id (Optional)" />}
                  fullWidth
                  onChange={handleStationIdChange}
                  autoComplete="new-password"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="token"
                  type="password"
                  label={<FormattedMessage id="hashConnect.token" defaultMessage="Token (Optional)" />}
                  fullWidth
                  onChange={handleTokenChange}
                  autoComplete="new-password"
                />
              </Grid>
            </>
          )}
          {!!(
            isPaidOrg &&
            [ERP_NAMES.priorityPro, ERP_NAMES.hash, ERP_NAMES.mdNavision, ERP_NAMES.mdBC].includes(erp) &&
            selectedCompany &&
            otherCompanies.length
          ) && (
            <>
              <Grid item xs={12}>
                <Checkbox
                  fullWidth
                  checked={isSelectedOther}
                  onChange={onOtherCompanyChange}
                  label={
                    <FormattedMessage
                      values={{
                        label: labelOptions.name,
                      }}
                      id="priorityConnect.chooseCompany.other"
                      defaultMessage="Connect other companies to {label}"
                    />
                  }
                />
              </Grid>
              {isSelectedOther && (
                <Grid item xs={12}>
                  <CheckboxSet
                    fullWidth
                    items={otherCompanies}
                    checked={selectedOtherCompanies}
                    onChange={onChangeOther}
                    renderFooter={() => (
                      <CheckboxSetBase
                        items={[
                          {
                            value: 'all',
                            label: {
                              primary: (
                                <FormattedMessage
                                  id="sageConnect.chooseCompany.selectAll"
                                  defaultMessage="Select All"
                                />
                              ),
                              secondary: `${selectedOtherCompanies.length} of ${otherCompanies.length}`,
                            },
                          },
                        ]}
                        checked={selectedOtherCompanies.length ? ['all'] : []}
                        onChange={onChangeOtherAll}
                        ListItemProps={{ dense: true, disablePadding: true }}
                        ListProps={{
                          component: Paper,
                          dense: true,
                          disablePadding: true,
                          variant: 'outlined',
                          sx: { borderTopLeftRadius: 0, borderTopRightRadius: 0 },
                        }}
                        CheckboxProps={{
                          indeterminate,
                        }}
                      />
                    )}
                  />
                </Grid>
              )}
            </>
          )}
          {isPriority && (
            <Grid item xs={12}>
              <Select
                required
                label={<FormattedMessage id="priorityConnect.version" defaultMessage="Version" />}
                FormControlProps={{ fullWidth: true }}
                options={priorityVersionOptions}
                value={priorityVersion?.value || priorityVersion}
                onChange={onChangeVersion}
              />
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleConnectModal} variant="text">
          <FormattedMessage id="button.cancel" defaultMessage="Cancel" />
        </Button>
        <Button disabled={isDisabledSubmit} onClick={onCompanySave}>
          <FormattedMessage id="sageConnect.chooseCompany.save" defaultMessage="Save" />
        </Button>
      </DialogActions>
    </>
  );
};

export default ERPConnectMultiStep;
