// @flow
/* eslint-disable jsx-a11y/label-has-for */
import React, { Component } from 'react';
import { withStyles } from '@mui/styles';
import cx from 'classnames';
import { FormattedMessage } from 'react-intl';
import sheet from './sheet';

type Props = {
  classes: {
    [key: string]: string,
  },
  label?: {
    id: string,
    defaultMessage: string,
  },
  hint: Node,
  'data-element': string,
  onChange: (value: boolean) => void,
  value: boolean,
  disabled: boolean,
  children?: React$Node,
  withBorder?: boolean,
  withSmallSizeLabel?: boolean,
  labelClass?: string,
};

class Switcher extends Component<Props> {
  render() {
    const { classes, label, value, onChange, withBorder, withSmallSizeLabel, hint, children, disabled, labelClass } =
      this.props;

    return (
      <div
        className={cx(classes.switcherWrapper, {
          [classes.activeSwitcher]: value,
          [classes.withChildren]: children,
          [classes.withBorder]: withBorder,
          [classes.withSmallSizeLabel]: withSmallSizeLabel,
          [classes.disabled]: disabled,
        })}
      >
        <div className={classes.top}>
          <label>
            {label && (
              <div
                className={cx(classes.switcherLabel, {
                  [labelClass]: labelClass,
                })}
              >
                <FormattedMessage {...label} />
              </div>
            )}

            <div className={classes.checkbox}>
              <input
                type="checkbox"
                value={value}
                data-element={this.props['data-element']}
                onChange={() => onChange(!value)}
                disabled={disabled}
              />
              <span className={classes.switcher} />
            </div>
          </label>
        </div>
        {(!value || !children) && hint ? <div className={classes.hint}>{hint}</div> : null}
        {value && children ? children : null}
      </div>
    );
  }
}

export default withStyles(sheet)(Switcher);
