// @flow
import React from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { companiesByOrganizationSelector } from 'domain/companies';
import { isAdminSelector } from 'domain/env';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';

type TCompaniesTitle = {
  onClickAdd: () => void,
};

const CompaniesTitle: React$StatelessFunctionalComponent<TCompaniesTitle> = ({ onClickAdd }) => {
  const list = useSelector(companiesByOrganizationSelector);
  const isAdmin = useSelector(isAdminSelector);

  return (
    <Box display="flex" alignItems="center" mb={2}>
      <Typography variant="h6">
        <FormattedMessage id="companies.title" defaultMessage="Companies List" /> ({list.size})
      </Typography>
      {isAdmin && (
        <Button variant="outlined" onClick={onClickAdd} startIcon={<AddOutlinedIcon />} sx={{ ml: 'auto' }}>
          <FormattedMessage id="companies.addBtn" defaultMessage="Add Company" />
        </Button>
      )}
    </Box>
  );
};

export default CompaniesTitle;
