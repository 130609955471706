// @flow
import { useSelector } from 'react-redux';
import { userIdSelector } from 'domain/env';
import { chatAllUsersSelector } from 'domain/chat';
import { extractEmail } from 'lib/helpers';

const parseSearchLink = (searchLink: string) =>
  decodeURIComponent(searchLink)
    .split('&')
    .map((item) => item.split('='));

const mapStateToProps = (state) => ({
  userId: userIdSelector(state),
  users: chatAllUsersSelector(state),
});

const useFavorites = () => {
  const { userId, users } = useSelector(mapStateToProps);

  const getUser = (userID) => users.find((u) => u.userId === userID);

  const getUserBySearchLink = (searchLink: string) => {
    const tags = parseSearchLink(searchLink);
    const userID = tags.length === 1 ? extractEmail(tags[0][1]) : null;
    return userID ? getUser(userID) : null;
  };

  const isCurrentUser = (userFilter: string) => userFilter === userId;

  return {
    getUserBySearchLink,
    isCurrentUser,
  };
};

export default useFavorites;
