// @flow
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { timezonesListSelector } from 'domain/dictionary';
import {
  updateNotificationsAction,
  getNotificationsSelector,
  getNotificationsIsLoadingSelector,
} from 'domain/notifications';

import ProfileNotificationsForm from 'pages/profiles/company/pages/notifications/form';
import CircularProgressWithBackdrop from 'components/mui/CircularProgressWithBackdrop';
import { NotificationsFactory } from 'domain/notifications/adapters';
import { sessionST } from 'lib/sessionStorage';

const mapStateToProps = (state) => ({
  timezonesOptions: timezonesListSelector(state),
  notifications: getNotificationsSelector(state),
  isLoading: getNotificationsIsLoadingSelector(state),
});

const ProfileNotificationsTab = () => {
  const { timezonesOptions, notifications, isLoading } = useSelector(mapStateToProps);
  const dispatch = useDispatch();

  const [isSubmitting, setISSubmitting] = useState(false);

  const onSubmit = useCallback(
    (data) => {
      setISSubmitting(true);
      new Promise((resolve, reject) => {
        sessionST().shouldSaveReminder().set(false);
        dispatch(updateNotificationsAction({ data, resolve, reject }));
      }).finally(() => setISSubmitting(false));
    },
    [dispatch],
  );

  useEffect(
    () => () => {
      const shouldSaveReminder = sessionST().shouldSaveReminder().get();
      const remindMe = JSON.parse(shouldSaveReminder || 'false');
      if (remindMe && !isSubmitting) {
        const defaultData = { ...notifications.toJS() };
        const data = NotificationsFactory({
          ...defaultData,
          approval_notification: { ...defaultData.approval_notification, remind_me: remindMe },
        });
        onSubmit(data);
      }
    },
    [onSubmit, notifications, isSubmitting],
  );

  return (
    <>
      {isLoading ? (
        <CircularProgressWithBackdrop type="light" />
      ) : (
        <ProfileNotificationsForm
          timezonesOptions={timezonesOptions}
          onSubmit={onSubmit}
          initialValues={{
            ...notifications.toJS(),
          }}
        />
      )}
    </>
  );
};

export default ProfileNotificationsTab;
