/* @flow */
import * as React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { withStyles } from '@mui/styles';
import cx from 'classnames';
import { loadingSelector } from 'domain/env';
import { transactionsSelector, sendMessageAction } from 'domain/requestTransaction';
import Spinner from 'components/Spinner';
import Header from './header';
import Transactions from './transaction';
import Preview from './preview';

const sheet = (theme) => ({
  container: {
    display: 'grid',
    gridTemplateRows: '50px 110px 1fr',
    height: '100vh',
    background: '#fff',
    gridTemplateColumns: '1fr 2fr',
    '@media (max-width: 749px)': {
      gridTemplateColumns: '1fr',
      gridTemplateRows: '50px 60px 1fr',
    },
  },
  isPreview: {
    '& $content': {
      '@media (min-width: 750px)': {
        gridColumnStart: 2,
        padding: [0, 20, 0, 40],
      },
      '@media (max-width: 749px)': {
        display: 'none',
      },
    },
    '& $title': {
      '@media (max-width: 749px)': {
        display: 'none',
      },
    },
  },
  preview: {
    gridColumnStart: 1,
    gridColumnEnd: 2,
    gridRowStart: 2,
    gridRowEnd: 4,
    padding: [15, 30],
    background: theme.colors.greyLighter,
    position: 'relative',
    overflowY: 'auto',
    '@media (max-width: 749px)': {
      padding: [15, 10],
      gridColumnEnd: 3,
    },
  },
  content: {
    gridColumnStart: 1,
    gridColumnEnd: 3,
    padding: [0, 20],
    overflowY: 'auto',
    position: 'relative',
  },
  title: {
    color: '#333333',
    '@media (min-width: 750px)': {
      padding: [0, 40],
      fontSize: 34,
      lineHeight: '110px',
    },
    '@media (max-width: 749px)': {
      fontSize: 19,
      padding: [0, 20],
      lineHeight: '60px',
    },
  },
  blank: {
    display: 'flex',
  },
  loading: {
    position: 'absolute',
  },
});

type State = {
  isPreview: boolean,
};

class RequesTransaction extends React.Component<*, State> {
  state = {
    isPreview: window.innerWidth > 749,
  };

  handlePreview = (isPreview: boolean) => {
    this.setState({
      isPreview,
    });
  };

  render() {
    const {
      classes,
      transactions,
      match: {
        params: { anonymousToken },
      },
      loading,
    } = this.props;
    const { isPreview } = this.state;
    return (
      <section className={cx(classes.container, { [classes.isPreview]: isPreview })}>
        <Header onChange={this.handlePreview} isPreview={this.state.isPreview} />
        {isPreview ? (
          <div className={classes.preview}>
            <Preview anonymousToken={anonymousToken} />
          </div>
        ) : null}
        <FormattedMessage id="requestTransactions.transactionsRequests" defaultMessage="Transactions">
          {(t: string) => <h1 className={classes.title}>{t}</h1>}
        </FormattedMessage>
        <div className={cx(classes.content, { [classes.blank]: transactions.size === 0 })}>
          {loading ? null : (
            <Transactions list={transactions} send={(d) => this.props.sendMessage({ ...d, anonymousToken })} />
          )}
          <Spinner loading={loading} className={classes.loading} />
        </div>
      </section>
    );
  }
}

const mapStateToProps = (state) => ({
  transactions: transactionsSelector(state),
  loading: loadingSelector(state),
});

const mapDispatchToProps = {
  sendMessage: sendMessageAction,
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(sheet))(RequesTransaction);
