// @flow
import * as actions from './actions';
import { devicesFactory } from './adapters';

export const reducer = {
  devices(state = devicesFactory([]), action) {
    switch (action.type) {
      case actions.getDevicesAction.success:
        return action.payload;
      default:
        return state;
    }
  },
};
