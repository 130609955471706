import React from 'react';
import { FormattedMessage } from 'react-intl';

import { styled } from '@mui/material/styles';
import MuiChip from '@mui/material/Chip';
import { Statuses } from 'pages/configurations/company/pages/integrations/helpers';
import { red } from '@mui/material/colors';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import ArrowRightIcon from 'pages/components/AccordionArrowRightIcon';
import MuiButton from '@mui/material/Button';
import MuiLoadingButton from '@mui/lab/LoadingButton';

export const Chip = styled(MuiChip)(() => ({
  borderRadius: 4,
}));

export const createStatuses = (item) => ({
  [Statuses.connected]: (
    <Chip
      color="success"
      label={
        <FormattedMessage id="settings.connections.connected" defaultMessage="Connected">
          {(t) =>
            item.settings.getIn(['details', 'company_name'])
              ? `${t} (${item.settings.getIn(['details', 'company_name'])})`
              : t
          }
        </FormattedMessage>
      }
      size="small"
    />
  ),
  [Statuses.inactive]: (
    <Chip
      variant="outlined"
      color="error"
      label={
        <FormattedMessage id="settings.connections.inactive" defaultMessage="Inactive">
          {(t) =>
            item.settings.getIn(['details', 'company_name'])
              ? `${t} (${item.settings.getIn(['details', 'company_name'])})`
              : t
          }
        </FormattedMessage>
      }
      size="small"
      sx={() => ({ backgroundColor: red[100] })}
    />
  ),
  [Statuses.authorized]: (
    <Chip
      color="warning"
      sx={(theme) => ({ backgroundColor: theme.palette.warning.light })}
      label={<FormattedMessage id="settings.connections.authorized" defaultMessage="Authorized" />}
      size="small"
    />
  ),
  [Statuses.connecting]: (
    <Stack direction="row" spacing={1} justifyContent="center" alignItems="center">
      <Chip
        color="warning"
        label={<FormattedMessage id="settings.connections.connecting" defaultMessage="Connecting" />}
        size="small"
        sx={(theme) => ({ backgroundColor: theme.palette.warning.light })}
      />
      <Typography variant="subtitle2" sx={(theme) => ({ color: theme.palette.warning.light })}>
        <FormattedMessage
          id="settings.connections.connectingMsg"
          defaultMessage="Process might take few minutes.
                          Reload the page to check the connection status"
        />
      </Typography>
    </Stack>
  ),
});

export const Accordion = styled(MuiAccordion)(({ theme }) => ({
  marginBottom: 1.5,
  marginTop: '12px !important',
  border: `1px solid ${theme.palette.grey[200]}`,
  borderRadius: theme.shape.borderRadius * 2,
  boxShadow: 'none',

  '&.MuiPaper-root.MuiAccordion-root::before': {
    display: 'none',
  },
}));

export const AccordionSummary = styled((props) => (
  <MuiAccordionSummary elevation={0} expandIcon={<ArrowRightIcon />} {...props} />
))(() => ({
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-content': {
    margin: 0,
    height: 64,
  },
  '& .MuiAccordionSummary-content.Mui-expanded': {
    margin: 0,
  },
}));

export const Button = ({ onClick, ...rest }) => (
  <MuiButton
    {...rest}
    onClick={(...args) => {
      args[0].stopPropagation();
      if (typeof onClick === 'function') onClick(...args);
    }}
  />
);
export const LoadingButton = ({ onClick, ...rest }) => (
  <MuiLoadingButton
    {...rest}
    onClick={(...args) => {
      args[0].stopPropagation();
      if (typeof onClick === 'function') onClick(...args);
    }}
  />
);
