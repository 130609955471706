// @flow
import { put, call, select } from 'redux-saga/effects';
import Api, { doLogout, logoutAction } from 'domain/api';
// selectors
import * as Router from 'domain/router';
// actions
import * as action from './actions';
// adapters
import { categoriesStoreAdapter } from './adapters';
// types
import type { SelectEffect, CallEffect } from 'redux-saga';
import type { RouterParams } from 'pages/company/sagas';
import type { Category } from 'domain/categories/types.js.flow';

type Params = $Diff<RouterParams, {| companyId: string |}>;

type apiConfig = {|
  apiName: string,
  config: {| params: {| dokkaToken: string |} |},
  // eslint-disable-next-line max-len
  prepare: (
    params: Params,
  ) => Generator<SelectEffect<any, []>, {| dokkaToken: string, isConfidential: boolean |}, Object>,
  success: (data: Array<Category>) => Object,
  failure: () => Generator<*, void, *>,
|};

type apiResponse = {| data: Array<Category> |};

// eslint-disable-next-line max-len
export function* getCategoriesWorkerParams(params: Params): Generator<CallEffect<*, *, *>, apiConfig, {||}> {
  // eslint-disable-next-line max-len
  function* prepare(
    prepareParams: Params = {},
  ): Generator<SelectEffect<any, []>, {| isConfidential: boolean |}, Object> {
    return {
      isConfidential: prepareParams.companyType === 'confidential',
      category: prepareParams.category,
      ...(yield select(Router.querySelector)),
    };
  }

  return {
    apiName: 'getCategories',
    config: {
      params: yield call(prepare, params),
    },
    prepare,
    success(data) {
      return {
        type: action.getCategoriesAction.success,
        payload: categoriesStoreAdapter(data),
      };
    },
    // $FlowFixMe
    failure(err) {
      // eslint-disable-next-line no-console
      console.log('categories worker error', err);
      // return doLogout(action.getCategoriesAction, err);
      return logoutAction(action.getCategoriesAction, err);
    },
  };
}

export function* ensureGetCategories(params: Params): Generator<Effect, void, apiResponse> {
  yield put({
    type: action.getCategoriesAction.request,
  });
  // if we yield different data from generator (2 yields) it cant be
  // correctly typed with Generator<Effect, void, yield1Type | yield2Type>
  // so ignoring type for the first yield
  // $FlowFixMe
  const { apiName, config }: apiConfig = yield call(getCategoriesWorkerParams, params);
  try {
    const { data } = yield call(Api[apiName], config);
    yield put({
      type: action.getCategoriesAction.success,
      payload: categoriesStoreAdapter(data),
    });
  } catch (err) {
    yield doLogout(action.getCategoriesAction, err);
  }
}
