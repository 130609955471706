// @flow
import * as React from 'react';
import { compose, type Dispatch } from 'redux';
import { connect } from 'react-redux';
import { Map } from 'immutable';
import { referenceSelector, clearReferenceAction, clearExtraPolygonAction } from 'domain/journal';
import type { ReferenceRecord } from 'domain/journal/types.js.flow';

import { withStyles } from '@mui/styles';
import sheet from 'pages/document/components/ArrowDrawArea/sheet';

type Props = {
  reference: Map<string, ReferenceRecord>,
  clearReference: Dispatch<clearReferenceAction>,
  clearExtraPolygon: Dispatch<clearExtraPolygonAction>,
  classes: {
    [key: string]: string,
  },
  theme: any,
};

class ArrowDrawArea extends React.PureComponent<Props> {
  componentDidMount() {
    global.addEventListener('resize', this.update, false);
  }

  componentDidUpdate(prevProps) {
    const { reference } = this.props;
    if (reference.count()) {
      this.startHandle();
    }

    if (prevProps.reference.count() !== reference.count()) {
      this.clear();
    }
  }

  componentWillUnmount() {
    this.clear();
    global.removeEventListener('resize', this.update);
    this.stopHandle();
  }

  startHandle = () => {
    global.addEventListener('mousemove', this.mouseHandler, false);
    global.document.addEventListener('mouseup', this.stopHandle, false);
  };

  stopHandle = () => {
    const { clearReference, clearExtraPolygon } = this.props;
    global.removeEventListener('mousemove', this.mouseHandler, false);
    global.document.removeEventListener('mouseup', this.stopHandle, false);
    this.clear();
    clearReference();
    clearExtraPolygon();
  };

  mouseHandler = ({ clientX, clientY }: SyntheticMouseEvent<*>) => {
    window.requestAnimationFrame(() => this.drawLine(clientX, clientY));
  };

  drawLine = (x, y) => {
    const { reference, theme } = this.props;
    if (this.canvas && reference.size > 0) {
      const ctx = this.canvas.getContext('2d');
      this.clear();
      const { x1, y1, x2, y2 } = reference.first();

      const toX = x2 || x;
      const toY = y2 || y;

      ctx.arc(x1, y1, 3, 0, 2.5 * Math.PI, false);
      ctx.fill();
      ctx.strokeStyle = theme.palette.primary.main;
      ctx.fillStyle = theme.palette.primary.main;
      ctx.lineWidth = 2;
      ctx.beginPath();
      ctx.moveTo(x1, y1);
      ctx.lineTo(toX, toY + window.scrollY);
      ctx.stroke();
    }
  };

  clear = () => {
    if (this.canvas) {
      const ctx = this.canvas.getContext('2d');
      ctx.save();
      ctx.setTransform(1, 0, 0, 1, 0, 0);
      ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
      ctx.restore();
    }
  };

  update = () => {
    this.forceUpdate();
  };

  isMouseHandle: boolean = false;

  canvas: ?HTMLElement;

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.wrapper}>
        <canvas
          className={classes.canvas}
          ref={(el) => {
            this.canvas = el;
          }}
          width={global.innerWidth}
          height={global.innerHeight}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  reference: referenceSelector(state),
});

const mapPropsToDispatch = {
  clearReference: clearReferenceAction,
  clearExtraPolygon: clearExtraPolygonAction,
};

export default compose(
  connect(mapStateToProps, mapPropsToDispatch),
  withStyles(sheet, { withTheme: true }),
)(ArrowDrawArea);
