// @flow

export const DEFAULT_FORMAT = 'MM/DD/YYYY';

export const ERRORS = {
  from: 'from',
  to: 'to',
  period: 'period',
};

export type ErrorsType = $Keys<typeof ERRORS>;

export default /* :: Object.freeze( */ {
  en: 'MM/DD/YYYY',
  he: 'MM/DD/YYYY',
  es: 'MM/DD/YYYY',
  it: 'MM/DD/YYYY',
} /* :: ) */;
