/* @flow */
import * as React from 'react';

// redux
import { compose, type Dispatch } from 'redux';
import { connect } from 'react-redux';

// toasts
import toast from 'components/Toast';

// actions/selectors
import {
  selectedLinesFlatSelector,
  reconcileRequestRowLineNumberSelector,
  selectedLineIdsSelector,
} from 'domain/journal';
import {
  createTransactionRequestAction,
  hideReconciliationRequestDetailsAction,
  requestPopupIsOpenSelector,
} from 'domain/reconciliation';
import { rtlEnable, documentViewSelector, userNamesSelector } from 'domain/env';
import { bookkeeperMessagesSelector } from 'domain/requestTransaction';
import { chatClientsSelector } from 'domain/chat';
import { currentCompanyNameSelector } from 'domain/companies/companiesSelector';
import { currentCompanySelector } from 'domain/documents/documentSelector';
import { userIdSelector } from 'domain/env/envSelector';

// jss
import { withStyles } from '@mui/styles';
import tableSheet from 'components/Tables/sheet';
import cx from 'classnames';

// types
import type { JornalEntryType, RecordsCellType } from 'domain/journal/helper';
import type { ReferenceRecord } from 'domain/journal/types.js.flow';
import type { IMessage } from 'components/RequestMessages/types.js.flow';
import type { IUser } from './recipient/helpers';

// immutable
import { List, Record, Map, type RecordOf } from 'immutable';

import get from 'lodash/get';

// components
import Modal from 'components/Modal';
import Popup from 'components/Popup';
import TransactionRequest from 'components/Tables/layout/transactionRequest';
import Cell from 'components/Tables/cell';
import { BookkeeperMessages } from 'components/RequestMessages';
import DescriptionCell from 'components/Tables/description';
import RecipientParams from './recipient';

// helpers
import { promisify } from 'lib/helpers';
import { emailReg } from 'components/Form/validation';
import indexedDb from 'indexedDb';

// jss
import sheet from './sheet';
import type { RecordFactory } from 'immutable';

import elements from 'components/elements';

interface IChatUsers {
  firstName: string;
  lastName: string;
  userId: string;
}

type Props = {|
  classes: { [key: string]: string },
  documentView: Map<'documentsView' | 'infoBarWidth', number>,
  setActive: (p: { id: string, params: ReferenceRecord }) => void,
  createTransactionRequest: Dispatch<typeof createTransactionRequestAction>,
  activeReference: ?ReferenceRecord,
  selectedLinesData: JornalEntryType,
  selectedLineIds: List<string>,
  bookkeeperMessages: List<*>,
  currentRow: ?number,
  rtl: boolean,
  onClose: () => void,
  users: List<RecordOf<IChatUsers>>,
  bookkeeperName: {|
    firstName: string,
    lastName: string,
  |},
  companyName: string,
  companyId: string,
  userId: string,
  hideReconciliationRequestDetails: Dispatch<typeof hideReconciliationRequestDetailsAction>,
  pageParams: {
    page: number,
    pageSize: number,
  },
  isRequestPopupOpen: boolean,
|};

type State = {|
  replyTo?: ?{|
    email: $PropertyType<IMessage, 'email'>,
    lastName: $PropertyType<IMessage, 'lastName'>,
    firstName: $PropertyType<IMessage, 'firstName'>,
  |},
  actionInProgress: boolean,
  recipient: string,
  storeRecipients: List<RecordOf<IUser>>,
|};

export const ChatUserFactory: RecordFactory<IChatUsers> = new Record({
  firstName: '',
  lastName: '',
  username: '',
  userId: '',
});

function getFormFieldValue(form: HTMLCollection<HTMLElement>, fields: $ReadOnlyArray<string>) {
  return fields.reduce((a, v) => {
    const value = get(form, [v, 'value']);
    if (value !== 'undefined') {
      return { ...a, [v]: value };
    }
    return a;
  }, {});
}

const storeRecipientsAdapter = (recipients: Array<IUser>) =>
  List(
    recipients.map((recipient) =>
      ChatUserFactory({
        userId: recipient.recEmail,
        username: recipient.recName,
      }),
    ),
  );

class RequestPopup extends React.Component<Props, State> {
  state: State = {
    replyTo: null,
    actionInProgress: false,
    recipient: '',
    storeRecipients: List([]),
  };

  componentDidMount() {
    this.loadCompanyRecipients();
  }

  onRecipientChange = (user: ?RecordOf<IUser>) => {
    if (typeof user !== 'undefined' && user !== null) {
      const recipient = user.username;
      this.setState({ recipient });
    }
  };

  get senderName() {
    const {
      bookkeeperName: { firstName, lastName },
    } = this.props;
    return `${firstName} ${lastName}`;
  }

  getItem = (data: RecordsCellType | void) => {
    const { classes } = this.props;

    if (data && data instanceof Record) {
      if (data.type === 'description') {
        return <DescriptionCell classes={classes} data={data} />;
      }

      return (
        <Cell
          data={data}
          key={data.type + data.readonly}
          rtl={this.props.rtl}
          isPriorityErp={false}
          createRefernce={() => {}}
          activeReference={this.props.activeReference}
          setActiveReference={this.props.setActive}
          polygons={Map()}
          optionList={List()}
          active={null}
          classes={classes}
          onChange={() => {}}
          onActive={() => {}}
          documentView={this.props.documentView}
          isSupport={false}
          insertGrid={() => {}}
          setActiveCell={() => {}}
          isBulkEdit={false}
          gridData={Map()} // required for reconciliation only, unused in this table
          amountColumn="H" // required for reconciliation only, unused in this table
        />
      );
    }
    return null;
  };

  getAllResipients = () => this.state.storeRecipients.concat(this.props.users);

  loadCompanyRecipients = () => {
    const { companyId, userId } = this.props;
    indexedDb.companyRecipients
      .where({ companyId, userId })
      .toArray()
      .then((companyResipients) => {
        const storeRecipients = storeRecipientsAdapter(companyResipients);
        this.setState({ storeRecipients });
      });
  };

  pickRecipient = (message: IMessage) => {
    const { email, firstName, lastName } = message;
    this.setState({ replyTo: { email, firstName, lastName } });
  };

  handleSave = (e: SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();
    this.setState({ actionInProgress: true });
    const { createTransactionRequest, selectedLineIds, currentRow, pageParams } = this.props;
    let data = getFormFieldValue(e.currentTarget.elements, ['email', 'name', 'message']);

    // validation for create request only
    if (!currentRow) {
      if (data.email.length === 0 || data.name.length === 0 || data.message.length === 0) {
        this.setState({ actionInProgress: false }, () => toast.error('Required fields must be filled'));
        return;
      }
      if (!emailReg.test(data.email)) {
        this.setState({ actionInProgress: false }, () => toast.error('Please enter a valid email address'));
        return;
      }
    }

    // if replyTo is set, we are in reply mode, so recepient data
    // should be taken from user's message we are replying to.
    const { replyTo } = this.state;
    if (replyTo) {
      data = { ...data, ...replyTo };
    }

    this.saveCompanyRecipient(data);

    // $FlowFixMe
    promisify(createTransactionRequest, {
      ...data,
      line_ids: selectedLineIds,
      pageParams,
    })
      .then(() => {
        this.setState({ actionInProgress: false });
        // close popup. Success toast will be triggered in saga
        this.handleClose();
      })
      .catch(() => {
        this.setState({ actionInProgress: false });
        // errors are not yet handled
        this.handleClose();
      });
  };

  saveCompanyRecipient = ({ email, name }) => {
    const { companyId, userId } = this.props;
    const isExistsRecipient = this.getAllResipients().reduce(
      (res, val) =>
        // eslint-disable-next-line no-mixed-operators
        res || (val.userId === email && val.username === name),
      false,
    );
    if (!isExistsRecipient) {
      indexedDb.companyRecipients
        .put({ companyId, userId, recName: name, recEmail: email })
        .then(this.loadCompanyRecipients);
    }
  };

  handleClose = () => {
    const { onClose, hideReconciliationRequestDetails } = this.props;
    // to have empty name when popup is opened next time
    this.setState({ recipient: '' }, hideReconciliationRequestDetails);
    if (typeof onClose === 'function') {
      onClose();
    }
  };

  render() {
    const { classes, selectedLinesData, bookkeeperMessages, currentRow, companyName, selectedLineIds } = this.props;

    const { actionInProgress, replyTo, recipient } = this.state;

    return this.props.isRequestPopupOpen ? (
      <Modal>
        <Popup
          disableMove
          className={cx(classes.container, {
            [classes.create]: !currentRow,
            [classes.edit]: currentRow,
          })}
          close
          cancel={this.handleClose}
        >
          <form onSubmit={this.handleSave} className={classes.form}>
            <h1 className={classes.mainTitle}>{!currentRow ? 'Request Details from' : 'Transaction Details'}</h1>
            {!currentRow && <RecipientParams users={this.getAllResipients()} onChange={this.onRecipientChange} />}
            {!currentRow ? <h4 className={classes.title}>About following items ({selectedLineIds.size}) </h4> : null}
            <section
              data-element={elements.je.request.table}
              className={cx(classes.table, { [classes.transactionUpdate]: currentRow })}
            >
              <TransactionRequest getItem={this.getItem} data={selectedLinesData} />
              {selectedLineIds.size - 3 > 0 ? (
                <div className={classes.separator}>{selectedLineIds.size - 3} more</div>
              ) : null}
            </section>
            <section className={cx(classes.message, { [classes.transactionUpdate]: currentRow })}>
              {currentRow && (
                <h3 data-element={elements.je.request.historyTitle} className={cx(classes.messageTitle)}>
                  History
                </h3>
              )}
              <BookkeeperMessages
                list={bookkeeperMessages}
                onPicRecipient={this.pickRecipient}
                listClassName={classes.messageList}
                inputClassName={classes.messageInput}
                createMode={!currentRow}
                placeholderVariables={{
                  name: recipient,
                  company: companyName,
                  sender: this.senderName,
                }}
              />
            </section>
            <section className={classes.footer}>
              <button
                className={classes.cancelButton}
                onClick={this.handleClose}
                data-element={elements.je.request.btn.cancel}
              >
                Cancel
              </button>
              <button
                data-element={elements.je.request.btn.send}
                className={cx(classes.actionButton, { [classes.actionInProgress]: actionInProgress })}
                type="submit"
                disabled={currentRow && !replyTo}
              >
                {!actionInProgress && 'Send'}
              </button>
            </section>
          </form>
        </Popup>
      </Modal>
    ) : null;
  }
}

const mapStateToProps = (state) => ({
  selectedLinesData: selectedLinesFlatSelector(state),
  bookkeeperMessages: bookkeeperMessagesSelector(state),
  currentRow: reconcileRequestRowLineNumberSelector(state),
  selectedLineIds: selectedLineIdsSelector(state),
  documentView: documentViewSelector(state),
  rtl: rtlEnable(state),
  users: chatClientsSelector(state),
  bookkeeperName: userNamesSelector(state),
  companyName: currentCompanyNameSelector(state),
  companyId: currentCompanySelector(state),
  userId: userIdSelector(state),
  isRequestPopupOpen: requestPopupIsOpenSelector(state),
});

const mapDispatchToProps = {
  createTransactionRequest: createTransactionRequestAction,
  hideReconciliationRequestDetails: hideReconciliationRequestDetailsAction,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles((theme) => ({ ...tableSheet(theme), ...sheet(theme) })),
)(RequestPopup);
