import { CORNERS } from '../constants';

type Props = {
  setStyles: (s: any) => void,
  setPositionX: (x: number) => void,
  setPositionY: (y: number) => void,
  handleMouseDown: (e: any) => void,
  selectRef: ?HTMLDivElement,
  positionX: number,
  positionY: number,
  styles: { width: number, height: number },
  isRtl: boolean,
};

export const useResize = ({
  selectRef,
  styles,
  setStyles,
  positionX,
  positionY,
  setPositionX,
  setPositionY,
  handleMouseDown,
  isRtl,
}: Props) => {
  let initialWidth = 0;
  let initialHeight = 0;
  let initialMouseX = 0;
  let initialMouseY = 0;

  const MIN_SIZE = 20;

  const changeStyles = ({ newWidth, newHeight }) => {
    setStyles({
      ...styles,
      width: newWidth > MIN_SIZE ? newWidth : MIN_SIZE,
      height: newHeight > MIN_SIZE ? newHeight : MIN_SIZE,
    });
  };

  const getWidthBySide = (e, isLeftSide) =>
    isLeftSide ? initialWidth - (e.pageX - initialMouseX) : initialWidth + (e.pageX - initialMouseX);
  const handleMouseMoveBottomRight = (e) => {
    const newWidth = getWidthBySide(e, isRtl);
    const newHeight = initialHeight + (e.pageY - initialMouseY);
    changeStyles({ newWidth, newHeight });
  };

  const handleMouseMoveTopRight = (e) => {
    const newWidth = getWidthBySide(e, isRtl);
    const newHeight = initialHeight - (e.pageY - initialMouseY);
    if (newHeight > MIN_SIZE) {
      setPositionY(positionY + (e.pageY - initialMouseY));
    }
    changeStyles({ newWidth, newHeight });
  };
  const handleMouseMoveTopLeft = (e) => {
    const newWidth = getWidthBySide(e, !isRtl);
    const newHeight = initialHeight - (e.pageY - initialMouseY);

    if (newWidth > MIN_SIZE) {
      setPositionX(positionX + (e.pageX - initialMouseX));
    }
    if (newHeight > MIN_SIZE) {
      setPositionY(positionY + (e.pageY - initialMouseY));
    }
    changeStyles({ newWidth, newHeight });
  };

  const handleMouseMoveBottomLeft = (e) => {
    const newWidth = getWidthBySide(e, !isRtl);
    const newHeight = initialHeight + (e.pageY - initialMouseY);
    if (newWidth > MIN_SIZE) {
      setPositionX(positionX + (e.pageX - initialMouseX));
    }
    changeStyles({ newWidth, newHeight });
  };
  const handleCornerMouseDown = (e) => {
    e.preventDefault();
    e.stopPropagation();
    handleMouseDown();
    initialWidth = selectRef.current.getBoundingClientRect().width;
    initialHeight = selectRef.current.getBoundingClientRect().height;
    initialMouseX = e.pageX;
    initialMouseY = e.pageY;
    const cornerType = e.target.getAttribute('data-element');

    if (cornerType === CORNERS.BOTTOM_RIGHT) {
      window.addEventListener('mousemove', isRtl ? handleMouseMoveBottomLeft : handleMouseMoveBottomRight);
    } else if (cornerType === CORNERS.TOP_RIGHT) {
      window.addEventListener('mousemove', isRtl ? handleMouseMoveTopLeft : handleMouseMoveTopRight);
    } else if (cornerType === CORNERS.TOP_LEFT) {
      window.addEventListener('mousemove', isRtl ? handleMouseMoveTopRight : handleMouseMoveTopLeft);
    } else if (cornerType === CORNERS.BOTTOM_LEFT) {
      window.addEventListener('mousemove', isRtl ? handleMouseMoveBottomRight : handleMouseMoveBottomLeft);
    }
    window.addEventListener('mouseup', handleCornerMouseUp);
  };
  const handleCornerMouseUp = () => {
    window.removeEventListener('mousemove', handleMouseMoveBottomRight);
    window.removeEventListener('mousemove', handleMouseMoveTopRight);
    window.removeEventListener('mousemove', handleMouseMoveTopLeft);
    window.removeEventListener('mousemove', handleMouseMoveBottomLeft);
  };

  return {
    handleCornerMouseDown,
  };
};
