/* @flow */
import React from 'react';
import { withStyles } from '@mui/styles';
import arrow from './arrow.svg';

type Props = {|
  classes: {
    [key: string]: string,
  },
|};

const sheet = {
  indicator: {
    border: '0 none',
    width: 18,
    height: 10,
    background: `url("${arrow}") scroll no-repeat center`,
    transform: ({ selectProps: { menuIsOpen } }) => (menuIsOpen ? 'rotate(180deg)' : ''),
    transition: 'transform .3s ease-in-out',
    cursor: 'pointer',
  },
};

const DropdownIndicator = (props: Props): React$Node => {
  const { classes } = props;
  return <button type="button" className={classes.indicator} />;
};

export default withStyles(sheet)(DropdownIndicator);
