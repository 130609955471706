/* @flow */
import React, { memo } from 'react';
import { FormattedMessage } from 'react-intl';
import { isChrome, isSupportedVersion } from 'lib/systemHelpers/browserHelpers';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Typography from '@mui/material/Typography';

import useStyles from 'pages/authPage/Layout/LegacyBrowser/sheet';

const hideBrowserWarning = !isChrome || isSupportedVersion;

function AuthLegacyBrowser() {
  const classes = useStyles();

  return !hideBrowserWarning ? (
    <List>
      <ListItem>
        <ListItemAvatar>
          <div className={classes.chromeIcon}>
            <i className={classes.attentionIcon} />
          </div>
        </ListItemAvatar>
        <ListItemText
          primary={
            <Typography variant="subtitle2">
              <FormattedMessage
                id="auth.browserWarning.browserOutOfDate"
                defaultMessage="Your browser is out of date!"
              />
            </Typography>
          }
          secondary={
            <Typography variant="body2" color="text.secondary">
              <FormattedMessage id="auth.browserWarning.suggestion1" defaultMessage="Please, update to " />
              <FormattedMessage
                id="auth.browserWarning.suggestion2"
                values={{ version: <strong>{process.env.REACT_APP_CHROME_SUPPORTED_VERSION}</strong> }}
                defaultMessage={`v. ${process.env.REACT_APP_CHROME_SUPPORTED_VERSION || ''}`}
              />
              <FormattedMessage
                id="auth.browserWarning.suggestion3"
                defaultMessage=" or newer for better work experience"
              />
            </Typography>
          }
        />
      </ListItem>
    </List>
  ) : null;
}

export default memo(AuthLegacyBrowser);
