import Box from '@mui/material/Box';
import ButtonBase from '@mui/material/ButtonBase';
import ListMui from '@mui/material/List';
import ListItemButtonMui from '@mui/material/ListItemButton';
import MuiKeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

import { blue } from '@mui/material/colors';
import { makeStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';

export const useStyles = makeStyles(() => ({
  pdfDocWrapper: {
    maxHeight: '100%',
  },
}));

export const Wrapper = styled('div', { label: 'DocumentNavBar' })(() => ({
  top: 0,
  zIndex: 10,
  position: 'absolute',
  height: '100%',
  '#doc-preview &': {
    left: 0,
  },
  '#doc-preview-rtl &': {
    right: 0,
  },
}));

export const Container = styled('div', {
  shouldForwardProp: (propName) => propName !== 'isOpen',
})(({ theme, isOpen }) => ({
  width: isOpen ? 'auto' : 0,
  maxWidth: 109,
  height: '100%',
  maxHeight: '100%',
  overflow: 'hidden',
  overflowY: 'auto',
  boxShadow: '0px 0px 9px 1px #00000040',
  backgroundColor: theme.palette.common.white,
  borderRight: `1px solid ${theme.palette.grey[300]}`,
}));

export const List = styled(ListMui)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: theme.spacing(1.5),
  maxHeight: '100%',
  gap: theme.spacing(2),
}));

export const ListItem = styled(ListItemButtonMui)(({ theme }) => ({
  position: 'relative',
  padding: 0,
  border: `2px solid ${theme.palette.grey[200]}`,
  borderRadius: theme.shape.borderRadius,
  overflow: 'hidden',

  '&.Mui-selected': {
    background: 'none',
    borderColor: theme.palette.primary.main,
  },
}));

export const PageNumber = styled(Box, { shouldForwardProp: (propName) => propName !== 'active' })(
  ({ theme, active }) => ({
    position: 'absolute',
    top: 0,
    left: 0,
    height: 24,
    minWidth: 24,
    textAlign: 'center',
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 500,
    backgroundColor: active ? blue[50] : theme.palette.grey[100],
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(0, 1),
    color: active ? theme.palette.primary.main : theme.palette.grey[600],
    zIndex: 2,
  }),
);

export const ToggleButton = styled(ButtonBase)(({ theme }) => ({
  position: 'absolute',
  top: 20,
  height: 50,
  width: 30,
  borderRadius: `0px ${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0px`,
  overflow: 'hidden',

  borderStyle: 'solid',
  borderColor: theme.palette.grey[200],
  backgroundColor: theme.palette.common.white,

  '#doc-preview &': {
    right: -29,
    borderRadius: `0px ${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0px`,
    boxShadow: '3px 3px 3px -1px #00000040',
    borderLeft: 'none',
  },

  '#doc-preview-rtl &': {
    left: -29,
    borderRadius: `${theme.shape.borderRadius}px 0px 0px ${theme.shape.borderRadius}px`,
    boxShadow: '-3px 3px 3px -1px #00000040',
    borderRight: 'none',
  },
}));

export const WidgetContainer = styled('div')(() => ({
  height: '100%',
  position: 'absolute',
  top: 0,
  '#doc-preview &': {
    right: 0,
  },
  '#doc-preview-rtl &': {
    left: 0,
  },
}));

export const KeyboardArrowRightIcon = styled(MuiKeyboardArrowRightIcon, {
  shouldForwardProp: (prop) => prop !== 'isOpen',
})(({ isOpen, theme }) => ({
  transform: theme.direction === 'ltr' ? `rotate(${isOpen ? 180 : 0}deg)` : `rotate(${isOpen ? 0 : 180}deg)`,
  '#doc-preview-rtl &': {
    transform: `rotate(${isOpen ? 0 : 180}deg)`,
  },
}));
