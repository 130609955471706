// @flow
// This selector was separated because otherwise common domain selector will has a cyclic dependency with "domain/restriction"

import { selector } from 'lib/selectors';
import * as ACL from 'domain/restriction';
import type { TDocument } from 'domain/documents/types.js.flow';

export const getIndicateWarningByDocSelector = selector(
  ACL.isGranted,
  (isGranted) => (doc?: TDocument) => isGranted(ACL.IS_BOOKKEEPER_IN_FINANCIAL_COMPANY) && doc && doc.indicateWarning,
);
