/* @flow */
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import elements from 'components/elements';

type Props = {
  classes: {|
    [key: string]: string,
  |},
};

function BlankTransaction({ classes }: Props) {
  return (
    <div className={classes.blank}>
      <div className={classes.done} />
      <div className={classes.message} data-element={elements.je.request.transaction.answered}>
        <FormattedMessage
          id="requestTransactions.allRequestAnswered"
          defaultMessage="All transactions requests {br} were answered"
          values={{ br: <br /> }}
        />
      </div>
    </div>
  );
}

export default BlankTransaction;
