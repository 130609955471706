// @flow
import React, { useMemo, useRef, useEffect, useState } from 'react';

import { useHintStyle } from './sheet';

type IProps = {
  text: string,
  optionEl: HTMLButtonElement,
  width: number,
};

const OptionHint = ({ text, optionEl, width }: IProps) => {
  const el = useRef(null);
  const [hintHeight, setHintHeight] = useState(0);

  const classes = useHintStyle();

  const isShow = useMemo(() => optionEl.offsetWidth > width, [width, optionEl]);

  useEffect(() => {
    setTimeout(() => {
      if (el.current) {
        setHintHeight(el.current.offsetHeight);
      }
    }, 0);
  }, [optionEl]);

  const style = useMemo(() => {
    const { x, y } = optionEl.getBoundingClientRect();
    return {
      top: y - hintHeight - 10,
      left: x,
      maxWidth: width,
      // minWidth: width,
    };
  }, [optionEl, hintHeight, width]);

  return isShow ? (
    <div ref={el} style={style} className={classes.hintBox}>
      <div className={classes.hintText}>{text}</div>
      <div className={classes.arrow} />
    </div>
  ) : null;
};

export default OptionHint;
