/* @flow */
import place from './images/place.svg';

export default {
  box: {
    display: 'flex',
    alignItems: 'center',
    maxWidth: '100%',
  },
  match: {
    backgroundColor: 'rgb(231, 245, 234)',
  },
  button: {
    display: 'block',
    border: '0 none',
    textAlign: 'left',
    padding: 0,
    outline: '0 none',
    cursor: 'pointer',
    '&[disabled]': {
      cursor: 'wait',
    },
    minHeight: 16,
    minWidth: 16,
    background: `url( ${place} ) 50% 50% no-repeat`,
    marginLeft: 5,
  },

  item: {
    flexGrow: 1,
    maxWidth: 'calc( 100% - 16px )',
  },
};
