// @flow
import React from 'react';
import { put, select, call } from 'redux-saga/effects';
import Api, { doLogout } from 'domain/api';
import { FormattedMessage } from 'react-intl';
import { get } from 'lodash/fp';
import { yieldSuccessNotification, yieldValidationNotification, yieldErrorNotification } from 'lib/toasts';
import { storage } from 'lib/storage';
import { localeSelector } from 'domain/env/envSelector';
import * as actions from './organizationActions';
import { organizationIdSelector, setOrganizationIdAction, tokenSelector, signInWithOrganizationAction } from '../env';
import { type OrganizationMainFields, type updateFields } from './types.js.flow';
import {
  organizationAdapter,
  organizationFeaturesAdapter,
  orgBackupConnectionsAdapter,
  organizationListAdapter,
  signingsFactory,
  signerFactory,
  OrganizationApiKeysAdapter,
  organizationApiKeyFactory,
} from './helpers';
import { currentOrganizationSelector } from 'domain/organization/organizationSelector';
import { resetConfigurationAction } from 'domain/companies/companiesActions';
import { configurationCompanySelector } from 'domain/companies/companiesSelector';

type UploadLogoSagaParams<T> = {|
  file: File,
  resolve: (result: Promise<T> | T) => void,
  reject: (err: mixed) => void,
|};

export function* ensureGetOrganizationFeatures(): Generator<*, void, any> {
  const organizationId = yield select(organizationIdSelector);
  try {
    const { data } = yield call(Api.getOrgFeatures, {
      id: organizationId,
    });

    yield put({
      type: actions.getOrganizationFeatures.success,
      payload: organizationFeaturesAdapter(data),
    });
  } catch (err) {
    yield doLogout(actions.getOrganizationFeatures, err);
  }
}

export function* ensureSetOrganizationFeatures({
  key,
  value,
}: {
  key: string,
  value: boolean,
}): Generator<*, void, any> {
  const organizationId = yield select(organizationIdSelector);
  try {
    const { data } = yield call(Api.setOrgFeatures, {
      data: {
        [key]: value,
      },
      id: organizationId,
    });
    yield put({
      type: actions.setOrganizationFeaturesAction.success,
      payload: organizationFeaturesAdapter(data),
    });
    yieldSuccessNotification(
      <FormattedMessage id="form.organization.features.update.success" defaultMessage="Settings updated" />,
    );
  } catch (err) {
    yield doLogout(actions.setOrganizationFeaturesAction, err);
  }
}

export function* ensureGetOrganization(): Generator<*, void, any> {
  const organizationId = yield select(organizationIdSelector);
  if (!organizationId) return;
  try {
    const { data } = yield call(Api.getOrganization, {
      id: organizationId,
    });
    yield call(ensureGetOrganizationFeatures);
    yield put({
      type: actions.setOrganization.success,
      payload: organizationAdapter(data),
    });
  } catch (err) {
    yield doLogout(actions.getOrganization, err);
  }
}

export function* ensureUnsetCurrentOrganization(): Generator<*, void, any> {
  const organizationTempId = yield select(currentOrganizationSelector);
  if (organizationTempId) {
    yield put({
      type: actions.unsetCurrentOrganization.type,
      payload: null,
    });
  }
}

export function* ensureGetOrganizationBackupConnections(): Generator<*, void, any> {
  const organizationId = yield select(organizationIdSelector);
  try {
    const { data } = yield call(Api.getOrgBackupConnections, {
      id: organizationId,
    });

    yield put({
      type: actions.getOrgBackupConnections.success,
      payload: orgBackupConnectionsAdapter(data),
    });
  } catch (err) {
    yield doLogout(actions.getOrgBackupConnections, err);
  }
}

// eslint-disable-next-line max-len
export function* ensureGetBackupSSOParams({ cloudStorageType, resolve, reject }): Generator<*, void, any> {
  try {
    const { data } = yield call(Api.getBackupSSOParams, {
      params: {
        cloudStorageType,
        redirectUrl: window.location.href,
      },
    });
    resolve(data);
  } catch (err) {
    reject(err);
    yield doLogout(actions.getBackupSSOParamsAction, err);
  }
}

export function* ensureDisconnectOrgBackup({ payload }): Generator<*, void, any> {
  try {
    yield call(Api.disconnectOrgBackup, {});
    yield call(ensureGetOrganizationBackupConnections);
    yieldSuccessNotification(
      <FormattedMessage
        values={{
          drive: payload.name,
        }}
        id="configurations.backup.toast.disconnect.success"
        defaultMessage="{drive} disconnected successfully"
      />,
    );
  } catch (err) {
    yieldValidationNotification(err);
    yield doLogout(actions.disconnectOrgBackupAction, err);
  }
}

export function* ensureGetOrganizationList(): Generator<*, number, any> {
  try {
    const { data } = yield call(Api.getOrganizationList);
    const orgList = organizationListAdapter(data);
    yield put({
      type: actions.getOrganizationList.success,
      payload: orgList,
    });
    return orgList.size;
  } catch (err) {
    return 0;
    // no logout as this is invoked on signin flow
    // any errors must allow  flow to further continue
  }
}

export function* ensureUpdateOrganization(fields: updateFields): Generator<*, void, any> {
  const organizationId = yield select(organizationIdSelector);
  const company = yield select(configurationCompanySelector);
  try {
    const { data } = yield call(Api.updateOrganization, {
      data: fields,
      id: organizationId,
    });
    yield call(ensureGetOrganizationFeatures);

    yield put({
      type: actions.setOrganization.success,
      payload: organizationAdapter(data),
    });

    /*
    reset the company config, if it was load, because some changes to the organization settings
    may change company settings, so we should get they again
     */
    if (company.id) {
      yield put({
        type: resetConfigurationAction.type,
      });
    }

    yieldSuccessNotification(
      <FormattedMessage id="forms.organization.toast.messages.org_updated" defaultMessage="Organization updated" />,
    );
  } catch (err) {
    yieldValidationNotification(err);
    yield doLogout(actions.setOrganization, err);
  }
}

function* SignInUserWithOrganization(email) {
  yield put({ type: signInWithOrganizationAction.request });
  const t1Token = yield select(tokenSelector);
  const language = yield select(localeSelector);
  try {
    const {
      data: { dokkaToken },
    } = yield call(Api.signIn, { data: { email, t1Token, language } });
    yield put({
      type: signInWithOrganizationAction.type,
      payload: {
        dokkaToken,
      },
    });
    yield put({
      type: signInWithOrganizationAction.success,
    });
    storage().token().set(dokkaToken);
  } catch (err) {
    yield put({
      type: signInWithOrganizationAction.failure,
    });
    yield doLogout(signInWithOrganizationAction, err);
  }
}

export function* ensureCreateOrganization(fields: OrganizationMainFields): Generator<*, void, any> {
  try {
    const { data } = yield call(Api.createOrganization, {
      data: fields,
    });
    yield put({
      type: actions.setOrganization.success,
      payload: organizationAdapter(data),
    });
    yield put({
      type: setOrganizationIdAction.type,
      payload: {
        organizationId: data.id,
      },
    });
    yield call(SignInUserWithOrganization, data.id);
    yield call(ensureGetOrganizationFeatures);
  } catch (err) {
    yieldValidationNotification(err);
    yield doLogout(actions.setOrganization, err);
  }
}
// eslint-disable-next-line max-len
export function* ensureUploadLogoOrganization({
  file,
  resolve,
  reject,
}: UploadLogoSagaParams<string>): Generator<*, void, any> {
  const organizationId = yield select(organizationIdSelector);
  try {
    const formData = new global.FormData();
    formData.append('picture', file);
    const { data } = yield call(Api.updateOrganizationLogo, {
      data: formData,
      id: organizationId,
    });
    yield put({
      type: actions.uploadLogoOrganization.success,
      payload: data,
    });
    if (typeof resolve === 'function') resolve(data.picture);
  } catch (err) {
    if (typeof reject === 'function') reject(err);
    yield doLogout(actions.uploadLogoOrganization, err);
  }
}

export function* ensureGetOrganizationSignings(): Generator<*, void, any> {
  const organizationId = yield select(organizationIdSelector);
  try {
    const { data } = yield call(Api.getSignings, {
      type: 'org',
      id: organizationId,
    });
    yield put({
      type: actions.getOrganizationSignings.success,
      payload: signingsFactory(data),
    });
  } catch (err) {
    yield doLogout(actions.getOrganizationSignings, err);
  }
}

export function* ensureUpdateOrganizationSignings(formData): Generator<*, void, any> {
  const organizationId = yield select(organizationIdSelector);
  try {
    const { data } = yield call(Api.updateSignings, {
      data: formData,
      type: 'org',
      id: organizationId,
    });
    yield put({
      type: actions.updateOrganizationSignings.success,
      payload: signerFactory(data),
    });
    yieldSuccessNotification(
      <FormattedMessage
        id="forms.organization.toast.messages.org_signings_updated"
        defaultMessage="Organization signing data updated"
      />,
    );
  } catch (err) {
    yieldValidationNotification(err);
    yield doLogout(actions.updateOrganizationSignings, err);
  }
}

// eslint-disable-next-line max-len
export function* ensureSetOrganizationSigningsGuid(formData: { guid: string }): Generator<*, void, any> {
  const organizationId = yield select(organizationIdSelector);
  try {
    const { data } = yield call(Api.setGuidSignings, {
      data: { guid: formData.guid },
      type: 'org',
      id: organizationId,
    });
    yield put({
      type: actions.setOrganizationSigningsGuid.success,
      payload: data.guid,
    });
    yieldSuccessNotification(
      <FormattedMessage
        id="forms.organization.toast.messages.org_signings_updated"
        defaultMessage="Organization signing data updated"
      />,
    );
  } catch (err) {
    yieldValidationNotification(err);
    yield doLogout(actions.updateOrganizationSignings, err);
  }
}

export function* ensureRemoveOrganizationSigner({ tokenName, idx }): Generator<*, void, any> {
  const organizationId = yield select(organizationIdSelector);
  try {
    if (tokenName) {
      yield call(Api.removeSignings, {
        data: {
          token_name: tokenName,
        },
        type: 'org',
        id: organizationId,
      });
    }
    yield put({
      type: actions.removeOrganizationSigner.success,
      payload: {
        tokenName,
        idx,
      },
    });
    yieldSuccessNotification(
      <FormattedMessage
        id="forms.organization.toast.messages.org_signings_removed"
        defaultMessage="Organization signing data removed"
      />,
    );
  } catch (err) {
    yieldValidationNotification(err);
    yield doLogout(actions.removeOrganizationSigner, err);
  }
}

export function* ensureRemoveAllOrganizationSignings(): Generator<*, void, any> {
  const id = yield select(organizationIdSelector);
  try {
    yield call(Api.deleteAllSignings, {
      type: 'org',
      id,
    });
    yield put({
      type: actions.removeAllOrganizationSigner.success,
    });
    yieldSuccessNotification(
      <FormattedMessage
        id="forms.organization.toast.messages.org_signings_removed"
        defaultMessage="Organization signing data removed"
      />,
    );
  } catch (err) {
    yield doLogout(actions.removeAllOrganizationSigner, err);
  }
}

export function* ensureGetOrganizationPasswordExpiration(): Generator<*, void, any> {
  const organizationId = yield select(organizationIdSelector);
  try {
    const { data } = yield call(Api.getPasswordExpiration, {
      id: organizationId,
    });
    yield put({
      type: actions.getPasswordExpirationAction.success,
      payload: data.expiration_days,
    });
  } catch (err) {
    yield doLogout(actions.getPasswordExpirationAction, err);
  }
}

export function* ensureUpdateOrganizationPasswordExpiration(values): Generator<*, void, any> {
  const organizationId = yield select(organizationIdSelector);
  try {
    const { data } = yield call(Api.updatePasswordExpiration, {
      data: { ...values },
      id: organizationId,
    });
    yield put({
      type: actions.updatePasswordExpirationAction.success,
      payload: data.expiration_days,
    });
    yieldSuccessNotification(
      <FormattedMessage id="form.organization.features.update.success" defaultMessage="Settings updated" />,
    );
  } catch (err) {
    yieldValidationNotification(err);
    yield doLogout(actions.updatePasswordExpirationAction, err);
  }
}

export function* getOrganizationApiKeys(): Generator<*, void, any> {
  try {
    yield put({
      type: actions.getOrganizationApiKeysAction.request,
    });
    const { data } = yield call(Api.getOrganizationApiKeys, {});
    yield put({
      type: actions.getOrganizationApiKeysAction.success,
      payload: OrganizationApiKeysAdapter(data),
    });
  } catch (err) {
    yield put({
      type: actions.getOrganizationApiKeysAction.failure,
    });
    yield doLogout(actions.getOrganizationApiKeysAction, err);
  }
}

export function* createOrganizationApiKey({ payload: { resolve, reject, ...payload } }): Generator<*, void, any> {
  try {
    const { data } = yield call(Api.createOrganizationApiKey, {
      data: { ...payload },
    });
    if (typeof resolve === 'function') resolve();
    yield put({
      type: actions.createOrganizationApiKeyAction.success,
      payload: organizationApiKeyFactory(data),
    });
    yieldSuccessNotification(
      <FormattedMessage id="configurations.apiKeys.toast.create.success" defaultMessage="API key created successfully" />,
    );
  } catch (err) {
    if (typeof reject === 'function') reject(err.response);
    const error = get(['response', 'data', 'message'])(err);
    if (error) {
      yieldErrorNotification(error);
    }
    yield doLogout(actions.createOrganizationApiKeyAction, err);
  }
}

export function* deleteOrganizationApiKey({ payload: { apiKey } }): Generator<*, void, any> {
  try {
    yield call(Api.deleteOrganizationApiKey, { apiKey });
    yield put({
      type: actions.deleteOrganizationApiKeyAction.success,
      payload: { apiKey },
    });
    yieldSuccessNotification(
      <FormattedMessage
        id="configurations.apiKeys.toast.delete.success"
        defaultMessage="API key deleted successfully"
      />,
    );
  } catch (err) {
    const error = get(['response', 'data', 'message'])(err);
    if (error) {
      yieldErrorNotification(error);
    }
    yield doLogout(actions.deleteOrganizationApiKeyAction, err);
  }
}

export function* updateOrganizationApiKey({ payload: { api_key: apiKey, resolve, reject, ...payload },
}): Generator<*, void, any> {
  try {
    const { data } = yield call(Api.updateOrganizationApiKey, {
      apiKey,
      data: {
        ...payload,
      },
    });

    yield put({
      type: actions.updateOrganizationApiKeyAction.success,
      payload: organizationApiKeyFactory(data),
    });
    yieldSuccessNotification(
      <FormattedMessage
        id="configurations.apiKeys.toast.update.success"
        defaultMessage="API key updated successfully"
      />,
    );
    if (typeof resolve === 'function') resolve();
  } catch (err) {
    const error = get(['response', 'data', 'message'])(err);

    if (error) {
      yieldErrorNotification(error);
    }
    if (typeof reject === 'function') reject(err);
    yield doLogout(actions.updateOrganizationApiKeyAction, err);
  }
}
