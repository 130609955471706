import { styled, alpha } from '@mui/material/styles';
import LinkMui from '@mui/material/Link';

export const GhostTabsStyled = styled('div')(({ theme }) => ({
  backgroundColor: alpha(theme.palette.primary.main, 0.08),
  padding: theme.spacing(0.5),
  borderRadius: 6,
  display: 'flex',
}));

export const GhostTabStyled = styled(LinkMui)(({ theme }) => ({
  flex: '1 1 50%',
  textAlign: 'center',
  borderRadius: 6,
  color: theme.palette.grey[500],
  padding: theme.spacing(0.5, 1),
  fontSize: theme.typography.pxToRem(12),

  '&:hover, &.tab-active': {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.common.white,
    boxShadow: `2px 2px 4px ${alpha(theme.palette.primary.main, 0.12)}`,
  },

  '& + &': {
    marginLeft: theme.spacing(0.5),
  },
}));
