// @flow
import React, { useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useDrop } from 'react-dnd';
import { useIntl } from 'react-intl';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { ExtractLinesAction } from 'domain/textract';

import { alpha } from '@mui/material/styles';
import { grey } from '@mui/material/colors';

const DropContainer = () => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const ref = useRef(null);

  const [{ canDrop, isOverCurrent }, drop] = useDrop({
    accept: 'extractLines',
    canDrop: () => true,
    drop: () => {
      if (isOverCurrent) {
        dispatch(ExtractLinesAction());
      }
    },
    collect: (monitor) => ({
      canDrop: monitor.canDrop(),
      isOverCurrent: monitor.isOver({ shallow: true }),
    }),
  });

  drop(ref);

  return (
    <Stack
      // 28px add column button size
      width="calc(100% + 28px)"
      height="100%"
      justifyContent="center"
      alignItems="center"
      zIndex="10"
      ref={ref}
      position="absolute"
      display={canDrop ? 'flex' : 'none'}
      borderRadius={1}
      bgcolor={alpha(grey[600], 0.6)}
    >
      <Typography variant="h6" color="common.white" textAlign="center">
        {formatMessage({
          id: '3WayMatching.dropZone',
          defaultMessage: 'Drop the table here to import line items from a document',
        })}
      </Typography>
    </Stack>
  );
};

export default DropContainer;
