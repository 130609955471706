/* @flow */

import moment from 'moment';

export const isSameDay = (date1: string, date2: string): boolean => moment(date1).diff(date2, 'day') === 0;

export const formatDate = (d: ?Date): ?string => {
  if (d instanceof Date) {
    return moment(d).format('DD/MM/YYYY');
  }
  return null;
};
