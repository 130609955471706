// @flow
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { currentCompanySelector } from 'domain/documents';
import { toggleEventPanelAction } from 'domain/events';
import * as ACL from 'domain/restriction';

import IconButton from '@mui/material/IconButton';
import Tooltip from 'components/mui/Tooltip';

import HistoryIcon from '@mui/icons-material/History';

import elements from 'components/elements';

const mapStateToProps = (state) => ({
  currentCompanyID: currentCompanySelector(state),
  isGranted: ACL.isGranted(state),
});

const EventsHistoryIconButton = () => {
  const dispatch = useDispatch();
  const { currentCompanyID, isGranted } = useSelector(mapStateToProps);
  const isSupplier = isGranted(ACL.IS_SUPPLIER_USER);
  const isAvailable = currentCompanyID && !isSupplier;

  const onClick = () => {
    dispatch(toggleEventPanelAction());
  };

  return (
    isAvailable && (
      <Tooltip t={{ id: 'header.menu.history', defaultMessage: 'History' }}>
        <IconButton color="primary" onClick={onClick} data-element={elements.history.openHistoryButton}>
          <HistoryIcon />
        </IconButton>
      </Tooltip>
    )
  );
};

export default EventsHistoryIconButton;
