/* @flow */
import * as React from 'react';
import type { VGridProps } from '../Types.js.flow';
import { List } from 'immutable';
import RowMessage from './RowMessage';
import type { RowMessage as RowMessageType } from 'domain/journal/types.js.flow';

type WithRowMessagesProps = {|
  messageRender?: (m: RowMessageType) => React$ComponentType<ActionMessagesProps>,
  rowMessages: List<RowMessageType>,
  rowMessagesRelativeCol: string,
|};

type Props = {|
  ...$Exact<VGridProps>,
  ...WithRowMessagesProps,
|};

type State = {};

const withRowMessages = (WrappedComponent: React$ComponentType<VGridProps>) => {
  class WithRowMessages extends React.Component<Props, State> {
    static defaultProps = {
      getItem: () => null,
    };

    getMessages = (row: string) => {
      const { rowMessages } = this.props;
      return rowMessages.find((m) => m.row === row);
    };

    getItem = (row: number, col: string) => {
      const { getItem, rowMessagesRelativeCol } = this.props;
      const message = rowMessagesRelativeCol === col ? this.getMessages(row) : null;
      return (
        <React.Fragment>
          {getItem(row, col)}
          {message && this.renderMessage(message)}
        </React.Fragment>
      );
    };

    renderMessage = (message: RowMessageType) => {
      const { messageRender } = this.props;
      return messageRender ? messageRender(message) : <RowMessage {...message.toJS()} />;
    };

    render() {
      return <WrappedComponent {...this.props} getItem={this.getItem} />;
    }
  }

  WithRowMessages.displayName = WrappedComponent.displayName;

  return WithRowMessages;
};

export default withRowMessages;
