import mail from './mail.svg';
import mailH from './mail-h.svg';

// eslint-disable-next-line no-unused-vars
export default (theme) => ({
  requestContainer: {
    display: 'flex',
  },
  indicator: {
    background: `url(${mailH}) scroll no-repeat`,
    position: 'absolute',
    width: 24,
    height: 24,
    zIndex: 10,
    right: 0,
    top: -1,
    border: '0 none',
    cursor: 'pointer',
  },
  read: {
    background: theme.colors.primaryLight,
    maskImage: `url(${mail})`,
  },
});
