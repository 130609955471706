// @flow
import React, { useMemo } from 'react';
import { isDomainSSOOnly, isRedirectFromSSO } from 'amplify/helpers';
import { passwordLessAuthDomains } from 'domain/env/helpers';

import AuthLayout from './Layout';
import PasswordLessAuth from 'pages/authPage/PasswordLessAuth/screens/accessLink';
import LegacyBrowser from 'pages/authPage/Layout/LegacyBrowser';

const AuthPage = () => {
  const authType = useMemo(() => {
    if (passwordLessAuthDomains.includes(window.location.host)) return 'passwordLess';
    return 'default';
  }, []);

  return isDomainSSOOnly() || isRedirectFromSSO() ? (
    'redirecting...'
  ) : (
    <AuthLayout>
      {authType === 'passwordLess' && <PasswordLessAuth />}
      <LegacyBrowser />
    </AuthLayout>
  );
};

export default AuthPage;
