/* @flow */
import calendar from './calendar.svg';
import info from './info.svg';
import chat from './chat.svg';
import arrow from './arrow.svg';
import done from './done.svg';

export default (theme) => ({
  container: {
    paddingBottom: 30,
  },
  head: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  amount: {
    color: theme.colors.secondaryDark,
    fontSize: 19,
    fontWeight: 600,
  },
  minus: {
    color: '#BC5353',
  },
  item: {
    marginTop: 13,
    '&:first-child': {
      marginTop: 0,
    },
  },
  icon: {
    position: 'relative',
    fontWeight: 'bold',
    '&:before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      width: 24,
      height: 24,
      left: 0,
      top: 0,
      margin: 'auto',
      bottom: 0,
    },
  },
  status: {
    borderRadius: '4px 4px 0 0',
    textAlign: 'center',
    color: '#fff',
    height: 22,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 12,
    position: 'relative',
    transition: '0.3s background',
    '&$up': {
      height: 22,
    },
  },
  unread: {
    backgroundColor: '#5A6872',
    '&:before': {
      content: '""',
      display: 'block',
      width: 6,
      height: 6,
      background: '#fff',
      border: '4px solid #F47F2A',
      borderRadius: '50%',
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 6,
      margin: 'auto',
    },
  },
  read: {
    backgroundColor: '#8C9BA5',
  },
  content: {
    border: '1px solid #D3D6D9',
    borderTopWidth: 0,
    padding: [15, 25, 30, 25],
    borderRadius: '0 0 4px 4px',
    '@media (max-width: 749px)': {
      padding: [12, 5],
    },
  },
  date: {
    color: '#5A6872',
    fontSize: 16,
    marginBottom: 8,
    lineHeight: '24px',
    paddingLeft: 30,
    fontWeight: 400,
    '&:before': {
      background: `url("${calendar}") scroll no-repeat`,
    },
  },
  attribute: {
    color: '#171A1C',
    fontSize: 16,
    display: 'flex',
    lineHeight: 1.6667,
    paddingLeft: 30,
  },
  params: {
    color: '#8C9BA5',
    paddingLeft: 10,
  },
  header: {
    marginBottom: 10,
  },
  description: {
    marginBottom: 20,
  },
  subTitle: {
    fontSize: 14,
  },
  descriptionTitle: {
    textTransform: 'uppercase',
    paddingLeft: 30,
    '&:before': {
      background: `url("${info}") scroll no-repeat`,
    },
  },
  text: {
    color: '#171A1C',
    fontSize: 12,
    paddingLeft: 30,
    maxWidth: '80%',
  },
  messageTitle: {
    paddingLeft: 30,
    '&:before': {
      background: `url("${chat}") scroll no-repeat`,
    },
  },
  blank: {
    textAlign: 'center',
    margin: 'auto',
  },
  done: {
    height: 256,
    width: 256,
    backgroundColor: theme.colors.primaryLight,
    WebkitMask: `url("${done}") 50% 50% no-repeat`,
  },
  message: {
    color: '#333333',
    fontSize: 24,
    lineHeight: '28px',
    marginTop: 30,
  },
  up: {},
  indicator: {
    width: 22,
    height: 22,
    display: 'inline-block',
    position: 'absolute',
    right: 8,
    background: `url(${arrow}) scroll no-repeat center center`,
    transition: '0.3s transform',
    '&$up': {
      transform: 'rotate(180deg)',
    },
  },
});
