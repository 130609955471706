import { useMemo } from 'react';
import { LicenseManager } from 'ag-grid-enterprise';
import isEmpty from 'lodash/isEmpty';

import useAgGridGlobalTheme from 'components/AgGrid/hooks/useAgGridGlobalTheme';
import { SYMBOL_FOR_EMPTY_CELL } from 'components/AgGrid/hooks/columnTypes/useColumnTypes';
import { isServiceColumn } from 'components/AgGrid/helpers';

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-material.css';

LicenseManager.setLicenseKey(process.env.REACT_APP_AG_GRID_LICENSE);

const useAgGridDefaultConfig = (useCustomStyles = () => {}) => {
  useAgGridGlobalTheme();
  useCustomStyles();
  // TODO: ag-grid separate SSRM and CSRM props
  return useMemo(
    () => ({
      debug: false,
      columnMenu: 'legacy', // TODO: ag-grid review maybe migrate to new type menu 'columnMenu="new"'
      detailCellRenderer: 'masterViewCellRenderer',
      animateRows: false, // Row Animations can be a performance bottleneck
      // this is needed for tooltip,
      // columnHoverHighlight add .ag-column-hover on cell for finding hovered cell
      columnHoverHighlight: true,
      suppressClickEdit: true,
      suppressMaxRenderedRowRestriction: true,
      suppressMenuHide: true,
      suppressScrollOnNewData: false,
      suppressColumnMoveAnimation: true,
      suppressMultiSort: true,
      suppressMoveWhenColumnDragging: true,
      suppressRowClickSelection: true, // TODO: deprecated need use new selection api
      enableCellSelection: true, // TODO: deprecated need use new selection api
      enableRangeHandle: true, // TODO: deprecated need use new selection api
      enableRangeSelection: true, // TODO: deprecated need use new selection api
      rowSelection: 'multiple', // TODO: deprecated need use new selection api
      localeText: {
        applyFilter: 'OK',
        cancelFilter: 'Cancel',
        resetFilter: 'Clear Filter',
      },
      tooltipShowDelay: 0,
      defaultColDef: {
        pinned: null,
        sortable: true,
        resizable: true,
        enableValue: true,
        minWidth: 100,
        valueFormatter: ({ value, colDef }) => {
          if (isServiceColumn(colDef?.field)) return null;

          return isEmpty(value) ? SYMBOL_FOR_EMPTY_CELL : value;
        },
        cellClassRules: {
          'cell-negative-number': ({ value }) => typeof value === 'number' && value < 0,
        },
      },
      scrollbarWidth: 8,
      alwaysShowHorizontalScroll: true,
      alwaysShowVerticalScroll: true,
      statusBar: {
        statusPanels: [
          {
            statusPanel: 'amountSumStatusPanel',
          },
        ],
      },
      getContextMenuItems: () => ['copy', 'copyWithHeaders', 'paste'],
      getRowStyle: (e) => {
        if (e.node.rowPinned) return { border: 'none' };
        return e.node.detail ? { overflowY: 'auto', backgroundColor: '#fff' } : undefined;
      },
      getMainMenuItems: (e: {| defaultItems: string[] |}) => {
        const {
          defaultItems = [],
          column: { colId },
        } = e;

        const currentItems = Array.isArray(defaultItems) && defaultItems.slice(0, -3);

        // suppress autosize menu option for system columns
        return colId.startsWith('__') ? currentItems.filter((i) => !['autoSizeThis'].includes(i)) : currentItems;
      },
    }),
    [],
  );
};

export default useAgGridDefaultConfig;
