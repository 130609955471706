import DividerMui from '@mui/material/Divider';
import Box from '@mui/material/Box';

import { styled } from '@mui/material/styles';

export const MessagePeriodDivider = styled(DividerMui)(({ theme }) => ({
  marginTop: theme.spacing(1.5),
  marginBottom: theme.spacing(1.5),
  color: theme.palette.grey[500],
  fontWeight: 500,
  fontSize: theme.typography.pxToRem(14),
}));

export const NoMessages = styled('div')(({ theme }) => ({
  textAlign: 'center',
  padding: theme.spacing(2),
  maxWidth: '60%',
  color: theme.palette.grey[500],
  margin: '0 auto',
}));

export const MessagesThread = styled('div')(() => ({
  overflowY: 'auto',
  flex: '1 1 auto',
  height: '100%',
}));

export const MessagesList = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100%',
  padding: theme.spacing(2),
}));
