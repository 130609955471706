// @flow
import React, { useCallback, useEffect, useState, useRef } from 'react';
import Api from 'domain/api';
import { useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';

import LayoutAside from 'components/mui/Layouts/LayoutAside';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import AlertTitle from '@mui/material/AlertTitle';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { DeferredTaskAlert, DeferredTaskValue } from 'pages/deferredTask/StyledCompoents';

// POSSIBLE STATUSES FROM B-END
// PENDING = "PENDING"
// STARTED = "STARTED"
// SUCCESS = "SUCCESS"
// FAILURE = "FAILURE"
// RETRY = "RETRY" - NOT USED NOW
// REVOKED = "REVOKED" - NOT USED NOW

const DeferredTask = () => {
  const { taskId } = useParams();
  const { formatMessage } = useIntl();
  const [status, setStatus] = useState('STARTED');
  const [url, setUrl] = useState();
  const [intlObj, setIntlObj] = useState();
  const [fakeProgress, setFakeProgress] = useState(0);
  const fetchIntervalIDRef = useRef(null);
  const progressIntervalIDRef = useRef(null);
  const timeRef = useRef(0);
  const timeConstantRef = useRef(15000);
  const progressIntervalFrequencyRef = useRef(1000);

  const handleResponse = useCallback((response) => {
    setStatus(response.status);
    setUrl(response.result);
  }, []);

  const fetchApi = useCallback(async () => Api.pollingDeferredTask({ taskId }), [taskId]);

  useEffect(() => {
    try {
      fetchApi().then(({ data }) => {
        handleResponse(data);

        if (['STARTED', 'PENDING'].includes(data.status)) {
          fetchIntervalIDRef.current = setInterval(() => {
            fetchApi().then(({ data: response }) => {
              handleResponse(response);
            });
          }, 5000);
        }
      });
    } catch (error) {
      console.error('[ERROR] deferred task polling:', error);
    }

    return () => clearInterval(fetchIntervalIDRef.current);
  }, [fetchApi, handleResponse]);

  useEffect(() => {
    if (['STARTED', 'PENDING'].includes(status)) {
      progressIntervalIDRef.current = setInterval(() => {
        timeRef.current += progressIntervalFrequencyRef.current;

        const value = 1 - Math.exp((-1 * timeRef.current) / timeConstantRef.current);
        const percent = (value * 100).toFixed(1);

        setFakeProgress(percent);
      }, progressIntervalFrequencyRef.current);
    }
    if (['SUCCESS', 'FAILURE'].includes(status)) {
      setFakeProgress(100);
      clearInterval(fetchIntervalIDRef.current);
      clearInterval(progressIntervalIDRef.current);
    }
  }, [status]);

  useEffect(() => {
    if (url) {
      window.open(url, '_blank');
    }
  }, [url]);

  useEffect(() => {
    switch (status) {
      case 'STARTED':
      case 'PENDING':
        setIntlObj({ id: 'deferredTask.grid.export.preparingExportFile', defaultValue: 'Preparing export file ....' });
        break;
      case 'FAILURE':
        setIntlObj({ id: 'app.generalUnknownError', defaultValue: 'Something went wrong' });
        break;
      default:
        break;
    }
  }, [status]);

  // using for waiting async task for generate export file from workspace grid
  // could be improved in the future for other async tasks
  // in url params passed type where we can determinate which type of task we are polling
  // and improve UI and code according type of task
  return (
    <LayoutAside>
      <Container maxWidth={false} sx={{ py: 3 }}>
        <Box maxWidth="35%">
          {['STARTED', 'PENDING'].includes(status) && (
            <DeferredTaskAlert
              severity="info"
              icon={<CircularProgress size="20px" />}
              action={<DeferredTaskValue>{fakeProgress}%</DeferredTaskValue>}
            >
              {intlObj && <Typography variant="body2">{formatMessage(intlObj)}</Typography>}
            </DeferredTaskAlert>
          )}
          {status === 'FAILURE' && (
            <DeferredTaskAlert severity="error">
              <Typography variant="body2" color="error">
                {formatMessage(intlObj)}
              </Typography>
            </DeferredTaskAlert>
          )}
          {status === 'SUCCESS' && (
            <DeferredTaskAlert
              severity="info"
              action={
                <Button href={url} sx={{ mx: 'auto', minWidth: 120 }}>
                  {formatMessage({ id: 'button.download', defaultValue: 'Download' })}
                </Button>
              }
            >
              <AlertTitle>
                {formatMessage({
                  id: 'deferredTask.grid.export.success.title',
                  defaultMessage: 'Download export file',
                })}
              </AlertTitle>
              <Typography variant="body2">
                {formatMessage({
                  id: 'deferredTask.grid.export.success.description',
                  defaultMessage:
                    'If the download didn’t start automatically, you can download the export file by pressing the button.',
                })}
              </Typography>
            </DeferredTaskAlert>
          )}
        </Box>
      </Container>
    </LayoutAside>
  );
};

export default DeferredTask;
