// @flow
import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import {
  configurationCompanySelector,
  type ConfigurationCompany,
  updateLogoCompany,
  updateCompany,
} from 'domain/companies';
import { organization as organizationSelector, type OrganizationType } from 'domain/organization';
import { type main } from 'domain/organization/types.js.flow';
import MainForm from 'pages/common/forms/main';
import { prepareToForm, prepareToSubmit } from 'pages/configurations/helpers';

type Props = {
  classes: {
    [key: string]: string,
  },
  company: ConfigurationCompany,
  updateCompany: (data: main) => void,
  uploadLogo: (file: File) => void,
  organization: OrganizationType,
};

type EmailParamsType = {|
  address: string,
  domain: string,
  initialAddress: string,
  isDefault: boolean,
|};

class MainFormContainer extends Component<Props> {
  onSubmit = (formData: main) => {
    const { domain, initialAddress } = this.getEmailParams();

    const preparedFormData = prepareToSubmit(formData);

    const data = {
      ...preparedFormData.toJS(),
      email_box: `${formData.email_box || initialAddress}@${domain}`,
    };
    this.props.updateCompany({ ...data, update_tab: 'main_tab' });
  };

  onUploadLogo = (file, resolve, reject) => {
    const { uploadLogo } = this.props;

    uploadLogo({ file, resolve, reject });
  };

  get initialValues(): ConfigurationCompany {
    const { organization, company } = this.props;

    const updatedCompany = company.set('email_box', this.getEmailParams().address);

    if (!company.country || company.country.length < 1) {
      return updatedCompany.set('country', organization.get('country'));
    }

    return prepareToForm(updatedCompany);
  }

  getEmailParams = (): EmailParamsType => {
    const { company } = this.props;

    const [address, domain] = company.email_box.split('@');
    const isDefault = address === company.id;

    return {
      address: isDefault ? '' : address,
      initialAddress: company.id,
      domain,
      isDefault,
    };
  };

  render() {
    return (
      <MainForm
        initialValues={this.initialValues}
        onLogoChange={this.onUploadLogo}
        onSubmit={this.onSubmit}
        context="company"
        emailParams={this.getEmailParams()}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  company: configurationCompanySelector(state),
  organization: organizationSelector(state),
});

export default compose(connect(mapStateToProps, { uploadLogo: updateLogoCompany, updateCompany }))(MainFormContainer);
