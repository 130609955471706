// @flow
import React, { useCallback, useContext } from 'react';
import { generatePath, useParams } from 'react-router-dom';
import ROUTES_PATH from 'domain/router/routesPathConfig';
import CompanyContext from 'pages/company/CompanyContext';

import EmptyContainer from './components/EmptyContainer';

import noResults from './images/noResults.png';

const NoResult: React$StatelessFunctionalComponent<Props> = () => {
  const params = useParams();
  const { companyType } = useContext(CompanyContext);

  const resetSearchAction = useCallback(() => {
    const path =
      companyType === 'confidential'
        ? ROUTES_PATH.COMPANY_CONFIDENTIAL_WORKSPACE.absolute
        : ROUTES_PATH.COMPANY_WORKSPACE.absolute;

    return generatePath(path, { ...params });
  }, [params, companyType]);

  return (
    <EmptyContainer
      link={resetSearchAction}
      imageSrc={noResults}
      title={{ id: 'document.search.no_result', defaultMessage: 'No results have been found' }}
      description1={{
        id: 'document.search.no_document_decription_1',
        defaultMessage: "We can't find any document matching your search.",
      }}
      description2={{
        id: 'document.search.no_document_decription_2',
        defaultMessage: 'Please, try a different search.',
      }}
      buttonText={{
        id: 'document.search.no_document.reset_search',
        defaultMessage: 'Reset search',
      }}
    />
  );
};

export default NoResult;
