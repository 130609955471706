// @flow
import React from 'react';

import {
  DRAWER_MINI_WIDTH,
  DRAWER_STATIC_WIDTH,
  DRAWER_EXPANDED_WIDTH,
} from 'components/mui/Layouts/components/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Divider from '@mui/material/Divider';

import { styled } from '@mui/material/styles';

type TAppBarMini = {
  children: React$Node,
};

type TAppBar = {
  children: React$Node,
  open: boolean,
};

const AppBarMui = styled(MuiAppBar)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.grey[300]}`,
  zIndex: theme.zIndex.drawer + 1,
}));

const AppBarStaticMui = styled(MuiAppBar)(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  marginLeft: DRAWER_STATIC_WIDTH,
  width: `calc(100% - ${DRAWER_STATIC_WIDTH}px)`,
  borderBottom: `1px solid ${theme.palette.grey[300]}`,
}));

const AppBarExpandedMui = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  marginLeft: DRAWER_MINI_WIDTH,
  width: `calc(100% - ${DRAWER_MINI_WIDTH}px)`,
  borderBottom: `1px solid ${theme.palette.grey[300]}`,
  // transition: theme.transitions.create(['width', 'margin'], {
  //   easing: theme.transitions.easing.sharp,
  //   duration: theme.transitions.duration.enteringScreen,
  // }),

  ...(open && {
    marginLeft: DRAWER_EXPANDED_WIDTH,
    width: `calc(100% - ${DRAWER_EXPANDED_WIDTH}px)`,
  }),
}));

export const AppBarItemDivider = styled(Divider)(() => ({
  height: 24,
}));

AppBarItemDivider.defaultProps = {
  orientation: 'vertical',
};

export const AppBar: React$StatelessFunctionalComponent<TAppBarMini> = ({ children }) => (
  <AppBarMui color="inherit" elevation={0}>
    <Toolbar>{children}</Toolbar>
  </AppBarMui>
);

export const AppBarDocument: React$StatelessFunctionalComponent<TAppBarMini> = ({ children }) => (
  <AppBarMui color="inherit" elevation={0}>
    <Toolbar sx={{ justifyContent: 'space-between' }}>{children}</Toolbar>
  </AppBarMui>
);

export const AppBarStatic: React$StatelessFunctionalComponent<TAppBarMini> = ({ children }) => (
  <AppBarStaticMui color="inherit" elevation={0}>
    <Toolbar>{children}</Toolbar>
  </AppBarStaticMui>
);

export const AppBarExpanded: React$StatelessFunctionalComponent<TAppBar> = ({ children, open }) => (
  <AppBarExpandedMui color="inherit" elevation={0} open={open}>
    <Toolbar>{children}</Toolbar>
  </AppBarExpandedMui>
);
