import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';

export const PinCell = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  backgroundColor: theme.palette.grey[50],
}));

export const PinCellText = styled('div')(() => ({
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  unicodeBidi: 'plaintext',
  flexGrow: 1,
}));

export const PinCellAction = styled(IconButton, { shouldForwardProp: (propName) => propName !== 'pinned' })(
  ({ pinned, theme }) => ({
    outline: 'none',
    marginRight: 2,
    marginLeft: -4, // ripple edge
    opacity: 0.5,
    padding: 4,

    '&:hover': {
      opacity: 1,
      color: theme.palette.primary.main,
    },

    ...(pinned && {
      opacity: 1,
      color: theme.palette.primary.main,

      '&:hover': {
        color: theme.palette.primary.dark,
      },
    }),
  }),
);
