// @flow
import React from 'react';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import AssigmentButton from 'pages/configurations/components/AssigmentButton';
import SelectPanelWrapper from 'pages/configurations/components/SelectPanelWrapper';

import CloseIcon from '@mui/icons-material/Close';

type TProps = {
  isVisible: boolean,
  count: number,
  countTitle: string,
  bulkActionsConfig: {
    bulkAssignAvailable: boolean,
    isDisableBulkAssign: boolean,
    bulkRevokeAvailable: boolean,
    isDisableBulkRevoke: boolean,
  },
  onClearSelected: () => void,
  onBulkAssign: () => void,
  onBulkRevoke: () => void,
};

const SelectPanel: React$StatelessFunctionalComponent<TProps> = ({
  bulkActionsConfig,
  isVisible,
  count,
  onClearSelected,
  countTitle,
  onBulkAssign,
  onBulkRevoke,
}) => (
  <SelectPanelWrapper isVisible={isVisible} count={count} elevation={24}>
    <Stack direction="row" alignItems="center" spacing={2} p={2}>
      <Typography variant="body1" sx={{ mr: '40px !important' }}>
        {countTitle}
      </Typography>
      {bulkActionsConfig.bulkAssignAvailable && (
        <AssigmentButton disabled={bulkActionsConfig.isDisableBulkAssign} assigned={false} onClick={onBulkAssign} />
      )}
      {bulkActionsConfig.bulkRevokeAvailable && (
        <AssigmentButton disabled={bulkActionsConfig.isDisableBulkRevoke} assigned onClick={onBulkRevoke} />
      )}
      <IconButton type="button" onClick={onClearSelected}>
        <CloseIcon />
      </IconButton>
    </Stack>
  </SelectPanelWrapper>
);

export default SelectPanel;
