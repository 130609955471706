// @flow
import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { configurationCompanySelector, type ConfigurationCompany, updateCompany } from 'domain/companies';
import { currenciesListSelector } from 'domain/dictionary';
import { type financial } from 'domain/organization/types.js.flow';
import FinancialForm from 'pages/common/forms/financial';

type Props = {
  company: ConfigurationCompany,
  updateCompany: (data: financial) => void,
  currencies: any,
};

class FinancialFormContainer extends Component<Props> {
  onSubmit = (data) => {
    this.props.updateCompany({ ...data.toJS(), update_tab: 'financial_tab' });
  };

  render() {
    const { company, currencies } = this.props;
    return <FinancialForm initialValues={company} onSubmit={this.onSubmit} context="company" currencies={currencies} />;
  }
}

const mapStateToProps = (state) => ({
  company: configurationCompanySelector(state),
  currencies: currenciesListSelector(state),
});

export default compose(connect(mapStateToProps, { updateCompany }))(FinancialFormContainer);
