// @flow
import { makeStyles } from '@mui/styles';

import CONST from 'pages/common/constant';

export default makeStyles(() => ({
  contentWrapper: {
    minHeight: `calc(100vh - ${CONST.headerHeight}px)`,
  },
}));
