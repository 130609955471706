// @flow
import React from 'react';
import { FormattedMessage } from 'react-intl';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import SvgIcon from '@mui/material/SvgIcon';

import { Dialog, Button } from './StyledComponents';

import { ReactComponent as DCIcon } from './icons/dc-icon.svg';
import BGImage from './icons/dc-bg.png';

type TTalkToUsDialog = {
  open: boolean,
  onClose: () => void,
  action: () => void,
};

const TalkToUsDialog: React$StatelessFunctionalComponent<TTalkToUsDialog> = ({ open, onClose, action }) => (
  <Dialog open={open} onClose={onClose} maxWidth="md">
    <Stack alignItems="center" sx={{ p: 3, maxWidth: 555, width: '100%' }}>
      <Box fontSize={59} display="flex" sx={{ my: 2 }}>
        <SvgIcon component={DCIcon} inheritViewBox fontSize="inherit" />
      </Box>
      <Typography variant="h5" sx={{ my: 1.5 }}>
        <FormattedMessage id="app.selector.requestAccessDialog.title" defaultMessage="Upgrade to access DOKKA Close" />
      </Typography>
      <Typography variant="subtitle1" textAlign="center" sx={{ my: 1.5 }}>
        <FormattedMessage
          id="app.selector.requestAccessDialog.body"
          defaultMessage="Streamline and automate your financial close process with DOKKA. Gain control over your workflows, close faster and reduce errors with powerful tools designed to give you complete visibility into your records"
        />
      </Typography>
      <Box style={{ margin: '12px 36px' }}>
        <img src={BGImage} alt="AP" width="100%" />
      </Box>
      <Stack direction="row" sx={{ my: 1.5 }} spacing={1}>
        <Button variant="text" onClick={onClose}>
          <FormattedMessage id="app.selector.requestAccessDialog.action.cancel" defaultMessage="No thanks" />
        </Button>
        <Button sx={{ p: [0.75, 1.75] }} variant="contained" onClick={action}>
          <FormattedMessage id="app.selector.requestAccessDialog.action.sendRequest" defaultMessage="Talk to us" />
        </Button>
      </Stack>
    </Stack>
  </Dialog>
);

export default TalkToUsDialog;
