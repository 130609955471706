/* flow */
import { Amplify, Auth, Hub } from 'aws-amplify';
import awsconfig from 'amplify/awsconfig';
import awsauth, { cognitoCookieDomain } from 'amplify/awsauth';
import { persistTokenRefreshTime, checkForTokenInactivityTimeout, InactivityTimeoutError } from './helpers';
import ROUTES_PATH from 'domain/router/routesPathConfig';
import { generatePath } from 'react-router-dom';
import { notify } from 'lib/errorLogger/logger';
import { partial } from 'lodash/fp';
import { cognitoUsernameSelector, signOutAction } from 'domain/env';

const logger = partial(notify.captureEvent, ['Cognito Auth Event']);
awsconfig.Auth.cookieStorage.domain = cognitoCookieDomain;

Amplify.configure(awsconfig);
Auth.configure({ oauth: awsauth });
// sign-in event happens on login app as of migration to unified login so
// this invocation migrates here
persistTokenRefreshTime(new Date().getTime());

const eventHandler = (dispatch) => async (response) => {
  const {
    payload: { event, data },
  } = response;

  switch (event) {
    case 'signOut':
      break;
    case 'tokenRefresh':
      persistTokenRefreshTime(new Date().getTime());
      break;
    case 'signIn_failure':
    case 'cognitoHostedUI_failure':
    case 'customState_failure':
      logger({ data: { message: data.message, event } }, 'Cognito login failure');
      setTimeout(() => {
        window.location.href = `${generatePath(ROUTES_PATH.ACCESS_LIMITED.absolute)}?msg=${data.message}`;
      }, 0);
      break;
    default:
      break;
  }
};

export default ({ dispatch, getState }) => {
  Hub.listen('auth', eventHandler(dispatch));
  // this handles case when user is authorized in another tab with other user id or
  // logged auth without further authorization
  document.addEventListener('visibilitychange', async () => {
    const isVisible = document.visibilityState === 'visible';
    if (isVisible) {
      try {
        checkForTokenInactivityTimeout();
        const { username } = await Auth.currentAuthenticatedUser();
        const state = getState();
        const cognitoUsername = cognitoUsernameSelector(state);
        // cognitoUsername can be null sporadicaly if user opens new tab and 'visibilitychange'
        // event handler runs before we retrieve userProfile
        // in this case we dont want to check for username correspondence
        if (cognitoUsername !== null && username?.toLowerCase() !== cognitoUsername?.toLowerCase()) {
          console.warn(
            'Redirected to companies list view as user is likely to have  \
          changed identity in another tab',
          );
          window.location = '/';
        }
      } catch (error) {
        dispatch({ type: signOutAction.type });
        if (error instanceof InactivityTimeoutError) {
          console.error('Inactivity timeout reached. User is about to be logged out.', error);
        } else {
          console.error(
            "This message comes from user being logged out in another \
          tab/device when we try to get user session after initial tab is activated. \
          It doesn't indicate an issue.",
            error,
          );
        }
      }
    }
  });
};
