import ArrowRightIcon from '@mui/icons-material/ArrowRight';

import { styled } from '@mui/material/styles';

export default styled(ArrowRightIcon)(({ theme }) => ({
  transform: `rotate(${theme.direction === 'ltr' ? 0 : 180}deg)!important`,
  '.MuiAccordionSummary-expandIconWrapper.Mui-expanded &': {
    transform: `rotate(${theme.direction === 'ltr' ? -90 : 270}deg)!important`,
  },
}));
