import React, { memo } from 'react';
import { compose } from 'redux';

import { withStyles } from '@mui/styles';

type TDelimiterProps = {|
  classes: { [key]: string },
|};

const Delimiter = ({ classes }: TDelimiterProps) => <div className={classes.delimiter} />;

const sheet = (theme) => ({
  delimiter: {
    height: 30,
    width: 2,
    marginLeft: 25,
    backgroundColor: theme.colors.primaryLightest,
  },
});

export default compose(withStyles(sheet))(memo(Delimiter));
