/* @flow */
import * as React from 'react';
import { FormattedMessage } from 'react-intl';

type Props = {
  id: string,
  defaultMessage: string,
  classes: {|
    [key: string]: string,
  |},
  children: React$Node,
};

function TranslationField({ id, defaultMessage, classes, children }: Props) {
  return (
    <div className={classes.fieldWrapper}>
      <FormattedMessage id={id} defaultMessage={defaultMessage}>
        {(l: string) => (
          <label htmlFor={id} className={classes.label}>
            {l}
          </label>
        )}
      </FormattedMessage>
      {children}
    </div>
  );
}

export default TranslationField;
