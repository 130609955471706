// @flow
import React from 'react';

import { TILE_Z_INDEXES } from 'pages/company/TileGrid/TileCard/components/StyledComponents';
import { useIntl } from 'react-intl';

import { styled } from '@mui/material/styles';

const Badge = styled('div')(({ theme }) => ({
  display: 'flex',
  writingMode: 'vertical-lr',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'absolute',
  right: 0,
  bottom: theme.spacing(2),
  backgroundColor: theme.palette.error.main,
  color: theme.palette.primary.contrastText,
  padding: theme.spacing(2, 0.25),
  borderRadius: '4px 0 0 4px',
  zIndex: TILE_Z_INDEXES.copyBadge, // overlap MuiCardActionArea-focusHighlight
}));

const Text = styled('span')(({ theme }) => ({
  transform: 'rotate(180deg)',
  fontSize: theme.typography.pxToRem(12),
  fontWeight: theme.typography.fontWeightMedium,
}));

type TCopyBadge = {
  versionID: string | number,
};

const CopyBadge: React$StatelessFunctionalComponent<TCopyBadge> = ({ versionID }) => {
  const { formatMessage } = useIntl();

  return (
    <Badge>
      <Text>{formatMessage({ id: 'documents.item.badge.duplicate', defaultMessage: 'Duplicate' }, { versionID })}</Text>
    </Badge>
  );
};

export default CopyBadge;
