// @flow

import type { TooltipType, TooltipTypeConfig } from 'components/OutsideTooltip/types';

const defaultConfig = 'commonBlack';

type Configs = {
  [key: TooltipType]: TooltipTypeConfig,
};

const configs: Configs = {
  polygon: {
    className: 'typePolygon',
  },

  commonBlack: {
    className: 'typeCommonBlack',
    arrowClassName: 'typeCommonBlackArrow',
  },

  userPic: {
    className: 'typeUserPic',
  },

  gridFilter: {
    className: 'typeGridFilter',
  },
};

const getConfig = (type: TooltipType = defaultConfig): TooltipTypeConfig => {
  return configs[type];
};

export default getConfig;
