import ListItemButtonMui from '@mui/material/ListItemButton';

import { alpha, styled } from '@mui/material/styles';

export const FavoriteListWrapper = styled('div', {
  label: 'FavoriteListWrapper',
})(({ theme }) => ({
  padding: theme.spacing(0, 0, 0, 2),
}));

export const FavoriteListLink = styled(ListItemButtonMui, {
  label: 'FavoriteListLink',
})(({ theme }) => ({
  fontSize: theme.typography.pxToRem(14),
  borderRadius: '8px 0 0 8px',
  padding: theme.spacing(0.5, 2, 0.5, 1),
  minHeight: 36,

  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.1),
  },
}));
