import { DnDItemTarget, DOCUMENT, LINKED, LINKED_DOCUMENT } from 'components/DnDItem';
import { alpha, styled } from '@mui/material/styles';
import ListItemButtonMui from '@mui/material/ListItemButton';
import ListItemMui from '@mui/material/ListItem';

export const DropTarget = DnDItemTarget([DOCUMENT, LINKED, LINKED_DOCUMENT]);

export const ListItem = styled(ListItemMui, { label: 'CategoryListItem' })(({ theme }) => ({
  padding: theme.spacing(0.5, 2),

  '& + &': {
    paddingTop: 0,
  },
}));

export const ListItemButton = styled(ListItemButtonMui, {
  shouldForwardProp: (propName) => !['level', 'isPendingApproval'].includes(propName),
})(({ theme, level, isPendingApproval }) => ({
  borderRadius: 8,
  fontSize: theme.typography.pxToRem(14),
  fontWeight: 400,
  marginLeft: theme.spacing(level === 2 ? 3.5 : 0),
  padding: theme.spacing(0.5, 1.5),

  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.1),
  },

  '&.Mui-selected': {
    backgroundColor: alpha(theme.palette.common.white, 0.3),
    fontWeight: 500,

    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.1),
    },
  },

  '&.isDndOver': {
    backgroundColor: alpha(theme.palette.secondary.main, 0.3),
  },

  ...(isPendingApproval && {
    backgroundColor: theme.palette.warning.main,

    '&:hover': {
      backgroundColor: alpha(theme.palette.warning.main, 0.8),
    },

    '&.Mui-selected': {
      backgroundColor: theme.palette.warning.light,
      fontWeight: 500,

      '&:hover': {
        backgroundColor: alpha(theme.palette.warning.light, 0.8),
      },
    },
  }),
}));

export const ListItemImage = styled('div', {
  shouldForwardProp: (propName) => !['src', 'isPendingApproval'].includes(propName),
})(({ src, theme }) => ({
  width: 20,
  minWidth: 20,
  height: 20,
  backgroundColor: theme.palette.common.white,
  WebkitMask: src !== null && src !== undefined ? `url(${src}) 0 0 no-repeat` : '',
  maskSize: 'contain',
}));
