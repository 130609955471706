export const CALL_HISTORY_METHOD = '@@router/CALL_HISTORY_METHOD';
export const LOCATION_CHANGE = '@@router/LOCATION_CHANGE';
export const SAVE_LOCATION_DATA = '@@router/SAVE_LOCATION_DATA';

export const locationChangeAction = (location, action) => ({
  type: LOCATION_CHANGE,
  payload: { location, action },
});

export const saveLocationDataAction = (data = {}) => ({
  type: SAVE_LOCATION_DATA,
  payload: data,
});

function updateLocation(method) {
  return (pathname, data = {}) => ({
    type: CALL_HISTORY_METHOD,
    payload: { method, pathname, data },
  });
}

export const push = updateLocation('push');
export const replace = updateLocation('replace');

export const navigate = {
  push,
  replace,
};
