// @flow
import React from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import { organizationListSelector } from 'domain/organization';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const OrganizationsTitle = () => {
  const { formatMessage } = useIntl();
  const list = useSelector(organizationListSelector);

  return (
    <Box display="flex" alignItems="center" mb={2}>
      <Typography variant="h6">
        {formatMessage({ id: 'organizations.title', defaultMessage: 'Organizations' })} ({list.size})
      </Typography>
    </Box>
  );
};

export default OrganizationsTitle;
