// @flow
import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { organization as organizationSelector, type OrganizationType, updateOrganization } from 'domain/organization';
import { type financial } from 'domain/organization/types.js.flow';
import FinancialForm from 'pages/common/forms/financial';

type Props = {
  organization: OrganizationType,
  updateOrganization: (data: financial) => void,
};

class FinancialFormContainer extends Component<Props> {
  onSubmit = (data: main) => {
    this.props.updateOrganization({ ...data.toJS(), update_tab: 'financial_tab' });
  };

  render() {
    const { organization } = this.props;
    return <FinancialForm initialValues={organization} onSubmit={this.onSubmit} context="organization" />;
  }
}

const mapStateToProps = (state) => ({
  organization: organizationSelector(state),
});

export default compose(connect(mapStateToProps, { updateOrganization }))(FinancialFormContainer);
