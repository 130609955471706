import ToggleButtonMui from '@mui/material/ToggleButton';
import ToggleButtonGroupMui from '@mui/material/ToggleButtonGroup';

import { alpha, styled } from '@mui/material/styles';

export const WorkspaceViewToggleGroup = styled(ToggleButtonGroupMui)(({ theme }) => ({
  backgroundColor: alpha(theme.palette.primary.main, 0.08),
  padding: theme.spacing(0.5),
  borderRadius: 6,
}));
export const WorkspaceViewToggleButton = styled(ToggleButtonMui)(({ theme }) => ({
  padding: theme.spacing(0.5),
  borderRadius: 6,
  border: 'none',

  '&.MuiToggleButtonGroup-grouped': {
    '&:not(:last-of-type)': {
      borderRadius: 6,
    },

    '&:not(:first-of-type)': {
      borderRadius: 6,
      marginLeft: theme.spacing(0.5),
    },
  },

  '&.Mui-selected': {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.common.white,
    boxShadow: `2px 2px 4px ${alpha(theme.palette.primary.main, 0.12)}`,
  },
}));
