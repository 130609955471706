/* @flow */
import * as React from 'react';
import type { RecordOf } from 'immutable';
import cx from 'classnames';
import { withStyles } from '@mui/styles';
import type { GeneratedStringType, ListCellSet } from 'domain/journal/helper';
import elements from 'components/elements';
import { EVENTS, type FSM$Event } from '../fsm';
import sheet from './sheet';

type Props = {|
  classes: {|
    [key: string]: string,
  |},
  data: RecordOf<GeneratedStringType>,
  maxLength: number,
  onBlur: () => void,
  onChange: (cell: string, cellSet: RecordOf<ListCellSet>) => void,
  eventCollector: (e: FSM$Event, p?: mixed) => void,
  getRefs: (el: ?HTMLElement) => void,
  value: string, // eslint-disable-line react/no-unused-prop-types
|};

type State = {|
  value: string,
|};

class LiteralCell extends React.Component<Props, State> {
  state = {
    value: this.constructor.getValue(this.props),
  };

  static getValue({ data: { cellSet, initValue }, value }): string {
    if (value !== cellSet.value) return value || '';
    if (cellSet.value) return cellSet.value;
    return initValue || '';
  }

  get isInitial() {
    if (this.state.value === this.props.data.initValue) return true;
    return false;
  }

  handleUpdate = (value: string) => {
    const { onChange, data } = this.props;
    if (value !== data.cellSet.value) {
      onChange(data._cell, data.cellSet.set('value', value.length ? value : null));
    }
  };

  handleChange = (event) => {
    this.setState({
      value: event.target.value,
    });
  };

  handleBlur = () => {
    this.handleUpdate(this.state.value);
    this.props.onBlur();
  };

  handleKyeDown = (e: SyntheticKeyboardEvent<HTMLElement>) => {
    if (e.keyCode === 9) e.preventDefault();
    if (e.keyCode === 13 || e.keyCode === 9) {
      // ENTER  // TAB
      this.handleUpdate(this.state.value);
      this.props.eventCollector(EVENTS.onEnter);
    }
    if (e.keyCode === 27) {
      // Esc
      this.props.eventCollector(EVENTS.onEsc);
    }
  };

  render() {
    const { classes, data, maxLength } = this.props;
    return (
      <input
        className={cx(classes.input, {
          [classes.init]: this.isInitial,
        })}
        id={this.props.data._cell}
        name={this.props.data.name}
        onChange={this.handleChange}
        onBlur={this.handleBlur}
        value={this.state.value}
        ref={this.props.getRefs}
        onKeyDown={this.handleKyeDown}
        maxLength={maxLength}
        data-element={elements.je.literal.field}
        data-element-id={`${data._cell}-${data.type}`}
      />
    );
  }
}

export default withStyles(sheet)(LiteralCell);
