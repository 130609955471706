import React, { memo, useCallback } from 'react';
import { compose, type Dispatch } from 'redux';
import { connect } from 'react-redux';
import { toggleDocumentHotkeysModalAction } from 'domain/ui';

import elements from 'components/elements';
import BarIcon from 'components/BarIcon';
import BarDropdownItem from 'components/BarDropdown/Item';

type TKeyboardButton = {|
  isWhite: boolean,
  toggleModal: Dispatch<toggleDocumentHotkeysModalAction>,
|};

const HotkeysButton = ({ isWhite, toggleModal }: TKeyboardButton) => {
  const onClick = useCallback(() => toggleModal(), [toggleModal]);

  return (
    <BarDropdownItem title="Keyboard shortcuts" titleLangId="header.menu.info.hotkeys" isWhite={isWhite}>
      <BarIcon icon="keyboard" white={!isWhite} data-element={elements.header.keyboard} onClick={onClick} />
    </BarDropdownItem>
  );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  toggleModal: toggleDocumentHotkeysModalAction,
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(memo(HotkeysButton));
