/* @flow */
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';

import { bindMenu, bindTrigger, usePopupState } from 'material-ui-popup-state/hooks';

import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Typography from '@mui/material/Typography';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import TextFieldsOutlinedIcon from '@mui/icons-material/TextFieldsOutlined';

import { SEARCH_TYPES, searchTypeSelector, setSearchTypeAction } from 'domain/companies';

const items = [
  {
    id: SEARCH_TYPES.byCompany,
    title: {
      id: 'companies.searchType.byCompanyName',
      defaultMessage: 'By company name',
    },
    icon: HomeOutlinedIcon,
  },
  {
    id: SEARCH_TYPES.byDocumentText,
    title: {
      id: 'companies.searchType.byDocumentText',
      defaultMessage: 'By document text',
    },
    icon: TextFieldsOutlinedIcon,
  },
];
const SearchTypeButton = () => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const popupState = usePopupState({ variant: 'popover', popupId: 'SearchVariantButton' });
  const searchType = useSelector(searchTypeSelector);

  const Icon = items.filter(({ id }) => id === searchType)[0].icon || items[0].icon;

  const handleClick = (type) => () => {
    dispatch(setSearchTypeAction(type));
    popupState.close();
  };

  return (
    <>
      <IconButton sx={{ ml: 2 }} {...bindTrigger(popupState)}>
        <Icon color="primary" />
      </IconButton>

      <Menu slotProps={{ paper: { elevation: 8 } }} {...bindMenu(popupState)}>
        {items.map(({ id, title, icon: ItemIcon }) => (
          <MenuItem key={id} onClick={handleClick(id)}>
            <ListItemIcon>
              <ItemIcon />
            </ListItemIcon>
            <Typography variant="body2">{formatMessage(title)}</Typography>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default SearchTypeButton;
