// @flow
import React, { useState, useCallback, memo, useRef, useEffect, useMemo } from 'react';
import { useGridFilter } from 'ag-grid-react';
import { useIntl } from 'react-intl';
import isEqual from 'lodash/isEqual';

import { MenuItem } from '@mui/material';
import Box from '@mui/material/Box';
import CheckboxBase from 'components/mui/Form/Checkbox/CheckboxBase';
import LinearProgress from '@mui/material/LinearProgress';
import VirtualizedList from 'pages/company/Grid/components/CustomHeaderFilter/CustomSetColumnFilter/VirtualizedList';
import { AgGridFilterDropdown } from 'pages/company/Grid/components/CustomHeaderFilter/CustomSetColumnFilter/StyledComponents';
import { type TFilterRowOptions } from 'pages/company/Grid/components/CustomHeaderFilter/CustomSetColumnFilter/VirtualizedRow';
import { type TGridApi, type TColumnDefs } from 'pages/company/Grid/types.js.flow';
import { createTranslationsList } from 'components/AgGrid/helpers/translations';
import { debounce } from '@mui/material/utils';

type TModel = {
  type: string,
  values: TFilterRowOptions,
} | typeof undefined;
  
type TCustomSetColumnFilter = {
  model?: TModel,
  onModelChange: (model: TModel | null) => void,
  blanks: boolean,
  colDef: TColumnDefs,
  options: Array<{}>,
  context: {|
    autocompleteApiInstance: (data: {}) => Promise<{ data: {| options: Array<{}> |}}>,
    fetchParams: {},
    additionalSetFilterPayload?: {| vendor_id: string |}
  |},
  api: TGridApi,
};

const CustomSetColumnFilter: React$StatelessFunctionalComponent<TCustomSetColumnFilter> = (props) => {
  const {
    model, // ag-grid props
    onModelChange, // ag-grid props
    colDef: { field, type }, // // ag-grid props, type tag_set | select | approval_set
    options, // passed by b-end in filterParams
    context: {
      autocompleteApiInstance,
      fetchParams,
      additionalSetFilterPayload,
    },
    api: { getFilterModel },
  } = props;

  const [isBusy, setIsBusy] = useState(false);
  const [values, setValues] = useState([]);
  // when we have async filters in presets(grid state filter model)  - ag-grid initialize filter component
  // we want skip this request for this case and fetch list if user manually opened filter
  const [isFirstGuiAttached, setIsFirstGuiAttached] = useState(false);
  const [containsFilterType, setContainsFilterType] = useState('contains');
  const containerRef = useRef(null); // dropdown parent element
  const unappliedModelRef = useRef(model?.values || []);
  const [searchValue, setSearchValue] = useState('');
  const listRef = useRef();
  // @to-do add more filter types to consider async
  const isAsyncFilter = ['select', 'tag_set', 'approval_set'].includes(type);
  const [closeFilter, setCloseFilter] = useState();
  const inputRef = useRef(null);
  const intl = useIntl();
  const { formatMessage } = intl;
  const filterTranslations = useMemo(() => createTranslationsList(intl), [intl]);

  const filterOptions = useMemo(() => [
    { 
      label: filterTranslations.contains,
      value: 'contains',
    },
    { 
      label: filterTranslations.notContains,
      value: 'notContains',
    },
  ], [filterTranslations]);

  const withoutBlanks = (list) => list.filter(option => option.id !== '_blank');

  const [currentOptions, setCurrentOptions] = useState(options || []);

  const [filteredOptions, setFilteredOptions] = useState([]);

  const allSelected = useMemo(() => filteredOptions
    .every(({ id }) => values.map(item => item.id).includes(id))
      && values.length !== 0
      && filteredOptions.length !== 0,
  [filteredOptions, values]);

  const onSelectAll = useCallback(() => {
    // @to-do if we  want unchecking select all to uncheck only filtered values, we must unckeck filteredOptions only
    // instead of [];
    const selected = allSelected ? [] : filteredOptions;
    setValues(selected);
  }, [filteredOptions, setValues, allSelected]);

  useEffect(() => {
    debugger; 
    const previousValues = model?.values.concat() || [];
    // unappliedModelRef - initial model, used for reset to previous state on detach
    unappliedModelRef.current = model;

    if (previousValues.length > 0) {
      setValues(previousValues);
    }

    if (model?.type) {
      setContainsFilterType(model?.type);
    }
    // 'onSelectAll' should not be dependency as it will trigger this useEffect once it changes
    // we only want to rely on model. setContainsFilterType has no dependencies and can be
    // considered unchangable
  }, [model, setContainsFilterType]);

  const afterGuiAttached = useCallback((params) => {
    setIsFirstGuiAttached(true);
    if (!params || !params.suppressFocus) {
      // Focus the input element for keyboard navigation.
      // Can't do this in an effect,
      // as the component is not recreated when hidden and then shown again
      inputRef.current?.focus();
    }

    if (params) {
      const { hidePopup } = params;

      setCloseFilter(() => hidePopup);
    }

    if (listRef.current) {
      listRef.current.scrollToItem(0, 'smart');
    }
  }, []);

  const afterGuiDetached = useCallback(() => {
    if (unappliedModelRef.current !== null && unappliedModelRef.current !== undefined) {
      setValues(unappliedModelRef.current?.values.concat());
    // preserves 'all selected' state on close open event without applying filter
    } else if (!allSelected) {
      setValues(currentOptions);
    }
  }, [currentOptions, allSelected]);

  // register filter handlers with the grid
  useGridFilter({
    afterGuiAttached,
    afterGuiDetached,
  });

  const fetch = useCallback(
    async () => {
      try {
        setIsBusy(true);

        const filterModel = getFilterModel();
        const {
          data: { options: list },
          // $FlowFixMe
        } = await autocompleteApiInstance({
          // eslint-disable-next-line camelcase
          data: {
            field,
            search_model: {
              // for document grid we add fetchParams from context to request
              // for insights grid we add additionalSetFilterPayload from context to request
              //
              // additionalSetFilterPayload holds vendor_id
              // fetchParams holds all search params from url
              // if search applied on workspace
              //
              // either additionalSetFilterPayload or fetchParams are present in context
              // but not both
              ...(additionalSetFilterPayload || fetchParams),
              filterModel,
            },
          },
        });

        setCurrentOptions(list);
        // if no values provided default filter state should be 'all selected'
        // in case of search term provided 'all selected' is not considered default state
        if ((model === null || model === undefined || model.values.length === 0) && searchValue?.length === 0) {
          setValues(list);
        }
        setIsBusy(false);

        return list;
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log('ERROR: fetching column filter data', error);
        setIsBusy(false);
        return [];
      }
    },
    [field, fetchParams, autocompleteApiInstance, additionalSetFilterPayload, model, searchValue, getFilterModel],
  );

  const onFilterTypeChanged = useCallback(({ target: { value } }) => {
    setContainsFilterType(value);
  }, [setContainsFilterType]);

  const onChangeInput = useCallback(
    (event) => {
      const { value } = event.target;
      setSearchValue(value);
    },
    [setSearchValue],
  );

  const debouncedOnChangeInout = useMemo(() => debounce(onChangeInput, 250), [onChangeInput]);

  const onClickItem = useCallback((option) => {
    const existIndex = values.findIndex((value) => value.id === option.id);
    if (existIndex > -1) {
      setValues(values.filter(({ id }) => id !== option.id));
    } else {
      setValues([...values, option]);
    }
  }, [values]);

  // model has chages after filter popup opened
  const modelDirty = (currentModel, unAppliedModel) => !isEqual(currentModel, unAppliedModel);
  // filter has values curently applied for filtering
  const previousFilterInitializedWithValues = unappliedModelRef?.current?.values;
  // every possible option selected including ones that are filtered out. respects blanks option that can be excluded
  // in case search applied
  const allPossibleValuesSelected = useMemo(
    () => values.length > 0 &&
      (
        (!searchValue && values.length === currentOptions.length) ||
        (searchValue && values.length === withoutBlanks(currentOptions).length)
      ),
  [searchValue, values, currentOptions]);

  const onApply = useCallback(() => {
    const currentModel = { values, type: containsFilterType, filterType: 'set' };
    // apply model when changes is detected
    if (modelDirty(currentModel, unappliedModelRef.current)) {
      // apply new filter if values changed but still not all selected
      if (values.length > 0 && !allPossibleValuesSelected) {
        // if anything selected but not all selected
        onModelChange({
          filterType: 'set',
          type: containsFilterType,
          values,
        });
      // reset filter in case all selected and filter to reset previously existed
      } else if (previousFilterInitializedWithValues) {
        // if all deselected or all selected but previously filter was applied - clean filter
        onModelChange(null);
      }
      // if filter applied, nullify unuplied changes to prevent them from rendering on next filter open
      unappliedModelRef.current = null;
    }

    closeFilter?.();
    
  }, [onModelChange, closeFilter, containsFilterType, values, allPossibleValuesSelected, previousFilterInitializedWithValues]);

  useEffect(() => {
    if (isAsyncFilter && isFirstGuiAttached) {
      fetch().then();
    }
  // 'fetch' shouldn't be in dependencies list as it changes due to its dependencies
  // and will trigger unneccesary async requests
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAsyncFilter, isFirstGuiAttached]);

  // reduce options list once search applied
  useEffect(() => {
    const applicator = searchValue?.length > 0 ? withoutBlanks : (x) => x;
    const list = applicator(currentOptions.filter(({ label }) => label.toLowerCase().indexOf(searchValue?.toLowerCase()) > -1));
   setFilteredOptions(list);
  }, [searchValue, currentOptions]);
  
  return (
    <Box flex="1">
      <form className="ag-filter-wrapper ag-focus-managed">
        <div className="ag-filter-body-wrapper ag-set-filter-body-wrapper">
          <Box ref={containerRef} className="ag-set-filter" sx={{ margin: '10px' }}>
            <AgGridFilterDropdown
              onChange={onFilterTypeChanged}
              value={containsFilterType}
              MenuProps={{
                container: containerRef.current,
                disablePortal: true,
              }}
            >
              {
                filterOptions.map(({ label, value }) => (<MenuItem key={value} value={value}>{label}</MenuItem>))
              }
            </AgGridFilterDropdown>
          </Box>
          <div className="ag-set-filter">
            <Box
              role="presentation"
              className="ag-labeled ag-label-align-left ag-text-field ag-input-field"
              sx={{ marginLeft: '0px', marginRight: '10px' }}
            >
              <div
                className="ag-input-field-label ag-label ag-hidden ag-text-field-label"
                aria-hidden="true"
                role="presentation"
              />
              <div className="ag-wrapper ag-input-wrapper ag-text-field-input-wrapper" role="presentation">
                <CheckboxBase
                  checked={allSelected}
                  onChange={onSelectAll}
                />
                <input
                  ref={inputRef}
                  className="ag-input-field-input ag-text-field-input"
                  type="text"
                  tabIndex={0}
                  placeholder="Search"
                  onChange={debouncedOnChangeInout}
                />
              </div>
            </Box>
            {isBusy && <LinearProgress />}

            {searchValue && filteredOptions.length === 0 && <Box textAlign="center">No matches.</Box>}

            <VirtualizedList ref={listRef} options={filteredOptions} values={values} onClickItem={onClickItem} />
          </div>
        </div>
        <div className="ag-filter-apply-panel">
          <button type="submit" className="ag-button ag-standard-button ag-filter-apply-panel-button" onClick={onApply}>
            {formatMessage({ id: 'button.ok' })}
          </button>
          <button
            type="button"
            className="ag-button ag-standard-button ag-filter-apply-panel-button"
            onClick={closeFilter}
          >
            {formatMessage({ id: 'button.cancel' })}
          </button>
        </div>
      </form>
    </Box>
  );
};

export default memo(CustomSetColumnFilter);
