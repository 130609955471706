// @flow
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useParams, generatePath } from 'react-router-dom';

import { isAuthorizedSelector } from 'domain/env';
import ROUTES_PATH from 'domain/router/routesPathConfig';
import * as ACL from 'domain/restriction';

import NotFoundContainer from './components/NotFoundContainer';

const mapStateToProps = (state) => ({
  isAuthorized: isAuthorizedSelector(state),
  isGranted: ACL.isGranted(state),
});

const DocNotFoundPage = () => {
  const { isAuthorized, isGranted } = useSelector(mapStateToProps);
  const { companyId } = useParams();

  const link = useMemo(() => {
    if (companyId) {
      const path = isGranted(ACL.IS_SUPPLIER_USER)
        ? ROUTES_PATH.COMPANY_SUPPLIER.absolute
        : ROUTES_PATH.COMPANY_DEFAULT_CATEGORY.absolute;
      return generatePath(path, { companyId });
    }
    return isAuthorized ? '/' : generatePath(ROUTES_PATH.AUTH_LOGIN.absolute);
  }, [isGranted, isAuthorized, companyId]);

  return (
    <NotFoundContainer
      link={link}
      title={{ id: '404.documentNotFoundHeader', defaultMessage: 'Document not found' }}
      description={{ id: '404.documentNotFoundDescription', defaultMessage: '' }}
      buttonText={{ id: '404.documentNotFoundWorkspaceButton', defaultMessage: 'Go to workspace' }}
    />
  );
};

export default DocNotFoundPage;
