/* @flow */
import * as React from 'react';
import cx from 'classnames';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withStyles } from '@mui/styles';
import { lineItemsDataSelector } from 'domain/journal';
import elements from 'components/elements';
import sheet from './sheet';

type IndicatorProps = {
  classes: {|
    [key: string]: string,
  |},
  onClick: (id: string, status: 'read' | 'unread') => void,
  data?: {
    line_id: string,
    line_request_status: 'read' | 'unread' | null,
  },
};

function Indicator({ classes, onClick, data }: IndicatorProps) {
  if (typeof data === 'undefined' || data.line_request_status === null) return null;
  // eslint-disable-next-line camelcase
  const { line_request_status, line_id } = data;
  const isRead = data.line_request_status === 'read';
  const dataElement = elements.je.request.indicator[line_request_status];
  return (
    <button
      type="button"
      className={cx(classes.indicator, { [classes.read]: isRead })}
      onClick={() => onClick(line_id, line_request_status)}
      data-element={dataElement}
    />
  );
}

const mapStateToProps = (state) => ({
  lineItemsData: lineItemsDataSelector(state),
});

export default function withRequest(WrappedComponent: React$ComponentType<*>) {
  class WithRequest extends React.Component<*> {
    static defaultProps = {
      onRequestIndicatorClick: () => null,
    };

    getItem = (row: number, col: string) => {
      const { classes, lineItemsData } = this.props;
      return (
        <>
          {this.props.getItem(row, col)}
          {this.isLast(col) && typeof row !== 'undefined' ? (
            <Indicator
              classes={classes}
              onClick={(id, status) => this.props.onRequestIndicatorClick({ id, status, row })}
              data={lineItemsData.get(row.toString())}
            />
          ) : null}
        </>
      );
    };

    get isLast() {
      const [col] = this.props.meta;
      const last = col[col.length - 1];
      return (c: string) => last === c;
    }

    render() {
      const { lineItemsData, ...rest } = this.props;
      return <WrappedComponent {...rest} getItem={this.getItem} />;
    }
  }

  return compose(
    connect(mapStateToProps),
    withStyles((theme) => sheet(theme)),
  )(WithRequest);
}
