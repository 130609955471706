// @flow
import React from 'react';

import { type TextFieldProps } from '@mui/material/TextField';
import TextFieldBase from 'components/mui/Form/TextField/TextFieldBase';

export type TTextFieldBaseMui = {} & TextFieldProps;

const TextFieldTrim: React$StatelessFunctionalComponent<TTextFieldBaseMui> = ({ onChange, ...rest }) => {
  const handleChange = (e) => {
    onChange(e.target.value.trimStart());
  };
  return <TextFieldBase onChange={handleChange} {...rest} />;
};

export default TextFieldTrim;
