import React from 'react';

import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import Approver from 'pages/components/Approver';

const ApprovalsCell = ({ value }) => (
  <Stack direction="row" gap={1} flexWrap="wrap">
    {value.map((v, i) => (
      <Approver
        key={v.id}
        avatar={<Avatar alt={v.fullName} src={v.picture} />}
        label={v.fullName}
        variant="outlined"
        hasArrow={i + 1 !== value.length}
      />
    ))}
  </Stack>
);

export default ApprovalsCell;
