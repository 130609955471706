// @flow
import React from 'react';
import { useSelector } from 'react-redux';
import { organization as organizationSelector } from 'domain/organization';
import ROUTES_PATH from 'domain/router/routesPathConfig';
import { useParams, Outlet, generatePath } from 'react-router-dom';

import Stack from '@mui/material/Stack';
import Tabs, { requiredTab } from 'components/Tabs';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import SupervisedUserCircleOutlinedIcon from '@mui/icons-material/SupervisedUserCircleOutlined';
import ScoreOutlinedIcon from '@mui/icons-material/ScoreOutlined';
import FeaturedVideoOutlinedIcon from '@mui/icons-material/FeaturedVideoOutlined';
import Paper from '@mui/material/Paper';

type Props = {};

const ConfigurationsInfo: React$StatelessFunctionalComponent<Props> = () => {
  const [, section = 'main'] = useParams()['*'].split('/');

  const organization = useSelector(organizationSelector);

  const tabs = [
    {
      to: generatePath(ROUTES_PATH.ORGANIZATION_SETTINGS_GENERAL_MAIN.absolute),
      id: 'configurations.info.tabs.main',
      defaultMessage: 'Main info',
      required: requiredTab(organization, ['name', 'picture', 'city', 'country', 'street']),
      tabValue: 'main',
      Icon: InfoOutlinedIcon,
    },
    {
      to: generatePath(ROUTES_PATH.ORGANIZATION_SETTINGS_GENERAL_CONTACT.absolute),
      id: 'configurations.info.tabs.contact',
      defaultMessage: 'Contact Person info',
      required: requiredTab(organization.contact, ['first_name', 'last_name', 'email']),
      tabValue: 'contact',
      Icon: SupervisedUserCircleOutlinedIcon,
    },
    {
      to: generatePath(ROUTES_PATH.ORGANIZATION_SETTINGS_GENERAL_FINANCIAL.absolute),
      id: 'configurations.info.tabs.financial',
      defaultMessage: 'Financial info',
      tabValue: 'financial',
      Icon: ScoreOutlinedIcon,
    },
    {
      to: generatePath(ROUTES_PATH.ORGANIZATION_SETTINGS_GENERAL_FEATURES.absolute),
      id: 'configurations.info.tabs.features',
      defaultMessage: 'Features',
      tabValue: 'features',
      Icon: FeaturedVideoOutlinedIcon,
    },
  ];
  return (
    <Stack alignItems="flex-start" direction="row">
      <Paper variant="outlined" sx={{ width: 270, border: 2, mr: 5, p: 1, pr: 0, borderColor: 'grey.200' }}>
        <Tabs value={section} tabs={tabs} orientation="vertical" textAlign="flex-end" />
      </Paper>
      <Stack component={Paper} variant="outlined" flex={1} sx={{ p: 3 }}>
        {organization.id ? <Outlet /> : null}
      </Stack>
    </Stack>
  );
};

ConfigurationsInfo.displayName = 'ConfigurationsInfo';

export default ConfigurationsInfo;
