// @flow
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { rtlEnable, userIdSelector } from 'domain/env';
import { storage } from 'lib/storage';

import Tags from 'pages/document/DocumentTopPanel/tags';
import Approval from 'pages/document/DocumentTopPanel/approval';
import { Wrapper, Resizer } from 'pages/document/DocumentTopPanel/StyledComponents';
import Box from '@mui/material/Box';

import DragIndicatorOutlinedIcon from '@mui/icons-material/DragIndicatorOutlined';

type TDocumentTopPanel = {
  setIsChangeApprovals: () => void,
};

const mapStateToProps = (state) => ({
  userId: userIdSelector(state),
  isRtl: rtlEnable(state),
});

const DocumentTopPanel: React$StatelessFunctionalComponent<TDocumentTopPanel> = ({ setIsChangeApprovals }) => {
  const { isRtl, userId } = useSelector(mapStateToProps);
  const { companyId, documentId } = useParams();
  const [isOpenPaneType, setOpenPaneType] = useState('');
  const storedValueByUserIDRef = useRef(JSON.parse(storage().tagsWidth().get()) || {});
  const storedW = storedValueByUserIDRef.current[userId];
  const wrapperW = window.innerWidth - 8; // 8 - separator width
  const minW = 360;
  const maxW = wrapperW - minW;
  const defaultW = wrapperW / 2;
  const [tagsW, setPaneWidth] = useState(storedW || null);

  // memo fn - avoid rerender for approvers pane
  const onOpenApprovalsPane = useCallback(() => setOpenPaneType('approvals'), []);

  const onOpenTagsPane = useCallback(() => setOpenPaneType('tags'), []);

  const onClose = useCallback(() => setOpenPaneType(''), []);

  const onMouseDown = useCallback(
    (e) => {
      const startX = e.clientX;

      if (document.body) {
        document.body.classList.add('UserSelect-none');
      }

      const onMouseMove = (event) => {
        const diffX = event.clientX - startX;
        let newTagsW = isRtl ? tagsW - diffX : tagsW + diffX;

        if (newTagsW < minW) newTagsW = minW;
        if (newTagsW > maxW) newTagsW = maxW;

        setPaneWidth(newTagsW);

        storedValueByUserIDRef.current[userId] = newTagsW;
      };

      const onMouseUp = () => {
        document.removeEventListener('mousemove', onMouseMove);
        document.removeEventListener('mouseup', onMouseUp);

        if (document.body) {
          document.body.classList.remove('UserSelect-none');
        }

        storage().tagsWidth().set(JSON.stringify(storedValueByUserIDRef.current));
      };

      document.addEventListener('mousemove', onMouseMove);
      document.addEventListener('mouseup', onMouseUp);
    },
    [maxW, minW, tagsW, userId, isRtl],
  );

  useEffect(() => {
    if (!storedW) {
      setPaneWidth(defaultW);
    }
  }, [storedW, defaultW]);

  return (
    <Wrapper>
      {(!isOpenPaneType || isOpenPaneType === 'tags') && (
        <Box
          style={{
            width: isOpenPaneType === 'tags' ? '100%' : tagsW,
            minWidth: minW,
          }}
        >
          {/* key={tagsW} // need for rerender/recalculate visible tags when width is changing */}
          <Tags key={tagsW} onOpen={onOpenTagsPane} onClose={onClose} />
        </Box>
      )}
      {!isOpenPaneType && (
        <Resizer onMouseDown={onMouseDown} tabIndex={0}>
          <DragIndicatorOutlinedIcon sx={{ fontSize: 12, pointerEvents: 'none' }} />
        </Resizer>
      )}
      {(!isOpenPaneType || isOpenPaneType === 'approvals') && (
        <Box style={{ flex: '1', minWidth: minW }}>
          <Approval
            key={tagsW} // need for rerender/recalculate visible approvers when width is changing
            onOpen={onOpenApprovalsPane}
            onClose={onClose}
            setIsChangeApprovals={setIsChangeApprovals}
            documentId={documentId}
            companyId={companyId}
          />
        </Box>
      )}
    </Wrapper>
  );
};

export default DocumentTopPanel;
