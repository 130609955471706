/* @flow */
import * as React from 'react';
import type { RecordOf } from 'immutable';
import type { CheckBoxCellType } from 'domain/journal/types.js.flow';
import { debounce } from 'throttle-debounce';
import elements from 'components/elements';

import { CheckboxCell, CellCheckboxOverlay, Checkbox } from 'components/Tables/Boolean/StyledComponents';

type Props = {|
  data: RecordOf<CheckBoxCellType>,
  onClick: () => void,
  onChange: (cell: string, cellSet: RecordOf<{| value: ?boolean |}>) => void,
  onBlur: () => void,
  onFocus: () => void,
  onKeyDown: (e: SyntheticKeyboardEvent<HTMLElement>) => void,
  getRefs: (el: ?HTMLElement) => void,
|};

type State = {
  checked: boolean,
  prev: ?boolean,
};

class BooleanCell extends React.Component<Props, State> {
  static getDerivedStateFromProps(props: Props, state: State) {
    if (props.data.cellSet.value !== state.prev) {
      return {
        checked: Boolean(props.data.cellSet.value),
        prev: props.data.cellSet.value,
      };
    }
    return null;
  }

  state = {
    checked: Boolean(this.props.data.cellSet.value),
    prev: this.props.data.cellSet.value,
  };

  handleFocus = () => {
    this.props.onClick();
  };

  handleUpdate = ({ currentTarget: { checked } }: SyntheticInputEvent<HTMLInputElement>) => {
    const { data } = this.props;
    this.setState(
      {
        checked,
      },
      () => {
        this.debounsedChange(data._cell, data.cellSet.set('value', checked));
      },
    );
  };

  debounsedChange = debounce(800, false, this.props.onChange);

  handleKey = (e: SyntheticKeyboardEvent<HTMLElement>) => {
    if (e.keyCode === 9) {
      e.preventDefault();
    }
    this.props.onKeyDown(e);
  };

  render() {
    const { data } = this.props;
    return (
      <CheckboxCell data-element-id={`${data._cell}-${data.type}`} data-element={elements.je.booleanCell.container}>
        <CellCheckboxOverlay onClick={this.handleFocus} disabled={data.readonly} style={data.style.toJS()} />

        <Checkbox
          onChange={this.handleUpdate}
          inputRef={this.props.getRefs}
          inputProps={{
            name: data.name,
            id: data.name,
          }}
          onBlur={this.props.onBlur}
          onFocus={this.props.onFocus}
          onKeyDown={this.handleKey}
          checked={this.state.checked}
          disabled={data.readonly}
          // cant fix ripple after unfocus cell through tab moving, ripple accumulate if again focus cell via tab
          // ripple - for checked checkbox works well
          disableRipple
        />
      </CheckboxCell>
    );
  }
}

BooleanCell.displayName = 'BooleanCell';

export default BooleanCell;
