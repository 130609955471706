/* @flow */
export type TooltipParams = {|
  left: number,
  top: number,
  heightBox: number,
  widthBox: number,
|};

export const getTooltipParams = (el: HTMLElement): TooltipParams => {
  const { x, y, height, width } = el.getBoundingClientRect();
  return { left: x + window.scrollX, top: y + window.scrollY, heightBox: height, widthBox: width };
};
