/* @flow */
import React from 'react';
import { useSelector } from 'react-redux';
import {
  documentsGridLoaderSelector,
  loadedSelector,
  processingDocsCountSelector,
  documentsCurrentCountSelector,
} from 'domain/documents';
import { loadingSelector } from 'domain/env';
import * as ACL from 'domain/restriction';
import useQuery from 'hooks/useQuery';

import NoResult from 'pages/common/empty/noResult';
import NoDocuments from 'pages/common/empty/noDocuments';
import Divider from '@mui/material/Divider';

const STUB_VIEWS = {
  noDocs: 'noDocs',
  notPresent: 'notPresent',
  notFound: 'notFound',
};

const mapStateToProps = (state) => ({
  isGranted: ACL.isGranted(state),
  companyTotalDocs: documentsCurrentCountSelector(state),
  loading: loadingSelector(state),
  gridLoading: documentsGridLoaderSelector(state),
  processing: processingDocsCountSelector(state),
  docsLoaded: loadedSelector(state),
});

const StubWhenEmpty = () => {
  const { isGranted, companyTotalDocs, loading, gridLoading, processing, docsLoaded } =
    useSelector(mapStateToProps);
  const query = useQuery();

  const search = Object.values(query).some((item) => (Array.isArray(item) ? item.some((i) => !!i) : !!item));
  const noDocView = isGranted(ACL.IS_ACCOUNT) ? STUB_VIEWS.noDocs : STUB_VIEWS.notPresent;
  const withoutDocs = companyTotalDocs ? noDocView : STUB_VIEWS.notPresent;
  const noDocs = search ? STUB_VIEWS.notFound : withoutDocs;

  const view = loading || gridLoading || !docsLoaded || (processing && !search) ? null : noDocs;

  return view ? (
    <>
      <Divider />
      {view === STUB_VIEWS.notFound && <NoResult />}
      {[STUB_VIEWS.noDocs, STUB_VIEWS.notPresent].includes(view) && <NoDocuments view={view} />}
    </>
  ) : null;
};

export default StubWhenEmpty;
