/* @flow */
import * as React from 'react';
import { withStyles } from '@mui/styles';
import { connect } from 'react-redux';
import { compose } from 'redux';

import cx from 'classnames';
import sheet from './sheet';
import getConfig from './constants';
import { FormattedMessage } from 'react-intl';
import type { OutsideTooltipData } from './types.js.flow';
import { rtlEnable } from 'domain/env';
import { createMemoFn } from 'lib/propHelpers';

export type Props = OutsideTooltipData & {|
  classes: {
    [key: string]: string,
  },
  isRtl: boolean,
|};

export type State = {
  isShow: boolean,
};

class OutsideTooltip extends React.Component<Props> {
  maxRuleEl: HTMLDivElement | null = null;

  minRuleEl: HTMLDivElement | null = null;

  arrowRef: HTMLDivElement | null = null;

  constructor(props) {
    super(props);
    this.state = {
      isShow: false,
    };
  }

  componentDidMount() {
    window.addEventListener('mousemove', this.handleMouseMove);
  }

  componentWillUnmount() {
    window.removeEventListener('mousemove', this.handleMouseMove);
  }

  getIdKey = () => {
    const { pos } = this.props;
    return pos ? `${pos.left}-${pos.top}` : '';
  };

  getWindowWidth = () => window.innerWidth;

  getTooltipWidth = createMemoFn(
    () => {
      const { pos } = this.props;
      const { widthBox } = pos || { widthBox: 0 };

      return {
        maxRuleEl: this.maxRuleEl,
        minRuleEl: this.minRuleEl,
        browserWidth: window.innerWidth,
        widthBox,
      };
    },
    ({ maxRuleEl, minRuleEl, browserWidth, widthBox }) => {
      if (maxRuleEl && minRuleEl) {
        const maxWidth = this.maxRuleEl.offsetWidth;
        const minWidth = this.minRuleEl.offsetWidth;
        const res = maxWidth > browserWidth ? Math.max(Math.max(browserWidth / 2, widthBox), minWidth) : maxWidth;
        return Math.min(browserWidth, res);
      }
      return 0;
    },
  );

  getStartPosition = () => {
    const { pos } = this.props;
    const { left, top } = pos || { left: 0, top: 0 };
    return { left, top };
  };

  getLeftDelta = () => {
    const { left } = this.getStartPosition();
    const tooltipWidth = this.getTooltipWidth();
    const posibleWidth = Math.floor(this.getWindowWidth() - left);
    const leftDelta = Math.max(tooltipWidth - posibleWidth, 0);
    return Math.max(leftDelta, 0);
  };

  getPositionStyle = () => {
    const { left, top } = this.getStartPosition();
    const leftDelta = this.getLeftDelta();
    const currentLeft = left - leftDelta;
    const tooltipWidth = this.getTooltipWidth();

    return { left: currentLeft, top, maxWidth: tooltipWidth, minWidth: tooltipWidth };
  };

  getArrowPositionStyle = () => {
    const { pos } = this.props;
    if (!pos) return {};
    const { height, width } = this.arrowRef ? this.arrowRef.getBoundingClientRect() : { height: 14, width: 14 };
    const arrowWidth = Math.sqrt(height * height + width * width);

    const tooltipWidth = this.getTooltipWidth();
    const widthBox = Math.max(pos.widthBox, arrowWidth);
    const actualWidth = Math.min(widthBox, tooltipWidth);
    const left = Math.floor(actualWidth / 2) - arrowWidth / 4;
    const leftDelta = this.getLeftDelta();
    return { left: leftDelta + left };
  };

  setMaxEl = (el: HTMLDivElement) => {
    this.maxRuleEl = el;
  };

  setMinEl = (el: HTMLDivElement) => {
    this.minRuleEl = el;
    setTimeout(() => {
      this.forceUpdate();
    }, 10);
  };

  setArrowEl = (el: HTMLDivElement) => {
    this.arrowRef = el;
  };

  isBetween = (val: number, from: number, to: number) => val >= from && val <= to;

  isShow = (e: MouseEvent, pos: $PropertyType<Props, 'pos'>) => {
    const { clientX, clientY } = e;
    const { left, top, widthBox, heightBox } = pos;
    const { scrollX, scrollY } = window;

    return (
      this.isBetween(clientX, left - scrollX, left + widthBox - scrollX) &&
      this.isBetween(clientY, top - scrollY, top + heightBox - scrollY)
    );
  };

  handleMouseMove = (e: MouseEvent) => {
    const { isShow: isShowState } = this.state;
    const { pos } = this.props;
    const isShow = pos ? this.isShow(e, pos) : false;

    if (isShowState !== isShow) {
      this.setState({ isShow });
    }
  };

  renderText = () => {
    const { textId, text } = this.props;
    return textId ? <FormattedMessage id={textId} defaultMessage={text} /> : <span>{text}</span>;
  };

  render() {
    const { classes, type, classNameWrapper } = this.props;
    const { isShow } = this.state;
    const text = this.renderText();
    const { className, arrowClassName } = getConfig(type);

    return isShow ? (
      <div key={this.getIdKey()} className={classes.outsideTooltipBox} style={this.getPositionStyle()}>
        <div className={cx(classes.outsideTooltipWrapper, classNameWrapper)}>
          <div className={classes.rulerBox}>
            <span ref={this.setMaxEl} className={cx(classes.outsideTooltipMaxRuler, classes[className])}>
              {text}
            </span>
            <span ref={this.setMinEl} className={cx(classes.outsideTooltipMinRuler, classes[className])}>
              {text}
            </span>
          </div>
          <div className={cx(classes.outsideTooltip, classes[className])}>{text}</div>
          {arrowClassName && (
            <div
              ref={this.setArrowEl}
              className={cx(classes.arrow, classes[arrowClassName])}
              style={this.getArrowPositionStyle()}
            />
          )}
        </div>
      </div>
    ) : null;
  }
}

const mapStateToProps = (state) => ({
  isRtl: rtlEnable(state),
});

export default compose(connect(mapStateToProps, {}), withStyles(sheet))(OutsideTooltip);
