// @flow
import React, { type Node } from 'react';
import cx from 'classnames';
import { compose } from 'redux';
import { withStyles } from '@mui/styles';
import { rtlEnable } from 'domain/env';
import { connect } from 'react-redux';
import sheet, { iconSet } from './sheet';

type Props = {
  classes: {
    button: string,
  },
  className?: string,
  action?: (e?: SyntheticEvent<HTMLButtonElement>) => ?mixed,
  type?: 'button' | 'submit' | 'reset',
  disabled?: boolean,
  'data-element': string,
  // used in styles
  icon: $Keys<typeof iconSet>, // eslint-disable-line react/no-unused-prop-types
  direction: 'rtl' | 'ltr', // eslint-disable-line react/no-unused-prop-types
  white?: boolean, // eslint-disable-line react/no-unused-prop-types
  animateWhenDisabled?: boolean, // eslint-disable-line react/no-unused-prop-types
  grey?: boolean, // eslint-disable-line react/no-unused-prop-types
  bgColor?: string, // eslint-disable-line react/no-unused-prop-types
};

// TODO: rm after facelift
function BarIcon({
  classes,
  action = () => null,
  type = 'button',
  className,
  disabled,
  icon,
  white,
  animateWhenDisabled,
  grey,
  bgColor,
  ...rest
}: Props): Node {
  return (
    <button className={cx(classes.button, className)} type={type} disabled={disabled} onClick={action} {...rest} />
  );
}

const mapStateToProps = (state) => ({
  direction: rtlEnable(state) ? 'rtl' : 'ltr',
});

export default compose(connect(mapStateToProps, {}), withStyles(sheet))(BarIcon);
