import { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import CompanyContext from 'pages/company/CompanyContext';
import { saveBackSearchUrlAction } from 'domain/env/envActions';
import { querySelector } from 'domain/router';

const useSaveBackUrl = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const query = useSelector(querySelector);
  const { companyType } = useContext(CompanyContext);

  return () => {
    dispatch(
      saveBackSearchUrlAction({
        query,
        params: {
          ...params,
          companyType,
        },
      }),
    );
  };
};

export default useSaveBackUrl;
