// @flow
import React from 'react';
import { Link } from 'react-router-dom';

import { DrawerStatic, DrawerHeader } from 'components/mui/Layouts/components/Drawer';
import { LayoutRoot, LayoutContent } from 'components/mui/Layouts/components/Layout';
import AsideLogo from 'components/mui/Layouts/components/AsideLogo';

type TLayoutAside = {
  children: React$Node,
};

const LayoutAside: React$StatelessFunctionalComponent<TLayoutAside> = ({ children }) => (
  <LayoutRoot>
    <DrawerStatic variant="permanent">
      <DrawerHeader>
        <Link to="/">
          <AsideLogo />
        </Link>
      </DrawerHeader>
    </DrawerStatic>

    <LayoutContent>{children}</LayoutContent>
  </LayoutRoot>
);

export default LayoutAside;
