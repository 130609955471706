/* @flow */
/* eslint-disable camelcase */
/* eslint consistent-return: 0 */
import React from 'react';
import { Link } from 'react-router-dom';
import { withStyles } from '@mui/styles';
import cx from 'classnames';
import { scrollBarWidth, updateStyle } from 'lib/domHelpers';
import elements from 'components/elements';
import sheet from './sheet';
import { throttle } from 'throttle-debounce';

type CloseProps = {
  cancel: string | (() => void),
  className?: string,
  setRef: ?(el: Node) => void,
};

export function Close({ cancel, className, setRef = null }: CloseProps) {
  return typeof cancel === 'function' ? (
    <button
      ref={setRef}
      type="button"
      className={className}
      onClick={cancel}
      onMouseDown={(e: MouseEvent) => e.stopPropagation()}
      data-element={elements.popup.close}
    />
  ) : (
    <Link to={cancel} className={className} data-element={elements.popup.close} />
  );
}

type Pos = { x: number, y: number };

const PosBox = withStyles({
  moveBox: {
    flip: false,
    position: 'absolute',
    left: ({ pos }) => (pos ? pos.x : 0),
    top: ({ pos }) => (pos ? pos.y : 0),
  },
})(({ children, classes, pos }) => <div className={cx({ [classes.moveBox]: pos })}>{children}</div>);

type Props = {
  contentRef: ?HTMLDivElement,
  children: React$Node,
  classes: {
    [key: string]: string,
  },
  cancel: string | (() => void),
  close: boolean,
  className?: string,
  closeClassName?: string,
  containerClassName?: string,
  disableMove?: boolean,
};

type State = {
  popupPos: ?Pos,
  startPos: ?Pos,
  deltaPos: ?Pos,
  limitPos: ?Pos,
  isMove: boolean,
};

class Popup extends React.Component<Props, State> {
  static defaultProps = {
    disableMove: false,
  };

  constructor(props) {
    super(props);
    this.body = null;
  }

  state = {
    popupPos: null,
    startPos: null,
    deltaPos: null,
    limitPos: null,
    isMove: false,
  };

  componentDidMount() {
    this.body = document.body;
    if (this.body) {
      this.body.addEventListener('keydown', this.handleKeyDown, false);
    }
    if (this.body) {
      updateStyle(this.body, [
        { name: 'overflow', value: 'hidden' },
        { name: 'padding-right', value: scrollBarWidth() },
      ]);
    }
    window.addEventListener('resize', this.setLimitPos);
    this.setLimitPos();
  }

  UNSAFE_componentWillReceiveProps() {
    if (this.props.contentRef) {
      setTimeout(this.setModalStyles, 200);
    }
  }

  componentWillUnmount() {
    if (this.body) {
      this.body.removeEventListener('keydown', this.handleKeyDown);
    }
    if (this.body) {
      this.body.style.cssText = "paddingRight: ''; overflow: '';";
    }
    this.removeMoveEvents();
    window.removeEventListener('resize', this.setLimitPos);
  }

  setModalStyles = () => {
    // eslint-disable-next-line max-len
    if (this.props.contentRef) {
      const { height, width } = this.props.contentRef.getBoundingClientRect();
      if (this.popup) {
        updateStyle(this.popup, [
          { name: 'width', value: `${width}px` },
          { name: 'height', value: `${height}px` },
        ]);
      }
    }
  };

  setPopupRef = (popup: ?HTMLDivElement) => {
    this.popup = popup;
  };

  setOverlayRef = (overlay: ?HTMLDivElement) => {
    this.overlay = overlay;
  };

  setCenterPopupPos = () => {
    const popupPos = this.getPopupCenterPos();
    this.setState({ popupPos });
  };

  setLimitPos = () => {
    if (!this.popup || !this.overlay) return { x: 0, y: 0 };
    const overlay = this.overlay.getBoundingClientRect();
    const popup = this.popup.getBoundingClientRect();
    this.setState({
      limitPos: {
        y: overlay.height - popup.height,
        x: overlay.width - popup.width,
      },
    });
  };

  getPopupCenterPos = () => {
    if (!this.popup || !this.overlay) return { x: 0, y: 0 };
    const overlay = this.overlay.getBoundingClientRect();
    const popup = this.popup.getBoundingClientRect();
    return { x: (overlay.width - popup.width) / 2, y: (overlay.height - popup.height) / 2 };
  };

  getPosFromEvent = (e: MouseEvent): Pos => {
    const { clientX, clientY } = e;
    return { x: clientX, y: clientY };
  };

  getPopupPos = () => {
    const { popupPos, deltaPos, limitPos } = this.state;
    if (!popupPos) return null;

    const pos = deltaPos ? { x: popupPos.x + deltaPos.x, y: popupPos.y + deltaPos.y } : popupPos;
    const res = {
      x: Math.max(Math.min(pos.x, limitPos.x), 0),
      y: Math.max(Math.min(pos.y, limitPos.y), 0),
    };
    return res;
  };

  initMoveEvents = () => {
    window.addEventListener('mousemove', this.move);
    window.addEventListener('mouseup', this.stopMove);
  };

  removeMoveEvents = () => {
    window.removeEventListener('mousemove', this.move);
    window.removeEventListener('mouseup', this.stopMove);
  };

  handleKeyDown = (event: KeyboardEvent) => {
    if (event.keyCode === 27) {
      const { cancel } = this.props;
      if (typeof cancel === 'function') return cancel();
    }
  };

  startMove = (e: MouseEvent) => {
    const { disableMove } = this.props;
    e.preventDefault();
    if (!disableMove) {
      const { popupPos } = this.state;
      this.setLimitPos();
      this.setState({ isMove: true, startPos: this.getPosFromEvent(e) });
      this.initMoveEvents();
      if (!popupPos) {
        this.setCenterPopupPos();
      }
    }
  };

  move = (e: MouseEvent) => {
    e.preventDefault();
    const { isMove } = this.state;
    if (isMove) {
      this.throttleMove(e);
    }
  };

  throttleMove = throttle(50, (e: MouseEvent) => {
    const { isMove, startPos } = this.state;
    if (isMove) {
      const mousePos = this.getPosFromEvent(e);
      const deltaPos = { x: mousePos.x - startPos.x, y: mousePos.y - startPos.y };
      this.setState({ deltaPos });
    }
  });

  stopMove = () => {
    this.removeMoveEvents();
    this.setState({
      deltaPos: null,
      isMove: false,
      startPos: null,
      popupPos: this.getPopupPos(),
    });
  };

  popup: ?HTMLElement;

  overlay: ?HTMLElement;

  body: ?HTMLBodyElement;

  render() {
    const { classes, cancel, close, className, closeClassName, containerClassName, children, disableMove } = this.props;

    const { popupPos, deltaPos } = this.state;

    return (
      <div
        className={cx(classes.container, containerClassName, {
          [classes.defaultPos]: !popupPos,
        })}
        data-element={elements.popup.container}
      >
        <Close className={classes.overlay} cancel={cancel} setRef={this.setOverlayRef} />
        <PosBox pos={this.getPopupPos()}>
          <div className={cx(classes.popup, className)} ref={this.setPopupRef} data-element={elements.popup.content}>
            {children}
            {close && <Close className={cx(classes.close, closeClassName)} cancel={cancel} />}
            {!disableMove && (
              <div
                className={cx(classes.moveArea, {
                  [classes.canMove]: !disableMove,
                  [classes.moving]: !!deltaPos,
                })}
                tabIndex="0"
                role="button"
                aria-pressed="false"
                onMouseDown={this.startMove}
              />
            )}
          </div>
        </PosBox>
      </div>
    );
  }
}

Popup.displayName = 'Popup';

export default withStyles(sheet)(Popup);
