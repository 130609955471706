// @flow
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { ArcherElement } from 'react-archer';
import { rtlEnable, documentViewSelector } from 'domain/env';
import { set } from 'lodash/fp';
import { MatchinArrowsAnchors, getOppositeCoordinates } from 'components/TextractCanvas/utils';
import { createPortal } from 'react-dom';

import { type TextractBlock, type TextractPolygon } from 'domain/textract/types.js.flow';

type Props = {|
  polygon: TextractBlock,
  polygonIndex: number,
  width: number,
  height: number,
  scale: number,
  currentAngle: number,
  points: TextractPolygon[],
  relations: {|
    relation: {|
      targetId: string,
      targetAnchor: 'left' | 'right',
      sourceAnchor: 'left' | 'right',
      style: Object,
    |},
    JELineIndex: number,
    color: {|
      fill: string,
      border: string,
    |},
  |},
  wrapper: ?HTMLElement,
|};

export const ArrowTrigger: React$StatelessFunctionalComponent<Props> = ({
  polygon,
  polygonIndex,
  relations: { relation, color, JELineIndex },
  points,
  width,
  height,
  scale,
  currentAngle,
  wrapper,
}) => {
  const markerWidth = 6 * scale;
  const rtl = useSelector(rtlEnable);
  // this forces recalculation of memoizedValue when docside size is changed
  // however document's width itself is  not changed as sidebar is larger then document
  // so dcument is centered within sidebar. We need recalc to position points properly though
  const userdefinedDocumentWidth = useSelector(documentViewSelector);
  const [hovered, setHovered] = useState(false);

  const relations = useMemo(
    () =>
      hovered
        ? [set(['style', 'strokeColor'], color.border)(relation)]
        : [set(['style', 'strokeColor'], 'transparent')(relation)],
    [hovered, relation, color.border],
  );

  const [zoomOverflow, position] = useMemo(() => {
    // indicates both margins from document to document wrapper. Is subject to change and is fragile
    const documentDefaultMargin = 18;
    const wrapperPosition = wrapper ? wrapper.getBoundingClientRect().width : 0;
    // indicates if pdf-document is zoomed or not
    const delta = wrapperPosition - width;
    const zoomApplied = delta < 0;

    const [topLeft, bottomRight] = getOppositeCoordinates(points);

    let point = rtl ? { X: topLeft.X, Y: topLeft.Y } : { X: bottomRight.X, Y: topLeft.Y };

    // eslint-disable-next-line default-case
    switch (currentAngle) {
      case 90:
      case 270:
        point = { X: topLeft.X, Y: topLeft.Y };
        break;
      case 180:
        point = { X: rtl ? bottomRight.X : topLeft.X, Y: topLeft.Y };
        break;
    }

    const defaultPosition = width * point.X + markerWidth;
    // 6 preserves  some extra padding from splitter
    const zoomedPosition = wrapperPosition - markerWidth / 2 - 6;

    const horizontalPosition = zoomApplied ? zoomedPosition : defaultPosition;
    const compensatedHorizontalPosition =
      delta > documentDefaultMargin
        ? horizontalPosition + (delta - documentDefaultMargin * scale) / 2
        : horizontalPosition;

    const rowHeight = height * (bottomRight.Y - topLeft.Y);

    return [
      zoomApplied,
      {
        width: markerWidth,
        height: markerWidth,
        top: height * point.Y + rowHeight / 4,
        // should rely on rtl here, clarify why it works for zoomed but doesnt work for rtl only
        [rtl && zoomApplied ? 'right' : 'left']: compensatedHorizontalPosition,
      },
    ];
    // dont remove userdefinedDocumentWidth, see selector clarification above
  }, [markerWidth, points, height, wrapper, width, rtl, scale, currentAngle, userdefinedDocumentWidth]);

  useEffect(() => {
    if (hovered) {
      const anchor = MatchinArrowsAnchors.journalEntryElementIdFactory(JELineIndex);

      const el = document.getElementById(anchor);

      el?.scrollIntoView({ block: 'nearest' });
    }
  }, [hovered, JELineIndex]);

  return wrapper !== null && typeof wrapper !== 'undefined'
    ? createPortal(
        <ArcherElement id={MatchinArrowsAnchors.documentElementIdFactory(polygon.id)} relations={relations}>
          <div
            id={MatchinArrowsAnchors.textractVisibleAnchorElementIdFactory(polygon.id)}
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
            style={{
              position: 'absolute',
              // in zoomed mode points must be invisible so that arrow points to row itself
              background: zoomOverflow ? 'transparent' : color.border,
              zIndex: 9,
              borderRadius: '50%',
              ...position,
            }}
          />
        </ArcherElement>,
        wrapper,
      )
    : null;
};
