// @flow
import React, { useMemo, memo, useEffect } from 'react';

import { JELayoutPane } from 'pages/document/Body/JE/JELayout/StyledCompoents';

type TJELayout = {
  isVertical: boolean,
  size: number,
  children: React$Node,
  order: number,
  updateContentWidth: () => void,
};

// 8px - width separator, 34px - left bar width
// (8 + 34) / 2 = 21;
const offset = 21;

const JELayout: React$StatelessFunctionalComponent<TJELayout> = ({
  children,
  isVertical,
  size,
  order,
  updateContentWidth,
}) => {
  useEffect(() => {
    updateContentWidth();
  }, [updateContentWidth]);

  const style = useMemo(
    () => ({
      maxHeight: isVertical ? '100%' : `${size}%`,
      maxWidth: isVertical ? `calc(${size}% + ${offset}px)` : '100%',
      order,
    }),
    [isVertical, size, order],
  );

  return <JELayoutPane style={style}>{children}</JELayoutPane>;
};

export default memo(JELayout);
