// @flow
import React, { Component } from 'react';
import { DropTarget } from 'react-dnd';
import type { ConnectDropTarget, DropTargetConnector } from 'react-dnd';
import { NativeTypes } from 'react-dnd-html5-backend';

import Stack from '@mui/material/Stack';

import { grey } from '@mui/material/colors';
import elements from 'components/elements';

type Props = {
  htmlFor: string,
  children: React$Node,
  connectDropTarget: ConnectDropTarget,
  onDrop: (e: SyntheticDragEvent<any>) => void,
};

class Dropzone extends Component<Props> {
  render() {
    const { onDrop, htmlFor, children, connectDropTarget } = this.props;
    return connectDropTarget(
      <label
        htmlFor={htmlFor}
        onDrop={onDrop}
        style={{ position: 'relative' }}
        data-element={elements.popup.Upload.dropZone}
      >
        <Stack
          spacing={1}
          justifyContent="center"
          alignItems="center"
          border={`1px dashed ${grey[300]}`}
          borderRadius={2}
          py={3}
        >
          {children}
        </Stack>
      </label>,
    );
  }
}

const collect = (connect: DropTargetConnector) => ({
  connectDropTarget: connect.dropTarget(),
});

export default DropTarget([NativeTypes.FILE], {}, collect)(Dropzone);
