// @flow
import React, { useEffect, useState, useCallback } from 'react';
import { useIntl } from 'react-intl';
import useDebounceValue from 'hooks/useDebounceValue';

import TextFieldBase from 'components/mui/Form/TextField/TextFieldBase';
import IconButton from '@mui/material/IconButton';
import ArrowCircleRightSharpIcon from '@mui/icons-material/ArrowCircleRightSharp';

type TTagsSearch = {
  value: string,
  disabled: boolean,
  onClick: (tag: string) => void,
  onChange: (value: string) => void,
};

const TagsSearch = React.forwardRef(({ value, onChange, disabled, onClick, ...rest }: TTagsSearch, ref) => {
  const intl = useIntl();
  const [currentValue, setCurrentValue] = useState(value);
  const debouncedValue = useDebounceValue(currentValue, 250);

  const onChangeInput = (e: SyntheticInputEvent<HTMLInputElement>) => {
    const { target } = e;
    setCurrentValue(target.value);
  };

  const addTag = useCallback(
    (e: SyntheticMouseEvent) => {
      e.preventDefault();
      onClick(currentValue);
    },
    [onClick, currentValue],
  );

  useEffect(() => {
    onChange(debouncedValue);
  }, [debouncedValue, onChange]);

  useEffect(() => {
    if (value.length === 0) {
      setCurrentValue('');
    }
  }, [value]);

  return (
    <TextFieldBase
      ref={ref}
      label={intl.formatMessage({ id: 'modal.document.tags.inputLabel', defaultMessage: 'Tag Name' })}
      value={currentValue}
      onChange={onChangeInput}
      autoFocus
      fullWidth
      sx={{ mt: 1 }}
      InputProps={{
        endAdornment: (
          <IconButton
            aria-label="enter"
            disabled={disabled}
            onClick={addTag}
            variant="contained"
            sx={{
              rotate: '-90deg',
            }}
          >
            <ArrowCircleRightSharpIcon color={disabled ? 'disabled' : 'primary'} sx={{ width: 32, height: 32 }} />
          </IconButton>
        ),
      }}
      {...rest}
    />
  );
});

export default TagsSearch;
