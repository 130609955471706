// @flow
import { makeStyles } from '@mui/styles';
import type { ThemesParamsType } from 'styles/dokkaTheme/types.js.flow';

export default makeStyles((theme: ThemesParamsType) => ({
  '@keyframes spin': {
    from: {
      transform: 'rotate(0deg)',
    },
    to: {
      transform: 'rotate(360deg)',
    },
  },
  progressBar: {
    height: 25,
    display: 'flex',
    alignItems: 'center',
    background: theme.colors.greyDarker,
    color: theme.colors.primaryWhite,
    justifyContent: 'center',
  },

  icon: {
    marginRight: 10,
    animationName: '$spin',
    animationDuration: '1000ms',
    animationIterationCount: 'infinite',
    animationTimingFunction: 'linear',
  },
}));
