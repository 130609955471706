// @flow
import React, { useCallback } from 'react';

import { usePopupState, bindHover, bindFocus, bindMenu } from 'material-ui-popup-state/hooks';
import HoverMenu from 'material-ui-popup-state/HoverMenu';
import { FormattedMessage } from 'react-intl';

import MenuItemMui from '@mui/material/MenuItem';
import MenuItemIcon from 'pages/company/DocumentContextMenu/components/MenuItemIcon';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import type { SxProps, Theme } from '@mui/material/styles';

import ArrowLeftOutlinedIcon from '@mui/icons-material/ArrowLeftOutlined';
import ArrowRightOutlinedIcon from '@mui/icons-material/ArrowRightOutlined';

import { styled } from '@mui/material/styles';
import { useTheme } from '@mui/material';

const MenuItemStyled = styled(MenuItemMui)(({ theme }) => ({
  '&.MuiListItem-divider': {
    borderBottom: 'none',
    borderTop: `1px solid ${theme.palette.divider}`,
  },
}));

export type TMenuItem = {
  id: string,
  title: string,
  action: (id: string) => void,
  restriction: number,
  Icon: React$Node,
  disabled?: boolean,
  separator?: boolean,
  hidden?: boolean,
  submenu?: Array<TMenuItem>,
  sx?: SxProps<Theme>,
};

type TMenuOptionProps = {
  entry: TMenuItem,
  onClick: () => void,
};

const MenuItem: React$StatelessFunctionalComponent<TMenuOptionProps> = ({ entry, onClick }) => {
  const popupState = usePopupState({
    variant: 'popover',
    popupId: `ContextMenu-Item-${entry.id}`,
  });
  const { direction } = useTheme();
  const isRtl = direction === 'rtl';

  const itemPopupState = entry.submenu
    ? {
        ...bindHover(popupState),
        ...bindFocus(popupState),
      }
    : {};

  const handleClick = useCallback(
    (event, item) => {
      popupState.close();
      onClick(event, item);
    },
    [onClick, popupState],
  );

  return (
    <MenuItemStyled
      key={entry.id}
      disabled={entry.disabled}
      divider={entry.separator}
      onClick={(e) => handleClick(e, entry)}
      {...(entry.sx && { sx: entry.sx })}
      {...itemPopupState}
    >
      <MenuItemIcon id={entry.id} color={entry.sx && entry.sx.color} />

      <Typography variant="body2" flexGrow={1}>
        {typeof entry.title === 'string' ? (
          <FormattedMessage id={`contextMenu.${entry.id}`} defaultMessage={entry.title} />
        ) : (
          entry.title
        )}
      </Typography>

      {entry.submenu && (
        <>
          <Box sx={{ mr: -1, display: 'flex', alignItems: 'center' }}>
            {isRtl ? <ArrowLeftOutlinedIcon /> : <ArrowRightOutlinedIcon />}
          </Box>
          <HoverMenu
            anchorOrigin={{ vertical: 'top', horizontal: isRtl ? 'left' : 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: isRtl ? 'right' : 'left' }}
            PaperProps={{ sx: { ...(isRtl ? { mr: -0.5 } : { ml: 0.5 }) } }}
            {...bindMenu(popupState)}
          >
            {entry.submenu.map((item) => (
              <MenuItem key={item.id} entry={item} onClick={handleClick} />
            ))}
          </HoverMenu>
        </>
      )}
    </MenuItemStyled>
  );
};

export default MenuItem;
