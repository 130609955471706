/* @flow */
import React, { memo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { userProfileSelector } from 'domain/env';
import { updateOrganizationUserPicture, updateOrganizationUserProfile } from 'domain/organizationUser';

import Form from 'pages/profiles/company/pages/info/form';

const ProfileMainTab = () => {
  const userProfile = useSelector(userProfileSelector);
  const dispatch = useDispatch();

  const onSubmit = useCallback(
    (data) =>
      new Promise((resolve, reject) => {
        dispatch(updateOrganizationUserProfile({ data, resolve, reject }));
      }),
    [dispatch],
  );

  const onUploadPicture = useCallback(
    (file) =>
      new Promise(() => {
        dispatch(updateOrganizationUserPicture(file));
      }),
    [dispatch],
  );

  return (
    <>
      {userProfile.id && (
        <Form
          initialValues={userProfile}
          onAvatarChange={onUploadPicture}
          onSubmit={onSubmit}
          pictureValue={userProfile.pic}
        />
      )}
    </>
  );
};

export default memo(ProfileMainTab);
