// @flow
import { Record, Set } from 'immutable';
import type { RecordOf, RecordFactory } from 'immutable';
import type { Message } from './types.js.flow';

export const messageFactory: RecordFactory<Message> = new Record({
  id: '',
  type: 'info',
  text: '',
});

export const messagesFactory = (messages: Message[]): Set<RecordOf<Message>> => Set(messages.map(messageFactory));
