/* @flow */
import * as React from 'react';
import { withStyles } from '@mui/styles';
import type { List } from 'immutable';
import Item from './item';
import Blank from './blank';
import sheet from './sheet';

type Props = {
  classes: {|
    [key: string]: string,
  |},
  list: List<*>,
  send: () => void,
};

function TransactionList({ list, classes, send }: Props) {
  return list.size ? (
    <ul className={classes.container}>
      {list.map((e) => (
        <li key={e.id} className={classes.item}>
          <Item classes={classes} data={e} send={send} />
        </li>
      ))}
    </ul>
  ) : (
    <Blank classes={classes} />
  );
}

export default withStyles(sheet)(TransactionList);
