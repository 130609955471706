// @flow
import React, { useMemo, useCallback } from 'react';

import { useDispatch, useSelector } from 'react-redux';

// selectors
import { setOrganizationFeaturesAction } from 'domain/organization';
import { setCompanyFeatureSet, currentCompanyNameSelector } from 'domain/companies';
import { organizationNameSelector, localeSelector, userNamesSelector, userSelector } from 'domain/env/envSelector';
import { currentConnectedERPselector } from 'domain/settings/settingsSelector';

// components
import Hint from '../Hint';
import { FormControlLabelSwitch } from 'components/mui/Form/FormControl/FormControlLabelSwitch';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Switch from 'components/mui/Form/Switch/SwitchBase';

// intl
import { FormattedMessage } from 'react-intl';

// helpers
import openFreshDeskChat from 'lib/freshdeskChat/openFreshDeskChat';

// styles
import useStyles from './sheet';
import { useTheme } from '@mui/material';

type Props = {
  featureKey: string,
  state: 'string',
  display_name: 'string',
  help: 'string',
  value: boolean,
  show_hint: boolean,
  isCompany?: boolean,
};

const mapStateToProps = (state) => ({
  organizationName: organizationNameSelector(state),
  currentCompanyName: currentCompanyNameSelector(state),
  currentConnectedERP: currentConnectedERPselector(state),
  locale: localeSelector(state),
  userName: userNamesSelector(state),
  email: userSelector(state).userId,
});

const FeatureItem: React$StatelessFunctionalComponent<Props> = ({
  featureKey,
  state,
  display_name: displayName,
  value,
  help,
  show_hint: showHint,
  isCompany = false,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { labelOptions } = useTheme();

  const isDisabled = state === 'disabled';

  const { email, userName, organizationName, currentCompanyName, currentConnectedERP, locale } =
    useSelector(mapStateToProps);

  const updateFeaturesAction = isCompany ? setCompanyFeatureSet : setOrganizationFeaturesAction;

  const openChat = useCallback(() => {
    const { name, supportEmail } = labelOptions;
    if (name !== 'AlPrime') {
      openFreshDeskChat({ email, organizationName, currentCompanyName, currentConnectedERP, userName, locale });
    } else {
      window.location = `mailto:${supportEmail}`;
    }
  }, [labelOptions, email, organizationName, currentCompanyName, currentConnectedERP, userName, locale]);

  const onChange = (key, value) => {
    dispatch(updateFeaturesAction({ key, value }));
  };

  const disabledHintMessage = useMemo(
    () => (
      <FormattedMessage
        values={{
          contact: (
            <span className={classes.contact} onClick={openChat}>
              <FormattedMessage id="forms.organization.features.hintContact" defaultMessage='"Contact Support"' />
            </span>
          ),
        }}
        id="forms.organization.features.hint"
        defaultMessage="Open a service request using {contact} to activate this option"
      />
    ),
    [openChat, classes],
  );

  return (
    <Grid container mb={2.5} alignItems="center">
      <FormControlLabelSwitch
        label={<Typography variant="body1">{displayName}</Typography>}
        onChange={(e) => onChange(featureKey, e.target.checked)}
        checked={value}
        control={<Switch />}
        disabled={isDisabled}
      />
      {isDisabled && (showHint || help) && (
        <Hint>
          {showHint ? (
            <>
              {help} {help && <br />} {disabledHintMessage}
            </>
          ) : (
            help
          )}
        </Hint>
      )}
      {!isDisabled && help && <Hint isHelp>{help}</Hint>}
    </Grid>
  );
};

export default FeatureItem;
