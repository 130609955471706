// @flow
import React from 'react';

import TextFieldBase from 'components/mui/Form/TextField/TextFieldBase';
import AddPhotoAlternateOutlinedIcon from '@mui/icons-material/AddPhotoAlternateOutlined';
import Box, { type BoxProps as TBoxProps } from '@mui/material/Box';
import AvatarMui from '@mui/material/Avatar';

import { makeStyles } from '@mui/styles';
import { alpha } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  iconButton: {
    width: 152,
    height: 152,
    position: 'relative',
    cursor: 'pointer',
    '& svg': {
      display: 'none',
    },
    '&:hover, &:active, &:focus': {
      '& svg': {
        display: 'inline-block',
      },
      '&::after': {
        content: '""',
        position: 'absolute',
        width: '100%',
        height: '100%',
        backgroundColor: alpha(theme.palette.primary.main, 0.3),
        borderRadius: 12,
        top: 0,
      },
    },
  },
}));

type Props = {
  label: {
    id: string,
    defaultMessage: string,
  },
  onChange: (event: KeyboardEvent, newValue: string, oldValue: string, name: string) => void,
  name: string,
  value: string,
  accept: string,
  input: {
    value: string,
    onChange: (event: KeyboardEvent, newValue: string, oldValue: string, name: string) => void,
    name: string,
  },
  disabled?: boolean,
  onUpload: () => void,
  picture?: string,
  BoxProps: TBoxProps,
};

const CompanyPic: React$StatelessFunctionalComponent<Props> = ({
  picture,
  accept,
  name,
  disabled,
  onUpload,
  BoxProps,
  input,
}) => {
  const classes = useStyles();

  const onChange = (event: Event) => {
    event.preventDefault();
    const file = event.target.files[0];
    if (file) {
      new Promise((resolve, reject) => {
        onUpload(file, resolve, reject);
      }).then((img: string) => {
        input.onChange(img);
      });
    }
  };

  const getPicture = () => picture || '';

  return (
    <Box mb={5} width={152} height={152} {...BoxProps}>
      <label htmlFor="fileInput">
        <TextFieldBase
          id="fileInput"
          type="file"
          multiple={false}
          disabled={disabled}
          name={name}
          inputProps={{ accept }}
          onChange={onChange}
          sx={{ display: 'none' }}
        />
        <div style={{ position: 'relative' }} className={classes.iconButton}>
          <AvatarMui
            src={getPicture()}
            sx={{ width: 152, height: 152, borderRadius: 3 }}
            imgProps={{ style: { objectFit: 'contain' } }}
            alt="company_logo"
          />
          <AddPhotoAlternateOutlinedIcon
            sx={() => ({
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              margin: 'auto',
              color: 'common.white',
              zIndex: 2,
            })}
          />
        </div>
      </label>
    </Box>
  );
};

export default CompanyPic;
