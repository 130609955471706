// @flow
import React, { memo, useCallback, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';

import { supplierAliasesSelector } from 'domain/env/envSelector';
import { updateSupplierAliasesAction } from 'domain/env/envActions';

import toast from 'components/Toast';
import GroupItem from 'pages/profiles/company/pages/components/GroupItem';
import TextFieldBase from 'components/mui/Form/TextField/TextFieldBase';

import { rules } from 'components/Form/validation';

const Aliases = () => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  const aliases = useSelector(supplierAliasesSelector);

  const [value, setValue] = useState('');

  const handleChange = useCallback((e) => {
    setValue(e.currentTarget.value);
  }, []);

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();
      if (value) {
        const emails = value.split(',');
        for (let i = 0; i < emails.length; i++) {
          if (rules.emailExtended(emails[i].trim())) {
            toast.error(
              formatMessage({
                id: 'profile.aliases.toast.error',
                defaultMessage: 'Must contain emails only',
              }),
            );
            return;
          }
        }
      }

      new Promise((resolve, reject) => {
        dispatch(updateSupplierAliasesAction({ aliases: value, resolve, reject }));
      });
    },
    [value, dispatch, formatMessage],
  );

  useEffect(() => {
    setValue(aliases);
  }, [aliases]);

  return (
    <form onSubmit={handleSubmit} id="user_profile_form">
      <GroupItem
        title={{
          id: 'profile.aliases.title',
          defaultMessage: 'Other emails',
        }}
      >
        <TextFieldBase
          id="aliases"
          name="aliases"
          value={value}
          placeholder={formatMessage({
            id: 'profile.aliases.input.placeholder',
            defaultMessage: 'Add emails (comma separated) for accessing to Supplier Portal',
          })}
          onChange={handleChange}
          multiline
          fullWidth
          rows={7}
        />
      </GroupItem>
    </form>
  );
};

export default memo(Aliases);
