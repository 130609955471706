// @flow
import React, { useCallback } from 'react';
import { Map, RecordOf } from 'immutable';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage, type MessageDescriptor } from 'react-intl';
import { organizationFeaturesSelector } from 'domain/organization';

import type { OrgBackupConnection, OrganizationFeaturesType } from 'domain/organization/types.js.flow';
import useTheme from '@mui/material/styles/useTheme';
import { useConfirm } from 'material-ui-confirm';

import MuiAccordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import ArrowRightIcon from 'pages/components/AccordionArrowRightIcon';
import MuiButton from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import { styled } from '@mui/material/styles';
import google from './images/google.svg';
import dropbox from './images/dropbox.svg';
import onedrirve from './images/onedrirve.svg';
import icloud from './images/icloud.svg';
import box from './images/box.svg';
import RefreshIcon from '@mui/icons-material/Refresh';

const providerIcons = {
  GOOGLE_DRIVE: google,
  DROPBOX: dropbox,
  ONE_DRIVE: onedrirve,
  I_CLOUD: icloud,
  BOX: box,
};

const Accordion = styled(MuiAccordion)(() => ({
  '&.MuiPaper-root.MuiAccordion-root::before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary elevation={0} expandIcon={<ArrowRightIcon />} {...props} />
))(({ theme }) => ({
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-content': {
    margin: theme.spacing(1, 0),
  },
}));

export type Props = {
  connection: RecordOf<OrgBackupConnection>,
  isSelected: boolean,
  onClickOpen: (type: $PropertyType<OrgBackupConnection, 'type'>) => void,
  onClickClose: (type: $PropertyType<OrgBackupConnection, 'type'>) => void,
  onClickConnect: (connection: OrgBackupConnection) => void,
  onClickDisconnect: (connection: OrgBackupConnection) => void,

  // store
  organizationFeatures: Map<OrganizationFeaturesType>,
};

const Button = ({ onClick, ...rest }) => (
  <MuiButton
    {...rest}
    onClick={(...args) => {
      args[0].stopPropagation();
      if (typeof onClick === 'function') onClick(...args);
    }}
  />
);

export const COMING_SOON_STATUS = 'comingSoon';

const statusTitles: {
  [key: $PropertyType<OrgBackupConnection, 'connection'>]: MessageDescriptor,
} = {
  syncing: {
    id: 'configurations.backup.connection.status.syncing',
    defaultMessage: 'Syncing',
  },
  synced: {
    id: 'configurations.backup.connection.status.synced',
    defaultMessage: 'Synced',
  },
  fail: {
    id: 'configurations.backup.connection.status.fail',
    defaultMessage: 'Failed',
  },
  disconnected: {
    id: 'configurations.backup.connection.status.disconnected',
    defaultMessage: 'Disconnected',
  },
};

const connectionColors = {
  syncing: 'warning',
  synced: 'success',
  fail: 'error',
};

const BackupConnection = ({
  organizationFeatures,
  connection,
  onClickConnect,
  isSelected,
  onClickDisconnect,
  onClickOpen,
  onClickClose,
}) => {
  const confirm = useConfirm();
  const theme = useTheme();

  const isConnection = ![COMING_SOON_STATUS, 'disconnected'].includes(connection.connection);

  const isComingSoon = connection.connection === COMING_SOON_STATUS;

  const isError = connection.connection === 'fail' && connection.error;

  const isSyncingStatus = connection.connection === 'syncing';

  const isLock = !organizationFeatures.get('document_backup');

  const onToggle = useCallback(() => {
    const toggleAction = isSelected ? onClickClose : onClickOpen;
    toggleAction();
  }, [isSelected, onClickClose, onClickOpen]);

  const onClickDisconnectBtn = useCallback(() => {
    confirm({
      title: (
        <FormattedMessage id="configurations.backup.disconnectModal.title" defaultMessage="Disconnect Cloud storage?" />
      ),
      description: (
        <FormattedMessage
          id="configurations.backup.disconnectModal.text"
          values={{ drive: connection.name }}
          defaultMessage="Are you sure you want to disconnect from your {drive}. Uploaded documents will not be synchronized."
        />
      ),
      confirmationText: (
        <FormattedMessage id="configurations.backup.disconnectModal.disconnectButton" defaultMessage="Disconnect" />
      ),
      confirmationButtonProps: {
        color: 'error',
      },
    }).then(() => {
      onClickDisconnect(connection);
    });
  }, [confirm, connection, onClickDisconnect]);

  const onClickConnectBtn = useCallback(() => {
    isLock
      ? confirm({
          title: (
            <FormattedMessage
              id="lockedFunctionality.backup.modal.title"
              defaultMessage="“Cloud Backup” extension disabled."
            />
          ),
          description: (
            <>
              <FormattedMessage
                id="configurations.backup.lockedFunctionality.modal.headerText"
                values={{ feature: <b>“Cloud Backup”</b> }}
                defaultMessage="{feature} extension allows you to synchronise your documents with your own Cloud Storage account."
              />
              &nbsp;
              <FormattedMessage
                id="configurations.backup.lockedFunctionality.modal.text"
                defaultMessage="Documents will be periodically uploaded keeping your account in sync with your cloud storage."
              />
              &nbsp;
              <FormattedMessage
                id="configurations.backup.lockedFunctionality.modal.footerText"
                values={{ feature: <b>“Cloud Backup”</b> }}
                defaultMessage="Contact our sales team to activate {feature} extension."
              />
            </>
          ),
          confirmationText: (
            <FormattedMessage id="lockedFunctionality.modal.confirmButton" defaultMessage="Contact Us" />
          ),
        }).then(() => {
          window.location = `mailto:${theme.labelOptions.supportEmail}`;
        })
      : onClickConnect();
  }, [confirm, isLock, onClickConnect, theme.labelOptions.supportEmail]);

  const getStatusLabel = (status: $PropertyType<OrgBackupConnection, 'connection'>): MessageDescriptor =>
    statusTitles[status] || { id: ' ', defaultMessage: ' ' };

  const renderButton = useCallback(() => {
    switch (true) {
      case isConnection:
        return (
          <Button variant="outlined" onClick={onClickDisconnectBtn}>
            <FormattedMessage id="configurations.backup.disconnectBtn" defaultMessage="Disconnect" />
          </Button>
        );
      case !isConnection && !isComingSoon:
        return (
          <Button
            onClick={onClickConnectBtn}
            {...(isLock && {
              sx: {
                backgroundColor: (theme) => theme.palette.grey[500],
                '&:hover': { backgroundColor: (theme) => theme.palette.grey[600] },
              },
            })}
          >
            <FormattedMessage id="configurations.backup.connectBtn" defaultMessage="Connect" />
          </Button>
        );
      default:
        return null;
    }
  }, [isComingSoon, isConnection, isLock, onClickConnectBtn, onClickDisconnectBtn]);

  return (
    <Accordion
      expanded={isSelected}
      disableGutters
      sx={{
        mb: 1.5,
        mt: '0!important',
        border: 1,
        borderColor: 'grey.200',
        borderRadius: (theme) => `${theme.shape.borderRadius * 2}px!important`,
        boxShadow: 'none',
      }}
      onChange={onToggle}
    >
      <AccordionSummary>
        <Stack direction="row" justifyContent="space-between" alignItems="center" flex="1" flexWrap="no-wrap">
          <Stack display="flex" direction="row" spacing={1} alignItems="center">
            <Stack
              justifyContent="center"
              alignItems="center"
              sx={(theme) => ({
                padding: theme.spacing(0.5, 1),
                border: 1,
                borderColor: theme.palette.grey[200],
                backgroundColor: theme.palette.grey[100],
                borderRadius: 2,
                minHeight: 34,
              })}
            >
              <img width={24} height="auto" src={providerIcons[connection.type]} alt="icon" />
            </Stack>
            <Typography>{connection.name}</Typography>
            <Stack spacing={1} direction="row" alignItems="center">
              {isConnection && (
                <Chip
                  label={<FormattedMessage {...getStatusLabel(connection.connection)} />}
                  color={connectionColors[connection.connection]}
                  size="small"
                />
              )}
              {connection.username && <Typography>{connection.username}</Typography>}
              {isSyncingStatus && (
                <RefreshIcon
                  size="small"
                  color="success"
                  sx={{
                    animation: 'spin 1s linear infinite',
                    '@keyframes spin': {
                      '0%': {
                        transform: 'rotate(0deg)',
                      },
                      '100%': {
                        transform: 'rotate(360deg)',
                      },
                    },
                  }}
                />
              )}
            </Stack>
          </Stack>
          {renderButton()}
        </Stack>
      </AccordionSummary>
      <AccordionDetails sx={{ px: 5 }}>
        <Typography variant="body2">
          {isComingSoon ? (
            <FormattedMessage id="configurations.backup.connection.comingSoonMessage" defaultMessage="Coming soon" />
          ) : (
            <FormattedMessage
              id="configurations.backup.connection.message"
              defaultMessage="Documents will be periodically uploaded keeping your account in sync with your cloud storage."
            />
          )}
        </Typography>
        {isError && (
          <Stack color="error.main">
            <Typography variant="body2">
              <FormattedMessage
                id="configurations.backup.connection.error"
                values={{ error: connection.error }}
                defaultMessage="Document synchronization failed: {error}"
              />
            </Typography>
            <Typography variant="body2">
              <FormattedMessage
                id="configurations.backup.connection.errorReconnect"
                values={{ drive: connection.name }}
                defaultMessage="File synchronization was not successful. Please, check your {drive} storage availability and re-connect"
              />
            </Typography>
          </Stack>
        )}
      </AccordionDetails>
    </Accordion>
  );
};

const mapStateToProps = (state) => ({
  organizationFeatures: organizationFeaturesSelector(state),
});

export default compose(connect(mapStateToProps))(BackupConnection);
