import DrawerMui from '@mui/material/Drawer';
import { DnDItemTarget, DOCUMENT, LINKED } from 'components/DnDItem';
import { alpha, styled } from '@mui/material/styles';

const DropTarget = DnDItemTarget([DOCUMENT, LINKED]);

export const DEFAULT_PANEL_WIDTH = 400;
export const MIN_PANEL_WIDTH = 400;
export const MAX_PANEL_WIDTH = 820;

export const Drawer = styled(DrawerMui)(({ theme }) => ({
  '& .MuiDrawer-paper': {
    width: DEFAULT_PANEL_WIDTH,
    zIndex: theme.zIndex.drawer + 2, // Appbar has theme.zIndex.drawer + 1 (from mui example)
    border: 'none',
  },
}));

export const DrawerContent = styled('div', {
  shouldForwardProp: (propName) => !['isOver', 'isLocked'].includes(propName),
})(({ theme, isLocked }) => ({
  display: 'flex',
  flexDirection: 'column',
  flex: '1 0 auto',
  position: 'relative',
  backgroundColor: isLocked ? alpha(theme.palette.primary.main, 0.04) : 'transparent',
  paddingLeft: 8,
  backgroundImage: isLocked
    ? `repeating-linear-gradient(45deg, ${theme.palette.grey[300]} 10px, transparent 12px, transparent 20px)`
    : 'none',
}));

export const DropTargetArea = styled(DropTarget, {
  shouldForwardProp: (propName) => propName !== 'isOver',
})(({ theme, isOver }) => ({
  filter: isOver ? 'brightness(0.8)' : 'none',
  backgroundColor: isOver ? alpha(theme.palette.primary.main, 0.04) : 'transparent',
  backgroundImage: isOver
    ? `repeating-linear-gradient(45deg, ${alpha(
        theme.palette.primary.main,
        0.3,
      )} 10px, transparent 12px, transparent 20px)`
    : 'none',
  padding: theme.spacing(2),
  flex: '1 1 auto',
  // for scrolling stretched content
  height: 0,
  overflowY: 'auto',
}));

export const DrawerDragger = styled('div')(({ theme }) => ({
  position: 'absolute',
  left: 0,
  width: 8,
  bottom: 0,
  top: 0,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'ew-resize',
  backgroundColor: theme.palette.grey[200],
}));
