import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  organization as organizationSelector,
  organizationPasswordExpirationDaysSelector,
  organizationSecurity2FAFeaturesSelector,
  organizationSecuritySSOFeaturesSelector,
} from 'domain/organization/organizationSelector';
import { updatePasswordExpirationAction, updateOrganization } from 'domain/organization/organizationActions';

import SecurityForm from './components/SecurityForm';
import DomainForm from './components/DomainForm';
import FeatureItem from 'pages/configurations/components/FeaturesSettings/components/FeatureItem';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';

const mapStateToProps = (state) => ({
  organization: organizationSelector(state),
  expirationDays: organizationPasswordExpirationDaysSelector(state),
  twoFAFeature: organizationSecurity2FAFeaturesSelector(state),
  ssoFeature: organizationSecuritySSOFeaturesSelector(state),
});
const Security = () => {
  const dispatch = useDispatch();
  const { organization, expirationDays, twoFAFeature, ssoFeature } = useSelector(mapStateToProps);

  const onSubmit = useCallback(
    (data) => {
      dispatch(updatePasswordExpirationAction(data.toJS()));
    },
    [dispatch],
  );

  const onSubmitDomain = useCallback(
    (data) => {
      dispatch(updateOrganization({ ...data.toJS(), domain: data.domain.toString(), update_tab: 'main_tab' }));
    },
    [dispatch],
  );

  return (
    <Stack spacing={4}>
      <Stack component={Paper} variant="outlined" p={2}>
        {twoFAFeature.map(([key, values]) => (
          <FeatureItem key={key} featureKey={key} {...values} />
        ))}
        {expirationDays !== null && (
          <SecurityForm initialValues={{ expiration_days: expirationDays }} onSubmit={onSubmit} />
        )}
      </Stack>
      <Stack component={Paper} variant="outlined" p={2}>
        {ssoFeature.map(([key, values]) => (
          <FeatureItem key={key} featureKey={key} {...values} />
        ))}
        {organization.hint_domain && (
          <DomainForm
            initialValues={organization.set('domain', organization.domain ? organization.domain.split(',') : [])}
            onSubmit={onSubmitDomain}
          />
        )}
      </Stack>
    </Stack>
  );
};
export default Security;
