// @flow
import React from 'react';
import { useIntl } from 'react-intl';
import type { LocationShape } from 'react-router-dom';

import RouterLink from 'components/mui/Router/RouterLink';
import Link from '@mui/material/Link';
import AlertMui from '@mui/material/Alert';
import Typography from '@mui/material/Typography';

import { styled } from '@mui/material/styles';

const Alert = styled(AlertMui)(({ theme }) => ({
  margin: theme.spacing(1),

  '& .MuiAlert-action': {
    alignItems: 'center',
    marginRight: 0,
    paddingTop: 0,
  },
}));

type TDocumentDuplicateAlert = {
  versionID: string | number,
  href: string | LocationShape,
};

const DocumentDuplicateAlert: React$StatelessFunctionalComponent<TDocumentDuplicateAlert> = ({ versionID, href }) => {
  const { formatMessage } = useIntl();

  return (
    <Alert
      severity="error"
      action={
        <Link underline="hover" component={RouterLink} href={href}>
          {formatMessage({ id: 'document.show.duplicate.origin', defaultMessage: 'Original file' })}
        </Link>
      }
    >
      <Typography variant="body2" fontWeight="500" noWrap flex="1 1 auto">
        {formatMessage({ id: 'document.show.duplicate.title', defaultMessage: 'Duplicate' })} &nbsp; {versionID}
      </Typography>
    </Alert>
  );
};

export default DocumentDuplicateAlert;
