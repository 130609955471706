// @flow
import React, { Component } from 'react';

import DragIndicatorOutlinedIcon from '@mui/icons-material/DragIndicatorOutlined';

import { styled } from '@mui/material/styles';

const Resizer = styled('div', { shouldForwardProp: (propName) => !['order', 'isVertical'].includes(propName) })(
  ({ theme, order, isVertical }) => ({
    position: 'relative',
    background: theme.palette.grey[300],
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexShrink: 0,
    order,
    ...(isVertical
      ? {
          height: '100%',
          width: 8,
          cursor: 'col-resize',
          borderLeft: `1px solid ${theme.palette.grey[400]}`,
          borderRight: `1px solid ${theme.palette.grey[400]}`,
        }
      : {
          height: 8,
          width: '100%',
          cursor: 'row-resize',
          borderTop: `1px solid ${theme.palette.grey[400]}`,
          borderBottom: `1px solid ${theme.palette.grey[400]}`,
        }),
  }),
);

export type TDocumentWorkspaceResizer = {
  onResize: (e: MouseEvent) => void,
  onResizeStart?: () => void,
  onResizeEnd?: () => void,
  isVertical: boolean,
  order?: number,
  className?: string, // TODO: RM after rework supplier portal
};

class DocumentWorkspaceResizer extends Component<TDocumentWorkspaceResizer> {
  resizing: boolean;

  componentDidMount() {
    document.addEventListener('mousemove', this.onResize);
    document.addEventListener('mouseup', this.onResizeEnd);
  }

  componentWillUnmount() {
    document.removeEventListener('mousemove', this.onResize);
    document.removeEventListener('mouseup', this.onResizeEnd);
  }

  onResizeStart = () => {
    const { onResizeStart } = this.props;

    if (document.body) {
      // eslint-disable-next-line react/prop-types
      document.body.classList.add('UserSelect-none');
    }
    this.resizing = true;

    if (typeof onResizeStart === 'function') {
      onResizeStart();
    }
  };

  onResizeEnd = () => {
    const { onResizeEnd } = this.props;

    if (document.body) {
      // eslint-disable-next-line react/prop-types
      document.body.classList.remove('UserSelect-none');
    }
    this.resizing = false;
    if (typeof onResizeEnd === 'function') {
      onResizeEnd();
    }
  };

  onResize = (event: MouseEvent) => {
    if (!this.resizing) return false;

    const { onResize } = this.props;

    return onResize(event);
  };

  render() {
    const { order, isVertical, className } = this.props;

    return (
      <Resizer isVertical={isVertical} order={order} onMouseDown={this.onResizeStart} className={className}>
        <DragIndicatorOutlinedIcon sx={{ fontSize: 12, transform: `rotateZ(${isVertical ? 0 : '90deg'})` }} />
      </Resizer>
    );
  }
}

export default DocumentWorkspaceResizer;
