/* @flow */
import get from 'lodash/get';
import type { Props } from './OutsideTooltip';

export default {
  '@keyframes showTooltip': {
    from: {
      opacity: 0,
    },
    to: {
      opacity: 1,
    },
  },

  outsideTooltipBox: {
    maxHeight: 0,
    /*minWidth: (props: Props) => {
      if (props.pos) {
        const maxAvailableWidth = window.innerWidth - props.pos.left;
        return  Math.max( Math.min(maxAvailableWidth, get(props, 'pos.widthBox', 0)), 50 );
      }
      return 0;
    },*/
    zIndex: 99999,
    position: 'absolute',
    top: (props: Props) => get(props, 'pos.top', 0),
    left: (props: Props) => get(props, 'pos.left', 0),
  },

  outsideTooltipWrapper: {
    position: 'relative',
    opacity: 0,
    animation: '$showTooltip 0.3s forwards',
  },

  outsideTooltip: {
    pointerEvents: 'none',
    position: 'absolute',
    bottom: 'calc(100% + 4px)',
    display: 'block',
    animationDelay: (props: Props) => `${get(props, 'delay', 0)}ms`,
    overflow: 'hidden',
    maxWidth: '100%',
    textOverflow: 'ellipsis',
    '&>span': {
      unicodeBidi: 'plaintext',
    },
  },

  typePolygon: {
    color: '#333',
    background: '#fff',
    boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.25)',
    padding: 3,
    border: '1px solid #F2F2F2',
    borderRadius: 2,
  },

  typeCommonBlack: {
    color: '#FFF',
    fontSize: 11,
    backgroundColor: '#000',
    padding: [5, 10],
    marginBottom: 7,
    boxShadow: 'none',
    border: 'none',
  },

  typeUserPic: {
    backgroundColor: '#000',
    borderRadius: 4,
    border: 'none',
    color: '#FFF',
    padding: 10,
    fontSize: 10,
  },

  typeGridFilter: {
    color: '#333',
    background: '#fff',
    boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.25)',
    borderRadius: 4,
    border: 'none',
    padding: 10,
    minWidth: 80,
    fontSize: 12,
  },

  rulerBox: {
    position: 'absolute',
    left: 0,
    maxWidth: 0,
    overflow: 'hidden',
    opacity: 0,
  },

  outsideTooltipMaxRuler: {
    position: 'absolute',
    left: 0,
    height: '10px !important',
    whiteSpace: 'nowrap',
    padding: [0, 10],
    border: '1px solid red',
    width: 'min-content',
  },

  outsideTooltipMinRuler: {
    position: 'absolute',
    left: 0,
    height: '10px !important',
    whiteSpace: 'wrap',
    padding: [0, 10],
    border: '1px solid blue',
    width: 'min-content',
  },

  arrow: {
    position: 'absolute',
    bottom: 8,
    height: 14,
    width: 14,
    backgroundColor: 'black',
    transform: 'rotate( 45deg )',
    zIndex: -1,
  },

  typeCommonBlackArrow: {
    backgroundColor: 'black',
  },
};
