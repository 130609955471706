// @flow
import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { getCurrentCompanyData, isGridWorkSpaceSelector } from 'domain/companies/companiesSelector';
import { documentCountSelector, gridCurrentFiltersSelector } from 'domain/documents/documentSelector';

import FavoriteTagsPanel from 'pages/company/FavoriteTagsPanel';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

const mapStateToProps = (state) => ({
  count: documentCountSelector(state),
  currentCompany: getCurrentCompanyData(state),
  gridFilters: gridCurrentFiltersSelector(state),
  isGridView: isGridWorkSpaceSelector(state),
});

const CompanyTitlePanel = () => {
  const { formatMessage } = useIntl();
  const { count, currentCompany, gridFilters, isGridView } = useSelector(mapStateToProps);
  const { search } = useLocation();
  const isGridFilters = Object.keys(gridFilters).length > 0;

  return (
    ((isGridView && isGridFilters) || search) && (
      <Stack direction="row" pb={2} alignItems="center">
        <Typography variant="h6" mr={4} flexShrink="0">
          {formatMessage({
            id: 'documents.tags.results',
            defaultMessage: 'Results',
          })}
          &nbsp; ({count})
        </Typography>
        {currentCompany && <FavoriteTagsPanel />}
      </Stack>
    )
  );
};

export default CompanyTitlePanel;
