/* @flow */
import * as A from './actions';
import { CategoriesStoreFactory } from './adapters';
import type { CategoriesStore } from './types.js.flow';
import * as documentsActions from 'domain/documents/documentsActions';

type Action = any;

export const reducer = {
  categories(state: CategoriesStore = CategoriesStoreFactory(), action: Action) {
    switch (action.type) {
      case A.getCategoriesAction.type:
        return state;

      case documentsActions.moveAllCategoryDocumentsToOtherCategoryAction.success:
      case documentsActions.moveDocumentToCategoryAction.success:
      case A.getCategoriesAction.success: {
        return state.setIn(['list'], action.payload.list);
      }

      default:
        return state;
    }
  },
};
