// @flow

import React, { useState } from 'react';
import { createPortal } from 'react-dom';

type Props = {};

export const FunctionalIFrameComponent: React$StatelessFunctionalComponent<Props> = ({
  children,
  title,
  ...props
}: Props) => {
  const [contentRef, setContentRef] = useState(null);
  const mountNode = contentRef?.contentWindow?.document?.body;

  return (
    <iframe title={title} {...props} ref={setContentRef}>
      {mountNode && createPortal(children, mountNode)}
    </iframe>
  );
};

export default FunctionalIFrameComponent;
