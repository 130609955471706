import React, { memo } from 'react';
import { compose } from 'redux';

import { withStyles } from '@mui/styles';

import elements from 'components/elements';
import BarIcon from 'components/BarIcon';
import BarDropdownItem from 'components/BarDropdown/Item';

type THelpCenterButton = {|
  classes: {
    [key: string]: string,
  },
  isWhite: boolean,
  helpdeskLink: string,
|};

const HelpCenterButton = ({ classes, isWhite, helpdeskLink }: THelpCenterButton) =>
  helpdeskLink ? (
    <a className={classes.link} href={helpdeskLink} target="_blank" rel="noopener noreferrer">
      <BarDropdownItem title="Help Center" titleLangId="header.menu.info.helpCenter" isWhite={isWhite}>
        <BarIcon icon="info" white={!isWhite} data-element={elements.header.ask.help} />
      </BarDropdownItem>
    </a>
  ) : null;

const sheet = {
  link: {
    textDecoration: 'none',
  },
};

export default compose(withStyles(sheet))(memo(HelpCenterButton));
