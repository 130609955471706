/* @flow */
import * as React from 'react';
import { useIntl } from 'react-intl';
import messages from './messages';

import AlertMui from '@mui/material/Alert';
import Button from '@mui/material/Button';
import AlertTitle from '@mui/material/AlertTitle';
import Typography from '@mui/material/Typography';

import { styled } from '@mui/material/styles';

const Alert = styled(AlertMui)(({ theme }) => ({
  '& a': {
    color: theme.palette.common.white,

    '&:hover': {
      textDecoration: 'none',
    },
  },
}));

type Props = {
  children?: React$Node,
  text?: string,
  type: 'warning' | 'error' | 'info',
};

const JEToast = ({ type, children, text }: Props) => {
  const { formatMessage } = useIntl();

  return (
    <Alert
      variant="filled"
      severity={type}
      action={
        <Button variant="text" size="small" sx={{ color: 'common.white' }} onClick={() => null}>
          {formatMessage({ id: 'forms.common.close', defaultMessage: 'Close' })}
        </Button>
      }
    >
      <AlertTitle m={0}>{formatMessage({ ...messages[type] })}</AlertTitle>
      {text ? (
        <Typography variant="body2" sx={{ overflowWrap: 'anywhere' }} dangerouslySetInnerHTML={{ __html: text }} />
      ) : null}
      {children}
    </Alert>
  );
};

export default JEToast;
