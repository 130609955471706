// @flow
import React from 'react';

import Tooltip, { TTooltipMui } from 'components/mui/Tooltip';

import { styled } from '@mui/material/styles';

type TTooltipForButton = {
  WrapperProps?: any,
} & TTooltipMui;

const TooltipButtonWrapper = styled('div')(() => ({
  display: 'inline-flex',
}));

// MUI: You are providing a disabled `button` child to the Tooltip component.
//      A disabled element does not fire events.
//      Tooltip needs to listen to the child element's events to display the title.
// ================================================================================================
//      so its wrapper only for elements that can be disabled
// ================================================================================================
const TooltipForButton: React$StatelessFunctionalComponent<TTooltipForButton> = ({
  children,
  WrapperProps = {},
  ...rest
}) => (
  <Tooltip {...rest}>
    <TooltipButtonWrapper {...WrapperProps}>{children}</TooltipButtonWrapper>
  </Tooltip>
);

export default TooltipForButton;
