// @flow
import React, { useState } from 'react';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper, { type PopperProps } from '@mui/material/Popper';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

// icons
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';

// intl
import { useIntl } from 'react-intl';

// theme
import { useTheme } from '@mui/material';

// types
import { type TOptions } from '../..';

type TListItem = {
  handleMenuItemClick: () => void,
  handleClose: () => void,
  isAllowedDivider: boolean,
} & TOptions;

type TList = {} & PopperProps & TListItem;

const List: React$StatelessFunctionalComponent<TList> = ({
  open,
  anchorRef,
  handleClose,
  options,
  handleMenuItemClick,
  placement = 'top',
  popperModifiers,
}) => (
  <Popper
    sx={{
      zIndex: 1,
    }}
    open={open}
    anchorEl={anchorRef.current}
    role={undefined}
    transition
    placement={placement}
    disablePortal
    modifiers={popperModifiers}
  >
    {({ TransitionProps, placement }) => (
      <Grow
        {...TransitionProps}
        style={{
          transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
        }}
      >
        <Paper>
          <ClickAwayListener onClickAway={handleClose}>
            <MenuList id="split-button-menu" autoFocusItem>
              {options.map(({ key, ...rest }, index) => (
                <ListItem
                  key={key}
                  handleMenuItemClick={handleMenuItemClick}
                  handleClose={handleClose}
                  {...rest}
                  isAllowedDivider={index !== 0}
                />
              ))}
            </MenuList>
          </ClickAwayListener>
        </Paper>
      </Grow>
    )}
  </Popper>
);

const ListItem: React$StatelessFunctionalComponent<TListItem> = ({
  title,
  onClick,
  submenu,
  handleMenuItemClick,
  handleClose,
  divider,
  disabled,
  isDanger,
  isHidden,
  confirmOptions,
  icon,
  htmlFor,
  isAllowedDivider,
}) => {
  const [childOpen, setChildOpen] = useState(false);
  const ref = React.useRef(null);
  const { direction } = useTheme();
  const { formatMessage } = useIntl();
  const isRtl = direction === 'rtl';

  if (isHidden) {
    return null;
  }
  return (
    <>
      {divider && isAllowedDivider && <Divider sx={{ my: '0 !important' }} />}
      <label htmlFor={htmlFor}>
        <MenuItem
          onClick={() => handleMenuItemClick(onClick, confirmOptions)}
          onMouseEnter={() => setChildOpen(true)}
          onMouseLeave={() => setChildOpen(false)}
          ref={ref}
          sx={{ display: 'flex', justifyContent: 'space-between' }}
          disabled={disabled}
        >
          {icon && <ListItemIcon>{icon}</ListItemIcon>}
          <ListItemText>
            <Typography variant="body2" color={isDanger ? 'error' : 'inherit'}>
              {typeof title === 'string' ? title : formatMessage(title)}
            </Typography>
          </ListItemText>

          {submenu && (
            <>
              {isRtl ? <KeyboardArrowLeftIcon /> : <KeyboardArrowRightIcon />}
              <List
                options={submenu}
                handleMenuItemClick={handleMenuItemClick}
                formatMessage={formatMessage}
                handleClose={handleClose}
                anchorRef={ref}
                open={childOpen}
                placement={isRtl ? 'left' : 'right'}
              />
            </>
          )}
        </MenuItem>
      </label>
    </>
  );
};

export default List;
