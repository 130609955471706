import shapeIcon from './icons/shapeIcon.svg';

export default {
  indicator: {
    width: 10,
    height: 10,
    margin: 5,
    background: `url(${shapeIcon}) center no-repeat`,
    transform: ({ selectProps: { menuIsOpen } }) => (menuIsOpen ? 'rotate(180deg)' : ''),
    transition: 'transform .3s ease-in-out',
  },
};
