// @flow
import React, { forwardRef } from 'react';
import AvatarMui, { type AvatarProps } from '@mui/material/Avatar';

import PersonIcon from '@mui/icons-material/Person';

// TODO: check types, its temporary
type TAvatar = {
  size?: number,
  src: string | null,
  name?: string,
  sx?: {|
    [key: string]: string,
  |},
} & AvatarProps;

const Avatar = forwardRef(({ src, name, size, sx }: TAvatar, ref) => {
  const props = {
    alt: name || 'avatar',
  };

  const avatarSx = {
    ...(size && { width: size, height: size }),
    ...sx,
  };

  return src ? (
    <AvatarMui src={src} sx={avatarSx} ref={ref} {...props} />
  ) : (
    <AvatarMui sx={avatarSx} ref={ref} {...props}>
      <PersonIcon sx={{ width: '70%', height: '70%' }} />
    </AvatarMui>
  );
});

export default Avatar;
