// @flow
import { useCallback, useRef, useState } from 'react';

type TUseResize = {
  current: number,
  min: number,
  max: number,
  onMouseUp?: () => void,
  onChange?: () => void,
  isRtl?: boolean,
};
// https://codesandbox.io/s/resizable-drawer-forked-3e6po?file=/src/CustomDrawer.js:192-245
// https://codesandbox.io/s/resizable-drawers-mui-fpk52?from-embed=&file=/src/ResponsiveDrawer.js:1950-2016 --- second variant
const useResize = ({ current, min, max, isRtl, onMouseUp, onChange }: TUseResize) => {
  const [width, setWidth] = useState(current);
  const widthRef = useRef(current); // on mouseup callback, width from state not actual, so used ref

  const handleMouseMove = useCallback(
    (e) => {
      const newWidth = isRtl ? e.clientX : window.innerWidth - e.clientX;

      if (newWidth >= min && newWidth <= max) {
        setWidth(newWidth);
        widthRef.current = newWidth;

        if (typeof onChange === 'function') {
          onChange(e, widthRef.current);
        }
      }
    },
    [min, max, isRtl, onChange],
  );

  const handleMouseUp = () => {
    if (typeof onMouseUp === 'function') {
      onMouseUp(widthRef.current);
    }

    if (document.body) {
      document.body.classList.remove('UserSelect-none');
    }

    document.removeEventListener('mouseup', handleMouseUp, true);
    document.removeEventListener('mousemove', handleMouseMove, true);
  };

  const handleMouseDown = () => {
    if (document.body) {
      document.body.classList.add('UserSelect-none');
    }
    document.addEventListener('mouseup', handleMouseUp, true);
    document.addEventListener('mousemove', handleMouseMove, true);
  };

  return {
    width,
    handleMouseDown,
  };
};

export default useResize;
