// @flow
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDrag } from 'react-dnd';
import { getOppositeCoordinates } from 'components/TextractCanvas/utils';

import Box from '@mui/material/Box';
import { DragLineExtractionContainer } from 'components/TextractCanvas/components/DragLineExtraction/StyledComponents';

import { type TextractPolygon } from 'domain/textract/types.js.flow';

type Props = {
  width: number,
  height: number,
  page: number,
  tableCoordinates: Array<TextractPolygon>,
  mappings: Array<{|
    name: string,
    bindingCell: {
      [key: number]: {|
        X: number,
        Y: number,
      |},
    },
    id: string,
    metadata: {
      mandatory: boolean,
    },
  |}>,
};

const cropAndMerge = (canvases, width, height, callback) => {
  const buffer = document.createElement('canvas');
  const bufferCanvasContext = buffer.getContext('2d');

  buffer.width = width * 0.25;
  buffer.height = height * 0.25;

  canvases.forEach(({ canvas, offsetX, offsetY, width: imgWidth, height: imgHeight }) => {
    bufferCanvasContext.drawImage(canvas, offsetX, offsetY, imgWidth, imgHeight, 0, 0, buffer.width, buffer.height);
  });

  callback(buffer.toDataURL());
};

const isCanvasReady = (canvas) => canvas !== null && !!canvas?.offsetWidth && !!canvas?.offsetHeight;

const DragLineExtraction: React$StatelessFunctionalComponent<Props> = ({
  width,
  height,
  page,
  tableCoordinates,
  mappings,
}) => {
  const [previewImg, setPreviewImg] = useState('');
  const textractCanvasId = `textract-${page}`;
  const pdfPageContainerId = `pdfPage-${page}`;

  const [, dragRef, preview] = useDrag({
    item: {
      type: 'extractLines',
      name: 'textract',
    },
  });

  const textractCanvas = document.getElementById(textractCanvasId);
  const pdfCanvas = document.getElementById(pdfPageContainerId)?.getElementsByTagName('canvas')[0];

  const normalizePositionByAngle = useCallback((points, w, h) => {
    const [topLeft, bottomRight] = getOppositeCoordinates(points);

    const normalizedW = w * (bottomRight.X - topLeft.X);
    const normalizedH = h * (bottomRight.Y - topLeft.Y);
    const topPoint = h * topLeft.Y;
    const leftPoint = w * topLeft.X;

    return [topPoint, leftPoint, normalizedH, normalizedW];
  }, []);

  const getCellRectStyles = useCallback(
    (points) => {
      const [top, left, cellH, cellW] = normalizePositionByAngle(points, width, height);

      return {
        top,
        left,
        width: cellW,
        height: cellH,
      };
    },
    [normalizePositionByAngle, height, width],
  );

  const tablePreviewOptions = useMemo(() => {
    const [offsetY, offsetX, elementH, elementW] = normalizePositionByAngle(tableCoordinates, width, height);

    return {
      offsetX,
      offsetY,
      width: elementW,
      height: elementH,
    };
  }, [normalizePositionByAngle, tableCoordinates, width, height]);

  useEffect(() => {
    if (previewImg.length) {
      const img = new Image();
      img.src = previewImg;
      preview(img);
    }
  }, [previewImg, preview]);

  useEffect(() => {
    if (isCanvasReady(pdfCanvas) && isCanvasReady(textractCanvas) && !previewImg.length) {
      const pdfCanvasOptions = {
        canvas: pdfCanvas,
        ...tablePreviewOptions,
      };

      const textractCanvasOptions = {
        canvas: textractCanvas,
        ...tablePreviewOptions,
      };

      cropAndMerge(
        [pdfCanvasOptions, textractCanvasOptions],
        tablePreviewOptions.width,
        tablePreviewOptions.height,
        setPreviewImg,
      );
    }
  }, [pdfCanvas, textractCanvas, tablePreviewOptions, previewImg.length]);

  return (
    <DragLineExtractionContainer ref={dragRef}>
      {mappings.map(({ bindingCell, id }) => (
        <Box
          key={id}
          position="absolute"
          zIndex={3}
          style={{
            cursor: 'move',
            ...getCellRectStyles(bindingCell),
          }}
        />
      ))}
    </DragLineExtractionContainer>
  );
};

export default DragLineExtraction;
