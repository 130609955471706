// @flow
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { isAuthorizedSelector } from 'domain/env';
import ROUTES_PATH from 'domain/router/routesPathConfig';
import { generatePath } from 'react-router-dom';
import { prevLocationSelector } from 'domain/router';

import NotFoundContainer from './components/NotFoundContainer';

const mapStateToProps = (state) => ({
  isAuthorized: isAuthorizedSelector(state),
  prevLocation: prevLocationSelector(state),
});

const NotFoundPage = () => {
  const { isAuthorized, prevLocation } = useSelector(mapStateToProps);

  const link = useMemo(() => {
    const { pathname } = prevLocation;
    if (pathname === '') {
      return pathname;
    }
    return isAuthorized ? '/' : generatePath(ROUTES_PATH.AUTH_LOGIN.absolute);
  }, [prevLocation, isAuthorized]);

  return (
    <NotFoundContainer
      link={link}
      title={{ id: '404.header', defaultMessage: 'Page not found' }}
      description={{ id: '404.description', defaultMessage: 'The page you are looking for does not exist' }}
      buttonText={{ id: '404.backButton', defaultMessage: 'Back to homepage' }}
    />
  );
};

export default NotFoundPage;
