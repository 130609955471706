/* @flow */
import React from 'react';
import type { CategoriesList, CategoryRecord } from 'domain/categories/types.js.flow';
import type { DocDropParams } from 'pages/company/type.js.flow';

import Node from 'pages/company/CompanyNavigation/Categories/Node';

type Props = {|
  tree: CategoriesList,
  onCategoryClick: (e: MouseEvent, item: CategoryRecord) => void,
  onDocMove: (payload: DocDropParams) => void,
|};

const Categories: React$StatelessFunctionalComponent<Props> = ({ tree, onCategoryClick, onDocMove }: Props) =>
  tree.map((item) => (
    <Node
      onDocMove={onDocMove}
      onCategoryClick={onCategoryClick}
      key={item.id}
      item={item}
      level={item.level}
      tree={item.children}
    />
  ));

export default Categories;
