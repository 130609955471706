import { styled } from '@mui/material/styles';

export const QuickActionsWidget = styled('div', { label: 'QuickActionsWidget' })(({ theme }) => ({
  boxShadow: theme.shadows[3],
  backgroundColor: theme.palette.common.white,
  padding: theme.spacing(2),
  position: 'absolute',
  right: theme.spacing(3),
  zIndex: 1,
  top: theme.spacing(3),
  width: '20%',
  minWidth: 284,
}));
