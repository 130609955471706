/* @flow */
import moment from 'moment';

export function getDate(timestamp: string): string {
  return moment(timestamp).format('DD MMM HH:mm');
}

interface Message {
  firstName?: string;
  lastName?: string;
}

export function fullName(user: Message) {
  const name = [];
  if (user.firstName) {
    name.push(user.firstName);
  }
  if (user.lastName) {
    name.push(user.lastName);
  }
  return name.join(' ');
}
