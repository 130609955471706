// @flow
import React from 'react';
import { useSelector } from 'react-redux';

import { rtlEnable } from 'domain/env';

import InfoIcon from '@mui/icons-material/Info';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import Tooltip from 'components/mui/Tooltip';

type Props = {
  children: React$Node,
  isHelp?: boolean,
};

const iconProps = {
  fontSize: 'small',
  sx: { color: 'grey.600' },
};

const Hint: React$StatelessFunctionalComponent<Props> = ({ children, isHelp = false }) => {
  const isRtl = useSelector(rtlEnable);
  return (
    <Tooltip placement={isRtl ? 'left' : 'right'} t={children}>
      {isHelp ? <HelpOutlineOutlinedIcon {...iconProps} /> : <InfoIcon {...iconProps} />}
    </Tooltip>
  );
};

export default Hint;
