/* @flow */
import React from 'react';

import AuthLogo from 'pages/authPage/Layout/Logo';
import AuthLayoutHeader from 'pages/authPage/Layout/Header';
import AuthAsideDefault from 'pages/authPage/Layout/AuthAside/Default';
import FooterWithTerms from 'components/mui/Layouts/components/FooterWithTerms';
import { AuthLayoutGrid, AuthMain, AuthMainContainer, AuthMainContent } from 'pages/authPage/Layout/StyledComponents';

type TAuthLayout = {
  children: React$Node,
};

function AuthLayout({ children }: TAuthLayout) {
  const mqColorFooterLink = { xs: 'common.white', md: 'primary.main' };

  return (
    <AuthLayoutGrid>
      <AuthAsideDefault />
      <AuthMain>
        <AuthLayoutHeader />
        <AuthMainContainer disableGutters maxWidth={false}>
          <AuthLogo />
          <AuthMainContent>{children}</AuthMainContent>
        </AuthMainContainer>
        <FooterWithTerms mqColor={mqColorFooterLink} />
      </AuthMain>
    </AuthLayoutGrid>
  );
}

export default AuthLayout;
