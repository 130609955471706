// @flow
import React from 'react';
import PhoneBase, { type TPhoneBase } from 'components/mui/Form/Phone/PhoneBase';
import type { FieldProps } from 'redux-form/lib/FieldProps.types.js.flow';

type TPhoneForm = TPhoneBase & FieldProps;

const PhoneForm: React$StatelessFunctionalComponent<TPhoneForm> = ({ input, meta, ...props }) => {
  const { onChange, value } = input;
  const isError = Boolean(meta.touched && meta.error);

  return <PhoneBase onChange={onChange} value={value} error={isError} {...props} />;
};

export default PhoneForm;
