// @flow
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import SearchBase from 'components/mui/Layouts/components/AppbarSearchBase';
import { organizationFilterSelector, setOrganizationsFilter } from 'domain/organization';

const AppBarSearch = () => {
  const dispatch = useDispatch();
  const term = useSelector(organizationFilterSelector);

  const onChange = (value: string) => {
    dispatch(setOrganizationsFilter(value));
  };

  return <SearchBase value={term} onChange={onChange} />;
};

export default AppBarSearch;
