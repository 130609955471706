// @flow
import React from 'react';
import useAppbarCompanyLogo from 'components/mui/Layouts/components/AppbarLogoCompany/useAppbarCompanyLogo';

import LogoBase from 'components/mui/Layouts/components/AppbarLogoBase';

const AppBarLogo = () => {
  const { name, email, phone, src, isAllowPhoneLink } = useAppbarCompanyLogo();

  return <LogoBase src={src} name={name} email={email} phone={phone} isAllowPhoneLink={isAllowPhoneLink} />;
};

export default AppBarLogo;
