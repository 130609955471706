// @flow
import React, { useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { compose } from 'redux';
import { reduxForm, Field, type FormProps } from 'redux-form/immutable';

import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import FormHelperText from '@mui/material/FormHelperText';

import TextFieldForm from 'components/mui/Form/TextField/TextFieldForm';
import DateTimeField from 'components/mui/Form/DateTimeField';
import { addError, rules } from 'components/Form/validation';

type TManageApiKeysForm = {
  isEdit: boolean,
  onCancel: () => void,
  userIP: string,
  dateFormat: string,
} & FormProps;

const ManageApiKeysForm: React$StatelessFunctionalComponent<TManageApiKeysForm> = ({
  handleSubmit,
  isEdit,
  invalid,
  submitting,
  onCancel,
  userIP,
  dateFormat,
}) => {
  const { formatMessage } = useIntl();

  const submitButtonTitle = useMemo(
    () =>
      isEdit ? (
        <FormattedMessage id="button.save" defaultMessage="Save" />
      ) : (
        <FormattedMessage id="button.create" defaultMessage="Create" />
      ),
    [isEdit],
  );

  return (
    <form onSubmit={handleSubmit}>
      <DialogContent>
        <Stack spacing={2}>
          <Field
            component={TextFieldForm}
            label={formatMessage({
              id: 'form.organization.apiKeys.create.keyName.label',
              defaultMessage: 'Key name',
            })}
            required
            name="key_name"
            id="key_name"
            fullWidth
            withTrim
          />
          <Field
            component={TextFieldForm}
            label={formatMessage({
              id: 'form.organization.apiKeys.create.ipAddresses.label',
              defaultMessage: 'IP-addresses',
            })}
            name="ips"
            id="ips"
            placeholder={userIP}
            fullWidth
            withTrim
          />
          <FormHelperText sx={{ mt: '0 !important', ml: '12px !important' }}>
            {formatMessage({
              id: 'form.organization.apiKeys.create.ipAddresses.description',
              defaultMessage: 'Add allowed IP addresses (comma-separated).',
            })}
          </FormHelperText>
          <Field
            component={DateTimeField}
            label={formatMessage({
              id: 'form.organization.apiKeys.create.expirationDate.label',
              defaultMessage: 'Expiration',
            })}
            required
            name="expiration"
            id="expiration"
            fullWidth
            extraProps={{
              format: dateFormat,
            }}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={onCancel}>
          {formatMessage({
            id: 'button.cancel',
            defaultMessage: 'Cancel',
          })}
        </Button>
        <LoadingButton variant="contained" type="submit" loading={submitting} disabled={invalid || submitting}>
          {submitButtonTitle}
        </LoadingButton>
      </DialogActions>
    </form>
  );
};

const validator = (values) => (errors, fieldName) => {
  const val = values.get(fieldName, '');
  switch (fieldName) {
    case 'key_name':
      return compose(addError(fieldName, rules.required(val)))(errors);

    case 'expiration':
      return compose(
        addError(fieldName, rules.date(val)),
        addError(fieldName, rules.isMomentValue(val)),
        addError(fieldName, rules.required(val)),
      )(errors);

    default:
      return errors;
  }
};

const validate = (values) => ['key_name', 'expiration'].reduce(validator(values), {});

export default compose(
  reduxForm({
    form: 'organizationApiKeysForm',
    validate,
  }),
)(ManageApiKeysForm);
