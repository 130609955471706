// @flow
import { List, Map } from 'immutable';
import { type OrganizationType } from 'domain/organization';
import { type CompaniesList } from 'domain/companies/companiesSelector';
import type { EnhancedUserList, UserType } from 'domain/organizationUser/types.js.flow';

const getNumberWithDash = (number: string | number) => `${number}`.replace(/^.{3}/, (m) => `${m}-`);

const removeDashFromString = (number: string) => number.replace(/-/, '');

// ** info-main form for company and organization */

export const prepareToForm = (data: OrganizationType) =>
  data.update('whatsapp_phone', (w) => w && getNumberWithDash(w));

export const prepareToSubmit = (data: OrganizationType) =>
  data.update('whatsapp_phone', (w) => w && removeDashFromString(w));

export const getUserDisplayName = ({ first_name, last_name }: UserType) => {
  const fullName = `${typeof first_name === 'string' ? `${first_name} ` : ''}${last_name || ''}`;
  return fullName;
};

function descendingComparator(a, b, sortBy) {
  if (b[sortBy] < a[sortBy]) {
    return -1;
  }
  if (b[sortBy] > a[sortBy]) {
    return 1;
  }
  return 0;
}

export function getComparator(order, sortBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, sortBy)
    : (a, b) => -descendingComparator(a, b, sortBy);
}

const rolesOrder = [
  'admin',
  'accountant',
  'limited-accountant',
  'restricted-accountant',
  'user',
  'confidential-user',
  'restricted-user',
  'confidential-restricted-user',
  'supplier',
];

export const sortUsers = (users: EnhancedUserList) => {
  const userGroups = users
    .slice()
    .sort((a, b) => a.display_name.localeCompare(b.display_name))
    .reduce((map, user) => {
      const groupName = user.admin ? 'admin' : user.role;
      const group = map.get(groupName) || List();

      return map.set(groupName, group.push(user));
    }, Map());

  return rolesOrder.reduce((list, role) => {
    const userGroup = userGroups.get(role) || List();
    return list.concat(userGroup);
  }, List());
};

export const sortCompanies = (companies: CompaniesList) =>
  companies.slice().sort((a, b) => a.cname.localeCompare(b.cname));
