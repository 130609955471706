// @flow
import mediaQuery from 'lib/mediaQuery';

const btn = {
  outline: 'none',
  border: 'none',
  borderRadius: 4,
  fontWeight: 400,
  fontSize: 14,
  lineHeight: '16px',
  cursor: 'pointer',
  transition: '0.3s background',
  maxHeight: 50,
  padding: [12, 15],
  minHeight: 40,
  '&:focus': {
    outline: 'none',
  },
  '&[disabled]:hover': {
    boxShadow: 'none',
    cursor: 'not-allowed',
  },
};

const uiBtn = (theme) => ({
  // backgroundColor: theme.colors.secondaryDark,
  color: '#fff',
  borderRadius: 4,
  padding: [12, 15],
  fontFamily: 'Open Sans',
  fontWeight: 600,
  fontSize: 13,
  cursor: 'pointer',
  width: '100%',
  maxHeight: 50,
  minHeight: 40,
  transition: '0.3s all',
  outline: 'none',
  border: 'none',
  '&:hover': {
    boxShadow: `0 2px 25px ${theme.colors.secondaryLight}`,
    backgroundColor: theme.colors.secondaryLight,
  },
  '&:focus': {
    outline: 'none',
  },
  '&:active': {
    backgroundColor: theme.colors.secondaryDark,
  },
  '&[disabled]': {
    backgroundColor: theme.colors.greyDarker,
    color: 'rgba(255, 255, 255, 0.8)',
  },
  '&[disabled]:hover': {
    boxShadow: 'none',
    cursor: 'not-allowed',
  },
});

export default (theme) => ({
  btn: uiBtn(theme),
  btnGray: {
    ...uiBtn(theme),
    backgroundColor: theme.colors.greyDark,
    color: '#FFF',
    '&:hover': {
      backgroundColor: theme.colors.greyLightest,
    },
    '&:active': {
      backgroundColor: theme.colors.greyDark,
    },
  },
  btnDanger: {
    ...uiBtn(theme),
    backgroundColor: theme.colors.primaryDanger,
    color: '#ffffff',
    '&:hover': {
      boxShadow: '0 2px 25px rgba(50, 45, 45, 0.25)',
      backgroundColor: theme.colors.primaryDanger,
    },
    '&:active': {
      backgroundColor: theme.colors.primaryDanger,
    },
  },
  btnBordered: {
    ...btn,
    border: '1px solid',
    backgroundColor: '#FFF',
    fontSize: 13,
    padding: [12, 15],
    lineHeight: '15px',
    borderColor: theme.colors.primaryLight,
    transition: 'border 0.2s ease, color 0.2s ease, background 0.2s ease',
    color: theme.colors.greyDarkest,
    '&:hover': {
      borderColor: theme.colors.primaryLight,
      boxShadow: '0px 4px 12px rgba(63, 109, 178, 0.1)',
      color: theme.colors.primaryLight,
      borderSize: 2,
    },
    '&:active': {
      backgroundColor: '#fff',
      borderColor: theme.colors.primaryDark,
      borderWidth: 2,
      color: '#fff',
    },
    '&:focus': {
      outline: 'none',
    },
    '&[disabled]': {
      color: theme.colors.greyLight,
      borderColor: theme.colors.greyLight,
    },
  },
  btnType2: {
    ...btn,
    color: '#4F4F4F',
    background: '#fff',
    border: '1px solid #E0E0E0',
    '&:hover': {
      backgroundColor: '#FCFCFC',
    },
    '&:active': {
      backgroundColor: '#fff',
    },
    '&[disabled]': {
      backgroundColor: theme.colors.greyLighter,
      color: '#E0E0E0',
    },
  },
  btnType3: {
    ...btn,
    color: '#fff',
    background: theme.colors.primaryLight,
    border: `1px solid ${theme.colors.primaryLight}`,
    '&:hover': {
      boxShadow: '0px 12px 24px rgba(63, 109, 178, 0.2)',
    },
    '&:active': {
      background: theme.colors.primaryDark,
      border: '1px solid #224F92',
    },
    '&[disabled]': {
      background: '#DEE2E6',
      border: '1px solid #DEE2E6',
      color: '#fff',
    },
  },
});

export const resetButton = {
  background: 'none',
  border: 'none',
  cursor: 'pointer',
  borderRadius: 2,
  '&:focus': {
    outline: 'none',
  },
  '&[disabled]:hover': {
    cursor: 'not-allowed',
  },
};

// shadows
export const shadow = {
  boxShadow: '0px 12px 24px rgba(0, 0, 0, 0.1)',
};

export const modalBehaviours = {
  modalTitle: {
    [`@media (max-height: ${mediaQuery.maxHeight})`]: {
      padding: [30, 30, 0],
      fontSize: 20,
    },
  },
  modalTitleNoPadding: {
    [`@media (max-height: ${mediaQuery.maxHeight})`]: {
      padding: 0,
      fontSize: 20,
    },
  },
  modalButtonGroupBottomMargin: {
    [`@media (max-height: ${mediaQuery.maxHeight})`]: {
      paddingBottom: 20,
    },
  },
};
