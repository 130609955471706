import React, { memo, useCallback, useMemo, useRef, useLayoutEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import useOnClickOutside from 'hooks/useOnClickOutside';

import { rectBounding } from '../helpers';

import Paper from '@mui/material/Paper';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';

export type TDataForContextMenu = {|
  text: string,
  shapeBounding: DOMRectReadOnly,
  containerBounding: DOMRectReadOnly,
|};

type TContextMenuProps = {
  isOpen: boolean,
  onClose: () => void,
  onClickCopy: () => void,
  dataForContextMenu: TDataForContextMenu,
};

export const defaultDataForContextMenu: TDataForContextMenu = {
  text: '',
  shapeBounding: rectBounding,
  containerBounding: rectBounding,
};

const ContextMenu = ({ isOpen, onClose, onClickCopy, dataForContextMenu }: TContextMenuProps) => {
  const contextMenuRef = useRef();
  const [menuBounding, setMenuBounding] = useState(rectBounding);

  const position = useMemo(() => {
    if (isOpen) {
      const { shapeBounding, containerBounding } = dataForContextMenu;
      const { right: shapeR, top: shapeT, left: shapeL, bottom: shapeB } = shapeBounding;
      const { top: docT, left: docL, width: docW } = containerBounding;
      const { width: menuW } = menuBounding;

      const shapeOffset = {
        left: Math.abs(docL - shapeL),
        right: Math.abs(docL - shapeR),
        top: Math.abs(docT - shapeT),
        bottom: Math.abs(docT - shapeB),
      };

      const menuPosition = {
        left: shapeOffset.right,
        top: shapeOffset.bottom,
      };

      if (shapeOffset.right + menuW > docW) {
        menuPosition.left = docW - menuW - 5;
      }

      return menuPosition;
    }

    return {};
  }, [dataForContextMenu, isOpen, menuBounding]);

  // TODO: make dynamic handlers if more than one item
  const onClickItem = useCallback(() => {
    onClickCopy();
    onClose();
  }, [onClose, onClickCopy]);

  useOnClickOutside(contextMenuRef, onClose);

  useLayoutEffect(() => {
    if (contextMenuRef.current) {
      setMenuBounding(contextMenuRef.current.getBoundingClientRect());
    }
  }, [isOpen]);

  return (
    isOpen && (
      <Paper
        sx={{ position: 'absolute', left: 'auto', top: 'auto', zIndex: 3 }}
        style={{ ...position }}
        ref={contextMenuRef}
      >
        <MenuList disablePadding>
          <MenuItem onClick={onClickItem} role="button" tabIndex={0} onKeyUp={onClickItem}>
            <ListItemIcon>
              <ContentCopyIcon color="primary" />
            </ListItemIcon>
            <ListItemText>
              <FormattedMessage id="document.polygon.contextMenu.copy" defaultMessage="Copy" />
            </ListItemText>
          </MenuItem>
        </MenuList>
      </Paper>
    )
  );
};

export default memo(ContextMenu);
