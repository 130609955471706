// @flow
/* eslint-disable react/no-danger */
import React from 'react';
import { FixedSizeList } from 'react-window';
import { type List, is } from 'immutable';
import { updateStyle, scrollBarWidth } from 'lib/domHelpers';
import type { OptionsItemType } from 'domain/journal/helper';
import { type ValueNormalize } from '../helpers';
import { throttleCancelable } from 'lib/helpers';

import OptionAddNew from 'components/Tables/Autocomplete/options/OptionAddNew';
import HighlightText from 'components/mui/HighlightText';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import Box from '@mui/material/Box';
import ListItemButton from '@mui/material/ListItemButton';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import OptionHint from 'components/Tables/Autocomplete/options/OptionHint';

import EditIcon from '@mui/icons-material/Edit';

import { withStyles } from '@mui/styles';
import sheet from './sheet';

export type OptionProps = {|
  inputEl?: HTMLInputElement,
  optionList: List<OptionsItemType>,
  valueNormalize: ValueNormalize,
  doSelect: (idx: number) => void,
  setActive: (idx: number) => void,
  index: ?number,
  term: string,
  rtl: boolean,
  setDirection: (s: boolean) => void,
  defaultValue: ?string,
  onRenderItems: (from: number, to: number) => void,
  isLoading: boolean,
  minWidth: number,
  maxWidth: number,
  itemHeight: number,
  itemsCount: number,
  pageItemsCount: number,
  setListRef: any,
  isDisabledScroll: boolean,
|};

type Props = {
  ...OptionProps,
  classes: {
    [key: string]: string,
  },
  children: React$Node,
  addNewItem: ?(cb: () => void) => void,
  onClickEdit: (item: OptionsItemType) => void,
};

type State = {
  height: number,
  style: {
    bottom?: number,
  },
  visibleStartIndex: number,
};

type VListRenderParams = {
  overscanStartIndex: number,
  overscanStopIndex: number,
  visibleStartIndex: number,
  visibleStopIndex: number,
};

const DEFAULT_ITEMS_PER_PAGE = 10;

class Options extends React.Component<Props, State> {
  maxContentWidth: number = 0;

  optionsEl: { [key: number | string]: HTMLButtonElement } = {};

  scrollBox: ?HTMLElement;

  container: ?HTMLElement;

  body: ?HTMLElement;

  constructor(props: Props) {
    super(props);

    this.state = {
      height: 0,
      style: {},
      visibleStartIndex: 0,
    };
  }

  componentDidMount() {
    const { isDisabledScroll, inputEl } = this.props;
    this.body = document.querySelector('[data-element="content.document.tableBlock"]');
    if (this.body) {
      updateStyle(this.body, [{ name: 'padding-right', value: scrollBarWidth() }]);
    }

    if (!isDisabledScroll) {
      updateStyle(this.body, [{ name: 'overflow', value: 'hidden' }]);
      this.disableScroll();
    }

    this.setContentWidth(inputEl);
    this.initPosition();
  }

  shouldComponentUpdate(nextProps: Props, nextState: State) {
    const { term, optionList, index, isLoading, minWidth } = this.props;
    const { directionUP, visibleStartIndex } = this.state;
    if (term !== nextProps.term) {
      this.resetContentWidth(nextProps.inputEl);
      return true;
    }

    if (term !== nextProps.term) return true;
    if (index !== nextProps.index) return true;
    if (minWidth !== nextProps.minWidth) return true;
    if (!is(optionList, nextProps.optionList)) return true;
    if (isLoading !== nextProps.isLoading) return true;

    if (nextState.directionUP !== directionUP) return true;
    if (nextState.visibleStartIndex !== visibleStartIndex) return true;

    return false;
  }

  componentWillUnmount() {
    const { isDisabledScroll } = this.props;
    if (this.body) {
      updateStyle(this.body, [{ name: 'padding-right', value: null }]);
    }

    if (!isDisabledScroll) {
      updateStyle(this.body, [{ name: 'overflow', value: null }]);
      this.enableScroll();
    }
    this.onMouseMove.cancel();
  }

  onMouseMove = throttleCancelable(20, (index: number) => {
    const { setActive } = this.props;
    setActive(this.vListKeyToDataKey(index));
  });

  /* onMouseMove = (index: number) => {
    const { setActive } = this.props;
    setActive(this.vListKeyToDataKey(index));
  } */

  onListRender = ({ visibleStartIndex, visibleStopIndex }: VListRenderParams) => {
    const { onRenderItems = (x) => x } = this.props;
    onRenderItems(visibleStartIndex, visibleStopIndex);
    this.setState({ visibleStartIndex });
    this.initPosition();
    this.forceUpdate();
  };

  onClickEdit = (e: SyntheticMouseEvent, item: OptionsItemType) => {
    const { onClickEdit } = this.props;
    e.preventDefault();
    e.stopPropagation();
    onClickEdit(item);
  };

  getValue(value: string) {
    const { term } = this.props;
    return <HighlightText value={value} searchTerm={term} component="span" />;
  }

  getDataList = () => {
    const { optionList } = this.props;
    return this.isShiftList() ? optionList.unshift(null) : optionList;
  };

  getWidth = () => {
    const minWidth = this.isShiftList() ? 200 : 0;
    return Math.min(Math.max(this.maxContentWidth, minWidth), window.innerWidth - 50);
  };

  getItemCounts = () => {
    const { isLoading } = this.props;
    const getDateList = this.getDataList();
    return isLoading ? getDateList.size + 1 : getDateList.size;
  };

  getActiveText = () => {
    const data = this.getDataList();
    const activeIndex = this.getActiveIndex();
    return activeIndex >= 0 && data && data.get(activeIndex) ? data.get(activeIndex).display : null;
  };

  getActiveEl = () => this.optionsEl[this.getActiveIndex()];

  getHintProps = () => {
    const optionEl = this.getActiveEl();
    const text = this.getActiveText();
    const width = this.getWidth();
    return optionEl && text && optionEl.offsetWidth > width ? { optionEl, text, width } : null;
  };

  getActiveIndex = () => {
    const { index } = this.props;
    return this.isShiftList() ? index + 1 : index;
  };

  isActiveIndex = (index: number) => index === this.getActiveIndex();

  getPageItemCount = () => Math.min(this.getAutoHeightCount(), this.getItemCounts());

  getAutoHeightCount = () => {
    const { itemHeight, pageItemsCount } = this.props;
    const { height } = this.state;
    return height && itemHeight ? Math.floor(height / itemHeight) : pageItemsCount || DEFAULT_ITEMS_PER_PAGE;
  };

  setOptionsElement = (el: HTMLButtonElement, index: number, isEditable = false) => {
    this.optionsEl[index] = el;
    const increase = isEditable ? 10 : 0;
    this.setContentWidth(el, increase);
  };

  resetContentWidth = (el: HTMLButtonElement) => {
    this.maxContentWidth = 0;
    this.setContentWidth(el);
    this.forceUpdate();
  };

  setContentWidth = (el: HTMLButtonElement, increase = 0) => {
    if (el) {
      const width = el.offsetWidth;
      this.maxContentWidth = width > this.maxContentWidth ? width + increase : this.maxContentWidth;
    }
  };

  vListKeyToDataKey = (key: number): number | string => {
    const subtract = this.isShiftList() ? 1 : 0;
    return key - subtract;
  };

  initPosition = () => {
    const { inputEl } = this.props;
    if (inputEl) {
      const { top, height, left } = inputEl.getBoundingClientRect();
      const width = this.getWidth();
      const { innerHeight } = window;
      const center = Math.floor(top + height / 2);
      const isDirectionUP = innerHeight / center < 2;
      const maxHeight = isDirectionUP ? top : innerHeight - center;
      const overlap = width + left + 5 - window.innerWidth;
      const currentLeft = overlap > 0 ? left - overlap : left;
      const style = isDirectionUP
        ? {
            bottom: innerHeight - top,
            left: currentLeft,
          }
        : {
            top: top + height,
            left: currentLeft,
          };

      const margin = 120;
      this.setState({ height: maxHeight - margin, style });
    }
  };

  isShiftList = () => {
    const { addNewItem } = this.props;
    return !!addNewItem;
  };

  enableScroll = () => {
    if (this.scrollBox) {
      updateStyle(this.scrollBox, [{ name: 'overflow', value: null }]);
      this.scrollBox.removeAttribute('data-scroll-box');
    }
  };

  disableScroll = () => {
    const { inputEl } = this.props;
    if (inputEl) {
      this.scrollBox =
        inputEl.closest('.scroll-box') ||
        document.querySelector('[data-element="je.grid.float"]') ||
        document.querySelector('.search-scroll-box');
      if (this.scrollBox) {
        updateStyle(this.scrollBox, [{ name: 'overflow', value: 'hidden' }]);
        this.scrollBox.setAttribute('data-scroll-box', 'open');
      }
    }
  };

  refContainer = (el: HTMLDivElement) => {
    this.container = el;
    this.forceUpdate();
  };

  renderBaseItem = ({ index, data, style }) => {
    const { doSelect, classes } = this.props;
    const isActive = this.isActiveIndex(index);
    const itemData = data.get(index);

    return itemData ? (
      <Box display="flex" style={style}>
        <ListItemButton
          selected={isActive}
          disabled={!itemData.isActive}
          dense
          onMouseDown={(e) => {
            e.preventDefault();
          }}
          sx={{ direction: 'ltr', paddingRight: '28px' }}
          onMouseMove={() => this.onMouseMove(index)}
          onMouseEnter={() => this.onMouseMove(index)}
          onClick={() => doSelect(this.vListKeyToDataKey(index))}
        >
          <div className={classes.optionTextRuler} ref={(el) => this.setOptionsElement(el, index, itemData.isEditable)}>
            {itemData.display}
          </div>
          <span className={classes.optionText}>{this.getValue(itemData.display)}</span>
        </ListItemButton>
        {itemData.isEditable && (
          <IconButton
            color="primary"
            sx={{ position: 'absolute', top: 1, right: 0 }}
            onClick={(e: SyntheticMouseEvent) => this.onClickEdit(e, itemData)}
          >
            <EditIcon />
          </IconButton>
        )}
      </Box>
    ) : (
      <ListItem component="div" sx={{ direction: 'ltr' }} style={style}>
        <CircularProgress size={12} />
      </ListItem>
    );
  };

  renderItem = (params) => {
    const { addNewItem } = this.props;
    const { index, style } = params;
    const isAddNew = this.isShiftList() && index === 0;
    return isAddNew ? <OptionAddNew onClick={addNewItem} style={style} /> : this.renderBaseItem(params);
  };

  render() {
    const { style, visibleStartIndex } = this.state;
    const { classes, itemHeight, setListRef, index, isLoading } = this.props;

    const itemCounts = this.getItemCounts();
    const hintProps = this.getHintProps();

    return (
      <Paper className={classes.list} style={style} ref={this.refContainer}>
        {this.container && (itemCounts || isLoading) && itemHeight && (
          <>
            {hintProps && <OptionHint key={visibleStartIndex} {...hintProps} />}
            <FixedSizeList
              className="list"
              height={itemHeight * this.getPageItemCount()}
              itemCount={itemCounts}
              itemSize={itemHeight}
              width={this.getWidth()}
              itemData={this.getDataList()}
              onItemsRendered={this.onListRender}
              ref={setListRef}
              activeIndex={index}
            >
              {this.renderItem}
            </FixedSizeList>
          </>
        )}
      </Paper>
    );
  }
}

export default withStyles(sheet)(Options);
