// @flow
import { asyncAction, syncAction } from 'lib/action';

export const getOrganizationUsers = asyncAction('ORGANIZATION_USERS/GET');
export const createOrganizationUser = asyncAction('ORGANIZATION_USERS/CREATE');
export const deleteOrganizationUser = asyncAction('ORGANIZATION_USERS/DELETE');
export const updateOrganizationUser = asyncAction('ORGANIZATION_USERS/UPDATE');
export const updateOrganizationUserProfile = asyncAction('ORGANIZATION_USERS/UPDATE_PROFILE');
export const updateOrganizationUserPicture = asyncAction('ORGANIZATION_USERS/UPDATE_PICTURE');
export const assignOrganizationUser = asyncAction('ORGANIZATION_USERS/ASSIGN');
export const revokeOrganizationUser = asyncAction('ORGANIZATION_USERS/REVOKE');
export const revokeUserFromAllCompanies = asyncAction('ORGANIZATION_USERS/REVOKE_FROM_ALL_COMPANIES');
export const bulkAssignOrganizationUsers = asyncAction('ORGANIZATION_USERS/BULK_ASSIGN');
export const bulkRevokeOrganizationUsers = asyncAction('ORGANIZATION_USERS/BULK_REVOKE');
export const bulkAssignOrganizationCompanies = asyncAction('ORGANIZATION_USERS/BULK_ASSIGN_COMPANIES');
export const bulkRevokeOrganizationCompanies = asyncAction('ORGANIZATION_USERS/BULK_REVOKE_COMPANIES');
export const downloadUsersReportAction = syncAction('ORGANIZATION_USERS/DOWNLOAD_USERS_REPORT');
