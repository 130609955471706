// @flow
import React, { useState } from 'react';
import type { RecordOf } from 'immutable';
import { useDispatch } from 'react-redux';
import { setCurrentOrganization } from 'domain/organization';
import ROUTES_PATH from 'domain/router/routesPathConfig';
import { generatePath } from 'react-router-dom';
import type { OrganizationListItemType } from 'domain/organization/types.js.flow';

import RouterLink from 'components/mui/Router/RouterLink';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea, CardActions } from './StyledComponents';

type TGridItem = {
  entry: RecordOf<OrganizationListItemType>,
};

const GridCard: React$StatelessFunctionalComponent<TGridItem> = ({ entry }) => {
  const dispatch = useDispatch();
  const pathTo = generatePath(ROUTES_PATH.COMPANIES.absolute);
  const [isHovered, setIsHovered] = useState(false);

  const onMouseOver = () => setIsHovered(true);

  const onMouseLeave = () => setIsHovered(false);

  const onClick = () => dispatch(setCurrentOrganization(entry.id));

  return (
    <Card raised={isHovered} onMouseOver={onMouseOver} onMouseLeave={onMouseLeave}>
      <CardActionArea component={RouterLink} href={pathTo} onClick={onClick}>
        <CardMedia
          component="img"
          height="130"
          image={entry.get('picture')}
          alt="Organization logo"
          loading="lazy"
          sx={{ objectFit: 'contain' }}
        />
      </CardActionArea>
      <CardActions>
        <Typography variant="subtitle1" textAlign="center" noWrap>
          {entry.get('name')}
        </Typography>
      </CardActions>
    </Card>
  );
};

export default GridCard;
