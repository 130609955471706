// @flow
import * as actions from './actions';
import { messagesFactory } from './adapters';

export const reducer = {
  messages(state = messagesFactory([]), action) {
    switch (action.type) {
      case actions.getMessagesAction.success:
        return action.payload;
      default:
        return state;
    }
  },
};
