// @flow
import React from 'react';
import { formatTagLabel } from 'lib/helpers';
import useToggle from 'hooks/useToggle';
import useFavoriteTags from 'pages/company/FavoriteTagsPanel/useFavoriteTags';
import { FormattedMessage } from 'react-intl';

import Tooltip from 'components/mui/Tooltip';
import Stack from '@mui/material/Stack';
import Rating from '@mui/material/Rating';
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';
import AvatarMui from '@mui/material/Avatar';
import GridFilterTags from 'pages/company/FavoriteTagsPanel/components/GridFilterTags';
import CreateFavoriteTagsModal from 'pages/company/FavoriteTagsPanel/components/CreateFavoriteTagsModal';

const FavoriteTagsPanel = () => {
  const {
    tags,
    gridTags,
    errorMeta,
    modalTitle,
    checkUserTag,
    handleSaveTags,
    handleDeleteTag,
    isSupplier,
    isOpenModal,
    handleToggleModal,
    resetErrorMeta,
  } = useFavoriteTags();
  const [isHovered, toggleIsHovered] = useToggle();

  return (
    (tags.length > 0 || gridTags) && (
      <Stack spacing={2} direction="row" alignItems="center">
        {tags.length > 0 && !isSupplier && (
          <>
            <Tooltip t={{ id: 'documents.tags.saveToFavorites', defaultMessage: 'Save to Favorites' }}>
              <Rating
                max={1}
                value={null}
                size="large"
                onChange={() => handleToggleModal()}
                onMouseOver={toggleIsHovered}
                onMouseLeave={toggleIsHovered}
              />
            </Tooltip>
            {isOpenModal && (
              <CreateFavoriteTagsModal
                onClose={handleToggleModal}
                onSubmit={handleSaveTags}
                title={modalTitle}
                resetError={resetErrorMeta}
                errorMeta={errorMeta}
              />
            )}
          </>
        )}

        <Stack direction="row" alignItems="center" flexWrap="wrap" gap={0.5}>
          {tags.map((tag) => {
            const user = checkUserTag(tag.value);

            return (
              <Chip
                key={` ${tag.value}${tag.type} `}
                color="primary"
                variant="outlined"
                label={
                  <>
                    {['date', 'invoice_date', 'payment_date'].includes(tag.type) && (
                      <>
                        <FormattedMessage id={`documents.tags.${tag.type}`} defaultMessage="Date Fil" />
                        &nbsp;
                      </>
                    )}
                    {user ? user.username : formatTagLabel(tag.value)}
                  </>
                }
                {...(user && {
                  avatar: <AvatarMui src={user.picture} imgProps={{ loading: 'lazy' }} />,
                })}
                onDelete={() => handleDeleteTag(tag)}
              />
            );
          })}
          {gridTags && tags.length > 0 && <Divider orientation="vertical" flexItem sx={{ mx: 1 }} />}
          {gridTags && <GridFilterTags gridFilters={gridTags} isHovered={isHovered} />}
        </Stack>
      </Stack>
    )
  );
};

export default FavoriteTagsPanel;
