/* @flow */
import * as React from 'react';
import cx from 'classnames';
import { withStyles } from '@mui/styles';
import sheet from './sheet';

type Props = {
  classes: {|
    [key: string]: string,
  |},
  children: React$Node,
  title: (s: boolean) => string | React$Node,
  isOpen: boolean,
  onCollapse: (status: boolean) => void,
  onBlur?: () => void,
};

type State = {
  isOpen: boolean,
};

class Accordion extends React.Component<Props, State> {
  static defaultProps = {
    isOpen: false,
    onCollapse: () => {},
  };

  state = {
    isOpen: this.props.isOpen,
  };

  componentDidUpdate(prevProps: Props) {
    const { isOpen } = this.props;
    if (isOpen !== prevProps.isOpen && this.state.isOpen !== isOpen) {
      if (!isOpen) {
        this.animateCollapse();
      }
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ isOpen });
    }
  }

  handleFocus = () => {
    this.focused = true;
    if (this.timeout) {
      clearTimeout(this.timeout);
      this.timeout = undefined;
    }
  };

  handleBlur = () => {
    const { onBlur } = this.props;
    this.timeout = setTimeout(() => {
      if (typeof onBlur === 'function') onBlur();
    }, this.blurTimeOut);
    this.focused = false;
  };

  animateCollapse = () => {
    const { isOpen } = this.state;
    if (isOpen && this.container) {
      const el = this.container;
      const height = this.container.offsetHeight;
      el.style.setProperty('height', `${height}px`);
      setTimeout(() => {
        el.style.setProperty('height', null);
      }, 100);
    }
  };

  toggle = () => {
    const {
      props: { onCollapse },
      state: { isOpen },
    } = this;
    this.setState({ isOpen: !isOpen }, () => {
      if (typeof onCollapse === 'function') onCollapse(!isOpen);
    });
  };

  refProxy = (el: ?HTMLElement) => {
    this.container = el;
  };

  blurTimeOut: number = 150;

  timeout: ?TimeoutID;

  focused: boolean;

  container: ?HTMLElement;

  render() {
    const { classes } = this.props;
    return (
      <div
        // eslint-disable-next-line
        tabIndex="1"
        onFocus={this.handleFocus}
        className={cx(classes.container, { accordion__container_open: this.state.isOpen })}
        onBlur={this.handleBlur}
      >
        <div className={classes.header}>
          <button type="button" onClick={this.toggle} className={classes.btn}>
            {this.props.title(this.state.isOpen)}
          </button>
        </div>
        <div ref={this.refProxy} className={cx(classes.content, { [classes.open]: this.state.isOpen })}>
          {this.props.children}
        </div>
      </div>
    );
  }
}

export default withStyles(sheet)(Accordion);
