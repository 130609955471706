import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

export const DocumentPreviewPortalElement = styled(Box, {
  label: 'DocumentPreviewPortalElement',
})(() => ({
  height: '100%',
  width: 'fit-content',
  boxShadow: '0px 4px 15px rgba(79, 116, 209, 0.4)',
  position: 'absolute',
  zIndex: 1,
}));

export const DocumentWidgetPanel = styled('div', {
  label: 'DocumentWidgetPanel',
  shouldForwardProp: (propName) => !['hide'].includes(propName),
})(({ hide }) => ({
  display: hide ? 'none' : 'block',
  position: 'relative',
  width: 34,
  minWidth: 34,
  flexGrow: 1,
  zIndex: 20,
}));

export const DocumentWorkspace = styled(Box, {
  label: 'DocumentWorkspace',
})(() => ({
  display: 'flex',
  width: '100%',
  flexGrow: 1,
  overflow: 'auto',
  position: 'relative',
}));
