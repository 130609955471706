// @flow
import React, { useEffect, useState } from 'react';
import useDebounceValue from 'hooks/useDebounceValue';

import TextFieldBase from 'components/mui/Form/TextField/TextFieldBase';

type TAppBarSearchBase = {
  value: string,
  onChange: (value: string) => void,
};

const InputSearch = React.forwardRef(({ value, onChange, ...rest }: TAppBarSearchBase, ref) => {
  const [currentValue, setCurrentValue] = useState(value);
  const debouncedValue = useDebounceValue(currentValue, 250);

  const onChangeInput = (e: SyntheticInputEvent<HTMLInputElement>) => {
    const { target } = e;
    setCurrentValue(target.value);
  };

  useEffect(() => {
    onChange(debouncedValue);
  }, [debouncedValue, onChange]);

  useEffect(() => {
    if (value.length === 0) {
      setCurrentValue('');
    }
  }, [value]);

  return <TextFieldBase ref={ref} value={currentValue} onChange={onChangeInput} {...rest} />;
});

export default InputSearch;
