// @flow
import { call, put, takeEvery, takeLatest, select } from 'redux-saga/effects';
import { sbChannelList } from 'sendbird-utils';
import { channelsToThreads } from 'sendbird-utils/lib/imAdapters';
import CONSTANTS from 'domain/constants';
import * as Chat from 'domain/chat/sagas';
import { signOutAction, silentSignOutAction } from 'domain/env';

import type { Saga } from 'redux-saga';
import { notify } from 'lib/errorLogger';
import { messagesLoadedSelector, allowedChatWorkerSelector } from 'domain/chat/chatSelector';
import { ensureAllChatChannel } from 'domain/chat/sagas';
import { MultyWorker } from 'lib/worker';

function* ensureAllChannel() {
  const isThreadLoaded = yield select(messagesLoadedSelector);
  if (!isThreadLoaded) {
    try {
      // all user channels from all envs are retrieved.
      // channelsToThreads internally filters by current env and adopts
      // try filtering by env initially
      const threads = channelsToThreads(CONSTANTS.BUILD_ENV, yield call(sbChannelList, 100));
      yield put({
        type: Chat.actions.chatGetThreadsAction.success,
        payload: threads,
      });
    } catch (err) {
      // New selector required as previous one holds outdated value
      const isThreadLoaded = yield select(messagesLoadedSelector);
      // We caught because several api requests were launched and after 1 successful fetch ws was disconnected
      // other calls throw due to disconnection and we consider it ok.
      // Capture event only if we disconnected during call with no thread loaded yet
      if (!isThreadLoaded) {
        notify.captureEvent(err);
      }
    }
  }
}

let isInited = false;

const stopWorker = (worker: any) => () => {
  worker.terminate('getChatMessages');
  isInited = false;
};

export default function* chat(dispatch: Dispatch<*>): Saga<*> {
  yield takeEvery(Chat.actions.chatConnectAction.success, ensureAllChannel);
  const appId = process.env.REACT_APP_SENDBIRD_APP_ID;
  if (appId) {
    yield takeEvery(Chat.actions.sendMessageAction.type, Chat.ensureSendMessage);
    yield takeEvery(Chat.actions.receiveMessageAction.success, Chat.receiveMessage);

    yield takeEvery(Chat.actions.setActiveChannelAction.type, Chat.ensureActiveChannel);
    yield takeLatest(Chat.actions.getPreviousMessageAction.type, Chat.ensureGetPreviousMessage);

  }
  const MW = new MultyWorker(dispatch);
  

  yield takeEvery([signOutAction.type, silentSignOutAction.type], stopWorker(MW));

  yield takeEvery([Chat.actions.setOpenAction.type, Chat.actions.setAllowedChatWorkerAction.type], function* () {
    const isAllowed = yield select(allowedChatWorkerSelector);
    if (!isAllowed) {
      MW.pause('getChatMessages');
    } else {
      if (!isInited) {
        yield call(MW.init, ensureAllChatChannel);
        isInited = true;
      }
      MW.resume('getChatMessages');
    }
  });
  yield call(MW.listen);
  
}
