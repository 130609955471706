// @flow
import { Record, Set, Map, List, type RecordFactory } from 'immutable';
import type { Reconciliation } from './types.js.flow';
import { type JornalEntryType, getRowByNumber, matchCell, JournalFactory } from '../journal/helper';

export type ValidatableTransaction = {|
  type: 'Invoice' | 'Bill' | 'Payment' | 'Expense',
  amount: number,
|};

export const ReconciliationFactory: RecordFactory<Reconciliation> = new Record({
  selectedEntry: List(),
  searchEntry: JournalFactory(),
  detailsRow: false,
  rowState: null,
  loading: false,
  requestRow: Map(),
  requestPopup: false,
});

export const rowAdapter = (data: JornalEntryType, rowNumber: number): JornalEntryType =>
  data.mapEntries(([key, value]) => {
    // eslint-disable-next-line no-unused-vars
    const [column, _] = matchCell(key);
    const newCellName = `${column}${rowNumber}`;
    // $FlowFixMe
    return [newCellName, value.set('_cell', newCellName)];
  });

// eslint-disable-next-line max-len
export const selectRowsWithResetCounter = (matrix: JornalEntryType, rows: Set<number>): List<JornalEntryType> =>
  rows.reduce(
    // $FlowFixMe
    (A: List<JornalEntryType>, row: number): JornalEntryType => {
      // $FlowFixMe
      const rowJE = getRowByNumber(matrix, Set([row]));
      return A.push(rowJE);
    },
    List(),
  );

export function linesReordering(gr: any, rowsList: List<number>) {
  return rowsList
    .reduce((a, row, k) => a.set(k, gr.get(row, new Map())), new Map())
    .flatMap((v, k) =>
      v.update((u) => u.mapEntries((e) => [e[1]._col + k, e[1].set('_cell', e[1]._col + k).set('_row', k)])),
    );
}

// eslint-disable-next-line max-len
export function excludeLines(gr, rows: Set<number>, col: string, status: $ReadOnlyArray<string>): Set<number> {
  return rows.filter((f) => !status.includes(gr.getIn([f, col + f, 'cellSet', 'value'])));
}

export function selectFirst3Row(rows: Set<number>) {
  return rows
    .sort((a, b) => a - b)
    .slice(0, 3)
    .toList();
}

// eslint-disable-next-line max-len
export const removeLine = (row: number, matrix: JornalEntryType): JornalEntryType | Map<empty, empty> =>
  matrix.reduce((A, V, K) => {
    // eslint-disable-next-line no-unused-vars
    const [_, cellRow] = matchCell(K);
    return cellRow === row ? A : A.set(K, V);
  }, new Map());

// eslint-disable-next-line max-len
export const actionRestricted = (transactions: $ReadOnlyArray<ValidatableTransaction>, total: number): boolean => {
  if (transactions.length === 1) {
    const transaction = transactions[0];
    return transaction.type === 'Bill' || transaction.type === 'Invoice'
      ? transaction.amount < total
      : transaction.amount !== total;
  }
  const transactionsAmount: number = transactions.reduce((A, V) => A + V.amount, 0);
  return transactionsAmount !== total;
};

export function sideEffectFlatternJeList(list: List<JornalEntryType>) {
  return list.reduce((A, V, K) => A.merge(rowAdapter(V, K)), Map());
}

// filters out given column cells
// eslint-disable-next-line max-len
export function sideEffectFilterColumnCellsFactory(column: string): (data: JornalEntryType) => JornalEntryType {
  return (data: JornalEntryType) => data.filterNot((cell) => cell._col === column);
}

export function sideEffectMakeCellsReadOnly(data: JornalEntryType): JornalEntryType {
  // $FlowFixMelook at RecordOf definition in runtime.
  return data.map((cell) => cell.set('readonly', true));
}

export function sideEffectRemoveCellStyle(data: JornalEntryType): JornalEntryType {
  // $FlowFixMe look at RecordOf definition in runtime.
  return data.map((cell) => cell.set('style', Map()));
}
