// @flow
import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { loadingSelector } from 'domain/env';
import { isImmutable } from 'immutable';
import isObject from 'lodash/isObject';

type Props = {
  isLoadingProcess?: boolean,
  [key: string]: any,
};

export const isChangeProps = (prev: Props, next: Props, propNames: Array<string> = []) =>
  !propNames.every((propName) => Object.is(prev[propName], next[propName]));

export const isChangeAnyProps = (prev: Props, next: Props) => {
  const props = Array.from(new Set([...Object.keys(prev), ...Object.keys(next)]));
  return isChangeProps(prev, next, props);
};

export type PropsConfig = Array<string>;

export const withPropsCollector = (WrappedComponent) => {
  class WithPropsCollector extends React.Component<Props> {
    shouldComponentUpdate(nextProps: Props): boolean {
      const { isLoadingProcess } = this.props;
      return !(isLoadingProcess && nextProps.isLoadingProcess);
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  WithPropsCollector.displayName = WrappedComponent.displayName;

  const mapStateToProps = (state: any) => ({
    isLoadingProcess: loadingSelector(state),
  });

  return compose(connect(mapStateToProps))(WithPropsCollector);
};

export const immutableToJs = (val) => (isImmutable(val) ? val.toJS() : val);

export const getDiffFields = (prev: Props, next: Props) => {
  const prevKeys = Object.keys(prev);
  const nextKeys = Object.keys(next);
  const propNames = Array.from(new Set([...prevKeys, ...nextKeys]));
  return propNames.reduce((res, propName) => {
    const prevProp = prev[propName];
    const nextProp = next[propName];
    const isDif = !Object.is(prevProp, nextProp);
    return isDif ? [...res, propName] : res;
  }, []);
};

export const testChangeProps = (prev: Props, next: Props) => {
  const diffFields = getDiffFields(prev, next);
  return diffFields.reduce((res, field) => {
    const prevData = immutableToJs(prev[field]);
    const nextData = immutableToJs(next[field]);
    const deepDiff = isObject(prevData) && isObject(nextData) ? testChangeProps(prevData, nextData) : null;
    return {
      ...res,
      [field]: {
        prev: prevData,
        current: nextData,
        _deepDiff: deepDiff,
      },
    };
  }, {});
};

export const withTestProp = (Component) => {
  let lastProps = {};
  let count = 1;
  return (props) => {
    const test = testChangeProps(lastProps, props);
    if (Object.keys(test).length > 0) {
      // eslint-disable-next-line no-console
      console.log(`Test Props #${count} ${Component.name} >>>>>`, test);
      count++;
    }
    lastProps = props;
    return <Component {...props} />;
  };
};

export const createTestChangeParams = () => {
  let prev = {};
  return (next) => {
    const res = testChangeProps(prev, next);
    prev = next;
    return res;
  };
};

export const testChangeParams = createTestChangeParams();

export const createChangeResolver = (initValue: any = {}) => {
  let lastValue = initValue;

  return (testValues: any) => {
    // DONT call when check with initialValue
    // on first call we have always different values
    // because initValue is {}, so form submit is fired
    if (Object.keys(lastValue).length === 0) {
      lastValue = testValues;
      return false;
    }

    const isChanges = !!Object.values(testChangeProps(lastValue, testValues)).length;
    const value = isChanges ? testValues : null;
    if (value) {
      lastValue = value;
    }
    return value;
  };
};

export const createMemoPropFn = (fn, propNames: string[]) => {
  let prevProps: any = {};
  let lastValue: any = null;

  return (currentProps: Props) =>
    (...args: any[]) => {
      if (isChangeProps(prevProps, currentProps, propNames)) {
        prevProps = currentProps;
        lastValue = fn(...args);
      }
      return lastValue;
    };
};

export const isChangeArgs = (prev: any[], next: any[]) =>
  prev.length !== next.length || !next.every((arg, index) => Object.is(prev[index], arg));

// isChangeProps
export const createMemoFn = <T: any>(prepareFn, handlerFn = x => x, debugName = null) => {
  let lastPrepareData: any = null;
  let lastValue: any;
  let debugCount: number = 0;
  return (...args): T => {
    const prepareData = prepareFn(...args);
    const isUpdate = lastPrepareData === null || isChangeAnyProps(prepareData, lastPrepareData);

    if (debugName && isUpdate) {
      debugCount++;
      // eslint-disable-next-line no-console
      console.log(
        `Debug #${debugCount} MemoFn ${debugName}`,
        testChangeProps(lastPrepareData || {}, prepareData || {}),
      );
    }

    if (isUpdate) {
      lastPrepareData = prepareData;
      lastValue = handlerFn(prepareData);
    }
    return lastValue;
  };
};

export const createArgsMemoFn = (fn, debugName = null) =>
  createMemoFn(
    (...args) => args,
    (args) => fn(...args),
    debugName,
  );
