// @flow
import React from 'react';

import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import Avatar from 'components/mui/Avatar';

type Props = {
  tag: string,
  users: { [userId: string]: { picture: string, username: string } },
};

const UserOptionLabel: React$StatelessFunctionalComponent<Props> = ({ tag, users, ...rest }) => {
  const isUser = !!users[tag];

  return (
    <Stack direction="row" alignItems="center" gap={1} {...rest}>
      <Box>
        <Avatar src={users[tag]?.picture} />
      </Box>
      <Box>
        <Typography variant="body2">{isUser ? `${users[tag].username} (${tag})` : tag}</Typography>
      </Box>
    </Stack>
  );
};

export default UserOptionLabel;
