/* @flow */
import React, { useCallback, useState, useMemo } from 'react';
import useDebounce from 'hooks/useDebounce';

import TextFieldBase from 'components/mui/Form/TextField/TextFieldBase';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import { type InputBaseProps } from '@mui/material/InputBase';

export type SearchTextProps = {
  searchText: string,
  onChangeSearchText: (t: string) => void,
  delay?: number,
  minNumber?: number,
  isClearOnBlur?: boolean,
  size?: string, // size for mui TextField
  InputProps?: InputBaseProps,
};

const SearchText = ({
  searchText,
  onChangeSearchText,
  delay = 500,
  minNumber = 1,
  isClearOnBlur = true,
  size = 'medium',
  InputProps = {},
}: SearchTextProps) => {
  const [localText, setLocalText] = useState(null);

  const currentText = useMemo(() => (localText === null ? searchText : localText), [localText, searchText]);

  const onChangeSearchTextDebounce = useDebounce(onChangeSearchText, delay);

  const onChange = useCallback(
    (e: SyntheticInputEvent) => {
      const { value } = e.currentTarget;
      setLocalText(value);
      onChangeSearchTextDebounce(value.length >= minNumber ? value : '');
    },
    [onChangeSearchTextDebounce, minNumber],
  );

  const onClear = useCallback(() => {
    setLocalText('');
    onChangeSearchTextDebounce('');
  }, [onChangeSearchTextDebounce]);

  const onBlur = useCallback(() => {
    if (isClearOnBlur) {
      onClear();
    }
  }, [onClear, isClearOnBlur]);

  return (
    <TextFieldBase
      onChange={onChange}
      onBlur={onBlur}
      value={currentText}
      size={size}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
        endAdornment: currentText && (
          <InputAdornment position="end">
            <IconButton type="button" onClick={onClear}>
              <CloseIcon />
            </IconButton>
          </InputAdornment>
        ),
        ...InputProps,
      }}
      fullWidth
    />
  );
};

export default SearchText;
