/* @flow */
import * as React from 'react';
import { getLabel, getOptionsLoader } from './hosts';
import { getThemeLoader } from './themes';
import { type TLabelOptions } from './type.js.flow';
import LC from './context';

export type Props = {|
  children: React$Node,
|};

type State = {|
  label: ?string,
  labelOptions: ?TLabelOptions,
|};

class Labels extends React.Component<Props, State> {
  state = {
    label: null,
    styles: null,
    labelOptions: null,
  };

  componentDidMount() {
    this.setLabel();
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    this.setLabel();

    if (prevState.label !== this.state.label) {
      this.loadLabelOptios();
    }

    if (!Object.is(prevState.labelOptions, this.state.labelOptions)) {
      this.updateFavicon();
      this.updateTitle();
    }
  }

  setLabel = () => {
    const { label } = this.state;
    const hostLabel = getLabel();
    if (label !== hostLabel) {
      this.setState({ label: hostLabel });
    }
  };

  updateFavicon = () => {
    const { labelOptions } = this.state;
    const links = document.getElementsByTagName('link');

    Array.from(links).forEach((link) => {
      const dataID = link.getAttribute('data-icon');
      const opton = labelOptions[dataID];
      if (opton) {
        link.setAttribute('href', opton);
      }
    });
  };

  updateTitle = () => {
    const { labelOptions } = this.state;
    const titles = document.getElementsByTagName('title');
    Array.from(titles).forEach((title) => {
      const text = labelOptions.title;
      if (text) {
        // eslint-disable-next-line
        title.textContent = text;
      }
    });
  };

  loadLabelOptios = () => {
    const { label } = this.state;
    if (label) {
      getOptionsLoader(label)().then((module) => {
        const labelOptions: LabelOptions = module.default;
        this.setState({ labelOptions });
        this.loadThemeStyle(labelOptions.theme);
      });
    }
  };

  loadThemeStyle = (theme) => {
    getThemeLoader(theme)().then((module) => {
      const styles = module.default;
      this.setState({ styles });
    });
  };

  render() {
    const { children } = this.props;
    const { label, labelOptions, styles } = this.state;
    return label && labelOptions && styles ? (
      <LC.Provider value={{ label, labelOptions: { ...labelOptions, styles } }}>{children}</LC.Provider>
    ) : null;
  }
}

export default Labels;
