import { Workbox } from 'workbox-window';

const SW_SRC_NAME = 'sw.js';
export function unregister() {
  if ('serviceWorker' in window.navigator) {
    window.navigator.serviceWorker.ready.then((registration) => {
      if (registration.active.scriptURL.indexOf(SW_SRC_NAME) !== -1) {
        registration.unregister().then(() => {
          window.location.reload();
        });
      }
    });
  }
}

export function registerSW() {
  if (process.env.NODE_ENV !== 'production') {
    return;
  }
  // Check if the serviceWorker Object exists in the navigator object ( means if browser supports SW )
  if ('serviceWorker' in navigator) {
    window.navigator.serviceWorker.ready.then((registration) => {
      if (registration.active.scriptURL.indexOf(SW_SRC_NAME) !== -1) {
        console.log('service worker is detected');
        const wb = new Workbox(`/${SW_SRC_NAME}`);
        wb.register();
      } else {
        console.log('service worker not detected');
      }
    });
  }
}
