// @flow
import React from 'react';
import { DragLayer } from 'react-dnd';
import { compose } from 'redux';
import { withStyles } from '@mui/styles';
import layerSheet from './layerSheet';
// compoennts
import IconWithCount from './IconWithCount';
// classnames
import cx from 'classnames';

type TDropTargets = { [key: string]: {| props: {| className: string |} |} };
type TMonitor = {|
  isDragging: () => boolean,
  getItemType: () => string,
  getItem: () => {|
    id: string,
    documentID: string[] | Set<string>,
    itemType: string,
  |} | null,
  getInitialClientOffset: () => { x: number, y: number },
  getInitialSourceClientOffset: () => { x: number, y: number },
  getClientOffset: () => { x: number, y: number },
  getDifferenceFromInitialOffset: () => { x: number, y: number },
  getSourceClientOffset: () => { x: number, y: number },
  getTargetIds: () => string[],
  registry: { dropTargets: TDropTargets },
  subscribeToOffsetChange: (listener: () => void) => void,
|};

export type Props = {
  classes: {
    [key: string]: string,
  },
  isDragging: boolean,
  targetsList: string[],
  dropTargets: TDropTargets,
  leftBarClassName: ?string,
  style: { [key: string]: string },
  isLinkedSidebar: boolean,
  previewSrc: string,
  linkid: string,
  selectedDocumentsSize: number,
  isLeftBarClassName: boolean,
};

type TCollectProps = $Diff<Props, {| isDragging: *, item: *, itemType: *, dropTarget: *, targetsList: * |}>;

let subscribedToOffsetChange = false;
let dragPreviewRef = null;
let isLeftBarClassName = false;

const isTargetsHasClass = (targets, targetsAllInfo, targetClass): boolean => {
  if (typeof targetClass !== 'string') return false;

  return targets.some((el) => targetsAllInfo[el].props.className === targetClass);
};

const onOffsetChange = (monitor) => () => {
  if (!dragPreviewRef) return;
  let offset = monitor.getSourceClientOffset();

  if (isLeftBarClassName) {
    offset = monitor.getClientOffset();
  }
  if (!offset) return;

  const transform = `translate(${offset.x}px, ${offset.y}px)`;
  dragPreviewRef.style.opacity = '1';
  dragPreviewRef.style.transform = transform;
};

class DnDLayer extends React.Component<Props, {}> {
  componentDidUpdate() {
    dragPreviewRef = this.rootNode;
  }

  rootNode: ?HTMLDivElement;

  render = () => {
    const {
      isDragging,
      classes,
      isLinkedSidebar,
      previewSrc,
      style,
      linkid,
      selectedDocumentsSize: docCount,
      isLeftBarClassName: isIcon,
    } = this.props;
    if (!isDragging) {
      return null;
    }
    const dragDocName = docCount > 1 ? 'files' : 'file';
    const dragTypeName = linkid && !isLinkedSidebar ? 'link' : dragDocName;

    return (
      <div className={classes.overlay}>
        <div className={cx({ [classes.wrapper]: isIcon })}>
          <div
            ref={(el) => {
              this.rootNode = el;
            }}
            className={classes.layerBox}
          >
            {isIcon ? (
              <IconWithCount iconType={dragTypeName} count={docCount} />
            ) : (
              <img src={previewSrc} className={classes.image} style={style} alt="preview" />
            )}
          </div>
        </div>
      </div>
    );
  };
}

const collect = (monitor: TMonitor, props: TCollectProps) => {
  const targetsList = monitor.getTargetIds();
  const { dropTargets } = monitor.registry;
  const { leftBarClassName } = props;

  const handleOffsetChange = onOffsetChange(monitor);
  if (!subscribedToOffsetChange && leftBarClassName) {
    monitor.subscribeToOffsetChange(handleOffsetChange);

    subscribedToOffsetChange = true;
  }

  const isLeftBarClassTarget = isTargetsHasClass(targetsList, dropTargets, leftBarClassName);

  if (isLeftBarClassTarget && !isLeftBarClassName) {
    isLeftBarClassName = true;
  } else if (!isLeftBarClassTarget && isLeftBarClassName) {
    isLeftBarClassName = false;
  }

  return {
    item: monitor.getItem(),
    itemType: monitor.getItemType(),
    isDragging: monitor.isDragging(),
    dropTargets,
    isLeftBarClassName,
  };
};

export const CustomDnDLayer = compose(DragLayer(collect), withStyles(layerSheet))(DnDLayer);
