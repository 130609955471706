import { useApiToken } from 'lib/apiTokenKeeper';
import { useParams } from 'react-router-dom';
import Api from 'domain/api';

type TUseCardThumbnailPath = {
  documentID: string,
};

const useCardThumbnailPath = ({ documentID }: TUseCardThumbnailPath) => {
  const dokkaToken = useApiToken();
  const { companyId } = useParams();
  const params = {
    companyId,
    documentID,
    dokkaToken,
  };

  return dokkaToken ? Api.getDocumentThumbnailUrl(params) : null;
};

export default useCardThumbnailPath;
