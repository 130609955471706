// @flow
import React from 'react';
import { useIntl } from 'react-intl';

import Button from '@mui/material/Button';

type TDocumentViewButton = {|
  onClick: () => void,
  isShowJEForApprover: boolean,
|};

const DocumentViewButton: React$StatelessFunctionalComponent<TDocumentViewButton> = ({
  onClick,
  isShowJEForApprover,
}) => {
  const { formatMessage } = useIntl();
  const buttonProps = {
    variant: isShowJEForApprover ? 'contained' : 'outlined',
  };
  const buttonTitle = {
    id: isShowJEForApprover ? 'document.show.btns.backToApprovalView' : 'document.show.btns.skipToPublish',
    defaultMessage: isShowJEForApprover ? 'Back to approval view' : 'Financial view',
  };
  return (
    <Button {...buttonProps} onClick={onClick}>
      {formatMessage(buttonTitle)}
    </Button>
  );
};

export default DocumentViewButton;
