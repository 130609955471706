import React from 'react';

import { FormattedMessage } from 'react-intl';

const FeatureDisabledMessage = () => (
  <FormattedMessage
    id="configurations.supplier_defaults.feature_disabled"
    defaultMessage="Feature disabled for your organization"
    tagName="h2"
  />
);

export default FeatureDisabledMessage;
