// @flow
import React, { useCallback, memo } from 'react';
import useDidUpdateEffect from 'hooks/useDidUpdateEffect';

import AutocompleteVirtualized from 'components/mui/Form/Autocomplete/AutocompleteVirtualized';
import { TextField } from 'pages/company/Grid/components/DropdownCellEditor/StyledComponents';

import type { TOptions, TOption } from 'components/mui/Form/Autocomplete/AutocompleteBase';

type TProps = {
  stopEditing: () => void,
  values: TOptions,
  column: any,
  onValueChange: (TOption | {}) => void,
  value: TOption | {},
};

const MultipleDropdownCellEditor: React$StatelessFunctionalComponent<TProps> = ({
  stopEditing,
  values,
  column: { actualWidth },
  onValueChange,
  value,
}) => {
  const handleChange = useCallback(
    (e, option) => {
      onValueChange(option || {});
    },
    [onValueChange],
  );

  // stop edit after select option
  useDidUpdateEffect(() => {
    stopEditing();
  }, [value, stopEditing]);

  return (
    <AutocompleteVirtualized
      open
      value={Object.keys(value).length ? value : null}
      options={values}
      onChange={handleChange}
      renderInput={(params) => (
        <TextField {...params} inputProps={{ ...params.inputProps }} variant="standard" autoFocus />
      )}
      ListboxProps={{ optionStyle: { unicodeBidi: 'normal' }, suppressToolTip: false }}
      sx={{ width: actualWidth }}
      fullWidth
      autoComplete
      disablePortal
    />
  );
};

export default memo(MultipleDropdownCellEditor);
