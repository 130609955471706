// @flow
import React, { useRef, useCallback, useMemo, useState, useLayoutEffect } from 'react';
import { rtlEnable } from 'domain/env/envSelector';
import { useSelector } from 'react-redux';
import useTranslate from 'hooks/useTranslate';
import { type TDataForTranslateTooltip } from './helpers';
import { rectBounding } from '../helpers';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import { TranslatePopup, TranslateColumn } from 'pages/document/polygons/TranslatePopup/StyledComponents';

const MAX_CONTAONER_WIDTH = 400;
const HORIZONTAL_OFFSET = 10;
const VERTICAL_OFFSET = 10;
const MAX_MENU_HEIGHT = 300;
const BOUNDING_CONTAINER_WIDTH = 2 * HORIZONTAL_OFFSET + MAX_CONTAONER_WIDTH;
const SCALE_BOUNDING_CONTAINER_WIDTH = BOUNDING_CONTAINER_WIDTH + 50;

type Props = {|
  onClose(): void,
  dataForTranslateTooltip: TDataForTranslateTooltip,
|};

type TSize = string | number;

const mapStateToProps = (state) => ({
  isRtl: rtlEnable(state),
});

const PageTranslatePopup = ({ dataForTranslateTooltip, onClose }: Props) => {
  const tooltipRef = useRef();
  const [tooltipBounding, setTooltipBounding] = useState(rectBounding);

  const { isRtl } = useSelector(mapStateToProps);

  const {
    availableTranslationList,
    targetTranslateOption,
    sourceTranslateOption,
    onChangeTargetTranslateOption,
    onChangeSourceTranslateOption,
    targetTranslate,
    isTranslating,
  } = useTranslate(dataForTranslateTooltip.text);

  const styles = useMemo(() => {
    const { shapeBounding, containerBounding, visibleContainerBounding } = dataForTranslateTooltip;
    const { right: shapeR, left: shapeL, bottom: shapeB, top: shapeT, height: shapeH } = shapeBounding;
    const { top: docT, bottom: docB, left: docL, right: docR } = containerBounding;
    const { top: vT, bottom: vB, left: vL, right: vR, height: vH, width: vW } = visibleContainerBounding;
    const { width: tooltipW, height: tooltipH } = tooltipBounding;

    const shapeOffset = {
      left: Math.abs(vL - shapeL),
      right: Math.abs(vR - shapeR),
      top: Math.abs(vT - shapeT),
      bottom: Math.abs(vB - shapeB),
    };

    const docOffset = {
      left: Math.abs(vL - docL),
      right: Math.abs(vR - docR),
      top: Math.abs(vT - docT),
      bottom: Math.abs(vB - docB),
    };

    const startPosition = isRtl ? 'right' : 'left';
    const endPosition = isRtl ? 'left' : 'right';

    const tooltipPosition: {|
      top: TSize,
      bottom: TSize,
      left: TSize,
      right: TSize,
    |} = {
      top: shapeOffset.top + shapeH,
      bottom: 'auto',
      [startPosition]: shapeOffset[startPosition] + docOffset[startPosition],
      [endPosition]: 'auto',
    };

    if (+tooltipPosition[startPosition] + tooltipW + HORIZONTAL_OFFSET > vW) {
      if (+tooltipPosition[startPosition] - docOffset[startPosition] < vW / 2) {
        tooltipPosition[startPosition] = docOffset[startPosition] + HORIZONTAL_OFFSET;
      } else {
        tooltipPosition[startPosition] = 'auto';
        tooltipPosition[endPosition] = docOffset[endPosition] + HORIZONTAL_OFFSET;
      }
    }
    if (+tooltipPosition.top + tooltipH + VERTICAL_OFFSET + docOffset.bottom > vH) {
      const bottom = shapeOffset.bottom + shapeH;
      if (bottom + tooltipH > vH) {
        tooltipPosition.top = docOffset.top + VERTICAL_OFFSET;
      } else {
        tooltipPosition.top = 'auto';
        tooltipPosition.bottom = bottom - VERTICAL_OFFSET;
      }
    }

    const transformOrigin = `${tooltipPosition.top === 'auto' ? 'bottom' : 'top'} ${
      tooltipPosition.left === 'auto' ? 'right' : 'left'
    }`;

    return { tooltipPosition, transformOrigin };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataForTranslateTooltip, tooltipBounding, targetTranslate, isRtl]);

  const getWrapperSizes = useCallback(() => {
    const { width: containerWidth } = dataForTranslateTooltip.visibleContainerBounding;

    const maxWidth = `max(${MAX_CONTAONER_WIDTH}px, calc(${containerWidth}px * 0.5))`;
    const maxHeight = tooltipBounding.height || 'auto';
    const transform = `scale(${
      containerWidth < BOUNDING_CONTAINER_WIDTH ? 1 * (containerWidth / SCALE_BOUNDING_CONTAINER_WIDTH) : 1
    })`;

    return {
      maxWidth,
      maxHeight,
      height: maxHeight,
      transform,
    };
  }, [tooltipBounding, dataForTranslateTooltip]);

  const getTranslationSectionSizes = useCallback(() => {
    const { height: containerHeight } = dataForTranslateTooltip.containerBounding;
    const maxHeight = containerHeight / 2;

    return { maxHeight };
  }, [dataForTranslateTooltip]);

  const onMouseDown = useCallback((e) => {
    e.stopPropagation();
  }, []);

  const getValue = useCallback(
    (value: string) => availableTranslationList.find((i) => i.value === value) || null,
    [availableTranslationList],
  );

  // useOnClickOutside(tooltipRef, onClose);

  useLayoutEffect(() => {
    if (tooltipRef.current) {
      setTooltipBounding(tooltipRef.current.getBoundingClientRect());
    }
  }, []);

  return (
    <ClickAwayListener onClickAway={onClose}>
      <TranslatePopup
        ref={tooltipRef}
        role="tooltip"
        onMouseDown={onMouseDown}
        style={{
          ...getWrapperSizes(),
          ...styles.tooltipPosition,
          transformOrigin: styles.transformOrigin,
        }}
      >
        <Stack direction="row" spacing={1} overflow="hidden" style={{ ...getTranslationSectionSizes() }}>
          <Box flex="1 1 50%" pb={1}>
            <TranslateColumn>
              <Typography variant="body2" color="grey.700">
                {dataForTranslateTooltip.text}
              </Typography>
            </TranslateColumn>
          </Box>
          <Divider orientation="vertical" flexItem />
          <Box flex="1 1 50%" pb={1}>
            <TranslateColumn>
              <Typography variant="body2">{targetTranslate}</Typography>
              {isTranslating && (
                <Box display="flex" justifyContent="center" alignItems="center" height="100%" m="auto">
                  <CircularProgress />
                </Box>
              )}
            </TranslateColumn>
          </Box>
        </Stack>
        <Stack direction="row" spacing={1}>
          <Autocomplete
            disableClearable
            fullWidth
            size="small"
            id="sourceTranslateOption"
            options={availableTranslationList}
            onChange={onChangeSourceTranslateOption}
            value={getValue(sourceTranslateOption)}
            renderInput={(params) => <TextField {...params} />}
            ListboxProps={{ style: { maxHeight: MAX_MENU_HEIGHT } }}
          />
          <Autocomplete
            disableClearable
            fullWidth
            size="small"
            id="targetTranslateOption"
            options={availableTranslationList}
            onChange={onChangeTargetTranslateOption}
            value={getValue(targetTranslateOption)}
            renderInput={(params) => <TextField {...params} />}
            ListboxProps={{ style: { maxHeight: MAX_MENU_HEIGHT } }}
          />
        </Stack>
      </TranslatePopup>
    </ClickAwayListener>
  );
};

export default PageTranslatePopup;
