// @flow
import React, { useCallback } from 'react';

import IconButton from '@mui/material/IconButton';
import AttachFileIcon from '@mui/icons-material/AttachFile';

type TProps = any;

const LinkedCellRenderer = ({ data, context, colDef }: TProps) => {
  const {
    rowDocumentData: { linkid },
  } = data;

  const {
    components: {
      [colDef.cellRenderer]: { onClickLinked },
    },
  } = context;

  const handleClick = useCallback(() => {
    onClickLinked(linkid);
  }, [linkid, onClickLinked]);

  if (!linkid) return null;

  return (
    <IconButton onClick={handleClick} sx={{ fontSize: '22px' }}>
      <AttachFileIcon fontSize="inherit" />
    </IconButton>
  );
};
export default LinkedCellRenderer;
