// @flow
import React, { useCallback, memo } from 'react';
import { NumericFormat } from 'react-number-format';

import Box from '@mui/material/Box';
import { TextField } from 'pages/company/Grid/components/InputNumberCellEditor/StyledComponents';

type TProps = {
  onValueChange: (value: string) => void,
  value?: number,
};

const InputNumberCellEditor: React$StatelessFunctionalComponent<TProps> = ({ value, onValueChange }) => {
  const handleChange = useCallback(
    ({ value: inputValue }) => {
      onValueChange(inputValue ? +inputValue : undefined);
    },
    [onValueChange],
  );

  return (
    <Box display="flex" alignItems="center" height="100%" mx={0.5}>
      <NumericFormat
        customInput={TextField}
        decimalScale={2}
        value={value}
        onValueChange={handleChange}
        variant="standard"
        dir="ltr"
        fullWidth
        autoFocus
      />
    </Box>
  );
};

export default memo(InputNumberCellEditor);
