import React, { useCallback, useMemo } from 'react';
import { companyFeatureSetSelector, ERP_NAMES, ERP_TITLES } from 'domain/companies';
import Api from 'domain/api';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { ERPFactory } from 'domain/settings/adapters';
import { activateErpAction, erpsSelector, getSSOParams } from 'domain/settings';
import { queryToSearch } from 'domain/router/helpers';

import { Button } from 'pages/configurations/company/pages/integrations/styled';
import AddMoreCompaniesSection from 'pages/configurations/company/pages/integrations/components/ERPConnectForm/AddMoreCompaniesSection';

import qboIcon from 'pages/configurations/company/pages/integrations/icons/qboIcon.png';
import xeroIcon from 'pages/configurations/company/pages/integrations/icons/xeroIcon.png';
import zohoIcon from 'pages/configurations/company/pages/integrations/icons/zohoIcon.svg';
import netsuiteIcon from 'pages/configurations/company/pages/integrations/icons/netsuiteIcon.png';
import sapIcon from 'pages/configurations/company/pages/integrations/icons/sapIcon.png';
import sageSaIcon from 'pages/configurations/company/pages/integrations/icons/sageIcon.svg';
import dokkaIcon from 'pages/configurations/company/pages/integrations/icons/dokkaIcon.png';
import priorityIcon from 'pages/configurations/company/pages/integrations/icons/priorityIcon.svg';
import qbd from 'pages/configurations/company/pages/integrations/icons/qbd.png';
import hashavshevetIcon from 'pages/configurations/company/pages/integrations/icons/hashavshevetIcon.svg';
import MDNavisionIcon from 'pages/configurations/company/pages/integrations/icons/MD-Navision.svg';
import MDBCIcon from 'pages/configurations/company/pages/integrations/icons/MD-BC.svg';
import AcumaticaIcon from 'pages/configurations/company/pages/integrations/icons/acumatica.png';

import cx from 'classnames';
import useStyles from 'pages/configurations/company/pages/integrations/hooks/erpConfigs/styled';

const mapStateToProps = (state) => ({
  sslVerification: companyFeatureSetSelector(state).get('erp_ssl_verification'),
  erps: erpsSelector(state),
});

const useErpConfigs = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { erps, sslVerification } = useSelector(mapStateToProps);

  const defaultErpStatus = erps.getIn([ERP_NAMES.defaultErp, 'status']);

  const onConnect = useCallback(
    (erpType, configs) => {
      new Promise((resolve, reject) => dispatch(getSSOParams({ erpType, configs, resolve, reject })))
        .then((response) => {
          const paramsExist = Boolean(Object.keys(response.params).length);
          window.location.href = `${response.endpoint}${paramsExist ? `?${queryToSearch(response.params)}` : ''}`;
        })
        .catch(() => null);
    },
    [dispatch],
  );

  const getFormData = useCallback((object) => {
    const formData = new FormData();
    Object.keys(object).forEach((key) => formData.append(key, object[key]));
    return formData;
  }, []);

  const getIgnoreSSlFieldSet = useCallback(
    () =>
      sslVerification
        ? [
            {
              name: 'ssl',
              type: 'checkbox',
              label: {
                id: 'erpConnect.verifySSL',
                defaultMessage: 'Verify SSL Certificate',
              },
            },
          ]
        : [],
    [sslVerification],
  );

  const erpsOptions = useMemo(
    () => [
      {
        section: ERP_NAMES.acumatica,
        title: ERP_TITLES[ERP_NAMES.acumatica],
        erpName: 'Acumatica',
        icon: AcumaticaIcon,
        erpConnect: {
          fields: [
            {
              name: 'api_url',
              type: 'text',
              label: {
                id: 'acumatica.apiUrl',
                defaultMessage: 'API Url',
              },
            },
            {
              name: 'name',
              type: 'text',
              label: {
                id: 'acumatica.name',
                defaultMessage: 'Username',
              },
            },
            {
              name: 'password',
              type: 'password',
              label: {
                id: 'acumatica.password',
                defaultMessage: 'Password',
              },
            },
            {
              name: 'tenant',
              type: 'text',
              label: {
                id: 'acumatica.tenant',
                defaultMessage: 'Company ID',
              },
            },
          ],
          api: Api.signInAcumatica,
          apiPayloadSerializer: (formData) => ({ data: { ...formData.toJS() } }),
          isSingleStep: true,
        },
        settings: erps.get(ERP_NAMES.acumatica, ERPFactory()),
      },
      {
        section: ERP_NAMES.mdNavision,
        title: ERP_TITLES[ERP_NAMES.mdNavision],
        erpName: 'MD Navision',
        icon: MDNavisionIcon,
        erpConnect: {
          fields: [
            {
              name: 'username',
              type: 'text',
              label: {
                id: 'sageConnect.username',
                defaultMessage: 'Username',
              },
            },
            {
              name: 'password',
              type: 'password',
              label: {
                id: 'sageConnect.password',
                defaultMessage: 'Password',
              },
            },
            {
              name: 'apiUrl',
              type: 'text',
              label: {
                id: 'priorityConnect.apiUrl',
                defaultMessage: 'API Url',
              },
            },
          ],
          api: Api.signInMDNavision,
          apiPayloadSerializer: (formData) => ({ data: { ...formData.toJS() } }),
          onSuccessToastMessageProps: {
            id: 'priorityConnect.allWillConnect',
            defaultMessage: 'Selected companies will be connected to {label} Please, refresh company list',
          },
        },
        settings: erps.get(ERP_NAMES.mdNavision, ERPFactory()),
      },
      {
        section: ERP_NAMES.mdBC,
        title: ERP_TITLES[ERP_NAMES.mdBC],
        erpName: 'MD Business Central',
        icon: MDBCIcon,
        erpConnect: {
          fields: [
            {
              name: 'client_id',
              type: 'text',
              label: {
                id: 'mdBC.clientId',
                defaultMessage: 'Client ID',
              },
            },
            {
              name: 'client_secret',
              type: 'password',
              label: {
                id: 'mdBC.clientSecret',
                defaultMessage: 'Client Secret',
              },
            },
            {
              name: 'tenant_id',
              type: 'text',
              label: {
                id: 'mdBC.tenantId',
                defaultMessage: 'Tenant ID',
              },
            },
          ],
          api: Api.signInMDBC,
          apiPayloadSerializer: (formData) => ({ data: { ...formData.toJS() } }),
          onSuccessToastMessageProps: {
            id: 'priorityConnect.allWillConnect',
            defaultMessage: 'Selected companies will be connected to {label} Please, refresh company list',
          },
        },
        settings: erps.get(ERP_NAMES.mdBC, ERPFactory()),
      },
      {
        section: ERP_NAMES.qbo,
        title: ERP_TITLES[ERP_NAMES.qbo],
        erpName: 'QuickBooks Online',
        icon: qboIcon,
        button: (
          <Button className={cx(classes.connectBtn, classes.qboButton)} onClick={() => onConnect(ERP_NAMES.qbo)} />
        ),
        settings: erps.get(ERP_NAMES.qbo, ERPFactory()),
      },
      {
        section: ERP_NAMES.qbd,
        title: ERP_TITLES[ERP_NAMES.qbd],
        erpName: 'QuickBooks Desktop',
        icon: qbd,
        settings: erps.get(ERP_NAMES.qbd, ERPFactory()),
      },
      {
        section: ERP_NAMES.xero,
        title: ERP_TITLES[ERP_NAMES.xero],
        erpName: 'XERO',
        icon: xeroIcon,
        button: (
          <Button
            className={cx(classes.connectBtn, classes.xeroButton)}
            type="button"
            onClick={() => onConnect(ERP_NAMES.xero)}
          />
        ),
        settings: erps.get(ERP_NAMES.xero, ERPFactory()),
      },
      {
        section: ERP_NAMES.zoho,
        title: ERP_TITLES[ERP_NAMES.zoho],
        erpName: 'Zoho Books',
        icon: zohoIcon,
        button: (
          <Button
            className={cx(classes.connectBtn, classes.zohoButton)}
            type="button"
            onClick={() => onConnect(ERP_NAMES.zoho)}
          />
        ),
        settings: erps.get(ERP_NAMES.zoho, ERPFactory()),
      },
      {
        section: ERP_NAMES.sageSA,
        title: ERP_TITLES[ERP_NAMES.sageSA],
        erpName: 'Sage Business Cloud (South Africa)',
        icon: sageSaIcon,
        erpConnect: {
          fields: [
            {
              name: 'username',
              type: 'text',
              label: {
                id: 'sageConnect.username',
                defaultMessage: 'Username',
              },
            },
            {
              name: 'password',
              type: 'password',
              label: {
                id: 'sageConnect.password',
                defaultMessage: 'Password',
              },
            },
          ],
          api: Api.signInSage,
          apiPayloadSerializer: (formData, dokkaToken) => ({ data: { dokkaToken, ...formData.toJS() } }),
        },
        settings: erps.get(ERP_NAMES.sageSA, ERPFactory()),
      },
      {
        section: ERP_NAMES.sage,
        title: ERP_TITLES[ERP_NAMES.sage],
        erpName: 'Sage Business Cloud (Global)',
        icon: sageSaIcon,
        button: (
          <Button
            className={cx(classes.connectBtn, classes.sageGlobalButton)}
            onClick={() => onConnect(ERP_NAMES.sage)}
          />
        ),
        settings: erps.get(ERP_NAMES.sage, ERPFactory()),
      },
      {
        section: ERP_NAMES.priorityPro,
        title: ERP_TITLES[ERP_NAMES.priorityPro],
        erpName: 'Priority',
        icon: priorityIcon,
        erpConnect: {
          fields: [
            {
              name: 'username',
              type: 'text',
              label: {
                id: 'sageConnect.username',
                defaultMessage: 'Username',
              },
            },
            {
              name: 'password',
              type: 'password',
              label: {
                id: 'sageConnect.password',
                defaultMessage: 'Password',
              },
            },
            {
              name: 'apiUrl',
              type: 'text',
              label: {
                id: 'priorityConnect.apiUrl',
                defaultMessage: 'API Url',
              },
            },
            ...getIgnoreSSlFieldSet(),
          ],
          api: Api.signInPriority,
          apiPayloadSerializer: (formData, dokkaToken) => ({ data: { dokkaToken, ...formData.toJS() } }),
          onSuccessToastMessageProps: {
            id: 'priorityConnect.allWillConnect',
            defaultMessage: 'Selected companies will be connected to {label} Please, refresh company list',
          },
        },
        settings: erps.get(ERP_NAMES.priorityPro, ERPFactory()),
      },
      {
        section: ERP_NAMES.priorityProSQL,
        title: ERP_TITLES[ERP_NAMES.priorityProSQL],
        icon: priorityIcon,
        settings: erps.get(ERP_NAMES.priorityProSQL, ERPFactory()),
      },
      {
        section: ERP_NAMES.fox,
        title: ERP_TITLES[ERP_NAMES.fox],
        erpName: 'Custom ERP',
        icon: dokkaIcon,
        button: <div />,
        settings: erps.get(ERP_NAMES.fox, ERPFactory()),
      },
      {
        section: ERP_NAMES.hash,
        title: ERP_TITLES[ERP_NAMES.hash],
        icon: hashavshevetIcon,
        erpName: 'H-ERP',
        erpConnect: {
          api: Api.connectHashDevice,
          apiPayloadSerializer: (formData, dokkaToken) => ({ data: { dokkaToken, ...formData } }),
          onSuccessToastMessageProps: {
            id: 'priorityConnect.allWillConnect',
            defaultMessage: 'Selected companies will be connected to {label} Please, refresh company list',
          },
        },
        settings: erps.get(ERP_NAMES.hash, ERPFactory()),
      },
      // TODO: should be changed after back-end is ready DAW-1575
      {
        section: ERP_NAMES.netsuite,
        title: ERP_TITLES[ERP_NAMES.netsuite],
        icon: netsuiteIcon,
        erpName: 'NETSUITE',
        erpConnect: {
          fields: [
            {
              name: 'accountId',
              type: 'text',
              label: {
                id: 'netsuiteConnect.accountId',
                defaultMessage: 'Account Id',
              },
            },
            {
              name: 'tokenKey',
              type: 'text',
              label: {
                id: 'netsuiteConnect.tokenKey',
                defaultMessage: 'Token Key',
              },
            },
            {
              name: 'tokenSecret',
              type: 'password',
              label: {
                id: 'netsuiteConnect.accountSecret',
                defaultMessage: 'Token  Secret',
              },
            },
            {
              name: 'restUrl',
              type: 'text',
              label: {
                id: 'netsuiteConnect.restUrl',
                defaultMessage: 'Rest connector URL',
              },
            },
          ],
          api: Api.signInNetSuite,
          apiPayloadSerializer: (formData, dokkaToken) => ({
            data: {
              dokkaToken,
              ...formData.toJS(),
            },
          }),
          onSuccessToastMessageProps: { id: 'netsuiteConnect.toast', defaultMessage: 'NetSuite credentials sent' },
          isSingleStep: true,
        },
        settings: erps.get(ERP_NAMES.netsuite, ERPFactory()),
      },
      {
        section: ERP_NAMES.sap,
        title: ERP_TITLES[ERP_NAMES.sap],
        icon: sapIcon,
        erpName: 'SAP Business One',
        erpConnect: {
          section: {
            element: (fields) => <AddMoreCompaniesSection fields={fields} />,
            fields: [
              {
                name: 'companies_excel',
                label: {
                  id: 'sapConnect.uploadCompaniesExcel',
                  defaultMessage: 'Upload companies excel',
                },
              },
            ],
            onSuccessToastMessageProps: {
              id: 'sapConnect.section.toast.success',
              defaultMEssages: 'Selected companies will be connected to DOKKA. Please, refresh company list',
            },
          },
          fields: [
            {
              name: 'username',
              type: 'text',
              label: {
                id: 'sapConnect.username',
                defaultMessage: 'SAP username',
              },
            },
            {
              name: 'password',
              type: 'password',
              label: {
                id: 'sapConnect.password',
                defaultMessage: 'SAP password',
              },
            },
            {
              name: 'CompanyDB',
              type: 'text',
              label: {
                id: 'sapConnect.companyDB',
                defaultMessage: 'Company DB name',
              },
            },
            {
              name: 'api_url',
              type: 'text',
              label: {
                id: 'sapConnect.apiUrl',
                defaultMessage: 'SAP server url',
              },
            },
            ...getIgnoreSSlFieldSet(),
          ],
          api: Api.signInSAP,
          apiPayloadSerializer: (formData, dokkaToken) => ({
            data: getFormData({ ...formData.toJS(), dokkaToken }),
          }),
          onSuccessToastMessageProps: { id: 'sapConnect.toast.success', defaultMessage: 'SAP credentials sent' },
          isSingleStep: true,
        },
        settings: erps.get(ERP_NAMES.sap, ERPFactory()),
      },
      {
        section: ERP_NAMES.defaultErp,
        title: 'Export to Excel/CSV only',
        icon: dokkaIcon,
        erpName: 'Default ERP',
        button: defaultErpStatus === 'inactive' && (
          <Button onClick={() => dispatch(activateErpAction(ERP_NAMES.defaultErp))}>
            <FormattedMessage id="settings.connections.btn.activate" defaultMessage="Activate" />
          </Button>
        ),
        settings: erps.get(ERP_NAMES.defaultErp, ERPFactory()),
      },
    ],
    [
      classes.connectBtn,
      classes.qboButton,
      classes.sageGlobalButton,
      classes.xeroButton,
      classes.zohoButton,
      defaultErpStatus,
      dispatch,
      erps,
      getFormData,
      getIgnoreSSlFieldSet,
      onConnect,
    ],
  );

  return {
    erpsOptions,
  };
};

export default useErpConfigs;
