// @flow
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { updatePasswordAction, loadingSelector, resetPasswordErrorSelector, errorSelector } from 'domain/env';
import usePasswordValidation from 'components/PasswordValidationPrompt/usePasswordValidation';

import Spinner from 'components/Spinner';
import AuthLayout from 'pages/authPage/Layout';
import AuthPaperBox from 'pages/authPage/Layout/PaperBox';
import BuildVersion from 'pages/authPage/Layout/BuildVersion';
import TextFieldBase from 'components/mui/Form/TextField/TextFieldBase';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import PasswordValidationPrompt from 'components/PasswordValidationPrompt';

type TResetPasswordPage = {};
const mapStateToProps = (state) => ({
  loading: loadingSelector(state),
  invalidResetToken: resetPasswordErrorSelector(state),
  formError: errorSelector(state),
});

const ResetPasswordPage: React$StatelessFunctionalComponent<TResetPasswordPage> = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const { loading, invalidResetToken, formError } = useSelector(mapStateToProps);
  const { passwordValues, isValid, handleChange, validationRules } = usePasswordValidation();
  const { recoveryToken, passwordExpired } = useParams();
  const formType = passwordExpired ? 'expired' : 'default';

  const t = {
    default: {
      title: {
        id: 'reset.title',
        defaultMessage: 'Change Password',
      },
      subtitle: {
        id: 'reset.subtitle',
        defaultMessage: 'Enter a new password',
      },
    },
    expired: {
      title: {
        id: 'updatePassword.title',
        defaultMessage: 'Password has expired',
      },
      subtitle: {
        id: 'updatePassword.subtitle',
        defaultMessage: 'Set new password to continue use application',
      },
    },
  };

  const onSubmit = (event) => {
    event.preventDefault();
    dispatch(
      updatePasswordAction({
        token: recoveryToken,
        password: passwordValues.password,
      }),
    );
  };

  return (
    <AuthLayout>
      {invalidResetToken === false && (
        <>
          <AuthPaperBox>
            <Typography variant="h5">{intl.formatMessage(t[formType].title)}</Typography>
            <Typography variant="subtitle2" color="text.secondary" fontWeight="400" mb={3}>
              {intl.formatMessage(t[formType].subtitle)}
            </Typography>

            <form onSubmit={onSubmit}>
              {formError && (
                <Typography variant="body2" color="error">
                  {formError}
                </Typography>
              )}
              <TextFieldBase
                id="password"
                type="password"
                name="password"
                value={passwordValues.password}
                label={intl.formatMessage({
                  id: 'reset.password.placeholder',
                  defaultMessage: 'New Password',
                })}
                onChange={handleChange}
                fullWidth
                required
                autoFocus
                margin="dense"
              />
              <PasswordValidationPrompt validation={validationRules} />
              <TextFieldBase
                id="passwordConfirmation"
                type="password"
                name="passwordConfirmation"
                value={passwordValues.passwordConfirmation}
                label={intl.formatMessage({
                  id: 'reset.passwordConfirmation.placeholder',
                  defaultMessage: 'Confirm New Password',
                })}
                onChange={handleChange}
                fullWidth
                required
                margin="dense"
              />
              <Button type="submit" variant="contained" disabled={!isValid} fullWidth size="large" sx={{ mt: 2 }}>
                <FormattedMessage id="reset.submit" defaultMessage="Save Password" />
              </Button>
            </form>
          </AuthPaperBox>
          <BuildVersion />
        </>
      )}
      <Spinner loading={loading} />
    </AuthLayout>
  );
};

export default ResetPasswordPage;
