// @flow
import React, { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import countriesList from 'lib/countries';

import Grid from '@mui/material/Grid';
import TextFieldBase, { type TTextFieldBaseMui } from 'components/mui/Form/TextField/TextFieldBase';
import TextField from '@mui/material/TextField';
import Autocomplete, { type AutocompleteProps } from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';

export type TOption = {
  value: string | number,
  label: string,
};

const dictionary = countriesList.reduce(
  (a, v) => ({
    byName: { ...a.byName, [v.name]: v.callingCode },
    byCode: { ...a.byCode, [v.callingCode]: v.name },
  }),
  {
    byName: {},
    byCode: {},
  },
);

function sorter(a: string, b: string) {
  return a.localeCompare(b);
}

const options = [...new Set(countriesList.map((e) => e.name))]
  .sort(sorter)
  .map((e) => ({ label: e, value: dictionary.byName[e] }));

export type TPhoneBase = {
  onChange: (value: string) => void,
  value: string,
  codeLabel?: React$Node | string,
  numberLabel?: React$Node | string,
  CodeProps?: AutocompleteProps,
  CodeInputProps?: TTextFieldBaseMui,
  NumberProps?: TTextFieldBaseMui,
  withoutBrackets?: boolean,
};

const PhoneBase: React$StatelessFunctionalComponent<TPhoneBase> = ({
  onChange,
  value,
  codeLabel,
  numberLabel,
  CodeProps = {},
  CodeInputProps = {},
  NumberProps = {},
  withoutBrackets,
}) => {
  const intl = useIntl();

  const numberValue = useMemo(() => value.split('-')[1] || '', [value]);

  const codeValue = useMemo(() => value.split('-')[0], [value]);

  const handleCodeChange = useCallback(
    (e, option) => {
      if (typeof onChange === 'function') {
        onChange(`${option.value}-${numberValue}`);
      }
    },
    [numberValue, onChange],
  );

  const handleNumberChange = useCallback(
    (targetValue: number) => {
      if (typeof onChange === 'function') {
        onChange(`${codeValue}-${targetValue}`);
      }
    },
    [codeValue, onChange],
  );

  const getCodeLabel = useCallback(
    (o: TOption) => {
      if (withoutBrackets) {
        return o.label.length ? `${o.label} ${o.value}` : `${o.value}`;
      }

      return o.label.length ? `${o.label} (${o.value})` : `(${o.value})`;
    },
    [withoutBrackets],
  );

  const renderOptionLabel = useCallback(
    (props, o: TOption) => {
      const codeWithLabel = getCodeLabel(o);
      return (
        <Box component="li" {...props} key={codeWithLabel}>
          {codeWithLabel}
        </Box>
      );
    },
    [getCodeLabel],
  );

  const filterCodes = useCallback(
    (options, { inputValue }) =>
      options.filter((o) => {
        const codeWithLabel = getCodeLabel(o).toUpperCase();
        return codeWithLabel.includes(inputValue.toUpperCase());
      }),
    [getCodeLabel],
  );

  const getCurrentCodeOption = useMemo(() => options.filter(({ value }) => value === codeValue)[0], [codeValue]);

  // because the whatsapp phone is creating by the support console and the phone code does not match with our options
  const initialCodeOptions = useMemo(() => {
    if (CodeProps?.disabled) {
      return { inputValue: codeValue };
    }
    return getCurrentCodeOption ? { defaultValue: getCurrentCodeOption } : {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Grid container spacing={1} wrap="nowrap" dir="ltr">
      <Grid item xs="auto" sx={{ minWidth: 120 }}>
        <Autocomplete
          id="phoneCode"
          options={options}
          {...initialCodeOptions}
          onChange={handleCodeChange}
          getOptionLabel={(option) => (option.value ? `(${option.value})` : '')}
          filterOptions={filterCodes}
          renderOption={renderOptionLabel}
          disableClearable
          componentsProps={{
            paper: {
              sx: {
                width: 'max-content',
              },
            },
          }}
          {...CodeProps}
          renderInput={(params) => (
            <TextField
              {...params}
              {...CodeInputProps}
              label={codeLabel || intl.formatMessage({ id: 'labels.phoneCode', defaultMessage: 'Code' })}
            />
          )}
        />
      </Grid>
      <Grid item xs>
        <TextFieldBase
          id="phoneNumber"
          value={numberValue}
          onChange={handleNumberChange}
          fullWidth
          type="tel"
          label={
            numberLabel ||
            intl.formatMessage({
              id: 'labels.phoneNumber',
              defaultMessage: 'Phone',
            })
          }
          {...NumberProps}
        />
      </Grid>
    </Grid>
  );
};

export default PhoneBase;
