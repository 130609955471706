// @flow
import React from 'react';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import TextFieldMui from '@mui/material/TextField';
import AutocompleteMui from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';

import { lightBlue } from '@mui/material/colors';
import Avatar from 'components/mui/Avatar';

import { styled } from '@mui/material/styles';

type TOption = {
  value: string,
  label: string,
  picture: string | null,
};

type Props = {
  options: TOption[],
  onSelectChange: (TOption[]) => void,
};

const TextField = styled(TextFieldMui)(() => ({
  '& .MuiOutlinedInput-root.MuiInputBase-sizeSmall': {
    padding: 0,
  },

  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
}));

const Autocomplete: React$StatelessFunctionalComponent<Props> = ({ options, onSelectChange }) => {
  return (
    <AutocompleteMui
      multiple
      id="usersListForUpload"
      options={options}
      onChange={onSelectChange}
      getOptionLabel={({ label }) => label}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      renderOption={(props, { value, picture, label }) => (
        <Stack {...props} key={value} direction="row" alignItems="center" spacing={1}>
          <Avatar src={picture} size={15} />
          <Typography variant="caption">{label}</Typography>
        </Stack>
      )}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip
            key={option.value}
            avatar={<Avatar src={option.picture} size={15} />}
            label={option.label}
            size="small"
            sx={{ pl: 0.5, backgroundColor: lightBlue[50] }}
            {...getTagProps({ index })}
          />
        ))
      }
      fullWidth
      size="small"
      filterSelectedOptions
      disableClearable
      forcePopupIcon={false}
      sx={{ maxHeight: 38, overflowY: 'auto' }}
      renderInput={(params) => <TextField {...params} />}
    />
  );
};

export default Autocomplete;
