// @flow
import React, { Component } from 'react';
import { withStyles } from '@mui/styles';
import cx from 'classnames';
import { compose } from 'redux';
import { rtlEnable } from 'domain/env';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, type intlShape } from 'react-intl';
import sheet from './sheet';

type Props = {
  classes: {
    [key: string]: string,
  },
  placeholder: {
    id: string,
    defaultMessage: string,
  },
  label: {
    id: string,
    defaultMessage: string,
  },
  onChange: (event: KeyboardEvent, newValue: string, oldValue: string, name: string) => void,
  name: string,
  value: string,
  accept: string,
  input: {
    value: string,
    onChange: (event: KeyboardEvent, newValue: string, oldValue: string, name: string) => void,
    name: string,
  },
  className: string,
  mark: string,
  required?: boolean,
  'data-element': string,
  disabled?: boolean,
  capital?: boolean,
  intl: intlShape,
  onUpload: () => void,
};

type State = {
  value: string,
};

class FileInput extends Component<Props, State> {
  constructor(props) {
    super(props);
    const [tail] = props.input.value.split('/').reverse();
    this.state = {
      value: tail,
    };
  }

  onChange = (event: Event) => {
    event.preventDefault();
    const file = event.target.files[0];
    if (file && this.props.onUpload) {
      new Promise((resolve, reject) => {
        this.props.onUpload(file, resolve, reject);
      }).then((picture) => {
        const [tail] = picture.split('/').reverse();
        this.props.input.onChange(picture);
        this.setState({ value: tail });
      });
      return;
    }
    if (file) {
      this.props.input.onChange(file);
      this.setState({ value: file.name });
      return;
    }
    this.setState({ value: null });
  };

  render() {
    const { classes, label, placeholder, mark, accept, name, disabled, className } = this.props;
    const { value } = this.state;
    return (
      <div className={cx(className, classes.wrapper)}>
        <div className={classes.header}>
          <span className={classes.label}>
            <FormattedMessage {...label} />
          </span>
          {placeholder ? (
            <span className={classes.required}>
              <FormattedMessage {...placeholder} />
            </span>
          ) : null}
        </div>
        <label
          htmlFor="fileInput"
          className={cx(classes.fileWrapper, {
            [classes.withValue]: value,
            [classes.disabledFileInput]: disabled,
          })}
          data-element={this.props['data-element']}
        >
          <input
            id="fileInput"
            type="file"
            multiple={false}
            disabled={disabled}
            name={name}
            accept={accept}
            onChange={this.onChange}
            className={classes.file}
          />
          {value && mark ? mark : value || <FormattedMessage id="input.upload" defaultMessage="Upload" />}
        </label>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  direction: rtlEnable(state) ? 'rtl' : 'ltr',
});

export default compose(withStyles(sheet), injectIntl, connect(mapStateToProps))(FileInput);
