/* @flow */
import React from 'react';
import { Map, type RecordOf } from 'immutable';
import elements from 'components/elements';

import Tooltip from 'components/mui/Tooltip';

import cx from 'classnames';

type Props = {|
  classes: {
    [key: string]: string,
  },
  data: RecordOf<{
    readonly: boolean,
    bold: boolean,
    text: string,
    link: boolean,
    style: Map<string, mixed>,
    name: string,
    _cell: string,
    type: string,
  }>,
|};

class DescriptionCell extends React.Component<Props> {
  get cellStyle() {
    const { style } = this.props.data;
    if (Map.isMap(this.props.data.style)) return style.toJS();
    return {};
  }

  render() {
    const { classes, data } = this.props;
    const t = data.hint || data.text;

    return (
      <Tooltip t={t} placement="top" disableFocusListener>
        <div
          // .cell-text-parent use for calculating autosize columns etc
          // src/components/Tables/grid/withResize/withResize.jsx
          className={cx(classes.description, 'cell-text-parent', {
            [classes.bold]: data.bold,
            [classes.readonly]: data.readonly,
          })}
          style={this.cellStyle}
          data-element={elements.je.description.container} // dont rm - styles applied to this attribute
          data-element-id={`${data._cell}-${data.type}`}
        >
          {data.link ? (
            <a href={data.link} target="_blank" rel="noopener noreferrer">
              {data.text}
            </a>
          ) : (
            // className .cell-text use for detect full width of text for resizing columns
            // src/components/Tables/grid/withResize/withResize.jsx
            <span className="cell-text">{data.text}</span>
          )}
        </div>
      </Tooltip>
    );
  }
}

export default DescriptionCell;
