import c from './constatnt';

const field = {
  height: c.ROW_HEIGHT - 2,
  border: 0,
  width: '100%',
  // textAlign: 'left',
  position: 'absolute',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  lineHeight: `${c.ROW_HEIGHT - 2}px`,
  padding: [0, 10],
  // backgroundColor: '#fff',
  unicodeBidi: 'plaintext',
  '&:focus': {
    zIndex: 8,
  },
};

const invalidCell = {
  boxShadow: 'inset 0 0 0 1px #BC5353',
  boxSizing: 'border-box',
  padding: 1,
  position: 'relative',
};

export default (theme) => ({
  tableWrapper: {
    maxWidth: '100%',
    position: 'relative',
    // height: '100%',
    flex: [1, 1],
    display: 'flex',
    flexDirection: 'column',

    '& input, & textarea': {
      fontFamily: theme.typography.fontFamily,
    },
  },
  description: {
    padding: [0, 10], // affect on wrapper withItems for calculate column width, if change also change in src/components/Tables/grid/withResize/withResize.jsx
    width: '100%',
    textAlign: 'left',
    fontSize: 12,
    color: '#171A1C',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    unicodeBidi: 'plaintext',
  },
  activeRow: {},
  lineItems: {
    '&:hover, &$activeRow ': {
      backgroundColor: 'rgba(108, 152, 216, 0.1)',
    },
  },
  fieldWrapper: {
    position: 'relative',
    height: c.ROW_HEIGHT - 2,
    lineHeight: 0,
    fontSize: 0,
    border: 0,
    // '.rtl &': {
    //   flip: false,
    //   direction: 'rtl',
    // },
    '& *': {
      fontSize: 12,
      lineHeight: 'normal',
    },
  },
  field: {
    width: '100%',
    padding: [0, 8],
    height: c.ROW_HEIGHT - 2,
  },
  readonly: {},
  float: {
    ...field,
    textAlign: () => (theme.direction === 'rtl' ? 'inherit' : 'right'),
    '&$readonly': {
      color: theme.palette.grey[700],
    },
  },
  string: {
    ...field,
    '&$readonly': {
      color: theme.palette.grey[700],
    },
  },
  paginated_list: {
    ...field,
    padding: [0, 28, 0, 8],
  },
  index_list: {
    ...field,
    padding: [0, 28, 0, 8],
  },
  string_auto_generated: {
    ...field,
  },
  checkbox: {
    position: 'absolute',
    ...field,
  },
  initValue: {
    color: 'hsl(210, 14%, 70%)',
  },
  dragSupport: {
    // outline: '1px solid #9FBEEB',
  },
  dragAvailable: {
    outline: `2px solid ${theme.palette.primary.main} !important`,
    zIndex: 10,
  },
  bold: {
    fontWeight: 600,
  },
  disabled: {
    userSelect: 'none',
  },
  btnShield: {
    position: 'absolute',
    width: c.ROW_HEIGHT - 2,
    height: c.ROW_HEIGHT - 2,
    padding: 0,
    top: 0,
    bottom: 0,
    right: 4,
    margin: 'auto',
    borderRadius: 2,
    cursor: 'pointer',
    border: '0 none',
    background: 'transparent',
    zIndex: 8,
    '&:after': {
      content: '""',
      display: 'inline-block',
      border: '4px solid transparent',
      borderTopColor: '#333333',
      width: 0,
      height: 0,
      position: 'absolute',
      right: 0,
      bottom: 0,
      top: 5,
      left: 0,
      margin: 'auto',
    },
  },
  dateToggler: {
    position: 'absolute',
    padding: 0,
    top: 0,
    bottom: 0,
    right: 0,
    zIndex: 9,
  },
  btnRotate: {
    transform: 'rotate(180deg)',
  },
  hasError: {
    ...invalidCell,
  },
  mandatory: {
    ...invalidCell,
  },
  active: {
    border: `1px solid ${theme.palette.primary.light} !important`,
    borderRadius: 2,
    '& > $mandatory': {
      boxShadow: 'none',
    },
  },
  hiddenCell: {
    position: 'absolute',
    padding: 0,
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    border: '0 none',
    color: 'transparent',
    opacity: 0.001,
    cursor: 'default',
    '&::selection': {
      background: 'transparent',
      opacity: 0.001,
    },
  },
  fakeCell: {
    pointerEvents: 'none',
    position: 'absolute',
    left: 1,
    top: 1,
    right: 1,
    bottom: 1,
    width: 'auto',
    height: 'auto',
    textAlign: 'left',
  },

  error: {
    margin: [100, 0, 30, 40],
  },

  spinner: {
    position: 'absolute',
    height: '95%',
    backgroundColor: 'transparent',
    pointerEvents: 'none',
  },
});
