// @flow
import { styled } from '@mui/material/styles';

export const LayoutRoot = styled('div')(() => ({
  display: 'flex',
  flexGrow: 1,
  width: '100%',
}));

export const LayoutContent = styled('section')(() => ({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  width: '100%',
}));
