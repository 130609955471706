// @flow
/* eslint-disable max-len */
import { selector } from 'lib/selectors';
import { List } from 'immutable';
// other selectors
import { userGUIDSelector } from 'domain/env/envSelector';
// import { isDocumentConfidentialSelector } from 'domain/documents/documentSelector';
// import { organizationUsersSelector } from 'domain/organizationUser/selectors';
// types
import type { DokkaStore } from '../types.js.flow';
import type {
  ApprovalsRecord,
  UserEnhancedApprovalRecord,
  ApprovalRecordList,
  ApprovalFlowStatus,
  ApprovalReasonRecordMap,
  FormFields,
  ApprovalGroupsType,
} from 'domain/approvals/types.js.flow';
import type { TUiAFStampMode } from 'domain/ui/types.js.flow';
import type { ChatUserRecords } from 'domain/chat/types.js.flow';
// factories
import { UserEnhancedApprovalFactory } from 'domain/approvals/helpers';

type SelectorType<T> = (s: DokkaStore) => T;

// this can not be imported from reconciliation
export const approvals = (state: any): ApprovalsRecord => state.approvals;
export const approvalsListSelector: SelectorType<ApprovalRecordList> = selector(
  approvals,
  (a: ApprovalsRecord) => a.list,
);
export const approvalsAuthorSelector: SelectorType<string> = selector(approvals, (a: ApprovalsRecord) => a.author);
export const approvalsEditableSelector: SelectorType<string> = selector(approvals, (a: ApprovalsRecord) => a.editable);
export const approvalsSignatureSelector: SelectorType<string> = selector(
  approvals,
  (a: ApprovalsRecord) => a.signature,
);
export const approvalsCurrentSignerSelector: SelectorType<string> = selector(
  approvals,
  (a: ApprovalsRecord) => a.currentSigner,
);
export const approvalsStatusSelector: SelectorType<ApprovalFlowStatus> = selector(
  approvals,
  (a: ApprovalsRecord) => a.status,
);
export const approvalsStatusAsBoolSelector: SelectorType<boolean> = selector(
  approvals,
  (a: ApprovalsRecord) => a.status !== 'draft' && a.status !== null,
);
export const isCurrentUserSignaturePendingSelector: SelectorType<string> = selector(
  approvalsCurrentSignerSelector,
  userGUIDSelector,
  approvalsStatusAsBoolSelector,
  (signerPending: string, currentUserGuid: string, isFlowActive: boolean) =>
    signerPending === currentUserGuid && isFlowActive,
);
export const isSignApprovalsStartSelector: SelectorType<boolean> = selector(
  approvalsListSelector,
  (approvalList: ApprovalRecordList) => {
    const first = approvalList.first();
    return first ? first.get('status') === 'approved' : false;
  },
);

export const userEnhancedApprovalsListSelector: SelectorType<List<UserEnhancedApprovalRecord>> = selector(
  approvalsListSelector,
  (state) => state.chat.users, // can not use selector
  (approvalList: ApprovalRecordList, userList: ChatUserRecords) =>
    approvalList.map((approval) => {
      const user = userList.find((u) => u.userId === approval.email);
      const fullName = user ? [user.firstName, user.lastName].join(' ') : approval.email;
      return UserEnhancedApprovalFactory({
        userGuid: approval.userGuid,
        tag: approval.email,
        id: approval.id,
        picture: approval.picture,
        status: approval.status,
        fullName,
      });
    }),
);

export const approvalsRejectReasonsSelector: SelectorType<ApprovalReasonRecordMap> = selector(
  approvals,
  (approval: ApprovalsRecord) => approval.rejectReasonsList,
);

export const approvalsRejectReasonIdSelector: SelectorType<?string> = selector(
  approvals,
  (approval: ApprovalsRecord) => approval.rejectReason,
);

export const approvalsRejectReasonDetailsSelector: SelectorType<?string> = selector(
  approvals,
  (approval: ApprovalsRecord) => approval.rejectMessage,
);

export const approvalsRejectReasonTextSelector: SelectorType<?string> = selector(
  approvalsRejectReasonIdSelector,
  approvalsRejectReasonsSelector,
  (id: ?string, list: ApprovalReasonRecordMap) => (id ? list.get(id, null) : null),
);

export const approveFormFieldsListSelector: SelectorType<FormFields> = selector(
  approvals,
  (approval: ApprovalsRecord) => approval.approveFormFieldsList,
);

export const getApprovalStampModeByApprovals: SelectorType<TUiAFStampMode> = selector(
  isSignApprovalsStartSelector,
  approvalsListSelector,
  approvalsStatusSelector,
  (isFirstNodeApproved: boolean, list: ApprovalRecordList, status: ApprovalFlowStatus) => {
    // if first approver is not yet approve flow and list of approver are greater than zero
    // AF status not active - we can edit stamp position
    if (!isFirstNodeApproved && list.size > 0 && status !== 'active') {
      return 'edit';
    }

    return 'hidden';
  },
);

export const approvalGroupListSelector: SelectorType<List<ApprovalGroupsType>> = selector(
  approvals,
  (approval: ApprovalsRecord) => approval.approvalGroups,
);

export const approvalGroupsLoadedSelector: SelectorType<boolean> = selector(
  approvals,
  (approval: ApprovalsRecord) => approval.approvalGroupsLoaded,
);

const approvalActiveGroupIdSelector: SelectorType<List<ApprovalGroupsType>> = selector(
  approvals,
  (approval: ApprovalsRecord) => approval.groupId,
);

export const activeGroupNameEnhancedSelector: SelectorType<?string> = selector(
  approvalActiveGroupIdSelector,
  approvalGroupListSelector,
  (activeGroupId: string, groupsList: List<ApprovalGroupsType>) => {
    const groupName = groupsList.find((g) => g.group_id === activeGroupId);
    return groupName ? groupName.group_title : null;
  },
);

// export const organizationUsersForConfidentialDocSelector: SelectorType<ChatUserRecords> =
//   selector(state => state.chat.users, isDocumentConfidentialSelector,
//     (users: ChatUserRecords, isDocumentConfidential: boolean) => (!isDocumentConfidential ? users : users.filter(
//       user => user.role !== 'user', // user role doesnt have access to confidentialdocs
//     )),
//   );
