import PaperMui from '@mui/material/Paper';
import { styled } from '@mui/material/styles';

export const TranslatePopup = styled(PaperMui, { label: 'TranslatePopup' })(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  position: 'absolute',
  top: 0,
  left: 0,
  zIndex: 10,
  padding: theme.spacing(1),
  margin: theme.spacing(1),
  cursor: 'auto',
  border: `1px solid ${theme.palette.grey[200]}`,
  pointerEvents: 'all',
}));

export const TranslateColumn = styled('div', { label: 'TranslatePopup' })(({ theme }) => ({
  height: '100%',
  padding: theme.spacing(0, 0.5),
  flex: 1,
  overflowY: 'auto',
  userSelect: 'text',
  cursor: 'auto',
  minWidth: 50,
  minHeight: 50,
}));
