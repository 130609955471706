/* @flow */
import { dataTypes } from './acceptTypes';

export default function download(url: string, filename: string) {
  if (window.navigator.msSaveOrOpenBlob) {
    window.fetch(url).then((response) => {
      response.blob().then((data) => {
        const [type] = filename.split('.').reverse();
        const file = new window.Blob([data], {
          type: dataTypes[type],
        });
        window.navigator.msSaveOrOpenBlob(file, filename);
      });
    });
  } else {
    window.open(url);
  }
}
