import defaultPic from './images/person9.svg';
import type { Props } from './UserPic';

export const DEFAULT_SIZE = 28;

const getColor = ({ isHighlite, src }: Props, theme: any) => {
  if (src) return 'transparent';
  return isHighlite ? theme.colors.primaryOrange : theme.colors.primaryDark;
};

const getBorder = (props: Props, theme: any) => (!props.src ? `1px solid ${getColor(props, theme)}` : 'none');

const getSize = ({ size, src, disableBorder }: Props) => {
  const currentSize = size || DEFAULT_SIZE;
  return src || disableBorder ? currentSize : currentSize - 2; // - border
};

export default (theme) => ({
  picBox: {
    backgroundColor: (props: Props) => getColor(props, theme),
    borderRadius: '50%',
    overflow: 'hidden',
    boxSizing: 'border-box',
  },

  picBoxBorder: {
    border: (props: Props) => getBorder(props, theme),
  },

  pic: {
    backgroundColor: theme.colors.primaryWhite,
    borderRadius: '50%',
    width: (props: Props) => getSize(props),
    height: (props: Props) => getSize(props),
    boxSizing: 'border-box',
  },

  srcPic: {
    border: 'none !important',
    background: ({ src }) => `url(${src}) 50% 50% / cover no-repeat`,
  },

  defaultPick: {
    WebkitMask: `url("${defaultPic}") 50% 50% no-repeat`,
    WebkitMaskSize: 'cover',
  },
});
