import React, { useMemo } from 'react';
import Helmet from 'react-helmet';
import { useLocation } from 'react-router-dom';
import { useTheme } from '@mui/material';
import { matchRoute } from 'domain/router/utils';
import { options as dokkaWhitelabelOptions } from 'labels/dokka';
import { useIntl } from 'react-intl';

const MetadataManager = () => {
  const location = useLocation();
  const {
    labelOptions: { name },
  } = useTheme();
  const intl = useIntl();
  const [titleMessageId, descriptionMessageId] = useMemo(
    () =>
      matchRoute.isLogin(location.pathname)
        ? ['app.meta.title.login', 'app.meta.description.login']
        : ['app.meta.title.default', 'app.meta.description.default'],
    [location.pathname],
  );
  const dokkaWhitelabel = name === dokkaWhitelabelOptions.name;

  return dokkaWhitelabel ? (
    <Helmet>
      <title>{intl.formatMessage({ id: titleMessageId, defaultMessage: 'DOKKA AP' })}</title>
      <meta
        name="description"
        content={intl.formatMessage({ id: descriptionMessageId, defaultMessage: 'DOKKA AP' })}
      />
    </Helmet>
  ) : null;
};

export default MetadataManager;
