import { List } from 'immutable';

export const workspaceHotkeys = new List([
  {
    description: {
      id: 'company.hotkeys.listDuplicateDocs',
      defaultMessage: 'List duplicate documents uploaded to Dokka',
    },
    keys: [new List(['Control', 'KeyE'])],
    level: 'historyTrail',
  },
]);
