import TextFieldMui from '@mui/material/TextField';
import { styled } from '@mui/material/styles';

export const TextField = styled(TextFieldMui, { label: 'TextFieldNumber' })(({ theme }) => ({
  backgroundColor: theme.palette.grey[300],
  borderRadius: 8,
  height: 32,
  padding: theme.spacing(0, 0.5),
  '& .MuiInputBase-root': {
    height: '100%',
    padding: theme.spacing(0, 0.5),
  },

  '& .MuiInputBase-input': {
    unicodeBidi: 'plaintext',
    textAlign: 'left',
    fontSize: theme.typography.pxToRem(13),
  },
}));
