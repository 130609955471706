// @flow
import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import moment from 'moment';

import { type ApiKeyType } from 'domain/organization/types.js.flow';

import Stack from '@mui/material/Stack';
import { grey } from '@mui/material/colors';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import { IconButton } from '@mui/material';
import { DeleteOutlineOutlined, EditOutlined } from '@mui/icons-material';

type TKeysItem = {
  apiKey: ApiKeyType,
  handleDeleteApiKeyClick: (apiKey: string) => () => Promise<void>,
  handleEditClick: (apiKey: string) => () => void,
  dateFormat: string,
};

const KeyItem: React$StatelessFunctionalComponent<TKeysItem> = ({
  apiKey,
  handleDeleteApiKeyClick,
  handleEditClick,
  dateFormat,
}) => {
  const { formatMessage } = useIntl();

  const isExpired = moment(apiKey.expiration).isBefore(moment());

  const handleCopyKey = useCallback((key) => () => navigator.clipboard.writeText(key), []);

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      bgcolor={grey[50]}
      sx={{
        border: `1px solid ${grey[200]}`,
        borderRadius: 4,
      }}
      p={3}
    >
      <Box maxWidth="33%" flexGrow={2}>
        <Stack direction="row" alignItems="center" spacing={1}>
          <Typography variant="h6">{apiKey.key_name}</Typography>
          <Chip
            label={
              <>
                {formatMessage({
                  id: `configurations.apiKeys.${isExpired ? 'expired' : 'expires'}`,
                  defaultMessage: isExpired ? 'Expired' : 'Expires',
                })}
                &nbsp;
                {moment(apiKey.expiration).format(dateFormat)}
              </>
            }
            color={isExpired ? 'error' : 'success'}
            size="small"
          />
        </Stack>
        <Typography variant="body2" color={grey[600]}>
          IP: {apiKey.ips.replace(/,/g, ', ')}
        </Typography>
      </Box>
      <Stack maxWidth="33%" flexGrow={2} color={grey[500]} direction="row" alignItems="center">
        <Box>{apiKey.api_key}</Box>
        <Divider orientation="vertical" variant="middle" sx={{ height: 34, ml: 2, mr: 1 }} />
        <Button variant="text" onClick={handleCopyKey(apiKey.api_key)}>
          {formatMessage({ id: 'button.copy', defaultMessage: 'Copy' })}
        </Button>
      </Stack>
      <Stack direction="row">
        <IconButton onClick={handleEditClick(apiKey.api_key)}>
          <EditOutlined />
        </IconButton>
        <IconButton onClick={handleDeleteApiKeyClick(apiKey.api_key)}>
          <DeleteOutlineOutlined sx={{ color: 'error.main' }} />
        </IconButton>
      </Stack>
    </Stack>
  );
};

export default KeyItem;
