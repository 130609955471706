/* @flow */
import * as React from 'react';
import cx from 'classnames';

import { withStyles } from '@mui/styles';
import sheet from './sheet';
import { FormattedMessage } from 'react-intl';
import type { TLabelOptions } from 'labels/type.js.flow';
import flow from 'lodash/flow';

export type P = {
  classes: {|
    [key: string]: string,
  |},
  className: string,
  theme: {
    labelOptions: TLabelOptions,
  },
};

const JEError: React.StatelessFunctionalComponent<P> = ({ classes, className, theme: { labelOptions } }: P) => (
  /* <FormattedMessage
      id="document.show.journalEntry.selectedRows"
      defaultMessage="{selectedRows} of {countAllRows} selected"
      values={{ selectedRows: selectedRows.size, countAllRows }}
    /> */

  <div className={cx(classes.box, className)}>
    <div className={classes.title}>
      <FormattedMessage id="document.journalEntry.error.title" defaultMessage="Something went wrong" />
    </div>
    <div className={classes.mesage}>
      <FormattedMessage
        id="document.journalEntry.error.message"
        defaultMessage="The data entry form is not available due to a connectivity or other issue.
        Please, click {sync} button at the top-right of the sccreen  or contact {email}"
        values={{
          sync: <span className={classes.sync}>Sync</span>,
          email: (
            <a href={`mailto:${labelOptions.supportEmail}`} className={classes.email}>
              {labelOptions.supportEmail}
            </a>
          ),
        }}
      />
    </div>
  </div>
);

export default flow(withStyles(sheet, { withTheme: true }))(JEError);
