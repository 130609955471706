/* @flow */
import * as Sentry from '@sentry/browser';
import get from 'lodash/get';
import omit from 'lodash/omit';

const FILTERED_KEYS = ['settings', 'env', 'messages', 'companies', 'chat', 'form', 'organization'];

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  release: process.env.REACT_APP_BUILD_VERSION,
  environment: process.env.REACT_APP_ENV,
  beforeBreadcrumb(breadcrumb, hint) {
    if (breadcrumb.category === 'ui.click' || breadcrumb.category === 'ui.input') {
      const { target } = hint.event;
      if (target && typeof target.getAttribute === 'function') {
        const name = target.getAttribute('data-element');
        const id = target.getAttribute('data-element-id');
        if (name) {
          // eslint-disable-next-line no-param-reassign
          breadcrumb.message = [name, id].filter((f) => f).join('-');
        } else if (target.textContent) {
          // eslint-disable-next-line no-param-reassign
          breadcrumb.message = target.textContent;
        }
      }
    }
    return breadcrumb;
  },
  beforeSend(event: Event): Promise<Event | null> | Event | null {
    const state = get(event.extra, 'state', {});
    // eslint-disable-next-line no-unused-vars
    const normalizedState = Object.entries(state).reduce(
      (res, [key, val]) => (!FILTERED_KEYS.includes(key) ? { ...res, [`store_${key}`]: val } : res),
      {},
    );
    const extra = omit({ ...event.extra, ...normalizedState }, ['state']);
    return { ...event, extra };
  },
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  integrations: [
    new Sentry.Replay({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
});

export function setUser(id: string, email: string) {
  Sentry.configureScope((scope) => {
    scope.setUser({ id, email });
  });
}

export function enrichException(error: Error, meta?: { data: Object }, tag?: string) {
  Sentry.withScope((scope) => {
    if (typeof tag === 'string') {
      scope.setTag('source', tag);
    }
    if (meta) {
      scope.setExtra('data', meta);
    }
    Sentry.captureException(error);
  });
}
