// @flow
import React, { useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { companyApplicationsSelector, connectSlackAction, disconnectSlackAction } from 'domain/companies';

import AppItem from 'pages/configurations/company/pages/applications/components/AppItem';

import SlackLogo from 'pages/configurations/company/pages/applications/components/images/slack.png';

const Applications = () => {
  const [selected, setSelected] = useState(null);
  const dispatch = useDispatch();
  const applications = useSelector(companyApplicationsSelector);
  const apps = useMemo(
    () => [
      {
        id: 'slack',
        name: 'Slack',
        img: SlackLogo,
        onConnect: () => {
          dispatch(connectSlackAction());
        },
        onDisconnect: () => {
          dispatch(disconnectSlackAction());
        },
        ...applications.slack,
      },
    ],
    [dispatch, applications],
  );

  return apps.map(({ id, ...rest }) => (
    <AppItem
      key={id}
      isSelected={id === selected}
      onClickOpen={() => setSelected(id)}
      onClickClose={() => setSelected(null)}
      {...rest}
    />
  ));
};

export default Applications;
