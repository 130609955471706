// @flow
import React, { type Node } from 'react';
import cx from 'classnames';
import OutsideClickHandler from 'components/OutsideClickHandler';
import { withStyles } from '@mui/styles';
import sheet from './sheet';

export type Props = {
  classes: {
    [key: string]: string,
  },
  button: (isActive: boolean) => Node,
  children: () => Node,

  // jss props
  isWhite?: boolean,
  buttonSize?: number,
};

type State = {|
  isOpen: boolean,
|};

// TODO: remove after facelifting
class BarDropdown extends React.Component<Props, State> {
  state = {
    isOpen: false,
  };

  toggle = () => {
    const isOpen = !this.state.isOpen;
    this.setState({ isOpen });
  };

  onMouseUp = () => {
    setTimeout(this.toggle, 0);
  };

  render() {
    const { classes, button, children } = this.props;
    const { isOpen } = this.state;
    return (
      <div className={classes.box}>
        <OutsideClickHandler onOutsideClick={this.toggle} disabled={!isOpen}>
          <div className={cx(classes.button, { [classes.buttonOpen]: isOpen })}>
            <div className={classes.buttonPos}>{button(isOpen, this.toggle)}</div>
          </div>
          {isOpen && (
            <div onMouseUp={this.onMouseUp} className={classes.items}>
              {children()}
            </div>
          )}
        </OutsideClickHandler>
      </div>
    );
  }
}

export default withStyles(sheet)(BarDropdown);
