/* @flow */
import * as React from 'react';
import { withStyles } from '@mui/styles';
import OTC from './context';
import { getTooltipParams } from './helper';

import sheet from './sheet';

export type Props = {|
  children: React$Node,
  text: string,
  textId?: string,
  widthBox?: number,
  className?: string,
  classNameWrapper?: string,
|};

class OutsideTooltip extends React.PureComponent<Props> {
  getChildren = (setData) => {
    const { children, text, textId, widthBox, className, classNameWrapper } = this.props;
    const childrenWithProps = React.Children.map(children, (child) =>
      React.cloneElement(child, {
        onMouseEnter: (e: SyntheticEvent<HTMLElement>) => {
          const pos = getTooltipParams(e.currentTarget);
          setData({ text, textId, className, classNameWrapper, pos: { ...pos, widthBox: widthBox || pos.widthBox } });
        },
        onMouseLeave: () => {
          setData(null);
        },
      }),
    );
    return childrenWithProps;
  };

  render() {
    return <OTC.Consumer>{({ setOutsideTooltip }) => this.getChildren(setOutsideTooltip)}</OTC.Consumer>;
  }
}

export default withStyles(sheet)(OutsideTooltip);
