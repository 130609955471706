// @flow
import React from 'react';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

type Props = {
  value: number,
};

const ProgressBar: React$StatelessFunctionalComponent<Props> = ({ value }) => (
  <Box sx={{ display: 'flex', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
    <CircularProgress variant="determinate" value={value} size={82} />
    <Box
      sx={{
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        position: 'absolute',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Typography variant="body2" component="div" color="text.secondary">{`${value}%`}</Typography>
    </Box>
  </Box>
);

export default ProgressBar;
