// @flow
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useTheme } from '@mui/material';

import Typography from '@mui/material/Typography';
import LinkMui from '@mui/material/Link';
import { Footer, FooterNavigation } from 'components/mui/Layouts/components/FooterWithTerms/StyledComponents';

import CircleIcon from '@mui/icons-material/Circle';

type TFooterWithTerms = {
  mqColor?: {
    [key: string]: string,
  },
};

function FooterWithTerms({ mqColor }: TFooterWithTerms) {
  const { labelOptions } = useTheme();
  const { termsAndConditionLink, privacyLink } = labelOptions;
  const linkColor = mqColor || 'primary.main';

  return (
    <Footer>
      <FooterNavigation separator={<CircleIcon sx={{ fontSize: 3 }} color="primary" />}>
        {termsAndConditionLink && (
          <LinkMui
            color={linkColor}
            href={termsAndConditionLink}
            underline="hover"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FormattedMessage id="auth.termsShort" defaultMessage="Terms and Conditions" />
          </LinkMui>
        )}

        {privacyLink && (
          <LinkMui color={linkColor} href={privacyLink} underline="hover" target="_blank" rel="noopener noreferrer">
            <FormattedMessage id="auth.privacyPolicy" defaultMessage="Privacy Policy" />
          </LinkMui>
        )}
        <LinkMui
          color={linkColor}
          href={`mailto:${labelOptions.supportEmail}`}
          underline="hover"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FormattedMessage id="auth.support" defaultMessage="Support" />
        </LinkMui>
      </FooterNavigation>
      <Typography variant="hint" color="text.secondary" fontWeight="400">
        <FormattedMessage id="auth.cookies" defaultMessage="We use cookies to give you a better experience" />
      </Typography>
    </Footer>
  );
}

export default FooterWithTerms;
