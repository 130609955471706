// @flow
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { deleteCompany } from 'domain/companies';

import Dialog from 'components/mui/Dialog';
import Typography from '@mui/material/Typography';
import TextFieldBase from 'components/mui/Form/TextField/TextFieldBase';

type Props = {
  onClose: () => void,
};

const DeleteModal: React$StatelessFunctionalComponent<Props> = ({ onClose }) => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();

  const [isDisabled, setIsDisabled] = useState(true);

  const keyWord = formatMessage({
    id: 'configurations.delete_company.keyword',
    defaultMessage: 'Delete',
  });

  const onChange = (e) => {
    const { value } = e.target;
    setIsDisabled(keyWord.toLowerCase() !== value.toLowerCase());
  };

  const handleDeleteCompany = () => {
    dispatch(deleteCompany());
    onClose();
  };

  return (
    <Dialog
      open
      onClose={onClose}
      onOk={handleDeleteCompany}
      okBtnProps={{ disabled: isDisabled, color: 'error' }}
      okText={formatMessage({ id: 'button.yes', defaultMessage: 'Yes' })}
      cancelText={formatMessage({ id: 'button.no', defaultMessage: 'No' })}
      title={formatMessage({ id: 'configurations.delete_company.title', defaultMessage: 'Are you sure?' })}
    >
      <Typography variant="body2">
        {formatMessage(
          {
            id: 'configurations.delete_company.placeholder',
            defaultMessage:
              'Are you sure you want to delete this company? The data will be permanently deleted. If so, please type "{keyWord}" in the box below. ATTENTION the data will be permanently deleted',
          },
          { keyWord },
        )}
      </Typography>
      <TextFieldBase onChange={onChange} fullWidth autoFocus size="small" sx={{ marginTop: 2 }} />
    </Dialog>
  );
};

export default DeleteModal;
