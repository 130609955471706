import { takeEvery } from 'redux-saga/effects';
import Cookies from 'js-cookie';

import { locationChangeAction } from 'domain/env/envActions';
import { cognitoCookieDomain } from 'amplify/awsauth';

const COOKIE_OPTIONS = {
  expires: 365,
  domain: cognitoCookieDomain,
};
function setLocationParams({ payload: locale }) {
  const rtl = locale === 'he';

  Cookies.set('locale', JSON.stringify({ rtl, locale }), COOKIE_OPTIONS);
  window.location.reload();
}

export default function* ensureChangeLanguage() {
  yield takeEvery(locationChangeAction.type, setLocationParams);
}
