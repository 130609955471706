/* @flow */
import React from 'react';

// styles
import sheets from './sheets';
import { withStyles } from '@mui/styles';

type Props = {|
  classes: {
    [key: string]: string,
  },
|};

const DropdownIndicator = (props: Props): React$Node => {
  const { classes } = props;
  return <div className={classes.indicator} />;
};

export default withStyles(sheets)(DropdownIndicator);
