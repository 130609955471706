// @flow
import * as React from 'react';
import { Map, Set, List } from 'immutable';
import { compose, type Dispatch } from 'redux';
import { connect } from 'react-redux';
import { withStyles } from '@mui/styles';
import type { PreviousMessageListQuery } from 'sendbird';
import {
  chatThreadSelector,
  sendMessageAction,
  getPreviousMessageAction,
  messageQuerySelector,
  isChatConnectedSelector,
} from 'domain/chat';
import { userSelector } from 'domain/env';
import { GroupMessages } from 'domain/chat/helpers';
import type { TMessageRecord, MessageGroup } from 'domain/chat/types.js.flow';
import InputField from './input';
import Messages from './messages';
import sheet from './sheet';

type Props = {|
  classes: {
    [key: string]: string,
  },
  sendMessage: Dispatch<sendMessageAction>,
  getPrevious: Dispatch<getPreviousMessageAction>,
  threads: Map<string, Set<TMessageRecord>>,
  threadId: ?string,
  messageQuery: Map<string, PreviousMessageListQuery>,
  owner: {
    userId: string,
    chatUser: string,
    chatToken: string,
  },
  params: {
    companyId: string,
    documentId?: string,
  },
  isConnected: boolean,
|};

class MessageThread extends React.Component<Props> {
  get thread(): ?MessageGroup {
    const { threadId, threads } = this.props;
    if (threadId && this.messageQuery) {
      const thread = threads.get(threadId);
      if (thread) return GroupMessages(thread.messages.toList());
      if (this.messageQuery.get('isLoading') === false) return new List();
    }
    return null;
  }

  get messageQuery(): ?PreviousMessageListQuery {
    const { threadId, messageQuery } = this.props;
    if (threadId) return messageQuery.get(threadId);
    return null;
  }

  handleSend = (message) => {
    const { threadId, params, sendMessage } = this.props;
    sendMessage({ message, threadId, params });
  };

  handlePrev = () => {
    const { threadId } = this.props;
    const mq = this.messageQuery;
    if (mq && mq.hasMore && !mq.isLoading) {
      this.props.getPrevious(threadId);
    }
  };

  render() {
    const { classes, threadId, isConnected, owner } = this.props;
    return (
      <div className={classes.container}>
        <Messages list={this.thread} classes={classes} ownerId={owner.userId} onPrev={this.handlePrev} />
        <InputField disabled={!isConnected} threadId={threadId} onSend={this.handleSend} classes={classes} />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  threads: chatThreadSelector(state),
  owner: userSelector(state),
  messageQuery: messageQuerySelector(state),
  isConnected: isChatConnectedSelector(state),
});

const mapDispatchToProps = {
  sendMessage: sendMessageAction,
  getPrevious: getPreviousMessageAction,
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(sheet))(MessageThread);
