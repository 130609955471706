// @flow
import React from 'react';
import { useIntl } from 'react-intl';
import { compose } from 'redux';
import { reduxForm, Field, formValueSelector, type FormProps } from 'redux-form/immutable';
import { connect } from 'react-redux';

import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import TextFieldForm from 'components/mui/Form/TextField/TextFieldForm';
import UploadImg from './components/UploadImg';

type Props = {
  onClose: () => void,
  picture: File,
  handleSubmit: ({ name: string, picture: File }) => void,
  formID?: string,
} & FormProps;

const CreateCompanyForm: React$StatelessFunctionalComponent<Props> = ({
  handleSubmit,
  picture,
  formID,
  submitting,
  onClose,
}) => {
  const { formatMessage } = useIntl();

  return (
    <form onSubmit={handleSubmit} id={formID}>
      <DialogContent>
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          bgcolor="rgba(134, 132, 141, 0.08)"
          borderRadius={1}
          height={200}
          width="100%"
          py={2.5}
          px={16}
          mb={2}
        >
          <Field
            component={UploadImg}
            label={{
              id: 'form.company.create.logo.label',
              defaultMessage: 'Company logo',
            }}
            required
            mark="logo.png"
            accept="image/png, image/jpg, image/jpeg, image/bmp"
            capital
            name="picture"
            src={picture ? window.URL.createObjectURL(picture) : null}
          />
        </Grid>
        <Field
          component={TextFieldForm}
          label={formatMessage({
            id: 'form.company.create.name.label',
            defaultMessage: 'Company name',
          })}
          placeholder={formatMessage({
            id: 'form.company.create.name.placeholder',
            defaultMessage: 'Company name',
          })}
          fullWidth
          required
          name="name"
          withTrim
        />
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={onClose}>
          {formatMessage({
            id: 'button.cancel',
            defaultMessage: 'Cancel',
          })}
        </Button>
        <LoadingButton variant="contained" type="submit" loading={submitting}>
          {formatMessage({
            id: 'button.next',
            defaultMessage: 'Next',
          })}
        </LoadingButton>
      </DialogActions>
    </form>
  );
};

const mapStateToProps = (state) => ({
  name: formValueSelector('createCompanyForm')(state, 'name'),
  picture: formValueSelector('createCompanyForm')(state, 'picture'),
});

export default compose(
  connect(mapStateToProps),
  reduxForm({
    form: 'createCompanyForm',
  }),
)(CreateCompanyForm);
