// @flow
import React from 'react';
import { FormattedMessage } from 'react-intl';
import cx from 'classnames';
import { withStyles } from '@mui/styles';
import { emailReg } from '../validation';
import sheet from './sheet';

export type MetaType = {
  touched: boolean,
  error: string,
};

type Props = {
  classes: {
    [key: string]: string,
  },
  className: string,
  meta: MetaType,
  textAlign?: 'left' | 'center' | 'right',
  isWide?: boolean,
};

class Message extends React.Component<Props> {
  get isError() {
    const { meta } = this.props;
    return meta.touched && meta.error;
  }

  // TODO: FormattedMessage return array instead string now
  static getMessage([str: string]) {
    const match = str.match(emailReg);
    if (Array.isArray(match)) {
      const parts = str.split(match[0]);
      return (
        <>
          {parts[0]}
          <a href={`mailto:${match[0]}`}>{match[0]}</a>
          {parts[1]}
        </>
      );
    }
    return str;
  }

  render() {
    const { meta, classes, className, textAlign = 'left', isWide } = this.props;
    return this.isError ? (
      <span className={cx(classes.errorText, { [classes.isWide]: isWide }, classes[textAlign], className)}>
        <FormattedMessage id={`message.${meta.error.split(' ').join('_')}`} defaultMessage={meta.error}>
          {this.constructor.getMessage}
        </FormattedMessage>
      </span>
    ) : null;
  }
}

export default withStyles(sheet)(Message);
