/* @flow */
import { selector } from 'lib/selectors';

export const messageHistory = (state) => state.requestTransaction;

export const companyPictureSelector = selector(messageHistory, (m) => m.companyPicture);
export const companyNameSelector = selector(messageHistory, (m) => m.companyName);
export const transactionsSelector = selector(messageHistory, (m) =>
  m.transactions.toList().map((e) => e.set('currency', m.currency)),
);
export const bookkeeperMessagesSelector = selector(messageHistory, (m) =>
  m.bookkeeperMessages.sortBy((item) => new Date(item.timestamp)),
);
