/* @flow */
import React from 'react';
import type {
  TChips,
  TChip,
} from 'components/mui/Layouts/components/EventsHistoryPanel/components/FiltersBlock/types.js.flow';

import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
// TODO: mui Chip component for some reason does not want or
//  cant apply styles(for Avatar component adds special classes)
//  for our component from components/mui/Avatar (resizing margins etc)
import AvatarMui from '@mui/material/Avatar';

type TEventsHistoryFilterChips = {
  chips: TChips,
  onRemove: (option: TChip) => void,
};

const EventsHistoryFilterChips: React$StatelessFunctionalComponent<TEventsHistoryFilterChips> = ({ chips, onRemove }) =>
  chips.size > 0 && (
    <Stack flexDirection="row" flexWrap="wrap" gap={0.5} mb={1}>
      {chips.map((chip) => (
        <Chip
          color="primary"
          variant="outlined"
          size="small"
          key={chip.value}
          onDelete={() => onRemove(chip)}
          label={chip.label}
          {...(chip.type === 'user' && { avatar: <AvatarMui src={chip.picture} /> })}
        />
      ))}
    </Stack>
  );

export default EventsHistoryFilterChips;
