import { create as createJss } from 'jss';
import rtl from 'jss-rtl';
import preset from 'jss-preset-default';

const presets = preset().plugins;

const config = {
  plugins: presets,
};

const jss = (rtlEnable = false) => {
  if (rtlEnable) return createJss({ ...config, plugins: config.plugins.concat(rtl()) });
  return createJss(config);
};

export default jss;
