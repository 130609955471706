/* @flow */
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Map, List, Set, type RecordOf } from 'immutable';
import { toast } from 'react-toastify';
import type { JournalEntryMessageType } from 'domain/journal/types.js.flow';
import JEToast from './component';
import Typography from '@mui/material/Typography';

export type DataType = {
  error?: string,
  warning?: string,
  info?: string,
};

type EventsType = 'warning' | 'error' | 'info';

const WARNING = 'warning';
const ERROR = 'error';
const INFO = 'info';

export class EventHandler {
  static _closeToastItem(idx: number) {
    toast.update(idx, { autoClose: 5 });
  }

  constructor() {
    this._messages = new Map();

    this._dict = {
      error: [],
      warning: [],
      info: [],
    };
  }

  _setMessage(message: string, idx: number, type: EventsType): void {
    this._messages = this._messages.set(List.of(message, type), idx);
  }

  _isExistMessage(message: string, type: EventsType) {
    return this._messages.has(List.of(message, type));
  }

  _getToastIndex(message: string, type: EventsType) {
    return this._messages.get(List.of(message, type));
  }

  // eslint-disable-next-line max-len
  onResponceTranslate({ id, defaultMessage, type, values }: { id: string, defaultMessage: string, type: EventsType }) {
    // TODO: maybe need to do like _createToast, find index if exist - update

    if (this._isExistMessage(defaultMessage, type)) return;

    const toastId = toast(
      <JEToast type={type}>
        <Typography variant="body2" sx={{ overflowWrap: 'anywhere' }}>
          <FormattedMessage id={id} defaultMessage={defaultMessage} values={values} />
        </Typography>
      </JEToast>,
      {
        type,
        autoClose: false,
        onClose: () => this.onClose({ text: defaultMessage, type }),
      },
    );

    this._setMessage(defaultMessage, toastId, type);
  }

  onResponseForDuplicate(message: RecordOf<JournalEntryMessageType>) {
    if (this._isExistMessage(message.text, message.level)) return;

    const toastId = toast(<JEToast text={message.text} type={message.level} />, {
      type: message.level,
      autoClose: false,
      onClose: () => this.onClose({ text: message.text, type: message.level }),
    });

    this._setMessage(message.text, toastId, message.level);
  }

  onResponce(messages: List<RecordOf<JournalEntryMessageType>>) {
    messages
      .reduce((a, v) => {
        this._createToast(v.text, v.level, v.type);
        return a.remove(v.level);
      }, Set.of(WARNING, ERROR, INFO))
      .forEach((e) => {
        this._closeToastsList(e);
      });
  }

  _createToast(message: string, type: EventsType, subType: string) {
    const idx = this._getToastIndex(subType, type);
    const isActiveToast = toast.isActive(idx);

    if (isActiveToast) {
      toast.update(idx, { render: <JEToast text={message} type={type} /> });
    } else {
      const toastId = toast(<JEToast text={message} type={type} />, {
        type,
        autoClose: false,
        onClose: () => this.onClose({ text: subType, type }),
      });
      this._setMessage(subType, toastId, type);
    }
  }

  getListIdByType(type: EventsType): List<number> {
    return this._messages.reduce((a, v, k) => (k.get(1) === type ? a.push(v) : a), new List());
  }

  _closeToastsList(type: EventsType) {
    this.getListIdByType(type).forEach(this.constructor._closeToastItem);
  }

  onClose = ({ text, type }) => {
    this._messages = this._messages.remove(List.of(text, type));
  };

  // eslint-disable-next-line class-methods-use-this
  closeAll() {
    toast.dismiss();
    // this is invoked on documentWillUnmount and doesnt allow to remember messages
    // active between docs and update active toasts

    // this._messages = this._messages.clear();
  }

  _messages: Map<List<string>, number>;
}
