/* @flow */

export default {
  input: {
    width: '100%',
    height: '100%',
    padding: [0, 10],
    border: '0 none',
  },
  init: {
    color: 'hsl(210, 14%, 70%)',
  },
};
