// @flow
import React, { Component } from 'react';
import { injectIntl, type IntlShape } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';

import type { RecordOf } from 'immutable';
import type { featureSetFactory } from 'domain/companies/helpers';
import type { SigningsType, SigningType } from 'domain/organization/types.js.flow';

import {
  companySigningsSelector,
  removeCompanySigner,
  updateCompanySignings,
  removeAllCompanySigner,
  addEmptyCompanySigner,
  setCompanyFeatureSet,
  setCompanySigningsGuid,
  companyFeatureSetSelector,
  getCompanySigningsCopy,
} from 'domain/companies';
import { isDisablingFinAllowedSelector } from 'domain/dashboard/selectors';

import Modal from 'components/ConfirmationModal/modal';
import Prompt from 'components/Prompt';
import FeaturesForm from 'pages/common/forms/features';

type Props = {
  // eslint-disable-next-line react/no-unused-prop-types
  handleSubmit: () => void,
  signings: RecordOf<SigningsType>,
  sign: (data: SigningType) => void,
  setFeatureSet: ({ key: string, value: boolean }) => void,
  addSign: () => void,
  featureSet: RecordOf<featureSetFactory>,
  remove: (tokenName: string) => void,
  removeAll: () => void,
  getCopy: () => void,
  setGuid: (guid: string) => void,
  intl: IntlShape,
  isDisablingAllowed: boolean,
};

type State = {
  showWarningModal: boolean,
};
class FeaturesFormContainer extends Component<Props, State> {
  state = {
    showWarningModal: false,
  };

  onSetFutureSet = (value: boolean): void => {
    const { setFeatureSet, isDisablingAllowed } = this.props;

    if (!value && !isDisablingAllowed) {
      this.setState({ showWarningModal: true });
      return;
    }

    setFeatureSet({ key: 'fin', value });
  };

  onCloseWarningModal = () => {
    this.setState({ showWarningModal: false });
  };

  get guidNotPresent(): boolean {
    const { guid, tokens } = this.props.signings;
    // turned on and guid empty
    return tokens.size > 0 && guid.length === 0;
  }

  get signersNotPresent(): boolean {
    const { tokens, guid } = this.props.signings;
    // if turned on, check for filled value
    if (tokens.size > 0 || guid.length > 0) {
      // 2 signers present, check if at least one is not filled
      if (tokens.size === 2) {
        return tokens.some((token) => token.name.length === 0 && token.password.length === 0);
      }
      // single signer present, check fails automaticaly as we need 2
      return true;
    }
    // turned off, check passes automatically
    return false;
  }

  get message(): string {
    const { intl } = this.props;
    return this.guidNotPresent
      ? intl.formatMessage({
          id: 'forms.common.features.guidEmpty',
          defaultMessage: 'Signing will not work properly without provided GUID',
        })
      : intl.formatMessage({
          id: 'forms.common.features.signersEmpty',
          defaultMessage: 'Signing will not work properly until both signers are added',
        });
  }

  render() {
    const { showWarningModal } = this.state;
    const {
      sign,
      featureSet,
      addSign,
      remove,
      getCopy,
      removeAll,
      signings,
      setGuid,
      intl: { formatMessage },
    } = this.props;

    return (
      <>
        {showWarningModal && (
          <Modal
            red
            isWarning
            isActionRequired={false}
            title={formatMessage({
              id: 'company.financialWarningModal.title',
              defaultMessage: 'Warning',
            })}
            placeholder={formatMessage({
              id: 'company.financialWarningModal.message',
              defaultMessage:
                'You still have financial documents available or possibly analyzing.' +
                ' Please, set them as General documents first or wait until all documents are done processing',
            })}
            confirmText={formatMessage({
              id: 'company.financialWarningModal.confirm',
              defaultMessage: 'OK',
            })}
            cancel={this.onCloseWarningModal}
            onConfirm={this.onCloseWarningModal}
          />
        )}
        <Prompt when={this.guidNotPresent || this.signersNotPresent} message={this.message} />
        <FeaturesForm
          onSign={sign}
          onSetGuid={(data) => setGuid(data.toJS())}
          featureSet={featureSet.fin}
          onSetFeatureSet={this.onSetFutureSet}
          onAddSign={addSign}
          onSignRemove={remove}
          onGetCopy={getCopy}
          onAllSignsRemove={removeAll}
          signings={signings}
          context="company"
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  signings: companySigningsSelector(state),
  featureSet: companyFeatureSetSelector(state),
  isDisablingAllowed: isDisablingFinAllowedSelector(state),
});

const mapDispatchToProps = {
  sign: updateCompanySignings,
  setFeatureSet: setCompanyFeatureSet,
  addSign: addEmptyCompanySigner,
  remove: removeCompanySigner,
  setGuid: setCompanySigningsGuid,
  getCopy: getCompanySigningsCopy,
  removeAll: removeAllCompanySigner,
};

export default compose(injectIntl, connect(mapStateToProps, mapDispatchToProps))(FeaturesFormContainer);
