import React, { memo, useCallback, useRef, useEffect } from 'react';
import { compose } from 'redux';

import { withStyles } from '@mui/styles';

import elements from 'components/elements';
import Tooltip from 'components/Tooltip';
import BarIcon from 'components/BarIcon';

type TFullscreenButton = {|
  classes: {
    [key: string]: string,
  },
  isWhite: boolean,
|};

const FullscreenButton = ({ classes, isWhite }: TFullscreenButton) => {
  const bodyRef = useRef();

  const openFullscreen = useCallback(() => {
    if (bodyRef.current) {
      const element = bodyRef.current;

      if (element.requestFullscreen) {
        element.requestFullscreen();
      } else if (element.webkitRequestFullscreen) {
        /* Safari */
        element.webkitRequestFullscreen();
      } else if (element.msRequestFullscreen) {
        /* IE11 */
        element.msRequestFullscreen();
      }
    }
  }, []);

  const exitFullscreen = useCallback(() => {
    if (document.exitFullscreen) {
      document.exitFullscreen().then();
    } else if (document.webkitExitFullscreen) {
      /* Safari */
      document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) {
      /* IE11 */
      document.msExitFullscreen();
    }
  }, []);

  const onClick = useCallback(() => {
    const isFullscreen =
      document.fullscreenElement ||
      document.webkitFullscreenElement ||
      document.mozFullScreenElement ||
      document.msFullscreenElement;

    if (isFullscreen) exitFullscreen();
    else openFullscreen();
  }, [exitFullscreen, openFullscreen]);

  useEffect(() => {
    const [el] = document.getElementsByTagName('body');

    bodyRef.current = el;

    return () => {
      bodyRef.current = null;
    };
  }, []);

  return (
    <Tooltip id="header.menu.fullscreen" defaultMessage="Full screen" className={classes.wrapper}>
      <BarIcon icon="fullscreen" action={onClick} data-element={elements.header.fullscreen} white={isWhite} />
    </Tooltip>
  );
};

const sheet = {
  wrapper: {
    display: 'flex',
    alignItems: 'center',
  },
};

export default compose(withStyles(sheet))(memo(FullscreenButton));
