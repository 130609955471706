// @flow
import React from 'react';
import { Link, generatePath } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { isDokkaSupportSelector } from 'domain/env';
import ROUTES_PATH from 'domain/router/routesPathConfig';

import { DrawerStatic, DrawerHeader, DrawerFooter } from 'components/mui/Layouts/components/Drawer';

import Box from '@mui/material/Box';
import List from '@mui/material/List';
import Stack from '@mui/material/Stack';
import { LayoutRoot, LayoutContent } from 'components/mui/Layouts/components/Layout';
import { AppBarStatic, AppBarItemDivider } from 'components/mui/Layouts/components/AppBar';
import SettingsLink from 'components/mui/Layouts/components/Drawer/SettingsLink';
import HelpDropdown from 'components/mui/Layouts/components/HelpDropdown';
import LocaleSelect from 'components/mui/LocaleSelect';
import AppbarLogo from 'components/mui/Layouts/Companies/components/Logo';
import AppbarSearch from 'components/mui/Layouts/Companies/components/Search';
import AccountDropdown from 'components/mui/Layouts/components/AccountDropdown';
import ApplicationDropdown from 'components/mui/Layouts/components/ApplicationDropdown';
import FullscreenIconButton from 'components/mui/Layouts/components/FullscreenIconButton';
import AsideLogo from 'components/mui/Layouts/components/AsideLogo';

type TLayoutCompanies = {
  children: React$Node,
};

const LayoutCompanies: React$StatelessFunctionalComponent<TLayoutCompanies> = ({ children }) => {
  const isGhost = useSelector(isDokkaSupportSelector);
  const to = isGhost ? generatePath(ROUTES_PATH.ORGANIZATIONS.absolute) : generatePath(ROUTES_PATH.COMPANIES.absolute);

  return (
    <LayoutRoot>
      <DrawerStatic variant="permanent">
        <DrawerHeader>
          <Link to={to}>
            <AsideLogo />
          </Link>
        </DrawerHeader>
        <DrawerFooter>
          <List>
            <SettingsLink />
          </List>
        </DrawerFooter>
      </DrawerStatic>

      <AppBarStatic>
        <Box flexShrink={0}>
          <AppbarLogo />
        </Box>
        <Box flexShrink={1} flexGrow={1} px={2} display="flex" justifyContent="center">
          <AppbarSearch />
        </Box>
        <Stack direction="row" alignItems="center" spacing={1} flexShrink={0} ml="auto">
          <FullscreenIconButton />
          <AppBarItemDivider />
          <HelpDropdown />
          <LocaleSelect flagOnly />
          <ApplicationDropdown />
          <AccountDropdown />
        </Stack>
      </AppBarStatic>

      <LayoutContent>
        <DrawerHeader />
        {children}
      </LayoutContent>
    </LayoutRoot>
  );
};

export default LayoutCompanies;
