import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { companyPaidUnpaidFeatureEnabledSelector, companyApprovalsFeatureEnabledSelector } from 'domain/companies';
import {
  documentsChangeTypesAction,
  getDocumentsForBulkPaid,
  getDocumentsForBulkUnpaid,
  getDocumentsForBulkActivateApprovalFlow,
  getDocumentsForBulkDeactivateApprovalFlow,
} from 'domain/documents';
import { changeBulkApprovalStatusAction } from 'domain/approvals/actions';
import { promisify } from 'lib/helpers';
import toast from 'components/Toast';
import * as ACL from 'domain/restriction';
import { useIntl } from 'react-intl';

import type { TDotsMenu } from 'pages/company/CompanyBulkPanel/DotsMenu';

type TOptionValue = string | number | boolean;

type TOption = {
  label: string,
  value: boolean,
  disabled?: boolean,
  action?: (value?: TOptionValue) => void,
  confirm?: {
    isAutoConfirm: boolean,
    title: string,
    placeholder: string,
    confirmText: string,
  },
};

type TMenuOptions = Array<TOption>;

export type TUseDotsMenu = {
  menuOptions: TMenuOptions,
  itemsOptions: {
    [key: string]: {
      disabled: boolean,
      onClick: () => void,
    },
  },
};

const useDotsMenu = ({ clearSelection, selectedIds, itemsOptions }: TDotsMenu) => {
  const { formatMessage } = useIntl();

  const isPainUnpaidEnabled = useSelector(companyPaidUnpaidFeatureEnabledSelector);
  const isApprovalsEnabled = useSelector(companyApprovalsFeatureEnabledSelector);
  const documentsForBulkPaid = useSelector(getDocumentsForBulkPaid(selectedIds));
  const documentsForBulkUnpaid = useSelector(getDocumentsForBulkUnpaid(selectedIds));
  const documentsForBulkActivateApprovalFlow = useSelector(getDocumentsForBulkActivateApprovalFlow(selectedIds));
  const documentsForBulkDeactivateApprovalFlow = useSelector(getDocumentsForBulkDeactivateApprovalFlow(selectedIds));
  const isGranted = useSelector(ACL.isGranted);

  const dispatch = useDispatch();
  const total = selectedIds.size;

  const getID = useCallback((documents) => documents.keySeq().toArray(), []);

  const handleBulkPaidUnpaid = useCallback(
    async (type: 'paid' | 'unpaid') => {
      const documentsIds = type === 'paid' ? getID(documentsForBulkPaid) : getID(documentsForBulkUnpaid);

      const fn = (arg) => dispatch(documentsChangeTypesAction({ ...arg }));
      const { success } = await promisify(fn, { documentsIds, type });
      toast.success(
        formatMessage(
          {
            id: type === 'paid' ? 'toast.bulkPaid' : 'toast.bulkUnpaid',
            defaultMessage: `${success.length} of ${documentsIds.length} were marked as ${type}`,
          },
          {
            success: success.length,
            total: documentsIds.length,
          },
        ),
      );
      clearSelection();
    },
    [clearSelection, getID, documentsForBulkPaid, documentsForBulkUnpaid, dispatch, formatMessage],
  );

  const handleBulkActivateDeactivateApprovalFlow = useCallback(
    async (type: 'activate' | 'deactivate') => {
      const documentID =
        type === 'activate'
          ? getID(documentsForBulkActivateApprovalFlow)
          : getID(documentsForBulkDeactivateApprovalFlow);

      const fn = (arg) => dispatch(changeBulkApprovalStatusAction({ ...arg }));
      const { success } = await promisify(fn, { documentID, status: type === 'activate' ? 'active' : 'draft' });
      toast.success(
        formatMessage(
          {
            id: type === 'activate' ? 'toast.bulkActivateApprovalFlow' : 'toast.bulkDeactivateApprovalFlow',
            defaultMessage: `Approval flow was ${type}d for ${success.length} of ${documentID.length} documents`,
          },
          {
            success: success.length,
            total: documentID.length,
          },
        ),
      );
      clearSelection();
    },
    [
      clearSelection,
      getID,
      documentsForBulkActivateApprovalFlow,
      documentsForBulkDeactivateApprovalFlow,
      dispatch,
      formatMessage,
    ],
  );

  const menuOptions = [
    {
      title: {
        id: 'options.markAsPaid',
        defaultMessage: 'Mark as Paid',
      },
      key: 'markAsPaid',
      value: 'markAsPaid',
      disabled: documentsForBulkPaid.size === 0,
      onClick: () => handleBulkPaidUnpaid('paid'),
      confirmOptions: {
        title: formatMessage({
          id: 'confirm.selectPanel.bulkPaid.title',
          defaultMessage: 'Mark as Paid',
        }),
        description: formatMessage(
          {
            id: 'confirm.selectPanel.bulkPaid.placeholder',
            defaultMessage: `${documentsForBulkPaid.size} of ${total} documents will be marked as Paid.`,
          },
          {
            available: documentsForBulkPaid.size,
            total,
          },
        ),
        confirmationText: formatMessage({
          id: 'confirm.selectPanel.bulkPaid.btn',
          defaultMessage: 'Mark as Paid',
        }),
        isAutoConfirm: false,
      },
      isHidden: !isPainUnpaidEnabled,
    },
    {
      title: {
        id: 'options.markAsUnpaid',
        defaultMessage: 'Mark as Unpaid',
      },
      key: 'markAsUnpaid',
      value: 'markAsUnpaid',
      disabled: documentsForBulkUnpaid.size === 0,
      onClick: () => handleBulkPaidUnpaid('unpaid'),
      confirmOptions: {
        title: formatMessage({
          id: 'confirm.selectPanel.bulkUnpaid.title',
          defaultMessage: 'Mark as Unpaid',
        }),
        description: formatMessage(
          {
            id: 'confirm.selectPanel.bulkUnpaid.placeholder',
            defaultMessage: `${documentsForBulkUnpaid.size} of ${total} documents will be marked as Unpaid.`,
          },
          {
            available: documentsForBulkUnpaid.size,
            total,
          },
        ),
        confirmationText: formatMessage({
          id: 'confirm.selectPanel.bulkUnpaid.btn',
          defaultMessage: 'Mark as Unpaid',
        }),
        isAutoConfirm: false,
      },
      isHidden: !isPainUnpaidEnabled,
    },
    {
      title: {
        id: 'document.show.actions.setBulkApprovalFlow',
        defaultMessage: 'Create an approval flow',
      },
      key: 'setBulkApprovalFlow',
      value: 'setBulkApprovalFlow',
      isHidden: !isApprovalsEnabled || !isGranted(ACL.IS_ACCOUNT),
      divider: true,
      ...itemsOptions['bulkApprovalItemOptions'],
    },
    {
      title: {
        id: 'options.activateApprovalFlow',
        defaultMessage: 'Activate approval flow',
      },
      key: 'activateApprovalFlow',
      value: 'activateApprovalFlow',
      disabled: documentsForBulkActivateApprovalFlow.size === 0,
      onClick: () => handleBulkActivateDeactivateApprovalFlow('activate'),
      confirmOptions: {
        title: formatMessage({
          id: 'confirm.selectPanel.bulkActivateApproval.title',
          defaultMessage: 'Activate Approval Flow?',
        }),
        description: formatMessage(
          {
            id: 'confirm.selectPanel.bulkActivateApproval.placeholder',
            defaultMessage: `Approval flow will be activated to ${documentsForBulkActivateApprovalFlow.size}/${total} documents`,
          },
          {
            available: documentsForBulkActivateApprovalFlow.size,
            total,
          },
        ),
        confirmationText: formatMessage({
          id: 'confirm.selectPanel.bulkActivateApproval.btn',
          defaultMessage: 'Activate',
        }),
        isAutoConfirm: false,
      },
      isHidden: !isApprovalsEnabled || !isGranted(ACL.IS_ACCOUNT),
    },
    {
      title: {
        id: 'options.replaceApprover',
        defaultMessage: 'Replace approver',
      },
      key: 'replaceApprover',
      value: 'replaceApprover',
      isHidden: !isApprovalsEnabled,
      ...itemsOptions.replaceApprover,
    },
    {
      title: {
        id: 'options.deactivateApprovalFlow',
        defaultMessage: 'Deactivate approval flow',
      },
      key: 'deactivateApprovalFlow',
      value: 'deactivateApprovalFlow',
      disabled: documentsForBulkDeactivateApprovalFlow.size === 0,
      onClick: () => handleBulkActivateDeactivateApprovalFlow('deactivate'),
      confirmOptions: {
        title: formatMessage({
          id: 'confirm.selectPanel.bulkDeactivateApproval.title',
          defaultMessage: 'Deactivate Approval Flow?',
        }),
        description: formatMessage(
          {
            id: 'confirm.selectPanel.bulkDeactivateApproval.placeholder',
            defaultMessage: `Approval flow will be deactivated to ${documentsForBulkDeactivateApprovalFlow.size}/${total} documents`,
          },
          {
            available: documentsForBulkDeactivateApprovalFlow.size,
            total,
          },
        ),
        confirmationText: formatMessage({
          id: 'confirm.selectPanel.bulkDeactivateApproval.btn',
          defaultMessage: 'Deactivate',
        }),
      },
      isHidden: !isApprovalsEnabled || !isGranted(ACL.IS_ACCOUNT),
    },
    {
      title: {
        id: 'document.show.actions.moveToCompany',
        defaultMessage: 'Move to other company',
      },
      key: 'moveToCompany',
      value: 'moveToCompany',
      isHidden: !isGranted(ACL.IS_ACCOUNT),
      divider: true,
      ...itemsOptions['moveToItemOptions'],
    },
  ];

  return {
    menuOptions: menuOptions.filter(({ isHidden }) => !isHidden),
  };
};

export default useDotsMenu;
