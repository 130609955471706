/* @flow */
import React from 'react';
import classnames from 'classnames';
import useSheet from './sheet';
import SearchText, { type SearchTextProps } from 'components/SearchText/SearchText';

const Search = (props: SearchTextProps) => {
  const classes = useSheet();
  return (
    <div className={classnames(classes.container)}>
      <SearchText {...props} />
    </div>
  );
};

export default Search;
