// @flow
import * as Bowser from 'bowser';

const browser = Bowser.getParser(window.navigator.userAgent);

const { name: browserName } = browser.getBrowser();
const isSupportedVersion = browser.satisfies({
  chrome: `>=${process.env.REACT_APP_CHROME_SUPPORTED_VERSION}`,
  // think if we want to ask user to update edge
  // edge: `>=${process.env.REACT_APP_EDGE_SUPPORTED_VERSION}`,
});
// browser name aliases can be found here
// @see https://github.com/lancedikson/bowser/blob/master/src/constants.js
const isValidVersion = browser.satisfies({
  chrome: `>${process.env.REACT_APP_CHROME_VALID_VERSION}`,
  edge: `>${process.env.REACT_APP_EDGE_VALID_VERSION}`,
});

const isChromium = browserName === 'Chromium';
const isChrome = browserName === 'Chrome';
const isSafari = browserName === 'Safari';
const isIE = browserName === 'Internet Explorer';
const isEdge = browserName === 'Microsoft Edge';
const isFirefox = browserName === 'Firefox';
const isOpera = browserName === 'Opera';
const isMobile = browser.getPlatformType(true) === 'mobile';
const isIOs = !!window.navigator.platform && /(Mac|iPhone|iPod|iPad)/.test(window.navigator.platform);
const isIPad = !!window.navigator.platform && /iPad/.test(window.navigator.platform);

const isValidBrowser = isFirefox || isChromium || ((isChrome || isEdge) && isValidVersion);

export {
  browserName,
  isSupportedVersion,
  isValidVersion,
  isChrome,
  isSafari,
  isIE,
  isEdge,
  isFirefox,
  isOpera,
  isValidBrowser,
  isMobile,
  isIOs,
  isIPad,
};
