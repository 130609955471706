// @flow
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { ReactComponent as JeOnly } from 'pages/document/components/DocumentViewDropdown/components/ViewIcons/jeOnly.svg';
import { ReactComponent as DocOnly } from 'pages/document/components/DocumentViewDropdown/components/ViewIcons/docOnly.svg';
import { ReactComponent as DocAndJe } from 'pages/document/components/DocumentViewDropdown/components/ViewIcons/docAndJe.svg';
import type { TViewArrangement } from 'pages/document/types.js.flow';

export const IconLtrView = () => <SvgIcon component={DocAndJe} inheritViewBox />;
export const IconRtlView = () => <SvgIcon component={DocAndJe} inheritViewBox sx={{ transform: 'rotate(180deg)' }} />;
export const IconTtbView = () => <SvgIcon component={DocAndJe} inheritViewBox sx={{ transform: 'rotate(90deg)' }} />;
export const IconBttView = () => <SvgIcon component={DocAndJe} inheritViewBox sx={{ transform: 'rotate(270deg)' }} />;
export const IconJeView = () => <SvgIcon component={JeOnly} inheritViewBox />;
export const IconDocView = () => <SvgIcon component={DocOnly} inheritViewBox />;

export const VIEW_ICONS = {
  ltr: IconLtrView,
  rtl: IconRtlView,
  ttb: IconTtbView,
  btt: IconBttView,
  jeOnly: IconJeView,
  docOnly: IconDocView,
};

type TIconViewByID = {|
  id: TViewArrangement,
|};
export const IconViewByID: React$StatelessFunctionalComponent<TIconViewByID> = ({ id }) => {
  const Icon = VIEW_ICONS[id];

  return <Icon />;
};
