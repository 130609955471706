import React, { useState, useMemo, useCallback } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import {
  deleteApprovalsGroupAction,
  updateApprovalsGroupAction,
  createApprovalsGroupAction,
} from 'domain/approvals/actions';
import { approvalGroupListSelector } from 'domain/approvals/selectors';
import { chatParticipantsSelector } from 'domain/chat/chatSelector';
import { companyFeatureSetSelector } from 'domain/companies/companiesSelector';

import ControlsCell from './components/ControlsCell';
import ApprovalsCell from './components/ApprovalsCell';
import ApprovalGroup from './ApprovalGroup';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import { useConfirm } from 'material-ui-confirm';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import FeatureDisabledMessage from 'pages/configurations/components/FeatureDisabledMessage';

import { HeadersAdapter, RowsAdapter, ColumnTypes, createRow, createCellStyle, Fields } from './helpers';
import elements from 'components/elements';
import { update } from 'lodash/fp';
import { useIntl } from 'react-intl';

import bg from './assets/bg.png';

const BgImg = styled('img')(({ theme }) => ({ width: 280, height: 254, marginBottom: theme.spacing(6) }));

const STable = styled(Table)(() => ({
  borderCollapse: 'separate',
  borderSpacing: '0px 8px',
}));
const STableRow = styled(TableRow)(({ theme }) => ({
  backgroundColor: theme.palette.grey[100],
}));
const STableHeaderCell = styled(TableCell)(({ theme }) => ({ border: 'none', padding: theme.spacing(1.5, 0, 0, 3) }));
const STableCell = styled(TableCell)(({ theme }) => {
  const radius = 8;
  const color = theme.palette.grey[300];
  return {
    borderBottom: `1px solid ${color}`,
    borderTop: `1px solid ${color}`,
    padding: theme.spacing(1.5, 0, 1.5, 3),
    '&:first-of-type': {
      border: `1px solid ${color}`,
      borderRight: 'none',
      borderLeftStyle: 'solid',
      borderTopLeftRadius: radius,
      borderBottomLeftRadius: radius,
    },
    '&:last-of-type': {
      border: `1px solid ${color}`,
      borderLeft: 'none',
      borderRightStyle: 'solid',
      borderTopRightRadius: radius,
      borderBottomRightRadius: radius,
    },
  };
});

const mapStateToProps = (state) => ({
  approvalGroups: approvalGroupListSelector(state),
  users: chatParticipantsSelector(state),
  featureSet: companyFeatureSetSelector(state),
});

const Approvals = () => {
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [isGroupModalOpen, setIsGroupModalOpen] = useState(false);

  const dispatch = useDispatch();
  const confirm = useConfirm();
  const { formatMessage } = useIntl();

  const { approvalGroups, users, featureSet } = useSelector(mapStateToProps);

  const handleDeleteGroup = useCallback(
    (groupId) => {
      dispatch(deleteApprovalsGroupAction({ groupId }));
    },
    [dispatch],
  );

  const handleEditGroup = useCallback((group) => {
    setSelectedGroup(group);
    setIsGroupModalOpen(true);
  }, []);

  const onDeleteGroupClick = useCallback(
    (group) => {
      confirm({
        title: formatMessage(
          {
            id: 'configurations.company.approval.deleteModal.title',
            defaultMessage: `Remove ${group.group_title}?`,
          },
          { groupName: group.group_title },
        ),
        description: formatMessage(
          {
            id: 'configurations.company.approval.deleteModal.body',
            defaultMessage: `${group.group_title} will be removed. It will not affect existing approval flows`,
          },
          { groupName: group.group_title },
        ),
        confirmationText: formatMessage(
          {
            id: 'configurations.company.approval.deleteModal.confirmButton',
            defaultMessage: 'Remove',
          },
          { groupName: group.group_title },
        ),
        confirmationButtonProps: { color: 'error' },
        cancellationText: formatMessage({
          id: 'configurations.company.approval.deleteModal.cancelButton',
          defaultMessage: 'Cancel',
        }),
      }).then(() => {
        handleDeleteGroup(group.group_id);
      });
    },
    [confirm, formatMessage, handleDeleteGroup],
  );

  const handleClose = useCallback(() => {
    setSelectedGroup(null);
    setIsGroupModalOpen(false);
  }, []);

  const onSubmit = useCallback(
    async (values) =>
      new Promise((resolve, reject) => {
        const payload = {
          resolve,
          reject,
          ...update([Fields.approvers], (a) => a.map((i) => i.id))(values),
          ...(selectedGroup?.group_id && { groupId: selectedGroup.group_id }),
        };
        const action = selectedGroup ? updateApprovalsGroupAction : createApprovalsGroupAction;
        dispatch(action(payload));
      }).then(() => {
        handleClose();
      }),
    [dispatch, handleClose, selectedGroup],
  );

  const onNewGroupClick = useCallback(() => {
    setIsGroupModalOpen(true);
  }, []);

  const disableEdit = users.size < 1;

  const rowData = useMemo(() => RowsAdapter(approvalGroups, users), [approvalGroups, users]);
  const columnDefs = useMemo(
    () => HeadersAdapter({ formatMessage, handleDeleteGroup: onDeleteGroupClick, handleEditGroup, disableEdit }),
    [formatMessage, onDeleteGroupClick, handleEditGroup, disableEdit],
  );

  const frameworkComponents = useMemo(
    () => ({
      [ColumnTypes.controls]: ControlsCell,
      [ColumnTypes.approvals]: ApprovalsCell,
    }),
    [],
  );

  return featureSet.get('approvals', false) ? (
    <>
      {rowData?.length ? (
        <>
          <Box display="flex" mb={2}>
            <Typography variant="subtitle1">
              {formatMessage(
                {
                  id: 'configurations.company.approvals.title',
                  defaultMessage: `Approval groups (${rowData?.length})`,
                },
                { count: rowData?.length },
              )}
            </Typography>
            <Button
              type="button"
              variant="outlined"
              startIcon={<AddOutlinedIcon />}
              onClick={onNewGroupClick}
              sx={{ ml: 'auto' }}
              data-element={elements.configuration.company.approvalGroups.createGroupButton}
            >
              {formatMessage({ id: 'configurations.company.createGroupBtn', defaultMessage: 'Add groups' })}
            </Button>
          </Box>
          <Box component={Paper} p={2} data-element={elements.configuration.company.approvalGroups.groupList}>
            <TableContainer>
              <STable>
                <TableHead>
                  <TableRow>
                    {columnDefs.map((header) => (
                      <STableHeaderCell key={header.field} style={createCellStyle(header)}>
                        {header.headerName}
                      </STableHeaderCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rowData.map((r) => (
                    <STableRow
                      key={r.group_id}
                      data-element={elements.configuration.company.approvalGroups.groupTile.container}
                    >
                      {createRow({
                        rowData: r,
                        columnDefs,
                        renderCell: ({ header, value, key }) => (
                          <STableCell
                            key={key}
                            style={createCellStyle(header)}
                            data-element={`${elements.configuration.company.approvalGroups.groupTile.container}.${key}`}
                          >
                            {frameworkComponents[header.cellRenderer]?.({ ...header.cellRendererParams, value }) ||
                              value}
                          </STableCell>
                        ),
                      })}
                    </STableRow>
                  ))}
                </TableBody>
              </STable>
            </TableContainer>
          </Box>
        </>
      ) : (
        <Stack justifyContent="center" alignItems="center" flex="1">
          <BgImg src={bg} />
          <Typography variant="h5" data-element={elements.configuration.company.approvalGroups.noGroupsTitle}>
            {formatMessage({
              id: 'configurations.company.approvals.empty_list.title',
              defaultMessage: 'No Groups yet',
            })}
          </Typography>
          <Typography
            variant="body1"
            sx={{ mb: 3.25 }}
            data-element={elements.configuration.company.approvalGroups.noGroupsDescription}
          >
            {formatMessage({
              id: 'configurations.company.approvals.emptyListText',
              defaultMessage:
                'Approval groups allow you to quick setup approval flow with the defined lists of approvers',
            })}
          </Typography>
          <Button
            onClick={onNewGroupClick}
            data-element={elements.configuration.company.approvalGroups.createGroupButton}
          >
            {formatMessage({ id: 'configurations.company.createGroupBtn', defaultMessage: 'Add groups' })}
          </Button>
        </Stack>
      )}
      {isGroupModalOpen && (
        <ApprovalGroup
          open={isGroupModalOpen}
          title={
            selectedGroup
              ? formatMessage({
                  id: 'configurations.company.approvalGroup.modal.title.edit',
                  defaultMessage: 'Edit group',
                })
              : formatMessage({
                  id: 'configurations.company.approvalGroup.modal.title.create',
                  defaultMessage: 'Create group',
                })
          }
          handleClose={handleClose}
          initialValues={selectedGroup || {}}
          onSubmit={onSubmit}
        />
      )}
    </>
  ) : (
    <FeatureDisabledMessage />
  );
};

export default Approvals;
