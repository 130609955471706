// @flow
import * as React from 'react';
import { withStyles } from '@mui/styles';
import cx from 'classnames';
import sheet from './sheet';

export type Props = {
  classes: {
    [key: string]: string,
  },
  // eslint-disable-next-line react/no-unused-prop-types
  size?: number, // used in styles
  src?: string,
  className?: string,
  // eslint-disable-next-line react/no-unused-prop-types
  isHighlite?: boolean, // used in styles
  disableBorder?: boolean,
};

// TODO: MUI - check and delete
class UserPic extends React.Component<Props> {
  render() {
    const { classes, className, src, disableBorder = false } = this.props;
    return (
      <div
        className={cx(
          classes.picBox,
          {
            [classes.picBoxBorder]: !disableBorder && !src,
          },
          className,
        )}
      >
        <div
          className={cx(classes.pic, {
            [classes.srcPic]: !!src,
            [classes.defaultPick]: !src,
          })}
        />
      </div>
    );
  }
}

export default withStyles(sheet)(UserPic);
