// @flow
import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import TextFieldBase from 'components/mui/Form/TextField/TextFieldBase';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';

import useStyles from './sheet';

type Props = {
  name: string,
  accept: string,
  src?: string,
  disabled: boolean,
  input: {
    value: string,
    onChange: (event: KeyboardEvent, newValue: string, oldValue: string, name: string) => void,
    name: string,
  },
};

const UploadImg: React$StatelessFunctionalComponent<Props> = ({ src = null, disabled, name, accept, input }) => {
  const classes = useStyles();

  const onChangeInput = (event: Event) => {
    event.preventDefault();
    const file = event.target.files[0];
    if (file) {
      input.onChange(file);
    }
  };

  return (
    <>
      <TextFieldBase
        id="fileInput"
        type="file"
        multiple={false}
        disabled={disabled}
        name={name}
        inputProps={{ accept }}
        onChange={onChangeInput}
        sx={{ display: 'none' }}
      />
      <label htmlFor="fileInput" style={{ width: '100%', height: '100%' }}>
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          width="100%"
          height="100%"
          sx={{ backgroundColor: (theme) => (src ? '#FFF' : theme.palette.grey[300]) }}
          borderRadius={1}
          border="1px solid #E9E8EA"
          className={classes.logoContainer}
        >
          {src ? (
            <img src={src} style={{ maxWidth: '100%', maxHeight: '100%' }} alt="logo" />
          ) : (
            <Typography variant="h4" color="#FFF">
              LOGO
            </Typography>
          )}
          <AddPhotoAlternateIcon className={classes.icon} />
        </Grid>
      </label>
    </>
  );
};

export default UploadImg;
