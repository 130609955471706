// @flow
import React from 'react';

// jss
import { withStyles } from '@mui/styles';
import sheet from './sheet';

type Props = {|
  classes: typeof sheet,
  date: ?string,
  description: ?string,
|};

const TransactionDetails: React$StatelessFunctionalComponent<Props> = ({ classes, date, description }: Props) => (
  <section className={classes.container}>
    <div className={classes.left}>
      <div className={classes.date}>{date}</div>
      <div className={classes.description}>{description}</div>
    </div>
  </section>
);

export default withStyles(sheet)(TransactionDetails);
