import MuiDrawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';

import ChevronLeftOutlinedIcon from '@mui/icons-material/ChevronLeftOutlined';

import { styled } from '@mui/material/styles';
import ListItemIconMui from '@mui/material/ListItemIcon';

export const DRAWER_STATIC_WIDTH = 64;
export const DRAWER_MINI_WIDTH = 88;
export const DRAWER_EXPANDED_WIDTH = 260;

const openedMixin = () => ({
  width: DRAWER_EXPANDED_WIDTH,
  overflowX: 'hidden',
  // transition: theme.transitions.create('width', {
  //   easing: theme.transitions.easing.sharp,
  //   duration: theme.transitions.duration.enteringScreen,
  // }),
});

const closedMixin = () => ({
  width: DRAWER_MINI_WIDTH,
  overflowX: 'hidden',
  // transition: theme.transitions.create('width', {
  //   easing: theme.transitions.easing.sharp,
  //   duration: theme.transitions.duration.leavingScreen,
  // }),
});

const drawerPaperMixin = (theme) => ({
  backgroundColor: theme.palette.primary.dark,
  color: theme.palette.primary.contrastText,
  borderRight: 'none',
});

export const DrawerStatic = styled(MuiDrawer)(({ theme }) => ({
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  overflowX: 'hidden',
  width: DRAWER_STATIC_WIDTH,

  '& .MuiDrawer-paper': {
    width: DRAWER_STATIC_WIDTH,
    ...drawerPaperMixin(theme),
  },
}));

export const DrawerExpanded = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',

    ...(open && {
      ...openedMixin(theme),

      '& .MuiDrawer-paper': {
        ...openedMixin(theme),
        ...drawerPaperMixin(theme),
      },
    }),
    ...(!open && {
      ...closedMixin(theme),

      '& .MuiDrawer-paper': {
        ...closedMixin(theme),
        ...drawerPaperMixin(theme),
      },
    }),
  }),
);

export const DrawerToggle = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: theme.mixins.toolbar.minHeight,
  left: 0,
  transform: 'translate(-50%, -50%)',
  backgroundColor: theme.palette.background.paper,
  border: `1px solid ${theme.palette.grey[300]}`,
  boxShadow: theme.shadows[3],

  '&:hover': {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[0],
  },
}));

export const DrawerToggleIcon = styled(ChevronLeftOutlinedIcon, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    transform: theme.direction === 'rtl' ? `rotate(${open ? 180 : 0}deg)` : `rotate(${open ? 0 : 180}deg)`,

    // transition: theme.transitions.create('transform', {
    //   easing: theme.transitions.easing.sharp,
    //   duration: open ? theme.transitions.duration.leavingScreen : theme.transitions.duration.enteringScreen,
    // }),
  }),
);

export const DrawerHeader = styled('div', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: open ? 'start' : 'center',
  // necessary for content to be below app bar
  minHeight: theme.mixins.toolbar.minHeight + 1, // +1 <AppBar> borderBottom
}));

export const DrawerContent = styled('div')(() => ({
  overflowY: 'auto',
}));

export const DrawerFooter = styled('div')(() => ({
  marginTop: 'auto',
}));

export const ListItemIcon = styled(ListItemIconMui, {
  shouldForwardProp: (propName) => !['minWidth'].includes(propName),
})(({ theme, minWidth }) => ({
  minWidth: minWidth || 0,
  marginRight: theme.spacing(1),
}));
