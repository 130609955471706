// type @flow
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useIntl, FormattedMessage } from 'react-intl';
import { generatePath, Outlet, useParams } from 'react-router-dom';
import { loadingSelector } from 'domain/env/envSelector';
import ROUTES_PATH from 'domain/router/routesPathConfig';

import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import LayoutOrganizationSettings from 'components/mui/Layouts/OrganizationSettings';
import CircularProgressWithBackdrop from 'components/mui/CircularProgressWithBackdrop';
import StickySubheader from 'components/mui/Layouts/components/StickySubheader';
import Tabs from 'components/Tabs';

const links = [
  {
    name: 'info',
    id: 'configurations.sidebar.info',
    defaultMessage: 'General Info',
    to: generatePath(ROUTES_PATH.ORGANIZATION_SETTINGS_GENERAL_MAIN.absolute),
    disabled: false,
    tabValue: 'general',
  },
  {
    name: 'connections',
    id: 'configurations.sidebar.connections',
    defaultMessage: 'Users',
    to: generatePath(ROUTES_PATH.ORGANIZATION_SETTINGS_USERS.absolute),
    disabled: false,
    tabValue: 'users',
  },
  {
    name: 'document_backup',
    id: 'configurations.sidebar.backups',
    defaultMessage: 'Cloud Backup',
    to: generatePath(ROUTES_PATH.ORGANIZATION_SETTINGS_BACKUPS.absolute),
    disabled: false,
    tabValue: 'backups',
    lockedModalTitle: () => (
      <FormattedMessage
        id="lockedFunctionality.backup.modal.title"
        defaultMessage="“Cloud Backup” extension disabled."
      />
    ),
    lockedModalMessage: () => null,
  },
  {
    name: 'security',
    id: 'configurations.sidebar.security',
    defaultMessage: 'Security',
    to: generatePath(ROUTES_PATH.ORGANIZATION_SETTINGS_SECURITY.absolute),
    disabled: false,
    tabValue: 'security',
  },
  {
    name: 'subscription',
    id: 'configurations.sidebar.subscription',
    defaultMessage: 'Subscription',
    to: generatePath(ROUTES_PATH.ORGANIZATION_SETTINGS_BILLINGS.absolute),
    disabled: true,
    tabValue: 'subscription',
  },
  {
    name: 'keys',
    id: 'configurations.sidebar.apiKeys',
    defaultMessage: 'API Keys',
    to: generatePath(ROUTES_PATH.ORGANIZATION_SETTINGS_API_KEYS.absolute),
    disabled: false,
    tabValue: 'keys',
  },
];

const OrganizationSettings = () => {
  const isBusy = useSelector(loadingSelector);
  const { formatMessage } = useIntl();
  const [tab] = useParams()['*'].split('/'); // cant found better solution

  // @ATTENTION locked logic wasnt reworked
  const tabs = useMemo(() => links.map((link: string) => ({ ...link, isLocked: false })), []);

  return (
    <LayoutOrganizationSettings>
      <CircularProgressWithBackdrop isOpen={isBusy} />
      <StickySubheader>
        <Typography variant="h6">
          {formatMessage({ id: 'configurations.page.title', defaultMessage: 'Settings' })}
        </Typography>
      </StickySubheader>
      <Container maxWidth={false}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          {/* TODO: this sidebar have extra logic with isLocked param, pls review behaviour */}
          {/* <Sidebar links={tabs} /> */}
          <Tabs value={tab} tabs={tabs} />
        </Box>
      </Container>

      <Container maxWidth={false} sx={{ flexGrow: 1, py: 2, display: 'flex', flexDirection: 'column' }}>
        <Outlet />
      </Container>
    </LayoutOrganizationSettings>
  );
};

export default OrganizationSettings;
