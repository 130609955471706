// @flow
import React from 'react';

// mui
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import ListSubheader from '@mui/material/ListSubheader';

type TOption = {
  value: string | number,
  label: string,
};
export const renderOption = ({ value, label }: TOption) => (
  <MenuItem key={`${value}-${label}`} value={value}>
    {label}
  </MenuItem>
);

export const renderDivider = () => (
  <ListSubheader key="divider">
    <Divider />
  </ListSubheader>
);
