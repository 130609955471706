// @flow
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { documentUpdateLinkAction, isLinkedDocsLockedSelector } from 'domain/documents';

import Box from '@mui/material/Box';
import TextFieldBase from 'components/mui/Form/TextField/TextFieldBase';
import InputLabel from '@mui/material/InputLabel';
import { useIntl } from 'react-intl';

type TLinkedNote = {
  note: string,
  linkID: string,
};

const LinkedNote: React$StatelessFunctionalComponent<TLinkedNote> = ({ note, linkID }) => {
  const dispatch = useDispatch();
  const isLocked = useSelector(isLinkedDocsLockedSelector);
  const { formatMessage } = useIntl();
  const [value, setValue] = useState(note);

  const onChange = (e) => {
    setValue(e.target.value);
  };

  const onBlur = () => {
    if (note !== value) {
      dispatch(documentUpdateLinkAction({ text: value, linkID }));
    }
  };

  useEffect(() => {
    setValue(note);
  }, [note]);

  return (
    <Box mb={2}>
      <InputLabel htmlFor="linkedNoteField" sx={{ mb: 1, color: 'text.primary' }}>
        {formatMessage({
          id: 'documents.linkedPanel.note.label',
          defaultMessage: 'Notes',
        })}
      </InputLabel>
      <TextFieldBase
        id="linkedNoteField"
        multiline
        fullWidth
        variant="filled"
        rows={4}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        disabled={isLocked}
        placeholder={formatMessage({
          id: 'documents.linkedPanel.note.placeholder',
          defaultMessage: 'Type your note here',
        })}
        InputProps={{
          sx: {
            p: 1.5,
            backgroundColor: 'grey.100',
            borderWidth: 1,
            borderStyle: 'solid',
            borderColor: 'grey.200',
          },
        }}
      />
    </Box>
  );
};

export default LinkedNote;
