import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { currentCompanyDateFormatSelector, shouldHighlightAmountSelector } from 'domain/companies/companiesSelector';
import moment from 'moment';

import { compose } from 'redux';
import { convertToFloatNumber, splitNumberByCommas } from 'lib/helpers';

export const SYMBOL_FOR_EMPTY_CELL = '–';

const CELL_STYLES = {
  display: 'flex',
  alignItems: 'center',
  padding: '0px',
  maxWidth: '100%',
};

const formatNumberToFloat = (value) => compose(splitNumberByCommas, convertToFloatNumber)(value);

const valueFormatterNumber = ({ value }) =>
  typeof value === 'number' ? formatNumberToFloat(value) : SYMBOL_FOR_EMPTY_CELL;

const valueFormatterAmount =
  (shouldHighlightAmount) =>
  ({ value }) => {
    if (typeof value === 'number') {
      const isHighlightNegative = shouldHighlightAmount && value < 0;
      const formattedString = formatNumberToFloat(isHighlightNegative ? Math.abs(value) : value);

      return isHighlightNegative ? `(${formattedString})` : formattedString;
    }

    // b-end return zero like string '0.0' - handle this case
    if (typeof value === 'string' && value.length > 0) {
      return formatNumberToFloat(Number(value));
    }

    return SYMBOL_FOR_EMPTY_CELL;
  };

const useAgGridColumnTypes = () => {
  const companyDateFormat = useSelector(currentCompanyDateFormatSelector);
  const shouldHighlightAmount = useSelector(shouldHighlightAmountSelector);
  const dateFormat = companyDateFormat || 'DD/MM/YYYY';

  // apply filter params in addFilters method - here only if you need some data from other places (redux etc)
  return useMemo(
    () => ({
      text: {},
      date: {
        filterParams: {
          format: dateFormat,
        },
        valueFormatter: ({ value }) => (value ? moment(value).format(dateFormat) : SYMBOL_FOR_EMPTY_CELL),
      },
      number: {
        cellStyle: { textAlign: 'right' },
        valueFormatter: valueFormatterNumber,
      },
      string: {},
      boolean: { cellRenderer: 'agCheckboxCellRenderer' },
      actions: {
        width: 0,
      },
      integer: {
        valueFormatter: ({ value }) => (typeof value === 'number' ? parseInt(value, 10) : SYMBOL_FOR_EMPTY_CELL),
      },
      select: {},
      extraSelect: {
        cellRenderer: 'alertCellRenderer',
        cellRendererParams: {
          needContainerForDetectOverflow: true,
        },
        valueFormatter: ({ value }: { value: string[] }) =>
          Array.isArray(value) && !!value.length ? value.join(';') : SYMBOL_FOR_EMPTY_CELL,
      },
      static_select: {},
      amount: {
        cellRenderer: 'amountCellRenderer',
        cellClass: 'amount',
        cellStyle: { textAlign: 'right' },
        valueFormatter: valueFormatterAmount(shouldHighlightAmount),
      },
      tag: {
        cellRenderer: 'tagsCellRenderer',
        cellStyle: {
          ...CELL_STYLES,
        },
        maxWidth: 1200,
        minWidth: 100,
      },
      tag_set: {
        cellRenderer: 'tagsCellRenderer',
        cellStyle: {
          ...CELL_STYLES,
        },
        maxWidth: 1200,
        minWidth: 100,
      },
      approval: {
        cellRenderer: 'approvalsCellRenderer',
        cellStyle: {
          ...CELL_STYLES,
        },
        maxWidth: 1000,
        minWidth: 100,
      },
      approval_set: {
        cellRenderer: 'approvalsCellRenderer',
        cellStyle: {
          ...CELL_STYLES,
        },
        maxWidth: 1000,
        minWidth: 100,
      },
    }),
    [shouldHighlightAmount, dateFormat],
  );
};

export default useAgGridColumnTypes;
