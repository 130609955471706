// @flow
import React, { memo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';

import FTPConnectionModal from 'components/ERPSettings/FTPConnection/FTPConnectionModal';

const FTPConnection: React$StatelessFunctionalComponent<TFTPConnection> = ({ label, value }) => {
  const [isOpenModal, setIsOpenModal] = useState(false);

  const onClick = () => setIsOpenModal(true);
  const onClose = () => setIsOpenModal(false);

  return (
    <Stack spacing={0.5} direction="row" alignItems="center">
      <Typography variant="body2">{label}</Typography>
      {!!value && <CheckCircleRoundedIcon color="success" />}
      <Button sx={{ py: 0 }} variant="text" onClick={onClick}>
        <FormattedMessage id="button.connect" defaultMessage="Connect" />
      </Button>
      {isOpenModal && <FTPConnectionModal open={isOpenModal} onClose={onClose} />}
    </Stack>
  );
};

export default memo(FTPConnection);
