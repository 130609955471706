// @flow
import React from 'react';
import { useDispatch } from 'react-redux';
import ROUTES_PATH from 'domain/router/routesPathConfig';
import { generatePath } from 'react-router-dom';
import { unsetCurrentOrganization } from 'domain/organization';
import { FormattedMessage } from 'react-intl';

import { GhostTabsStyled, GhostTabStyled } from 'components/mui/Layouts/components/GhostTabs/StyledComponents';
import RouterNavLink from 'components/mui/Router/RouterNavLink';

const GhostTabs = () => {
  const dispatch = useDispatch();
  const onClickCompanies = () => dispatch(unsetCurrentOrganization());

  return (
    <GhostTabsStyled>
      <GhostTabStyled
        activeClassName="tab-active"
        exact
        component={RouterNavLink}
        underline="none"
        href={generatePath(ROUTES_PATH.ORGANIZATIONS.absolute)}
      >
        <FormattedMessage id="companies.organizationSwitcher.organizations" />
      </GhostTabStyled>

      <GhostTabStyled
        activeClassName="tab-active"
        exact
        component={RouterNavLink}
        underline="none"
        href={generatePath(ROUTES_PATH.COMPANIES.absolute)}
        onClick={onClickCompanies}
      >
        <FormattedMessage id="companies.organizationSwitcher.companies" />
      </GhostTabStyled>
    </GhostTabsStyled>
  );
};

export default GhostTabs;
