// @flow
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { updatePasswordAction, loadingSelector, resetPasswordErrorSelector } from 'domain/env';
import ValidationPrompt from 'components/PasswordValidationPrompt';
import usePasswordValidation from 'components/PasswordValidationPrompt/usePasswordValidation';
import Spinner from 'components/Spinner';
import AuthLayout from 'pages/authPage/Layout';
import AuthPaperBox from 'pages/authPage/Layout/PaperBox';
import FormVersionBuild from 'pages/authPage/Layout/BuildVersion';
import TextFieldBase from 'components/mui/Form/TextField/TextFieldBase';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

type TResetPasswordPage = {};
const mapStateToProps = (state) => ({
  loading: loadingSelector(state),
  invalidResetToken: resetPasswordErrorSelector(state),
});

const SetPasswordPage: React$StatelessFunctionalComponent<TResetPasswordPage> = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const { loading, invalidResetToken } = useSelector(mapStateToProps);
  const { recoveryToken } = useParams();
  const { passwordValues, isValid, validationRules, handleChange } = usePasswordValidation();

  const onSubmit = (event) => {
    event.preventDefault();
    dispatch(
      updatePasswordAction({
        token: recoveryToken,
        password: passwordValues.password,
      }),
    );
  };

  return (
    <AuthLayout>
      {invalidResetToken === false && (
        <AuthPaperBox>
          <Typography variant="h5">
            <FormattedMessage id="setpasswd.title" defaultMessage="Set Password" />
          </Typography>
          <Typography variant="subtitle2" color="text.secondary" fontWeight="400" mb={3}>
            <FormattedMessage id="reset.subtitle" defaultMessage="Enter a new password" />
          </Typography>
          <form onSubmit={onSubmit}>
            <TextFieldBase
              id="password"
              type="password"
              name="password"
              value={passwordValues.password}
              label={intl.formatMessage({
                id: 'reset.password.placeholder',
                defaultMessage: 'New Password',
              })}
              onChange={handleChange}
              fullWidth
              required
              autoFocus
              margin="dense"
            />
            <ValidationPrompt validation={validationRules} />
            <TextFieldBase
              id="passwordConfirmation"
              type="password"
              name="passwordConfirmation"
              value={passwordValues.passwordConfirmation}
              label={intl.formatMessage({
                id: 'reset.passwordConfirmation.placeholder',
                defaultMessage: 'Confirm New Password',
              })}
              onChange={handleChange}
              fullWidth
              required
              margin="dense"
            />
            <Button type="submit" variant="contained" disabled={!isValid} fullWidth size="large" sx={{ mt: 2 }}>
              <FormattedMessage id="reset.submit" defaultMessage="Save Password" />
            </Button>
          </form>
        </AuthPaperBox>
      )}
      <Spinner loading={loading} />
      <FormVersionBuild />
    </AuthLayout>
  );
};

export default SetPasswordPage;
