// @flow
// imutable
import { Record, type RecordFactory, type RecordOf } from 'immutable';

const MAX_FILE_SIZE = 64 * 1024 * 1024; // 64MB in bytes

export function checkFiles(dict: string[], current: string) {
  return dict.includes(current);
}

export function checkFileSize(fileSize: number) {
  return fileSize <= MAX_FILE_SIZE;
}

export type SelectedCategoryLabel = {|
  id: string,
  defaultMessage: string,
|};

export type SelectedCategory = {|
  label: RecordOf<SelectedCategoryLabel>,
  value: ?number,
|};

export const SelectedCategoryLabelFactory: RecordFactory<SelectedCategoryLabel> = new Record({
  id: '',
  defaultMessage: '',
});

export const SelectedCategoryFactory: RecordFactory<SelectedCategory> = new Record({
  label: new SelectedCategoryLabelFactory(),
  value: null,
});

type SelectedCategoryAdapterType = {|
  label: SelectedCategoryLabel,
  ...$Rest<SelectedCategory, {| label: RecordOf<SelectedCategoryLabel> |}>,
|};

export const selectedCategoryAdapter = ({ label, ...rest }: SelectedCategoryAdapterType) =>
  SelectedCategoryFactory({
    label: SelectedCategoryLabelFactory(label),
    ...rest,
  });
