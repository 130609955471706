/* @flow */
import type { ThemesParamsType } from 'styles/dokkaTheme/types.js.flow';
import arrow from './arrow-up.svg';

export default (theme: ThemesParamsType) => ({
  messages: {
    // marginBottom: 10,
  },
  create: {
    marginTop: 10,
  },
  anonymous: {
    display: 'flex',
    alignItems: 'flex-end',
    '& $textWrapper': {
      flex: [1, 1, '100%'],
      minWidth: 0,
    },
  },
  title: {
    color: theme.colors.primaryLight,
    fontSize: 16,
    fontWeight: 'bold',
    marginBottom: 10,
  },
  messageTitle: {
    whiteSpace: 'nowrap',
    '& b': {
      fontWeight: 600,
    },
  },
  textWrapper: {
    border: '2px solid #DEE2E6',
    boxShadow: '0px 8px 16px rgba(113, 113, 113, 0.1)',
    borderRadius: 4,
    fontSize: 0,
    lineHeight: 0,
  },
  text: {
    width: '100%',
    height: 90,
    resize: 'none',
    padding: [3, 11],
    lineHeight: 1.5,
    color: '#171A1C',
    fontSize: 13,
    border: '0 none',
    margin: 0,
    '@media (hover: none)': {
      fontSize: 16,
    },
  },
  btnGroup: {
    display: 'flex',
    marginTop: 24,
    justifyContent: 'flex-end',
  },
  btn: {
    ...theme.button,
    backgroundColor: theme.colors.secondaryDark,
    width: 32,
    height: 32,
    borderRadius: '50%',
    position: 'relative',
    minHeight: 'auto',
    marginLeft: 15,
    flex: [0, 0, '32px'],
    '&:after': {
      content: '""',
      display: 'inline-block',
      background: `url("${arrow}") scroll no-repeat`,
      height: 14,
      width: 10,
      position: 'absolute',
      margin: 'auto',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
    },
  },
  replyto: {
    display: 'flex',
    fontSize: 12,
    lineHeight: 1.5,
    color: '#171A1C',
    padding: [8, 11],
  },
  recipient: {
    color: theme.colors.primaryLight,
    marginLeft: 10,
  },
  container: {
    flex: 1,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: 30,
  },
  bookkeeperMessagingContainer: {
    flex: 1,
    height: 'calc(100% - 30px)',
    display: 'flex',
    flexDirection: 'column',
  },
  innerListClassName: {
    // maxHeight: 'none',
    maxHeight: '100%',
    margin: 0,
    border: '1px solid #D3D6D9',
    borderRadius: 4,
  },
  emailTemplate: {
    height: '100%',
    '&>div, & $textWrapper, & $text': {
      height: '100%',
      backgroundColor: theme.colors.greyLighter,
    },
  },
});
