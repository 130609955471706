// @flow
import React from 'react';
import cx from 'classnames';
import { FormattedMessage } from 'react-intl';
// import ContextMenu, { getSubmenuPos } from 'pages/company/DocumentContextMenu';

type ActionItemType = {
  id: string,
  defaultMessage: string,
  onClick: () => void,
  disabled?: boolean,
  isHidden?: boolean,
  divided?: boolean,
  isGranted: (number | number[]) => boolean,
  submenu: Array<MenuItem>,
  isDanger: boolean,
};

type Props = {
  classes: {
    [key: string]: string,
  },
  actions: ActionItemType[],
  close: () => void,
  isRtl: boolean,
  isLinked?: boolean,
};

type State = {
  active: ?number,
};

class ActionsPopup extends React.PureComponent<Props, State> {
  state = {
    active: null,
  };

  componentDidMount() {
    document.addEventListener('click', this.onToggle);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.onToggle);
  }

  onToggle = (event: Event) => {
    const { close } = this.props;
    if (!event.target.closest('#dokkaDocumentMoreMenu')) {
      close();
    }
  };

  setActive = (active: number) => {
    this.setState({ active });
  };

  addItemEl = (el: HTMLButtonElement) => {
    this.itemElements.push(el);
  };

  itemElements: Array<HTMLButtonElement> = [];

  render() {
    const { classes, actions, close, isRtl, isLinked = false } = this.props;
    const { active } = this.state;

    return (
      <div className={classes.actionsPopup} id="dokkaDocumentMoreMenu">
        <ul className={classes.actionsList}>
          {actions
            .filter((i) => !i.isHidden)
            .map((item, idx) => (
              <li key={item.id}>
                {React.isValidElement(item.node) ? (
                  item.node
                ) : (
                  <>
                    <button
                      type="button"
                      className={cx(classes.actionItem, {
                        [classes.divided]: item.divided,
                        [classes.activeActionItem]: idx === active,
                        [classes.submenuActionItem]: item.submenu,
                        [classes.dangerActionItem]: item.isDanger,
                        [classes.disabledActionItem]: item.disabled,
                      })}
                      disabled={item.disabled}
                      onMouseOver={() => {
                        if (!item.disabled) {
                          this.setActive(idx);
                        }
                      }}
                      onFocus={() => {
                        if (!item.disabled) {
                          this.setActive(idx);
                        }
                      }}
                      onClick={!item.disabled ? item.onClick : () => {}}
                      ref={this.addItemEl}
                    >
                      <FormattedMessage id={item.id} defaultMessage={item.defaultMessage} />
                    </button>
                    {/* {item.submenu && active === idx && ( */}
                    {/*  <ContextMenu */}
                    {/*    items={item.submenu} */}
                    {/*    position={getSubmenuPos(this.itemElements[idx], isRtl)} */}
                    {/*    onHide={close} */}
                    {/*    id={null} */}
                    {/*    isSubmenu */}
                    {/*    isRtl={isRtl} */}
                    {/*    isLinked={isLinked} */}
                    {/*  /> */}
                    {/* )} */}
                  </>
                )}
              </li>
            ))}
        </ul>
      </div>
    );
  }
}

export default ActionsPopup;
