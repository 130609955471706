// @flow
import React, { Component } from 'react';
import { compose } from 'redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { reduxForm, Field } from 'redux-form/immutable';
import { countries, dokkaCountries } from 'lib/countries';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import TextField from 'components/mui/Form/TextField/TextFieldForm';
import AutocompleteForm from 'components/mui/Form/Autocomplete/AutocompleteForm';

import { styled } from '@mui/material/styles';

const formID = 'create_org';
const options = countries.map((i) => ({ label: i, value: i }));

const Autocomplete = styled(AutocompleteForm)(({ theme, separated }) => {
  // find option by particular index and if input empty display separator
  const option = `&:has(.MuiAutocomplete-input[value=""]) + .MuiAutocomplete-popper .MuiAutocomplete-option[data-option-index="${
    separated - 1
  }"]`;
  return {
    [option]: {
      borderBottom: `1px solid ${theme.palette.grey[300]}`,
    },
  };
});

type Props = {
  intl: any,
  handleSubmit: () => void,
};

class OrganizationModal extends Component<Props> {
  render() {
    const {
      handleSubmit,
      intl: { formatMessage },
    } = this.props;
    return (
      <>
        <DialogTitle sx={{ textAlign: 'center' }}>
          <FormattedMessage id="form.organization.create.title" defaultMessage="Organization Setup" />
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2} component="form" id={formID} onSubmit={handleSubmit}>
            <Grid item xs={12} sx={{ mb: 2 }}>
              <Typography variant="subtitle1" color="grey.600" sx={{ textAlign: 'center' }}>
                <FormattedMessage
                  id="form.organization.create.subtitle"
                  defaultMessage="Enter the basic information of your organization"
                />
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Field
                component={TextField}
                fullWidth
                label={formatMessage({
                  id: 'form.organization.create.name.label',
                  defaultMessage: 'Organization name',
                })}
                placeholder={formatMessage({
                  id: 'form.organization.create.name.placeholder',
                  defaultMessage: 'Organization name',
                })}
                InputLabelProps={{ shrink: true }}
                required
                name="name"
                type="text"
                withTrim
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                component={Autocomplete}
                separated={dokkaCountries.length}
                disablePortal
                autoHighlight
                options={options}
                inputProps={{
                  label: formatMessage({
                    id: 'form.organization.create.country.placeholder',
                    defaultMessage: 'Select your country',
                  }),
                }}
                name="country"
              />
            </Grid>
            <Grid item xs={12} sx={{ mt: 2 }}>
              <Typography>
                <FormattedMessage
                  id="form.organization.create.contactsTitle"
                  defaultMessage="Contact Person information"
                />
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Field
                component={TextField}
                fullWidth
                label={formatMessage({
                  id: 'form.organization.create.firstName.label',
                  defaultMessage: 'First name',
                })}
                placeholder={formatMessage({
                  id: 'form.organization.create.firstName.placeholder',
                  defaultMessage: 'Enter your first name',
                })}
                InputLabelProps={{ shrink: true }}
                required
                name="first_name"
                type="text"
                withTrim
              />
            </Grid>
            <Grid item xs={6}>
              <Field
                component={TextField}
                fullWidth
                label={formatMessage({
                  id: 'form.organization.create.lastName.label',
                  defaultMessage: 'Last name',
                })}
                placeholder={formatMessage({
                  id: 'form.organization.create.lastName.placeholder',
                  defaultMessage: 'Enter your last name',
                })}
                InputLabelProps={{ shrink: true }}
                required
                name="last_name"
                type="text"
                withTrim
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                component={TextField}
                fullWidth
                label={formatMessage({
                  id: 'form.organization.create.email.label',
                  defaultMessage: 'Email',
                })}
                placeholder={formatMessage({
                  id: 'form.organization.create.email.placeholder',
                  defaultMessage: 'email@example.com',
                })}
                hint={{
                  id: 'form.organization.create.email.hint',
                  defaultMessage: 'Change it if you want to',
                }}
                InputLabelProps={{ shrink: true }}
                required
                name="email"
                type="email"
                withTrim
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button form={formID} type="submit">
            <FormattedMessage id="forms.common.continue" defaultMessage="Continue" />
          </Button>
        </DialogActions>
      </>
    );
  }
}

export default compose(
  injectIntl,
  reduxForm({
    form: 'organizationForm',
  }),
)(OrganizationModal);
