// @flow
import React from 'react';
import { Link } from 'react-router-dom';

import { DrawerStatic, DrawerHeader, DrawerFooter } from 'components/mui/Layouts/components/Drawer';

import Box from '@mui/material/Box';
import List from '@mui/material/List';
import Stack from '@mui/material/Stack';
import { LayoutRoot, LayoutContent } from 'components/mui/Layouts/components/Layout';
import { AppBarStatic, AppBarItemDivider } from 'components/mui/Layouts/components/AppBar';
import SettingsLink from 'components/mui/Layouts/components/Drawer/SettingsLink';
import HelpDropdown from 'components/mui/Layouts/components/HelpDropdown';
import LocaleSelect from 'components/mui/LocaleSelect';
import AppbarSearch from 'components/mui/Layouts/Organizations/components/Search';
import AccountDropdown from 'components/mui/Layouts/components/AccountDropdown';
import FullscreenIconButton from 'components/mui/Layouts/components/FullscreenIconButton';
import ApplicationDropdown from 'components/mui/Layouts/components/ApplicationDropdown';

import IconLogo from 'components/Icons/logo';

type TLayoutOrganizations = {
  children: React$Node,
};

const LayoutOrganizations: React$StatelessFunctionalComponent<TLayoutOrganizations> = ({ children }) => (
  <LayoutRoot>
    <DrawerStatic variant="permanent">
      <DrawerHeader>
        <Link to="/organizations">
          <IconLogo />
        </Link>
      </DrawerHeader>
      <DrawerFooter>
        <List>
          <SettingsLink />
        </List>
      </DrawerFooter>
    </DrawerStatic>

    <AppBarStatic>
      <Box flexShrink={1} flexGrow={1} px={2} display="flex" justifyContent="center">
        <AppbarSearch />
      </Box>
      <Stack direction="row" alignItems="center" spacing={1} flexShrink={0} ml="auto">
        <FullscreenIconButton />
        <AppBarItemDivider />
        <HelpDropdown />
        <LocaleSelect flagOnly />
        <ApplicationDropdown />
        <AccountDropdown />
      </Stack>
    </AppBarStatic>

    <LayoutContent>
      <DrawerHeader />
      {children}
    </LayoutContent>
  </LayoutRoot>
);

export default LayoutOrganizations;
