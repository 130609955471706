import { styled, alpha } from '@mui/material/styles';
import CheckboxMui from '@mui/material/Checkbox';

export const RowCheckboxWrapper = styled('div')(({ theme }) => ({
  maxWidth: 7,
  transition: 'max-width 0.2s;',
  backgroundColor: theme.palette.primary.main,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: 'calc( 100% - 2px )',
  overflow: 'hidden',
  position: 'relative',
  flex: '1 0 22px',

  '&:hover, &.is-visible': {
    maxWidth: 22,

    '& .MuiCheckbox-root': {
      transform: 'translateX(0)',
    },
  },
}));

export const RowCheckbox = styled(CheckboxMui)(({ theme }) => ({
  '&': {
    color: theme.palette.common.white,
    padding: 0,
    transform: 'translate3d(-20px, 0, 0)',

    '&.Mui-checked': {
      color: theme.palette.common.white,
    },

    '&.Mui-disabled': {
      color: alpha(theme.palette.common.white, 0.7),
    },
  },
}));
