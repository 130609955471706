// @flow

import { selector } from 'lib/selectors';
import { jeMatchedLineNumbersSelector, isStatementSelector } from 'domain/journal/selectors';
import { rtlEnable as rtlEnabledSelector } from 'domain/env';
import { Map, List } from 'immutable';

export const textract = <T: Object>(state: { textract: T }): T => state.textract;
export const textractData = selector(textract, (t) => t?.data);
// indicates data coming from textract is of new shape
export const textractEnabledSelector = selector(
  textract,
  isStatementSelector,
  (t, isStatement) => (t.enabled && !isStatement) || false,
);
export const textractFieldsMappingSelector = selector(textract, (t) => t.fields);
export const textractFieldsMappingUniqueSelector = selector(textractFieldsMappingSelector, (fields) => {
  const res = fields
    ? Object.values(
        fields.reduce((a, b) => {
          if (!a[b.display_name]) a[b.display_name] = b;
          return a;
        }, {}),
      )
    : {};
  return res;
});
// fields mapping
export const textractLineItemsTableSelector = selector(textractData, (t) => t?.items_table || {});

export const textractLineItemsPolygonsSelector = (page: ?number) =>
  selector(textractLineItemsTableSelector, (table) =>
    Object.entries(table).reduce((A, [column, v]) => {
      Object.entries(v).map(([row, cell]) => {
        if (!cell) {
          return A;
        }
        if (page) {
          if (cell.page === page) {
            A.push({ row, column, page: cell.page, id: cell.cell_id, Geometry: { Polygon: cell.polygon } });
          }
        } else {
          A.push({ row, column, page: cell.page, id: cell.cell_id, Geometry: { Polygon: cell.polygon } });
        }
      });
      return A;
    }, []),
  );

export const textractLineItemsTableLastColumnSelector = selector(
  textractLineItemsTableSelector,
  rtlEnabledSelector,
  (table, rtl) => {
    // selects last column cells, doesnt respect jeView, only respects rtl
    // keys might be presented as [1,2,4,5,6,8] with empty columns
    // sometimes brain sends reversed textract data
    const tableKeys = Object.keys(table);
    // swap first-last column according rtl and reversed order data
    const func = rtl ? Math.min : Math.max;

    return table ? table[func(...tableKeys)] : {};
  },
);

// returns first page with table
export const textractFirstTablePageSelector = selector(textractData, (t) => t?.tables_coords?.[0]?.Page);

export const textractTableCoordinatesByPageSelector = (page) =>
  selector(textractData, (t) => {
    const table = t?.tables_coords?.filter(({ Page }) => page === Page)[0];
    return table?.Geometry?.Polygon;
  });

export const textractLineItemsTableLastColumnMatchedLinesSelector = selector(
  textractLineItemsTableLastColumnSelector,
  jeMatchedLineNumbersSelector,
  textractLineItemsPolygonsSelector(),
  (column, matchedLines: Map<string, List<number>>, polygons) => {
    const polygonIds =
      polygons && column && Object.keys(column).length > 0
        ? matchedLines
            .map((lines) => lines.map((line) => column[line]))
            .map((items) => items.map((item) => item?.cell_id))
            .map((items) => items.map((item) => polygons.find((poly) => poly.id === item)))
        : new Map();

    return polygonIds.toJS();
  },
);

// returns first line of table fields
export const textractLineItemsTableHeadersSelector = selector(textractLineItemsTableSelector, (lineItems) =>
  lineItems ? Object.values(lineItems).map((line) => line[0] || line[1]) : [],
);

export const textractLineItemsBoundTableHeadersSelector = selector(textractLineItemsTableHeadersSelector, (headers) =>
  headers
    ?.map(({ data, header }) => ({ key: data, header }))
    // non-empty header indicates field is already bound in brains
    ?.filter(({ header }) => header !== null),
);
export const textractTableFieldsMappingSelector = selector(textractLineItemsTableSelector, (lineItems) => {
  if (lineItems) {
    return Object.values(lineItems).reduce((acc, fields) => {
      // @to-do use header_row instead of 0
      // temp fix as currently endpoint sends matrix with either 0 or 1 first row index
      const field = fields[0] || fields[1];
      acc.push({
        name: field.data,
        bindingCell: field && field.polygon,
        id: field && field.cell_id,
        metadata: field.metadata,
      });

      return acc;
    }, []);
  }
  return [];
});
