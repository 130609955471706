/* @flow */
import { makeStyles } from '@mui/styles';

export default makeStyles(() => ({
  general: {
    overflowY: 'auto',
    '& .react-pdf__Page': {
      display: 'flex',
      justifyContent: 'center',
    },
  },
  splitView: {
    width: '100%',
    flexDirection: 'column',
  },
  financial: {
    display: 'flex',
    justifyContent: 'flex-start',
    '& $blank': {
      margin: [0, 'auto'],
      padding: [0, 35],
      boxSizing: 'border-box',
    },
    '& $item:not($blank)': {
      marginTop: 10,
    },
  },
  pdfWrapper: {
    overflow: 'auto',
    // padding: [0, 0, 0, 35],
    maxHeight: '100%',
    position: 'relative',
    '&$general': {
      textAlign: 'center',
      flex: [1, 1],
      maxHeight: 'none',
      height: '100%',
    },
    '& .react-pdf__Page': {
      borderRadius: 2,
      position: 'relative',
      height: '100%',
      '& > .react-pdf__Page__textContent, & > .react-pdf__Page__canvas': {
        borderRadius: 2,
        // position: 'absolute',
      },
      '& .react-pdf__Page__annotations': {
        display: 'none',
      },
    },
  },
  pageList: {
    display: 'block',
    width: '100%',
    position: 'relative',
    overflowY: 'auto',
    height: '100%',
    '& > $item:last-child': {
      // this allows document last page to be scrolled
      // higher and not be overlapped by footer in standard
      // split view
      marginBottom: 90,
    },
  },
  item: {
    display: 'block',
    margin: [10, 'auto'],
    minWidth: '100px',
    position: 'relative',
  },
  blank: {
    width: 'fit-content',
  },
  blankImage: {
    transformOrigin: ['left', 'top'],
  },
  blankComplete: {
    display: 'none',
  },
  fakePage: {
    background: '#fff',
    margin: [0, 'auto'],
  },
  tagsWrapper: {
    width: 800,
    margin: [10, 'auto'],
  },
  tags: {
    background: 'none!important',
    padding: 5,
    marginBottom: 40,
    '&:after': {
      display: 'none',
    },
  },
  generalPageList: {
    '& $item:first-of-type': {
      marginTop: 0,
    },

    '& $item:last-child': {
      marginBottom: 100,
    },
    '& $blank': {
      left: 0,
      right: 0,
      marginTop: 0,
    },
  },

  pdfContainer: {
    position: 'relative',
    width: '100%',
    height: '100%',
  },
}));
