// @flow
import { makeStyles } from '@mui/styles';

export default makeStyles((theme) => ({
  container: {
    position: 'absolute',
    zIndex: 10,
    width: '100%',
  },
  textArea: {
    boxShadow: '1px 1px 4px rgba(0, 0, 0, 0.3)',
    backgroundColor: theme.colors.primaryWhite,
    resize: 'none',
    position: 'fixed',
  },
}));
