// @flow
import moment from 'moment';

export function nameFormater(...names: Array<string>): string {
  return names.join(' ');
}

export function timeFormater(t: string): string {
  return moment(t, moment.ISO_8601).format('H:mm');
}

export function listTimeFormatter(t: number | string): string {
  const isOlderThenOneDay = moment(t).isBefore(moment().subtract(1, 'day').endOf('day'));
  const format = isOlderThenOneDay ? 'MMM D' : 'H:mm';
  return moment(t).format(format);
}
