/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
// @flow
import React, { useState, useRef } from 'react';
import AvatarEditor from 'react-avatar-editor';
import { useIntl } from 'react-intl';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';

const ZOOM_STEP = 0.2;

export type Props = {
  image: File,
  onCropFinished: (canvas: HTMLCanvasElement) => void, // dataURL
};

const UserPicEditor: React$StatelessFunctionalComponent<Props> = ({ image, onCropFinished }) => {
  const editorRef = useRef(null);

  const [scale, setScale] = useState(1);

  const { formatMessage } = useIntl();

  const upscale = () => setScale(scale < 3 ? scale + ZOOM_STEP : scale);

  const downscale = () => setScale(scale > ZOOM_STEP + ZOOM_STEP / 2 ? scale - ZOOM_STEP : ZOOM_STEP);

  const handleSave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (editorRef) {
      // This returns a HTMLCanvasElement, it can be made into a data URL or a blob,
      // drawn on another canvas, or added to the DOM.
      const canvas = editorRef.current.getImage();

      // If you want the image resized to the canvas size (also a HTMLCanvasElement)
      // const canvasScaled = this.editor.getImageScaledToCanvas();
      onCropFinished(canvas);
    }
  };

  const width = 225;
  return (
    <>
      <DialogContent sx={{ textAlign: 'center' }}>
        <AvatarEditor
          ref={editorRef}
          image={image}
          width={width}
          height={width}
          border={25}
          borderRadius={width / 2}
          color={[0, 0, 0, 0.4]}
          scale={scale}
          rotate={0}
          crossOrigin="anonymous"
        />
        <Grid container alignItems="center" justifyContent="center">
          <IconButton onClick={downscale}>
            <RemoveIcon />
          </IconButton>
          <SearchIcon color="disabled" sx={{ margin: [0, 3] }} />
          <IconButton onClick={upscale}>
            <AddIcon />
          </IconButton>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSave} sx={{ width: 100 }}>
          {formatMessage({ id: 'button.save', defaultMessage: 'Save' })}
        </Button>
      </DialogActions>
    </>
  );
};

export default UserPicEditor;
