import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';

import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

type Props = {|
  hide: () => void,
  initiateScan: () => void,
  isHaveNotImage: boolean,
|};

const SourceActions: React$StatelessFunctionalComponent<Props> = ({ hide, initiateScan, isHaveNotImage }) => {
  const { formatMessage } = useIntl();

  const scanButtonText = useMemo(
    () =>
      isHaveNotImage
        ? { id: 'scan.buttons.scan', defaultMessage: 'Scan' }
        : { id: 'scan.buttons.newScan', defaultMessage: 'New scan' },
    [isHaveNotImage],
  );

  return (
    <Stack direction="row" spacing={1}>
      <Button variant="outlined" onClick={hide}>
        {formatMessage({ id: 'scan.buttons.close', defaultMessage: 'Close' })}
      </Button>

      <Button onClick={initiateScan}>{formatMessage(scanButtonText)}</Button>
    </Stack>
  );
};

export default SourceActions;
