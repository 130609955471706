import file from './images/file.svg';
import files from './images/files.svg';
import link from './images/link.svg';

export default (theme) => ({
  container: { display: 'flex' },

  icon: {
    width: 40,
    height: 40,
    border: '1px solid red',
    backgroundColor: theme.colors.primaryDark,
    maskSize: '80%',
  },

  file: {
    extend: 'icon',
    WebkitMask: `url("${file}") 50% 50% no-repeat`,
  },

  files: {
    extend: 'icon',
    WebkitMask: `url("${files}") 50% 50% no-repeat`,
  },

  link: {
    extend: 'icon',
    WebkitMask: `url("${link}") 50% 50% no-repeat`,
  },

  countContainer: {
    minWidth: '16px',
    minHeight: '16px',
    textAlign: 'center',
    marginLeft: '4px',
    alignSelf: 'flex-start',
    backgroundColor: theme.colors.primaryDark,
    borderRadius: '50%',
    lineHeight: '0px',
  },

  count: {
    display: 'inline-block',
    textAlign: 'center',
    fontSize: '10px',
    padding: '50% 0',
    color: '#ffffff',
    margin: 'auto 2px',
  },
});
