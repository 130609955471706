// @flow
import * as React from 'react';
import type { TMessageRecord, ChatUser } from 'domain/chat/types.js.flow';
import UserPic from 'components/UserPic';
import { listTimeFormatter } from './helpers';
import Highlighter from 'react-highlight-words';

type Props = {
  classes: {
    [key: string]: string,
  },
  data: ChatUser,
  thread: ?TMessageRecord,
  counter: number,
  searchText?: string,
};

class PrivatItem extends React.Component<Props> {
  render() {
    const {
      classes,
      data: { username },
      thread,
      counter,
      searchText,
    } = this.props;
    return (
      <div className={classes.item}>
        <UserPic size={30} src={this.props.data.picture} />

        <div className={classes.content}>
          <div className={classes.info}>
            <div className={classes.name}>
              {searchText ? (
                <Highlighter
                  highlightClassName={classes.highlight}
                  searchWords={[searchText]}
                  autoEscape
                  textToHighlight={username}
                />
              ) : (
                username
              )}
            </div>

            {thread ? <div className={classes.text}>{thread.message}</div> : null}
          </div>
          <div className={classes.meta}>
            {thread ? <div className={classes.time}>{listTimeFormatter(thread.createdAt)}</div> : null}
            {counter > 0 ? <div className={classes.count}>{counter}</div> : null}
          </div>
        </div>
      </div>
    );
  }
}

export default PrivatItem;
