// @flow
import * as React from 'react';
import { List } from 'immutable';
import { connect } from 'react-redux';
import { compose, type Dispatch } from 'redux';
import { type OptionsItemType, type RecordCellSetType, RecordCellSet } from 'domain/journal/helper';
import {
  valueNormalize as defaultValueNormalize,
  filterList as defaultFilterList,
  localeSort,
  testOption,
  type ValueNormalize,
} from './helpers';
import type { CellStyleType } from '../styleHelper';
import { getJEListAction, getJEIndexListItemDataAction } from 'domain/journal';
import * as helpers from 'components/Tables/layout/virtualGrid/helpers';
import { documentDocumentIdSelector } from 'domain/documents';

import elements from 'components/elements';
import AutocompleteOptions from './options';
import withManageListItemModal from './withManageListItemModal';

import cx from 'classnames';
import { withStyles } from '@mui/styles';
import sheet from './sheet';

type LikeEvent = {
  target: {
    value: ?(string | number),
  },
};

type LikeInputEvent = {
  target: {
    value: string,
  },
};

type LikeKeyEvent = {
  keyCode: number,
  key?: string,
};

type Props = {|
  classes: {
    [key: string]: string,
  },
  maxWidth: number,
  isOpen: boolean,
  children: (n: ?string, (c: LikeInputEvent) => Promise<void>) => React$Node,
  disabled?: boolean,
  id?: string,
  placeholder?: string,
  optionList: List<OptionsItemType>,
  filterList: (l: List<OptionsItemType>, t: string) => List<OptionsItemType>,
  valueNormalize: ValueNormalize,
  blurTimeOut: number,
  input: {
    onChange: (e: LikeEvent, cb?: ?() => void) => void,
    onKeyDown: (e: LikeKeyEvent) => void,
    onToggleOpen: (s: boolean) => void,
    value: RecordCellSetType,
    onFocus?: () => void,
    onBlur: () => void,
    onCreate: (c: RecordCellSetType, cb?: ?() => void) => void,
    isCreatable: boolean,
  },
  onComplete: () => void,
  onCreate: () => Promise<?string>,
  onEdit: (cellValue: string) => Promise<?string>,
  name: { _cell: string, name: string },
  getRefs: (el: ?HTMLInputElement, component: any) => void,
  getList: Dispatch<typeof getJEListAction>,
  getIndexForm: Dispatch<typeof getJEIndexListItemDataAction>, // for wrapper component
  rtl: boolean,
  style: CellStyleType,
  params?: { id: string, cellName: string },
  pageLimit?: number,
  pageItemsCount?: number,
  pageToken?: number,
  documentID: string, // for wrapper component
|};

type State = {|
  term: string,
  index: number,
  isDirectionUP: ?boolean,
  isLoading: boolean,
|};

class Autocomplete extends React.Component<Props, State> {
  static defaultProps = {
    valueNormalize: defaultValueNormalize,
    filterList: defaultFilterList,
    blurTimeOut: 100,
  };

  state = {
    term: '',
    index: -1,
    isDirectionUP: undefined, // eslint-disable-line react/no-unused-state
    isLoading: false,
  };

  componentWillUnmount(): * {
    clearTimeout(this.timeout);
  }

  onBlur = () => {
    const { index } = this.state;
    // never ever change this without good reason.
    // if wrong index is provided, doSelect will fire and cause sideffects

    this.focused = false;
    this.timeout = setTimeout(() => {
      if (!this.focused) {
        if (index && index >= 1) this.doSelect(index);
        this.props.input.onToggleOpen(false);
        this.props.input.onBlur();
      }
    }, this.props.blurTimeOut);
  };

  onFocus = () => {
    this.focused = true;
    if (this.timeout) {
      clearTimeout(this.timeout);
      this.timeout = undefined;
    }
  };

  onCreate = (cb?: () => void) =>
    this.props
      .onCreate()
      .then((options) => {
        if (options) {
          // we must send request with empty value for create
          const set = RecordCellSet({ value: '', options });
          this.props.input.onCreate(set, cb);
        }
      })
      .catch(() => {
        if (this.input) this.input.focus();
        this.slected = false;
      });

  onEdit = (optionItem: OptionsItemType, cb?: () => void) =>
    this.props
      .onEdit(optionItem.get('value'))
      .then((options) => {
        const set = RecordCellSet({
          value: optionItem.get('value'),
          display: optionItem.get('display'),
          options,
        });
        this.props.input.onCreate(set, cb);
      })
      .catch(() => {
        if (this.input) this.input.focus();
        this.slected = false;
      });

  onDropCreate = async (dndValue: string) => {
    const optionList = this.getOptionList();
    const testDndValue = testOption(dndValue);
    const list = optionList.filter(testDndValue(false));
    const results = optionList.findIndex(testDndValue(true));

    if (this.isCreateAvailable) {
      if (results < 0) {
        // if there is no exact match, we show list of submatches found,
        // or open create dialog if mo matches
        if (list.size > 0) {
          await this.setValue(dndValue, 0);
          this.props.input.onToggleOpen(true);
        } else {
          await this.setValue(dndValue);
          this.onCreate(this.cbEnter);
        }
        // this.props.input.onToggleOpen(true);
      } else if (list.size === 1) {
        this.doSelect(results, this.cbEnter);
      } else {
        await this.setValue(dndValue, 0);
        this.props.input.onToggleOpen(true);
      }
    } else if (list.size) {
      if (results > -1) {
        this.doSelect(results, this.cbEnter);
      } else if (list.size) {
        await this.setValue(dndValue, 0);
        this.props.input.onToggleOpen(true);
      }
    }
  };

  onChange = (value, cb = (x) => x) => {
    const {
      optionList,
      input: { onChange },
    } = this.props;
    const item = optionList.filter((f) => f.value === value).first();
    const { display } = item || { display: '' };
    onChange({ target: { value, display } }, cb);
  };

  setValue = (term: string, index: number = -1): Promise<void> =>
    new Promise((resolve) => {
      this.setState({ term, index }, () => {
        resolve();
      });
    });

  get dafaultIndex() {
    const { input } = this.props;
    if (input.value.value === null) return -1;
    return this.defaultOptionList.findIndex((e) => e.id === input.value.value);
  }

  get term(): string {
    const { optionList, input } = this.props;
    if (input.value.value) {
      const value = optionList.filter((f) => f.id === input.value.value).first();
      this.slected = true;
      if (typeof value === 'string') return this.props.valueNormalize(value);
    }
    return '';
  }

  get defaultOptionList() {
    return this.props.optionList.sort(localeSort);
  }

  get value(): ?string {
    const {
      state: { term },
    } = this;
    if (term) return term;
    return this.term;
  }

  get isCreateAvailable() {
    return this.props.input.isCreatable;
  }

  getOptionList = () => {
    const { optionList } = this.props;
    const { term } = this.state;
    return term ? this.filterSearchList(optionList, term) : optionList;
  };

  setActive = (index: number) => {
    if (this.state.index !== index) {
      this.setState({ index }, () => {
        if (index >= 0) {
          this.scrollTo(index);
        }
      });
    }
  };

  setDirection = (isUp: boolean) => {
    this.setState({
      isDirectionUP: isUp, // eslint-disable-line react/no-unused-state
    });
  };

  getAllHandledRows = () => [...this.completePages, ...this.loadingPages];

  getMissingPages = (from, to) => {
    const { pageLimit } = this.props;
    const handledRows = this.getAllHandledRows();
    const pages = helpers.getLoadPagesByRows(from, to, 2, pageLimit);
    return pages.filter((p) => !handledRows.includes(p));
  };

  keyDown = (e: SyntheticKeyboardEvent<HTMLButtonElement>) => {
    const { index } = this.state;
    const optionList = this.getOptionList();

    if (e.keyCode === 38) {
      const overlapIndex = optionList.size - 1;
      this.setActive(index > 0 ? index - 1 : overlapIndex);
    }
    if (e.keyCode === 40) {
      const overlapIndex = 0;
      this.setActive(optionList.size > index + 1 ? index + 1 : overlapIndex);
    }
    if (e.keyCode === 27) {
      this.props.input.onToggleOpen(false);
      this.props.input.onKeyDown({ keyCode: 27 });
    }
    if (e.keyCode === 13) {
      e.preventDefault();
      this.doSelect(index, this.cbEnter);
    }
    if (e.keyCode === 8) {
      if (this.slected) {
        this.onChange('');
        this.slected = false;
      }
    }
    if (e.keyCode === 9) {
      // TAB
      e.preventDefault();
      this.doSelect(index, this.cbEnter);
    }
  };

  filterSearchList = (data, term: string) => {
    const testValue = testOption(term);
    return data.filter(testValue(false));
  };

  doSelect = (index: number, cb?: () => void) => {
    const item = this.getOptionList().get(index);

    if (item && !item.get('isActive')) return;

    if (index === -1) {
      if (this.state.term) {
        this.onCreate(cb);
      } else {
        const value = this.props.input.value.value || null;
        // if nothing was selected and input value exist, update with existing value
        // if nothing was selected but value was deleted - update with null
        // to delete on backend
        this.onChange(value, cb);
      }
    } else if (item) {
      this.onChange(item.value, cb);
    }
    this.slected = true;
    this.props.input.onToggleOpen(false);
  };

  cbEnter = () => this.props.input.onKeyDown({ keyCode: 13 });

  refsProxy = (el: ?HTMLInputElement) => {
    this.input = el;
    this.props.getRefs(el, this);
  };

  searchHandler = (e: LikeInputEvent) => {
    const { value } = e.currentTarget;
    this.props.input.onToggleOpen(true);
    // if we have search results to display set index to 0. 0 means we select first from a list
    // if no search results, -1 must remain caz it allows for onCreate to be invoked
    // on enter key event
    const selectedIndex = this.getOptionList().size ? 0 : -1;
    this.setValue(value, selectedIndex);
  };

  scrollTo = (index) => {
    const shiftVLIndex = this.isCreateAvailable ? index + 1 : index;
    if (this.listRef && this.listRef.current) {
      this.listRef.current.scrollToItem(shiftVLIndex, 'smart');
    }
  };

  handleClose = () => {
    this.props.input.onToggleOpen(false);
  };

  handleOpen = () => {
    this.props.input.onToggleOpen(true);
  };

  handleToggle = () => {
    const { isOpen } = this.props;
    const fn = isOpen ? this.handleClose : this.handleOpen;
    fn();
  };

  slected: boolean = false;

  input: ?HTMLInputElement;

  focused: boolean;

  timeout: ?TimeoutID;

  listRef: any = React.createRef();

  loadVListDelay: ?TimeoutID;

  render() {
    const {
      classes,
      style,
      name,
      pageItemsCount,
      maxWidth,
      rtl,
      isOpen,
      onComplete,
      valueNormalize,
      id,
      placeholder,
      disabled,
      input,
      children,
    } = this.props;
    const { index, term, isLoading } = this.state;
    return (
      <div
        className={classes.wrapper}
        tabIndex="0" // eslint-disable-line jsx-a11y/no-noninteractive-tabindex
        onFocus={this.onFocus}
        onBlur={this.onBlur}
        onKeyDown={this.keyDown}
        role="combobox"
        aria-expanded={isOpen}
        aria-controls={id}
        data-element={elements.je.autocomplete.container}
        data-element-id={name.name}
      >
        <div className={cx(classes.fieldWrapper, { [classes.open]: isOpen })}>
          <input
            id={id}
            aria-autocomplete="list"
            autoComplete="no"
            className={classes.field}
            placeholder={placeholder}
            name={name._cell}
            value={this.value}
            disabled={disabled}
            ref={this.refsProxy}
            onChange={this.searchHandler}
            onDoubleClick={() => input.onToggleOpen(true)}
            style={style}
            data-element={elements.je.autocomplete.field}
            data-element-id={`${name._cell}-${name.name}`}
          />
        </div>
        {isOpen && this.input ? (
          <AutocompleteOptions
            inputEl={this.input}
            maxWidth={maxWidth}
            rtl={rtl}
            optionList={this.getOptionList()}
            doSelect={(e) => this.doSelect(e, onComplete)}
            valueNormalize={valueNormalize}
            index={index}
            term={term}
            setDirection={this.setDirection}
            isLoading={isLoading}
            pageItemsCount={pageItemsCount}
            itemHeight={30}
            setListRef={this.listRef}
            setActive={this.setActive}
            onClickEdit={(item: OptionsItemType) => this.onEdit(item, this.cbEnter)}
            addNewItem={this.isCreateAvailable ? () => this.onCreate(this.cbEnter) : null}
          />
        ) : null}
        <div // eslint-disable-line
          role="button"
          className={cx(classes.btnShield, classes.btnRotate)}
          onClick={this.handleToggle}
          data-element={elements.je.autocomplete.shield}
        />
        {children(this.value, this.searchHandler)}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  documentID: documentDocumentIdSelector(state),
});

const mapDispatchToProps = {
  getList: getJEListAction,
  getIndexForm: getJEIndexListItemDataAction,
};

export { Autocomplete as AC };

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(sheet),
  withManageListItemModal,
)(Autocomplete);
