// @flow
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import Dialog from 'components/mui/Dialog';
import Box from '@mui/material/Box';
import TextFieldBase from 'components/mui/Form/TextField/TextFieldBase';

export type TErrorMeta = {|
  status: number,
  id: string,
  message: string,
|};

type TCreateFavoriteTagsModal = {
  title: string,
  onClose: () => void,
  onSubmit: (value: string) => void,
  errorMeta: ?TErrorMeta,
  resetError: () => void,
};

const CreateFavoriteTagsModal: React$StatelessFunctionalComponent<TCreateFavoriteTagsModal> = ({
  title,
  errorMeta,
  resetError,
  onSubmit,
  onClose,
}) => {
  const [value, setValue] = useState(title);
  const isDisabled = value.trim().length < 1 || Boolean(errorMeta);

  const onChange = (event: SyntheticInputEvent<HTMLInputElement>) => {
    setValue(event.target.value);
    if (isDisabled) {
      resetError();
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    onSubmit(value);
  };

  return (
    <Dialog
      open
      title={<FormattedMessage id="components.saveSearchPopup.title" defaultMessage="Add to Favorite Search" />}
      okText={<FormattedMessage id="components.saveSearchPopup.add" defaultMessage="Add" />}
      okBtnProps={{ disabled: isDisabled }}
      onOk={handleSubmit}
      onClose={onClose}
      disableRestoreFocus
    >
      <Box pt={3}>
        <form onSubmit={handleSubmit}>
          <TextFieldBase
            fullWidth
            autoFocus
            value={value}
            onChange={onChange}
            label={<FormattedMessage id="components.saveSearchPopup.name" defaultMessage="Title" />}
            error={Boolean(errorMeta)}
            inputProps={{ maxLength: 48 }}
            helperText={
              errorMeta && (
                <FormattedMessage
                  id={`components.saveSearchPopup.error.${errorMeta.id}`}
                  defaultMessage={errorMeta.message}
                />
              )
            }
          />
        </form>
      </Box>
    </Dialog>
  );
};

export default CreateFavoriteTagsModal;
