export default (theme) => ({
  body: {
    width: '100%',
  },

  input: {
    width: '100%',
    padding: [5, 10],
    border: `1px solid ${theme.colors.greyDarker}`,
  },

  dropdownBoxPosition: {
    width: '100%',
    height: 0,
    position: 'relative',
  },
  dropdownBox: {
    minWidth: '100%',
    maxHeight: 200,
    overflow: 'auto',
    position: 'absolute',
    zIndex: 1,
    top: 0,
    left: 0,
    backgroundColor: '#FFF',
    boxShadow: '0px 10px 30px rgba(0, 0, 0, 0.1)',
    paddingBottom: 10,
  },

  dropdownSectionTitle: {
    width: '100%',
    textAlign: 'center',
    fontSize: 12,
    fontWeight: 'normal',
    margin: [5, 0],
    color: theme.colors.greyDarker,
    display: 'flex',
    alignItems: 'center',
    '&:before': {
      flex: '1 1 auto',
      height: 1,
      content: '""',
      display: 'block',
      marginRight: 20,
      backgroundColor: theme.colors.greyDark,
    },
    '&:after': {
      flex: '1 1 auto',
      height: 1,
      content: '""',
      display: 'block',
      marginLeft: 20,
      backgroundColor: theme.colors.greyDark,
    },
  },

  tagOption: {
    padding: [5, 10],
    cursor: 'pointer',
    color: theme.colors.greyBlack,
  },

  userOption: {
    extend: 'tagOption',
    display: 'flex',
    alignItems: 'center',
  },

  userPicBox: {
    minWidth: 20,
  },

  userOptionText: {
    marginLeft: 10,
  },

  active: {
    backgroundColor: theme.colors.greyDark,
  },
});
