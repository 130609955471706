// @flow
import React from 'react';

import { TILE_Z_INDEXES } from 'pages/company/TileGrid/TileCard/components/StyledComponents';
import IconButton from '@mui/material/IconButton';

import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';

import { styled, darken } from '@mui/material/styles';

const Button = styled(IconButton, { label: 'TilePreviewIcon' })(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  color: theme.palette.common.white,
  backgroundColor: theme.palette.primary.main,
  border: `1px solid ${theme.palette.common.white}`,
  width: 32,
  height: 32,
  padding: theme.spacing(1),
  borderRadius: 50,
  margin: 'auto',
  zIndex: TILE_Z_INDEXES.previewEye,

  '&:hover': {
    backgroundColor: darken(theme.palette.primary.main, 0.1),
    boxShadow: theme.shadows[5],
  },

  '& .MuiSvgIcon-root': {
    fontSize: 18,
  },
}));

type TPreviewEye = {
  onClickEye: () => void,
};

const PreviewEye: React$StatelessFunctionalComponent<TPreviewEye> = ({ onClickEye }) => (
  <Button onClick={onClickEye}>
    <RemoveRedEyeOutlinedIcon />
  </Button>
);

export default PreviewEye;
