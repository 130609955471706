// @flow
import React from 'react';
import type { LocationShape } from 'react-router-dom';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { LayoutRoot, LayoutContent } from 'components/mui/Layouts/components/Layout';
import { DrawerHeader } from 'components/mui/Layouts/components/Drawer';
import { AppBarDocument, AppBarItemDivider } from 'components/mui/Layouts/components/AppBar';
import LocaleSelect from 'components/mui/LocaleSelect';
import BackToArrow from 'components/mui/Layouts/Document/components/BackToArrow';
import AppbarLogoCompany from 'components/mui/Layouts/components/AppbarLogoCompany';
import AccountDropdown from 'components/mui/Layouts/components/AccountDropdown';
import ChatIconButton from 'components/mui/Layouts/components/Chat/ChatIconButton';
import EventsHistoryIconButton from 'components/mui/Layouts/components/EventsHistoryPanel/EventsHistoryIconButton';
import FullscreenIconButton from 'components/mui/Layouts/components/FullscreenIconButton';
import DocumentNavigation from 'pages/document/DocumentNavigation';
import IconButtonExport from 'pages/document/components/IconButtonExport';
import IconButtonForceSync from 'pages/document/components/IconButtonForceSync';
import HelpDropdown from '../components/HelpDropdown';
import ApplicationDropdown from 'components/mui/Layouts/components/ApplicationDropdown';

type TLayoutDocument = {
  children: React$Node,
  backTo: string | LocationShape,
  hasConfirmBeforeLeave: boolean,
};

const LayoutDocument: React$StatelessFunctionalComponent<TLayoutDocument> = ({
  children,
  backTo,
  hasConfirmBeforeLeave,
}) => (
  <LayoutRoot>
    <AppBarDocument>
      <Box flexShrink={0} display="flex" alignItems="center">
        <BackToArrow href={backTo} hasConfirmBeforeLeave={hasConfirmBeforeLeave} />
        <AppbarLogoCompany />
      </Box>

      <DocumentNavigation hasConfirmBeforeLeave={hasConfirmBeforeLeave} />

      <Stack direction="row" alignItems="center" spacing={1} flexShrink={0}>
        <IconButtonExport />
        <IconButtonForceSync />
        <AppBarItemDivider />
        <FullscreenIconButton />
        <AppBarItemDivider />
        <EventsHistoryIconButton />
        <ChatIconButton />
        <HelpDropdown />
        <LocaleSelect flagOnly />
        <ApplicationDropdown />
        <AccountDropdown />
      </Stack>
    </AppBarDocument>

    <LayoutContent sx={{ height: '100vh' }}>
      <DrawerHeader />
      {children}
    </LayoutContent>
  </LayoutRoot>
);

export default LayoutDocument;
