// @flow
import React from 'react';

import Chip from '@mui/material/Chip';

import Avatar from 'components/mui/Avatar';

type Props = {
  tag: string,
  users: { [userId: string]: { picture: string, username: string } },
};
const EmailTag: React$StatelessFunctionalComponent<Props> = ({ tag, users, ...rest }) => (
  <Chip
    avatar={<Avatar alt={users[tag]?.username || tag} src={users[tag]?.picture} size={24} sx={{ ml: 0.5 }} />}
    variant="outlined"
    label={users[tag]?.username || tag}
    color="primary"
    {...rest}
  />
);

export default EmailTag;
