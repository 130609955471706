// @flow
import React, { useCallback } from 'react';
import Tooltip from 'components/mui/Tooltip';
import IconButton from '@mui/material/IconButton';

import ForwardToInboxOutlinedIcon from '@mui/icons-material/ForwardToInboxOutlined';

type TProps = {
  linkID: string,
  onOpenSendByEmailDialog: (linkId: string) => void,
};

const SendByEmailButton: React$StatelessFunctionalComponent<TProps> = ({ linkID, onOpenSendByEmailDialog }) => {
  const handelClick = useCallback(() => {
    onOpenSendByEmailDialog(linkID);
  }, [linkID, onOpenSendByEmailDialog]);

  return (
    <Tooltip
      t={{
        id: 'document.show.actions.sendByEmailLinked',
        defaultMessage: 'Share bundle via email',
      }}
    >
      <IconButton color="primary" onClick={handelClick}>
        <ForwardToInboxOutlinedIcon />
      </IconButton>
    </Tooltip>
  );
};

export default SendByEmailButton;
