// @flow
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import * as FullStory from '@fullstory/browser';
import { userGUIDSelector, userIdSelector, userNamesSelector } from 'domain/env';

const mapStateToProps = (state) => ({
  userId: userGUIDSelector(state),
  userEmail: userIdSelector(state),
  userName: userNamesSelector(state),
});

const Fullstory = () => {
  const { userId, userEmail, userName } = useSelector(mapStateToProps);
  useEffect(() => {
    FullStory.init({ orgId: process.env.REACT_APP_FULLSTORY_ORG_ID });
  }, []);

  useEffect(() => {
      FullStory.FullStory('setIdentity', {
        uid: userId,
        properties: { email: userEmail, name: `${userName.firstName} ${userName.lastName}` },
      });
  }, [userId, userEmail, userName]);
  return null;
};

export default Fullstory;
