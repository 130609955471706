import { styled } from '@mui/material/styles';

import Box from '@mui/material/Box';
import { alpha } from '@mui/material';

export const SelectContainer = styled(Box)(({ theme }) => ({
  minWidth: 5,
  minHeight: 5,
  backgroundColor: alpha(theme.palette.primary.light, 0.3),
  border: `1px solid ${theme.palette.primary.main}`,
  zIndex: 1,
  position: 'absolute',
  display: 'flex',
  flexDirection: 'column',
}));

export const Corner = styled(Box, { label: 'DragCorner' })(({ theme }) => ({
  width: 10,
  height: 10,
  backgroundColor: theme.palette.common.white,
  border: `1px solid ${theme.palette.primary.main}`,
  position: 'absolute',
}));

export const DraggableZone = styled('strong', { shouldForwardProp: (propName) => propName !== 'isDrag' })(
  ({ isDrag }) => ({
    flexGrow: 2,
    cursor: isDrag ? 'grabbing' : 'grab',
  }),
);
