// @flow
import { Record, List } from 'immutable';
import type { Device, DeviceRecord, DeviceRecordList } from './types.js.flow';

export const deviceFactory: DeviceRecord = new Record({
  deviceId: '',
  deviceType: '',
  displayGuid: '',
  erpType: '',
});

export const devicesFactory = (messages: Array<Device>): DeviceRecordList => List(messages.map(deviceFactory));
