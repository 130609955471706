/* @flow */
import cross from 'pages/common/cross.svg';

export default {
  box: {
    position: 'fixed',
    zIndex: 1300,
    top: 70,
    right: 10,
    width: 332,
  },

  status: {
    marginTop: 10,
    background: '#FF6666',
    borderRadius: 2,
    boxShadow: '0px 3px 10px rgba(0, 0, 0, 0.15)',
    color: '#fff',
    padding: [10, 20],
    fontSize: 16,
    lineHeight: '17px',
    fontWeight: 200,
    width: '100%',
    position: 'relative',
  },

  close: {
    position: 'absolute',
    cursor: 'pointer',
    top: 10,
    right: 10,
    width: 8,
    height: 8,
    backgroundSize: '100%',
    background: `url("${cross}") 0 0 no-repeat`,
    border: '0 none',
  },
};
