/* @flow */
import * as React from 'react';

import { SortableContainer } from 'react-sortable-hoc';
import ColSortableItem from './colSortableItem';

type IProps = {
  items: any[],
  children: React$Node,
  isDisabled?: boolean,
};

const ColSortableList = SortableContainer(({ items, isDisabled = false, children }: IProps) => (
  <div>
    {items.map((item, index) => (
      <ColSortableItem key={`item-${item.colName}`} index={index} item={item} disabled={isDisabled}>
        {children}
      </ColSortableItem>
    ))}
  </div>
));

export default ColSortableList;
