/* @flow */
import * as A from './actions';
import { googleSearchFactory, googleSearchItemsAdapter } from './adapters';
import type { GoogleSearchStore } from './types.js.flow';

type Action = any;

export const reducer = {
  googleSearch(state: GoogleSearchStore = googleSearchFactory(), action: Action) {
    switch (action.type) {
      case A.setGoogleSearchTextAction.type:
      case A.getStatementTxnSearchAction.type:
        return state.set('googleSearchText', action.payload).set('googlePanelStatus', true);

      case A.setGooglePanelStatusAction.type:
        return state.set('googlePanelStatus', action.payload);

      case A.getStatementTxnSearchAction.request:
        return state.set('isGoogleSearchLoading', true);

      case A.getStatementTxnSearchAction.success:
        return state.set('isGoogleSearchLoading', false).set('items', action.payload);

      case A.getStatementTxnSearchAction.failure:
        return state.set('isGoogleSearchLoading', false).set('items', googleSearchItemsAdapter([]));

      default:
        return state;
    }
  },
};
