import { asyncAction, syncAction } from 'lib/action';

export const fetchChatUsers = asyncAction('CHAT/GET_CHATUSERS'); // TODO: check
export const fetchCompanyChatUsers = asyncAction('CHAT/GET_COMPANY_CHATUSERS');
export const fetchDocumentChatUsers = asyncAction('CHAT/GET_DOCUMENT_CHATUSERS'); // TODO: check
export const chatConnectAction = asyncAction('CHAT/CONNECT');
export const chatGetThreadsAction = asyncAction('CHAT/GET_THREAD');
export const chatGetWorkerThreadsAction = asyncAction('CHAT/GET_WORKER_THREAD');
export const chatDisConnectAction = asyncAction('CHAT/DIS_CONNECT'); // TODO: check

export const createChannelAction = asyncAction('CHAT/CREATE_CHANNEL');
export const sendMessageAction = asyncAction('CHAT/SEND_MESSAGE');
export const receiveMessageAction = asyncAction('CHAT/RECEIVE_MESSAGE');
export const documentAddThreadAction = syncAction('CHAT/DOCUMENT_THREAD');
export const setActiveChannelAction = syncAction('CHAT/SET_ACTIVE_CHANNEL', ({ threadId, companyId }) => ({
  payload: { name: threadId, companyId },
}));

// same as setActiveChannelAction but its result will not be cleaned by chat component unmount
export const setActiveChannelForceAction = syncAction('CHAT/SET_ACTIVE_CHANNEL_FORCE', ({ threadId, companyId }) => ({
  payload: { name: threadId, companyId },
}));
export const removeActiveChannelForceAction = syncAction('CHAT/REMOVE_ACTIVE_CHANNEL_FORCE');

export const setCounterAction = syncAction('CHAT/SET_COUNTER');
export const getPreviousMessageAction = asyncAction('CHAT/GET_PREVIUS_MESSAGE', (name) => ({ payload: { name } }));
export const setMessageHandlerIdAction = syncAction('CHAT/SET_MESSAGE_HANDLER_ID');

export const getMessagesAction = asyncAction('CHAT/GET_MESSAGES');
export const markChannelAsReadAction = syncAction('CHAT/MARK_CHANNEL_AS_READ');
export const setChatConnectionStatusAction = syncAction('CHAT/SET_CHAT_CONNECTION_STATUS');
export const terminateChannelsAction = asyncAction('CHAT/TERMINATE_CHANNELS');
export const chatReadyToRenderAction = syncAction('CHAT/READY_TO_RENDER');

export const setOnPageAction = syncAction('CHAT/SET_ON_PAGE');
export const setOpenAction = syncAction('CHAT/SET_OPEN');
export const onInitOneSignalAction = syncAction('CHAT/ON_INIT_ONE_SIGNAL');
export const setAllowedChatWorkerAction = syncAction('CHAT/SET_ALLOWED_CHAT_WORKER');
export const chatResetUsersAction = syncAction('CHAT/RESET_USERS');
