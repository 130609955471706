/* @flow */
export default {
  headerHeight: 50,
  topBarHeight: 48,
};

export const userRoleMeta = {
  accountant: { title: 'Accountant', role: 'accountant' },
  'limited-accountant': { title: 'Limited Accountant', role: 'limited-accountant' },
  'restricted-accountant': { title: 'Restricted Accountant', role: 'restricted-accountant' },

  user: { title: 'Regular User', role: 'user' },
  'confidential-user': { title: 'Confidential User', role: 'confidential-user' },
  'restricted-user': { title: 'Restricted user', role: 'restricted-user' },
  'confidential-restricted-user': { title: 'Restricted Confidential User', role: 'confidential-restricted-user' },
};
