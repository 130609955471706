/* @flow */
export default {
  container: {
    height: '100%',
    position: 'relative',
    '&:hover > $tooltip': {
      opacity: 1,
      transitionDelay: (props: { debounce?: number }) => (props.debounce ? `${props.debounce}ms` : 0),
      transform: 'translateX(-50%)',
    },
  },
  tooltip: {
    width: (props: { width?: number }) => (props.width ? props.width : 'auto'),
    pointerEvents: 'none',
    position: 'absolute',
    top: 'calc(100% + 6px)',
    left: '50%',
    transform: 'translateX(-50%) translateY(75%)',
    background: '#171A1C',
    padding: [0, 8],
    color: '#fff',
    unicodeBidi: 'plaintext',
    opacity: 0,
    transitionDuration: '0.2s',
    transitionDelay: 0,
    transitionProperty: 'opacity, transform',
    willChange: 'opacity, transform',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
    zIndex: 11,
    fontSize: 10,
    lineHeight: '20px',
    textAlign: 'center',
    '& > span': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    '&:before': {
      content: '""',
      position: 'absolute',
      display: 'block',
      width: 0,
      height: 0,
      left: '50%',
      bottom: '100%',
      border: '6px solid transparent',
      borderBottomColor: '#171A1C',
      marginLeft: '-6px',
    },
  },
  up: {
    bottom: 'calc(100% + 6px)',
    transform: 'translateX(-50%) translateY(-75%)',
    top: 'auto',
    '&:before': {
      top: '100%',
      bottom: 'auto',
      border: '6px solid transparent',
      borderTopColor: 'rgba(0, 0, 0, 0.7)',
    },
  },
  // duplicating pure css style to make fullstory happy
  messageContainer: {
    '&:hover $message': {
      opaticy: 100,
    },
  },
  // duplicating pure css style to make fullstory happy
  message: {
    opacity: 0,
    overflowWrap: 'break-word',
  },
};
