// @flow
import React from 'react';
import { compose, type Dispatch } from 'redux';
import { connect } from 'react-redux';
import { Record, List, Set, type RecordOf, type Map, is } from 'immutable';
import { generateJETableStoreKey, matchCell } from 'domain/journal/helper';
import { createMemoFn, createMemoPropFn } from 'lib/propHelpers';
import {
  journalSelector,
  gridEntriesSelector,
  settingsEntriesSelector,
  updateJournalEntryAction,
  polygonsSelector,
  createReferenceAction,
  setRefernceAction,
  activeReferenceSelector,
  extraPolygonSelector,
  referenceSelector,
  insertGridAction,
  updateJournalEntriesAction,
  jeTypeSelector,
  jeInvoiceTypeSelector,
  initialHiddenColumnsSelector,
  getJournalEntryPageAction,
  lineItemsSelector,
  lineCountSelector,
  linesSelector,
  settingsSelector,
  selectRowAction,
  selectedRowSelector,
  reconcileColumnSelector,
  notHiddenColumnsSelector,
  pinnedColumnSelector,
  amountColumnSelector,
  rowMessagesSelector,
  errorFromErpSelector,
  isJeLayoutRTLSelector,
  journalSettingsDisabledColsSelector,
  journalSettingsPinnedColsSelector,
  getJELineColumnsVisibilityAction,
  getJournalSettingsPinnedColsAction,
  updateJournalSettingsPinnedColsAction,
  updateJELineColumnsVisibilityAction,
  getCachedPaginationListSelector,
  jeGridLineColumnsDictionarySelector,
  updateJournalSettingsOrderedColsAction,
  journalSettingsOrderedColsSelector,
  getJournalSettingsOrderedColsAction,
  setLoadingAction,
  endLoadingAction,
  isJELoadingSelector,
  setEditingAction,
  endEditingAction,
  isJEEditingSelector,
} from 'domain/journal';
import { documentStatusesSelector, documentHotkeysSelector } from 'domain/documents';
import { isChangeProps } from 'lib/helpers';
import { Reconciliation } from 'components/Reconciliation';
import Spinner from 'components/Spinner';
import { reconciledetailsRowSelector } from 'domain/reconciliation/selectors';
import {
  showRowDetailsAction,
  showReconciliationRequestDetailsAction,
  hideRowDetailsAction,
} from 'domain/reconciliation/actions';
import { currentConnectedERPselector } from 'domain/settings';
import { rtlEnable, documentViewSelector, userGUIDSelector } from 'domain/env';
import { getLoadPagesByRows, zipQueryParams } from './layout/virtualGrid/helpers';
import {
  getMeta,
  getMetaRange,
  getNext,
  checkCallDataSupport,
  keyCodeArrow,
  removeLine,
  OptionItemFactory,
  range,
  isStatement,
  createPolygonReferens,
  createMultiPolygonRefernce,
} from './helpers';
import _ from 'lodash';
import { FormattedMessage } from 'react-intl';
import { hotkeyMatchAdapter } from 'domain/documents/adapters';

import { Settings, Grid, Summary, ManualSummary, VGrid } from './layout';
import JEProcessingOverlay from './JEProcessingOverlay';
import Cell from './cell';
import DescriptionCell from './description';
import SelectAllCheckbox from 'components/Tables/grid/SelectAllCheckbox';
import Error from './Error';
import { RequestPopup } from 'components/ReconciliationRequest';
import elements from 'components/elements';
import LineHotkeyCatcher from 'components/Tables/LineHotkeyCatcher';
import DocumentHotkeyCatcher from 'components/Tables/DocumentHotkeyCatcher';
import { LineGridPanel } from 'components/Tables/StyledComponents';

import { withStyles } from '@mui/styles';
import cx from 'classnames';
import sheet from './sheet';

import type { THotkeyList, THotkeyMatch } from 'domain/documents/types.js.flow';
import type { ReferenceRecord, ExtraPolygonType, RowMessage } from 'domain/journal/types.js.flow';
import type {
  JornalEntryType,
  RecordsCellType,
  RecordCellSetType,
  TJEGridLineColumnsDictionary,
} from 'domain/journal/helper';
import type { ItemType, MetaType, ArrowKeyCode, EnterKeyCode, JeType, OptionsItemType } from './types.js.flow';
import type { TViewArrangement } from 'pages/document/types.js.flow';
import { orderByMap } from 'lib/array';
import { type JournalSettingOrder } from 'domain/journal/types.js.flow';

type Props = {
  data: JornalEntryType,
  gridData: JornalEntryType,
  settingsData: JornalEntryType,
  initialHiddenColumns: List<string>,
  classes: {|
    [key: string]: string,
  |},
  jeType: JeType,
  // eslint-disable-next-line react/no-unused-prop-types
  jeInvoiceType: string,
  className?: string,
  rtl: boolean,
  // eslint-disable-next-line react/no-unused-prop-types
  userGUID: string,
  polygons: Map<string, RecordOf<ItemType>>,
  documentID: string,
  createReference: Dispatch<typeof createReferenceAction>,
  onChange: Dispatch<typeof updateJournalEntryAction>,
  setActive: Dispatch<typeof setRefernceAction>,
  activeReference: ?ReferenceRecord,
  documentView: Map<'documentsView' | 'infoBarWidth', number>,
  extraPolygon: RecordOf<ExtraPolygonType>,
  reference: Map<string, any>,
  insertGrid: Dispatch<typeof insertGridAction>,
  lineItems: List<number>,
  settings: List<number>,
  onUpdate: Dispatch<typeof updateJournalEntriesAction>,
  getJournalEntryPage: Dispatch<typeof getJournalEntryPageAction>,
  lineCount: number,
  documentStatuses: List<string>,
  selectRow: Dispatch<typeof selectRowAction>,
  selectedRows: Set<number>,
  reconcileColumn: string,
  notHiddenColumns: Set<string>,
  showRowDetails: Dispatch<typeof showRowDetailsAction>,
  showReconciliationRequestDetails: Dispatch<typeof showRowDetailsAction>,
  amountColumn: string,
  rowMessages: List<RowMessage>,
  errorFromErp: string,
  hideRowDetails: Dispatch<typeof hideRowDetailsAction>,
  reconciledetailsRow: number | boolean,
  pinnedColumn: Array<string>,
  isJeLayoutRTL: boolean,
  isJELoading: boolean,
  journalSettingsPinnedCols: Array<string>,
  journalSettingsDisabledCols: Array<string>,
  getJELineColumnsVisibility: Dispatch<typeof getJELineColumnsVisibilityAction>,
  getJournalSettingsOrderedCols: Dispatch<typeof getJournalSettingsOrderedColsAction>,
  getJournalSettingsPinnedCols: Dispatch<typeof getJournalSettingsPinnedColsAction>,
  updateJournalSettingsPinnedCols: Dispatch<typeof updateJournalSettingsPinnedColsAction>,
  updateJELineColumnsVisibility: Dispatch<typeof updateJELineColumnsVisibilityAction>,
  updateJournalSettingsOrderedCols: Dispatch<typeof updateJournalSettingsOrderedColsAction>,
  hotkeyList: THotkeyList,
  viewArrangement: TViewArrangement,
  getCachedPaginationList: (id: string) => List<OptionsItemType> | void,
  gridLineColumnsDictionary: TJEGridLineColumnsDictionary,
  orderedColumns: JournalSettingOrder,
  hasVisibleColumnsManageButton: boolean,
  setEditing: Dispatch<typeof setEditingAction>,
  endEditing: Dispatch<typeof endEditingAction>,
  isJEEditing: boolean,
};

type State = {
  active: ?string,
  loadedVgridPages: Array<number>,
  cacheOrder: JournalSettingOrder | null,
};

class Tables extends React.Component<Props, State> {
  refVisible: ?[number, number];

  constructor(props) {
    super(props);
    this.state = {
      active: null,
      // saga loads first page, see here: src/pages/document/sagas.js
      // yield call(Journal.ensureJounalEntry, params.documentId, false, 1, 50);
      loadedVgridPages: [1],
      // it is a temporary duplicate of value in the global store while order update,
      // it is necessary to avoid blinking after sorting
      cacheOrder: null,
    };
  }

  componentDidMount() {
    this.setInitialFocus();
  }

  componentDidUpdate(prevProps: Props) {
    const {
      jeType,
      orderedColumns,
      data,
      getJournalSettingsPinnedCols,
      getJELineColumnsVisibility,
      getJournalSettingsOrderedCols,
      initialHiddenColumns,
    } = this.props;
    const { active, cacheOrder } = this.state;
    if (typeof active === 'string' && data.size > 0 && !data.get(active) && !isStatement(jeType)) {
      compose(this.handleActive, this.getEnterNextCell)(active);
    }

    if (prevProps.orderedColumns !== orderedColumns && cacheOrder) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ cacheOrder: null });
    }

    // determine when to recalculate columns that have been changed(not equal - recalculate columns state)
    const isEqualInitialHiddenColumns = is(prevProps.initialHiddenColumns, initialHiddenColumns);

    if (isChangeProps(prevProps, this.props, ['userGUID', 'jeInvoiceType', 'erp']) || !isEqualInitialHiddenColumns) {
      getJournalSettingsPinnedCols();
      getJELineColumnsVisibility();
      getJournalSettingsOrderedCols();
    }
  }

  onChangeSelectedRows = (selectedRows: Set<number>) => {
    const { selectRow } = this.props;
    selectRow(selectedRows);
  };

  onUpdateOrderAfterChangePinnedCols = (pinnedCols) => {
    const { journalSettingsPinnedCols } = this.props;
    const orderedColumns = this.getCurrentOrder();

    const isPinned = pinnedCols.length > journalSettingsPinnedCols.length;
    const [items, compare] = isPinned
      ? [pinnedCols, journalSettingsPinnedCols]
      : [journalSettingsPinnedCols, pinnedCols];
    const diffColl = items.find((i) => !compare.includes(i));
    this.onChangeOrder(
      orderedColumns
        .update('pinned', (pinned) => pinned.filter((i) => i !== diffColl))
        .update('float', (float) => float.filter((i) => i !== diffColl)),
    );
  };

  onChangePinnedCols = (pinnedCols: Array<string>) => {
    const { updateJournalSettingsPinnedCols } = this.props;
    this.onUpdateOrderAfterChangePinnedCols(pinnedCols);
    updateJournalSettingsPinnedCols(pinnedCols);
  };

  onChangeDisabledCols = (disabledCols: Array<string>) => {
    const { updateJELineColumnsVisibility } = this.props;
    updateJELineColumnsVisibility(disabledCols);
  };

  onChangeLoadedVgridPages = (loadedVgridPages: Array<number>) => {
    this.setState({ loadedVgridPages });
  };

  onChangeOrder = (order: JournalSettingOrder) => {
    const { updateJournalSettingsOrderedCols } = this.props;
    this.setState({ cacheOrder: order });
    updateJournalSettingsOrderedCols(order);
  };

  get meta(): MetaType {
    const { rtl, data } = this.props;
    return getMeta(data, rtl);
  }

  get gridMeta(): MetaType {
    return this.getGridMetaData();
  }

  get settingsMeta(): MetaType {
    return this.getSettingsMeta();
  }

  get combineMeta(): Array<MetaType> {
    return this.getCombineMeta();
  }

  get pinnedCols(): Array<string> {
    const { journalSettingsPinnedCols } = this.props;
    return Array.from(new Set([...this.permanentlyPinned, ...journalSettingsPinnedCols]));
  }

  get disabledCols(): Array<string> {
    const { journalSettingsDisabledCols } = this.props;
    return journalSettingsDisabledCols;
  }

  get permanentlyPinned(): Array<string> {
    return this.getPermanentlyPinned();
  }

  get visible() {
    if (this.refVisible && this.refVisible.length) {
      return this.refVisible.slice();
    }
    return undefined;
  }

  get visibleRowNumbers() {
    const { visible } = this;
    if (!visible) return undefined;

    const { settings } = this.props;
    const [start, end] = visible;
    const delta = end - start;
    const gridStart = start + settings.size + 0;

    return [...settings.toJS(), ...range(gridStart, gridStart + delta + 2)];
  }

  get visibleData() {
    const {
      visibleRowNumbers,
      meta: [cols],
    } = this;
    const { data, jeType } = this.props;
    if (isStatement(jeType)) {
      const keys = visibleRowNumbers.reduce((res, rowNum) => [...res, ...cols.map((c) => `${c}${rowNum}`)], []);
      return data.filter((v, k) => keys.includes(k));
    }
    return data;
  }

  get startGridRow() {
    const { lineItems } = this.props;
    return lineItems.reduce(
      (res: number, val: number) => (typeof res === 'undefined' || res > val ? val : res),
      undefined,
    );
  }

  get rowMessages() {
    const { rowMessages } = this.props;
    return rowMessages;
  }

  get cellHotkeys() {
    const { hotkeyList } = this.props;
    return hotkeyList.filter((keys) => keys.level === 'cell');
  }

  getCurrentOrder = () => {
    const { cacheOrder } = this.state;
    const { orderedColumns } = this.props;
    return cacheOrder || orderedColumns;
  };

  getCombineMeta = createMemoFn(
    () => {
      const [pCols] = this.getGridEnabledPinnedMeta();
      const [fCols, rows] = this.getGridEnabledFloatMeta();
      const { settingsMeta } = this;
      const orderedCols = this.orderColumns([...pCols, ...fCols]);
      return { orderedCols, rows, settingsMeta };
    },
    ({ orderedCols, rows, settingsMeta }) => [settingsMeta, [orderedCols, rows]],
  );

  getPermanentlyPinned = createMemoFn(
    () => {
      const [cols] = this.gridMeta;
      const { pinnedColumn } = this.props;
      const orderedCols = this.orderColumns(cols)[0];
      return { orderedCols, pinnedColumn };
    },
    ({ orderedCols, pinnedColumn }) => [orderedCols, ...pinnedColumn],
  );

  getSettingsMeta = createMemoFn(
    () => {
      const { rtl, settingsData } = this.props;
      return { rtl, settingsData };
    },
    ({ rtl, settingsData }) => getMetaRange(settingsData, rtl),
  );

  getAllOrders = () => {};

  getGridMetaData = createMemoFn(
    () => {
      const { rtl, gridData } = this.props;
      return { rtl, gridData };
    },
    ({ rtl, gridData }) => getMetaRange(gridData, rtl),
  );

  getStoreKey = (props: Props = this.props) => {
    const { userGUID, jeInvoiceType, erp } = props;
    return generateJETableStoreKey([userGUID, jeInvoiceType, erp]);
  };

  getGridEnabledPinnedMeta = () => {
    const [cols, rows] = this.getGridEnabledMeta();
    const orderedColumns = this.getCurrentOrder();

    const pinnedCols = this.extractPinnedCols(cols);
    const enablePinnedOrder = orderedColumns.pinned.filter((item) => item === '*' || pinnedCols.includes(item));
    return [orderByMap(pinnedCols, enablePinnedOrder), rows];
  };

  getGridEnabledFloatMeta = () => {
    const [cols, rows] = this.getGridEnabledMeta();
    const orderedColumns = this.getCurrentOrder();
    const floatCols = this.extractFloatCols(cols);
    const enableFloatOrder = orderedColumns.float.filter((item) => item === '*' || floatCols.includes(item));
    return [orderByMap(floatCols, enableFloatOrder), rows];
  };

  getGridEnabledMeta: MetaType = createMemoFn(
    () => {
      const [cols, rows] = this.gridMeta;
      const allEnabled = this.getAllEnabledColumns();
      const orderedCols = this.orderColumns(cols.filter((col) => allEnabled.includes(col)));
      return { orderedCols, rows };
    },
    ({ orderedCols, rows }) => [orderedCols, rows],
  );

  // get settings and force enabled cell
  getAllEnabledColumns = createMemoFn(
    () => ({
      enableColumns: this.getEnabledColumns(),
      forceEnableColumns: this.getForcedEnabledColumns(),
    }),
    ({ enableColumns, forceEnableColumns }) => _.uniq([...enableColumns, ...forceEnableColumns]),
  );

  getEnabledColumns = createMemoFn<Array<string>>(
    () => {
      const [columns] = this.gridMeta;

      return { columns, disabledCols: this.disabledCols };
    },
    ({ columns, disabledCols }) => columns.filter((c: string) => !disabledCols.includes(c)),
  );

  getForcedEnabledColumns = createMemoFn<Array<string>>(
    () => {
      const [columns] = this.gridMeta;
      return { columns };
    },
    ({ columns }) => columns.filter(this.isForcedEnabledCol),
  );

  getItem = (floatOptions, stringOptions) => (data: RecordsCellType | void) => {
    const {
      classes,
      selectedRows,
      isJeLayoutRTL,
      reference,
      data: gridData,
      rtl,
      activeReference,
      setActive,
      polygons,
      documentView,
      showRowDetails,
      amountColumn,
      setEditing,
      endEditing,
      isJEEditing,
    } = this.props;
    const { active } = this.state;
    if (data && data instanceof Record) {
      if (data.type === 'description') {
        return <DescriptionCell classes={classes} data={data} />;
      }
      const cell = matchCell(data._cell);
      const optionList = data.type === 'float' ? floatOptions : stringOptions;

      return (
        <Cell
          gridData={gridData}
          data={data}
          key={data.type + data.readonly}
          rtl={rtl}
          isPriorityErp={isJeLayoutRTL}
          createRefernce={this.handleCreateReference}
          activeReference={activeReference}
          setActiveReference={setActive}
          polygons={polygons}
          optionList={optionList}
          active={active}
          classes={classes}
          onChange={this.handleChange}
          onActive={this.handleActive}
          documentView={documentView}
          isSupport={this.checkSupport(data)}
          insertGrid={this.insertGrid}
          setActiveCell={this.setActiveCell}
          isBulkEdit={selectedRows.has(cell[1])}
          onShowDetails={showRowDetails}
          amountColumn={amountColumn}
          reference={reference}
          hotkeyList={this.cellHotkeys}
          setEditing={setEditing}
          endEditing={endEditing}
          isJEEditing={isJEEditing}
        />
      );
    }
    return null;
  };

  getUpdateParams(cell: string, cellSet: RecordCellSetType, isReference = false): RecordsCellType | void {
    const { data } = this.props;

    const item: RecordsCellType | void = data.get(cell);
    if (item instanceof Record) {
      return item.set(
        'cellSet',
        // remove spaces in value for some fields if we set value via dnd
        isReference
          ? cellSet.set('value', this.removeSpacesByCellName(item.name, cellSet.value).substr(0, item.maxLength))
          : cellSet,
      );
    }
    return undefined;
  }

  getCreateReferenceValue = (cell: string, cellSet: RecordCellSetType): RecordCellSetType => {
    const { data } = this.props;

    const item: RecordsCellType | void = data.get(cell);
    if (item instanceof Record) {
      const allowedCells = new RegExp(/priv_details/g);
      if (allowedCells.test(item.name) && !item.readonly) {
        const vCellSet = item.cellSet.update('value', (value) =>
          value && value.length > 0 ? [value, cellSet.value].join(' ') : cellSet.value,
        );
        return cellSet.set('value', vCellSet.value);
      }
    }
    return cellSet;
  };

  getOptions = createMemoPropFn(() => {
    const { polygons } = this.props;
    const baseOptions = polygons.reduce((acc, polygon) => acc.add(polygon.text), new Set()).toList();

    return {
      stringOptions: this.getStringOptions(baseOptions),
      floatOptions: this.getFloatOptions(baseOptions),
    };
  }, ['polygons']);

  getFloatOptions = (options) =>
    options.reduce((list, text) => {
      const value = parseFloat(text);
      if (value.toString() === text) {
        return list.push(
          OptionItemFactory({
            id: text,
            value: text,
            display: text,
          }),
        );
      }
      return list;
    }, new List());

  getStringOptions = (options) =>
    options.map((text) =>
      OptionItemFactory({
        id: text,
        value: text,
        display: text,
      }),
    );

  getNextCell = getNext;

  getRowInVisibleArea = (currentRow: number) => {
    const [topRow, bottomRow] = this.visible;

    const insideTopRow = topRow + (this.startGridRow + 1);
    const insideBottomRow = bottomRow + (this.startGridRow - 2);

    const res = _.cond([
      [(r) => r < insideTopRow, _.constant(insideTopRow)],
      [(r) => r > insideBottomRow, _.constant(insideBottomRow)],
      [_.stubTrue, _.constant(currentRow)],
    ])(currentRow);
    return res;
  };

  getPageParams = () => {
    const [startRow, endRow] = this.visible ? this.visible : [0, 0];
    const loadPages = getLoadPagesByRows(startRow, endRow, 0);
    const { page, offset } = zipQueryParams(loadPages);
    return [loadPages, { page, pageSize: offset }];
  };

  setInitialFocus = () => {
    const { data } = this.props;

    const focusedRecord = data.find((cellRecord) => cellRecord.focused);
    if (typeof focusedRecord !== 'undefined') {
      this.setActiveCell(focusedRecord._cell);
    }
  };

  // eslint-disable-next-line max-len
  getEnterNextCell = (active: string) => {
    const { rtl, isJeLayoutRTL } = this.props;
    return this.getNextCell(13, rtl, isJeLayoutRTL, this.combineMeta, this.visibleData, active);
  };

  setActiveCell = (cellId: string | null): void => {
    const { active } = this.state;
    if (cellId !== active) {
      this.setState({ active: cellId });
    }
  };

  setRefVisible = (ref: [number, number]) => {
    const { active } = this.state;
    this.refVisible = ref;
    if (active) {
      const [col, row] = matchCell(active);
      if (row >= this.startGridRow) {
        const visibleRow = this.getRowInVisibleArea(row);
        if (visibleRow !== row) {
          this.setActiveCell(`${col}${visibleRow}`);
        }
      }
    }
  };

  setLoadingAfterTimeout = (action: Dispatch<*>, payload, timeout: number = 1000) => {
    let id;
    let loadingSet = false;
    const { setLoading, endLoading } = this.props;
    new Promise((resolve, reject) => {
      id = setTimeout(() => {
        loadingSet = true;
        setLoading();
      }, timeout);

      action({ ...payload, resolve, reject });
    })
      .catch((e) => e)
      .finally(() => {
        if (id) {
          clearTimeout(id);
        }
        if (loadingSet) {
          endLoading();
        }
      });
  };

  removeSpacesByCellName = (cellName: string, value: string): string =>
    cellName.includes('priv_doc_finID') ? value.split(' ').join('') : value;

  extractFloatCols = (columns: Array<string>) => {
    const { pinnedCols } = this;
    return columns.filter((c) => !pinnedCols.includes(c));
  };

  extractPinnedCols = (columns: Array<string>) => {
    const { pinnedCols } = this;
    return columns.filter((c) => pinnedCols.includes(c));
  };

  isRequiredCell = (cellName: string) => {
    const { gridData } = this.props;
    const cellData = gridData.get(cellName);
    if (!cellData) return false;

    const { mandatory, readonly } = cellData;
    return mandatory && !readonly;
  };

  isForcedEnabledCol = (colName: string) => {
    const rows = _.tail(this.gridMeta[1]);
    return (
      this.pinnedCols.includes(colName) ||
      _.some(rows, (row) => {
        const cell = `${colName}${row}`;
        return this.isRequiredCell(cell);
      })
    );
  };

  orderColumns = (cols: Array<string>) => {
    const { rtl, isJeLayoutRTL } = this.props;
    return rtl !== isJeLayoutRTL ? [...cols].reverse() : cols;
  };

  insertGrid = ({ grid, polygons }, cell) => {
    const { documentID, jeType, insertGrid } = this.props;

    const [loadPages, pageParams, addLoadedPages] = isStatement(jeType)
      ? [...this.getPageParams(), this.onChangeLoadedVgridPages]
      : [null, {}, _.identity];

    addLoadedPages(loadPages);
    insertGrid({ polygons, grid, cell, documentID, pageParams });
  };

  clearLoadedVgridPages = () => {
    const { jeType } = this.props;
    if (isStatement(jeType)) {
      this.setState({ loadedVgridPages: [] });
    }
  };

  checkSupport = (data) => {
    const { reference, getCachedPaginationList } = this.props;
    return reference.size ? checkCallDataSupport(reference.first(), data, getCachedPaginationList) : undefined;
  };

  handleActive = (value: ArrowKeyCode | EnterKeyCode | string, currentActive?: string) => {
    const { active: stateActive } = this.state;
    const { rtl, isJeLayoutRTL } = this.props;
    const active = stateActive || currentActive;
    if (typeof value === 'string') {
      this.setActiveCell(value);
    } else if (typeof active === 'string' && (keyCodeArrow.has(value) || value === 13)) {
      const cell = this.getNextCell(value, rtl, isJeLayoutRTL, this.combineMeta, this.visibleData, active);
      this.setActiveCell(cell);
    }
  };

  handleChange = (
    cell: string,
    cellSet: RecordCellSetType,
    ignoreSelectedRows: boolean = false,
    hotkeyMatch: THotkeyMatch,
  ): void => {
    const { documentID, onChange, jeType, selectedRows } = this.props;
    const rowsToSelect = ignoreSelectedRows ? new Set() : selectedRows;
    const item = this.getUpdateParams(cell, cellSet);
    const [loadPages, pageParams, addLoadedPages] = isStatement(jeType)
      ? [...this.getPageParams(), this.onChangeLoadedVgridPages]
      : [null, {}, _.identity];
    addLoadedPages(loadPages);

    if (item) {
      this.setLoadingAfterTimeout(onChange, {
        item,
        documentID,
        selectedRows: rowsToSelect,
        pageParams,
        ...hotkeyMatchAdapter(hotkeyMatch),
      });
      this.clearLoadedVgridPages();
    }
  };

  handleCreateReference = (params: { cell: string, text: string, isMulti: boolean }) => {
    const { polygons, extraPolygon, createReference, documentID, jeType, selectedRows } = this.props;
    const hangler = params.isMulti ? createMultiPolygonRefernce : createPolygonReferens;
    const { cell, cellSet } = hangler(params.cell, params.text, polygons, extraPolygon);

    // const appendedCellSet = this.getCreateReferenceValue(cell, cellSet);
    const item = this.getUpdateParams(cell, cellSet, true);
    const [loadPages, pageParams, addLoadedPages] = isStatement(jeType)
      ? [...this.getPageParams(), this.onChangeLoadedVgridPages]
      : [null, {}, _.identity];
    addLoadedPages(loadPages);

    if (item) {
      this.setLoadingAfterTimeout(createReference, {
        item,
        documentID,
        pageParams,
        visible: this.visible,
        selectedRows,
      });
    }
  };

  removeHandler = (r: number) => {
    const { documentID, onUpdate, data, selectedRows, selectRow } = this.props;
    const item = removeLine(r, data, selectedRows);
    const pageParams = this.getPageParams();

    if (item) {
      this.setLoadingAfterTimeout(onUpdate, { item, documentID, visible: this.visible, pageParams: pageParams[1] });
    }
    selectRow(new Set());
  };

  isReadOnly = () => {
    const { documentStatuses } = this.props;
    return documentStatuses.includes('accepted');
  };

  renderGrid() {
    // eslint-disable-next-line max-len
    const {
      classes,
      data,
      className,
      jeType,
      lineCount,
      rtl,
      selectedRows,
      settingsData,
      documentID,
      hideRowDetails,
      reconciledetailsRow,
      viewArrangement,
      gridLineColumnsDictionary,
      activeReference,
      lineItems,
      gridData,
      getJournalEntryPage,
      initialHiddenColumns,
      reconcileColumn,
      notHiddenColumns,
      showReconciliationRequestDetails,
      hasVisibleColumnsManageButton,
    } = this.props;

    const { active, loadedVgridPages } = this.state;
    const { floatOptions, stringOptions } = this.getOptions(this.props)();
    const orderedColumns = this.getCurrentOrder();

    const pinnedMeta = this.getGridEnabledPinnedMeta();
    // exclude first title row, and last empty rows.
    const countAllRows = isStatement(jeType) ? lineCount - 6 : lineCount - 2;
    const isReadOnly = this.isReadOnly();

    return data.size > 0 ? (
      <div className={cx(classes.tableWrapper, className)}>
        <JEProcessingOverlay />
        <Settings
          getItem={this.getItem(floatOptions, stringOptions)}
          active={active}
          activeReference={activeReference}
          data={settingsData}
        />

        <LineGridPanel>
          {!isReadOnly && (
            <>
              <SelectAllCheckbox
                startRow={pinnedMeta[1][0]}
                countAllRows={countAllRows}
                selectedRows={selectedRows}
                handleChange={this.onChangeSelectedRows}
              />

              <div data-element={elements.je.gridTopBar.selected}>
                <FormattedMessage
                  id="document.show.journalEntry.selectedRows"
                  defaultMessage="{selectedRows} of {countAllRows} selected"
                  values={{ selectedRows: selectedRows.size, countAllRows }}
                />
              </div>
            </>
          )}
        </LineGridPanel>

        {isStatement(jeType) ? (
          <VGrid
            onRowsRender={this.setRefVisible}
            meta={this.gridMeta}
            lineItems={lineItems}
            getItem={this.getItem(floatOptions, stringOptions)}
            data={gridData}
            active={active ? matchCell(active) : [null, null]}
            getJournalEntry={getJournalEntryPage}
            removeHandler={this.removeHandler}
            rtl={rtl}
            lineCount={lineCount}
            storeKey={this.getStoreKey()}
            initialHiddenColumns={initialHiddenColumns}
            selectedRows={selectedRows}
            onChangeSelectedRows={this.onChangeSelectedRows}
            countAllRows={countAllRows}
            isReadOnly={isReadOnly}
            loadedPages={loadedVgridPages}
            onChangeLoadedPages={this.onChangeLoadedVgridPages}
            reconcileColumn={reconcileColumn}
            notHiddenColumns={notHiddenColumns}
            forcedEnabledCols={this.getForcedEnabledColumns(this.gridMeta[0])}
            rowMessagesRelativeCol={reconcileColumn}
            rowMessages={this.rowMessages}
            googleSearchCols={['B']}
            onRequestIndicatorClick={showReconciliationRequestDetails}
            gridLineColumnsDictionary={gridLineColumnsDictionary}
          />
        ) : (
          <Grid
            data={gridData}
            getItem={this.getItem(floatOptions, stringOptions)}
            active={active}
            removeHandler={this.removeHandler}
            activeReference={activeReference}
            pinnedCols={this.pinnedCols}
            disabledCols={this.disabledCols}
            meta={this.gridMeta}
            lineItems={lineItems}
            pinnedMeta={pinnedMeta}
            floatMeta={this.getGridEnabledFloatMeta()}
            enabledCols={this.getAllEnabledColumns()}
            forcedEnabledCols={this.getForcedEnabledColumns(this.gridMeta[0])}
            permanentlyPinned={this.permanentlyPinned}
            onChangePinnedCols={this.onChangePinnedCols}
            onChangeDisabledCols={this.onChangeDisabledCols}
            storeKey={this.getStoreKey()}
            selectedRows={selectedRows}
            onChangeSelectedRows={this.onChangeSelectedRows}
            countAllRows={countAllRows}
            isReadOnly={isReadOnly}
            viewArrangement={viewArrangement}
            gridLineColumnsDictionary={gridLineColumnsDictionary}
            onChangeOrder={this.onChangeOrder}
            order={orderedColumns}
            hasVisibleColumnsManageButton={hasVisibleColumnsManageButton}
          />
        )}
        {jeType === 'default' ? (
          <Summary
            getItem={this.getItem(floatOptions, stringOptions)}
            active={active}
            activeReference={activeReference}
          />
        ) : null}
        {jeType === 'manual_je' ? (
          <ManualSummary
            getItem={this.getItem(floatOptions, stringOptions)}
            active={active}
            activeReference={activeReference}
          />
        ) : null}
        <RequestPopup pageParams={this.getPageParams()[1]} />
        {reconciledetailsRow && (
          <Reconciliation documentID={documentID} onClose={hideRowDetails} pageParams={this.getPageParams()[1]} />
        )}
      </div>
    ) : null;
  }

  renderError() {
    const { classes, errorFromErp } = this.props;

    return errorFromErp ? <Error className={classes.error} /> : null;
  }

  render() {
    const { classes, isJELoading } = this.props;
    return (
      <>
        {isJELoading && <Spinner className={classes.spinner} loading={isJELoading} />}
        <LineHotkeyCatcher handleHotkeyMatch={this.handleChange} />
        <DocumentHotkeyCatcher handleHotkeyMatch={this.handleChange} />
        {this.renderGrid() || this.renderError()}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  activeReference: activeReferenceSelector(state),
  data: journalSelector(state),
  gridData: gridEntriesSelector(state),
  lineItems: lineItemsSelector(state),
  lines: linesSelector(state),
  settings: settingsSelector(state),
  settingsData: settingsEntriesSelector(state),
  polygons: polygonsSelector(state),
  rtl: rtlEnable(state),
  documentView: documentViewSelector(state),
  userGUID: userGUIDSelector(state),
  extraPolygon: extraPolygonSelector(state),
  reference: referenceSelector(state),
  jeType: jeTypeSelector(state),
  initialHiddenColumns: initialHiddenColumnsSelector(state),
  jeInvoiceType: jeInvoiceTypeSelector(state),
  erp: currentConnectedERPselector(state),
  lineCount: lineCountSelector(state),
  documentStatuses: documentStatusesSelector(state),
  selectedRows: selectedRowSelector(state),
  reconcileColumn: reconcileColumnSelector(state),
  notHiddenColumns: notHiddenColumnsSelector(state),
  amountColumn: amountColumnSelector(state),
  rowMessages: rowMessagesSelector(state),
  errorFromErp: errorFromErpSelector(state),
  reconciledetailsRow: reconciledetailsRowSelector(state),
  pinnedColumn: pinnedColumnSelector(state),
  orderedColumns: journalSettingsOrderedColsSelector(state),
  isJeLayoutRTL: isJeLayoutRTLSelector(state),
  journalSettingsDisabledCols: journalSettingsDisabledColsSelector(state),
  journalSettingsPinnedCols: journalSettingsPinnedColsSelector(state),
  hotkeyList: documentHotkeysSelector(state),
  getCachedPaginationList: getCachedPaginationListSelector(state),
  gridLineColumnsDictionary: jeGridLineColumnsDictionarySelector(state),
  isJELoading: isJELoadingSelector(state),
  isJEEditing: isJEEditingSelector(state),
});

const mapDispatchToProps = {
  createReference: createReferenceAction,
  onChange: updateJournalEntryAction,
  getJournalEntryPage: getJournalEntryPageAction,
  setActive: setRefernceAction,
  insertGrid: insertGridAction,
  onUpdate: updateJournalEntriesAction,
  selectRow: selectRowAction,
  showRowDetails: showRowDetailsAction,
  showReconciliationRequestDetails: showReconciliationRequestDetailsAction,
  hideRowDetails: hideRowDetailsAction,
  getJELineColumnsVisibility: getJELineColumnsVisibilityAction,
  getJournalSettingsPinnedCols: getJournalSettingsPinnedColsAction,
  getJournalSettingsOrderedCols: getJournalSettingsOrderedColsAction,
  updateJournalSettingsPinnedCols: updateJournalSettingsPinnedColsAction,
  updateJELineColumnsVisibility: updateJELineColumnsVisibilityAction,
  updateJournalSettingsOrderedCols: updateJournalSettingsOrderedColsAction,
  setLoading: setLoadingAction,
  endLoading: endLoadingAction,
  setEditing: setEditingAction,
  endEditing: endEditingAction,
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(sheet))(Tables);
