/* @flow */
import { compose } from 'redux';

type TextProccessType = (t: string) => string;
type CellType = $ReadOnly<{
  maxLength?: number,
  initValue?: ?string,
  type: 'string_auto_generated' | string,
  name: string,
  cellSet: $ReadOnly<{
    value: ?string,
  }>,
}>;
const stubText = (t: string) => t;

function textConcat(cell: CellType): TextProccessType {
  if (cell.name.startsWith('priv_doc_finID')) return stubText;
  if (cell.type === 'string_auto_generated' && cell.cellSet) {
    const { value } = cell.cellSet;
    if (typeof value === 'string' && cell.initValue !== value) {
      return (text: string) => [value, text].join(' ');
    }
    return stubText;
  }
  return stubText;
}

// eslint-disable-next-line max-len
export function prepareText<T: { +payload: { +text: string } }>(cell: CellType, data: T): T {
  return {
    ...data,
    payload: {
      ...data.payload,
      text: compose(textConcat(cell))(data.payload.text),
    },
  };
}
