/* @flow */
import * as React from 'react';
import { RecordCellSet, type RecordCellSetType, type RecordsCellType } from 'domain/journal/helper';
import type { ReferenceRecord } from 'domain/journal/types.js.flow';
import elements from 'components/elements';
import { EVENTS, type FSM$Event } from './fsm';
import type { MakeReference, CellDataGridType, CellDataType } from './types.js.flow';
import type { THotkeyList } from 'domain/documents/types.js.flow';
import { prepareText } from './textProccess';
import { Map } from 'immutable';

import Tooltip from 'components/mui/Tooltip';

import cx from 'classnames';

const POLYGON = 'POLYGON';
const MULTI_POLYGON = 'MULTI_POLYGON';
const GRID = 'GRID';

type Props = {|
  classes: {
    [key: string]: string,
  },
  isSupport: ?boolean,
  children: React$Node,
  data: RecordsCellType,
  className?: string,
  value: ?string,
  maxLength: number,
  activeReference: ?ReferenceRecord,
  createRefernce: (fn: MakeReference) => void,
  setActiveReference: (p: { id: string, params: ReferenceRecord }) => void,
  buttonRef: (e: ?HTMLElement) => void,
  handleKey: (e: SyntheticKeyboardEvent<HTMLElement>) => void,
  handleDoubleClick: () => void,
  handleFocus: () => void,
  handleClick: () => void,
  handleBlur: () => void,
  onChange: (cell: string, cellSet: RecordCellSetType) => void,
  eventCollector: (event: FSM$Event, params?: mixed) => void,
  insertGrid: (p: $PropertyType<CellDataGridType, 'payload'>, c: { id: string, name: string }) => void,
  setActiveCell: (cellId: string | null) => void,
  hintDisabled: boolean,
  reference: Map<string, ReferenceRecord>,
  hotkeyList: THotkeyList,
|};

type State = {|
  active: boolean,
|};

class MainCell extends React.Component<Props, State> {
  static defaultProps = {
    hintDisabled: false,
  };

  state: State = {
    active: false,
  };

  onMouseUp = () => {
    const { reference } = this.props;
    const ref = reference.first();
    if (ref) {
      const { type, polygonId, text } = ref;
      const dropParams =
        type === 'POLYGON'
          ? { type: 'POLYGON', payload: { id: polygonId, text } }
          : { type: 'MULTI_POLYGON', payload: { text } };
      this.handleDrop(dropParams);
    }
  };

  get preventEventForChars() {
    const { hotkeyList } = this.props;
    // 9 - tab
    const preventedChars = [9];

    hotkeyList.forEach((item) =>
      item.keys.forEach((keys) => {
        if (keys.includes('F7')) {
          preventedChars.push(118);
        }
      }),
    );
    return preventedChars;
  }

  get celleStyle() {
    return this.props.data.style.toJS();
  }

  get isActiveCall(): boolean {
    return this.state.active;
  }

  get value(): ?string {
    const { value } = this.props;
    return value || undefined;
  }

  get hint(): ?string {
    const {
      data: { hint },
    } = this.props;
    return hint || this.value;
  }

  get initialValue(): boolean {
    const { initValue, cellSet } = this.props.data;
    return cellSet.value === initValue;
  }

  handleDrop = (d: CellDataType) => {
    const { isSupport, createRefernce, data, setActiveCell } = this.props;

    if (isSupport === false) {
      this.handleDragLeave();
      return;
    }

    if (data.has('isCreatable') && (POLYGON === d.type || MULTI_POLYGON === d.type)) {
      this.props.eventCollector(EVENTS.onDrop, d.payload.text);
      setActiveCell(data._cell);
      this.handleDragLeave();
      return;
    }

    if (d.type === MULTI_POLYGON) {
      createRefernce({ cell: data._cell, text: prepareText(data, d), isMulti: true });
    }

    if (d.type === POLYGON) {
      createRefernce({ cell: data._cell, text: prepareText(data, d), isMulti: false });
    }

    if (d.type === GRID) {
      this.props.insertGrid(d.payload, { id: data._cell, name: data.name });
    }

    this.handleDragLeave();
  };

  handleDragEnter = () => {
    if (this.props.isSupport === false) return;
    const { activeReference } = this.props;
    const { active } = this.state;
    if (!active && activeReference) {
      this.setState({ active: true });
    }
  };

  handleDragLeave = () => {
    if (this.props.isSupport === false) return;
    this.setState({ active: false });
    const {
      activeReference,
      setActiveReference,
      data: { _cell },
    } = this.props;
    if (activeReference && activeReference.cell === _cell) {
      const params: ReferenceRecord = activeReference.set('x2', null).set('y2', null);
      setActiveReference({ id: params.id, params });
    }
  };

  handleChange = (event: SyntheticInputEvent<HTMLInputElement>) => {
    const { data, onChange, maxLength } = this.props;
    const {
      currentTarget: { value },
    } = event;
    const newValue = value.slice(0, maxLength);
    if (data.type === 'index_list' || data.type === 'paginated_list') {
      this.props.eventCollector(EVENTS.onPast, newValue);
    } else if (data.cellSet.value !== newValue) {
      onChange(data._cell, RecordCellSet({ value: newValue === '' ? null : newValue }));
    }
  };

  element: ?HTMLElement;

  proxyFocus = (e: SyntheticInputEvent<HTMLInputElement>) => {
    const { value } = this.props;
    this.props.handleFocus();
    if (typeof value === 'string') {
      e.currentTarget.setSelectionRange(0, value.length);
    }
  };

  proxyRef = (el: ?HTMLElement) => {
    this.props.buttonRef(el);
    if (el) {
      this.element = el;
    }
  };

  makeActive = (el: HTMLElement) => {
    const {
      activeReference,
      setActiveReference,
      data: { _cell },
    } = this.props;
    const { left, top, height, width } = el.getBoundingClientRect();
    if (activeReference) {
      const params: ReferenceRecord = activeReference
        .set('x2', left + width / 2)
        .set('y2', top + height / 2)
        .set('cell', _cell);
      setActiveReference({ id: params.id, params });
    }
  };

  handleKey = (e: SyntheticKeyboardEvent<HTMLElement>) => {
    if (this.preventEventForChars.includes(e.keyCode)) {
      e.preventDefault();
    }
    this.props.handleKey(e);
  };

  render() {
    const { classes, data, className, maxLength, value, children } = this.props;
    return (
      <div
        role="button"
        tabIndex="0"
        onMouseOver={this.handleDragEnter}
        onMouseEnter={this.handleDragEnter}
        onMouseLeave={this.handleDragLeave}
        onMouseUp={this.onMouseUp}
        onFocus={(e) => e.preventDefault()}
        className={cx(classes.fieldWrapper, className, 'droppable')}
      >
        {/* TODO uncomment after fix performance */}
        <Tooltip t={data.hint || this.value} disableFocusListener>
          <input
            type="text"
            value={this.value}
            className={cx(classes[data.type], classes.hiddenCell)}
            ref={this.proxyRef}
            maxLength={maxLength}
            onFocus={this.proxyFocus}
            onBlur={this.props.handleBlur}
            onClick={this.props.handleClick}
            onDoubleClick={this.props.handleDoubleClick}
            onKeyDown={this.handleKey}
            onChange={this.handleChange}
            data-element={elements.je.fakeCell.container}
            data-element-id={`${data._cell}-${data.type}`}
          />
        </Tooltip>

        <div
          className={cx(classes[data.type], classes.fakeCell, {
            [classes.dragAvailable]: this.isActiveCall,
            [classes.initValue]: this.initialValue,
          })}
          style={this.celleStyle}
          data-element={elements.je.fakeCell.value}
        >
          {value}
        </div>
        {children}
      </div>
    );
  }
}

export default MainCell;
