/* @flow */
import React, { useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import { List } from 'immutable';
import { ERP_NAMES } from 'domain/companies/helpers';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';

import type { erpNamesType } from 'domain/companies/types.js.flow';

type Props = {|
  label: string,
  formats?: List<string>,
  uploadFile: (f: File) => void,
  erp: erpNamesType,
  disabled?: boolean,
|};

const InputFile = ({ label, formats, disabled, uploadFile, erp }: Props) => {
  const [fileName, setFileName] = useState(undefined);

  const handleUpload = useCallback(
    (e: SyntheticInputEvent<HTMLInputElement>) => {
      if (e.currentTarget.files.length) {
        const file = e.currentTarget.files[0];
        const { name, lastModified } = file;

        new Promise((resolve, reject) => {
          uploadFile({ file, resolve, reject });
        }).then(() => {
          const date = moment(lastModified).format('DD-MM-YYYY');
          const fileName = erp === ERP_NAMES.defaultErp ? [date, name] : [date, erp, name];
          setFileName(fileName.join(' '));
        });
      }
    },
    [erp, uploadFile],
  );

  const accept = formats && formats.length ? formats.join(',') : '*';

  return (
    <Stack spacing={0.5} direction="row" alignItems="center">
      {fileName ? (
        <>
          <InsertDriveFileIcon color="primary" />
          <Typography variant="body2" component="label">
            {fileName}
            <input hidden type="file" accept={accept} onChange={handleUpload} value="" />
          </Typography>
        </>
      ) : (
        <>
          <Typography variant="body2">{label}</Typography>
          <Button sx={{ p: 0 }} variant="text" component="label" disabled={disabled}>
            <input hidden type="file" accept={accept} onChange={handleUpload} value="" />
            <FormattedMessage id="forms.common.import" defaultMessage="Import" />
          </Button>
        </>
      )}
    </Stack>
  );
};

export default InputFile;
