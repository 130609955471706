// @flow
import { call } from 'redux-saga/effects';

import { logoutAction } from 'domain/api';
import * as actions from './actions';
import { messagesFactory } from './adapters';

export const ensureWorkerGetMessagesCreator = () =>
  function* ensureWorkerGetMessages() {
    // eslint-disable-next-line require-yield
    function* prepare() {
      return {};
    }

    return {
      apiName: 'getMessages',
      interval: 30000,
      config: {
        params: yield call(prepare),
      },
      prepare,
      success(data) {
        return {
          type: actions.getMessagesAction.success,
          payload: messagesFactory(data),
        };
      },
      failure(err) {
        return logoutAction(actions.getMessagesAction, err);
      },
    };
  };
