import mediaQuery from 'lib/mediaQuery';
import noMessages from './noMessages.svg';

export default (theme) => ({
  container: {
    flex: [1, 1, '100%'],
    minHeight: 0,
    position: 'relative',
    height: 'calc(100% - 50px)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    [`@media (max-height: ${mediaQuery.maxHeight})`]: {
      height: 'calc(100% - 35px)',
    },
  },
  field: {
    margin: [20, 0],
    resize: 'none',
    width: '100%',
    fontSize: 14,
    color: '#171A1C',
    border: 0,
    padding: [0, 65, 0, 6],
    '&:focus': {
      outline: 'none',
    },
  },
  form: {
    display: 'flex',
    alignItems: 'center',
    borderTop: '1px solid #D3D6D9',
    position: 'relative',
  },
  send: {
    position: 'absolute',
    right: 11,
    top: 0,
    bottom: 0,
    width: 50,
    height: 40,
    margin: 'auto',
    border: '0 none',
    cursor: 'pointer',
    color: theme.colors.primaryLight,
    outline: 'none',
    '&[disabled]': {
      color: theme.colors.primaryLight,
      cursor: 'wait',
    },
    [`@media (max-height: ${mediaQuery.maxHeight})`]: {
      height: 30,
      fontSize: 12,
    },
  },
  // messages
  noMessage: {
    textAlign: 'center',
    color: '#828282',
    fontSize: 15,
    height: '100%',
    [`@media (max-height: ${mediaQuery.maxHeight})`]: {
      fontSize: 12,
    },
    backgroundSize: '70px 70px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& > span': {
      width: 140,
      height: 140,
      background: `url("${noMessages}") scroll no-repeat center center`,
      paddingTop: 120,
    },
  },
  spinner: {
    position: 'absolute',
  },
  messagesScroll: {
    flex: [1, 1, '100%'],
    overflowY: 'auto',
    height: '100%',
  },

  messages: {
    minHeight: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },

  noMessages: {
    justifyContent: 'center',
  },

  list: {
    padding: [0, 10],
  },
  item: {
    padding: [5, 0, 10],
  },
  userAva: {
    flex: [0, 0, '30px'],
  },
  messageContainer: {
    display: 'flex',
    alignItems: 'center',
    maxWidth: '80%',
    position: 'relative',
  },
  messageInfo: {
    flex: [1, 1, '100%'],
    marginLeft: 10,
  },
  ownMessage: {
    maxWidth: '80%',
    margin: [0, 0, 0, 'auto'],
    backgroundColor: theme.colors.primaryLight,
    borderRadius: [10, 10, 0, 10],
    color: '#fff',
    padding: [10, 12, 6, 12],
    fontSize: 14,
    fontWeight: 400,
    wordWrap: 'break-word',
    whiteSpace: 'pre-wrap',
    [`@media (max-height: ${mediaQuery.maxHeight})`]: {
      fontSize: 12,
      padding: [5, 10, 7, 6],
      '& > div:last-child': {
        fontSize: 10,
      },
    },
    '& > div:last-child': {
      fontSize: 12,
      textAlign: 'right',
      color: '#93B4E7',
    },
  },
  foreignMessage: {
    fontSize: 14,
    color: '#4F4F4F',
    backgroundColor: theme.colors.greyLight,
    padding: [6, 10, 5, 12],
    margin: [0, 'auto', 0, 0],
    borderRadius: [10, 10, 10, 0],
    wordWrap: 'break-word',
    whiteSpace: 'pre-wrap',
    [`@media (max-height: ${mediaQuery.maxHeight})`]: {
      fontSize: 12,
      padding: [5, 7, 7, 6],
      '& > div:last-child': {
        fontSize: 10,
      },
    },
    '& > div:last-child': {
      fontSize: 12,
      textAlign: 'right',
      color: theme.colors.greyDarker,
    },
  },
  ownerName: {
    color: '#828282',
    fontSize: 12,
  },
  period: {
    extend: 'ownerName',
    display: 'flex',
    position: 'relative',
    justifyContent: 'center',
    margin: [10, 0],
    '& > span': {
      backgroundColor: '#FFF',
      padding: [0, 10],
    },
    '&:after': {
      content: '""',
      position: 'absolute',
      bottom: 0,
      left: 0,
      right: 0,
      height: '0.5em',
      color: theme.colors.greyDark,
      borderTop: '1px solid',
      zIndex: -1,
    },
  },
});
