// @flow
import { asyncAction, syncAction } from 'lib/action';

export const getJournalEntryAction = asyncAction('JOURNAL/GET_ENTRY');
export const getJournalEntryPageAction = asyncAction('JOURNAL/GET_PAGE_ENTRY');
export const updateJournalEntryAction = asyncAction('JOURNAL/UPDATE_ENTRY');
export const updateJEFromInsightsAction = asyncAction('JOURNAL/UPDATE_ENTRY_FROM_INSIGHTS');
export const updateJournalEntriesAction = syncAction('JOURNAL/UPDATE_ENTRIES');
export const getTextMapAction = asyncAction('JOURNAL/GET_TEXT_MAP');
export const getJEListAction = asyncAction('JOURNAL/GET_LIST');
export const getJEIndexListItemDataAction = asyncAction('JOURNAL/GET_INDEX_LIST_ITEM_DATA');

export const setRefernceAction = syncAction('JOURNAL/SET_REFERENCE');
export const setExtraPolygonAction = syncAction('JOURNAL/SET_EXTRA_POLYGON');
export const clearExtraPolygonAction = syncAction('JOURNAL/CLEAR_EXTRA_POLYGON');
export const createReferenceAction = syncAction('JOURNAL/CREATE_REFERENCE');
export const clearReferenceAction = syncAction('JOURNAL/CLEAR_REFERENCE');
export const clearJEMessagesAction = syncAction('JOURNAL/CLEAR_JE_MESSAGES');
export const forceErpSyncAction = syncAction('JOURNAL/FORCE_ERP_SYNC');
export const insertGridAction = asyncAction('JOURNAL/INSERT_GRID');
export const resetMapTextAction = syncAction('JOURNAL/RESET_MAP_TEXT');
export const selectRowAction = syncAction('JOURNAL/SELECT_ROW');
export const updateCurrentPaginationListAction = syncAction('JOURNAL/UPDATE_CURRENT_PAGINATION_LIST');
export const clearJournalEntryAction = syncAction('JOURNAL/CLEAR_JE');

export const getJournalEntryForGridAction = asyncAction('JOURNAL/GET_ENTRY_FOR_GRID_item');

export const bulkCommitAction = asyncAction('JOURNAM/BULK_COMMIT');
export const getReconcileStatisticAction = asyncAction('JOURNAL/GET_RECONCILATE_STAT');

// JOURNAL_SETTINGS
export const getJournalSettingsPinnedColsAction = asyncAction('JOURNAL_SETTINGS/GET_PINNED_COLS');
export const updateJournalSettingsPinnedColsAction = asyncAction('JOURNAL_SETTINGS/UPDATE_PINNED_COLS');
export const getJELineColumnsVisibilityAction = asyncAction('JOURNAL_SETTINGS/GET_LINE_COLUMNS_VISIBILITY');
export const updateJELineColumnsVisibilityAction = asyncAction('JOURNAL_SETTINGS/UPDATE_LINE_COLUMNS_VISIBILITY');
export const getJournalSettingsOrderedColsAction = asyncAction('JOURNAL_SETTINGS/GET_ORDERED_COLS');
export const updateJournalSettingsOrderedColsAction = asyncAction('JOURNAL_SETTINGS/UPDATE_ORDERED_COLS');

// LOADING
export const setLoadingAction = syncAction('JOURNAL/SET_LOADING');
export const endLoadingAction = syncAction('JOURNAL/END_LOADING');

// EDITING
export const setEditingAction = syncAction('JOURNAL/SET_EDITING');
export const endEditingAction = syncAction('JOURNAL/END_EDITING');
