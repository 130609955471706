// @flow
import React from 'react';
import { FormattedMessage } from 'react-intl';
import elements from 'components/elements';

import { ButtonGroupStyled } from 'pages/company/DocumentPreview/components/StyledComponents';
import Button from '@mui/material/Button';
import RouterLink from 'components/mui/Router/RouterLink';

import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';
import ArrowDropUpOutlinedIcon from '@mui/icons-material/ArrowDropUpOutlined';

type TButtonGroup = {
  onClickLink: () => void,
  href: string,
  withContext: boolean,
  onClickContextMenu?: (e: MouseEvent) => void,
  isContextMenuOpen: boolean,
  contextMenuRef: any, // dont know correct type
};

const PreviewActionGroup: React$StatelessFunctionalComponent<TButtonGroup> = ({
  onClickLink,
  href,
  withContext,
  onClickContextMenu,
  isContextMenuOpen,
  contextMenuRef,
}) => {
  const handleContextMenuClick = (e: MouseEvent) => {
    onClickContextMenu(e);
  };

  return (
    <ButtonGroupStyled
      variant="contained"
      color="primary"
      data-element={elements.content.documents.preview.actions.group}
    >
      <Button
        classes={{ root: 'previewAction-link' }}
        component={RouterLink}
        href={href}
        onClick={onClickLink}
        data-element={elements.content.documents.preview.actions.open}
      >
        <FormattedMessage id="documents.item.preview.open" defaultMessage="Open" />
      </Button>
      {withContext && (
        <Button
          classes={{ root: 'previewAction-button' }}
          size="small"
          onClick={handleContextMenuClick}
          ref={contextMenuRef}
          data-element={elements.content.documents.preview.actions.context}
        >
          {isContextMenuOpen ? <ArrowDropUpOutlinedIcon /> : <ArrowDropDownOutlinedIcon />}
        </Button>
      )}
    </ButtonGroupStyled>
  );
};

export default PreviewActionGroup;
