/* @flow */
import { List } from 'immutable';
import * as A from './actions';
import { hideReconciliationRequestDetailsAction } from 'domain/reconciliation/actions';
import { MessageHistoryFactory, MessageFactory } from './adapters';

export const reducer = {
  requestTransaction(state = MessageHistoryFactory(), action: Action) {
    switch (action.type) {
      case A.getDocumentMessagingHistory.success:
        return state.merge(action.payload);

      case A.getTransactionMessagingHistory.success:
        return state.set('bookkeeperMessages', action.payload);

      // clear messages once request popup is closed
      // otherwise it willl render messaging when opened
      // for create transaction
      case hideReconciliationRequestDetailsAction.type:
        return state.set('bookkeeperMessages', new List());

      case A.sendMessageAction.request:
        return state.updateIn(['transactions', action.payload.transactionId], (t) =>
          t.set('status', 'read').update('messages', (m) =>
            m.push(
              MessageFactory({
                firstName: state.firstName,
                lastName: state.lastName,
                ...action.payload,
              }),
            ),
          ),
        );

      default:
        return state;
    }
  },
};
