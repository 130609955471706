/* @flow */
import React from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import { Reactotron, reduxPlugin, sagaPlugin } from 'lib/ReactotronConfig';
import { Provider } from 'react-redux';
import { IntlProvider } from 'react-intl';
import { LocalizationProvider, LicenseInfo } from '@mui/x-date-pickers-pro';
import { AdapterMoment } from '@mui/x-date-pickers-pro/AdapterMoment';
import { configureStore } from 'domain/store';
import intl from 'translation';
import { storage } from 'lib/storage';
import WithDragDropContextProvider from 'lib/dndProvider';
import { getResponseInterceptors } from 'lib/apiHelpers';
import { registerAppSwitcherTabActivationEventHandler } from 'lib/appSwitcher';
import ConnectionHealthService from 'lib/ConnectionHealthService';
import 'moment/locale/he';
import 'moment/locale/es';
import 'moment/locale/it';
import 'moment/locale/fr';
import { registerSW } from 'serviceWorkerRegistration';

import LabeledThemeProvider from 'components/LabeledThemeProvider';
import { OutsideTooltipProvider } from './components/OutsideTooltip';

import Labels from './labels';

// Azure App Insights
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';

import router from 'domain/router/router';
import initLocationChangeListener from 'domain/router/redux/locationChangeListener';
import rootSaga from './sagas';
import RootRoute from 'pages/RootRoute';
import { ErrorBoundary, notify } from 'lib/errorLogger';

import tokenKeeper from 'lib/apiTokenKeeper';

// handles auth events
import initCognitoEventHub from 'amplify/initializer';

const reactotronEnabled = storage().reactotronEnabled().get();
if (reactotronEnabled) {
  Reactotron.configure({ name: 'Dokka app' }).use(reduxPlugin()).use(sagaPlugin()).connect();
}

// !!! PAY ATTENTION - DON CHANGE ORDER store -> tokenKeeper -> runSaga -> location listener
const store = configureStore(router.navigate);
tokenKeeper.injectStore(store);
store.runSaga(rootSaga, store.dispatch);

const telemetryInitializer = (envelope) => {
  // eslint-disable-next-line no-param-reassign
  envelope.data.releaseVersion = process.env.REACT_APP_BUILD_VERSION;
};
// handles Cognito Authorization
initCognitoEventHub(store);
// register tab activation handler for app switcher
registerAppSwitcherTabActivationEventHandler();
// CONNECTION HEALTH SERVICE - detect slow connection for users
ConnectionHealthService(store.dispatch);
// Azure App Insights
const reactPlugin = new ReactPlugin();

const instrumentationKey = process.env.REACT_APP_AZURE_APP_INSIGHTS_KEY;

const ai = new ApplicationInsights({
  config: {
    enableAutoRouteTracking: true,
    instrumentationKey,
    extensions: [reactPlugin],
  },
});
ai.loadAppInsights();
ai.addTelemetryInitializer(telemetryInitializer);
// Azure App Insights

console.warn('App version:', process.env.REACT_APP_BUILD_VERSION);

axios.interceptors.response.use(...getResponseInterceptors(store));

const AppWithDnDContext = WithDragDropContextProvider(RootRoute);
const rootNode = document.getElementById('root');

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_DATEPICKER_LICENSE);

intl(store)
  .then(({ locale, translation }) => {
    ReactDOM.render(
      <Provider store={store}>
        <IntlProvider locale={locale} defaultLocale="en" messages={translation} textComponent="span">
          <Labels>
            {/* TODO: OutsideTooltipProvider check after facelifting */}
            <OutsideTooltipProvider>
              <LabeledThemeProvider>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <ErrorBoundary logger={notify.captureEvent}>
                    <AppWithDnDContext />
                  </ErrorBoundary>
                </LocalizationProvider>
              </LabeledThemeProvider>
            </OutsideTooltipProvider>
          </Labels>
        </IntlProvider>
      </Provider>,
      rootNode,
    );
  })
  .catch((err) => {
    // @see https://stackoverflow.com/questions/18391212/is-it-not-possible-to-stringify-an-error-using-json-stringify
    throw new Error(`Error loading translation or locale ${JSON.stringify(err, Object.getOwnPropertyNames(err))}`);
  });

export const locationListener = initLocationChangeListener(store, router);
export const appInsights = ai;

registerSW();
