import React from 'react';
import useMoveTo from 'pages/company/CompanyBarPanel/components/IconButtonMoveTo/useMoveTo';

import TooltipForButton from 'components/mui/Tooltip/TooltipForButton';
import IconButton from '@mui/material/IconButton';

import ArchiveIcon from '@mui/icons-material/Archive';

const IconButtonMoveTo = () => {
  const { onClick, archiveCategory, t, isDisabled } = useMoveTo();

  return archiveCategory ? (
    <TooltipForButton t={t}>
      <IconButton color="primary" onClick={onClick} disabled={isDisabled}>
        <ArchiveIcon />
      </IconButton>
    </TooltipForButton>
  ) : null;
};

export default IconButtonMoveTo;
