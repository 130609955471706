import ModalMui from '@mui/material/Modal';
import IconButton from '@mui/material/IconButton';
import ButtonGroupMui from '@mui/material/ButtonGroup';
import { alpha, styled } from '@mui/material/styles';

export const Modal = styled(ModalMui, { shouldForwardProp: (propName) => propName !== 'offset' })(({ offset }) => ({
  right: offset,
  outline: 'none',
  display: 'flex',
  alignItems: 'center',
  height: '100vh',

  '& .MuiBackdrop-root': {
    right: offset,
  },
}));

export const ModalCloseButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  right: theme.spacing(-6),
  top: theme.spacing(-1),
  color: theme.palette.common.white,
}));

export const PreviewContent = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
  boxSizing: 'content-box',
  margin: '0 auto',
  borderWidth: 6,
  borderStyle: 'solid',
  borderColor: 'transparent',

  '&.PreviewContent-isLinked': {
    borderColor: theme.palette.primary.main,
  },

  '&.PreviewContent-isPaid': {
    borderColor: theme.palette.secondary.main,
  },
}));

export const PreviewNavigation = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flex: '0 0 auto',
  padding: theme.spacing(0, 1.5),
}));

export const PreviewNavigationButton = styled(IconButton)(({ theme }) => ({
  boxShadow: theme.shadows[5],
  backgroundColor: theme.palette.common.white,
  color: theme.palette.grey[600],

  '& .MuiSvgIcon-root': {
    fontSize: theme.typography.pxToRem(48),
  },

  '&:hover': {
    backgroundColor: theme.palette.common.white,
    boxShadow: theme.shadows[10],

    '& .MuiSvgIcon-root': {
      color: theme.palette.primary.main,
    },
  },

  '&.Mui-disabled': {
    backgroundColor: theme.palette.grey[500],
  },
}));

export const PreviewCounter = styled('div')(({ theme }) => ({
  position: 'absolute',
  bottom: theme.spacing(1),
  left: '50%',
  transform: 'translate(-50%, 0%)',
  backgroundColor: alpha(theme.palette.grey[600], 1),
  padding: theme.spacing(0.5, 1.5),
  color: theme.palette.common.white,
  borderRadius: 16,
  whiteSpace: 'nowrap',
  zIndex: 1, // overlap image
}));

export const PreviewBadges = styled('div')(() => ({
  display: 'inline-flex',
  alignItems: 'flex-start',
  position: 'absolute',
  top: 0,
  left: 0,
}));

export const LinkedBadgeStyled = styled('div')(({ theme }) => ({
  display: 'inline-flex',
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  padding: theme.spacing(1, 0.5),
  marginLeft: theme.spacing(1),
  borderRadius: '0 0 4px 4px',
}));

export const PaidBadgeStyled = styled('div')(({ theme }) => ({
  display: 'inline-flex',
  backgroundColor: theme.palette.secondary.main,
  color: theme.palette.common.white,
  padding: theme.spacing(0.5, 1.5),
  fontSize: theme.typography.pxToRem(14),
  fontWeight: 500,
  borderRadius: '0 0 4px 0',
}));

export const ButtonGroupStyled = styled(ButtonGroupMui)(({ theme }) => ({
  // we have glitch/bug UI on border separator when absolute position and percentage translation are used together
  // don't know how to fix it now
  position: 'absolute',
  top: 0,
  left: '50%',
  transform: 'translate(-50%, -50%)',

  // fix for ButtonGroup behaviour - default css styles work for :first-of-type, :last-of-type
  // but in this group we are use RouterLink component - its doesn't work with selectors above
  // cant use first-child last-child - console error
  '& .MuiButtonGroup-grouped': {
    '&.previewAction-link': {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
      borderRight: `1px solid ${theme.palette.primary.dark}`,

      '&:only-child': {
        border: 'none',
        borderRadius: 100,
      },
    },
    '&.previewAction-button': {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
    },
  },
}));
