/* @flow */
import React from 'react';
import { createMemoFn } from 'lib/propHelpers';
import { createOrderMap, move } from 'lib/array';
import { FormattedMessage } from 'react-intl';
import type { JournalSettingOrder } from 'domain/journal/types.js.flow';

import ColumnOption from 'components/Tables/layout/grid/VisibilityColsDropdown/ColumnOption';
import ColSortableList from './colSortableList';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';
import Menu from '@mui/material/Menu';
import ListItem from '@mui/material/ListItem';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';

import AddCircleIcon from '@mui/icons-material/AddCircle';
import CancelIcon from '@mui/icons-material/Cancel';

import { styled } from '@mui/material/styles';
import { withStyles } from '@mui/styles';
import sheet from './sheet';

type Cols = Array<string>;

export type NamedCol = {|
  colName: string,
  title: string,
|};

export type NamedCols = Array<NamedCol>;

type Props = {
  classes: {|
    [key: string]: string,
  |},
  namedCols: NamedCols,
  pinnedCols: Cols,
  forcedEnabledCols: Cols,
  disabledCols: Cols,
  onChange: (c: Cols, p: boolean) => void,
  onResetColumnsWidth?: () => void,
  onChangeOrder: (c: Cols, p: boolean) => void,
  order: JournalSettingOrder,
};

type State = {
  cacheOrder: JournalSettingOrder | null,
};

const JELineColumnsManageDropdown = styled('div', { label: 'JELineColumnsManageDropdown' })(() => ({
  position: 'absolute',
  top: 0,
  right: -28,
  zIndex: 10,
}));

class ColsDropdown extends React.Component<Props, State> {
  constructor() {
    super();
    this.state = {
      cacheOrder: null,
    };
  }

  componentDidUpdate(prevProps: Readonly<P>) {
    const { order } = this.props;
    const { cacheOrder } = this.state;

    if (prevProps.order !== order && cacheOrder) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ cacheOrder: null });
    }
  }

  onChange = (colName: string) => () => {
    const { onChange, disabledCols } = this.props;
    const newDisabledCols = disabledCols.includes(colName)
      ? disabledCols.filter((c) => c !== colName)
      : [...disabledCols, colName];
    onChange(newDisabledCols);
  };

  getNewOrder = ({ oldIndex: from, newIndex: to }, order, list) => {
    if (from !== to) {
      const listOrder = list.map((i) => i.colName);

      const item = listOrder[from];

      // order columns in order map
      const newOrder = move(listOrder, from, to);

      // define already ordering columns
      const existsCols = order.filter((c) => c !== '*');

      // add new ordered column
      const nextExistsCols = existsCols.includes(item) ? existsCols : [...existsCols, item];

      // extract new ordering map from new columns order
      return createOrderMap(newOrder, nextExistsCols);
    }
    return order;
  };

  onSortEndPinned = (arg) => {
    const { order, onChangeOrder } = this.props;
    const newOrder = this.getNewOrder(arg, order.pinned, this.getPinnedCols());
    onChangeOrder(order.set('pinned', newOrder));
  };

  onSortFloatPinned = (arg) => {
    const { order, onChangeOrder } = this.props;
    const newOrder = this.getNewOrder(arg, order.float, this.getFloatCols());
    onChangeOrder(order.set('float', newOrder));
  };

  getPinnedCols = createMemoFn(
    () => {
      const { namedCols, pinnedCols } = this.props;
      return { namedCols, pinnedCols };
    },
    ({ namedCols, pinnedCols }) => {
      const cols = namedCols.filter((nCol) => pinnedCols.includes(nCol.colName));
      return cols;
    },
  );

  getFloatCols = createMemoFn(
    () => {
      const { namedCols, pinnedCols } = this.props;
      return { namedCols, pinnedCols };
    },
    ({ namedCols, pinnedCols }) => namedCols.filter((nCol) => !pinnedCols.includes(nCol.colName)),
  );

  isSelected = (colName: string) => {
    const { disabledCols, pinnedCols } = this.props;
    return !disabledCols.includes(colName) || pinnedCols.includes(colName);
  };

  list = (filteredNamedCols: NamedCols, isPinned) => {
    const { classes, pinnedCols, forcedEnabledCols } = this.props;
    const countSelected = filteredNamedCols.reduce((res, nCol) => (this.isSelected(nCol.colName) ? res + 1 : res), 0);
    const onSortEnd = isPinned ? this.onSortEndPinned : this.onSortFloatPinned;
    return (
      <ColSortableList items={filteredNamedCols} helperClass={classes.helper} onSortEnd={onSortEnd} useDragHandle>
        {(nCol) => {
          const checked = this.isSelected(nCol.colName);
          const isDisabled =
            (checked && !isPinned && countSelected === 1) ||
            forcedEnabledCols.includes(nCol.colName) ||
            pinnedCols.includes(nCol.colName);

          return (
            <ColumnOption
              column={nCol}
              isPinned={isPinned}
              checked={checked}
              isDisabled={isDisabled}
              onChange={this.onChange(nCol.colName)}
            />
          );
        }}
      </ColSortableList>
    );
  };

  render() {
    const { onResetColumnsWidth } = this.props;

    const pinedNamedCols = this.getPinnedCols();
    const floatNamedCols = this.getFloatCols();

    return (
      <PopupState variant="popover" popupId="jeColumnsManageMenu">
        {(popupState) => (
          <JELineColumnsManageDropdown>
            <IconButton size="small" color="primary" {...bindTrigger(popupState)}>
              {popupState.isOpen ? <CancelIcon fontSize="18" /> : <AddCircleIcon fontSize="18" />}
            </IconButton>
            <Menu
              {...bindMenu(popupState)}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
              anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
              slotProps={{ paper: { sx: { maxHeight: 450 } } }}
              MenuListProps={{ component: 'div' }}
            >
              {onResetColumnsWidth && (
                <ListItem component="div">
                  <Button variant="outlined" fullWidth size="small" onClick={onResetColumnsWidth}>
                    <FormattedMessage id="document.show.journalEntry.autoSize" defaultMessage="Autosize columns" />
                  </Button>
                  <Divider />
                </ListItem>
              )}
              {!!pinedNamedCols.length && this.list(pinedNamedCols, true)}
              {!!pinedNamedCols.length && !!floatNamedCols.length && <Divider component="div" />}
              {!!floatNamedCols.length && this.list(floatNamedCols, false)}
            </Menu>
          </JELineColumnsManageDropdown>
        )}
      </PopupState>
    );
  }
}

ColsDropdown.displayName = 'ColsDropdown';

export default withStyles(sheet)(ColsDropdown);
