// @flow
import * as React from 'react';
import cx from 'classnames';
import { connect } from 'react-redux';
import { withStyles } from '@mui/styles';
import { compose } from 'redux';
import { debounce } from 'throttle-debounce';
import { chatAllUsersSelector } from 'domain/chat/chatSelector';
import type { ChatUserRecords } from 'domain/chat/types.js.flow';
import { documentGetTagsSuggestions } from 'domain/documents';
import { FormattedMessage, injectIntl } from 'react-intl';
import UserPic from 'components/UserPic/UserPic';
import { customArrayFlat } from 'lib/helpers';
import sheet from './sheet';

type Props = {
  loadSuggestions: Function,
  users: ChatUserRecords,

  classes: {
    [key: string]: string,
  },
  className?: string,
  onSelect: (t: string) => void,
  onInputChange?: (e: SyntheticInputEvent<HTMLInputElement>) => void,
  excluded?: string[],
  inputClassname?: string,
  inputPlaceholder?: string,
  inputValue?: string,
  requirePrefix?: string,
  prefixOutputWithHashtag?: boolean,
};

type State = {
  suggestions: Array<string>,
  isOpen: boolean,
  active: ?string,
};

// TODO: remove after document page redesign
class TagSuggestions extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      suggestions: [],
      isOpen: false,
      active: null,
    };
  }

  componentDidUpdate(prevProps: Props): * {
    if (!this.props.inputValue && this.props.inputValue !== prevProps.inputValue) {
      this.clearSuggestions();
    }
    if (!this.isAllowedSuggestion() && this.state.suggestions.length) {
      this.clearSuggestions();
    }
  }

  onKeyDown = (e: KeyboardEvent) => {
    const { keyCode } = e;

    if (keyCode === 13) {
      this.onConfirm();
    }

    if (keyCode === 40) {
      this.onPreselectNext();
    }

    if (keyCode === 38) {
      this.onPreselectPrev();
    }
  };

  onConfirm = () => {
    // this.state.active is set when we have tags suggestions selected,
    // meaning we are seting tag, not pure text, so text must be
    const confirmValue = this.state.active ? this.stringToTag(this.state.active) : this.props.inputValue;
    this.onSelect(confirmValue);
  };

  onPreselectPrev = () => {
    const { active, suggestions } = this.state;
    if (suggestions.length) {
      const currentIndex = suggestions.indexOf(active);
      const prevIndex = currentIndex <= 0 ? suggestions.length - 1 : currentIndex - 1;
      this.setActive(suggestions[prevIndex]);
    }
  };

  onPreselectNext = () => {
    const { active, suggestions } = this.state;
    if (suggestions.length) {
      const currentIndex = suggestions.indexOf(active);
      const nextIndex = currentIndex >= suggestions.length - 1 ? 0 : currentIndex + 1;
      this.setActive(suggestions[nextIndex]);
    }
  };

  onInputChange = (value: string | null) => {
    if (value) {
      this.loadSuggestions(value);
    } else {
      this.clearSuggestions();
    }
  };

  onMouseSelect = (e: MouseEvent, tag: string) => {
    e.preventDefault();
    e.stopPropagation();
    this.onSelect(this.stringToTag(tag));
  };

  onSelect = (tag: ?string) => {
    /* const { excluded = [] } = this.props; */
    if (tag /* && !excluded.includes(tag) */) {
      this.closeSuggestions();
      this.props.onSelect(tag);
    }
  };

  getUsers = () => this.props.users.reduce((res, user) => ({ ...res, [user.userId]: user }), {});

  getSeparatedSuggestions = () => this.separateSuggestions(this.state.suggestions);

  setListRef = (el: HTMLDivElement) => {
    this.listBoxEl = el;
  };

  setListItemRef = (tag: string) => (el: HTMLLIElement) => {
    this.listBoxItemsEl[tag] = el;
  };

  setActive = (tag) => {
    this.setState({ active: tag });
    this.setScrollPosition(tag);
  };

  setScrollPosition = (tag: string) => {
    const currentEl = this.listBoxItemsEl[tag];
    if (this.state.isOpen && currentEl) {
      const listRect = this.listBoxEl.getBoundingClientRect();
      const itemRect = currentEl.getBoundingClientRect();

      const listTop = listRect.top;
      const listBottom = listTop + listRect.height;
      const itemTop = itemRect.top;
      const itemBottom = itemTop + itemRect.height;

      const MARGIN = 3;
      let scrollShift = 0;
      if (listTop > itemTop) {
        scrollShift = itemTop - listTop - MARGIN;
      } else if (itemBottom > listBottom) {
        scrollShift = itemBottom - listBottom + MARGIN;
      }

      this.listBoxEl.scrollTop = this.listBoxEl.scrollTop + scrollShift;
    }
  };

  stringToTag = (tag: string): string => (this.props.prefixOutputWithHashtag ? `#${tag}` : tag);

  isAllowedSuggestion = () => {
    const { inputValue, requirePrefix } = this.props;
    return requirePrefix ? inputValue.startsWith(requirePrefix) : true;
  };

  clearSuggestions = () => {
    this.setState({ suggestions: [], active: null });
  };

  separateSuggestions = (suggestions) => {
    const systemUsers = this.getUsers();
    return suggestions.reduce(
      ([users, tags], suggestion) =>
        systemUsers[suggestion] ? [[...users, suggestion], tags] : [users, [...tags, suggestion]],
      [[], []],
    );
  };

  loadSuggestions = debounce(500, (value: string) => {
    if (this.isAllowedSuggestion()) {
      const promise = new Promise((resolve, reject) => this.props.loadSuggestions({ prefix: value, resolve, reject }));
      promise.then((response) => {
        const res = response.map((tag) => Object.keys(tag).join(''));
        const suggestions = customArrayFlat(this.separateSuggestions(res));

        const active = suggestions.length ? suggestions[0] : null;
        this.setState({ suggestions, active });
      });
    }
  });

  handleInputChange = (e: SyntheticInputEvent<HTMLInputElement>) => {
    const { onInputChange = (x) => x } = this.props;
    onInputChange(e);
    const { value } = e.currentTarget;
    this.onInputChange(value);
    this.openSuggestions();
  };

  openSuggestions = () => {
    if (!this.state.isOpen) {
      this.setState({ isOpen: true });
    }
  };

  closeSuggestions = () => {
    if (this.state.isOpen) {
      this.setState({ isOpen: false });
    }
  };

  listBoxEl: ?HTMLDivElement = null;

  listBoxItemsEl: { [key: string]: string } = {};

  render() {
    const { classes, inputClassname, inputPlaceholder, className, inputValue } = this.props;
    const { suggestions, isOpen, active } = this.state;
    const [userSuggestions, tagSuggestions] = this.getSeparatedSuggestions();
    const users = this.getUsers();

    return (
      <div className={cx(classes.body, className)}>
        <div>
          <input
            className={cx(classes.input, inputClassname)}
            onChange={this.handleInputChange}
            onClick={this.openSuggestions}
            onFocus={this.openSuggestions}
            onBlur={this.closeSuggestions}
            onKeyDown={this.onKeyDown}
            value={inputValue || ''}
            placeholder={inputPlaceholder}
          />
          <div className={classes.dropdownBoxPosition}>
            {isOpen && !!suggestions.length && (
              <div ref={this.setListRef} className={classes.dropdownBox}>
                {!!userSuggestions.length && (
                  <ul>
                    <li className={classes.dropdownSectionTitle}>
                      <FormattedMessage id="documents.tags.suggestions.dropdown.users" default="Tags" />
                    </li>
                    {userSuggestions.map((tag) => {
                      const { username, picture } = users[tag];
                      return (
                        // eslint-disable-next-line   jsx-a11y/no-noninteractive-element-interactions
                        <li
                          ref={this.setListItemRef(tag)}
                          onMouseEnter={() => this.setActive(tag)}
                          onMouseDown={(e) => this.onMouseSelect(e, tag)}
                          className={cx(classes.userOption, {
                            [classes.active]: active === tag,
                          })}
                          key={tag}
                        >
                          <div className={classes.userPicBox}>
                            <UserPic size={20} src={picture} />
                          </div>
                          <div className={classes.userOptionText}> {`${username} (${tag})`}</div>
                        </li>
                      );
                    })}
                  </ul>
                )}
                {!!tagSuggestions.length && (
                  <ul>
                    <li className={classes.dropdownSectionTitle}>
                      <FormattedMessage id="documents.tags.suggestions.dropdown.tags" default="Tags" />
                    </li>
                    {tagSuggestions.map((tag) => (
                      // eslint-disable-next-line   jsx-a11y/no-noninteractive-element-interactions
                      <li
                        ref={this.setListItemRef(tag)}
                        onMouseEnter={() => this.setActive(tag)}
                        onMouseDown={(e) => this.onMouseSelect(e, tag)}
                        className={cx(classes.tagOption, {
                          [classes.active]: active === tag,
                        })}
                        key={tag}
                      >
                        {tag}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  users: chatAllUsersSelector(state),
});

export default compose(
  injectIntl,
  connect(mapStateToProps, { loadSuggestions: documentGetTagsSuggestions }),
  withStyles(sheet),
)(TagSuggestions);
