/* @flow */
import React from 'react';
import { FormattedMessage } from 'react-intl';

import DrawerMui from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import AppBar from '@mui/material/AppBar';
import Typography from '@mui/material/Typography';

import IconButton from '@mui/material/IconButton';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

import { styled } from '@mui/material/styles';

import elements from 'components/elements';

type TEventsHistoryDrawer = {
  children: React$Node,
  toggleOpen: () => void,
  isOpened: boolean,
};

export const EVENTS_HISTORY_DRAWER_WIDTH = 450;

const Drawer = styled(DrawerMui)(({ theme }) => ({
  // example taken from MUI for fixed drawer width
  width: EVENTS_HISTORY_DRAWER_WIDTH,
  flexShrink: 0,
  zIndex: theme.zIndex.drawer + 1,

  '& .MuiDrawer-paper': {
    width: EVENTS_HISTORY_DRAWER_WIDTH,
    boxSizing: 'border-box',
  },
}));

const EventsHistoryDrawer: React$StatelessFunctionalComponent<TEventsHistoryDrawer> = ({
  isOpened,
  toggleOpen,
  children,
}) => (
  <Drawer
    anchor="right"
    open={isOpened}
    onClose={toggleOpen}
    disableRestoreFocus
    PaperProps={{ 'data-element': elements.history.container }}
  >
    <AppBar position="static">
      <Toolbar>
        <Typography variant="h6">
          <FormattedMessage id="eventsHistory.panel.title" defaultMessage="History" />
        </Typography>
        <IconButton
          edge="end"
          onClick={toggleOpen}
          color="inherit"
          sx={{ ml: 'auto' }}
          data-element={elements.history.close}
        >
          <CloseOutlinedIcon />
        </IconButton>
      </Toolbar>
    </AppBar>
    {children}
  </Drawer>
);

export default EventsHistoryDrawer;
