/* @flow */
import * as React from 'react';
import { Set, Map } from 'immutable';
import memoizeOne from 'memoize-one';
import type { TableProps } from '../table';
import elements from 'components/elements';

import Box from '@mui/material/Box';
import { RowCheckbox, RowCheckboxWrapper } from 'components/Tables/grid/withSelectedRows/StyledComponents';

import cx from 'classnames';

export type SelectedRowsProps = {|
  classes: {|
    [key: string]: string,
  |},
  selectedRows: Set<number>,
  onChangeSelectedRows: (c: Set<number>) => void,
  selectPlaceCol: string,
  startRow: number,
  countAllRows: number,
  isReadOnly: boolean,
  disableCheckboxes?: boolean,
  lineItems: ?Set<number>,
  data: Map<string, any>,
  reconcileColumn: ?string,
|};

type Props = { ...TableProps, ...SelectedRowsProps };

export const withSelectedRows = (WrappedComponent: React.Component<TableProps>) => {
  class WithSelectedRows extends React.Component<Props> {
    memoIsSelectedRow = memoizeOne(this.isSelectableRow);

    onChangeSelectedRows = (row: number) => (e: SyntheticInputEvent<HTMLInputElement>) => {
      const { selectedRows, onChangeSelectedRows } = this.props;
      const newSelectedRows = e.currentTarget.checked ? selectedRows.add(row) : selectedRows.delete(row);
      onChangeSelectedRows(newSelectedRows);
    };

    get isOpenedCheckbox() {
      const { selectedRows } = this.props;
      return selectedRows.size;
    }

    getItem = (row: number, col: string) => {
      const { getItem, selectedRows, disableCheckboxes } = this.props;
      if (!this.isSelectableCol(row, col)) return getItem(row, col);

      return (
        <Box display="flex" alignItems="center" height="100%">
          <RowCheckboxWrapper className={cx({ 'is-visible': this.isOpenedCheckbox })}>
            <RowCheckbox
              size="small"
              checked={selectedRows.has(row)}
              onChange={this.onChangeSelectedRows(row)}
              disabled={disableCheckboxes}
              data-element={elements.je.grid.selectOne}
            />
          </RowCheckboxWrapper>
          <Box flex="0 1 100%" position="relative" maxWidth="100%">
            {getItem(row, col)}
          </Box>
        </Box>
      );
    };

    isSelectableRow(row: number) {
      const { startRow, countAllRows, lineItems, reconcileColumn, data } = this.props;
      if (lineItems && !lineItems.has(row)) return false;
      if (row <= startRow || row > startRow + countAllRows) return false;
      if (typeof reconcileColumn === 'string') {
        const status = data.getIn([reconcileColumn + row, 'cellSet', 'value']);

        if (status === 'Ignored') return false;
        if (status === 'NoBankAccount') return false;
        if (status === 'Reconciled') return false;
      }
      return true;
    }

    isSelectableCol(row: number, col: string) {
      const { selectPlaceCol } = this.props;
      if (this.memoIsSelectedRow(row) && col === selectPlaceCol) return true;
      return false;
    }

    render() {
      const { getItem: propGetItem, isReadOnly } = this.props;
      const getItem = isReadOnly ? propGetItem : this.getItem;

      return <WrappedComponent {...this.props} getItem={getItem} />;
    }
  }

  WithSelectedRows.defaultProps = {
    disableCheckboxes: false,
  };

  return WithSelectedRows;
};

export default withSelectedRows;
