// @flow
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import type { OrderedSet } from 'immutable';

import { bulkApprovalReplaceAction } from 'domain/approvals';

import Dialog from 'components/mui/Dialog';
import Form from './Form';

type Props = {
  onClose: () => void,
  clearSelection: () => void,
  selectedIDs: OrderedSet<string>,
  open: boolean,
};

const DialogBulkReplaceApprover: React$StatelessFunctionalComponent<Props> = ({
  onClose,
  selectedIDs,
  open = false,
  clearSelection,
}) => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const onSubmit = useCallback(
    (data) => {
      new Promise((resolve, reject) => {
        dispatch(
          bulkApprovalReplaceAction({
            selectedIDs,
            replace: data.get('replace')[0],
            to: data.get('to')[0],
            resolve,
            reject,
          }),
        );
      }).then(() => {
        onClose();
        clearSelection();
      });
    },
    [dispatch, onClose, selectedIDs, clearSelection],
  );

  return (
    <Dialog
      open={open}
      onClose={onClose}
      title={formatMessage({ id: 'form.dialog.replaceApprover.title', defaultMessage: 'Replace approver' })}
      maxWidth="sm"
      withActions={false}
      withContent={false}
    >
      <Form onSubmit={onSubmit} onCancel={onClose} selectedIDs={selectedIDs} />
    </Dialog>
  );
};

export default DialogBulkReplaceApprover;
