import * as t from 'typed-contracts';
import { validate } from 'lib/contracts';

export const device = t.isObject({
  deviceId: t.isString,
  deviceType: t.isString,
  displayGuid: t.isString,
  erpType: t.isString,
});

export const deviceList = t.isArray(device);

export const validateDeviceList = validate(deviceList);
