// @flow
import React from 'react';
import Tooltip from 'components/mui/Tooltip';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';

type TPublishStatus = {
  status: Array<string>,
};

const PublishStatus: React$StatelessFunctionalComponent<TPublishStatus> = ({ status }) => {
  const isPublished = status.includes('accepted');
  const t = {
    id: 'tooltip.published',
    defaultMessage: 'Published',
  };

  return (
    isPublished && (
      <Tooltip t={t} placement="top">
        <CheckCircleIcon sx={{ fontSize: 20, color: 'secondary.main' }} />
      </Tooltip>
    )
  );
};

export default PublishStatus;
