/* @flow */
import gridStyle from '../../grid/sheet';
import c from '../../constatnt';
import arrow from './arrow.svg';
import { PINNED_WIDTH, MIN_WIDTH } from './constants';

export default (theme) => ({
  container: {
    position: 'relative',
    margin: [5, 0, 30, 0],
    height: 'calc(100% - 80px)',
    minHeight: 310, // allows for scrolling in je-at-the-bottom split view
    '&:hover $btn': {
      opacity: 1,
    },
  },
  row: {
    ...gridStyle(theme).row,
    display: 'flex',
  },
  col: {
    ...gridStyle(theme).col,
    width: '100%',
    flexGrow: 1,
    borderBottom: '1px solid #DEE2E6',
    minWidth: MIN_WIDTH,
    '&:hover': {
      backgroundColor: '#F8F8F8',
    },
    '&::before': {
      content: '""',
      position: 'absolute',
      top: -1,
      left: -1,
      bottom: 2,
      borderRight: '1px solid #DEE2E6',
      zIndex: 0,
      '.rtl &': {
        left: 'auto',
        right: -1,
      },
      '.ltr &': {
        left: -1,
      },
    },
    '&:first-child:before': {
      display: 'none',
    },
  },
  pinned: {
    width: PINNED_WIDTH,
    right: 0,
    backgroundColor: '#fff',
    flexGrow: 0,
    position: 'sticky',
    flexBasis: PINNED_WIDTH,
    minWidth: PINNED_WIDTH,
    zIndex: 11,
    '&:after': {
      content: '""',
      width: 4,
      height: 'calc(100% + 2px)',
      background: '#5A6872',
      display: 'block',
      position: 'absolute',
      left: -4,
      top: -1,
      opacity: 0.4,
      zIndex: 2,
    },
    '&$active': {
      zIndex: 15,
    },
    '&:hover': {
      zIndex: 12,
    },
  },
  mandatory: {
    border: `1px solid ${theme.palette.error.main}`,
    '&:before': {
      display: 'none',
    },
  },
  activeCell: {
    backgroundColor: '#E2EAFF',
    outline: `1px solid ${theme.palette.primary.light}`,
    outlineOffset: -1,
    zIndex: 10,
    '&:before, & + $col:before': {
      display: 'none',
    },
    '[data-scroll-box="open"] &': {
      zIndex: 20,
    },
  },

  activeRow: {
    '& $removeBtn:not($removeLineEmpty)': {
      zIndex: 1,
      opacity: 1,
    },
    '& > div': {
      backgroundColor: '#E2EAFF',
    },
  },

  removeLine: {
    flex: [0, 0, '30px'],
    width: 30,
    lineHeight: 0,
    background: theme.palette.common.white,
  },
  removeLineEmpty: {
    backgroundColor: '#fff',
    opacity: 0,
  },
  removeBtn: {
    padding: 4,
    opacity: 0,
    transition: 'opacity 0.3s',
  },
  loading: {
    position: 'absolute',
    height: 40,
    top: 'auto',
    bottom: 0,
  },
  resize: {
    height: c.ROW_HEIGHT,
    width: 8,
    position: 'absolute',
    cursor: 'col-resize',
    zIndex: 10,
    outline: 'none',
    border: 'none',
    background: 'transparent',
    right: -5,
    top: 0,
    '&:before': {
      content: '""',
      width: 2,
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      position: 'absolute',
      background: 'transparent',
      margin: 'auto',
    },
  },
  resizing: {
    '&:before': {
      background: '#1470CD',
    },
  },
  empty: {
    height: 5,
    background: '#EEEEEE',
    maxWidth: 'calc(100% - 30px)',
    width: '60%',
    margin: ['auto', 'auto', 'auto', 15],
    position: 'absolute',
    top: 0,
    bottom: 0,
    backgroundImage: 'linear-gradient(90deg, #ddd 0px, #e8e8e8 40px, #ddd 80px)',
    animation: '$shine-avatar 1.6s infinite linear',
    backgroundSize: 600,
  },
  '@keyframes shine-avatar': {
    '0%': {
      backgroundPosition: '-100px',
    },
    '40%, 100%': {
      backgroundPosition: '140px',
    },
  },

  header: {
    position: 'sticky',
    top: 0,
    width: '100%',
    height: 26,
    zIndex: 14,
  },

  body: {
    position: 'relative',
    '& $row:not($activeRow):hover': {
      '& $removeBtn': {
        opacity: 1,
      },
      '& > div': {
        '&:hover': {
          backgroundColor: '#F8F8F8',
        },
        backgroundColor: '#F9F5E8',
      },
    },
  },

  wrapper: {
    display: 'flex',
    width: '100%',
  },
  float: {
    overflowX: 'auto',
    flex: [1, 1, '100%'],
    minWidth: 0,
  },
  outer: {
    overflow: 'auto',
    position: 'relative',
    width: '100%',
    '[data-scroll-box="open"] &': {
      overflow: 'hidden',
    },
  },
  scroll: {
    paddingBottom: 2,
    '&::-webkit-scrollbar': {
      appearance: 'none',
      '&:horizontal': {
        height: 7,
      },
      '&:vertical': {
        width: 7,
      },
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: 4,
      backgroundColor: 'rgba(0,0,0,.5)',
      boxShadow: '0 0 1px rgba(255,255,255,.5)',
    },
  },

  // btn

  btn: {
    position: 'absolute',
    width: 16,
    top: 0,
    bottom: -14,
    zIndex: 15,
    border: '0 none',
    outline: '0 none',
    background: 'none',
    cursor: 'pointer',
    opacity: 0,
    transition: '0.3s opacity',
    '&:before': {
      content: '""',
      position: 'absolute',
      left: 0,
      right: 0,
      bottom: 0,
      top: 0,
      background: '#171A1C',
      opacity: 0.2,
      transition: '0.3s opacity',
    },
    '&:after': {
      content: '""',
      position: 'absolute',
      left: 0,
      right: 0,
      bottom: 0,
      top: 0,
      margin: 'auto',
      width: 5,
      height: 8,
      background: `url("${arrow}") scroll no-repeat`,
    },
    '&:hover': {
      zIndex: 16,
      // opacity: 0.5,
      '&:before': {
        opacity: 0.5,
      },
    },
    '&[disabled]': {
      display: 'none',
    },
  },
  prev: {
    '.ltr &': {
      left: -5,
    },
    '.rtl &': {
      right: 0,
    },
  },
  next: {
    '.ltr &': {
      right: PINNED_WIDTH,
    },
    '.rtl &': {
      left: 0,
    },
    '&:after': {
      transform: 'rotate(180deg)',
    },
  },
});
