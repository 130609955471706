import { CALL_HISTORY_METHOD, saveLocationDataAction } from 'domain/router/redux/reduxActions';

const createRouterMiddleware =
  (navigate, showHistoryAction = false) =>
  (store) =>
  (next) =>
  (action) => {
    if (action.type !== CALL_HISTORY_METHOD) {
      return next(action);
    }
    const { method, pathname, data = {} } = action.payload;

    // eslint-disable-next-line default-case
    switch (method) {
      case 'push': {
        navigate(pathname);
        break;
      }
      case 'replace': {
        navigate(pathname, { replace: true });

        break;
      }
    }
    if (Object.keys(data).length > 0) {
      store.dispatch(saveLocationDataAction(data));
    }
    if (showHistoryAction) return next(action);
  };

export default createRouterMiddleware;
