// @flow
import { type RecordOf } from 'immutable';
import * as actions from './organizationActions';
import { type Actions, OrganizationType } from './types.js.flow';
import { organizationAdapter, signerFactory, signingsFactory } from './helpers';

export const reducer = {
  organization(state: RecordOf<OrganizationType> = organizationAdapter(), action: Actions) {
    switch (action.type) {
      case actions.setOrganization.success: {
        const { currentOrganization, sort, expiration_days, apiKeysLoaded } = state;

        return state
          .mergeDeep(action.payload)
          .set('currentOrganization', currentOrganization)
          .set('sort', sort)
          .set('expiration_days', expiration_days)
          .set('apiKeysLoaded', apiKeysLoaded)
          .update('list', (list) =>
            list.map((org) =>
              org.id !== action.payload.id
                ? org
                : org.merge({ picture: action.payload.picture, name: action.payload.name }),
            ),
          );
      }

      case actions.uploadLogoOrganization.success:
        return state.set('picture', action.payload.picture);

      case actions.getOrganizationSignings.success:
        return state.set('signings', action.payload);

      case actions.getOrganizationFeatures.success:
        return state.set('features', action.payload);

      case actions.setOrganizationFeaturesAction.success:
        return state.set('features', action.payload);

      case actions.getOrgBackupConnections.success:
        return state.set('backupConnection', action.payload);

      case actions.removeAllOrganizationSigner.success:
        return state.set('signings', signingsFactory());

      case actions.clearOrganization.type:
        return organizationAdapter();

      case actions.addEmptyOrganizationSigner.type:
        return state.updateIn(['signings', 'tokens'], (s) => s.push(signerFactory()));

      case actions.updateOrganizationSignings.success:
        return state.updateIn(['signings', 'tokens'], (s) => s.push(action.payload).filter((i) => i.token_name));

      case actions.setOrganizationSigningsGuid.success:
        return state.setIn(['signings', 'guid'], action.payload);

      case actions.removeOrganizationSigner.success:
        return state.updateIn(['signings', 'tokens'], (s) => {
          if (action.payload.tokenName) {
            return s.filter((i) => i.token_name !== action.payload.tokenName);
          }
          return s.filter((i, idx) => idx !== action.payload.idx);
        });

      case actions.getOrganizationList.success:
        return state.set('list', action.payload).set('loaded', true);

      case actions.setOrganizationsFilter.type:
        return state.set('filter', action.payload || '');

      case actions.setOrganizationsSort.type:
        return state.set('sort', action.payload);

      case actions.setCurrentOrganization.type:
        return state.set('currentOrganization', action.payload);

      case actions.unsetCurrentOrganization.type:
        return state.set('currentOrganization', null);

      case actions.getPasswordExpirationAction.success:
        return state.set('expiration_days', action.payload);

      case actions.getOrganizationApiKeysAction.success: {
        return state.set('apiKeys', action.payload).set('apiKeysLoaded', true);
      }

      case actions.createOrganizationApiKeyAction.success: {
        return state.updateIn(['apiKeys'], (k) => k.push(action.payload));
      }

      case actions.deleteOrganizationApiKeyAction.success:
        return state.updateIn(['apiKeys'], (k) =>
          k.filter((key) => {
            const { apiKey } = action.payload;
            return key.api_key !== apiKey;
          }),
        );

      case actions.updateOrganizationApiKeyAction.success: {
        return state.updateIn(['apiKeys'], (k) =>
          k.set(
            k.findIndex((key) => key.api_key === action.payload.api_key),
            action.payload,
          ),
        );
      }

      default:
        return state;
    }
  },
};
