// @flow
import React, { memo } from 'react';

import MenuItemIcons from 'pages/company/DocumentContextMenu/components/MenuItemIcons';
import ListItemIcon from '@mui/material/ListItemIcon';

type TMenuItemIcon = {
  id: string,
  color?: string,
};

const MenuItemIcon: React$StatelessFunctionalComponent<TMenuItemIcon> = ({ id, color }) => {
  const Icon = MenuItemIcons[id];

  return Icon ? (
    <ListItemIcon sx={{ minWidth: 32, color }}>
      <Icon />
    </ListItemIcon>
  ) : null;
};

export default memo(MenuItemIcon);
