// @flow
import React from 'react';
import { FormattedMessage } from 'react-intl';

type Props = {|
  total: number,
  count: number,
  className: string,
|};

const ResultsCount: React$StatelessFunctionalComponent<Props> = ({ className, total, count }: Props) => (
  <section className={className}>
    <FormattedMessage
      id="reconciliation.transactionDetails.results.counter"
      defaultMessage="'{count} of {total} results displayed"
      values={{ count, total }}
    >
      {(text: string) => <span>{text}</span>}
    </FormattedMessage>
  </section>
);

export default ResultsCount;
