// @flow
import React, { useState, useMemo, useEffect, useRef, useCallback, type StatelessFunctionalComponent } from 'react';
import SortableItem from './SortableItem';
import get from 'lodash/get';
import isFunction from 'lodash/isFunction';

import { DndContext, useSensor, useSensors, KeyboardSensor, PointerSensor, MouseSensor } from '@dnd-kit/core';
import { arrayMove, SortableContext, sortableKeyboardCoordinates } from '@dnd-kit/sortable';

export type GetKeyFn = (Object) => string | number;

const getIndexes = ({ active, over, list, keyGetter }) => {
  const oldIndex = list.findIndex((i) => active && keyGetter(i) === active.id);
  const newIndex = list.findIndex((i) => over && keyGetter(i) === over.id);

  return { oldIndex, newIndex };
};

type SortEvent = {| oldIndex: number, newIndex: number |};
type DragEvent = {| over: any, active: any, delta: any |};

export type Props = {|
  items: any,
  idKey?: GetKeyFn | string,
  children: any,
  className: string,
  itemClassName?: string,
  helperClass: string,
  dataElement?: string,
  getIsItemDisabled?: (item: any) => boolean,
  onSortOver: (e: SortEvent) => void,
  onSortEnd: (e: SortEvent) => void,
  Element: *,
|};

const ItemsFactory = (items) => (items.toArray ? items.toArray() : items);

const SortableList: StatelessFunctionalComponent<Props> = ({
  className,
  items,
  children,
  idKey,
  dataElement,
  getIsItemDisabled = () => false,
  itemClassName,
  helperClass,
  onSortOver,
  onSortEnd,
  Element = 'div',
}: Props) => {
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: { distance: 5 },
    }),
    useSensor(MouseSensor, {
      activationConstraint: { distance: 5 },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );
  const [localItems, setLocalItems] = useState(ItemsFactory(items));
  const [isDragging, setIseDragging] = useState(false);
  const delta = useRef({ x: 0, y: 0 });
  const oldIndex = useRef(null);

  const keyGetter = useCallback((item) => item[idKey], [idKey]);

  const contextItems = useMemo(() => localItems.map((i) => keyGetter(i)), [keyGetter, localItems]);

  const handleDragOver = useCallback(
    (event: DragEvent) => {
      const {
        active,
        over,
        delta: { x, y },
      } = event;

      if (over && active.id !== over.id && delta.current.x !== x && delta.current.y !== y) {
        setLocalItems((s) => {
          const { oldIndex, newIndex } = getIndexes({ active, over, list: s, keyGetter });
          onSortOver({ oldIndex, newIndex });
          return arrayMove(s, oldIndex, newIndex);
        });

        delta.current = { x, y };
      }
    },
    [keyGetter, onSortOver],
  );
  const handleDragStart = useCallback(
    (e: DragEvent) => {
      const { active } = e;
      oldIndex.current = getIndexes({ active, list: localItems, keyGetter }).oldIndex;
      setIseDragging(true);
    },
    [keyGetter, localItems],
  );
  const handleDragEnd = useCallback(
    (e: DragEvent) => {
      const { over } = e;
      const newIndex = localItems.findIndex((i) => over && keyGetter(i) === over.id);
      onSortEnd({ newIndex, oldIndex: oldIndex.current });

      oldIndex.current = null;
      setIseDragging(false);
    },
    [keyGetter, localItems, onSortEnd],
  );

  const memoizedChildren = useMemo(() => children, [isDragging]);

  const renderedChildren = isDragging ? memoizedChildren : children;

  useEffect(() => {
    if (!isDragging) {
      setLocalItems(ItemsFactory(items));
    }
  }, [isDragging, items]);

  return (
    <DndContext
      sensors={sensors}
      onDragStart={handleDragStart}
      onDragOver={handleDragOver}
      onDragEnd={handleDragEnd}
      onDragCancel={handleDragEnd}
    >
      <SortableContext items={contextItems}>
        <Element className={className} data-element={dataElement}>
          {localItems.map((item, index) => {
            const key = isFunction(idKey) ? idKey(item) : get(item, idKey, index);
            const disabled = getIsItemDisabled(item);

            return (
              <SortableItem
                helperClass={helperClass}
                className={itemClassName}
                itemIndex={index}
                key={key}
                id={key}
                item={item}
                disabled={disabled}
                render={renderedChildren}
              />
            );
          })}
        </Element>
      </SortableContext>
    </DndContext>
  );
};

export default SortableList;
