// @flow
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { documentWorkSpaceTypeSelector } from 'domain/companies';
import {
  currentCompanySelector,
  gridHasNewDocumentsSelector,
  documentsGridRefreshServerSideAction,
} from 'domain/documents';
import { updateWorkSpaceAction } from 'domain/env';
import type { TWorkspaceType } from 'domain/env/types.js.flow';

import {
  WorkspaceViewToggleGroup,
  WorkspaceViewToggleButton,
} from 'pages/company/CompanyBarPanel/components/WorkspaceViewToggle/StyledComponents';
import Badge from '@mui/material/Badge';
import Tooltip from 'components/mui/Tooltip';

import TableChartIcon from '@mui/icons-material/TableChart';
import ViewModuleIcon from '@mui/icons-material/ViewModule';

const mapStateToProps = (state) => ({
  viewType: documentWorkSpaceTypeSelector(state),
  companyId: currentCompanySelector(state),
  gridHasChanges: gridHasNewDocumentsSelector(state),
});

const WorkspaceViewToggle = () => {
  const dispatch = useDispatch();
  const { viewType, companyId, gridHasChanges } = useSelector(mapStateToProps);

  const isVisibleGridBadge = viewType === 'grid' && gridHasChanges;

  const onChange = (event, type: TWorkspaceType | null) => {
    // value === null - when we click on already selected button
    if (type !== null) {
      dispatch(updateWorkSpaceAction({ companyId, type }));
    } else if (viewType === 'grid' && gridHasChanges) {
      dispatch(documentsGridRefreshServerSideAction(false));
    }
  };

  return (
    <WorkspaceViewToggleGroup exclusive size="small" onChange={onChange} value={viewType}>
      <WorkspaceViewToggleButton value="grid">
        <Tooltip
          t={{ id: 'company.workspace.barSwitcher.grid.updateTooltip', defaultMessage: 'Refresh the grid' }}
          disabled={!isVisibleGridBadge}
        >
          <Badge
            // component={DocumentsViewToggleButton} // push badge to wrapper
            invisible={!isVisibleGridBadge}
            variant="dot"
            color="error"
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            <TableChartIcon />
          </Badge>
        </Tooltip>
      </WorkspaceViewToggleButton>
      <WorkspaceViewToggleButton value="tile">
        <ViewModuleIcon />
      </WorkspaceViewToggleButton>
    </WorkspaceViewToggleGroup>
  );
};

export default WorkspaceViewToggle;
