import { alpha, styled } from '@mui/material/styles';
import ListItemButtonMui from '@mui/material/ListItemButton';

export const ListItemWrapper = styled('div', {
  label: 'CompanyNavigationListActions',
  shouldForwardProp: (propName) => propName !== 'isActive',
})(({ theme, isActive }) => ({
  backgroundColor: isActive ? alpha(theme.palette.common.white, 0.08) : 'transparent',

  ...(isActive && {
    borderTop: `1px solid ${alpha(theme.palette.common.white, 0.5)}`,
    borderBottom: `1px solid ${alpha(theme.palette.common.white, 0.5)}`,
  }),
}));

export const ListItemButton = styled(ListItemButtonMui, {
  label: 'CompanyNavigationActionButton',
  shouldForwardProp: (propName) => propName !== 'isToggle',
})(({ theme, isToggle }) => ({
  justifyContent: isToggle ? 'initial' : 'center',

  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.1),
  },

  '&.Mui-selected': {
    backgroundColor: alpha(theme.palette.common.white, 0.3),

    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.1),
    },
  },
}));
