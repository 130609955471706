/* @flow */
import * as React from 'react';
import { compose } from 'redux';
import { FixedSizeList as List } from 'react-window';
import { debounce } from 'throttle-debounce';
import { Set } from 'immutable';
import cx from 'classnames';
import ResizeObserver from 'react-resize-observer';
import { withStyles } from '@mui/styles';
import { promisify } from 'lib/helpers';
import elements from 'components/elements';
import makeCancelable, { type PromiseCanceleable } from 'lib/makeCancelable';
import CONST from '../../constatnt';
import { getHeight, getLoadPagesByRows, zipQueryParams } from './helpers';
import VRow, { Row } from './row';
import withResize from './withResize';
import withColSwitch from './withColSwitch';
import withRowMessages from './withRowMessages';
import withGoogleSearch from 'components/Tables/grid/withGoogleSearch';
import { withSelectedRows } from 'components/Tables/grid/withSelectedRows';
import withRequest from './withRequest';
import sheet from './sheet';
import type { VGridProps } from './Types.js.flow';

type State = {};

class VirtualGrid extends React.Component<VGridProps, State> {
  componentWillUnmount() {
    if (this.getJECancellable) {
      this.getJECancellable.cancel();
    }
  }

  onRender = ({ visibleStartIndex, visibleStopIndex }) => {
    const visible = [visibleStartIndex, visibleStopIndex];
    this.visible = visible;
    this.props.onRowsRender(visible);
    this.loadPage(visible);
  };

  get itemData() {
    // eslint-disable-next-line max-len
    const { classes, active, meta, lineItems, removeHandler, getItem, selectedRows, reconcileColumn, data } =
      this.props;
    return {
      classes,
      meta: [meta[0], meta[1].slice(1)],
      getItem,
      active,
      lineItems,
      removeHandler,
      selectedRows,
      reconcileColumn,
      data,
    };
  }

  getJECancellable: ?PromiseCanceleable<*>;

  loadPage = debounce(50, ([start, end]) => {
    const { loadedPages } = this.props;
    const loadPages = getLoadPagesByRows(start, end, 0);
    const subtract = this.waiting.concat(loadedPages);
    const needPages = loadPages.filter((p) => !subtract.includes(p));
    this.loadJE(needPages);
  });

  loadJE = (pageList) => {
    const { getJournalEntry, loadedPages, onChangeLoadedPages } = this.props;
    if (pageList.length) {
      this.waiting = this.waiting.concat(pageList);
      this.getJECancellable = makeCancelable(promisify(getJournalEntry, zipQueryParams(pageList)));
      this.getJECancellable.promise
        .then(() => {
          this.waiting = this.waiting.filter((p) => !pageList.includes(p));
          onChangeLoadedPages([...loadedPages, ...pageList]);
        })
        .catch(() => null);
    }
  };

  refProxy = (el: ?HTMLElement) => {
    this.container = el;
    this.props.refProxy(el);
  };

  refresh = () => {
    this.forceUpdate();
  };

  visible: [number, number] = [0, 0];

  waiting: Set<number> = new Set();

  container: ?HTMLElement;

  header: ?HTMLElement;

  // eslint-disable-next-line react/no-multi-comp
  innerElementType = React.forwardRef(({ children, ...rest }, ref) => {
    const { classes, meta, data } = this.props;
    const row = meta[1][0];
    const rowEntries = data.filter((f) => f._row === row);
    return (
      <>
        <div className={cx(classes.header)}>
          <Row
            meta={meta}
            classes={classes}
            r={row}
            active={null}
            getItem={this.props.getItem}
            rowEntries={rowEntries}
            reconcileColumn={this.props.reconcileColumn}
          />
        </div>
        <div {...rest} ref={ref} className={classes.body}>
          {children}
        </div>
      </>
    );
  });

  // eslint-disable-next-line react/no-multi-comp
  outerElement = React.forwardRef(({ children, style: { height }, onScroll }, ref) => {
    const { classes } = this.props;
    const style = { maxHeight: height };

    return (
      <div className={cx(classes.outer, classes.scroll, 'scroll-box')} style={style} onScroll={onScroll} ref={ref}>
        {children}
      </div>
    );
  });

  render() {
    const { classes, rtl, lineCount } = this.props;

    return (
      <div className={cx(classes.container)} ref={this.refProxy} data-element={elements.je.virtualGrid.container}>
        <ResizeObserver onResize={() => this.refresh()} />
        {this.container ? (
          <List
            innerElementType={this.innerElementType}
            height={getHeight(this.container)}
            itemSize={CONST.ROW_HEIGHT}
            itemCount={lineCount - 1}
            width="100%"
            itemData={this.itemData}
            onItemsRendered={this.onRender}
            outerElementType={this.outerElement}
            direction={rtl ? 'rtl' : 'ltr'}
          >
            {VRow}
          </List>
        ) : null}
      </div>
    );
  }
}

export default compose(
  withStyles(sheet, { withTheme: true }),
  withColSwitch,
  withResize,
  withSelectedRows,
  withRowMessages,
  withGoogleSearch,
  withRequest,
)(VirtualGrid);
