// @flow
import React from 'react';

import { Modal, PreviewContent, ModalCloseButton } from 'pages/company/webtwain/components/ImagePopup/StyledComponents';
import Box from '@mui/material/Box';

import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

import type { Image as ImageType } from '../../type.js.flow';

type Props = {|
  image: ImageType,
  close: () => void,
|};

const ImagePopup: React$StatelessFunctionalComponent<Props> = ({ image, close }) => (
  <Modal open onClose={close}>
    <PreviewContent>
      <ModalCloseButton onClick={close}>
        <CloseOutlinedIcon />
      </ModalCloseButton>
      <Box boxShadow={10}>
        <img src={image.originalSizeSrc} alt={image.originalSource} style={{ maxHeight: '70vh' }} />
      </Box>
    </PreviewContent>
  </Modal>
);

export default ImagePopup;
