/* @flow */
import React, { useCallback } from 'react';
import type { SwitchCol } from './Types.js.flow';
import CancelIcon from '@mui/icons-material/Cancel';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { usePopupState, bindTrigger, bindMenu } from 'material-ui-popup-state/hooks';

import Menu from '@mui/material/Menu';
import IconButton from '@mui/material/IconButton';
import ColumnOption from 'components/Tables/layout/grid/VisibilityColsDropdown/ColumnOption';

type TVisibilityColsDropdownSimple = {
  columns: $ReadOnlyArray<SwitchCol>,
  handleChange: () => void,
};

const VisibilityColsDropdownForVirtualGrid: React$StatelessFunctionalComponent<TVisibilityColsDropdownSimple> = ({
  columns,
  handleChange,
}) => {
  const popupState = usePopupState({ variant: 'popover', popupId: 'VisibilityColsDropdownSimple' });

  const onChange = useCallback(
    (e, name, checked) => {
      e.preventDefault();

      handleChange(name, !checked);
    },
    [handleChange],
  );

  return (
    <>
      <IconButton
        size="small"
        color="primary"
        {...bindTrigger(popupState)}
        sx={{ position: 'absolute', top: 0, right: -30 }}
      >
        {popupState.isOpen ? <CancelIcon fontSize="18" /> : <AddCircleIcon fontSize="18" />}
      </IconButton>
      <Menu
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
        slotProps={{ paper: { sx: { maxHeight: 450 } } }}
        MenuListProps={{ component: 'div' }}
        {...bindMenu(popupState)}
      >
        {columns.map((c) => (
          <ColumnOption
            key={c.name}
            isPinned={false}
            checked={c.state}
            isDisabled={c.disabled}
            column={{ title: c.title, colName: c.name }}
            onChange={(e) => onChange(e, c.name, c.state)}
          />
        ))}
      </Menu>
    </>
  );
};

export default VisibilityColsDropdownForVirtualGrid;
