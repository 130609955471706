import DrawerMui from '@mui/material/Drawer';
import Badge from '@mui/material/Badge';
import Typography from '@mui/material/Typography';
import ListItemButton from '@mui/material/ListItemButton';

import { styled, alpha } from '@mui/material/styles';

const CHAT_DRAWER_WIDTH = 420;

export const ChatDrawer = styled(DrawerMui)(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  '& .MuiDrawer-paper': {
    width: CHAT_DRAWER_WIDTH,
  },
}));

export const TotalUnreadBadge = styled(Badge)(({ theme }) => ({
  // Mui example
  '& .MuiBadge-badge': {
    border: `2px solid ${theme.palette.common.white}`,
    padding: '0 4px',
  },
}));

export const ChannelMeta = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'end',
  flexDirection: 'column',
  flex: '0 0 auto',
  paddingLeft: theme.spacing(1),
}));

export const ChannelUnreadBadge = styled(Badge)(() => ({
  '& .MuiBadge-badge': {
    position: 'static',
    transform: 'none',
  },
}));

export const ChannelTime = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[500],
}));

export const ChannelActionArea = styled(ListItemButton)(({ theme }) => ({
  padding: theme.spacing(1.5),
  borderBottom: `1px solid ${theme.palette.grey[300]}`,
}));

export const DocumentChannelActionArea = styled(ListItemButton)(({ theme }) => ({
  backgroundColor: alpha(theme.palette.warning.main, 0.1),
  boxShadow: theme.shadows[3],
  margin: theme.spacing(0.5, 2),
  padding: theme.spacing(1.5),
  borderRadius: theme.shape.borderRadius,

  '&:hover': {
    backgroundColor: alpha(theme.palette.warning.main, 0.15),
  },
}));
