// @flow
import React from 'react';
import type { FieldProps } from 'redux-form/lib/FieldProps.types.js.flow';
import AutocompleteBase, { type TAutocompleteBase } from 'components/mui/Form/Autocomplete/AutocompleteBase';

type TAutocompleteForm = TAutocompleteBase & FieldProps;

const AutocompleteForm: React$StatelessFunctionalComponent<TAutocompleteForm> = (props) => {
  const { input, meta, ...rest } = props;
  const isError = Boolean(meta.touched && meta.error);

  return (
    <AutocompleteBase
      onChange={(event, option) => input.onChange(option && option.value)}
      value={input.value}
      error={isError}
      {...rest}
    />
  );
};

export default AutocompleteForm;
