// @flow
import React, { useEffect, useState, useRef, useCallback, useMemo } from 'react';
import { throttle } from 'throttle-debounce';
import useResizeObserver from '@react-hook/resize-observer';

import GC from '@grapecity/spread-sheets';
import { SpreadSheets } from '@grapecity/spread-sheets-react';
import '@grapecity/spread-sheets/styles/gc.spread.sheets.excel2013white.css';
import '@grapecity/spread-sheets-io';

import usePreloadDocuments from '../DocumentComponent/usePreloadDocuments';

import {
  DocumentPageControlsWrapper,
  ControlsStack,
} from 'pages/document/components/DocumentPageControls/components/StyledComponents';
import DocumentZoomControls from 'pages/document/components/DocumentPageControls/components/DocumentZoomControls';
import ProgressBar from 'pages/document/components/ProgressBar';

import useStyles from './sheet';

window.GC = GC;
GC.Spread.Sheets.LicenseKey = process.env.REACT_APP_SPREADJS_LICENSE;

interface IProps {
  documentID: string;
  spreadZoom: number;
  onZoom: (direction: 1 | -1 | 0) => void;
  onReset: () => void;
  getIsDisabledZoomButton: (direction: 1 | -1 | 0) => boolean;
  isSupplier: boolean;
}

const XLSComponent = ({ documentID, spreadZoom, getIsDisabledZoomButton, onZoom, onReset, isSupplier }: IProps) => {
  const [spreadApi, setSpreadApi] = useState(null);
  const [blob, setBlob] = useState(null);
  const [isRendered, setIsRendered] = useState(false);

  const wrapperRef = useRef(null);

  const classes = useStyles();

  const addExtraColumns = useCallback((spread: GC.Spread.Sheets.Workbook) => {
    if (wrapperRef.current) {
      const sheet = spread.getActiveSheet();
      let width = 0;

      for (let col = 0; col < sheet.getColumnCount(); col++) {
        width += sheet.getColumnWidth(col);
      }

      for (let col = 0; col < sheet.getColumnCount(GC.Spread.Sheets.SheetArea.rowHeader); col++) {
        width += sheet.getColumnWidth(col, GC.Spread.Sheets.SheetArea.rowHeader);
      }

      width += 10;

      const { width: wrapperWidth } = wrapperRef.current.getBoundingClientRect();

      const colsCount = sheet.getColumnCount();
      const colWidth = sheet.getColumnWidth(colsCount - 1);

      // sometimes sheet.getColumnCount() gets 1 more column due to which
      // we can't calculate colWidth correctly resulting in calculation error.
      // So if colWidth=0 we don't apply the addColumns method.
      if (colWidth) {
        sheet.addColumns(colsCount, Math.ceil((wrapperWidth - width) / colWidth));
      }
      // this must be set to true as xls file might have this feature disabled
      // which prevents added columns from being visible after being added
      sheet.options.gridline.showVerticalGridline = true;
      sheet.options.gridline.showHorizontalGridline = true;
    }
  }, []);

  const autoFitSheet = useCallback((spread: GC.Spread.Sheets.Workbook) => {
    const sheet = spread.getActiveSheet();
    // Get the row and column count of the worksheet
    const columnCount = sheet.getColumnCount();
    // Loop through the count to autoFit the entire sheet

    for (let i = 0; i < columnCount; i++) {
      sheet.autoFitColumn(i);
    }

    // Lets me left it here as the comment for the example because it is the source of significantly performance degradation
    /* const rowCount = sheet.getRowCount();
    for (let i = 0; i < rowCount; i++) {
      sheet.autoFitRow(i);
    } */
  }, []);

  const initSpread = useCallback(
    (spread) => {
      setSpreadApi(spread);

      const arrayBufferBlob = new Blob([blob], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });

      spread.import(
        arrayBufferBlob,
        () => {
          spread.options.newTabVisible = false;
          spread.options.allowContextMenu = false;
          spread.options.scrollbarMaxAlign = true;

          spread.sheets.forEach((sh) => {
            sh.options.isProtected = true;
          });

          addExtraColumns(spread);
          autoFitSheet(spread);
          setIsRendered(true);
        },
        (e) => {
          console.log(e);
        },
      );
    },
    [blob, addExtraColumns, autoFitSheet],
  );

  useResizeObserver(
    wrapperRef.current,
    throttle(300, () => {
      if (spreadApi) {
        spreadApi.refresh();
        addExtraColumns(spreadApi);
      }
    }),
  );

  const extraParams = useMemo(() => ({ original: 1, recordEvent: false }), []);

  const cachedDocument = usePreloadDocuments({
    currentDocumentId: documentID,
    extraParams,
    isSupplier,
    signatureStatus: 'hide',
  });

  useEffect(() => {
    if (cachedDocument.blob) {
      setBlob(cachedDocument.blob);
    }
  }, [cachedDocument.blob]);

  useEffect(() => {
    if (spreadApi) {
      const sheet = spreadApi.getActiveSheet();

      sheet.zoom(spreadZoom);
    }
  }, [spreadZoom, spreadApi]);

  const spreadSheetsStyle = useMemo(
    () => ({
      width: '100%',
      height: '100%',
    }),
    [],
  );

  return (
    <div ref={wrapperRef} className={classes.container}>
      <div className={classes.xlsContainer}>
        {!isRendered && <ProgressBar value={cachedDocument.downloadProgress} />}
        {blob && (
          <>
            <SpreadSheets hostStyle={spreadSheetsStyle} workbookInitialized={initSpread} allowUserResize />
            {/* DocumentPage-controls class use for change opacity in other component */}
            <DocumentPageControlsWrapper className="DocumentPage-controls">
              <ControlsStack>
                <DocumentZoomControls
                  onZoom={onZoom}
                  onReset={onReset}
                  getIsDisabledZoomButton={getIsDisabledZoomButton}
                />
              </ControlsStack>
            </DocumentPageControlsWrapper>
          </>
        )}
      </div>
    </div>
  );
};

export default XLSComponent;
