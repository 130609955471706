import arrow from './arrow.svg';

import { isMobile } from 'lib/systemHelpers/browserHelpers';

export default {
  container: {
    borderRadius: isMobile ? 0 : 2,
    backgroundColor: '#fff',
    boxShadow: '0px 12px 24px rgba(0, 0, 0, 0.2)',
    position: 'relative',
    overflow: 'hidden',
    height: '100%',
  },
  mobileSlider: {
    width: '100%',
    height: '100%',
    position: 'relative',
    transitionDuration: '0.2s',
    transitionProperty: 'transform',
    willChange: 'transform',
    display: 'flex',
  },
  slider: {
    width: '200%',
    height: '100%',
    position: 'relative',
    transitionDuration: '0.2s',
    transitionProperty: 'transform',
    willChange: 'transform',
    display: 'flex',
  },
  userListPos: {
    transform: 'translate(-50%)',
  },
  threadPos: {
    transform: 'translate(0%)',
  },
  screen: {
    width: '50%',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    maxHeight: (props) => props.maxHeight || '100%',
  },
  mobileScreen: {
    width: '100%',
  },
  back: {
    width: 20,
    height: 20,
    marginRight: 12,
    background: `url("${arrow}") scroll no-repeat center/contain`,
    border: '0 none',
    '.rtl &': {
      transform: 'scaleX(-1)',
    },
    cursor: 'pointer',
  },
};
