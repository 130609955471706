import remove from './remove.svg';
import star from './star.svg';
import * as ACL from 'domain/restriction';

export default (theme) => ({
  underbar: {
    top: 90,
    position: 'sticky',
    zIndex: 10,
    display: 'flex',
    alignItems: 'center',
    padding: [0, 20],
    background: ({ confidential }) => (confidential ? '#FFF9F9' : '#FCFCFC'),
    marginBottom: -20,
    flex: 0,
  },
  list: {
    display: 'flex',
    marginLeft: ({ isGranted }) => (isGranted(ACL.IS_SUPPLIER_USER) ? 0 : 10),
    flexWrap: 'wrap',
  },
  separator: {
    width: 0,
    height: 30,
    borderRight: `1px solid ${theme.colors.greyDark}`,
    margin: [0, 10],
  },
  item: {
    border: '1px solid #DEE2E6',
    transition: 'border 0.2s ease',
    borderRadius: 4,
    height: 30,
    background: '#DEE2E6',
    color: '#171A1C',
    padding: [0, 10, 0, 10],
    lineHeight: '26px',
    fontSize: 12,
    margin: [1, 2],
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    '&>span': {
      fontSize: 12,
      lineHeight: '14px',
      paddingRight: 15,
    },
    '&>button': {
      width: 9,
      minWidth: 9,
      height: 9,
      backgroundSize: '100% 100%',
      background: `url("${remove}") 0 0 no-repeat`,
      border: 0,
      cursor: 'pointer',
      marginLeft: 7,
    },
  },

  favHoveredItem: {
    border: `1px solid ${theme.colors.greyDarker}`,
    transition: 'border 0.2s ease',
  },

  hovered: {},

  userItem: {
    borderRadius: 50,
    paddingLeft: 0,
  },

  userPic: {
    marginRight: 8,
  },

  noTopMargin: {
    marginTop: 0,
  },
  count: {
    padding: [0, 0, 0, 20],
    fontSize: '14px',
    color: '#828282',
    marginTop: '20px',
    '&$noTopMargin': {
      marginTop: 0,
    },
  },
  star: {
    cursor: 'pointer',
    border: 'none',
    width: 16,
    height: 16,
    padding: [7, 6],
    backgroundColor: theme.colors.greyLight,
    WebkitMask: `url("${star}") 50% 50% no-repeat`,
    boxSizing: 'content-box',
    transition: 'background 0.3s ease',
  },

  tagsWrapper: {
    display: 'flex',
    alignItems: 'center',
    padding: ({ isGranted }) => (isGranted(ACL.IS_SUPPLIER_USER) ? '3px 0px' : '3px 5px'),
    borderRadius: 2,
    '&$hovered': {
      '& $star': {
        backgroundColor: theme.colors.primaryLight,
      },
    },
  },
  results: {
    color: '#5A6872',
    marginRight: 10,
    fontSize: 14,
  },
});
