// @flow
import * as React from 'react';
import { Set, List } from 'immutable';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { withStyles } from '@mui/styles';
import { makeChannelName } from 'domain/chat/helpers';
import { chatUsersWithoutSuppliers, chatThreadSelector, isChatConnectedSelector } from 'domain/chat';
import type { ThreadsContainer } from 'sendbird-utils/lib/imAdapters.js.flow';
import type { ChatUser } from 'domain/chat/types.js.flow';
import type { CurrentUserType } from 'domain/env/types.js.flow';
import type { S } from 'domain/types.js.flow';

import PrivatItem from './privat';
import sheet from './sheet';
import { createMemoFn } from 'lib/propHelpers';
import { relevantSearch } from 'lib/helpers';

type Props = {|
  classes: {
    [key: string]: string,
  },
  currentUser: CurrentUserType,
  onChat: (u: ChatUser) => void,
  users: List<ChatUser>,
  threads: ThreadsContainer,
  companyId: string,
  documentId: string,
  isConnected: boolean,
  searchText?: string,
|};

class Messages extends React.Component<Props> {
  getChannelName(userId: string): string {
    const { documentId, companyId, currentUser } = this.props;
    return makeChannelName([currentUser.userId, userId], companyId, documentId);
  }

  getMessage(userId: string) {
    const { threads } = this.props;
    return threads
      .getIn([this.getChannelName(userId), 'messages'], Set())
      .toList()
      .sortBy((m) => m.createdAt)
      .last();
  }

  getCounter(userId: string): number {
    const { threads } = this.props;
    return threads.getIn([this.getChannelName(userId), 'unreadMessageCount'], 0);
  }

  getUsers = createMemoFn(
    () => {
      const { users, searchText } = this.props;
      return { users, searchText };
    },
    ({ users, searchText }) => (searchText ? relevantSearch(searchText, users, (user) => user.username) : users),
  );

  compareWithSearchText = (testText: string) => {
    const { searchText } = this.props;
    if (!searchText) return true;
    return testText.toLowerCase().includes(searchText.toLowerCase());
  };

  render() {
    const { classes, isConnected, searchText, onChat } = this.props;
    return (
      <div className={classes.container}>
        <h2 className={classes.title}>
          <FormattedMessage id="chat.title.messages" defaultMessage="Messages" />
        </h2>
        <ul className={classes.users}>
          {this.getUsers().map((e) => (
            <li key={e.userId}>
              <button disabled={!isConnected} onClick={() => onChat(e)} className={classes.btn} type="button">
                <PrivatItem
                  data={e}
                  classes={classes}
                  thread={this.getMessage(e.userId)}
                  counter={this.getCounter(e.userId)}
                  searchText={searchText}
                />
              </button>
            </li>
          ))}
        </ul>
      </div>
    );
  }
}

const mapStateToProps = (state: S) => ({
  users: chatUsersWithoutSuppliers(state),
  threads: chatThreadSelector(state),
  isConnected: isChatConnectedSelector(state),
});

export default compose(connect(mapStateToProps), withStyles(sheet))(Messages);
