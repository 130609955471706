/* @flow */
import React from 'react';
import { FormattedMessage } from 'react-intl';

import useStyles from './sheet';

const NotSupported = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.logo} />
      <div className={classes.content}>
        <div className={classes.imageWrapper}>
          <div className={classes.ipadIcon} />
        </div>
        <div className={classes.generalMessage}>
          <FormattedMessage id="notSupportedPage.ipadMessage" defaultMessage="iPad is not supported" />
        </div>
        <div className={classes.description}>
          <div className={classes.descriptionTitle}>
            <FormattedMessage
              id="notSupportedPage.description.title3"
              defaultMessage="Please, access application from Windows, Mac or Linux"
            />
            <div className={classes.chromeWrapper}>
              <div className={classes.chromeIcon} />
              <a
                href="https://www.google.com/chrome/"
                target="_blank"
                rel="noopener noreferrer"
                className={classes.chromeLink}
              >
                Chrome
              </a>
            </div>
            <FormattedMessage id="notSupportedPage.description.title2" defaultMessage="internet browser." />
          </div>
          <div className={classes.descriptionMessage}>
            <FormattedMessage
              id="notSupportedPage.description.message"
              defaultMessage="We are working on adding support for other browsers in the near future."
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotSupported;
