import FormControlLabel from '@mui/material/FormControlLabel';
import CheckboxMui from '@mui/material/Checkbox';
import { styled } from '@mui/material/styles';

export const ControlLabel = styled(FormControlLabel)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(12),
  margin: 0,
}));

export const Checkbox = styled(CheckboxMui)(({ theme }) => ({
  padding: 0,
  marginRight: theme.spacing(1),
}));
