// @flow
import React, { memo } from 'react';
import { FormattedMessage } from 'react-intl';
import ROUTES_PATH from 'domain/router/routesPathConfig';
import { generatePath } from 'react-router-dom';

import AuthLayout from 'pages/authPage/Layout';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import LinkMui from '@mui/material/Link';
import Button from '@mui/material/Button';
import RouterLink from 'components/mui/Router/RouterLink';
import IconEmailSuccess from 'components/Icons/email-success';

const PasswordLessSuccessAuth = () => (
  <AuthLayout>
    <Box textAlign="center">
      <IconEmailSuccess size={128} />
      <Typography variant="h5" mb={2}>
        <FormattedMessage id="auth.passwordLess.success.title" defaultMessage="Your Access Link has been Emailed" />
      </Typography>
      <Typography variant="subtitle2" fontWeight="400" color="text.secondary" mb={2}>
        <FormattedMessage
          id="auth.passwordLess.success.text"
          defaultMessage="We have sent you an email with an access link to Supplier Portal. If you have registered user in the system, please check your inbox."
        />
      </Typography>
      <Typography variant="subtitle2" fontWeight="400" color="text.secondary" mb={4}>
        <FormattedMessage
          id="auth.passwordLess.success.support"
          defaultMessage="If there is a problem, please, contact "
        />
        <LinkMui href="mailto:support@dokka.com" underline="hover" target="_blank" rel="noopener noreferrer">
          support@dokka.com
        </LinkMui>
      </Typography>
      <Button fullWidth component={RouterLink} href={generatePath(ROUTES_PATH.AUTH_LOGIN.absolute)}>
        <FormattedMessage id="auth.passwordLess.success.backBtn" defaultMessage="Back To Login" />
      </Button>
    </Box>
  </AuthLayout>
);

export default memo(PasswordLessSuccessAuth);
