// @flow
import { connectionHealthAction } from 'domain/env/envActions';
import * as Sentry from '@sentry/browser';

class ConnectionHealthService {
  constructor(dispatch) {
    this.dispatch = dispatch;
    this.networkConnection = navigator.connection || navigator.mozConnection || navigator.webkitConnection;
    this.alertDisabled = process.env.REACT_APP_SLOW_CONNECTION_DETECTION_ALERT_DISABLED;
    this.init();
  }

  onChangeConnection = ({ target }) => {
    const { downlink } = target;

    if (!parseInt(this.alertDisabled)) {
      this.dispatch({
        type: connectionHealthAction.type,
        payload: downlink >= 2,
      });
    }

    if (downlink < 2 && !parseInt(this.alertDisabled)) {
      Sentry.captureMessage('Low bandwidth detected', {
        level: 'warning',
        tags: { source: 'CONNECTION_HEALTH' },
        extra: {
          bandwidth: downlink,
          alertDisabled: this.alertDisabled,
        },
      });
    }
  };

  init = () => {
    if (this.networkConnection) {
      this.networkConnection.onchange = this.onChangeConnection;
    } else {
      console.warn('NAVIGATOR CONNECTION API IS NOT SUPPORTED');
    }
  };
}

export default (dispatch) => new ConnectionHealthService(dispatch);
