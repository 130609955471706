import { styled } from '@mui/material/styles';
import ButtonBase from '@mui/material/ButtonBase';

export const Wrapper = styled('div', { label: 'DocumentTopPanel' })(({ theme }) => ({
  display: 'flex',
  flexShrink: 0,
  backgroundColor: theme.palette.grey[200],
}));

export const Resizer = styled(ButtonBase)(({ theme }) => ({
  width: 8,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'ew-resize',
  backgroundColor: theme.palette.grey[300],
  borderLeft: `1px solid ${theme.palette.grey[400]}`,
  borderRight: `1px solid ${theme.palette.grey[400]}`,
}));
