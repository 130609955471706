// @flow
import React from 'react';
import get from 'lodash/get';
import { compose } from 'redux';
import { Set } from 'immutable';
import cx from 'classnames';
import { FormattedMessage, injectIntl, type IntlShape } from 'react-intl';
import Message, { type MetaType } from 'components/Form/Message';
import DayPicker from 'react-day-picker';
import DateUtils from 'react-day-picker/build/DateUtils';
import moment from 'moment';
import 'react-day-picker/lib/style.css';
import { withStyles } from '@mui/styles';
import localeUtils from 'lib/localeHelper';
import formats, { ERRORS, type ErrorsType, DEFAULT_FORMAT } from 'components/Form/DayPicker/helper';
import InputElement from './input';
import sheet from './sheet';
import './style.css';

type DateTypes = 'date' | 'invoice_date' | 'payment_date';

type DateQueryItem = [string, string];

type DateQuery = {
  [key: DateTypes]: DateQueryItem,
};

type Props = {
  classes: {
    [key: string]: string,
  },
  format: $Values<typeof formats>,
  cancel: () => void,
  onFilter: (d: DateQuery) => void,
  query: {
    date?: [string, string],
    invoice_date?: [string, string],
    payment_date?: [string, string],
  },
  locale: $Keys<typeof formats>,
  intl: IntlShape,
};

type DateState = {|
  from: ?Date,
  to: ?Date,
  enteredTo: ?Date,
  invalid: Set<ErrorsType>,
|};

type DatesState = { [key: DateTypes]: DateState };

type State = {
  dates: DatesState,
  format: $Values<typeof formats>,
  currentDateType: DateTypes,
};

type DateTypeConfig = {
  type: string,
  title: {
    id: string,
    defaultMessage: string,
  },
};

const dateFilters: Array<DateTypeConfig> = [
  {
    type: 'date',
    title: {
      id: 'dayPicker.date',
      defaultMessage: 'By Date Received',
    },
  },
  {
    type: 'invoice_date',
    title: {
      id: 'dayPicker.invoice_date',
      defaultMessage: 'By Invoice Date',
    },
  },
  {
    type: 'payment_date',
    title: {
      id: 'dayPicker.payment_date',
      defaultMessage: 'By Payment Date',
    },
  },
];

export class DPicker extends React.Component<Props, State> {
  static isAllowedDay(day: Date, currentDateType: DateTypes): boolean {
    return currentDateType === 'payment_date' || DateUtils.isPastDay(day) || DateUtils.isSameDay(day, new Date());
  }

  constructor(props: Props) {
    super(props);
    moment.locale(props.locale);
    this.state = this.getInitialState();
  }

  getInitialState = (): $Exact<State> => {
    const dates = dateFilters.reduce(
      (res: DatesState, { type }: DateTypeConfig) => ({ ...res, [type]: this.getInitialDateState(type) }),
      {},
    );

    return {
      dates,
      format: this.getFormat(),
      currentDateType: 'date',
    };
  };

  getInitialDateState = (type: $PropertyType<DateTypeConfig, 'type'>): DateState => {
    const { query } = this.props;
    const date = get(query, type);

    const dateFn = (s?: string): ?Date => {
      const d = moment(s, DEFAULT_FORMAT);
      return d.isValid() ? d.toDate() : null;
    };

    const [from, to] = Array.isArray(date) && date.length === 2 ? date.map(dateFn) : [null, null];

    return { from, to, enteredTo: to, invalid: new Set() };
  };

  getFormat = () => {
    const { format } = this.props;
    return format || DEFAULT_FORMAT;
  };

  getCurrentDate = (): DateState => {
    const { currentDateType, dates } = this.state;
    return get(dates, currentDateType);
  };

  getTouchedDates = () => {
    const { dates } = this.state;
    const touchedEntries = Object.entries(dates).filter((entry: [string, DateState]) => {
      const { from, to } = entry[1];
      return from || to;
    });
    return Object.fromEntries(touchedEntries);
  };

  get disabled(): boolean {
    const touchedDates = Object.values(this.getTouchedDates());
    return touchedDates.length ? !touchedDates.every(this.validateDateState) : true;
  }

  get meta(): $Exact<MetaType> {
    const { invalid } = this.getCurrentDate();
    return {
      touched: invalid.has(ERRORS.period),
      error: 'invalidPeriod',
    };
  }

  setDateState = (newDateState: $Shape<DatesState>) => {
    const { currentDateType, dates } = this.state;
    const dateState = this.getCurrentDate();
    this.setState({
      dates: { ...dates, [currentDateType]: { ...dateState, ...newDateState } },
    });
  };

  setCurrentDateType = (type: string): void => {
    if (!this.validateDateState(this.getCurrentDate())) {
      this.handleReset();
    }
    this.setState({ currentDateType: type });
  };

  validateDateState = (dateState: DateState): boolean => {
    const { invalid, from, to } = dateState;
    return !invalid.size && from && to;
  };

  isSelectingFirstDay = (from: Date, to: Date, day: Date) => {
    const isBeforeFirstDay = from && DateUtils.isDayBefore(day, from);
    const isRangeSelected = from && to;
    return !from || isBeforeFirstDay || isRangeSelected;
  };

  handleDayClick = (day: Date) => {
    const { from, to } = this.getCurrentDate();
    const { currentDateType } = this.state;

    if (from && to && day >= from && day <= to) {
      this.handleReset();
      return;
    }
    if (this.isSelectingFirstDay(from, to, day) && this.constructor.isAllowedDay(day, currentDateType)) {
      this.setDateState({
        from: day,
        to: null,
        enteredTo: null,
      });
    } else if (this.constructor.isAllowedDay(day, currentDateType)) {
      this.setDateState({
        to: day,
        enteredTo: day,
      });
    }
  };

  handleDayMouseEnter = (day: Date) => {
    const { from, to } = this.getCurrentDate();
    const { currentDateType } = this.state;
    if (!this.isSelectingFirstDay(from, to, day) && this.constructor.isAllowedDay(day, currentDateType)) {
      this.setDateState({
        enteredTo: day,
      });
    }
  };

  handleReset = () => {
    const { currentDateType } = this.state;
    this.setDateState(this.getInitialDateState(currentDateType));
  };

  invalidatePeriod = (from: Date, to: Date, invalid: Set<ErrorsType>) => {
    const errorName = ERRORS.period;
    return DateUtils.isDayBefore(to, from) ? invalid.add(errorName) : invalid.remove(errorName);
  };

  update = (value) => {
    const { from, to } = this.getCurrentDate();
    if (value.from && to) {
      this.setDateState({
        ...value,
        invalid: this.invalidatePeriod(value.from, to, value.invalid),
      });
    } else if (from && value.to) {
      this.setDateState({
        ...value,
        invalid: this.invalidatePeriod(from, value.to, value.invalid),
      });
    } else {
      this.setDateState(value);
    }
  };

  handleOk = () => {
    const { dates } = this.state;
    const out = (d: ?Date): string => moment(d).format(DEFAULT_FORMAT);
    const datesEntries = Object.entries(dates).reduce((res, [key, date]: [string, DateState]) => {
      const { from, to } = date;
      return from && to ? [...res, [key, [from, to].map(out)]] : res;
    }, []);
    this.props.onFilter(Object.fromEntries(datesEntries));
    this.props.cancel();
  };

  render() {
    const { classes } = this.props;
    const { format, currentDateType } = this.state;
    const { from, to, enteredTo, invalid } = this.getCurrentDate();
    const toDay = new Date();
    const modifiers = { start: from, end: enteredTo };
    const [disabledDays, toMonth] = currentDateType !== 'payment_date' ? [{ after: toDay }, toDay] : [{}, null];

    const selectedDays = [from, { from, to: enteredTo }];

    return (
      <div className={classes.wrapper}>
        <h3 className={classes.title}>
          <FormattedMessage id="dayPicker.date_filter" defaultMessage="Date Filter" />
        </h3>

        <div className={classes.filterTypes}>
          {dateFilters.map(({ type, title }: DateTypeConfig) => (
            <button
              key={type}
              className={cx(classes.btn, classes.filterTypeBtn, {
                [classes.filterTypeActiveBtn]: type === currentDateType,
              })}
              onClick={() => {
                this.setCurrentDateType(type);
              }}
            >
              <FormattedMessage {...title} />
            </button>
          ))}
        </div>

        <div className={classes.range}>
          <FormattedMessage id="dayPicker.from" defaultMessage="From" />
          <InputElement
            name="from"
            classes={classes}
            format={format}
            onComplete={this.update}
            value={from}
            invalid={invalid}
          />
          <FormattedMessage id="dayPicker.to" defaultMessage="To" />
          <InputElement
            name="to"
            classes={classes}
            format={format}
            onComplete={this.update}
            value={to}
            invalid={invalid}
          />
        </div>
        <Message meta={this.meta} className={classes.messagePeriod} />
        <DayPicker
          key={currentDateType}
          className="Range"
          numberOfMonths={1}
          toMonth={toMonth}
          selectedDays={selectedDays}
          disabledDays={disabledDays}
          modifiers={modifiers}
          onDayClick={this.handleDayClick}
          onDayMouseEnter={this.handleDayMouseEnter}
          locale={this.props.locale}
          localeUtils={localeUtils(this.props.intl.formatMessage)}
        />
        <div className={classes.btnGroup}>
          <button
            type="button"
            className={cx(classes.btn, classes.btnSearch)}
            disabled={this.disabled}
            onClick={this.handleOk}
          >
            <FormattedMessage id="dayPicker.button.search" defaultMessage="Search" />
          </button>
        </div>
      </div>
    );
  }
}

export default compose(injectIntl, withStyles(sheet))(DPicker);
