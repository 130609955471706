/* @flow */
import * as React from 'react';
import { withStyles } from '@mui/styles';
import type { List } from 'immutable';
import cx from 'classnames';
import sheet from './sheet';
import CreateMessage from './create';
import MessageList from './list';
import type { IMessage } from './types.js.flow';

const templateVariables = ['name', 'sender', 'company'];

type templateVariablesEnum = 'name' | 'sender' | 'company';

type Props = {
  classes: {|
    [key: string]: string,
  |},
  listClassName?: string,
  inputClassName?: string,
  list: List<IMessage>,
  send: (message: IMessage & { text: string }) => void,
  onChangeText: (text: string) => void,
  onPicRecipient: (message: IMessage) => void,
  createMode: boolean,
  placeholderVariables: {|
    [templateVariablesEnum]: string,
  |},
};

type State = {
  active: ?number,
  text: ?string,
};

const initialEmailTemplate = `Hi [name],
I have some questions about certain bank transactions for "[company]" company.
Let me know if you have any questions.

Thanks,
[sender]`;
const replyTemplate = 'Please provide more details on this transaction';

class Messages extends React.Component<Props, State> {
  static getDerivedStateFromProps(props: Props, state: State) {
    // Initial state is set from props
    if (typeof state.text === 'undefined' && state.text !== null) {
      return {
        active: undefined,
        text: props.createMode ? Messages.interpolate(initialEmailTemplate, props) : replyTemplate,
      };
    }
    // Further props update state once, which will remove placeholder
    return {
      ...state,
      text: props.createMode ? Messages.interpolate(state.text, props) : state.text,
    };
  }

  static interpolate = (text: string, props: Props) =>
    templateVariables.reduce((A, V) => {
      const value = props.placeholderVariables[V]; // value to substtitude placeholder with
      const placeholder = `[${V}]`;
      return value ? A.replace(placeholder, value) : A;
    }, text);

  state: State = {
    active: undefined,
    text: undefined,
  };

  get activeName() {
    const { active } = this.state;
    if (typeof active === 'number') {
      const msg = this.props.list.get(active);
      // $FlowFixMe
      return `${msg.firstName} ${msg.lastName}`;
    }
    return undefined;
  }

  handlReplay = (idx: number) => {
    this.setState({ active: idx }, () => {
      if (this.textArea && typeof this.textArea.focus === 'function') {
        this.textArea.focus();
      }
      this.props.onPicRecipient(
        // $FlowFixMe
        this.props.list.get(idx),
      );
    });
  };

  handleOnChange = (text) => {
    if (this.props.createMode) {
      this.setState({ text: Messages.interpolate(text, this.props) });
    } else {
      this.setState({ text });
    }
  };

  refProxy = (el: ?HTMLElement) => {
    this.textArea = el;
  };

  textArea: ?HTMLElement;

  render() {
    const { classes, list, listClassName, inputClassName, createMode } = this.props;
    return (
      <div className={classes.bookkeeperMessagingContainer}>
        {list.size ? (
          <div className={cx(classes.messages, listClassName)}>
            <MessageList list={list} onReplay={this.handlReplay} listClassName={classes.innerListClassName} />
          </div>
        ) : null}
        {(this.activeName || createMode) && (
          <CreateMessage
            isInitialRequest={createMode}
            isAnonymous={!list.size}
            active={this.activeName}
            classes={classes}
            refProxy={this.refProxy}
            onChange={this.handleOnChange}
            value={this.state.text}
            className={inputClassName}
          />
        )}
      </div>
    );
  }
}

export default withStyles(sheet)(Messages);
