// @flow
import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import moment from 'moment';

import { erpIndexesSyncDateSelector, isSyncRunningSelector, syncErpIndexesAction } from 'domain/settings';
import { companiesByIdSelector } from 'domain/companies';

import useToggle from 'hooks/useToggle';

import ButtonMui from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import BacksyncIcon from '@mui/icons-material/Loop';

import { styled } from '@mui/material';
import { grey } from '@mui/material/colors';

const Button = styled(ButtonMui, { shouldForwardProp: (propName) => propName !== 'isBusy' })(({ isBusy }) => ({
  '@keyframes rotate': {
    from: {
      transform: 'rotate(360deg)',
    },
    to: {
      transform: 'rotate(0deg)',
    },
  },
  '&[disabled]': {
    svg: {
      animation: isBusy ? 'rotate 0.8s infinite linear' : false,
    },
  },
}));

const SyncButton = () => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const { companyId } = useParams();

  const isSyncRunning = useSelector(isSyncRunningSelector);
  const [isBusy, toggleBusy] = useToggle(isSyncRunning);

  const indexesSyncTime = useSelector(erpIndexesSyncDateSelector);
  const dateFormat = useSelector(companiesByIdSelector).getIn([companyId, 'dateFormat'], 'DD/MM/YYYY');

  const handleClick = useCallback(
    (e) => {
      e.stopPropagation();
      toggleBusy();

      new Promise((resolve, reject) => dispatch(syncErpIndexesAction({ resolve, reject })));
    },
    [dispatch, toggleBusy],
  );

  const buttonTitle = formatMessage({
    id: isBusy ? 'configurations.integrations.syncButton.updating' : 'configurations.integrations.syncButton',
    defaultMessage: isBusy ? 'Updating' : 'Update',
  });

  return (
    <Stack borderRight={`1px solid ${grey[200]}`} direction="row" alignItems="center" spacing={2} pr={2}>
      <Typography variant="caption" color={grey[600]}>
        {formatMessage({ id: 'configurations.integrations.lastSync', defaultMessage: 'Latest update' })}:{' '}
        {indexesSyncTime ? moment(`${indexesSyncTime}Z`).format(`${dateFormat} HH:mm:ss`) : '-'}
      </Typography>
      <Button
        size="small"
        color="primary"
        startIcon={<BacksyncIcon />}
        onClick={handleClick}
        disabled={isBusy}
        isBusy={isBusy}
      >
        {buttonTitle}
      </Button>
    </Stack>
  );
};

export default SyncButton;
