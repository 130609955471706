// @flow
export default () => ({
  wrapper: {
    position: 'relative',
    height: '100%',

    '&:focus': {
      zIndex: 10,
    },

    '&.is-paginated': {
      '& $fieldWrapper': {
        display: 'flex',
        alignItems: 'center',
      },
    },
  },
  open: {},
  fieldWrapper: {
    position: 'relative',
    height: '100%',

    '&.field-with-icon': {
      '& $field': {
        paddingLeft: 18,
      },
    },
  },
  field: {
    width: '100%',
    padding: [0, 28, 0, 8],
    border: '0 none',
    outline: 'none',
    height: '100%',
  },

  fieldIconWrapper: {
    position: 'absolute',
    top: 2,
    left: 0,
    fontSize: '16px !important',
  },

  btnShield: {
    position: 'absolute',
    width: 24,
    height: 24,
    borderRadius: 2,
    padding: 0,
    top: 0,
    bottom: 0,
    right: 4,
    margin: 'auto',
    cursor: 'pointer',
    zIndex: 9,
    '&:after': {
      content: '""',
      display: 'inline-block',
      border: '4px solid transparent',
      borderTopColor: '#333333',
      width: 0,
      height: 0,
      position: 'absolute',
      right: 0,
      bottom: 0,
      top: 5,
      left: 0,
      margin: 'auto',
    },
  },
  btnRotate: {
    transform: ({ isOpen }) => (isOpen ? 'rotate(180deg)' : 'rotate(0deg)'),
  },
});
