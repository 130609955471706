export default (theme) => ({
  wrapper: {
    zIndex: 999,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
    top: -7,
    maxHeight: 0,
  },

  chatBox: {
    position: 'fixed',
    top: 52,
    right: 10,
    maxWidth: 400,
    maxHeight: 0,
    height: 0,
    boxShadow: '0px 2px 13px rgba(50, 45, 45, 0.25)',
    transition: 'height 0.2s ease, maxHeight 0.2s ease',
  },

  closeChatBox: {
    maxHeight: '0vh',
    height: 0,
    transition: 'height 1s ease, maxHeight 1s ease',
  },

  openChatBox: {
    maxHeight: '70vh',
    height: 600,
    transition: 'height 0.5s ease',
  },

  widgetPlaceArrow: {
    height: 0,
    width: 0,
    position: 'relative',
    zIndex: 1,
    borderTop: '12px solid transparent',
    borderLeft: '10px solid transparent',
    borderRight: '10px solid transparent',
    borderBottom: `10px solid ${theme.colors.primaryLight}`,
  },

  chatBtn: {
    maxHeight: 24,
    position: 'relative',
  },

  topChatWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },

  chatUnread: {
    cursor: 'pointer',
    position: 'absolute',
    top: -6,
    left: 14,
    zIndex: 1,
    height: 16,
    minWidth: 16,
    borderRadius: 50,
    backgroundColor: '#FFF',
    boxShadow: '0px 2px 13px rgba(50, 45, 45, 0.25)',
    color: '#BC5353',
    fontSize: 11,
    fontWeight: 'bold',
    lineHeight: '16px',
    padding: [0, 5],
    textAlign: 'center',
    border: 'none',
    outline: 'none',
  },

  newMessages: {
    animation: '$newMessage 1.5s ease-in 1',
  },

  '@keyframes newMessage': {
    '0%': {
      transform: 'scale(1)',
    },
    '10%': {
      transform: 'scale(1.5)',
    },
    '20%': {
      transform: 'scale(1)',
    },
    '30%': {
      transform: 'scale(1.4)',
    },
    '40%': {
      transform: 'scale(1)',
    },
    '50%': {
      transform: 'scale(1.3)',
    },
    '60%': {
      transform: 'scale(1)',
    },
    '70%': {
      transform: 'scale(1.2)',
    },
    '80%': {
      transform: 'scale(1)',
    },
    '90%': {
      transform: 'scale(1.1)',
    },
    '94%': {
      transform: 'scale(1)',
    },
    '97%': {
      transform: 'scale(1.05)',
    },
    '100%': {
      transform: 'scale(1)',
    },
  },

  chatPlace: {
    height: 0,
    width: 0,
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
});
