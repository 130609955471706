// @flow
import React, { memo, useCallback, useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import { getFTPConnectionAction, saveFTPConnectionAction } from 'domain/settings/settingsActions';
import Spinner from 'components/mui/CircularProgressWithBackdrop';
import FTPConnectionForm from 'components/ERPSettings/FTPConnection/FTPConnectionForm';

import { Map } from 'immutable';

import { useDispatch } from 'react-redux';

type TFTPConnectionModal = {
  onClose: () => void,
};

const FTPConnectionModal: React$StatelessFunctionalComponent<TFTPConnectionModal> = ({ open, onClose }) => {
  const dispatch = useDispatch();
  const [isBusy, setIsBusy] = useState(true);
  const [initialValues, setInitialValues] = useState(
    Map({
      address: '',
      login: '',
      password: '',
    }),
  );

  const handleSubmit = useCallback(
    (values) =>
      new Promise((resolve, reject) => {
        dispatch(saveFTPConnectionAction({ values, resolve, reject }));
      }).then(() => {
        onClose();
      }),
    [dispatch, onClose],
  );

  useEffect(() => {
    new Promise((resolve, reject) => {
      dispatch(getFTPConnectionAction({ resolve, reject }));
    })
      .then((config) => {
        setInitialValues(Map(config));
      })
      .finally(() => {
        setIsBusy(false);
      });
  }, [dispatch]);

  return (
    <Dialog fullWidth open={open} onClose={onClose}>
      {isBusy && <Spinner isOpen={isBusy} BackdropProps={{ sx: { position: 'absolute' } }} />}
      <FTPConnectionForm
        key={isBusy.toString()}
        initialValues={initialValues}
        onSubmit={handleSubmit}
        onClose={onClose}
      />
    </Dialog>
  );
};

export default memo(FTPConnectionModal);
