import React from 'react';

import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import styled from '@mui/material/styles/styled';
import { darken } from '@mui/material/styles';
import { green, orange, red } from '@mui/material/colors';
import MuiKeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

import elements from 'components/elements';

const [hover] = [0.05];

const sizes = {
  large: 1.5,
  medium: 1,
  small: 0.5,
};

const KeyboardArrowRightIcon = styled(MuiKeyboardArrowRightIcon, {
  shouldForwardProp: (prop) => prop !== 'arrowDirection',
})(({ theme, arrowDirection, fontSize }) => ({
  margin: theme.spacing(0, -sizes[fontSize]),
  transform: `rotate(${theme.direction === 'rtl' ? 180 : 0}deg)`,
  ...(arrowDirection === 'column' && { transform: 'rotate(90deg)' }),
}));

const createStatusColors = (theme) => ({
  draft: {
    backgroundColor: theme.palette.common.white,
    color: 'inherit',
    '&&:hover': {
      backgroundColor: darken(theme.palette.common.white, hover),
    },
  },
  pending: {
    backgroundColor: orange[100],
    color: 'inherit',
    borderColor: theme.palette.warning.light,
    '&&:hover': {
      backgroundColor: darken(orange[100], hover),
    },
  },
  approved: {
    backgroundColor: green[100],
    color: 'inherit',
    borderColor: theme.palette.success.light,
    '&&:hover': {
      backgroundColor: darken(green[100], hover),
    },
  },
  rejected: {
    backgroundColor: red[100],
    color: 'inherit',
    borderColor: theme.palette.error.light,
    '&&:hover': {
      backgroundColor: darken(red[100], hover),
    },
  },
});

const colors = {
  draft: undefined,
  pending: 'warning',
  approved: 'success',
  rejected: 'error',
};

const SChip = styled(Chip)(({ theme, status }) => ({
  ...(status
    ? createStatusColors(theme)[status]
    : {
        backgroundColor: theme.palette.common.white,

        '& .MuiChip-deleteIcon': {
          color: theme.palette.grey[300],
        },
      }),
}));

const Approver = React.forwardRef(
  ({ hasArrow = true, status, arrowDirection = 'row', spacing = 1, size = 'medium', sx, ...props }, ref) => (
    <Stack justifyContent="center" alignItems="center" direction={arrowDirection} spacing={spacing} ref={ref} sx={sx}>
      <SChip
        data-element={elements.content.documents.approvals.approvalItem}
        variant="outlined"
        color={colors[status]}
        status={status}
        size={size}
        {...props}
      />
      {hasArrow && (
        <Stack>
          <KeyboardArrowRightIcon arrowDirection={arrowDirection} fontSize={size} />
        </Stack>
      )}
    </Stack>
  ),
);

export default Approver;
