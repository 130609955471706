// @flow

import { localeSelector } from 'domain/env';
import type { LocalesType, EnvStore } from 'domain/env/types.js.flow';
import type { Store } from 'redux';
import { handleImport } from 'lib/import';

import '@formatjs/intl-pluralrules/polyfill';
import '@formatjs/intl-pluralrules/locale-data/en';
import '@formatjs/intl-pluralrules/locale-data/he';
import '@formatjs/intl-pluralrules/locale-data/es';
import '@formatjs/intl-pluralrules/locale-data/it';
import '@formatjs/intl-pluralrules/locale-data/fr';

type LoadersType<T> = {
  [key: LocalesType]: () => Promise<T>,
};

type S = {
  env: EnvStore,
};

type A = () => void;

type TranslationDictionary = {
  default: {
    [key: string]: string,
  },
};

type Intl = {
  locale: LocalesType,
  translation: TranslationDictionary,
};

const TRANSLATION: LoadersType<TranslationDictionary> = {
  en: () => handleImport(import('./en')),
  uk: () => handleImport(import('./en')),
  he: () => handleImport(import('./he')),
  es: () => handleImport(import('./es')),
  it: () => handleImport(import('./it')),
  fr: () => handleImport(import('./fr')),
};

type IntlType = [Array<$npm$ReactIntl$LocaleData>, Intl];

export default function intl(store: Store<S, A>): Promise.all<IntlType> {
  const locale: LocalesType = localeSelector(store.getState());
  return Promise.all([TRANSLATION[locale]()]).then((res: IntlType): Intl => {
    return { locale, translation: res[0].default };
  });
}
