// @flow
import React, { useCallback, useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { useMatch } from 'react-router-dom';
import { matchRoute } from 'domain/router/utils';
import { usePopupState, bindTrigger, bindMenu } from 'material-ui-popup-state/hooks';
import { documentHotkeysIsLoadingSelector, documentHotkeysSelector } from 'domain/documents';
import {
  helpdeskLinkSelector,
  localeSelector,
  organizationNameSelector,
  userNamesSelector,
  userSelector,
} from 'domain/env';
import { currentCompanyNameSelector } from 'domain/companies';
import { currentConnectedERPselector } from 'domain/settings';
import { toggleDocumentHotkeysModalAction, toggleWorkspaceHotkeysModalAction } from 'domain/ui';
import { useTheme } from '@mui/material';
import * as ACL from 'domain/restriction';
import useScript from 'hooks/useScript';
import openChat from 'lib/freshdeskChat/openFreshDeskChat';

import Tooltip from 'components/mui/Tooltip';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import { ListItemIcon } from 'components/mui/Layouts/components/Drawer';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';

import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import KeyboardIcon from '@mui/icons-material/Keyboard';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import ROUTES_PATH from 'domain/router/routesPathConfig';

type THelpDropdown = {};

const mapStateToProps = (state) => ({
  isLoadingHotkeys: documentHotkeysIsLoadingSelector(state),
  hotkeys: documentHotkeysSelector(state),
  isGranted: ACL.isGranted(state),
  helpdeskLink: helpdeskLinkSelector(state),
  currentUser: userSelector(state),
  userName: userNamesSelector(state),
  organizationName: organizationNameSelector(state),
  currentCompanyName: currentCompanyNameSelector(state),
  currentConnectedERP: currentConnectedERPselector(state),
  locale: localeSelector(state),
});

const HelpDropdown: React$StatelessFunctionalComponent<THelpDropdown> = () => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const {
    isGranted,
    hotkeys,
    isLoadingHotkeys,
    helpdeskLink,
    currentUser,
    userName,
    organizationName,
    currentCompanyName,
    currentConnectedERP,
    locale,
  } = useSelector(mapStateToProps);
  const isAllowedFreshdeskByACL = isGranted(ACL.ALLOW_FRESHDESK);
  const isSupplier = isGranted(ACL.IS_SUPPLIER_USER);
  const isDocumentPage = useMatch(ROUTES_PATH.DOCUMENT.absolute);

  const popupState = usePopupState({ variant: 'popover', popupId: 'HelpDropdown' });
  const { labelOptions } = useTheme();

  const freshdeskStatus = useScript(
    isAllowedFreshdeskByACL
      ? `https://widget.freshworks.com/widgets/${process.env.REACT_APP_FRESHDESK_WIDGET_ID}.js`
      : undefined,
  );

  const isWorkspacePage = matchRoute.isCompany(window.location.pathname);

  const availableItems = {
    contactSupport: isAllowedFreshdeskByACL || labelOptions.name === 'AlPrime',
    helpDesk: Boolean(helpdeskLink),
    hotKeys: (isDocumentPage && !isLoadingHotkeys && hotkeys.size > 0 && isGranted(ACL.IS_ACCOUNT)) || isWorkspacePage,
  };

  const isVisible = !isSupplier && Object.values(availableItems).filter(Boolean).length > 0;

  const onClickHotkey = useCallback(() => {
    const action = isWorkspacePage ? toggleWorkspaceHotkeysModalAction : toggleDocumentHotkeysModalAction;
    dispatch(action());
    popupState.close();
  }, [popupState, dispatch, isWorkspacePage]);

  const onClickContactSupport = () => {
    if (labelOptions.name === 'AlPrime') {
      window.location = `mailto:${labelOptions.supportEmail}`;
    } else {
      const { userId: email } = currentUser;
      openChat({ email, organizationName, currentCompanyName, currentConnectedERP, userName, locale });
    }

    popupState.close();
  };

  const tTooltip =
    freshdeskStatus === 'ready' || availableItems.contactSupport
      ? {
          id: 'header.menu.help',
          defaultMessage: 'Help',
        }
      : {
          id: 'header.menu.helpDisabled',
          defaultMessage: 'Experiencing help widget issues',
        };

  useLayoutEffect(() => {
    if (freshdeskStatus === 'ready') {
      console.log('DOKKA LOG: Freshdesk script loaded');

      // hide widget launcher iframe via css, because sometimes freshdesk widget dont want hide through
      // window.FreshworksWidget('hide', 'launcher');
      // I guess Freshdesk script is not yet initialized when hide API is invoked
      // 1 - always hide using styles/css - see useCreateTheme file
      // 2- try hide use widget API after 1second timeout
      const timer = setTimeout(() => {
        window.FreshworksWidget('hide', 'launcher');
      }, 1000);

      return () => clearTimeout(timer);
    }

    return () => {};
  }, [freshdeskStatus]);

  return (
    isVisible && (
      <>
        <Tooltip t={tTooltip}>
          <IconButton color="inherit" {...bindTrigger(popupState)}>
            <HelpOutlineOutlinedIcon color="primary" />
          </IconButton>
        </Tooltip>

        <Menu
          {...bindMenu(popupState)}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          {availableItems.helpDesk && (
            <MenuItem
              component={Link}
              href={helpdeskLink}
              target="_blank"
              rel="noopener noreferrer"
              onClick={popupState.close}
            >
              <ListItemIcon>
                <InfoOutlinedIcon />
              </ListItemIcon>
              <ListItemText>
                {formatMessage({
                  id: 'header.menu.info.helpCenter',
                  defaultMessage: 'Help Center',
                })}
              </ListItemText>
            </MenuItem>
          )}

          <MenuItem disabled={freshdeskStatus !== 'ready'} onClick={onClickContactSupport}>
            <ListItemIcon>
              <ChatBubbleOutlineIcon />
            </ListItemIcon>
            <ListItemText>
              {formatMessage({
                id: 'header.menu.info.support',
                defaultMessage: 'Contact support',
              })}
            </ListItemText>
          </MenuItem>

          {availableItems.hotKeys && (
            <MenuItem onClick={onClickHotkey}>
              <ListItemIcon>
                <KeyboardIcon />
              </ListItemIcon>
              <ListItemText>
                {formatMessage({
                  id: 'header.menu.info.hotkeys',
                  defaultMessage: 'Keyboard shortcuts',
                })}
              </ListItemText>
            </MenuItem>
          )}
        </Menu>
      </>
    )
  );
};

export default HelpDropdown;
