import React, { useEffect, Fragment } from 'react';
import usePrompt from 'hooks/usePrompt';

import { useConfirm } from 'material-ui-confirm';

// eslint-disable-next-line react/prop-types
const Prompt = ({ when, message }) => {
  const blocker = usePrompt(when);
  const confirm = useConfirm();

  useEffect(() => {
    if (blocker.state === 'blocked') {
      confirm({
        description: message,
      })
        .then(() => {
          blocker.proceed();
        })
        .catch(() => {
          blocker.reset();
        });
    }
  }, [blocker, confirm, message]);

  return <></>;
};

export default Prompt;
