// @flow
import React from 'react';

// TODO: check types, its temporary
import SelectMui, { type SelectProps as MuiSelectProps } from '@mui/material/Select';
import FormControl, { type FormControlProps as MuiFormControlProps } from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import InputLabel, { type InputLabelProps as MuiInputLabelProps } from '@mui/material/InputLabel';
import elements from 'components/elements';

export type TOption = {
  value: string | number,
  label: string,
};

export type TOptions = $ReadOnlyArray<TOption>;

export type TSelectBase = {
  options: TOptions,
  id?: string,
  renderOption?: (o: TOption) => string,
  FormControlProps?: MuiFormControlProps,
  InputLabelProps?: MuiInputLabelProps,
} & MuiSelectProps;

const ITEM_HEIGHT = 36;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 8.5,
    },
  },
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'left',
  },
};

const SelectBase: React$StatelessFunctionalComponent<TSelectBase> = ({
  label,
  options,
  renderOptionLabel,
  renderOptions,
  id,
  FormControlProps = {},
  InputLabelProps = {},
  inputProps = {},
  ...props
}) => (
  <FormControl {...FormControlProps}>
    <InputLabel id={id} {...InputLabelProps}>
      {label}
    </InputLabel>
    <SelectMui
      labelId={id}
      label={label}
      MenuProps={MenuProps}
      inputProps={{ ...inputProps, 'data-element': elements.popup.Upload.categorySelector.input }}
      {...props}
    >
      {typeof renderOptions === 'function'
        ? renderOptions()
        : options.map((o) => (
            <MenuItem
              key={`${o.value}-${o.label}`}
              value={o.value}
              data-element={elements.popup.Upload.categorySelector.listItem}
            >
              {typeof renderOptionLabel === 'function' ? renderOptionLabel(o) : o.label}
            </MenuItem>
          ))}
    </SelectMui>
  </FormControl>
);

export default SelectBase;
