// @flow
import React from 'react';
import { type FormControlLabelProps as MuiFormControlLabelProps } from '@mui/material/FormControlLabel';
import Switch from 'components/mui/Form/Switch/SwitchBase';
import { FormControlLabelSwitch } from 'components/mui/Form/FormControl/FormControlLabelSwitch';
import { type SwitchProps as MuiSwitchProps } from '@mui/material/Switch';

import type { FieldProps } from 'redux-form/lib/FieldProps.types.js.flow';

// TODO: switch is WIP
type TSwitchForm = {
  SwitchProps?: MuiSwitchProps,
  FormControlLabelProps?: MuiFormControlLabelProps,
} & FieldProps;

const SwitchForm: React$StatelessFunctionalComponent<TSwitchForm> = (props) => {
  const { input, SwitchProps = {}, FormControlLabelProps = {} } = props;
  const { onChange, value } = input;

  return (
    <FormControlLabelSwitch
      color="primary"
      checked={value}
      control={<Switch onChange={onChange} {...SwitchProps} />}
      {...FormControlLabelProps}
    />
  );
};

export default SwitchForm;
