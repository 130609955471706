// @flow
import React, { useCallback, useState, useEffect, useRef, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import { organizationDomainSelector } from 'domain/organization';

import Autocomplete from '@mui/material/Autocomplete';

import TextFieldForm from 'components/mui/Form/TextField/TextFieldForm';

import type { FieldProps } from 'redux-form/lib/FieldProps.types.js.flow';
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import ArrowCircleRightSharpIcon from '@mui/icons-material/ArrowCircleRightSharp';
import { emailReg } from 'components/Form/validation';

type Props = {
  domainOptions: Array<string>,
  handleSubmit: () => void,
  label?: string,
  meta: $PropertyType<FieldProps, 'meta'>,
  input: {
    value: Array<string>,
    name: string,
    onChange: (value: Array<string>) => void,
    onBlur: (value: Array<string>) => void,
  },
};

const getIsValidDomain = (domain) => emailReg.test(`some@${domain}`);

const DomainAutocomplete: React$StatelessFunctionalComponent<Props> = ({
  domainOptions,
  handleSubmit,
  input: { value, onChange, onBlur, ...inputRest },
  label: inputLabel,
  meta,
  ...rest
}) => {
  const { formatMessage } = useIntl();
  const isFirstRender = useRef(true);
  const organizationDomain = useSelector(organizationDomainSelector);

  const domainValue = organizationDomain ? organizationDomain.split(',') : [];

  const [inputValue, setInputValue] = useState('');
  const [error, setError] = useState(false);
  const [helperText, setHelperText] = useState('');

  const resetError = useCallback(() => {
    setError(false);
    setHelperText('');
  }, []);

  const addDomain = useCallback(
    (e: SyntheticMouseEvent) => {
      e.stopPropagation();
      e.preventDefault();
      if (!getIsValidDomain(inputValue)) {
        setError(true);
        setHelperText(formatMessage({ id: 'message.invalid_domain', defaultMessage: 'Invalid domain' }));
      } else {
        resetError();
        setInputValue('');
        onChange([...domainValue, inputValue]);
      }
    },
    [inputValue, onChange, domainValue, resetError, formatMessage],
  );

  const deleteDomain = useCallback(
    (domain) => () => {
      const domains = domainValue.filter((v) => v !== domain);
      onChange(domains);
    },
    [domainValue, onChange],
  );

  const isDisableSubmit = useMemo(
    () => !inputValue || domainValue.includes(inputValue) || error,
    [inputValue, error, domainValue],
  );

  const handleKeyDown = useCallback(
    (event) => {
      if (
        ((event.key === 'Backspace' || event.key === 'Enter') && !event.target.value) ||
        (event.key === 'Enter' && isDisableSubmit)
      ) {
        event.stopPropagation();
        event.preventDefault();
      }
      if (event.key === 'Enter' && event.target.value && !isDisableSubmit) {
        addDomain(event);
      }
    },
    [addDomain, isDisableSubmit],
  );

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    handleSubmit();
  }, [value, handleSubmit]);

  return (
    <>
      <Autocomplete
        {...rest}
        multiple
        autoHighlight
        id={inputLabel}
        value={domainValue}
        onChange={(event, newValue) => onChange(newValue)}
        inputValue={inputValue}
        onInputChange={(e, newValue) => {
          if (error && getIsValidDomain(newValue)) {
            resetError();
          }
          setInputValue(newValue.toLowerCase());
        }}
        filterSelectedOptions
        options={domainOptions}
        freeSolo
        disableClearable
        renderTags={() => null}
        renderInput={(params) => (
          <TextFieldForm
            {...params}
            {...rest}
            input={inputRest}
            label={inputLabel}
            meta={meta}
            value={inputValue}
            onKeyDown={handleKeyDown}
            error={error}
            helperText={helperText}
            inputProps={{
              ...params.inputProps,
              maxLength: 32,
            }}
            InputProps={{
              ...params.InputProps,
              ...{
                endAdornment: (
                  <IconButton
                    aria-label="enter"
                    disabled={isDisableSubmit}
                    onClick={addDomain}
                    variant="contained"
                    sx={{
                      rotate: '-90deg',
                      p: 0,
                    }}
                  >
                    <ArrowCircleRightSharpIcon
                      color={isDisableSubmit ? 'disabled' : 'primary'}
                      sx={{ width: 32, height: 32 }}
                    />
                  </IconButton>
                ),
                sx: { maxWidth: 'max(50%, 400px)' },
              },
            }}
          />
        )}
      />

      <Box sx={{ mt: '8px !important', display: 'flex', flexWrap: 'wrap', gap: 1, maxWidth: 'max(50%, 400px)' }}>
        {domainValue?.map((domain) => (
          <Chip variant="outlined" color="primary" key={domain} label={domain} onDelete={deleteDomain(domain)} />
        ))}
      </Box>
    </>
  );
};

export default DomainAutocomplete;
