import { GRID_SERVICE_KEYS } from 'components/AgGrid/helpers';
import { IS_OPEN_CONTEXT_MENU_CLASS } from 'components/AgGrid/hooks/agGridThemeClasses';
import { makeStyles } from '@mui/styles';
import { alpha } from '@mui/material/styles';

const {
  PREVIEW_CHECKBOX_COLUMN_NAME,
  PREVIEW_BTN_COLUMN_NAME,
  COLUMNS_VISIBILITY_MENU,
  CONTEXT_MENU_COLUMN_NAME,
  COLUMNS_LINKED_ICON,
} = GRID_SERVICE_KEYS;

export default makeStyles((theme) => {
  const isRtl = theme.direction === 'rtl';
  const LEFT = isRtl ? 'right' : 'left';
  const RIGHT = isRtl ? 'left' : 'right';

  return {
    '@global': {
      '.ag-theme-material': {
        '&&, && .ag-header-row': {
          fontFamily: 'var(--ag-font-family)',
          fontSize: 'var(--ag-font-size)',
        },
        '& .ag-react-container': {
          width: '100%',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          position: 'relative',
        },
        // for tooltip handler in filter set item
        '& .ag-set-filter-item .ag-react-container': {
          position: 'static',
        },
        '&': `
        --ag-font-family: ${theme.typography.fontFamily};
        --ag-font-size: 14px;
        --ag-material-primary-color: ${theme.palette.primary.main};
        --ag-material-accent-color: ${theme.palette.primary.main};
        // --ag-checkbox-indeterminate-color: ${theme.palette.primary.main};
        --ag-input-focus-border-color: ${theme.palette.primary.main};
        --ag-input-border-color-invalid: ${theme.palette.error.main};
        --ag-invalid-color: ${theme.palette.error.main};
        --ag-checkbox-unchecked-color: ${theme.palette.action.active};
        --ag-foreground-color: ${theme.palette.text.primary};
        --ag-header-foreground-color: ${theme.palette.text.primary};
        --ag-selected-row-background-color: ${alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity)};
        --ag-range-selection-border-color: ${theme.palette.primary.main};
        --ag-row-hover-color: ${theme.palette.action.hover};
        --ag-header-background-color:  ${theme.palette.grey[100]};
        --ag-border-color:  ${theme.palette.grey[300]};
        --ag-background-color: ${theme.palette.common.white};
        --ag-cell-background-color-transparent: ${alpha(theme.palette.common.white, 0.4)};
        --ag-shadow-color: ${alpha(theme.palette.common.black, 0.5)};
      `,
        '&& .ag-theme-material .ag-picker-field-wrapper': {
          border: 'solid 1px',
          borderColor: 'var(--ag-border-color)',
        },

        '&& .ag-theme-material .ag-popup-child': {
          boxShadow: 'none',

          '&.ag-react-container.ag-tooltip-custom': {
            position: 'absolute',
            pointerEvents: 'none',
            minWidth: 50,
            maxWidth: 300,
            height: 'auto',

            '& .ag-tooltip-custom--content': {
              fontSize: 12,
              backgroundColor: theme.palette.grey[800],
              borderRadius: 4,
              color: theme.palette.common.white,
              padding: theme.spacing(0.5, 1),
              display: 'flex',
              background: 'white',
              wordBreak: 'break-all',
              width: 'max-content',
            },
          },
        },

        '&& .ag-root-wrapper': {
          background: 'none !important',
        },

        '&& .ag-header': {
          marginBottom: 10,
          border: 'none',
          background: 'none',
        },

        '&& .ag-status-bar': {
          borderBottom: 'none',
          borderLeft: 'none',
          borderRight: 'none',
          backgroundColor: 'var(--ag-background-color)',
        },
        '&& .ag-cell-wrapper .ag-selection-checkbox': {
          marginLeft: theme.spacing(1),
          marginRight: 0,
        },

        '&& .ag-group-contracted, .ag-group-expanded': {
          margin: '0 !important',
        },

        '&& .ag-standard-button': {
          padding: theme.spacing(0.5, 1.25),
          borderRadius: 100,
          '&:hover': {
            backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.hoverOpacity),
            cursor: 'pointer',
          },
        },

        '&& input, && .ag-picker-field-wrapper.ag-picker-has-focus, && .ag-checkbox-input-wrapper, && .ag-floating-filter-button-button, && .ag-standard-button':
          {
            boxShadow: 'none!important',
            '&:focus': {
              borderColor: theme.palette.primary.main,
            },
          },

        '&& .ag-body-horizontal-scroll.ag-scrollbar-invisible': {
          pointerEvents: 'all !important',
        },

        // ======== STYLES FOR CELL
        '& .ag-cell, & .ag-header-cell': {
          padding: theme.spacing(0, 2),
          borderTop: '1px solid var(--ag-border-color)',
          borderBottom: '1px solid var(--ag-border-color)',
        },

        '& .ag-header-cell': {
          backgroundColor: 'var(--ag-header-background-color)',

          '&-resize:before': {
            content: '""',
            position: 'absolute',
            zIndex: 1,
            left: 'calc(50% - 1px)',
            width: 2,
            height: '30%',
            top: '35%',
            backgroundColor: theme.palette.grey[300],
          },

          [`&.${COLUMNS_VISIBILITY_MENU}`]: {
            padding: theme.spacing(0, 0.5),
            borderRadius: '0 8px 8px 0',
            border: '1px solid var(--ag-border-color) !important',
          },

          [`&.${CONTEXT_MENU_COLUMN_NAME}`]: {
            display: 'none',
          },

          [`&.${PREVIEW_CHECKBOX_COLUMN_NAME}`]: {
            flexDirection: 'row-reverse',
          },

          '& .ag-header-select-all': {
            margin: 0,
          },
        },

        '& .ag-cell': {
          backgroundColor: 'var(--ag-cell-background-color-transparent)',

          '&.ag-cell-focus[class*="__SERVICE"]': {
            borderTopColor: 'var(--ag-border-color)',
            borderBottomColor: 'var(--ag-border-color)',
            borderLeftColor: 'transparent',
            borderRightColor: 'transparent',
          },

          '&.ag-cell-range-selected-1:not(.ag-column-hover)': {
            backgroundColor: 'var(--ag-range-selection-background-color ,rgba(122,134,203,.1))',
          },

          '&.ag-cell-range-selected:not(.ag-column-hover)': {
            backgroundColor: 'var(--ag-range-selection-background-color ,rgba(122,134,203,.1))',
          },

          '&.ag-cell-range-selected.ag-cell-range-left.ag-cell-range-right': {
            border: '1px solid var(--ag-range-selection-border-color)',
          },

          '&.cell-negative-number': {
            // Minus must be always on the left side DA-4725
            unicodeBidi: 'plaintext',
          },

          [`&.${COLUMNS_VISIBILITY_MENU}`]: {
            borderRight: '1px solid var(--ag-border-color) !important',
            borderRadius: '0 8px 8px 0',
          },

          [`&.${CONTEXT_MENU_COLUMN_NAME}`]: {
            opacity: 0,
            border: 0,
            backgroundColor: 'transparent',

            // context menu icon stay visible when menu is open
            [`&.${IS_OPEN_CONTEXT_MENU_CLASS}`]: {
              opacity: '1!important',
            },
          },

          [`&.${PREVIEW_CHECKBOX_COLUMN_NAME}`]: {
            '& .ag-cell-wrapper': {
              flexDirection: 'row-reverse',
            },
          },

          [`&[col-id="${PREVIEW_CHECKBOX_COLUMN_NAME}"],
            &[col-id="${PREVIEW_BTN_COLUMN_NAME}"],
            &[col-id="${CONTEXT_MENU_COLUMN_NAME}"],
            &[col-id="${COLUMNS_LINKED_ICON}"],
            &[col-id="${COLUMNS_VISIBILITY_MENU}"]`]: {
            '& .ag-range-handle': {
              width: 0,
            },
          },
        },

        // ======== STYLES FOR ROW
        '& .ag-row': {
          maxHeight: 48,
          background: 'none',
          border: 'none',

          '&.ag-row-hover': {
            background: 'none',

            '&:before': {
              display: 'none',
            },

            '& .ag-cell': {
              backgroundColor: 'var(--ag-row-hover-color)',

              [`&.${CONTEXT_MENU_COLUMN_NAME}`]: {
                opacity: 1,
                backgroundColor: 'transparent',
              },
            },
          },

          '& :before': {
            background: 'none',
          },

          '&.ag-row-level-1': {
            backgroundColor: 'var(--ag-background-color) !important',
            width: 'calc(100% - 70px)',
            maxHeight: 390,
            marginLeft: 60,
            borderLeft: `3px solid ${theme.palette.common.black}`,
            outline: '1px solid var(--ag-border-color)',
            borderRadius: 8,
          },

          '&.row-has-error': {
            '& .ag-cell': {
              backgroundColor: `${alpha(theme.palette.error.main, theme.palette.action.activatedOpacity)}`,
            },
            '&.ag-row-hover .ag-cell': {
              backgroundColor: `${alpha(
                theme.palette.error.main,
                theme.palette.action.activatedOpacity + theme.palette.action.hoverOpacity,
              )}`,
            },
            '&.ag-row-selected .ag-cell': {
              backgroundColor: `${alpha(
                theme.palette.error.main,
                theme.palette.action.activatedOpacity + theme.palette.action.selectedOpacity,
              )}`,
            },
            '& .ag-cell.ag-cell-range-selected:not(.ag-column-hover)': {
              '&:after': {
                content: '""',
                width: '100%',
                height: '100%',
                position: 'absolute',
                top: 0,
                left: 0,
                backgroundColor: 'var(--ag-range-selection-background-color ,rgba(122,134,203,.1)) !important',
              },
            },
          },

          '&.row-has-warning': {
            '& .ag-cell': {
              backgroundColor: `${alpha(theme.palette.warning.main, theme.palette.action.activatedOpacity)}`,
            },
            '&.ag-row-hover .ag-cell': {
              backgroundColor: `${alpha(
                theme.palette.warning.main,
                theme.palette.action.activatedOpacity + theme.palette.action.hoverOpacity,
              )}`,
            },
            '&.ag-row-selected .ag-cell': {
              backgroundColor: `${alpha(
                theme.palette.warning.main,
                theme.palette.action.activatedOpacity + theme.palette.action.selectedOpacity,
              )}`,
            },
            '& .ag-cell.ag-cell-range-selected:not(.ag-column-hover)': {
              '&:after': {
                content: '""',
                width: '100%',
                height: '100%',
                position: 'absolute',
                top: 0,
                left: 0,
                backgroundColor: 'var(--ag-range-selection-background-color ,rgba(122,134,203,.1)) !important',
              },
            },
          },

          '&.row-publish-scheduling': {
            '& .ag-cell': {
              backgroundColor: `${alpha(theme.palette.grey[600], theme.palette.action.activatedOpacity)}`,
              color: theme.palette.text.disabled,
            },
            '&.ag-row-hover .ag-cell': {
              backgroundColor: `${alpha(
                theme.palette.grey[600],
                theme.palette.action.activatedOpacity + theme.palette.action.hoverOpacity,
              )}`,
            },
            '&.ag-row-selected .ag-cell': {
              backgroundColor: `${alpha(
                theme.palette.grey[600],
                theme.palette.action.activatedOpacity + theme.palette.action.selectedOpacity,
              )}`,
            },
            '& .ag-cell.ag-cell-range-selected:not(.ag-column-hover)': {
              '&:after': {
                content: '""',
                width: '100%',
                height: '100%',
                position: 'absolute',
                top: 0,
                left: 0,
                backgroundColor: 'var(--ag-range-selection-background-color ,rgba(122,134,203,.1)) !important',
              },
            },
          },
        },

        '& .ag-row-group': {
          '& .ag-icon-tree-closed, & .ag-icon-tree-open:before': {
            fontSize: 22,
            lineHeight: '22px',
            color: theme.palette.action.active,
          },

          '& .ag-icon-tree-closed:before': {
            content: '"--ag-icon-font-code-small-right"',
          },

          '& .ag-icon-tree-open:before': {
            content: '"--ag-icon-font-code-small-down"',
          },
        },

        '& .ag-row-group-expanded': {
          [`& .${PREVIEW_CHECKBOX_COLUMN_NAME}`]: {
            paddingLeft: 0,

            '& .ag-cell-wrapper': {
              borderLeft: `2px solid ${theme.palette.common.black}`,
              borderRadius: '4px 0 0 4px',
            },
          },
        },

        // ======== STYLES FOR HEADER PINNED COLUMNS
        '& .ag-header': {
          [`& .ag-pinned-${LEFT}-header`]: {
            '& .ag-header-cell': {
              '&:nth-child(2)': {
                borderRadius: '8px 0 0 8px',
                border: '1px solid var(--ag-border-color)',
                borderRight: 'none',
              },
            },
          },

          [`& .ag-pinned-${RIGHT}-header`]: {
            border: 'none',
          },
        },

        [`& .ag-pinned-${LEFT}-cols-container`]: {
          '& .ag-row': {
            '& .ag-cell': {
              '&:nth-child(2)': {
                borderRadius: '8px 0 0 8px',
                border: '1px solid var(--ag-border-color)',
                borderRight: 'none',
              },
              [`&.ag-cell-first-${LEFT}-pinned`]: {
                boxShadow: '5px 0px 5px -6px var(--ag-shadow-color)',
              },
              [`&.ag-cell-last-${LEFT}-pinned`]: {
                boxShadow: '5px 0px 5px -6px var(--ag-shadow-color)',
              },
            },
          },
        },

        [`& .ag-pinned-${RIGHT}-cols-container`]: {
          '& .ag-row': {
            '& .ag-cell': {
              [`&.ag-cell-first-${RIGHT}-pinned`]: {
                boxShadow: '-5px 0px 5px -6px var(--ag-shadow-color)',
              },
              [`&.ag-cell-last-${RIGHT}-pinned`]: {
                boxShadow: '-5px 0px 5px -6px var(--ag-shadow-color)',
              },
            },
          },
        },
        //
        '& .ag-row-selected': {
          backgroundColor: 'transparent',

          '&:before': {
            display: 'none',
          },

          '& .ag-cell': {
            backgroundColor: 'var(--ag-selected-row-background-color)',
          },
        },

        '& .ag-body-viewport.ag-layout-normal': {
          overflowX: 'scroll',
          overflowY: 'scroll',
        },

        '& ::-webkit-scrollbar': {
          '-webkit-appearance': 'none',
          width: 8,
          height: 8,
        },
        '& ::-webkit-scrollbar-thumb': {
          borderRadius: 4,
          backgroundColor: alpha(theme.palette.common.black, 0.5),
          boxShadow: `0 0 1px ${alpha(theme.palette.common.white, 0.5)}`,
        },

        '& .ag-center-cols-viewport::-webkit-scrollbar': {
          display: 'none !important',
        },

        '& .ag-body-horizontal-scroll.ag-scrollbar-invisible.ag-apple-scrollbar': {
          opacity: 1,
          visibility: 'visible',
        },
        '& .ag-checkbox-cell': {
          justifyContent: 'center',
        },

        '& .ag-paging-panel .ag-paging-page-size': {
          margin: '0 auto 0 50px',

          '& .ag-label': {
            display: 'none',
          },

          '& .ag-picker-field-wrapper': {
            border: '1px solid var(--ag-border-color)',
          },
        },
      },
    },
  };
});
