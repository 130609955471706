import React, { useContext, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { userFeaturesSelector } from 'domain/env';
import { rootCategoriesListSelector } from 'domain/categories';
import { moveAllCategoryDocumentsToOtherCategoryAction } from 'domain/documents';
import { findCatBySystemType, isSystemType } from 'domain/categories/helpers';
import { CATEGORY_SYSTEM_TYPES } from 'domain/categories/constants';
import { querySelector } from 'domain/router';
import CompanyContext from 'pages/company/CompanyContext';
import { useConfirm } from 'material-ui-confirm';

const mapStateToProps = (state) => ({
  rootCategoriesList: rootCategoriesListSelector(state),
  userFeatures: userFeaturesSelector(state),
  searchQuery: querySelector(state),
});
const useMoveTo = () => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const { userFeatures, rootCategoriesList, searchQuery } = useSelector(mapStateToProps);
  const { category1 } = useParams();
  const { companyType } = useContext(CompanyContext);
  const confirm = useConfirm();

  const archiveCategory = findCatBySystemType(rootCategoriesList, CATEGORY_SYSTEM_TYPES.archive);
  const currentCategory = rootCategoriesList.find((cat) => String(cat.id) === category1);

  const isAutoConfirm = !userFeatures.modal;

  const isAllowedMoveAllDocFromCurrentCategory = currentCategory
    ? isSystemType(currentCategory, CATEGORY_SYSTEM_TYPES.done)
    : false;
  const isArchiveDocumentMovable = archiveCategory ? archiveCategory.documentMovable : false;
  const isNotEmptyCurrentCategory = currentCategory ? !!currentCategory.count : false;
  const isNotUseSearch = !Object.values(searchQuery).some((value) =>
    Array.isArray(value) ? value.some((v) => v) : value,
  );

  const isAllowMoveAllDocToArchive =
    isAllowedMoveAllDocFromCurrentCategory && isArchiveDocumentMovable && isNotEmptyCurrentCategory && isNotUseSearch;

  const tCategoryName = useMemo(() => {
    if (archiveCategory) {
      return formatMessage({
        id: `category.name.${archiveCategory.nameLangId}`,
        defaultMessage: archiveCategory.name,
      });
    }

    return '';
  }, [archiveCategory, formatMessage]);

  const t = useMemo(() => {
    if (tCategoryName) {
      return formatMessage(
        { id: 'tooltip.company.bar.move', defaultMessage: 'Move “Done“ documents to {catName}' },
        { catName: tCategoryName },
      );
    }

    return '';
  }, [formatMessage, tCategoryName]);

  const dispatchMove = () => {
    dispatch(
      moveAllCategoryDocumentsToOtherCategoryAction({
        fromCategoryId: currentCategory.id,
        toCategoryId: archiveCategory.id,
        workspaceType: companyType,
      }),
    );
  };

  const onClick = () => {
    if (isAutoConfirm) {
      dispatchMove();
    } else {
      confirm({
        title: formatMessage(
          {
            id: 'confirm.company.bar.moveTo.title',
            defaultMessage: 'Move all documents to {catName}',
          },
          { catName: tCategoryName },
        ),

        description: formatMessage(
          {
            id: 'confirm.company.bar.moveTo.description',
            defaultMessage: 'All documents from “Done“ category will be moved to {catName}. Are you sure?',
          },
          { catName: <strong>{tCategoryName}</strong> },
        ),
        confirmationText: formatMessage({
          id: 'button.yes',
          defaultMessage: 'Yes',
        }),
      }).then(() => {
        dispatchMove();
      });
    }
  };

  return {
    onClick,
    archiveCategory,
    t,
    isDisabled: !isAllowMoveAllDocToArchive,
  };
};

export default useMoveTo;
