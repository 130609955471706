/* @flow */
import React, { memo, useMemo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { chatAllUsersSelector } from 'domain/chat/chatSelector';

import Chip from '@mui/material/Chip';
import Avatar from 'components/mui/Avatar';

import { blueGrey } from '@mui/material/colors';

import type { ChatUserRecords } from 'domain/chat/types.js.flow';

type TTagBox = {
  tag: string,
  onDelete: (tag: string) => void,
  isUnique?: boolean,
};

const TagBox: React$StatelessFunctionalComponent<TTagBox> = ({ onDelete, tag, isUnique = false }) => {
  const userList: ChatUserRecords = useSelector(chatAllUsersSelector);

  const user = useMemo(() => userList.find((u) => u.userId === tag), [userList, tag]);

  const deleteTag = useCallback(() => {
    onDelete(tag);
  }, [onDelete, tag]);

  return (
    <Chip
      label={user ? user.username : tag}
      onDelete={deleteTag}
      avatar={user && <Avatar alt={tag} src={user.picture} size={24} sx={{ ml: 0.5 }} />}
      color="primary"
      sx={{ backgroundColor: isUnique && blueGrey[100], color: isUnique && 'common.black' }}
      variant={isUnique ? 'filled' : 'outlined'}
    />
  );
};

export default memo(TagBox);
