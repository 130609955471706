// @flow
import React from 'react';
import { useSelector } from 'react-redux';
import { currentOrganizationDataSelector, organization as organizationSelector } from 'domain/organization';

import LogoBase from 'components/mui/Layouts/components/AppbarLogoBase';

const mapStateToProps = (state) => ({
  organization: organizationSelector(state),
  currentOrganization: currentOrganizationDataSelector(state),
});

const AppBarLogo = () => {
  const { currentOrganization, organization } = useSelector(mapStateToProps);
  const org = currentOrganization || organization;

  return org.id && <LogoBase src={org.picture} name={org.name} />;
};

export default AppBarLogo;
