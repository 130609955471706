// @flow
export const CATEGORY_SYSTEM_TYPE_FIELD = 'nameLangId';

export const CATEGORY_SYSTEM_TYPES = {
  archive: 'archive',
  done: 'done',
  all: 'all',
  pending_approval: 'pending_approval',
  to_approve: 'to_approve',
  inbox: 'inbox',
};
