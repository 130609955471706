// @flow
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Set } from 'immutable';
import cx from 'classnames';
import { withStyles } from '@mui/styles';
import { Link } from 'react-router-dom';
import type { TDocumentRecord, TDocument } from 'domain/documents/types.js.flow';
import { isDocPaid } from 'domain/documents/helpers';
import CONST from 'domain/documents/constants';
import type { ChatUser } from 'domain/chat/types.js.flow';
import Tooltip from 'components/Tooltip';
import ImageFallback from 'components/ImageFallback';
// import UserPicSlots from 'components/UserPicSlots';
import elements from 'components/elements';
import { getPreviewStyle } from './helpers';
import sheet from './sheet';
import type { Map, RecordOf } from 'immutable';
import { getRotateByDocID } from 'lib/pdfHelpers';

type Props = {
  previewSrc: string,
  className: string,
  locale: 'en' | 'hb',
  onDragStart: (e: SyntheticDragEvent<HTMLElement>, d: TDocumentRecord) => void,
  onShowLinked: (link: string, linkText: string) => void,
  document: TDocumentRecord,
  to: string,
  onContextMenu: (event: SyntheticMouseEvent<HTMLDivElement>, document: TDocumentRecord) => void,
  classes: {
    [key: string]: string,
  },
  openNotes: () => void,
  onDragEnd: () => void,
  readOnly: boolean,
  dragging: boolean,
  onClick: () => void,
  onPreview: (d: TDocument) => void,
  hasUnread: boolean,
  linkedAsSinglePage?: boolean,
  selected: boolean,
  isAllowSignForCompany: boolean,
  usersById: Map<string, RecordOf<ChatUser>>,
  getIndicateWarningByDoc: (doc: TDocument) => boolean,
  isAllowNotes?: boolean,
};

type State = {
  showAdditionalInfo: boolean,
  showBottomAdditionalInfo: boolean,
};

function filterTags(tags: Set<string>): Set<string> {
  return tags.filter((tag) => !tag.startsWith('_'));
}

class DocumentItem extends React.Component<Props, State> {
  container: ?HTMLElement;

  constructor(props) {
    super(props);
    this.state = {
      showAdditionalInfo: false,
      showBottomAdditionalInfo: false,
    };
  }

  onMouseOver = () => {
    if (this.linkedTag) {
      this.setState({
        showAdditionalInfo: true,
      });
    }
  };

  onMouseOut = () => {
    if (this.linkedTag) {
      this.setState({
        showAdditionalInfo: false,
      });
    }
  };

  onBottomMouseOver = () => {
    const { showBottomAdditionalInfo } = this.state;
    if (!showBottomAdditionalInfo) {
      this.setState({
        showBottomAdditionalInfo: true,
      });
    }
  };

  onBottomMouseOut = () => {
    const { showBottomAdditionalInfo } = this.state;
    if (showBottomAdditionalInfo) {
      this.setState({
        showBottomAdditionalInfo: false,
      });
    }
  };

  onPreview = () => {
    const { onPreview, document } = this.props;
    onPreview(document);
  };

  get linkedTag() {
    const {
      document: { linkid },
    } = this.props;
    return !!linkid;
  }

  get linkedText() {
    const {
      document: { ltext },
    } = this.props;
    return ltext || '';
  }

  get manyPages() {
    const {
      document: { viewinfo },
    } = this.props;
    return viewinfo && viewinfo.pages > 1;
  }

  get renderAsMultipage() {
    const { linkedAsSinglePage } = this.props;
    return (this.linkedTag && !linkedAsSinglePage) || this.manyPages;
  }

  get tags(): Set<string> {
    const {
      document: { tags },
    } = this.props;
    return filterTags(tags);
  }

  get rotationStyle() {
    const {
      document: {
        documentID,
        viewinfo: { rotations },
        protected: isProtected,
      },
    } = this.props;
    const rotationFromLS = JSON.parse(window.localStorage.getItem('dokka_documents_rotation') || '{}')[documentID];
    // $FlowFixMe
    const angle = rotationFromLS ? rotationFromLS[0] : (rotations || [])[0];
    return isProtected ? {} : { transform: `rotateZ(${angle}deg)` };
  }

  getStoredRotation = () => {
    const {
      document: { documentID, viewinfo },
    } = this.props;
    return getRotateByDocID(documentID) ? getRotateByDocID(documentID)[0] : viewinfo.rotations[0];
  };

  getThumbImageStyle = () => {
    const {
      document: { protected: isProtected },
    } = this.props;
    const rotation = isProtected ? 0 : this.getStoredRotation();
    return getPreviewStyle(rotation, this.container);
  };

  getApprovalStatus = () => {
    const {
      document: { tags, approvals },
    } = this.props;
    const titles = {
      approval_pending: 'Pending Approval',
      approval_complete: 'Approved',
      approval_rejected: 'Approval Rejected',
    };

    const pendingApproval =
      tags.includes('_S_APPROVAL_PENDING') && approvals.status === 'active' ? 'approval_pending' : null;
    const approvedApproval = tags.includes('_S_APPROVAL_COMPLETE') ? 'approval_complete' : null;
    const rejectedApproval = tags.includes('_S_APPROVAL_REJECTED') ? 'approval_rejected' : null;

    const approvalStatus = approvedApproval || pendingApproval || rejectedApproval || null;
    return approvalStatus ? { status: approvalStatus, title: titles[approvalStatus] } : null;
  };

  getUserTags = () => {
    const {
      document: { tags },
      usersById,
    } = this.props;
    return tags.filter((tag) => tag.includes('@') > 0 && usersById.get(tag));
  };

  getSlotsData = () => {
    const { usersById } = this.props;
    return this.getUserTags()
      .toJS()
      .map((tag) => ({
        userId: tag,
        username: usersById.getIn([tag, 'username']),
        picSrc: usersById.getIn([tag, 'picture']),
        isHighlite: false,
      }));
  };

  render() {
    const {
      classes,
      document,
      className,
      onShowLinked,
      onContextMenu,
      hasUnread,
      locale,
      getIndicateWarningByDoc,
      dragging,
      readOnly,
      onClick,
      previewSrc,
      selected,
      to,
      openNotes,
      isAllowNotes,
    } = this.props;

    const {
      doctype: type,
      status,
      notes,
      documentID,
      linkid,
      protected: isProtected,
      sourceID,
      versionID,
      tags,
    } = document;

    const { showAdditionalInfo, showBottomAdditionalInfo } = this.state;
    const isBeingProcessed = tags.has(CONST.tags.isBeingProcessed);
    const approvalStatus = this.getApprovalStatus();

    const invisibleStatuses = ['read', 'signed', 'pending'];
    const preparedStatuses = Set(status.filter((item) => invisibleStatuses.indexOf(item) + 1 < 1));
    const linkedBtnTranslate = { id: 'documents.item.show', defaultMessage: 'Show' };

    const body = (
      <>
        {isBeingProcessed && (
          <div
            onFocus={() => 0}
            role="button"
            tabIndex="0"
            className={classes.processing}
            data-element={elements.content.documents.label.processing}
            onMouseLeave={this.onBottomMouseOut}
            onMouseOver={this.onBottomMouseOver}
          >
            <div className={classes.processingIcon} />
            <FormattedMessage id="documents.item.status.processing" defaultMessage="Processing..." />
          </div>
        )}
        <div
          className={cx(classes.document, className, {
            [classes.dragging]: dragging,
            [classes.multipage]: this.renderAsMultipage,
          })}
          draggable={!readOnly}
          onContextMenu={(event) => onContextMenu(event, document)}
          role="presentation"
          onClick={onClick}
          ref={(el) => {
            this.container = el;
          }}
        >
          <ImageFallback
            src={previewSrc}
            getStyle={this.getThumbImageStyle}
            type={type}
            alt="document thumb"
            draggable={false}
            className={cx(classes.thumb, [{ [classes.protectedThumb]: isProtected }])}
          />

          {isDocPaid(document) && (
            <div
              className={cx(classes.paid, {
                [classes.paidWithDuplicate]: sourceID,
              })}
            >
              <FormattedMessage id="documents.paid.markText" defaultMessage="PAID" />
            </div>
          )}

          {sourceID && (
            <FormattedMessage id="documents.item.duplicate" defaultMessage="Duplicate">
              {(translate: string) => <span className={classes.duplicate}>{`${translate} ${versionID || 1}`}</span>}
            </FormattedMessage>
          )}
          <div
            onFocus={() => 0}
            role="button"
            tabIndex="0"
            className={classes.bottom}
            data-element={elements.content.documents.properties}
            onMouseLeave={this.onBottomMouseOut}
            onMouseOver={this.onBottomMouseOver}
          >
            {hasUnread ? <div className={cx(classes.type, classes.icon, classes.hasUnread)} /> : null}
            {preparedStatuses.map((key: string) =>
              key ? (
                <Tooltip
                  key={key}
                  upDirection
                  disable={key !== 'notSigned'}
                  id="documents.item.notSignedTooltip"
                  defaultMessage="No E-Signature"
                >
                  <div
                    className={cx(classes.icon, classes[key], classes[locale])}
                    data-element={elements.content.documents.status}
                  />
                </Tooltip>
              ) : null,
            )}

            {approvalStatus && (
              <div className={cx(classes.icon, classes.status_icon, classes[approvalStatus.status])} />
            )}
          </div>
        </div>
      </>
    );
    return (
      <Tooltip message={this.tags.join(', ')} disable={this.tags.size < 1} tooltipClass={classes.documentTooltip}>
        <div // eslint-disable-line jsx-a11y/mouse-events-have-key-events
          className={cx(classes.wrapper, {
            [classes.linkedActionsShow]: showAdditionalInfo && this.linkedTag,
            [classes.selected]: selected,
            [classes.hasWarning]: getIndicateWarningByDoc(document) && !(this.linkedTag && Boolean(onShowLinked)),
          })}
          onMouseLeave={this.onMouseOut}
          onMouseOver={this.onMouseOver}
          data-element={elements.content.documents.item}
          aria-selected={selected}
        >
          {this.linkedTag && onShowLinked ? (
            <div // eslint-disable-line
              className={cx(classes.linkedShowBtn, {
                [classes.withLinkedText]: this.linkedText.length > 0,
              })}
              onClick={() => onShowLinked(linkid)}
              onContextMenu={(event) => onContextMenu(event, document)}
              key={`${documentID}-linkedShow`}
              data-element={elements.content.documents.linkedBtn}
            >
              <div className={classes.linkedBtnBody}>
                <FormattedMessage {...linkedBtnTranslate}>
                  {(translate: string) => <span className={classes.linkedBtnText}>{translate}</span>}
                </FormattedMessage>
                {this.linkedText.length > 0 ? (
                  <div className={classes.linkedTextWrapper}>
                    <span
                      className={cx(classes.linkedText, {
                        [classes.linkedOneLineText]: this.linkedText.indexOf('\n') < 1,
                      })}
                    >
                      {this.linkedText}
                    </span>
                  </div>
                ) : null}
              </div>
            </div>
          ) : null}
          {to ? (
            <Link to={to} onClick={onClick}>
              {body}
            </Link>
          ) : (
            body
          )}
          <button
            type="button"
            onContextMenu={(event) => onContextMenu(event, document)}
            className={classes.preview}
            onClick={this.onPreview}
          >
            <div className={classes.previewIcon} />
          </button>
          {isAllowNotes && notes ? (
            <button
              type="button"
              className={cx(classes.notes, { [classes.withSID]: sourceID })}
              onClick={openNotes}
              data-element={elements.content.documents.notes}
            >
              <span>{notes}</span>
            </button>
          ) : null}

          {approvalStatus && (
            <Tooltip
              disable={!showBottomAdditionalInfo}
              className={classes.approvalStatusTooltip}
              tooltipClass={classes.tooltipShift}
              upDirection
              id={`documents.item.${approvalStatus.status}`}
              defaultMessage={approvalStatus.title}
            />
          )}

          {/* <div */}
          {/*  className={cx(classes.userPicksBox, { */}
          {/*    [classes.userPicksBoxWithDuplicate]: sourceID, */}
          {/*  })} */}
          {/* > */}
          {/*  <UserPicSlots size={28} slots={this.getSlotsData()} limit={4} /> */}
          {/* </div> */}
        </div>
      </Tooltip>
    );
  }
}

DocumentItem.defaultProps = {
  selected: false,
};

export default withStyles(sheet)(DocumentItem);
