// @flow
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Field } from 'redux-form/immutable';

import { timezonesListSelector } from 'domain/dictionary';

import SelectForm from 'components/mui/Form/Select/SelectForm';
import Grid from '@mui/material/Grid';

import useTimeSelectorForm from './useTimeSelectorForm';

type Props = {
  name: string,
  value: {
    hours: number,
    minute: number,
    timezone: string,
  },
  label: string,
  onChange: (e: SyntheticInputEvent<HTMLInputElement>, value: any, prevValue: any, field: string) => void,
};
const TimeSelector: React$StatelessFunctionalComponent<Props> = ({ name, value, onChange, label }) => {
  const { hoursOptions } = useTimeSelectorForm();
  const timezonesOptions = useSelector(timezonesListSelector);
  const handleChange = useCallback(
    (field) => (e) => {
      onChange(e, { ...value, [field]: e.target.value }, value, name);
    },
    [name, value, onChange],
  );
  return (
    <Grid container spacing={1} alignItems="center">
      <Grid item>
        <Field
          component={SelectForm}
          options={hoursOptions}
          name={`${name}.hours`}
          id={`${name}.hours`}
          FormControlProps={{ size: 'small' }}
          label={label}
          input={{
            onChange: handleChange('hours'),
            value: value.hours,
          }}
        />
      </Grid>
      <Grid item xs zeroMinWidth>
        <Field
          component={SelectForm}
          options={timezonesOptions}
          name={`${name}.timezone`}
          id={`${name}.timezone`}
          FormControlProps={{ fullWidth: true, size: 'small' }}
          label={label}
          input={{
            onChange: handleChange('timezone'),
            value: value.timezone,
          }}
        />
      </Grid>
    </Grid>
  );
};

export default TimeSelector;
