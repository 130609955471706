// @flow
import React from 'react';

// redux
import { compose } from 'redux';
import { Field, reduxForm } from 'redux-form/immutable';

// intl
import { useIntl } from 'react-intl';

// mui
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';

// components
import TextFieldForm from 'components/mui/Form/TextField/TextFieldForm';
import Phone from 'components/mui/Form/Phone/PhoneForm';

type Props = {
  handleSubmit: () => void,
};

const ContactFormContainer: React$StatelessFunctionalComponent<Props> = ({ handleSubmit }) => {
  const { formatMessage } = useIntl();

  return (
    <Grid container component="form" justifyContent="space-between" spacing={3} onSubmit={handleSubmit}>
      <Grid item xs={6}>
        <Field
          component={TextFieldForm}
          label={formatMessage({
            id: 'forms.common.firstName.label',
            defaultMessage: 'First name',
          })}
          placeholder={formatMessage({
            id: 'forms.common.firstName.placeholder',
            defaultMessage: 'First name',
          })}
          required
          name="first_name"
          type="text"
          fullWidth
          withTrim
        />
      </Grid>
      <Grid item xs={6}>
        <Field
          component={TextFieldForm}
          label={formatMessage({
            id: 'forms.common.lastName.label',
            defaultMessage: 'Last name',
          })}
          placeholder={formatMessage({
            id: 'forms.common.lastName.placeholder',
            defaultMessage: 'Last name',
          })}
          required
          name="last_name"
          type="text"
          fullWidth
          withTrim
        />
      </Grid>
      <Grid item xs={6}>
        <Field
          component={TextFieldForm}
          label={formatMessage({
            id: 'forms.common.email.label',
            defaultMessage: 'Email',
          })}
          placeholder={formatMessage({
            id: 'forms.common.email.placeholder',
            defaultMessage: 'email@example.com',
          })}
          required
          name="email"
          type="email"
          fullWidth
        />
      </Grid>
      <Grid item xs={6}>
        <Field
          component={Phone}
          label={{
            id: 'forms.common.phone.label',
            defaultMessage: 'Phone',
          }}
          placeholder={{
            id: 'forms.common.phone.placeholder',
            defaultMessage: '(050)12 34 567',
          }}
          name="phone"
        />
      </Grid>
      <Grid item xs={12} display="flex" justifyContent="flex-end">
        <Button type="submit" variant="contained" sx={{ minWidth: (theme) => theme.typography.pxToRem(140) }}>
          {formatMessage({ id: 'button.save', defaultMessage: 'Save' })}
        </Button>
      </Grid>
    </Grid>
  );
};

export default compose(
  reduxForm({
    form: 'contactForm',
  }),
)(ContactFormContainer);
