export default ({ email, userName, organizationName, currentCompanyName, currentConnectedERP, locale }) => {
  const { firstName, lastName } = userName;

  const DEFAULT_VALUE_WHEN_ERP_NOT_SET = 'Other';

  // eslint-disable-next-line prefer-destructuring
  const FreshworksWidget = window.FreshworksWidget;

  // $FlowFixMe
  FreshworksWidget('identify', 'ticketForm', {
    name: `${firstName} ${lastName}`,
    email,
  });

  // $FlowFixMe
  FreshworksWidget('setLabels', {
    he: require('lib/freshdeskChat/translations/he.js').default,
  });

  // $FlowFixMe
  FreshworksWidget('hide', 'ticketForm', [
    'custom_fields.cf_dokka_company',
    'custom_fields.cf_organization',
    'custom_fields.cf_erp',
    'custom_fields.cf_username',
    'custom_fields.cf_app_url',
    'custom_fields.cf_product',
  ]);

  // $FlowFixMe
  FreshworksWidget('prefill', 'ticketForm', {
    custom_fields: {
      // get custom ticket fields
      // https://developers.freshdesk.com/api/#list_all_ticket_fields
      cf_dokka_company: currentCompanyName,
      cf_organization: organizationName,
      // if currentConnectedERP === null, widget API will not set it to null but
      // rather will use previously initialized value if we opened widget before
      // with another ERP connected
      // so we agreed to use default option too override value set
      // in previous session
      cf_erp: currentConnectedERP || DEFAULT_VALUE_WHEN_ERP_NOT_SET,
      cf_app_url: window.location.href,
      cf_product: 'Dokka',
    },
  });

  // $FlowFixMe
  FreshworksWidget('open');

  const applyFormStyles = () => {
    const iframe = ((document.getElementById('widget-frame'): any): HTMLIFrameElement);
    let description = null;
    if (iframe) {
      description = iframe.contentWindow.document.querySelector('[data-testid="text-area-input"]');
    }
    if (description === null) {
      // looking recursively for DOM element until it is loaded
      setTimeout(() => applyFormStyles(), 100);
    } else {
      const input: HTMLElement = iframe.contentWindow.document.querySelector('#subject');
      const labels: HTMLCollection<HTMLElement> = iframe.contentWindow.document.getElementsByTagName('label');
      const form: HTMLElement = iframe.contentWindow.document.querySelector('[data-testid="advanced-form"]');
      input.style.setProperty('text-align', 'right');
      description.style.setProperty('text-align', 'right');
      description.style.setProperty('direction', 'rtl');
      input.style.setProperty('direction', 'rtl');
      form.style.setProperty('direction', 'rtl');

      Array.from(labels).forEach((label) => {
        label.style.setProperty('text-align', 'right');
        label.style.setProperty('direction', 'rtl');
      });
    }
  };

  if (locale === 'he') {
    applyFormStyles();
  }
};
