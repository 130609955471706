// @flow
import { useMemo } from 'react';
import { range } from 'lodash';
import { OptionData } from 'components/Form/VirtualSelect/Options/Option';

const useTimeSelectorForm = () => {
  const hoursOptions = useMemo((): $ReadOnlyArray<OptionData> => {
    const arr = range(0, 24, 1);

    return arr.reduce((acc, v, index) => {
      acc[index] = { value: v, label: v.toString().length < 2 ? `0${v}:00` : `${v.toString()}:00` };
      return acc;
    }, []);
  }, []);

  return {
    hoursOptions,
  };
};

export default useTimeSelectorForm;
