// @flow
import { Record, type RecordFactory, type RecordOf, Map } from 'immutable';
import { LOCATION_CHANGE, SAVE_LOCATION_DATA } from 'domain/router/redux/reduxActions';
import type { Location } from 'react-router-dom';

import { parseQuery } from './helpers';
import * as actions from './RouterActions';
import * as envActions from 'domain/env/envActions';

export type Query = {
  [key: string]: string | string[],
};

type RLocation = {|
  ...$Exact<Location>,
  query: Query,
|};

type Router = {
  location: RecordOf<RLocation>,
  prevLocation: RecordOf<RLocation>,
  complete: string | null,
  customSingOut: boolean,
};

type Action =
  | {
      type: string,
      payload: Location,
    }
  | { type: string, payload: string };

export type LocationType = RecordOf<RLocation>;

const LocationFactory: RecordFactory<RLocation> = new Record({
  pathname: '',
  search: '',
  hash: '',
  key: '',
  state: new Map(),
  query: new Map(),
});

const RouterFactory: RecordFactory<Router> = new Record({
  location: LocationFactory(),
  prevLocation: LocationFactory(),
  // This must be null by default. When accessing link directly (in a new tab with no history) router will pass empty string
  // and in case default value being empty string App component will not recognize complete pros change and will not force rerender
  // while when navigating through app routes will cause props to always change. This leads to inconsistency between direct link
  // and navigationg through app behaviours, making direct link scenarion have less rerenders.
  complete: null,
  customSingOut: false,
});

export type RouterStore = RecordOf<Router>;

const createQueryAdapter = () => {
  let lastSearch = '';
  let lastResult = {};

  return (search: string) => {
    lastResult = lastSearch === search ? lastResult : parseQuery(search);
    lastSearch = search;
    return lastResult;
  };
};

const queryAdapter = createQueryAdapter();

export const reducer = {
  routing(state: RouterStore = RouterFactory(), action: Action) {
    switch (action.type) {
      case LOCATION_CHANGE: {
        const {
          payload: { location },
        } = action;

        return state
          .set(
            'location',
            LocationFactory({
              ...location,
              query: queryAdapter(location.search),
              state: Map({ ...location.state }),
            }),
          )
          .set('prevLocation', state.location);
      }
      case SAVE_LOCATION_DATA: {
        const data = action.payload;

        return state.setIn(['location', 'state'], Map({ ...state.location.state.toJS(), ...data }));
      }
      case actions.completeEmptyAction.type:
      case actions.completeResetAction.type:
      case actions.completeAction.type:
        return state.set('complete', action.payload);

      case envActions.signOutAction.type:
        return state.set('customSingOut', !!action.payload);
      default:
        return state;
    }
  },
};
