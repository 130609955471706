/* eslint-disable camelcase */
// @flow
import * as React from 'react';
import Textarea from 'react-textarea-autosize';
import { injectIntl, type IntlShape } from 'react-intl';

type Props = {|
  classes: {
    [key: string]: string,
  },
  onSend: (e: SyntheticEvent<HTMLFormElement>) => void,
  threadId: ?string,
  intl: IntlShape,
  disabled: boolean,
|};

class InputField extends React.Component<Props> {
  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    // focus only when we open messages, otherwize it will try to bring focused
    // field into view when it mustn't be visible
    if (nextProps.threadId !== null && this.textarea) {
      this.textarea.focus();
    }
  }

  onSend = (): void => {
    const message: string = this.textarea.value;
    if (message.replace(/\s+/g, '').length > 0) {
      this.props.onSend(message);
      this.textarea.value = '';
      this.textarea.focus();
    }
  };

  onKeyDown = (e: SyntheticEvent<HTMLFormElement>) => {
    if (e.ctrlKey || e.metaKey) {
      if (e.keyCode === 13) {
        this.onSend(e);
      }
    }
  };

  setRef = (el: HTMLInputElement) => {
    this.textarea = el;
  };

  textarea: HTMLTextAreaElement;

  render() {
    const { classes, intl } = this.props;
    return (
      // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
      <div className={classes.form}>
        <Textarea
          inputRef={this.setRef}
          type="text"
          name="message"
          minRows={1}
          maxRows={3}
          className={classes.field}
          onKeyDown={this.onKeyDown}
          placeholder={intl.formatMessage({
            id: 'chat.room.typeMessagePlaceholder',
            defaultMessage: 'Type your message...',
          })}
        />
        <button type="submit" disabled={this.props.disabled} className={classes.send} onClick={this.onSend}>
          {intl.formatMessage({
            id: 'chat.room.typeMessageButton',
            defaultMessage: 'Send',
          })}
        </button>
      </div>
    );
  }
}

export default injectIntl(InputField);
