// @flow
import React from 'react';
import Tooltip from 'components/mui/Tooltip';

import CancelIcon from '@mui/icons-material/Cancel';

type TRejectedStatus = {
  status: Array<string>,
};

const RejectedStatus: React$StatelessFunctionalComponent<TRejectedStatus> = ({ status }) => {
  const isRejected = status.includes('rejected');
  const t = {
    id: 'tooltip.rejected',
    defaultMessage: 'Rejected',
  };

  return (
    isRejected && (
      <Tooltip t={t} placement="top">
        <CancelIcon sx={{ fontSize: 20, color: 'error.main' }} />
      </Tooltip>
    )
  );
};

export default RejectedStatus;
