// @flow
import React from 'react';
import { useSelector } from 'react-redux';
import { isChatConnectedSelector } from 'domain/chat';
import { FormattedMessage } from 'react-intl';

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

import { styled } from '@mui/material/styles';

const Wrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  borderRadius: theme.borderRadius,
  fontWeight: 500,
  color: theme.palette.common.white,
  backgroundColor: theme.palette.error.main,
  fontSize: theme.typography.pxToRem(14),
  padding: theme.spacing(1, 2),
  marginBottom: theme.spacing(2),
}));

const ChatStatusAlert = () => {
  const isConnected = useSelector(isChatConnectedSelector);

  return (
    !isConnected && (
      <Wrapper>
        <Box mr={2}>
          <CircularProgress size={20} sx={{ color: 'inherit' }} />
        </Box>
        <FormattedMessage
          id="chat.statusBar.weAreTryingToReconnect"
          defaultMessage="Your computer is offline. Trying to reconnect"
        />
      </Wrapper>
    )
  );
};

export default ChatStatusAlert;
