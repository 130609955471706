// @flow
import * as React from 'react';

type Props = {};

class RedirectPage extends React.Component<Props> {
  render() {
    return <div>redirecting...</div>;
  }
}

export default RedirectPage;
