// @flow
import React from 'react';
import { useIntl } from 'react-intl';
import useSettingsLink from 'components/mui/Layouts/components/AccountDropdown/useSettingsLink';
import useSaveBackUrl from 'components/mui/Layouts/components/AccountDropdown/useSaveBackUrl';

import ListItem from '@mui/material/ListItem';
// import ListItemButton from '@mui/material/ListItemButton';
import { ListItemButton } from 'pages/company/CompanyNavigation/StyledComponents';
import { ListItemIcon } from 'components/mui/Layouts/components/Drawer';
import ListItemText from '@mui/material/ListItemText';
import RouterLink from 'components/mui/Router/RouterLink';
import Tooltip from 'components/mui/Tooltip';
import IconButton from '@mui/material/IconButton';
import SettingsIcon from '@mui/icons-material/Settings';

type TSettingsLink = {
  expanded?: boolean,
};

const SettingsLink: React$StatelessFunctionalComponent<TSettingsLink> = ({ expanded }) => {
  const { formatMessage } = useIntl();
  const link = useSettingsLink();
  const saveBackUrl = useSaveBackUrl();

  return (
    link && (
      <ListItem disablePadding sx={{ justifyContent: expanded ? 'start' : 'center' }}>
        {expanded ? (
          <ListItemButton component={RouterLink} href={link} color="inherit" onClick={saveBackUrl}>
            <ListItemIcon
              sx={{
                color: 'inherit',
              }}
            >
              <SettingsIcon />
            </ListItemIcon>
            <ListItemText primary={formatMessage({ id: 'header.menu.settings', defaultMessage: 'Settings' })} />
          </ListItemButton>
        ) : (
          <Tooltip t={{ id: 'header.menu.settings', defaultMessage: 'Settings' }} placement="right">
            <IconButton component={RouterLink} href={link} color="inherit" onClick={saveBackUrl}>
              <SettingsIcon />
            </IconButton>
          </Tooltip>
        )}
      </ListItem>
    )
  );
};

export default SettingsLink;
