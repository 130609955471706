/* @flow */
export const PINNED_WIDTH = 160;
export const REMOVE_COLUMN_SIZE = 30;
export const MIN_WIDTH = 90;
export const DEFAULT_FIELD_WIDTH = {
  Date: 95,
  Description: 360,
  Amount: 90,
  // Action: PINNED_WIDTH,
};
