/* @flow */
import * as React from 'react';
import { type List, type Map } from 'immutable';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { lineItemsSelector, lineCountSelector, isJeLayoutRTLSelector, headerLinesNumberSelector } from 'domain/journal';
import { shouldProcessingDocBeBlockedSelector, textractEnabledForDocumentSelector } from 'domain/documents';
import { rtlEnable } from 'domain/env';
import { matchCell, type EntriesType } from 'domain/journal/helper';
import elements from 'components/elements';
import { GridTable as Grid, withResize, withPinnedCols, withSelectedRows } from '../../grid';
import { type TColumnsWidth, cleanTableColsWidth } from 'components/Tables/grid/withResize/helpers';
import { isMandatory, isActive, getDataCell } from '../helpers';
import { renderColgroup, removeButton } from '../component';
import FloatGrid from './float';
import VisibilityColsDropdown, {
  type NamedCols,
  type NamedCol,
} from 'components/Tables/layout/grid/VisibilityColsDropdown/VisibilityColsDropdown';
import type { MetaType } from '../../types.js.flow';
import type { TViewArrangement } from 'pages/document/types.js.flow';
import type { TJEGridLineColumnsDictionary } from 'domain/journal/helper';

import { withStyles } from '@mui/styles';
import cx from 'classnames';
import sheet from './sheet';
import { orderByMap } from 'lib/array';
import type { JournalSettingOrder } from 'domain/journal/types.js.flow';
import withMatchingColorcoding from '../../grid/withMatchingColorcoding';
import DropContainer from './DropContainer';

type Props = {|
  classes: {|
    [key: string]: string,
  |},
  data: Map<string, EntriesType>,
  line: List<number>,
  lineCount: number,
  getItem: (d: ?EntriesType) => React$Node,
  active: ?string,
  removeHandler: (r: number) => void, // eslint-disable-line react/no-unused-prop-types
  isJeLayoutRTL: boolean,
  rtl: boolean,
  storeKey: string,
  shouldHideGridSettings: boolean, // selector
  hasVisibleColumnsManageButton: boolean, // manual props - like for master view - hide button
  pinnedCols: Array<string>,
  disabledCols: Array<string>,
  onChangePinnedCols: (c: Array<string>) => void,
  onChangeDisabledCols: (c: Array<string>) => void,
  forcedEnabledCols: Array<string>,
  permanentlyPinned: Array<string>,
  meta: MetaType,
  pinnedMeta: MetaType,
  floatMeta: MetaType,
  selectedRows: Array<number>,
  onChangeSelectedRows: (c: Array<number>) => void,
  isReadOnly: boolean,
  viewArrangement: TViewArrangement,
  headerLinesNumber: number,
  gridLineColumnsDictionary: TJEGridLineColumnsDictionary,
  onChangeOrder: (c: Array<string>, p: boolean) => void,
  order: JournalSettingOrder,
  textractEnabledForDocument: boolean,
|};

type State = {
  hovered: ?number,
  pinnedColsWidth: TColumnsWidth,
  floatColsWidth: TColumnsWidth,
};

const ResizableGrid = compose(withPinnedCols, withResize)(Grid);
const ResizableGridWithMatching = compose(withMatchingColorcoding)(ResizableGrid);
const SelectedRowsGrid = compose(withSelectedRows)(ResizableGrid);
const SelectedRowsGridWithMatching = compose(withMatchingColorcoding)(SelectedRowsGrid);

class GridLayout extends React.Component<Props, State> {
  boxEl: ?HTMLDivElement = null;

  rm = removeButton(this.props);

  constructor(props) {
    super(props);
    this.state = {
      hovered: null,
      pinnedColsWidth: {},
      floatColsWidth: {},
    };
  }

  onChangeColsWidth = (gridKey) => (width: TColumnsWidth) => {
    this.setState({ [gridKey]: width });
  };

  onResetColumnsWidth = async () => {
    await cleanTableColsWidth(this.getPinnedStoreKey());
    await cleanTableColsWidth(this.getFloatStoreKey());
    this.setState({ pinnedColsWidth: {}, floatColsWidth: {} });
  };

  getNamedCols(): NamedCols {
    const {
      meta: [cols, rows],
      data,
    } = this.props;
    return this.orderCols(cols).reduce((res: NamedCols, colName: string): NamedCol => {
      const cellName = `${colName}${rows[0]}`;
      const title = data.getIn([cellName, 'text']) || data.getIn([cellName, 'cellSet', 'value']);
      return title ? [...res, { title, colName }] : res;
    }, []);
  }

  get getWrapperWidth() {
    return this.boxEl ? this.boxEl.offsetWidth - 30 : null;
  }

  getPinnedStoreKey = () => {
    const { storeKey } = this.props;
    return `pined-table-${storeKey}`;
  };

  getFloatStoreKey = () => {
    const { storeKey } = this.props;
    return `main-table-${storeKey}`;
  };

  getEntry = (col: string, row: number) => {
    const { data } = this.props;
    return data.get(`${col}${row}`);
  };

  getRowStyle = (row: number) => {
    const { active, classes, line } = this.props;
    const { hovered } = this.state;
    const rowClassName = cx(classes.row, {
      [classes.lineItems]: line.includes(row),
      [classes.hovered]: hovered === row,
    });
    if (typeof active === 'string' && matchCell(active)[1] === row) {
      return cx(rowClassName, classes.activeRow);
    }
    return rowClassName;
  };

  getItem = (row: number, col: string) => {
    const { getItem, data } = this.props;
    return getItem(getDataCell(data, row, col));
  };

  getCollStyle = (col: string, row: number) => {
    const { classes, active } = this.props;
    return cx(classes.col, {
      [classes.mandatory]: isMandatory(this.getEntry(col, row)),
      [classes.active]: isActive(col, row, active),
    });
  };

  setHovered = (row: ?number, status: boolean) => {
    const { hovered } = this.state;
    if (status) {
      this.setState({ hovered: row });
    } else {
      this.setState({
        hovered: hovered === row ? null : hovered,
      });
    }
  };

  setBoxEl = (el: HTMLDivElement) => {
    this.boxEl = el;
  };

  orderCols = (cols: Array<string>) => {
    const {
      rtl,
      isJeLayoutRTL,
      order: { float },
      pinnedMeta,
    } = this.props;
    const res = orderByMap(cols, [...pinnedMeta[0], ...float]);
    return rtl !== isJeLayoutRTL ? [...res].reverse() : res;
  };

  fixedColStyle = (col: string, row: number) => {
    const { classes } = this.props;
    return cx(this.getCollStyle(col, row), classes.fixedColumn);
  };

  fixedColgroup = () => {
    const { classes } = this.props;
    return renderColgroup([
      { id: 'A', className: classes.tableRemove },
      { id: 'B', className: classes.tableCol },
    ]);
  };

  floatColgroup = () => {
    const { classes } = this.props;
    return renderColgroup([{ id: 'A', className: classes.tableCol, span: this.float[0].length }]);
  };

  removeItem = (row: number) => {
    const { line } = this.props;
    return line.includes(row) ? this.rm(row) : null;
  };

  pinCol = (status: boolean) => (col: number) => {
    const { pinnedCols, onChangePinnedCols } = this.props;
    const newPinnedCols = status ? [...pinnedCols, col] : pinnedCols.filter((c) => c !== col);
    onChangePinnedCols(newPinnedCols);
  };

  render() {
    const {
      classes,
      active,
      shouldHideGridSettings,
      onChangeDisabledCols,
      disabledCols,
      pinnedCols,
      forcedEnabledCols,
      permanentlyPinned,
      pinnedMeta,
      floatMeta,
      selectedRows,
      onChangeSelectedRows,
      lineCount,
      isReadOnly,
      isJeLayoutRTL,
      viewArrangement,
      headerLinesNumber,
      gridLineColumnsDictionary,
      onChangeOrder,
      order,
      textractEnabledForDocument,
      rtl,
      hasVisibleColumnsManageButton = true,
    } = this.props;

    const { pinnedColsWidth, floatColsWidth, hovered } = this.state;

    const countAllRows = lineCount - 2; // exclude first title row, and last empty row.
    const [ResizableGridComponent, SelectedRowsGridComponent] = textractEnabledForDocument
      ? [ResizableGridWithMatching, SelectedRowsGridWithMatching]
      : [ResizableGrid, SelectedRowsGrid];

    return (
      <div className={classes.container} data-element={elements.je.grid.container} ref={this.setBoxEl}>
        <DropContainer />
        <div className={classes.gridBox}>
          <div className={classes.pinnedBox}>
            <div className={cx(classes.pinnedScrollBox, 'scroll-box')}>
              <div className={cx(classes.fixed)}>
                <SelectedRowsGridComponent
                  storeKey={this.getPinnedStoreKey()}
                  className={classes.fixedContainer}
                  meta={pinnedMeta}
                  getRowStyle={this.getRowStyle}
                  getCollStyle={this.fixedColStyle}
                  getItem={this.getItem}
                  prevItem={this.removeItem}
                  colgroup={this.fixedColgroup}
                  active={active}
                  setHovered={this.setHovered}
                  isRtl={isJeLayoutRTL}
                  onPinClick={this.pinCol(false)}
                  permanentlyPinned={permanentlyPinned}
                  pinnedCols={pinnedCols}
                  selectedRows={selectedRows}
                  startRow={pinnedMeta[1][0]}
                  countAllRows={countAllRows}
                  onChangeSelectedRows={onChangeSelectedRows}
                  selectPlaceCol={pinnedMeta[0][0]}
                  isReadOnly={isReadOnly}
                  tableColsWidth={pinnedColsWidth}
                  onChangeColsWidth={this.onChangeColsWidth('pinnedColsWidth')}
                  disabledCols={disabledCols}
                  viewArrangement={viewArrangement}
                  headerLineNumber={headerLinesNumber}
                  lineCount={lineCount}
                  wrapperWidth={this.getWrapperWidth}
                  gridLineColumnsDictionary={gridLineColumnsDictionary}
                  hovered={hovered}
                  rtl={rtl}
                  {...(textractEnabledForDocument ? { matchingArrowsPosition: 'left' } : {})}
                />
              </div>
            </div>
          </div>

          <FloatGrid
            disabledCols={disabledCols}
            pinnedCols={pinnedCols}
            forcedEnabledCols={forcedEnabledCols}
            isJeLayoutRTL={isJeLayoutRTL}
            isRtl={rtl}
          >
            <ResizableGridComponent
              storeKey={this.getFloatStoreKey()}
              meta={floatMeta}
              getRowStyle={this.getRowStyle}
              getCollStyle={this.getCollStyle}
              getItem={this.getItem}
              colgroup={this.floatColgroup}
              active={active}
              setHovered={this.setHovered}
              isRtl={isJeLayoutRTL}
              onPinClick={this.pinCol(true)}
              permanentlyPinned={permanentlyPinned}
              pinnedCols={pinnedCols}
              tableColsWidth={floatColsWidth}
              onChangeColsWidth={this.onChangeColsWidth('floatColsWidth')}
              disabledCols={disabledCols}
              viewArrangement={viewArrangement}
              headerLineNumber={headerLinesNumber}
              lineCount={lineCount}
              wrapperWidth={this.getWrapperWidth}
              gridLineColumnsDictionary={gridLineColumnsDictionary}
              isAutoresize
              hovered={hovered}
            />
          </FloatGrid>
        </div>
        {!shouldHideGridSettings && hasVisibleColumnsManageButton && (
          <VisibilityColsDropdown
            disabledCols={disabledCols}
            namedCols={this.getNamedCols()}
            pinnedCols={pinnedCols}
            forcedEnabledCols={forcedEnabledCols}
            onChange={onChangeDisabledCols}
            onResetColumnsWidth={this.onResetColumnsWidth}
            onChangeOrder={onChangeOrder}
            order={order}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  line: lineItemsSelector(state),
  lineCount: lineCountSelector(state),
  isJeLayoutRTL: isJeLayoutRTLSelector(state),
  rtl: rtlEnable(state),
  shouldHideGridSettings: shouldProcessingDocBeBlockedSelector(state),
  headerLinesNumber: headerLinesNumberSelector(state),
  textractEnabledForDocument: textractEnabledForDocumentSelector(state),
});

export default compose(connect(mapStateToProps), withStyles(sheet))(GridLayout);
