// @flow
import React from 'react';
import { useSelector } from 'react-redux';

import { loadingSelector, sagaDataLoadingSelector } from 'domain/env';

import Container from '@mui/material/Container';

import Bar from './components/Bar';
import LayoutOrganizations from 'components/mui/Layouts/Organizations';
import StickySubheader from 'components/mui/Layouts/components/StickySubheader';
import OrganizationsTitle from './components/Title';
import OrganizationsGrid from './components/Grid';
import CircularProgressWithBackdrop from 'components/mui/CircularProgressWithBackdrop';

const mapStateToProps = (state) => ({
  loading: loadingSelector(state),
  isSagaDataLoading: sagaDataLoadingSelector(state),
});

const Organizations = () => {
  const { isSagaDataLoading, loading } = useSelector(mapStateToProps);
  return (
    <>
      <LayoutOrganizations>
        <StickySubheader>
          <OrganizationsTitle />
          <Bar />
        </StickySubheader>
        <Container maxWidth={false}>
          <OrganizationsGrid />
        </Container>
        <CircularProgressWithBackdrop type="light" isOpen={loading || isSagaDataLoading} />
      </LayoutOrganizations>
    </>
  );
};

export default Organizations;
