// @flow
import { styled } from '@mui/material/styles';

export const TILE_WIDTH = 164;

export const TileCardGridList = styled('section')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: `repeat(auto-fill, minmax(${TILE_WIDTH}px, 1fr))`,
  gap: theme.spacing(2),
  margin: theme.spacing(2, 0),
}));

export const GridTileCard = styled('div')(() => ({
  width: TILE_WIDTH,
  marginLeft: 'auto',
  marginRight: 'auto',
}));
