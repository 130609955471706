// @flow
import React from 'react';
import useSheet from './sheet';
import { TextractFieldBinding } from './index';

type Props = {|
  onClick: (name: string) => void,
  onSelect: (index: number, mandatory: boolean) => void,
  selectedFieldIndex: number,
  mandatory: boolean | null,
  disabledFields: number[],
  width: number,
  height: number,
  deltaAngle: number,
  mappings: Array<{|
    name: string,
    bindingCell: {
      [key: number]: {|
        X: number,
        Y: number,
      |},
    },
    id: string,
    metadata: {
      mandatory: boolean,
    },
  |}>,
  fields: Array<{}>,
  boundCells: string[],
|};

export const TextractFieldBindings: React$StatelessFunctionalComponent<Props> = ({
  mappings,
  fields,
  width,
  height,
  onClick,
  onSelect,
  selectedFieldIndex,
  mandatory,
  disabledFields,
  boundCells,
  deltaAngle,
}) => {
  const classes = useSheet();

  return (
    <div className={classes.container}>
      {mappings.map(({ bindingCell, name, id, metadata: { mandatory = false } }, index) => (
        <TextractFieldBinding
          key={id}
          bound={boundCells.includes(id)}
          isMandartory={mandatory}
          width={width}
          height={height}
          name={name}
          onClick={onClick}
          bindingCell={bindingCell}
          fields={fields}
          onSelect={onSelect}
          selectedFieldIndex={selectedFieldIndex}
          mandatoryField={mandatory}
          disabledFields={disabledFields}
          index={index}
          deltaAngle={deltaAngle}
        />
      ))}
    </div>
  );
};
