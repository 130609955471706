import { shadow } from 'lib/ui';
import mgf from './mgf.svg';
import mgfA from './mgf-a.svg';

export default (theme) => ({
  wrapper: {
    marginLeft: 'auto',
    position: 'relative',
    flex: [1, 1, '327px'],
    maxWidth: 327,
  },
  input: {
    color: theme.colors.greyDarkest,
    border: `1px solid ${theme.colors.greyLight}`,
    height: 30,
    width: '100%',
    padding: [0, 15, 0, 27],
    borderRadius: 2,
    outline: 'none',
    transition: '0.3s all',
    fontSize: 14,
    background: {
      image: `url("${mgf}")`,
      color: 'transparent',
      size: [12, 12],
      repeat: 'no-repeat',
    },
    backgroundPositionY: 'center',
    backgroundPositionX: '10px',
    '.rtl &': {
      backgroundPositionX: '10px',
    },
    '&::placeholder': {
      color: theme.colors.greyBlack,
      opacity: 0.5,
      fontWeight: 'normal',
    },
    '&:hover': {
      border: `1px solid ${theme.colors.greyDarker}`,
      color: theme.colors.greyDarkest,
    },
    '&:focus': {
      opacity: 1,
      backgroundImage: `url("${mgfA}")`,
      backgroundColor: '#fff',
      fontWeight: 300,
      border: '1px solid #6C98D8',
      color: theme.colors.greyBlack,
      '&::placeholder': {
        color: theme.colors.greyDarkest,
      },
    },
  },
  suggestions: {
    ...shadow,
    position: 'absolute',
    zIndex: 12,
    top: 30,
    left: 0,
    right: 0,
    background: {
      color: '#fff',
    },
    border: `1px solid ${theme.colors.greyLight}`,
    '& > ul': {
      maxHeight: 200,
      overflowY: 'auto',
      '& li:first-child': {
        marginTop: 0,
      },
      '& li:last-child': {
        marginBottom: 0,
      },
    },
    '& > ul mark': {
      background: 'transparent',
      color: 'inherit',
    },
    '& > ul > li': {
      padding: [15, 15],
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: theme.colors.primaryLight,
        color: '#FFF',
      },
    },
  },
  activeSuggestion: {
    color: 'rgba(51, 51, 51, 0.6)',
  },
});
