import { makeStyles, createStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      position: 'relative',
    },
    header: {
      border: `1px solid ${theme.palette.grey[200]}`,
      display: 'flex',
      boxSizing: 'border-box',
      height: 30,
    },

    inputBox: {
      flexGrow: 1,
    },

    input: {
      border: 'none',
      height: '100%',
      fontSize: 14,
      padding: [0, 10],
      maxWidth: '100%',
      width: '100%',
      display: 'block',
    },

    handler: {
      cursor: 'pointer',
      flexBasis: 30,
      minWidth: 30,
      backgroundColor: 'transparent',
      border: 'none',
      outside: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    arrow: {
      height: 0,
      width: 0,
      border: '5px solid transparent',
      borderTopColor: theme.palette.grey[500],
      borderBottomWidth: 0,
      transform: 'rotate(0deg)',
      transition: 'transform .3s ease-in-out',
    },
    arrowOpen: {
      transform: 'rotate(180deg)',
      transition: 'transform .3s ease-in-out',
    },

    listPosition: {
      maxHeight: 0,
      position: 'absolute',
      zIndex: 100,
    },

    listPositionBottom: {
      bottom: -3,
    },

    listPositionTop: {
      top: -3,
    },

    listBox: {
      position: 'absolute',
      backgroundColor: theme.palette.common.white,
      boxShadow: theme.shadows[3],
      borderRadius: theme.shape.borderRadius,
    },

    listBoxBottom: {
      bottom: 0,
    },

    listBoxTop: {
      top: 0,
    },
  }),
);
