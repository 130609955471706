import { select, call, spawn, put, take } from 'redux-saga/effects';
import * as Env from 'domain/env';
import Api, { doLogout } from 'domain/api';
import { ensureGetOrganization, ensureGetOrganizationList } from 'domain/organization/sagas';
import { signInAction } from 'domain/env';

import { saveOnboardingInfoAction } from 'domain/env/envActions';
import type { Saga } from 'redux-saga';
// eslint-disable-next-line import/no-cycle
import { appInsights } from 'index';
import { navigate } from 'domain/router/redux/reduxActions';
import ROUTES_PATH from 'domain/router/routesPathConfig';
import { appsAdapter } from 'domain/env/adapters';
import { generatePath } from 'react-router-dom';

function* initFreshDesk(): Saga<*, *, void> {
  const locale = yield select(Env.localeSelector);
  // freshworks setup.
  // must be iniitialized before widget script is loaded
  // in Header component
  window.fwSettings = {
    widget_id: process.env.REACT_APP_FRESHDESK_WIDGET_ID,
    locale,
  };

  // eslint-disable-next-line
  !(function (...args) {
    if (typeof window.FreshworksWidget !== 'function') {
      console.log('freshDesk parameters initialized');
      // eslint-disable-next-line
      var n = function () {
        n.q.push(args);
      };
      // eslint-disable-next-line
      (n.q = []), (window.FreshworksWidget = n);
    }
  })();
}

function* ensureOrganization() {
  yield call(initFreshDesk);
  const isAuthorized = yield select(Env.isAuthorizedSelector);

  if (isAuthorized) {
    try {
      const { data } = yield call(Api.getUserProfile);
      const profileData = data.AP.profile;
      // if ghost - redirect to org list
      const defaultGhostURL = generatePath(ROUTES_PATH.ORGANIZATIONS.absolute);
      const currentUrl = window.location.pathname;
      if (profileData.isDokkaSupport && currentUrl === '/') {
        yield put(navigate.replace(defaultGhostURL, {}));
      }
      // identifying appInsights user using initialized appInsights instance
      appInsights.setAuthenticatedUserContext(profileData.userID);
      console.log('AppInsights user email set ', profileData.userID);

      yield put({
        type: saveOnboardingInfoAction.type,
        payload: {
          organizationId: profileData.organizationId,
          organizationName: profileData.organizationName,
          isSuperUser: profileData.isSuperUser,
          firstName: profileData.firstName,
          lastName: profileData.lastName,
          userGUID: profileData.userGUID,
          permissions: profileData.permissions,
          helpdeskLink: profileData.support_portal,
          picture: profileData.picture,
          phone: profileData.phone,
          title: profileData.title,
          organizationBot: profileData.organizationBot,
          newChatFlow: profileData.newChatFlow,
          features: profileData.features,
          allowFreshdesk: profileData.allowFreshdesk,
          limited: profileData.limited,
          restricted: profileData.restricted,
          readonly: profileData.readonly,
          cognitoUsername: profileData.cognitoUsername,
          apps: appsAdapter(data),
        },
      });

      yield call(ensureGetOrganization);
      yield call(ensureGetOrganizationList);
    } catch (err) {
      yield doLogout(saveOnboardingInfoAction, err);
    }
  } else {
    yield take(signInAction.success);
    yield call(ensureGetOrganization);
  }
}

export default function* organization(): Saga<*> {
  yield spawn(ensureOrganization);
}
