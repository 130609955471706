// @flow
import { asyncAction, syncAction } from 'lib/action';

export const getDashboardStats = asyncAction('DASHBOARD/GET_STATS');
export const putDashboardStatsFromWorker = syncAction('DASHBOARD/PUT_STATS');
export const getFavoritesAction = asyncAction('DASHBOARD/GET_FAVORITES');
export const saveFavoritesAction = asyncAction('DASHBOARD/SAVE_FAVORITES');
export const clearFavoritesAction = syncAction('DASHBOARD/CLEAR_FAVORITES');
export const deleteFavoritesAction = asyncAction('DASHBOARD/DELETE_FAVORITES');
export const getTodoItemsAction = asyncAction('DASHBOARD/GET_TODO_ITEMS');
export const updateTodoItemAction = asyncAction('DASHBOARD/UPDATE_TODO_ITEM');
export const createTodoItemAction = asyncAction('DASHBOARD/CREATE_TODO_ITEM');
export const removeTodoItemAction = asyncAction('DASHBOARD/REMOVE_TODO_ITEM');
export const removeCompletedTodoAction = asyncAction('DASHBOARD/REMOVE_COMPLETED_TODO');
export const RemoveTodoItemWithDelayAction = syncAction('DASHBOARD/REMOVE_TODO_ITEM_WITH_DELAY');
export const allowCreateTodoItemDeletionAction = syncAction('DASHBOARD/ALLOW_CREATE_TODO_ITEM_DELETION');
export const workerStartAction = syncAction('DASHBOARD/WORKER_START');
