// @flow
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ChatDrawerContent from 'components/mui/Layouts/components/Chat/components/ChatDrawerContent';
import ChatMessagesDrawer from 'components/mui/Layouts/components/Chat/components/ChatMessagesDrawer';

import { approveApprovalAction } from 'domain/approvals/actions';
import { isSignApprovalsStartSelector, isCurrentUserSignaturePendingSelector } from 'domain/approvals/selectors';
import { documentGet } from 'domain/documents/documentsActions';
import { currentCompanySelector } from 'domain/documents/documentSelector';
import { companyFeatureSetSelector, companyEnableClientChatFeatureSelector } from 'domain/companies/companiesSelector';
import {
  documentUnreadMessagesCountSelector,
  isOpenSelector,
  setActiveChannelAction,
  setOpenAction,
} from 'domain/chat';
import { documentsByIdSelector, documentSelector } from 'domain/documents';
import Layout from '../components/Layout';
import Header from './components/Header';
import NoPending from './components/NoPending';
import Stack from '@mui/material/Stack';
import Flow from './components/Flow/Flow';
import Document from '../components/Document/Document';
import Footer from './components/Footer/Footer';
import Box from '@mui/material/Box';
import { FormattedMessage } from 'react-intl';
import Alert from '@mui/material/Alert';
import DialogApprovalFormApprove from 'pages/document/components/Dialog/DialogApprovalFormApprove';
import DialogRejectApproval from 'pages/document/components/Dialog/DialogRejectApproval';
import Slide from '@mui/material/Slide';
import Widgets from './components/Widgets';

export type View = 'chat' | 'doc' | null;

export type SignatureStatus = 'disabled' | 'show' | 'hide';

type FormType = 'approve' | 'reject' | null;

const mapStateToProps = (state) => ({
  document: documentSelector(state),
  documents: documentsByIdSelector(state),
  isSignApprovalsStart: isSignApprovalsStartSelector(state),
  isCurrentUserSignaturePending: isCurrentUserSignaturePendingSelector(state),
  companyId: currentCompanySelector(state),
  unreadMessagesCount: documentUnreadMessagesCountSelector(state),
  featureSet: companyFeatureSetSelector(state),
  isEnableClientChat: companyEnableClientChatFeatureSelector(state),
  isChatOpen: isOpenSelector(state),
});

const MobileApprovalsPage = () => {
  const dispatch = useDispatch();
  const [view, setView] = useState(null);
  const [formType, setFormType] = useState(null);
  const [signatureStatus, setSignatureStatus] = useState('disabled');
  const [id, setId] = useState(null);

  const chatContentRef = useRef();

  const {
    document,
    documents,
    isSignApprovalsStart,
    isCurrentUserSignaturePending,
    companyId,
    unreadMessagesCount,
    featureSet,
    isEnableClientChat,
    isChatOpen,
  } = useSelector(mapStateToProps);

  const changeView = useCallback((type: View = 'doc') => {
    setView(type);
  }, []);

  const changeSignatureStatus = useCallback((status: SignatureStatus) => {
    setSignatureStatus(status);
  }, []);

  const getDocIds = useCallback(() => {
    const [...keys] = documents.keys();
    return keys;
  }, [documents]);

  const getCurrentDocId = useCallback(() => document.get('documentID'), [document]);

  const getCurrentDocIndex = useCallback(() => {
    const docIds = getDocIds();
    return docIds.indexOf(getCurrentDocId());
  }, [getCurrentDocId, getDocIds]);

  const getPrevDocId = useCallback(() => {
    const docIds = getDocIds();
    const currentIndex = getCurrentDocIndex();
    const prevIndex = currentIndex > 0 ? currentIndex - 1 : docIds.length - 1;
    return docIds[prevIndex];
  }, [getCurrentDocIndex, getDocIds]);

  const getNextDocId = useCallback(() => {
    const docIds = getDocIds();
    const currentIndex = getCurrentDocIndex();
    const nextIndex = currentIndex < docIds.length - 1 ? currentIndex + 1 : 0;
    return docIds[nextIndex];
  }, [getCurrentDocIndex, getDocIds]);

  const openForm = useCallback(
    (type: FormType) => () => {
      setFormType(type);
    },
    [],
  );
  const goToDoc = useCallback(
    (docId: string) => {
      dispatch(documentGet(docId));
    },
    [dispatch],
  );

  const goToPrev = useCallback(() => {
    goToDoc(getPrevDocId());
  }, [getPrevDocId, goToDoc]);

  const goToNext = useCallback(() => {
    goToDoc(getNextDocId());
  }, [getNextDocId, goToDoc]);

  const getIsGoNextDoc = useCallback(() => getNextDocId() !== getCurrentDocId(), [getCurrentDocId, getNextDocId]);

  const onDoneAction = useCallback(() => {
    const hasNext = getIsGoNextDoc();

    if (hasNext) {
      goToNext();
    }
  }, [goToNext, getIsGoNextDoc]);

  const approveDoc = useCallback(() => {
    if (isCurrentUserSignaturePending) {
      const isGoNext = getIsGoNextDoc();
      // eslint-disable-next-line no-new
      new Promise((resolve, reject) => {
        dispatch(approveApprovalAction({ resolve, reject }));
      }).then(() => {
        if (isGoNext) {
          goToNext();
        }
      });
    }
  }, [dispatch, getIsGoNextDoc, goToNext, isCurrentUserSignaturePending]);

  const onApprove = useCallback(() => {
    const isUseApprovalForm = featureSet && featureSet.approvals_form;
    const approveAction = isUseApprovalForm ? openForm('approve') : approveDoc;
    return approveAction();
  }, [approveDoc, featureSet, openForm]);

  const isSignedDoc = useCallback(() => {
    const { tags } = document;
    return tags.has('_S_SIG_DOKKA1_SIGNED') || tags.has('_S_SIG_DOKKA2_SIGNED') || isSignApprovalsStart;
  }, [isSignApprovalsStart, document]);

  const resolveSignatureStatus = useCallback(() => (isSignedDoc() ? 'show' : 'disabled'), [isSignedDoc]);

  const changeSignature = useCallback(
    (e: Event) => {
      const signature = e.currentTarget.checked ? 'show' : 'hide';
      changeSignatureStatus(signature);
    },
    [changeSignatureStatus],
  );

  const toggleView = useCallback(() => {
    const isOpen = view !== 'chat';
    dispatch(setOpenAction(isOpen));
    dispatch(setActiveChannelAction({ threadId: null, companyId }));
    const nextView = isOpen ? ['chat'] : [];
    changeView(...nextView);
  }, [changeView, companyId, dispatch, view]);

  const isDraft = useCallback(() => {
    const {
      approvals: { status },
    } = document;
    return status === 'draft';
  }, [document]);

  const renderHeader = useCallback(
    () => (
      <Header
        onClickPrev={goToPrev}
        onClickNext={goToNext}
        currentDocNum={getCurrentDocIndex() + 1}
        docsCount={documents.size}
        unreadMessagesCount={unreadMessagesCount}
        onViewBtnClick={toggleView}
        view={view}
        isNavigationDisabled={Boolean(formType)}
        isEnableClientChat={isEnableClientChat}
      />
    ),
    [
      documents.size,
      formType,
      getCurrentDocIndex,
      goToNext,
      goToPrev,
      isEnableClientChat,
      toggleView,
      unreadMessagesCount,
      view,
    ],
  );

  const hasFooter = !!(view === 'doc' && documents.size && !isDraft());

  useEffect(() => {
    changeSignatureStatus(resolveSignatureStatus());
    changeView('doc');
    setId(document.documentID);
  }, [document.documentID, resolveSignatureStatus]);

  return (
    <Layout
      headerContent={renderHeader}
      footerContent={
        hasFooter && (
          <Footer
            onApprove={onApprove}
            onReject={openForm('reject')}
            onChangeSignature={changeSignature}
            signatureStatus={signatureStatus}
            isApproveEnable={isCurrentUserSignaturePending}
            isRejectEnable={isCurrentUserSignaturePending}
          />
        )
      }
    >
      <>
        {isDraft() && view === 'doc' && (
          <Stack flex={0} sx={{ p: 1 }}>
            <Alert severity="warning">
              <FormattedMessage
                id="document.approval.submit.deactivated"
                defaultMessage="Approval flow has been deactivated"
              />
            </Alert>
          </Stack>
        )}
        {view === 'doc' && (
          <>
            {documents.size ? (
              <Stack flex={1} minHeight={0} overflow="hidden" position="relative">
                <Stack flex={0}>
                  <Flow />
                </Stack>
                <Widgets />
                <Box flex={1} minHeight={0} maxWidth="100%" overflow="auto" sx={{ m: 1 }}>
                  <Document
                    signatureStatus={signatureStatus}
                    prevDocId={getPrevDocId()}
                    nextDocId={getNextDocId()}
                    currentDocumentId={id}
                  />
                </Box>
              </Stack>
            ) : (
              <NoPending />
            )}
          </>
        )}
      </>
      <Stack minHeight={0} overflow="hidden" flexGrow={view === 'chat' ? 1 : 0}>
        <Slide direction="down" mountOnEnter unmountOnExit in={view === 'chat'}>
          <Stack minHeight={0} sx={{ position: 'relative', flexGrow: 1 }} ref={chatContentRef}>
            <ChatDrawerContent sx={{ p: 1, pt: 0 }} documentId={id} />
            <ChatMessagesDrawer
              open={isChatOpen}
              variant="temporary"
              PaperProps={{ style: { position: 'absolute', width: '100%' } }}
              BackdropProps={{ style: { position: 'absolute' } }}
              ModalProps={{
                container: chatContentRef.current,
                style: { position: 'absolute' },
              }}
              handleCloseChat={toggleView}
            />
          </Stack>
        </Slide>
      </Stack>
      {formType === 'approve' && (
        <DialogApprovalFormApprove
          onClose={openForm(null)}
          onManageApprovals={onDoneAction}
          isGoNext={getIsGoNextDoc()}
          isMobile
        />
      )}
      {formType === 'reject' && <DialogRejectApproval onClose={openForm(null)} onManageApprovals={onDoneAction} />}
    </Layout>
  );
};

export default MobileApprovalsPage;
