// @flow
import { useRef, useEffect, useCallback } from 'react';

// Hook
function useDebounce(fn, delay) {
  const timerRel = useRef(null);
  const fnRef = useRef(null);
  fnRef.current = fn;
  const delayRef = useRef(null);
  delayRef.current = delay;

  const cancel = () => {
    if (timerRel.current) {
      clearTimeout(timerRel.current);
      timerRel.current = null;
    }
  };

  useEffect(() => cancel, []);

  return useCallback((...args) => {
    cancel();
    timerRel.current = setTimeout(() => {
      timerRel.current = null;
      fnRef.current(...args);
    }, delayRef.current);
  }, []);
}

export default useDebounce;
