import { call, select } from 'redux-saga/effects';
import * as selectors from 'domain/journal/selectors';
import { generateJETableStoreKey, swapJEGridLineColumnsData } from 'domain/journal/helper';
import { userGUIDSelector } from 'domain/env';
import { currentConnectedERPselector } from 'domain/settings';
import indexedDb from 'indexedDb';

export function* getJEntryIndexedDBTableStoreKey(withSuffix: boolean = true) {
  const userGUID = yield select(userGUIDSelector);
  const jeInvoiceType = yield select(selectors.jeInvoiceTypeSelector);
  const erp = yield select(currentConnectedERPselector);
  return userGUID && jeInvoiceType && erp ? generateJETableStoreKey([userGUID, jeInvoiceType, erp], withSuffix) : null;
}

type TIndexedDbTablesForMigrationFromLetterToID = {
  tableName: string,
  tableDataKey: string,
  prefix: string,
};

const indexedDbTablesForMigrationFromLetterToID: Array<TIndexedDbTablesForMigrationFromLetterToID> = [
  {
    tableName: 'tableColsWidth',
    tableDataKey: 'width',
    prefix: 'main-table-',
  },
  {
    tableName: 'tableColsWidth',
    tableDataKey: 'width',
    prefix: 'pined-table-', // legacy typo for pined
  },
  {
    tableName: 'tablePinnedCols',
    tableDataKey: 'pinnedCols',
    prefix: '',
  },
  {
    tableName: 'tableDisabledCols',
    tableDataKey: 'disabledCols',
    prefix: '',
  },
];

/**
 * We used to store column names in capital letters, like [A, B, C, D]
 * but for rtl version and also new types of column like AA, AB - we had an intersection between this naming, and it didn't work correctly
 * so this migration change Letter naming to Column ID naming - [priv_budget, priv_qty, priv_rate]
 */
export function* migrateJETablesFromLetterToID() {
  const outdatedTableStoreKey = yield call(getJEntryIndexedDBTableStoreKey, false);
  const newTableStoreKey = yield call(getJEntryIndexedDBTableStoreKey);
  const columnsDictionary = yield select(selectors.jeGridLineColumnsDictionarySelector);

  if (columnsDictionary.size > 0) {
    const fetchedData = yield Promise.all(
      indexedDbTablesForMigrationFromLetterToID.map(({ tableName, prefix }) =>
        indexedDb[tableName].get(`${prefix}${outdatedTableStoreKey}`),
      ),
    );

    yield fetchedData.forEach(async (dbData, index) => {
      if (dbData) {
        const { prefix, tableDataKey, tableName } = indexedDbTablesForMigrationFromLetterToID[index];
        const prevTableNameKey = `${prefix}${outdatedTableStoreKey}`;
        const newTableNameKey = `${prefix}${newTableStoreKey}`;
        const migratedData = swapJEGridLineColumnsData(dbData[tableDataKey], columnsDictionary);

        await indexedDb[tableName].put({
          tableName: newTableNameKey,
          [tableDataKey]: migratedData,
        });

        await indexedDb[tableName].delete(prevTableNameKey);
      }
    });
  }
}

/**
 * Its migration between indexedDb tables, from tableDisabledCols to tableJELineColumnsVisibility
 * before we store disabled line columns as array, now we store to indexedDB all
 * line columns with current visibility state - false/true
 * false - hidden, true - visible
 * also we need call this migration before previous migration - from column letter to column id
 */
export function* migrateJETableDisabledColumnsToTableColumnsVisibility() {
  const storeKey = yield call(getJEntryIndexedDBTableStoreKey);
  if (storeKey) {
    const dbDisabledColumns = yield indexedDb.tableDisabledCols.get(storeKey);

    if (dbDisabledColumns && dbDisabledColumns.disabledCols) {
      const columnsDictionary = yield select(selectors.jeGridLineColumnsDictionarySelector);

      // build data - if not exist in previous db = visible column
      const lineColumnsVisibility = columnsDictionary.reduce(
        (prevObj, columnID) => ({ ...prevObj, [columnID]: !dbDisabledColumns.disabledCols.includes(columnID) }),
        {},
      );

      // put new record to indexedDB
      yield indexedDb.tableJELineColumnsVisibility.put({
        tableName: storeKey,
        lineColumnsVisibility,
      });

      // delete previous record of hidden line columns
      yield indexedDb.tableDisabledCols.delete(storeKey);
    }
  }
}
