// @flow
import React, { memo } from 'react';
import Icon from 'react-icon-base';
import { type TIconBase } from './types.js.flow';

const IconLogo = (props: TIconBase) => (
  <Icon width="36" height="30" viewBox="0 0 36 30" fill="none" color="white" {...props}>
    <path
      d="M14.7041 22.8498C18.4405 19.8062 21.2249 15.7542 22.7265 11.175C23.9676 7.58895 24.4019 3.7732 23.9984 0L35.8762 6.8297C36.2416 10.543 35.7975 14.2914 34.5746 17.8166C33.0546 22.4516 30.2211 26.5453 26.4185 29.6003L14.7041 22.8498Z"
      fill="currentColor"
    />
    <path
      d="M1.0393 14.7236C2.6032 10.1111 5.60728 6.50815 9.1508 4.50378L18.0591 9.62111C14.5601 11.6007 11.5808 15.2036 10.0268 19.7865C9.44445 21.497 9.09488 23.2782 8.98748 25.082V25.082L0 19.9349C0.117339 18.1599 0.466748 16.4079 1.0393 14.7236V14.7236Z"
      fill="currentColor"
    />
  </Icon>
);

export default memo(IconLogo);
