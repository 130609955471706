import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';

import { styled } from '@mui/material/styles';

export const FakeDropdownContainer = styled(Box, { label: 'FakeDropdownContainer' })(() => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  borderRadius: 2,
  display: 'flex',
  alignItems: 'center',
}));

export const FakeDropdown = styled(Chip, { label: 'FakeDropdownChip' })(({ theme }) => ({
  flex: 1,
  margin: theme.spacing(0, 0.5),
  justifyContent: 'left',
  backgroundColor: theme.palette.grey[300],

  '& .MuiChip-label': {
    unicodeBidi: 'plaintext',
    padding: theme.spacing(0, 1),
  },
}));
