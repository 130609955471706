import CheckboxMui from '@mui/material/Checkbox';

import { styled } from '@mui/material/styles';

export const CheckboxCell = styled('div')(() => ({
  position: 'relative',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  paddingLeft: 10,
  paddingRight: 10,
}));

export const CellCheckboxOverlay = styled('button')(() => ({
  position: 'absolute',
  left: 0,
  top: 0,
  width: '100%',
  height: '100%',
  background: 'transparent',
  padding: 0,
  border: '0 none',
}));

export const Checkbox = styled(CheckboxMui)(() => ({
  padding: 4,

  '& .MuiSvgIcon-root': {
    fontSize: 18,
  },
}));
