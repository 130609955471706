// @fjow
import React, { useState } from 'react';

export default (manualIsActive: boolean, setManualIsActive: ((boolean) => boolean) => void) => {
  const [isActive, setIsActive] = useState(false);

  const createToggleHandler = React.useCallback(
    (cb) => () => {
      cb && cb();
      setManualIsActive
        ? setManualIsActive(({ isNoteWidgetActive }) => ({
            isNoteWidgetActive: !isNoteWidgetActive,
          }))
        : setIsActive((active) => !active);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setIsActive, isActive],
  );

  const setActive = React.useCallback(
    (active) => {
      const handler = setManualIsActive || setIsActive;
      handler(active);
    },
    [setManualIsActive, setIsActive],
  );

  return {
    isActive: manualIsActive || isActive,
    createToggleHandler,
    setIsActive: setActive,
  };
};
