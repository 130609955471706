// @flow
import React from 'react';
import { useSelector } from 'react-redux';
import { generatePath } from 'react-router-dom';
import ROUTES_PATH from 'domain/router/routesPathConfig';
import { currentCompanySelector } from 'domain/documents';
import * as ACL from 'domain/restriction';

import BackToArrowBase from 'components/mui/Layouts/components/BackToArrowBase';

const BackToArrow = () => {
  const isGranted = useSelector(ACL.isGranted);
  const companyId = useSelector(currentCompanySelector);
  const tooltip = companyId
    ? { id: 'tooltip.backToWorkspace', defaultMessage: 'Back to Workspace' }
    : { id: 'configurations.org.back', defaultMessage: 'Back to Company List' };

  const ROUTE_ID = isGranted(ACL.IS_SUPPLIER_USER)
    ? ROUTES_PATH.COMPANY_SUPPLIER.id
    : ROUTES_PATH.COMPANY_DEFAULT_CATEGORY.id;

  const path = companyId
    ? generatePath(ROUTES_PATH[ROUTE_ID].absolute, { companyId })
    : generatePath(ROUTES_PATH.COMPANIES.absolute);

  return <BackToArrowBase path={path} tooltip={tooltip} />;
};

export default BackToArrow;
