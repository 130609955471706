// @flow
import React from 'react';
import { compose, type Dispatch } from 'redux';
import { connect } from 'react-redux';
import { navigate } from 'domain/router/redux/reduxActions';
import { withStyles } from '@mui/styles';
import { Link, type LocationShape } from 'react-router-dom';
import { type Company } from 'domain/companies';
import * as ACL from 'domain/restriction';
import Tooltip from 'components/Tooltip';
import { organizationWhatsappPhoneSelector } from 'domain/organization/organizationSelector';

import ConfirmationModal from 'components/ConfirmationModal/modal';
import { injectIntl, type IntlShape } from 'react-intl';
import cx from 'classnames';
import elements from 'components/elements';
import sheet from './sheet';

type Props = {
  classes: {
    [key: string]: string,
  },
  to: string | LocationShape,
  company: Company,
  isConfidential: boolean,
  enabled: boolean,
  home: boolean,
  usePreventConfirmation?: boolean,
  intl: IntlShape,
  navigatePush: Dispatch<typeof push>,
  organoizationWhatsappPhone: ?string,
  isGranted: (r: number | number[]) => boolean,
  erpCompanyName?: string,
};

type IState = {
  isShowConfirmation: boolean,
};

class Left extends React.PureComponent<Props, IState> {
  state = {
    isShowConfirmation: false,
  };

  onClickLink = (e) => {
    const { usePreventConfirmation } = this.props;
    if (usePreventConfirmation) {
      e.preventDefault();
      this.setState({ isShowConfirmation: true });
    }
  };

  onCloseConfirmation = () => {
    this.setState({ isShowConfirmation: false });
  };

  isAllowToLinkPhone = () => {
    const { isGranted } = this.props;
    return !isGranted(ACL.IS_ACCOUNT);
  };

  goTo = () => {
    const { navigatePush, to } = this.props;
    this.onCloseConfirmation();
    navigatePush(to);
  };

  render() {
    const { isShowConfirmation } = this.state;
    const {
      classes,
      to,
      company,
      enabled,
      home,
      intl: { formatMessage },
      organoizationWhatsappPhone,
      erpCompanyName,
      isConfidential,
    } = this.props;

    const email = company[isConfidential ? 'email.confidential' : 'email'];
    const whatsappPhone = company.whatsappPhone || organoizationWhatsappPhone;

    return (
      <div className={cx(classes.left, { [classes.withHome]: home })}>
        {home && (
          <Tooltip id="company.companyList" defaultMessage="Company list" alignToEdge="left">
            <Link to="/" className={classes.home} data-element={elements.header.home}>
              <span className={classes.homeIcon} />
            </Link>
          </Tooltip>
        )}
        {!enabled ? null : (
          <Tooltip
            message={formatMessage({
              id: 'document.backToWorkspace',
              defaultMessage: 'Back to Workspace',
            })}
            width={150}
            alignToEdge="left"
          >
            <Link to={to} className={classes.back} data-element={elements.header.back} onClick={this.onClickLink} />
          </Tooltip>
        )}
        <img
          src={company.logo}
          alt={company.cname}
          className={classes.logo}
          data-element={elements.header.company.logo}
        />
        <dl className={classes.info} data-element={elements.header.company.about}>
          <dt className={classes.name}>
            <Tooltip
              message={company.cname}
              tooltipClass={classes.tooltip}
              disable={company.cname && company.cname.length < 20}
            >
              <span className={classes.nameWrap}>{`${company.cname} `}</span>
              {erpCompanyName && <span className={classes.nameWrap}>&nbsp;{`(${erpCompanyName})`}</span>}
            </Tooltip>
          </dt>
          <dd className={classes.descriptionWrapper}>
            <div className={classes.infoBox}>
              <span className={classes.emailIcon} />
              <a href={`mailto:${email}`} className={classes.email}>
                {email}
              </a>
            </div>
            {Boolean(whatsappPhone) && (
              <>
                <div className={classes.separate} />
                <div className={classes.infoBox}>
                  <span className={classes.phoneIcon} />
                  {this.isAllowToLinkPhone() ? (
                    <a
                      href={`https://api.whatsapp.com/send?phone=${whatsappPhone}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className={classes.phoneLink}
                    >
                      <span className={classes.phoneText}>{whatsappPhone}</span>
                    </a>
                  ) : (
                    <span className={classes.phoneText}>{whatsappPhone}</span>
                  )}
                </div>
              </>
            )}
          </dd>
        </dl>

        {isShowConfirmation && (
          <ConfirmationModal
            cancel={this.onCloseConfirmation}
            onConfirm={this.goTo}
            blue
            title={formatMessage({
              id: 'document.leave.confirm.title',
              defaultMessage: 'Approval flow was not activated',
            })}
            placeholder={formatMessage({
              id: 'document.leave.confirm.placeholder',
              defaultMessage: 'Are you sure you want to leave the document without Approval flow activation?',
            })}
            confirmText={formatMessage({
              id: 'document.leave.confirm.btn',
              defaultMessage: 'Leave',
            })}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  organoizationWhatsappPhone: organizationWhatsappPhoneSelector(state),
  isGranted: ACL.isGranted(state),
});

const mapDispatchToProps = {
  navigatePush: navigate.push,
};

export default compose(injectIntl, connect(mapStateToProps, mapDispatchToProps), withStyles(sheet))(Left);
