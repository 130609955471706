/* @flow */
import { Set } from 'immutable';
import type { MetaType } from '../../types.js.flow';
import type { CSSCol } from './Types.js.flow';
import { generateNumRange } from 'lib/helpers';

export type ZippedQueryParams = {|
  page: number,
  offset: number,
|};

const DEFAULT_OFFSET = 50;

export function getHeight(container: ?HTMLElement) {
  const wrapper = document.getElementById('tableWrapper');
  if (wrapper && container) {
    const c = container.getBoundingClientRect();
    const w = wrapper.getBoundingClientRect();
    return w.bottom - c.bottom - 70 + c.height;
  }
  return 150;
}

export function createCelClassName(col: string) {
  return `col-${col}`;
}

export function createProperyName(col: string) {
  return `--bnk-col-${col}-width`;
}

export function getColRules(meta: MetaType, reconcileColumn: string): { [key: string]: CSSCol } {
  return meta[0].reduce((a, v) => {
    if (reconcileColumn === v) return a;
    return {
      ...a,
      [createCelClassName(v)]: {
        width: `var(${createProperyName(v)}, 100%)`,
        flex: [0, 0, `var(${createProperyName(v)}, 100%)`],
      },
    };
  }, {});
}

export function withLimit(value: number, max: number, min: number) {
  return Math.max(Math.min(value, max), min);
}

export function setPropeyValue(container: HTMLElement, propery: string, value: string): void {
  container.style.setProperty(propery, value);
}

// eslint-disable-next-line max-len
export function namedCols(meta: MetaType, data: any): $ReadOnlyArray<{ name: string, title: string }> {
  const [cols, rows] = meta;
  const getCellName = (col: string) => `${col}${rows[0]}`;
  const getTitle = (cellName: string) => data.getIn([cellName, 'text'], data.getIn([cellName, 'cellSet', 'value']));
  return cols.map((name: string) => ({ name, title: getTitle(getCellName(name)) }));
}

function createRange(start: number, end: number, shift: number): $ReadOnlyArray<number> {
  let range = [];
  for (let i = start + shift; i <= end + shift; i++) {
    range = range.concat(i);
  }
  return range;
}

// eslint-disable-next-line max-len
export function getPageParams(start: number, end: number, rows: Set<number>, pageLimit: number = DEFAULT_OFFSET) {
  // after JE update we dont have first line_item, and shift will point to first loaded lineitem
  // on X page. Further calculations will be broken.
  // for current BS we hardcode it to 4 = Settings(2lines) + BS header(1 line) + 1
  // const shift = rows.sort().first();
  const range = createRange(start, end, 4);
  const needRows = range.reduce((a, v) => (rows.has(v) ? a : a.concat(v)), []);
  return needRows.reduce((a, v) => a.add(Math.ceil(v / pageLimit)), new Set());
}

export const generateSequence = (count: number) => Array.from(new Array(count).keys());

export const getRowPage = (offset: number) => (row: number) => {
  const page = Math.floor(row / offset) + 1;
  return page > 0 ? page : 1;
};

export const getRowOnePage = (firsRow: number, lastRow: number, offset: number) => {
  const getPage = getRowPage(offset);
  const firstPage = getPage(firsRow);
  const lastPage = getPage(lastRow);
  return firstPage === lastPage ? firstPage : null;
};

export const getOptimizeQueryParams = (firsRow: number, lastRow: number) => {
  const startOffsset = lastRow - firsRow;

  return generateSequence(startOffsset + 1).reduce((res, val) => {
    const getQueryParams = () => {
      const currentOffset = startOffsset + val;
      const currentPage = getRowOnePage(firsRow, lastRow, currentOffset);
      return currentPage ? [currentPage, currentOffset] : null;
    };

    return res || getQueryParams();
  }, null);
};

export const getFirstRowOfPage = (page: number, offset: number) => (page - 1) * offset;

export const getLastRowOfPage = (page: number, offset: number) => page * offset - 1;

export const zipQueryParams = (pages: Array<number>, useOffset: number = DEFAULT_OFFSET): ZippedQueryParams => {
  const firsRow = getFirstRowOfPage(pages[0], useOffset);
  const lastRow = getLastRowOfPage(pages[pages.length - 1], useOffset);
  const [page, offset] = getOptimizeQueryParams(firsRow, lastRow);
  return { page, offset };
};

export const getPrevPages = (page: number, count: number = 1) =>
  generateSequence(count)
    .reverse()
    .reduce((res, val) => {
      const item = page - val - 1;
      return item > 0 && !res.includes(item) ? [...res, item] : res;
    }, []);

export const getNextPages = (page: number, count: number = 1) =>
  generateSequence(count).reduce((res, val) => [...res, page + val + 1], []);

// eslint-disable-next-line max-len
export const getLoadPagesByRows = (
  startRow: number,
  endRow: number,
  pageGap: number = 2,
  offset: number = DEFAULT_OFFSET,
) => {
  const getPage = getRowPage(offset);
  const startPage = getPage(startRow);
  const endPage = getPage(endRow);

  const currentPages = startPage === endPage ? [startPage] : generateNumRange(startPage, endPage);

  const res = [...getPrevPages(startPage, pageGap), ...currentPages, ...getNextPages(endPage, pageGap)];

  return res;
};

export function getPageMeta(startRow: number, endRow: number) {
  const loadPages = getLoadPagesByRows(startRow, endRow, 0);
  const { page, offset } = zipQueryParams(loadPages);
  return [loadPages, { page, pageSize: offset }];
}
