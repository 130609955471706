// @flow
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Tile, TileActions, THUMBNAIL_H } from 'pages/company/TileGrid/TileCard/components/StyledComponents';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Skeleton from '@mui/material/Skeleton';

import elements from 'components/elements';

type TTileCardFake = {
  value: string | number,
};

const ThumbnailFake = () => (
  <Box height={THUMBNAIL_H} display="flex">
    <Box mx="auto" width="80%">
      <Box ml="50%" mt={1}>
        <Skeleton height={15} animation="wave" />
        <Skeleton height={10} animation="wave" />
        <Skeleton height={10} animation="wave" />
      </Box>
      <Skeleton height={60} animation="wave" />
      <Skeleton height={10} animation="wave" />
      <Stack direction="row" spacing={1}>
        <Skeleton height={40} animation="wave" width="60%" />
        <Skeleton height={40} animation="wave" width="40%" />
      </Stack>
      <Skeleton height={10} animation="wave" />
      <Skeleton height={10} animation="wave" />
    </Box>
  </Box>
);

const TileCardFake: React$StatelessFunctionalComponent<TTileCardFake> = ({ value }) => (
  <Tile data-element={elements.content.analyzing.container}>
    <ThumbnailFake />

    <TileActions>
      <CircularProgress size={20} />
      <Typography variant="body2" color="primary" mx={1} flex="1" textTransform="uppercase">
        <FormattedMessage id="document.dashboard.analyzing" defaultMessage="analyzing" />
      </Typography>
      <Typography variant="body2" color="primary" fontWeight="500">
        {value}
      </Typography>
    </TileActions>
  </Tile>
);

export default TileCardFake;
