// @flow

import React from 'react';
import MuiDialog, { type DialogProps } from '@mui/material/Dialog';

const DialogDraggable: React$StatelessFunctionalComponent<DialogProps> = (props) => (
  <MuiDialog aria-labelledby="draggable-dialog-title" {...props} />
);

export default DialogDraggable;
