// @flow
import React, { useMemo, useCallback, useState, useEffect, useRef } from 'react';
import type { OptionData, OptionValue } from './Options/Option';
import { debounce } from 'throttle-debounce';
import usePrevious from 'hooks/usePrevious';

import { omit } from 'lodash/fp';
import Options from './Options';
import identity from 'lodash/identity';
import get from 'lodash/get';

import cx from 'classnames';
import { useStyles } from './styled';
import Popper from '@mui/material/Popper';

export type VirtualSelectProps = {|
  options: OptionData[],
  onChange: (v: OptionValue) => void,
  onCloseCallback?: () => void,
  value: OptionValue,
  defaultLabel: string,
  className?: string,
  headerClassName?: string,
  placeholder?: string,
  isDebugOpen?: boolean,
  maxVisibleOptionsCount?: number,
  onVirtualListRender?: (from: number, to: number) => void,
  onListOpen?: () => void,
  onListClose?: () => void,
  onSearch?: (t: string) => void,
  searchDelay?: number,
  isLoading?: boolean,
  open?: boolean,
  disabled?: boolean,
  isFilterOptionByTerm?: boolean,
  renderInput: () => any,
  disablePopper: boolean,
|};

const getIndex = (...indexes) => indexes.reduce((res, index) => (res !== null || index === null ? res : index), null);

const VirtualSelect = ({
  options,
  onChange,
  value,
  defaultLabel = '',
  onSearch = identity,
  placeholder = '',
  isDebugOpen = false,
  className = '',
  headerClassName = '',
  maxVisibleOptionsCount = 15,
  onVirtualListRender = identity,
  onListOpen = identity,
  onListClose = identity,
  onCloseCallback = identity,
  searchDelay = 500,
  isLoading = false,
  open = false,
  disabled,
  isFilterOptionByTerm = true,
  renderInput: renderInputDefault = () => null,
  disablePopper = true,
}: VirtualSelectProps) => {
  const containerElRef = useRef(null);
  const listBoxElRef = useRef(null);
  const inputElRef = useRef(null);
  const [inputSearchText, setInputSearchText] = useState(null);
  const [isOpen, setOpenStatus] = useState(false);
  const [activeIndex, setActiveIndex] = useState(null);
  const [preselectIndex, setPreselectIndex] = useState(null);
  const [currentOptions, setCurrentOptions] = useState(options);
  const prevOptionsLength = usePrevious(options.length);
  const classes = useStyles();

  const getValue = useMemo(() => (index) => get(currentOptions, [index, 'value']), [currentOptions]);

  const getLabel = useMemo(() => (index) => get(currentOptions, [index, 'label']), [currentOptions]);

  const isBottomPosition = useMemo(() => {
    if (!containerElRef.current) return true;
    const count = Math.min(currentOptions.length, maxVisibleOptionsCount);
    const rect = containerElRef.current.getBoundingClientRect();
    return !(rect.bottom + count * 30 + 10 > window.innerHeight);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentOptions, maxVisibleOptionsCount, isOpen]);

  const eventStop = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const inputValue = inputSearchText === null ? getLabel(activeIndex) || defaultLabel : inputSearchText;

  const onOpen = () => {
    setOpenStatus(true);
    onListOpen();
  };

  const onPreselect = (index: number | null) => {
    setPreselectIndex(index);
  };

  const onClose = useCallback(() => {
    if (!isDebugOpen && isOpen) {
      setInputSearchText(null);
      setOpenStatus(false);
      onPreselect(null);
      onListClose();
      onCloseCallback();
      setCurrentOptions(options); // reset to default options if we made filter by term
      setTimeout(() => {
        if (inputElRef.current) inputElRef.current.blur();
      }, 0);
    }
  }, [isDebugOpen, isOpen, onCloseCallback, onListClose, options]);

  const onSelectOption = useCallback(
    (index: number) => {
      onClose();
      const optionValue = getValue(index);
      setActiveIndex(index);
      onChange(optionValue);
    },
    [onClose, getValue, onChange],
  );

  const applyPreselect = () => {
    if (preselectIndex !== null) {
      onSelectOption(preselectIndex);
    } else {
      onClose();
    }
  };

  const onHandlerClick = () => {
    if (disabled) return;

    if (isOpen) {
      applyPreselect();
      onClose();
    } else {
      inputElRef.current.focus();
    }
  };

  const filterOptionByTerm = useCallback(
    (term: string) => {
      if (term === null || term.length === 0) {
        // if term ia empty return all options
        setCurrentOptions(options);
      } else {
        const filteredOptions = options.filter((option) =>
          String(option.label).toLowerCase().includes(term.toLowerCase()),
        );
        setCurrentOptions(filteredOptions);
      }
    },
    [options],
  );

  // TODO: fix eslint, now debounce work wrong
  const onSearchStart = useCallback(
    debounce(searchDelay, (searchText: string) => {
      onPreselect(null);
      onSearch(searchText);

      if (isFilterOptionByTerm) {
        filterOptionByTerm(searchText);
      }
    }),
    [searchDelay, isFilterOptionByTerm, filterOptionByTerm, onSearch],
  );

  const onInputChange = useCallback(
    (e) => {
      const { value: val } = e.currentTarget;
      setInputSearchText(val);
      onSearchStart(val);
    },
    [onSearchStart],
  );

  const onKeyDown = (e: KeyboardEvent) => {
    const { length } = currentOptions;
    const keyActions = {
      // down
      40: () => {
        const currentIndex = getIndex(preselectIndex, activeIndex);
        const shift = isLoading ? 0 : 1;
        const upIndex = currentIndex !== null ? currentIndex + shift : 0;
        const nextIndex = length > upIndex ? upIndex : 0;
        onPreselect(nextIndex);
      },
      // up
      38: () => {
        const currentIndex = getIndex(preselectIndex, activeIndex);
        const shift = isLoading ? 0 : 1;
        const downIndex = currentIndex !== null ? currentIndex - shift : length - shift;
        const nextIndex = downIndex >= 0 ? downIndex : length - shift;
        onPreselect(nextIndex);
      },
      // enter
      13: () => {
        applyPreselect();
      },
      // escape
      27: () => {
        onClose();
      },
    };
    get(keyActions, e.keyCode, identity)();
  };

  const getIndexByValue = useCallback(
    (valueForCompare: string | null) => currentOptions.findIndex((option) => option.value === valueForCompare),
    [currentOptions],
  );

  const renderInput = useCallback(
    ({
      type = 'test',
      onChange: handleChange = onInputChange,
      ref = inputElRef,
      value: defaultValue = inputValue,
      onKeyDown: handleKeyDown = onKeyDown,
      onFocus = onOpen,
      onBlur = onClose,
      placeholder: defaultPlaceholder = placeholder,
      disabled: defaultDisabled = disabled,
      isOpen: opened = isOpen,
      onBtnClick = onHandlerClick,
      onBtnMouseDown = eventStop,
    } = {}) => {
      const params = {
        type,
        onChange: handleChange,
        ref,
        value: defaultValue,
        onKeyDown: handleKeyDown,
        onFocus,
        onBlur,
        placeholder: defaultPlaceholder,
        disabled: defaultDisabled,
        opened,
        onBtnClick,
        onBtnMouseDown,
      };
      const inputParams = omit(['onBtnClick', 'onBtnMouseDown', 'opened'])(params);
      return (
        renderInputDefault(params) || (
          <div className={cx(classes.header, headerClassName)}>
            <div className={classes.inputBox}>
              <input {...inputParams} className={classes.input} />
            </div>
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
            <div
              role="button"
              tabIndex="0"
              className={classes.handler}
              onClick={onBtnClick}
              onMouseDown={onBtnMouseDown}
            >
              <div className={cx(classes.arrow, { [classes.arrowOpen]: opened })} />
            </div>
          </div>
        )
      );
    },
    [
      classes.arrow,
      classes.arrowOpen,
      classes.handler,
      classes.header,
      classes.input,
      classes.inputBox,
      disabled,
      headerClassName,
      inputValue,
      isOpen,
      onClose,
      onHandlerClick,
      onInputChange,
      onKeyDown,
      onOpen,
      placeholder,
      renderInputDefault,
    ],
  );

  const menu = React.useMemo(
    () => (
      <div
        className={cx(classes.listPosition, {
          [classes.listPositionBottom]: isBottomPosition,
          [classes.listPositionTop]: !isBottomPosition,
        })}
      >
        <div
          ref={listBoxElRef}
          className={cx(classes.listBox, {
            [classes.listBoxBottom]: !isBottomPosition,
            [classes.listBoxTop]: isBottomPosition,
          })}
          onMouseDown={eventStop}
          role="button"
          tabIndex="0"
        >
          <Options
            options={currentOptions}
            onPreselect={onPreselect}
            onSelect={onSelectOption}
            searchTerm={inputSearchText}
            optionWidth={containerElRef.current ? containerElRef.current.clientWidth : 200}
            activeIndex={activeIndex}
            preselectIndex={preselectIndex}
            maxVisibleOptionsCount={maxVisibleOptionsCount}
            onVirtualListRender={onVirtualListRender}
          />
        </div>
      </div>
    ),
    [
      activeIndex,
      classes.listBox,
      classes.listBoxBottom,
      classes.listBoxTop,
      classes.listPosition,
      classes.listPositionBottom,
      classes.listPositionTop,
      currentOptions,
      inputSearchText,
      isBottomPosition,
      maxVisibleOptionsCount,
      onSelectOption,
      onVirtualListRender,
      preselectIndex,
    ],
  );

  useEffect(() => {
    const optionIndex = getIndexByValue(value);

    setActiveIndex(optionIndex === -1 ? null : optionIndex);
  }, [value, getIndexByValue]);

  useEffect(() => {
    // this allows to open dropdown automatically.
    // must be controlled by passing specific prop not to break JE behavior
    if (inputElRef.current && open) inputElRef.current.focus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // commonly used for async select, we need to update the list of options when the options have been changed
  useEffect(() => {
    if (options.length !== prevOptionsLength) {
      setCurrentOptions(options);
    }
  }, [options, prevOptionsLength]);

  return (
    <div ref={containerElRef} className={cx(classes.container, className)}>
      {renderInput()}
      {isOpen ? (
        disablePopper ? (
          menu
        ) : (
          <Popper
            open
            anchorEl={inputElRef.current}
            sx={{ zIndex: (theme) => theme.zIndex.modal + 1 }}
            placement={isBottomPosition ? 'bottom-start' : 'top-start'}
          >
            {menu}
          </Popper>
        )
      ) : null}
    </div>
  );
};

export default VirtualSelect;
