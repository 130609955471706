/* @flow */

export default (theme) => ({
  helper: {
    border: `1px solid ${theme.palette.grey[400]}`,
    zIndex: theme.zIndex.modal,
    boxShadow: theme.shadows[4],
    cursor: 'ns-resize',
  },
});
