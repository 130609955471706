/* @flow */
import { type RecordOf } from 'immutable';

export interface IUser {
  username: string;
  userId: string;
}

interface IFullNameUser {
  firstName: string;
  lastName: string;
  userId: string;
  username: string;
}

export function filter(data: RecordOf<IFullNameUser>, term: string) {
  const t = term.toLowerCase();
  if (data.firstName.toLowerCase().includes(t)) return true;
  if (data.lastName.toLowerCase().includes(t)) return true;
  if (data.userId.toLowerCase().includes(t)) return true;
  if (data.username.toLowerCase().includes(t)) return true;
  return false;
}
