// @flow
import React from 'react';
import moment from 'moment';
import type { TMessageRecord } from 'domain/chat/types.js.flow';

import Typography from '@mui/material/Typography';
import { styled, alpha } from '@mui/material/styles';
import { blue } from '@mui/material/colors';

type TChatMessage = {
  data: TMessageRecord,
  isMy: boolean,
};

const Message = styled('div', { shouldForwardProp: (propName) => propName !== 'isMy' })(({ theme, isMy }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  maxWidth: '80%',
  borderRadius: 8,
  marginTop: theme.spacing(1),
  padding: theme.spacing(2),
  borderBottomLeftRadius: 0,
  backgroundColor: isMy ? blue[50] : alpha(theme.palette.secondary.main, 0.3),
  ...(isMy && {
    marginLeft: 'auto',
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 0,
  }),
}));

const MessageText = styled(Typography)(() => ({
  wordWrap: 'break-word',
  whiteSpace: 'pre-wrap',
}));

const MessageTime = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[600],
  fontSize: theme.typography.pxToRem(12),
  marginLeft: 'auto',
  paddingLeft: theme.spacing(1),
}));

const toHumanDate = (date) => moment(date).format('hh:mm A');

const ChatMessage: React$StatelessFunctionalComponent<TChatMessage> = ({ data, isMy }) => (
  <Message isMy={isMy}>
    <MessageText variant="body2" class="fs-exclude">
      {data.message}
    </MessageText>
    <MessageTime>{toHumanDate(data.createdAt)}</MessageTime>
  </Message>
);
export default ChatMessage;
