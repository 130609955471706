// @flow
import { storage } from 'lib/storage';
import { Record, type RecordOf, type RecordFactory } from 'immutable';
import type { RolesType, EnvType, CurrentUserType } from './types.js.flow';

export const ROLES = {
  accountant: 'accountant',
  'limited-accountant': 'limited-accountant',
  'restricted-accountant': 'restricted-accountant',
  user: 'user',
  'restricted-user': 'restricted-user',
  'confidential-user': 'confidential-user',
  'confidential-restricted-user': 'confidential-restricted-user',
  supplier: 'supplier',
};

export const passwordLessAuthDomains = [
  'supplier.dokka.biz',
  'suppliers.dokka.com',
  'suppliers.dokka.co.il',
  'suppliers-release.dokka.biz',
  // 'localhost:3000',
];

export function checkRoles(role: ?RolesType): ?RolesType {
  if (role && typeof ROLES[role] !== 'undefined') return role;
  return null;
}

export function getRoles(): ?RolesType {
  const role: ?RolesType = storage().role().get();
  return checkRoles(role);
}

export function setRoles(role: ?RolesType): boolean {
  const r: ?RolesType = checkRoles(role);
  if (r !== null && r !== undefined) return storage().role().set(r);
  return false;
}

export function userAdapter({
  userId,
  chatUser,
  chatToken,
  role,
  userGUID,
}: RecordOf<EnvType>): RecordOf<CurrentUserType> {
  const userFactory: RecordFactory<CurrentUserType> = new Record({
    userId: null,
    chatUser: null,
    chatToken: null,
    role: null,
    userGUID: null,
  });
  return userFactory({
    userId,
    chatUser,
    chatToken,
    role,
    userGUID,
  });
}
