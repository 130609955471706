/* @flow */
import React from 'react';
import ROUTES_PATH from 'domain/router/routesPathConfig';
import { generatePath } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import RouterLink from 'components/mui/Router/RouterLink';
import IconSuccess from './icon-success.svg';

function PasswordResetSuccess() {
  return (
    <Box textAlign="center">
      <img src={IconSuccess} alt="icon" />
      <Typography variant="h5" gutterBottom>
        <FormattedMessage id="restore.success.title" defaultMessage="Email was sent!" />
      </Typography>

      <Typography variant="subtitle1" color="text.secondary" mb={2}>
        <FormattedMessage
          id="restore.success.subtitle"
          defaultMessage="We have sent you an email with a temporary link (expiring after 10 minutes) to reset your password. Please check your inbox."
        />
      </Typography>
      <Box>
        <Button
          variant="text"
          size="large"
          href={generatePath(ROUTES_PATH.AUTH_LOGIN.absolute)}
          component={RouterLink}
          startIcon={<KeyboardBackspaceIcon />}
        >
          <FormattedMessage id="button.back" defaultMessage="Back" />
        </Button>
      </Box>
    </Box>
  );
}

export default PasswordResetSuccess;
