// @flow

export const PublishTypes = Object.freeze({
  Publish: 'publish',
  ForcePublish: 'force_publish',
  PublishAndStay: 'publish_and_stay',
  SchedulePublish: 'schedule_publish',
});

const publishConfig = {
  confirmationTitle: {
    id: 'document.show.modals.publish.title',
    defaultMessage: 'Publish Document?',
  },
  confirmationPlaceholder: {
    id: 'document.show.modals.publish.placeholder',
    defaultMessage: 'Are you sure you  want to publish the document?',
  },
  confirmationConfirmText: {
    id: 'document.show.modals.publish.btn',
    defaultMessage: 'Publish',
  },
};

export const actionsList = [
  {
    key: PublishTypes.Publish,
    confirm: publishConfig,
    title: {
      id: 'document.show.btns.publish',
      defaultMessage: 'Publish',
    },
    acceptContext: {
      type: 'common',
      force: false,
      remainOnDocument: false,
    },
  },
  {
    key: PublishTypes.ForcePublish,
    confirm: {
      ...publishConfig,
      confirmationTitle: {
        id: 'document.show.modals.force_publish.title',
        defaultMessage: 'Force Publish Document?',
      },
      confirmationPlaceholder: {
        id: 'document.show.modals.force_publish.placeholder',
        defaultMessage: 'Are you sure you  want to force publish the document?',
      },
      confirmationConfirmText: {
        id: 'document.show.modals.force_publish.btn',
        defaultMessage: 'Force Publish',
      },
    },
    title: {
      id: 'document.show.btns.force_publish',
      defaultMessage: 'Force publish',
    },
    acceptContext: {
      type: 'common',
      force: true,
      remainOnDocument: false,
    },
    confirmClass: 'forceAcceptBtn',
  },
  {
    key: PublishTypes.PublishAndStay,
    confirm: {
      ...publishConfig,
      confirmationConfirmText: {
        id: 'document.show.btns.publish_and_stay',
        defaultMessage: 'Publish and stay',
      },
    },
    title: {
      id: 'document.show.btns.publish_and_stay',
      defaultMessage: 'Publish and stay',
    },
    acceptContext: {
      type: 'common',
      force: false,
      remainOnDocument: true,
    },
  },
  {
    key: PublishTypes.SchedulePublish,
    confirm: {
      ...publishConfig,
      confirmationConfirmText: {
        id: 'document.show.btns.schedule_publish',
        defaultMessage: 'Quick publish',
      },
    },
    title: {
      id: 'document.show.btns.schedule_publish',
      defaultMessage: 'Quick publish',
    },
    acceptContext: {
      type: 'schedule',
      force: false,
      remainOnDocument: false,
    },
  },
];

export default actionsList;
