// @flow
import * as React from 'react';
import { Map, Set, type RecordOf } from 'immutable';
import { compose, type Dispatch } from 'redux';
import { connect } from 'react-redux';
import { withStyles } from '@mui/styles';
import cx from 'classnames';
import { getParamsFromChannelName } from 'sendbird-utils';
import { ChatUserFactory, makeChannelName } from 'domain/chat/helpers';
import { userSelector } from 'domain/env';
import {
  chatThreadSelector,
  createChannelAction,
  chatParticipantsByIdSelector,
  activeChannelSelector,
  setActiveChannelAction,
} from 'domain/chat';
import type { ChatUser, TMessageRecord } from 'domain/chat/types.js.flow';
import type { CurrentUserType } from 'domain/env/types.js.flow';
import Title from './title';
import Messages from './messages/documentMessages';
import MessageThread from './thread';
import StatusBar from './ChatStatusAlert';
import sheet from './sheet';
import UserPic from 'components/UserPic/UserPic';
import Search from 'components/Chat__legacy/Search/Search';

type Props = {|
  classes: {
    [key: string]: string,
  },
  // eslint-disable-next-line react/no-unused-prop-types
  threads: Map<string, Set<TMessageRecord>>, // @to-do check if prop is used
  currentUser: CurrentUserType,
  // eslint-disable-next-line react/no-unused-prop-types
  create: Dispatch<typeof createChannelAction>, // @to-do check if prop is used
  companyId: string,
  documentId: string,
  usersById: Map<string, RecordOf<ChatUser>>,
  activeChannel: ?string,
  setActiveChannel: Dispatch<typeof setActiveChannelAction>,
  maxHeight?: number,
|};

type State = {
  searchText: string,
};

class ChatContainer extends React.Component<Props, State> {
  state = {
    searchText: '',
  };

  componentWillUnmount() {
    const { companyId, setActiveChannel } = this.props;
    setActiveChannel({
      threadId: null,
      companyId,
    });
    // remove flag that will be set again in document saga when needed
    // setChatReadyForRender(false);
  }

  onChat = (d: RecordOf<ChatUser>) => {
    const { currentUser, companyId, setActiveChannel, documentId } = this.props;
    setActiveChannel({
      threadId: makeChannelName([currentUser.userId, d.userId], companyId, documentId),
      companyId,
    });
  };

  onChangeSearchText = (searchText: string) => {
    this.setState({ searchText });
  };

  get participant(): RecordOf<ChatUser> {
    const { activeChannel, currentUser, usersById } = this.props;
    if (typeof activeChannel === 'string') {
      const { users } = getParamsFromChannelName(activeChannel);
      const participant = users.filter((e) => e !== currentUser.userId)[0];
      if (typeof participant === 'string') {
        return usersById.get(participant, ChatUserFactory());
      }
    }
    return ChatUserFactory();
  }

  get status() {
    return typeof this.props.activeChannel === 'string';
  }

  render() {
    const { classes, currentUser, usersById, companyId, documentId } = this.props;
    return (
      <div className={classes.container}>
        <div
          className={cx(classes.slider, {
            [classes.userListPos]: !this.status,
            [classes.threadPos]: this.status,
          })}
        >
          <div className={classes.screen}>
            <Title user={this.participant.get('username')}>
              <button
                type="button"
                onClick={() => this.props.setActiveChannel({ threadId: null, companyId })}
                className={classes.back}
              />

              <UserPic size={30} src={this.participant.get('picture')} />
            </Title>
            <StatusBar />
            <MessageThread threadId={this.props.activeChannel} params={{ companyId }} />
          </div>
          <div className={classes.screen}>
            <Title user={currentUser.chatUser}>
              <UserPic size={30} src={usersById.getIn([currentUser.userId, 'picture'])} />
            </Title>
            <StatusBar />
            <Search searchText={this.state.searchText} onChangeSearchText={this.onChangeSearchText} />
            <Messages
              onChat={this.onChat}
              searchText={this.state.searchText}
              currentUserID={currentUser.userId}
              companyId={companyId}
              documentId={documentId}
            />
          </div>
        </div>
      </div>
    );
  }
}

ChatContainer.displayName = 'ChatContainer';

const mapStateToProps = (state) => ({
  activeChannel: activeChannelSelector(state),
  threads: chatThreadSelector(state),
  currentUser: userSelector(state),
  usersById: chatParticipantsByIdSelector(state),
});

const mapDispatchToProps = {
  setActiveChannel: setActiveChannelAction,
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(sheet))(ChatContainer);
