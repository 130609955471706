// @flow
import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';

function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    const params = useParams();
    const navigate = useNavigate();
    return <Component {...props} match={{ params }} navigate={navigate} />;
  }

  return ComponentWithRouterProp;
}

export default withRouter;
