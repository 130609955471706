// @flow
import React from 'react';
import { List } from 'immutable';

import Stack from '@mui/material/Stack';

import KeyItem from './components/KeyItem';

import { type ApiKeyType } from 'domain/organization/types.js.flow';

type TKeysList = {
  apiKeys: List<ApiKeyType>,
  handleDeleteApiKeyClick: (apiKey: string) => () => Promise<void>,
  handleEditClick: (apiKey: string) => () => void,
  dateFormat: string,
}

const KeysList: React$StatelessFunctionalComponent<TKeysList> = ({ apiKeys,handleDeleteApiKeyClick, handleEditClick, dateFormat }) => (
  <Stack spacing={4} p={3}>
    {apiKeys.map((apiKey) => (
      <KeyItem key={apiKey.api_key} apiKey={apiKey} handleDeleteApiKeyClick={handleDeleteApiKeyClick} handleEditClick={handleEditClick} dateFormat={dateFormat} />
    ))}
  </Stack>
);

export default KeysList;
