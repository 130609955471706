// @flow
import React, { memo, useCallback, useMemo } from 'react';
import { OrderedSet } from 'immutable';
import { useSelector, useDispatch } from 'react-redux';
import { getDocumentsForBulkApprove, documentsBulkApproveAction } from 'domain/documents';
import { promisify } from 'lib/helpers';
import { useIntl } from 'react-intl';
import { useConfirm } from 'material-ui-confirm';

import Tooltip from 'components/mui/Tooltip/TooltipForButton';
import toast from 'components/Toast';
import IconButton from '@mui/material/IconButton';

import PlaylistAddCheckOutlinedIcon from '@mui/icons-material/PlaylistAddCheckOutlined';

export type TBulkApprove = {
  selectedIds: OrderedSet<string>,
  clearSelection: () => void,
};

const BulkApproveButton: React$StatelessFunctionalComponent<TBulkApprove> = ({ selectedIds, clearSelection }) => {
  const { formatMessage } = useIntl();
  const documentsForBulkApprove = useSelector(getDocumentsForBulkApprove(selectedIds));
  const dispatch = useDispatch();
  const confirm = useConfirm();

  const availableCount = documentsForBulkApprove.size;
  const isDisabledButton = availableCount === 0;
  const documentsID = useMemo(() => documentsForBulkApprove.keySeq().toArray(), [documentsForBulkApprove]);

  const handleBulkApprove = useCallback(async () => {
    const fn = (arg) => dispatch(documentsBulkApproveAction({ ...arg }));
    const { success } = await promisify(fn, { data: { documentID: documentsID } });
    toast.success(
      formatMessage(
        {
          id: 'toast.bulkApprove',
          defaultMessage: `${success.length} of ${availableCount} were scheduled for approval`,
        },
        {
          success: success.length,
          total: availableCount,
        },
      ),
    );
    clearSelection();
  }, [documentsID, clearSelection, availableCount, dispatch, formatMessage]);

  const handleClick = useCallback(() => {
    confirm({
      title: formatMessage({
        id: 'confirm.selectPanel.bulkApprove.title',
        defaultMessage: 'Approve documents?',
      }),
      description: formatMessage(
        {
          id: 'confirm.selectPanel.bulkApprove.placeholder',
          defaultMessage: `${availableCount} of ${selectedIds.size} documents will be scheduled for Approval. \n It may take a while.`,
        },
        {
          total: selectedIds.size,
          available: availableCount,
        },
      ),
      confirmationText: formatMessage({
        id: 'confirm.selectPanel.bulkApprove.btn',
        defaultMessage: 'Approve',
      }),
    }).then(() => {
      handleBulkApprove();
    });
  }, [handleBulkApprove, confirm, formatMessage, availableCount, selectedIds.size]);

  return (
    <Tooltip t={{ id: 'tooltip.selectedPanel.bulkApprove', defaultMessage: 'Approve documents' }} placement="top">
      <IconButton aria-label="approve" color="primary" onClick={handleClick} disabled={isDisabledButton}>
        <PlaylistAddCheckOutlinedIcon />
      </IconButton>
    </Tooltip>
  );
};

export default memo(BulkApproveButton);
