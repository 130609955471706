// @flow
import React from 'react';

// components
import Header, { type Props } from './Header';

// redux
import { useSelector } from 'react-redux';
import * as ACL from 'domain/restriction';

// helpers
import scriptLoader from 'react-async-script-loader';

const HeaderWithFreshdesk = scriptLoader([
  `https://widget.freshworks.com/widgets/${process.env.REACT_APP_FRESHDESK_WIDGET_ID}.js`,
])(Header);

export default (props: Props) => {
  const isGranted = useSelector(ACL.isGranted);

  return isGranted(ACL.ALLOW_FRESHDESK) ? (
    <HeaderWithFreshdesk allowFreshdesk {...props} />
  ) : (
    <Header allowFreshdesk={false} {...props} />
  );
};
