// @flow
import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { activeChannelSelector, chatParticipantsByIdSelector, setActiveChannelAction } from 'domain/chat';
import { userIdSelector } from 'domain/env';
import { getParamsFromChannelName } from 'sendbird-utils';
import { ChatUserFactory } from 'domain/chat/helpers';

import MessagesThread from 'components/mui/Layouts/components/Chat/components/MessagesThread';
import { ChatDrawer } from 'components/mui/Layouts/components/Chat/components/StyledComponents';
import Avatar from 'components/mui/Avatar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import AppBar from '@mui/material/AppBar';
import Stack from '@mui/material/Stack';

import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';

type TChatMessagesDrawer = {
  handleCloseChat: () => void,
};

const mapStateToProps = (state) => ({
  activeChannel: activeChannelSelector(state),
  currentUserID: userIdSelector(state),
  usersByID: chatParticipantsByIdSelector(state),
});

const ChatMessagesDrawer: React$StatelessFunctionalComponent<TChatMessagesDrawer> = ({
  handleCloseChat,
  variant = 'temporary',
  PaperProps,
  BackdropProps,
  ModalProps,
}) => {
  const dispatch = useDispatch();
  const { activeChannel, currentUserID, usersByID } = useSelector(mapStateToProps);
  const { companyId } = useParams();
  const isOpen = typeof activeChannel === 'string';

  const participant = useMemo(() => {
    if (typeof activeChannel === 'string') {
      const { users } = getParamsFromChannelName(activeChannel);
      const user = users.filter((e) => e !== currentUserID)[0];

      if (typeof user === 'string') {
        return usersByID.get(user, ChatUserFactory());
      }
    }

    return ChatUserFactory();
  }, [activeChannel, usersByID, currentUserID]);

  const onClickBack = () => {
    dispatch(dispatch(setActiveChannelAction({ threadId: null, companyId })));
  };

  return (
    <ChatDrawer
      anchor="right"
      variant={variant}
      open={isOpen}
      PaperProps={PaperProps}
      BackdropProps={{ style: { backgroundColor: 'transparent' }, ...BackdropProps }}
      ModalProps={ModalProps}
      onClose={handleCloseChat}
    >
      <AppBar position="static" color="transparent" sx={{ zIndex: 2 }}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={onClickBack} sx={{ mr: 2 }}>
            <ArrowBackOutlinedIcon />
          </IconButton>

          <Stack spacing={2} direction="row" alignItems="center">
            <Avatar src={participant.get('picture')} />
            <Typography variant="h6">{participant.get('username')}</Typography>
          </Stack>

          <IconButton edge="end" onClick={handleCloseChat} color="inherit" sx={{ ml: 'auto' }}>
            <CloseOutlinedIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <MessagesThread threadId={activeChannel} params={{ companyId }} />
    </ChatDrawer>
  );
};

export default ChatMessagesDrawer;
