// @flow
import React, { memo } from 'react';
import { FormattedMessage } from 'react-intl';

import { PaidBadgeStyled } from 'pages/company/DocumentPreview/components/StyledComponents';

const PaidBadge = () => (
  <PaidBadgeStyled>
    <FormattedMessage id="documents.item.badge.paid" defaultMessage="Paid" />
  </PaidBadgeStyled>
);

export default memo(PaidBadge);
