// @flow
import * as React from 'react';
import { is } from 'immutable';
import moment from 'moment';
import type { TMessageRecord } from 'domain/chat/types.js.flow';

type Props = {|
  classes: {
    [key: string]: string,
  },
  data: TMessageRecord,
  ownerId: string,
|};

class MessageItem extends React.Component<Props> {
  shouldComponentUpdate(prevProps: Props) {
    if (!is(prevProps.data, this.props.data)) return true;
    return false;
  }

  get toHumanDate(): string {
    return moment(this.props.data.createdAt).format('hh:mm A');
  }

  render() {
    const { data, ownerId, classes } = this.props;
    return ownerId === data.sender.userId ? (
      <div className={classes.ownMessage}>
        <div>{data.message}</div>
        <div>{this.toHumanDate}</div>
      </div>
    ) : (
      <div className={classes.messageContainer}>
        <div className={classes.foreignMessage}>
          <div>{data.message}</div>
          <div>{this.toHumanDate}</div>
        </div>
      </div>
    );
  }
}

export default MessageItem;
