// @flow
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { organizationDomainSelector } from 'domain/organization';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import Tooltip from 'components/mui/Tooltip';

import type { EnhancedUser } from 'domain/organizationUser/types.js.flow';

type TProps = {
  user: EnhancedUser,
};

const EmailCell: React$StatelessFunctionalComponent<TProps> = ({ user }) => {
  const domains = useSelector(organizationDomainSelector);

  const isShowTooltip = useMemo(
    () => domains && !domains.split(',').some((domain) => user.email.endsWith(domain)),
    [domains, user.email],
  );

  return (
    <Stack direction="row" alignItems="center" spacing={0.5}>
      <Typography variant="body1">{user.email}</Typography>
      {isShowTooltip && (
        <Tooltip
          t={{
            id: 'configurations.company.users.tooltip.domain',
            defaultMessage: 'User/Email is not a part of organization domain',
          }}
        >
          <ErrorOutlineIcon color="error" />
        </Tooltip>
      )}
    </Stack>
  );
};

export default EmailCell;
