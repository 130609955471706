// @flow
import React, { useEffect, useState } from 'react';

// redux
import { useSelector } from 'react-redux';

// selectors
import { rtlEnable } from 'domain/env/envSelector';

// components
import { Carousel } from 'react-responsive-carousel';

// images
import Files from 'pages/authPage/Layout/AsideCarousel/images/Files.svg';
import Chat from 'pages/authPage/Layout/AsideCarousel/images/Chat.svg';
import Automation from 'pages/authPage/Layout/AsideCarousel/images/Automation.png';
import Reports from 'pages/authPage/Layout/AsideCarousel/images/Reports.svg';
import Approvals from 'pages/authPage/Layout/AsideCarousel/images/Approvals.svg';
import FilesHE from 'pages/authPage/Layout/AsideCarousel/images/FilesHE.svg';
import ChatHE from 'pages/authPage/Layout/AsideCarousel/images/ChatHE.svg';
import AutomationHE from 'pages/authPage/Layout/AsideCarousel/images/AutomationHE.png';
import ReportsHE from 'pages/authPage/Layout/AsideCarousel/images/ReportsHE.svg';
import ApprovalsHE from 'pages/authPage/Layout/AsideCarousel/images/ApprovalsHE.svg';

// styles
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import useStyles from 'pages/authPage/Layout/AsideCarousel/sheet';

const carouselItems = [
  {
    default: {
      src: Automation,
      title: 'Accounting Automation',
      subTitle: 'AI-powered transaction recording',
      styles: {},
    },
    he: {
      src: AutomationHE,
      title: 'אוטומציה של הנה״ח',
      subTitle: '!הליך קליטת והזנת החשבוניות אוטומטי',
      styles: {},
    },
  },
  {
    default: {
      src: Files,
      title: 'Digital Archive',
      subTitle: 'Go paperless!',
      styles: {
        marginTop: 68,
      },
    },
    he: {
      src: FilesHE,
      title: 'ארכיון דיגיטלי',
      subTitle: 'מהיום אין צורך בקלסרים וניירת',
      styles: {
        marginTop: 68,
      },
    },
  },
  {
    default: {
      src: Chat,
      title: 'Collaboration',
      subTitle: 'One place for your team',
      styles: {
        marginTop: 35,
        marginRight: 45,
      },
    },
    he: {
      src: ChatHE,
      title: 'תקשורת פנים ארגונית',
      subTitle: 'כל אנשי הצוות מחוברים יחד',
      styles: {},
    },
  },
  {
    default: {
      src: Reports,
      title: 'Reports',
      subTitle: 'Smart reports created instantly',
      styles: {
        marginTop: 54,
      },
    },
    he: {
      src: ReportsHE,
      title: 'דו״חות',
      subTitle: 'מנוע חכם להנפקת דוחות',
      styles: {
        marginTop: 54,
      },
    },
  },
  {
    default: {
      src: Approvals,
      title: 'Approval Flow',
      subTitle: 'Flexible and intuitive approvals',
      styles: {
        marginTop: 46,
      },
    },
    he: {
      src: ApprovalsHE,
      title: 'מערכת אישורים',
      subTitle: 'אישורי הזמנות, משלוחים ואישורי לפני תשלום',
      styles: {
        marginTop: 46,
      },
    },
  },
];

const AsideCarousel = () => {
  const isRtl = useSelector(rtlEnable);

  const classes = useStyles({ isRtl });

  const [isAutoPlay, setIsAutoPlay] = useState(true);

  const changeAutoPlay = () => {
    setIsAutoPlay(document.visibilityState === 'visible');
  };

  const onClickItem = () => {
    window.open(`https://dokka.com/${isRtl ? 'he/' : ''}`, '_blank');
  };

  useEffect(() => {
    window.addEventListener('visibilitychange', changeAutoPlay);
    return () => {
      window.removeEventListener('visibilitychange', changeAutoPlay);
    };
  }, []);

  return (
    <Carousel
      autoPlay={isAutoPlay}
      infiniteLoop
      showThumbs={false}
      showStatus={false}
      showArrows={false}
      transitionTime={1000}
      interval={4000}
      className={classes.asideCarousel}
      onClickItem={onClickItem}
    >
      {carouselItems.map((data) => {
        const { src, title, subTitle, styles } = isRtl ? data.he : data.default;
        return (
          <div key={title} className={classes.item} style={styles}>
            <img src={src} alt={title} />
            <p className="legend">
              {title}
              <span className={classes.subtitle}>{subTitle}</span>
            </p>
          </div>
        );
      })}
    </Carousel>
  );
};

export default AsideCarousel;
