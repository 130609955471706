/* @flow */
import { makeStyles } from '@mui/styles';
import chromeIcon from 'pages/common/chromeIcon.svg';
import attentionIcon from 'pages/common/attentionIcon.svg';

export default makeStyles({
  chromeIcon: {
    width: 30,
    height: 30,
    position: 'relative',
    background: `url("${chromeIcon}") center no-repeat`,
  },

  attentionIcon: {
    background: `url(${attentionIcon}) center no-repeat`,
    width: 25,
    height: 20,
    top: 0,
    left: 0,
    position: 'absolute',
    transform: 'translate(20px, 0px)',
  },
});
