// @flow
import { makeStyles } from '@mui/styles';

export default makeStyles(() => ({
  hide: {
    opacity: 0,
    pointerEvents: 'none',
    transition: 'opacity 50ms',
  },
}));
