/* @flow */
import { Record, Map } from 'immutable';
import type { ERPConnectionsType } from 'domain/contracts';
import type { ERPItemType, DetailsFactoryType, ERPFactoryType } from './types.js.flow';

const ERPDetailsFactory: DetailsFactoryType = new Record({
  company_name: '',
});

export const ERPFactory: ERPFactoryType = new Record({
  status: 'disconnected',
  details: ERPDetailsFactory(),
});

function erpItemAdapter({ details, status }): ERPItemType {
  return ERPFactory({
    details: ERPDetailsFactory(details),
    status,
  });
}

export function erpsAdapter(data: ERPConnectionsType) {
  return Object.entries(data) // $FlowFixMe
    .reduce((a, v) => a.set(v[0], erpItemAdapter(v[1])), new Map());
}
