// @flow
import React, { memo } from 'react';

import Paper from '@mui/material/Paper';

type TAuthPaperBox = {
  children: React$Node,
};

const AuthPaperBox: React$StatelessFunctionalComponent<TAuthPaperBox> = ({ children }: TAuthPaperBox) => (
  <Paper elevation={16} sx={{ p: 3.5, borderRadius: 4 }}>
    {children}
  </Paper>
);

export default memo(AuthPaperBox);
