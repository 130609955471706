// @flow
import React, { useCallback, useEffect, useState, Suspense, lazy } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { rtlEnable } from 'domain/env';
import useDidUpdateEffect from 'hooks/useDidUpdateEffect';
import useResize from 'pages/company/LinkedDocumentsPanel/useResize';
import { storage } from 'lib/storage';

import Typography from '@mui/material/Typography';
import LinearProgress from '@mui/material/LinearProgress';
import { JEFakePane, ToggleButton, WidgetContent } from 'pages/document/Body/JE/InsightsWidget/StyledComponents';

import DragHandleOutlinedIcon from '@mui/icons-material/DragHandleOutlined';

const JEInsights = lazy(() => import('pages/document/Body/JE/JEInsights'));

type Props = {|
  updateContentWidth: () => void,
  onViewResizeEnd?: () => void,
  onViewResizeStart?: () => void,
  onViewResize?: (event: any) => void,
|};

const InsightsWidget: React$StatelessFunctionalComponent<Props> = ({
  updateContentWidth,
  onViewResizeStart,
  onViewResize,
  onViewResizeEnd,
}) => {
  const { formatMessage } = useIntl();
  const insightsIsActive = JSON.parse(storage().insightsIsActive().get());
  const insightsWidthFromLS = JSON.parse(storage().insightsWidgetWidth().get());

  const [isActive, setIsActive] = useState(insightsIsActive);
  const [isResizing, setIsResizing] = useState(false);

  const isRtl = useSelector(rtlEnable);

  const onResizeMouseUp = useCallback(
    (width) => {
      storage().insightsWidgetWidth().set(width);

      if (typeof onViewResizeEnd === 'function') {
        onViewResizeEnd();
      }
    },
    [onViewResizeEnd],
  );

  const { handleMouseDown: onResizeMouseDown, width } = useResize({
    current: insightsWidthFromLS || window.innerWidth * 0.5,
    min: window.innerWidth * 0.12,
    max: window.innerWidth * 0.75,
    isRtl,
    onMouseUp: onResizeMouseUp,
    onChange: onViewResize,
  });

  const handleResizeMouseDown = useCallback(
    (e) => {
      if (isActive) {
        setIsResizing(true);
        onResizeMouseDown(e);

        if (typeof onViewResizeStart === 'function') {
          onViewResizeStart();
        }
      }
    },
    [isActive, onResizeMouseDown, onViewResizeStart],
  );

  const handleClick = useCallback(() => {
    if (!isResizing) {
      setIsActive(!isActive);
    }
    setIsResizing(false);
  }, [isResizing, isActive]);

  useDidUpdateEffect(() => {
    storage().insightsIsActive().set(isActive);
  }, [isActive]);

  useEffect(() => {
    document.addEventListener('transitionend', updateContentWidth);
    return () => {
      document.removeEventListener('transitionend', updateContentWidth);
    };
  }, [updateContentWidth]);

  return (
    <JEFakePane style={{ width: isActive ? width : 48, transition: `width ${isResizing ? 0 : 250}ms` }}>
      <ToggleButton onClick={handleClick}>
        <div style={{ transform: `rotate(${isRtl ? '-90' : '90'}deg)` }}>
          <Typography variant="subtitle2" color="primary" textTransform="uppercase">
            {formatMessage({ id: 'document.je.tabs.insights', defaultMessage: 'Insights' })}
          </Typography>
          <DragHandleOutlinedIcon
            color="disabled"
            cursor={isActive ? 'col-resize' : 'inherit'}
            onMouseDown={handleResizeMouseDown}
            sx={{ mb: -1.5 }}
          />
        </div>
      </ToggleButton>
      {isActive && (
        <WidgetContent>
          <Suspense fallback={<LinearProgress />}>
            <JEInsights />
          </Suspense>
        </WidgetContent>
      )}
    </JEFakePane>
  );
};

export default InsightsWidget;
