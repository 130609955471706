/* @flow */
import * as React from 'react';
import { withStyles } from '@mui/styles';
import cx from 'classnames';
import arrow from './arrow.svg';
import elements from 'components/elements';

const sheet = {
  container: {
    display: 'flex',
    width: '100%',
    height: 30,
    backgroundColor: '#8C9BA5',
    color: '#fff',
    fontSize: 12,
    alignItems: 'center',
    padding: [0, 12],
    borderRadius: 4,
    justifyContent: 'space-between',
    '.accordion__container_open &': {
      borderRadius: [4, 4, 0, 0],
    },
  },
  indicator: {
    flex: [0, 0, '90px'],
    whiteSpace: 'nowrap',
    paddingLeft: 16,
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    borderLeft: '1px solid #fff',
    width: 90,
    position: 'relative',
    justifyContent: 'space-between',
    '&:after': {
      content: '""',
      width: 24,
      height: 24,
      display: 'inline-block',
      background: `url(${arrow}) scroll no-repeat`,
      transition: '0.3s transform',
      verticalAlign: 'middle',
    },
  },
  up: {
    '&:after': {
      transform: 'rotate(180deg)',
    },
  },
  title: {
    minWidth: 0,
    flex: [1, 1],
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    marginRight: 40,
    textAlign: 'left',
  },
};

type Props = {
  classes: {|
    [key: string]: string,
  |},
  isUP: boolean,
  title: string,
};

function Title({ classes, isUP, title }: Props) {
  return (
    <div className={classes.container}>
      <span className={classes.title}>{title}</span>
      <span
        data-element={elements.je.request.transaction.showAll}
        className={cx(classes.indicator, { [classes.up]: isUP })}
      >
        {isUP ? 'Hide All' : 'Show All'}
      </span>
    </div>
  );
}

export default withStyles(sheet)(Title);
