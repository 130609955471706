import { styled } from '@mui/material/styles';

export const LineGridPanel = styled('div', {
  label: 'LineGridPanel',
})(() => ({
  fontSize: 12,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  paddingLeft: 33,
  marginTop: 30,
  minHeight: 15,
}));
