import React from 'react';
import { List } from 'immutable';
import ImageItem from '../ImageItem';

import { type ImageRecordList } from '../../type.js.flow';

type Props = {|
  list: ImageRecordList,
  selectedList: List<number>,
  itemClass: string,
  listClass: string,
  onMouseDown: (event: MouseEvent) => void,
  showImageInPopup: (index: number) => void,
|};

const ImageViewer: React$StatelessFunctionalComponent<Props> = ({
  list,
  itemClass,
  onMouseDown,
  selectedList,
  showImageInPopup,
}) => (
  <>
    {list.map((image) => (
      <ImageItem
        key={image.initialIndex}
        image={image}
        className={itemClass}
        onMouseDown={onMouseDown}
        showImageInPopup={showImageInPopup}
        selected={selectedList.contains(image.initialIndex)}
      />
    ))}
  </>
);

export default ImageViewer;
