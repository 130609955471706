import plusIcon from './icons/plusIcon.svg';
import minusIcon from './icons/minusIcon.svg';
import closeIcon from './icons/closeIcon.svg';

const minusButtonColor = '#5A6872';
const plusButtonColor = '#139D29';

export default {
  grid: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 2,
  },
  overflowHidden: {
    overflow: 'hidden',
  },
  hiddenGrid: {
    display: 'none',
  },
  button: {
    zIndex: 3,
    position: 'absolute',
    width: 18,
    height: 18,
    borderRadius: 2,
    cursor: 'pointer',
    transition: 'transform 0.3s',
    willChange: 'transform',
    outline: 'none',
    border: 'none',
    '&:hover': {
      transform: 'scale(1.1)',
    },

    '&[disabled]': {
      cursor: 'not-allowed',
    },
  },
  addButton: {
    background: `url("${plusIcon}") center no-repeat`,
    borderColor: plusButtonColor,
  },
  removeButton: {
    background: `url("${minusIcon}") center no-repeat`,
    borderColor: minusButtonColor,
  },
  closeButton: {
    background: `url("${closeIcon}") center no-repeat`,
    border: 'none',
  },
};
