// @flow
import React, { memo, useMemo } from 'react';
import { Document, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';

pdfjs.GlobalWorkerOptions.workerSrc = new URL('pdfjs-dist/build/pdf.worker.min.js', import.meta.url).toString();
// CDN WORKER
// pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const PdfViewerDocument = ({ children, ...rest }) => {
  // wrap memo fn !important else we have rerender
  const options = useMemo(() => ({ cMapUrl: `https://unpkg.com/pdfjs-dist@${pdfjs.version}/cmaps/` }), []);

  return (
    <Document options={options} {...rest}>
      {children}
    </Document>
  );
};

export default memo(PdfViewerDocument);
