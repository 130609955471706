// @flow
import React, { memo } from 'react';

import { TILE_Z_INDEXES } from 'pages/company/TileGrid/TileCard/components/StyledComponents';
import Typography from '@mui/material/Typography';
import ButtonBase from '@mui/material/ButtonBase';

import AttachFileIcon from '@mui/icons-material/AttachFile';

import { styled } from '@mui/material/styles';

type TLinkedBadge = {
  text?: string,
  onClick: () => void,
};

const Badge = styled(ButtonBase, { label: 'TileLinkedBadge' })(({ theme }) => ({
  display: 'inline-flex',
  position: 'absolute',
  top: 0,
  left: theme.spacing(1),
  right: 'auto',
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  padding: theme.spacing(1, 0.5, 0.5),
  fontSize: theme.typography.pxToRem(12),
  borderRadius: '0 0 4px 4px',
  zIndex: TILE_Z_INDEXES.linkedBadge,
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  textAlign: 'left',

  '& .MuiTypography-root': {
    display: 'none',
  },

  '& .MuiSvgIcon-root': {
    marginBottom: theme.spacing(0.5),
  },

  '&:hover': {
    boxShadow: theme.shadows[3],
    right: theme.spacing(1),

    '& .MuiTypography-root': {
      display: '-webkit-box',
    },
  },
}));

const Text = styled(Typography)(({ theme }) => ({
  marginLeft: theme.spacing(0.5),
  marginRight: theme.spacing(0.5),
  whiteSpace: 'pre-wrap',
  overflow: 'hidden',
  fontSize: theme.typography.pxToRem(14),
  lineHeight: 1.2,
  minHeight: theme.typography.pxToRem(14 * 1.2 * 4),
  // https://caniuse.com/css-line-clamp
  display: '-webkit-box',
  WebkitLineClamp: 4,
  WebkitBoxOrient: 'vertical',
}));

const LinkedBadge: React$StatelessFunctionalComponent<TLinkedBadge> = ({ text, onClick }) => (
  <Badge onClick={onClick}>
    <AttachFileIcon />
    {text && <Text variant="body2">{text}</Text>}
  </Badge>
);

export default memo(LinkedBadge);
