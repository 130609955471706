/* @flow */
import { type ActionMessagesProps } from './RowMessage';

import error from './images/error.svg';
import info from './images/info.svg';
import warning from './images/warning.svg';

const images = { error, info, warning };

const typeColors = {
  error: 'red',
  info: 'green',
  warning: '#F47F2A',
};

export default {
  box: {
    position: 'absolute',
    width: 26,
    height: 26,
    background: ({ type }: ActionMessagesProps) => `url(${images[type]}) 50% 50% no-repeat`,
    zIndex: 10,
    right: 22,
    top: -1,
    '&:hover $tooltipBox': {
      display: 'flex',
    },
  },

  tooltipBox: {
    display: 'none',
    flexDirection: 'row-reverse',
    alignItems: 'center',
    position: 'absolute',
    bottom: '50%',
    right: 30,
    maxHeight: 1,
    minWidth: '80vw',
    zIndex: 99,
  },

  tooltip: {
    padding: 10,
    backgroundColor: ({ type }: ActionMessagesProps) => typeColors[type],
    boxShadow: '0px 12px 24px rgba(0, 0, 0, 0.1)',
    fontSize: '12px',
    lineHeight: '1.1em',
    border: '0px solid transparent',
    borderRadius: 2,
    color: '#FFF',
    maxWidth: 400,
    '&:after': {
      content: '""',
      position: 'absolute',
      width: 0,
      height: 0,
      right: -12,
      bottom: -6,
      border: '6px solid transparent',
      borderLeftColor: ({ type }: ActionMessagesProps) => typeColors[type],
    },
  },
};
