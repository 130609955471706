// @flow
import React from 'react';
import { useSelector } from 'react-redux';
import { organizationFilteredListSelector, organizationLoadedSelector } from 'domain/organization';
import { loadingSelector, sagaDataLoadingSelector } from 'domain/env';

import Box from '@mui/material/Box';
import GridCard from './Card/GridCard';
import NotFound from '../NotFound';

const mapStateToProps = (state) => ({
  list: organizationFilteredListSelector(state),
  isSagaDataLoading: sagaDataLoadingSelector(state),
  isLoading: loadingSelector(state),
  loaded: organizationLoadedSelector(state),
});

const OrganizationsGrid = () => {
  const { list, isLoading, isSagaDataLoading, loaded } = useSelector(mapStateToProps);

  return list.size === 0 && !isLoading && !isSagaDataLoading && loaded ? (
    <NotFound />
  ) : (
    <Box display="grid" gridTemplateColumns="repeat(auto-fill, minmax(170px, 1fr))" gap={2} my={2}>
      {list.map((entry) => (
        <GridCard key={entry.get('id')} entry={entry} />
      ))}
    </Box>
  );
};

export default OrganizationsGrid;
