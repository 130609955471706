/* eslint-disable camelcase */
// @flow
import React from 'react';
import { compose } from 'redux';
import { withStyles } from '@mui/styles';
import { type IntlShape, injectIntl } from 'react-intl';
import elements from 'components/elements';
import sheet from './sheet';
import { connect } from 'react-redux';
import { searchSortSelector } from 'domain/companies/companiesSelector';

type Props = {
  classes: {
    [key: string]: string,
  },
  onChange: (e: SyntheticInputEvent<HTMLInputElement>) => void,
  intl: IntlShape,
  value: string,
  sortedByType: string,
};

type State = {
  value: string,
};

class SearchBar extends React.Component<Props, State> {
  state = { value: '' };

  componentDidMount() {
    this.focusTextInput();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { sortedByType } = this.props;
    // clear input bount to state.value once we dispatched
    // action that cleared search term in store

    // after react update this is trigered if pros didnt change
    // so extra condition was added
    if (nextProps.value === '' && this.props.value !== '') this.setState({ value: '' });
    if (sortedByType !== nextProps.sortedByType) this.focusTextInput();
  }

  onInputChange = (e: SyntheticInputEvent<HTMLInputElement>): void => {
    this.setState({ value: e.target.value });
    this.props.onChange(e);
  };

  setSearchInputRef = (element: ?HTMLInputElement): void => {
    this.searchInput = element;
  };

  focusTextInput = () => {
    if (this.searchInput) this.searchInput.focus();
  };

  searchInput: ?HTMLInputElement;

  render() {
    const { classes, intl } = this.props;
    return (
      <div className={classes.search}>
        <input
          ref={this.setSearchInputRef}
          value={this.state.value}
          type="text"
          name="search"
          className={classes.field}
          onChange={this.onInputChange}
          placeholder={intl.formatMessage({ id: 'header.search.palceholder', defaultMessage: 'Search' })}
          data-element={elements.header.search}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  sortedByType: searchSortSelector(state),
});

export default compose(injectIntl, withStyles(sheet), connect(mapStateToProps))(SearchBar);
