// @flow

import { makeDictionary, mergeMask, check, createPolicies, arrayFilter, advancedRights, arrayMap } from 'lib/policy';
import type { Dictionary, PoliciesType, PolycyParams } from 'lib/policy/types.js.flow';
import { selector } from 'lib/selectors';
import * as env from 'domain/env/envSelector';
import * as companies from 'domain/companies/companiesSelector';
import * as documents from 'domain/documents/documentSelector';
import CONST from 'domain/documents/constants';

import { ROLES } from 'domain/env/helpers';
import type { DokkaStore } from './types.js.flow';

const RIGHTS = {
  IS_AUTHORIZED: 'IS_AUTHORIZED',
  IS_NOT_AUTHORIZED: 'IS_NOT_AUTHORIZED',
  IS_ACCOUNT: 'IS_ACCOUNT',
  IS_LIMITED_ACCOUNT: 'IS_LIMITED_ACCOUNT',
  IS_RESTRICTED_ACCOUNTANT: 'IS_RESTRICTED_ACCOUNTANT',
  IS_READ_ONLY_ACCOUNTANT: 'IS_READ_ONLY_ACCOUNTANT',
  IS_USER: 'IS_USER',
  IS_NOT_USER: 'IS_NOT_USER',
  IS_NOT_CONFIDENTIAL_USER: 'IS_NOT_CONFIDENTIAL_USER',
  IS_CONFIDENTIAL_USER: 'IS_CONFIDENTIAL_USER',
  IS_RESTRICTED_USER: 'IS_RESTRICTED_USER',
  IS_NOT_RESTRICTED_USER: 'IS_NOT_RESTRICTED_USER',
  IS_CONFIDENTIAL_RESTRICTED_USER: 'IS_CONFIDENTIAL_RESTRICTED_USER',
  IS_NOT_CONFIDENTIAL_RESTRICTED_USER: 'IS_NOT_CONFIDENTIAL_RESTRICTED_USER',
  IS_SUPPLIER_USER: 'IS_SUPPLIER_USER',
  IS_NOT_SUPPLIER_USER: 'IS_NOT_SUPPLIER_USER',
  IS_COMPANY_SUPPORT_GENERAL_DOC_ONLY: 'IS_COMPANY_SUPPORT_GENERAL_DOC_ONLY',
  IS_COMPANY_SUPPORT_FINANCIAL_DOC: 'IS_COMPANY_SUPPORT_FINANCIAL_DOC',
  IS_ADMIN: 'IS_ADMIN',
  IS_NOT_ADMIN: 'IS_NOT_ADMIN',
  IS_EXPIRED: 'IS_EXPIRED',
  IS_CURRENT_DOCUMENT_FINANCIAL: 'IS_CURRENT_DOCUMENT_FINANCIAL',
  IS_ACCEPTED_DOCUMENT: 'IS_ACCEPTED_DOCUMENT',
  IS_NOT_ACCEPTED_DOCUMENT: 'IS_NOT_ACCEPTED_DOCUMENT',
  IS_DOCUMENT_BEING_PROCESSED: 'IS_DOCUMENT_BEING_PROCESSED',
  IS_NOT_DOCUMENT_BEING_PROCESSED: 'IS_NOT_DOCUMENT_BEING_PROCESSED',
  IS_DOCUMENT_SCHEDULED_ACCEPTANCE: 'IS_DOCUMENT_SCHEDULED_ACCEPTANCE',
  ALLOW_SUPPLIER_FORM: 'ALLOW_SUPPLIER_FORM',
  ALLOW_FRESHDESK: 'ALLOW_FRESHDESK',
  ALLOW_APPROVALS_GROUPS: 'ALLOW_APPROVALS_GROUPS',
};

type DictionaryRules = $Keys<typeof RIGHTS>;

const dictionary: Dictionary<DictionaryRules> = makeDictionary(Object.keys(RIGHTS));

export const myRestriction: (store: DokkaStore) => number = selector(
  env.roleSelector,
  env.roleModificatorsSelector,
  companies.doesCurrentCompanySupportGeneralDocsOnlySelector,
  env.isAdminSelector,
  env.isExpiredSelector,
  documents.documentSelector,
  companies.companyFeatureSetSelector,
  env.isAllowFreshdeskSelector,
  (
    e,
    roleModificators,
    dccsgdo: boolean,
    isAdmin: boolean,
    isExpired: boolean,
    currentDocument,
    companyFeatures,
    allowFreshdesk,
  ) =>
    mergeMask(dictionary)(
      // eslint-disable-line max-len
      []
        .concat([e ? RIGHTS.IS_AUTHORIZED : RIGHTS.IS_NOT_AUTHORIZED])
        .concat(e === ROLES.accountant ? [RIGHTS.IS_ACCOUNT] : [])
        .concat(e === ROLES.accountant && roleModificators.limited ? [RIGHTS.IS_LIMITED_ACCOUNT] : [])
        .concat(e === ROLES.accountant && roleModificators.restricted ? [RIGHTS.IS_RESTRICTED_ACCOUNTANT] : [])
        .concat(e === ROLES.accountant && roleModificators.readonly ? [RIGHTS.IS_READ_ONLY_ACCOUNTANT] : [])
        .concat(e === ROLES.user ? [RIGHTS.IS_USER] : [RIGHTS.IS_NOT_USER])
        .concat(e === ROLES['confidential-user'] ? [RIGHTS.IS_CONFIDENTIAL_USER] : [RIGHTS.IS_NOT_CONFIDENTIAL_USER]) // eslint-disable-line max-len
        .concat(
          e === ROLES.user && roleModificators.restricted
            ? [RIGHTS.IS_RESTRICTED_USER]
            : [RIGHTS.IS_NOT_RESTRICTED_USER],
        ) // eslint-disable-line max-len
        .concat(
          e === ROLES['confidential-user'] && roleModificators.restricted
            ? [RIGHTS.IS_CONFIDENTIAL_RESTRICTED_USER]
            : [RIGHTS.IS_NOT_CONFIDENTIAL_RESTRICTED_USER],
        ) // eslint-disable-line max-len
        // eslint-disable-next-line max-len
        .concat(e === ROLES.supplier ? [RIGHTS.IS_SUPPLIER_USER] : [RIGHTS.IS_NOT_SUPPLIER_USER])
        .concat([dccsgdo ? RIGHTS.IS_COMPANY_SUPPORT_GENERAL_DOC_ONLY : RIGHTS.IS_COMPANY_SUPPORT_FINANCIAL_DOC]) // eslint-disable-line max-len
        .concat(isAdmin ? [RIGHTS.IS_ADMIN] : [RIGHTS.IS_NOT_ADMIN])
        .concat(isExpired ? [RIGHTS.IS_EXPIRED] : [])
        .concat(currentDocument.get('doctype') === 'financial' ? [RIGHTS.IS_CURRENT_DOCUMENT_FINANCIAL] : []) // eslint-disable-line max-len
        .concat(
          currentDocument.tags.has(CONST.tags.isAccepted)
            ? [RIGHTS.IS_ACCEPTED_DOCUMENT]
            : [RIGHTS.IS_NOT_ACCEPTED_DOCUMENT],
        ) // eslint-disable-line max-len
        .concat(
          currentDocument.tags.has(CONST.tags.isBeingProcessed)
            ? [RIGHTS.IS_DOCUMENT_BEING_PROCESSED]
            : [RIGHTS.IS_NOT_DOCUMENT_BEING_PROCESSED],
        ) // eslint-disable-line max-len
        .concat(
          currentDocument.tags.has(CONST.tags.isScheduledAcceptance) ? [RIGHTS.IS_DOCUMENT_SCHEDULED_ACCEPTANCE] : [],
        ) // eslint-disable-line max-len
        .concat(companyFeatures.supplier_form ? [RIGHTS.ALLOW_SUPPLIER_FORM] : [])
        .concat(companyFeatures.approvals ? [RIGHTS.ALLOW_APPROVALS_GROUPS] : [])
        .concat(allowFreshdesk ? [RIGHTS.ALLOW_FRESHDESK] : []), // this should remain in rights
    ), // eslint-disable-line max-len
);

// eslint-disable-next-line max-len
const policy = (...arr: PolycyParams<DictionaryRules>): PoliciesType => createPolicies(dictionary, arr);

export const isGranted = selector(myRestriction, (restriction) => (lvl: number) => check(lvl, restriction));

export { arrayFilter, makeDictionary, mergeMask, advancedRights, check, arrayMap };

export function f<T>(fn: (d: T) => boolean): (a: Array<T>) => Array<T> {
  return (ar) => ar.filter(fn);
}

export const IS_AUTHORIZED = policy(RIGHTS.IS_AUTHORIZED);
export const IS_AUTHORIZED_AND_NOT_SUPPLIER = policy(
  RIGHTS.IS_USER,
  RIGHTS.IS_RESTRICTED_USER,
  RIGHTS.IS_CONFIDENTIAL_USER,
  RIGHTS.IS_CONFIDENTIAL_RESTRICTED_USER,
  RIGHTS.IS_ACCOUNT,
  RIGHTS.IS_LIMITED_ACCOUNT,
  RIGHTS.IS_RESTRICTED_ACCOUNTANT,
  RIGHTS.IS_READ_ONLY_ACCOUNTANT,
  RIGHTS.IS_ADMIN,
);

export const IS_CONFIDENTIAL_USER = policy(RIGHTS.IS_CONFIDENTIAL_USER, RIGHTS.IS_CONFIDENTIAL_RESTRICTED_USER);
export const IS_RESTRICTED_USER = policy(RIGHTS.IS_RESTRICTED_USER);
export const IS_CONFIDENTIAL_RESTRICTED_USER = policy(RIGHTS.IS_CONFIDENTIAL_RESTRICTED_USER);
export const IS_SUPPLIER_USER = policy(RIGHTS.IS_SUPPLIER_USER);
export const IS_NOT_AUTHORIZED = policy(RIGHTS.IS_NOT_AUTHORIZED, RIGHTS.IS_EXPIRED);
export const IS_ACCOUNT = policy(
  RIGHTS.IS_ACCOUNT,
  RIGHTS.IS_LIMITED_ACCOUNT,
  RIGHTS.IS_RESTRICTED_ACCOUNTANT,
  RIGHTS.IS_READ_ONLY_ACCOUNTANT,
);
export const IS_LIMITED_ACCOUNT = policy(RIGHTS.IS_LIMITED_ACCOUNT);
export const IS_RESTRICTED_ACCOUNTANT = policy(RIGHTS.IS_RESTRICTED_ACCOUNTANT);
export const IS_READ_ONLY_ACCOUNTANT = policy(RIGHTS.IS_READ_ONLY_ACCOUNTANT);
export const IS_USER = policy(
  RIGHTS.IS_USER,
  RIGHTS.IS_RESTRICTED_USER,
  RIGHTS.IS_CONFIDENTIAL_USER,
  RIGHTS.IS_CONFIDENTIAL_RESTRICTED_USER,
  RIGHTS.IS_SUPPLIER_USER,
);
export const IS_CONFIDENTIAL_GRANTED_USER = policy(
  RIGHTS.IS_CONFIDENTIAL_USER,
  RIGHTS.IS_CONFIDENTIAL_RESTRICTED_USER,
  RIGHTS.IS_ACCOUNT,
  RIGHTS.IS_LIMITED_ACCOUNT,
  RIGHTS.IS_RESTRICTED_ACCOUNTANT,
  RIGHTS.IS_READ_ONLY_ACCOUNTANT,
);
// eslint-disable-next-line max-len
export const IS_BOOKKEEPER_IN_FINANCIAL_COMPANY = policy(
  [RIGHTS.IS_ACCOUNT, RIGHTS.IS_COMPANY_SUPPORT_FINANCIAL_DOC],
  [RIGHTS.IS_LIMITED_ACCOUNT, RIGHTS.IS_COMPANY_SUPPORT_FINANCIAL_DOC],
  [RIGHTS.IS_RESTRICTED_ACCOUNTANT, RIGHTS.IS_COMPANY_SUPPORT_FINANCIAL_DOC],
  [RIGHTS.IS_READ_ONLY_ACCOUNTANT, RIGHTS.IS_COMPANY_SUPPORT_FINANCIAL_DOC],
);
export const IS_BK_WITHOUT_ADMIN_PERMISSIONS = policy(
  [RIGHTS.IS_ACCOUNT, RIGHTS.IS_NOT_ADMIN],
  [RIGHTS.IS_LIMITED_ACCOUNT, RIGHTS.IS_NOT_ADMIN],
  [RIGHTS.IS_RESTRICTED_ACCOUNTANT, RIGHTS.IS_NOT_ADMIN],
  [RIGHTS.IS_READ_ONLY_ACCOUNTANT, RIGHTS.IS_NOT_ADMIN],
);
export const IS_ALLOW_SUPPLIER_FORM_FOR_BK_WITHOUT_ADMIN_PERMISSIONS = policy(
  [RIGHTS.IS_ACCOUNT, RIGHTS.IS_NOT_ADMIN, RIGHTS.ALLOW_SUPPLIER_FORM],
  [RIGHTS.IS_LIMITED_ACCOUNT, RIGHTS.IS_NOT_ADMIN, RIGHTS.ALLOW_SUPPLIER_FORM],
  [RIGHTS.IS_RESTRICTED_ACCOUNTANT, RIGHTS.IS_NOT_ADMIN, RIGHTS.ALLOW_SUPPLIER_FORM],
  [RIGHTS.IS_READ_ONLY_ACCOUNTANT, RIGHTS.IS_NOT_ADMIN, RIGHTS.ALLOW_SUPPLIER_FORM],
);
export const ALLOW_APPROVAL_GROUPS_FOR_BK = policy(
  [RIGHTS.IS_ACCOUNT, RIGHTS.ALLOW_APPROVALS_GROUPS],
  [RIGHTS.IS_LIMITED_ACCOUNT, RIGHTS.ALLOW_APPROVALS_GROUPS],
  [RIGHTS.IS_RESTRICTED_ACCOUNTANT, RIGHTS.ALLOW_APPROVALS_GROUPS],
  [RIGHTS.IS_READ_ONLY_ACCOUNTANT, RIGHTS.ALLOW_APPROVALS_GROUPS],
);
export const ALLOW_SUPPLIER_FORM = policy([RIGHTS.ALLOW_SUPPLIER_FORM]);
export const ALLOW_APPROVALS_GROUPS = policy([RIGHTS.ALLOW_APPROVALS_GROUPS]);
export const IS_COMPANY_SUPPORT_FINANCIAL_DOC = policy(RIGHTS.IS_COMPANY_SUPPORT_FINANCIAL_DOC);
export const IS_ADMIN = policy(RIGHTS.IS_ADMIN);
export const IS_EXPIRED = policy(RIGHTS.IS_EXPIRED);
export const IS_DOCUMENT_SCHEDULED_ACCEPTANCE = policy(RIGHTS.IS_DOCUMENT_SCHEDULED_ACCEPTANCE);
export const IS_WORK_WITH_FINANCIAL_DOCUMENT = policy([
  RIGHTS.IS_NOT_USER,
  RIGHTS.IS_NOT_CONFIDENTIAL_USER,
  RIGHTS.IS_COMPANY_SUPPORT_FINANCIAL_DOC,
]);

export const IS_ALLOW_MERGE_DOCUMENTS = policy(
  RIGHTS.IS_USER,
  RIGHTS.IS_RESTRICTED_USER,
  RIGHTS.IS_CONFIDENTIAL_USER,
  RIGHTS.IS_CONFIDENTIAL_RESTRICTED_USER,
  RIGHTS.IS_ACCOUNT,
  RIGHTS.IS_LIMITED_ACCOUNT,
  RIGHTS.IS_RESTRICTED_ACCOUNTANT,
  RIGHTS.IS_READ_ONLY_ACCOUNTANT,
);

export const IS_WORK_ON_WITH_CURRENT_DOCUMENT_AS_FINANCIAL = policy([
  RIGHTS.IS_CURRENT_DOCUMENT_FINANCIAL,
  RIGHTS.IS_NOT_USER,
  RIGHTS.IS_NOT_RESTRICTED_USER,
  RIGHTS.IS_NOT_CONFIDENTIAL_USER,
  RIGHTS.IS_NOT_CONFIDENTIAL_RESTRICTED_USER,
]);

export const CAN_CHECK_DUPLICATE_ERP_DOCS = policy([RIGHTS.IS_CURRENT_DOCUMENT_FINANCIAL, RIGHTS.IS_NOT_SUPPLIER_USER]);

export const IS_ACCEPTABLE_DOCUMENT = policy([
  RIGHTS.IS_CURRENT_DOCUMENT_FINANCIAL,
  RIGHTS.IS_NOT_USER,
  RIGHTS.IS_NOT_CONFIDENTIAL_USER,
  RIGHTS.IS_NOT_RESTRICTED_USER,
  RIGHTS.IS_NOT_SUPPLIER_USER,
  RIGHTS.IS_NOT_CONFIDENTIAL_RESTRICTED_USER,
  RIGHTS.IS_COMPANY_SUPPORT_FINANCIAL_DOC,
  RIGHTS.IS_NOT_ACCEPTED_DOCUMENT,
  RIGHTS.IS_NOT_DOCUMENT_BEING_PROCESSED,
]);

export const ALLOW_FRESHDESK = policy(RIGHTS.ALLOW_FRESHDESK);
