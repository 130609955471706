/* @flow */
import mediaQuery from 'lib/mediaQuery';

export const params = {
  width: 183, // elemnt width
  gap: 20,
  k: 40, // width dead zone
  minN: 2, // min elements on page
  height: 260,
};
export const xsParams = {
  width: 140, // elemnt width
  gap: 15,
  k: 40, // width dead zone
  minN: 2, // min elements on page
  height: 200,
};

export const sideBarWidth = () => (window.innerHeight > mediaQuery.maxHeightNumber ? 470 : 380);
const maxSidebarWidth = window.innerWidth - (params.width + params.gap) * 2 - params.k - 8;

export const getWidth = ({ linkedSidebarWidth = 0 }: { linkedSidebarWidth: number }) => {
  const minWidth = sideBarWidth();
  return Math.min(Math.max(minWidth, linkedSidebarWidth), maxSidebarWidth);
};
