/* @flow */
import { useLayoutEffect } from 'react';

// redux
import { useDispatch } from 'react-redux';

// theme
import { useTheme } from '@mui/material';

// actions
import { onInitOneSignalAction } from 'domain/chat/chatActions';

export type Props = {};

const OneSignalInit: React$StatelessFunctionalComponent<Props> = () => {
  const dispatch = useDispatch();
  const {
    labelOptions: { onesignalAppId },
  } = useTheme();

  useLayoutEffect(() => {
    window.OneSignal = window.OneSignal || [];
    const { OneSignal } = window;
    // process.env.REACT_APP_ONESIGNAL_APP_ID  is set on dev/release envs only.
    // prod env should not have this variable set as prod keys are retrieved from WL config
    const appId = process.env.REACT_APP_ONESIGNAL_APP_ID ? process.env.REACT_APP_ONESIGNAL_APP_ID : onesignalAppId;
    console.log('OneSignal appId, ', appId);
    // we initializa SDK for every env except for local, not to get configuraion error
    // as we dont have app allowed on localhost
    // it will anyway work on allowed domains only and wont work on dynamic domains like PR
    OneSignal.push(() => {
      OneSignal.init({
        appId,
        allowLocalhostAsSecureOrigin: true,
        autoResubscribe: true,
      });
      dispatch(onInitOneSignalAction());
    });
  }, [dispatch, onesignalAppId]);

  return null;
};

export default OneSignalInit;
