// @flow
import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import Stack from '@mui/material/Stack';
import bgImg from './images/bgImage.svg';
import Typography from '@mui/material/Typography';

type Props = {};

class MobileApprovalHeader extends React.Component<Props> {
  render() {
    return (
      <Stack flex={1} justifyContent="center" alignItems="center" sx={{ p: 2 }}>
        <img src={bgImg} alt="Background image" />
        <Typography variant="body1" maxWidth={170} textAlign="center" sx={{ mt: 3 }}>
          <FormattedMessage id="mobile.approval.noPending" defaultMessage="No documents pending approval" />
        </Typography>
      </Stack>
    );
  }
}

export default MobileApprovalHeader;
