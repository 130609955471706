// @flow
import React, { useState, useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';

import Dialog from 'components/mui/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Stack from '@mui/material/Stack';
import TextFieldBase from 'components/mui/Form/TextField/TextFieldBase';
import Button from '@mui/material/Button';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

const reasonsList = {
  invalid: 'Invalid document type',
  data: 'There is not enough data in the document',
  damaged: 'Document is damaged or of poor quality',
  refers: 'The document refers to another company',
};

type ReasonsType = $Keys<typeof reasonsList>;

type TDialogRejectDocument = {
  onClose: () => void,
  onSubmit: (s: { reason: ?string, message: ?string }) => void,
};

const DialogRejectDocument: React$StatelessFunctionalComponent<TDialogRejectDocument> = ({ onClose, onSubmit }) => {
  const { formatMessage } = useIntl();

  const [reason, setReason] = useState(undefined);
  const [message, setMessage] = useState('');

  const onChange = useCallback((e: SyntheticInputEvent<HTMLInputElement>) => {
    setMessage(e.currentTarget.value);
  }, []);

  const onReject = useCallback(
    (e: SyntheticEvent<HTMLFormElement>) => {
      e.preventDefault();

      const langReason = reason
        ? formatMessage({
            id: `document.show.modals.reject.reason.${reason}`,
            defaultMessage: reasonsList[reason],
          })
        : undefined;

      onSubmit({
        reason: langReason,
        message,
      });
    },
    [formatMessage, reason, message, onSubmit],
  );

  const isDisabled = useMemo(() => !(reason || message), [reason, message]);

  return (
    <Dialog
      open
      onClose={onClose}
      title={formatMessage({ id: 'document.show.modals.reject.title', defaultMessage: 'Reject document?' })}
      maxWidth="sm"
      withActions={false}
      withContent={false}
    >
      <form onSubmit={onReject}>
        <DialogContent>
          <Stack spacing={2}>
            <RadioGroup aria-labelledby="reject-reason-group" name="reject-reason">
              {Object.keys(reasonsList).map((item: ReasonsType) => (
                <FormControlLabel
                  key={item}
                  onChange={() => setReason(item)}
                  value={formatMessage({
                    id: `document.show.modals.reject.reason.${item}`,
                    defaultMessage: reasonsList[item],
                  })}
                  control={<Radio />}
                  label={formatMessage({
                    id: `document.show.modals.reject.reason.${item}`,
                    defaultMessage: reasonsList[item],
                  })}
                />
              ))}
            </RadioGroup>
            <TextFieldBase
              value={message}
              onChange={onChange}
              label={formatMessage({ id: 'document.show.modals.reject.details', defaultMessage: 'Details' })}
              placeholder={formatMessage({ id: 'document.show.modals.reject.placeholder', defaultMessage: 'Details…' })}
              fullWidth
              autoFocus
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button variant="text" onClick={onClose}>
            {formatMessage({
              id: 'button.cancel',
              defaultMessage: 'Cancel',
            })}
          </Button>
          <Button color="error" type="submit" disabled={isDisabled}>
            {formatMessage({
              id: 'document.show.modals.reject.btn',
              defaultMessage: 'Reject',
            })}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default DialogRejectDocument;
