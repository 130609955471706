// @flow
import FormControlLabel from '@mui/material/FormControlLabel';
import { styled } from '@mui/styles';

export const FormControlLabelSwitch = styled(FormControlLabel, {
  shouldForwardProp: (propName) => propName !== 'labelColor',
})(({ theme, checked, labelColor }) => ({
  fontSize: theme.typography.pxToRem(14),
  marginLeft: theme.spacing(-1), // because custom switch, maybe need add custom FormControlLabel component with this margin
  color: checked && labelColor ? theme.palette[labelColor].main : 'inherit', // prop - if we want change label color

  '& .MuiFormControlLabel-label': {
    fontSize: theme.typography.pxToRem(14),
  },
}));
