import Container from '@mui/material/Container';

import mobileCurveMask from 'pages/authPage/Layout/AuthAside/Default/images/mobile-curve-mask.svg';

import { styled } from '@mui/material/styles';

const ASIDE_WIDTH = 520;

export const mqAuthHeight = '(min-height:900px)';

export const AuthLayoutGrid = styled('div', { label: 'AuthLayout' })(({ theme }) => ({
  display: 'flex',
  minHeight: '100vh',
  width: '100%',
  position: 'relative',

  [theme.breakpoints.down('md')]: {
    '&:before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: -1,
      maskImage: `url(${mobileCurveMask})`,
      maskSize: 'cover',
      maskPosition: 'left bottom',
      maskRepeat: 'no-repeat',
      backgroundImage: `linear-gradient(90deg, ${theme.palette.primary.main} 0%, ${theme.palette.secondary.main} 100%)`,
    },
  },
}));

export const AuthAsideBase = styled('aside', { label: 'AuthAside' })(({ theme }) => ({
  // https://stackoverflow.com/questions/49669689/how-to-create-a-curve-between-two-gradient-using-css
  //   https://codepen.io/Ferie/pen/MxwpYB
  //   https://stackoverflow.com/questions/45494235/is-it-possible-to-do-a-curved-line-with-css-gradient
  //   https://stackoverflow.com/questions/50237001/how-can-i-alter-the-curve-of-a-radial-gradient-in-css
  display: 'flex',
  flexDirection: 'column',
  flex: '0 0 auto',
  width: ASIDE_WIDTH,

  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));

export const AuthMain = styled('main', { label: 'AuthMain' })(() => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
}));

export const AuthMainContainer = styled(Container, { label: 'AuthMainContainer' })(() => ({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
}));

export const AuthMainContent = styled('div', { label: 'AuthMainContent' })(({ theme }) => ({
  width: 480,
  margin: 'auto',
  padding: theme.spacing(2),

  [theme.breakpoints.down('md')]: {
    maxWidth: 480,
    width: '100%',
  },
}));
