import type { TDimensions } from 'pages/document/page/components/ApprovalStampManagement/types.js.flow';
import { Record, RecordFactory } from 'immutable';

export const DimensionsFactory: RecordFactory<TDimensions> = new Record({
  width: 0,
  height: 0,
});

// BACK-END calculating for scaling stamp according page
// smaller_coefficient = 5.0
// signature_width = page_width / smaller_coefficient
// signature_height = page_height / smaller_coefficient
export const STAMP_SCALE_DEFAULT_COEFFICIENT = 5;
export const STAMP_SVG_HEIGHT = 474; // 474 - original stamp height(svg viewBox)
export const STAMP_SVG_WIDTH = 254; // 254 - original stamp width(svg viewBox)
export const STAMP_SHIFT_X = 5;
export const STAMP_SHIFT_Y = 5;
