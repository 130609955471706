// @flow
import React from 'react';
import { Link, type LocationShape } from 'react-router-dom';

type TRouterLink = {
  href: string | LocationShape,
};

// if we want to use router Link component in Mui Link component - we need forward ref
const RouterLink = React.forwardRef((props: TRouterLink, ref) => {
  // TODO: until we can describe the types :(
  // eslint-disable-next-line react/prop-types
  const { href, ...other } = props;
  return <Link ref={ref} to={href} {...other} />;
});

export default RouterLink;
